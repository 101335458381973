import { default as ConfidenceInterval } from './ConfidenceInterval';
import { default as EffectSize } from './EffectSize';
import { default as Error } from './Error';
import { default as Graph } from './Graph';
import { default as Means } from './Means';
import { default as Root } from './Root';
import { default as Status } from './Status';
import { default as Title } from './Title';

const pkg = Object.assign(Root, {
  EffectSize,
  Graph,
  Means,
  Title,
  ConfidenceInterval,
  Status,
  Error,
});

export {
  pkg as MetricResult,
  ConfidenceInterval as MetricResultConfidenceInterval,
  EffectSize as MetricResultEffectSize,
  Graph as MetricResultGraph,
  Means as MetricResultMeans,
  Status as MetricResultStatus,
  Title as MetricResultTitle,
  Error as MetricResultError,
};
