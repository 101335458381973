import React from 'react';

import { ConfidenceWebsiteLink } from '@spotify-confidence/core-react';
import {
  MetricsV1MetricInput,
  MetricsV1MetricMetricTypeSpecInput,
} from '@spotify-confidence/plugin-graphql';

import { defaultSpecs } from '../../../metricTypes';
import { EntitySelect } from '../EntitySelect';
import { FactTableSelect } from '../FactTableSelect';

type MetricInputsFormProps = {
  value: MetricsV1MetricInput;
  onChange: (newInput: MetricsV1MetricInput) => void;
  defaultSpec?: MetricsV1MetricMetricTypeSpecInput;
};

export const MetricInputsForm = ({
  value,
  onChange,
  defaultSpec,
}: MetricInputsFormProps) => {
  // Reset previously selected columns if fact table or entity changes
  const getDefaultTypeSpec = (): MetricsV1MetricMetricTypeSpecInput => {
    if (defaultSpec) {
      return defaultSpec;
    }
    if (value.typeSpec?.averageMetricSpec) {
      return defaultSpecs.average;
    } else if (value.typeSpec?.ratioMetricSpec) {
      return defaultSpecs.ratio;
    }
    return {};
  };

  return (
    <>
      <EntitySelect
        label="Entity"
        value={value.entity}
        onChange={entity =>
          onChange({
            ...value,
            entity,
            factTable: '',
            typeSpec: getDefaultTypeSpec(),
          })
        }
        helperText={
          <>
            Entities represent what you experiment on, for example users.{' '}
            <ConfidenceWebsiteLink route="/docs/entities" underline="always">
              Learn more
            </ConfidenceWebsiteLink>
          </>
        }
      />
      <FactTableSelect
        value={value.factTable}
        onChange={factTable => {
          if (factTable !== value.factTable) {
            onChange({
              ...value,
              factTable,
              filter: null,
              typeSpec: getDefaultTypeSpec(),
            });
          }
        }}
        helperText={
          <>
            Fact tables contain events or measurements that describe entities.{' '}
            <ConfidenceWebsiteLink route="/docs/fact-tables" underline="always">
              Learn more
            </ConfidenceWebsiteLink>
          </>
        }
        disabled={!value.entity}
        entity={value.entity}
      />
    </>
  );
};
