import { createContext, useContext } from 'react';

type Context = {
  isCollapsed: boolean;
};

const context = createContext<Context>({
  isCollapsed: false,
});

export const FloatingHeaderActionsContextProvider = context.Provider;

export const useFloatingHeaderActionsContext = () => {
  const ctx = useContext(context);
  if (!ctx) {
    throw new Error('FloatingHeaderActionsContextProvider not wrapped');
  }
  return ctx;
};
