import {
  createPlugin,
  createRoutableExtension,
} from '@backstage/core-plugin-api';

import {
  assignmentTableRouteRef,
  assignmentTablesCreateRouteRef,
  assignmentTablesRouteRef,
  entitiesRouteRef,
  entityRelationTableRouteRef,
  entityRelationTablesCreateRouteRef,
  entityRouteRef,
  factTableRouteRef,
  factTablesCreateRouteRef,
  factTablesRouteRef,
  metricRouteRef,
  metricsRouteRef,
  rootRouteRef,
  workflowInstanceRouteRef,
} from './routes';

export const metricsPlugin = createPlugin({
  id: 'metrics',
  routes: {
    root: rootRouteRef,
    metrics: metricsRouteRef,
    metric: metricRouteRef,
    entities: entitiesRouteRef,
    entity: entityRouteRef,
    entityRelationTable: entityRelationTableRouteRef,
    entityRelationTableCreate: entityRelationTablesCreateRouteRef,
    factTables: factTablesRouteRef,
    factTable: factTableRouteRef,
    factTablesCreate: factTablesCreateRouteRef,
    assignmentTables: assignmentTablesRouteRef,
    assignmentTable: assignmentTableRouteRef,
    assignmentTablesCreate: assignmentTablesCreateRouteRef,
  },
  externalRoutes: {
    workflowInstance: workflowInstanceRouteRef,
  },

  featureFlags: [{ name: 'entity-relation-tables' }],
});

export const ConfidenceMetricsPage = metricsPlugin.provide(
  createRoutableExtension({
    name: 'ConfidenceMetricsPage',
    component: () =>
      import(
        /* webpackChunkName: "confidence-metrics", webpackPrefetch: 10 */ './Router'
      ).then(m => m.Router),
    mountPoint: rootRouteRef,
  }),
);
