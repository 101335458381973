import React from 'react';

import {
  Box,
  CircularProgress,
  Collapse,
  InputLabel,
  TextFieldProps as MuiTextFieldProps,
  TextField,
} from '@material-ui/core';
import {
  Alert,
  AlertTitle,
  Autocomplete,
  AutocompleteProps,
  AutocompleteRenderInputParams,
  Value,
} from '@material-ui/lab';

import _ from 'lodash';

import {
  PaginatedListItem,
  UseAutocompleteOptions,
  useAutocompleteCreateOption,
  useAutocompleteOptions,
} from '../../hooks';
import { AutocompleteEmptyState } from './AutocompleteEmptyState';
import {
  AutocompleteTypeConversionMethod,
  AutocompleteTypeConversionMethods,
  useAutocompleteTypeConversionMethods,
} from './useAutocompleteTypeConversionMethods';

export type ConfidenceAutocompleteProps<
  T extends PaginatedListItem = PaginatedListItem,
  Multiple extends boolean | undefined = undefined,
  DisableClearable extends boolean | undefined = undefined,
  FreeSolo extends boolean | undefined = undefined,
> = React.PropsWithChildren<
  Omit<
    AutocompleteProps<string, Multiple, DisableClearable, FreeSolo>,
    | 'renderInput'
    | 'options'
    | 'ListBoxProps'
    | 'renderTags'
    | 'onChange'
    | AutocompleteTypeConversionMethod
  > &
    AutocompleteTypeConversionMethods<T, Multiple, DisableClearable, FreeSolo> &
    Pick<
      MuiTextFieldProps,
      | 'label'
      | 'placeholder'
      | 'helperText'
      | 'required'
      | 'variant'
      | 'margin'
      | 'name'
    > &
    Pick<
      // TODO: type comparison fails for queries, see if it's possible to fix this for a nicer developer experience
      // in the meantime the hook should be robust enough to handle non-compliant queries
      UseAutocompleteOptions<any>,
      'query' | 'queryOptions' | 'searchField'
    > & {
      error?: Error | string;
      autoSelectSingle?: boolean;
      onCreate?: (searchInput?: string) => void;
    }
>;

/*
  Use this component to allow selecting one or multiple Confidence resources by providing a lazy query to fetch a list of them.
  The query should return a paginated list of items, for example "entities" or "users".
  The component handles infinite scrolling, filtering based on user input and fetching pre-selected options (including loading/error states).
  You interact with the selected value as a string, but can render/disable/compare options based on the actual resource type.
*/

export const ConfidenceAutocomplete = <
  T extends PaginatedListItem = PaginatedListItem,
  Multiple extends boolean | undefined = undefined,
  DisableClearable extends boolean | undefined = undefined,
  FreeSolo extends boolean | undefined = undefined,
>({
  query,
  queryOptions,
  searchField,
  children,
  label,
  required,
  disabled,
  placeholder,
  error: inputError,
  helperText,
  loading: loadingOverride,
  value: selected,
  autoSelectSingle,
  variant = 'outlined',
  margin = 'dense',
  name,
  onCreate,
  ...autocompleteProps
}: ConfidenceAutocompleteProps<T, Multiple, DisableClearable, FreeSolo>) => {
  const [searchInput, setSearchInput] = React.useState('');
  const { loading, error, options, called, fetchNextPage } =
    useAutocompleteOptions<ReturnType<typeof query>[1]['data'], T>({
      queryOptions,
      query,
      searchField,
      search: searchInput,
      selected: selected ?? undefined,
    });

  const {
    getOptionLabel,
    getOptionDisabled,
    renderOption,
    getOptionSelected,
    groupBy,
    onHighlightChange,
    onChange,
    filterOptions,
    renderTags,
  } = useAutocompleteTypeConversionMethods<
    T,
    Multiple,
    DisableClearable,
    FreeSolo
  >({
    ...autocompleteProps,
    options,
    loading,
  });

  const hasError = Boolean(error || inputError);
  const showEmptyState = Boolean(
    called &&
      !onCreate &&
      !hasError &&
      !searchInput &&
      !loading &&
      !options.length &&
      !disabled,
  );

  const optionList = React.useMemo(() => options.map(o => o.name), [options]);

  let inputHelperText = helperText;
  if (inputError) {
    inputHelperText = _.isString(inputError) ? inputError : inputError.message;
  }

  const suggestedCreateName = !optionList.some(
    o => searchInput === getOptionLabel(o),
  )
    ? searchInput
    : undefined;

  const { ListboxComponent, classes, noOptionsText } =
    useAutocompleteCreateOption({
      createName: suggestedCreateName,
      label: suggestedCreateName ? `Create "${suggestedCreateName}"` : 'Create',
      onCreate,
    });

  const renderInput = React.useCallback(
    ({
      InputProps: { endAdornment, ...InputProps },
      ...params
    }: AutocompleteRenderInputParams) => {
      /*
        HTML5 form validation for required depends on a text value being present
        so we override the behavior to make it work as expected for multi-select
      */
      let textfieldRequired = required;
      if (_.isArray(selected)) {
        textfieldRequired = required ? selected.length === 0 : false;
      } else if (_.isArray(InputProps.startAdornment)) {
        // Fallback for uncontrolled values
        textfieldRequired = required
          ? InputProps.startAdornment.length === 0
          : false;
      }
      return (
        <TextField
          error={!!inputError}
          {...params}
          InputProps={{
            ...InputProps,
            endAdornment: (
              <>
                {endAdornment}
                {loading && <CircularProgress size="1em" color="inherit" />}
              </>
            ),
          }}
          InputLabelProps={{
            required,
          }}
          placeholder={placeholder}
          required={textfieldRequired}
          helperText={inputHelperText}
          variant={variant}
          margin={margin}
          name={name}
        />
      );
    },
    [
      inputError,
      loading,
      placeholder,
      required,
      inputHelperText,
      variant,
      margin,
      name,
      selected,
    ],
  );

  // Infinite scroll
  const ListboxProps = React.useMemo(() => {
    return {
      onScroll: (event: React.SyntheticEvent) => {
        const listboxNode = event.currentTarget;
        const { scrollHeight, scrollTop, clientHeight } = listboxNode;

        const endIsVisible = scrollHeight - scrollTop - clientHeight <= 10;
        if (endIsVisible) {
          fetchNextPage();
        }
      },
    };
  }, [fetchNextPage]);

  // If autoSelectSingle is enabled, auto-select the only option if there is only one
  React.useEffect(() => {
    if (!autoSelectSingle) {
      return;
    }

    const nothingSelected = _.isArray(selected)
      ? _.isEmpty(selected)
      : !selected;
    if (nothingSelected && options.length === 1) {
      const option = options[0].name;
      if (!getOptionDisabled?.(option)) {
        autocompleteProps.onChange?.(
          {} as React.ChangeEvent<{}>,
          (autocompleteProps.multiple ? [option] : option) as Value<
            string,
            Multiple,
            DisableClearable,
            FreeSolo
          >,
          'select-option',
        );
      }
    }
  }, [
    autoSelectSingle,
    options,
    selected,
    autocompleteProps.onChange,
    autocompleteProps.multiple,
  ]);

  // Since we are using a controlled input, we need to update the input value for already selected values
  React.useEffect(() => {
    if (!selected || !_.isString(selected) || autocompleteProps.multiple) {
      return;
    }
    if (
      !searchInput ||
      (searchInput === selected && searchInput !== getOptionLabel(selected))
    ) {
      setSearchInput(getOptionLabel(selected) || '');
    }
  }, [selected, searchInput, getOptionLabel, !autocompleteProps.multiple]);

  return (
    <div>
      {label && (
        <InputLabel required={required} disabled={disabled}>
          {label}
        </InputLabel>
      )}
      <Collapse in={!!error} unmountOnExit>
        <Alert severity="error">
          <AlertTitle>Something went wrong</AlertTitle>
          {error?.message}
        </Alert>
      </Collapse>
      <Collapse in={!showEmptyState}>
        <Autocomplete<string, Multiple, DisableClearable, FreeSolo>
          value={selected}
          options={optionList}
          loading={loading}
          disabled={disabled}
          inputValue={searchInput}
          onInputChange={(_e, v) => setSearchInput(v)}
          renderInput={renderInput}
          ListboxProps={ListboxProps}
          ListboxComponent={ListboxComponent}
          noOptionsText={noOptionsText}
          {...autocompleteProps}
          classes={{
            ...classes,
            ...autocompleteProps.classes,
          }}
          renderTags={renderTags}
          getOptionLabel={getOptionLabel}
          getOptionDisabled={getOptionDisabled}
          getOptionSelected={getOptionSelected}
          groupBy={groupBy}
          onHighlightChange={onHighlightChange}
          onChange={onChange}
          renderOption={renderOption}
          filterOptions={filterOptions}
        />
      </Collapse>
      <Collapse in={showEmptyState} unmountOnExit>
        <Box marginTop={1}>
          {children || (
            <AutocompleteEmptyState fullWidth={autocompleteProps.fullWidth} />
          )}
        </Box>
      </Collapse>
    </div>
  );
};
