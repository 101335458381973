import { Typography, styled } from '@material-ui/core';

import { HIGH_LEVEL_AXIS_HEIGHT, LOW_LEVEL_AXIS_HEIGHT } from './constants';

export const HighLevelAxis = styled('div')({
  display: 'flex',
  alignItems: 'center',
  overflowX: 'hidden',
  height: HIGH_LEVEL_AXIS_HEIGHT,
});

export const HighLevelTick = styled(Typography)(({ theme }) => ({
  fontWeight: 'bold',
  position: 'absolute',
  transition: theme.transitions.create('transform'),
  background: theme.palette.background.default,
  padding: theme.spacing(0, 4),
  marginLeft: -theme.spacing(4),
}));

export const LowLevelAxis = styled('div')({
  height: LOW_LEVEL_AXIS_HEIGHT,
});

export const TickWrapper = styled('div')(({ theme }) => ({
  position: 'absolute',
  top: '50%',
  bottom: 0,
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  transition: theme.transitions.create('transform'),
  pointerEvents: 'none',
}));
