import React from 'react';
import { useTimeoutFn } from 'react-use';

import {
  getTypeOrNull,
  useCheckResourcePermissionsMutation,
} from '@spotify-confidence/plugin-graphql';

export type UseCheckResourcePermissionsOptions = {
  // Name of the resource to check permissions for
  name?: string;

  // The relation. e.g., 'share'
  can?: string;

  // Optimistic permissions. If true, we will assume that the user has
  // permissions to perform the action. If false, we will assume that
  // the user does not have permissions to perform the action.
  optimistic?: boolean;
};

export function useCheckResourcePermissions({
  can,
  name,
  optimistic = false,
}: UseCheckResourcePermissionsOptions) {
  const [check, { data, error, loading }] =
    useCheckResourcePermissionsMutation();

  // TODO: This is a workaround for a bug where Apollo doesn't properly
  // make the request when the hook is first mounted. It seems to
  // somehow be related to the event loop, and could only be reproduced
  // in the Admin plugin subpages on first page load (not when navigating
  // between different pages).

  const [isReady, cancel, reset] = useTimeoutFn(() => {
    if (name && can) {
      check({
        variables: {
          resourceRelations: [
            {
              resource: name,
              relation: `can_${can}`,
            },
          ],
        },
      });
    }
  }, 0);

  React.useEffect(() => {
    if (!name || !can) {
      cancel();
    } else {
      reset();
    }
  }, [name, can, cancel, reset]);

  return {
    allowed:
      getTypeOrNull(data?.executeCheck, 'FgaV1ExecuteCheckResponse')
        ?.allAllowed ?? optimistic,
    hasData: !!data,
    error,
    loading: loading || !isReady(),
  };
}
