import React from 'react';

import {
  Checkbox,
  Collapse,
  FormControlLabel,
  FormHelperText,
} from '@material-ui/core';

import { FormFieldsBox, timeWindowUtils } from '@spotify-confidence/core-react';
import { MetricsV1MetricVarianceReductionConfigInput } from '@spotify-confidence/plugin-graphql';

import { TimeWindowDropdown } from './TimeWindowDropdown';

type VarianceReductionFormProps = {
  value?: MetricsV1MetricVarianceReductionConfigInput | null;
  onChange: (newValue: MetricsV1MetricVarianceReductionConfigInput) => void;
  disabled?: boolean;
};

export function VarianceReductionForm({
  value,
  onChange,
  disabled,
}: VarianceReductionFormProps) {
  const handleChange = (
    varianceReductionConfig: Partial<MetricsV1MetricVarianceReductionConfigInput>,
  ) => {
    onChange({
      ...value,
      ...varianceReductionConfig,
    });
  };

  return (
    <FormFieldsBox>
      <div>
        <FormControlLabel
          disabled={disabled}
          control={
            <Checkbox
              checked={Boolean(!value?.disabled)}
              name="variance-reduction-checkbox"
              onChange={e => {
                const isEnabled = e.target.checked;
                handleChange({
                  disabled: !isEnabled,
                  aggregationWindowOverride: isEnabled
                    ? value?.aggregationWindowOverride
                    : null,
                });
              }}
            />
          }
          label="Enabled"
        />
        <FormHelperText>
          Toggle variance reduction for this metric.
        </FormHelperText>
      </div>
      <Collapse in={Boolean(!value?.disabled)}>
        <TimeWindowDropdown
          label="Pre-exposure window"
          helperText="Override the window used to calculate pre-exposure data. By default the time window duration is used."
          name="variance-reduction-aggregation-window"
          disabled={disabled || Boolean(value?.disabled)}
          value={value?.aggregationWindowOverride || ''}
          allowEmpty
          onChange={aggregationWindowOverride =>
            handleChange({
              aggregationWindowOverride: aggregationWindowOverride || null,
            })
          }
          parseWindow={timeWindow =>
            timeWindow === ''
              ? 'Default'
              : timeWindowUtils.parseWindow(timeWindow)
          }
          fullWidth
        />
      </Collapse>
    </FormFieldsBox>
  );
}
