import {
  MetricDetailsFragment,
  MetricsV1AggregationThresholdDirection,
  MetricsV1AggregationType,
  MetricsV1MetricAverageMetricSpec,
  MetricsV1MetricMetricTypeSpec,
  MetricsV1MetricRatioMetricSpec,
} from '@spotify-confidence/plugin-graphql';

export type MetricType =
  | 'average'
  | 'ratio'
  | 'conversion'
  | 'consumption'
  | 'ctr'
  | 'unknown';

export const metricTypeName: Record<MetricType, string> = {
  average: 'Average / Share',
  ratio: 'Ratio',
  conversion: 'Conversion',
  consumption: 'Consumption',
  ctr: 'Click-through rate',
  unknown: 'Unknown',
};

export const getMetricType = (metric: MetricDetailsFragment): MetricType => {
  const metricType = metric.labels.find(
    label => label.key === 'metricType',
  )?.value;
  if (metricType) {
    return metricType as MetricType;
  }
  if (metric.typeSpec?.ratioMetricSpec) {
    return 'ratio';
  }
  if (metric.typeSpec?.averageMetricSpec) {
    return 'average';
  }
  return 'unknown';
};

type AverageTypeSpec = { averageMetricSpec: MetricsV1MetricAverageMetricSpec };
export const defaultAverageSpec: AverageTypeSpec = {
  averageMetricSpec: {
    aggregation: { type: MetricsV1AggregationType.AggregationTypeCount },
  },
};

export const defaultConversionSpec: AverageTypeSpec = {
  averageMetricSpec: {
    aggregation: {
      type: MetricsV1AggregationType.AggregationTypeCount,
      threshold: {
        direction:
          MetricsV1AggregationThresholdDirection.AggregationThresholdDirectionGte,
        threshold: { value: '1' },
      },
    },
  },
};

export const defaultConsumptionSpec: AverageTypeSpec = {
  averageMetricSpec: {
    aggregation: {
      type: MetricsV1AggregationType.AggregationTypeSum,
    },
  },
};

type RatioTypeSpec = { ratioMetricSpec: MetricsV1MetricRatioMetricSpec };

export const defaultRatioSpec: RatioTypeSpec = {
  ratioMetricSpec: {
    numeratorAggregation: {
      type: MetricsV1AggregationType.AggregationTypeCount,
    },
    denominatorAggregation: {
      type: MetricsV1AggregationType.AggregationTypeCount,
    },
  },
};

export const defaultCtrSpec: RatioTypeSpec = {
  ratioMetricSpec: {
    numeratorAggregation: {
      type: MetricsV1AggregationType.AggregationTypeSum,
    },
    numerator: {
      name: '',
    },
    denominatorAggregation: {
      type: MetricsV1AggregationType.AggregationTypeCount,
    },
  },
};

export const defaultSpecs: Record<MetricType, MetricsV1MetricMetricTypeSpec> = {
  average: defaultAverageSpec,
  conversion: defaultConversionSpec,
  consumption: defaultConsumptionSpec,
  ratio: defaultRatioSpec,
  ctr: defaultCtrSpec,
  unknown: {},
};
