export const extractLastNameComponent = (name: string | undefined) => {
  if (name === undefined) {
    return undefined;
  }

  const parts = name.split('/');
  if (parts.length <= 0) {
    return name;
  }

  return parts[parts.length - 1];
};

// TODO: move this to workflow package when created
// Extract the workflow type from a name with
// pattern `workflows/<type>/instances/<instance-id>`
export const extractWorkflowFromInstanceName = (name: string | undefined) => {
  if (name === undefined) {
    return undefined;
  }

  const parts = name.split('/');
  if (parts.length <= 0) {
    return name;
  }

  return parts[1];
};

// This method intentionally does not use look-behind because it doesn't work in Safari
export const escapeName = (name: string | undefined) =>
  name?.replace(/([^\\])(?=\/)/g, '$1\\')?.replace(/^\//, '\\/');
