import React, { useEffect } from 'react';
import { InView } from 'react-intersection-observer';

import {
  useDelayedLoading,
  useListFilter,
} from '@spotify-confidence/core-react';
import {
  getError,
  getTypeOrNull,
  useEventsQuery,
  useOnEventDefinitionCreatedSubscription,
  useOnEventDefinitionDeletedSubscription,
} from '@spotify-confidence/plugin-graphql';

export const useEvents = ({ pageSize = 25 }: { pageSize?: number }) => {
  const { filterString: filter } = useListFilter();

  const {
    data,
    fetchMore,
    refetch,
    // can be true even if we have data, if we're refetching
    loading,
    error: networkError,
  } = useEventsQuery({
    variables: {
      pageSize,
      filter,
    },
    fetchPolicy: 'cache-and-network',
    errorPolicy: 'all',
  });

  const listResponse = getTypeOrNull(
    data?.eventDefinitions,
    'EventsAdminV1ListEventDefinitionsResponse',
  );

  const appError = getError(data?.eventDefinitions);
  const error = appError || networkError;

  const { nextPageToken, eventDefinitions: events = [] } = listResponse ?? {};

  const InfiniteScroll = () =>
    nextPageToken ? (
      <InView
        onChange={async inView => {
          if (inView && nextPageToken) {
            await fetchMore({
              variables: {
                pageToken: nextPageToken,
              },
            });
          }
        }}
      />
    ) : null;

  // when a new event is created, refetch the list of events
  useOnEventDefinitionCreatedSubscription({
    onData: () => {
      // refetch after a delay to ensure the new event is indexed
      setTimeout(() => refetch(), 2000);
    },
  });

  // when a new event is created, refetch the list of events
  useOnEventDefinitionDeletedSubscription({
    onData: () => {
      // refetch after a delay to ensure the new event is indexed
      setTimeout(() => refetch(), 2000);
    },
  });

  useEffect(() => {
    function handleVisibilityChange() {
      if (!document.hidden) {
        refetch();
      }
    }

    window.addEventListener('visibilitychange', handleVisibilityChange);
    return () => {
      window.removeEventListener('visibilitychange', handleVisibilityChange);
    };
  }, [refetch]);

  const { showLoading } = useDelayedLoading(loading);

  return {
    events,
    // we're loading if it actually takes some time to load,
    // or if we don't have anything yet
    loading: showLoading || (loading && events.length === 0),
    error,
    InfiniteScroll,
  };
};
