import React from 'react';

import { Box } from '@material-ui/core';

import {
  ConfidenceWebsiteLink,
  FeatureFlagged,
  FormFieldsBox,
  SidebarValue,
  SidebarValueWrapper,
} from '@spotify-confidence/core-react';
import { MetricsV1AggregationType } from '@spotify-confidence/plugin-graphql';

import { aggregationUtils } from '../Aggregation';
import { MetricFilters } from '../MetricFilters';
import { MetricInputsForm } from '../MetricInputsForm';
import { RatioForm } from '../RatioForm';
import { metricInputsSection } from './genericMetricSections';
import { MetricSection } from './types';

const replaceEntityNullWithZeroAggs = [
  MetricsV1AggregationType.AggregationTypeSum,
  MetricsV1AggregationType.AggregationTypeCount,
  MetricsV1AggregationType.AggregationTypeCountDistinct,
];

const ratioMetricInputsEditSection: MetricSection = {
  ...metricInputsSection,
  form: (value, onChange) => (
    <FormFieldsBox>
      <MetricInputsForm value={value} onChange={onChange} />
      <FeatureFlagged with="metric-filters">
        <MetricFilters
          factTable={value.factTable}
          filter={value.filter}
          onChange={newFilter => {
            onChange({
              ...value,
              filter: newFilter,
            });
          }}
        />
      </FeatureFlagged>
      {value.typeSpec?.ratioMetricSpec && (
        <RatioForm
          value={value.typeSpec.ratioMetricSpec}
          onChange={ratioMetricSpec =>
            onChange({
              ...value,
              typeSpec: {
                ratioMetricSpec,
              },
            })
          }
          factTable={value.factTable}
          entity={value.entity}
        />
      )}
    </FormFieldsBox>
  ),
  updateMask: [metricInputsSection.updateMask, 'typeSpec.ratioMetricSpec'].join(
    ',',
  ),
  isValid: metric =>
    Boolean(
      metric.entity &&
        metric.factTable &&
        (metric.typeSpec?.ratioMetricSpec?.numerator?.name ||
          metric.typeSpec?.ratioMetricSpec?.denominator?.name),
    ),
};

const toCapLabel = (value: string | undefined | null) => {
  if (value !== undefined && value !== null && !isNaN(parseFloat(value))) {
    return value;
  }

  return 'No cap';
};

const ratioSection: MetricSection = {
  name: 'Ratio',
  description: (
    <>
      The values to get the ratio between across all entities.{' '}
      <ConfidenceWebsiteLink
        route="/docs/metrics#ratio-metrics"
        underline="always"
      >
        Learn more.
      </ConfidenceWebsiteLink>
    </>
  ),
  canBeEditedSafely: false,
  isValid: metric =>
    Boolean(
      metric.typeSpec?.ratioMetricSpec?.numerator?.name ||
        metric.typeSpec?.ratioMetricSpec?.denominator?.name,
    ),
  form: (value, onChange) =>
    value.typeSpec?.ratioMetricSpec && (
      <RatioForm
        value={value.typeSpec?.ratioMetricSpec}
        onChange={ratioMetricSpec =>
          onChange({
            ...value,
            typeSpec: {
              ratioMetricSpec,
            },
            nullHandling: {
              replaceEntityNullWithZero:
                replaceEntityNullWithZeroAggs.includes(
                  ratioMetricSpec.numeratorAggregation.type,
                ) ||
                replaceEntityNullWithZeroAggs.includes(
                  ratioMetricSpec.denominatorAggregation.type,
                ),
            },
          })
        }
        factTable={value.factTable}
        entity={value.entity}
      />
    ),
  summary: metric =>
    metric.typeSpec?.ratioMetricSpec && (
      <SidebarValueWrapper>
        <Box display="grid" gridTemplateColumns="1fr 1fr">
          <SidebarValue
            name="Numerator"
            value={
              metric.typeSpec?.ratioMetricSpec.numerator?.name ||
              'The number of fact rows'
            }
          />
          {metric.typeSpec?.ratioMetricSpec.numerator?.name && (
            <SidebarValue
              name="Numerator method"
              value={aggregationUtils.getAggregationTypeLabel(
                metric.typeSpec?.ratioMetricSpec.numeratorAggregation.type,
              )}
            />
          )}
        </Box>
        <Box display="grid" gridTemplateColumns="1fr 1fr">
          <SidebarValue
            name="Min cap"
            value={`${toCapLabel(
              metric.typeSpec?.ratioMetricSpec.numeratorAggregation.cap?.min
                ?.value,
            )}`}
          />
          <SidebarValue
            name="Max cap"
            value={`${toCapLabel(
              metric.typeSpec?.ratioMetricSpec.numeratorAggregation.cap?.max
                ?.value,
            )}`}
          />
        </Box>
        <Box display="grid" gridTemplateColumns="1fr 1fr">
          <SidebarValue
            name="Denominator"
            value={
              metric.typeSpec?.ratioMetricSpec.denominator?.name ||
              'The number of fact rows'
            }
          />
          {metric.typeSpec?.ratioMetricSpec.denominator?.name && (
            <SidebarValue
              name="Denominator method"
              value={aggregationUtils.getAggregationTypeLabel(
                metric.typeSpec?.ratioMetricSpec.denominatorAggregation.type,
              )}
            />
          )}
        </Box>
        <Box display="grid" gridTemplateColumns="1fr 1fr">
          <SidebarValue
            name="Min cap"
            value={`${toCapLabel(
              metric.typeSpec?.ratioMetricSpec.denominatorAggregation.cap?.min
                ?.value,
            )}`}
          />
          <SidebarValue
            name="Max cap"
            value={`${toCapLabel(
              metric.typeSpec?.ratioMetricSpec.denominatorAggregation.cap?.max
                ?.value,
            )}`}
          />
        </Box>
      </SidebarValueWrapper>
    ),
  updateMask: 'typeSpec.ratioMetricSpec',
};

// Used in the create form
export const createRatioMetricSections: MetricSection[] = [
  metricInputsSection,
  ratioSection,
];

// Used in the create summary + metric page sidebar
export const editRatioMetricSections: MetricSection[] = [
  ratioMetricInputsEditSection,
  ratioSection,
];
