import {
  AuthenticationProvider as Auth0Provider,
  CONFIDENCE_TokenOptions,
  useAuth as useAuth0,
} from './auth0/auth';
import {
  AuthenticationProvider as TestAuthProvider,
  useAuth as useTestAuth,
} from './test/auth';

export * from './auth0/auth.types';
export * from './useOrgId';

const isTestEnv = () =>
  !!(window as typeof window & { Cypress?: object }).Cypress;

export const AuthenticationProvider = isTestEnv()
  ? TestAuthProvider
  : Auth0Provider;
export const useAuth = isTestEnv() ? useTestAuth : useAuth0;
export const TOKEN_OPTIONS = CONFIDENCE_TokenOptions;
