import React from 'react';

import { createStyles, withStyles } from '@material-ui/core';

import {
  ConfidenceInterval as ConfidenceIntervalChart,
  ConfidenceIntervalProps,
} from '@spotify-confidence/core-react';
import { CommentZone } from '@spotify-confidence/plugin-comments-react';

import { useMetricResultContext } from './context';

const styles = createStyles({
  root: {},
});

const ConfidenceInterval = (props: ConfidenceIntervalProps) => {
  const { value: result } = useMetricResultContext();

  return (
    <CommentZone id="confidence-interval">
      <ConfidenceIntervalChart
        observedDifference={result?.result?.estimateRel?.estimate}
        lowerCI={result.result?.estimateRel?.lower ?? 0}
        upperCI={result.result?.estimateRel?.upper ?? 0}
        nim={result.nim}
        significant={result.isSignificant}
        unit={result.isFraction ? '%' : ''}
        {...props}
      />
    </CommentZone>
  );
};

export default withStyles(styles)(ConfidenceInterval);
