import React, { useCallback } from 'react';

import { Button } from '@material-ui/core';
import AddIcon from '@material-ui/icons/Add';

import {
  ActionBar,
  FilterBar,
  FilterListProvider,
  PageLayout,
  extractLastNameComponent,
  useAlert,
  useDialog,
  useMeasureDimensions,
  useNavigate,
} from '@spotify-confidence/core-react';
import { isType } from '@spotify-confidence/plugin-graphql';
import { useCheckResourcePermissions } from '@spotify-confidence/plugin-permissions-react';

import { useRouteRef } from '@backstage/core-plugin-api';

import { useSurfaces } from '../../hooks/useSurfaces';
import { surfaceRouteRef } from '../../routes';
import { SurfaceCreateDialog } from '../SurfaceCreateDialog';
import { SurfaceList } from './SurfaceList';

export const SurfaceIndexPage = () => {
  const { openDialog, closeDialog } = useDialog();
  const alert = useAlert();

  const { allowed } = useCheckResourcePermissions({
    name: 'account',
    can: 'create_surface',
  });

  const [ref, { height }] = useMeasureDimensions<HTMLDivElement>();
  const surfaceRoute = useRouteRef(surfaceRouteRef);
  const navigate = useNavigate();

  const { createSurface } = useSurfaces();

  const handleSubmit = useCallback(
    async ({
      displayName,
      description,
      owner,
    }: {
      owner?: string;
      displayName: string;
      description: string;
    }) => {
      const response = await createSurface({ displayName, description, owner });
      if (isType(response.data?.createSurface, 'WorkflowV1Surface')) {
        const url = surfaceRoute({
          id: extractLastNameComponent(response.data?.createSurface?.name)!,
        });

        // close dialog and navigate to the new surface
        closeDialog();
        navigate(url);
      } else {
        alert.post({
          severity: 'error',
          message:
            response.data?.createSurface?.message ?? 'Something went wrong',
        });
      }
    },
    [],
  );

  const handleCreate = useCallback(() => {
    openDialog({
      content: (
        <SurfaceCreateDialog onSubmit={handleSubmit} onClose={closeDialog} />
      ),
    });
  }, []);

  return (
    <FilterListProvider searchField="name" storageKey="surfaces">
      <PageLayout title="Surfaces">
        <ActionBar ref={ref}>
          <FilterBar />
          <Button
            variant="contained"
            color="primary"
            size="small"
            onClick={handleCreate}
            disabled={!allowed}
            data-testid="create-surface-button"
            startIcon={<AddIcon />}
          >
            Create
          </Button>
        </ActionBar>
        <SurfaceList onCreate={handleCreate} topOffset={height} />
      </PageLayout>
    </FilterListProvider>
  );
};
