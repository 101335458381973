import React from 'react';

import { WithStyles, createStyles, withStyles } from '@material-ui/core';

import { Numeral, NumeralProps } from '@spotify-confidence/core-react';
import { CommentZone } from '@spotify-confidence/plugin-comments-react';
import classNames from 'classnames';

import { useMetricResultContext } from './context';

const styles = createStyles({
  root: {},
});

export interface EffectSizeProps
  extends WithStyles<typeof styles>,
    NumeralProps {
  className?: string;
}

const EffectSize = ({ classes, className, ...rest }: EffectSizeProps) => {
  const { value: result } = useMetricResultContext();
  const value = result?.result?.estimateRel?.estimate;

  return (
    <CommentZone id="effect-size">
      <Numeral
        className={classNames(classes.root, className)}
        prefix={(value ?? 0) < 0 ? '-' : '+'}
        value={value ? Math.abs(value) : undefined}
        suffix="%"
        data-testid="metric-result-effect-size"
        {...rest}
      />
    </CommentZone>
  );
};

export default withStyles(styles)(EffectSize);
