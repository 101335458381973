import React from 'react';
import { InView } from 'react-intersection-observer';
import { Link } from 'react-router-dom';

import {
  Box,
  Chip,
  Link as MuiLink,
  Table,
  TableBody,
  TableCell,
  TableRow,
} from '@material-ui/core';

import {
  ACTION_BAR_HEIGHT,
  ConfidenceWebsiteLink,
  FilterListEmptyState,
  Highlight,
  ResourceTableRow,
  StickyTableContainer,
  StickyTableHead,
  extractLastNameComponent,
  useListFilter,
} from '@spotify-confidence/core-react';
import {
  getError,
  getTypeOrNull,
  useListFlagAppliedConnectionsQuery,
} from '@spotify-confidence/plugin-graphql';
import { formatDuration, intervalToDuration } from 'date-fns';

import { useRouteRef } from '@backstage/core-plugin-api';

import { flagAppliedConnectionRouteRef } from '../../../routes';
import {
  getFlagAppliedConnectionDetails,
  getFlagAppliedDestinationName,
} from '../../helpers';

type FlagAppliedListProps = {
  topOffset?: number;
};

export const FlagAppliedList = ({
  topOffset = ACTION_BAR_HEIGHT,
}: FlagAppliedListProps) => {
  const flagAppliedConnectionRoute = useRouteRef(flagAppliedConnectionRouteRef);
  const { searchQuery } = useListFilter();

  const { data, loading, error, fetchMore } =
    useListFlagAppliedConnectionsQuery({
      variables: {
        pageSize: 25,
      },
      fetchPolicy: 'cache-and-network',
    });

  const { flagAppliedConnections, nextPageToken } =
    getTypeOrNull(
      data?.flagAppliedConnections,
      'ConnectorsV1ListFlagAppliedConnectionsResponse',
    ) || {};

  const filteredConnections = (flagAppliedConnections || []).filter(
    connection =>
      Object.values(getFlagAppliedConnectionDetails(connection)).some(detail =>
        detail.value.toLowerCase().includes(searchQuery.toLowerCase()),
      ),
  );

  if (filteredConnections?.length === 0 && !loading) {
    return (
      <FilterListEmptyState
        type="connection"
        error={getError(data?.flagAppliedConnections) || error}
        description={
          <>
            Use flag applied connections to export assignment data.{' '}
            <ConfidenceWebsiteLink
              route="/docs/api/connectors/flag-applied"
              underline="always"
            >
              Read more about flag applied connections in the documentation
            </ConfidenceWebsiteLink>
            .
          </>
        }
      />
    );
  }

  return (
    <StickyTableContainer loading={loading} error={error}>
      <Table>
        <StickyTableHead topOffset={topOffset}>
          <TableRow>
            <TableCell>Destination</TableCell>
            <TableCell>Details</TableCell>
            <TableCell align="right" width={120}>
              Success rate
            </TableCell>
            <TableCell align="right" width={100}>
              Error rate
            </TableCell>
            <TableCell align="right" width={100}>
              Lag (p95)
            </TableCell>
          </TableRow>
        </StickyTableHead>
        <TableBody>
          {filteredConnections.map(connection => {
            const details = getFlagAppliedConnectionDetails(connection);
            return (
              <ResourceTableRow
                key={connection.name}
                name={connection.name}
                data-testid={connection.name}
                routeRef={flagAppliedConnectionRouteRef}
              >
                <TableCell>
                  <MuiLink
                    component={Link}
                    to={flagAppliedConnectionRoute({
                      id: extractLastNameComponent(connection.name)!,
                    })}
                  >
                    {getFlagAppliedDestinationName(connection)}
                  </MuiLink>
                </TableCell>
                <TableCell>
                  <Box
                    display="flex"
                    gridGap="0.5em"
                    flexWrap="wrap"
                    gridRowGap="0.5em"
                  >
                    {details.map(({ label, value }) => (
                      <Chip
                        size="small"
                        key={label}
                        label={
                          <>
                            <strong>{label}:</strong>{' '}
                            <Highlight highlight={searchQuery}>
                              {value}
                            </Highlight>
                          </>
                        }
                      />
                    ))}
                  </Box>
                </TableCell>
                <TableCell align="right">
                  {connection.usageMetadata?.successRate?.rate1m?.toFixed(0)}/s
                </TableCell>
                <TableCell align="right">
                  {connection.usageMetadata?.errorRate?.rate1m?.toFixed(0)}/s
                </TableCell>
                <TableCell align="right">
                  {connection.usageMetadata?.lag?.p95 &&
                    formatDuration(
                      intervalToDuration({
                        start: 0,
                        end: Math.round(connection.usageMetadata.lag.p95),
                      }),
                      {
                        format: ['hours', 'minutes', 'seconds'],
                      },
                    )
                      .replace(' seconds', 's')
                      .replace(' second', 's')
                      .replace(' minutes', 'm')
                      .replace(' minute', 'm')
                      .replace(' hours', 'h')
                      .replace(' hour', 'h')}
                </TableCell>
              </ResourceTableRow>
            );
          })}
        </TableBody>
      </Table>
      {data && (
        <InView
          onChange={async inView => {
            if (inView && nextPageToken) {
              await fetchMore({
                variables: {
                  pageToken: nextPageToken,
                },
              });
            }
          }}
        />
      )}
    </StickyTableContainer>
  );
};
