import React from 'react';

import _ from 'lodash';

import {
  AnyParams,
  ExternalRouteRef,
  RouteRef,
  SubRouteRef,
  useRouteRef,
} from '@backstage/core-plugin-api';

export type ResourcePathArgs<
  Params extends { id: string } = AnyParams & { id: string },
> = React.PropsWithChildren<{
  routeRef?: RouteRef<Params> | SubRouteRef<Params> | ExternalRouteRef<Params>;
  name: string;
  params?: Omit<Params, 'id'>;
}>;

export const useResourcePath = ({
  routeRef,
  name,
  params,
}: ResourcePathArgs) => {
  const resourceRoute = useRouteRef(routeRef as ExternalRouteRef);
  const id = _.last(name.split('/'));
  if (!resourceRoute) {
    // eslint-disable-next-line no-console
    console.warn(`Could not generate path to ${name}`);
    return null;
  }
  return resourceRoute({ ...params, id });
};
