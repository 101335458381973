import React from 'react';

import {
  AutocompleteEmptyState,
  ConfidenceAutocomplete,
} from '@spotify-confidence/core-react';
import { useListEntitiesLazyQuery } from '@spotify-confidence/plugin-graphql';

import { useRouteRef } from '@backstage/core-plugin-api';

import { entitiesRouteRef } from '../../../routes';

type EntitySelectProps = {
  value?: string;
  label?: string;
  id?: string;
  onChange: (newValue: string) => void;
  disabled?: boolean;
  helperText?: React.ReactNode;
};

export function EntitySelect({
  value,
  label = 'Entity',
  onChange,
  disabled,
  id,
  helperText,
}: EntitySelectProps) {
  const entitiesRoute = useRouteRef(entitiesRouteRef);

  return (
    <ConfidenceAutocomplete
      query={useListEntitiesLazyQuery}
      id={id}
      disabled={disabled}
      autoSelectSingle
      label={label}
      data-testid="entity-select"
      value={value}
      onChange={(_e, entity) => onChange(entity || '')}
      helperText={helperText}
    >
      <AutocompleteEmptyState
        title="No entities"
        description={helperText}
        actionLabel="Create entity"
        actionLink={entitiesRoute()}
      />
    </ConfidenceAutocomplete>
  );
}
