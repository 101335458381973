// eslint-disable new-cap
import React from 'react';

import _ from 'lodash';

import { ObjUtils } from '../utils';

type SetStateFn<S extends object, Args extends any[] = any[]> = (
  prevState: S,
  ...args: Args
) => S;

type UnboundStateApi<S extends object> = {
  [methodName: string]: SetStateFn<S>;
};

type BoundStateApi<S extends object, Api extends UnboundStateApi<S>> = {
  [K in keyof Api]: Api[K] extends (state: S, ...args: infer Args) => S
    ? (...args: Args) => void
    : never;
};

const API_UNSTABLE_REFERENCE_WARNING =
  'One or more methods of the given state API are not equal between renders. You might be attempting to use this hook improperly by passing an API with inline methods. It’s strongly recommended that all methods in the passed API object are static functions extracted outside of the useStateWithApi hook function call.';

/**
 * Accepts an initial state and (unbound) state-setter api methods, and returns a current state and bound api methods for that state.
 * @constraint Only the **initially passed** api object is considered. All methods in the passed object should be static functions.
 */
export const useMethods = <S extends object, Api extends UnboundStateApi<S>>(
  unboundStateApi: Api,
  initialState: S | (() => S),
): readonly [S, BoundStateApi<S, Api>] => {
  const [internalState, setInternalState] = React.useState<S>(initialState);

  const unboundApiRef = React.useRef(unboundStateApi);

  // Detect improper usage attempt when any API methods are not equal by reference between renders.
  React.useEffect(() => {
    if (!ObjUtils.isShallowEqual(unboundStateApi, unboundApiRef.current)) {
      // eslint-disable-next-line no-console
      console.error(API_UNSTABLE_REFERENCE_WARNING, unboundStateApi);
    }
  }, [unboundStateApi]);

  // To avoid triggering continuous re-renders (infinite re-render loop):
  // - Memoize the bound API (passed unbound api object may be a different reference each render).
  // - Consider only the initially passed unbound api object (since it may contain inline functions that trigger continuous re-renders).
  const boundStateApi = React.useMemo(
    () =>
      _.mapValues(
        unboundApiRef.current,
        <Args extends unknown[]>(setStateFn: SetStateFn<S, Args>) =>
          (...args: Args) => {
            setInternalState(state => setStateFn(state, ...args));
          },
      ) as BoundStateApi<S, Api>,
    // eslint-disable-next-line
    [],
  );

  return React.useMemo(
    () => [internalState, boundStateApi] as const,
    [internalState, boundStateApi],
  );
};
