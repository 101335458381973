import React from 'react';

import { LinkProps } from '@material-ui/core';

import { Link } from '@backstage/core-components';

import { ResourcePathArgs, useResourcePath } from '../../hooks/useResourcePath';

type ResourceLinkProps = LinkProps & ResourcePathArgs;

export const ResourceLink = ({
  routeRef,
  name,
  children,
  params,
  ...linkProps
}: ResourceLinkProps) => {
  const path = useResourcePath({ routeRef, name, params });

  if (!path) {
    return <>{children}</>;
  }

  return (
    <Link to={path} {...linkProps}>
      {children}
    </Link>
  );
};
