import React from 'react';

import { TextField } from '@material-ui/core';

import { getCountryDataList } from 'countries-list';
import _ from 'lodash';

import { AccountDetailsState } from './CreateAccountForm';
import { OPEN_MARKETS } from './utils';

export const CountryInfo = ({
  value,
  onChange,
}: {
  value: AccountDetailsState['billingDetails'];
  onChange: (newData: Partial<AccountDetailsState['billingDetails']>) => void;
}) => {
  return (
    <TextField
      select
      fullWidth
      variant="outlined"
      name="country"
      required
      onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
        const country = OPEN_MARKETS.find(m => m.code === e.target.value);
        onChange({
          address: {
            ...value.address,
            country: e.target.value,
          },
          taxType: country?.taxType,
        });
      }}
      label="Country"
      value={value.address.country}
      SelectProps={{ native: true }}
    >
      <option disabled value="">
        Select a country...
      </option>
      {_.sortBy([...getCountryDataList()], ['name']).map(m => (
        <option value={m.iso2} key={m.iso2}>
          {m.name}
        </option>
      ))}
    </TextField>
  );
};
