import React from 'react';

import {
  Box,
  Button,
  ButtonProps,
  Card,
  IconButton,
  Typography,
  makeStyles,
} from '@material-ui/core';
import Add from '@material-ui/icons/Add';
import Cancel from '@material-ui/icons/Cancel';

import { CommentZone } from '@spotify-confidence/plugin-comments-react';
import classNames from 'classnames';
import _ from 'lodash';

import {
  CriterionAttribute,
  CriterionSegment,
  LOGIC_OPERATORS,
  LogicOperator,
  TargetingCriteria as TargetingCriteriaModel,
  isCriterionSet,
} from '../../domain/targeting/targeting.model';
import { TargetingCriteriaState } from '../../domain/targeting/targeting.state';
import { AttributeCriterionChip } from './AttributeCriterionChip';
import { CreateCriterion } from './CreateCriterion';
import { SegmentCriterionChip } from './SegmentCriterionChip';
import { TargetingDropdown } from './TargetingDropdown';

const useStyles = makeStyles(theme => ({
  content: {
    maxWidth: '100%',
    // FIXME(jrydberg): is this needed? it hides the comment bubbles
    // overflowX: 'auto',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
    columnGap: theme.spacing(1),
    rowGap: theme.spacing(1.5),
    flexWrap: 'wrap',
    flex: 1,
    '& &': {
      rowGap: theme.spacing(2),
      alignItems: 'center',
      flexDirection: 'row',
    },
  },
  readOnly: {},
  group: {
    padding: theme.spacing(3),
  },
  rule: {
    position: 'relative',
  },
  deleteButton: {
    opacity: 0,
    position: 'absolute',
    right: theme.spacing(0.5),
    top: theme.spacing(0.5),
    transition: theme.transitions.create('opacity', {
      duration: theme.transitions.duration.shortest,
    }),
    '$rule:hover &': {
      opacity: 1,
    },
  },
}));

const OperatorPicker = ({
  operator,
  onChange,
  readOnly,
  highlight,
  ...buttonProps
}: Pick<ButtonProps, 'onMouseEnter' | 'onMouseLeave'> & {
  operator?: LogicOperator;
  onChange: (v: LogicOperator) => void;
  readOnly: boolean;
  highlight?: boolean;
}) => {
  return (
    <TargetingDropdown
      name="operator-picker"
      label={_.upperFirst(operator)}
      highlight={highlight}
      readOnly={readOnly}
      options={LOGIC_OPERATORS.map(o => ({
        label: _.upperFirst(o),
        onClick: () => onChange(o),
        selected: o === operator,
      }))}
      {...buttonProps}
    />
  );
};

export const TargetingCriteria = ({
  criterion,
  operator,
  depth = 1,
  maxDepth,
  readOnly,
  onAdd,
  onUpdate,
  onDelete,
}: {
  depth?: number;
  maxDepth: number;
  readOnly: boolean;
  criterion: TargetingCriteriaModel;
  operator?: LogicOperator;
} & TargetingCriteriaState) => {
  const classes = useStyles();
  const [operatorHovered, setOperatorHovered] = React.useState(false);
  if (isCriterionSet(criterion)) {
    const changeOperator = (v: LogicOperator) => {
      onUpdate(criterion.name, { operator: v });
    };
    const canAddSet = depth < maxDepth - 1;
    if (depth > maxDepth) {
      return <Typography>Max render depth reached</Typography>;
    }
    const canBeRemoved = readOnly ? false : depth !== 0;
    const canChangeOperator = criterion.criteria.length > 0 || depth >= 1;

    return (
      <Box
        display="flex"
        justifyContent="center"
        data-testid={`targeting-group-container${
          canBeRemoved ? '-removable' : ''
        }`}
      >
        <Box
          className={classNames(classes.content, {
            [classes.readOnly]: readOnly,
          })}
        >
          {canBeRemoved && (
            <IconButton
              title="Delete group"
              name="delete-group-button"
              className={classes.deleteButton}
              color="inherit"
              onClick={() => onDelete(criterion.name)}
            >
              <Cancel color="inherit" />
            </IconButton>
          )}
          {criterion.criteria.map((t, i) => {
            const isLastCriterion = i === criterion.criteria.length - 1;
            const content = (
              <TargetingCriteria
                criterion={t}
                readOnly={readOnly}
                depth={depth + 1}
                maxDepth={maxDepth}
                onUpdate={onUpdate}
                onAdd={onAdd}
                onDelete={onDelete}
                operator={isCriterionSet(t) ? t.operator : undefined}
              />
            );
            return (
              <React.Fragment key={t.name}>
                <CommentZone id="rule.criteria" data={{ name: t.name }}>
                  {isCriterionSet(t) ? (
                    <Card variant="outlined" className={classes.rule}>
                      <div className={classes.group}>{content}</div>
                    </Card>
                  ) : (
                    <Box className={classes.rule}>{content}</Box>
                  )}
                </CommentZone>
                <div>
                  {!isLastCriterion && canChangeOperator && (
                    <OperatorPicker
                      highlight={operatorHovered}
                      operator={operator}
                      readOnly={readOnly}
                      onChange={changeOperator}
                      onMouseEnter={() => setOperatorHovered(true)}
                      onMouseLeave={() => setOperatorHovered(false)}
                    />
                  )}
                </div>
              </React.Fragment>
            );
          })}
          {!readOnly && (
            <Box display="flex">
              <Box display="flex" gridGap={16}>
                <CreateCriterion
                  onCreate={newCriterion => {
                    onAdd(criterion.name, {
                      type: 'attribute',
                      ...newCriterion,
                    });
                  }}
                />
                <Button
                  size="small"
                  data-testid="add-segment-button"
                  onClick={() =>
                    onAdd(criterion.name, {
                      type: 'segment',
                    })
                  }
                  startIcon={<Add />}
                >
                  Add segment criterion
                </Button>

                {canAddSet && (
                  <Button
                    size="small"
                    data-testid="add-group-button"
                    onClick={() => onAdd(criterion.name, { type: 'set' })}
                    startIcon={<Add />}
                  >
                    Add group
                  </Button>
                )}
              </Box>
            </Box>
          )}
        </Box>
      </Box>
    );
  }
  const { type, name } = criterion;

  if (type === 'attribute') {
    return (
      <AttributeCriterionChip
        criterion={criterion}
        readOnly={readOnly}
        onDelete={readOnly ? undefined : () => onDelete(name)}
        onChange={(value: Partial<CriterionAttribute>) => onUpdate(name, value)}
      />
    );
  }
  return (
    <SegmentCriterionChip
      criterion={criterion}
      readOnly={readOnly}
      onDelete={readOnly ? undefined : () => onDelete(name)}
      onChange={(value: Partial<CriterionSegment>) => onUpdate(name, value)}
    />
  );
};
