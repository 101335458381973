import {
  FlagsTypesV1TargetingRangeRule,
  SemanticTypeFragment,
} from '@spotify-confidence/plugin-graphql';
import _ from 'lodash';
import { v4 as uuidv4 } from 'uuid';

export type TargetingCriteria = Criterion | CriterionSet;

export type CriterionSet = {
  name: string;
  operator: LogicOperator;
  criteria: TargetingCriteria[];
};

export const isCriterionSet = (cri: TargetingCriteria): cri is CriterionSet =>
  (cri as CriterionSet).criteria !== undefined;

export const isCriterion = (cri: TargetingCriteria): cri is Criterion =>
  !cri.hasOwnProperty('criteria');

export const isTargetingPresent = (criterion: TargetingCriteria) => {
  if (isCriterionSet(criterion)) {
    return criterion.criteria.length > 0;
  }

  if (criterion.type === 'attribute') {
    if (!criterion.attribute || !criterion.op) {
      return false;
    }
  }

  return true;
};

export const isTargetingValid = (criterion: TargetingCriteria) => {
  if (isCriterionSet(criterion)) {
    return (
      criterion.criteria.length > 0 && criterion.criteria.some(isTargetingValid)
    );
  }

  if (criterion.type === 'attribute') {
    if (!criterion.attribute || _.isNil(criterion.value) || !criterion.op) {
      return false;
    }
  }

  return true;
};

/**
 * 🎯 Targeting.LogicOperator
 */
export type LogicOperator = 'and' | 'or';

/**
 * 🧐 Criteria
 */
type BaseCriterion = {
  name: string;
};
export type Criterion = CriterionAttribute | CriterionSegment;
export type CriterionType = 'segment' | 'attribute';

export type CriterionSegment = BaseCriterion & {
  type: 'segment';
  op: SegmentOperator;

  // segment specificity
  segment: {
    displayName?: string;
    name?: string;
  };
};

export type AttributeType =
  | 'Boolean'
  | 'Number'
  | 'String'
  | 'Timestamp'
  | 'Version'
  | 'Any';

export type SemanticType =
  | 'Country code'
  | 'Enum'
  | 'Entity'
  | 'Version'
  | 'Date'
  | 'Timestamp';

/**
 * 🧐 Criteria.CriterionOperator
 */
export type CriterionOperator = AttributeOperator | SegmentOperator;
export type SegmentOperator = 'Matching' | 'Not matching';
export type AttributeOperator =
  // Boolean, String, Timestamp
  | 'Is'
  | 'Is Not'
  // Numbers
  | '='
  | '≠'
  | '>'
  | '<'
  | '≥'
  | '≤'
  // Timestamp
  | 'Is before'
  | 'Is after'
  | 'Is on or before'
  | 'Is on or after'
  // String, Version
  | 'In'
  | 'Not In'
  // closed range
  | 'Is between'
  | 'Is not between'
  // Any (null values)
  | 'Is not null'
  | 'Is null';

// A boolean expression with leaf nodes that reference criteria elements
export type Expression = {
  ref?: string;
  not?: Expression;
  and?: Operands;
  or?: Operands;
};

export type Operands = {
  operands: Expression[];
};

export const LOGIC_OPERATORS: LogicOperator[] = ['and', 'or'];

export const SEGMENT_OPERATORS: SegmentOperator[] = [
  'Matching',
  'Not matching',
];

export type ValueType = string | null;

export type AttributeValue = ValueType | ValueType[] | ClosedRange;

export type ClosedRangeValue = {
  inclusive: boolean;
  value: ValueType;
};
export type ClosedRange = {
  start: ClosedRangeValue;
  end: ClosedRangeValue;
};
export type ListMatcherType = 'Any' | 'All';

export type CriterionAttribute = BaseCriterion & {
  type: 'attribute';
  attribute: string;
  op: AttributeOperator;
  attributeType: AttributeType;
  listMatcherType?: ListMatcherType;
  value: AttributeValue;
};

export const SET_RULE_OPERATORS: AttributeOperator[] = ['In', 'Not In'];
export const CLOSED_RANGE_RULE_OPERATORS: AttributeOperator[] = [
  'Is between',
  'Is not between',
];

export const NOT_OP: (AttributeOperator | SegmentOperator)[] = [
  'Is Not',
  'Not In',
  '≠',
  'Is not between',
  'Not matching',
  'Is not null',
];

export const isSetOp = (op: AttributeOperator) =>
  op && SET_RULE_OPERATORS.includes(op);

export const isClosedRangeOp = (op: AttributeOperator) =>
  CLOSED_RANGE_RULE_OPERATORS.includes(op);

export const isClosedRangeValue = (
  value?: AttributeValue,
): value is ClosedRange =>
  !!value && value.hasOwnProperty('start') && value.hasOwnProperty('end');

export const isSetValue = (value?: AttributeValue): value is ValueType[] =>
  value !== undefined && Array.isArray(value);

export const isSingleValue = (value?: AttributeValue): value is ValueType =>
  value !== undefined && !isClosedRangeValue(value) && !isSetValue(value);

export const ATTRIBUTE_TYPES: AttributeType[] = [
  'Boolean',
  'Number',
  'String',
  'Timestamp',
  'Version',
  'Any',
];

export const ATTRIBUTE_OPERATORS: Record<AttributeType, AttributeOperator[]> = {
  Boolean: ['Is', 'Is Not', 'Is not null', 'Is null'],
  Number: [
    '=',
    '≠',
    '>',
    '<',
    '≥',
    '≤',
    'In',
    'Not In',
    'Is between',
    'Is not between',
    'Is not null',
    'Is null',
  ],
  String: ['Is', 'Is Not', 'In', 'Not In', 'Is not null', 'Is null'],
  Timestamp: [
    'Is',
    'Is Not',
    'Is before',
    'Is after',
    'Is on or before',
    'Is on or after',
    'Is between',
    'Is not between',
    'Is not null',
    'Is null',
  ],
  Version: [
    '=',
    '≠',
    '>',
    '<',
    '≥',
    '≤',
    'In',
    'Not In',
    'Is between',
    'Is not between',
    'Is not null',
    'Is null',
  ],
  Any: ['Is not null', 'Is null'],
};

type RuleTypesLiteral =
  | 'eqRule'
  | 'eqRule_not'
  | 'setRule'
  | 'setRule_not'
  | 'rangeRule'
  | 'rangeRule_not'
  | 'rangeRule_startExclusive'
  | 'rangeRule_startInclusive'
  | 'rangeRule_endExclusive'
  | 'rangeRule_endInclusive';

export const RULE_TO_OPERATOR: Record<
  AttributeType,
  { [key in RuleTypesLiteral]?: AttributeOperator }
> = {
  Boolean: {
    eqRule: 'Is',
    eqRule_not: 'Is Not',
  },
  Number: {
    eqRule: '=',
    eqRule_not: '≠',
    setRule: 'In',
    setRule_not: 'Not In',
    rangeRule: 'Is between',
    rangeRule_not: 'Is not between',
    rangeRule_startInclusive: '≥',
    rangeRule_endInclusive: '≤',
    rangeRule_startExclusive: '>',
    rangeRule_endExclusive: '<',
  },
  String: {
    eqRule: 'Is',
    eqRule_not: 'Is Not',
    setRule: 'In',
    setRule_not: 'Not In',
  },
  Timestamp: {
    eqRule: 'Is',
    eqRule_not: 'Is Not',
    rangeRule: 'Is between',
    rangeRule_not: 'Is not between',
    rangeRule_startInclusive: 'Is on or after',
    rangeRule_endInclusive: 'Is on or before',
    rangeRule_startExclusive: 'Is after',
    rangeRule_endExclusive: 'Is before',
  },
  Version: {
    eqRule: '=',
    eqRule_not: '≠',
    setRule: 'In',
    setRule_not: 'Not In',
    rangeRule: 'Is between',
    rangeRule_not: 'Is not between',
    rangeRule_startInclusive: '≥',
    rangeRule_endInclusive: '≤',
    rangeRule_startExclusive: '>',
    rangeRule_endExclusive: '<',
  },
  Any: {
    eqRule: 'Is null',
    eqRule_not: 'Is not null',
  },
};

export const getRuleCriteriaType = (operator: AttributeOperator) => {
  const eqRulesOperator: AttributeOperator[] = [
    'Is',
    '=',
    'Is Not',
    '≠',
    'Is null',
    'Is not null',
  ];
  const setRuleOperator: AttributeOperator[] = ['In', 'Not In'];
  const rangeRuleOperator: AttributeOperator[] = [
    '>',
    '<',
    '≤',
    '≥',
    '≠',
    'Is before',
    'Is after',
    'Is between',
    'Is not between',
    'Is on or after',
    'Is on or before',
  ];
  if (eqRulesOperator.includes(operator)) return 'eqRule';
  if (setRuleOperator.includes(operator)) return 'setRule';
  if (rangeRuleOperator.includes(operator)) return 'rangeRule';
  return 'void';
};
export const OPEN_ENDED_RANGE_RULES: Record<
  string,
  keyof FlagsTypesV1TargetingRangeRule
> = {
  '>': 'startExclusive',
  '<': 'endExclusive',
  '≥': 'startInclusive',
  '≤': 'endInclusive',
  'Is before': 'endExclusive',
  'Is after': 'startExclusive',
  'Is on or before': 'endInclusive',
  'Is on or after': 'startInclusive',
};

export const newTargeting = (): CriterionSet => ({
  criteria: [],
  operator: LOGIC_OPERATORS[1],
  name: 'group',
});

export const getAttributeTypeDefaultValue = (attributeType: AttributeType) => {
  let defaultValue: ValueType = '';
  if (attributeType === 'Any') {
    defaultValue = null;
  }
  if (attributeType === 'Boolean') {
    defaultValue = 'FALSE';
  }
  return defaultValue;
};

export const newAttributeCriterion = (
  overwrite: Partial<CriterionAttribute> = {},
): CriterionAttribute => {
  return {
    name: uuidv4(),
    type: 'attribute',
    op: overwrite?.attributeType
      ? ATTRIBUTE_OPERATORS[overwrite?.attributeType][0]
      : '=',
    attribute: '',
    attributeType: 'String',
    value: getAttributeTypeDefaultValue(overwrite.attributeType || 'String'),
    ...overwrite,
  };
};

export const newSegmentCriterion = (): CriterionSegment => ({
  name: uuidv4(),
  type: 'segment',
  op: SEGMENT_OPERATORS[0],

  segment: {
    name: undefined,
    displayName: undefined,
  },
});

export const newCriterionSet = (name: string): CriterionSet => {
  return {
    criteria: [],
    operator: 'or',
    name,
  };
};

export const newCriterion = (type: CriterionType): Criterion =>
  type === 'attribute' ? newAttributeCriterion() : newSegmentCriterion();

export const copyCriterion = (criterion: Criterion): Criterion => ({
  ...criterion,
  name: uuidv4(),
});

export const getSemanticTypeFromCriterionOption = (
  criterionOption: CriterionOption,
): SemanticType | undefined => {
  if (criterionOption.semanticType) {
    if (criterionOption.semanticType.country) {
      if (
        criterionOption.semanticType.country.format === 'TWO_LETTER_ISO_CODE'
      ) {
        return 'Country code';
      }
    }
    if (criterionOption.semanticType.enumType) {
      return 'Enum';
    }
    if (criterionOption.semanticType.entityReference) {
      return 'Entity';
    }
    if (criterionOption.semanticType.version) {
      return 'Version';
    }
    if (criterionOption.semanticType.date) {
      return 'Date';
    }
    if (criterionOption.semanticType.timestamp) {
      return 'Timestamp';
    }
  }
  return undefined;
};

export type CriterionOption = {
  name: string;
  displayName?: string;
  type: AttributeType;
  semanticType?: SemanticTypeFragment;
  hidden?: boolean;
};
