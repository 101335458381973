import React from 'react';

import {
  Box,
  CardContent,
  IconButton,
  Typography,
  makeStyles,
} from '@material-ui/core';
import DeleteIcon from '@material-ui/icons/Delete';
import EditIcon from '@material-ui/icons/Edit';
import { Alert } from '@material-ui/lab';

import {
  DateUtils,
  DraggableCard,
  DraggableCardProps,
  ResourceLink,
  extractLastNameComponent,
  extractWorkflowFromInstanceName,
} from '@spotify-confidence/core-react';
import {
  RuleFragment,
  SchemaEntryFragment,
} from '@spotify-confidence/plugin-graphql';
import { PermissionUtils } from '@spotify-confidence/plugin-permissions-react';
import { useGetWorkflowInstance } from '@spotify-confidence/plugin-workflows';

import { workflowInstanceRouteRef } from '../../../../routes';
import { ruleHelpers } from '../../../domain';
import { RuleResolvedCounter } from './RuleResolvedCounter';
import { RuleToggle } from './RuleToggle';
import { getRuleViewComponent } from './rules';

const useStyles = makeStyles(theme => ({
  header: {
    display: 'flex',
    justifyContent: 'space-between',
  },
  card: {
    marginBottom: theme.spacing(2),
  },
  cardContent: {
    padding: theme.spacing(2, 2),
  },
}));

export const RuleControl = ({
  workflowInstance,
  short = false,
  renderRule = childs => <Alert severity="info">{childs}</Alert>,
}: {
  workflowInstance?: string;
  short?: boolean;
  renderRule?: (c: React.ReactNode) => React.ReactNode;
}) => {
  if (!!!workflowInstance) {
    return null;
  }

  const workflow = extractWorkflowFromInstanceName(workflowInstance)!;

  const queryResult = useGetWorkflowInstance({
    workflowId: workflow,
    instanceId: extractLastNameComponent(workflowInstance ?? undefined) ?? '',
  });

  const { workflowInstance: workflowInstanceQueryResult, loading } =
    queryResult;

  const hasDisplayName = !!(
    workflowInstanceQueryResult?.displayName &&
    workflowInstanceQueryResult?.displayName !== ''
  );
  return loading
    ? null
    : renderRule(
        <>
          This rule is controlled by{' '}
          {hasDisplayName ? (
            <>
              {'the workflow instance '}
              <ResourceLink
                name={workflowInstance}
                routeRef={workflowInstanceRouteRef}
                underline="always"
                params={{
                  workflow:
                    extractWorkflowFromInstanceName(workflowInstance) || '',
                }}
              >
                {workflowInstanceQueryResult?.displayName}
              </ResourceLink>
            </>
          ) : (
            <ResourceLink
              name={workflowInstance}
              routeRef={workflowInstanceRouteRef}
              underline="always"
              params={{
                workflow:
                  extractWorkflowFromInstanceName(workflowInstance) || '',
              }}
            >
              a workflow instance
            </ResourceLink>
          )}
          {short ? '.' : ' and can not be edited.'}
        </>,
      );
};

export type RuleCardProps = DraggableCardProps & {
  rule: RuleFragment;
  onEdit?: () => void;
  onDelete?: () => void;
  onToggle?: (enabled: boolean) => Promise<void>;
  disabled?: boolean;
  seenAttributes?: SchemaEntryFragment[] | undefined;
};

export const RuleCard = ({
  rule,
  onEdit,
  onDelete,
  onToggle,
  disabled,
  seenAttributes,
  draggable,
  ...draggableCardProps
}: RuleCardProps) => {
  const classes = useStyles();
  const canEdit = PermissionUtils.hasRelation(rule, 'can_edit');

  const ruleType = ruleHelpers.getRuleType(rule);
  const RuleComponent = getRuleViewComponent(ruleType);

  const workflowInstance = rule.labels.find(
    label => label.key === 'workflowInstance',
  )?.value;
  const controlled = !!workflowInstance;

  return (
    <DraggableCard
      {...draggableCardProps}
      draggable={canEdit ? draggable : false}
      className={classes.card}
    >
      <CardContent
        className={classes.cardContent}
        data-testid="rule-card-content"
      >
        <RuleControl workflowInstance={workflowInstance ?? undefined} />

        <div className={classes.header}>
          <Box ml={-1} display="flex" alignItems="center">
            <RuleToggle
              rule={rule}
              disabled={disabled || controlled || !canEdit}
              onToggle={onToggle}
            />
            <RuleResolvedCounter name={rule.name} />
          </Box>
          <Box display="flex" alignItems="center">
            {rule.updateTime && (
              <Typography
                variant="body2"
                color="textSecondary"
                style={{ marginRight: 8 }}
              >
                Updated {DateUtils.xAgo(new Date(rule.updateTime))}
              </Typography>
            )}
            <IconButton
              disabled={disabled || controlled || !canEdit}
              onClick={() => onEdit?.()}
              name="edit-button"
            >
              <EditIcon fontSize="small" />
            </IconButton>
            <IconButton
              disabled={disabled || controlled || !canEdit}
              onClick={() => onDelete?.()}
              name="delete-button"
            >
              <DeleteIcon fontSize="small" />
            </IconButton>
          </Box>
        </div>

        <RuleComponent rule={rule} seenAttributes={seenAttributes} />
      </CardContent>
    </DraggableCard>
  );
};
