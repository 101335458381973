import React from 'react';

import { User, useAuth } from '../auth-factory';

function getStorageKey(context: string, user?: User) {
  return user ? [user.org_id, user.email, context].join('-') : context;
}

export function useStorageKey(context: string) {
  const { user } = useAuth();
  const [key, setKey] = React.useState(getStorageKey(context, user));

  React.useEffect(() => {
    if (user) {
      setKey(getStorageKey(context, user));
    }
  }, [context, user]);

  return key;
}
