import React from 'react';

import { alpha, makeStyles } from '@material-ui/core';

import classNames from 'classnames';

const useStyles = makeStyles(theme => ({
  scroll: {
    // hide scrollbar
    '&::-webkit-scrollbar': {
      display: 'none',
    },
    scrollbarWidth: 'none',

    // https://css-tricks.com/books/greatest-css-tricks/scroll-shadows/
    background:
      `linear-gradient(${
        // @ts-ignore this is to handle encore dialog background color
        theme.overrides?.MuiDialog?.paper.background ??
        theme.palette.background.paper
      } 30%, ${alpha(
        // @ts-ignore this is to handle encore dialog background color
        theme.overrides?.MuiDialog?.paper.background ??
          theme.palette.background.paper,
        0,
      )}) center top, ` +
      `linear-gradient(${alpha(
        // @ts-ignore this is to handle encore dialog background color
        theme.overrides?.MuiDialog?.paper.background ??
          theme.palette.background.paper,
        0,
      )}, ${
        // @ts-ignore this is to handle encore dialog background color
        theme.overrides?.MuiDialog?.paper.background ??
        theme.palette.background.paper
      } 70%) center bottom, ` +
      `radial-gradient(farthest-side at 50% 0, rgba(0, 0, 0, ${
        theme.palette.type === 'dark' ? 0.2 : 0.1
      }), rgba(0, 0, 0, 0)) center top, ` +
      `radial-gradient(farthest-side at 50% 100%, rgba(0, 0, 0, ${
        theme.palette.type === 'dark' ? 0.2 : 0.1
      }), rgba(0, 0, 0, 0)) center bottom`,

    backgroundRepeat: 'no-repeat',
    backgroundSize: '100% 40px, 100% 40px, 100% 14px, 100% 14px',
    backgroundAttachment: 'local, local, scroll, scroll',
  },
}));

interface WithClassNameProps {
  className?: string;
}

export const withScrollShadows = <P extends object>(
  Component: React.ComponentType<P>,
) => {
  const WrapperComponent = (props: P & WithClassNameProps) => {
    const classes = useStyles();

    const { className, ...rest } = props;
    return (
      <Component
        className={classNames(className, classes.scroll)}
        {...(rest as P)}
      />
    );
  };

  return WrapperComponent;
};
