import React from 'react';

import SvgIcon, { SvgIconProps } from '@material-ui/core/SvgIcon';

export const ConfidenceLogo = (props: SvgIconProps) => {
  return (
    <SvgIcon {...props} viewBox="0 0 24 24">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M2.96907 0C1.3293 0 0 1.3293 0 2.96907V21.0309C0 22.6707 1.3293 24 2.96907 24H21.0309C22.6707 24 24 22.6707 24 21.0309V2.96907C24 1.3293 22.6707 0 21.0309 0H2.96907ZM11.6644 11.1396C11.6644 11.4129 11.4429 11.6344 11.1696 11.6344H6.34485C6.07155 11.6344 5.85 11.4129 5.85 11.1396V6.31485C5.85 6.04155 6.07155 5.82 6.34485 5.82L11.1696 5.82C11.4429 5.82 11.6644 6.04155 11.6644 6.31485V11.1396ZM14.2624 11.6344C16.4487 11.6344 18.2211 9.86203 18.2211 7.67567V6.31485C18.2211 6.04155 17.9996 5.82 17.7263 5.82L12.9015 5.82C12.6282 5.82 12.4067 6.04155 12.4067 6.31485V11.1396C12.4067 11.4129 12.6282 11.6344 12.9015 11.6344H14.2624ZM15.3139 18.1911C16.9195 18.1911 18.2211 16.8895 18.2211 15.2839C18.2211 13.6783 16.9195 12.3767 15.3139 12.3767C13.7083 12.3767 12.4067 13.6783 12.4067 15.2839C12.4067 16.8895 13.7083 18.1911 15.3139 18.1911ZM8.75722 18.1911C10.3628 18.1911 11.6644 16.8895 11.6644 15.2839C11.6644 13.6783 10.3628 12.3767 8.75722 12.3767C7.15161 12.3767 5.85 13.6783 5.85 15.2839V17.6963C5.85 17.9696 6.07155 18.1911 6.34485 18.1911H8.75722Z"
        fill="currentColor"
      />
    </SvgIcon>
  );
};
