import React from 'react';

import { Typography } from '@material-ui/core';

import { targetingCodec } from '../../../../../../segment/domain/targeting';
import { InlineCriteria } from '../../InlineCriteria';
import { RuleCardIdentifier } from '../RulceCardIdentifier';
import { RuleCardAssign } from '../RuleCardAssign';
import { RuleCardSection } from '../RuleCardSection';
import { RuleViewProps, getRuleTargetingState } from '../shared';

export const RolloutRuleView = ({
  rule,
  className,
  seenAttributes,
}: RuleViewProps) => {
  const { segment, hasTargeting, targetingIsAlwaysIgnored } =
    getRuleTargetingState(rule);

  return (
    <Typography variant="body1" className={className} component="div">
      {hasTargeting && !targetingIsAlwaysIgnored && (
        <RuleCardSection>
          <strong>If&nbsp;</strong>
          <span>
            <InlineCriteria
              seenAttributes={seenAttributes}
              {...targetingCodec.fromSchemaTargeting(segment?.targeting)}
            />
          </span>
        </RuleCardSection>
      )}
      <RuleCardSection>
        <RuleCardIdentifier rule={rule} seenAttributes={seenAttributes} />
      </RuleCardSection>

      <RuleCardSection>
        <RuleCardAssign assignmentSpec={rule.assignmentSpec} />
      </RuleCardSection>
    </Typography>
  );
};
