import { getError } from '@spotify-confidence/plugin-graphql';
import _ from 'lodash';

import { PaginatedData, PaginatedListData, PaginatedListItem } from './types';

/*
    Get the object that contains the list data from a GraphQL response.
    For example, get the "entities" object from this response:
    {
        "entities": {
            "__typename": "MetricsV1ListEntitiesResponse",
            "nextPageToken": "abc",
            ...
        }
    }

    Useful to detect errors or get the next page token.
*/
export function getPaginatedListData<
  TData extends PaginatedData,
  TListItem extends PaginatedListItem,
>(data?: TData): PaginatedListData<TListItem> | null {
  const hasError = getError(data);
  if (hasError || !data) {
    return null;
  }

  const dataKeys = Object.keys(data) as (keyof typeof data)[];
  if (dataKeys.length === 0) {
    return null;
  }

  const itemKey = dataKeys.find(key => {
    const content = data![key];
    if (_.isObject(content)) {
      return !!content.__typename;
    }
    return false;
  }) as keyof typeof data;

  if (!itemKey) {
    return null;
  }

  return data[itemKey] as PaginatedListData<TListItem>;
}

/*
    Get the list of items from a GraphQL response.
    For example, get the list of "entities" from this response:
    {
        "entities": {
            "__typename": "MetricsV1ListEntitiesResponse",
            "nextPageToken": null,
            "entities": [{...}]
        }
    }
*/
export function getPaginatedItems<
  TData extends PaginatedData,
  TListItem extends PaginatedListItem,
>(data?: TData): null | TListItem[] {
  const listData = getPaginatedListData<TData, TListItem>(data);
  if (!listData) {
    return null;
  }

  const dataKeys = Object.keys(listData).filter(
    key => !['__typename', 'nextPageToken'].includes(key),
  ) as (keyof typeof listData)[];

  if (dataKeys.length === 0) {
    return null;
  }

  const itemKey = dataKeys.find(key =>
    _.isArray(listData[key]),
  ) as keyof typeof listData;

  const items = listData[itemKey];

  if (_.isArray(items)) {
    return items.filter(item => item?.__typename !== 'Error') as TListItem[];
  }

  return null;
}
