import React from 'react';

import { InputBase, ListItemText, MenuItem, Select } from '@material-ui/core';

import { appThemeApiRef, useApi } from '@backstage/core-plugin-api';

import { SelectListItem } from './SelectListItem';

export function ThemeSwitcher() {
  const appThemeApi = useApi(appThemeApiRef);
  const themeIds = appThemeApi.getInstalledThemes();
  const activeTheme = appThemeApi.getActiveThemeId();

  const handleSetTheme = (newThemeId: string | undefined) => {
    if (themeIds.some(it => it.id === newThemeId)) {
      appThemeApi.setActiveThemeId(newThemeId);
    } else {
      appThemeApi.setActiveThemeId(undefined);
    }
  };

  return (
    <SelectListItem>
      <ListItemText primary="Theme" />
      <Select
        value={activeTheme ?? ''}
        onChange={e => handleSetTheme(e.target.value as string)}
        input={<InputBase />}
      >
        {themeIds.map(theme => (
          <MenuItem key={theme.id} value={theme.id}>
            {theme.title}
          </MenuItem>
        ))}
        <MenuItem value="">Auto</MenuItem>
      </Select>
    </SelectListItem>
  );
}
