import {
  EventSchemaEntryFragment,
  Maybe,
} from '@spotify-confidence/plugin-graphql';

import {
  appendId,
  formatName,
  modifyListStructSchema,
  modifyStructSchema,
} from './schema.helpers';

export type Entry = {
  key: string;
  value?: Maybe<EventSchemaEntryFragment['value']>;
};

export const useSchema = ({
  onChange,
}: {
  onChange: React.Dispatch<React.SetStateAction<EventSchemaEntryFragment[]>>;
}) => {
  const addHandler = (identifier: string, newEntry: Entry) => {
    const _add = (schema: Entry[], currentIdentifier: string): Entry[] => {
      if (currentIdentifier === identifier) {
        return [newEntry, ...schema];
      }

      return schema.map((entry, i) => {
        if (entry.value?.structSchema?.schema) {
          return {
            ...entry,
            value: {
              structSchema: {
                schema: _add(
                  entry.value.structSchema.schema,
                  appendId(currentIdentifier, i),
                ),
              },
            },
          };
        }
        if (entry.value?.listSchema?.elementSchema?.structSchema?.schema) {
          return {
            ...entry,
            value: {
              listSchema: {
                elementSchema: {
                  structSchema: {
                    schema: _add(
                      entry.value.listSchema.elementSchema.structSchema.schema,
                      appendId(currentIdentifier, i),
                    ),
                  },
                },
              },
            },
          };
        }
        return entry;
      });
    };
    onChange(current => _add(current, '0'));
    return `${identifier}.0`;
  };

  const deleteHandler = (identifier: string) => {
    const _filterSchemaEntries = (
      schema: Entry[],
      currentIdentifier: string,
    ): Entry[] => {
      return schema
        .filter((_, i) => {
          return `${currentIdentifier}.${i}` !== identifier;
        })
        .map((e, i) => {
          if (e.value?.structSchema) {
            return modifyStructSchema(e, s =>
              _filterSchemaEntries(s, appendId(currentIdentifier, i)),
            );
          }
          if (e.value?.listSchema?.elementSchema?.structSchema) {
            return modifyListStructSchema(e, s =>
              _filterSchemaEntries(s, appendId(currentIdentifier, i)),
            );
          }
          return e;
        });
    };
    onChange(current => _filterSchemaEntries(current, '0'));
  };

  const updateHandler = (identifier: string, updatedEntry: Partial<Entry>) => {
    const _updateEntryKey = (
      entries: Entry[],
      currentIdentifier: string,
    ): Entry[] => {
      return entries.map((entry, i) => {
        const newId = appendId(currentIdentifier, i);
        if (newId === identifier) {
          return {
            ...entry,
            ...updatedEntry,
            ...(updatedEntry.key && { key: formatName(updatedEntry.key) }),
          };
        }
        if (entry.value?.structSchema?.schema) {
          return modifyStructSchema(entry, s => _updateEntryKey(s, newId));
        }
        if (entry.value?.listSchema?.elementSchema?.structSchema?.schema) {
          return modifyListStructSchema(entry, s => _updateEntryKey(s, newId));
        }
        return entry;
      });
    };

    onChange(current => _updateEntryKey(current, '0'));
  };

  return {
    onDelete: deleteHandler,
    onUpdate: updateHandler,
    onAdd: addHandler,
  };
};

export type SchemaState = ReturnType<typeof useSchema>;
