import React from 'react';

import { KeyboardDateTimePicker } from '@material-ui/pickers';

import { ChipTimestampPicker } from './ChipTimestampPicker';
import { SharedInputProps } from './types';

export const ChipDatetimePicker = (props: SharedInputProps) => {
  return (
    <ChipTimestampPicker
      component={KeyboardDateTimePicker}
      maxWidth={120}
      format="yyyy-MM-dd'T'HH:mm:00"
      displayFormat="yyyy-MM-dd HH:mm"
      id="datetime"
      {...props}
    />
  );
};
