import React from 'react';

import {
  BUCKET_COUNT,
  PercentageSlider,
  TreatmentCard,
  TreatmentCardProps,
} from '@spotify-confidence/core-react';

export type TreatmentCardWithWeightProps = {
  weight?: number;
  onWeightChange?: (v: number) => void;
  hideWeightControls?: boolean;
} & TreatmentCardProps;

/**
 * Treatment card with a weight slider.
 */
export function TreatmentCardWithWeight({
  id,
  disabled,
  weight,
  onWeightChange,
  hideWeightControls = false,
  ...props
}: TreatmentCardWithWeightProps) {
  return (
    <TreatmentCard id={id} disabled={disabled} {...props}>
      {!hideWeightControls && (
        <PercentageSlider
          id={id}
          name="treatment-weight-input"
          onChange={onWeightChange}
          value={weight || 0}
          maxValue={BUCKET_COUNT}
          disabled={disabled}
        />
      )}
    </TreatmentCard>
  );
}
