import React from 'react';

import { Box, Typography, makeStyles } from '@material-ui/core';

import {
  ColorUtils,
  NumberUtils,
  Numeral,
  SidebarValue,
  SidebarValueWrapper,
  useDisplayNames,
} from '@spotify-confidence/core-react';
import classNames from 'classnames';
import _ from 'lodash';

import { StatsDetailsPopover } from '../../../../shared/components/StatsDetails';

type VariantValueProps = {
  className?: string;
  variant: string;
  value?: string | number;
  events?: number;
  sampleSize?: number;
  isFraction?: boolean;
  originalValue?: string | number;
  isInteger?: boolean;
};

const useStyles = makeStyles(theme => ({
  wrapper: {
    position: 'relative',
    '&:before': {
      width: 4,
      top: 0,
      bottom: 0,
      content: '""',
      position: 'absolute',
      display: 'block',
      backgroundColor: (props: { color?: string }) =>
        props.color || theme.palette.text.primary,
      borderRadius: theme.shape.borderRadius,
    },
  },
  content: {
    marginLeft: theme.spacing(1.5),
  },
  name: {
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
  },
  value: {
    color: theme.palette.text.primary,
  },
}));

function formatIfNumber(value: string | number) {
  return _.isNumber(value) ? NumberUtils.formatNumberFixed4(value) : value;
}

function PopoverDetails({
  name,
  value,
  originalValue,
  sampleSize,
}: {
  name: string;
  value: string | number;
  originalValue: string | number;
  sampleSize: number;
}): JSX.Element {
  const usesVarianceReduction = originalValue && originalValue !== value;
  return (
    <SidebarValueWrapper>
      <Typography variant="subtitle1" style={{ wordBreak: 'break-all' }}>
        {name}
      </Typography>
      <Box display="grid" gridTemplateColumns="1fr 1fr" gridGap={8}>
        {value && (
          <SidebarValue name="Mean" value={formatIfNumber(value)} popover />
        )}
        {usesVarianceReduction && (
          <SidebarValue
            name="Without variance reduction"
            value={formatIfNumber(originalValue)}
            popover
          />
        )}
      </Box>
      {sampleSize && (
        <SidebarValue name="Sample size" value={sampleSize} popover />
      )}
    </SidebarValueWrapper>
  );
}

const VariantValueInner = ({
  variant,
  value,
  isFraction,
  isInteger,
}: VariantValueProps) => {
  const { displayNames } = useDisplayNames();
  const variantName = displayNames.get(variant) || variant;
  const classes = useStyles({ color: ColorUtils.stringToColor(variantName) });
  return (
    <div className={classes.content} data-testid={`variant-value-${variant}`}>
      <Typography className={classes.name} color="textSecondary" noWrap>
        {variantName}
      </Typography>
      {value && (
        <Typography className={classes.value} noWrap>
          {_.isNumber(value) ? (
            <Numeral
              component="span"
              color="inherit"
              value={isFraction ? value * 100 : value}
              formatter={_value => {
                const formatted = isInteger
                  ? NumberUtils.formatNumber(_value)
                  : NumberUtils.formatNumberFixed2(_value);
                if (formatted !== '0.00') {
                  return formatted;
                }

                return _value.toExponential(2);
              }}
              suffix={isFraction ? '%' : undefined}
              data-testid="variant-value-numeral"
            />
          ) : (
            value
          )}
        </Typography>
      )}
    </div>
  );
};

export const VariantValue = ({
  className,
  variant,
  value,
  sampleSize,
  isFraction,
  originalValue,
  isInteger,
}: VariantValueProps) => {
  const { displayNames } = useDisplayNames();
  const variantName = displayNames.get(variant) || variant;
  const classes = useStyles({ color: ColorUtils.stringToColor(variantName) });

  return originalValue || sampleSize ? (
    <div className={classNames(classes.wrapper, className)}>
      <StatsDetailsPopover
        details={
          <PopoverDetails
            name={variantName}
            value={value || ''}
            originalValue={originalValue || ''}
            sampleSize={sampleSize || 0}
          />
        }
      >
        <VariantValueInner
          variant={variant}
          value={value}
          isFraction={isFraction}
          isInteger={isInteger}
        />
      </StatsDetailsPopover>
    </div>
  ) : (
    <div className={classNames(classes.wrapper, className)}>
      <VariantValueInner
        variant={variant}
        value={value}
        isFraction={isFraction}
        isInteger={isInteger}
      />
    </div>
  );
};
