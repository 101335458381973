/* eslint-disable no-nested-ternary */
import React, { HTMLProps, PropsWithChildren } from 'react';

import { Drawer, Theme, makeStyles } from '@material-ui/core';

import classNames from 'classnames';

export type DrawerContentProps = React.PropsWithChildren<{
  drawer: React.ReactNode;
  showDrawer?: boolean;
  children?: React.ReactElement;
}>;

const DRAWER_WIDTH = 300;
const DRAWER_WIDTH_XL = 400;

const useStyles = makeStyles<Theme, { showDrawer: boolean }>(theme => ({
  wrapper: {
    transition: 'padding-right 0.3s ease-in-out',
    paddingRight: ({ showDrawer }) => (showDrawer ? DRAWER_WIDTH + 16 : 0),
    [theme.breakpoints.up('xl')]: {
      paddingRight: ({ showDrawer }) => (showDrawer ? DRAWER_WIDTH_XL + 16 : 0),
    },
    maxWidth: '100%',
  },

  drawer: {
    ...theme.mixins.toolbar,
    scrollBehavior: 'smooth',

    width: DRAWER_WIDTH,
    minWidth: DRAWER_WIDTH,
    [theme.breakpoints.up('xl')]: {
      width: DRAWER_WIDTH_XL,
      minWidth: DRAWER_WIDTH_XL,
    },
  },
}));

export const DrawerWrapper = ({
  children,
  open,
  className,
  ...rest
}: PropsWithChildren<{ open?: boolean } & HTMLProps<HTMLDivElement>>) => {
  const classes = useStyles({ showDrawer: Boolean(open) });
  return (
    <div className={classNames(classes.wrapper, className)} {...rest}>
      {children}
    </div>
  );
};

export const DrawerContent = ({
  children,
  drawer,
  showDrawer,
}: DrawerContentProps) => {
  const classes = useStyles({ showDrawer: Boolean(showDrawer) });

  return (
    <DrawerWrapper open={showDrawer}>
      {children}
      <Drawer
        variant="persistent"
        anchor="right"
        classes={{ paper: classes.drawer }}
        open={showDrawer}
      >
        {drawer}
      </Drawer>
    </DrawerWrapper>
  );
};
