import {
  FlagsTypesV1Expression,
  FlagsTypesV1TargetingCriteriaEntryInput,
  FlagsTypesV1TargetingInput,
  InputMaybe,
  Maybe,
  TargetingCriterionFragment,
  TargetingFragment,
  isType,
} from '@spotify-confidence/plugin-graphql';

import {
  Criterion,
  NOT_OP,
  TargetingCriteria,
  isCriterionSet,
} from '../../targeting.model';
import { toSchemaAttributeCriterion } from './attribute';
import { toSchemaSegmentCriterion } from './segment';

// remove empty operands and criteria before saving since they will incorrectly evaluate to true
export const removeEmptyTargeting = (
  input?: Maybe<TargetingFragment>,
): Maybe<TargetingFragment> => {
  const isEmpty = (o: InputMaybe<TargetingFragment['expression']>) =>
    o?.or?.operands?.length === 0 || o?.and?.operands?.length === 0;

  const _clean = (
    i?: Maybe<TargetingFragment['expression']>,
  ): FlagsTypesV1Expression => {
    if (!i) return {};

    if (i?.or) {
      const operands = (i.or.operands || [])
        .filter(o => !isEmpty(o))
        .map(f => _clean(f));
      if (operands?.length !== 0) {
        return {
          or: { operands: operands },
        };
      }
      return {};
    }
    if (i?.and) {
      const operands = (i.and.operands || [])
        .filter(o => !isEmpty(o))
        .map(f => _clean(f));
      if (operands?.length !== 0) {
        return {
          and: { operands: operands },
        };
      }
      return {};
    }

    return i;
  };

  if (!input?.expression || !input?.criteria?.length) return null;

  return {
    ...input,
    expression: _clean(input.expression),
  };
};

export const toInput = (
  targeting?: Maybe<TargetingFragment>,
): FlagsTypesV1TargetingInput => {
  const cleanedUp = removeEmptyTargeting(targeting);
  const criteria: FlagsTypesV1TargetingCriteriaEntryInput[] = (
    cleanedUp?.criteria ?? []
  ).map(c => {
    if (c.value?.segment?.segment) {
      return {
        ...c,
        value: {
          segment: isType(c.value.segment.segment, 'FlagsAdminV1Segment')
            ? { segment: c.value.segment?.segment.name }
            : undefined,
        },
      };
    }
    return c as FlagsTypesV1TargetingCriteriaEntryInput;
  });

  return {
    expression: cleanedUp?.expression ?? null,
    criteria,
  };
};

const getCriterionRef = (cri: Criterion): FlagsTypesV1Expression => {
  const isNot = !!cri.op && NOT_OP.includes(cri.op);
  const ref = {
    ref: cri.name,
  };

  return isNot
    ? {
        not: ref,
      }
    : ref;
};

// format UI models to match data payload sent to Schema (server)
export const toSchemaTargeting = (
  targeting?: TargetingCriteria,
): Maybe<TargetingFragment> => {
  const criteria: Array<{ key: string; value: TargetingCriterionFragment }> =
    [];

  const traverseExpression = (
    criterion: TargetingCriteria,
  ): FlagsTypesV1Expression | undefined => {
    if (isCriterionSet(criterion)) {
      return {
        [criterion.operator]: {
          operands: criterion.criteria
            .map(traverseExpression)
            .filter(v => v !== undefined),
        },
      } as FlagsTypesV1Expression;
    }

    criteria.push(
      criterion.type === 'attribute'
        ? toSchemaAttributeCriterion(criterion)
        : toSchemaSegmentCriterion(criterion),
    );

    return getCriterionRef(criterion);
  };

  if (targeting) {
    const expression = traverseExpression(targeting);

    return {
      criteria,
      expression,
    };
  }

  return null;
};
