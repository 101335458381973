import React from 'react';

import { DialogActions, DialogContent, Typography } from '@material-ui/core';
import { Alert } from '@material-ui/lab';

import {
  DialogForm,
  DialogHeader,
  FormSubmitButtons,
  useDialog,
} from '@spotify-confidence/core-react';
import {
  getTypeOrNull,
  useDataWarehouseStateQuery,
  useRemoveDemoDataMutation,
} from '@spotify-confidence/plugin-graphql';

export const ExitDemoModeDialog = () => {
  const { closeDialog } = useDialog();
  const [
    removeDemoData,
    { loading: removing, error: removeError, data: removed },
  ] = useRemoveDemoDataMutation();

  const warehouseState = useDataWarehouseStateQuery({
    skip: !removed,
  });

  const removingDemoData =
    removed && warehouseState.data
      ? !!getTypeOrNull(
          warehouseState.data.existsDataWarehouses,
          'MetricsV1ExistsDataWarehousesResponse',
        )?.demoData
      : false;

  const loading = removing || warehouseState.loading || removingDemoData;
  const error = removeError || warehouseState.error;

  const handleRemove = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    removeDemoData();
  };

  React.useEffect(() => {
    if (getTypeOrNull(removed?.removeDemoData, 'GoogleProtobufEmpty')) {
      warehouseState.startPolling(5000);
    } else {
      warehouseState.stopPolling();
    }
  }, [removed?.removeDemoData]);

  React.useEffect(() => {
    const response = getTypeOrNull(
      warehouseState.data?.existsDataWarehouses,
      'MetricsV1ExistsDataWarehousesResponse',
    );
    if (response && !response.demoData) {
      closeDialog();
    }
  }, [warehouseState.data]);

  return (
    <DialogForm onSubmit={handleRemove}>
      <DialogHeader
        title="Exit demo mode"
        onClose={closeDialog}
        disabled={loading}
      />
      <DialogContent>
        {error && <Alert severity="error">{error.message}</Alert>}
        <Typography paragraph>
          This will remove all demo data and allow you to connect to your own
          data warehouse.
        </Typography>
      </DialogContent>
      <DialogActions>
        <FormSubmitButtons
          label="Confirm"
          onCancel={closeDialog}
          loading={loading}
        />
      </DialogActions>
    </DialogForm>
  );
};
