import { ConfidenceUserApi } from '@spotify-confidence/core-react';
import { Confidence } from '@spotify-confidence/sdk';

import { AnalyticsApi, AnalyticsEvent } from '@backstage/core-plugin-api';

export class ConfidenceAnalytics implements AnalyticsApi {
  confidenceApi: Confidence;
  confidenceUserApi: ConfidenceUserApi;
  accessTokenData: Record<string, string | string[]> | undefined;

  constructor({
    confidenceApi,
    confidenceUserApi,
  }: {
    confidenceApi: Confidence;
    confidenceUserApi: ConfidenceUserApi;
  }) {
    this.confidenceApi = confidenceApi;
    this.confidenceUserApi = confidenceUserApi;
  }

  private async getAccessTokenData() {
    if (this.accessTokenData) {
      return this.accessTokenData;
    }
    const credentials = await this.confidenceUserApi.getCredentials();
    const token = await credentials.getTokenSilently();
    const accessTokenData = JSON.parse(window.atob(token.split('.')[1]));
    this.accessTokenData = accessTokenData;
    return accessTokenData;
  }

  async captureEvent(event: AnalyticsEvent) {
    const { action, subject, value, attributes = {} } = event;

    let user;

    try {
      const tokenData = await this.getAccessTokenData();
      // in the next release this user context will be handled by a context provider instead
      user = {
        account: tokenData['https://confidence.dev/account_name'] || '',
        user: tokenData['https://confidence.dev/user_name'] || '',
      };
    } catch (error) {
      console.debug('Could not get Confidence user credentials');
      user = {};
    }

    try {
      this.confidenceApi
        .withContext({
          user,
        })
        .track(action, {
          subject,
          value: value || 0,
          attributes,
        });
    } catch (error) {
      console.error(error);
    }
  }
}
