import React from 'react';

import { Chip, Typography } from '@material-ui/core';

import { InlineCriteria } from '../../InlineCriteria';
import { RuleCardIdentifier } from '../RulceCardIdentifier';
import { RuleCardAssign } from '../RuleCardAssign';
import { RuleCardSection } from '../RuleCardSection';
import {
  RuleViewProps,
  getRuleTargetingState,
  useRuleViewStyles,
} from '../shared';

export const DefaultRuleView = ({
  rule,
  className,
  seenAttributes,
}: RuleViewProps) => {
  const classes = useRuleViewStyles();
  const {
    segment,
    isExclusive,
    targeting,
    hasTargeting,
    targetingIsAlwaysIgnored,
  } = getRuleTargetingState(rule);

  // TODO: show exclusivityTags

  return (
    <Typography variant="body1" className={className} component="div">
      {hasTargeting && !targetingIsAlwaysIgnored && (
        <RuleCardSection>
          <strong>If&nbsp;</strong>
          <span>
            <InlineCriteria {...targeting} seenAttributes={seenAttributes} />
          </span>
        </RuleCardSection>
      )}
      <RuleCardSection>
        <RuleCardIdentifier rule={rule} seenAttributes={seenAttributes} />
      </RuleCardSection>
      {isExclusive && (
        <RuleCardSection>
          <strong>Excluding </strong>
          targets in other allocations tagged with{' '}
          {segment?.allocation?.exclusiveTo.map(tag => (
            <Chip
              key={tag}
              label={tag}
              size="small"
              variant="outlined"
              className={classes.chip}
            />
          ))}
        </RuleCardSection>
      )}
      <RuleCardSection>
        <RuleCardAssign assignmentSpec={rule.assignmentSpec} />
      </RuleCardSection>
    </Typography>
  );
};
