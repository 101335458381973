import React from 'react';

import { TOKEN_OPTIONS, useAuth } from '@spotify-confidence/core-react';
import { getTypeOrNull } from '@spotify-confidence/plugin-graphql';

import { useGetCurrentUserDetailsQuery } from '../generated/graphql';

export function useAccounts() {
  const { data, loading } = useGetCurrentUserDetailsQuery();
  const [switching, setSwitching] = React.useState(false);
  const user = getTypeOrNull(data?.currentUser, 'IamV1CurrentUser');

  const memberships = user ? user.accountMemberships ?? [] : [];

  const { loginWithRedirect, getAccessTokenSilently } = useAuth();

  const accounts = memberships.map(org => ({
    displayName: org.displayName,
    accountId: org.loginId,
  }));

  const selected = user?.account.slice('accounts/'.length) ?? '';

  const switchAccount = async (accountId: string) => {
    const account = memberships.find(a => a.loginId === accountId);

    if (!account) {
      console.warn(`Account ${accountId} not found`, memberships);
      return;
    }

    const externalId = account.externalId;

    setSwitching(true);
    await getAccessTokenSilently({
      ...TOKEN_OPTIONS,
      cacheMode: 'off',
      authorizationParams: {
        ...TOKEN_OPTIONS.authorizationParams,
        organization: externalId,
      },
    });

    await loginWithRedirect({
      appState: { returnTo: '/' },
      authorizationParams: { organization: externalId },
    });

    setSwitching(false);
  };

  return { accounts, selected, switchAccount, loading, switching };
}
