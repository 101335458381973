import React from 'react';

import { useDialog } from '@spotify-confidence/core-react';

import { ResultsStrategyConfigDialog } from './ResultsStrategyConfigDialog';
import { StatsConfigDialog } from './StatsConfigDialog';
import { StatsData, TestHorizonStrategy } from './types';
import { useSaveStats } from './useSaveStats';
import { useStatsConfigDescription } from './useStatsResultsConfig';

export const useIsStatsModuleStrategySequential = (statsData?: StatsData) =>
  statsData?.testHorizonStrategy === TestHorizonStrategy.SEQUENTIAL;

export const useIsStatsModuleStrategyFixed = (statsData?: StatsData) =>
  statsData?.testHorizonStrategy === TestHorizonStrategy.FIXED_HORIZON;

export const useStatsModuleResultsConfig = ({
  statsData,
  name,
  onlySequential,
}: {
  name: string;
  statsData?: StatsData;
  onlySequential?: boolean;
}) => {
  const { openDialog } = useDialog();
  const { save, loading } = useSaveStats({ name, statsData });
  const sequential = useIsStatsModuleStrategySequential(statsData);
  const fixed = useIsStatsModuleStrategyFixed(statsData);
  const strategy = statsData?.testHorizonStrategy;

  const { configDescription } = useStatsConfigDescription({
    ...statsData,
    sequential,
  });

  const openStatsConfig = () => {
    openDialog({
      content: <StatsConfigDialog statsData={statsData} onSave={save} />,
    });
  };

  const openResultsStrategyConfig = () => {
    openDialog({
      content: (
        <ResultsStrategyConfigDialog
          strategy={strategy}
          sequentialStrategyValue={TestHorizonStrategy.SEQUENTIAL}
          fixedStrategyValue={TestHorizonStrategy.FIXED_HORIZON}
          onSave={save}
          onlySequential={onlySequential}
        />
      ),
    });
  };

  return {
    loading,
    sequential,
    fixed,
    strategy,
    configDescription,
    openResultsStrategyConfig,
    openStatsConfig,
  };
};
