import React from 'react';
import { useSearchParams } from 'react-router-dom';

import type {
  Auth0ProviderOptions,
  GetTokenSilentlyOptions,
} from '@auth0/auth0-react';
import {
  AppState,
  Auth0Context,
  Auth0ContextInterface,
  Auth0Provider,
  User,
  useAuth0,
} from '@auth0/auth0-react';
import { GenericError } from '@auth0/auth0-spa-js';

import { Region } from '../../../utils';
import { useNavigate } from '../../routing';
import { useOrgId } from '../useOrgId';
import { getRegionFromTokenOrDefault } from './jwt';

export type AuthOptions = Auth0ProviderOptions;
export type TokenOptions = GetTokenSilentlyOptions;

export const createAuthOptions = (opts: AuthOptions) => opts;
export const createTokenOptions = (opts: TokenOptions) => opts;

export const DOMAIN = 'auth.confidence.dev';
export const CLIENT_ID = '2fG3H4RhlAbIZm9Rfn32zTaILH7w1X4w';
export const AUDIENCE = 'https://confidence.dev/';

export const CONFIDENCE_TokenOptions = createTokenOptions({
  authorizationParams: {
    audience: AUDIENCE,
    scope: 'openid profile email offline_access',
  },
});

export const CONFIDENCE_DefaultAuthOptions: Omit<AuthOptions, 'clientId'> = {
  domain: DOMAIN,
  cacheLocation: 'localstorage',
  useRefreshTokens: true,
  useRefreshTokensFallback: true, // Temporary, remove once affected users have logged in again
  ...CONFIDENCE_TokenOptions,
};

const TokenWrapper = ({
  context,
  onTokenReceived,
  children,
}: React.PropsWithChildren<{
  onTokenReceived: (token: string, region: Region) => void;
  context: Auth0ContextInterface<User>;
}>) => {
  const getToken = React.useCallback(
    async (options = CONFIDENCE_TokenOptions) => {
      if (!context.isAuthenticated) return;
      try {
        const token = await context.getAccessTokenSilently({
          ...options,
          authorizationParams: {
            ...options.authorizationParams,
            organization: context?.user?.org_id,
          },
        });

        onTokenReceived(token, getRegionFromTokenOrDefault(token));
      } catch (e) {
        // eslint-disable-next-line no-console
        console.error(e);

        if (
          ['login_required', 'invalid_grant', 'missing_refresh_token'].includes(
            (e as GenericError).error,
          )
        ) {
          context.loginWithRedirect({
            authorizationParams: { organization: context.user?.org_id },
          });
        }
      }
    },
    [context, onTokenReceived],
  );

  React.useEffect(() => {
    getToken();
  }, [context.isAuthenticated, context?.user?.org_id]);

  React.useEffect(() => {
    // Refresh token when tab becomes visible to avoid requests with expired tokens
    // If this is not enough, we can pass {cacheMode: 'off'} to getToken to force a new token
    const handleGetToken = () => getToken();
    document.addEventListener('visibilitychange', handleGetToken);
    return () => {
      document.removeEventListener('visibilitychange', handleGetToken);
    };
  }, [getToken]);

  return <>{children}</>;
};

/**
 * ! Constraint #02 - No Hardwired Config.
 * ! The Provider comes with backed-in `Confidence_AuthOptions`
 *
 * * todo(@oussaz) - passe AuthOptions as a prop or renderProp.
 */
export const AuthenticationProvider = ({
  children,
  onTokenReceived,
  clientId = CLIENT_ID,
}: {
  children: React.ReactElement;
  onTokenReceived: (token: string, region: Region) => void;
  clientId?: string;
}) => {
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const [storedOrgId, setStoredOrgId] = useOrgId();
  const hasInvitation = !!searchParams.get('invitation');
  const onRedirectCallback = (appState?: AppState, user?: User) => {
    if (user && user.org_id) {
      setStoredOrgId(user.org_id);
    }
    navigate(appState?.returnTo || window.location.pathname);
  };

  const orgId =
    searchParams.get('organization') ||
    searchParams.get('account') ||
    storedOrgId ||
    undefined;

  const authOptions = React.useMemo(
    () =>
      createAuthOptions({
        ...CONFIDENCE_DefaultAuthOptions,
        clientId,
        authorizationParams: {
          ...CONFIDENCE_DefaultAuthOptions.authorizationParams,
          organization: orgId,
        },
      }),
    [clientId, orgId],
  );

  return (
    <Auth0Provider
      {...authOptions}
      onRedirectCallback={onRedirectCallback}
      authorizationParams={{
        ...authOptions.authorizationParams,
        redirect_uri: window.location.origin,
        ...(hasInvitation
          ? {
              invitation: searchParams.get('invitation')!,
              organization: searchParams.get('organization')!,
              organization_name: searchParams.get('organization_name')!,
            }
          : { organization: orgId }),
      }}
    >
      <Auth0Context.Consumer>
        {value => (
          <TokenWrapper context={value} onTokenReceived={onTokenReceived}>
            {children}
          </TokenWrapper>
        )}
      </Auth0Context.Consumer>
    </Auth0Provider>
  );
};

export const useAuth = () => {
  const {
    loginWithPopup: auth0LoginWithPopup,
    loginWithRedirect: auth0LoginWithRedirect,
    logout: auth0Logout,
    ...auth0
  } = useAuth0();
  const [_orgId, setOrgId] = useOrgId();

  const loginWithPopup: typeof auth0LoginWithPopup = React.useCallback(
    (options, config) => {
      if (options?.authorizationParams?.organization) {
        setOrgId(options.authorizationParams.organization);
      }
      return auth0LoginWithPopup(options, config);
    },
    [auth0LoginWithPopup],
  );

  const loginWithRedirect: typeof auth0LoginWithRedirect = React.useCallback(
    options => {
      if (options?.authorizationParams?.organization) {
        setOrgId(options.authorizationParams.organization);
      }
      return auth0LoginWithRedirect(options);
    },
    [auth0LoginWithRedirect],
  );

  const logout: typeof auth0Logout = React.useCallback(
    options => {
      // Reset orgId to enable login with a freetext organization
      setOrgId(null);
      return auth0Logout(options);
    },
    [auth0Logout],
  );

  return { ...auth0, loginWithPopup, loginWithRedirect, logout };
};
