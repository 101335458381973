import React from 'react';

import { RecommendationCard } from '@spotify-confidence/core-react';

import { GuardrailStatus } from '../../result';

export function GuardrailCondition({ status }: { status: GuardrailStatus }) {
  switch (status) {
    case GuardrailStatus.ALL_POWERED_ALL_SIGNIFICANT:
    case GuardrailStatus.SOME_UNPOWERED_ALL_SIGNIFICANT:
      return (
        <RecommendationCard.CriteriaItem status="success">
          All guardrail metrics meet their tolerance levels.
        </RecommendationCard.CriteriaItem>
      );
    case GuardrailStatus.SOME_NOT_SIGNIFICANT:
    case GuardrailStatus.SOME_POWERED_NOT_SIGNIFICANT:
    case GuardrailStatus.ALL_POWERED_NONE_SIGNIFICANT:
      return (
        <RecommendationCard.CriteriaItem status="warning">
          At least one guardrail metric doesn't meet its tolerance level.
        </RecommendationCard.CriteriaItem>
      );
    case GuardrailStatus.SOME_MISSING:
      return (
        <RecommendationCard.CriteriaItem status="pending">
          At least one guardrail metric doesn't have data yet.
        </RecommendationCard.CriteriaItem>
      );
    case GuardrailStatus.WITHOUT_NIM_SOME_DETERIORATED:
      return (
        <RecommendationCard.CriteriaItem status="error">
          At least one guardrail metric has deteriorated.
        </RecommendationCard.CriteriaItem>
      );
    case GuardrailStatus.WITHOUT_NIM_NONE_DETERIORATED:
      return (
        <RecommendationCard.CriteriaItem status="success">
          No guardrail metrics have deteriorated.
        </RecommendationCard.CriteriaItem>
      );
    case GuardrailStatus.WAIT_FOR_END:
      return (
        <RecommendationCard.CriteriaItem status="pending">
          Guardrail metric results are made available when you end the
          experiment.
        </RecommendationCard.CriteriaItem>
      );
    case GuardrailStatus.ALL_POWERED_UNKNOWN_SIGNIFICANCE:
      return (
        <RecommendationCard.CriteriaItem status="success">
          All guardrail metrics are powered.
        </RecommendationCard.CriteriaItem>
      );
    case GuardrailStatus.SOME_UNPOWERED_UNKNOWN_SIGNIFICANCE:
      return (
        <RecommendationCard.CriteriaItem status="warning">
          At least one guardrail metric isn't powered yet.
        </RecommendationCard.CriteriaItem>
      );
    case GuardrailStatus.NO_GUARDRAILS:
      return null;
    default:
      return (
        <RecommendationCard.CriteriaItem status="error">
          Unknown status
        </RecommendationCard.CriteriaItem>
      );
  }
}
