import React from 'react';
import { useSearchParams } from 'react-router-dom';

export const useSearchParamsConfig = ({
  params,
  handleParams,
}: {
  params: string[];
  handleParams: (params: Record<string, string>) => void;
}) => {
  const [searchParams, setSearchParams] = useSearchParams();

  React.useEffect(() => {
    const valuesMap = params.reduce<Record<string, string>>((acc, param) => {
      const value = searchParams.get(param);
      if (value) {
        acc[param] = value;
      }
      return acc;
    }, {});

    if (!Object.values(valuesMap).length) {
      return;
    }

    handleParams(valuesMap);

    setSearchParams(
      paramsSet => {
        params.forEach(param => {
          paramsSet.delete(param);
        });

        return paramsSet;
      },
      { replace: true },
    );
  }, [searchParams, params, handleParams]);
};
