import React from 'react';

import { Link, MenuItem, TextField } from '@material-ui/core';

import { FormFieldsBox } from '@spotify-confidence/core-react';
import {
  ConnectorsV1AwsRegion,
  ConnectorsV1Format,
  ConnectorsV1FlagAppliedConnectionKinesisFlagAppliedConnectionConfig as KinesisInput,
} from '@spotify-confidence/plugin-graphql';

type KinesisFlagAppliedConnectionFormProps = {
  value?: KinesisInput | null;
  onChange: (conf: KinesisInput) => void;
  disabled?: boolean;
};

const defaultConfig: KinesisInput['kinesisConfig'] = {
  outputFormat: ConnectorsV1Format.Json,
  region: ConnectorsV1AwsRegion.EuWest_1,
  roleArn: '',
  stream: '',
};

const defaultInput: KinesisInput = {
  kinesisConfig: defaultConfig,
};

const regionOptions = Object.values(ConnectorsV1AwsRegion);
const formatOptions = Object.values(ConnectorsV1Format);

export const KinesisFlagAppliedConnectionForm = ({
  value,
  onChange,
  disabled,
}: KinesisFlagAppliedConnectionFormProps) => {
  const roleArn: string = value?.kinesisConfig.roleArn ?? '';
  const region: string = value?.kinesisConfig.region ?? '';
  const stream: string = value?.kinesisConfig.stream ?? '';
  const outputFormat: ConnectorsV1Format =
    value?.kinesisConfig.outputFormat ?? ConnectorsV1Format.Json;

  const handleConfigChange = React.useCallback(
    (field: keyof KinesisInput['kinesisConfig']) =>
      (e: React.ChangeEvent<HTMLInputElement>) => {
        onChange({
          ...defaultInput,
          ...value,
          kinesisConfig: {
            ...defaultConfig,
            ...value?.kinesisConfig,
            [field]: e.target.value,
          },
        });
      },
    [value],
  );

  return (
    <>
      <FormFieldsBox>
        <TextField
          disabled={disabled}
          label="Role ARN"
          variant="outlined"
          value={roleArn}
          required
          fullWidth
          helperText="The ARN for the AWS Role that has read & write access to the Kinesis stream."
          onChange={handleConfigChange('roleArn')}
        />

        <TextField
          disabled={disabled}
          select
          label="Region"
          variant="outlined"
          value={region}
          required
          fullWidth
          onChange={handleConfigChange('region')}
          helperText="The AWS region the Kinesis stream exists in."
        >
          {regionOptions.map(regionOption => (
            <MenuItem key={regionOption} value={regionOption}>
              {regionOption}
            </MenuItem>
          ))}
        </TextField>

        <TextField
          disabled={disabled}
          label="Stream"
          variant="outlined"
          value={stream}
          required
          fullWidth
          helperText="The Kinesis stream name."
          onChange={handleConfigChange('stream')}
        />

        <TextField
          disabled={disabled}
          select
          label="Output format"
          variant="outlined"
          value={outputFormat}
          required
          fullWidth
          onChange={handleConfigChange('outputFormat')}
          helperText={
            <>
              if the connector should write events in JSON or Protobuf binary
              format. Regardless of the format chosen, the connector wraps the
              event in a{' '}
              <Link
                href="https://cloudevents.io/"
                target="_blank"
                underline="always"
              >
                CloudEvents
              </Link>{' '}
              envelope.
            </>
          }
        >
          {formatOptions.map(formatOption => (
            <MenuItem key={formatOption} value={formatOption}>
              {formatOption}
            </MenuItem>
          ))}
        </TextField>
      </FormFieldsBox>
    </>
  );
};
