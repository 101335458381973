import React from 'react';

import {
  Box,
  Collapse,
  IconButton,
  Link,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Tooltip,
  Typography,
} from '@material-ui/core';
import KeyboardArrowDown from '@material-ui/icons/KeyboardArrowDown';
import KeyboardArrowUp from '@material-ui/icons/KeyboardArrowUp';
import LinkOutlined from '@material-ui/icons/LinkOutlined';
import ReplayOutlined from '@material-ui/icons/ReplayOutlined';
import { Alert } from '@material-ui/lab';

import { CodeEditor, DateUtils } from '@spotify-confidence/core-react';
import {
  MetricCalculationFragment,
  MetricScheduleFragment,
  MetricsV1MetricCalculationState,
  getTypeOrNull,
  isError,
} from '@spotify-confidence/plugin-graphql';
import { format } from 'date-fns';

import {
  StatusError,
  StatusOK,
  StatusPending,
  StatusRunning,
} from '@backstage/core-components';

const stateIcon = (calculation: MetricCalculationFragment) => {
  const state = calculation.state;
  switch (state) {
    case MetricsV1MetricCalculationState.StateCompleted:
      return (
        <StatusOK>
          <Typography variant="body2" display="inline">
            {`Completed at ${DateUtils.toDateString(
              calculation.updateTime,
              DateUtils.SHORT_DATE_TIME_FORMAT,
            )}`}
          </Typography>
        </StatusOK>
      );
    case MetricsV1MetricCalculationState.StateFailed:
      return (
        <StatusError>
          <Typography variant="body2" display="inline">
            Failed
          </Typography>
        </StatusError>
      );
    case MetricsV1MetricCalculationState.StateRunning:
      return (
        <StatusRunning>
          <Typography variant="body2" display="inline">
            Running
          </Typography>
        </StatusRunning>
      );
    default:
      return (
        <StatusPending>
          <Typography variant="body2" display="inline">
            Unknown
          </Typography>
        </StatusPending>
      );
  }
};

function addOffsetsToTimestamp(
  timestamp: string,
  exposureOffset?: string,
  aggregationWindow?: string,
): string {
  const newTimestamp = new Date(timestamp);

  if (exposureOffset) {
    const exposureSeconds = parseInt(exposureOffset.replace('s', ''), 10);
    newTimestamp.setSeconds(newTimestamp.getSeconds() + exposureSeconds);
  }

  if (aggregationWindow) {
    const aggregationSeconds = parseInt(aggregationWindow.replace('s', ''), 10);
    newTimestamp.setSeconds(newTimestamp.getSeconds() + aggregationSeconds);
  }

  return newTimestamp.toISOString();
}

type NextMetricCalculationRowProps = {
  scheduledMetricCalculation: MetricScheduleFragment;
  exposureOffset?: string;
  aggregationWindow?: string;
};

function NextMetricCalculationRow({
  scheduledMetricCalculation,
  exposureOffset,
  aggregationWindow,
}: NextMetricCalculationRowProps) {
  const [open, setOpen] = React.useState(false);
  if (
    scheduledMetricCalculation.status.calculating ||
    scheduledMetricCalculation.status.cancelled ||
    scheduledMetricCalculation.status.error
  ) {
    // in this case there'll be a metric calculation
    return null;
  }

  const nextFactStartTime = addOffsetsToTimestamp(
    scheduledMetricCalculation.nextExposureStartTime,
    exposureOffset,
    undefined,
  );

  const nextFactEndTime = addOffsetsToTimestamp(
    scheduledMetricCalculation.nextExposureEndTime,
    exposureOffset,
    aggregationWindow,
  );

  return (
    <TableRow>
      <TableCell
        style={{
          fontFeatureSettings: "'tnum'",
          fontVariantNumeric: 'tabular-num',
          width: '15%',
        }}
      >
        {DateUtils.toDateString(
          scheduledMetricCalculation.nextScheduledRunTime,
          DateUtils.SHORT_DATE_TIME_FORMAT,
        )}
      </TableCell>
      <TableCell>
        <StatusPending>
          <Typography variant="body2" display="inline">
            {nextCalculationString(scheduledMetricCalculation)}
          </Typography>
        </StatusPending>
        <Collapse in={open} timeout="auto" unmountOnExit>
          <Box>
            <Typography variant="body2" color="textSecondary" display="inline">
              {windowString(
                scheduledMetricCalculation.nextExposureStartTime,
                scheduledMetricCalculation.nextExposureEndTime,
                nextFactStartTime,
                nextFactEndTime,
                true,
              )}
            </Typography>
          </Box>
        </Collapse>
      </TableCell>
      <TableCell />
      <TableCell />
      <TableCell>
        <IconButton
          aria-label="expand row"
          size="small"
          name="expand-button"
          onClick={() => setOpen(!open)}
        >
          {open ? <KeyboardArrowUp /> : <KeyboardArrowDown />}
        </IconButton>
      </TableCell>
    </TableRow>
  );
}

type MetricCalculationRowProps = {
  calculation: MetricCalculationFragment;
  onRetrigger: (v: MetricCalculationFragment) => void;
  isRetriggerDisabled?: boolean;
};

function windowString(
  exposureStartTime: string,
  exposureEndTime: string,
  factStartTime: string,
  factEndTime: string,
  next: boolean,
) {
  return `The calculation ${
    next ? 'needs' : 'used'
  } new exposure for ${DateUtils.toDateString(
    exposureStartTime,
    DateUtils.SHORT_DATE_TIME_FORMAT,
  )} – ${DateUtils.toDateString(
    exposureEndTime,
    DateUtils.SHORT_DATE_TIME_FORMAT,
  )} and facts for ${DateUtils.toDateString(
    factStartTime,
    DateUtils.SHORT_DATE_TIME_FORMAT,
  )} – ${DateUtils.toDateString(
    factEndTime,
    DateUtils.SHORT_DATE_TIME_FORMAT,
  )}.`;
}

export function MetricCalculationRow({
  calculation,
  onRetrigger,
  isRetriggerDisabled,
}: MetricCalculationRowProps) {
  const [open, setOpen] = React.useState(false);
  const sqlJob = getTypeOrNull(calculation?.sqlJob, 'MetricsV1SqlJob');
  const link = sqlJob?.labels?.find(
    l => l.key === 'data_warehouse_link',
  )?.value;
  return (
    <React.Fragment>
      <TableRow key={calculation.name}>
        <TableCell
          style={{
            fontFeatureSettings: "'tnum'",
            fontVariantNumeric: 'tabular-num',
          }}
        >
          {`${format(
            new Date(calculation.endTime),
            DateUtils.SHORT_DATE_TIME_FORMAT,
          )}`}
        </TableCell>
        <TableCell>
          {stateIcon(calculation)}
          <Collapse in={open} timeout="auto" unmountOnExit>
            <Box>
              <Typography
                variant="body2"
                color="textSecondary"
                display="inline"
              >
                {windowString(
                  calculation.times.exposureStartTime,
                  calculation.times.exposureEndTime,
                  calculation.times.factStartTime,
                  calculation.times.factEndTime,
                  false,
                )}
              </Typography>
            </Box>
          </Collapse>
        </TableCell>
        <TableCell align="right">
          {(calculation.state ===
            MetricsV1MetricCalculationState.StateCompleted ||
            calculation.state ===
              MetricsV1MetricCalculationState.StateFailed) &&
            !calculation.supersededBy && (
              <Tooltip title="Retrigger">
                <IconButton
                  size="small"
                  disabled={isRetriggerDisabled}
                  onClick={() => onRetrigger(calculation)}
                >
                  <ReplayOutlined />
                </IconButton>
              </Tooltip>
            )}
        </TableCell>
        <TableCell>
          {link && (
            <Link href={link} target="_blank">
              <LinkOutlined />
            </Link>
          )}
        </TableCell>
        <TableCell>
          <Tooltip title="Toggle query">
            <IconButton
              aria-label="expand row"
              size="small"
              name="expand-button"
              onClick={() => setOpen(!open)}
            >
              {open ? <KeyboardArrowUp /> : <KeyboardArrowDown />}
            </IconButton>
          </Tooltip>
        </TableCell>
      </TableRow>
      <TableRow>
        <TableCell padding="none" colSpan={6}>
          <Collapse in={open} timeout="auto" unmountOnExit>
            <Box>
              {sqlJob?.failedQueryResult?.error && (
                <Alert severity="error">
                  {sqlJob?.failedQueryResult?.error}
                </Alert>
              )}
              {calculation.metricResults?.metricBucketStats &&
                calculation.metricResults?.metricBucketStats.length > 0 && (
                  <Table size="small" aria-label="results">
                    <TableHead>
                      <TableRow>
                        <TableCell>Group</TableCell>
                        <TableCell>Count</TableCell>
                        <TableCell>Count missing</TableCell>
                        {calculation.metricResults?.metricBucketStats[0]
                          .summaryStats.averageSummaryStats?.count !==
                          undefined && (
                          <>
                            <TableCell>Mean</TableCell>
                            <TableCell>Variance</TableCell>
                          </>
                        )}
                        {calculation.metricResults?.metricBucketStats[0]
                          .summaryStats.ratioSummaryStats?.count !==
                          undefined && (
                          <>
                            <TableCell>Numerator Mean</TableCell>
                            <TableCell>Numerator Variance</TableCell>
                            <TableCell>Denominator Mean</TableCell>
                            <TableCell>Denominator Variance</TableCell>
                          </>
                        )}
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {calculation.metricResults?.metricBucketStats.map(
                        bucket => (
                          <TableRow key={bucket.variant}>
                            <TableCell
                              component="th"
                              scope="row"
                              data-testid="variant"
                            >
                              {bucket.variant}
                            </TableCell>
                            {bucket.summaryStats?.averageSummaryStats?.count !==
                              undefined && (
                              <>
                                <TableCell data-testid="count">
                                  {
                                    bucket.summaryStats?.averageSummaryStats
                                      ?.count
                                  }
                                </TableCell>
                                <TableCell data-testid="count-missing">
                                  {
                                    bucket.summaryStats?.averageSummaryStats
                                      ?.countMissing
                                  }
                                </TableCell>
                                <TableCell data-testid="mean">
                                  {
                                    bucket.summaryStats?.averageSummaryStats
                                      ?.mean
                                  }
                                </TableCell>
                                <TableCell data-testid="variance">
                                  {
                                    bucket.summaryStats?.averageSummaryStats
                                      ?.variance
                                  }
                                </TableCell>
                              </>
                            )}
                            {bucket.summaryStats?.ratioSummaryStats?.count !==
                              undefined && (
                              <>
                                <TableCell data-testid="count">
                                  {
                                    bucket.summaryStats?.ratioSummaryStats
                                      ?.count
                                  }
                                </TableCell>
                                <TableCell data-testid="count-missing">
                                  {
                                    bucket.summaryStats?.ratioSummaryStats
                                      ?.countMissing
                                  }
                                </TableCell>
                                <TableCell data-testid="numerator-mean">
                                  {
                                    bucket.summaryStats?.ratioSummaryStats
                                      ?.numeratorMean
                                  }
                                </TableCell>
                                <TableCell data-testid="numerator-variance">
                                  {
                                    bucket.summaryStats?.ratioSummaryStats
                                      ?.numeratorVariance
                                  }
                                </TableCell>
                                <TableCell data-testid="denominator-mean">
                                  {
                                    bucket.summaryStats?.ratioSummaryStats
                                      ?.denominatorMean
                                  }
                                </TableCell>
                                <TableCell data-testid="denominator-variance">
                                  {
                                    bucket.summaryStats?.ratioSummaryStats
                                      ?.denominatorVariance
                                  }
                                </TableCell>
                              </>
                            )}
                          </TableRow>
                        ),
                      )}
                    </TableBody>
                  </Table>
                )}
              {calculation.sql && (
                <CodeEditor value={calculation.sql} mode="sql" readOnly />
              )}
            </Box>
          </Collapse>
        </TableCell>
      </TableRow>
    </React.Fragment>
  );
}

export function nextCalculationString(
  scheduledMetricCalculation: Pick<
    MetricScheduleFragment,
    'status' | 'metricCalculationSpec' | 'nextScheduledRunTime'
  >,
): string {
  let waitingString = 'Waiting for data to be delivered.';
  let dataExpected = '';
  let lastChecked = '';
  if (scheduledMetricCalculation.status.scheduled) {
    return `Next scheduled run is at ${DateUtils.toDateString(
      scheduledMetricCalculation.status.scheduled.nextScheduleTime,
      'yyyy-MM-dd HH:mm',
    )}`;
  } else if (scheduledMetricCalculation.status.waiting) {
    let what = 'data';
    if (scheduledMetricCalculation.status.waiting.exposureTable) {
      what = 'exposure';
    } else if (scheduledMetricCalculation.status.waiting.factTable) {
      what = 'facts';
    }
    waitingString = `Waiting for ${what} to be delivered${
      scheduledMetricCalculation.status.waiting.needDataUntilTime
        ? ` until ${DateUtils.toDateString(
            scheduledMetricCalculation.status.waiting.needDataUntilTime,
            DateUtils.SHORT_DATE_TIME_FORMAT,
          )}`
        : ''
    }.`;
  }

  if (
    scheduledMetricCalculation.metricCalculationSpec.metric &&
    !isError(scheduledMetricCalculation.metricCalculationSpec.metric)
  ) {
    const factTable =
      scheduledMetricCalculation.metricCalculationSpec.metric.factTable;
    if (
      factTable &&
      !isError(factTable) &&
      'dataDeliveredUntilUpdateStrategyConfig' in factTable
    ) {
      const deliveryConfig = factTable.dataDeliveredUntilUpdateStrategyConfig;
      if (
        deliveryConfig &&
        scheduledMetricCalculation.status.waiting?.needDataUntilTime
      ) {
        const nextScheduledRunTime = new Date(
          scheduledMetricCalculation.nextScheduledRunTime,
        );
        let delayInSeconds;
        if (deliveryConfig.automaticUpdateConfig) {
          const commitDelay = deliveryConfig.automaticUpdateConfig.commitDelay;
          delayInSeconds = parseInt(commitDelay.replace('s', ''), 10);
        } else if (
          deliveryConfig.dailyUpdateConfig ||
          deliveryConfig.hourlyUpdateConfig
        ) {
          const updateInfo = factTable.partitionedUpdateStrategyState;
          if (updateInfo?.usualLateness) {
            delayInSeconds = parseInt(
              updateInfo?.usualLateness?.replace('s', ''),
              10,
            );

            if (updateInfo?.currentPartitionLastCheckTime) {
              lastChecked = ` Last checked at ${DateUtils.toDateString(
                updateInfo?.currentPartitionLastCheckTime,
                DateUtils.SHORT_DATE_TIME_FORMAT,
              )}.`;
            }
          }
        }
        if (delayInSeconds) {
          nextScheduledRunTime.setSeconds(
            nextScheduledRunTime.getSeconds() + delayInSeconds,
          );

          dataExpected = ` Data estimated to be available by ${DateUtils.toDateString(
            nextScheduledRunTime,
            DateUtils.SHORT_DATE_TIME_FORMAT,
          )}.`;
        }
      }
    }
  }
  return `${waitingString}${dataExpected}${lastChecked}`;
}

export const MetricCalculationsList = ({
  calculations = [],
  onRetrigger,
  isRetriggerDisabled,
  hideSupersededCalculations,
  className,
  scheduledMetricCalculation,
  exposureOffset,
  aggregationWindow,
}: {
  calculations?: MetricCalculationFragment[];
  onRetrigger: (v: MetricCalculationFragment) => void;
  isRetriggerDisabled?: boolean;
  hideSupersededCalculations?: boolean;
  className?: string;
  scheduledMetricCalculation?: MetricScheduleFragment;
  exposureOffset?: string;
  aggregationWindow?: string;
}) => {
  const maybeFilteredCalculations = calculations
    .filter(c => !hideSupersededCalculations || !c.supersededBy)
    .sort((a, b) => {
      if (b.startTime !== a.startTime) {
        return (
          new Date(b.startTime).getTime() - new Date(a.startTime).getTime()
        );
      }
      return (
        new Date(b.createTime).getTime() - new Date(a.createTime).getTime()
      );
    });

  return (
    <>
      <div className={className}>
        <Table size="small" data-testid="metric-calculations-list">
          <TableHead>
            <TableRow>
              <TableCell>Partition</TableCell>
              <TableCell />
              <TableCell />
              <TableCell />
              <TableCell />
            </TableRow>
          </TableHead>
          <TableBody>
            {scheduledMetricCalculation && (
              <NextMetricCalculationRow
                scheduledMetricCalculation={scheduledMetricCalculation}
                exposureOffset={exposureOffset}
                aggregationWindow={aggregationWindow}
              />
            )}
            {maybeFilteredCalculations.map((calculation, i) => (
              <MetricCalculationRow
                key={calculation?.name ?? i}
                calculation={calculation}
                onRetrigger={onRetrigger}
                isRetriggerDisabled={isRetriggerDisabled}
              />
            ))}
          </TableBody>
        </Table>
      </div>
    </>
  );
};
