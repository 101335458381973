import React from 'react';
import { InView } from 'react-intersection-observer';

import { Fab, makeStyles } from '@material-ui/core';
import { duration } from '@material-ui/core/styles/transitions';
import ArrowUpwardIcon from '@material-ui/icons/ArrowUpward';

import { FadeIn } from './FadeIn';

type Props = {
  label?: string;
};

const useStyles = makeStyles(theme => ({
  fab: {
    position: 'fixed',
    left: '50%',
    transform: 'translateX(-50%)',
    bottom: theme.spacing(2),
    zIndex: 4,
    '&:hover, &:active, &:active:hover': {
      transform: 'translateX(-50%) scale(1.04)',
    },
  },
  fabIcon: {
    marginRight: theme.spacing(1),
  },
}));

export const ScrollTopButton = ({ label = 'Back to top' }: Props) => {
  const classes = useStyles();
  const [showButton, setShowButton] = React.useState(false);
  const ref = React.useRef<HTMLDivElement>(null);

  const scrollToTop = React.useCallback(() => {
    if (!ref?.current) return;
    try {
      const container = ref.current.parentNode as HTMLElement;
      container?.scrollTo({ top: 0, behavior: 'smooth' });
    } catch (e) {
      // eslint-disable-next-line no-console
      console.log(e);
    }
  }, []);

  return (
    <>
      <div ref={ref} />
      <InView onChange={inView => setShowButton(!inView)} />
      {showButton && (
        <FadeIn timeout={duration.enteringScreen}>
          <Fab
            variant="extended"
            size="medium"
            color="secondary"
            className={classes.fab}
            onClick={scrollToTop}
          >
            <ArrowUpwardIcon className={classes.fabIcon} />
            {label}
          </Fab>
        </FadeIn>
      )}
    </>
  );
};
