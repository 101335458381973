import { timeWindowUtils } from '@spotify-confidence/core-react';
import {
  InputMaybe,
  MetricsV1MetricMeasurementConfigInput,
} from '@spotify-confidence/plugin-graphql';

export const parseExposureOffset = (duration: string) => {
  if (duration === '0s') {
    return 'at exposure';
  }
  return `${timeWindowUtils.parseWindow(duration || '0s')} after exposure`;
};

export enum MeasurementConfigKind {
  ClosedWindow = 'closedWindow',
  SemiOpenWindow = 'semiOpenWindow',
  OpenWindow = 'openWindow',
}

export const getExposureOffsetIfExists = (
  measurementConfig?: InputMaybe<MetricsV1MetricMeasurementConfigInput>,
): string | undefined => {
  if (!!measurementConfig?.openWindow) {
    return undefined;
  } else if (!!measurementConfig?.semiOpenWindow) {
    return measurementConfig.semiOpenWindow.exposureOffset ?? undefined;
  } else if (!!measurementConfig?.closedWindow) {
    return measurementConfig.closedWindow.exposureOffset ?? undefined;
  }

  return undefined;
};

export const getAggregationWindowIfExists = (
  measurementConfig?: InputMaybe<MetricsV1MetricMeasurementConfigInput>,
): string | undefined => {
  if (!!measurementConfig?.openWindow) {
    return undefined;
  } else if (!!measurementConfig?.semiOpenWindow) {
    return measurementConfig.semiOpenWindow.aggregationWindow ?? undefined;
  } else if (!!measurementConfig?.closedWindow) {
    return measurementConfig.closedWindow.aggregationWindow ?? undefined;
  }

  return undefined;
};

export const isMeasurementConfigValid = (
  measurementConfig?: InputMaybe<MetricsV1MetricMeasurementConfigInput>,
): boolean => {
  if (!!measurementConfig?.semiOpenWindow) {
    return measurementConfig.semiOpenWindow.aggregationWindow !== '0s';
  } else if (!!measurementConfig?.closedWindow) {
    return measurementConfig.closedWindow.aggregationWindow !== '0s';
  }

  return true;
};

export const getMeasurementKind = (
  measurementConfig?: InputMaybe<MetricsV1MetricMeasurementConfigInput>,
): MeasurementConfigKind => {
  if (!!measurementConfig?.openWindow) {
    return MeasurementConfigKind.OpenWindow;
  } else if (!!measurementConfig?.semiOpenWindow) {
    return MeasurementConfigKind.SemiOpenWindow;
  }

  return MeasurementConfigKind.ClosedWindow;
};
