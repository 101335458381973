import React, { cloneElement, forwardRef, isValidElement } from 'react';

import {
  ListItemIcon,
  ListItemText,
  MenuItem,
  MenuItemProps,
  SvgIconProps,
  Theme,
  Tooltip,
  WithStyles,
  useMediaQuery,
  useTheme,
  withStyles,
} from '@material-ui/core';

import classNames from 'classnames';

import { useFloatingHeaderActionsContext } from './context';

const styles = (theme: Theme) => ({
  root: {
    height: 44,
    '&$iconOnly > $label': {
      maxWidth: 0,
      overflow: 'hidden',
      opacity: 0,
    },
    borderRadius: theme.shape.borderRadius,
  },
  icon: {
    minWidth: 'unset',
  },
  label: {
    maxWidth: 240,
    transition: theme.transitions.create(['max-width', 'opacity']),
    opacity: 1,
  },
  labelInner: {
    paddingLeft: theme.spacing(1),
  },
  iconOnly: {},
});

export interface ItemProps
  extends WithStyles<typeof styles>,
    Omit<MenuItemProps, 'classes' | 'button'> {
  className?: string;
  icon: React.ReactNode;
}

export const Item = forwardRef<HTMLLIElement, ItemProps>((props, ref) => {
  const theme = useTheme();
  const { isCollapsed } = useFloatingHeaderActionsContext();

  const { className, classes, icon, children, ...rest } = props;

  const isSmall = useMediaQuery(theme.breakpoints.down('md'));
  const onlyIcon = !isCollapsed && isSmall;

  const color = isCollapsed ? 'inherit' : 'secondary';

  const smallIcon = isValidElement<SvgIconProps>(icon)
    ? cloneElement(icon, { color, fontSize: 'small' })
    : null;

  return (
    <Tooltip
      title={children as string}
      disableHoverListener={!onlyIcon}
      disableFocusListener={!onlyIcon}
      disableTouchListener={!onlyIcon}
    >
      <MenuItem
        className={classNames(
          className,
          classes.root,
          onlyIcon && classes.iconOnly,
        )}
        button
        tabIndex={0}
        ref={ref}
        {...rest}
      >
        <ListItemIcon className={classes.icon}>{smallIcon}</ListItemIcon>
        <ListItemText
          className={classes.label}
          primary={<span className={classes.labelInner}>{children}</span>}
          primaryTypographyProps={{ variant: 'body2', color }}
        />
      </MenuItem>
    </Tooltip>
  );
});

export default withStyles(styles)(Item);
