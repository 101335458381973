import React, { useEffect } from 'react';
import { Link, useNavigate, useParams } from 'react-router-dom';

import { Button, Typography } from '@material-ui/core';

import {
  Breadcrumbs,
  ConfirmDialog,
  FilterListProvider,
  PageLayout,
  SidebarSection,
  SidebarValue,
  SidebarValueWrapper,
  useAlert,
  useDialog,
} from '@spotify-confidence/core-react';
import { PermissionUtils } from '@spotify-confidence/plugin-permissions-react';

import { useRouteRef } from '@backstage/core-plugin-api';

import { groupsRouteRef, rootRouteRef } from '../../../routes';
import { useGroup } from '../../hooks/useGroup';
import { GroupIdentityList } from './GroupIdentityList';

export const GroupPage = () => {
  const { id } = useParams();
  const { openDialog } = useDialog();
  const alert = useAlert();

  const navigate = useNavigate();

  const adminRoute = useRouteRef(rootRouteRef);
  const groupsRoute = useRouteRef(groupsRouteRef);

  const name = `groups/${id}`;

  const { group, loading, error, deleteGroup } = useGroup(name);
  const canEdit = PermissionUtils.hasRelation(group, 'can_edit');

  const handleDelete = () => {
    openDialog({
      content: (
        <ConfirmDialog
          title="Delete group"
          irreversible
          onConfirm={async () => {
            await deleteGroup();
            navigate(groupsRoute());
          }}
        />
      ),
    });
  };

  useEffect(() => {
    if (error) {
      alert.post({
        message: error.message,
        severity: 'error',
      });
    }
  }, [error]);

  return (
    <FilterListProvider storageKey="groupMembers">
      <PageLayout
        isLoading={loading}
        title={group?.displayName}
        headerBreadcrumbs={
          <Breadcrumbs>
            <Link to={adminRoute()}>Admin</Link>
            <Link to={groupsRoute()}>Groups</Link>
            <Typography>{group?.displayName}</Typography>
          </Breadcrumbs>
        }
        sideBarRight={
          <>
            <SidebarSection title="Actions">
              <Button
                variant="outlined"
                size="small"
                onClick={handleDelete}
                disabled={!canEdit}
              >
                Delete
              </Button>
            </SidebarSection>
            <SidebarSection title="About">
              <SidebarValueWrapper>
                <SidebarValue
                  name="Name"
                  singleLine
                  value={group?.displayName}
                />
                <SidebarValue
                  name="Description"
                  value={group?.description || '-'}
                />
              </SidebarValueWrapper>
            </SidebarSection>
          </>
        }
      >
        <GroupIdentityList name={name} readOnly={!canEdit} />
      </PageLayout>
    </FilterListProvider>
  );
};
