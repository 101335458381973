import React from 'react';

import { Typography } from '@material-ui/core';

import { CodeSnippet, SnippetProps } from '../../CodeSnippet';
import { f } from '../../CodeSnippet';
import { TUTORIAL_FLAG_NAME } from '../constants';

export const PythonClientSnippets = (props: SnippetProps) => {
  return (
    <ol>
      <li>
        <Typography variant="body1" gutterBottom>
          Add the Confidence SDK to your project.
        </Typography>
        <CodeSnippet
          language="python"
          text={f(`
pip install spotify-confidence-sdk
`)}
          showCopyCodeButton
        />
      </li>
      <li>
        <Typography variant="body1" gutterBottom>
          Initialize the Confidence instance.
        </Typography>
        <CodeSnippet
          language="python"
          text={f(`
from confidence.confidence import Confidence

root_confidence = Confidence("${props.apiKey}")
`)}
          showCopyCodeButton
        />
      </li>
      <li>
        <Typography variant="body1" gutterBottom>
          Next, we want to set the `visitor_id` context field to
          "tutorial_visitor", to match the targeting criteria pre-configured for
          this tutorial.
        </Typography>
        <CodeSnippet
          language="go"
          text={f(`
confidence = root_confidence.with_context({"visitor_id": "tutorial_visitor"})
          `)}
          showCopyCodeButton
        />
      </li>
      <li>
        <Typography variant="body1" gutterBottom>
          Resolve the "title" value for the "{TUTORIAL_FLAG_NAME}" flag.
        </Typography>
        <CodeSnippet
          language="python"
          text={f(`
value = confidence.resolve_string_details("${TUTORIAL_FLAG_NAME}.title", "Default")
print(f"Flag value: {value}")
      `)}
          showCopyCodeButton
        />
      </li>
    </ol>
  );
};
