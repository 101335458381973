import React from 'react';
import { InView } from 'react-intersection-observer';
import { useParams } from 'react-router-dom';

import {
  Button,
  Table,
  TableBody,
  TableCell,
  TableRow,
} from '@material-ui/core';
import AddIcon from '@material-ui/icons/Add';

import loadable from '@loadable/component';
import {
  ACTION_BAR_HEIGHT,
  ActionBar,
  DateUtils,
  FilterBar,
  FilterListEmptyState,
  FilterListProvider,
  Highlight,
  MediumScreenCell,
  PageLayout,
  ResourceTableRow,
  StickyTableContainer,
  StickyTableHead,
  extractLastNameComponent,
  useDialog,
  useListFilter,
  useMeasureDimensions,
  useSearchParamsConfig,
} from '@spotify-confidence/core-react';
import {
  getError,
  getTypeOrNull,
  useGetWorkflowSecretsQuery,
} from '@spotify-confidence/plugin-graphql';
import { useCheckResourcePermissions } from '@spotify-confidence/plugin-permissions-react';

import { Link } from '@backstage/core-components';
import { useRouteRef } from '@backstage/core-plugin-api';

import { workflowSecretRouteRef } from '../../../routes';
import { WorkflowSecretDialog } from './WorkflowSecretDialog';

const CreateWorkflowSecretDialog = loadable(() =>
  import('./CreateWorkflowSecretDialog').then(
    m => m.CreateWorkflowSecretDialog,
  ),
);

type WorkflowSecretsListProps = {
  pageSize?: number;
  pageToken?: string;
  createSecret?: () => void;
  topOffset?: number;
};

const WorkflowSecretsList = ({
  pageSize = 25,
  pageToken = '',
  createSecret,
  topOffset = ACTION_BAR_HEIGHT,
}: WorkflowSecretsListProps) => {
  const secretRoute = useRouteRef(workflowSecretRouteRef);

  const { searchQuery } = useListFilter();

  const { data, loading, error, fetchMore } = useGetWorkflowSecretsQuery({
    variables: {
      pageSize,
      pageToken,
    },
    fetchPolicy: 'cache-and-network',
  });

  const { workflowSecrets = [], nextPageToken } =
    getTypeOrNull(
      data?.workflowSecrets,
      'WorkflowV1ListWorkflowSecretsResponse',
    ) || {};
  const secrets = workflowSecrets.filter(secret =>
    extractLastNameComponent(secret.name)!
      .toLowerCase()
      .includes(searchQuery?.toLowerCase() ?? ''),
  );

  if (secrets.length === 0 && !loading) {
    return (
      <FilterListEmptyState
        error={getError(data?.workflowSecrets) || error}
        type="workflow secret"
        description={
          <>
            Use workflow secrets to access sensitive information in your
            workflows without having to store it in code.
          </>
        }
        onCreate={createSecret}
      />
    );
  }

  return (
    <StickyTableContainer loading={loading} error={error}>
      <Table>
        <StickyTableHead topOffset={topOffset}>
          <TableRow>
            <TableCell>Name</TableCell>
            <MediumScreenCell align="right">Last update</MediumScreenCell>
          </TableRow>
        </StickyTableHead>
        <TableBody>
          {secrets.map(secret => (
            <ResourceTableRow
              key={secret.name}
              routeRef={workflowSecretRouteRef}
              name={secret.name}
              data-testid={extractLastNameComponent(secret.name)}
            >
              <TableCell>
                <Link
                  to={secretRoute({
                    id: extractLastNameComponent(secret.name)!,
                  })}
                >
                  <Highlight highlight={searchQuery}>
                    {extractLastNameComponent(secret.name)}
                  </Highlight>
                </Link>
              </TableCell>

              <MediumScreenCell width={200} align="right">
                {DateUtils.xAgo(new Date(secret.updateTime))}
              </MediumScreenCell>
            </ResourceTableRow>
          ))}
        </TableBody>
      </Table>
      {data && (
        <InView
          onChange={async inView => {
            if (inView && nextPageToken) {
              await fetchMore({
                variables: {
                  pageToken: nextPageToken,
                },
              });
            }
          }}
        />
      )}
    </StickyTableContainer>
  );
};

export const WorkflowSecretsPage = () => {
  const { id } = useParams();

  const [ref, { height }] = useMeasureDimensions<HTMLDivElement>();

  const { allowed } = useCheckResourcePermissions({
    can: 'create_workflow_secret',
    name: 'account',
  });

  const { openDialog } = useDialog();

  const createSecret = React.useCallback(() => {
    openDialog({
      content: <CreateWorkflowSecretDialog />,
    });
  }, [openDialog]);

  useSearchParamsConfig({
    params: ['create'],
    handleParams: params => {
      if (params.create === 'true') {
        createSecret();
      }
    },
  });

  React.useEffect(() => {
    if (id) {
      openDialog({
        content: <WorkflowSecretDialog id={id} />,
      });
    }
  }, [id]);

  return (
    <FilterListProvider storageKey="workflow-secrets">
      <PageLayout narrow title="Workflow Secrets">
        <ActionBar ref={ref}>
          <FilterBar />
          <Button
            variant="contained"
            color="primary"
            size="small"
            onClick={createSecret}
            data-testid="create-workflow-secret-button"
            startIcon={<AddIcon />}
            disabled={!allowed}
          >
            Create
          </Button>
        </ActionBar>

        <WorkflowSecretsList
          createSecret={allowed ? createSecret : undefined}
          topOffset={height}
        />
      </PageLayout>
    </FilterListProvider>
  );
};
