import React from 'react';

import { TargetingDropdown } from '../TargetingDropdown';

export const InclusivePicker = ({
  inclusive,
  onChange,
  disabled,
  readOnly,
}: {
  inclusive: boolean;
  onChange: (inc: boolean) => void;
  disabled?: boolean;
  readOnly?: boolean;
}) => {
  return (
    <TargetingDropdown
      name="inclusive-picker"
      label={inclusive ? 'Incl.' : 'Excl.'}
      tooltip={inclusive ? 'Inclusive' : 'Exclusive'}
      readOnly={readOnly}
      disabled={disabled}
      options={[
        { label: 'Inclusive', onClick: () => onChange(true) },
        { label: 'Exclusive', onClick: () => onChange(false) },
      ]}
    />
  );
};
