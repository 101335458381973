import { useEffect, useState } from 'react';

export function useDelayedLoading(loading: boolean) {
  const [showLoading, setShowLoading] = useState(false);

  useEffect(() => {
    if (loading) {
      const timeout = setTimeout(() => {
        setShowLoading(true);
      }, 1 * 1000);
      return () => clearTimeout(timeout);
    }

    setShowLoading(false);
    return () => null;
  }, [loading]);

  return { showLoading };
}
