import React from 'react';
import { InView } from 'react-intersection-observer';

import {
  Avatar,
  IconButton,
  ListItemText,
  Table,
  TableBody,
  TableCell,
  TableRow,
  Tooltip,
  Typography,
  styled,
} from '@material-ui/core';
import Close from '@material-ui/icons/Close';

import {
  ACTION_BAR_HEIGHT,
  ConfirmDialog,
  DateUtils,
  FilterListEmptyState,
  Highlight,
  StickyTableContainer,
  StickyTableHead,
  useDialog,
  useListFilter,
} from '@spotify-confidence/core-react';
import {
  ListUserInvitationsDocument,
  UserInvitationFragment,
  getError,
  getTypeOrNull,
  useDeleteUserInvitationMutation,
  useListUserInvitationsQuery,
} from '@spotify-confidence/plugin-graphql';

import { StatusError, StatusOK } from '@backstage/core-components';

type InviteListProps = {
  pageSize?: number;
  pageToken?: string;
  topOffset?: number;
};

const NonRelativeAvatar = styled(Avatar)({
  position: 'static',
});

const DeleteInvitationButton = ({
  invitation,
}: {
  invitation: UserInvitationFragment;
}) => {
  const { openDialog } = useDialog();
  const [deleteInvitation, { loading: isDeleting }] =
    useDeleteUserInvitationMutation({
      refetchQueries: [ListUserInvitationsDocument],
    });

  const handleDelete = () => {
    const expirationDate = new Date(invitation.expirationTime);
    const hasExpired = expirationDate < new Date();

    if (hasExpired) {
      deleteInvitation({ variables: { name: invitation.name } });
    } else {
      openDialog({
        content: (
          <ConfirmDialog
            onConfirm={async () => {
              await deleteInvitation({ variables: { name: invitation.name } });
            }}
            title="Delete invitation"
          >
            <Typography>
              This will invalidate the invitation link for{' '}
              {invitation.invitedEmail}.
            </Typography>
          </ConfirmDialog>
        ),
      });
    }
  };

  return (
    <IconButton
      size="small"
      title="Remove invitation"
      name="remove-invitation-button"
      onClick={handleDelete}
      disabled={isDeleting}
    >
      <Close />
    </IconButton>
  );
};

export const InviteList = ({
  pageSize = 25,
  pageToken = '',
  topOffset = ACTION_BAR_HEIGHT,
}: InviteListProps) => {
  const { searchQuery } = useListFilter();

  const { data, loading, error, fetchMore } = useListUserInvitationsQuery({
    variables: {
      pageSize,
      pageToken,
    },
    fetchPolicy: 'cache-and-network',
  });

  const { userInvitations, nextPageToken } =
    getTypeOrNull(data?.userInvitations, 'IamV1ListUserInvitationsResponse') ||
    {};
  const getUserInvitationsError = getError(data?.userInvitations);

  const filteredInvitations = (userInvitations || []).filter(invitation =>
    invitation.invitedEmail.toLowerCase().includes(searchQuery.toLowerCase()),
  );
  if (filteredInvitations.length === 0 && !loading) {
    return (
      <FilterListEmptyState
        type="invitation"
        description="Invite colleagues to experiment together using Confidence."
      />
    );
  }

  return (
    <StickyTableContainer
      loading={loading}
      error={error ?? getUserInvitationsError}
    >
      <Table>
        <StickyTableHead topOffset={topOffset}>
          <TableRow>
            <TableCell colSpan={2}>Email</TableCell>
            <TableCell>Status</TableCell>
            <TableCell align="center">Remove</TableCell>
          </TableRow>
        </StickyTableHead>
        <TableBody>
          {filteredInvitations.map(invitation => {
            const expirationDate = new Date(invitation.expirationTime);
            const hasExpired = expirationDate < new Date();
            const expiredTime = DateUtils.xAgo(expirationDate);
            const expiredLabel = hasExpired
              ? `Expired ${expiredTime}`
              : `Expires ${expiredTime}`;

            return (
              <TableRow
                key={invitation.name}
                data-testid={invitation.invitedEmail}
              >
                <TableCell width={65}>
                  <NonRelativeAvatar />
                </TableCell>
                <TableCell width={300}>
                  <ListItemText
                    primary={
                      <Highlight highlight={searchQuery}>
                        {invitation.invitedEmail}
                      </Highlight>
                    }
                    secondary={`Invited by ${invitation.inviter}`}
                  />
                </TableCell>
                <TableCell width={140}>
                  <Tooltip title={expiredLabel} arrow>
                    <div>
                      {hasExpired ? (
                        <StatusError>Expired</StatusError>
                      ) : (
                        <StatusOK>Active</StatusOK>
                      )}
                    </div>
                  </Tooltip>
                </TableCell>
                <TableCell align="center" width={100}>
                  <DeleteInvitationButton invitation={invitation} />
                </TableCell>
              </TableRow>
            );
          })}
        </TableBody>
      </Table>
      {data && (
        <InView
          onChange={async inView => {
            if (inView && nextPageToken) {
              await fetchMore({
                variables: {
                  pageToken: nextPageToken,
                },
              });
            }
          }}
        />
      )}
    </StickyTableContainer>
  );
};
