import React from 'react';
import { Route, Routes } from 'react-router-dom';

import loadable from '@loadable/component';
import { AuthorizationGate } from '@spotify-confidence/core-react';

const pagesChunk = () =>
  import(
    /* webpackChunkName: "events-pages", webpackPrefetch: 5 */ './components'
  );
const loadOptions = {};

const InsightsPage = loadable(
  () => pagesChunk().then(m => m.InsightsPage),
  loadOptions,
);

const routes = (
  <>
    <Route path="/" element={<InsightsPage />} />
  </>
);

export const Router = () => (
  <AuthorizationGate>
    <Routes>{routes}</Routes>
  </AuthorizationGate>
);
