import {
  createExternalRouteRef,
  createRouteRef,
  createSubRouteRef,
} from '@backstage/core-plugin-api';

// internal routes
export const rootRouteRef = createRouteRef({
  id: 'confidence-root',
});

export const workflowRouteRef = createSubRouteRef({
  id: 'workflow',
  path: '/:workflow',
  parent: rootRouteRef,
});

export const workflowInstanceRouteRef = createSubRouteRef({
  id: 'workflow-instance',
  path: '/:workflow/:id',
  parent: rootRouteRef,
});

// external routes
export const metricRouteRef = createExternalRouteRef({
  id: 'metric',
  params: ['id'],
  optional: true,
});
export const metricsRouteRef = createExternalRouteRef({
  id: 'metrics',
  optional: true,
});
export const entitiesRouteRef = createExternalRouteRef({
  id: 'entities',
  optional: true,
});
export const entityRouteRef = createExternalRouteRef({
  id: 'entity',
  params: ['id'],
  optional: true,
});
export const assignmentTableRouteRef = createExternalRouteRef({
  id: 'assignmentTable',
  params: ['id'],
  optional: true,
});
export const assignmentTablesCreateRouteRef = createExternalRouteRef({
  id: 'assignment-tables-create',
  optional: true,
});
export const factTableRouteRef = createExternalRouteRef({
  id: 'factTable',
  params: ['id'],
  optional: true,
});
export const flagRouteRef = createExternalRouteRef({
  id: 'flag',
  params: ['id'],
  optional: true,
});
export const flagsRouteRef = createExternalRouteRef({
  id: 'flags',
  optional: true,
});
