import React from 'react';

import {
  ListItemSecondaryAction,
  ListItemSecondaryActionProps,
  Theme,
  createStyles,
  withStyles,
} from '@material-ui/core';

const styles = (theme: Theme) =>
  createStyles({
    gutters: {},
    root: {
      display: 'flex',
      alignItems: 'center',
      gap: theme.spacing(0.5),
      '&:not($gutters)': {
        right: theme.spacing(2),
      },
    },
  });

export interface SchemaEntryActionsProps extends ListItemSecondaryActionProps {}

const SchemaEntryActions = function SchemaEntryActions(
  props: SchemaEntryActionsProps,
) {
  return <ListItemSecondaryAction {...props} />;
};

export default withStyles(styles)(SchemaEntryActions);
