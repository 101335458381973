import React from 'react';
import { useIntersection } from 'react-use';

import {
  Paper,
  TableContainer,
  TableContainerProps,
  TableHead,
  TableHeadProps,
  makeStyles,
} from '@material-ui/core';
import { Alert } from '@material-ui/lab';

import { LoadingOverlay } from './LoadingOverlay';

const useTableContainerStyles = makeStyles({
  wrapper: {
    position: 'relative',
  },
  tableContainer: {
    display: 'initial',
    '& > table': {
      borderCollapse: 'unset',
    },
  },
});

const useTableHeadStyles = makeStyles(theme => ({
  tableHeader: ({ topOffset }: { topOffset: number }) => ({
    position: 'sticky',
    top: topOffset,
    zIndex: 1,
    background: theme.palette.background.default,
  }),
}));

export const StickyTableContainer = ({
  children,
  loading = false,
  error,
}: TableContainerProps & { loading?: boolean; error?: Error | null }) => {
  const classes = useTableContainerStyles();
  const intersectionRef = React.useRef(null);
  const intersection = useIntersection(intersectionRef, {});

  return (
    <Paper
      className={classes.wrapper}
      ref={intersectionRef}
      variant="elevation"
      elevation={0}
    >
      <LoadingOverlay
        style={{
          top: 50,
        }}
        loading={loading}
        height={intersection?.intersectionRect.height}
      />
      {error && <Alert severity="error">{error.message}</Alert>}
      <TableContainer component={Paper} className={classes.tableContainer}>
        {children}
      </TableContainer>
    </Paper>
  );
};

export const StickyTableHead = ({
  children,
  topOffset = 0,
}: TableHeadProps & { topOffset?: number }) => {
  const classes = useTableHeadStyles({ topOffset });
  return <TableHead className={classes.tableHeader}>{children}</TableHead>;
};
