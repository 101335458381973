import * as React from 'react';

import { Typography } from '@material-ui/core';

import {
  MetricsTableErrorFragment,
  MetricsV1TableState,
} from '@spotify-confidence/plugin-graphql';

import {
  StatusError,
  StatusOK,
  StatusPending,
  StatusWarning,
} from '@backstage/core-components';

export const tableStateLabel = (status: MetricsV1TableState) => {
  switch (status) {
    case MetricsV1TableState.TableStateActive:
      return 'Active';
    case MetricsV1TableState.TableStateFailed:
      return 'Failed';
    case MetricsV1TableState.TableStateCreating:
      return 'Creating';
    case MetricsV1TableState.TableStateUpdating:
      return 'Updating';
    default:
      return 'Unknown';
  }
};

export const formatTableError = (
  error: Maybe<MetricsTableErrorFragment> | undefined,
): string | undefined => {
  if (error === undefined || error === null) {
    return undefined;
  }

  if (
    (error.message === null || error.message === '') &&
    error.details.length <= 0
  ) {
    return 'Unknown error occurred';
  }

  if (error.message !== null && error.details.length <= 0) {
    return error.message;
  }

  return `${error.message}:\n${error.details.join('\n')}`;
};

function tableStateIcon(status: MetricsV1TableState) {
  switch (status) {
    case MetricsV1TableState.TableStateActive:
      return StatusOK;
    case MetricsV1TableState.TableStateFailed:
      return StatusError;
    case MetricsV1TableState.TableStateCreating:
      return StatusPending;
    case MetricsV1TableState.TableStateUpdating:
      return StatusPending;
    default:
      return StatusWarning;
  }
}

export const TableState = ({
  status,
  error,
}: {
  status: MetricsV1TableState;
  error: Maybe<MetricsTableErrorFragment> | undefined;
}) => {
  const Status = tableStateIcon(status);
  return (
    <Typography title={formatTableError(error)} noWrap component="div">
      <Status>
        <Typography variant="body2" component="span">
          {tableStateLabel(status)}
        </Typography>
      </Status>
    </Typography>
  );
};
