import { isEqual } from 'lodash';

import { ActiveFilter } from './FilterListProvider';
import {
  FilterState,
  MultipleNominalOperator,
  QueryRuleDefinition,
  QueryRuleValue,
  SingleNominalOperator,
} from './types';

export const getDefaultFilters = (
  filters: QueryRuleDefinition[],
): QueryRuleValue[] => {
  const defaultFilters: QueryRuleValue[] = filters
    .filter(f => f.defaultValue)
    .map(f => ({
      id: f.id,
      operator: f.operators[0],
      value: f.defaultValue,
    }));
  return defaultFilters;
};

export const getFilterString = (
  state: FilterState,
  activeFilters: ActiveFilter[],
  searchField: string,
): string => {
  const _filters = [];
  if (state.searchQuery !== '') {
    _filters.push(`${searchField}:*${state.searchQuery}*`);
  }
  activeFilters.forEach(a => {
    const transform = a.definition?.valueTransformer
      ? a.definition?.valueTransformer
      : (s: string) => s;
    if (!a.value?.value) return;
    if (a.value.operator === MultipleNominalOperator.IS_ANY_OF) {
      if (a.value.value.length !== 0) {
        _filters.push(
          `(${a.value.value
            .map((v: any) => `${a.value.id}:${transform(v.toString())}`)
            .join(' OR ')})^0`,
        );
      }
    } else if (a.value.operator === SingleNominalOperator.IS) {
      _filters.push(`${a.value.id}:${transform(a.value.value)}^0`);
    }
    return;
  });
  return _filters.join(' AND ');
};

export const isDefaultFilters = (
  state: FilterState,
  filters: QueryRuleDefinition[],
) => {
  const defaultFilters = getDefaultFilters(filters);
  return isEqual(
    {
      searchQuery: '',
      filters: defaultFilters,
    },
    state,
  );
};
