import React from 'react';

import { Typography } from '@material-ui/core';

import { CodeSnippet } from '../../CodeSnippet/CodeSnippet';
import { f } from '../../CodeSnippet/format';

export const GoClientSnippet = () => {
  return (
    <>
      <Typography variant="body1" gutterBottom>
        Let's track a "tutorial-event" event from your App and make sure it gets
        correctly published to our backend. In this particular example, we are
        waiting for the track event to be sent.
      </Typography>
      <CodeSnippet
        language="go"
        showLineNumbers
        text={f(`
wg := confidence.Track(context.Background(), "tutorial-event", map[string]interface{}{})
wg.Wait()
  `)}
        showCopyCodeButton
      />
      <Typography variant="body1" gutterBottom>
        Alternatively, we can send a Track event without waiting.
      </Typography>
      <CodeSnippet
        language="go"
        showLineNumbers
        text={f(`
confidence.Track(context.Background(), "tutorial-event", map[string]interface{}{})
  `)}
        showCopyCodeButton
      />
    </>
  );
};
