import React from 'react';
import { useAsyncFn, useDebounce } from 'react-use';

import { CircularProgress, TextField } from '@material-ui/core';

import { AccountDetailsState } from './CreateAccountForm';
import { BillingDetailsTaxType } from './hooks/useCreateAccount';

export const useTaxValidation = (
  taxType: BillingDetailsTaxType,
  country: string,
  value: string,
) => {
  const [{ loading, value: isValid }, check] = useAsyncFn(async () => {
    const response = await fetch(
      `https://onboarding.confidence.dev/v1/taxId:validate?tax_type=${taxType}&tax_id=${value}`,
      {
        method: 'post',
      },
    );
    const result = await response.json();
    return Boolean(result.valid);
  }, [taxType, value]);

  useDebounce(
    () => {
      if (taxType && value) {
        check();
      }
    },
    400,
    [value, taxType],
  );

  const getHelper = (): string => {
    if (value.length === 0) return ' ';
    if (taxType === BillingDetailsTaxType.GbVat && !value.startsWith('GB')) {
      return `The VAT number should start with GB`;
    } else if (
      taxType === BillingDetailsTaxType.EuVat &&
      !value.startsWith(country)
    ) {
      return `The VAT number should start with ${country}`;
    } else if (!loading && !isValid) {
      return `Invalid ${
        taxType === BillingDetailsTaxType.UsEin ? 'EIN number' : 'VAT number'
      } `;
    }
    return ' ';
  };

  const helperText = getHelper();
  return {
    isValid: isValid && helperText.trim().length === 0,
    loading,
    helperText,
  };
};
export const BillingInfo = ({
  value,
  onChange,
}: {
  value: AccountDetailsState['billingDetails'];
  onChange: (newData: Partial<AccountDetailsState['billingDetails']>) => void;
}) => {
  const [dirtyTaxId, setDirtyTaxId] = React.useState(false);
  const handleTaxBlur = () => {
    setDirtyTaxId(!!value.taxId.length);
  };

  const label =
    value.taxType === BillingDetailsTaxType.UsEin ? 'EIN number' : 'VAT number';

  const { isValid, loading, helperText } = useTaxValidation(
    value.taxType,
    value.address.country,
    value.taxId,
  );
  const taxError = !isValid && !loading;

  return (
    <>
      <TextField
        variant="outlined"
        label={label}
        placeholder="000000000"
        name="taxId"
        value={value.taxId ?? value.address.country}
        helperText={helperText}
        onBlur={handleTaxBlur}
        error={dirtyTaxId && taxError}
        required
        onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
          onChange({
            taxId: e.target.value,
          })
        }
        InputProps={{
          endAdornment: loading && <CircularProgress size="1rem" />,
        }}
        fullWidth
      />
    </>
  );
};
