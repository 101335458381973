import React from 'react';
import { useNavigate, useParams } from 'react-router-dom';

import {
  Box,
  Card,
  CardContent,
  CircularProgress,
  Collapse,
  Grid,
  TextField,
  Typography,
} from '@material-ui/core';
import { Alert } from '@material-ui/lab';

import {
  Breadcrumbs,
  DangerBox,
  DangerButton,
  DangerZone,
  InlineSave,
  PageLayout,
  SecretText,
} from '@spotify-confidence/core-react';
import {
  ApiClientFragment,
  getTypeOrNull,
  isType,
  useApiClientDetailsQuery,
  useDeleteApiClientMutation,
  useUpdateApiClientMutation,
} from '@spotify-confidence/plugin-graphql';
import { PermissionUtils } from '@spotify-confidence/plugin-permissions-react';
import { isEqual } from 'lodash';

import { CopyTextButton, Link } from '@backstage/core-components';
import { useRouteRef } from '@backstage/core-plugin-api';

import { apiClientsRouteRef, rootRouteRef } from '../../../routes';

export const ApiClient = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const adminRoute = useRouteRef(rootRouteRef);
  const apiClientsRoute = useRouteRef(apiClientsRouteRef);
  const name = `apiClients/${id}`;
  const [editApiClient, setEditApiClient] = React.useState<
    Pick<ApiClientFragment, 'displayName' | 'description'>
  >({
    displayName: '',
    description: '',
  });
  const { data, loading } = useApiClientDetailsQuery({
    variables: {
      name,
    },
    onCompleted: apiClientData => {
      if (isType(apiClientData.apiClient, 'IamV1ApiClient')) {
        setEditApiClient({
          displayName: apiClientData.apiClient?.displayName || '',
          description: apiClientData.apiClient?.description,
        });
      }
    },
  });

  const [mutate, { loading: isUpdating }] = useUpdateApiClientMutation();
  const [deleteApiClient, { loading: isDeleting, error: deleteError }] =
    useDeleteApiClientMutation({
      variables: {
        name: name,
      },
      update: cache => {
        cache.evict({
          id: cache.identify({
            __typename: 'IamV1ApiClient',
            name: name,
          }),
        });
      },
      onCompleted: () => {
        navigate(apiClientsRoute());
      },
    });

  const apiClient = getTypeOrNull(data?.apiClient, 'IamV1ApiClient');
  const allowed = PermissionUtils.hasRelation(apiClient, 'can_edit');

  const textChanged =
    !loading &&
    !isEqual(editApiClient, {
      displayName: apiClient?.displayName,
      description: apiClient?.description,
    });

  const reset = () => {
    setEditApiClient({
      displayName: apiClient?.displayName || '',
      description: apiClient?.description,
    });
  };
  const save = async () => {
    await mutate({
      variables: {
        updateMask: 'displayName,description',
        apiClient: {
          name: apiClient?.name,
          ...editApiClient,
        },
      },
    });
  };
  return (
    <PageLayout
      narrow
      isLoading={loading}
      title={apiClient?.displayName}
      headerBreadcrumbs={
        <Breadcrumbs>
          <Link to={adminRoute()}>Admin</Link>
          <Link to={apiClientsRoute()}>API Clients</Link>
          <Typography>{apiClient?.displayName}</Typography>
        </Breadcrumbs>
      }
    >
      <Card>
        <CardContent>
          {apiClient && (
            <Grid container spacing={2}>
              <Grid item md={12}>
                <Typography color="secondary" variant="overline">
                  Client ID
                </Typography>
                <Typography
                  display="block"
                  variant="body2"
                  gutterBottom
                  data-testid="api-client-id"
                >
                  {apiClient?.clientId}
                  <CopyTextButton text={apiClient?.clientId!} />
                </Typography>
              </Grid>
              <Grid item md={12}>
                <Typography color="secondary" variant="overline">
                  Client secret
                </Typography>
                <Typography
                  display="block"
                  variant="body2"
                  data-testid="api-client-secret"
                >
                  <SecretText
                    renderTrailing={visible =>
                      visible ? (
                        <CopyTextButton text={apiClient.clientSecret!} />
                      ) : null
                    }
                  >
                    {apiClient.clientSecret ?? ''}
                  </SecretText>
                </Typography>
              </Grid>
            </Grid>
          )}
        </CardContent>
      </Card>
      <Box mt={3}>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <TextField
              label="Display name"
              variant="outlined"
              name="name"
              disabled={!allowed}
              value={editApiClient.displayName}
              onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                setEditApiClient(v => ({
                  ...v,
                  displayName: e.target.value,
                }))
              }
              fullWidth
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              label="Description "
              name="description"
              variant="outlined"
              disabled={!allowed}
              value={editApiClient.description}
              onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                setEditApiClient(v => ({
                  ...v,
                  description: e.target.value,
                }))
              }
              fullWidth
            />
          </Grid>
        </Grid>
      </Box>

      <Collapse in={textChanged}>
        <Box textAlign="right" paddingTop={2}>
          <InlineSave
            onSave={save}
            saving={isUpdating}
            onCancel={reset}
            variant="text"
          />
        </Box>
      </Collapse>
      <Box my={3}>
        {deleteError && <Alert severity="error">{deleteError.message}</Alert>}

        <DangerZone>
          <DangerBox>
            <strong>Delete API Client</strong>
            <DangerButton
              variant="contained"
              disabled={isDeleting || loading || !allowed}
              color="inherit"
              endIcon={isDeleting && <CircularProgress size={14} />}
              onClick={() => deleteApiClient()}
            >
              Delete
            </DangerButton>
          </DangerBox>
        </DangerZone>
      </Box>
    </PageLayout>
  );
};
