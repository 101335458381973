import * as React from 'react';

import {
  Box,
  Step as MuiStep,
  StepButton as MuiStepButton,
  StepConnector as MuiStepConnector,
  StepLabel as MuiStepLabel,
  Stepper as MuiStepper,
  Theme,
  makeStyles,
  useMediaQuery,
} from '@material-ui/core';

import { HEADER_HEIGHT } from './Header';
import { Step } from './Step';
import { StepContext } from './StepContext';
import { useStepperContext } from './StepperContext';

const useStyles = makeStyles(theme => ({
  stepperWrapper: {
    gridArea: 'stepper',
    padding: '1rem',
    borderColor: theme.palette.divider,
    position: 'relative',
    [theme.breakpoints.up('md')]: {
      borderRight: `1px solid ${theme.palette.divider}`,
    },
  },
  stepper: {
    flexWrap: 'wrap',
    border: 'none',
    [theme.breakpoints.up('md')]: {
      flexWrap: 'initial',
      position: 'sticky',
      top: `calc(${HEADER_HEIGHT}px + ${theme.spacing(3)}px)`,
    },
  },
  verticalStep: {
    borderColor: theme.palette.divider,
  },
}));

type StepProps = {
  label: string;
  canNext?: boolean;
  content: React.ReactElement<typeof Step>;
};

type StepperProps = {
  steps: StepProps[];
  onComplete: () => Promise<void>;
};

export function Stepper({ steps }: StepperProps) {
  const classes = useStyles();
  const { activeStep, setActiveStep } = useStepperContext();

  const isSmallScreen = useMediaQuery((theme: Theme) =>
    theme.breakpoints.down('sm'),
  );
  const orientation = isSmallScreen ? 'horizontal' : 'vertical';

  return (
    <>
      <Box className={classes.stepperWrapper}>
        <MuiStepper
          activeStep={activeStep}
          orientation={orientation}
          className={classes.stepper}
          connector={
            <MuiStepConnector classes={{ line: classes.verticalStep }} />
          }
        >
          {steps.map(({ label, canNext }, index) => {
            const canGoToStep =
              index === 0 || steps[index - 1].canNext !== false;
            return (
              <MuiStep key={label}>
                <MuiStepButton
                  onClick={() => {
                    if (canGoToStep) {
                      setActiveStep(index);
                    }
                  }}
                  disabled={!canGoToStep}
                  completed={canGoToStep && canNext !== false}
                >
                  <MuiStepLabel>{label}</MuiStepLabel>
                </MuiStepButton>
              </MuiStep>
            );
          })}
        </MuiStepper>
      </Box>
      <StepContext.Provider
        value={{
          canNext: steps[activeStep].canNext,
        }}
      >
        {steps[activeStep].content}
      </StepContext.Provider>
    </>
  );
}
