import React, { PropsWithChildren } from 'react';
import { Link } from 'react-router-dom';

import {
  Card,
  CardActionArea,
  CardContent,
  Grid,
  Typography,
  makeStyles,
  styled,
} from '@material-ui/core';

import { LoadingOverlay, PageLayout } from '@spotify-confidence/core-react';
import {
  getTypeOrNull,
  useCurrentAccountInfoQuery,
} from '@spotify-confidence/plugin-graphql';

const useStyles = makeStyles({
  card: {
    height: '100%',
  },
  actionArea: {
    height: '100%',
  },
});

function AdminPageCategory({
  title,
  children,
}: PropsWithChildren<{ title: string }>) {
  return (
    <section>
      <Typography variant="h5" gutterBottom>
        {title}
      </Typography>
      <Grid container spacing={2}>
        {children}
      </Grid>
    </section>
  );
}

function AdminPageItem({
  href,
  disabled,
  children,
}: PropsWithChildren<{ href: string; disabled?: boolean }>) {
  const classes = useStyles();

  return (
    <Grid item xs={12} sm={12} md={6} lg={4}>
      <Card className={classes.card}>
        <CardActionArea
          component={Link}
          to={href}
          disabled={disabled}
          className={classes.actionArea}
        >
          <CardContent>{children}</CardContent>
        </CardActionArea>
      </Card>
    </Grid>
  );
}

const AdminPageItemTitle = ({ children }: { children?: React.ReactNode }) => (
  <Typography variant="h6">{children}</Typography>
);

const AdminPageItemDescription = ({
  children,
}: {
  children?: React.ReactNode;
}) => (
  <Typography variant="body2" color="textSecondary">
    {children}
  </Typography>
);

const Container = styled('div')(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  gridGap: theme.spacing(4),
}));

export function AdminPage() {
  const { data, loading } = useCurrentAccountInfoQuery();

  const currentAccountData = getTypeOrNull(
    data?.currentAccount,
    'AdminV1AccountInfo',
  );

  const isTotalConfidence = currentAccountData?.mode === 'TOTAL_CONFIDENCE';

  if (loading) {
    return <LoadingOverlay loading />;
  }

  return (
    <PageLayout narrow title="Admin">
      <Container>
        {/*  IAM */}
        <AdminPageCategory title="Identity & access management">
          <AdminPageItem href="/admin/policies">
            <AdminPageItemTitle>Policies</AdminPageItemTitle>
            <AdminPageItemDescription>
              Manage permission policies
            </AdminPageItemDescription>
          </AdminPageItem>

          <AdminPageItem href="/admin/users">
            <AdminPageItemTitle>Users</AdminPageItemTitle>
            <AdminPageItemDescription>
              Manage users and invitations
            </AdminPageItemDescription>
          </AdminPageItem>

          <AdminPageItem href="/admin/groups">
            <AdminPageItemTitle>Groups</AdminPageItemTitle>
            <AdminPageItemDescription>
              Manage groups of users
            </AdminPageItemDescription>
          </AdminPageItem>

          <AdminPageItem href="/admin/clients">
            <AdminPageItemTitle>Clients</AdminPageItemTitle>
            <AdminPageItemDescription>
              Manage clients for using flags and sending events
            </AdminPageItemDescription>
          </AdminPageItem>

          <AdminPageItem href="/admin/api-clients">
            <AdminPageItemTitle>API clients</AdminPageItemTitle>
            <AdminPageItemDescription>
              Manage clients for talking to the Confidence management APIs
            </AdminPageItemDescription>
          </AdminPageItem>

          <AdminPageItem href="/admin/oauth-apps">
            <AdminPageItemTitle>OAuth apps</AdminPageItemTitle>
            <AdminPageItemDescription>
              Manage apps using OAuth flows to authenticate on behalf of users
            </AdminPageItemDescription>
          </AdminPageItem>
        </AdminPageCategory>

        {/* Data modelling */}
        <AdminPageCategory title="Data modelling">
          <AdminPageItem href="/metrics/entities">
            <AdminPageItemTitle>Entities</AdminPageItemTitle>
            <AdminPageItemDescription>
              Manage the entities you experiment on
            </AdminPageItemDescription>
          </AdminPageItem>
          <AdminPageItem href="/metrics/fact-tables">
            <AdminPageItemTitle>Fact tables</AdminPageItemTitle>
            <AdminPageItemDescription>
              Manage data sources for metrics
            </AdminPageItemDescription>
          </AdminPageItem>
          <AdminPageItem href="/metrics/dimension-tables">
            <AdminPageItemTitle>Dimension tables</AdminPageItemTitle>
            <AdminPageItemDescription>
              Manage data sources for dimensions for entities
            </AdminPageItemDescription>
          </AdminPageItem>
          <AdminPageItem href="/metrics/assignment-tables">
            <AdminPageItemTitle>Assignment tables</AdminPageItemTitle>
            <AdminPageItemDescription>
              Manage data sources for experiment assignments
            </AdminPageItemDescription>
          </AdminPageItem>
        </AdminPageCategory>

        {/* Connections */}
        {!isTotalConfidence && (
          <>
            <AdminPageCategory title="Connections">
              <AdminPageItem href="/admin/metrics-datawarehouse">
                <AdminPageItemTitle>Metric data warehouse</AdminPageItemTitle>
                <AdminPageItemDescription>
                  Configure data warehouse connection to calculate metrics
                </AdminPageItemDescription>
              </AdminPageItem>
              <AdminPageItem href="/admin/connections/flag-applied">
                <AdminPageItemTitle>
                  Flag applied connections
                </AdminPageItemTitle>
                <AdminPageItemDescription>
                  Configure data warehouse connection to write flag assignments
                </AdminPageItemDescription>
              </AdminPageItem>
              <AdminPageItem href="/admin/connections/event">
                <AdminPageItemTitle>Event connections</AdminPageItemTitle>
                <AdminPageItemDescription>
                  Configure data warehouse connection to write events
                </AdminPageItemDescription>
              </AdminPageItem>
            </AdminPageCategory>
          </>
        )}

        {/* Billing */}
        <AdminPageCategory title="Billing">
          <AdminPageItem href="/admin/reports">
            <AdminPageItemTitle>Usage reports</AdminPageItemTitle>
            <AdminPageItemDescription>
              See how you use Confidence
            </AdminPageItemDescription>
          </AdminPageItem>
          {isTotalConfidence && (
            <AdminPageItem href="/admin/subscription">
              <AdminPageItemTitle>Subscription</AdminPageItemTitle>
              <AdminPageItemDescription>
                Update billing information and manage your subscription
              </AdminPageItemDescription>
            </AdminPageItem>
          )}
        </AdminPageCategory>
      </Container>
    </PageLayout>
  );
}
