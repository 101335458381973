import React from 'react';

import { useCheckWorkflowInstancePermissionsMutation } from '@spotify-confidence/plugin-graphql';

export type CheckWorkflowInstancePermissionOptions = {
  workflowInstanceId?: string;
};

export const useCheckWorkflowInstancePermissions = ({
  workflowInstanceId,
}: CheckWorkflowInstancePermissionOptions) => {
  const [check, { data, loading, error }] =
    useCheckWorkflowInstancePermissionsMutation();

  React.useEffect(() => {
    if (workflowInstanceId) {
      check({
        variables: {
          workflowInstance: workflowInstanceId,
        },
      });
    }
  }, [check, workflowInstanceId]);

  return {
    data,
    loading,
    error,
  };
};
