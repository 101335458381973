import { useAsyncFn, useDebounce } from 'react-use';

import { BillingDetails } from './useCreateAccount';

const SUPPORTED_COUNTRIES = ['US'];

const checkRequiredFields = (address: BillingDetails['address']): boolean => {
  return [
    address.line1,
    address.city,
    address.postalCode,
    address.country,
  ].every(v => (v ?? '').length !== 0);
};

export const useAddressValidation = (value: BillingDetails['address']) => {
  const [{ loading, value: isValid, error }, check] = useAsyncFn(async () => {
    const response = await fetch(
      `https://onboarding.confidence.dev/v1/address:validate`,
      {
        method: 'post',
        body: JSON.stringify(value),
      },
    );
    const result = await response.json();

    if (response.status === 400) {
      throw new Error(result.message ?? 'Could not validate address');
    }
    return Boolean(result.acceptable);
  }, [value]);

  useDebounce(
    () => {
      if (
        SUPPORTED_COUNTRIES.includes(value.country) &&
        checkRequiredFields(value)
      ) {
        check();
      }
    },
    400,
    [value],
  );

  return {
    // eslint-disable-next-line no-nested-ternary
    isValid: SUPPORTED_COUNTRIES.includes(value.country)
      ? isValid && !error
        ? true
        : false
      : checkRequiredFields(value),
    loading,
    error,
  };
};
