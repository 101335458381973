/* From https://ghe.spotify.net/konfidens/workflows */
import { MetricScheduleFragment } from '@spotify-confidence/plugin-graphql';

import { useMetricsModuleConfigState } from './useMetricsModuleConfigState';

export enum MetricKind {
  SUCCESS = 'SUCCESS',
  GUARDRAIL = 'GUARDRAIL',
}

export type SuccessMetric = {
  // in the range 0 through 1.0
  metricKind?: MetricKind;
  minimumDetectableEffect?: number;
};

export type GuardrailMetric = {
  metricKind?: MetricKind;
  nonInferiorityMargin?: number;
};

export enum MetricDirection {
  METRIC_DIRECTION_UNSPECIFIED = 'METRIC_DIRECTION_UNSPECIFIED',
  INCREASE = 'INCREASE',
  DECREASE = 'DECREASE',
}

export type Metric = {
  metric: string; // metrics.konfidens.services/Metric"
  metricRole: SuccessMetric | GuardrailMetric;
  preferredDirection: MetricDirection;
  schedule: string; // "metrics.konfidens.services/ScheduledMetricCalculation"
  calculation?: string; // "metrics.konfidens.services/MetricCalculation"
  surface?: string;
  surfaceOnly?: boolean;
};

export enum BucketingTemporalUnit {
  BUCKETING_TEMPORAL_UNIT_UNSPECIFIED = 'BUCKETING_TEMPORAL_UNIT_UNSPECIFIED',
  // MINUTES = "MINUTES", // Uncomment if we want to support this
  HOURS = 'HOURS',
  DAYS = 'DAYS',
  // WEEKS = 4,  // Uncomment if we want to support this
}

export type MetricsData = {
  entity?: string; // metrics.konfidens.services/Entity
  assignmentTable?: string; // metrics.konfidens.services/AssignmentTable

  metrics: Metric[];

  exposureFilter?: {
    factTable?: string | null;
  };

  /*
      For sequential experiments, this is the duration for which metric data is collected.
      For all experiments including fixed-horizon, this field is also used to determine
      frequency of some of the validation checks (in particular, tanking of metrics).
      It is an enum to avoid configurations that are unpractical in terms of
      visualization or metric calculation
    */
  bucket: BucketingTemporalUnit;

  // output only
  exposureTable?: string; // metrics.konfidens.services/ExposureTable
  exposureMetric?: string; // metrics.konfidens.services/Metric
  exposureTableSchedule?: string; // metrics.konfidens.services/ScheduledExposureCalculation
  exposureMetricSchedule?: string; // metrics.konfidens.services/ScheduledMetricCalculation
  exposureMetricCalculation?: string; // metrics.konfidens.services/MetricCalculation
  exposureCalculation?: string; // metrics.konfidens.services/ExposureCalculation
};

/* For edit dialogs */
export type MetricType = 'success' | 'guardrail';

export type MetricInput = Partial<Omit<Metric, 'metricRole'>> & {
  metricRole?: Partial<Metric['metricRole']>;
};

export type MetricsModuleConfigStateType = ReturnType<
  typeof useMetricsModuleConfigState
>;
export type MetricsModuleConfigContextType = MetricsModuleConfigStateType & {
  metricsWithSchedule: (Metric & { scheduleData?: MetricScheduleFragment })[];
  handleAddMetric: (
    props?: Partial<MetricsModuleConfiguratorDialogProps>,
    metricType?: MetricType,
  ) => void;
  guardrailOnly?: boolean;
};

export type MetricsModuleConfiguratorDialogStep = 'config' | 'metric';

export type MetricsModuleConfiguratorDialogProps = Pick<
  MetricsModuleConfigContextType,
  | 'addMetric'
  | 'saveConfig'
  | 'entity'
  | 'assignmentTable'
  | 'existingMetrics'
  | 'metricsData'
> &
  Partial<{
    currentStepDefault: MetricsModuleConfiguratorDialogStep;
    metricType?: MetricType;
  }>;

export type MetricsConfig = Pick<
  MetricsData,
  'assignmentTable' | 'entity' | 'bucket' | 'exposureFilter'
>;
