import React from 'react';

import { useCheckWorkflowInstancePermissions } from '../useCheckWorkflowInstancePermissions';

export type WorkflowPermissionContextType = ReturnType<
  typeof useCheckWorkflowInstancePermissions
>;

type WorkflowPermissionContextProps = React.PropsWithChildren<{
  name?: string;
}>;

const defaultValue = {
  data: undefined,
  loading: false,
  error: undefined,
};

const WorkflowPermissionContext =
  React.createContext<WorkflowPermissionContextType>(defaultValue);

export const WorkflowPermissionProvider = ({
  name,
  children,
}: WorkflowPermissionContextProps) => {
  const workflowPermissions = useCheckWorkflowInstancePermissions({
    workflowInstanceId: name,
  });
  return (
    <WorkflowPermissionContext.Provider value={workflowPermissions}>
      {children}
    </WorkflowPermissionContext.Provider>
  );
};

export const useWorkflowPermissionContext = () =>
  React.useContext(WorkflowPermissionContext);
