import * as React from 'react';

import {
  Maybe,
  PendingTransitionFragment,
  WorkflowInstanceCheckFragment,
} from '@spotify-confidence/plugin-graphql';
import _ from 'lodash';

import * as checkUtils from './checks';
import { hasFailingSteps } from './pendingTransitionUtils';
import { WorkflowInstanceStateType } from './types';

type Severity = 'error' | 'warning';
export type Status =
  | 'ok'
  | 'warning'
  | 'error'
  | 'running'
  | 'aborted'
  | 'pending';

type UseWorkflowInstanceStateOptions = React.PropsWithChildren<{
  state?: WorkflowInstanceStateType;
  checks?: WorkflowInstanceCheckFragment[];
  pendingTransition?: Maybe<PendingTransitionFragment>;
}>;

export const useWorkflowInstanceState = ({
  state = 'draft',
  checks = [],
  pendingTransition,
}: UseWorkflowInstanceStateOptions = {}) => {
  const checkState = checkUtils.getCheckState(checks);
  const lowerCaseState = _.toLower(state);
  const severity: Severity = ['live'].includes(lowerCaseState)
    ? 'error'
    : 'warning';

  let label = _.startCase(lowerCaseState);
  let status: Status = 'pending';

  if (!['archived', 'ended'].includes(lowerCaseState)) {
    if (pendingTransition) {
      if (hasFailingSteps(pendingTransition)) {
        label = `Failed to ${pendingTransition.transitionAction}`;
      } else {
        label = `${_.upperFirst(
          pendingTransition.transitionAction,
        )} in progress`;
      }
    } else if (checkUtils.checkFailed(checkState)) {
      label += ' w/ failing checks';
    }
  }

  if (pendingTransition && hasFailingSteps(pendingTransition)) {
    status = severity === 'warning' ? 'warning' : 'error';
  } else if (['archived', 'ended'].includes(lowerCaseState)) {
    status = 'aborted';
  } else if (['draft'].includes(lowerCaseState)) {
    if (checkUtils.checkFailed(checkState)) {
      status = 'warning';
    } else {
      status = 'running';
    }
  } else if (['live', 'active'].includes(lowerCaseState)) {
    if (checkUtils.checkFailed(checkState)) {
      status = 'error';
    } else {
      status = 'ok';
    }
  } else if (checkUtils.checkFailed(checkState)) {
    status = 'warning';
  }

  return { status, label };
};
