import React from 'react';

import { ConfirmDialog } from '@spotify-confidence/core-react';
import {
  FlagsAdminV1SegmentState,
  RuleFragment,
  getTypeOrNull,
  useArchiveSegmentMutation,
  useDeleteRuleMutation,
} from '@spotify-confidence/plugin-graphql';

export type DeleteRuleDialogProps = {
  rule: RuleFragment;
};

export const DeleteRuleDialog = ({ rule }: DeleteRuleDialogProps) => {
  const segment = getTypeOrNull(rule.segment, 'FlagsAdminV1Segment');
  const [archiveSegment] = useArchiveSegmentMutation({
    variables: {
      name: segment?.name ?? '',
    },
  });
  const [deleteRule] = useDeleteRuleMutation({
    variables: { name: rule.name },
    update: cache => {
      cache.evict({
        id: cache.identify({
          __typename: 'FlagsAdminV1FlagRule',
          name: rule.name,
        }),
      });
    },
  });

  const onConfirm = async () => {
    await deleteRule();
    const uiCreated = segment?.labels.some(
      l => l.key === 'uiCreated' && l.value === 'true',
    );
    if (uiCreated && segment?.state === FlagsAdminV1SegmentState.Allocated)
      await archiveSegment();
  };

  return (
    <ConfirmDialog
      expected="delete"
      title="Delete Rule"
      irreversible
      onConfirm={onConfirm}
    >
      Deleting a rule may have consequences on running experiments. To confirm
      this action, please type "delete".
    </ConfirmDialog>
  );
};
