import React from 'react';

import {
  Table,
  TableBody,
  TableCell,
  TableRow,
  Typography,
} from '@material-ui/core';

import {
  ACTION_BAR_HEIGHT,
  ConfidenceWebsiteLink,
  FilterListEmptyState,
  Highlight,
  ResourceTableRow,
  StickyTableContainer,
  StickyTableHead,
  extractLastNameComponent,
  useListFilter,
} from '@spotify-confidence/core-react';

import { Link } from '@backstage/core-components';
import { useRouteRef } from '@backstage/core-plugin-api';

import { useSurfaces } from '../../hooks/useSurfaces';
import { surfaceRouteRef } from '../../routes';

/**
 * Props for the surface list component. See {@link SurfaceList}.
 */
type SurfaceListProps = {
  onCreate?: () => void;
  topOffset?: number;
};

/**
 * Presentation component for a list of surfaces.
 */
export const SurfaceList = ({
  onCreate,
  topOffset = ACTION_BAR_HEIGHT,
}: SurfaceListProps) => {
  const surfaceRoute = useRouteRef(surfaceRouteRef);
  const { searchQuery } = useListFilter();

  const { surfaces, loading, error } = useSurfaces();

  if (surfaces.length === 0 && !loading) {
    return (
      <FilterListEmptyState
        type="surface"
        error={error}
        description={
          <>
            Use surfaces to organize your experiments by user-facing surfaces.{' '}
            <ConfidenceWebsiteLink route="/docs/surfaces" underline="always">
              Learn more about surfaces.
            </ConfidenceWebsiteLink>
          </>
        }
        onCreate={onCreate}
      />
    );
  }

  return (
    <StickyTableContainer loading={loading} error={error}>
      <Table>
        <StickyTableHead topOffset={topOffset}>
          <TableRow>
            <TableCell>Name</TableCell>
            <TableCell>Description</TableCell>
          </TableRow>
        </StickyTableHead>
        <TableBody>
          {surfaces.map(surface => (
            <ResourceTableRow
              key={surface.name}
              routeRef={surfaceRouteRef}
              name={surface.name}
            >
              <TableCell>
                <Link
                  to={surfaceRoute({
                    id: extractLastNameComponent(surface.name)!,
                  })}
                >
                  <Highlight highlight={searchQuery}>
                    {surface.displayName}
                  </Highlight>
                </Link>
              </TableCell>
              <TableCell>
                <Typography noWrap variant="body1" color="textSecondary">
                  <Highlight highlight={searchQuery}>
                    {surface.description ?? ''}
                  </Highlight>
                </Typography>
              </TableCell>
            </ResourceTableRow>
          ))}
        </TableBody>
      </Table>
    </StickyTableContainer>
  );
};
