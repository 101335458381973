import React from 'react';

import { Box, Typography, TypographyProps } from '@material-ui/core';

import {
  DateUtils,
  NumberUtils,
  Numeral,
} from '@spotify-confidence/core-react';
import _ from 'lodash';

import { BucketingTemporalUnit } from '../../../../../MetricsModule';
import { getGroupsFromGroupComparisonId } from '../../utils';
import { InlineTreatmentComparison } from '../InlineTreatmentComparison';
import { MetricName } from '../MetricName';
import * as tableUtils from '../tableUtils';
import { ResultData } from '../types';

type TooltipValueProps = {
  label: string;
  value?: number;
  suffix?: string;
  color?: TypographyProps['color'];
};

const TooltipValue = ({
  label,
  value,
  suffix,
  color = 'textSecondary',
}: TooltipValueProps) => {
  const prefix = value && value > 0 ? '+' : '';
  return (
    <Box display="flex" gridGap="2em" justifyContent="space-between">
      <Typography variant="caption" color={color}>
        {label}:
      </Typography>
      <Typography variant="caption">
        <Numeral
          prefix={prefix}
          value={value}
          formatter={NumberUtils.formatNumberFixed2}
          suffix={suffix}
        />
      </Typography>
    </Box>
  );
};

export const ChartTooltipDate = ({
  value,
  bucket = BucketingTemporalUnit.HOURS,
}: {
  value?: string | null;
  bucket?: BucketingTemporalUnit;
}) => {
  return (
    <Typography variant="subtitle2" gutterBottom>
      {value
        ? DateUtils.toDateString(
            value,
            bucket === BucketingTemporalUnit.DAYS
              ? tableUtils.DATE_FORMAT
              : tableUtils.DATETIME_FORMAT,
          )
        : '-'}
    </Typography>
  );
};

export const ChartTooltipNumber = ({
  label,
  value,
  showRelativeChanges,
}: {
  label: string;
  value?: number;
  showRelativeChanges?: boolean;
}) => {
  const suffix = showRelativeChanges ? '%' : '';
  return <TooltipValue label={label} value={value} suffix={suffix} />;
};

export const ChartTooltip = ({
  value,
  showRelativeChanges,
  bucket,
}: {
  value?: ResultData;
  showRelativeChanges?: boolean;
  bucket?: BucketingTemporalUnit;
}) => {
  if (value) {
    return (
      <>
        <Box marginBottom={2}>
          <MetricName name={value.metric} />
        </Box>
        <Box marginBottom={2}>
          <InlineTreatmentComparison
            {...getGroupsFromGroupComparisonId(value.comparison)}
          />
        </Box>
        <ChartTooltipDate value={value.result?.timeLabel} bucket={bucket} />
        <ChartTooltipNumber
          label="Observed difference"
          value={
            showRelativeChanges
              ? value.result?.estimateRel.estimate
              : value.result?.estimateAbs.estimate
          }
          showRelativeChanges={showRelativeChanges}
        />
        <ChartTooltipNumber
          label="Lower bound"
          value={
            showRelativeChanges
              ? value.result?.estimateRel.lower
              : value.result?.estimateAbs.lower
          }
          showRelativeChanges={showRelativeChanges}
        />
        <ChartTooltipNumber
          label="Upper bound"
          value={
            showRelativeChanges
              ? value.result?.estimateRel.upper
              : value.result?.estimateAbs.upper
          }
          showRelativeChanges={showRelativeChanges}
        />
        {!_.isNil(value.nim) && value.nim !== 0 && (
          <ChartTooltipNumber
            label="NIM"
            value={value.nim}
            showRelativeChanges
          />
        )}
      </>
    );
  }
  return null;
};
