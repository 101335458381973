import React from 'react';

import { Button, CircularProgress, TextField } from '@material-ui/core';
import { Alert } from '@material-ui/lab';

import { FormFieldsBox } from '@spotify-confidence/core-react';
import {
  ConnectorsV1FlagAppliedConnectionRedshiftFlagAppliedConnectionConfigInput as RedshiftInput,
  useValidateRedshiftFlagAppliedConnectionConfigMutation,
} from '@spotify-confidence/plugin-graphql';

import { BatchFileConfigForm } from '../BatchFileConfigForm';
import { S3BucketConfigForm } from '../S3BucketConfigForm';
import { RedshiftConfigForm } from './RedshiftConfigForm';
import { isRedshiftFlagAppliedInputValid } from './helpers';

type RedshiftFlagAppliedConnectionFormProps = {
  value: RedshiftInput;
  onChange: (conf: RedshiftInput) => void;
  disabled?: boolean;
};

export const RedshiftFlagAppliedConnectionForm = ({
  value,
  onChange,
  disabled,
}: RedshiftFlagAppliedConnectionFormProps) => {
  const [validate, validation] =
    useValidateRedshiftFlagAppliedConnectionConfigMutation({
      variables: {
        redshift: value,
      },
    });

  const canValidate = isRedshiftFlagAppliedInputValid(value);

  const handleConfigChange = (newValue: Partial<RedshiftInput>) => {
    onChange({
      ...value,
      ...newValue,
    });
  };

  const handleValidate = async () => {
    try {
      await validate();
    } catch (e) {
      //   We use the error from the validation object
    }
  };

  return (
    <FormFieldsBox>
      <RedshiftConfigForm
        disabled={disabled}
        value={value.redshiftConfig}
        onChange={newRedshiftConfig => {
          handleConfigChange({
            redshiftConfig: newRedshiftConfig,
          });
        }}
      />

      <TextField
        disabled={disabled}
        label="Table"
        variant="outlined"
        value={value.table}
        required
        fullWidth
        helperText="The name of the Redshift table to use for writing assignment data. The connector creates the table automatically when the first import is done."
        onChange={e => onChange({ ...value, table: e.target.value })}
      />

      <S3BucketConfigForm
        disabled={disabled}
        value={value.s3Config}
        onChange={newS3BucketConfig =>
          handleConfigChange({
            s3Config: newS3BucketConfig,
          })
        }
      />
      <BatchFileConfigForm
        disabled={disabled}
        value={value.batchFileConfig}
        onChange={newBatchFileConfig =>
          handleConfigChange({
            batchFileConfig: newBatchFileConfig,
          })
        }
      />
      <div>
        <Button
          size="small"
          variant="outlined"
          disabled={!canValidate || validation.loading}
          onClick={handleValidate}
          endIcon={validation.loading && <CircularProgress size="1em" />}
        >
          Validate
        </Button>
      </div>
      {validation.data && (
        <Alert severity="success">Validation successful!</Alert>
      )}
      {validation.error && (
        <Alert severity="error">{validation.error.message}</Alert>
      )}
    </FormFieldsBox>
  );
};
