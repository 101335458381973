import React from 'react';

import { Button, InputBase, MenuItem, makeStyles } from '@material-ui/core';
import AddCircleIcon from '@material-ui/icons/AddCircle';
import SearchIcon from '@material-ui/icons/Search';

import { capitalize } from 'lodash';

import useDebouncedInputProps from '../../../hooks/useDebouncedInputProps';
import { ContextMenu } from '../../ContextMenu';
import { useListFilter } from '../FilterListProvider';
import { FilterChip } from './FilterChip';

const useStyles = makeStyles(theme => {
  return {
    topContainer: {
      display: 'flex',
      gap: theme.spacing(2),
      alignItems: 'center',
      gridRow: 2,
      gridColumn: '1 / -1',
      [theme.breakpoints.up('sm')]: {
        gridRow: 1,
        gridColumn: 'auto',
      },
    },
    chipContainer: {
      display: 'flex',
      width: '100%',
      gap: theme.spacing(1),
      paddingTop: theme.spacing(2),
      paddingBottom: theme.spacing(1),
      gridRow: 3,
      gridColumn: '1 / -1',
      [theme.breakpoints.up('sm')]: {
        gridRow: 2,
      },
    },
    search: {
      display: 'flex',
      alignItems: 'center',
      gap: theme.spacing(1),
    },
    addButton: {
      color: theme.palette.secondary.main,
    },
    inputRoot: {
      backgroundColor: theme.palette.action.disabledBackground,
      padding: theme.spacing(1),
      borderRadius: theme.shape.borderRadius,
      alignItems: 'center',
    },
    inputMarginDense: {
      padding: 0,
    },
    icon: {
      marginLeft: theme.spacing(1),
      marginRight: theme.spacing(1),
    },
  };
});

export const FilterBar = () => {
  const classes = useStyles();

  const {
    searchQuery,
    filterOptions,
    activeFilters,
    setSearchQuery,
    addFilter,
  } = useListFilter();

  const debouncedInputProps = useDebouncedInputProps(
    searchQuery,
    setSearchQuery,
    300,
  );

  const canAddFilter = filterOptions.length !== 0;
  const hasActiveFilters = activeFilters.length !== 0;
  const showAddFilter = canAddFilter || hasActiveFilters;

  return (
    <>
      <div className={classes.topContainer}>
        <div className={classes.search}>
          <InputBase
            placeholder="Filter..."
            name="filter-bar"
            margin="dense"
            classes={{
              root: classes.inputRoot,
              inputMarginDense: classes.inputMarginDense,
            }}
            startAdornment={<SearchIcon className={classes.icon} />}
            inputProps={{ 'aria-label': 'search' }}
            {...debouncedInputProps}
          />
        </div>

        {showAddFilter && (
          <ContextMenu
            closeOnSelect
            renderButton={p => (
              <Button
                {...p}
                disabled={!canAddFilter}
                startIcon={<AddCircleIcon />}
                size="small"
                variant="text"
                className={classes.addButton}
              >
                Add filter
              </Button>
            )}
            renderMenu={() =>
              filterOptions.map(option => (
                <MenuItem
                  key={option.id}
                  divider
                  onClick={() => addFilter(option)}
                >
                  {option.displayName ?? capitalize(option.id)}
                </MenuItem>
              ))
            }
          />
        )}
      </div>

      <ActiveFilters />
    </>
  );
};

export function ActiveFilters() {
  const classes = useStyles();

  const { activeFilters } = useListFilter();
  const hasActiveFilters = activeFilters.length !== 0;

  if (!hasActiveFilters) return null;

  return (
    <div className={classes.chipContainer}>
      {activeFilters.map(filterField => (
        <FilterChip key={`filter-${filterField.value.id}`} {...filterField} />
      ))}
    </div>
  );
}
