export const hasTimestampError = (timestamp: string | undefined | null) => {
  if (timestamp === undefined || timestamp === null) {
    return false;
  }
  try {
    const date = new Date(timestamp);
    return isNaN(date.getTime());
  } catch (e) {
    return true;
  }
};

export const timestampToDate = (timestamp: string | undefined) => {
  if (timestamp === undefined || hasTimestampError(timestamp)) {
    return null;
  }

  return new Date(timestamp);
};
