import React from 'react';
import { useObservable } from 'react-use';

import { Duration, add } from 'date-fns';
import _ from 'lodash';

import { storageApiRef, useApi } from '@backstage/core-plugin-api';

import { useStorageKey } from './useStorageKey';

type DismissedEntries = {
  [id: string]: { dismissed: boolean; expiresAt?: number };
};

export function useDismissable() {
  const storage = useApi(storageApiRef);
  const key = useStorageKey('dismissable');
  const observed = useObservable(
    React.useMemo(() => storage.observe$<DismissedEntries>(key), [key]),
    storage.snapshot(key),
  );
  const dismissed = observed?.value || {};

  const isDismissed = React.useCallback(
    (dismissableId: string) => {
      return dismissed?.[dismissableId]?.dismissed;
    },
    [key, dismissed],
  );

  const dismiss = React.useCallback(
    (dismissableId: string | string[], expireIn?: Duration) => {
      const ids = _.isArray(dismissableId) ? dismissableId : [dismissableId];
      const toDismiss = ids.filter(id => !isDismissed(id));

      if (toDismiss.length > 0) {
        const newValue = {
          ...dismissed,
          ...Object.fromEntries(
            toDismiss.map(id => [
              id,
              {
                dismissed: true,
                expiresAt: expireIn
                  ? add(new Date(), expireIn).getTime()
                  : undefined,
              },
            ]),
          ),
        };
        storage.set(key, newValue);
      }
    },
    [key, dismissed],
  );

  const resetDismissal = React.useCallback(
    (dismissableId: string | string[]) => {
      const ids = _.isArray(dismissableId) ? dismissableId : [dismissableId];
      const toReset = ids.filter(isDismissed);
      if (toReset.length > 0) {
        storage.set(key, {
          ...dismissed,
          ...Object.fromEntries(
            toReset.map(id => [
              id,
              {
                dismissed: false,
              },
            ]),
          ),
        });
      }
    },
    [key, dismissed],
  );

  React.useEffect(() => {
    const currentTime = new Date().getTime();
    storage.set(
      key,
      Object.fromEntries(
        Object.entries(dismissed).filter(([_id, state]) => {
          return state.expiresAt ? state.expiresAt > currentTime : true;
        }),
      ),
    );
  }, []);

  return {
    dismiss,
    resetDismissal,
    isDismissed,
  };
}
