import React from 'react';

import {
  Box,
  DialogActions,
  DialogContent,
  FormControl,
  FormControlLabel,
  Radio,
  RadioGroup,
  Typography,
} from '@material-ui/core';

import {
  DialogBody,
  DialogHeader,
  FormSubmitButtons,
  useAlert,
  useDialog,
} from '@spotify-confidence/core-react';
import {
  FlagsAdminV1SegmentState,
  RuleFragment,
  VariantFragment,
  getTypeOrNull,
  isType,
  useAllocateSegmentMutation,
  useCreateMaterializedSegmentMutation,
  useCreateRuleMutation,
  useCreateSegmentMutation,
} from '@spotify-confidence/plugin-graphql';

import { toNewSegmentInput } from '../../../../segment/domain/segment.model';
import { ruleModel } from '../../../domain';
import { RuleType, ruleFragmentToInput } from '../../../domain/rule.model';
import { getRuleFormComponent } from '../../RuleForm';

export type Props = {
  variants: VariantFragment[];
  clients: string[];
  flag: string;
};

const RuleForm = ({
  variants,
  ruleType,
  onSave,
  clients,
  flagName,
}: {
  variants: VariantFragment[];
  ruleType: RuleType;
  onSave: (rule: RuleFragment) => Promise<void>;
  clients: string[];
  flagName: string;
}) => {
  const FormComponent = getRuleFormComponent(ruleType);

  return (
    <FormComponent
      title="Create Rule"
      variants={variants}
      rule={ruleModel.newRule(ruleType, flagName)}
      clients={clients}
      onSave={onSave}
    />
  );
};

const TypeDialog = ({ onSelect }: { onSelect: (type: RuleType) => void }) => {
  const [ruleType, setRuleType] = React.useState<RuleType | null>(null);
  const { closeDialog } = useDialog();

  return (
    <DialogBody>
      <DialogHeader
        title="Rule type"
        subTitle="Select the type of rule you want to create."
      />
      <DialogContent>
        <FormControl variant="standard" fullWidth>
          <RadioGroup
            aria-labelledby="demo-error-radios"
            name="quiz"
            value={ruleType}
            onChange={(_e, value) => setRuleType(value as RuleType)}
            style={{
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'space-between',
              width: '100%',
              gap: 16,
            }}
          >
            <FormControlLabel
              value="general"
              style={{ alignItems: 'start' }}
              control={<Radio />}
              label={
                <Box pt={0.5}>
                  <Typography variant="h6">General</Typography>
                  <Typography variant="body2" color="textSecondary">
                    Assign a variant, or variants, to an audience of users.
                    Filter the audience by attributes. Coordinate with other
                    rules.
                  </Typography>
                </Box>
              }
            />
            <FormControlLabel
              value="override"
              style={{ alignItems: 'start' }}
              control={<Radio />}
              label={
                <Box pt={0.5}>
                  <Typography variant="h6">Override</Typography>
                  <Typography variant="body2" color="textSecondary">
                    Force a specific variant for a set of users. Useful for
                    testing and debugging.
                  </Typography>
                </Box>
              }
            />
            <FormControlLabel
              value="rollout"
              style={{ alignItems: 'start' }}
              control={<Radio />}
              label={
                <Box pt={0.5}>
                  <Typography variant="h6">Rollout</Typography>
                  <Typography variant="body2" color="textSecondary">
                    Assign a percentage of an audience to a variant. Filter the
                    audience by attributes.
                  </Typography>
                </Box>
              }
            />
          </RadioGroup>
        </FormControl>
      </DialogContent>
      <DialogActions>
        <FormSubmitButtons
          onCancel={closeDialog}
          onSubmit={() => onSelect(ruleType!)}
          disabled={!ruleType}
          label="Next"
        />
      </DialogActions>
    </DialogBody>
  );
};

export const CreateRuleDialog = ({ variants, clients, flag }: Props) => {
  const alert = useAlert();
  const [createSegment] = useCreateSegmentMutation();
  const [allocateSegment] = useAllocateSegmentMutation();
  const [createMaterialization] = useCreateMaterializedSegmentMutation();
  const [createRule] = useCreateRuleMutation({
    refetchQueries: ['Flag'],
  });

  const { openDialog } = useDialog();

  const onSave = async (newRule: RuleFragment) => {
    if (!isType(newRule.segment, 'FlagsAdminV1Segment')) return;

    const segment = await createSegment({
      variables: {
        segment: toNewSegmentInput(newRule.segment, flag),
      },
    });

    if (isType(segment.data?.createSegment, 'FlagsAdminV1Segment')) {
      if (
        segment.data?.createSegment?.state ===
        FlagsAdminV1SegmentState.Unallocated
      ) {
        await allocateSegment({
          variables: {
            name: segment.data?.createSegment?.name || '',
          },
        });
      }
      const segmentName = segment.data?.createSegment?.name;
      if (!segmentName) return;
      if (newRule.materializationSpec?.writeMaterialization) {
        const id = getTypeOrNull(
          newRule.materializationSpec.writeMaterialization,
          'FlagsAdminV1MaterializedSegment',
        )?.name.split('/')[1];
        await createMaterialization({
          variables: {
            materializationId: id,
            materializedSegment: {
              displayName: `Materialization for ${segmentName}`,
            },
          },
        });
      }

      await createRule({
        variables: {
          parent: flag,
          rule: {
            ...ruleFragmentToInput(newRule),
            name: undefined,
            segment: segmentName,
          },
        },
      });
    } else {
      alert.post({
        message: segment.data?.createSegment?.message ?? 'Something went wrong',
        severity: 'error',
      });
    }
  };

  const handleSelect = (type: RuleType) => {
    openDialog({
      dialogProps: {
        maxWidth: [RuleType.GENERAL, RuleType.ROLLOUT].includes(type)
          ? 'md'
          : undefined,
      },
      content: (
        <RuleForm
          flagName={flag}
          variants={variants}
          ruleType={type}
          clients={clients}
          onSave={onSave}
        />
      ),
    });
  };

  return <TypeDialog onSelect={handleSelect} />;
};
