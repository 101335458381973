import React from 'react';

import {
  Box,
  Button,
  Card,
  Chip,
  Collapse,
  Dialog,
  DialogActions,
  DialogContent,
  IconButton,
  Radio,
  RadioGroup,
  SvgIcon,
} from '@material-ui/core';
import ExpandLess from '@material-ui/icons/ExpandLess';
import ExpandMore from '@material-ui/icons/ExpandMore';

import {
  ConfidenceWebsiteLink,
  DialogHeader,
  EditButton,
  FormControlCardLabel,
  FormSubmitButtons,
  SidebarSection,
  WizardStep,
  WizardStepper,
  extractLastNameComponent,
  useAlert,
  useDialog,
  useDisplayNames,
  useNavigate,
} from '@spotify-confidence/core-react';
import {
  MetricsV1MetricInput,
  getError,
  getTypeOrNull,
  isType,
  useCreateMetricMutation,
} from '@spotify-confidence/plugin-graphql';
import {
  IdentityChip,
  useListIdentitiesQuery,
} from '@spotify-confidence/plugin-permissions-react';
import { useFetchDisplayNames } from '@spotify-confidence/plugin-workflows';

import { useRouteRef } from '@backstage/core-plugin-api';

import { metricRouteRef } from '../../../../routes';
import { MetricType, defaultSpecs } from '../../../metricTypes';
import {
  AverageSimplifiedQuery,
  RatioSimplifiedQuery,
} from '../../SimplifiedQuery';
import { MetricSection } from '../../forms';
import { getCreateSections, getEditSections } from '../../forms';
import {
  METRIC_TYPE_CUSTOM_OPTIONS,
  METRIC_TYPE_OPTIONS,
  METRIC_TYPE_TEMPLATE_OPTIONS,
  MetricTypeOption,
} from './metricTypeOptions';

// https://stackoverflow.com/a/61406956/5239436
function insertAtIndex<T = any>(array: T[], index: number, newItem: T): T[] {
  return array.reduce<T[]>((newArray, currentItem, itemIndex) => {
    if (itemIndex - index) {
      newArray.push(currentItem);
    } else {
      newArray.push(newItem, currentItem);
    }
    return newArray;
  }, []);
}

const typeStepIndex = 1;

export const CreateMetricDialog = () => {
  const navigate = useNavigate();
  const metricRoute = useRouteRef(metricRouteRef);
  const alert = useAlert();

  useFetchDisplayNames({});

  const { closeDialog } = useDialog();
  const { displayNames } = useDisplayNames();

  const [activeStep, setActiveStep] = React.useState(0);

  const [metric, setMetric] = React.useState<MetricsV1MetricInput>({
    measurementConfig: {
      closedWindow: {
        aggregationWindow: '86400s',
        exposureOffset: '0s',
      },
    },
    displayName: '',
    entity: '',
    factTable: '',
    typeSpec: defaultSpecs.unknown,
  });
  const [metricType, setMetricType] = React.useState<MetricType>('unknown');
  const [showCustom, setShowCustom] = React.useState(false);

  const [previewExpanded, setPreviewExpanded] = React.useState(true);
  const [dialogOpen, setDialogOpen] = React.useState(false);
  const [dialogTitle, setDialogTitle] = React.useState('');
  const [dialogMetric, setDialogMetric] =
    React.useState<MetricsV1MetricInput>(metric);

  const [createMetric, { loading: creating, error: createError }] =
    useCreateMetricMutation({
      onCompleted: response => {
        const responseError = getError(response.createMetric);
        if (responseError) {
          alert.post({
            severity: 'error',
            message: responseError.message,
          });
        } else if (
          isType(response.createMetric, 'MetricsV1Metric') &&
          response.createMetric?.name
        ) {
          navigate(
            metricRoute({
              id: extractLastNameComponent(response.createMetric.name) || '',
            }),
          );
        }
      },
      update: cache => {
        cache.evict({ fieldName: 'metrics' });
        cache.gc();
      },
    });

  const handleSelectType = (e: React.ChangeEvent<HTMLInputElement>) => {
    const newType = e.target.value as MetricType;
    if (newType !== metricType) {
      setMetricType(e.target.value as MetricType);
      setMetric(current => ({
        ...current,
        typeSpec: defaultSpecs[newType],
      }));
    }
  };

  function handleSubmit() {
    createMetric({
      variables: {
        metric: {
          ...metric,
          labels: [{ key: 'metricType', value: metricType }],
        },
      },
    });
  }

  function togglePreview() {
    setPreviewExpanded(current => !current);
  }

  function openEditDialog(section: string) {
    setDialogOpen(true);
    setDialogTitle(section);
    setDialogMetric(metric);
  }

  function closeEditDialog() {
    setDialogOpen(false);
  }

  function submitEditDialog() {
    if (dialogMetric) {
      setMetric(dialogMetric);
      closeEditDialog();
    }
  }

  const convertSectionToStep = (step: MetricSection): WizardStep => {
    return {
      name: step.name,
      description: step.description,
      optional: step.optional,
      disableNext: step.isValid ? !step.isValid(metric) : false,
      content: step.form(metric, setMetric, false),
    };
  };

  const renderTypeOption = (option: MetricTypeOption) => (
    <FormControlCardLabel
      key={option.value}
      value={option.value}
      variant="outlined"
      control={<Radio color="primary" />}
      label={option.title}
      description={option.description}
      icon={option.icon as typeof SvgIcon}
      expandedContent={option.expandedContent}
    />
  );

  const metricTypeSection: MetricSection = {
    name: 'Type',
    description: (
      <>
        Select the kind of metric you would like to create.{' '}
        <ConfidenceWebsiteLink route="/docs/metrics" underline="always">
          Learn more.
        </ConfidenceWebsiteLink>
      </>
    ),
    canBeEditedSafely: false,
    isValid: () => !!metricType && metricType !== 'unknown',
    form: () => (
      <RadioGroup
        aria-label="type"
        name="metric-type"
        value={metricType}
        onChange={handleSelectType}
      >
        {METRIC_TYPE_TEMPLATE_OPTIONS.map(renderTypeOption)}
        <Box display="flex" alignItems="center" justifyContent="center">
          <Button
            endIcon={showCustom ? <ExpandLess /> : <ExpandMore />}
            onClick={() => setShowCustom(current => !current)}
          >
            Custom
          </Button>
        </Box>
        <Collapse in={showCustom}>
          {METRIC_TYPE_CUSTOM_OPTIONS.map(renderTypeOption)}
        </Collapse>
      </RadioGroup>
    ),
    summary: () => (
      <Chip
        label={
          METRIC_TYPE_OPTIONS.find(option => option.value === metricType)?.title
        }
      />
    ),
  };

  const sections: MetricSection[] = insertAtIndex(
    getCreateSections(metricType),
    typeStepIndex,
    metricTypeSection,
  );
  const summaryEditSections: MetricSection[] = insertAtIndex(
    getEditSections(metricType),
    typeStepIndex,
    metricTypeSection,
  );

  const { data } = useListIdentitiesQuery({
    skip: !metric.owner,
    variables: {
      filter: `name:"${metric.owner}"`,
    },
  });
  const ownerFragment =
    getTypeOrNull(data?.identities, 'IamV1ListIdentitiesResponse')
      ?.identities[0] ?? undefined;

  const wizardSteps: WizardStep[] = sections.map(convertSectionToStep).concat({
    name: 'Summary',
    forceReview: true,
    description: 'Review your metric before creating. You can edit it later.',
    content: (
      <div>
        <SidebarSection
          title="Preview"
          action={
            <IconButton size="small" onClick={togglePreview}>
              {previewExpanded ? (
                <ExpandLess fontSize="small" />
              ) : (
                <ExpandMore fontSize="small" />
              )}
            </IconButton>
          }
        >
          <Collapse in={previewExpanded}>
            <Card variant="outlined">
              <Box padding={4}>
                {metric.typeSpec?.averageMetricSpec && (
                  <AverageSimplifiedQuery
                    dense
                    entity={displayNames.get(metric.entity)}
                    factTable={displayNames.get(metric.factTable)}
                    measurement={
                      metric.typeSpec.averageMetricSpec.measurement?.name
                    }
                    aggregationMethod={
                      metric.typeSpec.averageMetricSpec.aggregation.type
                    }
                    aggregationThreshold={
                      metric.typeSpec.averageMetricSpec.aggregation.threshold ??
                      undefined
                    }
                    replaceMissingWithZero={
                      metric.nullHandling?.replaceEntityNullWithZero ?? false
                    }
                    filter={metric.filter}
                  />
                )}
                {metric.typeSpec?.ratioMetricSpec && (
                  <RatioSimplifiedQuery
                    dense
                    entity={displayNames.get(metric.entity)}
                    factTable={displayNames.get(metric.factTable)}
                    numerator={metric.typeSpec.ratioMetricSpec.numerator?.name}
                    numeratorAggregation={
                      metric.typeSpec.ratioMetricSpec.numeratorAggregation.type
                    }
                    denominator={
                      metric.typeSpec.ratioMetricSpec.denominator?.name
                    }
                    denominatorAggregation={
                      metric.typeSpec.ratioMetricSpec.denominatorAggregation
                        .type
                    }
                    replaceMissingWithZero={
                      metric.nullHandling?.replaceEntityNullWithZero ?? false
                    }
                    filter={metric.filter}
                  />
                )}
              </Box>
            </Card>
          </Collapse>
        </SidebarSection>
        {summaryEditSections.map(step => {
          const onEdit =
            step.name === 'Type'
              ? () => setActiveStep(typeStepIndex)
              : () => openEditDialog(step.name);
          return (
            <SidebarSection
              key={step.name}
              title={step.name}
              description={step.summaryDescription}
              action={<EditButton size="small" onClick={onEdit} />}
            >
              {step.summary(metric, {
                [metric.factTable]: () => displayNames.get(metric.factTable),
                [metric.entity]: () => displayNames.get(metric.entity),
                [metric.owner ?? 'unknown']: () =>
                  ownerFragment ? (
                    <IdentityChip {...ownerFragment} />
                  ) : (
                    'Unknown'
                  ),
              })}
            </SidebarSection>
          );
        })}
      </div>
    ),
  });

  const dialogSection = summaryEditSections.find(
    section => section.name === dialogTitle,
  );
  return (
    <>
      <Dialog
        onClose={closeEditDialog}
        open={dialogOpen}
        maxWidth="sm"
        fullWidth
      >
        <DialogHeader
          title={dialogTitle}
          subTitle={dialogSection?.description}
          onClose={closeEditDialog}
        />
        <DialogContent>
          {dialogSection?.form(dialogMetric, setDialogMetric, false)}
        </DialogContent>
        <DialogActions>
          <FormSubmitButtons
            onCancel={closeEditDialog}
            onSubmit={submitEditDialog}
            disabled={
              dialogSection?.isValid
                ? !dialogSection.isValid(dialogMetric)
                : false
            }
          />
        </DialogActions>
      </Dialog>
      <WizardStepper
        formName="metric-form"
        onClose={closeDialog}
        submitLabel="Create"
        onSubmit={handleSubmit}
        error={createError}
        loading={creating}
        activeStep={activeStep}
        onStepChange={setActiveStep}
        steps={wizardSteps}
      />
    </>
  );
};
