import _ from 'lodash';

export const isShallowEqual = <T extends { [key: string]: any }>(
  objA: T,
  objB: T,
) => {
  if (objA === objB) {
    return true;
  }
  const objAKeys = Object.keys(objA);
  const objBKeys = Object.keys(objB);
  if (objAKeys.length !== objBKeys.length) {
    return false;
  }
  return objAKeys.every(key => objA[key] === objB[key]);
};

export const cleanNulls = <T extends object>(input: T | null | undefined) =>
  _.omit(
    _.pickBy(input, v => !_.isNull(v)),
    '__typename',
  );

export const deepOmit = <T extends object>(value: T, key: string): T => {
  const _omit = (currentValue: any): any => {
    if (Array.isArray(currentValue)) {
      return currentValue.map(i => _omit(i));
    } else if (typeof currentValue === 'object' && currentValue !== null) {
      return Object.keys(currentValue).reduce((newObject, k) => {
        if (k === key) return newObject;
        return Object.assign({ [k]: _omit(currentValue[k]) }, newObject);
      }, {});
    }
    return currentValue;
  };
  return _omit(value) as T;
};
