import React, { PropsWithChildren, forwardRef } from 'react';

import { Theme, WithStyles, createStyles, withStyles } from '@material-ui/core';

const styles = (theme: Theme) =>
  createStyles({
    root: {
      flexShrink: 0,
      display: 'flex',
      flexDirection: 'column',
      gap: theme.spacing(1),
      alignItems: 'flex-end',
      '& > *': {
        flexGrow: 1,
      },
      [theme.breakpoints.down('md')]: {
        flexDirection: 'row',
        alignItems: 'stretch',
        justifyContent: 'stretch',
      },
    },
  });

export interface RecommendationCardContentProps
  extends WithStyles<typeof styles>,
    PropsWithChildren<{}> {}

const RecommendationCardActionArea = forwardRef<
  HTMLDivElement,
  RecommendationCardContentProps
>(function RecommendationCardActionArea({ classes, children }, ref) {
  return (
    <div ref={ref} className={classes.root}>
      {children}
    </div>
  );
});

export default withStyles(styles)(RecommendationCardActionArea);
