import React from 'react';

import { Button, Tooltip, Typography } from '@material-ui/core';
import { Alert } from '@material-ui/lab';

import {
  ConfidenceLogo,
  ListEmptyState,
  useDialog,
} from '@spotify-confidence/core-react';
import {
  getTypeOrNull,
  useCheckResourcePermissionsMutation,
} from '@spotify-confidence/plugin-graphql';

import { ExitDemoModeDialog } from './ExitDemoModeDialog';
import { removeDemoDataRequiredPermissions } from './constants';

export const DemoModeState = () => {
  const { openDialog } = useDialog();
  const [checkPermissions, { data, error, loading }] =
    useCheckResourcePermissionsMutation();

  const permission = getTypeOrNull(
    data?.executeCheck,
    'FgaV1ExecuteCheckResponse',
  );

  React.useEffect(() => {
    checkPermissions({
      variables: { resourceRelations: removeDemoDataRequiredPermissions },
    });
  }, []);

  const handleRemove = () => {
    openDialog({
      content: <ExitDemoModeDialog />,
    });
  };

  return (
    <ListEmptyState
      icon={ConfidenceLogo}
      title="Demo mode enabled"
      description={
        <>
          {error && (
            <Alert severity="error">
              Could not determine your permissions: {error.message}
            </Alert>
          )}
          <Typography paragraph>
            Your workspace is currently in demo mode. If you are ready to
            connect your own data warehouse and start running real experiments,
            you first need to exit demo mode.
          </Typography>
        </>
      }
      actions={
        <Tooltip
          title={
            !loading && !permission?.allAllowed
              ? 'You lack the necessary permissions to perform this action.'
              : ''
          }
          arrow
        >
          <div>
            <Button
              variant="contained"
              color="primary"
              onClick={handleRemove}
              disabled={loading || !permission?.allAllowed}
            >
              Exit demo mode
            </Button>
          </div>
        </Tooltip>
      }
      supportSnippet={null}
    />
  );
};
