import React from 'react';

import { TableCell, TableCellProps, makeStyles } from '@material-ui/core';

import { CommentZone } from '@spotify-confidence/plugin-comments-react';
import { Cell, GroupingState, flexRender } from '@tanstack/react-table';
import classNames from 'classnames';

import { ResultData, TableCellMeta } from './types';

const useStyles = makeStyles(theme => ({
  cell: {
    borderBottomWidth: 0,
    padding: theme.spacing(2),
    color: theme.palette.text.disabled,
    maxWidth: 100,
    [theme.breakpoints.up('md')]: {
      maxWidth: 200,
    },
  },
  noPadding: {
    '&$cell': {
      padding: 0,
    },
  },
}));

export const DataCell = ({
  cell,
  grouping,
  ...tableCellProps
}: Omit<TableCellProps, 'children'> & {
  cell: Cell<ResultData, unknown>;
  grouping?: GroupingState;
}) => {
  const classes = useStyles();
  const { disableComments, ...metaTableCellProps } = (cell.column.columnDef
    .meta || {}) as TableCellMeta;

  const content = flexRender(cell.column.columnDef.cell, cell.getContext());
  const notLastGroup =
    cell.row.groupingColumnId &&
    grouping &&
    grouping.length > 1 &&
    grouping?.indexOf(cell.row.groupingColumnId) < grouping?.length - 1;

  if (cell.getIsPlaceholder() || (cell.getIsAggregated() && notLastGroup)) {
    return (
      <TableCell
        {...tableCellProps}
        {...metaTableCellProps}
        className={classNames(tableCellProps.className, classes.cell)}
      />
    );
  }

  return (
    <TableCell
      {...tableCellProps}
      {...metaTableCellProps}
      className={classNames(tableCellProps.className, classes.cell)}
      classes={{
        paddingNone: classes.noPadding,
      }}
    >
      {disableComments ? (
        content
      ) : (
        <CommentZone id="cell" data={{ id: cell.id }}>
          {content}
        </CommentZone>
      )}
    </TableCell>
  );
};
