import React from 'react';

import { Button } from '@material-ui/core';

import {
  ConfirmDialog,
  useAlert,
  useDialog,
} from '@spotify-confidence/core-react';
import {
  Query,
  evictTypeFields,
  isType,
  useArchiveUserCreatedSegmentMutation,
} from '@spotify-confidence/plugin-graphql';

export const ArchiveButton = ({
  name,
  disabled,
}: {
  name: string;
  disabled?: boolean;
}) => {
  const { openDialog } = useDialog();
  const alert = useAlert();

  const [archive, { loading: isArchiving }] =
    useArchiveUserCreatedSegmentMutation({
      variables: {
        name,
      },
      update: cache => {
        evictTypeFields<Query>(cache, 'segments');
      },
      onCompleted: response => {
        if (isType(response.archiveSegment, 'FlagsAdminV1Segment')) {
          alert.post({
            severity: 'success',
            message: 'The segment has been archived',
            display: 'transient',
          });
        } else {
          alert.post({
            severity: 'error',
            message: response.archiveSegment?.message ?? 'Something went wrong',
          });
        }
      },
      onError: error => {
        alert.post({
          severity: 'error',
          message: error.message || 'Could not archive segment',
        });
      },
    });

  function handleArchiveSegment() {
    openDialog({
      content: (
        <ConfirmDialog
          expected="archive"
          onConfirm={async () => {
            await archive();
          }}
          irreversible
          title="Archive segment"
        />
      ),
    });
  }

  return (
    <Button
      variant="outlined"
      size="small"
      disabled={isArchiving || disabled}
      onClick={handleArchiveSegment}
    >
      Archive
    </Button>
  );
};
