import React from 'react';

import { Box } from '@material-ui/core';

import {
  ConfidenceWebsiteLink,
  SidebarValue,
  SidebarValueWrapper,
  timeWindowUtils,
} from '@spotify-confidence/core-react';
import {
  InputMaybe,
  MetricsV1MetricMeasurementConfigInput,
} from '@spotify-confidence/plugin-graphql';
import _ from 'lodash';

import { TimeWindowFormV2 } from '../../../TimeWindowForm';
import {
  getAggregationWindowIfExists,
  getExposureOffsetIfExists,
  isMeasurementConfigValid,
  parseExposureOffset,
} from '../../../TimeWindowForm/utils';
import { MetricSection } from '../../types';

const measurementDescription = (
  measurementConfig?: InputMaybe<MetricsV1MetricMeasurementConfigInput>,
): string => {
  if (!!measurementConfig?.openWindow) {
    return 'Cumulatively';
  } else if (!!measurementConfig?.semiOpenWindow) {
    return 'Cumulatively during a window';
  } else if (!!measurementConfig?.closedWindow) {
    return 'At the end of a window';
  }

  return 'Unknown measurement';
};

export const timeWindowSection: MetricSection = {
  name: 'Time window',
  description: (
    <>
      Set a starting point and duration time for evaluating the data in relation
      to the first exposure.{' '}
      <ConfidenceWebsiteLink
        route="/docs/metrics#time-windows"
        underline="always"
      >
        Learn more.
      </ConfidenceWebsiteLink>
    </>
  ),
  canBeEditedSafely: false,
  isValid: metric => isMeasurementConfigValid(metric.measurementConfig),
  form: (value, onChange, isEdit) => (
    <TimeWindowFormV2
      value={value}
      onChange={onChange}
      entity={value.entity}
      isEdit={isEdit}
    />
  ),
  summary: metric => (
    <SidebarValueWrapper>
      <SidebarValue
        name="Measure"
        value={measurementDescription(metric.measurementConfig)}
      />
      <Box display="grid" gridTemplateColumns="1fr 1fr">
        <SidebarValue
          name="Starting point"
          value={
            !!metric.measurementConfig?.openWindow
              ? 'At exposure'
              : _.upperFirst(
                  parseExposureOffset(
                    getExposureOffsetIfExists(metric.measurementConfig) ?? '0s',
                  ),
                )
          }
        />
        <SidebarValue
          name="Duration"
          value={
            !!metric.measurementConfig?.openWindow
              ? 'Experiment'
              : timeWindowUtils.parseWindow(
                  getAggregationWindowIfExists(metric.measurementConfig) ??
                    '0s',
                )
          }
        />
      </Box>
    </SidebarValueWrapper>
  ),
  updateMask: 'measurementConfig',
};
