import React from 'react';

import {
  Checkbox,
  CheckboxProps,
  MenuItem,
  MenuItemProps,
  Select,
  makeStyles,
} from '@material-ui/core';

import _ from 'lodash';

import {
  ColumnOption,
  ResultColumnType,
  defaultColumns,
  getColumnOption,
} from './MetricResultTable';

type ColumnSelectProps = {
  value: string[];
  onChange: (newValue: string[]) => void;
  disabledColumns?: string[];
  sequential?: boolean;
  columns?: ResultColumnType[];
};

const useStyles = makeStyles(theme => ({
  columnGroupTitle: {
    borderTop: `1px solid ${theme.palette.divider}`,
    borderBottom: `1px solid ${theme.palette.divider}`,
  },
  columnGroupItem: {
    paddingLeft: theme.spacing(4),
    '& + $columnItem': {
      borderTop: `1px solid ${theme.palette.divider}`,
    },
  },
  columnItem: {},
}));

type OptionItemProps = Omit<MenuItemProps, 'button'> & {
  checked?: CheckboxProps['checked'];
  indeterminate?: CheckboxProps['indeterminate'];
};

const OptionItem = React.forwardRef<HTMLLIElement, OptionItemProps>(
  (
    { children, checked = false, indeterminate = false, color, ...props },
    ref,
  ) => {
    return (
      <MenuItem ref={ref} {...props}>
        <Checkbox
          size="small"
          checked={(props.selected || checked) ?? false}
          indeterminate={indeterminate}
        />
        {children}
      </MenuItem>
    );
  },
);

export const ColumnSelect = ({
  columns = defaultColumns,
  value,
  disabledColumns,
  onChange,
  sequential,
}: ColumnSelectProps) => {
  const classes = useStyles();
  const columnOptions = React.useMemo(() => {
    return columns.map(getColumnOption);
  }, [columns]);
  const filteredOptions = React.useMemo<ColumnOption[]>(() => {
    return columnOptions.filter(option =>
      option.id === 'Expand' ? sequential : true,
    );
  }, [sequential]);

  const optionIsSelected = (option: ColumnOption) => value.includes(option.id);

  const toggleColumn = (option: ColumnOption) => {
    if (optionIsSelected(option)) {
      onChange(value.filter(column => column !== option.id));
    } else {
      onChange(value.concat(option.id));
    }
  };

  const toggleColumnGroup = (options: ColumnOption[]) => {
    const optionIds = options.map(child => child.id);

    if (options.some(optionIsSelected)) {
      onChange(value.filter(column => !optionIds.includes(column)));
    } else {
      onChange(_.uniq(value.concat(optionIds)));
    }
  };

  const renderOption = (option: ColumnOption, className?: string) => {
    return (
      <OptionItem
        key={option.id}
        className={className}
        value={option.id}
        selected={optionIsSelected(option)}
        disabled={disabledColumns?.includes(option.id) || option.disabled}
        onClick={() => toggleColumn(option)}
      >
        {option.name}
      </OptionItem>
    );
  };

  return (
    <Select
      value={value}
      multiple
      variant="outlined"
      margin="dense"
      displayEmpty
      renderValue={() =>
        value.length ? `${value.length} columns` : 'All columns'
      }
    >
      {filteredOptions.map(column => {
        if (column.children) {
          const someChildrenSelected = column.children.some(optionIsSelected);
          const allChildrenSelected = column.children.every(optionIsSelected);

          return [
            <OptionItem
              key={column.id}
              className={classes.columnGroupTitle}
              indeterminate={someChildrenSelected && !allChildrenSelected}
              checked={allChildrenSelected}
              onClick={() => toggleColumnGroup(column.children || [])}
            >
              {column.name}
            </OptionItem>,
            ...column.children.map(c =>
              renderOption(c, classes.columnGroupItem),
            ),
          ];
        }
        return renderOption(column, classes.columnItem);
      })}
    </Select>
  );
};
