import React from 'react';

import {
  ListItem,
  ListItemSecondaryAction,
  ListItemText,
  Switch,
} from '@material-ui/core';

import { useSidebarPinState } from '@backstage/core-components';

export function SidebarPinToggle() {
  const { isPinned, toggleSidebarPinState } = useSidebarPinState();

  return (
    <ListItem ContainerComponent="div">
      <ListItemText
        primary="Pin Sidebar"
        secondary="Prevent the sidebar from collapsing"
      />
      <ListItemSecondaryAction style={{ right: 0 }}>
        <Switch
          checked={isPinned}
          onChange={() => toggleSidebarPinState()}
          name="pin"
        />
      </ListItemSecondaryAction>
    </ListItem>
  );
}
