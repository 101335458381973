export const timeWindowUnits = ['weeks', 'days', 'hours', 'minutes', 'seconds'];
export type TimeWindowUnit = (typeof timeWindowUnits)[number];

export const timespanInSeconds: { [unit in TimeWindowUnit]: number } = {
  seconds: 1,
  minutes: 60,
  hours: 60 * 60,
  days: 60 * 60 * 24,
  weeks: 60 * 60 * 24 * 7,
};

export function isBiggerUnit(unit1: TimeWindowUnit, unit2: TimeWindowUnit) {
  return timeWindowUnits.indexOf(unit1) < timeWindowUnits.indexOf(unit2);
}

export function getClosestUnit(seconds: number): TimeWindowUnit {
  for (let i = 0; i < timeWindowUnits.length; i++) {
    const unit = timeWindowUnits[i];
    const unitInSeconds = timespanInSeconds[unit];
    if (seconds >= unitInSeconds && seconds % unitInSeconds === 0) {
      return unit;
    }
  }
  return 'seconds';
}

export function getWindowAsSeconds(window: string): number {
  // Format: 3600s
  return Number(window.slice(0, -1));
}

export function getWindowForUnit(window: string, unit: TimeWindowUnit) {
  return getWindowAsSeconds(window) / timespanInSeconds[unit];
}

export function createWindow(amount: number, unit: TimeWindowUnit): string {
  return `${Number(amount) * timespanInSeconds[unit]}s`;
}

export function parseWindow(timeWindow: string) {
  const unit = getClosestUnit(getWindowAsSeconds(timeWindow));
  const value = getWindowForUnit(timeWindow, unit);
  if (!value) {
    return 'None';
  }
  return `${value} ${value === 1 ? unit.slice(0, -1) : unit}`;
}

export function sortTimewindows(a: string, b: string) {
  return getWindowAsSeconds(a) - getWindowAsSeconds(b);
}
