import React from 'react';

import {
  Box,
  BoxProps,
  Theme,
  createStyles,
  withStyles,
} from '@material-ui/core';

const styles = (theme: Theme) =>
  createStyles({
    root: {
      paddingLeft: (props: { level?: number }) =>
        props.level ? theme.spacing(3) * props.level : theme.spacing(1),
    },
  });

export interface SchemaEntryNestedProps extends BoxProps {
  level?: number;
}

function SchemaEntryNested({ level = 0, ...props }: SchemaEntryNestedProps) {
  return <Box {...props} />;
}

export default withStyles(styles)(SchemaEntryNested);
