import React from 'react';

import { KeyboardDatePicker } from '@material-ui/pickers';

import { ChipTimestampPicker } from './ChipTimestampPicker';
import { SharedInputProps } from './types';

export const ChipDatePicker = (props: SharedInputProps) => {
  return (
    <ChipTimestampPicker
      component={KeyboardDatePicker}
      maxWidth={80}
      format="yyyy-MM-dd'T'00:00:00"
      displayFormat="yyyy-MM-dd"
      id="date"
      {...props}
    />
  );
};
