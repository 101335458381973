import React from 'react';

import {
  ListItemIcon,
  ListItemIconProps,
  createStyles,
  withStyles,
} from '@material-ui/core';

import { ExpandButton } from '@spotify-confidence/core-react';

const styles = () =>
  createStyles({
    root: {
      minWidth: 35,
    },
  });

export interface SchemaEntryExpandProps
  extends Omit<ListItemIconProps, 'onChange'> {
  expanded?: boolean;
  canExpand?: boolean;
  onChange?: (expanded: boolean) => void;
}

const SchemaEntryExpand = function SchemaEntryExpand({
  expanded,
  onChange,
  canExpand,
  ...props
}: SchemaEntryExpandProps) {
  return (
    <ListItemIcon {...props}>
      {canExpand && (
        <ExpandButton size="small" expanded={expanded} onChange={onChange} />
      )}
    </ListItemIcon>
  );
};

export default withStyles(styles)(SchemaEntryExpand);
