import { alpha, styled } from '@material-ui/core';

export default styled('div')(({ theme }) => ({
  margin: theme.spacing(2, -1),
  width: '80%',
  height: 1,
  borderTop: '1px solid',
  borderImage: `linear-gradient(90deg, ${alpha(
    theme.palette.getContrastText(theme.palette.background.paper),
    0.2,
  )} 80%, ${alpha(theme.palette.divider, 0)}) 1`,
}));
