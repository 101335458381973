import React from 'react';

import { useConfidence } from '@spotify-confidence/react';
import { Value } from '@spotify-confidence/sdk';

import { FeatureFlaggedProps } from '@backstage/core-app-api';

// If we wanna use the original FeatureFlagged component,
// we should re-export it from @backstage/core-app-api
// export { FeatureFlagged } from '@backstage/core-app-api';

export const FeatureFlagged = (props: FeatureFlaggedProps) => {
  const flag = 'with' in props ? props.with : props.without;
  const isEnabled = useConfidence().useFlag(`${flag}.enabled`, false);

  return <>{isEnabled ? props.children : null}</>;
};

/**
 * Function to split a component based on a feature flag.
 *
 * @param flag The flag that should be resolved
 * @param defaultValue The default value for said flag.
 * @param factory The factory function that will create the component based on the flag value.
 * @returns
 */
export const splitComponent = <
  V extends Value.Struct,
  P extends React.JSX.IntrinsicAttributes,
>(
  flag: string,
  defaultValue: V,
  factory: (value: V) => React.ComponentType<P>,
) => {
  return (props: P) => {
    const value = useConfidence().useFlag(flag, defaultValue);
    if (!value) {
      return null;
    }

    const Component = factory(value as V); // TODO check this
    return <Component {...props} />;
  };
};

export const useFeatureFlag = <TDefault extends Value.Struct>(
  flag: string,
  defaultValue: TDefault,
) => {
  return useConfidence().useFlag(flag, defaultValue);
};
