import React from 'react';

import { Box, makeStyles, useTheme } from '@material-ui/core';

import classNames from 'classnames';

import {
  KEY_JOINER,
  getEntryByIdentifier,
  getTypeSchema,
} from '../../domain/schema.helpers';
import { Entry } from '../../domain/schema.hooks';
import { AddFieldButton } from './AddFieldButton';
import { EntryEditor } from './EntryEditor';
import { EventSchema } from './EventSchema';
import { useSchemaContext } from './SchemaContext';

export const MAXIMUM_DEPTH = 2;

const useStyles = makeStyles(theme => ({
  root: {
    display: 'grid',
    gridTemplateColumns: '1fr',
    alignItems: 'stretch',
    transition: theme.transitions.create('grid-template-columns', {
      duration: theme.transitions.duration.standard,
    }),
    [theme.breakpoints.up(620)]: {
      gridTemplateColumns: '1fr 0px',
    },
  },
  open: {
    '&$root': {
      gap: theme.spacing(2),
      [theme.breakpoints.up(620)]: {
        gridTemplateColumns: '1fr 50%',
      },
      [theme.breakpoints.up('md')]: {
        gridTemplateColumns: '1fr 350px',
      },
    },
  },
}));

export const SchemaEditor = ({
  readOnly,
  permanentKeys = [],
  getEntryLabels,
}: {
  getEntryLabels?: (
    identity: string,
    path: string[],
    entry: Entry,
  ) => React.ReactNode;
  readOnly?: boolean;
  permanentKeys?: string[];
}) => {
  const classes = useStyles();
  const { schema, onAdd, onUpdate, onDelete, selectedEntry, setSelectedEntry } =
    useSchemaContext();

  const { entry, path, siblingKeys } = getEntryByIdentifier(
    schema,
    selectedEntry,
  );

  const theme = useTheme();
  return (
    <div
      className={classNames(
        classes.root,
        selectedEntry !== null && classes.open,
      )}
    >
      <Box padding={readOnly ? 0 : theme.spacing(2, 2, 2, 0)}>
        {!readOnly && (
          <Box textAlign="right" marginBottom={2}>
            <AddFieldButton
              variant="outlined"
              size="small"
              onClick={v => {
                const newId = onAdd('0', {
                  key: '',
                  value: getTypeSchema(v),
                });
                setSelectedEntry(newId);
              }}
            />
          </Box>
        )}
        <EventSchema
          path={[]} // recursively keep track of current path
          schema={schema}
          readOnly={readOnly}
          getEntryLabels={getEntryLabels}
        />
      </Box>

      {entry && selectedEntry && (
        <EntryEditor
          entry={entry}
          usedKeys={siblingKeys}
          selectedEntry={selectedEntry}
          isPermanent={permanentKeys.includes(path.join(KEY_JOINER))}
          onDelete={() => onDelete(selectedEntry)}
          onUpdate={updatedEntry => {
            onUpdate(selectedEntry, updatedEntry);
          }}
        />
      )}
    </div>
  );
};
