import React from 'react';

import { makeStyles } from '@material-ui/core';

import {
  ColorUtils,
  extractLastNameComponent,
} from '@spotify-confidence/core-react';
import {
  RuleFragment,
  getTypeOrNull,
} from '@spotify-confidence/plugin-graphql';

import { variantHelpers } from '../../../../../variant';
import { assignmentWeights } from '../../../../domain/assignments.model';

const useStyles = makeStyles(theme => ({
  variant: {
    display: 'inline-block',
    marginRight: theme.spacing(1),
    flexShrink: 0,
  },
  variantCircle: {
    display: 'inline-block',
    height: theme.typography.caption.fontSize,
    width: theme.typography.caption.fontSize,
    borderRadius: '50%',
    alignSelf: 'center',
    margin: theme.spacing(0, 0.5),
  },
}));

export const RuleCardAssign = ({
  assignmentSpec,
  hidePercentages,
}: {
  assignmentSpec?: RuleFragment['assignmentSpec'];
  hidePercentages?: boolean;
}) => {
  const classes = useStyles();
  const assignments =
    assignmentSpec?.assignments?.filter(v =>
      assignmentWeights(v.bucketRanges),
    ) || [];

  const variantWeightAsPercent = React.useCallback(
    (weight: number) =>
      variantHelpers.getVariantWeightAsPercent(
        weight,
        assignmentSpec?.bucketCount,
      ),
    [assignmentSpec?.bucketCount],
  );

  return (
    <>
      <strong>Assign </strong>
      {assignments.length > 0
        ? assignments.map(assignment => {
            const variantName =
              getTypeOrNull(
                assignment.variant?.variant,
                'FlagsAdminV1FlagVariant',
              )?.name ?? '';
            return (
              <span className={classes.variant} key={assignment.assignmentId}>
                <span
                  className={classes.variantCircle}
                  style={{
                    background: ColorUtils.stringToColor(variantName),
                  }}
                />
                {!hidePercentages && (
                  <span>
                    {variantWeightAsPercent(
                      assignmentWeights(assignment.bucketRanges),
                    )}
                    %{' '}
                  </span>
                )}
                {extractLastNameComponent(variantName)}
              </span>
            );
          })
        : 'default values'}
    </>
  );
};
