import React from 'react';

import { Box, BoxProps, alpha, makeStyles, useTheme } from '@material-ui/core';
import ArrowUpwardIcon from '@material-ui/icons/ArrowUpward';

import { useDisplayNames } from '@spotify-confidence/core-react';
import { AxisBottom, AxisLeft } from '@visx/axis';
import { localPoint } from '@visx/event';
import { GridRows } from '@visx/grid';
import { Group } from '@visx/group';
import ParentSize from '@visx/responsive/lib/components/ParentSize';
import { scaleBand } from '@visx/scale';
import { Bar, Circle, Line } from '@visx/shape';
import classNames from 'classnames';
import { ScaleBand } from 'd3';
import _ from 'lodash';

import { getGroupsFromGroupComparisonId } from '../../utils';
import {
  MetricResultTableGrouping,
  ResultData,
  TableMetaOptions,
} from '../types';
import { useChartTooltip } from './useChartTooltip';
import { useResultChart } from './useResultChart';
import { getResultColor } from './utils';

type IntervalPlotProps = TableMetaOptions & {
  results: ResultData[];
  grouping: MetricResultTableGrouping;
  width?: number;
  height?: number;
  padding?: { top?: number; bottom?: number; left?: number; right?: number };
  BoxProps?: Partial<BoxProps>;
};

const useStyles = makeStyles(theme => ({
  label: {
    ...theme.typography.body2,
    fill: theme.palette.text.primary,
  },
  numeral: {
    fontFeatureSettings: "'tnum'",
    fontVariantNumeric: 'tabular-num',
  },
  intervalBar: {
    '&:hover': {
      '& $barHoverBackground': {
        opacity: 0.7,
      },
    },
  },
  barHoverBackground: {
    transition: theme.transitions.create('opacity', { duration: '0.2s' }),
    opacity: 0,
  },
  nim: {
    color: theme.palette.text.disabled,
    filter: `drop-shadow(-1px -1px 0px ${theme.palette.background.paper})
    drop-shadow(1px -1px 0px ${theme.palette.background.paper})
    drop-shadow(1px 1px 0px ${theme.palette.background.paper})
    drop-shadow(-1px 1px 0px ${theme.palette.background.paper})`,
  },
  overlappingNim: {
    color: theme.palette.warning.main,
  },
}));

export const IntervalPlot = ({
  results = [],
  bucket,
  grouping: propGrouping,
  showRelativeChanges,
  sequential,
  height = 580,
  width = 800,
  padding: propsPadding,
  BoxProps: boxProps,
}: IntervalPlotProps) => {
  const theme = useTheme();
  const classes = useStyles();

  const { displayNames } = useDisplayNames();

  const padding = {
    top: theme.spacing(2),
    left: theme.spacing(8),
    right: theme.spacing(2),
    bottom: theme.spacing(9),
    ...propsPadding,
  };

  const { yMax, xMax, yScale, getLower, getUpper, getEstimate } =
    useResultChart({ results, height, width, showRelativeChanges, padding });

  let grouping: MetricResultTableGrouping = propGrouping;
  if (grouping.length === 0) {
    grouping = sequential ? ['metric', 'timeLabel'] : ['metric'];
  }

  const xAxisFormatter = (groupId?: string) => (value: string) => {
    if (groupId === 'metric') {
      return displayNames.get(value) || value;
    }
    if (groupId === 'comparison') {
      const groups = getGroupsFromGroupComparisonId(value);
      return Object.values(groups)
        .map(group => displayNames.get(group) || group)
        .join(' vs ');
    }
    return value;
  };

  //    Scales
  const groupValues: string[][] = grouping.map(group =>
    _.uniq(results.map(row => row[group] || '')),
  );

  const groupScales = groupValues.reduce<ScaleBand<string>[]>(
    (scales, values, index) => {
      if (_.isEmpty(scales)) {
        return [
          scaleBand<string>({
            range: [0, xMax],
            domain: values,
            padding: 0.5,
          }),
        ];
      }
      return scales.concat(
        scaleBand<string>({
          domain: values,
          range: [0, scales[index - 1]?.bandwidth() || xMax],
        }),
      );
    },
    [],
  );

  const mainGroup = _.first(grouping);
  const xScale = _.first(groupScales);

  const { containerRef, renderTooltip, showTooltip, hideTooltip } =
    useChartTooltip({ showRelativeChanges, bucket });

  const showRowTooltip = (
    event: React.MouseEvent<SVGElement>,
    row: ResultData,
  ) => {
    const coords = localPoint(event.currentTarget, event);
    showTooltip({
      tooltipLeft: coords?.x,
      tooltipTop: coords?.y,
      tooltipData: row,
    });
  };

  return (
    <Box {...boxProps} width={width} height={height}>
      <svg
        ref={containerRef}
        width="100%"
        height="100%"
        viewBox={`0 0 ${width} ${height}`}
      >
        <GridRows
          scale={yScale}
          width={xMax}
          height={yMax}
          stroke={alpha(theme.palette.text.secondary, 0.1)}
          left={padding.left}
          top={padding.top}
          numTicks={8}
        />

        <Group left={padding.left} top={padding.top}>
          {results.map(row => {
            const fill = getResultColor(row, theme);

            let left = 0;
            let circleWidth = 8;
            const strokeWidth = 2;
            const groupWidth = _.last(groupScales)?.bandwidth() || circleWidth;

            circleWidth = Math.min(circleWidth, groupWidth);

            groupScales.forEach((scale, index) => {
              const groupValue = row[grouping[index]];
              if (groupValue) {
                left += scale(groupValue) ?? 0;
              }
            });

            const top = yScale(getUpper(row));
            const bottom = yScale(getLower(row));
            const center = left + groupWidth / 2;

            const iconSize = circleWidth * 3;

            return (
              <Group
                key={`${row.metric}-${row.comparison}-${row.result?.timeLabel}`}
                className={classes.intervalBar}
              >
                <Bar
                  fill={theme.palette.action.hover}
                  className={classes.barHoverBackground}
                  onMouseMove={e => showRowTooltip(e, row)}
                  onMouseLeave={hideTooltip}
                  x={left}
                  width={groupWidth}
                  y={0}
                  height={yMax}
                />
                <Line
                  from={{ x: center, y: top }}
                  to={{ x: center, y: bottom }}
                  stroke={fill}
                  strokeWidth={strokeWidth}
                />
                <Line
                  stroke={fill}
                  strokeWidth={strokeWidth}
                  from={{
                    x: center - strokeWidth,
                    y: top,
                  }}
                  to={{
                    x: center + strokeWidth,
                    y: top,
                  }}
                />
                <Line
                  stroke={fill}
                  strokeWidth={strokeWidth}
                  from={{
                    x: center - strokeWidth,
                    y: bottom,
                  }}
                  to={{
                    x: center + strokeWidth,
                    y: bottom,
                  }}
                />
                {row.nim && showRelativeChanges && (
                  <Group
                    className={classNames(classes.nim, {
                      [classes.overlappingNim]: !row.isSignificant,
                    })}
                  >
                    <Line
                      from={{ x: center - iconSize, y: yScale(row.nim) }}
                      to={{
                        x: center + iconSize,
                        y: yScale(row.nim),
                      }}
                      strokeWidth={2}
                      stroke="currentColor"
                    />
                    <ArrowUpwardIcon
                      color="inherit"
                      height={iconSize}
                      width={iconSize}
                      x={center - iconSize / 2}
                      y={yScale(row.nim)}
                    />
                  </Group>
                )}
                {row.result && (
                  <Circle
                    fill={fill}
                    cx={center}
                    cy={yScale(getEstimate(row))}
                    r={circleWidth}
                    stroke={theme.palette.background.paper}
                    strokeWidth={1}
                  />
                )}
              </Group>
            );
          })}
        </Group>

        <AxisLeft
          scale={yScale}
          top={padding.top}
          left={padding.left}
          stroke={theme.palette.text.secondary}
          strokeWidth={1}
          tickFormat={tick => (showRelativeChanges ? `${tick}%` : `${tick}`)}
          tickLabelProps={{
            width: padding.left,
            className: classNames(classes.label, classes.numeral),
          }}
          numTicks={5}
          hideTicks
        />

        {xScale && (
          <AxisBottom
            scale={xScale}
            top={yMax + padding.top}
            left={padding.left}
            stroke={theme.palette.text.secondary}
            strokeWidth={1}
            tickFormat={xAxisFormatter(mainGroup)}
            tickLabelProps={{
              width: xScale.bandwidth(),
              verticalAnchor: 'start',
              className: classes.label,
            }}
            hideTicks
          />
        )}
      </svg>

      {renderTooltip()}
    </Box>
  );
};

export const ResponsiveIntervalPlot = (props: IntervalPlotProps) => {
  return (
    <ParentSize style={{ height: props.height }}>
      {parent => <IntervalPlot width={parent.width} {...props} />}
    </ParentSize>
  );
};
