import React from 'react';

import { Divider, TextField, Typography } from '@material-ui/core';

import { FormFieldsBox } from '@spotify-confidence/core-react';
import { ConnectorsV1DatabricksConnectionConfigInput } from '@spotify-confidence/plugin-graphql';

type DatabricksConnectionConfigFormProps = {
  value: ConnectorsV1DatabricksConnectionConfigInput;
  onChange: (conf: ConnectorsV1DatabricksConnectionConfigInput) => void;
  disabled?: boolean;
};

export const DatabricksConnectionConfigForm = ({
  value,
  onChange,
  disabled,
}: DatabricksConnectionConfigFormProps) => {
  const host: string = value?.host ?? '';
  const warehouseId: string = value?.warehouseId ?? '';
  const clientId: string = value?.clientId ?? '';
  const clientSecret: string = value?.clientSecret ?? '';

  const handleConfigChange =
    (field: keyof ConnectorsV1DatabricksConnectionConfigInput) =>
    (e: React.ChangeEvent<HTMLInputElement>) => {
      onChange({
        ...value,
        [field]: e.target.value,
      });
    };

  return (
    <FormFieldsBox>
      <FormFieldsBox spacing={1}>
        <div>
          <Typography variant="overline" color="textSecondary" gutterBottom>
            Databricks Connection settings
          </Typography>
          <Divider />
        </div>

        <TextField
          disabled={disabled}
          label="Host"
          variant="outlined"
          value={host}
          required
          fullWidth
          onChange={handleConfigChange('host')}
          helperText="The hostname for the Databricks instance, for example xx.x.gcp.databricks.com."
        />
      </FormFieldsBox>
      <TextField
        disabled={disabled}
        label="Warehouse ID"
        variant="outlined"
        value={warehouseId}
        required
        fullWidth
        helperText={
          <>The ID for the SQL warehouse that will be used to run queries.</>
        }
        onChange={handleConfigChange('warehouseId')}
      />
      <TextField
        disabled={disabled}
        label="Client ID"
        variant="outlined"
        value={clientId}
        required
        fullWidth
        helperText="The OAuth Client ID for the service principal."
        onChange={handleConfigChange('clientId')}
      />
      <TextField
        disabled={disabled}
        label="Client Secret"
        variant="outlined"
        value={clientSecret}
        required
        fullWidth
        helperText="The OAuth client secret for the service principal."
        onChange={handleConfigChange('clientSecret')}
      />
    </FormFieldsBox>
  );
};
