import loadable from '@loadable/component';

const flagComponentsChunk = () =>
  import(/* webpackChunkName: "flags-flag-components" */ './components.chunk');

export const FlagVariantDialog = loadable(() =>
  flagComponentsChunk().then(m => m.FlagVariantDialog),
);
export const VariantDropdown = loadable(() =>
  flagComponentsChunk().then(m => m.VariantDropdown),
);
export const FlagDialog = loadable(() =>
  flagComponentsChunk().then(m => m.FlagDialog),
);
export const FlagVariantSelector = loadable(() =>
  flagComponentsChunk().then(m => m.FlagVariantSelector),
);
export const FlagsModuleSection = loadable(() =>
  flagComponentsChunk().then(m => m.FlagsModuleSection),
);
export const CoordinationSidebarSection = loadable(() =>
  flagComponentsChunk().then(m => m.CoordinationSidebarSection),
);
export const FlagFilter = loadable(() =>
  flagComponentsChunk().then(m => m.FlagFilter),
);
export * from './types';
export * from './PauseResumeIntakeButtons';
