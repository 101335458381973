import React from 'react';

import { makeStyles } from '@material-ui/core';

import classNames from 'classnames';

import { NarrowBox, NarrowBoxProps } from './NarrowBox';

type HeaderTabsContainerProps = React.PropsWithChildren<{
  sticky?: boolean;
  narrow?: boolean | NarrowBoxProps['width'];
}>;

const useStyles = makeStyles(theme => ({
  root: {
    gridArea: 'pageSubheader',
    backgroundColor: theme.palette.background.paper,
  },
  narrow: {
    padding: theme.spacing(0, 3),
    '& > div > div': {
      paddingLeft: 0,
    },
  },
  sticky: { position: 'sticky', top: 0, zIndex: 5 },
}));

export const HeaderTabsContainer = React.forwardRef<
  HTMLDivElement | null,
  HeaderTabsContainerProps
>(({ sticky, narrow, children }, ref) => {
  const classes = useStyles();
  return (
    <div
      ref={ref}
      className={classNames(classes.root, {
        [classes.narrow]: narrow,
        [classes.sticky]: sticky,
      })}
    >
      {narrow ? (
        <NarrowBox width={typeof narrow === 'string' ? narrow : undefined}>
          {children}
        </NarrowBox>
      ) : (
        children
      )}
    </div>
  );
});
