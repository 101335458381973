import React from 'react';

import { Typography } from '@material-ui/core';

import { CodeSnippet } from '../../CodeSnippet/CodeSnippet';
import { f } from '../../CodeSnippet/format';

export const JsClientSnippets = () => {
  return (
    <>
      <Typography variant="body1" gutterBottom>
        Let's track the sample "tutorial-event" event from your service and make
        sure it gets published to our backend.
      </Typography>
      <CodeSnippet
        language="typescript"
        showLineNumbers
        text={f(`
confidence.track("tutorial-event");
`)}
        showCopyCodeButton
      />
    </>
  );
};
