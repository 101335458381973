import { RuleFragment } from '@spotify-confidence/plugin-graphql';

export type AssignmentKind = 'clientDefault' | 'fallthrough' | 'variant';

export const DEFAULT_BUCKET_COUNT = 10000;

export const newAssignments = (): RuleFragment['assignmentSpec'] => ({
  bucketCount: DEFAULT_BUCKET_COUNT,
  assignments: [],
});

export const bucketWeight = (lower: number, upper: number) =>
  Math.abs(upper - lower);

export const percent = (ratio: number) => `${ratio.toFixed(0)}%`;

export const assignmentWeights = (
  bucketRanges: {
    lower: number;
    upper: number;
  }[],
) => {
  return bucketRanges.length > 0
    ? bucketWeight(bucketRanges[0].lower, bucketRanges[0].upper)
    : 0;
};
