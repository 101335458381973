import React from 'react';

import { makeStyles } from '@material-ui/core';

type CardsGridLayoutProps = {
  singleItemFullWidth?: boolean;
};

const useStyles = makeStyles(theme => ({
  grid: {
    display: 'grid',
    gridAutoFlow: 'dense',
    width: '100%',
    gap: theme.spacing(2),
    gridTemplateColumns: '1fr',
    [theme.breakpoints.up('sm')]: {
      gridTemplateColumns: ({ singleItemFullWidth }: CardsGridLayoutProps) =>
        singleItemFullWidth ? '1fr' : `repeat(auto-fit, minmax(250px, 1fr))`,
    },
    [theme.breakpoints.up('lg')]: {
      gridTemplateColumns: ({ singleItemFullWidth }: CardsGridLayoutProps) =>
        singleItemFullWidth ? '1fr' : `repeat(auto-fit, minmax(350px, 0.5fr))`,
    },
  },
}));

export const CardsGridLayout = ({
  singleItemFullWidth,
  children,
}: React.PropsWithChildren<CardsGridLayoutProps>) => {
  const classes = useStyles({ singleItemFullWidth });
  return <div className={classes.grid}>{children}</div>;
};
