import React, { Fragment } from 'react';

import {
  Avatar,
  Box,
  Popover,
  Typography,
  makeStyles,
} from '@material-ui/core';

import {
  isType,
  useGetCurrentUserQuery,
} from '@spotify-confidence/plugin-graphql';

import { useSidebarOpenState } from '@backstage/core-components';

import { FeatureFlagsToggles } from './FeatureFlagsToggles';
import { SidebarPinToggle } from './SidarbarPinToggle';
import { ThemeSwitcher } from './ThemeSwitcher';
import { UserInfo } from './UserInfo';

const useStyles = makeStyles(theme => ({
  avatar: {
    width: theme.spacing(4),
    height: theme.spacing(4),
    border: `2px solid ${theme.palette.divider}`,
  },
  button: {
    display: 'flex',
    color: theme.palette.navigation.color,
    padding: theme.spacing(1, 2.5),
    border: 'none',
    outline: 'none',
    alignItems: 'center',
    margin: 0,
    background: 'transparent',
    cursor: 'pointer',
  },
  name: {
    color: theme.palette.navigation.selectedColor,
  },
  email: {
    color: theme.palette.navigation.color,
  },
}));

export function ProfileMenu() {
  const classes = useStyles();

  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);

  const { data } = useGetCurrentUserQuery();
  const user = isType(data?.currentUser, 'IamV1CurrentUser')
    ? data?.currentUser?.user
    : undefined;

  const openMenu = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const closeMenu = () => {
    setAnchorEl(null);
  };

  const { isOpen } = useSidebarOpenState();

  return (
    <Fragment>
      <button className={classes.button} onClick={openMenu}>
        <Avatar
          className={classes.avatar}
          src={user?.pictureUri ?? undefined}
        />
        {isOpen && (
          <Box
            marginLeft={1}
            display="flex"
            flexDirection="column"
            alignItems="flex-start"
          >
            <Typography noWrap variant="body1" className={classes.name}>
              {user?.fullName}
            </Typography>
            <Typography noWrap variant="body2" className={classes.email}>
              {user?.email}
            </Typography>
          </Box>
        )}
      </button>
      <Popover
        id="profile-menu"
        keepMounted
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        onClose={closeMenu}
        // for some reason sometimes a scroll bar appears in the popover
        PaperProps={{
          style: {
            overflow: 'hidden',
          },
        }}
      >
        <UserInfo user={user} />
        <ThemeSwitcher />
        <SidebarPinToggle />
        <FeatureFlagsToggles />
      </Popover>
    </Fragment>
  );
}
