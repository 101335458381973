import React from 'react';

import {
  getTypeOrNull,
  useDataWarehouseExistsQuery,
} from '@spotify-confidence/plugin-graphql';

export const useHasDataWarehouse = () => {
  const { loading, error, data, startPolling, stopPolling } =
    useDataWarehouseExistsQuery();
  const existsDataWarehouses = getTypeOrNull(
    data?.existsDataWarehouses,
    'MetricsV1ExistsDataWarehousesResponse',
  );

  const currentAccount = getTypeOrNull(
    data?.currentAccount,
    'AdminV1AccountInfo',
  );

  React.useEffect(() => {
    if (currentAccount?.totalConfidenceAccountInfo?.state === 'CREATING') {
      startPolling(10_000);
    } else {
      stopPolling();
    }
  }, [currentAccount?.totalConfidenceAccountInfo?.state]);

  return {
    loading,
    error,
    hasDataWarehouse: existsDataWarehouses?.exists || false,
    isTotalConfidence: currentAccount?.mode === 'TOTAL_CONFIDENCE' || false,
    isSettingUpTotalConfidence:
      currentAccount?.totalConfidenceAccountInfo?.state === 'CREATING' || false,
  };
};
