import React from 'react';
import { useSearchParams } from 'react-router-dom';

import {
  Box,
  Divider,
  IconButton,
  MenuItem,
  TextField,
} from '@material-ui/core';
import CalendarTodayOutlined from '@material-ui/icons/CalendarTodayOutlined';
import { DatePicker, MuiPickersUtilsProvider } from '@material-ui/pickers';

import DateFnsUtils from '@date-io/date-fns';
import { endOfMonth, format, startOfMonth } from 'date-fns';

import { DateRange, useDateRange } from '../../hooks/useDateRange';

const dateFilterFormat = (d: Date) => format(d, 'yyyy-MM-dd');

export function DateRangeFilter() {
  const { rangeType, dateRange } = useDateRange({});
  const [searchParams, setSearchParams] = useSearchParams();

  function handleRangeTypeChange(e: React.ChangeEvent<HTMLInputElement>) {
    const newRange = e.target.value;
    setSearchParams({
      range: newRange,
      ...(newRange === 'custom' && {
        start: dateFilterFormat(startOfMonth(Date.now())),
        end: dateFilterFormat(endOfMonth(Date.now())),
      }),
    });
  }

  function handleDateChange(dateKey: keyof DateRange) {
    return (d?: Date | null) => {
      setSearchParams({
        range: 'custom',
        start: dateFilterFormat(
          new Date(searchParams.get('start') ?? Date.now()),
        ),
        end: dateFilterFormat(new Date(searchParams.get('end') ?? Date.now())),
        [dateKey]: dateFilterFormat(d ?? new Date()),
      });
    };
  }

  return (
    <MuiPickersUtilsProvider utils={DateFnsUtils}>
      <Box display="flex" gridGap={8}>
        <TextField
          margin="dense"
          variant="outlined"
          select
          value={rangeType}
          onChange={handleRangeTypeChange}
        >
          <MenuItem value="currentMonth">Current month</MenuItem>
          <MenuItem value="previousMonth">Previous month</MenuItem>
          <Divider />
          <MenuItem value="last30Days">Last 30 days</MenuItem>
          <MenuItem value="last90Days">Last 90 days</MenuItem>
          <MenuItem value="yearToDate">Year to date</MenuItem>
          <Divider />
          <MenuItem value="custom">Custom</MenuItem>
        </TextField>
        {rangeType === 'custom' && (
          <>
            <DatePicker
              name="start-date"
              format="yyyy-MM-dd"
              autoOk
              emptyLabel="-"
              maxDate={dateRange.end}
              value={dateRange.start}
              onChange={handleDateChange('start')}
              TextFieldComponent={props => (
                <TextField
                  {...props}
                  margin="dense"
                  variant="outlined"
                  InputProps={{
                    endAdornment: (
                      <IconButton size="small">
                        <CalendarTodayOutlined fontSize="small" />
                      </IconButton>
                    ),
                  }}
                />
              )}
            />
            <DatePicker
              name="end-date"
              format="yyyy-MM-dd"
              autoOk
              emptyLabel="-"
              minDate={dateRange.start}
              value={dateRange.end}
              onChange={handleDateChange('end')}
              TextFieldComponent={props => (
                <TextField
                  {...props}
                  margin="dense"
                  variant="outlined"
                  InputProps={{
                    endAdornment: (
                      <IconButton size="small">
                        <CalendarTodayOutlined fontSize="small" />
                      </IconButton>
                    ),
                  }}
                />
              )}
            />
          </>
        )}
      </Box>
    </MuiPickersUtilsProvider>
  );
}
