import React from 'react';
import { useNavigate, useParams } from 'react-router-dom';

import { Typography } from '@material-ui/core';
import SettingsIcon from '@material-ui/icons/Settings';
import { Alert } from '@material-ui/lab';

import {
  Breadcrumbs,
  Content,
  FilterListProvider,
  FloatingHeader,
  FloatingHeaderActions,
  Header,
  Page,
} from '@spotify-confidence/core-react';

import { Link } from '@backstage/core-components';
import { useRouteRef } from '@backstage/core-plugin-api';

import { useSurface } from '../../hooks/useSurface';
import { makeSurfaceName } from '../../lib/utils';
import { surfaceIndexRouteRef, surfaceSettingsRouteRef } from '../../routes';
import { SurfaceExperimentsPage } from '../SurfaceExperimentsPage';

export const SurfacePage = () => {
  const pageRef = React.useRef<HTMLDivElement>(null);

  const params = useParams();
  const navigate = useNavigate();
  const name = makeSurfaceName(params.id!);

  const surfaceIndexRoute = useRouteRef(surfaceIndexRouteRef);
  const surfaceSettingsRoute = useRouteRef(surfaceSettingsRouteRef);

  const { surface, loading, error } = useSurface(name);

  return (
    <Page ref={pageRef}>
      <FloatingHeader pageElement={pageRef.current}>
        <Breadcrumbs disableMargins>
          <Link to={surfaceIndexRoute()}>Surfaces</Link>
          <Typography noWrap>{surface?.displayName ?? ''}</Typography>
        </Breadcrumbs>
        <FloatingHeaderActions.Root>
          <FloatingHeaderActions.Item
            onClick={() => navigate(surfaceSettingsRoute({ id: params.id! }))}
            icon={<SettingsIcon />}
          >
            Settings
          </FloatingHeaderActions.Item>
        </FloatingHeaderActions.Root>
      </FloatingHeader>
      <Header
        enableMargin
        title={surface?.displayName ?? ''}
        isLoading={loading}
      />
      <Content>
        {error && <Alert severity="error">{error.message}</Alert>}

        <FilterListProvider searchField="name" storageKey="surface">
          <SurfaceExperimentsPage name={name} />
        </FilterListProvider>
      </Content>
    </Page>
  );
};
