import React from 'react';

import { Button, Typography } from '@material-ui/core';
import Add from '@material-ui/icons/Add';

import {
  ActionBar,
  Breadcrumbs,
  FilterBar,
  FilterListProvider,
  PageLayout,
  useDialog,
  useMeasureDimensions,
} from '@spotify-confidence/core-react';
import { useCheckResourcePermissions } from '@spotify-confidence/plugin-permissions-react';

import { Link } from '@backstage/core-components';
import { useRouteRef } from '@backstage/core-plugin-api';

import { rootRouteRef } from '../../../routes';
import { CreateFlagAppliedConnectionDialog } from '../CreateFlagAppliedConnectionDialog';
import { FlagAppliedList } from './FlagAppliedList';

export const FlagAppliedIndexPage = () => {
  const adminRoute = useRouteRef(rootRouteRef);
  const { openDialog } = useDialog();
  const [ref, { height }] = useMeasureDimensions<HTMLDivElement>();

  const { allowed } = useCheckResourcePermissions({
    can: 'create_flag_applied_connection',
    name: 'account',
  });
  const handleCreate = () => {
    openDialog({
      dialogProps: {
        fullScreen: true,
      },
      content: <CreateFlagAppliedConnectionDialog />,
    });
  };

  return (
    <FilterListProvider storageKey="flag-applied">
      <PageLayout
        narrow
        title="Flag applied connections"
        headerBreadcrumbs={
          <Breadcrumbs>
            <Link to={adminRoute()}>Admin</Link>
            <Typography>Flag applied connections</Typography>
          </Breadcrumbs>
        }
      >
        <ActionBar ref={ref}>
          <FilterBar />
          <Button
            size="small"
            variant="contained"
            color="primary"
            startIcon={<Add />}
            onClick={handleCreate}
            disabled={!allowed}
          >
            Create
          </Button>
        </ActionBar>

        <FlagAppliedList topOffset={height} />
      </PageLayout>
    </FilterListProvider>
  );
};
