import React from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { useDebounce, useSessionStorage } from 'react-use';

import {
  Box,
  Button,
  Checkbox,
  CircularProgress,
  Collapse,
  Dialog,
  FormControl,
  FormControlLabel,
  FormHelperText,
  FormLabel,
  InputLabel,
  Link,
  Radio,
  RadioGroup,
  TextField,
  styled,
} from '@material-ui/core';

import {
  ConfidenceWebsiteLink,
  FormFieldsBox,
  ImpressionTracker,
  LoadingOverlay,
  WizardStepper,
  useAlert,
  useAuth,
  useFeatureFlag,
} from '@spotify-confidence/core-react';

import { useAnalytics } from '@backstage/core-plugin-api';

import { TransitionScreen } from '../shared';
import { useTaxValidation } from './BillingInfo';
import { CompanyInfo } from './CompanyInfo';
import { ConfidenceLogoHorizontal } from './ConfidenceLogoHorizontal';
import { CountryInfo } from './CountryInfo';
import { IntroStep } from './IntroStep';
import { useAddressValidation } from './hooks/useAddressValidation';
import {
  AuthConnection,
  BillingDetails,
  BillingDetailsTaxType,
  CreateAccountRequest,
  Region,
  useCreateAccount,
} from './hooks/useCreateAccount';
import { CreatedAccount, InviteResponse } from './hooks/useInvite';
import { useValidateAccountName } from './hooks/useValidateAccountName';
import CreateWorkspace from './img/create_workspace.png';
import MadeForBusinesses from './img/made_for_businesses.png';
import UnavailableMarket from './img/unavailable_market.png';

export type AccountDetailsState = Omit<
  CreateAccountRequest,
  'authConnections' | 'allowedLoginEmailDomains' | 'billingDetails'
> & {
  billingDetails: BillingDetails;
  authConnections: Record<AuthConnection, boolean>;
  loginDomain: string;
};

const DEFAULT_STATE: AccountDetailsState = {
  billingDetails: {
    email: '',
    taxId: '',
    taxType: BillingDetailsTaxType.EuVat,
    displayName: '',
    address: {
      line1: '',
      city: '',
      state: '',
      postalCode: '',
      country: '',
    },
  },
  displayName: '',
  loginId: '',
  region: Region.RegionEu,
  loginDomain: '',
  authConnections: {
    googleAuthConnection: false,
    passwordAuthConnection: false,
  },
};
const BrandingWrapper = styled('div')(({ theme }) => ({
  position: 'fixed',
  top: 0,
  left: theme.spacing(2),
  height: 64,
  [theme.breakpoints.up('sm')]: {
    left: 0,
  },
}));

export const Branding = () => (
  <BrandingWrapper>
    <ConfidenceLogoHorizontal />
  </BrandingWrapper>
);

const NAME_MAX_LENGTH = 21;
const NAME_MIN_LENGTH = 3;

const isDisplayNameValid = (displayName: string) => {
  const displayNameRegex = new RegExp(
    /[a-zA-Z][a-zA-Z0-9\\s\\-]{1,20}[a-zA-Z0-9]/,
  );

  return (
    displayNameRegex.test(displayName) &&
    displayName.length >= NAME_MIN_LENGTH &&
    displayName.length <= NAME_MAX_LENGTH
  );
};

const formatDisplayName = (displayName: string) => {
  return displayName.replace(/[^a-zA-Z0-9 -]/g, '').slice(0, NAME_MAX_LENGTH);
};

const formatLoginId = (loginId: string = '') => {
  return loginId
    .trim()
    .replace(/\s+/g, '-')
    .replace(/[^a-zA-Z0-9-]/g, '')
    .toLowerCase()
    .slice(0, NAME_MAX_LENGTH);
};

const isLoginIdValid = (loginId: string) => {
  const loginIdRegex = new RegExp(/[a-z][a-z0-9\\-]{1,19}[a-z0-9]$/);
  return (
    loginIdRegex.test(loginId) &&
    loginId.length >= NAME_MIN_LENGTH &&
    loginId.length <= NAME_MAX_LENGTH
  );
};

const isEmailNotVerifiedError = (error?: Error) => {
  return Boolean(error?.message.includes('Email not verified'));
};

export const CreateAccountForm = ({
  accountInvite,
  inviteId,
  onCreate,
  token,
  skipCompanyWizard,
}: {
  token?: string;
  accountInvite?: InviteResponse;
  inviteId?: string;
  skipCompanyWizard?: boolean;
  onCreate: (account?: CreatedAccount) => void;
}) => {
  const [searchParams, setSearchParams] = useSearchParams();
  const analytics = useAnalytics();
  const alert = useAlert();
  const [step, setStep] = React.useState(skipCompanyWizard ? 2 : 0);
  const navigate = useNavigate();
  const {
    isAuthenticated,
    isLoading,
    user,
    loginWithPopup,
    error: authError,
  } = useAuth();

  const { enabled: entitled } = useFeatureFlag<{ enabled: boolean }>('signup', {
    enabled: false,
  });

  const [{ value: createdAccount, loading: creating, error }, createAccount] =
    useCreateAccount(inviteId);

  React.useEffect(() => {
    if (authError) {
      if (!isEmailNotVerifiedError(authError)) {
        alert.post({
          message: `Failed to log in: ${authError.message}`,
          severity: 'error',
        });
      }
    }
  }, [authError]);

  React.useEffect(() => {
    if (skipCompanyWizard) {
      setStep(currentStep => Math.max(currentStep, 2));
    }
  }, [skipCompanyWizard]);

  // Form
  const [acceptTerms, setAcceptTerms] = React.useState<boolean>(false);
  const [accountDetails, setAccountDetails] =
    useSessionStorage<AccountDetailsState>(
      `signup-${inviteId ?? 'regular'}`,
      DEFAULT_STATE,
    );

  React.useEffect(() => {
    if (createdAccount) {
      onCreate(createdAccount);
    }
  }, [createdAccount]);

  const setField = React.useCallback(
    (newData: Partial<AccountDetailsState>) => {
      setAccountDetails({
        ...accountDetails,
        ...newData,
      });
    },
    [setAccountDetails, accountDetails],
  );

  const toggleAuthConnection = (connection: AuthConnection) => {
    return (_e: React.ChangeEvent<{}>, checked: boolean) => {
      setField({
        authConnections: {
          ...accountDetails.authConnections,
          [connection]: checked,
        },
      });
    };
  };

  React.useEffect(() => {
    if (accountInvite) {
      setField({
        adminEmail: accountInvite.email,
        loginDomain: accountInvite.autoJoinDomain,
      });
    }
  }, [accountInvite]);

  const hasSelectedAuthConnection = Object.values(
    accountDetails.authConnections,
  ).some(Boolean);

  const userAuthConnection: AuthConnection = user?.sub?.includes(
    'google-oauth2',
  )
    ? 'googleAuthConnection'
    : 'passwordAuthConnection';

  //   Account name validation
  const [isUnsupportedMarket, setIsUnsupportedMarket] = React.useState(false);
  const [availabilityState, checkAvailability] = useValidateAccountName();
  const { error: addressError, isValid: isAddressValid } = useAddressValidation(
    accountDetails.billingDetails.address,
  );
  const [loginId, setLoginId] = React.useState('');
  const [modified, setModified] = React.useState<string[]>([]);

  useDebounce(
    () => {
      if (!isLoginIdValid(loginId)) return;
      checkAvailability({
        loginId: loginId,
        inviteId: inviteId,
      });
    },
    300,
    [loginId],
  );

  const loginIdAvailable = availabilityState.value
    ? availabilityState.value.available ?? true
    : true;

  const loginIdError = availabilityState.value
    ? availabilityState.value.message
    : false;

  const validDisplayName = modified.includes('displayName')
    ? isDisplayNameValid(accountDetails.displayName)
    : true;

  let displayNameHelperText = 'This is typically your company name.';
  if (!validDisplayName) {
    if (!/^[a-zA-Z]/.test(accountDetails.displayName)) {
      displayNameHelperText = 'The name should start with a letter.';
    } else if (
      accountDetails.displayName.endsWith('-') ||
      accountDetails.displayName.endsWith(' ')
    ) {
      displayNameHelperText = `The name cannot end with "-" or a space.`;
    } else {
      displayNameHelperText = `The name must be between ${NAME_MIN_LENGTH} and ${NAME_MAX_LENGTH} characters.`;
    }
  }

  const validLoginId = modified.includes('loginId')
    ? isLoginIdValid(loginId)
    : true;

  let loginIdHelpText =
    "This is used when logging in to Confidence. It can't be changed later.";
  if (!loginIdAvailable) {
    loginIdHelpText = 'This workspace ID is already in use.';
  } else if (availabilityState.loading) {
    loginIdHelpText = 'Checking availability...';
  } else if (!!loginIdError) {
    loginIdHelpText = loginIdError;
  } else if (modified.includes('loginId') && loginId.endsWith('-')) {
    loginIdHelpText = `The ID cannot end with "-".`;
  } else if (!validLoginId) {
    loginIdHelpText = `The ID must be between ${NAME_MIN_LENGTH} and ${NAME_MAX_LENGTH} characters.`;
  }

  // Don't allow navigating through the form by changing the URL
  React.useEffect(() => {
    searchParams.delete('step');
    setSearchParams(searchParams);
  }, []);

  // User sign in
  const handleLogin = async () => {
    await loginWithPopup(
      { authorizationParams: { screen_hint: 'signup' } },
      {
        timeoutInSeconds: 600,
      },
    );
  };

  React.useEffect(() => {
    // automatically fill in information based on signed in user
    if (user) {
      if (user.sub?.includes('google')) {
        setField({
          authConnections: {
            ...accountDetails.authConnections,
            googleAuthConnection: true,
          },
          ...(user.email && !user.email.includes('gmail')
            ? {
                loginDomain: user.email.replace(/.*@/, ''),
              }
            : {}),
        });
      } else {
        setField({
          authConnections: {
            ...accountDetails.authConnections,
            passwordAuthConnection: true,
          },
        });
      }
    }
  }, [user]);

  // Create account
  const handleCreate = () => {
    if (!user) return;
    if (isUnsupportedMarket) {
      navigate(
        `/signup/unsupported-market?country=${accountDetails.billingDetails.address.country}`,
      );
      return;
    }
    createAccount(
      {
        displayName: accountDetails.displayName,
        loginId: loginId,
        region: accountDetails.region,
        authConnections: Object.entries(accountDetails.authConnections)
          .filter(([_, v]) => v)
          .map(([connection]) => ({
            [connection]: {},
          })) as CreateAccountRequest['authConnections'],
        adminEmail:
          accountDetails.adminEmail !== ''
            ? accountDetails.adminEmail
            : undefined,
        allowedLoginEmailDomains: accountDetails.authConnections
          .googleAuthConnection
          ? [accountDetails.loginDomain].filter(Boolean)
          : undefined,
        billingDetails: skipCompanyWizard
          ? undefined
          : {
              ...accountDetails.billingDetails,
              email: user.email!,
            },
      },
      token,
    );
    setAccountDetails(DEFAULT_STATE);
  };

  const { isValid, loading: loadingTax } = useTaxValidation(
    accountDetails.billingDetails.taxType,
    accountDetails.billingDetails.address.country,
    accountDetails.billingDetails.taxId,
  );

  const validCompanyInfo =
    accountDetails.billingDetails.displayName.length !== 0 && isAddressValid;

  const validTaxId = isValid && !loadingTax;

  if (isLoading) return <LoadingOverlay />;

  if (isAuthenticated) {
    return (
      <Dialog open fullScreen>
        <Branding />
        {step === 0 && (
          <ImpressionTracker subject="Signup: Business Screen">
            <IntroStep
              title={
                <>
                  Confidence is made for businesses.
                  <br /> Ready to dive in?
                </>
              }
              description="We'll start by asking you some information on your organisation!"
              buttonLabel="Let's go"
              img={MadeForBusinesses}
              onNext={() => {
                setStep(1);
              }}
            />
          </ImpressionTracker>
        )}
        {step === 1 && !isUnsupportedMarket && (
          <WizardStepper
            subject="Signup: Company Wizard"
            onStepChange={newStep => {
              if (!accountDetails.billingDetails.taxType) {
                analytics.captureEvent(
                  'unavailable-market-selected',
                  accountDetails?.billingDetails.address?.country,
                );
                setIsUnsupportedMarket(true);
                return;
              }

              if (!entitled) {
                analytics.captureEvent(
                  'unavailable-market-selected',
                  accountDetails?.billingDetails.address?.country,
                );
                setIsUnsupportedMarket(true);
                return;
              }
              setStep(newStep);
              setIsUnsupportedMarket(false);
            }}
            submitLabel="Next"
            onSubmit={() => {
              searchParams.delete('step');
              setSearchParams(searchParams);
              setStep(2);
            }}
            steps={[
              {
                name: 'Select country',
                description:
                  'Select the country where your company is registered.',
                disableNext:
                  accountDetails.billingDetails.address.country === '',
                content: (
                  <CountryInfo
                    value={accountDetails.billingDetails}
                    onChange={v => {
                      setField({
                        billingDetails: {
                          ...accountDetails.billingDetails,
                          ...v,
                        },
                      });
                    }}
                  />
                ),
              },
              {
                name: 'Enter your company details',
                description:
                  'We just need to know a bit more about your company.',
                disableNext: !validCompanyInfo || !validTaxId,
                content: (
                  <CompanyInfo
                    error={addressError}
                    value={accountDetails.billingDetails}
                    onChange={v => {
                      if (v.displayName)
                        setLoginId(formatLoginId(v.displayName));
                      setField({
                        ...(v.displayName
                          ? { displayName: v.displayName }
                          : {}),
                        billingDetails: {
                          ...accountDetails.billingDetails,
                          ...v,
                        },
                      });
                    }}
                  />
                ),
              },
            ]}
          />
        )}
        {step === 1 && isUnsupportedMarket && (
          <IntroStep
            title="We're not in your country just yet, but don't worry!"
            description="We are working on expanding Confidence to more countries. Click 'Notify Me' so you can be the first to know when we make it to your country."
            buttonLabel="Notify me"
            img={UnavailableMarket}
            onNext={() => {
              navigate(
                `/signup/unsupported-market?country=${accountDetails.billingDetails.address.country}`,
              );
            }}
          />
        )}
        {step === 2 && (
          <IntroStep
            title={
              <>
                Let's configure your <br /> organisation's workspace
              </>
            }
            description="Configure your workspace by answering a few questions."
            buttonLabel="Configure workspace"
            img={CreateWorkspace}
            onNext={() => {
              searchParams.delete('step');
              setStep(3);
            }}
          />
        )}
        {step === 3 && (
          <WizardStepper
            subject="Signup: Workspace Wizard"
            submitLabel="Create workspace"
            onSubmit={handleCreate}
            loading={creating}
            error={error}
            steps={[
              {
                name: 'Set workspace name',
                description:
                  'The workspace is where you and your team set up, run and analyze experiments.',
                disableNext: !loginId || !loginIdAvailable || !validDisplayName,
                content: (
                  <FormFieldsBox spacing={2}>
                    <TextField
                      helperText={displayNameHelperText}
                      variant="outlined"
                      label="Name"
                      placeholder="For example My Company"
                      value={accountDetails.displayName}
                      error={!validDisplayName}
                      required
                      onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                        setField({
                          displayName: formatDisplayName(e.target.value),
                        })
                      }
                      onBlur={() => {
                        if (!modified.includes('displayName')) {
                          setModified(current => current.concat('displayName'));
                        }
                      }}
                      fullWidth
                    />

                    <TextField
                      helperText={loginIdHelpText}
                      variant="outlined"
                      label="Workspace ID"
                      placeholder="For example my-company"
                      value={loginId}
                      required
                      name="loginId"
                      onBlur={() => {
                        if (!modified.includes('loginId')) {
                          setModified(current => current.concat('loginId'));
                        }
                      }}
                      inputProps={{
                        maxLength: NAME_MAX_LENGTH,
                      }}
                      onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                        setLoginId(formatLoginId(e.target.value))
                      }
                      error={!loginIdAvailable || !validLoginId || loginIdError}
                      InputProps={{
                        endAdornment: availabilityState.loading && (
                          <CircularProgress size="1rem" />
                        ),
                      }}
                      fullWidth
                    />
                  </FormFieldsBox>
                ),
              },

              {
                name: 'Set up how to log into Confidence',
                description:
                  'Choose the authentication methods that are available for people in your company to access Confidence.',
                disableNext: !hasSelectedAuthConnection,
                content: (
                  <>
                    <FormControl component="fieldset">
                      <FormControlLabel
                        value="passwordAuthConnection"
                        control={
                          <Checkbox
                            color="primary"
                            checked={
                              accountDetails.authConnections
                                .passwordAuthConnection
                            }
                            required={!hasSelectedAuthConnection}
                            disabled={
                              userAuthConnection === 'passwordAuthConnection'
                            }
                          />
                        }
                        onChange={toggleAuthConnection(
                          'passwordAuthConnection',
                        )}
                        label={`E-mail ${
                          userAuthConnection === 'passwordAuthConnection'
                            ? '(you used this when signing up)'
                            : ''
                        }`}
                      />
                      <FormControlLabel
                        value="googleAuthConnection"
                        control={
                          <Checkbox
                            color="primary"
                            checked={
                              accountDetails.authConnections
                                .googleAuthConnection
                            }
                            required={!hasSelectedAuthConnection}
                            disabled={
                              userAuthConnection === 'googleAuthConnection'
                            }
                          />
                        }
                        onChange={toggleAuthConnection('googleAuthConnection')}
                        label={`Google ${
                          userAuthConnection === 'googleAuthConnection'
                            ? '(you used this when signing up)'
                            : ''
                        }`}
                      />
                    </FormControl>

                    <Collapse
                      in={accountDetails.authConnections.googleAuthConnection}
                    >
                      <Box marginTop={4}>
                        <InputLabel>Google login options</InputLabel>
                        <FormControl component="fieldset">
                          <FormControlLabel
                            value="invite-only"
                            control={
                              <Radio
                                color="primary"
                                checked={
                                  accountDetails.loginDomain === undefined
                                }
                              />
                            }
                            onChange={() =>
                              setField({ loginDomain: undefined })
                            }
                            label="Only invited users can log in"
                          />
                          <FormControlLabel
                            value="invite-only"
                            control={
                              <Radio
                                color="primary"
                                checked={
                                  accountDetails.loginDomain !== undefined
                                }
                              />
                            }
                            onChange={() => setField({ loginDomain: '' })}
                            label={
                              <>
                                Allow everyone from a specific domain to login
                              </>
                            }
                          />
                          <Collapse
                            in={accountDetails.loginDomain !== undefined}
                          >
                            <TextField
                              label="Google login domain"
                              variant="outlined"
                              name="google-login-domain"
                              placeholder="For example spotify.com"
                              value={
                                accountDetails.authConnections
                                  .googleAuthConnection
                                  ? accountDetails.loginDomain
                                  : ''
                              }
                              onChange={(
                                e: React.ChangeEvent<HTMLInputElement>,
                              ) => setField({ loginDomain: e.target.value })}
                              disabled={
                                !accountDetails.authConnections
                                  .googleAuthConnection
                              }
                              fullWidth
                            />
                          </Collapse>
                        </FormControl>
                      </Box>
                    </Collapse>
                  </>
                ),
              },
              {
                name: 'Choose a region for storing your data',
                description:
                  'Confidence can store your data in your different regions around the world. ' +
                  'Storing data at the right place in the world is important because of data regulations. ' +
                  'Select the region that best fits your company. If you are unsure, select EU.',
                disableNext: !accountDetails.region,
                content: (
                  <FormControl component="fieldset">
                    <FormLabel required>Region</FormLabel>
                    <RadioGroup
                      aria-label="region"
                      name="region"
                      value={accountDetails.region}
                      onChange={(_e, region) =>
                        setField({ region: region as Region })
                      }
                    >
                      <FormControlLabel
                        value="REGION_EU"
                        control={<Radio color="primary" />}
                        label="EU"
                      />
                      <FormControlLabel
                        value="REGION_US"
                        control={<Radio color="primary" />}
                        label="US"
                      />
                    </RadioGroup>
                    <FormHelperText>
                      Note: this can't be changed later.
                    </FormHelperText>
                  </FormControl>
                ),
              },
              {
                name: 'And finally: Terms and conditions',
                disableNext: !acceptTerms,
                content: (
                  <div>
                    <FormControlLabel
                      control={
                        <Checkbox
                          color="primary"
                          checked={acceptTerms}
                          onChange={(_e, checked) => setAcceptTerms(checked)}
                          name="terms-of-service"
                        />
                      }
                      label={
                        <>
                          I agree to the{' '}
                          <ConfidenceWebsiteLink
                            underline="always"
                            route="/terms-of-service"
                          >
                            beta program terms of service
                          </ConfidenceWebsiteLink>
                          .
                        </>
                      }
                    />
                  </div>
                ),
              },
            ]}
          />
        )}
      </Dialog>
    );
  }

  if (isEmailNotVerifiedError(authError)) {
    return (
      <ImpressionTracker subject="Signup: Verify email screen">
        <TransitionScreen
          title="Verify your e&#8209;mail address"
          description="Please click on the link we sent you to verify your e&#8209;mail address."
          actions={
            <Button variant="contained" color="primary" onClick={handleLogin}>
              Log in
            </Button>
          }
          helperText={
            <>
              Didn't receive a verification e-mail?{' '}
              <Link
                underline="always"
                href="mailto:confidence-support@spotify.com"
                target="_blank"
              >
                Contact support
              </Link>
              .
            </>
          }
        />
      </ImpressionTracker>
    );
  }

  return (
    <ImpressionTracker subject="Signup: Welcome Screen">
      <TransitionScreen
        title="Welcome to Confidence"
        description="Sign up with your work e-mail address, not a personal account."
        actions={
          <Button variant="contained" color="primary" onClick={handleLogin}>
            Sign up
          </Button>
        }
      />
    </ImpressionTracker>
  );
};
