import React, { ComponentProps } from 'react';

import { Theme, makeStyles } from '@material-ui/core';

import classNames from 'classnames';

import {
  Breadcrumbs as BackstageBreadcrumbs,
  BackstageOverrides,
} from '@backstage/core-components';

const useStyles = makeStyles<
  Theme & { overrides: BackstageOverrides },
  { disableMargins?: boolean }
>(theme => ({
  root: {
    ...(theme.overrides?.BackstageHeader?.breadcrumb || {}),
    minWidth: 0,
    marginBottom: ({ disableMargins }) =>
      disableMargins ? 0 : theme.spacing(2),
  },
  ol: {
    maxWidth: '100%',
    flexWrap: 'nowrap',
  },
  li: {
    flexShrink: 0,
    minWidth: 0,
    '&:last-child': {
      flexShrink: 1,
    },
  },
}));

export type BreadcrumbsProps = ComponentProps<typeof BackstageBreadcrumbs> & {
  disableMargins?: boolean;
};

export const Breadcrumbs = ({
  className,
  children,
  disableMargins,
}: BreadcrumbsProps) => {
  const classes = useStyles({ disableMargins });

  return (
    <BackstageBreadcrumbs
      color="inherit"
      className={classNames(classes.root, className)}
      children={children}
      classes={{ ol: classes.ol, li: classes.li }}
    />
  );
};
