import { JwtPayload, jwtDecode } from 'jwt-decode';

import { Region } from '../../../utils';

export const REGION_CLAIM = 'https://confidence.dev/region';

export type ConfidenceJwtPayload = JwtPayload & { [REGION_CLAIM]: Region };

export const getRegionFromTokenOrDefault = (
  token: string,
  defaultRegion: Region = 'EU',
): Region => {
  try {
    const decoded = jwtDecode<ConfidenceJwtPayload>(token);
    return decoded[REGION_CLAIM] === 'US' ? 'US' : defaultRegion;
  } catch (error) {
    return defaultRegion;
  }
};
