import React from 'react';
import ReactDOM from 'react-dom';

import '@spotify-internal/encore-web/css/fonts.css';

import '@backstage/cli/asset-types';

import { App } from './App';
import './bootstrap';

ReactDOM.render(<App />, document.getElementById('root'));
