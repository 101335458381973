import React from 'react';

import { Typography } from '@material-ui/core';

import { CodeSnippet } from '../../CodeSnippet/CodeSnippet';
import { f } from '../../CodeSnippet/format';
import { TUTORIAL_FLAG_NAME } from '../../FlagStep/constants';

export const JsReactSnippets = () => {
  return (
    <>
      <Typography variant="body1" gutterBottom>
        To track an event, update your code with a button and the following
        method call. Click the button to emit an event to Confidence.
      </Typography>
      <CodeSnippet
        language="typescript"
        showLineNumbers
        highlightedNumbers={[11, 12, 13, 14, 15, 16, 17]}
        text={f(`
import { useConfidence } from '@spotify-confidence/react';

export const MyComponent = () => {
  const confidence = useConfidence();
  const title = confidence.useFlag('${TUTORIAL_FLAG_NAME}.title', 'Default Title');
  const message = confidence.useFlag('${TUTORIAL_FLAG_NAME}.message', 'Default message');
  return (
    <>
      <h1>{title}</h1>
      <p>{message}</p>
      <button
        onClick={() => {
          confidence.track("tutorial-event");
        }}
      >
        Send event
      </button>
    </>
  );
};
`)}
        showCopyCodeButton
      />
    </>
  );
};
