import React from 'react';

import {
  Box,
  Typography,
  TypographyProps,
  makeStyles,
} from '@material-ui/core';

import { useDisplayNames } from '@spotify-confidence/core-react';

const useStyles = makeStyles({ typography: { fontWeight: 'inherit' } });

export const MetricName = ({
  name,
  hideStatus,
  ...typographyProps
}: TypographyProps & {
  name: string;
  hideStatus?: boolean;
}) => {
  const classes = useStyles();
  const { displayNames } = useDisplayNames();

  return (
    <Box
      display="flex"
      flexDirection="column"
      alignItems="flex-start"
      gridGap={4}
    >
      <Typography
        className={classes.typography}
        color="textPrimary"
        {...typographyProps}
      >
        {displayNames.get(name) || name}
      </Typography>
    </Box>
  );
};
