import React from 'react';

import { Grid, GridSize } from '@material-ui/core';

export type SidebarContentProps = React.PropsWithChildren<{
  sideBarRight?: React.ReactNode;
  sideBarLeft?: React.ReactNode;
  className?: string;
}>;

export const SidebarContent = ({
  children,
  sideBarRight,
  sideBarLeft,
  className,
}: SidebarContentProps) => {
  const hasAnySidebar = !!sideBarLeft || !!sideBarRight;
  const hasBothSidebars = !!sideBarLeft && !!sideBarRight;

  const sidebarGridMd: GridSize = 4;
  const sidebarGridLg: GridSize = 3;
  let contentGridMd: GridSize = 12;
  let contentGridLg: GridSize = 12;

  if (hasAnySidebar) {
    contentGridMd = (contentGridMd -
      sidebarGridMd * (hasBothSidebars ? 2 : 1)) as GridSize;
    contentGridLg = (contentGridLg -
      sidebarGridLg * (hasBothSidebars ? 2 : 1)) as GridSize;
  }

  return (
    <Grid container spacing={4} className={className}>
      {sideBarLeft && (
        <Grid item xs={12} md={sidebarGridMd} lg={sidebarGridLg}>
          {sideBarLeft}
        </Grid>
      )}
      <Grid item xs={12} md={contentGridMd} lg={contentGridLg}>
        {children}
      </Grid>
      {sideBarRight && (
        <Grid item xs={12} md={sidebarGridMd} lg={sidebarGridLg}>
          {sideBarRight}
        </Grid>
      )}
    </Grid>
  );
};
