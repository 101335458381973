import React from 'react';

import {
  getError,
  getTypeOrNull,
  useListClientsQuery,
} from '@spotify-confidence/plugin-graphql';

export function useSortedClients() {
  const { data, loading, error } = useListClientsQuery();

  const sortedClients = React.useMemo(() => {
    const clients =
      getTypeOrNull(data?.clients, 'IamV1ListClientsResponse')?.clients ?? [];

    return [...clients].sort((a, b) =>
      b.createTime.localeCompare(a.createTime),
    );
  }, [data]);

  return {
    clients: sortedClients,
    loading,
    error: getError(data?.clients) || error,
  };
}
