import React from 'react';

import { Radio, RadioGroup } from '@material-ui/core';

import {
  ConfidenceWebsiteLink,
  FormControlCardLabel,
  WizardStepper,
  useDialog,
} from '@spotify-confidence/core-react';
import {
  ConnectorsV1FlagAppliedConnectionBigQueryFlagAppliedConnectionConfigInput as BigQueryInput,
  ConnectorsV1FlagAppliedConnectionInput,
  ConnectorsV1FlagAppliedConnectionDatabricksFlagAppliedConnectionConfigInput as DatabricksInput,
  ConnectorsV1FlagAppliedConnectionKinesisFlagAppliedConnectionConfigInput as KinesisInput,
  ListFlagAppliedConnectionsDocument,
  ConnectorsV1FlagAppliedConnectionPubsubFlagAppliedConnectionConfigInput as PubsubInput,
  ConnectorsV1FlagAppliedConnectionRedshiftFlagAppliedConnectionConfigInput as RedshiftInput,
  ConnectorsV1FlagAppliedConnectionSnowflakeFlagAppliedConnectionConfigInput as SnowflakeInput,
  getError,
  isType,
  useCreateFlagAppliedConnectionMutation,
} from '@spotify-confidence/plugin-graphql';

import bigQueryLogo from '../../../shared/resources/bigquery.svg';
import databricksLogo from '../../../shared/resources/databricks.svg';
import kinesisLogo from '../../../shared/resources/kinesis.svg';
import pubsubLogo from '../../../shared/resources/pubsub.svg';
import redshiftLogo from '../../../shared/resources/redshift.svg';
import snowflakeLogo from '../../../shared/resources/snowflake.svg';
import {
  BigQueryFlagAppliedConnectionForm,
  defaultBigQueryFlagAppliedInput,
} from '../BigQuery';
import {
  DatabricksFlagAppliedConnectionForm,
  defaultDatabricksFlagAppliedInput,
} from '../Databricks';
import { KinesisFlagAppliedConnectionForm } from '../Kinesis';
import { PubSubFlagAppliedConnectionForm } from '../PubSub';
import {
  RedshiftFlagAppliedConnectionForm,
  defaultRedshiftFlagAppliedInput,
} from '../Redshift';
import {
  SnowflakeFlagAppliedConnectionForm,
  defaultSnowflakeFlagAppliedInput,
} from '../Snowflake';

type ConnectionType = keyof Omit<
  ConnectorsV1FlagAppliedConnectionInput,
  'name' | 'labels' | 'proto_type_url' | 'owner' | 'builtin'
>;

type ConnectionInput =
  | BigQueryInput
  | DatabricksInput
  | KinesisInput
  | PubsubInput
  | RedshiftInput
  | SnowflakeInput;

type ConnectionDetails = { name: string; link: string };

const connectionDetails: Record<ConnectionType, ConnectionDetails> = {
  bigQuery: {
    name: 'BigQuery',
    link: '/docs/api/connectors/flag-applied',
  },
  databricks: {
    name: 'Databricks',
    link: '/docs/api/connectors/flag-applied',
  },
  snowflake: {
    name: 'Snowflake',
    link: '/docs/api/connectors/flag-applied',
  },
  redshift: {
    name: 'Redshift',
    link: '/docs/api/connectors/flag-applied',
  },
  pubsub: {
    name: 'Pub/Sub',
    link: '/docs/api/connectors/flag-applied',
  },
  kinesis: {
    name: 'Kinesis',
    link: '/docs/api/connectors/flag-applied',
  },
};

export const CreateFlagAppliedConnectionDialog = () => {
  const { closeDialog } = useDialog();
  const [type, setType] = React.useState<ConnectionType | ''>('');
  const [inputData, setInputData] =
    React.useState<ConnectorsV1FlagAppliedConnectionInput>({});

  const handleSelectType = (e: React.ChangeEvent<HTMLInputElement>) => {
    const newType = e.target.value as ConnectionType;
    if (newType !== type) {
      setType(newType);
      switch (newType) {
        case 'databricks': {
          setInputData({ databricks: defaultDatabricksFlagAppliedInput });
          break;
        }
        case 'redshift': {
          setInputData({ redshift: defaultRedshiftFlagAppliedInput });
          break;
        }
        case 'bigQuery': {
          setInputData({ bigQuery: defaultBigQueryFlagAppliedInput });
          break;
        }
        case 'snowflake': {
          setInputData({ snowflake: defaultSnowflakeFlagAppliedInput });
          break;
        }

        default:
          setInputData({});
      }
    }
  };

  const handleClose = () => {
    closeDialog();
  };

  function onInputChange(connectionType: ConnectionType) {
    return (data: ConnectionInput) => {
      setInputData({
        [connectionType]: data,
      });
    };
  }

  const [
    createFlagAppliedConnection,
    { loading: isCreating, data, error: createError },
  ] = useCreateFlagAppliedConnectionMutation();

  const handleCreate = () => {
    if (type) {
      createFlagAppliedConnection({
        variables: {
          flagAppliedConnection: inputData,
        },
        awaitRefetchQueries: true,
        refetchQueries: [ListFlagAppliedConnectionsDocument],
        onCompleted: response => {
          if (
            isType(
              response.createFlagAppliedConnection,
              'ConnectorsV1FlagAppliedConnection',
            )
          ) {
            handleClose();
          }
        },
      });
    }
  };

  const details = type
    ? connectionDetails[type]
    : { name: 'Configuration', link: '/docs' };

  const error = getError(data?.createFlagAppliedConnection) ?? undefined;

  return (
    <WizardStepper
      onClose={handleClose}
      submitLabel="Create"
      onSubmit={handleCreate}
      error={createError ?? error}
      loading={isCreating}
      formName="flag-applied-form"
      steps={[
        {
          name: 'Connection Type',
          description: `Select the type of connection you want to use to consume assignment data from flags.`,
          disableNext: !type,
          content: (
            <>
              <RadioGroup
                aria-label="type"
                name="flag-applied-connection-type"
                value={type}
                onChange={handleSelectType}
              >
                <FormControlCardLabel
                  value="bigQuery"
                  variant="outlined"
                  control={<Radio color="primary" />}
                  label="BigQuery"
                  description="Export assignment data to a table in BigQuery."
                  src={bigQueryLogo}
                />
                <FormControlCardLabel
                  value="databricks"
                  variant="outlined"
                  control={<Radio color="primary" />}
                  label="Databricks"
                  description="Export assignment data to a table in Databricks, by first writing the data as Parquet files to a S3 bucket, and then importing these files into the specified table."
                  src={databricksLogo}
                />
                <FormControlCardLabel
                  value="snowflake"
                  variant="outlined"
                  control={<Radio color="primary" />}
                  label="Snowflake"
                  description="Export assignment data to a table in Snowflake."
                  src={snowflakeLogo}
                />
                <FormControlCardLabel
                  value="redshift"
                  variant="outlined"
                  control={<Radio color="primary" />}
                  label="Redshift"
                  description="Export assignment data to a table in Redshift, by first writing the data as Parquet files to a S3 bucket, and then importing these files into the configured table."
                  src={redshiftLogo}
                />
                <FormControlCardLabel
                  value="pubsub"
                  variant="outlined"
                  control={<Radio color="primary" />}
                  label="Pub/Sub"
                  description="Forward assignment data to a Pub/Sub topic."
                  src={pubsubLogo}
                />
                <FormControlCardLabel
                  value="kinesis"
                  variant="outlined"
                  control={<Radio color="primary" />}
                  label="Kinesis"
                  description="Forward assignment data to a Kinesis stream."
                  src={kinesisLogo}
                />
              </RadioGroup>
            </>
          ),
        },
        {
          name: `${details.name} connection`,
          description: (
            <span>
              You can read more about how to create a {details.name} connection
              in our{' '}
              <ConfidenceWebsiteLink route={details.link} underline="always">
                documentation
              </ConfidenceWebsiteLink>
              .
            </span>
          ),
          content: (
            <div data-testid="create-connection-dialog">
              {type === 'bigQuery' && (
                <BigQueryFlagAppliedConnectionForm
                  value={inputData.bigQuery!}
                  onChange={onInputChange('bigQuery')}
                />
              )}
              {type === 'databricks' && (
                <DatabricksFlagAppliedConnectionForm
                  value={inputData.databricks!}
                  onChange={onInputChange('databricks')}
                />
              )}
              {type === 'snowflake' && (
                <SnowflakeFlagAppliedConnectionForm
                  value={inputData.snowflake!}
                  onChange={onInputChange('snowflake')}
                />
              )}
              {type === 'redshift' && (
                <RedshiftFlagAppliedConnectionForm
                  value={inputData.redshift!}
                  onChange={onInputChange('redshift')}
                />
              )}
              {type === 'pubsub' && (
                <PubSubFlagAppliedConnectionForm
                  value={inputData.pubsub}
                  onChange={onInputChange('pubsub')}
                />
              )}
              {type === 'kinesis' && (
                <KinesisFlagAppliedConnectionForm
                  value={inputData.kinesis}
                  onChange={onInputChange('kinesis')}
                />
              )}
            </div>
          ),
        },
      ]}
    />
  );
};
