import { createApiRef } from '@backstage/core-plugin-api';

/**
 * An API for uploading images to a backend and returning a URL from which
 * they can be accessed.
 *
 * @public
 */
export interface ImageUploadClient {
  /**
   * Uploads an image and returns a URL from which it can be accessed.
   *
   * @param file - The image file to upload.
   * @returns A promise that resolves to a URL from which the image can be accessed.
   */
  upload: (file: File) => Promise<string>;
}

export const imageUploadApiRef = createApiRef<ImageUploadClient>({
  id: 'plugin.image-upload',
});
