import React from 'react';

import { useSaveWorkflowInstanceModuleDataMutation } from '@spotify-confidence/plugin-workflows';

import { StatsData } from './types';

export function useSaveStats({
  name,
  statsData,
}: {
  name: string;
  statsData?: StatsData;
}) {
  const input: StatsData = React.useMemo(
    () => ({
      alpha: statsData?.alpha || 0.05,
      power: statsData?.power || 0.8,
      fallbackSampleSize: statsData?.fallbackSampleSize || 0,
      testHorizonStrategy: statsData?.testHorizonStrategy,
    }),
    [statsData],
  );

  const [save, queryResult] = useSaveWorkflowInstanceModuleDataMutation();

  return {
    save: async (newData: Partial<StatsData>) => {
      await save({
        variables: {
          workflowInstance: {
            name,
            moduleData: [
              {
                key: 'stats',
                value: {
                  ...input,
                  ...newData,
                },
              },
            ],
          },
          updateMask: 'moduleData.stats',
        },
      });
    },
    ...queryResult,
  };
}
