import React, { ReactElement } from 'react';
import { useNavigate } from 'react-router-dom';

import { MenuItemProps, TextField } from '@material-ui/core';
import FileCopyOutlinedIcon from '@material-ui/icons/FileCopyOutlined';

import {
  ConfirmDialog,
  FloatingHeaderActions,
  useAlert,
  useDialog,
} from '@spotify-confidence/core-react';
import {
  getError,
  getTypeOrNull,
  useCloneMutation,
} from '@spotify-confidence/plugin-graphql';
import {
  WorkflowInstance,
  WorkflowPermissionGuard,
  workflowInstanceRouteRef,
} from '@spotify-confidence/plugin-workflows';

import { useRouteRef } from '@backstage/core-plugin-api';

import { hasFunction } from '../../modules/helpers';

const CloneDialog = ({
  workflowInstance,
  onLoading,
}: {
  workflowInstance: NonNullable<WorkflowInstance>;
  onLoading: () => void;
}): ReactElement => {
  const navigate = useNavigate();
  const alert = useAlert();
  const workflowInstanceRoute = useRouteRef(workflowInstanceRouteRef);

  const [newDisplayName, setNewDisplayName] = React.useState<string>('');
  const [clone, cloneData] = useCloneMutation({
    variables: {
      name: workflowInstance.name,
      newDisplayName,
    },
    onCompleted: data => {
      const response = getTypeOrNull(
        data.executeFunction,
        'WorkflowV1ExecuteFunctionResponse',
      );
      const error = getError(data.executeFunction);
      if (response?.response?.instance?.name) {
        const name = response?.response?.instance?.name;
        const [_, workflow, __, id] = name.split('/');
        const workflowInstanceLink =
          workflowInstanceRoute?.({ workflow: workflow!, id: id! }) ?? '';
        navigate(workflowInstanceLink);
      } else if (error) {
        alert.post({
          severity: 'error',
          message: error.message,
        });
      }
    },
  });

  React.useEffect(() => {
    if (cloneData.loading) {
      onLoading();
    }
  }, [cloneData, onLoading]);

  return (
    <ConfirmDialog
      onConfirm={clone}
      title="Clone"
      confirmDisabled={!newDisplayName.length}
    >
      Clone this A/B test to create a new draft with the same setup. The
      original test will not be modified.
      <TextField
        fullWidth
        placeholder="Enter new name..."
        name="new-dislay-name"
        variant="outlined"
        size="small"
        value={newDisplayName}
        // eslint-disable-next-line jsx-a11y/no-autofocus
        autoFocus
        onChange={e => setNewDisplayName(e.target.value)}
      />
    </ConfirmDialog>
  );
};

export const CloneMenuItem = ({
  workflowInstance,
  disabled,
  ...props
}: Omit<MenuItemProps, 'children'> & {
  workflowInstance: NonNullable<WorkflowInstance>;
}): ReactElement => {
  const { openDialog } = useDialog();
  const [loading, setLoading] = React.useState<boolean>(false);

  if (!workflowInstance || !hasFunction(workflowInstance, 'clone')) {
    return <></>;
  }

  const confirmClone = () => {
    return openDialog({
      content: (
        <CloneDialog
          workflowInstance={workflowInstance}
          onLoading={() => setLoading(true)}
        />
      ),
    });
  };

  return (
    <WorkflowPermissionGuard type="function" name="clone">
      {({ allowed }) => (
        <FloatingHeaderActions.Item
          {...props}
          disabled={disabled || loading || !allowed}
          onClick={confirmClone}
          icon={<FileCopyOutlinedIcon />}
        >
          Clone
        </FloatingHeaderActions.Item>
      )}
    </WorkflowPermissionGuard>
  );
};
