import React from 'react';

import {
  Button,
  IconButton,
  MenuItem,
  Select,
  Theme,
  makeStyles,
} from '@material-ui/core';
import ChevronLeft from '@material-ui/icons/ChevronLeft';
import ChevronRight from '@material-ui/icons/ChevronRight';

import classNames from 'classnames';
import { Duration, add } from 'date-fns';
import startOfDay from 'date-fns/startOfDay';
import _ from 'lodash';

type TimeControlProps = {
  className?: string;
  originDate: Date;
  onOriginDateChange: (newDate: Date) => void;
  timeRange: TimeRange;
  onTimeRangeChange: (newTimeRange: TimeRange) => void;
  timeWindowJumpSize: Duration;
};

const useStyles = makeStyles((theme: Theme) => ({
  timeControls: {
    display: 'flex',
    gap: theme.spacing(2),
    alignItems: 'center',
    padding: theme.spacing(0, 2),
  },
  timeNavigationArrows: {
    display: 'flex',
    alignItems: 'center',
    gap: theme.spacing(1),
  },
  timeRangeSelect: {
    width: 120,
  },
}));

const timeRanges = ['Week', 'Month', 'Quarter', 'Year'] as const;
export type TimeRange = (typeof timeRanges)[number];

export const TimeControls = ({
  originDate,
  onOriginDateChange,
  timeRange,
  onTimeRangeChange,
  timeWindowJumpSize,
  className,
}: TimeControlProps) => {
  const classes = useStyles();
  const today = startOfDay(new Date());

  const resetTime = () => {
    onOriginDateChange(today);
  };

  const handleTimeRangeChange = (e: React.ChangeEvent<{ value: unknown }>) => {
    onTimeRangeChange(e.target.value as TimeRange);
  };

  const jumpBack = () => {
    onOriginDateChange(
      add(
        originDate,
        _.mapValues(timeWindowJumpSize, amount => -1 * (amount ?? 0)),
      ),
    );
  };

  const jumpForward = () => {
    onOriginDateChange(add(originDate, timeWindowJumpSize));
  };

  return (
    <div className={classNames(classes.timeControls, className)}>
      <div className={classes.timeNavigationArrows}>
        <IconButton size="small" onClick={jumpBack}>
          <ChevronLeft />
        </IconButton>
        <IconButton size="small" onClick={jumpForward}>
          <ChevronRight />
        </IconButton>
      </div>
      <Select
        value={timeRange}
        className={classes.timeRangeSelect}
        onChange={handleTimeRangeChange}
        variant="outlined"
        margin="dense"
      >
        {timeRanges.map(range => (
          <MenuItem key={range} value={range}>
            {range}
          </MenuItem>
        ))}
      </Select>
      <Button onClick={resetTime}>Today</Button>
    </div>
  );
};
