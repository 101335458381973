export const removeDemoDataRequiredPermissions = [
  {
    relation: 'can_edit_workflow_instance',
    resource: 'account',
  },
  {
    relation: 'can_edit_metric',
    resource: 'account',
  },
  {
    relation: 'can_edit_scheduled_metric_calculation',
    resource: 'account',
  },
  {
    relation: 'can_edit_metric_calculation',
    resource: 'account',
  },
  {
    relation: 'can_edit_scheduled_exposure_calculation',
    resource: 'account',
  },
  {
    relation: 'can_edit_entity',
    resource: 'account',
  },
  {
    relation: 'can_edit_assignment_table',
    resource: 'account',
  },
  {
    relation: 'can_edit_fact_table',
    resource: 'account',
  },
  {
    relation: 'can_edit_dimension_table',
    resource: 'account',
  },
  {
    relation: 'can_edit_data_warehouse',
    resource: 'account',
  },
  {
    relation: 'can_edit_flag',
    resource: 'account',
  },
  {
    relation: 'can_edit_segment',
    resource: 'account',
  },
  {
    relation: 'can_edit_client',
    resource: 'account',
  },
  {
    relation: 'can_edit_api_client',
    resource: 'account',
  },
  {
    relation: 'can_edit_flag_applied_connection',
    resource: 'account',
  },
];
