import React, { Fragment, useCallback, useState } from 'react';

import {
  Box,
  List,
  ListItem,
  ListItemSecondaryAction,
  ListItemText,
  Switch,
  Typography,
} from '@material-ui/core';

import {
  FeatureFlagState,
  featureFlagsApiRef,
  useApi,
} from '@backstage/core-plugin-api';

export function FeatureFlagsToggles() {
  const flagsApi = useApi(featureFlagsApiRef);
  const flags = flagsApi.getRegisteredFlags();

  const initialFlagState = Object.fromEntries(
    flags.map(({ name }) => [name, flagsApi.isActive(name)]),
  );

  const [state, setState] = useState<Record<string, boolean>>(initialFlagState);

  const toggleFlag = useCallback(
    (flagName: string) => {
      const newState = flagsApi.isActive(flagName)
        ? FeatureFlagState.None
        : FeatureFlagState.Active;
      flagsApi.save({
        states: { [flagName]: newState },
        merge: true,
      });

      setState(prevState => ({
        ...prevState,
        [flagName]: newState === FeatureFlagState.Active,
      }));
    },
    [flagsApi],
  );

  if (!flags.length) return null;

  return (
    <Fragment>
      <Box paddingX={1.5} paddingTop={1.5}>
        <Typography variant="subtitle1">Feature flags</Typography>
      </Box>
      <List>
        {flags.map(flag => (
          <ListItem key={flag.name}>
            <ListItemText
              primary={flag.name}
              secondary={flag.description}
              primaryTypographyProps={{
                variant: 'body2',
              }}
              secondaryTypographyProps={{ variant: 'caption' }}
            />
            <ListItemSecondaryAction style={{ right: 0 }}>
              <Switch
                checked={state[flag.name]}
                onChange={() => toggleFlag(flag.name)}
              />
            </ListItemSecondaryAction>
          </ListItem>
        ))}
      </List>
    </Fragment>
  );
}
