import loadable from '@loadable/component';

const componentsChunk = () =>
  import(/* webpackChunkName: "metrics-components" */ './components.chunk');

export const Recommendation = loadable(() =>
  componentsChunk().then(m => m.Recommendation),
);

export * from './types';
export * from './Recomendation/GuardrailCondition';
export * from './Recomendation/SuccessCondition';
export * from './Recomendation/HealthCheckCondition';
