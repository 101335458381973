import { useAsyncFn } from 'react-use';

export enum Region {
  RegionEu = 'REGION_EU',
  RegionUs = 'REGION_US',
}
export type AuthConnection = 'googleAuthConnection' | 'passwordAuthConnection';

export enum BillingDetailsTaxType {
  EuVat = 'EU_VAT',
  GbVat = 'GB_VAT',
  UsEin = 'US_EIN',
  NoVat = 'NO_VAT',
  AdNrt = 'AD_NRT',
  IsVat = 'IS_VAT',
  RsPib = 'RS_PIB',
  ChVat = 'CH_VAT',
  TrTin = 'TR_TIN',
  UaVat = 'UA_VAT',
  CaBn = 'CA_BN',
  MxRfc = 'MX_RFC',
  IlVat = 'IL_VAT',
  ArCuit = 'AR_CUIT',
  BoTin = 'BO_TIN',
  ClTin = 'CL_TIN',
  CoNit = 'CO_NIT',
  UyRuc = 'UY_RUC',
  VeRif = 'VE_RIF',
  IdNpwp = 'ID_NPWP',
  MyFrp = 'MY_FRP',
  PhTin = 'PH_TIN',
  SgGst = 'SG_GST',
  ThVat = 'TH_VAT',
  VnTin = 'VN_TIN',
  KrBrn = 'KR_BRN',
  TwVat = 'TW_VAT',
  JpTrn = 'JP_TRN',
}

export type BillingDetails = {
  address: {
    city?: string;
    country: string;
    line1?: string;
    line2?: string;
    postalCode?: string;
    state?: string;
  };
  displayName: string;
  email: string;
  taxId: string;
  taxType: BillingDetailsTaxType;
};

export type CreateAccountRequest = {
  displayName: string;
  loginId: string;
  allowedLoginEmailDomains?: string[];
  adminEmail?: string;
  region: Region;
  authConnections: Record<AuthConnection, {}>[];
  billingDetails?: BillingDetails;
};

export function useCreateAccount(inviteId?: string) {
  return useAsyncFn(
    async (params: CreateAccountRequest, accessToken?: string) => {
      const { billingDetails, ...account } = params;
      if (!accessToken) {
        throw new Error(
          'You need to supply an access token to create an account.',
        );
      }
      let url = 'https://onboarding.confidence.dev/v1/accounts';
      if (inviteId) {
        url += `?invite=accountInvites%2f${inviteId}`;
      }
      const response = await fetch(url, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${accessToken}`,
        },
        body: JSON.stringify({ account, billingDetails }),
      });
      const json = await response.json();
      if (!response.ok) {
        throw new Error(json.message);
      }
      return json;
    },
    [],
  );
}
