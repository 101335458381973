import React from 'react';

import { Typography } from '@material-ui/core';

import { CodeSnippet, SnippetProps } from '../../CodeSnippet';
import { f } from '../../CodeSnippet';
import { TUTORIAL_FLAG_NAME } from '../constants';

export const AndroidClientSnippets = (props: SnippetProps) => {
  return (
    <ol>
      <li>
        <Typography variant="body1" gutterBottom>
          Add the following dependency to your gradle file:
        </Typography>
        <CodeSnippet
          language="kotlin"
          text={f(`
          implementation("com.spotify.confidence:confidence-sdk-android:0.3.1")
`)}
          showCopyCodeButton
        />
      </li>
      <li>
        <Typography variant="body1" gutterBottom>
          Confidence needs the internet permission to fetch the flags. Add the
          internet permission to your android app manifest file.
        </Typography>
        <CodeSnippet
          language="kotlin"
          text={f(`
          <uses-permission android:name="android.permission.INTERNET" />
`)}
          showCopyCodeButton
        />
      </li>
      <li>
        <Typography variant="body1" gutterBottom>
          Initialize the Confidence instance. We also want to set the
          `visitor_id` context to "tutorial_visitor", to match the targeting
          criteria pre-configured for this tutorial.
        </Typography>
        <CodeSnippet
          language="kotlin"
          text={f(`
import com.spotify.confidence.ConfidenceFactory
import com.spotify.confidence.ConfidenceValue

val confidence = ConfidenceFactory.create(
    context = applicationContext,
    clientSecret = "${props.apiKey}"
)
confidence.putContext(key = "visitor_id", value = ConfidenceValue.String("tutorial_visitor"))
`)}
          showCopyCodeButton
        />
      </li>
      <li>
        <Typography variant="body1" gutterBottom>
          Fetch the flag evaluations from backend. The suspending function
          `fetchAndActivate()` fetches the latest values from backend before
          returning, and should be called as early as possible in your
          application. after fetching the flags, We can get the title in the "
          {TUTORIAL_FLAG_NAME}" flag.
        </Typography>
        <CodeSnippet
          language="kotlin"
          text={f(`
coroutineScope.launch {
  confidence.fetchAndActivate()
// flags are ready to be resolved here
  val flagTitle = confidence.getValue(
      key = "${TUTORIAL_FLAG_NAME}.title",
      default = "Default"
    )
}
      `)}
          showCopyCodeButton
        />
      </li>
    </ol>
  );
};
