import React from 'react';

import {
  Button,
  IconProps,
  LinearProgress,
  List,
  ListItem,
  ListItemText,
  ListSubheader,
  makeStyles,
} from '@material-ui/core';
import CheckCircleOutlineIcon from '@material-ui/icons/CheckCircleOutline';
import HighlightOffIcon from '@material-ui/icons/HighlightOff';
import RadioButtonUncheckedIcon from '@material-ui/icons/RadioButtonUnchecked';

import { MetricsV1ValidateDataWarehouseConfigResponseValidation } from '@spotify-confidence/plugin-graphql';

type ValidationChecklistProps = {
  title?: string;
  checks?: MetricsV1ValidateDataWarehouseConfigResponseValidation[];
  loading?: boolean;
  onValidate?: () => void;
  canValidate?: boolean;
};

const useStyles = makeStyles(theme => ({
  listItemRoot: {
    gap: theme.spacing(1),
  },
  checkIcon: {
    alignSelf: 'baseline',
  },
  listItemTextRoot: {
    margin: 0,
  },
}));

export const ValidationCheckList = ({
  title,
  checks = [],
  loading,
  onValidate,
  canValidate,
}: ValidationChecklistProps) => {
  const classes = useStyles();
  const hasNotValidated = checks.every(check => !check.error && !check.success);

  return (
    <>
      <List disablePadding>
        {title && <ListSubheader disableGutters>{title}</ListSubheader>}
        {loading && <LinearProgress />}
        {checks.map(check => {
          let Icon = RadioButtonUncheckedIcon;
          let iconColor: IconProps['color'] = 'inherit';
          if (check.success) {
            Icon = CheckCircleOutlineIcon;
            iconColor = 'primary';
          } else if (check.error) {
            Icon = HighlightOffIcon;
            iconColor = 'error';
          }
          return (
            <ListItem
              key={check.key}
              classes={{ root: classes.listItemRoot }}
              disableGutters
            >
              <Icon
                fontSize="small"
                className={classes.checkIcon}
                color={iconColor}
              />
              <ListItemText
                classes={{ root: classes.listItemTextRoot }}
                primary={check.description}
                primaryTypographyProps={{
                  variant: 'body2',
                  color: check.error ? 'error' : undefined,
                  gutterBottom: true,
                }}
              />
            </ListItem>
          );
        })}
      </List>
      {onValidate && (
        <div>
          <Button
            size="small"
            onClick={onValidate}
            disabled={loading || !canValidate}
            variant="outlined"
          >
            {hasNotValidated ? 'Validate' : 'Refresh'}
          </Button>
        </div>
      )}
    </>
  );
};
