import React from 'react';

import { Box, Collapse } from '@material-ui/core';
import {
  ToggleButton,
  ToggleButtonGroup,
  ToggleButtonProps,
} from '@material-ui/lab';

import { TimeWindowInput } from './TimeWindowInput';

type SelectTimeWindowProps = {
  value: string;
  onChange: (newValue?: string) => void;
  options?: PresetOption[];
  disabled?: boolean;
  name?: string;
  size?: ToggleButtonProps['size'];
};

type PresetOption = { label: string; value: string };

export const selectTimeWindowDefaultOptions: PresetOption[] = [
  { label: '1 hour', value: '3600s' },
  { label: '1 day', value: '86400s' },
  { label: '1 week', value: '604800s' },
];

export const SelectTimeWindow = ({
  value,
  onChange,
  options = selectTimeWindowDefaultOptions,
  disabled,
  name = '',
  size,
}: SelectTimeWindowProps) => {
  const hasCustomValue = !options.some(o => o.value === value);
  const [customEnabled, setIsCustomEnabled] = React.useState(hasCustomValue);
  const isCustom = hasCustomValue || customEnabled;

  const handlePresetChange = (option: PresetOption) => () => {
    setIsCustomEnabled(false);
    onChange(option.value);
  };

  return (
    <Box
      display="flex"
      flexDirection="column"
      data-testid={`${name}-select-container`}
    >
      <ToggleButtonGroup>
        {options.map(option => (
          <ToggleButton
            size={size}
            disabled={disabled}
            key={option.value}
            value={option.value}
            selected={!isCustom && option.value === value}
            onClick={handlePresetChange(option)}
          >
            {option.label}
          </ToggleButton>
        ))}
        <ToggleButton
          size={size}
          selected={isCustom}
          disabled={disabled}
          onClick={() => setIsCustomEnabled(true)}
        >
          Custom
        </ToggleButton>
      </ToggleButtonGroup>

      <Collapse in={isCustom}>
        <TimeWindowInput
          margin="dense"
          label=""
          value={value}
          onChange={onChange}
          disabled={disabled}
        />
      </Collapse>
    </Box>
  );
};
