import React from 'react';

import { Typography } from '@material-ui/core';

import {
  NumberUtils,
  Numeral,
  NumeralProps,
} from '@spotify-confidence/core-react';

type CommonTooltipProps = {
  lowerCI?: number;
  upperCI?: number;
  unit: string;
};

type BaseTooltipProps = CommonTooltipProps & {
  formatter?: NumeralProps['formatter'];
};

export function BaseTooltip({
  lowerCI,
  upperCI,
  unit,
  formatter = NumberUtils.formatNumberFixed2,
}: BaseTooltipProps) {
  return (
    <>
      <Typography>
        <strong>Lower bound:</strong>{' '}
        <Numeral value={lowerCI} formatter={formatter} />
        {unit}
      </Typography>
      <Typography gutterBottom>
        <strong>Upper bound:</strong>{' '}
        <Numeral value={upperCI} formatter={formatter} />
        {unit}
      </Typography>
    </>
  );
}
