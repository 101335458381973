import React from 'react';

import { Collapse, DialogActions, TextField } from '@material-ui/core';
import { Alert } from '@material-ui/lab';

import {
  DialogBody,
  DialogForm,
  DialogHeader,
  FormFieldsBox,
  FormSubmitButtons,
  StringUtils,
  useAlert,
  useDialog,
} from '@spotify-confidence/core-react';
import {
  isType,
  useCreateUserInvitationMutation,
} from '@spotify-confidence/plugin-graphql';

const listFormat = new Intl.ListFormat('en', {
  style: 'short',
  type: 'disjunction',
});

export const CreateInviteDialog = ({
  onCreated,
  hasAutoJoin,
  allowedLoginEmailDomains = [],
}: {
  onCreated?: (name: string) => void;
  hasAutoJoin?: boolean;
  allowedLoginEmailDomains?: string[];
}) => {
  const { closeDialog } = useDialog();
  const alert = useAlert();
  const [invitedEmail, setInvitedEmail] = React.useState('');
  const [dirty, setDirty] = React.useState(false);
  const validEmail = StringUtils.isValidEmail(invitedEmail);

  const [createInvitation, { loading, error }] =
    useCreateUserInvitationMutation();

  const handleCreateInvitation = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    createInvitation({
      variables: {
        userInvitation: {
          invitedEmail,
        },
      },
      onCompleted: response => {
        if (isType(response.createUserInvitation, 'IamV1UserInvitation')) {
          closeDialog();
          onCreated?.(response.createUserInvitation.name);
        } else {
          alert.post({
            severity: 'error',
            message:
              response.createUserInvitation?.message ?? 'Something went wrong',
          });
        }
      },
      refetchQueries: ['ListUserInvitations'],
    });
  };

  const handleBlur = () => {
    setDirty(!!invitedEmail.length);
  };

  return (
    <DialogForm
      onSubmit={handleCreateInvitation}
      data-testid="create-invitation-dialog"
    >
      <DialogHeader
        title="Create invitation"
        subTitle="Enter the email for the person you want to invite to this Confidence workspace."
      />
      <DialogBody>
        {error && <Alert severity="error">{error.message}</Alert>}
        <FormFieldsBox spacing={1}>
          <TextField
            name="createinvitation"
            variant="outlined"
            required
            type="email"
            label="Email"
            value={invitedEmail}
            onBlur={handleBlur}
            onChange={e => setInvitedEmail(e.target.value)}
            fullWidth
            helperText={
              <Collapse in={!validEmail && dirty}>
                Please enter a valid e-mail address.
              </Collapse>
            }
            error={!validEmail && dirty}
            autoFocus
          />
          {hasAutoJoin && allowedLoginEmailDomains.length > 0 && (
            <Alert severity="info">
              Everyone with
              {allowedLoginEmailDomains.length === 1
                ? ` a ${allowedLoginEmailDomains[0]} email`
                : ` an email in ${listFormat.format(
                    allowedLoginEmailDomains,
                  )}`}{' '}
              can access this workspace. You can still create an invite to send
              an email invitation, or to invite someone outside of your
              organization.
            </Alert>
          )}
        </FormFieldsBox>
      </DialogBody>
      <DialogActions>
        <FormSubmitButtons
          onCancel={closeDialog}
          loading={loading}
          disabled={!validEmail || loading}
          label="Create"
        />
      </DialogActions>
    </DialogForm>
  );
};
