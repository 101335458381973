import React from 'react';

import { TextField } from '@material-ui/core';

import { FormFieldsBox } from '@spotify-confidence/core-react';
import { MetricsV1MetricInput } from '@spotify-confidence/plugin-graphql';
import { IdentityAutocomplete } from '@spotify-confidence/plugin-permissions-react';

type AboutMetricFormProps = {
  value: MetricsV1MetricInput;
  onChange: (newInput: MetricsV1MetricInput) => void;
};

export const AboutMetricForm = ({ value, onChange }: AboutMetricFormProps) => {
  return (
    <FormFieldsBox>
      <TextField
        label="Name"
        name="metric-name"
        required
        value={value.displayName}
        onChange={e => onChange({ ...value, displayName: e.target.value })}
        variant="outlined"
      />
      <TextField
        label="Description"
        name="metric-description"
        multiline
        minRows={5}
        value={value.description}
        onChange={e => onChange({ ...value, description: e.target.value })}
        variant="outlined"
      />
      <IdentityAutocomplete
        value={value.owner}
        onChange={(_e, owner) => onChange({ ...value, owner })}
        label="Owner"
        required
        name="owner"
        data-testid="owner"
      />
    </FormFieldsBox>
  );
};
