import React from 'react';

import { Box, Typography, makeStyles, styled } from '@material-ui/core';

import { ConfidenceLogo } from '@spotify-confidence/core-react';

const MAX_PAGE_WIDTH = 700;

const Logo = styled(ConfidenceLogo)(({ theme }) => ({
  width: 100,
  minHeight: 100,
  [theme.breakpoints.up('sm')]: {
    width: 120,
    minHeight: 120,
  },
}));

const useStyles = makeStyles(theme => ({
  wrapper: {
    paddingTop: `min(8vh, ${theme.spacing(12)}px)`,
    paddingLeft: theme.spacing(3),
    paddingRight: theme.spacing(3),
    [theme.breakpoints.up('sm')]: {
      paddingTop: `min(20vh, ${theme.spacing(32)}px)`,
    },
  },
  title: {
    lineHeight: '1em',
  },
  description: {
    padding: theme.spacing(1, 0),
    fontSize: theme.typography.h6.fontSize,
    [theme.breakpoints.up('md')]: {
      fontSize: theme.typography.h5.fontSize,
    },
  },
}));

export type TransitionScreenProps = {
  title: React.ReactNode;
  graphics?: React.ReactNode;
  description?: React.ReactNode;
  actions?: React.ReactNode;
  helperText?: React.ReactNode;
};

export const TransitionScreen = ({
  title,
  graphics = <Logo fontSize="inherit" />,
  description,
  actions,
  helperText,
}: TransitionScreenProps) => {
  const classes = useStyles();
  return (
    <div className={classes.wrapper}>
      <Box width="100%" maxWidth={MAX_PAGE_WIDTH} margin="0 auto">
        {graphics && (
          <Box
            display="flex"
            justifyContent="center"
            maxHeight={300}
            marginBottom={6}
            maxWidth="100%"
          >
            {graphics}
          </Box>
        )}
        <Typography
          variant="h3"
          component="h1"
          align="center"
          className={classes.title}
        >
          {title}
        </Typography>
        {description && (
          <Typography
            component="div"
            variant="body1"
            color="textSecondary"
            align="center"
            className={classes.description}
          >
            {description}
          </Typography>
        )}
        {actions && (
          <Box
            display="flex"
            flexDirection="row"
            gridGap={8}
            alignItems="center"
            justifyContent="center"
            marginTop={4}
            marginBottom={helperText ? 4 : 0}
          >
            {actions}
          </Box>
        )}
        {helperText && (
          <Typography
            variant="body2"
            color="textSecondary"
            align="center"
            component="div"
          >
            {helperText}
          </Typography>
        )}
      </Box>
    </div>
  );
};
