import React, { Fragment } from 'react';

import {
  Button,
  ListItemText,
  Table,
  TableBody,
  TableCell,
  TableRow,
} from '@material-ui/core';
import Add from '@material-ui/icons/Add';

import {
  ActionBar,
  FilterBar,
  FilterListEmptyState,
  Highlight,
  ResourceTableRow,
  StickyTableContainer,
  StickyTableHead,
  extractLastNameComponent,
  useAlert,
  useDialog,
  useListFilter,
  useMeasureDimensions,
  useNavigate,
} from '@spotify-confidence/core-react';
import { isType } from '@spotify-confidence/plugin-graphql';
import { useCheckResourcePermissions } from '@spotify-confidence/plugin-permissions-react';

import { useRouteRef } from '@backstage/core-plugin-api';

import { groupRouteRef } from '../../../routes';
import { useGroups } from '../../hooks/useGroups';
import { CreateGroupDialog } from './CreateGroupDialog';

export const GroupList = () => {
  const { searchQuery } = useListFilter();
  const alert = useAlert();
  const [actionBarRef, { height: actionBarHeight }] =
    useMeasureDimensions<HTMLDivElement>();

  const { allowed } = useCheckResourcePermissions({
    name: 'account',
    can: 'create_group',
  });
  const { openDialog, closeDialog } = useDialog();
  const groupRoute = useRouteRef(groupRouteRef);
  const navigate = useNavigate();

  const { groups, loading, error, fetchMoreObserver, createGroup } =
    useGroups();

  const handleCreate = () => {
    openDialog({
      content: (
        <CreateGroupDialog
          onCreate={async ({ displayName, description }) => {
            const result = await createGroup({ displayName, description });
            if (isType(result, 'IamV1Group')) {
              const id = extractLastNameComponent(result?.name)!;
              navigate(groupRoute({ id }));
              closeDialog();
            } else {
              alert.post({
                severity: 'error',
                message: result?.message ?? 'Something went wrong',
              });
            }
          }}
        />
      ),
    });
  };

  const filteredGroups = (groups || []).filter(
    group =>
      group.displayName.toLowerCase().includes(searchQuery.toLowerCase()) ||
      group.description?.toLowerCase().includes(searchQuery.toLowerCase()),
  );

  return (
    <Fragment>
      <ActionBar ref={actionBarRef} topOffset={0}>
        <FilterBar />
        <Button
          startIcon={<Add />}
          variant="contained"
          color="primary"
          size="small"
          data-testid="create-group-button"
          onClick={handleCreate}
          disabled={!allowed}
        >
          Create
        </Button>
      </ActionBar>

      {filteredGroups.length === 0 && !loading ? (
        <FilterListEmptyState
          error={error}
          type="group"
          description="Add groups to manage access to your resources"
        />
      ) : (
        <StickyTableContainer loading={loading} error={error}>
          <Table>
            <StickyTableHead topOffset={actionBarHeight}>
              <TableRow>
                <TableCell>Name</TableCell>
              </TableRow>
            </StickyTableHead>
            <TableBody>
              {filteredGroups.map(group => (
                <ResourceTableRow
                  key={group.name}
                  name={group.name}
                  routeRef={groupRouteRef}
                >
                  <TableCell>
                    <ListItemText
                      primary={
                        <Highlight highlight={searchQuery}>
                          {group.displayName}
                        </Highlight>
                      }
                      secondary={
                        <Highlight highlight={searchQuery}>
                          {group.description ?? '(No description)'}
                        </Highlight>
                      }
                    />
                  </TableCell>
                </ResourceTableRow>
              ))}
            </TableBody>
          </Table>
          {fetchMoreObserver}
        </StickyTableContainer>
      )}
    </Fragment>
  );
};
