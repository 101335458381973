import React from 'react';

import { ConfirmDialog, useAlert } from '@spotify-confidence/core-react';
import {
  EvaluationContextFieldOverrideFragment,
  getError,
  useDeleteEvaluationContextFieldOverrideMutation,
} from '@spotify-confidence/plugin-graphql';

import { overrideRefetchQueries } from '../constants';

export const DeleteSchemaOverrideDialog = ({
  override,
}: {
  override: EvaluationContextFieldOverrideFragment;
}) => {
  const alert = useAlert();

  const [remove] = useDeleteEvaluationContextFieldOverrideMutation({
    refetchQueries: overrideRefetchQueries,
    update: (cache, data) => {
      const error = getError(data.data?.deleteEvaluationContextFieldOverride);
      if (!error) {
        cache.evict({
          id: cache.identify({
            __typename: 'FlagsAdminV1EvaluationContextFieldOverride',
            name: override.name,
          }),
        });
      }
    },
    onError: e => {
      alert.post({
        severity: 'error',
        message: e.message,
      });
    },
    onCompleted: response => {
      const responseError = getError(
        response.deleteEvaluationContextFieldOverride,
      );
      if (responseError) {
        alert.post({
          severity: 'error',
          message: responseError.message,
        });
      }
    },
  });

  const onConfirm = async () => {
    await remove({ variables: { name: override.name } });
  };

  return (
    <ConfirmDialog onConfirm={onConfirm} title="Remove override">
      This removes the override for the field "{override.field}". If the field
      is still present in the context schema, it will revert to its original
      state. Do you wish to proceed?
    </ConfirmDialog>
  );
};
