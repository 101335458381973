import React from 'react';

import { Box, Chip, Typography } from '@material-ui/core';
import AccessTime from '@material-ui/icons/AccessTime';

type StepTitleProps = {
  title: string;
  estimatedTime?: string;
};

export const StepTitle = ({ title, estimatedTime }: StepTitleProps) => {
  return (
    <Box
      display="flex"
      gridGap={8}
      alignItems="center"
      justifyContent="space-between"
      flexWrap="wrap"
      marginBottom={2}
    >
      <Typography variant="h3">{title}</Typography>
      {estimatedTime && (
        <Chip
          icon={<AccessTime />}
          label={`Estimated time: ${estimatedTime}`}
        />
      )}
    </Box>
  );
};
