import React from 'react';

import { Box, Collapse } from '@material-ui/core';

import {
  getTypeOrNull,
  useListClientCredentialsQuery,
} from '@spotify-confidence/plugin-graphql';

import { ProgrammingLanguage, useConnect } from '../context';

export type CodeSnippets = Record<
  ProgrammingLanguage,
  (apiKey: string) => React.ReactNode
>;

type Props = {
  snippets: CodeSnippets;
};

export const Instructions = ({ snippets }: Props) => {
  const { client, language } = useConnect();

  const credentialsData = useListClientCredentialsQuery({
    variables: {
      parent: client?.name!,
    },
    skip: !client,
  });

  const currentSecret =
    getTypeOrNull(
      credentialsData.data?.clientCredentials,
      'IamV1ListClientCredentialsResponse',
    )?.clientCredentials?.[0]?.clientSecret?.secret ?? '<API KEY>';

  return (
    <Collapse in={Boolean(client)}>
      <Box marginY={2}>
        <Box marginTop={2} display="flex" flexDirection="column" gridGap={16}>
          <Box>{snippets[language]?.(currentSecret)}</Box>
        </Box>
      </Box>
    </Collapse>
  );
};
