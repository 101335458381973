import React from 'react';

import { Box, Typography } from '@material-ui/core';

import { TagsInput } from '@spotify-confidence/core-react';
import {
  CommentZone,
  CommentZoneContext,
} from '@spotify-confidence/plugin-comments-react';
import { AllocationFragment, Maybe } from '@spotify-confidence/plugin-graphql';

import { defaultAllocation } from '../domain/segment.model';
import { ReadOnlyTags } from './ReadOnlyTags';

type ExclusionCriteriaProps = {
  readOnly?: boolean;
  allocation?: Maybe<AllocationFragment>;
  onAllocationChange: (allocation: AllocationFragment) => void;
};

export const ExclusionCriteria = ({
  readOnly = false,
  allocation,
  onAllocationChange,
}: ExclusionCriteriaProps) => {
  const handleChange = React.useCallback(
    (update: Partial<AllocationFragment>) => {
      onAllocationChange({
        ...defaultAllocation,
        ...allocation,
        ...update,
      });
    },
    [allocation, onAllocationChange],
  );

  return (
    <Box
      display="flex"
      flexDirection="column"
      justifyContent="space-between"
      height="100%"
    >
      <CommentZoneContext zone="exclusion-criteria">
        <Box mb={1}>
          <Typography
            variant="h6"
            component="label"
            htmlFor="exclusion-criteria"
          >
            <CommentZone id="title" component="span">
              Exclusion criteria
            </CommentZone>
          </Typography>
          <Typography variant="body1" color="textSecondary">
            Determines who will not be targeted for inclusion.
          </Typography>
        </Box>
        <CommentZone id="value">
          {readOnly ? (
            <ReadOnlyTags tags={allocation?.exclusiveTo} />
          ) : (
            <TagsInput
              id="exclusion-criteria"
              placeholder="Add tag"
              disabled={readOnly}
              freeSolo
              autoSelect
              value={allocation?.exclusiveTo ?? []}
              onChange={(_e, exclusiveTo = []) => handleChange({ exclusiveTo })}
            />
          )}
        </CommentZone>
      </CommentZoneContext>
    </Box>
  );
};
