import { styled } from '@material-ui/core';

export const RuleCardSection = styled('div')(({ theme }) => ({
  borderBottom: `1px solid ${theme.palette.action.hover}`,
  padding: theme.spacing(1, 0),
  display: 'flex',
  alignItems: 'baseline',
  gap: '0.4em',
  flexWrap: 'wrap',
  '&:last-child': {
    border: 'none',
  },
  '&:first-child': {
    paddingTop: 0,
  },
  [theme.breakpoints.up('lg')]: {
    paddingRight: 200,
  },
}));
