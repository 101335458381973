import React from 'react';

import { Box, Button, MenuItem, TextField } from '@material-ui/core';
import { Alert, AlertTitle } from '@material-ui/lab';

import { FormFieldsBox } from '@spotify-confidence/core-react';
import {
  MetricsV1AwsRegion,
  MetricsV1RedshiftConfigInput,
  getTypeOrNull,
  useValidateDataWarehouseConfigMutation,
} from '@spotify-confidence/plugin-graphql';

import { CopySQLButton } from './CopySQLButton';
import { OptionalAwsCredentialSettings } from './OptionalAwsCredentialSettings';

type RedshiftDatawarehouseFormProps = {
  value?: MetricsV1RedshiftConfigInput | null;
  onChange: (conf: MetricsV1RedshiftConfigInput) => void;
  disabled?: boolean;
  requireSecret?: boolean;
  warehouseName?: string;
};

const canValidate = (p: Partial<MetricsV1RedshiftConfigInput> = {}) =>
  !!(p.cluster && p.database && p.roleArn && p.region);

const defaultConfig: MetricsV1RedshiftConfigInput = {
  cluster: '',
  database: '',
  region: MetricsV1AwsRegion.EuWest_1,
  roleArn: '',
};

export const RedshiftDatawarehouseForm = ({
  value,
  onChange,
  disabled,
  requireSecret,
  warehouseName,
}: RedshiftDatawarehouseFormProps) => {
  const region: MetricsV1AwsRegion =
    value?.region ?? MetricsV1AwsRegion.EuWest_1;
  const roleArn: string = value?.roleArn ?? '';
  const cluster: string = value?.cluster ?? '';
  const database: string = value?.database ?? '';
  const exposureSchema: string = value?.exposureSchema ?? '';

  const isValid = canValidate(value ?? {});
  const [validate, validation] = useValidateDataWarehouseConfigMutation({
    variables: {
      existingWarehouse: warehouseName,
      redshiftConfig: value,
    },
  });

  const availableSchemas = React.useMemo(() => {
    const options =
      getTypeOrNull(
        validation?.data?.validateDataWarehouseConfig,
        'MetricsV1ValidateDataWarehouseConfigResponse',
      )?.redshiftConfigValidation?.availableSchemas || [];
    if (options.length > 0) {
      return options;
    }
    if (exposureSchema) {
      return [exposureSchema];
    }
    return [];
  }, [exposureSchema, validation.data]);

  const handleChange = React.useCallback(
    (field: keyof MetricsV1RedshiftConfigInput) =>
      (e: React.ChangeEvent<HTMLInputElement>) => {
        onChange({ ...defaultConfig, ...value, [field]: e.target.value });
      },
    [onChange],
  );

  return (
    <>
      <FormFieldsBox>
        <TextField
          disabled={disabled}
          select
          label="Region"
          variant="outlined"
          value={region}
          required
          fullWidth
          onChange={handleChange('region')}
        >
          {Object.values(MetricsV1AwsRegion).map(regionOption => (
            <MenuItem key={regionOption} value={regionOption}>
              {regionOption}
            </MenuItem>
          ))}
        </TextField>

        <OptionalAwsCredentialSettings
          value={value?.credentialsSettings ?? undefined}
          onChange={newValue =>
            onChange({
              ...defaultConfig,
              ...value,
              credentialsSettings: newValue,
            })
          }
          requireSecret={requireSecret}
        />

        <TextField
          disabled={disabled}
          label="Role ARN"
          variant="outlined"
          value={roleArn}
          required
          fullWidth
          inputProps={{
            minLength: 20,
          }}
          onChange={handleChange('roleArn')}
        />

        <TextField
          disabled={disabled}
          label="Cluster"
          variant="outlined"
          value={cluster}
          required
          fullWidth
          onChange={handleChange('cluster')}
        />

        <TextField
          disabled={disabled}
          label="Database"
          variant="outlined"
          value={database}
          required
          fullWidth
          onChange={handleChange('database')}
        />

        <Box display="flex" gridGap={8}>
          <Button
            size="small"
            variant="outlined"
            disabled={!isValid || validation.loading}
            onClick={() => validate()}
          >
            Validate and get schema options
          </Button>
          <CopySQLButton roleArn={roleArn} disabled={!isValid} />
        </Box>

        {validation.error && (
          <Alert severity="error">
            <AlertTitle>Validation failed</AlertTitle>
            {validation.error.message}
          </Alert>
        )}

        <TextField
          disabled={disabled || !validation.data || validation.loading}
          select
          label="Exposure schema"
          variant="outlined"
          value={exposureSchema}
          required
          fullWidth
          onChange={handleChange('exposureSchema')}
        >
          <MenuItem disabled value="">
            Select schema for exposure
          </MenuItem>
          {availableSchemas.map(availableSchema => (
            <MenuItem key={availableSchema} value={availableSchema}>
              {availableSchema}
            </MenuItem>
          ))}
        </TextField>
      </FormFieldsBox>
    </>
  );
};
