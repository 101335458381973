import React, { PropsWithChildren, forwardRef } from 'react';

import { WithStyles, createStyles, withStyles } from '@material-ui/core';

const styles = () =>
  createStyles({
    root: {
      flexGrow: 1,
    },
  });

export interface RecommendationCardContentProps
  extends WithStyles<typeof styles>,
    PropsWithChildren<{}> {}

const RecommendationCardContent = forwardRef<
  HTMLDivElement,
  RecommendationCardContentProps
>(function RecommendationCardStatus({ classes, children }, ref) {
  return (
    <div ref={ref} className={classes.root}>
      {children}
    </div>
  );
});

export default withStyles(styles)(RecommendationCardContent);
