import {
  createExternalRouteRef,
  createRouteRef,
  createSubRouteRef,
} from '@backstage/core-plugin-api';

// internal routes
export const rootRouteRef = createRouteRef({ id: 'admin-root' });

export const workflowSecretsRouteRef = createSubRouteRef({
  id: 'secrets',
  parent: rootRouteRef,
  path: '/workflow-secrets',
});

export const workflowSecretRouteRef = createSubRouteRef({
  id: 'secret',
  parent: rootRouteRef,
  path: '/workflow-secrets/:id',
});

export const policiesRouteRef = createSubRouteRef({
  id: 'policies',
  parent: rootRouteRef,
  path: '/policies',
});

export const groupsRouteRef = createSubRouteRef({
  id: 'groups',
  parent: rootRouteRef,
  path: '/groups',
});

export const groupRouteRef = createSubRouteRef({
  id: 'group',
  parent: rootRouteRef,
  path: '/groups/:id',
});

export const usersRouteRef = createSubRouteRef({
  id: 'users',
  parent: rootRouteRef,
  path: '/users',
});

export const invitationsRouteRef = createSubRouteRef({
  id: 'invitations',
  parent: rootRouteRef,
  path: '/users/invitations',
});

export const userRouteRef = createSubRouteRef({
  id: 'users',
  parent: rootRouteRef,
  path: '/users/:id',
});

export const apiClientsRouteRef = createSubRouteRef({
  id: 'apiClients',
  parent: rootRouteRef,
  path: '/api-clients',
});

export const apiClientRouteRef = createSubRouteRef({
  id: 'apiClient',
  parent: rootRouteRef,
  path: '/api-clients/:id',
});

export const clientsRouteRef = createSubRouteRef({
  id: 'clients',
  parent: rootRouteRef,
  path: '/clients',
});

export const clientRouteRef = createSubRouteRef({
  id: 'client',
  parent: rootRouteRef,
  path: '/clients/:id',
});

export const clientSchemaRouteRef = createSubRouteRef({
  id: 'clientSchema',
  parent: rootRouteRef,
  path: '/clients/schema',
});

export const oauthAppsRouteRef = createSubRouteRef({
  id: 'apiClients',
  parent: rootRouteRef,
  path: '/oauth-apps',
});

export const oauthAppRouteRef = createSubRouteRef({
  id: 'apiClient',
  parent: rootRouteRef,
  path: '/oauth-apps/:id',
});

export const metricsDataWarehouseRouteRef = createSubRouteRef({
  id: 'metricsDataWarehouse',
  parent: rootRouteRef,
  path: '/metrics-datawarehouse',
});

export const reportsRouteRef = createSubRouteRef({
  id: 'reports',
  parent: rootRouteRef,
  path: '/reports',
});

export const subscriptionRouteRef = createSubRouteRef({
  id: 'subscription',
  parent: rootRouteRef,
  path: '/subscription',
});

export const workflowsRouteRef = createSubRouteRef({
  id: 'workflows',
  parent: rootRouteRef,
  path: '/workflows',
});

export const workflowRouteRef = createSubRouteRef({
  id: 'workflow',
  parent: rootRouteRef,
  path: '/workflows/:id',
});

export const workflowInstancesRouteRef = createSubRouteRef({
  id: 'workflow-instances',
  parent: rootRouteRef,
  path: '/workflows/:id/instances',
});

export const workflowInstanceOverviewRouteRef = createSubRouteRef({
  id: 'workflow-instance-overview',
  parent: rootRouteRef,
  path: '/workflows/:id/instances/:instance',
});

export const workflowInstanceEventsRouteRef = createSubRouteRef({
  id: 'workflow-instance-events',
  parent: rootRouteRef,
  path: '/workflows/:id/instances/:instance/events',
});

export const workflowLogsRouteRef = createSubRouteRef({
  id: 'workflow-logs',
  parent: rootRouteRef,
  path: '/workflows/:id/logs',
});

export const flagAppliedConnectionsRouteRef = createSubRouteRef({
  id: 'flag-applied-connections',
  parent: rootRouteRef,
  path: '/connections/flag-applied',
});

export const flagAppliedConnectionRouteRef = createSubRouteRef({
  id: 'flag-applied-connection',
  parent: rootRouteRef,
  path: '/connections/flag-applied/:id',
});

export const eventConnectionsRouteRef = createSubRouteRef({
  id: 'event-connections',
  parent: rootRouteRef,
  path: '/connections/event',
});

export const eventConnectionRouteRef = createSubRouteRef({
  id: 'event-connection',
  parent: rootRouteRef,
  path: '/connections/event/:id',
});

// external routes
export const assignmentTableRouteRef = createExternalRouteRef({
  id: 'assignment-table',
  params: ['id'],
  optional: true,
});

export const entityRouteRef = createExternalRouteRef({
  id: 'entity',
  params: ['id'],
  optional: true,
});

export const entitiesRouteRef = createExternalRouteRef({
  id: 'entities',
  optional: true,
});
