/* eslint-disable no-console */
import {
  ApolloClient,
  ApolloLink,
  InMemoryCache,
  createHttpLink,
  from,
  split,
} from '@apollo/client';
import {
  ApolloCache,
  ModifierDetails,
  StoreObject,
} from '@apollo/client/cache';
import { setContext } from '@apollo/client/link/context';
import { onError } from '@apollo/client/link/error';
import { GraphQLWsLink } from '@apollo/client/link/subscriptions';
import { getMainDefinition } from '@apollo/client/utilities';
import { ObjUtils, Region } from '@spotify-confidence/core-react';
import { createClient } from 'graphql-ws';

import { Error as GraphQLErrorResult } from '../graphql';
import { namedMerger } from './namedMerger';

type GraphQLResult = { __typename?: string };
type ErrorResult = Partial<GraphQLErrorResult> & {
  __typename?: 'Error';
};
type ValueOfTypename<T extends GraphQLResult> = T['__typename'];

export function isType<
  Result extends GraphQLResult,
  Typename extends ValueOfTypename<Result>,
>(
  result: Result | null | undefined,
  typename: Typename,
): result is Extract<Result, { __typename: Typename }> {
  return result?.__typename === typename;
}

export function getError<Result extends GraphQLResult>(
  result: Result | null | undefined,
): Error | null {
  if (isError(result)) {
    const error = result as ErrorResult;
    return new Error(
      error.message ??
        error.code?.replaceAll('_', ' ') ??
        'Something went wrong',
    );
  }
  return null;
}

export function getFunctionError<Result extends GraphQLResult>(
  result: Result | null | undefined,
): Error | null {
  const error = getError(result);
  if (error) {
    return new Error(
      error.message.replace(/^Function failed \[/, '').replace(/\]$/, ''),
    );
  }

  return null;
}

export function getTypeOrNull<
  Result extends GraphQLResult,
  Typename extends ValueOfTypename<Result>,
>(
  result: Result | null | undefined,
  typename: Typename,
): Extract<Result, { __typename: Typename }> | null {
  return result?.__typename === typename
    ? (result as Extract<Result, { __typename: Typename }>)
    : null;
}

export function isError<Result extends GraphQLResult>(
  result: Result | null | undefined,
): result is Extract<Result, { __typename: 'Error' }> {
  return result?.__typename === 'Error';
}

export const evictTypeFields = <T>(
  cache: ApolloCache<Record<string, StoreObject>>,
  field: keyof T,
) => {
  cache.modify({
    fields: {
      [field]: (_: any, { DELETE }: ModifierDetails) => {
        return DELETE;
      },
    },
  });
};

export const createApolloClient = ({
  token,
  uri,
  region,
}: {
  token?: string;
  uri?: string;
  region?: Region;
}) => {
  // this will only be served for the inital load before we have a token
  if (!token || !uri || !region)
    return new ApolloClient({ cache: new InMemoryCache() });

  const httpLink = createHttpLink({ uri });

  const wsLink = new GraphQLWsLink(
    createClient({
      url: `wss://graphql-websocket.${region}.confidence.dev/websocket`,
      connectionParams: {
        authorization: token ? `Bearer ${token}` : '',
      },
    }),
  );

  const omitTypenameLink = new ApolloLink((operation, forward) => {
    if (operation.variables) {
      operation.variables = ObjUtils.deepOmit(
        operation.variables,
        '__typename',
      );
    }
    return forward(operation);
  });

  const authLink = setContext(async (_, { headers }) => {
    return {
      headers: {
        ...headers,
        authorization: token ? `Bearer ${token}` : '',
      },
    };
  });

  const errorLink = onError(({ graphQLErrors, networkError }) => {
    if (graphQLErrors)
      graphQLErrors.forEach(({ message, path }) =>
        console.error(`[GraphQL error]: Message: ${message}, Path: ${path}`),
      );

    if (networkError) console.error(`[Network error]: ${networkError}`);
  });

  // The split function takes three parameters:
  //
  // * A function that's called for each operation to execute
  // * The Link to use for an operation if the function returns a "truthy" value
  // * The Link to use for an operation if the function returns a "falsy" value
  const splitLink = split(
    ({ query }) => {
      const definition = getMainDefinition(query);
      return (
        definition.kind === 'OperationDefinition' &&
        definition.operation === 'subscription'
      );
    },
    wsLink,
    httpLink,
  );

  return new ApolloClient({
    connectToDevTools: true,
    defaultOptions: {
      watchQuery: {
        fetchPolicy: 'cache-and-network',
        errorPolicy: 'all',
      },
    },
    link: from([authLink, omitTypenameLink, errorLink, splitLink]),
    cache: new InMemoryCache({
      typePolicies: {
        WorkflowV1Workflow: {
          keyFields: ['name'],
        },
        WorkflowV1WorkflowInstance: {
          keyFields: ['name'],
          fields: {
            checks: {
              merge(_existing, incoming) {
                return incoming;
              },
            },
          },
        },

        WorkflowV1WorkflowSecret: {
          keyFields: ['name'],
        },
        WorkflowV1WorkflowVersion: {
          keyFields: ['name'],
        },
        IamV1OAuthApp: {
          keyFields: ['name'],
        },
        IamV1ApiClient: {
          keyFields: ['name'],
        },
        IamV1Identity: {
          keyFields: ['name'],
        },
        IamV1Group: {
          keyFields: ['name'],
        },
        IamV1CryptoKey: {
          keyFields: ['name'],
        },
        IamV1Policy: {
          keyFields: ['name'],
        },
        IamV1Role: {
          keyFields: ['name'],
        },
        IamV1CurrentUser: {
          merge(existing, incoming, { mergeObjects }) {
            return mergeObjects(existing, incoming);
          },
        },
        IamV1CurrentUserAccountMembership: {
          keyFields: ['account'],
        },
        FgaV1ResourcePermission: {
          keyFields: ['name'],
        },
        ConnectorsV1FlagAppliedConnection: {
          keyFields: ['name'],
        },
        WorkflowV1Surface: {
          keyFields: ['name'],
        },
        WorkflowV1ExclusivityGroup: {
          keyFields: ['name'],
        },
        FlagsAdminV1Segment: {
          keyFields: ['name'],
        },
        FlagsAdminV1Flag: {
          keyFields: ['name'],
        },
        MetricsV1Metric: {
          keyFields: ['name'],
        },
        MetricsV1AssignmentTable: {
          keyFields: ['name'],
        },
        MetricsV1DataWarehouse: {
          keyFields: ['name'],
        },
        MetricsV1DimensionTable: {
          keyFields: ['name'],
        },
        MetricsV1FactTable: {
          keyFields: ['name'],
        },
        MetricsV1EntityRelationTable: {
          keyFields: ['name'],
        },
        MetricsV1Entity: {
          keyFields: ['name'],
        },
        EventsAdminV1EventDefinition: {
          keyFields: ['name'],
        },
        FlagsAdminV1FlagRule: {
          keyFields: ['name'],
        },
        FlagsAdminV1FlagVariant: {
          keyFields: ['name'],
        },
        EventconnectorsV1Event: {
          keyFields: ['name'],
        },
        MetricsV1ExposureCalculation: {
          keyFields: ['name'],
        },
        MetricsV1ScheduledExposureCalculation: {
          keyFields: ['name'],
          fields: {
            exposureCalculations: {
              keyArgs: ['parent'],
              merge: namedMerger('exposureCalculations'),
            },
          },
        },
        MetricsV1ScheduledMetricCalculation: {
          keyFields: ['name'],
          fields: {
            metricCalculations: {
              keyArgs: ['parent'],
              merge: namedMerger('metricCalculations'),
            },
          },
        },
        BillingV1StripeCustomer: {
          keyFields: ['name'],
        },
        MetricsV1MetricCalculation: {
          keyFields: ['name'],
        },
        IamV1User: {
          keyFields: ['name'],
        },
        IamV1UserInvitation: {
          keyFields: ['name'],
        },
        CollaborationV1Comment: {
          keyFields: ['name'],
          fields: {
            commentReplies: {
              keyArgs: ['filter'],
              merge: namedMerger('commentReplies'),
            },
          },
        },
        CollaborationV1CommentReply: {
          keyFields: ['name'],
        },
        // https://www.apollographql.com/docs/react/pagination/core-api/#merging-paginated-results
        Query: {
          fields: {
            workflowInstances: {
              keyArgs: ['parent', 'filter'],
              merge: namedMerger('workflowInstances'),
            },
            surfaces: {
              keyArgs: ['filter'],
              merge: namedMerger('surfaces'),
            },
            workflowSecrets: {
              keyArgs: [],
              merge: namedMerger('workflowSecrets'),
            },
            workflows: {
              keyArgs: [],
              merge: namedMerger('workflows'),
            },
            workflowLogs: {
              keyArgs: ['parent'],
              merge: namedMerger('workflowLogs'),
            },
            workflowVersions: {
              keyArgs: ['parent'],
              merge: namedMerger('workflowVersions'),
            },
            oAuthApps: {
              keyArgs: [],
              merge: namedMerger('oAuthApps'),
            },
            metrics: {
              keyArgs: ['filter'],
              merge: namedMerger('metrics'),
            },
            flags: {
              keyArgs: ['filter'],
              merge: namedMerger('flags'),
            },
            apiClients: {
              keyArgs: [],
              merge: namedMerger('apiClients'),
            },
            identities: {
              keyArgs: ['filter'],
              merge: namedMerger('identities'),
            },
            groups: {
              keyArgs: [],
              merge: namedMerger('groups'),
            },
            clients: {
              keyArgs: [],
              merge: namedMerger('clients'),
            },
            segments: {
              keyArgs: ['filter', 'view'],
              merge: namedMerger('segments'),
            },
            clientCredentials: {
              keyArgs: ['parent'],
              merge: namedMerger('clientCredentials'),
            },
            eventDefinitions: {
              keyArgs: ['filter'],
              merge: namedMerger('eventDefinitions'),
            },
            metricCalculations: {
              keyArgs: ['parent'],
              merge: namedMerger('metricCalculations'),
            },
            entities: {
              keyArgs: ['filter'],
              merge: namedMerger('entities'),
            },
            assignmentTables: {
              keyArgs: ['filter'],
              merge: namedMerger('assignmentTables'),
            },
            factTables: {
              keyArgs: ['filter'],
              merge: namedMerger('factTables'),
            },
            exposureCalculations: {
              keyArgs: ['parent'],
              merge: namedMerger('exposureCalculations'),
            },
            events: {
              keyArgs: ['resource'],
              merge: namedMerger('events'),
            },
            resourcePermissions: {
              keyArgs: ['resource'],
              merge: namedMerger('resourcePermissions'),
            },
            scheduledExposureCalculations: {
              keyArgs: [],
              merge: namedMerger('scheduledExposureCalculations'),
            },
            scheduledMetricCalculations: {
              keyArgs: [],
              merge: namedMerger('scheduledMetricCalculations'),
            },
            users: {
              keyArgs: ['filter'],
              merge: namedMerger('users'),
            },
            userInvitations: {
              keyArgs: [],
              merge: namedMerger('userInvitations'),
            },
            policies: {
              keyArgs: [],
              merge: namedMerger('policies'),
            },
            roles: {
              keyArgs: [],
              merge: namedMerger('roles'),
            },
            comments: {
              keyArgs: ['filter', 'resource'],
              merge: namedMerger('comments'),
            },
            commentReplies: {
              keyArgs: ['parent'],
              merge: namedMerger('commentReplies'),
            },
          },
        },
      },
    }),
  });
};
