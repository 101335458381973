import React from 'react';

import { MenuItem, Select } from '@material-ui/core';

import _ from 'lodash';

import {
  MetricResultTableGrouping,
  ResultColumnType,
  defaultColumns,
  getColumnOption,
} from './MetricResultTable';

type GroupingSelectProps = {
  value: MetricResultTableGrouping;
  onChange: (newValue: MetricResultTableGrouping) => void;
  groupingOptions?: MetricResultTableGrouping[];
  columns?: ResultColumnType[];
};

export const defaultGroupingOptions: MetricResultTableGrouping[] = [
  ['metric', 'comparison'],
  ['comparison', 'metric'],
  // TODO: allow ungrouped result when visualizations handle it + the table supports pagination
  // [],
];

export const GroupingSelect = ({
  value,
  onChange,
  groupingOptions = defaultGroupingOptions,
  columns = defaultColumns,
}: GroupingSelectProps) => {
  const columnOptions = React.useMemo(
    () => columns.map(getColumnOption),
    [columns],
  );

  const handleGroupingChange = (
    event: React.ChangeEvent<{ value: unknown }>,
  ) => {
    const newGrouping = event.target.value as MetricResultTableGrouping;
    onChange(newGrouping);
  };

  const getGroupingLabel = (newValue: unknown) => {
    const newGrouping = newValue as MetricResultTableGrouping;
    if (newGrouping.length === 0) {
      return 'No grouping';
    }
    return newGrouping
      .map(
        group =>
          columnOptions.find(option => option.id === group)?.name ||
          _.upperFirst(group),
      )
      .join(' → ');
  };

  return (
    <Select
      value={value}
      onChange={handleGroupingChange}
      variant="outlined"
      margin="dense"
      displayEmpty
      renderValue={getGroupingLabel}
      name="grouping"
    >
      {groupingOptions.map((option, i) => (
        <MenuItem key={`groupingOption-${i}`} value={option}>
          {getGroupingLabel(option)}
        </MenuItem>
      ))}
    </Select>
  );
};
