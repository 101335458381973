import React from 'react';

import {
  ListItem,
  ListItemProps,
  Theme,
  alpha,
  createStyles,
  withStyles,
} from '@material-ui/core';

import SchemaEntryNested from './SchemaEntryNested';

const styles = (theme: Theme) =>
  createStyles({
    root: {
      paddingTop: theme.spacing(0.5),
      paddingBottom: theme.spacing(0.5),
      paddingRight: theme.spacing(1),
      '&:hover:after, &$selected:after': {
        background: alpha(
          theme.palette.action.selected,
          theme.palette.action.selectedOpacity,
        ),
        borderRadius: 0,
      },
    },
    selected: {},
  });

export interface SchemaEntryProps extends ListItemProps {
  level?: number;
}

const SchemaEntry = function SchemaEntry({
  level,
  ...props
}: SchemaEntryProps) {
  return <SchemaEntryNested level={level} component={ListItem} {...props} />;
};

export default withStyles(styles)(SchemaEntry);
