import React from 'react';
import { InView } from 'react-intersection-observer';

import {
  Box,
  Button,
  DialogActions,
  Grid,
  LinearProgress,
  makeStyles,
} from '@material-ui/core';
import ReplayIcon from '@material-ui/icons/Replay';
import { Alert } from '@material-ui/lab';

import {
  ActionMenu,
  DialogBody,
  DialogHeader,
  useDialog,
  useDisplayNames,
} from '@spotify-confidence/core-react';
import {
  MetricsV1ScheduledExposureCalculationState,
  getError,
  getTypeOrNull,
  isType,
  useListExposureCalculationsQuery,
  useRetriggerExposureMutation,
} from '@spotify-confidence/plugin-graphql';

import { ExposureCalculationsList } from './ExposureCalculationsList';

const useStyles = makeStyles(theme => {
  return {
    container: {
      display: 'flex',
      justifyContent: 'right',
      alignItems: 'center',
      marginBottom: theme.spacing(2),
    },
  };
});

export const ScheduledExposureCalculationDialog = ({
  name,
}: {
  name: string;
}) => {
  const { closeDialog } = useDialog();
  const { displayNames } = useDisplayNames();
  const {
    data: listData,
    loading,
    error: listError,
    fetchMore,
  } = useListExposureCalculationsQuery({
    variables: { parent: name, pageSize: 50 },
  });

  const classes = useStyles();
  const calculations = getTypeOrNull(
    listData?.exposureCalculations,
    'MetricsV1ListExposureCalculationsResponse',
  )?.exposureCalculations;

  const scheduledExposureCalculation = getTypeOrNull(
    listData?.scheduledExposureCalculation,
    'MetricsV1ScheduledExposureCalculation',
  );
  const metricCalculations = getTypeOrNull(
    scheduledExposureCalculation?.exposedEntitiesMetricSchedule,
    'MetricsV1ScheduledMetricCalculation',
  )?.metricCalculations;
  const metricCalculationsResponse = getTypeOrNull(
    metricCalculations,
    'MetricsV1ListMetricCalculationsResponse',
  );

  const [
    retriggerExposure,
    { data: retriggerData, loading: isRetriggering, error: retriggerError },
  ] = useRetriggerExposureMutation();

  const handleRetrigger = React.useCallback(
    (retriggerDependentMetricSchedules: boolean) =>
      retriggerExposure({
        variables: {
          name,
          retriggerDependentMetricSchedules,
        },
      }),
    [retriggerExposure, name],
  );

  const error =
    getError(retriggerData?.retriggerScheduledExposureCalculation) ||
    listError ||
    retriggerError;

  return loading ? (
    <LinearProgress />
  ) : (
    <>
      <DialogHeader onClose={closeDialog} title="Exposure calculations" />
      <DialogBody autoHeight>
        {(calculations?.length || 0) > 0 &&
          scheduledExposureCalculation?.state ===
            MetricsV1ScheduledExposureCalculationState.Active && (
            <div className={classes.container}>
              <ActionMenu
                renderButton={props => (
                  <Button
                    {...props}
                    variant="contained"
                    color="primary"
                    size="small"
                    startIcon={<ReplayIcon />}
                    disabled={isRetriggering}
                  >
                    Retrigger
                  </Button>
                )}
                buttons={[
                  {
                    label: 'Retrigger only exposure',
                    onClick: () => handleRetrigger(false),
                    key: 'retriggerExposure',
                  },
                  {
                    label: 'Retrigger exposure and all metrics',
                    onClick: () => handleRetrigger(true),
                    key: 'retriggerExposureAndMetrics',
                  },
                ]}
              />
            </div>
          )}
        {listData && (
          <>
            <Box mb={3}>
              <Grid item xs={12}>
                <ExposureCalculationsList
                  calculations={calculations}
                  metricCalculations={
                    metricCalculationsResponse?.metricCalculations
                  }
                  scheduledExposureCalculation={
                    scheduledExposureCalculation || undefined
                  }
                  displayNames={displayNames}
                />
                <InView
                  onChange={async inView => {
                    const nextPageToken =
                      isType(
                        listData?.exposureCalculations,
                        'MetricsV1ListExposureCalculationsResponse',
                      ) && listData?.exposureCalculations?.nextPageToken;
                    if (inView && nextPageToken) {
                      await fetchMore({
                        variables: {
                          pageToken: nextPageToken,
                          metricCalculationsPageToken:
                            metricCalculationsResponse?.nextPageToken,
                        },
                      });
                    }
                  }}
                />
              </Grid>
            </Box>
          </>
        )}
      </DialogBody>
      <DialogActions>
        <Box width="100%">
          {error ? (
            <Box width="100%">
              <Alert severity="error">{error.message}</Alert>
            </Box>
          ) : null}
          {loading ? (
            <Box display="flex" justifyContent="center" width="100%">
              <LinearProgress />
            </Box>
          ) : null}
        </Box>
      </DialogActions>
    </>
  );
};
