import React from 'react';

import { Box } from '@material-ui/core';

import {
  ConfidenceWebsiteLink,
  FeatureFlagged,
  FormFieldsBox,
  SidebarValue,
  SidebarValueWrapper,
  timeWindowUtils,
} from '@spotify-confidence/core-react';
import { MetricsV1MetricInput } from '@spotify-confidence/plugin-graphql';
import _ from 'lodash';

import { AboutMetricForm } from '../AboutMetricForm';
import { MetricDefaultsForm } from '../MetricDefaultsForm';
import { MetricFilters } from '../MetricFilters';
import { MetricFilterPreview } from '../MetricFilters/MetricFilterPreview';
import { metricFilterHasEmptyAttributes } from '../MetricFilters/useMetricFilters';
import { MetricInputsForm } from '../MetricInputsForm';
import { MissingValuesForm } from '../MissingValuesForm';
import { VarianceReductionForm } from '../VarianceReductionForm';
import { MetricSection } from './types';

export const aboutSection: MetricSection = {
  name: 'About',
  description:
    'Set a name and description for your metric. You can change them later.',
  canBeEditedSafely: true,
  isValid: metric => !!metric.displayName && !!metric.owner,
  form: (value, onChange) => (
    <AboutMetricForm value={value} onChange={onChange} />
  ),
  summary: (metric, renderValues) => (
    <SidebarValueWrapper>
      <SidebarValue name="Name" singleLine value={metric.displayName} />
      <SidebarValue name="Description" value={metric.description || '-'} />
      <SidebarValue
        name="Owner"
        value={renderValues?.[metric?.owner ?? 'unknown']?.() || 'Unknown'}
      />
    </SidebarValueWrapper>
  ),
  updateMask: 'displayName,description,owner',
};

export const metricInputsSection: MetricSection = {
  name: 'Inputs',
  description: (
    <>
      Select the entity you want to measure and the fact table with the
      measurements you want to use.
    </>
  ),
  canBeEditedSafely: false,
  isValid: metric =>
    Boolean(
      metric.entity &&
        metric.factTable &&
        !metricFilterHasEmptyAttributes(metric.filter),
    ),
  form: (value, onChange) => (
    <FormFieldsBox>
      <MetricInputsForm value={value} onChange={onChange} />
      <FeatureFlagged with="metric-filters">
        <MetricFilters
          factTable={value.factTable}
          filter={value.filter}
          onChange={newFilter => {
            onChange({
              ...value,
              filter: newFilter,
            });
          }}
        />
      </FeatureFlagged>
    </FormFieldsBox>
  ),
  summary: (metric, renderValues) => (
    <SidebarValueWrapper>
      <SidebarValue
        name="Fact table"
        value={renderValues?.[metric.factTable]?.() || metric.factTable}
      />
      <SidebarValue
        name="Entity"
        value={renderValues?.[metric.entity]?.() || metric.entity}
      />
      {metric.filter && (
        <SidebarValue
          name="Filter"
          value={<MetricFilterPreview filter={metric.filter} />}
        />
      )}
    </SidebarValueWrapper>
  ),
  updateMask: 'factTable,entity,filter',
};

export const suggestedUsageSection: MetricSection = {
  name: 'Suggested usage',
  optional: true,
  canBeEditedSafely: true,
  description: (
    <>
      Help other experimenters use this metric effectively by setting a
      preferred direction of change and effect size.
    </>
  ),
  isValid: metric =>
    metric.defaultEffectSize?.value
      ? Number(metric.defaultEffectSize.value) > 0 &&
        Number(metric.defaultEffectSize.value) <= 1
      : true,
  form: (value, onChange) => (
    <MetricDefaultsForm value={value} onChange={onChange} />
  ),
  summary: metric => (
    <Box display="grid" gridTemplateColumns="1fr 1fr">
      <SidebarValue
        name="Preferred direction"
        value={
          metric.preferredDirection
            ? _.upperFirst(metric.preferredDirection.toLocaleLowerCase())
            : 'Not set'
        }
      />
      <SidebarValue
        name="Threshold"
        value={
          metric.defaultEffectSize?.value
            ? `${Number(metric.defaultEffectSize.value) * 100}%`
            : 'Not set'
        }
      />
    </Box>
  ),
  updateMask: 'preferredDirection,defaultEffectSize',
};

export const varianceReductionSection: MetricSection = {
  name: 'Variance reduction',
  description: (
    <>
      Reduce variance in results by comparing pre-exposure data to detect
      significant changes earlier.{' '}
      <ConfidenceWebsiteLink
        route="/docs/stats/variance-reduction#variance-reduction-using-pre-exposure-covariates-to-reduce-the-variance"
        underline="always"
      >
        Learn more about variance reduction.
      </ConfidenceWebsiteLink>
    </>
  ),
  canBeEditedSafely: false,
  summaryDescription:
    'Variance reduction is enabled by default to detect significant changes earlier.',
  form: (
    value: MetricsV1MetricInput,
    onChange: (newValue: MetricsV1MetricInput) => void,
  ) => (
    <VarianceReductionForm
      value={value.varianceReductionConfig}
      onChange={varianceReductionConfig =>
        onChange({
          ...value,
          varianceReductionConfig,
        })
      }
    />
  ),
  summary: (metric: MetricsV1MetricInput) => (
    <Box display="grid" gridTemplateColumns="1fr 1fr">
      <SidebarValue
        name="Status"
        value={
          metric.varianceReductionConfig?.disabled ? 'Disabled' : 'Enabled'
        }
      />
      {!metric.varianceReductionConfig?.disabled && (
        <SidebarValue
          name="Pre-exposure window"
          value={
            metric.varianceReductionConfig?.aggregationWindowOverride
              ? timeWindowUtils.parseWindow(
                  metric.varianceReductionConfig?.aggregationWindowOverride,
                )
              : 'Default (same as duration)'
          }
        />
      )}
    </Box>
  ),
  updateMask: 'varianceReductionConfig',
};

export const missingValuesSection: MetricSection = {
  name: 'Missing values',
  description: (
    <>
      Entities with missing values can either be excluded or included. To
      include them, the missing values need to be replaced.{' '}
      <ConfidenceWebsiteLink
        route="/docs/stats/missing-values"
        underline="always"
      >
        Learn more about missing values.
      </ConfidenceWebsiteLink>
    </>
  ),
  canBeEditedSafely: false,
  summaryDescription:
    'Confidence automatically selects the way to handle nulls depending how the metric is aggregated.',
  form: (
    value: MetricsV1MetricInput,
    onChange: (newValue: MetricsV1MetricInput) => void,
  ) => (
    <MissingValuesForm
      value={value.nullHandling}
      onChange={nullHandling =>
        onChange({
          ...value,
          nullHandling,
        })
      }
    />
  ),
  summary: (metric: MetricsV1MetricInput) => (
    <SidebarValue
      name="Status"
      value={
        metric.nullHandling?.replaceEntityNullWithZero === false
          ? 'Exclude entities with a missing measurement'
          : 'Include all entities replacing missing with zero'
      }
    />
  ),
  updateMask: 'nullHandling',
};
