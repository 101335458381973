import * as React from 'react';

import {
  Box,
  Button,
  Collapse,
  Theme,
  makeStyles,
  useMediaQuery,
} from '@material-ui/core';
import ExpandLess from '@material-ui/icons/ExpandLess';
import ExpandMore from '@material-ui/icons/ExpandMore';

import { HEADER_HEIGHT } from './Header';

type DocsProps = {
  /** content of the docs */
  children?: React.ReactNode;
};

const useStyles = makeStyles(theme => ({
  wrapper: {
    position: 'relative',
    borderBottom: `1px solid ${theme.palette.divider}`,
    [theme.breakpoints.up('md')]: {
      borderBottom: 'none',
      borderLeft: `1px solid ${theme.palette.divider}`,
    },
  },
  sticky: {
    [theme.breakpoints.up('md')]: {
      position: 'sticky',
      top: HEADER_HEIGHT,
    },
  },
  docs: {
    padding: theme.spacing(3),
    borderTop: `1px solid ${theme.palette.divider}`,
    display: 'grid',
    gap: theme.spacing(1),
    [theme.breakpoints.up('md')]: {
      borderTop: 'none',
    },
  },
}));

export function Docs(props: DocsProps) {
  const { children } = props;
  const classes = useStyles();

  const [expanded, setExpanded] = React.useState(false);
  const toggleExpanded = () => setExpanded(current => !current);

  const bigScreen = useMediaQuery((theme: Theme) => theme.breakpoints.up('md'));
  const visible = bigScreen || expanded;

  return (
    <Box className={classes.wrapper} gridArea="docs">
      {!bigScreen && (
        <Box textAlign="right" width="100%" padding={3} paddingTop={0}>
          <Button
            endIcon={expanded ? <ExpandLess /> : <ExpandMore />}
            onClick={toggleExpanded}
            size="small"
          >
            View resources
          </Button>
        </Box>
      )}
      <Collapse in={visible} className={classes.sticky}>
        <div className={classes.docs}>{children}</div>
      </Collapse>
    </Box>
  );
}
