import React from 'react';
import { TransitionGroup } from 'react-transition-group';

import {
  Collapse,
  IconButton,
  List,
  ListItem,
  ListItemText,
  Typography,
} from '@material-ui/core';
import Edit from '@material-ui/icons/Edit';

import {
  SidebarSection,
  extractLastNameComponent,
  extractWorkflowFromInstanceName,
  useAlert,
  useDialog,
} from '@spotify-confidence/core-react';
import {
  SurfaceFragment,
  getTypeOrNull,
  isError,
  isType,
  useUpdateSegmentMutation,
  useUpdateWorkflowInstanceSurfacesMutation,
  useWorkflowInstanceSurfacesQuery,
} from '@spotify-confidence/plugin-graphql';
import {
  surfaceRouteRef,
  useSurfaces,
} from '@spotify-confidence/plugin-surfaces';
import _ from 'lodash';

import { Link } from '@backstage/core-components';
import { useRouteRef } from '@backstage/core-plugin-api';

import { SurfacesDialog } from './SurfacesDialog';

/**
 * Props for {@link SurfacesSidebarSection}.
 */
export interface SurfacesSidebarSectionV2Props {
  workflowInstanceName: string;
  disabled?: boolean;
}

/**
 * Sidebar section for managing surfaces for a workflow instance.
 *
 * @param props See {@link SurfacesSidebarSectionV2Props}.
 * @public
 */
export const SurfacesSidebarSectionV2 = (
  props: SurfacesSidebarSectionV2Props,
) => {
  const { openDialog, closeDialog } = useDialog();
  const { workflowInstanceName: name, disabled } = props;
  const workflowId = extractWorkflowFromInstanceName(name)!;

  const alert = useAlert();
  const surfaceRoute = useRouteRef(surfaceRouteRef);

  const surfaceHrefFromName = (surfaceName: string) => {
    return surfaceRoute({ id: extractLastNameComponent(surfaceName)! });
  };

  const { data } = useWorkflowInstanceSurfacesQuery({
    variables: {
      name,
    },
  });
  const workflowInstance = getTypeOrNull(
    data?.workflowInstance,
    'WorkflowV1WorkflowInstance',
  );
  const workflowSurfaces = workflowInstance?.surfaces ?? [];
  const [updateSurfaces] = useUpdateWorkflowInstanceSurfacesMutation();
  const segment = getTypeOrNull(
    getTypeOrNull(data?.workflowInstance, 'WorkflowV1WorkflowInstance')
      ?.moduleData2.flags?.segment,
    'FlagsAdminV1Segment',
  );
  const [saveSegment] = useUpdateSegmentMutation();
  const tags = [
    ...new Set([
      ...(segment?.allocation?.exclusiveTo ?? []),
      ...(segment?.allocation?.exclusivityTags ?? []),
    ]),
  ];
  const handleEdit = () => {
    openDialog({
      content: (
        <SurfacesDialog
          workflowId={workflowId}
          tags={tags}
          surfaces={
            (workflowSurfaces.filter(s => !isError(s)) ??
              []) as SurfaceFragment[]
          }
          onClose={closeDialog}
          onSubmit={async (surfaces, groups) => {
            const [segmentResponse, response] = await Promise.all([
              saveSegment({
                variables: {
                  segment: {
                    name: segment?.name,
                    allocation: {
                      exclusiveTo: groups,
                      exclusivityTags: groups,
                    },
                  },
                  updateMask:
                    'allocation.exclusiveTo,allocation.exclusivityTags',
                },
              }),
              updateSurfaces({
                variables: {
                  input: {
                    name,
                    surfaces: surfaces.map(s => s.name),
                  },
                },
                update: cache => {
                  // TODO: can we restrict this to only affect surfaces that changed?
                  cache.evict({ fieldName: 'workflowInstances' });
                },
              }),
            ]);

            if (
              segmentResponse &&
              isError(segmentResponse.data?.updateSegment)
            ) {
              alert.post({
                severity: 'error',
                display: 'transient',
                message:
                  segmentResponse?.data?.updateSegment?.message ??
                  'Something went wrong',
              });
              return;
            }
            if (isError(response.data?.updateWorkflowInstance)) {
              alert.post({
                severity: 'error',
                display: 'transient',
                message:
                  response?.data?.updateWorkflowInstance?.message ??
                  'Something went wrong',
              });
              return;
            }

            closeDialog();
          }}
        />
      ),
    });
  };

  // Load surfaces to understand if we should show the surfaces section
  // or not. Non-optional from a data-loading perspective, but better
  // than showing an empty surfaces-section when there's no surfaces at
  // all in the system.
  const { surfaces: existingSurfaces } = useSurfaces();
  if (existingSurfaces.length === 0) {
    // If we don't have any surfaces, we don't show the section
    // right now.
    return null;
  }

  return (
    <SidebarSection
      title="Surfaces and Exclusivity"
      action={
        !disabled && (
          <IconButton size="small" onClick={handleEdit} title="Edit">
            <Edit fontSize="small" />
          </IconButton>
        )
      }
    >
      {workflowSurfaces.length === 0 && (
        <Typography variant="body1" color="textSecondary">
          No surfaces selected
        </Typography>
      )}

      {/* -10 comes from margin: 6 + padding: 4 on the list item */}
      <List disablePadding style={{ marginTop: -10 }}>
        <TransitionGroup>
          {workflowSurfaces.map((surface, i) => {
            const surfaceExclusivityGroups = tags.filter(t =>
              isType(surface, 'WorkflowV1Surface')
                ? t.startsWith(surface.name)
                : false,
            );
            return (
              <Collapse key={`surface-${i}`}>
                <ListItem disableGutters>
                  <ListItemText
                    primary={
                      isType(surface, 'WorkflowV1Surface') ? (
                        <Link to={surfaceHrefFromName(surface.name)}>
                          {surface.displayName}
                        </Link>
                      ) : (
                        <span>{surface.message ?? 'Unknown'}</span>
                      )
                    }
                    secondary={
                      surfaceExclusivityGroups.length !== 0 && (
                        <List>
                          {surfaceExclusivityGroups.map(group => (
                            <ListItem key={group}>
                              <ListItemText
                                primary={extractLastNameComponent(group)}
                                primaryTypographyProps={{ variant: 'body2' }}
                              />
                            </ListItem>
                          ))}
                        </List>
                      )
                    }
                  />
                </ListItem>
              </Collapse>
            );
          })}
        </TransitionGroup>
      </List>
    </SidebarSection>
  );
};
