import React from 'react';

import { Radio, RadioGroup } from '@material-ui/core';

import {
  ConfidenceWebsiteLink,
  FormControlCardLabel,
  WizardStepper,
  useDialog,
} from '@spotify-confidence/core-react';
import {
  ConnectorsV1EventConnectionBigQueryEventConnectionConfigInput as BigQueryInput,
  ConnectorsV1EventConnectionInput,
  ConnectorsV1EventConnectionDatabricksEventConnectionConfigInput as DatabricksInput,
  ListEventConnectionsDocument,
  ConnectorsV1EventConnectionRedshiftEventConnectionConfigInput as RedshiftInput,
  ConnectorsV1EventConnectionSnowflakeEventConnectionConfigInput as SnowflakeInput,
  getError,
  isType,
  useCreateEventConnectionMutation,
} from '@spotify-confidence/plugin-graphql';

import bigQueryLogo from '../../../shared/resources/bigquery.svg';
import databricksLogo from '../../../shared/resources/databricks.svg';
import redshiftLogo from '../../../shared/resources/redshift.svg';
import snowflakeLogo from '../../../shared/resources/snowflake.svg';
import {
  BigQueryEventConnectionForm,
  defaultBigQueryEventInput,
} from '../BigQuery';
import {
  DatabricksEventConnectionForm,
  defaultDatabricksEventInput,
} from '../Databricks';
import {
  RedshiftEventConnectionForm,
  defaultRedshiftEventInput,
} from '../Redshift';
import {
  SnowflakeEventConnectionForm,
  defaultSnowflakeEventInput,
} from '../Snowflake';

type ConnectionType = keyof Omit<
  ConnectorsV1EventConnectionInput,
  | 'name'
  | 'labels'
  | 'proto_type_url'
  | 'includedDefinitions'
  | 'owner'
  | 'builtin'
>;

type ConnectionInput =
  | BigQueryInput
  | DatabricksInput
  | RedshiftInput
  | SnowflakeInput;

type ConnectionDetails = { name: string; link: string };

const connectionDetails: Record<ConnectionType, ConnectionDetails> = {
  bigQuery: {
    name: 'BigQuery',
    link: '/docs/api/connectors/events',
  },
  databricks: {
    name: 'Databricks',
    link: '/docs/api/connectors/events',
  },
  snowflake: {
    name: 'Snowflake',
    link: '/docs/api/connectors/events',
  },
  redshift: {
    name: 'Redshift',
    link: '/docs/api/connectors/events',
  },
};

export const CreateEventConnectionDialog = () => {
  const { closeDialog } = useDialog();
  const [type, setType] = React.useState<ConnectionType | ''>('');
  const [inputData, setInputData] =
    React.useState<ConnectorsV1EventConnectionInput>({});

  const handleSelectType = (e: React.ChangeEvent<HTMLInputElement>) => {
    const newType = e.target.value as ConnectionType;
    if (newType !== type) {
      setType(newType);
      switch (newType) {
        case 'snowflake': {
          setInputData({ snowflake: defaultSnowflakeEventInput });
          break;
        }
        case 'databricks': {
          setInputData({ databricks: defaultDatabricksEventInput });
          break;
        }
        case 'bigQuery': {
          setInputData({ bigQuery: defaultBigQueryEventInput });
          break;
        }
        case 'redshift': {
          setInputData({ redshift: defaultRedshiftEventInput });
          break;
        }
        default:
          setInputData({});
      }
    }
  };

  const handleClose = () => {
    closeDialog();
  };

  function onInputChange(connectionType: ConnectionType) {
    return (data: ConnectionInput) => {
      setInputData({
        [connectionType]: data,
      });
    };
  }

  const [
    createEventConnection,
    { data, loading: isCreating, error: createError },
  ] = useCreateEventConnectionMutation();

  const handleCreate = () => {
    if (type) {
      createEventConnection({
        variables: {
          eventConnection: inputData,
        },
        awaitRefetchQueries: true,
        refetchQueries: [ListEventConnectionsDocument],
        onCompleted: response => {
          if (
            isType(
              response.createEventConnection,
              'ConnectorsV1EventConnection',
            )
          ) {
            handleClose();
          }
        },
      });
    }
  };
  const error = getError(data?.createEventConnection) ?? undefined;
  const details = type
    ? connectionDetails[type]
    : { name: 'Configuration', link: '/docs' };

  return (
    <WizardStepper
      onClose={handleClose}
      submitLabel="Create"
      onSubmit={handleCreate}
      error={createError ?? error}
      loading={isCreating}
      formName="event-connection-form"
      steps={[
        {
          name: 'Connection Type',
          description: `Select the type of connection you want to use to consume event data ingested through the Confidence event sender SDKs.`,
          disableNext: !type,
          content: (
            <>
              <RadioGroup
                aria-label="type"
                name="event-connection-type"
                value={type}
                onChange={handleSelectType}
              >
                <FormControlCardLabel
                  value="bigQuery"
                  variant="outlined"
                  control={<Radio color="primary" />}
                  label="BigQuery"
                  description="Export events to BigQuery. The connector writes each event type to a separate table in the configured dataset with an optional prefix."
                  src={bigQueryLogo}
                />
                <FormControlCardLabel
                  value="databricks"
                  variant="outlined"
                  control={<Radio color="primary" />}
                  label="Databricks"
                  description="Export events to Databricks tables, by first writing the data as Parquet files to a S3 bucket, and then importing these files into Databricks tables. Events are written to separate tables per event type in the configured schema/catalogue."
                  src={databricksLogo}
                />
                <FormControlCardLabel
                  value="snowflake"
                  variant="outlined"
                  control={<Radio color="primary" />}
                  label="Snowflake"
                  description="Export events to tables in Snowflake."
                  src={snowflakeLogo}
                />
                <FormControlCardLabel
                  value="redshift"
                  variant="outlined"
                  control={<Radio color="primary" />}
                  label="Redshift"
                  description="Export events to tables in Redshift, by first writing the data as Parquet files to a S3 bucket, and then import these files into Redshift tables, one per event type."
                  src={redshiftLogo}
                />
              </RadioGroup>
            </>
          ),
        },
        {
          name: `${details.name} connection`,
          description: (
            <span>
              You can read more about how to create a {details.name} connection
              in our{' '}
              <ConfidenceWebsiteLink route={details.link} underline="always">
                documentation
              </ConfidenceWebsiteLink>
              .
            </span>
          ),
          content: (
            <div data-testid="create-connection-dialog">
              {type === 'bigQuery' && (
                <BigQueryEventConnectionForm
                  value={inputData.bigQuery!}
                  onChange={onInputChange('bigQuery')}
                />
              )}
              {type === 'databricks' && (
                <DatabricksEventConnectionForm
                  value={inputData.databricks!}
                  onChange={onInputChange('databricks')}
                />
              )}
              {type === 'snowflake' && (
                <SnowflakeEventConnectionForm
                  value={inputData.snowflake!}
                  onChange={onInputChange('snowflake')}
                />
              )}
              {type === 'redshift' && (
                <RedshiftEventConnectionForm
                  value={inputData.redshift!}
                  onChange={onInputChange('redshift')}
                />
              )}
            </div>
          ),
        },
      ]}
    />
  );
};
