import React from 'react';

import loadable from '@loadable/component';
import { createWorkflowExtension } from '@spotify-confidence/plugin-workflows';

import { createPlugin } from '@backstage/core-plugin-api';

import { AbTestTableRow } from './components/AbTestWorkflowPage/AbTestTableRow';
import { AB_TEST_WORKFLOW_ID } from './constants';

export const confidenceWorkflowsModuleAbTestPlugin = createPlugin({
  id: 'confidence-workflows-module-ab-test',
});

const componentsChunk = () =>
  import(
    /* webpackChunkName: "workflows-ab-test-pages", webpackPrefetch: 5 */ './components'
  );
const loadOptions = {};

const AbTestWorkflowPage = loadable(
  () => componentsChunk().then(m => m.AbTestWorkflowPage),
  loadOptions,
);
const AbTestWorkflowInstancePage = loadable(
  () => componentsChunk().then(m => m.AbTestWorkflowInstancePage),
  loadOptions,
);

export const ABTestWorkflowExtension =
  confidenceWorkflowsModuleAbTestPlugin.provide(
    createWorkflowExtension({
      title: 'A/B test',
      workflowId: AB_TEST_WORKFLOW_ID,
      workflowPage: () => <AbTestWorkflowPage />,
      workflowInstancePage: () => <AbTestWorkflowInstancePage />,
      workflowTableRowComponent: AbTestTableRow,
    }),
  );
