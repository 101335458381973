import React from 'react';

import { Radio, RadioGroup } from '@material-ui/core';

import {
  ConfidenceWebsiteLink,
  FormControlCardLabel,
  WizardStepper,
  useAlert,
  useDialog,
} from '@spotify-confidence/core-react';
import {
  BigQueryConfigFragment,
  ListDataWarehousesDocument,
  MetricsV1DataWarehouseConfigInput,
  MetricsV1DatabricksConfigInput,
  MetricsV1SnowflakeConfigInput,
  RedshiftConfigFragment,
  isType,
  useCreateDataWarehouseMutation,
} from '@spotify-confidence/plugin-graphql';

import bigQueryLogo from '../../../shared/resources/bigquery.svg';
import databricksLogo from '../../../shared/resources/databricks.svg';
import redshiftLogo from '../../../shared/resources/redshift.svg';
import snowflakeLogo from '../../../shared/resources/snowflake.svg';
import { RedshiftDatawarehouseForm } from '../../Redshift';
import { BigQueryDatawarehouseForm } from '../BigQuery';
import { DataBricksDatawarehouseForm } from '../Databricks';
import { SnowflakeDatawarehouseForm } from '../Snowflake';
import { DataWareHouseType } from '../types';

type ConfigDetails = { name: string; link: string };

const configDetails: Record<DataWareHouseType, ConfigDetails> = {
  bigQueryConfig: {
    name: 'BigQuery',
    link: '/docs/data-warehouse-native/quickstart/bigquery',
  },
  dataBricksConfig: {
    name: 'Databricks',
    link: '/docs/data-warehouse-native/quickstart/databricks',
  },
  snowflakeConfig: {
    name: 'Snowflake',
    link: '/docs/data-warehouse-native/quickstart/snowflake',
  },
  redshiftConfig: {
    name: 'Redshift',
    link: '/docs/data-warehouse-native/quickstart/redshift',
  },
};

export const ConfigureDatawarehouseDialog = () => {
  const { closeDialog } = useDialog();
  const alert = useAlert();
  const [type, setType] = React.useState<DataWareHouseType | ''>('');
  const [inputData, setInputData] = React.useState<
    Partial<MetricsV1DataWarehouseConfigInput>
  >({});

  const handleSelectType = (e: React.ChangeEvent<HTMLInputElement>) => {
    const newType = e.target.value as DataWareHouseType;
    if (newType !== type) {
      setType(e.target.value as DataWareHouseType);
      setInputData({});
    }
  };

  const handleClose = () => {
    closeDialog();
  };

  function setConfig(warehouseType: DataWareHouseType) {
    return (
      data:
        | MetricsV1DatabricksConfigInput
        | BigQueryConfigFragment
        | RedshiftConfigFragment
        | MetricsV1SnowflakeConfigInput,
    ) => {
      setInputData({
        [warehouseType]: data,
      });
    };
  }

  const [createWarehouseMutation, { loading: isCreating, error: createError }] =
    useCreateDataWarehouseMutation();

  const createWarehouse = () => {
    if (type) {
      createWarehouseMutation({
        variables: {
          dataWarehouse: {
            config: inputData,
          },
        },
        awaitRefetchQueries: true,
        refetchQueries: [ListDataWarehousesDocument],
        onCompleted: response => {
          if (isType(response.createDataWarehouse, 'MetricsV1DataWarehouse')) {
            handleClose();
          } else {
            alert.post({
              severity: 'error',
              message:
                response.createDataWarehouse?.message ?? 'Something went wrong',
            });
          }
        },
      });
    }
  };

  const details = type
    ? configDetails[type]
    : { name: 'Configuration', link: '/docs' };

  return (
    <WizardStepper
      onClose={handleClose}
      submitLabel="Save"
      onSubmit={createWarehouse}
      error={createError}
      loading={isCreating}
      steps={[
        {
          name: 'Data Warehouse Type',
          description: `Select the type of data warehouse you are using to store your
            data. Once the configuration is saved, this can not be
            changed.`,
          disableNext: !type,
          content: (
            <>
              <RadioGroup
                aria-label="type"
                name="data-warehouse-type"
                value={type}
                onChange={handleSelectType}
              >
                <FormControlCardLabel
                  value="bigQueryConfig"
                  variant="outlined"
                  control={<Radio color="primary" />}
                  label="BigQuery"
                  description="Use Google BigQuery as your Data Warehouse."
                  src={bigQueryLogo}
                />
                <FormControlCardLabel
                  value="dataBricksConfig"
                  variant="outlined"
                  control={<Radio color="primary" />}
                  label="Databricks"
                  description="Use Databricks as your Data Warehouse."
                  src={databricksLogo}
                />
                <FormControlCardLabel
                  value="snowflakeConfig"
                  variant="outlined"
                  control={<Radio color="primary" />}
                  label="Snowflake"
                  description="Use Snowflake as your Data Warehouse."
                  src={snowflakeLogo}
                />
                <FormControlCardLabel
                  value="redshiftConfig"
                  variant="outlined"
                  control={<Radio color="primary" />}
                  label="Redshift"
                  description="Use Redshift as your Data Warehouse."
                  src={redshiftLogo}
                />
              </RadioGroup>
            </>
          ),
        },
        {
          name: `${details.name} configuration`,
          description: (
            <span>
              You can read more about how to set up {details.name} in our{' '}
              <ConfidenceWebsiteLink route={details.link} underline="always">
                documentation
              </ConfidenceWebsiteLink>
              .
            </span>
          ),
          content: (
            <>
              {type === 'bigQueryConfig' && (
                <BigQueryDatawarehouseForm
                  value={inputData?.bigQueryConfig}
                  onChange={setConfig('bigQueryConfig')}
                />
              )}
              {type === 'dataBricksConfig' && (
                <DataBricksDatawarehouseForm
                  value={inputData?.dataBricksConfig}
                  onChange={setConfig('dataBricksConfig')}
                  requireAccessToken
                />
              )}
              {type === 'redshiftConfig' && (
                <RedshiftDatawarehouseForm
                  value={inputData?.redshiftConfig}
                  onChange={setConfig('redshiftConfig')}
                  requireSecret
                />
              )}
              {type === 'snowflakeConfig' && (
                <SnowflakeDatawarehouseForm
                  value={inputData?.snowflakeConfig}
                  onChange={setConfig('snowflakeConfig')}
                />
              )}
            </>
          ),
        },
      ]}
    />
  );
};
