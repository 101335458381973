import { useDisplayNames } from '@spotify-confidence/core-react';

import {
  assignmentTableRouteRef,
  entityRouteRef,
  factTableRouteRef,
} from '../routes';
import { MetricsData } from './types';
import { useSaveMetrics } from './useSaveMetrics';

export const useMetricsModuleConfigState = ({
  workflowInstanceName,
  metricsData,
}: {
  workflowInstanceName?: string;
  metricsData?: MetricsData;
}) => {
  const { displayNames } = useDisplayNames();

  const saveMetrics = useSaveMetrics({
    metricsData,
    name: workflowInstanceName,
  });

  const entity = metricsData?.entity;
  const assignmentTable = metricsData?.assignmentTable;
  const existingMetrics = (metricsData?.metrics || []).map(m => m.metric || '');

  const config = [];
  if (metricsData?.entity) {
    config.push({
      label: 'Entity',
      content: displayNames.get(metricsData.entity) || metricsData.entity,
      name: metricsData.entity,
      routeRef: entityRouteRef,
    });
  }
  if (metricsData?.assignmentTable) {
    config.push({
      label: 'Assignment table',
      content:
        displayNames.get(metricsData.assignmentTable) ||
        metricsData.assignmentTable,
      name: metricsData.assignmentTable,
      routeRef: assignmentTableRouteRef,
    });
  }
  if (metricsData?.exposureFilter?.factTable) {
    config.push({
      label: 'Exposure filter fact table',
      content:
        displayNames.get(metricsData?.exposureFilter?.factTable) ||
        metricsData.exposureFilter.factTable,
      name: metricsData.exposureFilter.factTable,
      routeRef: factTableRouteRef,
    });
  }

  return {
    ...saveMetrics,
    existingMetrics,
    metricsData,
    entity,
    assignmentTable,
    config,
  };
};
