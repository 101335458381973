import React from 'react';

import { AutocompleteProps, FilterOptionsState } from '@material-ui/lab';

import _ from 'lodash';

import {
  countryCodes,
  getCountryLabel,
} from '../../../../rule/domain/rule.helpers';
import { ValueType } from '../../../domain/targeting/targeting.model';
import { ChipAutocomplete } from './ChipAutocomplete';

type ChipCountryPickerProps = (
  props: Pick<
    AutocompleteProps<ValueType, boolean, boolean, boolean>,
    'disabled' | 'value' | 'multiple'
  > & {
    readOnly?: boolean;
    onChange?: (val: ValueType | ValueType[] | null) => void;
  },
) => JSX.Element;

const filterOptions = (options: string[], v: FilterOptionsState<string>) =>
  options.filter(
    o =>
      v.getOptionLabel(o).toLowerCase().includes(v.inputValue.toLowerCase()) ||
      o.toLowerCase().includes(v.inputValue.toLowerCase()),
  );

export const ChipCountryPicker: ChipCountryPickerProps = ({
  value,
  onChange,
  ...props
}) => {
  let formattedValue: string | string[] | null = null;

  if (_.isArray(value)) {
    formattedValue = value.filter(v => _.isString(v)) as string[];
  } else if (_.isString(value)) {
    formattedValue = value || null;
  }

  return (
    <ChipAutocomplete
      options={countryCodes}
      value={formattedValue}
      valueType="String"
      type="text"
      onChange={(_e, newValue) => {
        if (newValue) {
          onChange?.(newValue);
        }
      }}
      getOptionLabel={getCountryLabel}
      filterOptions={filterOptions}
      {...props}
    />
  );
};
