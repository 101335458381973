import React from 'react';

import {
  Box,
  DialogActions,
  FormControlLabel,
  Radio,
  RadioGroup,
} from '@material-ui/core';

import {
  DialogBody,
  DialogForm,
  DialogHeader,
  FormSubmitButtons,
  useDialog,
} from '@spotify-confidence/core-react';

import {
  FixedStrategyDescription,
  SequentialStrategyDescription,
} from './StrategyCard';

export const ResultsStrategyConfigDialog = <T extends string>({
  strategy: strategyDefault,
  sequentialStrategyValue,
  fixedStrategyValue,
  onSave,
  onlySequential,
}: {
  strategy: T | undefined;
  sequentialStrategyValue: T;
  fixedStrategyValue: T;
  onSave: (newData: { testHorizonStrategy: T | undefined }) => Promise<void>;
  onlySequential?: boolean;
}) => {
  const { closeDialog } = useDialog();
  const [loading, setLoading] = React.useState<boolean>(false);
  const [strategy, setStrategy] = React.useState(strategyDefault);

  const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    (async () => {
      setLoading(true);
      await onSave({
        testHorizonStrategy: strategy,
      });
      setLoading(false);
      closeDialog();
    })();
  };

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setStrategy(event.target.value as T);
  };

  return (
    <DialogForm onSubmit={handleSubmit}>
      <DialogHeader title="How do you want to see your results?" />
      <DialogBody>
        <RadioGroup value={strategy} onChange={handleChange}>
          <FormControlLabel
            value={sequentialStrategyValue}
            control={<Radio color="primary" />}
            label={
              <Box padding={2}>
                <SequentialStrategyDescription />
              </Box>
            }
          />
          {!onlySequential && (
            <FormControlLabel
              value={fixedStrategyValue}
              control={<Radio color="primary" />}
              label={
                <Box padding={2}>
                  <FixedStrategyDescription />
                </Box>
              }
            />
          )}
        </RadioGroup>
      </DialogBody>
      <DialogActions>
        <FormSubmitButtons onCancel={closeDialog} loading={loading} />
      </DialogActions>
    </DialogForm>
  );
};
