import {
  ConnectorsV1AwsRegion,
  ConnectorsV1RedshiftConfigInput,
  ConnectorsV1EventConnectionRedshiftEventConnectionConfigInput as RedshiftEventInput,
  ConnectorsV1FlagAppliedConnectionRedshiftFlagAppliedConnectionConfigInput as RedshiftFlagAppliedInput,
} from '@spotify-confidence/plugin-graphql';

import { defaultBatchConfig } from '../BatchFileConfigForm';
import {
  defaultS3BucketConfig,
  isS3BucketConfigValid,
} from '../S3BucketConfigForm';

export function isRedshiftConfigValid(
  config: ConnectorsV1RedshiftConfigInput,
): Boolean {
  return Boolean(
    config.region &&
      config.database &&
      config.schema &&
      config.cluster &&
      config.roleArn,
  );
}

export const defaultRedshiftConfig: ConnectorsV1RedshiftConfigInput = {
  cluster: '',
  database: '',
  region: ConnectorsV1AwsRegion.EuWest_1,
  roleArn: '',
  schema: '',
};

export const defaultRedshiftFlagAppliedInput: RedshiftFlagAppliedInput = {
  redshiftConfig: defaultRedshiftConfig,
  s3Config: defaultS3BucketConfig,
  batchFileConfig: defaultBatchConfig,
  table: '',
};

export const defaultRedshiftEventInput: RedshiftEventInput = {
  redshiftConfig: defaultRedshiftConfig,
  s3Config: defaultS3BucketConfig,
  batchFileConfig: defaultBatchConfig,
  tablePrefix: '',
};

export const isRedshiftFlagAppliedInputValid = (
  input: RedshiftFlagAppliedInput,
) => {
  return Boolean(
    input.table &&
      isS3BucketConfigValid(input.s3Config) &&
      isRedshiftConfigValid(input.redshiftConfig),
  );
};

export const isRedshiftEventInputValid = (input: RedshiftEventInput) => {
  return (
    isS3BucketConfigValid(input.s3Config) &&
    isRedshiftConfigValid(input.redshiftConfig)
  );
};
