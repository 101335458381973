import React from 'react';

import { DialogActions, DialogContent } from '@material-ui/core';
import { Alert } from '@material-ui/lab';

import {
  DialogForm,
  DialogHeader,
  FormSubmitButtons,
  useDialog,
} from '@spotify-confidence/core-react';
import {
  MetricsV1MetricInput,
  useUpdateMetricMutation,
} from '@spotify-confidence/plugin-graphql';

import { EditMetricWarning, MetricSection } from '../forms';

export type EditSectionDialogProps = {
  metric: MetricsV1MetricInput;
  section: MetricSection;
};

export const EditSectionDialog = ({
  metric,
  section,
}: EditSectionDialogProps) => {
  const { closeDialog } = useDialog();
  const [changedMetric, setChangedMetric] = React.useState(metric);

  const [saveMetric, { loading: saving, error: saveError }] =
    useUpdateMetricMutation();

  const onSubmit = (e: React.FormEvent<{}>) => {
    e.preventDefault();
    saveMetric({
      variables: {
        metric: changedMetric,
        updateMask: section.updateMask,
      },
      onCompleted: () => {
        closeDialog();
      },
    });
  };

  return (
    <>
      <DialogHeader
        title={section.name}
        subTitle={section.description}
        onClose={closeDialog}
      />
      <DialogForm onSubmit={onSubmit}>
        <DialogContent>
          {saveError && <Alert severity="error">{saveError.message}</Alert>}
          {!section.canBeEditedSafely && <EditMetricWarning />}
          {section.form(changedMetric, setChangedMetric, true)}
        </DialogContent>
        <DialogActions>
          <FormSubmitButtons
            onCancel={closeDialog}
            loading={saving}
            disabled={section.isValid ? !section.isValid(changedMetric) : false}
          />
        </DialogActions>
      </DialogForm>
    </>
  );
};
