import { useConfidence } from '@spotify-confidence/react';

import { LocalStorageFeatureFlags } from '@backstage/core-app-api';

export class ConfidenceFeatureFlags extends LocalStorageFeatureFlags {
  isActive(name: string): boolean {
    const confidence = useConfidence();
    return super.isActive(name) || confidence.useFlag(`${name}.enabled`, false);
  }
}
