import React from 'react';

import {
  InlineDropdown,
  MultipleNominalOperator,
  MultipleValue,
  QueryRuleDefinition,
} from '@spotify-confidence/core-react';

const stateFilter: QueryRuleDefinition<MultipleValue<string>> = {
  id: 'state',
  displayName: 'State',
  defaultValue: ['OK', 'ALLOCATED', 'UNALLOCATED'],
  operators: [MultipleNominalOperator.IS_ANY_OF],
  renderEditComponent: props => (
    <InlineDropdown
      {...props}
      options={['OK', 'ALLOCATED', 'UNALLOCATED', 'ARCHIVED']}
      multiple
    />
  ),
};

export const filters = [stateFilter];
