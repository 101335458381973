import React from 'react';

import { Button, CircularProgress } from '@material-ui/core';

import {
  ConfirmDialog,
  SidebarSection,
  SidebarValue,
  SidebarValueWrapper,
  extractLastNameComponent,
  useDialog,
  useNavigate,
} from '@spotify-confidence/core-react';
import {
  EventDefinitionFragment,
  Query,
  evictTypeFields,
  isType,
  useDeleteEventDefinitionMutation,
} from '@spotify-confidence/plugin-graphql';
import {
  IdentityChip,
  PermissionUtils,
} from '@spotify-confidence/plugin-permissions-react';

import { useRouteRef } from '@backstage/core-plugin-api';

import { eventIndexRouteRef } from '../../../routes';
import { EditAboutButton } from './AboutDialog/AboutDialog';

export const EventPageSidebar = ({
  eventDefinition,
}: {
  eventDefinition?: EventDefinitionFragment | null;
}) => {
  const { openDialog } = useDialog();
  const navigate = useNavigate();
  const eventList = useRouteRef(eventIndexRouteRef);
  const canEdit = PermissionUtils.hasRelation(eventDefinition, 'can_edit');

  const [deleteEventDef, { loading: isDeleting }] =
    useDeleteEventDefinitionMutation({
      variables: {
        name: eventDefinition?.name!,
      },
      onCompleted: () => {
        // Todo: Remove timeout when there is proper backend support
        setTimeout(() => {
          navigate(eventList());
        }, 1000);
      },
      update: cache => {
        evictTypeFields<Query>(cache, 'eventDefinitions');
      },
    });
  function handleDeleteEvent() {
    openDialog({
      content: (
        <ConfirmDialog
          expected="delete"
          onConfirm={async () => {
            await deleteEventDef();
          }}
          irreversible
          title="Delete event"
        />
      ),
    });
  }

  return (
    <>
      <SidebarSection title="Actions">
        <Button
          variant="outlined"
          size="small"
          onClick={handleDeleteEvent}
          disabled={isDeleting || !eventDefinition?.name || !canEdit}
          endIcon={
            isDeleting && <CircularProgress size="1em" color="inherit" />
          }
        >
          Delete
        </Button>
      </SidebarSection>
      <SidebarSection
        title="About"
        action={
          <EditAboutButton
            eventDefinition={eventDefinition}
            disabled={!canEdit}
          />
        }
      >
        <SidebarValueWrapper>
          <SidebarValue
            name="Name"
            singleLine
            value={extractLastNameComponent(eventDefinition?.name)}
          />
          <SidebarValue
            name="Owner"
            value={
              isType(eventDefinition?.owner, 'IamV1Identity') &&
              eventDefinition?.owner ? (
                <IdentityChip {...eventDefinition.owner} />
              ) : (
                'Unknown'
              )
            }
          />
        </SidebarValueWrapper>
      </SidebarSection>
    </>
  );
};
