import React from 'react';

import {
  Box,
  CircularProgress,
  DialogActions,
  TextField,
} from '@material-ui/core';
import DeleteIcon from '@material-ui/icons/Delete';
import { Alert } from '@material-ui/lab';

import {
  DangerBox,
  DangerButton,
  DangerZone,
  DialogBody,
  DialogHeader,
  FormSubmitButtons,
  useDialog,
  useNavigate,
} from '@spotify-confidence/core-react';
import {
  GetWorkflowSecretsDocument,
  useDeleteWorkflowSecretMutation,
  useUpdateWorkflowSecretMutation,
} from '@spotify-confidence/plugin-graphql';
import { useCheckResourcePermissions } from '@spotify-confidence/plugin-permissions-react';

import { useRouteRef } from '@backstage/core-plugin-api';

import { workflowSecretsRouteRef } from '../../../routes';

export const WorkflowSecretDialog = ({ id }: { id: string }) => {
  const { closeDialog } = useDialog();
  const [newSecretValue, setNewSecretValue] = React.useState('');
  const navigate = useNavigate();

  const secretsRoute = useRouteRef(workflowSecretsRouteRef);
  const secretName = `workflowSecrets/${id}`;

  const { allowed: canEdit } = useCheckResourcePermissions({
    name: secretName,
    can: 'edit',
  });

  const [deleteSecret, { loading: isDeleting, error: deleteError }] =
    useDeleteWorkflowSecretMutation({
      variables: {
        deleteWorkflowSecretName: secretName,
      },
      refetchQueries: [{ query: GetWorkflowSecretsDocument }],
      onCompleted: () => navigate(secretsRoute()),
    });

  const handleClose = () => {
    closeDialog();
    navigate(secretsRoute());
  };

  const [update, { loading: isUpdating, error: updateError }] =
    useUpdateWorkflowSecretMutation({
      onCompleted: () => handleClose(),
    });

  const handleUpdateSecret = () => {
    update({
      variables: {
        workflowSecret: {
          name: secretName,
          secret: newSecretValue,
        },
      },
    });
  };
  const loading = isDeleting || isUpdating;
  const error = deleteError || updateError;

  return (
    <>
      <DialogHeader title={id} onClose={handleClose} />
      <DialogBody autoHeight>
        {error && <Alert severity="error">{error.message}</Alert>}

        <TextField
          label="New secret value"
          id="secret-value"
          variant="outlined"
          placeholder="Secret..."
          required
          fullWidth
          multiline
          minRows={4}
          value={newSecretValue}
          disabled={!canEdit || isDeleting}
          onChange={e => setNewSecretValue(e.target.value)}
          helperText="The current value is hidden, but you can change it by entering a new value and saving."
        />

        <Box mt={2}>
          <DangerZone>
            <DangerBox>
              <strong>Delete Secret</strong>

              <DangerButton
                startIcon={<DeleteIcon />}
                variant="contained"
                disabled={isDeleting || !canEdit}
                color="inherit"
                endIcon={isDeleting && <CircularProgress size="1em" />}
                onClick={() => deleteSecret()}
              >
                Delete
              </DangerButton>
            </DangerBox>
          </DangerZone>
        </Box>
      </DialogBody>
      <DialogActions>
        <FormSubmitButtons
          onCancel={handleClose}
          onSubmit={handleUpdateSecret}
          disabled={loading || !newSecretValue || !canEdit || isDeleting}
          label="Save"
        />
      </DialogActions>
    </>
  );
};
