import React from 'react';

import {
  Card,
  CardContent,
  Checkbox,
  FormControlLabel,
  FormGroup,
  TextField,
  Typography,
} from '@material-ui/core';

import {
  ConnectorsV1AwsCredentialsAuthenticationSettingsInput,
  getTypeOrNull,
  useGetCurrentUserServiceAccountIdQuery,
} from '@spotify-confidence/plugin-graphql';

export type AwsCredentials = {
  accessKeyId: string;
  secretAccessKey?: string | null;
};

type OptionalAwsCredentialSettingsProps = {
  value?: AwsCredentials;
  onChange: (newValue?: AwsCredentials) => void;
  requireSecret?: boolean;
};

export const defaultCredentialsSettings: ConnectorsV1AwsCredentialsAuthenticationSettingsInput =
  {
    accessKeyId: '',
    secretAccessKey: '',
  };

export const OptionalAwsCredentialSettings = ({
  value: credentialsSettings,
  onChange,
  requireSecret,
}: OptionalAwsCredentialSettingsProps) => {
  const [prevCredentials, setPrevCredentials] = React.useState<AwsCredentials>(
    credentialsSettings ?? defaultCredentialsSettings,
  );

  const handleChange = React.useCallback(
    (newValue: Partial<AwsCredentials> | undefined) => {
      onChange({
        ...defaultCredentialsSettings,
        ...newValue,
      });
    },
    [onChange],
  );

  const toggleUseCredentials = React.useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      if (!e.target.checked && !!credentialsSettings) {
        setPrevCredentials(credentialsSettings);
      }
      onChange(e.target.checked ? prevCredentials : undefined);
    },
    [credentialsSettings, onChange, prevCredentials],
  );

  const { data: { currentUser } = {} } =
    useGetCurrentUserServiceAccountIdQuery();
  const user = getTypeOrNull(currentUser, 'IamV1CurrentUser');
  return (
    <>
      <FormControlLabel
        id="credentials-authentication-redshift"
        control={
          <Checkbox
            checked={!!credentialsSettings}
            onChange={toggleUseCredentials}
          />
        }
        checked={credentialsSettings !== undefined}
        label="Authenticate with credentials"
      />
      {credentialsSettings ? (
        <FormGroup>
          <TextField
            disabled={!credentialsSettings}
            label="Access key id"
            required
            value={credentialsSettings.accessKeyId}
            onChange={e =>
              handleChange({
                ...credentialsSettings,
                accessKeyId: e.target.value,
              })
            }
          />
          <TextField
            disabled={!credentialsSettings}
            required={requireSecret}
            label="Secret access key"
            value={credentialsSettings.secretAccessKey || ''}
            onChange={e =>
              handleChange({
                ...credentialsSettings,
                secretAccessKey: e.target.value,
              })
            }
            placeholder={
              credentialsSettings !== defaultCredentialsSettings
                ? '<existing secret>'
                : ''
            }
          />
        </FormGroup>
      ) : (
        <>
          {user && (
            <Card variant="outlined">
              <CardContent>
                <Typography color="textSecondary" variant="body2" gutterBottom>
                  Your Service Account ID
                </Typography>
                <Typography variant="subtitle1">
                  {user.serviceAccountOauth2ClientId}
                </Typography>
              </CardContent>
            </Card>
          )}
        </>
      )}
    </>
  );
};
