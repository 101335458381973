import React from 'react';

import { Divider, MenuItem, TextField, Typography } from '@material-ui/core';

import { FormFieldsBox } from '@spotify-confidence/core-react';
import {
  ConnectorsV1AwsRegion,
  ConnectorsV1RedshiftConfigInput,
} from '@spotify-confidence/plugin-graphql';

import { OptionalAwsCredentialSettings } from '../../../metrics-datawarehouse/Redshift/OptionalAwsCredentialSettings';

type RedshiftConfigFormProps = {
  value: ConnectorsV1RedshiftConfigInput;
  onChange: (conf: ConnectorsV1RedshiftConfigInput) => void;
  disabled?: boolean;
};

const regionOptions = Object.values(ConnectorsV1AwsRegion);

export const RedshiftConfigForm = ({
  value,
  onChange,
  disabled,
}: RedshiftConfigFormProps) => {
  const handleConfigChange = (
    newValue: Partial<ConnectorsV1RedshiftConfigInput>,
  ) => {
    onChange({
      ...value,
      ...newValue,
    });
  };

  return (
    <>
      <FormFieldsBox>
        <FormFieldsBox spacing={1}>
          <div>
            <Typography variant="overline" color="textSecondary" gutterBottom>
              Redshift settings
            </Typography>
            <Divider />
            <TextField
              disabled={disabled}
              label="Cluster"
              variant="outlined"
              value={value.cluster}
              required
              fullWidth
              helperText="The name of the Redshift cluster to use."
              onChange={e => handleConfigChange({ cluster: e.target.value })}
            />
          </div>
          <TextField
            disabled={disabled}
            select
            label="Region"
            variant="outlined"
            value={value.region}
            required
            fullWidth
            helperText="The AWS region of the cluster. Because of AWS limitations, the region of the cluster needs to match the region of the S3 bucket."
            onChange={e =>
              handleConfigChange({
                region: e.target.value as ConnectorsV1AwsRegion,
              })
            }
          >
            {regionOptions.map(regionOption => (
              <MenuItem key={regionOption} value={regionOption}>
                {regionOption}
              </MenuItem>
            ))}
          </TextField>

          <OptionalAwsCredentialSettings
            value={value?.credentialsSettings ?? undefined}
            onChange={credentialsSettings =>
              handleConfigChange({
                credentialsSettings: {
                  accessKeyId: credentialsSettings?.accessKeyId ?? '',
                  secretAccessKey: credentialsSettings?.secretAccessKey ?? '',
                },
              })
            }
          />

          <TextField
            disabled={disabled}
            label="Role ARN"
            variant="outlined"
            value={value.roleArn}
            required
            fullWidth
            helperText="The role to use for the Redshift COPY jobs. This role needs to have permission to create tables and copy data into tables in the configured schema, and load files from the S3 bucket."
            onChange={e => handleConfigChange({ roleArn: e.target.value })}
          />
          <TextField
            disabled={disabled}
            label="Database"
            variant="outlined"
            value={value.database}
            required
            fullWidth
            helperText="The name of the Redshift database to use."
            onChange={e => handleConfigChange({ database: e.target.value })}
          />
          <TextField
            disabled={disabled}
            label="Schema"
            variant="outlined"
            value={value.schema}
            required
            fullWidth
            helperText="The name of the Redshift schema where the connector creates the preceding table."
            onChange={e => handleConfigChange({ schema: e.target.value })}
          />
        </FormFieldsBox>
      </FormFieldsBox>
    </>
  );
};
