import React from 'react';

import { FeatureFlagged, FormFieldsBox } from '@spotify-confidence/core-react';
import { MetricsV1MetricInput } from '@spotify-confidence/plugin-graphql';

import { defaultConsumptionSpec, defaultSpecs } from '../../metricTypes';
import { MeasurementSelect } from './MeasurementSelect';
import { MetricFilters } from './MetricFilters';
import { MetricInputsForm } from './MetricInputsForm';

type ConsumptionMetricInputsFormProps = {
  value: MetricsV1MetricInput;
  onChange: (newInput: MetricsV1MetricInput) => void;
};

export const ConsumptionMetricInputsForm = ({
  value,
  onChange,
}: ConsumptionMetricInputsFormProps) => {
  const handleMeasurementChange = (measurementName: string) => {
    onChange({
      ...value,
      typeSpec: {
        ...defaultConsumptionSpec,
        averageMetricSpec: {
          ...defaultConsumptionSpec.averageMetricSpec,
          measurement: {
            name: measurementName || '',
          },
        },
      },
    });
  };

  React.useEffect(() => {
    onChange({
      ...value,
      nullHandling: {
        replaceEntityNullWithZero: true,
      },
    });
  }, []);

  return (
    <FormFieldsBox>
      <MetricInputsForm
        value={value}
        onChange={onChange}
        defaultSpec={defaultSpecs.consumption}
      />

      <FeatureFlagged with="metric-filters">
        <MetricFilters
          factTable={value.factTable}
          filter={value.filter}
          onChange={newFilter => {
            onChange({
              ...value,
              filter: newFilter,
            });
          }}
        />
      </FeatureFlagged>

      {value.typeSpec?.averageMetricSpec && (
        <MeasurementSelect
          label="Consumption value"
          name="consumption-value"
          emptyLabel=""
          required
          value={value.typeSpec.averageMetricSpec.measurement?.name || ''}
          factTable={value.factTable}
          onChange={handleMeasurementChange}
          disabled={!value.factTable}
          helperText="The measurement that represents consumption for an entity."
        />
      )}
    </FormFieldsBox>
  );
};
