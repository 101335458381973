import React from 'react';

import { Box, Collapse, Typography } from '@material-ui/core';

import { MonitorCard } from '../MonitorCard';
import { useConnect } from '../context';
import { TUTORIAL_FLAG_NAME } from './constants';
import { useFlagResolved } from './useFlagResolved';

const Connected = () => {
  return (
    <MonitorCard title="Connected">
      <Typography paragraph variant="body1" color="textSecondary">
        Great job! You have now added a feature flag that can control your
        product from Confidence. This allows you to test different experiences
        against each other or gradually roll out changes.
      </Typography>
      <Typography>
        Next up: track events to measure the impact of your changes.
      </Typography>
    </MonitorCard>
  );
};

const Waiting = () => {
  return (
    <MonitorCard
      waiting
      title={
        <>
          Waiting for{' '}
          <Typography component="span" variant="inherit" color="primary">
            {TUTORIAL_FLAG_NAME}
          </Typography>{' '}
          to be used...
        </>
      }
    >
      <Typography variant="body1" color="textSecondary" paragraph>
        Launch your application (locally is totally OK). Confidence will detect
        when the flag has been connected (this might take a few minutes).
      </Typography>
    </MonitorCard>
  );
};

export function MonitorFlag() {
  const { client, setFlagResolved } = useConnect();
  const { hasResolved, startPolling, stopPolling } = useFlagResolved({
    skip: !client,
  });

  React.useEffect(() => {
    if (!hasResolved) {
      startPolling(5000);
    } else {
      stopPolling();
    }
  }, [hasResolved]);

  React.useEffect(() => {
    setFlagResolved(Boolean(hasResolved));
  }, [hasResolved, setFlagResolved]);

  return (
    <Collapse in={Boolean(client)} mountOnEnter>
      <Box marginY={4}>{hasResolved ? <Connected /> : <Waiting />}</Box>
    </Collapse>
  );
}
