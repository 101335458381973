import React from 'react';

import { Box, BoxProps, Typography, makeStyles } from '@material-ui/core';

import classNames from 'classnames';

const useStyles = makeStyles(theme => ({
  section: {
    borderTop: `1px solid ${theme.palette.divider}`,
    paddingTop: theme.spacing(2),
    marginBottom: theme.spacing(1),
  },
  noDivider: {
    '&$section': {
      borderTop: 'none',
    },
  },
}));

type CardSectionProps = BoxProps & {
  title?: string;
  description?: React.ReactNode;
  htmlFor?: string;
  justifyContent?: BoxProps['justifyContent'];
  noDivider?: boolean;
};

export const CardSection = ({
  className,
  children,
  title,
  description,
  htmlFor,
  justifyContent,
  noDivider,
  ...props
}: CardSectionProps) => {
  const classes = useStyles();

  return (
    <Box
      flex={1}
      className={classNames(classes.section, className, {
        [classes.noDivider]: noDivider,
      })}
      {...props}
    >
      <Box
        display="flex"
        flexDirection="column"
        justifyContent={justifyContent ?? 'space-between'}
        height="100%"
      >
        {(title || description) && (
          <Box mb={1}>
            {title && (
              <Typography variant="h6" component="label" htmlFor={htmlFor}>
                {title}
              </Typography>
            )}
            {description && (
              <Typography variant="body1" color="textSecondary">
                {description}
              </Typography>
            )}
          </Box>
        )}
        {children}
      </Box>
    </Box>
  );
};
