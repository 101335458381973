import {
  createPlugin,
  createRoutableExtension,
} from '@backstage/core-plugin-api';

import {
  assignmentTableRouteRef,
  assignmentTablesCreateRouteRef,
  entitiesRouteRef,
  entityRouteRef,
  factTableRouteRef,
  flagRouteRef,
  flagsRouteRef,
  metricRouteRef,
  metricsRouteRef,
  rootRouteRef,
  workflowInstanceRouteRef,
  workflowRouteRef,
} from './routes';

export const workflowsPlugin = createPlugin({
  id: 'workflows',
  routes: {
    root: rootRouteRef,
    workflowRoute: workflowRouteRef,
    workflowInstanceRoute: workflowInstanceRouteRef,
  },
  externalRoutes: {
    flag: flagRouteRef,
    flags: flagsRouteRef,
    metric: metricRouteRef,
    metrics: metricsRouteRef,
    entity: entityRouteRef,
    entities: entitiesRouteRef,
    assignmentTable: assignmentTableRouteRef,
    assignmentTablesCreate: assignmentTablesCreateRouteRef,
    factTable: factTableRouteRef,
  },
});

export const ConfidenceWorkflowsPage = workflowsPlugin.provide(
  createRoutableExtension({
    name: 'ConfidenceWorkflowsPage',
    component: () =>
      import(
        /* webpackChunkName: "confidence-workflows", webpackPrefetch: 10 */ './Router'
      ).then(m => m.Router),
    mountPoint: rootRouteRef,
  }),
);
