import { isAfter, isBefore, isSameDay, startOfDay } from 'date-fns';

import { TimelineActivityValues } from '../types';

export function getActivityState({
  start,
  plannedStart,
  end,
  plannedEnd,
}: TimelineActivityValues<number>) {
  const today = startOfDay(new Date());

  const state = {
    hasTimeData: Boolean(start || plannedStart || end || plannedEnd),
    notYetStarted: !start,
    startsToday: false,
    endsToday: true,
    plannedEndInFuture: false,
    plannedStartOverdue: false,
    startedLate: false,
    endedEarly: false,
    endedLate: false,
    startAfterPlannedEnd: false,
    plannedEndOverdue: false,
    startedTodayWithoutEnd: false,
    passedCompletePlan: false,
    ranOnlyToday: false,
  };

  const activityStart = start || plannedStart;
  const activityEnd = end || plannedEnd;

  if (activityStart) {
    state.startsToday = isSameDay(today, activityStart);
  }
  if (start && plannedStart) {
    state.startedLate = isBefore(startOfDay(plannedStart), startOfDay(start));
  }
  if (plannedStart && !start) {
    state.plannedStartOverdue = isBefore(plannedStart, today);
  }
  if (start && plannedEnd) {
    state.startAfterPlannedEnd = isAfter(start, plannedEnd);
  }

  if (activityEnd) {
    state.endsToday = isSameDay(today, activityEnd);
    state.ranOnlyToday = state.startsToday && state.endsToday;
  }
  if (plannedEnd && end) {
    state.endedLate = isAfter(end, plannedEnd);
    state.endedEarly = isBefore(startOfDay(end), startOfDay(plannedEnd));
  }
  if (plannedEnd && !end) {
    state.plannedEndInFuture = isBefore(today, plannedEnd);
    state.plannedEndOverdue = isBefore(plannedEnd, today);
  }

  if (state.plannedEndOverdue && state.plannedStartOverdue) {
    state.passedCompletePlan = true;
  }

  state.startedTodayWithoutEnd = state.startsToday && !end;

  return state;
}
