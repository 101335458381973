/* From https://ghe.spotify.net/konfidens/workflows */

export type SampleSizeMetric = {
  metric: string;
  requiredSampleSize?: number;
  // metricCalculation?: MetricCalculation;
  error?: string;
};

export enum SampleSizeState {
  Empty = 'EMPTY',
  CalculatingExposure = 'CALCULATING_EXPOSURE',
  CalculatingMetrics = 'CALCULATING_METRICS',
  Done = 'DONE',
  Error = 'ERROR',
  Stale = 'STALE',
}

export type SampleSizeData = {
  requiredSampleSize?: number;
  // exposureCalculation?: ExposureCalculation;
  metrics: SampleSizeMetric[];
  state: SampleSizeState;
  error?: string;
};
