import React from 'react';

import {
  Dialog,
  DialogActions,
  DialogContent,
  IconButton,
  List,
  ListItem,
  ListItemSecondaryAction,
  ListItemText,
  styled,
} from '@material-ui/core';
import Add from '@material-ui/icons/Add';
import Close from '@material-ui/icons/Close';

import {
  DialogHeader,
  FormSubmitButtons,
  TimeWindowInput,
  timeWindowUtils,
  useLocalStorage,
} from '@spotify-confidence/core-react';
import _ from 'lodash';

import { defaultOptionValues, storageKey } from './constants';

type CustomOptionDialogProps = {
  open?: boolean;
  onClose?: () => void;
};

const NoPaddingListItem = styled(ListItem)({
  paddingTop: 0,
  paddingBottom: 0,
}) as typeof ListItem;

export const CustomOptionDialog = ({
  open = false,
  onClose,
}: CustomOptionDialogProps) => {
  const [storedOptions, setStoredOptions] = useLocalStorage(
    storageKey,
    defaultOptionValues,
  );
  const [options, setOptions] = React.useState(storedOptions);

  const addOption = () => {
    setOptions(current => current.concat('0s'));
  };

  const setOptionValue = (index: number, value: string) => {
    setOptions(current => current.map((v, i) => (i === index ? value : v)));
  };

  const removeOption = (index: number) => {
    setOptions(current => current.filter((_o, i) => i !== index));
  };

  const handleClose = () => {
    setOptions(storedOptions);
    onClose?.();
  };

  const handleSave = () => {
    setStoredOptions(_.uniq(options).sort(timeWindowUtils.sortTimewindows));
    onClose?.();
  };

  const formIsInvalid = options.includes('0s');

  return (
    <Dialog open={open} onClose={handleClose}>
      <DialogHeader
        title="Add options"
        subTitle="Manage custom time window options. These will persist in your browser."
        onClose={handleClose}
      />
      <DialogContent>
        <List>
          {options.map((option, index) => (
            <NoPaddingListItem key={index} disableGutters>
              <TimeWindowInput
                label=""
                margin="dense"
                value={option}
                required
                onChange={value => setOptionValue(index, value || '0s')}
              />
              <ListItemSecondaryAction>
                <IconButton
                  size="small"
                  onClick={() => removeOption(index)}
                  disabled={options.length < 2}
                >
                  <Close fontSize="small" />
                </IconButton>
              </ListItemSecondaryAction>
            </NoPaddingListItem>
          ))}
          <ListItem disableGutters button onClick={addOption}>
            <IconButton size="small">
              <Add fontSize="small" />
            </IconButton>

            <ListItemText primary="Add option" />
          </ListItem>
        </List>
      </DialogContent>
      <DialogActions>
        <FormSubmitButtons
          onCancel={handleClose}
          disabled={formIsInvalid}
          onSubmit={handleSave}
        />
      </DialogActions>
    </Dialog>
  );
};
