import React, { useEffect, useState } from 'react';

import { Chip, Tooltip, createStyles, makeStyles } from '@material-ui/core';
import Visibility from '@material-ui/icons/Visibility';

import {
  isType,
  useRuleResolvedCounterQuery,
} from '@spotify-confidence/plugin-graphql';

export const useStyles = makeStyles(theme =>
  createStyles({
    chip: {
      margin: 0,
      backgroundColor: theme.palette.action.selected,
      color: theme.palette.text.primary,
      '& > svg': {
        color: 'inherit',
      },

      '& > span': {
        fontWeight: 500,
      },
    },
    bold: {
      fontWeight: 700,
    },
  }),
);

type Props = {
  name: string;
};

function useCount({ period }: { period: number }) {
  const fr = 60;

  const [count, setCount] = useState<number | null>(null);
  const [target, setTarget] = useState<number | null>(null);
  const [diff, setDiff] = useState<number>(0);

  function updateTarget(newTarget: number) {
    let current;
    if (typeof count !== 'number') {
      // rolling start
      current = newTarget > 10000 ? newTarget * 0.999 : newTarget;
      setCount(current);
    } else {
      current = count;
    }
    setTarget(newTarget);
    setDiff(newTarget - current);
  }

  useEffect(() => {
    function update() {
      if (!target) return;
      const current = count || target;

      // calculate ease in with smooth step between 0.9-0.1
      const p = 1 - (target - current) / diff;
      const ease = Math.min(Math.max((p - 1) / (0.9 - 1), 0), 1) || 0;

      const next = current + (ease * diff) / ((0.9 * fr * period) / 1000);
      setCount(next);
    }

    const interval = setInterval(update, 1000 / fr);
    return () => {
      clearInterval(interval);
    };
  });

  return {
    count: typeof count === 'number' ? Math.round(count) : null,
    set: function set(v: number) {
      updateTarget(v);
    },
  };
}

export const RuleResolvedCounter = ({ name }: Props) => {
  const pollInterval = 30000;
  const { count, set } = useCount({ period: pollInterval });

  const { startPolling, stopPolling, data } = useRuleResolvedCounterQuery({
    variables: {
      name: name!,
    },
    fetchPolicy: 'no-cache',
    onError: () => {
      stopPolling();
    },
  });

  startPolling(pollInterval);
  React.useEffect(() => {
    if (
      isType(data?.rule, 'FlagsAdminV1FlagRule') &&
      typeof data?.rule?.usageMetadata.resolveCount === 'number'
    ) {
      set(data.rule.usageMetadata.resolveCount);
    }
  }, [data]);

  const classes = useStyles();
  const formattedCount = count === null ? '...' : count.toLocaleString();

  return (
    <Tooltip
      title={
        count === null
          ? formattedCount
          : `Resolved ${formattedCount} time${count === 1 ? '' : 's'}`
      }
    >
      <span>
        <Chip
          size="small"
          disabled={count === 0 || count === null}
          icon={<Visibility />}
          className={classes.chip}
          label={formattedCount}
        />
      </span>
    </Tooltip>
  );
};
