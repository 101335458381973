import React from 'react';

import {
  ListItemText,
  Theme,
  WithStyles,
  createStyles,
  withStyles,
} from '@material-ui/core';
import { Alert, AlertTitle } from '@material-ui/lab';

import { CommentZone } from '@spotify-confidence/plugin-comments-react';
import classNames from 'classnames';

import { VariantValue } from '../MetricResultTable';
import { Annotation } from '../types';
import { getAnnotationContextValue } from '../utils';
import { useMetricResultContext } from './context';

function renderAnnotation(annotation: Annotation) {
  const group = getAnnotationContextValue(annotation, 'CONTEXT_GROUP');
  const message =
    annotation.error?.details || annotation.warning || annotation.info;
  return (
    <>
      {group ? (
        <VariantValue variant={group} value={message} />
      ) : (
        <ListItemText primary={message} />
      )}
    </>
  );
}

const styles = (theme: Theme) =>
  createStyles({
    root: {
      display: 'flex',
      '& > *': {
        // min width zero to make noWrap for typography work
        // in the children
        minWidth: 0,
        flex: 1,
      },
      gap: theme.spacing(2),
      flexDirection: 'row',
      alignItems: 'center',
    },
    subList: {
      '&:not(:first-child)': {
        marginTop: theme.spacing(2),
        borderTop: `1px solid ${theme.palette.divider}`,
      },
    },
    listSubheader: {
      display: 'inline-flex',
      gap: theme.spacing(1),
      alignItems: 'center',
    },
    listItem: {
      marginLeft: theme.spacing(1),
    },
    textWrapper: {
      whiteSpace: 'normal', // Allow text to wrap
      wordBreak: 'break-word', // Break words if necessary
    },
    errorHeader: {
      marginBottom: theme.spacing(2),
    },
    errorContainer: {
      display: 'flex',
      flexWrap: 'wrap',
      gap: theme.spacing(2),
    },
  });

export interface ErrorProps extends WithStyles<typeof styles> {
  className?: string;
  component?: React.ElementType<{ className?: string }>;
}

const Error = ({
  classes,
  className,
  component: Component = 'div',
}: ErrorProps) => {
  const { value: result } = useMetricResultContext();
  const errors =
    result?.annotations?.filter(annotation => annotation.error) ?? [];
  if (errors.length === 0) {
    return null;
  }
  return (
    <Component className={classNames(classes.root, className)}>
      <CommentZone id="error">
        <Alert severity="error">
          <div className={classes.errorHeader}>
            <AlertTitle>Metric error</AlertTitle>
          </div>
          <div className={classes.errorContainer}>
            {errors.map(error => renderAnnotation(error))}
          </div>
        </Alert>
      </CommentZone>
    </Component>
  );
};

export default withStyles(styles)(Error);
