import React, { PropsWithChildren } from 'react';

import { useAnalytics } from '@backstage/core-plugin-api';

function textContent(elem: React.ReactElement | string): string {
  if (!elem) {
    return '';
  }

  if (typeof elem === 'string') {
    return elem;
  }
  const children = elem.props && elem.props.children;
  if (children instanceof Array) {
    return children.map(textContent).join('');
  }
  return textContent(children);
}

export function InteractionTracker({
  subject: explicitSubject,
  children,
  ...attributes
}: PropsWithChildren<{ subject?: string; [k: string]: any }>) {
  const analytics = useAnalytics();

  const child = React.Children.only(children);
  if (!React.isValidElement(child)) {
    throw new Error(
      'InteractionTracker can only have a single child that must be an element',
    );
  }

  const subject = explicitSubject ?? textContent(child);

  const onClick = (...args: any[]) => {
    analytics.captureEvent('click', subject, attributes);
    return child.props.onClick?.(...args);
  };

  return React.cloneElement(child, { ...child.props, onClick });
}
