import React from 'react';

import { Link, MenuItem, TextField } from '@material-ui/core';

import {
  ConnectorsV1SnowflakeConfigInput,
  ConnectorsV1EventConnectionSnowflakeEventConnectionConfigInput as SnowflakeInput,
} from '@spotify-confidence/plugin-graphql';

import { CryptoKeyInput } from '../../../metrics-datawarehouse/components/Snowflake/CryptoKeyInput';

type SnowflakeFlagAppliedConnectionFormProps = {
  value?: ConnectorsV1SnowflakeConfigInput | null;
  onChange: (conf: ConnectorsV1SnowflakeConfigInput) => void;
  disabled?: boolean;
  snowflakeConfigValidation?: SnowflakeValidation | null;
  validating?: boolean;
  validationError?: Error | null;
  validationStatus: React.ReactNode;
};

const defaultConfig: SnowflakeInput['snowflakeConfig'] = {
  authenticationKey: '',
  account: '',
  user: '',
  role: '',
  database: '',
  schema: '',
};

type SnowflakeValidation = {
  databases: string[];
  roles: string[];
  schemas: string[];
};

export const canSendValidationRequest = (
  p: Partial<SnowflakeInput['snowflakeConfig']> = {},
) => {
  return !!(p.user && p.account && p.authenticationKey);
};

function getAvailableOptionsFromValidation(
  validation?: SnowflakeValidation | null,
  type?: keyof SnowflakeValidation,
  currentValue?: string,
) {
  if (validation && type) {
    const options = validation?.[type] || [];
    if (options.length > 0) {
      return options;
    }
  }
  if (currentValue) {
    return [currentValue];
  }
  return [];
}

export const SnowflakeConnectionForm = ({
  value,
  onChange,
  disabled,
  snowflakeConfigValidation,
  validationError,
  validating = false,
  validationStatus,
}: SnowflakeFlagAppliedConnectionFormProps) => {
  const account = value?.account ?? '';
  const user = value?.user ?? '';
  const role = value?.role ?? '';
  const authenticationKey = value?.authenticationKey ?? '';
  const database = value?.database ?? '';
  const schema = value?.schema ?? '';

  const availableRoles = getAvailableOptionsFromValidation(
    snowflakeConfigValidation,
    'roles',
    role,
  );
  const availableDatabases = getAvailableOptionsFromValidation(
    snowflakeConfigValidation,
    'databases',
    database,
  );
  const availableSchemas = getAvailableOptionsFromValidation(
    snowflakeConfigValidation,
    'schemas',
    schema,
  );

  const handleConfigChange =
    (field: keyof ConnectorsV1SnowflakeConfigInput) =>
    (e: React.ChangeEvent<HTMLInputElement>) => {
      onChange({
        ...defaultConfig,
        ...value,
        [field]: e.target.value,
      });
    };

  return (
    <>
      <TextField
        disabled={disabled}
        label="Account identifier"
        variant="outlined"
        value={account}
        required
        fullWidth
        helperText={
          <>
            The{' '}
            <Link
              href="https://docs.snowflake.com/en/user-guide/admin-account-identifier"
              underline="always"
              target="_blank"
            >
              Snowflake account identifier
            </Link>{' '}
            (e.g. myorg-account123).
          </>
        }
        onChange={handleConfigChange('account')}
      />
      <TextField
        disabled={disabled}
        label="User"
        variant="outlined"
        value={user}
        required
        fullWidth
        helperText="The Snowflake user name."
        onChange={handleConfigChange('user')}
      />
      <CryptoKeyInput
        disabled={disabled}
        label="Crypto Key"
        variant="outlined"
        value={authenticationKey}
        required
        fullWidth
        helperText={
          <>
            The key used to authenticate the requests to the Snowflake API. Copy
            the public key and{' '}
            <Link
              href="https://docs.snowflake.com/en/user-guide/key-pair-auth#step-4-assign-the-public-key-to-a-snowflake-user"
              underline="always"
              target="_blank"
            >
              assign it
            </Link>{' '}
            to the Snowflake user configured above.
          </>
        }
        onChange={newValue =>
          onChange({
            ...defaultConfig,
            ...value,
            authenticationKey: newValue,
          })
        }
      />
      {validationStatus}

      <TextField
        label="Role"
        select
        required
        value={role}
        disabled={validating || availableRoles.length === 0}
        error={!!validationError}
        onChange={handleConfigChange('role')}
        helperText="The role to use to write data to your table."
        variant="outlined"
      >
        <MenuItem value="">Select role</MenuItem>
        {availableRoles.map(availableRole => (
          <MenuItem key={availableRole} value={availableRole}>
            {availableRole}
          </MenuItem>
        ))}
      </TextField>

      <TextField
        label="Database"
        select
        required
        value={database}
        disabled={validating || availableDatabases.length === 0}
        error={!!validationError}
        onChange={handleConfigChange('database')}
        helperText="The database where Confidence will store the data."
        variant="outlined"
      >
        <MenuItem value="">Select database</MenuItem>
        {availableDatabases.map(availableDatabase => (
          <MenuItem key={availableDatabase} value={availableDatabase}>
            {availableDatabase}
          </MenuItem>
        ))}
      </TextField>

      <TextField
        label="Schema"
        select
        required
        value={schema}
        disabled={validating || availableSchemas.length === 0}
        error={!!validationError}
        onChange={handleConfigChange('schema')}
        helperText="The schema where Confidence will store the data."
        variant="outlined"
      >
        <MenuItem value="">Select schema</MenuItem>
        {availableSchemas.map(availableSchema => (
          <MenuItem key={availableSchema} value={availableSchema}>
            {availableSchema}
          </MenuItem>
        ))}
      </TextField>
    </>
  );
};
