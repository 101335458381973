import React from 'react';
import { Link } from 'react-router-dom';

import {
  Box,
  Grid,
  Link as MuiLink,
  makeStyles,
  styled,
} from '@material-ui/core';

import {
  SidebarSection,
  extractLastNameComponent,
  useDialog,
  useDisplayNames,
} from '@spotify-confidence/core-react';
import { metricRouteRef } from '@spotify-confidence/plugin-workflows';

import { useRouteRef } from '@backstage/core-plugin-api';

import { Metric, MetricsData, ScheduledMetricCalculationDialog } from '..';
import { MetricStatus } from '../../metric/components/MetricStatus';
import { useMetricsWithSchedule } from '../useMetricsWithSchedule';

const useStyles = makeStyles({
  fixedWidth: {
    width: '10em', // Adjust this value as needed
  },
});

type MetricsCalculationSidebarSectionProps = {
  metricsData?: MetricsData;
};

const CalculationsButton = styled(Box)({
  cursor: 'pointer',
});

export const MetricsCalculationSidebarSection = ({
  metricsData,
}: MetricsCalculationSidebarSectionProps) => {
  const { displayNames } = useDisplayNames();
  const { openDialog } = useDialog();

  const metricRoute = useRouteRef(metricRouteRef);
  const classes = useStyles();

  const handleMetricSchedule = (metric: Metric) => {
    openDialog({
      dialogProps: {
        maxWidth: 'lg',
      },
      content: <ScheduledMetricCalculationDialog name={metric.schedule} />,
    });
  };

  const metricsWithSchedules = useMetricsWithSchedule(metricsData);

  return (
    <SidebarSection title="Metric calculations" zoneContext="metrics">
      {metricsWithSchedules &&
        metricsWithSchedules
          .filter(metric => metric.scheduleData)
          .map(metric => {
            const scheduledMetricCalculation = metric.scheduleData;
            const metricId = extractLastNameComponent(metric?.metric);
            return (
              <Grid container alignItems="center">
                <Grid item xs={6} className={classes.fixedWidth}>
                  <MuiLink
                    variant="body2"
                    title={displayNames.get(metric.metric)}
                    component={Link}
                    to={
                      metricRoute?.({
                        id: metricId ?? '',
                      }) ?? ''
                    }
                  >
                    {displayNames.get(metric.metric)}
                  </MuiLink>
                </Grid>
                <Grid item xs={6}>
                  <CalculationsButton
                    onClick={() => handleMetricSchedule(metric)}
                  >
                    <MetricStatus schedule={scheduledMetricCalculation!} />
                  </CalculationsButton>
                </Grid>
              </Grid>
            );
          })}
    </SidebarSection>
  );
};
