import React from 'react';

import { DialogActions, DialogContent } from '@material-ui/core';
import { Alert } from '@material-ui/lab';

import {
  DialogHeader,
  EditButton,
  FormFieldsBox,
  FormSubmitButtons,
  useDialog,
} from '@spotify-confidence/core-react';
import {
  EventDefinitionFragment,
  getTypeOrNull,
  useUpdateEventDefinitionMutation,
} from '@spotify-confidence/plugin-graphql';
import { IdentityAutocomplete } from '@spotify-confidence/plugin-permissions-react';

const AboutDialog = ({
  eventDefinition,
}: {
  eventDefinition: EventDefinitionFragment;
}) => {
  const { closeDialog } = useDialog();
  const [owner, setOwner] = React.useState(
    getTypeOrNull(eventDefinition?.owner, 'IamV1Identity')?.name ?? null,
  );

  const [updateEventDefinition, { loading: saving, error }] =
    useUpdateEventDefinitionMutation({
      onCompleted: () => {
        closeDialog();
      },
    });

  const handleSave = async (e: React.ChangeEvent<{}>) => {
    e.preventDefault();
    await updateEventDefinition({
      variables: {
        event: {
          owner: owner,
          name: eventDefinition.name,
        },
        updateMask: 'owner',
      },
    });
  };

  return (
    <form onSubmit={handleSave}>
      <DialogHeader title="About" onClose={closeDialog} />
      <DialogContent>
        <FormFieldsBox>
          {error && <Alert severity="error">{error.message}</Alert>}
          <IdentityAutocomplete
            value={owner}
            onChange={(_e, newIdentity) => {
              setOwner(newIdentity);
            }}
            label="Owner"
            required
          />
        </FormFieldsBox>
      </DialogContent>
      <DialogActions>
        <FormSubmitButtons onCancel={closeDialog} loading={saving} />
      </DialogActions>
    </form>
  );
};

export const EditAboutButton = ({
  eventDefinition,
  disabled,
}: {
  eventDefinition?: Maybe<EventDefinitionFragment>;
  disabled?: boolean;
}) => {
  const { openDialog } = useDialog();
  const handleEditAbout = () => {
    if (eventDefinition) {
      openDialog({
        content: <AboutDialog eventDefinition={eventDefinition} />,
      });
    }
  };

  return (
    <EditButton size="small" onClick={handleEditAbout} disabled={disabled} />
  );
};
