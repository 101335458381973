import React from 'react';

import { Theme, makeStyles } from '@material-ui/core';

import _ from 'lodash';

export const ACTION_BAR_HEIGHT = 70;

type StyleProps = { columns: number; topOffset: number };

const useStyles = makeStyles<Theme, StyleProps>(theme => ({
  root: {
    position: 'sticky',
    top: ({ topOffset }) => topOffset,
    zIndex: 5,
    background: theme.palette.background.default,
    width: '100%',
    minHeight: ACTION_BAR_HEIGHT,
  },
  content: {
    display: 'grid',
    padding: theme.spacing(2, 0),
    rowGap: theme.spacing(1),
    columnGap: theme.spacing(2),
    alignItems: 'center',
    gridAutoFlow: 'column',
    justifyContent: 'space-between',
    [theme.breakpoints.up('sm')]: {
      justifyContent: 'flex-start',
      gridTemplateColumns: ({ columns }) =>
        `${
          columns - 1 !== 0 ? `repeat(${columns - 1}, max-content)` : ''
        } 1fr max-content`,
      '& > *:last-child': {
        gridColumn: -1,
      },
    },
  },
}));

export const ActionBar = React.forwardRef<
  HTMLDivElement | null,
  React.PropsWithChildren<{ topOffset?: number }>
>(({ topOffset, children }, ref) => {
  const classes = useStyles({
    columns: _.isArray(children) ? children.length : 1,
    topOffset: topOffset || 0,
  });

  return (
    <div className={classes.root} ref={ref}>
      <div className={classes.content}>{children}</div>
    </div>
  );
});
