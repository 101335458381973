import { extractLastNameComponent } from '@spotify-confidence/core-react';
import {
  ClientFragment,
  DraftEventFragment,
  EventDefinitionFragment,
  EventDefinitionFragmentDoc,
  EventSchemaEntryFragment,
  EventsDocument,
  getError,
  getTypeOrNull,
  isError,
  useCreateEventMutation,
  useDraftEventsQuery,
  useOnEventDefinitionCreatedSubscription,
} from '@spotify-confidence/plugin-graphql';

import { toEventSchemaInput } from './useEvent';

export type DraftEvent = {
  id: string;
  reason: DraftEventFragment['reason'];
  payload: DraftEventFragment['payload'];
  lastSeen: Date;
  schema: EventSchemaEntryFragment[];
  client: ClientFragment | undefined | null;
};

/**
 * Hook for fetching draft events and creating new events from them.
 *
 * @public
 * @returns {draftEvents} current draft events
 * @returns {loading} whether the query is loading
 * @returns {error} any error that occurred during the query
 * @returns {loadMore} function for fetching the next page of draft events
 * @returns {createEvent} function for creating a new event
 */
export function useDraftEvents() {
  const {
    data,
    error: apolloError,
    fetchMore,
    loading,
  } = useDraftEventsQuery({
    // Poll every 30 seconds so that we can see new draft events
    pollInterval: 30 * 1000,
  });

  const { draftEvents: rawDraftEvents, nextPageToken } = getTypeOrNull(
    data?.queryDraftEvents,
    'EventsAdminV1QueryDraftEventsResponse',
  ) ?? { draftEvents: [], nextPageToken: '' };

  const error = getError(data?.queryDraftEvents) ?? apolloError;

  const loadMore = () =>
    fetchMore({
      variables: {
        nextPageToken,
      },
    });

  const draftEvents: DraftEvent[] = rawDraftEvents.map(draftEvent => ({
    id: extractLastNameComponent(draftEvent.draftEventDefinition!)!,
    reason: draftEvent.reason,
    payload: draftEvent.payload,
    lastSeen: new Date(draftEvent.eventTime),
    schema: draftEvent.schema?.structSchema?.schema ?? [],
    client: getTypeOrNull(draftEvent.client, 'IamV1Client'),
  }));

  const [createMutation] = useCreateEventMutation({
    refetchQueries: [EventsDocument],
    update: (cache, { data: createData }) => {
      if (isError(createData?.createEventDefinition)) {
        return;
      }

      const newEvent = getTypeOrNull(
        createData?.createEventDefinition,
        'EventsAdminV1EventDefinition',
      );

      if (!newEvent) {
        return;
      }

      cache.modify({
        fields: {
          queryDraftEvents(response = { draftEvents: [] }) {
            const existingDraftEvents = response.draftEvents ?? [];
            const filteredDraftEvents = [...existingDraftEvents].filter(
              (draftEvent: DraftEventFragment) =>
                draftEvent.draftEventDefinition !== newEvent.name,
            );

            return {
              ...response,
              draftEvents: filteredDraftEvents,
            };
          },
        },
      });
    },
  });

  // Remove the draft event from the list when a new event is created
  useOnEventDefinitionCreatedSubscription({
    onData: options => {
      const e = options.data.data?.eventDefinitionCreated?.eventDefinition;

      options.client.cache.modify({
        fields: {
          queryDraftEvents(response = { draftEvents: [] }) {
            const existingDraftEvents = response.draftEvents ?? [];
            const filteredDraftEvents = [...existingDraftEvents].filter(
              (draftEvent: DraftEventFragment) =>
                draftEvent.draftEventDefinition !== e?.name,
            );

            return {
              ...response,
              draftEvents: filteredDraftEvents,
            };
          },
        },
      });

      if (e) {
        const cacheId = options.client.cache.identify(e);

        if (cacheId) {
          const cached = options.client.cache.readFragment({
            id: cacheId,
            fragment: EventDefinitionFragmentDoc,
            fragmentName: 'EventDefinition',
          }) as EventDefinitionFragment | null;

          // Only update fragment if the new event is newer
          if (!cached || cached.updateTime < e.updateTime)
            options.client.cache.writeFragment({
              id: cacheId,
              data: e,
              fragment: EventDefinitionFragmentDoc,
              fragmentName: 'EventDefinition',
            });
        }
      }
    },
  });

  const createEvent = async ({
    eventId,
    owner,
    schema,
  }: {
    eventId: string;
    owner: string;
    schema: EventSchemaEntryFragment[];
  }) => {
    const schemaInput = toEventSchemaInput(schema);

    const result = await createMutation({
      variables: {
        eventId,
        event: {
          owner,
          schema: schemaInput,
        },
      },
    });

    if (isError(result.data?.createEventDefinition)) {
      throw getError(result.data?.createEventDefinition);
    }

    return getTypeOrNull(
      result.data?.createEventDefinition,
      'EventsAdminV1EventDefinition',
    );
  };

  return {
    draftEvents,
    loading,
    error,
    loadMore,
    createEvent,
  };
}
