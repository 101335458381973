import React from 'react';

import {
  Table,
  TableBody,
  TableCell,
  TableFooter,
  TableHead,
  TableProps,
  TableRow,
  makeStyles,
} from '@material-ui/core';

import { useDisplayNames } from '@spotify-confidence/core-react';
import { CommentZoneContext } from '@spotify-confidence/plugin-comments-react';
import '@tanstack/react-table';
import { Row, Table as TableType } from '@tanstack/react-table';
import classNames from 'classnames';

import { DataCell } from './DataCell';
import { DataRow } from './DataRow';
import { FooterRow } from './Footer';
import { HeaderRow } from './Header';
import { ResultData } from './types';

type MetricResultTableProps = Omit<TableProps, 'children' | 'results'> & {
  table: TableType<ResultData>;
};

const useStyles = makeStyles(theme => ({
  table: {
    '& tr': {
      backgroundColor: 'transparent',
      '& th': {
        wordBreak: 'keep-all',
      },
      '&:nth-of-type(odd), &:nth-of-type(even)': {
        backgroundColor: 'transparent',
      },
      '&:hover': {
        cursor: 'default',
      },
    },
  },
  headerRow: {
    '&:not(:first-child) th': {
      paddingTop: theme.spacing(8),
    },
    '& + & th': {
      paddingTop: theme.spacing(2),
    },
  },
}));

export const MetricResultTable = ({
  table,
  ...tableProps
}: MetricResultTableProps) => {
  const classes = useStyles();
  const grouping = table.getState().grouping;
  const { displayNames } = useDisplayNames();

  const shouldRenderFooter = table
    .getFooterGroups()
    .some(f => f.headers.some(h => !!h.column.columnDef.footer));

  const header = (
    <TableHead>
      {table.getHeaderGroups().map(headerGroup => (
        <HeaderRow
          key={headerGroup.id}
          headerGroup={headerGroup}
          grouping={grouping}
        />
      ))}
    </TableHead>
  );

  const footer = shouldRenderFooter && (
    <TableRow>
      {table.getFooterGroups().map(headerGroup => (
        <FooterRow
          key={headerGroup.id}
          headerGroup={headerGroup}
          grouping={grouping}
        />
      ))}
    </TableRow>
  );

  function renderGroupRows(row: Row<ResultData>) {
    const isSecondToLastGrouping = row.depth === grouping.length - 2;
    const isLastGrouping = row.depth === grouping.length - 1;

    const groupCell = row
      .getAllCells()
      .find(cell => cell.column.id === row.groupingColumnId);
    const colSpan = row.getVisibleCells().length - 1;

    if (isLastGrouping) {
      return (
        <React.Fragment key={row.id}>
          <TableBody>
            <React.Fragment key={row.id}>
              <DataRow row={row} grouping={grouping} />
              {row.getIsExpanded() &&
                row.subRows.map(subRow => (
                  <DataRow key={subRow.id} row={subRow} grouping={grouping} />
                ))}
            </React.Fragment>
          </TableBody>
        </React.Fragment>
      );
    }

    // We want to keep everything in the same table to align columns across groups
    return (
      <React.Fragment key={row.id}>
        <TableHead className={classes.headerRow}>
          <TableRow>
            {groupCell ? (
              <DataCell
                cell={groupCell}
                grouping={grouping}
                colSpan={colSpan}
              />
            ) : (
              <TableCell colSpan={colSpan}>
                {
                  (displayNames.get(row.groupingValue as string) ||
                    row.groupingValue) as string
                }
              </TableCell>
            )}
          </TableRow>
        </TableHead>
        {isSecondToLastGrouping && header}
        {row.subRows.map(renderGroupRows)}
        {isSecondToLastGrouping && footer}
      </React.Fragment>
    );
  }

  const content =
    grouping.length > 1 ? (
      table.getRowModel().rows.map(row => {
        // We want to keep everything in the same table to align columns across groups
        return (
          <React.Fragment key={row.id}>{renderGroupRows(row)}</React.Fragment>
        );
      })
    ) : (
      <>
        {header}
        <TableBody>
          {table.getRowModel().rows.map(row => (
            <CommentZoneContext zone="row" key={row.id} data={{ id: row.id }}>
              <DataRow key={row.id} row={row} grouping={grouping} />
            </CommentZoneContext>
          ))}
        </TableBody>
      </>
    );

  return (
    <CommentZoneContext zone="metric.result.table">
      <Table
        {...tableProps}
        className={classNames(tableProps.className, classes.table)}
      >
        {content}
        {shouldRenderFooter && <TableFooter>{footer}</TableFooter>}
      </Table>
    </CommentZoneContext>
  );
};
