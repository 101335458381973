import React from 'react';

import { Theme, WithStyles, createStyles, withStyles } from '@material-ui/core';

import classNames from 'classnames';

const styles = (theme: Theme) =>
  createStyles({
    root: {
      aspectRatio: '1 / 1',
      position: 'relative',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      width: '100%',
      background: theme.palette.action.hover,
      borderRadius: theme.shape.borderRadius,
      overflow: 'hidden',
      marginBottom: theme.spacing(1),
      [theme.breakpoints.up('md')]: {
        maxWidth: 200,
      },
    },
    image: {
      position: 'absolute',
      height: '100%',
      width: '100%',
      objectFit: 'cover',
    },
  });

export interface RecommendationCardImageProps
  extends WithStyles<typeof styles> {
  className?: string;
  src?: string;
  alt?: string;
}

function RecommendationCardImage({
  src,
  alt,
  className,
  classes,
}: RecommendationCardImageProps) {
  return (
    <div className={classNames(className, classes.root)}>
      {src && <img src={src} className={classes.image} alt={alt} />}
    </div>
  );
}

export default withStyles(styles)(RecommendationCardImage);
