import {
  MetricsV1AggregationType,
  MetricsV1AggregationThresholdDirection as ThresholdDirection,
} from '@spotify-confidence/plugin-graphql';

export const getAggregationTypeLabel = (type: MetricsV1AggregationType) => {
  const prefix = 'AGGREGATION_TYPE_';
  return type.startsWith(prefix)
    ? type.substring(prefix.length).split('_').join(' ')
    : type;
};

const aggregationThresholdDirectionDetails: Record<
  ThresholdDirection,
  { label: string; description: string }
> = {
  [ThresholdDirection.AggregationThresholdDirectionEq]: {
    label: '=',
    description: 'Equal to',
  },
  [ThresholdDirection.AggregationThresholdDirectionGt]: {
    label: '>',
    description: 'Greater than',
  },
  [ThresholdDirection.AggregationThresholdDirectionGte]: {
    label: '≥',
    description: 'Greater than or equal to',
  },
  [ThresholdDirection.AggregationThresholdDirectionLt]: {
    label: '<',
    description: 'Less than',
  },
  [ThresholdDirection.AggregationThresholdDirectionLte]: {
    label: '≤',
    description: 'Less than or equal to',
  },
};

export const getAggregationThresholdDirectionDetails = (
  direction: ThresholdDirection,
) => {
  return (
    aggregationThresholdDirectionDetails[direction] || {
      label: 'Unknown',
      description: direction,
    }
  );
};
