import React from 'react';

import {
  Box,
  BoxProps,
  Divider,
  FormHelperText,
  Typography,
  styled,
} from '@material-ui/core';

import { CodeEditor } from '@spotify-confidence/core-react';
import {
  MetricsV1AggregationThresholdInput,
  MetricsV1AggregationType,
  MetricsV1FilterInput,
} from '@spotify-confidence/plugin-graphql';

import { aggregationUtils } from '../forms';

interface SharedSimplifiedQueryProps extends BoxProps {
  helperText?: string;
  query?: string;
  title?: string;
  description?: string;
  dense?: boolean;
}

const LeftBorderBox = styled(Box)(({ theme }) => ({
  borderLeft: `1px solid ${theme.palette.text.disabled}`,
}));

const SimplifiedQuery = ({
  helperText,
  query,
  title = 'Simplified query',
  description,
  dense,
  ...boxProps
}: SharedSimplifiedQueryProps) => {
  return (
    <Box {...boxProps}>
      <Typography variant={dense ? 'body2' : 'h6'}>{title}</Typography>
      {description && (
        <Typography variant={dense ? 'body2' : 'body1'} color="textSecondary">
          {description}
        </Typography>
      )}
      {!dense && (
        <Box marginY={2}>
          <Divider />
        </Box>
      )}
      <LeftBorderBox marginY={2}>
        <CodeEditor value={query} withBorder={false} readOnly />
      </LeftBorderBox>
      {helperText && <FormHelperText>{helperText}</FormHelperText>}
    </Box>
  );
};

type AverageCodeSnippetProps = {
  entity?: string;
  measurement?: string;
  aggregationMethod?: MetricsV1AggregationType;
  factTable?: string;
  aggregationThreshold?: MetricsV1AggregationThresholdInput;
  replaceMissingWithZero?: boolean;
  filter?: Maybe<MetricsV1FilterInput>;
};

const averageCodeSnippet = ({
  entity = 'entity',
  measurement,
  aggregationMethod = MetricsV1AggregationType.AggregationTypeSum,
  factTable = 'fact_table',
  aggregationThreshold,
  replaceMissingWithZero,
  filter,
}: AverageCodeSnippetProps = {}) => {
  const aggregation = aggregationThreshold
    ? `IF(${aggregationUtils.getAggregationTypeLabel(aggregationMethod)}(${
        measurement || '*'
      }) ${
        aggregationUtils.getAggregationThresholdDirectionDetails(
          aggregationThreshold.direction,
        ).label
      } ${aggregationThreshold.threshold.value}, 1, 0)`
    : `${aggregationUtils.getAggregationTypeLabel(aggregationMethod)}(${
        measurement || '*'
      })`;
  const measure = replaceMissingWithZero ? 'IFNULL(measure, 0)' : 'measure';

  const filterString =
    filter && filter.criteria.length !== 0 ? 'WHERE <FILTER>' : '';
  return `
WITH measurements AS (
  SELECT
    ${entity},
    ${aggregation} as measure
  FROM ${factTable}
  GROUP BY ${entity}
)

SELECT
  AVG(${measure}) as metric
FROM measurements ${filterString}
`.trim();
};

type AverageSimplifiedQueryProps = Omit<SharedSimplifiedQueryProps, 'query'> &
  AverageCodeSnippetProps;

export const AverageSimplifiedQuery = ({
  entity,
  measurement,
  aggregationMethod,
  factTable,
  aggregationThreshold,
  replaceMissingWithZero,
  filter,
  ...props
}: AverageSimplifiedQueryProps) => {
  const queryProps = {
    entity,
    measurement,
    aggregationMethod,
    factTable,
    aggregationThreshold,
    replaceMissingWithZero,
    filter,
  };
  return <SimplifiedQuery {...props} query={averageCodeSnippet(queryProps)} />;
};

type RatioCodeSnippetProps = {
  entity?: string;
  numerator?: string;
  denominator?: string;
  numeratorAggregation?: MetricsV1AggregationType;
  denominatorAggregation?: MetricsV1AggregationType;
  factTable?: string;
  replaceMissingWithZero?: boolean;
  filter?: Maybe<MetricsV1FilterInput>;
};

const ratioCodeSnippet = ({
  entity = 'entity',
  numerator = '*',
  denominator = '*',
  numeratorAggregation = MetricsV1AggregationType.AggregationTypeAvg,
  denominatorAggregation = MetricsV1AggregationType.AggregationTypeAvg,
  factTable = 'fact_table',
  replaceMissingWithZero,
  filter,
}: RatioCodeSnippetProps = {}) => {
  const numeratorWrapper = replaceMissingWithZero
    ? 'IFNULL(numerator, 0)'
    : 'numerator';
  const denominatorWrapper = replaceMissingWithZero
    ? 'IFNULL(denominator, 0)'
    : 'denominator';
  const filterString =
    filter && filter.criteria.length !== 0 ? 'WHERE <FILTER>' : '';

  return `
WITH measurements AS (
  SELECT
      ${entity},
      ${aggregationUtils.getAggregationTypeLabel(
        numeratorAggregation,
      )}(${numerator}) as numerator,
      ${aggregationUtils.getAggregationTypeLabel(
        denominatorAggregation,
      )}(${denominator}) as denominator,
    GROUP BY ${entity}
    FROM ${factTable}
)

SELECT
  SUM(${numeratorWrapper}) / SUM(${denominatorWrapper}) as metric
FROM measurements ${filterString}
`.trim();
};

type RatioSimplifiedQueryProps = Omit<SharedSimplifiedQueryProps, 'query'> &
  RatioCodeSnippetProps;

export const RatioSimplifiedQuery = ({
  entity,
  numerator,
  denominator,
  numeratorAggregation,
  denominatorAggregation,
  factTable,
  replaceMissingWithZero,
  filter,
  ...props
}: RatioSimplifiedQueryProps) => {
  const queryProps = {
    entity,
    numerator,
    denominator,
    numeratorAggregation,
    denominatorAggregation,
    factTable,
    replaceMissingWithZero,
    filter,
  };
  return <SimplifiedQuery {...props} query={ratioCodeSnippet(queryProps)} />;
};
