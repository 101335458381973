import React from 'react';
import { useLocation } from 'react-router-dom';

import _ from 'lodash';

import { storageApiRef, useApi } from '@backstage/core-plugin-api';

import { useStorageKey } from './useStorageKey';

export type RecentlyVisitedItem = {
  route: string;
  title: string;
};

export function useRecentlyVisited() {
  const location = useLocation();
  const storage = useApi(storageApiRef);
  const key = useStorageKey('recentlyVisited');

  const triggerRecentlyVisited = React.useCallback(
    (title: string) => {
      const item = { title, route: location.pathname };
      const recentlyVisited = storage.snapshot<RecentlyVisitedItem[]>(key);
      if (_.isArray(recentlyVisited.value)) {
        const newValue = [
          item,
          ...recentlyVisited.value.filter(v => v.route !== item.route),
        ].slice(0, 9);
        storage.set(key, newValue);
      } else {
        storage.set(key, [item]);
      }
    },
    [key, location.pathname],
  );

  return {
    recentlyVisitedItems: storage.snapshot<RecentlyVisitedItem[]>(key).value,
    triggerRecentlyVisited,
  };
}
