import React from 'react';

import {
  Box,
  Button,
  Table,
  TableBody,
  TableCell,
  TableRow,
  alpha,
  styled,
} from '@material-ui/core';
import AddIcon from '@material-ui/icons/Add';
import SignalWifi0BarIcon from '@material-ui/icons/SignalWifi0Bar';

import {
  ACTION_BAR_HEIGHT,
  ActionBar,
  ConfidenceWebsiteLink,
  DateUtils,
  FilterBar,
  FilterListEmptyState,
  FilterListProvider,
  Highlight,
  PageLayout,
  ResourceTableRow,
  StickyTableContainer,
  StickyTableHead,
  VerboseTooltip,
  useDialog,
  useListFilter,
  useMeasureDimensions,
} from '@spotify-confidence/core-react';
import { useCheckResourcePermissions } from '@spotify-confidence/plugin-permissions-react';

import { Link, WarningPanel } from '@backstage/core-components';
import { useRouteRef } from '@backstage/core-plugin-api';

import { eventRouteRef } from '../../../routes';
import { DraftEvent, useDraftEvents } from '../../hooks';
import { useEvents } from '../../hooks/useEvents';
import { CreateEventFromDraftDialog } from '../CreateEventFromDraftDialog';
import { EventStatusIndicator } from '../EventStatusIndicator';
import { CreateEventDialog } from './CreateEventDialog';

const DraftEventTableRow = styled(TableRow)(({ theme }) => ({
  ['& td']: {
    background: alpha(theme.palette.warning.light, 0.2),
  },
}));

type EventListProps = {
  pageSize?: number;
  createEvent?: () => void;
  topOffset?: number;
};

const EventList = ({
  pageSize = 25,
  createEvent,
  topOffset = ACTION_BAR_HEIGHT,
}: EventListProps) => {
  const eventRoute = useRouteRef(eventRouteRef);
  const { openDialog, closeDialog } = useDialog();

  const { searchQuery } = useListFilter();

  const {
    events,
    loading: loadingExisting,
    error,
    InfiniteScroll,
  } = useEvents({ pageSize });

  const {
    draftEvents,
    loading: loadingDrafts,
    createEvent: createEventFromDraft,
    error: draftErrors,
  } = useDraftEvents();

  const filteredDraftEvents = draftEvents.filter(draftEvent =>
    draftEvent.id.toLowerCase().includes(searchQuery.toLowerCase()),
  );

  if (error) {
    return (
      <WarningPanel title="Could not load data" message={error?.message} />
    );
  }

  const eventsFormatted = events.map(event => ({
    ...event,
    displayName: event.name.split('/')[1],
  }));

  const loading = loadingDrafts || loadingExisting;

  if (
    eventsFormatted.length === 0 &&
    filteredDraftEvents.length === 0 &&
    !loading
  ) {
    return (
      <FilterListEmptyState
        type="event"
        description={
          <>
            Events can be tracked to collect data for your metrics.{' '}
            <ConfidenceWebsiteLink
              route="/docs/api/events/how-it-works"
              underline="always"
            >
              Learn more about events.
            </ConfidenceWebsiteLink>
          </>
        }
        onCreate={createEvent}
      />
    );
  }

  const handleAccept = (draftEvent: DraftEvent) => {
    openDialog({
      content: (
        <CreateEventFromDraftDialog
          draftEvent={draftEvent}
          onCancel={closeDialog}
          onCreate={async (owner, schema) => {
            await createEventFromDraft({
              eventId: draftEvent.id,
              owner,
              schema,
            });
            closeDialog();
          }}
        />
      ),
      dialogProps: {
        fullScreen: true,
      },
    });
  };

  return (
    <StickyTableContainer loading={loading} error={error ?? draftErrors}>
      <Table>
        <StickyTableHead topOffset={topOffset}>
          <TableRow>
            <TableCell>Name</TableCell>
            <TableCell>Status</TableCell>
            <TableCell>Updated</TableCell>
          </TableRow>
        </StickyTableHead>
        <TableBody>
          {filteredDraftEvents.map(draftEvent => (
            <DraftEventTableRow key={draftEvent.id}>
              <TableCell>
                <Box display="inline-flex" alignItems="center" gridGap={8}>
                  <Highlight highlight={searchQuery}>{draftEvent.id}</Highlight>
                </Box>
              </TableCell>
              <TableCell>
                <VerboseTooltip title="Sent but not stored" verbose>
                  <SignalWifi0BarIcon color="error" />
                </VerboseTooltip>
              </TableCell>
              <TableCell>
                <Button
                  size="small"
                  variant="outlined"
                  onClick={() => handleAccept(draftEvent)}
                >
                  Create
                </Button>
              </TableCell>
            </DraftEventTableRow>
          ))}
          {eventsFormatted.map(event => (
            <ResourceTableRow
              key={event.name}
              routeRef={eventRouteRef}
              name={event.name}
            >
              <TableCell>
                <Link to={eventRoute({ id: event.displayName })}>
                  <Highlight highlight={searchQuery}>
                    {event.displayName}
                  </Highlight>
                </Link>
              </TableCell>
              <TableCell>
                <EventStatusIndicator
                  publishTime={event.usageMetadata?.lastPublishTime}
                  validationFailedTime={
                    event.usageMetadata?.lastValidationFailedTime
                  }
                  verbose
                />
              </TableCell>
              <TableCell>
                {DateUtils.xAgo(new Date(event.updateTime))}
              </TableCell>
            </ResourceTableRow>
          ))}
        </TableBody>
      </Table>
      <InfiniteScroll />
    </StickyTableContainer>
  );
};

export const EventIndexPage = () => {
  const [ref, { height }] = useMeasureDimensions<HTMLDivElement>();
  const { allowed } = useCheckResourcePermissions({
    name: 'account',
    can: 'create_event_definition',
  });

  const { openDialog } = useDialog();

  const createEvent = React.useCallback(() => {
    openDialog({
      content: <CreateEventDialog />,
    });
  }, [openDialog]);

  return (
    <FilterListProvider searchField="name" storageKey="events">
      <PageLayout title="Events" scrollButton>
        <ActionBar ref={ref}>
          <FilterBar />
          <Button
            variant="contained"
            color="primary"
            size="small"
            onClick={allowed ? createEvent : undefined}
            data-testid="create-event-button"
            startIcon={<AddIcon />}
            disabled={!allowed}
          >
            Create
          </Button>
        </ActionBar>
        <EventList createEvent={createEvent} topOffset={height} />
      </PageLayout>
    </FilterListProvider>
  );
};
