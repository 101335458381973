import React, { PropsWithChildren, createContext, useContext } from 'react';

const commentZoneContext = createContext<string>('');

type ZoneData = { [key: string]: string };

/**
 * CommentZoneProvider is a context provider that sets the current zone
 * for comments.  This is useful for creating a hierarchy of zones.
 */
export const CommentZoneProvider = ({
  zone,
  children,
}: PropsWithChildren<{ zone: string }>) => {
  return (
    <commentZoneContext.Provider value={zone}>
      {children}
    </commentZoneContext.Provider>
  );
};

/**
 * Props for CommentZoneContext.
 */
export interface CommentZoneContextProps
  extends PropsWithChildren<{
    // Zone ID
    zone: string;

    // Auxiliary data for the zone
    data?: ZoneData;
  }> {}

/**
 * CommentZoneContext is a context provider that sets the current zone
 * for comments.  This is useful for creating a hierarchy of zones.
 *
 * @param props See @{link CommentZoneContextProps}
 */
export const CommentZoneContext = (props: CommentZoneContextProps) => {
  const { zone, data, children } = props;
  const context = useContext(commentZoneContext);
  const qualifiedZone = makeZone(context, zone, data);

  return (
    <CommentZoneProvider zone={qualifiedZone}>{children}</CommentZoneProvider>
  );
};

/**
 * Hook to get the current comment zone.
 *
 * @returns the current comment zone
 */
export function useCommentZone(): string {
  return useContext(commentZoneContext);
}

// Helper function to create a zone ID
// @internal
function makeZone(parent: string, id: string, data?: ZoneData) {
  const fullId = data
    ? `${id}[${Object.entries(data)
        .map(([k, v]) => `${k}:${v}`)
        .join(',')}]`
    : id;
  const concatId = parent.length > 0 ? `${parent}/${fullId}` : fullId;
  return concatId;
}

export function useZone(zone: string, data?: ZoneData) {
  const parent = useCommentZone();
  return makeZone(parent, zone, data);
}
