import React from 'react';

import { Button, CircularProgress, TextField } from '@material-ui/core';
import { Alert } from '@material-ui/lab';

import { FormFieldsBox } from '@spotify-confidence/core-react';
import {
  ConnectorsV1FlagAppliedConnectionDatabricksFlagAppliedConnectionConfigInput as DatabricksInput,
  useValidateDatabricksFlagAppliedConnectionConfigMutation,
} from '@spotify-confidence/plugin-graphql';

import { DatabricksConfigForm } from './DatabricksConfigForm';
import { isDatabricksFlagAppliedInputValid } from './helpers';

type DatabricksFlagAppliedConnectionFormProps = {
  value: DatabricksInput;
  onChange: (conf: DatabricksInput) => void;
  disabled?: boolean;
};

export const DatabricksFlagAppliedConnectionForm = ({
  value,
  onChange,
  disabled,
}: DatabricksFlagAppliedConnectionFormProps) => {
  const [validate, validation] =
    useValidateDatabricksFlagAppliedConnectionConfigMutation({
      variables: {
        databricks: value,
      },
    });

  const canValidate = isDatabricksFlagAppliedInputValid(value);

  const handleValidate = async () => {
    try {
      await validate();
    } catch (e) {
      //   We use the error from the validation object
    }
  };

  return (
    <FormFieldsBox>
      <TextField
        disabled={disabled}
        label="Table"
        variant="outlined"
        value={value.table}
        required
        fullWidth
        helperText="The name of the Databricks table to use for writing assignment data. The connector creates the table automatically when the first import is done."
        onChange={e => onChange({ ...value, table: e.target.value })}
      />
      <DatabricksConfigForm
        value={value.databricksConfig}
        onChange={newValue =>
          onChange({
            ...value,
            databricksConfig: newValue,
          })
        }
        disabled={disabled}
        schemaHelperText="The name of the Databricks Schema/Catalogue where the connector creates the preceding table."
      />
      <div>
        <Button
          size="small"
          variant="outlined"
          disabled={!canValidate || validation.loading}
          onClick={handleValidate}
          endIcon={validation.loading && <CircularProgress size="1em" />}
        >
          Validate
        </Button>
      </div>
      {validation.data && (
        <Alert severity="success">Validation successful!</Alert>
      )}
      {validation.error && (
        <Alert severity="error">{validation.error.message}</Alert>
      )}
    </FormFieldsBox>
  );
};
