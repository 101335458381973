import React from 'react';

import {
  Collapse,
  FormControlLabel,
  InputLabel,
  Radio,
  RadioGroup,
} from '@material-ui/core';

import { FeatureFlagged, FormFieldsBox } from '@spotify-confidence/core-react';
import {
  MetricsV1AggregationType,
  MetricsV1MetricInput,
} from '@spotify-confidence/plugin-graphql';

import { defaultConversionSpec } from '../../metricTypes';
import { MeasurementSelect } from './MeasurementSelect';
import { MetricFilters } from './MetricFilters';
import { MetricInputsForm } from './MetricInputsForm';

type ConversionMetricInputsFormProps = {
  value: MetricsV1MetricInput;
  onChange: (newInput: MetricsV1MetricInput) => void;
};

type ConversionType = 'row' | 'measurement';

const defaultMeasurementConversionSpec = {
  ...defaultConversionSpec,
  averageMetricSpec: {
    ...defaultConversionSpec.averageMetricSpec,
    measurement: {
      name: '',
    },
    aggregation: {
      ...defaultConversionSpec.averageMetricSpec.aggregation,
      type: MetricsV1AggregationType.AggregationTypeSum,
    },
  },
};

export const ConversionMetricInputsForm = ({
  value,
  onChange,
}: ConversionMetricInputsFormProps) => {
  const [type, setType] = React.useState<ConversionType>(
    value.typeSpec?.averageMetricSpec?.measurement?.name
      ? 'measurement'
      : 'row',
  );

  React.useEffect(() => {
    onChange({
      ...value,
      nullHandling: {
        replaceEntityNullWithZero: true,
      },
    });
  }, []);

  const handleSelectType = (e: React.ChangeEvent<HTMLInputElement>) => {
    const newType = e.target.value as ConversionType;
    if (newType !== type) {
      setType(newType);
      if (newType === 'row') {
        onChange({
          ...value,
          typeSpec: defaultConversionSpec,
        });
      }
      if (newType === 'measurement') {
        onChange({
          ...value,
          typeSpec: defaultMeasurementConversionSpec,
        });
      }
    }
  };

  const handleMeasurementChange = (measurementName: string) => {
    onChange({
      ...value,
      typeSpec: {
        ...defaultConversionSpec,
        averageMetricSpec: {
          ...defaultConversionSpec.averageMetricSpec,
          aggregation: {
            ...defaultConversionSpec.averageMetricSpec.aggregation,
            type: MetricsV1AggregationType.AggregationTypeSum,
          },
          measurement: {
            name: measurementName || '',
          },
        },
      },
    });
  };

  return (
    <FormFieldsBox>
      <MetricInputsForm
        value={value}
        onChange={onChange}
        defaultSpec={
          type === 'measurement'
            ? defaultMeasurementConversionSpec
            : defaultConversionSpec
        }
      />
      <FeatureFlagged with="metric-filters">
        <MetricFilters
          factTable={value.factTable}
          filter={value.filter}
          onChange={newFilter => {
            onChange({
              ...value,
              filter: newFilter,
            });
          }}
        />
      </FeatureFlagged>

      <RadioGroup
        aria-label="type"
        name="conversion-type"
        value={type}
        onChange={handleSelectType}
      >
        <InputLabel htmlFor="conversion-type">
          How to evaluate conversions
        </InputLabel>
        <FormControlLabel
          name="conversion-type-row"
          control={<Radio value="row" color="primary" />}
          label="Each table row represents a successful conversion"
        />
        <FormControlLabel
          name="conversion-type-measurement"
          control={<Radio value="measurement" color="primary" />}
          label="A measurement determines a successful conversion"
        />
      </RadioGroup>

      <Collapse in={type === 'measurement'} unmountOnExit>
        {value.typeSpec?.averageMetricSpec && (
          <MeasurementSelect
            label="Conversion measurement"
            name="measurement"
            emptyLabel=""
            required
            value={value.typeSpec.averageMetricSpec.measurement?.name || ''}
            factTable={value.factTable}
            onChange={handleMeasurementChange}
            disabled={!value.factTable}
            helperText="A conversion is considered successful if there is at least one true or non-zero value in this measurement for an entity."
          />
        )}
      </Collapse>
    </FormFieldsBox>
  );
};
