import React from 'react';

import {
  ListItemSecondaryAction,
  ListItemText,
  Typography,
  styled,
} from '@material-ui/core';

import {
  AutocompleteEmptyState,
  ConfidenceAutocomplete,
} from '@spotify-confidence/core-react';
import {
  FactTableOptionFragment,
  MetricsV1TableState,
  useListFactTablesLazyQuery,
} from '@spotify-confidence/plugin-graphql';

import { OverflowTooltip } from '@backstage/core-components';
import { useRouteRef } from '@backstage/core-plugin-api';

import { TableState } from '../../../../components/TableState';
import { factTablesCreateRouteRef } from '../../../../routes';

type FactTableSelectProps = {
  value?: string;
  onChange: (newValue: string) => void;
  disabled?: boolean;
  helperText?: React.ReactNode;
  entity?: string;
};

const OptionItem = styled('div')({ position: 'relative', width: '100%' });

export function FactTableSelect({
  value = '',
  onChange,
  disabled,
  helperText,
  entity,
}: FactTableSelectProps) {
  const factTableCreateRoute = useRouteRef(factTablesCreateRouteRef);

  const handleChange = (_e: React.ChangeEvent<{}>, newValue: string | null) => {
    onChange(newValue || '');
  };

  return (
    <ConfidenceAutocomplete<FactTableOptionFragment>
      query={useListFactTablesLazyQuery}
      disabled={disabled}
      autoSelectSingle
      value={value}
      onChange={handleChange}
      data-testid="fact-table"
      label="Fact table"
      queryOptions={{
        variables: {
          filter: entity ? `entities.entityName:"${entity}"` : undefined,
        },
        skip: !entity,
      }}
      required
      helperText={helperText}
      getOptionDisabled={table =>
        table.state !== MetricsV1TableState.TableStateActive
      }
      renderOption={(table, { inputValue, ...state }) => (
        <OptionItem {...state}>
          <ListItemText
            primary={table.displayName}
            secondary={
              <OverflowTooltip
                text={(table.measures ?? [])
                  .map(m => m.column.name)
                  .join(' ∘ ')}
              />
            }
            secondaryTypographyProps={{
              component: 'div',
            }}
          />
          <ListItemSecondaryAction>
            {table.state !== MetricsV1TableState.TableStateActive && (
              <Typography color="textSecondary" variant="body2" component="div">
                <TableState status={table.state} error={table.error} />
              </Typography>
            )}
          </ListItemSecondaryAction>
        </OptionItem>
      )}
    >
      <AutocompleteEmptyState
        title="No fact tables"
        description={helperText}
        actionLabel="Create fact table"
        actionLink={factTableCreateRoute()}
      />
    </ConfidenceAutocomplete>
  );
}
