/**
 * Operators: Nominal Operators
 */

export enum SingleNominalOperator {
  IS = 'IS',
  IS_NOT = 'IS_NOT',
}

export enum MultipleNominalOperator {
  IS_ANY_OF = 'IS_ANY_OF',
  IS_NONE_OF = 'IS_NONE_OF',
}

export type NominalOperator = SingleNominalOperator | MultipleNominalOperator;

/**
 * Operators: Range Operators
 */

export enum StartRangeOperator {
  IS_GREATER_THAN = 'IS_GREATER_THAN', // exclusive >
  IS_AT_LEAST = 'IS_AT_LEAST', // inclusive ≥
}

export enum EndRangeOperator {
  IS_LESS_THAN = 'IS_LESS_THAN', // exclusive <
  IS_AT_MOST = 'IS_AT_MOST', // inclusive ≤
}

export enum FullRangeOperator {
  IS_GREATER_THAN_AND_IS_LESS_THAN = 'IS_GREATER_THAN_AND_IS_LESS_THAN', // exclusive > AND exclusive <
  IS_GREATER_THAN_AND_IS_AT_MOST = 'IS_GREATER_THAN_AND_IS_AT_MOST', // exclusive > AND inclusive ≤
  IS_AT_LEAST_AND_IS_LESS_THAN = 'IS_AT_LEAST_AND_IS_LESS_THAN', // inclusive ≥ AND exclusive <
  IS_AT_LEAST_AND_IS_AT_MOST = 'IS_AT_LEAST_AND_IS_AT_MOST', // inclusive ≥ AND inclusive ≤
  NOT_AT_LEAST_AND_IS_AT_MOST = 'NOT_AT_LEAST_AND_IS_AT_MOST',
}

export type RangeOperator =
  | StartRangeOperator
  | EndRangeOperator
  | FullRangeOperator;

/**
 * Operators: All
 */

export enum InvalidOperator {
  UNKNOWN = 'UNKNOWN',
}

export type Operator = NominalOperator | RangeOperator | InvalidOperator;

/**
 * Operators: Type Guards
 */

// nominal operators

export const isSingleNominalOperator = (
  operator?: Operator,
): operator is SingleNominalOperator =>
  Object.values(SingleNominalOperator).includes(
    operator as SingleNominalOperator,
  );

export const isMultipleNominalOperator = (
  operator?: Operator,
): operator is MultipleNominalOperator =>
  Object.values(MultipleNominalOperator).includes(
    operator as MultipleNominalOperator,
  );

export const isNominalOperator = (
  operator?: Operator,
): operator is RangeOperator =>
  isSingleNominalOperator(operator) || isMultipleNominalOperator(operator);

// range operators

export const isStartRangeOperator = (
  operator?: Operator,
): operator is StartRangeOperator =>
  Object.values(StartRangeOperator).includes(operator as StartRangeOperator);

export const isEndRangeOperator = (
  operator?: Operator,
): operator is EndRangeOperator =>
  Object.values(EndRangeOperator).includes(operator as EndRangeOperator);

export const isFullRangeOperator = (
  operator?: Operator,
): operator is FullRangeOperator =>
  Object.values(FullRangeOperator).includes(operator as FullRangeOperator);

export const isRangeOperator = (
  operator?: Operator,
): operator is RangeOperator =>
  isStartRangeOperator(operator) ||
  isEndRangeOperator(operator) ||
  isFullRangeOperator(operator);

// all operators

export const isOperator = (value: unknown): value is Operator =>
  isNominalOperator(value as Operator) || isRangeOperator(value as Operator);

/**
 * Values
 */

export type SingleValue<T extends any = unknown> = T;
export type MultipleValue<T extends any = unknown> = T[];
export type RangeValue<T extends any = unknown> = {
  start?: T;
  end?: T;
};

export type QueryBuilderValue<T extends any = unknown> =
  | SingleValue<T>
  | MultipleValue<T>
  | RangeValue<T>;

export const isMultipleValue = <T extends any>(
  value: QueryBuilderValue<T>,
): value is MultipleValue<T> => Array.isArray(value);

export const isRangeValue = <T extends any>(
  value: QueryBuilderValue<T>,
): value is RangeValue<T> =>
  value === Object(value) &&
  ((value as RangeValue).hasOwnProperty('start') ||
    (value as RangeValue).hasOwnProperty('end'));

export const isSingleValue = <T extends any>(
  value: QueryBuilderValue<T>,
): value is SingleValue<T> => !isRangeValue(value) && !isMultipleValue(value);
/**
 * Query Rules & Props
 */

export type QueryRuleDefinition<
  V extends QueryBuilderValue = QueryBuilderValue,
  O extends Operator = Operator,
> = {
  id: string;
  operators: O[];
  valueTransformer?: (s: string) => string;
  renderEditComponent: (
    props: RenderEditComponentProps<V, O>,
  ) => React.ReactNode;
  displayName?: string;
  description?: string;
  renderValue?: RenderEditComponentProps<V, O>['renderValue'];
  defaultValue?: V;
  customOperatorLabels?: PartialRecord<Operator, string>;
};

export type QueryRuleValue<
  V extends QueryBuilderValue = QueryBuilderValue,
  O extends Operator = Operator,
> = {
  id: QueryRuleDefinition<V, O>['id'];
  operator: O;
  value?: V;
};

export type RenderEditComponentProps<
  V extends QueryBuilderValue = QueryBuilderValue,
  O extends Operator = Operator,
> = {
  value?: V;
  onChange: (value?: V) => void;
  operator?: O;
  renderValue?: (value: V) => React.ReactNode;
};

export type PartialRecord<K extends keyof any, T> = {
  [P in K]?: T;
};

export type CustomAddOption = Omit<
  QueryRuleDefinition,
  'renderEditComponent' | 'renderValue'
>;

export type AddRuleComponentProps = {
  allowDuplicateRules?: boolean;
  rules: QueryRuleDefinition<any, Operator>[] | CustomAddOption[];
  value: QueryRuleValue<any, Operator>[]; // Which rules have been added?
  disabledRuleIds?: string[]; // Which rules cannot be added?
  addRuleButton: null | HTMLElement;
  onClose: Function;
  onAdd: (rule: QueryRuleDefinition<any, Operator> | CustomAddOption) => void;
};
