import React from 'react';
import { useAsyncFn } from 'react-use';

import { Chip, Paper } from '@material-ui/core';
import { Alert } from '@material-ui/lab';

import {
  FormFieldsBox,
  WizardStepper,
  useDialog,
} from '@spotify-confidence/core-react';
import { EventSchemaEntryFragment } from '@spotify-confidence/plugin-graphql';

import { SchemaEditor } from '../../../../schema/components/SchemaEditor';
import { DraftSchemaEntries } from '../../../../schema/components/SchemaEditor/DraftSchemaEntries';
import { SchemaProvider } from '../../../../schema/components/SchemaEditor/SchemaContext';
import {
  KEY_JOINER,
  getHasInvalidChild,
  getPermanentKeys,
} from '../../../../schema/domain/schema.helpers';
import { useEvent } from '../../../hooks/useEvent';

type SchemaDialogProps = {
  eventName: string;
  draftSchema?: EventSchemaEntryFragment[];
  updateSchema: ReturnType<typeof useEvent>['updateSchema'];
};

export const SchemaDialog = ({
  draftSchema,
  eventName,
  updateSchema,
}: SchemaDialogProps) => {
  const { closeDialog } = useDialog();
  const { event } = useEvent({
    name: eventName,
  });
  const initialSchema = event?.schema ?? [];

  const [schema, setSchema] =
    React.useState<EventSchemaEntryFragment[]>(initialSchema);

  React.useEffect(() => {
    setSchema(initialSchema);
  }, [initialSchema]);

  const [{ loading: saving, error }, save] = useAsyncFn(async () => {
    await updateSchema({ schema });
  }, [schema]);

  const invalidChildren = getHasInvalidChild(schema);

  const handleSubmit = async () => {
    await save();
    closeDialog();
  };

  const permanentKeys = getPermanentKeys(event?.schema ?? []);
  const getEntryLabels = (_identity: string, path: string[]) => {
    if (!permanentKeys.includes(path.join(KEY_JOINER))) {
      return <Chip size="small" label="New" color="secondary" />;
    }
    return null;
  };

  return (
    <WizardStepper
      onClose={closeDialog}
      submitLabel="Save"
      disableNext={invalidChildren}
      onSubmit={handleSubmit}
      loading={saving}
      error={error}
      cancelLabel="Close"
      width="lg"
      steps={[
        {
          name: 'Edit event schema',
          optional: true,
          content: (
            <FormFieldsBox>
              {error && <Alert severity="error">{error.message}</Alert>}
              <SchemaProvider schema={schema} onChange={setSchema}>
                {draftSchema && (
                  <DraftSchemaEntries draftSchema={draftSchema} />
                )}
                <Paper variant="outlined">
                  <SchemaEditor
                    permanentKeys={permanentKeys}
                    getEntryLabels={getEntryLabels}
                  />
                </Paper>
              </SchemaProvider>
            </FormFieldsBox>
          ),
        },
      ]}
    />
  );
};
