import {
  createPlugin,
  createRoutableExtension,
} from '@backstage/core-plugin-api';

import {
  clientRouteRef,
  clientSchemaRouteRef,
  clientsRouteRef,
  flagRouteRef,
  flagsRouteRef,
  rootRouteRef,
  workflowInstanceRouteRef,
  workflowRouteRef,
} from './routes';

export const flagsPlugin = createPlugin({
  id: 'flags',
  routes: {
    root: rootRouteRef,
    flag: flagRouteRef,
    flags: flagsRouteRef,
  },
  externalRoutes: {
    workflow: workflowRouteRef,
    workflowInstance: workflowInstanceRouteRef,
    clients: clientsRouteRef,
    clientSchema: clientSchemaRouteRef,
    client: clientRouteRef,
  },
  featureFlags: [],
});

export const ConfidenceFlagsPage = flagsPlugin.provide(
  createRoutableExtension({
    name: 'ConfidenceFlagsPage',
    component: () =>
      import(
        /* webpackChunkName: "confidence-flags", webpackPrefetch: 10 */ './Router'
      ).then(m => m.Router),
    mountPoint: rootRouteRef,
  }),
);
