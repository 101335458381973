import React from 'react';
import { TransitionGroup } from 'react-transition-group';

import {
  Collapse,
  IconButton,
  List,
  ListItem,
  ListItemText,
  Typography,
} from '@material-ui/core';
import Edit from '@material-ui/icons/Edit';

import {
  SidebarSection,
  extractLastNameComponent,
  useAlert,
  useDialog,
} from '@spotify-confidence/core-react';
import {
  SurfaceFragment,
  getTypeOrNull,
  isError,
  isType,
  useUpdateWorkflowInstanceSurfacesMutation,
  useWorkflowInstanceSurfacesQuery,
} from '@spotify-confidence/plugin-graphql';
import {
  surfaceRouteRef,
  useSurfaces,
} from '@spotify-confidence/plugin-surfaces';
import _ from 'lodash';

import { Link } from '@backstage/core-components';
import { useRouteRef } from '@backstage/core-plugin-api';

import { SurfacesDialog } from './SurfacesDialog';

/**
 * Props for {@link SurfacesSidebarSection}.
 */
export interface SurfacesSidebarSectionProps {
  workflowInstanceName: string;
  disabled?: boolean;
}

/**
 * Sidebar section for managing surfaces for a workflow instance.
 *
 * @param props See {@link SurfacesSidebarSectionProps}.
 * @public
 */
export const SurfacesSidebarSection = (props: SurfacesSidebarSectionProps) => {
  const { openDialog, closeDialog } = useDialog();
  const { workflowInstanceName: name, disabled } = props;
  const alert = useAlert();
  const surfaceRoute = useRouteRef(surfaceRouteRef);

  const surfaceHrefFromName = (surfaceName: string) => {
    return surfaceRoute({ id: extractLastNameComponent(surfaceName)! });
  };

  const { data } = useWorkflowInstanceSurfacesQuery({
    variables: {
      name,
    },
  });
  const workflowInstance = getTypeOrNull(
    data?.workflowInstance,
    'WorkflowV1WorkflowInstance',
  );
  const workflowSurfaces = workflowInstance?.surfaces ?? [];
  const [updateSurfaces] = useUpdateWorkflowInstanceSurfacesMutation();

  const handleEdit = () => {
    openDialog({
      content: (
        <SurfacesDialog
          surfaces={
            (workflowSurfaces.filter(s => !isError(s)) ??
              []) as SurfaceFragment[]
          }
          onClose={closeDialog}
          onSubmit={async surfaces => {
            const response = await updateSurfaces({
              variables: {
                input: {
                  name,
                  surfaces: surfaces.map(s => s.name),
                },
              },
              update: cache => {
                // TODO: can we restrict this to only affect surfaces that changed?
                cache.evict({ fieldName: 'workflowInstances' });
              },
            });
            if (
              isType(
                response.data?.updateWorkflowInstance,
                'WorkflowV1WorkflowInstance',
              )
            ) {
              closeDialog();
            } else {
              alert.post({
                severity: 'error',
                message:
                  response?.data?.updateWorkflowInstance?.message ??
                  'Something went wrong',
              });
            }
          }}
        />
      ),
    });
  };

  // Load surfaces to understand if we should show the surfaces section
  // or not. Non-optional from a data-loading perspective, but better
  // than showing an empty surfaces-section when there's no surfaces at
  // all in the system.
  const { surfaces: existingSurfaces } = useSurfaces();
  if (existingSurfaces.length === 0) {
    // If we don't have any surfaces, we don't show the section
    // right now.
    return null;
  }

  return (
    <SidebarSection
      title="Surfaces"
      action={
        <IconButton
          size="small"
          disabled={disabled}
          onClick={handleEdit}
          title="Edit"
        >
          <Edit fontSize="small" />
        </IconButton>
      }
    >
      {workflowSurfaces.length === 0 && (
        <Typography variant="body1" color="textSecondary">
          No surfaces selected
        </Typography>
      )}

      {/* -10 comes from margin: 6 + padding: 4 on the list item */}
      <List disablePadding style={{ marginTop: -10 }}>
        <TransitionGroup>
          {workflowSurfaces.map((surface, i) => (
            <Collapse key={`surface-${i}`}>
              <ListItem
                disableGutters
                divider={i < workflowSurfaces.length! - 1}
              >
                <ListItemText
                  primary={
                    isType(surface, 'WorkflowV1Surface') ? (
                      <Link to={surfaceHrefFromName(surface.name)}>
                        {surface.displayName}
                      </Link>
                    ) : (
                      <span>{surface.message ?? 'Unknown'}</span>
                    )
                  }
                  secondary={
                    getTypeOrNull(surface, 'WorkflowV1Surface')?.description ??
                    '(No description)'
                  }
                />
              </ListItem>
            </Collapse>
          ))}
        </TransitionGroup>
      </List>
    </SidebarSection>
  );
};
