import React from 'react';

import {
  DateUtils,
  MediumScreenCell,
  NumberCell,
} from '@spotify-confidence/core-react';
import {
  getTypeOrNull,
  useCurrentUser,
} from '@spotify-confidence/plugin-graphql';
import { WorkflowInstanceExposureCell } from '@spotify-confidence/plugin-metrics';
import {
  WorkflowInstanceListItem,
  WorkflowInstanceNameCell,
  WorkflowInstanceStateCell,
  WorkflowInstanceTableRow,
  moduleHelpers,
} from '@spotify-confidence/plugin-workflows';

import { RolloutData } from '../RolloutWorkflowInstancePage/RolloutModule';

export const RolloutTableRow = ({
  workflowInstance,
}: {
  workflowInstance: WorkflowInstanceListItem;
}) => {
  const rolloutData = moduleHelpers.getModuleData<RolloutData>(
    workflowInstance.moduleData,
    'rollout',
  );

  const currentUser = useCurrentUser();

  const comments =
    getTypeOrNull(
      workflowInstance.comments,
      'CollaborationV1ListCommentsResponse',
    )?.comments ?? [];

  const assignCount =
    comments
      ?.filter(c => !c.resolved)
      ?.filter(
        c =>
          getTypeOrNull(c.assignee, 'IamV1Identity')?.name ===
          currentUser?.identity?.name,
      )?.length ?? 0;

  return (
    <WorkflowInstanceTableRow workflowInstance={workflowInstance}>
      <WorkflowInstanceNameCell
        workflowInstance={workflowInstance}
        badgeCount={assignCount}
      />
      <WorkflowInstanceStateCell workflowInstance={workflowInstance} />
      <NumberCell
        mediumScreenSize
        value={Number(rolloutData?.reach ?? 0) * 100}
        suffix="%"
      />
      <WorkflowInstanceExposureCell workflowInstance={workflowInstance} />
      <MediumScreenCell>
        {DateUtils.xAgo(new Date(workflowInstance?.updateTime))}
      </MediumScreenCell>
    </WorkflowInstanceTableRow>
  );
};
