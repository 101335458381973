import React from 'react';
import { Link, LinkProps } from 'react-router-dom';

import { Tooltip, styled } from '@material-ui/core';

import { Tab } from '@backstage/core-components';

type HeaderTabParameters = Tab & {
  disabled?: boolean;
  tooltip?: string;
  to?: LinkProps['to'];
};

const ActiveTooltip = styled(Tooltip)({
  pointerEvents: 'auto',
});

export const createHeaderTab = ({
  id,
  label,
  disabled,
  to,
  tooltip = '',
  tabProps,
}: HeaderTabParameters): Tab => {
  return {
    id,
    label: (
      <ActiveTooltip title={tooltip} arrow>
        <span>{label}</span>
      </ActiveTooltip>
    ) as unknown as Tab['label'], // Should be string according to Backstage types, but MUI allows ReactNode
    tabProps: {
      component: disabled ? 'span' : Link,
      to,
      disabled,
      ...tabProps,
    },
  };
};
