import React from 'react';

import {
  Box,
  Card,
  CardContent,
  Divider,
  Typography,
  useTheme,
} from '@material-ui/core';

import {
  ColorUtils,
  DateUtils,
  InlineVariant,
  Numeral,
} from '@spotify-confidence/core-react';
import { format } from 'date-fns';
import {
  Bar,
  BarChart,
  CartesianGrid,
  Legend,
  Rectangle,
  ResponsiveContainer,
  Tooltip,
  TooltipProps,
  XAxis,
  YAxis,
} from 'recharts';

import { useUsageReport } from '../../hooks/useUsageReport';
import { currencyFormatter } from './Reports';

const CustomTooltip = ({
  active,
  payload,
  label,
}: TooltipProps<number, string>) => {
  if (active && payload && payload.length) {
    return (
      <Card>
        <CardContent>
          <Typography variant="subtitle2" color="textSecondary" gutterBottom>
            {DateUtils.toDateString(label, 'yyyy-MM-dd')}
          </Typography>
          <Divider />
          <Box marginTop={2}>
            {payload.map(variant => (
              <Box
                key={variant.name}
                display="flex"
                justifyContent="space-between"
                gridGap={8}
              >
                <InlineVariant color={variant.color} label={variant.name} />
                <Numeral value={variant.value} />
              </Box>
            ))}
          </Box>
        </CardContent>
      </Card>
    );
  }

  return null;
};

export const Graph = ({
  data,
  activeService,
  showCost,
}: {
  data: ReturnType<typeof useUsageReport>;
  activeService?: string;
  showCost?: boolean;
}) => {
  const theme = useTheme();
  return (
    <ResponsiveContainer width="99%" height={300}>
      <BarChart width={500} height={300} data={data.byDate}>
        <CartesianGrid stroke={theme.palette.divider} vertical={false} />
        <XAxis
          dataKey="period"
          stroke={theme.palette.text.primary}
          tickFormatter={date => format(new Date(date), 'MMM d')}
        />
        <YAxis
          stroke={theme.palette.text.primary}
          tickFormatter={t => (showCost ? `${t}$` : t)}
        />
        <Tooltip
          cursor={{ fill: 'transparent' }}
          formatter={value => currencyFormatter.format(Number(value))}
          labelFormatter={v => format(new Date(v), 'yyyy-MM-dd')}
          content={CustomTooltip}
        />

        <Legend />
        {data.skus.map((p, i) => (
          <Bar
            dataKey={p}
            key={p}
            stackId="a"
            fill={ColorUtils.colorList[i % ColorUtils.colorList.length]}
            shape={(props: any) => {
              const active = !activeService || activeService === p;
              const newProps = {
                ...props,
                opacity: active ? 1 : 0.2,
              };
              return <Rectangle {...newProps} />;
            }}
          />
        ))}
      </BarChart>
    </ResponsiveContainer>
  );
};
