import {
  IdentityFragment,
  getError,
  getTypeOrNull,
  isError,
  useGetCurrentUserQuery,
  useGetCurrentUserSuspenseQuery,
} from '@spotify-confidence/plugin-graphql';

export function useCurrentIdentity(): IdentityFragment | null {
  const { data } = useGetCurrentUserQuery();
  if (isError(data?.currentUser)) return null;

  return getTypeOrNull(data?.currentUser?.identity, 'IamV1Identity');
}

export function useCurrentIdentitySuspense(): IdentityFragment {
  const { data } = useGetCurrentUserSuspenseQuery();

  if (isError(data?.currentUser)) throw getError(data?.currentUser);

  const identity = getTypeOrNull(data?.currentUser?.identity, 'IamV1Identity');
  if (!identity) throw new Error('No identity found');

  return identity;
}
