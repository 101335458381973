import React from 'react';

import {
  Box,
  ClickAwayListener,
  Grow,
  MenuItem,
  MenuList,
  Paper,
  Popper,
  Typography,
  makeStyles,
} from '@material-ui/core';
import BarChartIcon from '@material-ui/icons/BarChart';
import EditIcon from '@material-ui/icons/Edit';
import SettingsIcon from '@material-ui/icons/Settings';

import { SectionButton } from '@spotify-confidence/core-react';

type StrategyDescriptionProps = {
  name: string;
  body: string;
  footer?: string;
  variant?: 'small';
};

export const StrategyDescription = ({
  name,
  body,
  footer,
  variant,
}: StrategyDescriptionProps) => {
  const smallVariant = variant === 'small';
  return (
    <>
      <Typography variant={smallVariant ? 'body2' : 'h6'}>{name}</Typography>
      <Box mt={0.5}>
        <Typography
          variant={smallVariant ? 'body2' : 'body1'}
          color={smallVariant ? 'textSecondary' : 'inherit'}
        >
          {body}
        </Typography>
      </Box>
      {!!footer && (
        <Box mt={0.5}>
          <Typography
            variant={smallVariant ? 'caption' : 'body2'}
            color="textSecondary"
          >
            {footer}
          </Typography>
        </Box>
      )}
    </>
  );
};

export const SequentialStrategyDescription = (
  props: Partial<StrategyDescriptionProps>,
) => (
  <StrategyDescription
    name="Continuously"
    body="View results as they come in."
    footer="It may be harder to detect significant effects on metrics."
    {...props}
  />
);

export const FixedStrategyDescription = (
  props: Partial<StrategyDescriptionProps>,
) => (
  <StrategyDescription
    name="Upon conclusion"
    body="View results after a set time frame."
    footer="It will be easier to detect significant effects on metrics."
    {...props}
  />
);

export type StrategyCardMenuProps = {
  disabled?: boolean;
  onEditResultsSettingsClick: () => void;
  onEditConfigureStatisticsClick: () => void;
};

const useStyles = makeStyles(theme => ({
  popper: {
    zIndex: theme.zIndex.drawer,
  },
}));

export const StrategyCardMenu = ({
  disabled,
  onEditResultsSettingsClick,
  onEditConfigureStatisticsClick,
}: StrategyCardMenuProps) => {
  const classes = useStyles();

  const [openEditMenu, setOpenEditMenu] = React.useState(false);
  const anchorRef = React.useRef<HTMLButtonElement>(null);

  const handleToggleEditMenu = () => {
    setOpenEditMenu(prevOpen => !prevOpen);
  };

  const handleCloseEditMenu = (event: React.MouseEvent<EventTarget>) => {
    if (
      anchorRef.current &&
      anchorRef.current.contains(event.target as HTMLElement)
    ) {
      return;
    }

    setOpenEditMenu(false);
  };

  const handleMenuOptionClick =
    (callbackProp: () => void) => (event: React.MouseEvent<EventTarget>) => {
      callbackProp();
      handleCloseEditMenu(event);
    };

  return (
    <Box>
      <SectionButton
        onClick={handleToggleEditMenu}
        size="small"
        ref={anchorRef}
        disabled={disabled}
      >
        <EditIcon fontSize="small" />
      </SectionButton>

      <Popper
        open={openEditMenu}
        anchorEl={anchorRef.current}
        placement="bottom-start"
        className={classes.popper}
        transition
        disablePortal
      >
        {({ TransitionProps }) => (
          <Grow {...TransitionProps}>
            <Paper>
              <ClickAwayListener onClickAway={handleCloseEditMenu}>
                <MenuList>
                  <MenuItem
                    onClick={handleMenuOptionClick(onEditResultsSettingsClick)}
                  >
                    <Box
                      display="flex"
                      justifyContent="space-between"
                      gridGap={8}
                      width="100%"
                    >
                      <Typography variant="inherit">
                        Results Settings
                      </Typography>
                      <SettingsIcon color="action" fontSize="small" />
                    </Box>
                  </MenuItem>
                  <MenuItem
                    onClick={handleMenuOptionClick(
                      onEditConfigureStatisticsClick,
                    )}
                  >
                    <Box
                      display="flex"
                      justifyContent="space-between"
                      gridGap={8}
                      width="100%"
                    >
                      <Typography variant="inherit">
                        Configure Statistics
                      </Typography>
                      <BarChartIcon color="action" fontSize="small" />
                    </Box>
                  </MenuItem>
                </MenuList>
              </ClickAwayListener>
            </Paper>
          </Grow>
        )}
      </Popper>
    </Box>
  );
};

export const StrategyCard = ({
  sequential,
  footer,
}: { sequential: boolean } & Pick<StrategyDescriptionProps, 'footer'>) => {
  const DescriptionComponent = sequential
    ? SequentialStrategyDescription
    : FixedStrategyDescription;

  return (
    <Box>
      <DescriptionComponent variant="small" footer={footer} />
    </Box>
  );
};
