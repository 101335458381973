import React from 'react';
import { InView } from 'react-intersection-observer';

import {
  Avatar,
  ListItemText,
  Table,
  TableBody,
  TableCell,
  TableRow,
  Tooltip,
  styled,
} from '@material-ui/core';

import {
  ACTION_BAR_HEIGHT,
  DateUtils,
  FilterListEmptyState,
  Highlight,
  ResourceTableRow,
  StickyTableContainer,
  StickyTableHead,
  useListFilter,
} from '@spotify-confidence/core-react';
import {
  getError,
  getTypeOrNull,
  useListUsersQuery,
} from '@spotify-confidence/plugin-graphql';
import { differenceInMonths } from 'date-fns';

import { StatusOK, StatusPending } from '@backstage/core-components';

import { userRouteRef } from '../../../routes';

type UserListProps = {
  pageSize?: number;
  pageToken?: string;
  topOffset?: number;
};

const NonRelativeAvatar = styled(Avatar)({
  position: 'static',
});

export const UserList = ({
  pageSize = 25,
  pageToken = '',
  topOffset = ACTION_BAR_HEIGHT,
}: UserListProps) => {
  const { searchQuery } = useListFilter();
  const filter = searchQuery
    ? `email:*${searchQuery}* OR fullName:*${searchQuery}*`
    : undefined;

  const { data, loading, error, fetchMore } = useListUsersQuery({
    variables: {
      pageSize,
      pageToken,
      filter,
    },
  });

  const { users: filteredUsers, nextPageToken } = getTypeOrNull(
    data?.users,
    'IamV1ListUsersResponse',
  ) || { users: [] };
  const getUserError = getError(data?.users);

  if (!filteredUsers || (filteredUsers.length === 0 && !loading)) {
    return (
      <FilterListEmptyState
        error={error ?? getUserError}
        type="user"
        description="Invite colleagues to experiment together using Confidence."
      />
    );
  }

  return (
    <StickyTableContainer loading={loading} error={error}>
      <Table>
        <StickyTableHead topOffset={topOffset}>
          <TableRow>
            <TableCell colSpan={2}>Name</TableCell>
            <TableCell>Status</TableCell>
          </TableRow>
        </StickyTableHead>
        <TableBody>
          {filteredUsers.map(user => {
            const lastLoggedIn = user.lastLoginTime
              ? `Logged in ${DateUtils.xAgo(new Date(user.lastLoginTime))}`
              : 'Never logged in';
            const inactive =
              !user.lastLoginTime ||
              differenceInMonths(new Date(), new Date(user.lastLoginTime)) >= 1;
            return (
              <ResourceTableRow
                key={user.name}
                name={user.name}
                data-testid={user.fullName}
                routeRef={userRouteRef}
              >
                <TableCell width={65}>
                  <NonRelativeAvatar src={user.pictureUri ?? undefined} />
                </TableCell>
                <TableCell>
                  <ListItemText
                    primary={
                      <Highlight highlight={searchQuery}>
                        {user.fullName}
                      </Highlight>
                    }
                    secondary={
                      <Highlight highlight={searchQuery}>
                        {user.email}
                      </Highlight>
                    }
                  />
                </TableCell>
                <TableCell width={120}>
                  <Tooltip title={lastLoggedIn} arrow>
                    <div>
                      {inactive ? (
                        <StatusPending>Inactive</StatusPending>
                      ) : (
                        <StatusOK>Active</StatusOK>
                      )}
                    </div>
                  </Tooltip>
                </TableCell>
              </ResourceTableRow>
            );
          })}
        </TableBody>
      </Table>
      {data && (
        <InView
          onChange={async inView => {
            if (inView && nextPageToken) {
              await fetchMore({
                variables: {
                  pageToken: nextPageToken,
                },
              });
            }
          }}
        />
      )}
    </StickyTableContainer>
  );
};
