import { Typography, styled } from '@material-ui/core';

import { HIGH_LEVEL_AXIS_HEIGHT } from './TimeAxis';

const MARKER_SIZE = 8;
const ACTIVITY_ROW_HEIGHT = 49;

export const TimelineHeader = styled('div')(({ theme }) => ({
  position: 'sticky',
  top: 0,
  zIndex: 4,
  display: 'flex',
  justifyContent: 'space-between',
  background: theme.palette.background.default,
  borderBottom: `1px solid ${theme.palette.action.disabled}`,
}));

export const TimelineActivities = styled('div')({
  display: 'grid',
  gridTemplateColumns: 'max-content 1fr',
});

export const LabelColumn = styled('div')(({ theme }) => ({
  background: theme.palette.background.default,
  display: 'none',
  [theme.breakpoints.up('md')]: {
    display: 'initial',
    maxWidth: 300,
  },
  [theme.breakpoints.up('lg')]: {
    maxWidth: 400,
  },
}));

export const LabelContent = styled(LabelColumn)({ gridColumn: 1 });

export const LabelHeader = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  padding: theme.spacing(0, 1.5),
  height: HIGH_LEVEL_AXIS_HEIGHT,
}));

export const Label = styled(Typography)({
  overflow: 'hidden',
  whiteSpace: 'nowrap',
  textOverflow: 'ellipsis',
});

export const ActivityRow = styled('div')(({ theme }) => ({
  maxHeight: ACTIVITY_ROW_HEIGHT,
  height: ACTIVITY_ROW_HEIGHT,
  borderBottom: `1px solid ${theme.palette.divider}`,
  display: 'flex',
  alignItems: 'center',
  '&:last-child, &:last-of-type': {
    borderBottom: 'none',
  },
}));

export const TimelineColumn = styled('div')({
  overflow: 'hidden',
  position: 'relative',
});

export const TimelineContent = styled('div')(({ theme }) => ({
  position: 'relative',
  overflowX: 'hidden',
  gridColumnStart: 1,
  gridColumnEnd: -1,
  [theme.breakpoints.up('md')]: {
    gridColumnStart: 2,
  },
}));

export const LabelHeaderText = styled(Typography)({
  fontWeight: 'bold',
});

export const MarkerDotWrapper = styled('div')({
  position: 'absolute',
  right: 0,
  bottom: -MARKER_SIZE / 2,
  height: MARKER_SIZE,
  overflow: 'hidden',
  pointerEvents: 'none',
});

export const MarkerDot = styled('div')(({ theme }) => ({
  position: 'absolute',
  top: 0,
  width: MARKER_SIZE,
  height: MARKER_SIZE,
  marginLeft: theme.spacing(1) + MARKER_SIZE / 4,
  background: theme.palette.primary.main,
  borderRadius: '50%',
  transition: theme.transitions.create('transform'),
  boxShadow: theme.shadows[1],
}));

export const MarkerLine = styled('div')(({ theme }) => ({
  position: 'absolute',
  top: 0,
  zIndex: 3,
  marginLeft: theme.spacing(2) - MARKER_SIZE / 4,
  width: 1,
  height: '100%',
  backgroundColor: theme.palette.primary.main,
  transition: theme.transitions.create('transform'),
  pointerEvents: 'none',
}));
