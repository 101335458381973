import React from 'react';
import { Route, Routes } from 'react-router-dom';

import loadable from '@loadable/component';
import { AuthorizationGate } from '@spotify-confidence/core-react';

import { eventRouteRef } from './routes';

const eventsPagesChunk = () =>
  import(
    /* webpackChunkName: "events-pages", webpackPrefetch: 5 */ './event/components'
  );
const loadOptions = {};

const EventIndexPage = loadable(
  () => eventsPagesChunk().then(m => m.EventIndexPage),
  loadOptions,
);
const EventPage = loadable(
  () => eventsPagesChunk().then(m => m.EventPage),
  loadOptions,
);

const routes = (
  <>
    <Route path="/" element={<EventIndexPage />} />
    <Route path={eventRouteRef.path} element={<EventPage />} />
  </>
);

export const Router = () => (
  <AuthorizationGate>
    <Routes>{routes}</Routes>
  </AuthorizationGate>
);
