import * as React from 'react';
import { useLocation, useSearchParams } from 'react-router-dom';

import { Dialog, DialogProps, makeStyles } from '@material-ui/core';

const useStyles = makeStyles({
  paper: {
    transition: 'max-width 225ms ease-in-out 0ms',
  },
  paperFullScreen: {
    paddingBottom: 0,
  },
});

type State = {
  content: React.ReactElement | null;
  dialogProps?: Partial<DialogProps>;
  onClose?: () => void;
  disableBackdropClick?: boolean;
  stack: React.ReactElement[];
};

const defaultState: State = {
  content: null,
  dialogProps: {},
  onClose: undefined,
  disableBackdropClick: true,
  stack: [],
};

export type ContextType = {
  openDialog: (opts: Partial<State>) => void;
  closeDialog: (
    event?: any,
    reason?: 'backdropClick' | 'escapeKeyDown',
  ) => void;

  popDialog: () => void;
};

const EXIT_TIMEOUT = 300;

const context = React.createContext<ContextType | undefined>(undefined);

export const useDialog = (): ContextType => {
  const _context = React.useContext(context);

  if (!_context) {
    throw new Error('Missing `DialogProvider` wrapper.');
  }

  return _context;
};

const LocationListener = ({
  children,
  onChange,
}: React.PropsWithChildren<{ onChange: () => void }>) => {
  const location = useLocation();
  const [searchParams] = useSearchParams();
  const mountedRef = React.useRef(false);

  React.useEffect(() => {
    if (!searchParams.get('step')) {
      if (mountedRef.current) {
        onChange();
      }
    }
  }, [location, searchParams.get('step')]);

  React.useEffect(() => {
    mountedRef.current = true;
  }, []);

  return <>{children}</>;
};

export const DialogProvider = ({ children }: React.PropsWithChildren<{}>) => {
  const classes = useStyles();

  const [{ content, dialogProps, onClose, disableBackdropClick }, setState] =
    React.useState<State>(defaultState);

  // We use the closing value to avoid empty dialog content before the animation finishes
  const [closing, setClosing] = React.useState(false);

  const handleClose = React.useCallback(() => {
    if (!closing) {
      setClosing(true);
      if (onClose) {
        onClose();
      }
    }
  }, [onClose, closing]);

  const closeDialog = React.useCallback(
    (_event: any, reason?: 'backdropClick' | 'escapeKeyDown') => {
      if (reason === 'backdropClick' && disableBackdropClick) {
        return;
      }
      handleClose();
    },
    [handleClose, disableBackdropClick],
  );

  const openDialog = React.useCallback(
    (opts: Partial<State>) => {
      setClosing(false);
      setState({
        ...defaultState,
        ...opts,
      });
    },
    [handleClose, defaultState],
  );

  const popDialog = React.useCallback(() => {
    setState(state => {
      const stack = [...state.stack];
      const popped = stack.pop() ?? null;
      return {
        ...state,
        content: popped,
        stack,
      };
    });
  }, [setState]);

  const isOpen = React.useMemo(() => {
    return !!content && !closing;
  }, [content, closing]);

  React.useEffect(() => {
    // Reset to default state after dialog has closed
    let timeout: ReturnType<typeof setTimeout>;
    if (content && closing) {
      timeout = setTimeout(() => {
        setState(defaultState);
        setClosing(false);
      }, EXIT_TIMEOUT);
    }
    return () => {
      if (timeout) clearTimeout(timeout);
    };
  }, [content, closing]);

  return (
    <context.Provider
      value={{
        openDialog,
        closeDialog,
        popDialog,
      }}
    >
      <Dialog
        open={isOpen}
        onClose={closeDialog}
        disableEnforceFocus
        disableRestoreFocus
        fullWidth
        maxWidth="sm"
        classes={classes}
        transitionDuration={{
          exit: EXIT_TIMEOUT,
        }}
        {...dialogProps}
      >
        {content && (
          <LocationListener onChange={handleClose}>{content}</LocationListener>
        )}
      </Dialog>

      {children}
    </context.Provider>
  );
};
