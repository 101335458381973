import React from 'react';

import { Avatar, Box, Button, Typography, makeStyles } from '@material-ui/core';

import { useAuth } from '@spotify-confidence/core-react';

const useStyles = makeStyles(theme => ({
  root: {
    padding: theme.spacing(6, 10),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  avatar: {
    width: theme.spacing(10),
    height: theme.spacing(10),
    border: `2px solid ${theme.palette.divider}`,
    marginBottom: theme.spacing(2),
  },
}));

type User = {
  fullName?: string | null;
  email?: string | null;
  pictureUri?: string | null;
};

export function UserInfo({ user }: { user?: User | null }) {
  const classes = useStyles();
  const { logout } = useAuth();

  const handleLogout = () => {
    logout({ logoutParams: { returnTo: `${window.location.origin}` } });
  };

  return (
    <Box className={classes.root}>
      <Avatar className={classes.avatar} src={user?.pictureUri ?? undefined} />
      <Typography variant="body1">{user?.fullName}</Typography>
      <Typography variant="body2" color="textSecondary">
        {user?.email}
      </Typography>
      <Box marginTop={2}>
        <Button size="small" variant="outlined" onClick={handleLogout}>
          Sign out
        </Button>
      </Box>
    </Box>
  );
}
