import React, { PropsWithChildren, forwardRef } from 'react';

import {
  ListItem,
  ListItemIcon,
  ListItemText,
  SvgIconProps,
  Theme,
  WithStyles,
  createStyles,
  useMediaQuery,
  useTheme,
  withStyles,
} from '@material-ui/core';
import CheckCircleOutlineIcon from '@material-ui/icons/CheckCircleOutline';
import ErrorOutlineOutlinedIcon from '@material-ui/icons/ErrorOutlineOutlined';
import RadioButtonUncheckedOutlinedIcon from '@material-ui/icons/RadioButtonUncheckedOutlined';

import classNames from 'classnames';

const styles = (theme: Theme) =>
  createStyles({
    root: {
      flexGrow: 1,
    },
    icon: {},
    iconStartAlign: {
      marginTop: theme.spacing(0.5),
    },
  });

export interface RecommendationCardCriteriaItemProps
  extends WithStyles<typeof styles>,
    PropsWithChildren<{}> {
  className?: string;

  status: 'error' | 'success' | 'warning' | 'pending';

  // (optional) icon component to use. defaults to an icon
  // that corresponds to the status.
  IconComponent?: React.ComponentType<SvgIconProps>;
}

const RecommendationCardCondition = forwardRef<
  HTMLLIElement,
  RecommendationCardCriteriaItemProps
>(function RecommendationCardCriteriaItem(
  { className, status, classes, children, IconComponent },
  ref,
) {
  const color =
    // eslint-disable-next-line no-nested-ternary
    status === 'success'
      ? 'primary'
      : // eslint-disable-next-line no-nested-ternary
      status === 'warning'
      ? 'secondary'
      : status === 'error'
      ? 'error'
      : 'secondary';

  const Icon =
    IconComponent ??
    // eslint-disable-next-line no-nested-ternary
    (status === 'success'
      ? CheckCircleOutlineIcon
      : status === 'error'
      ? ErrorOutlineOutlinedIcon
      : RadioButtonUncheckedOutlinedIcon);

  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down('md'));

  return (
    <ListItem
      ref={ref}
      className={classNames(className, classes.root)}
      alignItems="flex-start"
      disableGutters={isSmallScreen}
    >
      <ListItemIcon classes={{ alignItemsFlexStart: classes.iconStartAlign }}>
        <Icon color={color} />
      </ListItemIcon>
      <ListItemText primary={children} />
    </ListItem>
  );
});

export default withStyles(styles)(RecommendationCardCondition);
