import React from 'react';

import { SvgIconProps } from '@material-ui/core';
import EmojiFlagsIcon from '@material-ui/icons/EmojiFlags';
import EventIcon from '@material-ui/icons/Event';
import FingerprintIcon from '@material-ui/icons/Fingerprint';
import FormatListBulletedIcon from '@material-ui/icons/FormatListBulleted';
import StyleIcon from '@material-ui/icons/Style';
import TextFieldsIcon from '@material-ui/icons/TextFields';
import TimerIcon from '@material-ui/icons/Timer';
import ToggleOnIcon from '@material-ui/icons/ToggleOn';

import { DoubleIcon, IntIcon, JsonIcon } from '@spotify-confidence/core-react';

import { Option } from './SchemaEditor/AddFieldButton';

export const FieldIcon = ({
  type,
  ...rest
}: {
  type: Option;
} & SvgIconProps) =>
  ({
    boolSchema: <ToggleOnIcon {...rest} />,
    stringSchema: <TextFieldsIcon {...rest} />,
    intSchema: <IntIcon {...rest} />,
    doubleSchema: <DoubleIcon {...rest} />,
    listSchema: <FormatListBulletedIcon {...rest} />,
    structSchema: <JsonIcon {...rest} />,
    dateSchema: <EventIcon {...rest} />,
    timestampSchema: <TimerIcon {...rest} />,
    country: <EmojiFlagsIcon {...rest} />,
    entityReference: <FingerprintIcon {...rest} />,
    enumType: <StyleIcon {...rest} />,
  }[type] || null);
