import React from 'react';

import { ListItem, styled } from '@material-ui/core';
import Add from '@material-ui/icons/Add';

const StyledLi = styled('li')(({ theme }) => ({
  position: 'sticky',
  bottom: 0,
  listStyle: 'none',
  backgroundColor: theme.palette.background.paper,
  borderTop: `1px solid ${theme.palette.action.selected}`,
  zIndex: 1,
}));

const StyledListItem = styled(ListItem)(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  gap: theme.spacing(1),
  paddingTop: theme.spacing(1),
  paddingBottom: theme.spacing(1),
  width: '100%',
  '&:after': {
    height: '100%',
    top: 0,
    left: 0,
  },
})) as typeof ListItem;

export const CreateNewOption = ({
  label = 'Create',
  ...props
}: React.HTMLProps<HTMLLIElement> & { label?: string }) => {
  return (
    <StyledLi role="option" {...props}>
      <StyledListItem button dense>
        <Add fontSize="small" />
        {label}
      </StyledListItem>
    </StyledLi>
  );
};
