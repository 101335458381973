import { useCallback } from 'react';

import {
  WorkflowV1SurfaceInput,
  getError,
  getTypeOrNull,
  useDeleteSurfaceMutation,
  useSurfaceQuery,
  useUpdateSurfaceMutation,
} from '@spotify-confidence/plugin-graphql';
import { PermissionUtils } from '@spotify-confidence/plugin-permissions-react';

/**
 * Companion hook for the SurfacePage component.
 */
export function useSurface(name: string) {
  const { data, loading, error } = useSurfaceQuery({
    variables: {
      name,
    },
  });

  const surfaceError = getError(data?.surface);
  const surface = getTypeOrNull(data?.surface, 'WorkflowV1Surface');
  const canEdit = PermissionUtils.hasRelation(surface, 'can_edit');

  const [updateSurfaceMutation] = useUpdateSurfaceMutation();
  const [deleteSurfaceMutation, { loading: isDeleting }] =
    useDeleteSurfaceMutation({
      variables: {
        name,
      },
      update: cache => {
        cache.evict({
          id: cache.identify({
            __typename: 'WorkflowV1Surface',
            name: name,
          }),
        });
      },
    });

  const updateSurface = useCallback(
    async (updated: Partial<WorkflowV1SurfaceInput>, updateMask: string) => {
      return await updateSurfaceMutation({
        variables: {
          input: updated,
          updateMask: updateMask,
        },
      });
    },
    [updateSurfaceMutation],
  );

  const deleteSurface = useCallback(async () => {
    const response = await deleteSurfaceMutation({ variables: { name } });
    const responseError = getError(response.data?.deleteSurface);
    if (responseError?.message !== undefined) {
      throw new Error(responseError.message);
    }
  }, [deleteSurfaceMutation]);

  return {
    // data
    surface,
    loading,
    error: error ?? surfaceError,
    isDeleting,
    canEdit,

    // operations
    updateSurface,
    deleteSurface,
  };
}
