import { useEffect, useState } from 'react';

export function useDelayedTrigger({
  delay,
  duration,
}: {
  delay: number;
  duration: number;
}) {
  const [count, setCount] = useState(0);
  const [value, setValue] = useState(false);

  useEffect(() => {
    if (value) {
      const id = setTimeout(() => setValue(false), duration);
      return () => clearTimeout(id);
    }
    return () => null;
  }, [value]);

  useEffect(() => {
    if (count > 0) {
      const id = setTimeout(() => setValue(true), delay);
      return () => clearTimeout(id);
    }
    return () => null;
  }, [count]);

  const trigger = () => setCount(currentCount => currentCount + 1);

  return { value, trigger };
}
