import React from 'react';

import { Grid, GridProps } from '@material-ui/core';
import { Skeleton, SkeletonProps } from '@material-ui/lab';

type GridSkeletonProps = {
  gridProps: GridProps[];
  skeletonProps?: SkeletonProps;
};

export function GridSkeleton({ gridProps, skeletonProps }: GridSkeletonProps) {
  return (
    <>
      {gridProps.map((gridProp, index) => (
        <Grid {...gridProp} key={`Grid-${index}`}>
          <Skeleton
            height={44}
            width="100%"
            animation="wave"
            {...skeletonProps}
          />
        </Grid>
      ))}
    </>
  );
}
