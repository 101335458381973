import React from 'react';

import {
  Box,
  Button,
  ButtonProps,
  MenuItem,
  makeStyles,
} from '@material-ui/core';
import AddIcon from '@material-ui/icons/Add';

import { ContextMenu } from '@spotify-confidence/core-react';
import {
  EventsAdminV1EventSchema,
  EventsAdminV1FieldSemanticType,
} from '@spotify-confidence/plugin-graphql';

import { schemaTypeDisplayName } from '../../domain/schema.helpers';
import { Entry } from '../../domain/schema.hooks';
import { FieldIcon } from '../FieldIcon';

export const SCHEMA_TYPES: Array<keyof EventsAdminV1EventSchema> = [
  'boolSchema',
  'dateSchema',
  'doubleSchema',
  'intSchema',
  'listSchema',
  'stringSchema',
  'structSchema',
  'timestampSchema',
];

export const SEMANTIC_STRING_TYPES: Array<
  keyof EventsAdminV1FieldSemanticType
> = ['country', 'enumType', 'entityReference'];

export type Option =
  | keyof EventsAdminV1FieldSemanticType
  | keyof EventsAdminV1EventSchema;
export type AddFieldButtonProps = Omit<ButtonProps, 'onClick'> & {
  onClick?: (type: Option) => void;
  entry?: Entry;
  disabledTypes?: Array<Option>;
};

const useStyles = makeStyles(theme => {
  const color =
    theme.palette.type === 'light'
      ? theme.palette.primary.dark
      : theme.palette.primary.main;
  return {
    text: {
      color,
      '&:hover': {
        color,
      },
    },
    label: {
      '$text &': {
        alignSelf: 'flex-start',
        textAlign: 'left',
      },
    },
  };
});

export const AddFieldButton = ({
  onClick,
  startIcon = <AddIcon />,
  entry,
  disabledTypes = [],
  ...rest
}: AddFieldButtonProps) => {
  const classes = useStyles();
  return (
    <ContextMenu
      closeOnSelect
      renderButton={props => (
        <Button
          aria-haspopup="true"
          classes={{
            text: classes.text,
            label: classes.label,
          }}
          data-testid={`add-field${entry ? `-${entry.key}` : ''}`}
          startIcon={startIcon}
          {...rest}
          {...props}
        >
          Add a field
          {entry && <>{entry.key && ` to ${entry.key}`}</>}
        </Button>
      )}
      renderMenu={() =>
        SCHEMA_TYPES.filter(t => !disabledTypes.includes(t)).map(type => (
          <TypeOption key={type} type={type} onClick={onClick} />
        ))
      }
    />
  );
};

export function TypeOption({
  onClick,
  type,
}: {
  onClick?: (a: any) => void;
  type: Option;
}) {
  return (
    <MenuItem onClick={() => onClick?.(type)}>
      <Box width={16} height={16} display="inline-flex" marginRight={1.5}>
        <FieldIcon type={type} fontSize="small" color="inherit" />
      </Box>
      {schemaTypeDisplayName(type)}
    </MenuItem>
  );
}
