/* eslint-disable no-nested-ternary */
import React from 'react';

import { TableRow, makeStyles } from '@material-ui/core';

import { GroupingState, Row } from '@tanstack/react-table';

import { DataCell } from './DataCell';
import { getVisibleCellsExcludingGroupTitle } from './tableUtils';
import { ResultData } from './types';

const useRowStyles = makeStyles(theme => {
  return {
    row: {
      borderBottom: `1px solid ${theme.palette.divider}`,
      '&:last-child': {
        borderBottom: 'none',
      },
    },
    alignTop: {
      verticalAlign: 'top',
    },
  };
});

export const DefaultRow = ({
  row,
  grouping,
}: {
  row: Row<ResultData>;
  grouping?: GroupingState;
}) => {
  const classes = useRowStyles();
  const cells = getVisibleCellsExcludingGroupTitle(row, grouping);

  return (
    <TableRow hover className={classes.row}>
      {cells.map(cell => (
        <DataCell key={cell.id} cell={cell} grouping={grouping} />
      ))}
    </TableRow>
  );
};

export const DataRow = ({
  row,
  grouping,
}: {
  row: Row<ResultData>;
  grouping?: GroupingState;
}) => {
  const defaultRow = <DefaultRow row={row} grouping={grouping} />;
  const isGrouped = row.getIsGrouped();
  const isExpanded = row.getIsExpanded();
  const isNotLastGroup = grouping ? row.depth < grouping.length - 1 : true;

  React.useEffect(() => {
    if (isGrouped && !isExpanded && isNotLastGroup) {
      row.toggleExpanded();
    }
  }, [row, isGrouped, isExpanded, isNotLastGroup]);

  return defaultRow;
};
