import { useMethods } from '@spotify-confidence/core-react';
import {
  DataDeliveredUntilUpdateStrategyConfigFragment,
  FactTableFragment,
  InputMaybe,
  MetricsV1Column,
  MetricsV1DataDeliveredUntilUpdateStrategyConfigAutomaticUpdateStrategyConfigInput,
  MetricsV1DataDeliveredUntilUpdateStrategyConfigInput,
  MetricsV1DataDeliveredUntilUpdateStrategyConfigStrategy,
  MetricsV1EntityColumnMappingInput,
  MetricsV1FactTableInput,
  getTypeOrNull,
} from '@spotify-confidence/plugin-graphql';

const createBlankColumn = (): MetricsV1Column => ({
  name: '',
});

const createAutomaticUpdateConfig =
  (): MetricsV1DataDeliveredUntilUpdateStrategyConfigAutomaticUpdateStrategyConfigInput => ({
    commitDelay: '1800s',
  });

const createManualUpdateStrategy =
  (): MetricsV1DataDeliveredUntilUpdateStrategyConfigInput => ({
    strategy: MetricsV1DataDeliveredUntilUpdateStrategyConfigStrategy.Automatic,
    automaticUpdateConfig: createAutomaticUpdateConfig(),
  });

const createBlankFactTable = (
  partialMetric?: Partial<MetricsV1FactTableInput>,
): MetricsV1FactTableInput => ({
  sql: '',
  displayName: '',
  owner: null,
  timestampColumn: createBlankColumn(),
  entities: [
    {
      column: createBlankColumn(),
      entity: '',
    },
  ],
  measures: [],
  dimensions: [],
  dataDeliveredUntilUpdateStrategyConfig: createManualUpdateStrategy(),
  ...partialMetric,
});

const methods = {
  changeField: (
    state: MetricsV1FactTableInput,
    field: Partial<MetricsV1FactTableInput>,
  ) => {
    return {
      ...state,
      ...field,
    };
  },
  updateEntityByIndex: (
    state: MetricsV1FactTableInput,
    index: number,
    field: Partial<MetricsV1EntityColumnMappingInput>,
  ) => {
    return {
      ...state,
      entities: state?.entities?.map((e, idx) => {
        if (idx === index) return { ...e, ...field };
        return e;
      }),
    };
  },
  addEntity: (state: MetricsV1FactTableInput) => {
    return {
      ...state,
      entities: [
        ...(state?.entities || []),
        {
          entity: '',
          column: createBlankColumn(),
        },
      ],
    };
  },

  removeEntity: (state: MetricsV1FactTableInput, index: number) => {
    return {
      ...state,
      entities: state?.entities?.filter((_e, idx) => idx !== index),
    };
  },
};

export const mapDataDeliveredUntilUpdateStrategyConfig =
  function mapDataDeliveredUntilUpdateStrategyConfig(
    config?: InputMaybe<DataDeliveredUntilUpdateStrategyConfigFragment>,
  ): MetricsV1DataDeliveredUntilUpdateStrategyConfigInput {
    if (config) {
      return {
        strategy: config.strategy,
        dailyUpdateConfig: config.dailyUpdateConfig ? {} : null,
        hourlyUpdateConfig: config.hourlyUpdateConfig ? {} : null,
        automaticUpdateConfig: config.automaticUpdateConfig,
      };
    }
    return {
      strategy: MetricsV1DataDeliveredUntilUpdateStrategyConfigStrategy.Manual,
    };
  };

const mapToInput = function mapToInput(
  input?: FactTableFragment,
): MetricsV1FactTableInput {
  if (!input) return createBlankFactTable();
  return {
    dataDeliveredUntilUpdateStrategyConfig:
      mapDataDeliveredUntilUpdateStrategyConfig(
        input.dataDeliveredUntilUpdateStrategyConfig,
      ),
    owner: getTypeOrNull(input.owner, 'IamV1Identity')?.name ?? null,
    dimensions: input.dimensions,
    displayName: input.displayName,
    entities: input.entities.map(i => {
      return {
        column: i.column,
        entity: getTypeOrNull(i.entity, 'MetricsV1Entity')?.name ?? 'unknown',
      };
    }),
    factDataDeliveredUntilTime: input.factDataDeliveredUntilTime,
    labels: input.labels,
    measures: input.measures,
    name: input.name,
    sql: input.sql,
    timestampColumn: input.timestampColumn,
  };
};

export const useEditFactTableState = (existingTable?: FactTableFragment) =>
  useMethods(methods, mapToInput(existingTable));

export type FactTableMethods = ReturnType<typeof useEditFactTableState>[1];
