import React, { useEffect } from 'react';
import { useEvent } from 'react-use';

import {
  Box,
  Chip,
  CircularProgress,
  DialogContent,
  Divider,
  Fade,
  InputAdornment,
  InputBase,
  List,
  ListItemIcon,
  ListItemText,
  Paper,
  Typography,
  alpha,
  makeStyles,
  styled,
} from '@material-ui/core';
import ArrowDownwardIcon from '@material-ui/icons/ArrowDownward';
import ArrowUpwardIcon from '@material-ui/icons/ArrowUpward';
import SearchIcon from '@material-ui/icons/Search';

import { useDialog, useNavigate } from '@spotify-confidence/core-react';

import { SearchDocument, useSearch } from '../../hooks/useSearch';
import { ResultListItem } from './ResultListItem';

const useStyles = makeStyles(theme => ({
  root: {
    height: 'calc(100vh - 400px)',
    minHeight: 400,
    display: 'flex',
    flexDirection: 'column',
  },
  header: {
    flexShrink: 0,
  },
  keyboard: {
    border: `1px solid ${theme.palette.secondary.main}`,
    borderRadius: theme.shape.borderRadius / 2,
    padding: theme.spacing(0, 0.5),
    textTransform: 'uppercase',
  },
  results: {
    flex: '1 1 auto',
    height: 0,
    overflowY: 'auto',
    overflowX: 'hidden',
  },
  input: {
    paddingLeft: 0,
    paddingTop: 0,
  },
  searchLabel: {
    color: 'inherit',
  },
  searchBar: {
    height: 38,
    width: 300,
    [theme.breakpoints.down('sm')]: {
      width: 'min-content',
    },
    display: 'flex',
    padding: theme.spacing(1.5),
    alignItems: 'center',
    justifyContent: 'space-between',
    cursor: 'text',
    border: `1px solid ${alpha(theme.palette.navigation.color, 0.2)}`,
    '&:hover': {
      border: `1px solid ${theme.palette.navigation.selectedColor}`,
    },
    color: theme.palette.navigation.color,
    background: theme.palette.navigation.background,
    transition: theme.transitions.create('border', {
      duration: theme.transitions.duration.shortest,
    }),
  },
  searchIcon: {
    display: 'flex',
    justifyContent: 'center',
    color: theme.palette.navigation.color,
    [theme.breakpoints.up('sm')]: {
      marginRight: theme.spacing(1),
    },
  },
  hideMobile: {
    [theme.breakpoints.down('sm')]: {
      display: 'none',
    },
  },
}));

const ResultContainer = styled('div')(({ theme }) => ({
  marginTop: theme.spacing(2),
}));

const ShortcutBase = styled('button')(({ theme }) => ({
  backgroundColor: theme.palette.navigation.background,
  border: '1px solid',
  borderRadius: theme.shape.borderRadius,
  color: theme.palette.navigation.color,
  borderColor: alpha(theme.palette.navigation.color, 0.2),
  whiteSpace: 'nowrap',
}));

const StyledDivider = styled(Divider)(({ theme }) => ({
  margin: theme.spacing(0, -1),
}));

const SearchDialog = () => {
  const classes = useStyles();

  const [query, setQuery] = React.useState('');
  const navigate = useNavigate();
  const { closeDialog } = useDialog();
  const { results, loading } = useSearch(query);

  const handleClose = () => closeDialog();

  const [selected, setSelected] = React.useState(0);

  // Cache the results to prevent flickering
  const [cachedResults, setCachedResults] = React.useState<SearchDocument[]>(
    [],
  );

  // Update the cache when the results change and we are no longer
  // loading.
  useEffect(
    () => {
      if (!loading) setCachedResults(results);
    },
    // Since arrays are reference types, we need to compare the stringified
    // version of the results to determine if we need to update the cache
    [JSON.stringify(results.map(r => r.id)), loading],
  );

  // Update the selected index when the results change
  useEffect(() => {
    setSelected(0);
  }, [JSON.stringify(cachedResults.map(r => r.id))]);

  const handleKeyDown = (e: React.KeyboardEvent) => {
    if (e.key === 'Enter') {
      navigate(cachedResults[selected].href);
      handleClose();
    } else if (e.key === 'ArrowDown') {
      setSelected(curr => Math.min(curr + 1, cachedResults.length - 1));
      e.preventDefault();
    } else if (e.key === 'ArrowUp') {
      setSelected(curr => Math.max(curr - 1, 0));
      e.preventDefault();
    }
  };

  return (
    <DialogContent className={classes.root}>
      <div className={classes.header}>
        <InputBase
          placeholder="What are you looking for?"
          inputProps={{ autoFocus: true }}
          classes={{
            input: classes.input,
          }}
          endAdornment={
            <InputAdornment position="end">
              <Fade in={loading}>
                <CircularProgress size={20} color="inherit" />
              </Fade>
            </InputAdornment>
          }
          onChange={e => setQuery(e.target.value)}
          onKeyDown={handleKeyDown}
          value={query}
          fullWidth
          margin="none"
        />

        <StyledDivider orientation="horizontal" />

        <Fade in={cachedResults.length > 0}>
          <Box display="flex" justifyContent="space-between" paddingY={1}>
            <Box display="flex" alignItems="center" gridGap={8}>
              <ArrowUpwardIcon className={classes.keyboard} />
              <ArrowDownwardIcon className={classes.keyboard} />
              <Typography variant="body2" color="textSecondary">
                Navigate
              </Typography>
            </Box>
            <Box display="flex" alignItems="center" gridGap={8}>
              <Typography className={classes.keyboard} variant="body2">
                Enter
              </Typography>
              <Typography variant="body2" color="textSecondary">
                Open
              </Typography>
            </Box>
          </Box>
        </Fade>
      </div>

      <ResultContainer className={classes.results}>
        {cachedResults.length === 0 && query && (
          <Box
            width="100%"
            height="100%"
            display="flex"
            justifyContent="center"
            alignItems="center"
          >
            <Typography variant="body2" color="textSecondary">
              No results found for "{query}"
            </Typography>
          </Box>
        )}

        <List>
          {cachedResults.map((result, i) => (
            <ResultListItem
              key={`${result.id}:${i}`}
              selected={i === selected}
              href={result.href}
              rank={i}
            >
              <ListItemIcon>{result.icon}</ListItemIcon>
              <ListItemText
                primary={result.title}
                secondary={result.subtitle}
              />
              <Box marginLeft="auto">
                <Chip label={result.category} size="small" />
              </Box>
            </ResultListItem>
          ))}
        </List>
      </ResultContainer>
    </DialogContent>
  );
};

export const getPlatform = (): string =>
  (navigator as any).userAgentData?.platform ||
  navigator?.platform ||
  'unknown';

export const isMac = () => {
  return /MAC/.test(getPlatform().toUpperCase());
};

export const MODIFIER_SYMBOL = isMac() ? '⌘' : 'Ctrl';

export const SearchBar = () => {
  const classes = useStyles();
  const { openDialog } = useDialog();

  const handleOpenSearch = () => {
    openDialog({
      content: <SearchDialog />,
      disableBackdropClick: false,
      dialogProps: {
        maxWidth: 'md',
      },
    });
  };

  const onKeyDown = React.useCallback((data: any) => {
    if (data.key === 'k' && data.metaKey) {
      handleOpenSearch();
    }
  }, []);

  useEvent('keydown', onKeyDown);

  return (
    <Paper
      variant="outlined"
      className={classes.searchBar}
      onClick={handleOpenSearch}
    >
      <Box className={classes.searchIcon}>
        <SearchIcon color="inherit" />
      </Box>
      <Box flex={1} className={classes.hideMobile}>
        <Typography className={classes.searchLabel} variant="body2">
          Search
        </Typography>
      </Box>
      <ShortcutBase className={classes.hideMobile}>
        {MODIFIER_SYMBOL} K
      </ShortcutBase>
    </Paper>
  );
};
