import {
  WorkflowV1WorkflowInstanceModuleDataEntry,
  isType,
} from '@spotify-confidence/plugin-graphql';

import { WorkflowInstance } from '../workflowInstancePage';

export function getModuleData<T = any>(
  moduleData: WorkflowV1WorkflowInstanceModuleDataEntry[] = [],
  name: string,
): T | undefined {
  return moduleData?.find(data => data.key === name)?.value;
}

export function hasFunction(
  workflowInstance: WorkflowInstance,
  functionName: string,
) {
  const workflowVersion = isType(
    workflowInstance.workflowVersion,
    'WorkflowV1WorkflowVersion',
  )
    ? workflowInstance.workflowVersion
    : null;
  if (!workflowVersion) return false;
  return workflowVersion.workflowInfo?.functions.some(
    m => m.key === functionName,
  );
}

export function hasModule(workflowInstance: WorkflowInstance, module: string) {
  const workflowVersion = isType(
    workflowInstance.workflowVersion,
    'WorkflowV1WorkflowVersion',
  )
    ? workflowInstance.workflowVersion
    : null;
  if (!workflowVersion) return false;
  return workflowVersion.workflowInfo?.modules.some(m => m.key === module);
}
