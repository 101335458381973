import React from 'react';

import {
  Box,
  Button,
  Collapse,
  Typography,
  makeStyles,
} from '@material-ui/core';
import ArrowDownwardIcon from '@material-ui/icons/ArrowDownward';
import ExpandLess from '@material-ui/icons/ExpandLess';
import ExpandMore from '@material-ui/icons/ExpandMore';
import { Alert, AlertProps, AlertTitle } from '@material-ui/lab';

import { ObjUtils } from '@spotify-confidence/core-react';
import {
  FlagsResolverAdminV1ResolvedFlagRuleDetailsRuleEvaluationReason,
  FlagsResolverAdminV1ResolvedFlagRuleDetailsSegmentEvaluationReason,
  RuleDetailsFragment,
  getTypeOrNull,
} from '@spotify-confidence/plugin-graphql';
import classNames from 'classnames';
import { capitalize } from 'lodash';

import { CodeSnippet } from '@backstage/core-components';

import { ruleHelpers } from '../../../../rule';
import { RuleControl } from '../../../../rule/components/RulesSection/RuleCard';
import { getRuleViewComponent } from '../../../../rule/components/RulesSection/RuleCard/rules';

const getReadableText = (
  ruleEvaluationReason: FlagsResolverAdminV1ResolvedFlagRuleDetailsRuleEvaluationReason,
  segmentEvaluationReason: FlagsResolverAdminV1ResolvedFlagRuleDetailsSegmentEvaluationReason,
): {
  title: string;
  details?: string;
  severity: AlertProps['severity'];
  status: 'success' | 'fail' | 'error' | 'not evaluated';
} => {
  switch (true) {
    case ruleEvaluationReason ===
      FlagsResolverAdminV1ResolvedFlagRuleDetailsRuleEvaluationReason.RuleMatched:
      return {
        severity: 'success',
        status: 'success',
        title: 'The resolve context matched the rule’s targeting criteria',
      };

    case ruleEvaluationReason ===
      FlagsResolverAdminV1ResolvedFlagRuleDetailsRuleEvaluationReason.RuleMatchedFallthrough:
      return {
        severity: 'success',
        status: 'success',
        title:
          "The resolve context matched the rule's targeting criteria, but no variant returned.",
        details:
          'The rule uses fall-through assignment, and lets the next matching rule return a variant. For rollouts, the units in the control group get the current default experience to best mimic the difference between what they have now and the experience you are rolling out.',
      };

    case ruleEvaluationReason ===
      FlagsResolverAdminV1ResolvedFlagRuleDetailsRuleEvaluationReason.RuleNotEnabled:
      return {
        severity: 'info',
        status: 'not evaluated',
        title: 'Rule is inactive.',
      };

    case ruleEvaluationReason ===
      FlagsResolverAdminV1ResolvedFlagRuleDetailsRuleEvaluationReason.RuleNotEvaluated:
      return {
        severity: 'info',
        status: 'not evaluated',
        title: 'Another rule with higher priority matched.',
        details:
          'The rules are evaluated in the order listed on the flag page. The first matching rule returns a variant. Later rules are not evaluated.',
      };

    case ruleEvaluationReason ===
      FlagsResolverAdminV1ResolvedFlagRuleDetailsRuleEvaluationReason.SegmentNotFoundOrNotActive:
      return {
        severity: 'info',
        status: 'error',
        title: "Rule's segment not found.",
        details:
          'This is an unexpected error. Resolve again and reach out to support if the issue remains.',
      };

    case ruleEvaluationReason ===
      FlagsResolverAdminV1ResolvedFlagRuleDetailsRuleEvaluationReason.SegmentNotMatched &&
      segmentEvaluationReason ===
        FlagsResolverAdminV1ResolvedFlagRuleDetailsSegmentEvaluationReason.TargetingKeyMissing:
      return {
        severity: 'error',
        status: 'fail',
        title: 'Fields in targeting criteria missing from resolve context.',
      };

    case ruleEvaluationReason ===
      FlagsResolverAdminV1ResolvedFlagRuleDetailsRuleEvaluationReason.SegmentNotMatched &&
      segmentEvaluationReason ===
        FlagsResolverAdminV1ResolvedFlagRuleDetailsSegmentEvaluationReason.TargetingNotMatched:
      return {
        severity: 'error',
        status: 'fail',
        title:
          "Some fields in the resolve context don't match the targeting criteria.",
      };

    case ruleEvaluationReason ===
      FlagsResolverAdminV1ResolvedFlagRuleDetailsRuleEvaluationReason.SegmentNotMatched &&
      segmentEvaluationReason ===
        FlagsResolverAdminV1ResolvedFlagRuleDetailsSegmentEvaluationReason.BitsetNotMatched:
      return {
        severity: 'error',
        status: 'fail',
        title: 'Entity value not in rule allocation.',
        details:
          "Rules with less than 100% allocation don't return values for all entity values. The entity value used in this resolve did not hash into the allocation of the rule, but different entity values will.",
      };

    case ruleEvaluationReason ===
      FlagsResolverAdminV1ResolvedFlagRuleDetailsRuleEvaluationReason.MaterializationNotMatched:
      return {
        severity: 'error',
        status: 'fail',
        title: 'Entity value not in sticky assignment table.',
        details:
          "The rule has paused intake, which means that it's only serving entity values found in its sticky assignment table. The entity value in the resolve context was not found in the table.",
      };

    case ruleEvaluationReason ===
      FlagsResolverAdminV1ResolvedFlagRuleDetailsRuleEvaluationReason.MaterializationAndSegmentNotMatched &&
      segmentEvaluationReason ===
        FlagsResolverAdminV1ResolvedFlagRuleDetailsSegmentEvaluationReason.TargetingNotMatched:
      return {
        severity: 'error',
        status: 'fail',
        title:
          'Entity value not in sticky assignment table or mismatching targeting criteria.',
        details:
          'The rule has sticky assignment enabled. The resolve context must either contain an entity value already in the sticky assignment table, or match the targeting criteria of the rule. Some fields in the resolve context don’t match the targeting criteria.',
      };

    case ruleEvaluationReason ===
      FlagsResolverAdminV1ResolvedFlagRuleDetailsRuleEvaluationReason.MaterializationAndSegmentNotMatched &&
      segmentEvaluationReason ===
        FlagsResolverAdminV1ResolvedFlagRuleDetailsSegmentEvaluationReason.BitsetNotMatched:
      return {
        severity: 'error',
        status: 'fail',
        title:
          'No match in sticky assignment table and entity value not in allocation.',
        details:
          'The rule has sticky assignment enabled.The resolve context must either contain an entity value already in the sticky assignment table, or match the targeting criteria of the rule. The entity value used in this resolve did not hash into the allocation of the rule but different entity values will.',
      };

    default:
      return {
        severity: 'error',
        status: 'error',
        title: 'Resolve status unknown',
        details:
          'This is an unexpected error. Resolve again and reach out to support if the issue remains..',
      };
  }
};

const useStyles = makeStyles({
  isAfterMatched: {
    opacity: 0.4,
  },
  alert: {
    width: '100%',
  },
  alertMessage: {
    flex: 1,
  },
});

export function RuleDetail({
  isAfterMatched,
  isLastRule,
  rule: ruleDetails,
  value,
}: {
  isAfterMatched: boolean;
  isLastRule: boolean;
  rule: RuleDetailsFragment;
  value: any;
}) {
  const { ruleEvaluationReason, segmentEvaluationReason } = ruleDetails;
  const classes = useStyles();
  const [expendDetails, setExtendDetails] = React.useState(false);
  const { title, details, status, severity } = getReadableText(
    ruleEvaluationReason,
    segmentEvaluationReason,
  );
  const rule = getTypeOrNull(ruleDetails.rule, 'FlagsAdminV1FlagRule');
  if (!rule) return null;

  const workflowInstance = rule.labels.find(
    label => label.key === 'workflowInstance',
  )?.value;

  const ruleType = ruleHelpers.getRuleType(rule);
  const RuleComponent = getRuleViewComponent(ruleType);

  return (
    <Box
      className={classNames({
        [classes.isAfterMatched]: isAfterMatched,
      })}
      display="flex"
      flexDirection="column"
      alignItems="center"
    >
      <Alert
        variant="standard"
        classes={{ message: classes.alertMessage }}
        className={classes.alert}
        severity={severity}
      >
        <AlertTitle>
          {capitalize(status)}: {title}
        </AlertTitle>

        <RuleControl
          short
          workflowInstance={workflowInstance ?? undefined}
          renderRule={c => (
            <Typography variant="body1" gutterBottom>
              {c}
            </Typography>
          )}
        />

        <Box
          width="100%"
          padding={2}
          borderRadius={8}
          bgcolor="background.paper"
        >
          <RuleComponent rule={rule} />
        </Box>
        {details && (
          <>
            <Button
              variant="text"
              size="small"
              endIcon={expendDetails ? <ExpandLess /> : <ExpandMore />}
              onClick={() => setExtendDetails(v => !v)}
            >
              Show details
            </Button>
            <Collapse in={expendDetails}>
              <Typography variant="body2">{details}</Typography>
            </Collapse>
          </>
        )}
        {ruleEvaluationReason ===
          FlagsResolverAdminV1ResolvedFlagRuleDetailsRuleEvaluationReason.RuleMatched &&
          value !== null && (
            <CodeSnippet
              language="json"
              text={JSON.stringify(
                ObjUtils.deepOmit(value ?? {}, '__typename'),
                null,
                2,
              )}
            />
          )}
      </Alert>

      {isLastRule && <ArrowDownwardIcon />}
    </Box>
  );
}
