/* eslint-disable no-nested-ternary, new-cap */
import React from 'react';

import { DialogActions } from '@material-ui/core';
import { Alert } from '@material-ui/lab';

import {
  DialogBody,
  DialogForm,
  DialogHeader,
  FormSubmitButtons,
  useDialog,
} from '@spotify-confidence/core-react';
import {
  FlagsAdminV1EvaluationContextSchemaFieldKind as Kind,
  FlagsAdminV1EvaluationContextFieldOverrideInput as OverrideInput,
  getError,
  useCreateEvaluationContextFieldOverrideMutation,
} from '@spotify-confidence/plugin-graphql';

import { SchemaOverrideForm } from '../SchemaOverrideForm';
import { overrideRefetchQueries } from '../constants';

export type CreateSchemaOverrideDialogProps = {
  defaultValues?: Partial<OverrideInput>;
  existingOverrides?: string[];
};

export const CreateSchemaOverrideDialog = ({
  defaultValues,
  existingOverrides,
}: CreateSchemaOverrideDialogProps) => {
  const { closeDialog } = useDialog();
  const [override, setOverride] = React.useState<OverrideInput>({
    field: '',
    displayName: '',
    kind: Kind.StringKind,
    clients: [],
    ...defaultValues,
  });
  const [create, createResult] =
    useCreateEvaluationContextFieldOverrideMutation({
      refetchQueries: overrideRefetchQueries,
    });

  const handleCreateOverride = React.useCallback(
    (e: React.FormEvent<HTMLFormElement>) => {
      e.preventDefault();
      create({
        variables: { evaluationContextFieldOverride: override },
      });
    },
    [override],
  );

  const error =
    createResult.error ||
    getError(createResult.data?.createEvaluationContextFieldOverride);

  React.useEffect(() => {
    if (createResult.data && !error) {
      closeDialog();
    }
  }, [createResult.data, error]);

  return (
    <DialogForm onSubmit={handleCreateOverride}>
      <DialogHeader title="Create schema override" />
      <DialogBody>
        {error && <Alert severity="error">{error.message}</Alert>}
        <SchemaOverrideForm
          value={override}
          onChange={setOverride}
          existingOverrides={existingOverrides}
        />
      </DialogBody>
      <DialogActions>
        <FormSubmitButtons
          onCancel={closeDialog}
          loading={createResult.loading}
          label="Create"
        />
      </DialogActions>
    </DialogForm>
  );
};
