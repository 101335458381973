import {
  IdentityFragment,
  getTypeOrNull,
} from '@spotify-confidence/plugin-graphql';

export const getIdentityDescription = (
  identity: IdentityFragment,
  fallback: string = '(No description)',
): string => {
  return (
    getTypeOrNull(identity?.user, 'IamV1User')?.email ??
    getTypeOrNull(identity?.group, 'IamV1Group')?.description ??
    getTypeOrNull(identity?.apiClient, 'IamV1ApiClient')?.description ??
    (identity?.everyone ? 'All users in this workspace' : undefined) ??
    fallback
  );
};
