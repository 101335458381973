import {
  createComponentExtension,
  createPlugin,
} from '@backstage/core-plugin-api';

export const confidencePermissionsPlugin = createPlugin({
  id: 'confidence-permissions-react',
});

export const ResourcePermissionsButton = confidencePermissionsPlugin.provide(
  createComponentExtension({
    name: 'ResourcePermissionsButton',
    component: {
      lazy: () =>
        import('./components/ResourcePermissionsButton').then(
          m => m.ResourcePermissionsButton,
        ),
    },
  }),
);

export const ResourcePermissionGuard = confidencePermissionsPlugin.provide(
  createComponentExtension({
    name: 'ResourcePermissionGuard',
    component: {
      lazy: () =>
        import('./components/ResourcePermissionGuard').then(
          m => m.ResourcePermissionGuard,
        ),
    },
  }),
);

export const IdentityAutocomplete = confidencePermissionsPlugin.provide(
  createComponentExtension({
    name: 'IdentityAutocomplete',
    component: {
      lazy: () =>
        import('./components/IdentityAutocomplete').then(
          m => m.IdentityAutocomplete,
        ),
    },
  }),
);

export const IdentityChip = confidencePermissionsPlugin.provide(
  createComponentExtension({
    name: 'IdentityChip',
    component: {
      lazy: () => import('./components/IdentityChip').then(m => m.IdentityChip),
    },
  }),
);

export const IdentityAvatar = confidencePermissionsPlugin.provide(
  createComponentExtension({
    name: 'IdentityAvatar',
    component: {
      lazy: () =>
        import('./components/IdentityAvatar').then(m => m.IdentityAvatar),
    },
  }),
);
