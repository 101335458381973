import React from 'react';

import useDebounced, {
  ChangeHandler,
  DebouncedChangeHandler,
  ImmediateChangeHandler,
} from './useDebounced';

type InputChangeHandler = DebouncedChangeHandler<
  React.ChangeEvent<HTMLInputElement>
>;
type InputBlurHandler = ImmediateChangeHandler<
  React.FocusEvent<HTMLInputElement>
>;

const useDebouncedInputProps = <T extends string>(
  value: T,
  onChange: ChangeHandler<T>,
  delay?: number,
) => {
  const [displayValue, handleChangeDebounced, handleChangeImmediately] =
    useDebounced<T>(value, onChange, delay);

  const handleChange: InputChangeHandler = React.useCallback(
    event => handleChangeDebounced(event.target.value as T),
    [handleChangeDebounced],
  );
  const handleBlur: InputBlurHandler = React.useCallback(
    event => handleChangeImmediately(event.target.value as T),
    [handleChangeImmediately],
  );

  return React.useMemo(
    () => ({
      value: displayValue,
      onChange: handleChange,
      onBlur: handleBlur,
    }),
    [displayValue, handleChange, handleBlur],
  );
};

export default useDebouncedInputProps;
