import React from 'react';
import { useSearchParams } from 'react-router-dom';

import { useAuth } from '../behaviors';
import { LoginPage } from './index';

export const AuthorizationGate = ({
  children,
}: {
  children: React.ReactElement;
}) => {
  const {
    isAuthenticated,
    isLoading,
    loginWithPopup,
    loginWithRedirect,
    error,
  } = useAuth();
  const [searchParams] = useSearchParams();

  const shouldLogin = searchParams.get('login') === 'true';
  const organization = searchParams.get('organization') ?? undefined;

  React.useEffect(() => {
    if (!isLoading && !isAuthenticated && shouldLogin && organization) {
      loginWithRedirect({
        authorizationParams: {
          organization,
        },
        appState: {
          returnTo: window.location.pathname,
        },
      });
    }
  }, [shouldLogin, organization, isLoading, isAuthenticated]);

  if (isLoading) {
    return null;
  }

  if (!isAuthenticated) {
    return (
      <LoginPage
        onLogin={() => loginWithPopup(undefined, { timeoutInSeconds: 600 })}
        loading={isLoading}
        error={error}
      />
    );
  }

  return children;
};
