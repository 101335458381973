import { createContext, useContext } from 'react';

import { ClientFragment } from '@spotify-confidence/plugin-graphql';

export enum ProgrammingLanguage {
  js = 'js',
  js_react = 'js-react',
  swift = 'swift',
  kotlin = 'kotlin',
  java = 'java',
  go = 'go',
  python = 'python',
  rust = 'rust',
  flutter = 'flutter',
}

export const ProgrammingLanguageLabels: Record<ProgrammingLanguage, string> = {
  [ProgrammingLanguage.java]: 'Java',
  [ProgrammingLanguage.js]: 'JavaScript',
  [ProgrammingLanguage.js_react]: 'JavaScript (React)',
  [ProgrammingLanguage.swift]: 'Swift (iOS)',
  [ProgrammingLanguage.kotlin]: 'Kotlin (Android)',
  [ProgrammingLanguage.go]: 'Go',
  [ProgrammingLanguage.flutter]: 'Flutter',
  [ProgrammingLanguage.python]: 'Python',
  [ProgrammingLanguage.rust]: 'Rust',
};

export type ConnectContext = {
  language: ProgrammingLanguage;
  setLanguage: (language: ProgrammingLanguage) => void;
  client: ClientFragment | null;
  setClient: (client: ClientFragment) => void;
  flagResolved: boolean;
  setFlagResolved: (resolved: boolean) => void;
  eventPublished: boolean;
  setEventPublished: (published: boolean) => void;
};

export const connectContext = createContext<ConnectContext | null>(null);

export const ConnectProvider = connectContext.Provider;

export const useConnect = () => {
  const ctx = useContext(connectContext);
  if (!ctx) {
    throw new Error('Connect context not found');
  }
  return ctx;
};
