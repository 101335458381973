import React, { forwardRef } from 'react';

import {
  Theme,
  Tooltip,
  WithStyles,
  createStyles,
  withStyles,
} from '@material-ui/core';
import NimSymbol from '@material-ui/icons/KeyboardTab';

import classNames from 'classnames';
import * as d3 from 'd3';
import _ from 'lodash';

import { BaseTooltip } from './BaseTooltip';

export interface ConfidenceIntervalProps extends WithStyles<typeof styles> {
  lowerCI?: number;
  upperCI?: number;
  observedDifference?: number;
  minLower?: number;
  maxUpper?: number;
  unit?: string;
  nim?: number;
  mde?: number;
  significant?: boolean | null;
  direction?: 'desired' | 'undesired';

  // Styling
  className?: string;
  height?: number;
  width?: number;
  padding?: number;
}

const styles = (theme: Theme) =>
  createStyles<string, ConfidenceIntervalProps>({
    root: {},

    container: {
      position: 'relative',
      height: 48,
    },

    axis: {
      position: 'absolute',
      left: 0,
      right: 0,
      top: '50%',
      transform: 'translateY(-50%)',
      borderTop: `1px solid ${theme.palette.text.secondary}`,
    },

    zero: {
      position: 'absolute',
      top: 0,
      bottom: 0,
      transform: 'translateX(-50%)',
      width: 1,
      borderLeft: `1px solid ${theme.palette.text.secondary}`,
      left: '50%', // default
    },

    difference: {
      position: 'absolute',
      top: '50%',
      transform: 'translate(-50%, -50%)',
      width: 6,
      height: 6,
      borderRadius: '50%',
      background: theme.palette.background.default,
      border: `1px solid ${theme.palette.text.primary}`,
      left: '50%', // default
    },

    nim: ({ nim = 0, significant }) => ({
      position: 'absolute',
      top: '50%',
      transform: `translateY(-50%) ${nim > 0 ? 'rotate(180deg)' : ''}`,
      color: significant
        ? theme.palette.text.secondary
        : theme.palette.warning.light,
      transformOrigin: 'center',
    }),

    interval: ({ upperCI = 0, lowerCI = 0, significant }) => ({
      position: 'absolute',
      height: '60%',
      top: '20%',
      borderRadius: theme.shape.borderRadius / 2,

      color: significant
        ? theme.palette.text.primary
        : theme.palette.text.secondary,

      // eslint-disable-next-line no-nested-ternary
      background: isInfinity(lowerCI)
        ? `linear-gradient(to right, rgba(0, 0, 0, 0) 0%, currentColor 50%, currentColor 100%)`
        : isInfinity(upperCI)
        ? `linear-gradient(to left, rgba(0, 0, 0, 0) 0%, currentColor 50%, currentColor 100%)`
        : 'currentColor',
    }),
  });

function isInfinity(number: number) {
  return number === Infinity || number === -Infinity;
}

const pct = (value: number) => `${value}%`;

const ConfidenceInterval = forwardRef<HTMLDivElement, ConfidenceIntervalProps>(
  function ConfidenceInterval(props, ref) {
    const {
      className,
      classes,
      lowerCI = 0,
      upperCI = 0,
      observedDifference,
      minLower = lowerCI,
      maxUpper = upperCI,
      nim,
      significant,
      unit,
    } = props;

    // domain: value (for example %), range: width (pixels)
    const scale = d3.scaleLinear().domain([minLower, maxUpper]).range([0, 100]);

    const lowerIsInfinite = isInfinity(lowerCI);
    const upperIsInfinite = isInfinity(upperCI);

    const scaledX = lowerIsInfinite ? 0 : Math.max(scale(lowerCI), 0);
    const scaledWidth = upperIsInfinite
      ? 100 - scaledX
      : Math.min(scale(upperCI) - scaledX, 100 - scaledX);

    const nimPosition = nim && nim !== 0 ? scale(nim) : 0;
    const iconSize = 24;

    let zeroPosition = scale(0);

    if (maxUpper < 0) {
      zeroPosition = scale(maxUpper);
    } else if (minLower > 0) {
      zeroPosition = scale(minLower);
    }

    return (
      <div className={classNames(classes.root, className)} ref={ref}>
        <div className={classes.container}>
          <div className={classes.axis} />
          <div className={classes.zero} style={{ left: pct(zeroPosition) }} />
          <Tooltip
            title={
              <BaseTooltip lowerCI={lowerCI} upperCI={upperCI} unit={unit} />
            }
          >
            <div
              className={classNames(classes.interval, {
                [classes.upperInfinite]: upperIsInfinite,
                [classes.lowerInfinite]: lowerIsInfinite,
              })}
              style={{ left: pct(scaledX), width: pct(scaledWidth) }}
            />
          </Tooltip>
          {/* FIXME: inf */}
          {!_.isNil(nim) && (
            <NimSymbol
              style={{
                left:
                  nim > 0
                    ? pct(nimPosition)
                    : `calc(${nimPosition}% - ${iconSize}px)`,
              }}
              className={classNames(classes.nim, {
                [classes.positiveNim]: nim > 0,
                [classes.overlappingNim]: !significant,
              })}
            />
          )}
          {!_.isNil(observedDifference) && (
            <div
              className={classes.difference}
              style={{ left: pct(scale(observedDifference)) }}
            />
          )}
        </div>
      </div>
    );
  },
);

export default withStyles(styles)(ConfidenceInterval);
