import { Theme } from '@material-ui/core';

import { ResultData } from '../types';

export function getResultColor(row: ResultData, theme: Theme) {
  if (row.isSignificant) {
    if (row.direction === 'undesired') {
      return theme.palette.error.main;
    }
    return theme.palette.text.primary;
  }
  return theme.palette.text.disabled;
}
