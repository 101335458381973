/* eslint-disable no-nested-ternary */
import React from 'react';
import { useEffectOnce } from 'react-use';

import {
  Box,
  Collapse,
  DialogActions,
  IconButton,
  ListItemText,
  MenuItem,
  TextField,
} from '@material-ui/core';
import OpenInNewIcon from '@material-ui/icons/OpenInNew';

import {
  ConfidenceAutocomplete,
  DialogBody,
  DialogForm,
  DialogHeader,
  FormSubmitButtons,
  PercentageInput,
  PercentageInputProps,
  RequiredResourceAutocomplete,
  extractLastNameComponent,
  useDialog,
  useDisplayNames,
} from '@spotify-confidence/core-react';
import {
  MetricEntityFragment,
  MetricsV1MetricPreferredDirection,
  SelectableMetricFragment,
  getTypeOrNull,
  isType,
  useAddMetricRowQuery,
  useMetricConfigEntitiesLazyQuery,
} from '@spotify-confidence/plugin-graphql';
import _ from 'lodash';

import {
  featureFlagsApiRef,
  useApi,
  useRouteRef,
} from '@backstage/core-plugin-api';

import { mapKeyTypeLabel } from '../entities/domain/useFormattedEntityType.hook';
import { metricRouteRef, metricsRouteRef } from '../routes';
import {
  isGuardrailMetric,
  isSuccessMetric,
  toNonDesiredDirection,
  toPreferredDirection,
} from './helpers';
import { Metric, MetricDirection, MetricInput, MetricKind } from './types';

const DEFAULT_NIM = 0.03;
const DEFAULT_MDE = 0.03;

interface SharedProps {
  onSubmit: (v: Metric) => Promise<any>;
}

type MetricType = 'success' | 'guardrail';

interface CreateMetricProps extends SharedProps {
  entity?: string;
  allowedMetricTypes?: MetricType[];
  allowSetEffect?: boolean;
  existingMetrics: string[];
  metric?: Metric;
}

interface EditMetricProps extends SharedProps {
  entity?: undefined;
  allowedMetricTypes?: MetricType[];
  allowSetEffect?: boolean;
  existingMetrics?: string[];
  metric: Metric;
}

export type MetricDialogProps = CreateMetricProps | EditMetricProps;

function translateDirection(
  d?: MetricsV1MetricPreferredDirection | null,
): MetricDirection {
  if (d === MetricsV1MetricPreferredDirection.Decrease) {
    return MetricDirection.DECREASE;
  } else if (d === MetricsV1MetricPreferredDirection.Increase) {
    return MetricDirection.INCREASE;
  }
  return MetricDirection.INCREASE;
}

export const MetricDialog = ({
  metric: metricProp,
  onSubmit,
  entity,
  allowSetEffect,
  allowedMetricTypes = ['success', 'guardrail'],
  existingMetrics = [],
}: MetricDialogProps) => {
  const editMode = !!metricProp;
  const { closeDialog } = useDialog();
  const [selectedEntity, setSelectedEntity] = React.useState<
    string | undefined
  >(entity);
  const [loading, setLoading] = React.useState<boolean>(false);
  const [metric, setMetric] = React.useState<MetricInput>({
    metric: metricProp?.metric || '',
    metricRole: metricProp?.metricRole,
    preferredDirection:
      metricProp?.preferredDirection || MetricDirection.INCREASE,
    schedule: metricProp?.schedule || '',
  });

  const updateMetric = React.useCallback(
    (newValues: Partial<MetricInput>) => {
      setMetric(v => ({
        ...v,
        ...newValues,
      }));
    },
    [setMetric],
  );

  const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    (async () => {
      setLoading(true);
      await onSubmit(metric as Metric);
      setLoading(false);
      closeDialog();
    })();
  };

  const handleMetricSelect = (m?: SelectableMetricFragment | null) => {
    updateMetric({
      metric: m?.name,
      preferredDirection: translateDirection(m?.preferredDirection),
      metricRole: {
        ...metric.metricRole,
        ...(isSuccessMetric(metric)
          ? {
              metricKind: MetricKind.SUCCESS,
              minimumDetectableEffect: m?.defaultEffectSize?.value
                ? Number(m.defaultEffectSize.value)
                : DEFAULT_MDE,
            }
          : {}),
        ...(isGuardrailMetric(metric)
          ? {
              metricKind: MetricKind.GUARDRAIL,
              nonInferiorityMargin: m?.defaultEffectSize?.value
                ? Number(m.defaultEffectSize.value)
                : DEFAULT_MDE,
            }
          : {}),
      },
    });
  };

  const title = metricProp ? <MetricTitle metric={metric} /> : 'Add metric';
  const isMetricTypeSelected =
    isGuardrailMetric(metric) || isSuccessMetric(metric);
  return (
    <DialogForm onSubmit={handleSubmit}>
      <DialogHeader title={title} />
      <DialogBody>
        {!editMode && (
          <>
            <MetricTypePicker
              allowedMetricTypes={allowedMetricTypes}
              onChange={updateMetric}
            />
            {entity === undefined && (
              <ConfidenceAutocomplete<MetricEntityFragment>
                query={useMetricConfigEntitiesLazyQuery}
                data-testid="entity-select"
                onChange={(_e, newEntity) =>
                  setSelectedEntity(newEntity ?? undefined)
                }
                value={selectedEntity}
                label="Entity"
                placeholder="Select entity"
                required
                helperText="The entity determines who to calculate metrics
                  for, and is typically the same as the randomization unit."
                renderOption={(entityItem, { inputValue, ...state }) => (
                  <div {...state}>
                    <ListItemText
                      primary={entityItem.displayName}
                      secondary={mapKeyTypeLabel(entityItem.primaryKeyType)}
                    />
                  </div>
                )}
              />
            )}
            <Collapse
              in={isMetricTypeSelected && selectedEntity !== undefined}
              unmountOnExit
            >
              <Box mb={1}>
                <MetricPicker
                  existingMetrics={existingMetrics}
                  onChange={handleMetricSelect}
                  value={metric.metric}
                  entity={selectedEntity!}
                />
              </Box>
            </Collapse>
          </>
        )}
        {isMetricTypeSelected && (editMode || selectedEntity !== undefined) && (
          <>
            <Box mb={2} mt={1}>
              <DirectionField
                metric={metric}
                onChange={preferredDirection =>
                  updateMetric({ preferredDirection })
                }
              />
            </Box>
            {allowSetEffect !== false &&
              (isSuccessMetric(metric) || isGuardrailMetric(metric)) && (
                <Box mb={2}>
                  <PercentageField
                    metric={metric}
                    onChange={percentage => updateMetric(percentage)}
                  />
                </Box>
              )}
          </>
        )}
      </DialogBody>
      <DialogActions>
        <FormSubmitButtons
          onCancel={closeDialog}
          loading={loading}
          disabled={!isMetricTypeSelected}
          label={editMode ? 'Save' : 'Add'}
        />
      </DialogActions>
    </DialogForm>
  );
};

function MetricTitle({ metric }: { metric?: MetricInput }) {
  const metricRoute = useRouteRef(metricRouteRef);
  const { displayNames } = useDisplayNames();

  const metricId = extractLastNameComponent(metric?.metric);
  return (
    <>
      {metric?.metric
        ? displayNames.get(metric.metric) || metric.metric
        : undefined}
      {metricId && (
        <IconButton
          size="small"
          onClick={() =>
            window.open(
              metricRoute?.({
                id: metricId,
              }),
              '_blank',
            )
          }
        >
          <OpenInNewIcon fontSize="small" />
        </IconButton>
      )}
    </>
  );
}

function MetricPicker({
  value,
  existingMetrics,
  entity,
  onChange,
}: {
  existingMetrics: string[];
  value?: string | null;
  entity: string;
  onChange: (metric?: SelectableMetricFragment | null) => void;
}) {
  const metricsRoute = useRouteRef(metricsRouteRef);
  const featureFlags = useApi(featureFlagsApiRef);
  const { data, loading, error, refetch } = useAddMetricRowQuery({
    fetchPolicy: 'cache-and-network',
    variables: {
      entity: entity,
    },
  });

  const additionalAllowedMetricEntities = featureFlags.isActive(
    'entity-relation-tables',
  )
    ? (
        getTypeOrNull(
          data?.entityRelationTables,
          'MetricsV1ListEntityRelationTablesResponse',
        )?.entityRelationTables || []
      ).map(
        entityRelationTable =>
          getTypeOrNull(entityRelationTable.targetEntity, 'MetricsV1Entity')
            ?.name,
      )
    : [];

  const metricsOptions = (
    getTypeOrNull(data?.metrics, 'MetricsV1ListMetricsResponse')?.metrics || []
  ).filter(
    m =>
      isType(m.entity, 'MetricsV1Entity') &&
      (m.entity.name === entity ||
        additionalAllowedMetricEntities.includes(m.entity.name)) &&
      !existingMetrics.includes(m.name),
  );
  const selectedMetric = metricsOptions.find(o => o.name === value) || null;

  return (
    <RequiredResourceAutocomplete
      data-testid="metric-select"
      error={error}
      options={metricsOptions}
      onChange={(_e, v) => onChange(v)}
      value={selectedMetric}
      loading={loading}
      errorTitle="Could not get metrics"
      emptyTitle="There are no metrics for this entity"
      emptyDescription="Metrics are required to determine the success of a test."
      createLink={metricsRoute?.()}
      createText="Create a metric"
      label="Metric"
      required
      placeholder="Select metric"
      getOptionLabel={f => f.displayName}
      refetch={refetch}
    />
  );
}

function DirectionField({
  metric,
  onChange,
}: {
  metric: MetricInput;
  onChange: (preferredDirection: MetricDirection) => void;
}) {
  const preferredDirectionProps = React.useMemo(
    () => ({
      value: (() => {
        if (metric.preferredDirection) {
          if (isGuardrailMetric(metric)) {
            return toNonDesiredDirection(metric.preferredDirection);
          }
          return metric.preferredDirection;
        }
        return undefined;
      })(),
      label: isGuardrailMetric(metric)
        ? 'Non-desired direction'
        : 'Preferred direction',
      onChange: (e: React.ChangeEvent<HTMLInputElement>) => {
        const preferredDirection = isGuardrailMetric(metric)
          ? toPreferredDirection(e.target.value as MetricDirection)
          : (e.target.value as MetricDirection);
        onChange(preferredDirection);
      },
    }),
    [metric, onChange],
  );

  return (
    <TextField
      fullWidth
      variant="outlined"
      margin="dense"
      select
      required
      {...preferredDirectionProps}
    >
      <MenuItem value={MetricDirection.INCREASE}>Increase</MenuItem>
      <MenuItem value={MetricDirection.DECREASE}>Decrease</MenuItem>
    </TextField>
  );
}

function PercentageField({
  metric,
  onChange,
  required,
}: {
  metric: MetricInput;
  onChange: (value: Partial<MetricInput>) => void;
  required?: boolean;
}) {
  const scale = (number: number) => number / 100;
  const percentageInputProps: PercentageInputProps = React.useMemo(() => {
    if (isGuardrailMetric(metric)) {
      return {
        value: metric.metricRole.nonInferiorityMargin
          ? metric.metricRole.nonInferiorityMargin * 100
          : undefined,
        label: 'Non-inferiority margin',
        key: 'nim',
        required,
        onChange: (v?: number) =>
          onChange({
            metricRole: {
              metricKind: MetricKind.GUARDRAIL,
              nonInferiorityMargin: _.isNumber(v) ? scale(v) : undefined,
            },
          }),
      };
    }
    if (isSuccessMetric(metric)) {
      return {
        required,
        value: metric.metricRole.minimumDetectableEffect
          ? metric.metricRole.minimumDetectableEffect * 100
          : undefined,
        label: 'Minimum detectable effect',
        key: 'mde',
        onChange: (v?: number) => {
          onChange({
            metricRole: {
              metricKind: MetricKind.SUCCESS,
              minimumDetectableEffect: _.isNumber(v) ? scale(v) : undefined,
            },
          });
        },
      };
    }
    // we dont support secondary metrics yet, but we need to return something
    return {
      value: 0,
      label: 'Change',
      onChange: () => {},
    };
  }, [metric, onChange, required]);

  return (
    <PercentageInput
      fullWidth
      margin="dense"
      variant="outlined"
      {...percentageInputProps}
    />
  );
}

function MetricTypePicker({
  onChange,
  allowedMetricTypes,
}: {
  allowedMetricTypes: MetricType[];
  onChange: (v: Partial<MetricInput>) => void;
}) {
  useEffectOnce(() => {
    // do nothing if we allow more than one type
    if (allowedMetricTypes.length !== 1) return;

    if (allowedMetricTypes[0] === 'guardrail') {
      onChange({
        metricRole: {
          metricKind: MetricKind.GUARDRAIL,
          nonInferiorityMargin: DEFAULT_NIM,
        },
      });
    }

    if (allowedMetricTypes[0] === 'success') {
      onChange({
        metricRole: {
          metricKind: MetricKind.SUCCESS,
          minimumDetectableEffect: DEFAULT_MDE,
        },
      });
    }
  });

  // nothing to pick
  if (allowedMetricTypes.length === 1) return null;

  return (
    <Box mb={4}>
      <TextField
        select
        SelectProps={{ displayEmpty: true }}
        fullWidth
        required
        margin="dense"
        variant="outlined"
        name="metric-type-select"
        defaultValue=""
        onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
          const newValue = e.target.value;
          if (newValue === 'success') {
            onChange({
              metricRole: {
                metricKind: MetricKind.SUCCESS,
                minimumDetectableEffect: DEFAULT_MDE,
              },
            });
          } else {
            onChange({
              metricRole: {
                metricKind: MetricKind.GUARDRAIL,
                nonInferiorityMargin: DEFAULT_NIM,
              },
            });
          }
        }}
      >
        <MenuItem value="" disabled>
          Select metric type...
        </MenuItem>
        <MenuItem value="success">Success metric</MenuItem>
        <MenuItem value="guardrail">Guardrail metric</MenuItem>
      </TextField>
    </Box>
  );
}
