import React, { PropsWithChildren } from 'react';

import { Tooltip } from '@material-ui/core';
import MenuIcon from '@material-ui/icons/Menu';

import {
  FeatureFlagged,
  useAuth,
  useLocation,
} from '@spotify-confidence/core-react';
import { BillingBanner } from '@spotify-confidence/plugin-admin';
import { useAccountMode } from '@spotify-confidence/plugin-graphql';
import { useConfidence } from '@spotify-confidence/react';
import { IconDeviceComputer } from '@spotify-internal/encore-web';

import {
  Sidebar,
  SidebarDivider,
  SidebarGroup,
  SidebarItem,
  SidebarPage,
  SidebarSpace,
  useSidebarPinState,
} from '@backstage/core-components';
import { IconComponent } from '@backstage/core-plugin-api';
import { UserSettingsSignInAvatar } from '@backstage/plugin-user-settings';

import { HEADER_HEIGHT, Header } from './Header';
import { ModuleIcons } from './ModuleIcons';
import { ProfileMenu } from './ProfileMenu';

const TooltipSidebarItem = ({
  text = '',
  ...props
}: React.ComponentProps<typeof SidebarItem>) => {
  const { isPinned } = useSidebarPinState();
  return (
    <Tooltip title={isPinned ? '' : text} placement="right" arrow>
      <SidebarItem text={text} {...props} />
    </Tooltip>
  );
};

const NO_SIDEBAR_PAGES = ['/invitation', '/onboarding', '/signup', '/beta'];

export type MenuItem = {
  label: string;
  to: string;
  icon?: IconComponent;
  children?: MenuItem[];
};

export const isMenuItem = (
  value?: MenuItem | React.ReactNode,
): value is MenuItem => (value ? value.hasOwnProperty('to') : false);

export const getMenuLinks = ({
  isTotalConfidence,
  reanalysisEnabled,
}: {
  isTotalConfidence: boolean;
  reanalysisEnabled: boolean;
}): (MenuItem | React.ReactNode)[] => {
  return [
    {
      label: 'A/B Tests',
      to: '/workflows/abtest',
      icon: ModuleIcons.abtests,
    },
    {
      label: 'Rollouts',
      to: '/workflows/rollout',
      icon: ModuleIcons.rollouts,
    },
    ...(!isTotalConfidence
      ? [
          {
            label: 'Analyses',
            to: '/workflows/analysis',
            icon: ModuleIcons.analysis,
          },
        ]
      : []),
    ...(!isTotalConfidence && reanalysisEnabled
      ? [
          {
            label: 'Reanalyses',
            to: '/workflows/reanalysis',
            icon: ModuleIcons.analysis,
          },
        ]
      : []),
    <SidebarDivider />,
    {
      label: 'Flags',
      to: '/flags',
      icon: ModuleIcons.flags,
    },
    {
      label: 'Segments',
      to: '/flags/segments',
      icon: ModuleIcons.segments,
    },
    <SidebarDivider />,
    {
      label: 'Metrics',
      to: '/metrics',
      icon: ModuleIcons.metrics,
      children: [
        {
          label: 'Metrics',
          to: '/metrics',
        },
        {
          label: 'Assignment tables',
          to: '/metrics/assignment-tables',
        },
        {
          label: 'Fact tables',
          to: '/metrics/fact-tables',
        },
        {
          label: 'Dimension tables',
          to: '/metrics/dimension-tables',
        },
        {
          label: 'Entities',
          to: '/metrics/entities',
        },
      ],
    },
    {
      label: 'Events',
      to: '/events',
      icon: ModuleIcons.events,
    },
    <SidebarDivider />,
    {
      icon: ModuleIcons.surfaces,
      to: '/surfaces',
      label: 'Surfaces',
    },
  ];
};

export const Root = ({ children }: PropsWithChildren<{}>) => {
  const { isAuthenticated } = useAuth();
  const location = useLocation();
  const { isTotalConfidence } = useAccountMode();
  const reanalysisEnabled = useConfidence().useFlag(
    'reanalysis.enabled',
    false,
  );

  if (
    !isAuthenticated ||
    NO_SIDEBAR_PAGES.some(page => location.pathname.startsWith(page))
  )
    return <>{children}</>;

  return (
    <>
      <Header to="/" />
      <BillingBanner headerHeight={HEADER_HEIGHT} />
      <SidebarPage>
        <Sidebar disableExpandOnHover>
          <SidebarGroup label="Menu" icon={<MenuIcon />}>
            {getMenuLinks({ isTotalConfidence, reanalysisEnabled }).map(
              (m, i) => {
                if (isMenuItem(m)) {
                  return (
                    <TooltipSidebarItem
                      key={m.to}
                      icon={m.icon ?? ModuleIcons.abtests}
                      to={m.to}
                      text={m.label}
                    />
                  );
                }
                return (
                  <React.Fragment key={`menu-item-${i}`}>{m}</React.Fragment>
                );
              },
            )}

            <FeatureFlagged with="insights">
              <SidebarDivider />
              <TooltipSidebarItem
                icon={ModuleIcons.insights}
                to="/insights"
                text="Insights"
              />
              <SidebarDivider />
            </FeatureFlagged>
          </SidebarGroup>

          <SidebarSpace />
          <SidebarGroup label="Settings" icon={<UserSettingsSignInAvatar />}>
            <TooltipSidebarItem
              text="Admin"
              to="/admin"
              icon={IconDeviceComputer}
            />
            <SidebarDivider />
            <ProfileMenu />
          </SidebarGroup>
        </Sidebar>
        {children}
      </SidebarPage>
    </>
  );
};
