export type NumberFormatter = Intl.NumberFormat['format'];

// initialize constructors with pre-specified options for consistency and performance
const makeNumberFormatter = (options?: object): NumberFormatter =>
  new Intl.NumberFormat('en-US', options).format;
const makeNumberFormatterFixed = (decimals: number) =>
  makeNumberFormatter({
    minimumFractionDigits: decimals,
    maximumFractionDigits: decimals,
  });

export const formatNumber: NumberFormatter = makeNumberFormatter();
export const formatNumberFixed2: NumberFormatter = makeNumberFormatterFixed(2);
export const formatNumberFixed4: NumberFormatter = makeNumberFormatterFixed(4);
export const formatNumberFixed8: NumberFormatter = makeNumberFormatterFixed(8);
export const formatNumberCompact: NumberFormatter = makeNumberFormatter({
  notation: 'compact',
});

export const formatNumberAbbreviation = (value: number) => {
  // Stolen and altered from:
  // https://stackoverflow.com/questions/10599933/convert-long-number-into-abbreviated-string-in-javascript-with-a-special-shortn
  if (value >= 1000) {
    const charactersAllowed = 4;
    const suffixes = ['', 'K', 'M', 'B'];
    let suffixNum = Math.min(
      Math.ceil(value.toString().length / 3) - 1,
      suffixes.length - 1,
    );
    let shortValue = 0;
    for (let precision = 3; precision >= 1; precision--) {
      shortValue = parseFloat(
        (suffixNum !== 0
          ? value / Math.pow(1000, suffixNum)
          : value
        ).toPrecision(precision),
      );
      const dotLessShortValue = shortValue
        .toString()
        .replace(/[^a-zA-Z 0-9]+/g, '');
      if (dotLessShortValue.length <= charactersAllowed - 1) {
        break;
      }
    }
    while (shortValue % 1000 === 0 && suffixNum < suffixes.length - 1) {
      shortValue = shortValue / 1000;
      suffixNum++;
    }
    return shortValue + suffixes[suffixNum];
  }
  return value.toString();
};
