import React from 'react';

import { WithStyles, createStyles, withStyles } from '@material-ui/core';

import classNames from 'classnames';

import {
  ResponsiveTimelineChart,
  TimelineChartProps,
} from '../MetricResultTable';
import { useMetricResultContext } from './context';

const styles = createStyles({
  root: {},
});

export interface GraphProps
  extends WithStyles<typeof styles>,
    Omit<TimelineChartProps, 'results' | 'bucket'> {
  className?: string;
  component?: React.ElementType<{ className?: string }>;
}

const Graph = ({
  classes,
  className,
  component: Component = 'div',
  ...rest
}: GraphProps) => {
  const { value: result, results, bucket } = useMetricResultContext();
  const errors =
    result?.annotations?.filter(annotation => annotation.error) ?? [];
  if (errors.length > 0) {
    return null;
  }
  return (
    <Component className={classNames(classes.root, className)}>
      <ResponsiveTimelineChart results={results} bucket={bucket} {...rest} />
    </Component>
  );
};

export default withStyles(styles)(Graph);
