import React from 'react';
import {
  useLocation,
  useNavigate,
  useParams,
  useSearchParams,
} from 'react-router-dom';

import { Button, Typography } from '@material-ui/core';
import Add from '@material-ui/icons/Add';

import {
  ActionBar,
  Breadcrumbs,
  FilterBar,
  FilterListProvider,
  PageLayout,
  createHeaderTab,
  useDialog,
  useMeasureDimensions,
} from '@spotify-confidence/core-react';
import {
  getTypeOrNull,
  useGetCurrentAccountAutojoinQuery,
} from '@spotify-confidence/plugin-graphql';
import { useCheckResourcePermissions } from '@spotify-confidence/plugin-permissions-react';

import { HeaderTabs, Link, Tab } from '@backstage/core-components';
import { useRouteRef } from '@backstage/core-plugin-api';

import {
  invitationsRouteRef,
  rootRouteRef,
  usersRouteRef,
} from '../../../routes';
import { CreateInviteDialog } from './CreateInviteDialog';
import { InviteList } from './InviteList';
import { UserDialog } from './UserDialog';
import { UserList } from './UserList';

export const UserIndexPage = () => {
  const { id } = useParams();
  const location = useLocation();
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const { openDialog, closeDialog } = useDialog();
  const { allowed: canInvite } = useCheckResourcePermissions({
    name: 'account',
    can: 'create_user_invitation',
  });

  const { data } = useGetCurrentAccountAutojoinQuery();

  const currentAccount = getTypeOrNull(
    data?.currentAccount,
    'AdminV1AccountInfo',
  );

  const hasAutoJoin = currentAccount?.authConnections.some(
    connection => connection.autoJoin,
  );
  const allowedLoginEmailDomains = currentAccount?.allowedLoginEmailDomains;

  const adminRoute = useRouteRef(rootRouteRef);
  const activeUsersRoute = useRouteRef(usersRouteRef);
  const invitationsRoute = useRouteRef(invitationsRouteRef);

  const [tabsRef, { height: tabsHeight }] =
    useMeasureDimensions<HTMLDivElement>();
  const [actionBarRef, { height: actionBarHeight }] =
    useMeasureDimensions<HTMLDivElement>();

  const buildUrl = (base: string) => `${base}?${searchParams.toString()}`;

  const tabs: Tab[] = [
    createHeaderTab({
      id: 'users',
      label: 'Active users',
      to: buildUrl(activeUsersRoute()),
    }),
    createHeaderTab({
      id: 'invitations',
      label: 'Pending invitations',
      to: buildUrl(invitationsRoute()),
    }),
  ];

  const tabIndex = location.pathname.includes('invitations') ? 1 : 0;
  const tab = tabs[tabIndex].id;

  const handleCreateInvitation = () => {
    openDialog({
      content: (
        <CreateInviteDialog
          onCreated={() => {
            if (tab !== 'invitations') {
              navigate(buildUrl(invitationsRoute()));
            }
          }}
          hasAutoJoin={hasAutoJoin}
          allowedLoginEmailDomains={allowedLoginEmailDomains}
        />
      ),
    });
  };
  React.useEffect(() => {
    if (id) {
      openDialog({ content: <UserDialog id={id} /> });
    } else {
      closeDialog();
    }
  }, [id]);

  return (
    <FilterListProvider storageKey="users">
      <PageLayout
        narrow
        title="Users"
        headerBreadcrumbs={
          <Breadcrumbs>
            <Link to={adminRoute()}>Admin</Link>
            <Typography>Users</Typography>
          </Breadcrumbs>
        }
        headerTabs={<HeaderTabs selectedIndex={tabIndex} tabs={tabs} />}
        headerTabsRef={tabsRef}
      >
        <ActionBar ref={actionBarRef} topOffset={tabsHeight}>
          <FilterBar />
          <Button
            startIcon={<Add />}
            variant="outlined"
            size="small"
            data-testid="invite-user-button"
            onClick={handleCreateInvitation}
            disabled={!canInvite}
          >
            Invite a colleague
          </Button>
        </ActionBar>
        {tab === 'invitations' ? (
          <InviteList topOffset={actionBarHeight + tabsHeight} />
        ) : (
          <UserList topOffset={actionBarHeight + tabsHeight} />
        )}
      </PageLayout>
    </FilterListProvider>
  );
};
