import React from 'react';

import { Paper } from '@material-ui/core';
import { grey } from '@material-ui/core/colors';
import { StyleRules, Theme, alpha } from '@material-ui/core/styles';
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';

import {
  blue100,
  brightRed100,
  desktopAlto,
  desktopBallad,
  desktopBalladBold,
  desktopBrio,
  desktopCanon,
  desktopCello,
  desktopFinale,
  desktopForte,
  desktopMesto,
  desktopMestoBold,
  gold155,
  mobileAlto,
  mobileBallad,
  mobileBalladBold,
  mobileBrio,
  mobileCanon,
  mobileCello,
  mobileFinale,
  mobileForte,
  mobileMesto,
  mobileMestoBold,
  solidBlack0,
  solidWhite200,
  spacer4,
  spacer8,
  spacer12,
  spacer16,
  spacer20,
  spacer24,
  spacer32,
  spacer56,
  spotifyGreen157,
} from '@spotify-internal/encore-foundation';
import { IconChevronRight } from '@spotify-internal/encore-web';
import '@spotify-internal/encore-web/css/fonts.css';

import { BackstageOverrides } from '@backstage/core-components';
import {
  BackstageTheme,
  PageTheme,
  createTheme,
  darkTheme,
  pageTheme as defaultPageThemes,
  lightTheme,
} from '@backstage/theme';

import { BuildThemeOptions } from './types';

export const buildEncoreTheme = (
  options: BuildThemeOptions,
): BackstageTheme => {
  const {
    palette = {},
    type = 'light',
    activeRating,
    outlinedHover,
    outlinedResting,
    snackbarBackground,
  } = options;
  const pageThemesFontColorOverride: Record<string, PageTheme> = {};
  Object.keys(defaultPageThemes).map(key => {
    pageThemesFontColorOverride[key] = {
      ...defaultPageThemes[key],
      fontColor: palette.text?.primary || solidBlack0,
    };
  });

  const base: Theme = type === 'dark' ? darkTheme : lightTheme;
  const baseTheme = createTheme({
    palette: {
      ...base.palette,
      ...palette,
      status: {
        ok: type === 'dark' ? spotifyGreen157 : '#169B45',
        warning: type === 'dark' ? gold155 : '#C77200',
        error: brightRed100,
        running: blue100,
        aborted: palette.text?.disabled || base.palette.status.aborted,
        pending: palette.text?.disabled || base.palette.status.pending,
        ...palette.status,
      },
      primary: {
        main: spotifyGreen157,
        light: '#1FDF64',
        dark: type === 'dark' ? spotifyGreen157 : '#169B45',
        contrastText: solidBlack0,
        ...palette.primary,
      },
      error: {
        main: brightRed100,
        dark: brightRed100,
        light: '#DE1327',
        contrastText: solidWhite200,
        ...palette.error,
      },
      warning: {
        main: gold155,
        dark: type === 'dark' ? gold155 : '#C77200',
        light: '#FFAF45',
        contrastText: solidBlack0,
        ...palette.warning,
      },
      info: {
        main: blue100,
        dark: blue100,
        light: '#0C6DDF',
        contrastText: solidWhite200,
        ...palette.info,
      },
      success: {
        main: spotifyGreen157,
        dark: type === 'dark' ? spotifyGreen157 : '#169B45',
        light: '#1FDF64',
        contrastText: solidBlack0,
        ...palette.success,
      },
    },
    fontFamily: desktopBallad.fontFamily,
    pageTheme: pageThemesFontColorOverride,
    defaultPageTheme: 'home',
  });

  const createCustomThemeOverrides = (
    theme: BackstageTheme,
  ): BackstageOverrides & Partial<StyleRules> => {
    const outlinedButton = {
      padding: [spacer12, spacer24].join(' '),
      backgroundColor: 'transparent',
      color: theme.palette.text.primary,
      borderColor: outlinedResting || theme.palette.text.secondary,
      '&:hover': {
        borderColor: theme.palette.text.primary,
        backgroundColor:
          theme.palette.type === 'dark'
            ? 'rgba(255,255,255,0.03)'
            : 'rgba(0, 0, 0, 0.04)',
      },
      '&.Mui-disabled': {
        borderColor: theme.palette.text.primary,
        backgroundColor: 'transparent',
        opacity: 0.3,
      },
    };

    const textButton = {
      padding: [spacer12, 0].join(' '),
      color: theme.palette.text.secondary,
      '&:hover': {
        color: theme.palette.text.primary,
        backgroundColor: 'transparent',
      },
      '&:after': {
        width: '100%',
        height: 1,
        borderWidth: 0,
        borderBottomWidth: 3,
        bottom: 0,
        top: 'unset',
        left: 'unset',
      },
      '&.Mui-focusVisible:after': {
        borderColor: 'inherit',
      },
    };

    return {
      BackstageHeader: {
        header: {
          backgroundImage: 'unset',
          boxShadow: 'unset',
          color: theme.page.fontColor,
          paddingBottom: theme.spacing(1),
          marginLeft: 'auto',
          marginRight: 'auto',
          maxWidth: theme.breakpoints.values.xl,
          [theme.breakpoints.down('md')]: {
            flexWrap: 'nowrap',
          },
        },
        breadcrumb: {
          marginBottom: theme.spacing(2),
        },
        title: {
          color: theme.page.fontColor,
          whiteSpace: 'nowrap',
          textOverflow: 'ellipsis',
          overflow: 'hidden',
          ...theme.typography.h1,
        },
        subtitle: {
          color: alpha(theme.page.fontColor, 0.8),
        },
        type: {
          color: alpha(theme.page.fontColor, 0.8),
        },
        leftItemsBox: {
          overflow: 'hidden',
          flexGrow: 'initial',
          '&:has(+ $rightItemsBox:empty)': {
            flexGrow: 1,
          },
        },
        rightItemsBox: {
          gap: theme.spacing(1),
          alignItems: 'flex-end',
          paddingLeft: theme.spacing(2),
          paddingBottom: theme.spacing(2.5),
          flexGrow: 1,
          height: '100%',
          paddingRight: theme.spacing(2),
          '&:empty': {
            display: 'none',
          },
        },
      },
      BackstageContent: {
        root: {
          width: '100%',
          maxWidth: theme.breakpoints.values.xl,
          marginLeft: 'auto',
          marginRight: 'auto',
          paddingTop: theme.spacing(2),
          'div:has([role=tablist]) + &': {
            paddingTop: theme.spacing(4),
          },
        },
      },
      BackstagePage: {
        root: {
          scrollbarGutter: 'stable',
        },
      },
      BackstageSidebarPage: {
        root: {
          // Cannot override drawer styles normally: https://github.com/backstage/backstage/issues/11153
          '& > nav > div > div:first-child, & > nav > div > div > [data-testid*="item-with-submenu"] > div:last-child':
            {
              paddingTop: spacer8,
              paddingBottom: spacer8,
              borderRight: `1px solid ${theme.palette.divider}`,
              '& > hr': {
                height: 1,
                width: '100%',
                background: theme.palette.divider,
                margin: theme.spacing(1.2, 0),
              },
              '& > *': {
                width: '100%',
              },
            },
          '& > nav > div > div > [data-testid*="item-with-submenu"] > div:last-child':
            {
              paddingTop: spacer16,
            },
        },
      },
      BackstageSidebarItem: {
        root: {
          borderRadius: 0,
          '&$selected$selected': {
            borderLeft: 'none',
          },
        },
        label: {
          ...theme.typography.body1,
          '$root$selected:has(+ a[aria-current=page]) &': {
            color: theme.palette.navigation.color,
            fontWeight: 'normal',
          },
          '$selected &, [data-testid*="item-with-submenu"][class]:not([class=""]) &, $root$highlightable:hover &, $root$selected:has(+ a[aria-current=page]):hover &':
            {
              fontWeight: 'bold',
              color: theme.palette.navigation.selectedColor,
            },
        },
        highlightable: {
          '&:hover': {
            backgroundColor: theme.palette.navigation.background,
          },
        },
        highlighted: {
          backgroundColor: theme.palette.navigation.background,
        },
        buttonItem: {
          '&$highlightable:hover': {
            transform: 'scale(1)',
            backgroundColor: theme.palette.navigation.background,
          },
        },
        iconContainer: {
          height: 48,
          width: 48,
          minWidth: 48,
          marginRight: spacer16,
          marginLeft: spacer8,
          borderRadius: theme.shape.borderRadius,
          transition: theme.transitions.create(['background-color', 'color'], {
            duration: '0.1s',
          }),
          '$root$selected &': {
            marginLeft: spacer8,
            backgroundColor: theme.palette.navigation.selectedColor,
            color: theme.palette.navigation.background,
          },
          '$root$selected:has(+ a[aria-current=page]) &': {
            backgroundColor: theme.palette.navigation.background,
            color: theme.palette.navigation.color,
          },
          '$root$selected:hover &, $root$selected:has(+ a[aria-current=page]):hover &':
            {
              color: theme.palette.navigation.background,
            },
          '$root:hover &, $root$selected:has(+ a[aria-current=page]):hover &': {
            color: theme.palette.navigation.selectedColor,
          },

          '& > span > div > svg': {
            width: 24,
            height: 24,
          },
        },
        closed: {
          marginLeft: 0,
          width: '100%',
          minWidth: 'auto',
          '&$selected$root': {
            width: '100%',
          },
          '& + div:last-child h5': {
            display: 'block',
          },
        },
        open: {
          [theme.breakpoints.up('sm')]: {
            width: '100%',
          },
        },
        closedItemIcon: {
          height: 48,
          width: 48,
          borderRadius: theme.shape.borderRadius,
          display: 'flex',
          alignItems: 'center',
          transition: theme.transitions.create(['background-color', 'color'], {
            duration: '0.1s',
          }),

          '$root$selected &': {
            paddingRight: 0,
            backgroundColor: theme.palette.navigation.selectedColor,
            color: theme.palette.navigation.background,
          },
          '$root:hover &': {
            color: theme.palette.navigation.selectedColor,
          },
          '$root$selected:hover &': {
            color: theme.palette.navigation.background,
          },
          '$root$selected:has(+ a[aria-current=page]) &': {
            backgroundColor: theme.palette.navigation.background,
            color: theme.palette.navigation.color,
          },

          '& > div': {
            marginLeft: '0 !important',
          },

          '& > div > svg': {
            width: 24,
            height: 24,
            '& + svg': {
              display: 'none',
            },
          },
        },
        secondaryAction: {
          width: 'auto',
        },
        submenuArrow: {
          display: 'none',
        },
        searchContainer: {
          width: '100%',
          margin: 0,
        },
        searchField: {
          fontWeight: 'normal',
          color: theme.palette.text.secondary,
        },
        searchFieldHTMLInput: {
          padding: [spacer12, spacer8].join(' '),
        },
        expandButton: {
          '&:hover': {
            transform: 'scale(1)',
          },
        },
      },
      BackstageSidebarSubmenu: {
        title: {
          display: 'none',
          color: theme.palette.navigation.selectedColor,
          padding: [spacer4, spacer16, spacer8].join(' '),
          ...theme.typography.subtitle1,
          fontWeight: 'bold',
        },
      },
      BackstageSidebarSubmenuItem: {
        item: {
          height: 'auto',
          borderRadius: 0,
          padding: spacer8,
          '& > svg, & > span > svg:first-child': {
            display: 'none',
          },
          '&:hover': {
            backgroundColor: 'transparent',
            transform: 'scale(1)',
          },
          justifyContent: 'flex-start',
        },
        itemContainer: {
          [theme.breakpoints.down('sm')]: {
            paddingLeft: theme.spacing(4),
          },
        },
        dropdownItem: {
          '&:hover': {
            backgroundColor: 'transparent',
            '& $textContent': {
              color: theme.palette.navigation.selectedColor,
              fontWeight: 'bold',
            },
          },
        },
        label: {
          ...theme.typography.body2,
          margin: 0,
          '$item:hover &, $selected &': {
            fontWeight: 'bold',
            color: theme.palette.navigation.selectedColor,
          },
        },
        selected: {
          '&$item': {
            backgroundColor: 'transparent',
          },
        },
      },
      BackstageHeaderTabs: {
        defaultTab: {
          textTransform: 'none',
          padding: [spacer8, spacer12].join(' '),
          ...mobileCello,
          [theme.breakpoints.up('md')]: {
            ...desktopCello,
          },
        },
        tabsWrapper: {
          backgroundColor: 'transparent',
          width: '100%',
          maxWidth: theme.breakpoints.values.xl,
          marginLeft: 'auto',
          marginRight: 'auto',
        },
      },
      BackstageBreadcrumbsStyledBox: {
        root: {
          textDecoration: 'none',
        },
      },
      BackstageOpenedDropdown: {
        icon: {
          '& path': {
            fill: solidWhite200,
          },
        },
      },
      BackstageTabbedCard: {
        indicator: {
          backgroundColor: theme.palette.text.primary,
        },
      },
      BackstageTable: {
        root: {
          '&> :first-child': {
            borderBottom: `1px solid ${theme.palette.divider}`,
            boxShadow: 'none',
          },
          '& th': {
            borderTop: 'none',
            textTransform: 'none !important',
          },
        },
      },

      MuiAlert: {
        root: {
          border: 0,
          borderRadius: theme.spacing(2),
          padding: theme.spacing(2),
          '&$standardSuccess, &$standardError, &$standardInfo, &$standardWarning ':
            {
              backgroundColor: snackbarBackground,
              color: theme.palette.text.primary,
              '[class*="MuiDialog"][role="dialog"]:not(.MuiDialog-paperFullScreen) &':
                {
                  backgroundColor:
                    theme.palette.type === 'dark'
                      ? theme.palette.action.active
                      : snackbarBackground,
                },
            },
        },
        icon: {
          color: 'inherit',
        },
        standardSuccess: {
          '& $icon': {
            color: theme.palette.success.dark,
          },
        },
        standardWarning: {
          '& $icon': {
            color: theme.palette.warning.dark,
          },
        },
        filledSuccess: {
          color: theme.palette.success.contrastText,
        },
        filledError: {
          color: theme.palette.error.contrastText,
        },
        filledInfo: {
          color: theme.palette.info.contrastText,
        },
        filledWarning: {
          color: theme.palette.warning.contrastText,
        },
        outlinedSuccess: {
          backgroundColor: theme.palette.success.main,
          color: theme.palette.success.contrastText,
          border: 'none',
          '& $icon': {
            color: 'inherit',
          },
        },
        outlinedError: {
          backgroundColor: theme.palette.error.main,
          color: theme.palette.error.contrastText,
          border: 'none',
          '& $icon': {
            color: 'inherit',
          },
        },
        outlinedInfo: {
          backgroundColor: theme.palette.info.main,
          color: theme.palette.info.contrastText,
          border: 'none',
          '& $icon': {
            color: 'inherit',
          },
        },
        outlinedWarning: {
          backgroundColor: theme.palette.warning.main,
          color: theme.palette.warning.contrastText,
          border: 'none',
          '& $icon': {
            color: 'inherit',
          },
        },
      },
      MuiAlertTitle: {
        root: {
          fontWeight: 'bold',
        },
      },
      MuiPaper: {
        root: {
          boxShadow: 'none',
          '[role="tooltip"] &': {
            backgroundColor:
              theme.palette.type === 'dark'
                ? snackbarBackground
                : theme.palette.background.paper,
          },
        },
        rounded: {
          borderRadius: theme.spacing(2),
        },
        outlined: {
          border: `1px solid ${theme.palette.divider}`,
          backgroundColor: theme.palette.background.paper,

          transition: theme.transitions.create('border-color', {
            duration: theme.transitions.duration.shortest,
          }),

          // to style action areas with a different border color
          '&:has(a[role="button"]:only-child):hover': {
            borderColor: theme.palette.text.primary,
          },
        },
      },
      MuiCardHeader: {
        root: {
          padding: theme.spacing(4),
          paddingBottom: 0,
          '&:only-child:last-child': {
            paddingBottom: theme.spacing(4),
          },
        },
      },
      MuiCardContent: {
        root: {
          padding: theme.spacing(4),
          // Override MUI Default style
          '&:last-child': {
            paddingBottom: theme.spacing(4),
          },
          '&:empty': {
            paddingBottom: 0,
          },
        },
      },
      MuiCardActionArea: {
        focusHighlight: {
          backgroundColor: 'transparent',
        },
      },
      MuiListItem: {
        root: {
          paddingTop: spacer4,
          paddingBottom: spacer4,
        },
        gutters: {
          paddingLeft: spacer12,
          paddingRight: spacer12,
        },
        button: {
          position: 'relative',
          zIndex: 0,
          '&:hover': {
            backgroundColor: 'transparent',
            '&:after': {
              backgroundColor: theme.palette.action.hover,
            },
          },
          '&:after': {
            content: '""',
            position: 'absolute',
            transitionDuration: theme.transitions.duration.shorter,
            borderRadius: theme.shape.borderRadius,
            zIndex: -1,
            border: 'none',
            top: 0,
            left: 0,
            bottom: 0,
            right: 0,
            height: '100%',
            width: '100%',
          },
          '&.Mui-selected': {
            '&:hover': {
              backgroundColor: 'transparent',
              '&:after': {
                backgroundColor: theme.palette.action.hover,
              },
            },
            backgroundColor: 'transparent',
            '&:after': {
              backgroundColor: theme.palette.action.selected,
            },
          },
        },
      },
      MuiListItemIcon: {
        root: {
          color: theme.palette.text.primary,
        },
      },
      MuiListItemText: {
        primary: {
          color: theme.palette.text.primary,
        },
      },
      MuiListItemSecondaryAction: {
        root: {
          right: 10,
          ':not(.MuiListItem-gutters) + &': {
            right: 0,
          },
        },
      },
      MuiBreadcrumbs: {
        separator: {
          color: theme.palette.text.secondary,
        },
        li: {
          textDecoration: 'none',
          '&:not(:last-child)': {
            color: theme.palette.text.secondary,
          },
          // Override Backstage inline styling
          '&:last-child > div *': {
            fontStyle: 'normal',
          },
        },
      },
      MuiTooltip: {
        tooltip: {
          fontSize: theme.typography.caption.fontSize,
        },
      },
      MuiTypography: {
        colorPrimary: {
          color: activeRating,
        },
      },
      MuiButtonBase: {
        root: {
          transition: theme.transitions.create(
            ['background-color', 'border-color', 'color', 'transform'],
            { duration: '80ms' },
          ),
          '&:after': {
            content: '""',
            border: '3px solid transparent',
            transition: theme.transitions.create('border-color'),
            boxSizing: 'border-box',
            pointerEvents: 'none',
            width: 'calc(100% + 12px)',
            height: 'calc(100% + 12px)',
            position: 'absolute',
            borderRadius: 'inherit',
            top: -6,
            left: -6,
            zIndex: 4,
          },
          '&.Mui-focusVisible:after': {
            borderColor: theme.palette.text.primary,
          },
        },
        disabled: {
          '&$root': {
            opacity: 0.3,
          },
        },
      },
      MuiButton: {
        root: {
          maxHeight: 46,
          borderRadius: 500,
          padding: [spacer12, spacer24].join(' '),
          position: 'relative',
          textTransform: 'none',
          transition: theme.transitions.create(
            [
              'background-color',
              'border-color',
              'color',
              'boxShadow',
              'transform',
            ],
            { duration: '80ms' },
          ),
          '&:hover': {
            transform: 'scale(1.04)',
            boxShadow: 'none',
          },
          '&:active': {
            boxShadow: 'none',
            transform: 'scale(1)',
            opacity: 0.7,
          },
        },
        contained: {
          boxShadow: 'none',
          backgroundColor: theme.palette.secondary.main,
          color: theme.palette.secondary.contrastText,
          '&:hover': {
            boxShadow: 'none',
            backgroundColor: theme.palette.secondary.dark,
          },
          '&:active': {
            boxShadow: 'none',
          },
          '&.Mui-disabled, &:hover.Mui-disabled': {
            backgroundColor: theme.palette.secondary.main,
            color: theme.palette.secondary.contrastText,
          },
        },
        containedPrimary: {
          '&.Mui-disabled, &:hover.Mui-disabled': {
            backgroundColor: theme.palette.primary.main,
            color: theme.palette.primary.contrastText,
          },
          '&:active': {
            backgroundColor: theme.palette.primary.dark,
            opacity: 1,
          },
          '&:hover': {
            backgroundColor: theme.palette.primary.light,
          },
        },
        containedSecondary: {
          '&.Mui-disabled, &:hover.Mui-disabled': {
            backgroundColor: theme.palette.secondary.main,
            color: theme.palette.secondary.contrastText,
          },
        },
        outlined: outlinedButton,
        outlinedPrimary: outlinedButton,
        outlinedSecondary: outlinedButton,
        text: textButton,
        textPrimary: textButton,
        textSecondary: textButton,
        sizeLarge: {
          maxHeight: 50,
          padding: [spacer12, spacer32].join(' '),
          ...mobileCello,
          [theme.breakpoints.up('md')]: {
            ...desktopCello,
          },
          '&$text': {
            paddingLeft: 0,
            paddingRight: 0,
          },
        },
        sizeSmall: {
          maxHeight: spacer32,
          padding: ['6.5px', spacer16].join(' '),
          ...mobileMestoBold,
          [theme.breakpoints.up('md')]: {
            ...desktopMestoBold,
          },
          '&$text': {
            paddingLeft: 0,
            paddingRight: 0,
          },
        },
        startIcon: {
          marginRight: spacer8,
        },
        endIcon: {
          marginLeft: spacer8,
        },
        colorInherit: {
          '&.Mui-disabled': {
            color: 'inherit',
          },
          '&$text:hover': {
            color: 'inherit',
            opacity: 0.7,
          },
        },
      },
      MuiIconButton: {
        root: {
          transition: theme.transitions.create(
            ['background-color', 'border-color', 'color', 'transform'],
            { duration: '80ms' },
          ),
          '&, &.Mui-disabled': {
            color: theme.palette.text.secondary,
          },
          '&:hover': {
            transform: 'scale(1.04)',
            backgroundColor: outlinedHover,
          },
          '&:active': {
            transform: 'scale(1)',
          },
          '&:after': {
            width: '100%',
            borderWidth: 0,
            borderBottomWidth: 3,
            height: 1,
            bottom: -6,
            top: 'unset',
            left: 'unset',
            borderRadius: 0,
          },
          '&.Mui-focusVisible:after': {
            borderColor: 'inherit',
          },
        },
        disabled: {
          '&$root': {
            opacity: 0.3,
          },
        },
        colorPrimary: {
          '&, &.Mui-disabled': {
            color: activeRating,
          },
          '&:hover': {
            backgroundColor: outlinedHover,
          },
        },
        colorSecondary: {
          '&, &.Mui-disabled': {
            color: theme.palette.secondary.main,
          },
          '&:hover': {
            backgroundColor: outlinedHover,
          },
        },
        colorInherit: {
          '&, &.Mui-disabled': {
            color: 'inherit',
          },
          '&:hover': {
            backgroundColor: 'unset',
            opacity: 0.7,
          },
        },
      },
      MuiButtonGroup: {
        root: {},
        grouped: {
          padding: [spacer8, spacer20].join(' '),
          borderRadius: theme.shape.borderRadius,
          '&:hover': {
            transform: 'scale(1)',
          },
          '&.Mui-disabled': {
            opacity: 0.3,
          },
        },
        contained: {
          boxShadow: 'none',
          backgroundColor: theme.palette.primary.contrastText,
        },
        groupedContained: {
          border: `1px solid ${outlinedResting}`,
          backgroundColor: theme.palette.primary.contrastText,
          color: theme.palette.text.primary,
          '&:hover, &:active': {
            backgroundColor: outlinedHover,
            borderColor: outlinedResting,
          },
          '&:not(:last-child)': {
            borderColor: outlinedResting,
          },
          '&.Mui-disabled': {
            backgroundColor: theme.palette.primary.contrastText,
            color: theme.palette.text.primary,
            borderColor: outlinedResting,
          },
        },
        groupedContainedHorizontal: {
          '&:not(:last-child)': {
            borderRightColor: grey[800],
          },
          '&:not(:first-child)': {
            borderLeft: 'none',
          },
        },
        groupedContainedVertical: {
          color: theme.palette.text.primary,
          '&:not(:first-child)': {
            borderTop: 'none',
          },
        },
        groupedContainedPrimary: {
          backgroundColor: theme.palette.primary.contrastText,
          color: theme.palette.text.primary,
          borderColor: grey[800],
          '&:hover, &:active': {
            backgroundColor: outlinedHover,
            borderColor: grey[800],
          },
          '&:not(:last-child)': {
            borderColor: grey[800],
          },
          '&.Mui-disabled': {
            backgroundColor: theme.palette.primary.contrastText,
            borderColor: grey[800],
          },
        },
        groupedContainedSecondary: {
          borderColor: outlinedResting,
          '&:hover, &:active': {
            backgroundColor: outlinedHover,
            borderColor: outlinedResting,
          },
          '&:not(:last-child)': {
            borderColor: outlinedResting,
          },
          '&.Mui-disabled': {
            borderColor: outlinedResting,
          },
        },
        groupedOutlined: {
          borderColor: theme.palette.text.secondary,
          '&:hover': {
            borderColor: theme.palette.text.secondary,
          },
          '&.Mui-disabled': {
            borderColor: theme.palette.text.secondary,
          },
        },
        groupedOutlinedPrimary: {
          borderColor: grey[800],
          '&:hover, &:active': {
            borderColor: grey[800],
          },
          '&.Mui-disabled': {
            borderColor: grey[800],
          },
        },
        groupedOutlinedSecondary: {
          borderColor: theme.palette.text.secondary,
          '&:hover': {
            borderColor: theme.palette.text.secondary,
          },
          '&.Mui-disabled': {
            borderColor: theme.palette.text.secondary,
          },
          '&:not(:last-child)': {
            borderBottomColor: 'transparent',
          },
        },
        groupedText: {
          color: theme.palette.text.primary,
          padding: [spacer8, spacer12].join(' '),
          '&:hover': {
            backgroundColor: outlinedHover,
          },
          '&:not(:last-child)': {
            borderColor: outlinedResting,
          },
        },
        groupedTextHorizontal: {
          '&:not(:last-child)': {
            borderRightColor: outlinedResting,
          },
        },
        groupedTextVertical: {
          '&:not(:last-child)': {
            borderBottomColor: outlinedResting,
          },
        },
        groupedTextPrimary: {
          '&:not(:last-child)': {
            borderColor: outlinedResting,
          },
        },
        groupedTextSecondary: {
          '&:not(:last-child)': {
            borderColor: outlinedResting,
          },
        },
      },
      MuiFab: {
        root: {
          backgroundColor: grey[300],
          color: theme.palette.primary.contrastText,
          boxShadow: 'none',
          padding: spacer16,
          ...mobileCello,
          [theme.breakpoints.up('md')]: {
            ...desktopCello,
          },
          '&:hover': {
            transform: 'scale(1.04)',
            boxShadow: 'none',
          },
          '&:active, &:active:hover': {
            transform: 'scale(1)',
            boxShadow: 'none',
          },
        },
        label: {
          display: 'inline-flex',
          gap: spacer8,
        },
        secondary: {
          '&:active, &:active:hover': {
            opacity: 0.7,
          },
        },
        disabled: {
          '&$root': {
            color: theme.palette.action.disabled,
            background: theme.palette.action.disabledBackground,
          },
        },
        extended: {
          padding: [spacer12, spacer16].join(' '),
          '&.MuiFab-sizeMedium': {
            padding: [spacer8, spacer16].join(' '),
          },
          '&.MuiFab-sizeSmall': {
            padding: [spacer4, spacer8].join(' '),
          },
        },
        sizeMedium: {
          padding: spacer12,
          ...mobileBalladBold,
          [theme.breakpoints.up('md')]: {
            ...desktopBalladBold,
          },
        },
        sizeSmall: {
          padding: spacer8,
          ...mobileBalladBold,
          [theme.breakpoints.up('md')]: {
            ...desktopBalladBold,
          },
        },
      },
      MuiCheckbox: {
        root: {
          '&, &.Mui-disabled, &:active, &:hover': {
            backgroundColor: 'transparent',
          },
          '&:after': {
            content: '""',
            display: 'block',
            transition: theme.transitions.create('border-color'),
            boxSizing: 'border-box',
            pointerEvents: 'none',
            width: 'calc(100% + 6px)',
            height: 'calc(100% + 6px)',
            position: 'absolute',
            border: '3px solid transparent',
            borderRadius: 6,
            top: -3,
            left: -3,
          },
          '&.Mui-focusVisible:after': {
            borderColor: theme.palette.text.primary,
          },
        },
        disabled: {
          '&$root': {
            opacity: 1,
            color: theme.palette.text.disabled,
          },
        },
        colorPrimary: {
          color: theme.palette.text.secondary,
          '&:hover, &.Mui-checked': {
            color: activeRating,
          },
          '&.Mui-disabled': {
            color: theme.palette.action.disabled,
          },
        },
        colorSecondary: {
          color: theme.palette.text.secondary,
          '&:hover': {
            color: theme.palette.secondary.dark,
          },
          '&.Mui-disabled': {
            color: theme.palette.action.disabled,
          },
        },
      },
      MuiRadio: {
        root: {
          '&, &.Mui-disabled, &:active, &:hover': {
            backgroundColor: 'transparent',
          },
          '&:after': {
            content: '""',
            display: 'block',
            transition: theme.transitions.create('border-color'),
            boxSizing: 'border-box',
            pointerEvents: 'none',
            width: 'calc(100% + 6px)',
            height: 'calc(100% + 6px)',
            position: 'absolute',
            border: '3px solid transparent',
            borderRadius: 9999,
            top: -3,
            left: -3,
          },
          '&.Mui-focusVisible:after': {
            borderColor: theme.palette.text.primary,
          },
        },
        disabled: {
          '&$root': {
            opacity: 1,
            color: theme.palette.text.disabled,
          },
        },
        colorPrimary: {
          color: theme.palette.text.secondary,
          '&:hover, &.Mui-checked': {
            color: activeRating,
          },
          '&.Mui-disabled': {
            color: theme.palette.action.disabled,
          },
        },
        colorSecondary: {
          color: theme.palette.text.secondary,
          '&:hover': {
            color: theme.palette.secondary.dark,
          },
          '&.Mui-disabled': {
            color: theme.palette.action.disabled,
          },
        },
      },
      MuiTableBody: {
        root: {
          '& > tr:last-of-type, & > tr:last-child, & > [role=row]:last-of-type, & > [role=row]:last-child':
            {
              '& [role=cell], & td': {
                borderBottom: 'none',
              },
            },
        },
      },
      MuiTableRow: {
        root: {
          color: theme.palette.text.primary,
          transition: theme.transitions.create('background-color', {
            duration: '100ms',
          }),
        },
        hover: {
          '&$hover:hover': {
            backgroundColor: theme.palette.action.hover,
          },
        },
        selected: {
          '&$root': {
            backgroundColor: outlinedHover,
          },
        },
      },
      MuiTableContainer: {
        root: {
          border: 'none',
          backgroundColor: 'transparent',
        },
      },
      MuiTableCell: {
        head: {
          borderBottom: `1px solid ${theme.palette.action.disabled}`,
          color: theme.palette.text.primary,
          ...theme.typography.subtitle1,
          fontWeight: 'bold',
        },
        body: {
          borderBottom: `1px solid ${theme.palette.action.hover}`,
          color: theme.palette.text.primary,
          ...theme.typography.body2,
        },
        root: {
          padding: spacer12,
        },
      },
      MuiLink: {
        underlineAlways: {
          '&:hover': {
            color: activeRating,
          },
        },
      },
      MuiPickersCalendarHeader: {
        root: {
          color: theme.palette.text.disabled,
        },
        iconButton: {
          backgroundColor: 'transparent',
        },
      },
      MuiPickersToolbar: {
        toolbar: {
          backgroundColor: theme.palette.secondary.main,
          '& h3': {
            fontSize: '2em',
          },
        },
      },
      MuiPickersToolbarText: {
        toolbarTxt: {
          color: theme.palette.secondary.contrastText,
        },
        toolbarBtnSelected: {
          color: theme.palette.secondary.contrastText,
        },
      },
      MuiPickersTimePickerToolbar: {
        ampmLabel: {
          opacity: 0.3,
          '&.MuiPickersToolbarText-toolbarBtnSelected': {
            opacity: 1,
          },
        },
      },
      MuiPickersClock: {
        clock: {
          backgroundColor: theme.palette.background.default,
        },
      },
      MuiPickersDay: {
        current: {
          color: activeRating,
        },
      },
      MuiRating: {
        iconFocus: {
          transform: 'scale(1)',
        },
        iconHover: {
          transform: 'scale(1.04)',
        },
        iconEmpty: {
          color: theme.palette.text.primary,
        },
        iconFilled: {
          color: activeRating,
        },
        iconActive: {
          color: activeRating,
        },
      },
      MuiSwitch: {
        root: {
          '&:has(.Mui-disabled)': {
            opacity: 0.3,
          },
        },
        sizeSmall: {
          width: 36,
        },
        switchBase: {
          marginLeft: 0,
          padding: 12,
          top: 2,
          '$root$sizeSmall &': {
            padding: 7,
          },
          '&:hover': {
            backgroundColor: alpha(
              outlinedResting || theme.palette.action.hover,
              theme.palette.action.hoverOpacity,
            ),
          },
          '&.Mui-checked': {
            transform: 'translateX(14px)',
            '$root$sizeSmall &': {
              transform: 'translateX(10px)',
            },
          },
        },
        disabled: {
          '&$root': {
            opacity: 1,
          },
        },
        thumb: {
          backgroundColor: theme.palette.background.default,
          boxShadow: 'none',
          width: 12,
          height: 12,
          left: 2,
          position: 'relative',
          '$sizeSmall &': {
            width: 8,
            height: 8,
          },
        },
        track: {
          width: 30,
          height: 16,
          opacity: 1,
          '$sizeSmall &': {
            width: 30,
            height: 12,
          },
          backgroundColor: theme.palette.text.disabled,
          '$switchBase.Mui-checked + &, $switchBase.Mui-checked.Mui-disabled + &':
            {
              backgroundColor: activeRating,
              opacity: 1,
            },
          '$switchBase.Mui-disabled + &': {
            backgroundColor: theme.palette.text.disabled,
            opacity: 1,
          },
        },
      },
      MuiInputBase: {
        inputMarginDense: {
          paddingTop: spacer12,
          paddingBottom: spacer12,
        },
        input: {
          colorScheme: theme.palette.type,
          padding: [spacer20, spacer16].join(' '),
        },
        inputMultiline: {
          colorScheme: theme.palette.type,
          padding: [spacer20, spacer16].join(' '),
        },
      },
      MuiInput: {
        underline: {
          '&:before, &:after': {
            display: 'none',
          },
        },
        formControl: {
          'label + &': {
            marginTop: 0,
          },
        },
        root: {
          '&:-webkit-autofill': {
            '-webkit-box-shadow': `0 0 0 100px ${outlinedHover} inset`,
            '-webkit-text-fill-color': 'inherit',
          },
          border: 'none',
          transition: theme.transitions.create(['box-shadow'], {
            duration: '0.1s',
          }),
          boxShadow: `0 0 0 1px ${outlinedResting} inset`,
          borderRadius: theme.shape.borderRadius,
          '&:hover, &:focus, .Mui-focused$root&': {
            borderRadius: theme.shape.borderRadius,
            boxShadow: `0 0 0 1px ${theme.palette.text.primary} inset`,
            '$disabled&': {
              boxShadow: `0 0 0 1px ${outlinedResting} inset`,
            },
          },
          '&:focus, .Mui-focused$root&, $root:focus&': {
            boxShadow: `0 0 0 2px ${theme.palette.text.primary} inset`,
            backgroundColor: 'transparent',
            '.Mui-error&': {
              boxShadow: `0 0 0 2px ${theme.palette.error.main} inset`,
            },
          },
          '&:has($input[aria-expanded="true"])': {
            boxShadow: `0 0 0 1px ${outlinedResting} inset`,
            '.Mui-error&': {
              boxShadow: `0 0 0 1px ${theme.palette.error.main} inset`,
            },
          },
          '.Mui-error&': {
            boxShadow: `0 0 0 1px ${theme.palette.error.main} inset`,
          },
        },
        disabled: {
          '&$root': {
            cursor: 'not-allowed',
          },
        },
      },
      MuiOutlinedInput: {
        root: {
          '&:-webkit-autofill': {
            '-webkit-box-shadow': `0 0 0 100px ${outlinedHover} inset`,
            '-webkit-text-fill-color': 'inherit',
          },
          border: 'none',
          transition: theme.transitions.create(['box-shadow'], {
            duration: '0.1s',
          }),
          boxShadow: `0 0 0 1px ${outlinedResting} inset`,
          borderRadius: theme.shape.borderRadius,
          '&:hover, &:focus, .Mui-focused$root&': {
            borderRadius: theme.shape.borderRadius,
            boxShadow: `0 0 0 1px ${theme.palette.text.primary} inset`,
            '$disabled&': {
              boxShadow: `0 0 0 1px ${outlinedResting} inset`,
            },
          },
          '&:focus, .Mui-focused$root&, $root:focus&': {
            boxShadow: `0 0 0 2px ${theme.palette.text.primary} inset`,
            backgroundColor: 'transparent',
            '.Mui-error&': {
              boxShadow: `0 0 0 2px ${theme.palette.error.main} inset`,
            },
          },
          '&:has($input[aria-expanded="true"])': {
            boxShadow: `0 0 0 1px ${outlinedResting} inset`,
            '.Mui-error&': {
              boxShadow: `0 0 0 1px ${theme.palette.error.main} inset`,
            },
          },
          '.Mui-error&': {
            boxShadow: `0 0 0 1px ${theme.palette.error.main} inset`,
          },
        },
        input: {
          '&:-webkit-autofill': {
            '-webkit-box-shadow': `0 0 0 100px ${outlinedHover} inset`,
            '-webkit-text-fill-color': 'inherit',
          },
        },
        notchedOutline: {
          display: 'none',
        },
        disabled: {
          '&$root': {
            opacity: 1,
            cursor: 'not-allowed',
          },
        },
      },
      MuiFilledInput: {
        root: {
          backgroundColor: 'transparent',
          '&:-webkit-autofill': {
            '-webkit-box-shadow': `0 0 0 100px ${outlinedHover} inset`,
            '-webkit-text-fill-color': 'inherit',
          },
          border: 'none',
          transition: theme.transitions.create(['box-shadow'], {
            duration: '0.1s',
          }),
          boxShadow: `0 0 0 1px ${outlinedResting} inset`,
          borderRadius: theme.shape.borderRadius,
          '&:hover, &:focus, .Mui-focused$root&': {
            backgroundColor: 'transparent',
            borderRadius: theme.shape.borderRadius,
            boxShadow: `0 0 0 1px ${theme.palette.text.primary} inset`,
            '$disabled&': {
              boxShadow: `0 0 0 1px ${outlinedResting} inset`,
            },
          },
          '&:focus, .Mui-focused$root&, $root:focus&': {
            boxShadow: `0 0 0 2px ${theme.palette.text.primary} inset`,
            backgroundColor: 'transparent',
            '.Mui-error&': {
              boxShadow: `0 0 0 2px ${theme.palette.error.main} inset`,
            },
          },
          '&:has($input[aria-expanded="true"])': {
            boxShadow: `0 0 0 1px ${outlinedResting} inset`,
            '.Mui-error&': {
              boxShadow: `0 0 0 1px ${theme.palette.error.main} inset`,
            },
          },
          '.Mui-error&': {
            boxShadow: `0 0 0 1px ${theme.palette.error.main} inset`,
          },
        },
        input: {
          padding: '18.5px 14px',
        },
        underline: {
          '&:before, &:after': {
            display: 'none',
          },
        },
        inputMarginDense: {
          paddingTop: 10.5,
          paddingBottom: 10.5,
        },
        disabled: {
          '&$root': {
            opacity: 0.3,
            cursor: 'not-allowed',
            backgroundColor: 'transparent',
          },
        },
        inputAdornedStart: {
          paddingLeft: 8,
        },
      },
      MuiSelect: {
        icon: {
          right: 7,
          color: theme.palette.text.secondary,
        },
        select: {
          borderRadius: theme.shape.borderRadius,
          backgroundColor: 'transparent',
          '&:focus': {
            borderRadius: theme.shape.borderRadius,
            backgroundColor: 'transparent',
          },
          '&$select': {
            paddingRight: spacer32,
            '&.MuiInputBase-inputAdornedEnd': {
              paddingRight: spacer8,
            },
          },
        },
        selectMenu: {
          // workaround to align select with regular TextFields horizontally, there is still a minHeight set
          height: 0,
        },
      },

      MuiFormLabel: {
        root: {
          ...theme.typography.subtitle2,
          fontWeight: 'bold',
          color: theme.palette.text.primary,
          [theme.breakpoints.up('sm')]: {
            ...theme.typography.subtitle2,
            fontWeight: 'bold',
            color: theme.palette.text.primary,
          },
          '&.Mui-focused': {
            color: theme.palette.text.primary,
          },
          '&.Mui-error': {
            color: theme.palette.error.main,
          },
        },
      },
      MuiFormGroup: {
        root: {
          gap: theme.spacing(1),
        },
      },
      MuiFormControl: {
        root: {
          gap: spacer8,
        },
      },
      MuiFormControlLabel: {
        label: {
          paddingLeft: theme.spacing(0.5),
        },
      },
      PrivateSwitchBase: {
        root: {
          padding: `${spacer4}`,
          marginLeft: '5px',
        },
      },
      MuiInputLabel: {
        root: {
          ...theme.typography.subtitle2,
          fontWeight: 'bold',
          color: theme.palette.text.primary,
        },
        asterisk: {
          color: theme.palette.errorText,
        },
        shrink: {
          transform: 'none',
        },
        marginDense: {
          transform: 'none',
        },
        outlined: {
          pointerEvents: 'all',
          '&, &$shrink, &$marginDense': {
            transform: 'none',
          },
        },
        filled: {
          pointerEvents: 'all',
          '&, &$shrink, &$marginDense, &$root$shrink$marginDense': {
            transform: 'none',
          },
        },
        formControl: {
          position: 'relative',
          transform: 'none',
        },
      },
      MuiFormHelperText: {
        root: {
          color: theme.palette.text.secondary,
          ...mobileMesto,
          [theme.breakpoints.up('md')]: {
            ...desktopMesto,
          },
        },
        contained: {
          marginLeft: 0,
          marginRight: 0,
        },
      },
      MuiInputAdornment: {
        marginDense: {
          '& .MuiIconButton-root': {
            padding: 6,
          },
          '& button:hover': {
            backgroundColor: alpha(
              theme.palette.action.hover,
              theme.palette.action.hoverOpacity,
            ),
          },
        },
      },
      MuiAvatar: {
        colorDefault: {
          backgroundColor: grey[400],
        },
      },
      MuiAvatarGroup: {
        avatar: {
          borderColor: solidWhite200,
        },
      },
      MuiToggleButtonGroup: {
        groupedHorizontal: {
          '&:not(:first-child)': {
            marginLeft: 0,
          },
          '&.Mui-selected': {
            '&:not(:first-child)': {
              borderLeft: `1px solid ${theme.palette.text.primary}`,
              '&.Mui-disabled': {
                borderLeftColor: theme.palette.action.disabledBackground,
              },
            },
            '&:not(:last-child)': {
              borderRight: `1px solid ${theme.palette.text.primary}`,
              '&.Mui-disabled': {
                borderRightColor: theme.palette.action.disabledBackground,
              },
            },
          },
        },
        groupedVertical: {
          '&:not(:first-child)': {
            marginTop: 0,
          },
          '&.Mui-selected': {
            '&:not(:first-child)': {
              borderTop: `1px solid ${theme.palette.text.primary}`,
              '&.Mui-disabled': {
                borderTopColor: theme.palette.action.disabledBackground,
              },
            },
            '&:not(:last-child)': {
              borderBottom: `1px solid ${theme.palette.text.primary}`,
              '&.Mui-disabled': {
                borderBottomColor: theme.palette.action.disabledBackground,
              },
            },
          },
        },
      },
      MuiSlider: {
        root: {
          color: activeRating,
        },
        rail: {
          backgroundColor: theme.palette.text.secondary,
        },
        thumbColorPrimary: {
          '& $valueLabel': {
            color: theme.palette.primary.main,
          },
        },
      },
      MuiToggleButton: {
        root: {
          backgroundColor: theme.palette.background.paper,
          borderColor: theme.palette.text.primary,
          color: theme.palette.text.primary,
          '&:hover': {
            backgroundColor: theme.palette.action.disabledBackground,
          },
          '&$disabled': {
            color: alpha(theme.palette.text.hint, 0.2),
          },
          '&.Mui-selected': {
            backgroundColor: theme.palette.secondary.main,
            color: theme.palette.secondary.contrastText,
            '&$disabled': {
              color: alpha(theme.palette.text.hint, 0.2),
            },
            '$root&:hover': {
              backgroundColor: theme.palette.secondary.main,
              color: theme.palette.secondary.contrastText,
              opacity: 0.7,
            },
          },
          '&:after': {
            content: '""',
            borderBottom: '3px solid transparent',
            transition: theme.transitions.create('border-color'),
            boxSizing: 'border-box',
            pointerEvents: 'none',
            width: '100%',
            position: 'absolute',
            bottom: -6,
          },
          '&.Mui-focusVisible:after': {
            borderColor: theme.palette.text.primary,
          },
        },
      },
      MuiSkeleton: {
        root: {
          backgroundColor: theme.palette.action.hover,
        },
      },
      MuiChip: {
        root: {
          height: '40px',
          borderRadius: 40,
          fontSize: theme.typography.body2.fontSize,
          paddingLeft: spacer4,
          paddingRight: spacer4,
          backgroundColor: theme.palette.action.hover,
          color: theme.palette.text.primary,
          transition: theme.transitions.create(
            ['background-color', 'opacity'],
            { duration: '0.1s' },
          ),
          '[class*="MuiDialog"][role="dialog"]:not(.MuiDialog-paperFullScreen) &':
            {
              backgroundColor:
                theme.palette.type === 'dark'
                  ? alpha(
                      theme.palette.action.disabled,
                      theme.palette.action.disabledOpacity,
                    )
                  : theme.palette.action.hover,
            },
        },
        outlined: {
          borderColor: outlinedResting,
        },
        outlinedPrimary: {
          color: activeRating,
          borderColor: activeRating,
        },
        deleteIcon: {
          color: theme.palette.text.primary,
          '&:hover': {
            color: 'inherit',
            opacity: 0.7,
          },
        },
        deleteIconOutlinedColorPrimary: {
          color: alpha(activeRating || theme.palette.primary.main, 0.7),
        },
        clickable: {
          '&:active': {
            opacity: 0.7,
            boxShadow: 'none',
          },
        },
        sizeSmall: {
          height: '24px',
          paddingLeft: 0,
          paddingRight: 0,
        },
        avatar: {
          '$root &': {
            backgroundColor: theme.palette.secondary.main,
            color: theme.palette.secondary.contrastText,
          },
        },
        avatarColorPrimary: {
          '$root &': {
            backgroundColor: theme.palette.primary.light,
            color: theme.palette.primary.contrastText,
          },
        },
        avatarColorSecondary: {
          '$root &': {
            backgroundColor: theme.palette.secondary.contrastText,
            color: theme.palette.secondary.main,
          },
          '$outlined &': {
            backgroundColor: theme.palette.secondary.main,
            color: theme.palette.secondary.contrastText,
          },
        },
      },
      MuiTreeItem: {
        root: {
          '&.Mui-selected > .MuiTreeItem-content .MuiTreeItem-label': {
            backgroundColor: outlinedHover,
          },
        },
      },
      MuiMenu: {
        paper: {
          borderRadius: theme.shape.borderRadius,
          marginTop: spacer8,
          border: `2px solid ${theme.palette.text.primary}`,
        },
      },
      MuiAutocomplete: {
        groupLabel: {
          backgroundColor:
            theme.palette.type === 'dark'
              ? snackbarBackground
              : theme.palette.background.paper,
        },
        inputRoot: {
          '&:has([aria-controls])': {
            '&, .Mui-focused&': {
              boxShadow: `0 0 0 1px ${outlinedResting} inset`,
            },
          },
          '&[class*="MuiInput-root"] input$input': {
            padding: [spacer20, spacer16].join(' '),
            '&:first-child': {
              padding: [spacer20, spacer16].join(' '),
            },
          },
          '&[class*="MuiInput-root"][class*="MuiInput-marginDense"] input$input':
            {
              padding: [spacer12, spacer8].join(' '),
              '&:first-child': {
                padding: [spacer12, spacer8].join(' '),
              },
            },
        },
        paper: {
          border: `2px solid ${theme.palette.text.primary}`,
          borderRadius: theme.shape.borderRadius,
          '&:empty': {
            border: 'none',
          },
        },
        clearIndicator: {
          marginRight: spacer8,
        },
        popupIndicator: {
          marginRight: spacer8,
        },
      },
      MuiStepper: {
        horizontal: {
          gap: spacer16,
          '&$alternativeLabel': {
            alignItems: 'stretch',
          },
        },
      },
      MuiStep: {
        horizontal: {
          '&$alternativeLabel': {
            flex: 'unset',
            marginLeft: spacer32,
            marginRight: spacer32,
            display: 'flex',
            flexDirection: 'column',
          },
        },
        vertical: {
          '&:not(:has(.MuiStepContent-root)) + $vertical .MuiStepConnector-vertical':
            {
              padding: [spacer8, 0].join(' '),
              marginLeft: 0,
            },
          '&$alternativeLabel': {
            width: '100%',
            '& .MuiStepContent-root': {
              borderLeft: 'none',
            },
            '& .MuiStepConnector-vertical': {
              marginLeft: 0,
            },
          },
          '&:has(.MuiStepLabel-labelContainer:only-child) + .MuiStepConnector-vertical':
            {
              marginLeft: 0,
            },
        },
      },
      MuiStepButton: {
        vertical: {
          '.MuiStep-alternativeLabel &': {
            justifyContent: 'center',
          },
        },
      },
      MuiStepConnector: {
        root: {
          '&.Mui-disabled': {
            opacity: 1,
          },
        },
        horizontal: {
          flex: 'unset',
        },
        lineHorizontal: {
          position: 'relative',
          top: '3pt',
          left: 'calc(50% - 0.5em)',
          content: '""',
          display: 'inline-block',
          width: '1em',
          height: '1em',
          borderColor: theme.palette.text.secondary,
          borderTopWidth: '0.25em',
          borderRightWidth: '0.25em',
          borderTopStyle: 'solid',
          borderRightStyle: 'solid',
          transform: 'rotate(45deg)',
        },
        vertical: {
          ':not(& ~ &)&': {
            paddingTop: spacer8,
            '.MuiStep-vertical:has(.MuiStepContent-root) + &': {
              paddingTop: 0,
            },
          },
        },
        alternativeLabel: {
          '&$horizontal': {
            top: 'calc(50% - 0.5em)',
            left: 'calc(-100% - 0.5em)',
            position: 'absolute',
          },
          '&$vertical': {
            position: 'initial',
            display: 'flex',
            justifyContent: 'center',
          },
        },
      },
      MuiStepLabel: {
        label: {
          color: theme.palette.text.secondary,
          '&$completed': {
            color: theme.palette.text.secondary,
          },
          '&$completed$active': {
            color: theme.palette.text.primary,
            fontWeight: 'bold',
          },
          '&$active': {
            color: theme.palette.text.primary,
            fontWeight: 'bold',
            '$labelContainer:only-child &': {
              textDecoration: 'underline',
            },
          },
        },
      },
      MuiTabs: {
        indicator: {
          display: 'flex',
          justifyContent: 'center',
          height: 3,
        },
        flexContainer: {
          gap: theme.spacing(2),
        },
      },
      MuiTab: {
        root: {
          ...mobileBalladBold,
          [theme.breakpoints.up('sm')]: {
            minWidth: 'auto',
          },
          [theme.breakpoints.up('md')]: {
            ...desktopBalladBold,
          },
          '&[data-testid*="header-tab"]': {
            padding: 0,
            marginRight: theme.spacing(2),
          },
          padding: 0,
        },
        textColorPrimary: {
          '&.Mui-selected': {
            color: theme.palette.text.primary,
          },
        },
      },
      MuiTabPanel: {
        root: {
          padding: theme.spacing(0, 0),
          '&:not([hidden])': {
            padding: theme.spacing(3, 0),
          },
        },
      },
      MuiAppBar: {
        root: {
          borderRadius: 0,
          '&$colorDefault, &$colorPrimary, &$colorInherit, &$colorTransparent':
            {
              backgroundColor: theme.palette.secondary.main,
              color: theme.palette.secondary.contrastText,
            },
        },
      },
      MuiAccordion: {
        root: {
          '&.Mui-disabled': {
            opacity: 1,
          },
        },
      },
      MuiAccordionSummary: {
        root: {
          minHeight: 66,
        },
      },
      MuiPaginationItem: {
        outlined: {
          backgroundColor: theme.palette.background.paper,
          border: `1px solid ${outlinedResting}`,
          color: theme.palette.text.primary,
          '.Mui-selected&': {
            backgroundColor: theme.palette.action.hover,
            borderColor: theme.palette.text.primary,
          },
        },
        outlinedPrimary: {
          '&.Mui-selected': {
            backgroundColor: outlinedHover,
            borderColor: outlinedResting,
            color: activeRating,
          },
          '&$root.Mui-selected:hover, &$root.Mui-selected.Mui-focusVisible': {
            backgroundColor: theme.palette.action.hover,
          },
        },
        outlinedSecondary: {
          '.Mui-selected&': {
            backgroundColor: outlinedHover,
            borderColor: theme.palette.text.primary,
          },
          '&$root.Mui-selected:hover, &$root.Mui-selected.Mui-focusVisible': {
            backgroundColor: theme.palette.action.hover,
          },
        },
      },
      MuiLinearProgress: {
        root: {
          height: 3,
          borderRadius: theme.shape.borderRadius,
        },
        bar: {
          borderRadius: theme.shape.borderRadius,
        },
        colorPrimary: {
          backgroundColor:
            theme.palette.type === 'dark'
              ? theme.palette.action.disabled
              : theme.palette.action.disabledBackground,
        },
        barColorPrimary: {
          backgroundColor: activeRating,
        },
      },
      MuiCircularProgress: {
        colorPrimary: {
          color: activeRating,
        },
      },
      MuiSnackbar: {
        root: {
          boxShadow: theme.shadows[1],
          borderRadius: theme.spacing(2),
        },
      },
      MuiSnackbarContent: {
        root: {
          backgroundColor: snackbarBackground,
          color: theme.palette.text.primary,
        },
      },
      MuiSvgIcon: {
        colorPrimary: {
          color: activeRating,
        },
      },
      MuiDialogTitle: {
        root: {
          padding: theme.spacing(1, 3),
        },
      },
      MuiDialog: {
        paper: {
          background:
            theme.palette.type === 'dark'
              ? snackbarBackground
              : theme.palette.background.paper,
          padding: spacer12,
          [theme.breakpoints.up('sm')]: {
            padding: spacer32,
          },
          [theme.breakpoints.up('md')]: {
            padding: spacer56,
          },
          borderRadius: spacer24,
        },
        paperFullScreen: {
          background: theme.palette.background.paper,
        },
      },
      MuiDialogContent: {
        dividers: {
          '& + *': {
            marginTop: theme.spacing(2),
          },
        },
      },
      MuiDialogActions: {
        root: {
          padding: theme.spacing(1, 3),
          marginTop: theme.spacing(4),
        },
      },
      MuiPopover: {
        paper: {
          background:
            theme.palette.type === 'dark'
              ? snackbarBackground
              : theme.palette.background.paper,
          borderRadius: theme.shape.borderRadius,
        },
      },
      MuiBottomNavigationAction: {
        root: {
          '&.Mui-selected': {
            borderTopColor: theme.palette.primary.main,
          },
        },
      },
    };
  };

  return {
    ...baseTheme,
    shape: {
      borderRadius: 6,
    },
    typography: {
      ...baseTheme.typography,
      h1: {
        ...mobileForte,
        [baseTheme.breakpoints.up('md')]: {
          ...desktopForte,
        },
      },
      h2: {
        ...mobileForte,
        [baseTheme.breakpoints.up('md')]: { ...desktopForte },
      },
      h3: { ...mobileBrio, [baseTheme.breakpoints.up('md')]: desktopBrio },
      h4: { ...mobileAlto, [baseTheme.breakpoints.up('md')]: desktopAlto },
      h5: { ...mobileCanon, [baseTheme.breakpoints.up('md')]: desktopCanon },
      h6: { ...mobileCello, [baseTheme.breakpoints.up('md')]: desktopCello },
      body1: {
        ...mobileBallad,
        [baseTheme.breakpoints.up('md')]: desktopBallad,
      },
      body2: { ...mobileMesto, [baseTheme.breakpoints.up('md')]: desktopMesto },
      caption: {
        ...mobileFinale,
        [baseTheme.breakpoints.up('md')]: desktopFinale,
      },
      subtitle1: {
        ...mobileBalladBold,
        [baseTheme.breakpoints.up('md')]: desktopBalladBold,
      },
      subtitle2: {
        ...mobileMestoBold,
        [baseTheme.breakpoints.up('md')]: desktopMestoBold,
      },
      button: {
        ...mobileBalladBold,
        [baseTheme.breakpoints.up('md')]: desktopBalladBold,
      },
      overline: {
        ...mobileFinale,
        fontSize: 12,
        letterSpacing: 1,
        textTransform: 'uppercase',
        [baseTheme.breakpoints.up('md')]: desktopFinale,
      },
    },
    props: {
      ...baseTheme.props,
      MuiButtonBase: {
        disableRipple: true,
      },
      MuiButtonGroup: {
        disableRipple: true,
      },
      MuiTableSortLabel: {
        IconComponent: ArrowDropDownIcon,
      },
      MuiPaper: {
        variant: 'outlined',
      },
      MuiTextField: {
        margin: 'dense',
      },
      MuiBreadcrumbs: {
        separator: <IconChevronRight size="small" />,
      },
      MuiSelect: {
        MenuProps: {
          PaperProps: {
            variant: 'elevation',
          },
        },
        margin: 'dense',
        IconComponent: KeyboardArrowDownIcon,
      },
      MuiInputLabel: {
        margin: 'dense',
      },
      MuiFormControl: {
        margin: 'dense',
      },
      MuiFormHelperText: {
        margin: 'dense',
      },
      MuiMenu: {
        getContentAnchorEl: null,
        PaperProps: {
          variant: 'elevation',
        },
        anchorOrigin: {
          vertical: 'bottom',
          horizontal: 'left',
        },
        transformOrigin: {
          vertical: 'top',
          horizontal: 'left',
        },
      },
      MuiAutocomplete: {
        PaperComponent: props => <Paper {...props} variant="elevation" />,
        popupIcon: <KeyboardArrowDownIcon />,
      },
      MuiList: {
        disablePadding: true,
      },
      MuiPopover: {
        PaperProps: {
          variant: 'elevation',
        },
      },
      MuiDialog: {
        PaperProps: {
          variant: 'elevation',
        },
      },
      MuiDrawer: {
        PaperProps: {
          variant: 'elevation',
        },
      },
      MuiLink: {
        color: 'inherit',
        underline: 'hover',
      },
      MuiAccordion: {
        variant: 'elevation',
      },
      MuiSnackbarContent: {
        variant: 'elevation',
        elevation: 1,
      },
    },
    overrides: {
      ...baseTheme.overrides,
      ...createCustomThemeOverrides(baseTheme),
    },
  };
};
