import React from 'react';

import {
  Checkbox,
  IconButton,
  TableCell,
  TableRow,
  Tooltip,
  makeStyles,
} from '@material-ui/core';
import Add from '@material-ui/icons/Add';

import {
  Highlight,
  useAlert,
  useDialog,
  useListFilter,
} from '@spotify-confidence/core-react';
import {
  FlagsAdminV1EvaluationContextFieldOverrideInput as OverrideInput,
  getError,
  getTypeOrNull,
  useCreateEvaluationContextFieldOverrideMutation,
  useUpdateEvaluationContextFieldOverrideMutation,
} from '@spotify-confidence/plugin-graphql';
import classNames from 'classnames';
import _ from 'lodash';

import { DeleteSchemaOverrideDialog } from '../DeleteSchemaOverrideDialog';
import { EditSchemaOverrideDialog } from '../EditSchemaOverrideDialog';
import { overrideRefetchQueries } from '../constants';
import { SchemaItem, isOverride } from '../types';
import { SchemaItemActionMenu } from './SchemaItemActionMenu';
import { SchemaItemValue } from './SchemaItemValue';

const useStyles = makeStyles(theme => ({
  hidden: {
    '& > td': {
      color: theme.palette.text.disabled,
    },
  },
}));

type SchemaItemRowProps = {
  override: SchemaItem;
  createOverride?: (defaultValues?: OverrideInput) => void;
  existingOverrides?: string[];
};

export const SchemaItemRow = ({
  override,
  createOverride,
  existingOverrides,
}: SchemaItemRowProps) => {
  const classes = useStyles();
  const alert = useAlert();
  const { openDialog } = useDialog();
  const { searchQuery } = useListFilter();

  const canEdit =
    getTypeOrNull(
      override.resourceRelations,
      'FgaV1ListResourceRelationsResponse',
    )?.resourceRelations.some(r => r.relation === 'can_edit') || false;

  const onError = React.useCallback(
    (e: Error) => {
      alert.post({
        severity: 'error',
        message: e.message,
      });
    },
    [alert],
  );

  const [update, updateResult] =
    useUpdateEvaluationContextFieldOverrideMutation({
      refetchQueries: overrideRefetchQueries,
      onError,
      onCompleted: response => {
        const responseError = getError(
          response.updateEvaluationContextFieldOverride,
        );
        if (responseError) {
          onError(responseError);
        }
      },
    });

  const [create, createResult] =
    useCreateEvaluationContextFieldOverrideMutation({
      refetchQueries: overrideRefetchQueries,
      onError,
      onCompleted: response => {
        const responseError = getError(
          response.createEvaluationContextFieldOverride,
        );
        if (responseError) {
          onError(responseError);
        }
      },
    });

  const editOverride = React.useCallback(() => {
    if (isOverride(override)) {
      openDialog({
        content: (
          <EditSchemaOverrideDialog
            override={override}
            existingOverrides={existingOverrides}
          />
        ),
      });
    }
  }, [override]);

  const removeOverride = React.useCallback(() => {
    if (isOverride(override)) {
      openDialog({
        content: <DeleteSchemaOverrideDialog override={override} />,
      });
    }
  }, [override]);

  const setVisibility = React.useCallback(
    (visible: boolean) => {
      if (override.name) {
        update({
          variables: {
            evaluationContextFieldOverride: {
              name: override.name,
              hidden: !visible,
            },
            updateMask: 'hidden',
          },
        });
      } else {
        create({
          variables: {
            evaluationContextFieldOverride: {
              field: override.field,
              kind: override.kind,
              hidden: !visible,
            },
          },
        });
      }
    },
    [override],
  );

  const loading = updateResult.loading || createResult.loading;
  const entity = getTypeOrNull(
    override.semanticType?.entityReference?.entity,
    'MetricsV1Entity',
  );

  return (
    <TableRow className={classNames({ [classes.hidden]: override.hidden })}>
      <TableCell>
        <Highlight highlight={searchQuery}>
          {entity?.displayName || override.displayName || override.field}
        </Highlight>
      </TableCell>
      <TableCell>
        <Highlight highlight={searchQuery}>{override.field}</Highlight>
      </TableCell>
      <TableCell>
        <SchemaItemValue override={override} />
      </TableCell>
      <TableCell align="center">
        <Checkbox
          checked={!override.hidden}
          disabled={loading || override.name ? !canEdit : !createOverride}
          color="secondary"
          size="small"
          onChange={(_e, checked) => {
            setVisibility(checked);
          }}
        />
      </TableCell>
      <TableCell align="right">
        {isOverride(override) ? (
          <SchemaItemActionMenu
            id={`${override.name}-actions`}
            disabled={loading || !canEdit}
            size="small"
            actions={[
              {
                label: 'Edit override',
                onClick: editOverride,
              },
              {
                label: 'Remove override',
                onClick: removeOverride,
              },
            ]}
          />
        ) : (
          <Tooltip placement="right" title="Create override" arrow>
            <IconButton
              size="small"
              disabled={loading || !createOverride}
              onClick={() =>
                createOverride?.({
                  field: override.field,
                  kind: override.kind,
                  displayName: _.startCase(override.field),
                })
              }
            >
              <Add fontSize="small" />
            </IconButton>
          </Tooltip>
        )}
      </TableCell>
    </TableRow>
  );
};
