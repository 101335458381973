import React from 'react';
import { useAsyncFn } from 'react-use';

import {
  Box,
  DialogActions,
  MenuItem,
  Select,
  Typography,
  makeStyles,
} from '@material-ui/core';
import { Alert, AlertTitle } from '@material-ui/lab';

import {
  BUCKET_COUNT,
  DialogBody,
  DialogHeader,
  FormSubmitButtons,
  PercentageSlider,
  extractLastNameComponent,
  useDialog,
} from '@spotify-confidence/core-react';
import {
  RuleFragment,
  VariantFragment,
  getError,
  getTypeOrNull,
} from '@spotify-confidence/plugin-graphql';

import { TargetingContainer } from '../../../../../segment/components/Targeting';
import { CriterionOptionsProvider } from '../../../../../segment/components/Targeting/CriterionOptionsProvider';
import { targetingHooks } from '../../../../../segment/domain/targeting';
import { ruleHelpers } from '../../../../domain';
import { RandomizationInput } from '../../RuleContainer';

export type Props = {
  title?: string;
  variants: VariantFragment[];
  rule: RuleFragment;
  onSave?: (rule: RuleFragment) => Promise<void> | void | null;
  clients: string[];
};

const getPlainVariantAssignment = (
  assignmentSpec?: RuleFragment['assignmentSpec'],
) => {
  const bucketRanges = assignmentSpec?.assignments?.[0]?.bucketRanges;
  return bucketRanges?.[0].upper ?? 0;
};

const useStyles = makeStyles({
  fieldValue: {
    minWidth: 300,
  },
});

export const RolloutRuleForm = ({
  title = 'Edit Rule',
  variants,
  rule,
  onSave,
  clients,
}: Props) => {
  const classes = useStyles();
  const { closeDialog } = useDialog();

  const segmentError = getError(rule.segment);
  const [segment, setSegment] = React.useState(
    getTypeOrNull(rule.segment, 'FlagsAdminV1Segment'),
  );
  const [targetingKeySelector, setTargetingKeySelector] = React.useState(
    rule.targetingKeySelector,
  );

  const evaluationContextOptions =
    targetingHooks.useDeriveEvaluationContextSchema(
      clients,
      segment?.targeting,
      targetingKeySelector,
    );

  const [variant, setVariant] = React.useState<VariantFragment | undefined>(
    ruleHelpers.getPreSelectedVariant(variants, rule.assignmentSpec),
  );
  const [assignmentSpec, setAssignmentSpec] = React.useState(
    ruleHelpers.mapVariantToAssignmentsSpec(
      variant,
      getPlainVariantAssignment(rule.assignmentSpec),
    ),
  );
  const [variantAssignment, setVariantAssignment] = React.useState<number>(
    getPlainVariantAssignment(assignmentSpec),
  );

  React.useEffect(() => {
    setAssignmentSpec(
      ruleHelpers.mapVariantToAssignmentsSpec(variant, variantAssignment),
    );
  }, [variant, variantAssignment]);

  const [{ loading: saving, error }, save] = useAsyncFn(async () => {
    if (!segment) throw new Error('missing segment');
    await onSave?.({
      ...rule,
      enabled: rule.enabled,
      assignmentSpec,
      segment,
      targetingKeySelector,
      labels: rule.labels,
    });
    closeDialog();
  }, [segment, rule, assignmentSpec, targetingKeySelector, onSave]);

  const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    save();
  };

  return (
    <>
      <DialogHeader
        title={title}
        subTitle="Use rollout rules to gradually enable a variant to a portion of users."
      />
      <form onSubmit={handleSubmit}>
        <DialogBody autoHeight>
          {error && (
            <Box display="flex">
              <Alert severity="error">
                <AlertTitle>Error while saving</AlertTitle>
                <Typography>{error.message}</Typography>
              </Alert>
            </Box>
          )}

          <Box
            display="flex"
            flexDirection="column"
            alignItems="start"
            gridGap={16}
            mb={4}
          >
            <div>
              <Typography variant="h6" component="div">
                Variant
              </Typography>
              <Typography variant="body1" component="div" color="textSecondary">
                Determines the experience that users will receive.
              </Typography>
            </div>
            <Select
              variant="outlined"
              margin="dense"
              value={variant?.name}
              className={classes.fieldValue}
              fullWidth={false}
              onChange={e =>
                setVariant(variants.find(v => v.name === e.target.value))
              }
            >
              {variants.map(v => (
                <MenuItem value={v.name} key={v.name}>
                  {extractLastNameComponent(v.name)}
                </MenuItem>
              ))}
            </Select>
          </Box>

          <CriterionOptionsProvider value={evaluationContextOptions}>
            <Box
              display="flex"
              flexDirection="column"
              alignItems="start"
              gridGap={16}
              mb={4}
            >
              <div>
                <Typography variant="h6" component="div">
                  Audience
                </Typography>
                <Typography
                  variant="body1"
                  component="div"
                  color="textSecondary"
                >
                  Determines who is targeted to receive the variant.
                </Typography>
              </div>
              {segmentError && (
                <Alert severity="error">{segmentError.message}</Alert>
              )}
              {segment && (
                <TargetingContainer
                  targeting={segment.targeting}
                  onChange={targeting => setSegment({ ...segment, targeting })}
                />
              )}
            </Box>

            <Box marginBottom={4}>
              <RandomizationInput
                value={targetingKeySelector}
                onChange={setTargetingKeySelector}
                helperText="Field in evaluation context used to randomly determine who will receive the variant."
                data-testid="randomization-field"
                minWidth={300}
                required
              />
            </Box>
          </CriterionOptionsProvider>

          <Box
            display="flex"
            flexDirection="column"
            alignItems="start"
            gridGap={16}
          >
            <div>
              <Typography variant="h6" component="div">
                Allocation
              </Typography>
              <Typography variant="body1" component="div" color="textSecondary">
                Limits the variant to a percentage of the audience.
              </Typography>
            </div>
            <Box px={1}>
              <PercentageSlider
                name="rule-allocation-input"
                maxValue={BUCKET_COUNT}
                value={variantAssignment}
                onChange={setVariantAssignment}
                minWidth={450}
              />
            </Box>
          </Box>
        </DialogBody>
        <DialogActions>
          <FormSubmitButtons onCancel={closeDialog} loading={saving} />
        </DialogActions>
      </form>
    </>
  );
};
