import { useCallback } from 'react';

import {
  getError,
  getTypeOrNull,
  useCreateSurfaceMutation,
  useSurfacesQuery,
  useUpdateSurfaceMutation,
} from '@spotify-confidence/plugin-graphql';

/**
 * Companion hook for the SurfaceIndexPage component.
 */
export function useSurfaces() {
  const {
    data,
    loading,
    fetchMore: fetchMoreQuery,
    error,
  } = useSurfacesQuery({
    fetchPolicy: 'cache-and-network',
  });
  const { surfaces = [], nextPageToken } =
    getTypeOrNull(data?.surfaces, 'WorkflowV1ListSurfacesResponse') ?? {};
  const getSurfaceError = getError(data?.surfaces);
  const [createSurfaceMutation] = useCreateSurfaceMutation();
  const [updateSurfaceMutation] = useUpdateSurfaceMutation();

  const createSurface = useCallback(
    async ({
      displayName,
      description,
      owner,
    }: {
      displayName: string;
      description: string;
      owner?: string;
    }) => {
      return await createSurfaceMutation({
        variables: {
          input: {
            displayName,
            description,
            owner,
          },
        },
      });
    },
    [createSurfaceMutation],
  );

  const updateSurface = useCallback(
    async (name: string, displayName: string, description: string) => {
      await updateSurfaceMutation({
        variables: {
          input: {
            name,
            displayName,
            description,
          },
          updateMask: 'displayName,description,owner',
        },
      });
    },
    [updateSurfaceMutation],
  );

  const fetchMore = useCallback(() => {
    if (nextPageToken) {
      fetchMoreQuery({
        variables: {
          pageToken: nextPageToken,
        },
      });
    }
  }, [fetchMoreQuery, nextPageToken]);

  return {
    // data
    surfaces,
    loading,
    error: error ?? getSurfaceError,
    fetchMore,

    // operations
    createSurface,
    updateSurface,
  };
}
