import { useDisplayNames } from '@spotify-confidence/core-react';
import {
  isType,
  useGetDisplayNamesQuery,
} from '@spotify-confidence/plugin-graphql';

type FetchDisplayNamesArgs = {
  metrics?: string[];
  entities?: string[];
  assignmentTables?: string[];
  factTables?: string[];
  skip?: boolean;
};

function buildNameFilter(strings: string[]) {
  if (strings.length) {
    return `name:(${strings.map(s => `"${s}" `)})`;
  }
  return '';
}

export function useFetchDisplayNames({
  metrics = [],
  entities = [],
  assignmentTables = [],
  factTables = [],
  skip,
}: FetchDisplayNamesArgs) {
  const { setDisplayName } = useDisplayNames();

  useGetDisplayNamesQuery({
    skip,
    variables: {
      metricsFilter: buildNameFilter(metrics),
      entitiesFilter: buildNameFilter(entities),
      assignmentTablesFilter: buildNameFilter(assignmentTables),
      factTablesFilter: buildNameFilter(factTables),
    },
    onCompleted: response => {
      const entries: [string, string][] = [];
      if (isType(response?.metrics, 'MetricsV1ListMetricsResponse')) {
        response.metrics.metrics.forEach(metric =>
          entries.push([metric.name, metric.displayName]),
        );
      }
      if (isType(response.entities, 'MetricsV1ListEntitiesResponse')) {
        response.entities.entities.forEach(entity =>
          entries.push([entity.name, entity.displayName]),
        );
      }
      if (
        isType(
          response.assignmentTables,
          'MetricsV1ListAssignmentTablesResponse',
        )
      ) {
        response.assignmentTables.assignmentTables.forEach(table =>
          entries.push([table.name, table.displayName]),
        );
      }
      if (isType(response.factTables, 'MetricsV1ListFactTablesResponse')) {
        response.factTables.factTables.forEach(table =>
          entries.push([table.name, table.displayName]),
        );
      }
      setDisplayName(Object.fromEntries(entries));
    },
  });
}
