import React from 'react';

import {
  Box,
  CircularProgress,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography,
  styled,
} from '@material-ui/core';
import Block from '@material-ui/icons/Block';
import { Alert, Skeleton } from '@material-ui/lab';

import {
  Breadcrumbs,
  ColorUtils,
  ListEmptyState,
  LoadingOverlay,
  PageLayout,
} from '@spotify-confidence/core-react';
import {
  getTypeOrNull,
  useCurrentAccountInfoQuery,
} from '@spotify-confidence/plugin-graphql';
import { useCheckResourcePermissions } from '@spotify-confidence/plugin-permissions-react';
import { format } from 'date-fns';

import { Link } from '@backstage/core-components';
import { useRouteRef } from '@backstage/core-plugin-api';

import { rootRouteRef } from '../../../routes';
import { useDateRange } from '../../hooks/useDateRange';
import { useUsageReport } from '../../hooks/useUsageReport';
import { DateRangeFilter } from './DateRangeFilter';
import { Graph } from './Graph';

export const currencyFormatter = new Intl.NumberFormat('en-US', {
  style: 'currency',
  currency: 'USD',
});

const amountFormatter = new Intl.NumberFormat('en-US', {
  notation: 'compact',
  compactDisplay: 'short',
});

const Circle = styled(Box)(({ theme }) => ({
  width: 25,
  height: 25,
  borderRadius: 25,
  border: `1px solid ${theme.palette.divider}`,
}));
const GraphWrapper = styled('div')({
  height: 330,
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
});

export const ReportsContent = ({ showCost }: { showCost?: boolean }) => {
  const [activeService, setActiveService] = React.useState<string>();

  const { dateRange: dates } = useDateRange({
    onInvalid: () => {},
  });
  const { allowed, loading: loadingPermissions } = useCheckResourcePermissions({
    can: 'view_usage_report',
    name: 'account',
  });

  const usage = useUsageReport({
    dates,
    skip: !allowed,
    showCost,
  });
  const { bySku, total, loading, error } = usage;

  if (loadingPermissions) {
    return <LoadingOverlay loading />;
  }

  if (!allowed) {
    return (
      <ListEmptyState
        title="Missing permissions"
        description="You don't have the necessary permissions to view the reports. Contact your Confidence administrator to gain access."
        supportSnippet={null}
        icon={Block}
      />
    );
  }

  return (
    <>
      <Box display="flex" alignItems="center" justifyContent="space-between">
        <DateRangeFilter />
        <Typography variant="h6">
          {format(new Date(dates.start), 'yyyy-MM-dd')} {' to '}
          {format(new Date(dates.end), 'yyyy-MM-dd')}
        </Typography>
      </Box>
      {error && <Alert severity="error">{error.message}</Alert>}
      <GraphWrapper>
        {loading && usage ? (
          <CircularProgress size="2em" />
        ) : (
          <Graph
            data={usage}
            activeService={activeService}
            showCost={showCost}
          />
        )}
      </GraphWrapper>
      <Table>
        <TableHead>
          <TableRow>
            <TableCell width={30} />
            <TableCell>Service</TableCell>
            <TableCell>Usage</TableCell>
            {showCost && <TableCell align="right">Cost</TableCell>}
          </TableRow>
        </TableHead>
        <TableBody>
          {loading ? (
            <SkeletonTable />
          ) : (
            <>
              {bySku.map((product, i) => (
                <TableRow
                  key={product.sku}
                  data-testid={product.sku}
                  hover
                  onMouseLeave={() => setActiveService(undefined)}
                  onMouseEnter={() => setActiveService(product.sku)}
                >
                  <TableCell>
                    <Circle
                      bgcolor={
                        ColorUtils.colorList[i % ColorUtils.colorList.length]
                      }
                    />
                  </TableCell>
                  <TableCell data-testid="service-column">
                    <strong>{product.sku}</strong>
                  </TableCell>
                  <TableCell data-testid="usage-column">
                    {amountFormatter.format(product.amountSum)}
                  </TableCell>
                  {showCost && (
                    <TableCell align="right" data-testid="cost-column">
                      {currencyFormatter.format(product.billedAmountSum)}
                    </TableCell>
                  )}
                </TableRow>
              ))}
              {showCost && (
                <TableRow>
                  <TableCell colSpan={3} />
                  <TableCell align="right" data-testid="total-cost-column">
                    <strong>{currencyFormatter.format(Number(total))}</strong>
                  </TableCell>
                </TableRow>
              )}
            </>
          )}
        </TableBody>
      </Table>
    </>
  );
};

function SkeletonTable() {
  return (
    <>
      {Array(3)
        .fill(0)
        .map((_n, index) => (
          <TableRow key={`table-${index}`}>
            <TableCell>
              <Circle />
            </TableCell>
            <TableCell>
              <Skeleton variant="text" width={200} />
            </TableCell>
            <TableCell>
              <Skeleton variant="text" width={80} />
            </TableCell>
            <TableCell align="right">
              <Skeleton variant="text" width={20} />
            </TableCell>
          </TableRow>
        ))}
    </>
  );
}

export const Reports = () => {
  const adminRoute = useRouteRef(rootRouteRef);

  const { data } = useCurrentAccountInfoQuery();

  const currentAccountData = getTypeOrNull(
    data?.currentAccount,
    'AdminV1AccountInfo',
  );

  const isTotalConfidence = currentAccountData?.mode === 'TOTAL_CONFIDENCE';

  return (
    <PageLayout
      narrow
      title="Usage reports"
      headerBreadcrumbs={
        <Breadcrumbs>
          <Link to={adminRoute()}>Admin</Link>
          <Typography>Usage reports</Typography>
        </Breadcrumbs>
      }
    >
      <ReportsContent showCost={!isTotalConfidence} />
    </PageLayout>
  );
};
