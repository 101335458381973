import React, { RefObject } from 'react';

import { Theme, makeStyles, useTheme } from '@material-ui/core';

import classNames from 'classnames';
import { scaleTime } from 'd3-scale';
import { Duration, add, isValid, set } from 'date-fns';
import startOfDay from 'date-fns/startOfDay';
import _ from 'lodash';

import { useMeasureDimensions } from '../../hooks';
import { LoadingOverlay } from '../LoadingOverlay';
import { TimeAxis } from './TimeAxis';
import { TimeControls, TimeRange } from './TimeControls';
import { TimelineActivity } from './TimelineActivity';
import {
  ActivityRow,
  Label,
  LabelColumn,
  LabelContent,
  LabelHeader,
  LabelHeaderText,
  MarkerDot,
  MarkerDotWrapper,
  MarkerLine,
  TimelineActivities,
  TimelineColumn,
  TimelineContent,
  TimelineHeader,
} from './TimelineComponents';
import { getTranslateX } from './helpers';
import { Activity, NamedEntity, TimelineActivityValues } from './types';

type Extract<T> = (entry: T) => TimelineActivityValues;
type TimelineProps<T extends NamedEntity> = {
  items: T[];
  extract: Extract<T>;
  margin?: number;
  topOffset?: number;
  getLabel: (item: T) => React.ReactNode;
  renderItem?: (item: T) => React.ReactNode;
  loading?: boolean;
};

const useStyles = makeStyles((theme: Theme) => ({
  labelRow: {
    padding: theme.spacing(0, 1.5),
    borderRight: `1px solid ${theme.palette.divider}`,
    transition: theme.transitions.create('background'),
    '&$hovered, &:hover': {
      background: theme.palette.action.hover,
    },
  },
  hovered: {},
  timeControls: {
    backgroundColor: theme.palette.background.default,
    position: 'absolute',
    right: 0,
    zIndex: 1,
  },
}));

const defaultViewSizeForTimeRange: Record<TimeRange, Duration> = {
  Week: { weeks: 1 },
  Month: { weeks: 2 },
  Quarter: { months: 3 },
  Year: { years: 1 },
};

const timeRangeScrollGranularity: Record<TimeRange, keyof Duration> = {
  Week: 'days',
  Month: 'days',
  Quarter: 'weeks',
  Year: 'months',
};

function getStartFromDate(originDate: Date, timeRange: TimeRange) {
  return add(
    originDate,
    _.mapValues(
      defaultViewSizeForTimeRange[timeRange],
      amount => -1 * (amount ?? 0),
    ),
  );
}

function getEndFromDate(originDate: Date, timeRange: TimeRange) {
  return add(originDate, defaultViewSizeForTimeRange[timeRange]);
}

export const Timeline = <T extends NamedEntity>({
  items,
  extract,
  margin = 3,
  topOffset = 0,
  getLabel,
  renderItem,
  loading,
}: TimelineProps<T>) => {
  const classes = useStyles();
  const theme = useTheme();
  const today = startOfDay(new Date());

  const timelineRef = React.useRef<HTMLDivElement>(null);
  const [, { width: timelineWidth }] = useMeasureDimensions<HTMLDivElement>(
    {
      measureWidth: true,
      measureHeight: false,
    },
    timelineRef,
  );

  const [labelColumnRef, { width: labelColumnWidth }] =
    useMeasureDimensions<HTMLDivElement>({
      measureWidth: true,
      measureHeight: false,
    });

  const [hovered, setHovered] = React.useState<string | null>(null);
  const [timeRange, setTimeRange] = React.useState<TimeRange>('Quarter');
  const [originDate, setOriginDate] = React.useState<Date>(today);

  const start = React.useMemo(
    () => getStartFromDate(originDate, timeRange),
    [originDate, timeRange],
  );
  const end = React.useMemo(
    () => getEndFromDate(originDate, timeRange),
    [originDate, timeRange],
  );

  const goToTime = (date: Date) => {
    if (date) {
      setOriginDate(date);
    }
  };

  const timeScale = React.useMemo(
    () =>
      scaleTime([
        theme.spacing(margin),
        timelineWidth - theme.spacing(margin * 2),
      ]).domain([start, end]),
    [margin, timelineWidth, start, end],
  );

  const todayX = timeScale(today);
  const activityMinWidth = timeScale(set(today, { hours: 16 })) - todayX;

  const activities: Activity<T>[] = React.useMemo(
    () =>
      items.map(item => {
        const params = extract(item);
        const activity: Partial<Activity<T>> = {};

        Object.entries(params).map(([key, date]) => {
          if (date && isValid(date)) {
            const timeKey = key as keyof TimelineActivityValues;
            const positionKey = `${key}X` as keyof Pick<
              Activity<T>,
              'plannedEndX' | 'plannedStartX' | 'startX' | 'endX'
            >;
            activity[timeKey] = date.getTime();
            activity[positionKey] = timeScale(startOfDay(date));
          }
        });

        return { item, ...activity };
      }),
    [items, extract, timeScale],
  );

  const hoverItem = React.useCallback(
    (item: T) => () => {
      setHovered(item.name);
    },
    [],
  );

  const removeHover = React.useCallback(() => setHovered(null), []);

  React.useEffect(() => {
    // Prevent default scroll behaviour in timeline
    if (timelineRef.current) {
      const onWheel = (e: WheelEvent) => {
        if (e.deltaX || e.metaKey || e.ctrlKey) {
          e.preventDefault();
        }
      };
      timelineRef.current.addEventListener('wheel', onWheel);
      return () => timelineRef.current?.removeEventListener('wheel', onWheel);
    }
    return undefined;
  }, []);

  React.useEffect(() => {
    // TODO: investigate if we can scroll normally and update start/end as we get close to the edge
    // Update start/end when scrolling vertically
    if (timelineRef.current) {
      const onWheel = (e: WheelEvent) => {
        const verticalScroll = Math.abs(e.deltaY) > Math.abs(e.deltaX);
        const scrollDelta = verticalScroll ? e.deltaY : e.deltaX;
        const shouldScrollTimeline =
          !verticalScroll || (verticalScroll && (e.ctrlKey || e.metaKey));

        if (scrollDelta === 0 || !shouldScrollTimeline) return;
        const amount =
          scrollDelta > 0
            ? Math.min(scrollDelta, 2)
            : Math.max(scrollDelta, -2);

        const scrollGranularity = timeRangeScrollGranularity[timeRange];
        setOriginDate(current =>
          add(current, {
            [scrollGranularity]: amount,
          }),
        );
      };
      const listener = _.throttle(onWheel, 150);
      timelineRef.current.addEventListener('wheel', listener);
      return () => timelineRef.current?.removeEventListener('wheel', listener);
    }
    return undefined;
  }, [timeRange]);

  return (
    <>
      <TimelineHeader style={{ top: topOffset }}>
        <LabelColumn style={{ width: labelColumnWidth }}>
          <LabelHeader>
            <LabelHeaderText>Name</LabelHeaderText>
          </LabelHeader>
        </LabelColumn>
        <TimelineColumn style={{ width: timelineWidth }}>
          <TimeControls
            className={classes.timeControls}
            originDate={originDate}
            onOriginDateChange={setOriginDate}
            timeRange={timeRange}
            onTimeRangeChange={setTimeRange}
            timeWindowJumpSize={defaultViewSizeForTimeRange[timeRange]}
          />
          <TimeAxis
            timeScale={timeScale}
            start={start}
            end={end}
            timeRange={timeRange}
            width={timelineWidth}
          />
        </TimelineColumn>
        <MarkerDotWrapper style={{ width: timelineWidth }}>
          <MarkerDot style={{ transform: getTranslateX(todayX) }} />
        </MarkerDotWrapper>
      </TimelineHeader>

      <TimelineActivities>
        <LabelContent ref={labelColumnRef as RefObject<HTMLDivElement>}>
          {activities.map(activity => (
            <ActivityRow
              key={activity.item.name}
              className={classNames(classes.labelRow, {
                [classes.hovered]: activity.item.name === hovered,
              })}
              onMouseEnter={hoverItem(activity.item)}
              onMouseLeave={removeHover}
            >
              <Label>{getLabel(activity.item)}</Label>
            </ActivityRow>
          ))}
        </LabelContent>

        <TimelineContent ref={timelineRef}>
          <MarkerLine style={{ transform: getTranslateX(todayX) }} />
          {activities.map(activity => (
            <ActivityRow
              key={activity.item.name}
              onMouseEnter={hoverItem(activity.item)}
              onMouseLeave={removeHover}
            >
              <TimelineActivity
                todayX={todayX}
                activityMinWidth={activityMinWidth}
                activity={activity}
                hovered={hovered === activity.item.name}
                renderItem={renderItem || getLabel}
                onNavigate={goToTime}
                originDate={originDate}
              />
            </ActivityRow>
          ))}
        </TimelineContent>
        <LoadingOverlay loading={loading} />
      </TimelineActivities>
    </>
  );
};
