import { ConfidenceUserApi } from '@spotify-confidence/core-react';
import { print } from 'graphql';

import { ImageUploadClient } from '@backstage/plugin-image-upload';

import { ImageUploadDocument } from '../generated/graphql';

/**
 * Implementation of the ImageUpload API that uses the Confidence API to
 * store images.
 *
 * It depends on the Confidence User API to get the access token to make
 * the request to the Confidence API.
 */
export class ConfidenceImageUploadClient implements ImageUploadClient {
  private readonly userApi: ConfidenceUserApi;

  constructor(userApi: ConfidenceUserApi) {
    this.userApi = userApi;
  }

  async upload(file: File): Promise<string> {
    // get our access token
    const client = await this.userApi.getCredentials();
    const region =
      (await this.userApi.getUser())?.region === 'US' ? 'US' : 'EU';

    const accessToken = await client.getTokenSilently();

    const query = print(ImageUploadDocument);
    const operationName = 'ImageUpload';

    const response = await fetch(
      `https://graphql-${region.toLowerCase()}-konfidens.spotify.com/graphql`,
      {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${accessToken}`,
        },
        body: JSON.stringify({
          query,
          operationName,
        }),
      },
    );

    const { data } = await response.json();
    const { signedUri, imageUri } = data.initiateImageUpload;

    const uploadResponse = await fetch(signedUri, {
      method: 'PUT',
      headers: {
        'Content-Type': file.type,
        // this is a constant header that is required by the Confidence API
        // to upload images.  it's is used to limit the size of the image.
        'X-Goog-Content-Length-Range': '0,10485760',
      },
      body: file,
    });

    if (uploadResponse.status !== 200) {
      throw new Error('Failed to upload image');
    }

    return imageUri;
  }
}
