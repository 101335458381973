import React from 'react';

import { Box } from '@material-ui/core';

import {
  SidebarValue,
  SidebarValueWrapper,
} from '@spotify-confidence/core-react';
import { MetricsV1AggregationType } from '@spotify-confidence/plugin-graphql';

import { ConversionMetricInputsForm } from '../ConversionMetricInputsForm';
import { MetricFilterPreview } from '../MetricFilters/MetricFilterPreview';
import { metricFilterHasEmptyAttributes } from '../MetricFilters/useMetricFilters';
import { MetricSection } from './types';

const conversionMetricInputsSection: MetricSection = {
  name: 'Inputs',
  description:
    'Select the entity you want to measure conversion for and the fact table that tracks the conversion event.',
  canBeEditedSafely: false,
  isValid: metric => {
    if (metricFilterHasEmptyAttributes(metric.filter)) {
      return false;
    }
    if (!metric.entity || !metric.factTable) {
      return false;
    }
    return metric.typeSpec?.averageMetricSpec?.aggregation.type !==
      MetricsV1AggregationType.AggregationTypeCount
      ? !!metric.typeSpec?.averageMetricSpec?.measurement?.name
      : true;
  },
  form: (value, onChange) => (
    <ConversionMetricInputsForm value={value} onChange={onChange} />
  ),
  summary: (metric, renderValues) => (
    <SidebarValueWrapper>
      <SidebarValue
        name="Fact table"
        value={renderValues?.[metric.factTable]?.() || metric.factTable}
      />
      <Box display="grid" gridTemplateColumns="1fr 1fr">
        <SidebarValue
          name="Entity"
          value={renderValues?.[metric.entity]?.() || metric.entity}
        />
        {metric.typeSpec?.averageMetricSpec?.measurement?.name && (
          <SidebarValue
            name="Conversion column"
            value={metric.typeSpec.averageMetricSpec.measurement.name}
          />
        )}
      </Box>
      {metric.filter && (
        <SidebarValue
          name="Filter"
          value={<MetricFilterPreview filter={metric.filter} />}
        />
      )}
    </SidebarValueWrapper>
  ),
  updateMask: 'entity,factTable,typeSpec.averageMetricSpec,filter',
};

// Used in the create form
export const createConversionMetricSections: MetricSection[] = [
  conversionMetricInputsSection,
];

// Used in the create summary + metric page sidebar
export const editConversionMetricSections: MetricSection[] = [
  conversionMetricInputsSection,
];
