import { useAsyncFn } from 'react-use';

export function useValidateAccountName() {
  return useAsyncFn(
    async ({ loginId, inviteId }: { loginId?: string; inviteId?: string }) => {
      let url = `https://onboarding.confidence.dev/v1/loginIdAvailability:check?login_id=${loginId}`;

      if (inviteId) {
        url += `&invite=accountInvites%2f${inviteId}`;
      }
      if (loginId) {
        const response = await fetch(url);
        return await response.json();
      }
      return undefined;
    },
    [],
  );
}
