import { MetricDirection } from '../../../MetricsModule';

export type Estimate = {
  estimate: number;
  stdErr?: number | null;
  lower: number;
  upper: number;
  powered?: number | null;
  isSignificant?: boolean;
};

export type Context =
  | 'CONTEXT_UNSPECIFIED'
  | 'CONTEXT_HYPOTHESIS'
  | 'CONTEXT_SEGMENT'
  | 'CONTEXT_COMPARISON'
  | 'CONTEXT_GROUP'
  | 'CONTEXT_UNKNOWN';

export type ContextItem = {
  key: Context;
  value: string;
};

export type AnnotationErrorCategory =
  | 'ERROR_CATEGORY_UNSPECIFIED'
  | 'ERROR_CATEGORY_GENERAL'
  | 'ERROR_CATEGORY_DATA_VALIDATION_ERROR'
  | 'ERROR_CATEGORY_ANALYSIS_ERROR'
  | 'ERROR_CATEGORY_NO_DATA';

export type AnnotationError = {
  details?: string;
  category?: AnnotationErrorCategory;
};

export type Annotation = {
  context?: ContextItem[];
  info?: string;
  warning?: string;
  error?: AnnotationError;
};

export enum ShippingRecommendation {
  SHIP = 'SHIP',
  SHIP_WITH_CAUTION = 'SHIP_WITH_CAUTION',
  CONTINUE = 'CONTINUE',
  MAYBE_SHIP = 'MAYBE_SHIP',
  END = 'END',
  ABORT = 'ABORT',
  DONT_SHIP = 'DONT_SHIP',
  WAIT_FOR_DATA = 'WAIT_FOR_DATA',
  ENOUGH_DATA_COLLECTED = 'ENOUGH_DATA_COLLECTED',
}

export enum QualityStatus {
  ALL_SUCCEEDED = 'ALL_SUCCEEDED',
  SOME_FAILED = 'SOME_FAILED',
  SOME_PENDING = 'SOME_PENDING',
}

export enum TankingStatus {
  ALL_SUCCEEDED = 'ALL_SUCCEEDED',
  SOME_FAILED = 'SOME_FAILED',
  SOME_PENDING = 'SOME_PENDING',
  NO_METRICS = 'NO_METRICS',
}

export enum GuardrailStatus {
  ALL_POWERED_ALL_SIGNIFICANT = 'ALL_POWERED_ALL_SIGNIFICANT',
  SOME_UNPOWERED_ALL_SIGNIFICANT = 'SOME_UNPOWERED_ALL_SIGNIFICANT',
  SOME_MISSING = 'SOME_MISSING',
  SOME_POWERED_NOT_SIGNIFICANT = 'SOME_POWERED_NOT_SIGNIFICANT',
  SOME_NOT_SIGNIFICANT = 'SOME_NOT_SIGNIFICANT',
  ALL_POWERED_NONE_SIGNIFICANT = 'ALL_POWERED_NONE_SIGNIFICANT',
  WITHOUT_NIM_SOME_DETERIORATED = 'WITHOUT_NIM_SOME_DETERIORATED',
  WITHOUT_NIM_NONE_DETERIORATED = 'WITHOUT_NIM_NONE_DETERIORATED',
  SOME_UNPOWERED_UNKNOWN_SIGNIFICANCE = 'SOME_UNPOWERED_UNKNOWN_SIGNIFICANCE',
  ALL_POWERED_UNKNOWN_SIGNIFICANCE = 'ALL_POWERED_UNKNOWN_SIGNIFICANCE',
  NO_GUARDRAILS = 'NO_GUARDRAILS',
  WAIT_FOR_END = 'WAIT_FOR_END',
}

export enum SuccessStatus {
  SOME_POWERED_SIGNIFICANT = 'SOME_POWERED_SIGNIFICANT',
  SOME_UNPOWERED_SIGNIFICANT = 'SOME_UNPOWERED_SIGNIFICANT',
  ALL_POWERED_NONE_SIGNIFICANT = 'ALL_POWERED_NONE_SIGNIFICANT',
  NONE_SIGNIFICANT = 'NONE_SIGNIFICANT',
  NO_SUCCESS_METRIC = 'NO_SUCCESS_METRIC',
  SOME_UNPOWERED_UNKNOWN_SIGNIFICANCE = 'SOME_UNPOWERED_UNKNOWN_SIGNIFICANCE',
  ALL_POWERED_UNKNOWN_SIGNIFICANCE = 'ALL_POWERED_UNKNOWN_SIGNIFICANCE',
  SOME_MISSING = 'SOME_MISSING',
  WAIT_FOR_END = 'WAIT_FOR_END',
}

export type ComparisonRecommendation = {
  baseline: string;
  compared: string;

  // This is optional to keep backwards compatibility with old workflows
  quality?: {
    status: QualityStatus;
    success?: string[];
    pending?: string[];
    failed?: string[];
  };

  tanking: {
    status: TankingStatus;
    success?: string[];
    pending?: string[];
    failed?: string[];
  };

  guardrail: {
    status: GuardrailStatus;
    poweredSignificant?: string[];
    unpoweredSignificant?: string[];
    poweredNotSignificant?: string[];
    unpoweredNotSignificant?: string[];
    missing?: string[];
  };

  success: {
    status: SuccessStatus;
    poweredSignificant?: string[];
    unpoweredSignificant?: string[];
    poweredNotSignificant?: string[];
    unpoweredNotSignificant?: string[];
    missing?: string[];
  };

  recommendation: ShippingRecommendation;
};

export enum StatsDataType {
  DATA_TYPE_UNSPECIFIED = 'DATA_TYPE_UNSPECIFIED',
  DATA_TYPE_CONTINUOUS = 'DATA_TYPE_CONTINUOUS',
  DATA_TYPE_BINARY = 'DATA_TYPE_BINARY',
  DATA_TYPE_COUNT = 'DATA_TYPE_COUNT',
}

export enum MetricAnalysisType {
  UNKNOWN = 'UNKNOWN',
  SUCCESS = 'SUCCESS',
  GUARDRAIL = 'GUARDRAIL',
  TANKING = 'TANKING',
  QUALITY = 'QUALITY',
}

export enum MetricResultStatus {
  UNKNOWN = 'UNKNOWN',
  NOT_SIGNIFICANT = 'NOT_SIGNIFICANT',
  DESIRED_SIGNIFICANT = 'DESIRED_SIGNIFICANT',
  UNDESIRED_SIGNIFICANT = 'UNDESIRED_SIGNIFICANT',
  DESIRED_AND_UNDESIRED_SIGNIFICANT = 'DESIRED_AND_UNDESIRED_SIGNIFICANT',
}

export enum Method {
  Z_TEST = 'Z_TEST',
  GST_Z_TEST = 'GST_Z_TEST',
  ASYMP_CS = 'ASYMP_CS',
  RATIO = 'RATIO',
  GST_RATIO = 'GST_RATIO',
  ASYMP_CS_RATIO = 'ASYMP_CS_RATIO',
  UNKNOWN = 'UNKNOWN',
}

export type StatsSettings = {
  method: Method;
  adjustedAlpha: number;
  adjustedBeta?: number;
  hasVarianceReduction: boolean;
};

export type MetricStatus = {
  status: MetricResultStatus;
  message: string;
  metricType: MetricAnalysisType; // MDE/NIM, direction
  decisionPowered: boolean;
  decisionIsSignificant: boolean;
  validationIsSignificant: boolean;
};

export type StatsResult = {
  time: string;
  baselineEstimateAbs: number;
  comparedEstimateAbs: number;
  baselineEstimateRel: number;
  comparedEstimateRel: number;
  differenceEstimateAbs: Estimate;
  differenceEstimateRel: Estimate;
  varianceReductionRate?: number;
  unadjustedEstimateBaseline?: number;
  unadjustedEstimateCompared?: number;
};

export type StatsGroupStats = {
  id?: string;
  sampleSize?: string;
};

export type StatsComparisonPair = {
  baseline: string;
  compared: string;
};

export type SampleSizeEstimate = {
  current: number;
  required: number;
  poweredEffectRel?: number;
  poweredEffectAbs?: number;
};

export type StatsComparisonResult = {
  status?: MetricStatus;
  groups: StatsComparisonPair;
  result?: StatsResult[];
  lastResult?: StatsResult;
  sampleSize: SampleSizeEstimate;
  isSignificant?: boolean;
  comparisonId?: string;
  usedDataType?: StatsDataType;
  groupStats?: StatsGroupStats[];
  dimensions?: Record<string, string>;
  segmentId: string;
};

export type MetricDetails = {
  metricType: MetricAnalysisType;
  plannedEffectSize?: number;
  direction?: MetricDirection;
  hasRun?: boolean;
};

export type StatsHypothesisResult = {
  id: string;
  statsSettings?: StatsSettings;
  result?: StatsComparisonResult[];
  annotations: Annotation[];
  metricDetails: MetricDetails;
};

export type StatsHypothesisResults = {
  results: StatsHypothesisResult[];
  annotations: Annotation[];
};

type Recommendation = {
  status: {
    quality?: QualityStatus;
    tanking: TankingStatus;
    guardrail: GuardrailStatus;
    success: SuccessStatus;
  };
  shipping: ShippingRecommendation;
};

export type TreatmentInformation = {
  id: string;
  recommendation?: Recommendation;
};

export type AnalyzeResult = {
  annotations: Annotation[];
  results: StatsHypothesisResult[];
  treatments: TreatmentInformation[];
};
