/* eslint-disable no-nested-ternary */
import React from 'react';

import { ApolloProvider } from '@apollo/client';
import { createApolloClient } from '@spotify-confidence/plugin-graphql';

import { configApiRef, useApi } from '@backstage/core-plugin-api';

import { DialogProvider } from '../../components';
import { Region } from '../../utils';
import { AuthenticationProvider } from '../auth-factory';
import { CLIENT_ID } from '../auth-factory/auth0/auth';

export type ConfidenceCoreContextType = {
  config: { [x: string]: any };
  setConfig: (v: { [x: string]: any }) => void;
  displayNames: Map<string, string>;
  setDisplayName: (v: Record<string, string>) => void;
  region?: Region;
};

const defaultValue = {
  config: {
    clientId: CLIENT_ID,
  },
  setConfig: () => {},
  displayNames: new Map(),
  setDisplayName: () => {},
};

const ConfidenceCoreContext =
  React.createContext<ConfidenceCoreContextType>(defaultValue);

export const ConfidenceCoreProvider = ({
  config: { clientId, ...c } = defaultValue.config,
  children,
}: React.PropsWithChildren<
  Partial<Pick<ConfidenceCoreContextType, 'config'>>
>) => {
  const [token, setToken] = React.useState<string>();
  const [region, setRegion] = React.useState<Region>();
  const [config, setConfig] = React.useState(c);

  const appConfig = useApi(configApiRef);

  const [displayNames, setDisplayNames] = React.useState<
    ConfidenceCoreContextType['displayNames']
  >(defaultValue.displayNames);

  const handleTokenReceived = (userToken: string, userRegion: Region) => {
    setToken(userToken);
    setRegion(userRegion);
  };

  const apolloClient = React.useMemo(() => {
    const uri = appConfig.getOptionalString('confidence.graphql')
      ? appConfig.getOptionalString('confidence.graphql')
      : region
      ? `https://graphql-${region.toLowerCase()}-konfidens.spotify.com/graphql`
      : undefined;
    return createApolloClient({
      token,
      uri,
      region,
    });
  }, [token, region]);

  const updateDisplayName = React.useCallback(
    (v: Record<string, string>) => {
      setDisplayNames(current => {
        const newMap = new Map(current);
        Object.entries(v).forEach(([key, value]) => {
          newMap.set(key, value);
        });
        return newMap;
      });
    },
    [setDisplayNames],
  );

  const value = React.useMemo<ConfidenceCoreContextType>(
    () => ({
      config,
      setConfig,
      displayNames,
      setDisplayName: updateDisplayName,
      region,
    }),
    [config, displayNames, setDisplayNames],
  );

  return (
    <ConfidenceCoreContext.Provider value={value}>
      <AuthenticationProvider
        clientId={clientId}
        onTokenReceived={handleTokenReceived}
      >
        <ApolloProvider client={apolloClient}>
          <DialogProvider>{children} </DialogProvider>
        </ApolloProvider>
      </AuthenticationProvider>
    </ConfidenceCoreContext.Provider>
  );
};

export const useConfidenceCoreContext = () =>
  React.useContext(ConfidenceCoreContext);

export const useDisplayNames = () => {
  const { displayNames, setDisplayName } = React.useContext(
    ConfidenceCoreContext,
  );

  return {
    displayNames,
    setDisplayName,
  };
};

export const useRegion = () => {
  const { region } = React.useContext(ConfidenceCoreContext);
  return region;
};
