import React from 'react';

import { Box, Chip } from '@material-ui/core';

import { ResourceLink } from '@spotify-confidence/core-react';

import { entityRouteRef } from '../../../../../routes';
import { SchemaItem } from '../types';
import { formatKind, formatSemanticType, getSemanticKind } from '../valueUtils';

type SchemaItemValueProps = {
  override: SchemaItem;
};

// We are wrapping text within fragments to make TypeScript happy
export const SchemaItemValue = ({ override }: SchemaItemValueProps) => {
  const semanticType = override.semanticType;
  const semanticTypeKind = getSemanticKind(semanticType);
  const kind = formatKind(override.kind);

  if (semanticType?.country) {
    if (semanticType.country.format === 'TWO_LETTER_ISO_CODE') {
      return <>Country code</>;
    }
    return <>Country</>;
  }
  if (semanticType?.entityReference) {
    const entity = semanticType.entityReference.entity;
    if (entity.__typename === 'Error') {
      return <>Entity</>;
    } else if (entity.__typename === 'MetricsV1Entity') {
      return (
        <ResourceLink routeRef={entityRouteRef} name={entity.name}>
          {entity.displayName}
        </ResourceLink>
      );
    }
  }
  if (semanticType?.enumType) {
    return (
      <Box display="flex" gridGap={4} flexWrap="wrap" maxWidth={400}>
        {semanticType.enumType.values.map((value, index) => (
          <Chip
            key={value.value || index}
            size="small"
            label={value.value}
            disabled={Boolean(override.hidden)}
          />
        ))}
      </Box>
    );
  }
  return <>{semanticTypeKind ? formatSemanticType(semanticTypeKind) : kind}</>;
};
