import React from 'react';

import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Link,
} from '@material-ui/core';
import Typography from '@material-ui/core/Typography';
import { Alert, AlertTitle } from '@material-ui/lab';

export interface ErrorBoundaryProps {
  onDidCatch: (message: string) => void;
  passiveGuard?: boolean;
  children?: React.ReactNode | JSX.Element;
  renderFallback?: (props: {
    message: string;
  }) => React.ReactNode | JSX.Element;
}

export interface ErrorBoundaryState {
  hasError: boolean;
  error?: Error;
}

const Fallback = ({ message, stack }: { message: string; stack?: string }) => (
  <Alert severity="error">
    <AlertTitle>Oops, something went wrong...</AlertTitle>
    <Typography variant="body2">
      Sorry, looks like we encountered an error. Try to refresh the page or{' '}
      <strong>
        contact us in <Link style={{ color: 'inherit' }} href="#" /> for help.
      </strong>
    </Typography>
    <Box mt={2}>
      <Accordion elevation={0}>
        <AccordionSummary>Error details</AccordionSummary>
        <AccordionDetails>
          <Box>
            <Typography variant="caption" paragraph>
              <strong>{message}</strong>
            </Typography>
            <Typography variant="body2" color="textSecondary">
              {stack}
            </Typography>
          </Box>
        </AccordionDetails>
      </Accordion>
    </Box>
  </Alert>
);

export class ErrorBoundary extends React.Component<
  ErrorBoundaryProps,
  ErrorBoundaryState
> {
  constructor(props: ErrorBoundaryProps) {
    super(props);
    this.state = { hasError: false };
  }

  static getDerivedStateFromError(error: Error) {
    return { hasError: true, error };
  }

  componentDidCatch(e: Error) {
    this.props.onDidCatch(e.message || 'Something went wrong');

    if (this.props.passiveGuard) {
      throw e;
    }
  }

  render() {
    const { hasError, error } = this.state;
    if (!hasError) {
      return this.props.children;
    }

    const { message = '', stack = '' } = error || {};
    const fallbackProps = { message, stack };

    return (
      this.props.renderFallback?.(fallbackProps) || (
        <Fallback {...fallbackProps} />
      )
    );
  }
}
