import React from 'react';

import { Button, Collapse, List } from '@material-ui/core';
import Add from '@material-ui/icons/Add';

import { EventSchemaEntryFragment } from '@spotify-confidence/plugin-graphql';

import {
  findIdentifier,
  getNestedSchema,
} from '../../../domain/schema.helpers';
import { MissingFieldsWarning } from '../../MissingFieldsWarning';
import { useSchemaContext } from '../SchemaContext';
import { DraftEventEntry } from './DraftEventEntry';
import { useDraftSchemaEntries } from './useDraftSchemaEntries';

type DraftSchemaEntriesProps = {
  draftSchema: EventSchemaEntryFragment[];
};

export const DraftSchemaEntries = ({
  draftSchema,
}: DraftSchemaEntriesProps) => {
  const [open, setOpen] = React.useState(false);
  const { schema } = useSchemaContext();
  const { handleAddAll, newFields, isExistingField } = useDraftSchemaEntries({
    draftSchema,
  });
  const hasNestedField = newFields.some(f => getNestedSchema(f.value));

  React.useEffect(() => {
    if (newFields.length === 0) {
      setOpen(false);
    } else {
      setOpen(true);
    }
  }, [newFields.length]);

  return (
    <Collapse in={open} unmountOnExit>
      <MissingFieldsWarning
        onClose={() => setOpen(false)}
        alignActions="end"
        actions={
          <Button
            size="small"
            variant="outlined"
            color="inherit"
            onClick={handleAddAll}
            startIcon={<Add />}
          >
            Add all
          </Button>
        }
      >
        <List>
          {newFields.map((field, i) => {
            const identifier = findIdentifier(schema, [field.key], '0');

            // We need a non-existing identifier to not accidentally update another field
            const fallbackIdentifier = `0.${schema.length + i}`;

            return (
              <DraftEventEntry
                key={field.key}
                path={[]}
                identifier={identifier || fallbackIdentifier}
                entry={field}
                isExisting={isExistingField}
                hasNestedField={hasNestedField}
              />
            );
          })}
        </List>
      </MissingFieldsWarning>
    </Collapse>
  );
};
