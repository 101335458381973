import React from 'react';

import { Button, Typography } from '@material-ui/core';
import Add from '@material-ui/icons/Add';

import {
  ActionBar,
  Breadcrumbs,
  FilterBar,
  FilterListProvider,
  PageLayout,
  useDialog,
  useMeasureDimensions,
} from '@spotify-confidence/core-react';
import { useCheckResourcePermissions } from '@spotify-confidence/plugin-permissions-react';

import { Link } from '@backstage/core-components';
import { useRouteRef } from '@backstage/core-plugin-api';

import { rootRouteRef } from '../../../routes';
import { CreateEventConnectionDialog } from '../CreateEventConnectionDialog';
import { EventConnectionList } from './EventConnectionList';

export const EventConnectionIndexPage = () => {
  const adminRoute = useRouteRef(rootRouteRef);
  const { openDialog } = useDialog();
  const [ref, { height }] = useMeasureDimensions<HTMLDivElement>();
  const { allowed } = useCheckResourcePermissions({
    can: 'create_event_connection',
    name: 'account',
  });
  const handleCreate = () => {
    openDialog({
      dialogProps: {
        fullScreen: true,
      },
      content: <CreateEventConnectionDialog />,
    });
  };

  return (
    <FilterListProvider storageKey="event-connections">
      <PageLayout
        narrow
        title="Event connections"
        headerBreadcrumbs={
          <Breadcrumbs>
            <Link to={adminRoute()}>Admin</Link>
            <Typography>Event connections</Typography>
          </Breadcrumbs>
        }
      >
        <ActionBar ref={ref}>
          <FilterBar />
          <Button
            size="small"
            variant="contained"
            color="primary"
            startIcon={<Add />}
            onClick={handleCreate}
            disabled={!allowed}
          >
            Create
          </Button>
        </ActionBar>

        <EventConnectionList topOffset={height} />
      </PageLayout>
    </FilterListProvider>
  );
};
