import React from 'react';

import {
  Checkbox,
  Collapse,
  FormHelperText,
  ListItemText,
  MenuItem,
  TextField,
  Typography,
  makeStyles,
} from '@material-ui/core';

import {
  MetricsV1AggregationThreshold,
  MetricsV1AggregationThresholdDirection,
  MetricsV1AggregationType,
  MetricsV1AggregationThresholdDirection as ThresholdDirection,
} from '@spotify-confidence/plugin-graphql';
import _ from 'lodash';

import {
  getAggregationThresholdDirectionDetails,
  getAggregationTypeLabel,
} from './aggregationUtils';

const useStyles = makeStyles(theme => ({
  wrapper: {
    display: 'grid',
    columnGap: theme.spacing(2),
    alignItems: 'flex-start',
    gridTemplateColumns: 'max-content 1fr',
  },
  label: {
    cursor: 'pointer',
  },
  thresholdInput: {
    gridRow: 2,
    gridColumn: 2,
  },
  alignedFields: {
    display: 'flex',
    marginTop: theme.spacing(1),
    gap: theme.spacing(2),
  },
  checkboxRoot: {
    padding: 0,
  },
}));

type AggregationThresholdInputProps = {
  value?: MetricsV1AggregationThreshold | null;
  onChange: (newValue: MetricsV1AggregationThreshold | null) => void;
  disabled?: boolean;
  entityName?: string;
  measurementName?: string;
  aggregationType?: MetricsV1AggregationType;
};

export function AggregationThresholdInput({
  value,
  onChange,
  disabled,
  entityName,
  measurementName,
  aggregationType,
}: AggregationThresholdInputProps) {
  const classes = useStyles();
  const useThreshold = Boolean(value);

  const directionValue =
    value?.direction || ThresholdDirection.AggregationThresholdDirectionEq;
  const thresholdValue = _.isNil(value?.threshold.value)
    ? ''
    : value?.threshold.value;

  const onDirectionChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const newDirection = e.target.value as ThresholdDirection;
    onChange({
      threshold: { value: thresholdValue },
      direction: newDirection,
    });
  };

  const onThresholdChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const newThreshold = e.target.value as string;
    onChange({
      direction: directionValue,
      threshold: { value: newThreshold },
    });
  };

  return (
    <div className={classes.wrapper}>
      <Checkbox
        classes={{
          root: classes.checkboxRoot,
        }}
        disabled={disabled}
        checked={useThreshold}
        name="use-threshold-checkbox"
        id="use-threshold-checkbox"
        onChange={e => {
          const isEnabled = e.target.checked;
          onChange(
            isEnabled
              ? {
                  direction:
                    MetricsV1AggregationThresholdDirection.AggregationThresholdDirectionEq,
                  threshold: {
                    value: '0',
                  },
                }
              : null,
          );
        }}
      />
      <label className={classes.label} htmlFor="use-threshold-checkbox">
        <Typography variant="body1" gutterBottom>
          Map aggregated values to booleans
        </Typography>
        <FormHelperText>
          Convert values to 0 or 1 based on a limit. Commonly used in
          conversion-like metrics, for example "fraction of users that listened
          to 5 or more songs".
        </FormHelperText>
      </label>
      <Collapse
        in={useThreshold}
        unmountOnExit
        className={classes.thresholdInput}
      >
        <div className={classes.alignedFields}>
          <TextField
            required
            variant="outlined"
            margin="dense"
            select
            value={directionValue}
            onChange={onDirectionChange}
            disabled={disabled}
            label="Comparison"
            name="comparison"
            style={{ minWidth: 100 }}
            SelectProps={{
              renderValue: d =>
                getAggregationThresholdDirectionDetails(d as ThresholdDirection)
                  .label,
            }}
          >
            {Object.values(ThresholdDirection).map(direction => {
              const details =
                getAggregationThresholdDirectionDetails(direction);
              return (
                <MenuItem key={direction} value={direction} dense>
                  <ListItemText
                    primary={details.label}
                    secondary={details.description}
                  />
                </MenuItem>
              );
            })}
          </TextField>
          <TextField
            required
            variant="outlined"
            margin="dense"
            label="Threshold"
            name="threshold-field"
            value={thresholdValue}
            onChange={onThresholdChange}
            type="number"
            disabled={disabled}
          />
        </div>
        <FormHelperText>
          Treat value within {entityName || 'entity'} as true if the{' '}
          {aggregationType
            ? getAggregationTypeLabel(aggregationType)
            : 'aggregation'}{' '}
          of {measurementName || 'measurement'} is{' '}
          {getAggregationThresholdDirectionDetails(
            directionValue,
          ).description.toLowerCase()}{' '}
          {thresholdValue}.
        </FormHelperText>
      </Collapse>
    </div>
  );
}
