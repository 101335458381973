import React from 'react';
import { useParams } from 'react-router-dom';

import { MenuItem } from '@material-ui/core';
import SaveAltIcon from '@material-ui/icons/SaveAlt';

import { ActionMenu, SectionButton } from '@spotify-confidence/core-react';
import { Table } from '@tanstack/react-table';
import { toPng } from 'html-to-image';

import { ResultData } from '../types';

export type DownloadResultsButtonType = 'timeline' | 'interval plot' | 'none';

type DownloadResultsButtonProps = {
  table: Table<ResultData>;
  filenameParams?: Record<string, string | undefined>;
  downloadableRef?: React.RefObject<HTMLElement> | null;
};

function downloadFile(
  href: string,
  filenameParams: DownloadResultsButtonProps['filenameParams'] = {},
  fileType?: string,
) {
  const link = document.createElement('a');
  link.download = `confidence-${Object.values(filenameParams).join('-')}`;
  if (fileType) {
    link.download += `.${fileType}`;
  }
  link.href = href;
  link.click();
  link.remove();
}

export const DownloadResultsButton = ({
  table,
  filenameParams,
  downloadableRef,
}: DownloadResultsButtonProps) => {
  const params = useParams();

  const downloadPng = () => {
    if (downloadableRef?.current) {
      toPng(downloadableRef.current, { cacheBust: true })
        .then(dataUrl => {
          downloadFile(dataUrl, { ...params, ...filenameParams });
        })
        // eslint-disable-next-line no-console
        .catch(console.log);
    }
  };

  const downloadJson = () => {
    const rows = table.getRowModel().flatRows.map(r => r.original);
    const dataString = `data:text/json;charset=utf-8,${encodeURIComponent(
      JSON.stringify(rows),
    )}`;
    downloadFile(dataString, { ...params, ...filenameParams }, 'json');
  };

  const options = [
    <MenuItem key="json" onClick={downloadJson}>
      JSON
    </MenuItem>,
  ];

  if (downloadableRef?.current) {
    options.push(
      <MenuItem key="png" onClick={downloadPng}>
        PNG
      </MenuItem>,
    );
  }

  return (
    <ActionMenu
      renderButton={props => (
        <SectionButton
          variant="text"
          size="small"
          startIcon={<SaveAltIcon />}
          {...props}
        >
          Download results
        </SectionButton>
      )}
      id="download-results"
      buttons={options}
    />
  );
};
