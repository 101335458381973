import React from 'react';

import {
  IconButton,
  IconButtonProps,
  Paper,
  PaperProps,
  Typography,
  alpha,
  makeStyles,
  styled,
} from '@material-ui/core';
import ArrowBack from '@material-ui/icons/ArrowBack';
import ArrowForward from '@material-ui/icons/ArrowForward';

import classNames from 'classnames';

import { createBorderRadius, getTranslateX } from '../helpers';

export const TimelineActivityWrapper = styled('div')(({ theme }) => ({
  padding: theme.spacing(0, 2),
  transition: theme.transitions.create(['transform']),
}));

export const TimelineActivityContent = styled(props => (
  <Typography variant="body2" component="div" {...props} />
))(({ theme }) => ({
  padding: theme.spacing(0, 1),
  whiteSpace: 'nowrap',
  display: 'inline-flex',
  gap: theme.spacing(1),
  alignItems: 'center',
  zIndex: 1,
  position: 'relative',
}));

const useLateBorderStyles = makeStyles(theme => ({
  root: {
    position: 'absolute',
    top: -1,
    bottom: -1,
    left: -1,
    zIndex: 1,
    border: `1px solid ${theme.palette.error.main}`,
    pointerEvents: 'none',
    borderRight: 'none',
    borderRadius: createBorderRadius(theme.shape.borderRadius, 0),
  },
  showRightBorder: {
    borderRight: `1px solid ${theme.palette.error.main}`,
    borderRadius: theme.shape.borderRadius,
  },
}));

export const LateStartBorder = ({
  showRightBorder,
  ...props
}: React.HTMLProps<HTMLDivElement> & { showRightBorder?: boolean }) => {
  const classes = useLateBorderStyles();
  return (
    <div
      className={classNames(classes.root, {
        [classes.showRightBorder]: showRightBorder,
      })}
      {...props}
    />
  );
};

export const OverduePlanLine = styled('div')(({ theme }) => ({
  position: 'absolute',
  top: 0,
  bottom: 0,
  width: 1,
  borderLeft: `1px dashed ${theme.palette.error.main}`,
  transition: theme.transitions.create(['transform']),
}));

export const TimelineActivityOverflowOverlay = styled('div')(({ theme }) => ({
  position: 'absolute',
  transform: getTranslateX('100%'),
  top: 0,
  bottom: 0,
  right: -1,
  width: '100vw',
  zIndex: 2,
  background: alpha(theme.palette.background.default, 0.5),
  transition: theme.transitions.create(['transform']),
  pointerEvents: 'none',
}));

const useSinglePlannedDateStyles = makeStyles(theme => ({
  root: {
    position: 'relative',
    padding: theme.spacing(0.5, 1),
    height: '100%',
    transition: theme.transitions.create(['transform']),
    borderStyle: 'dashed',
    borderColor: theme.palette.text.secondary,
    borderWidth: 0,
  },
  overdue: {
    borderColor: theme.palette.error.main,
  },
}));

const PlannedDateLine = ({
  overdue,
  children,
  className,
}: React.PropsWithChildren<{
  overdue?: boolean;
  className?: string;
}>) => {
  const classes = useSinglePlannedDateStyles();
  return (
    <div
      className={classNames(className, classes.root, {
        [classes.overdue]: overdue,
      })}
      children={children}
    />
  );
};

export const OnlyPlannedStartLine = styled(PlannedDateLine)({
  left: -1,
  borderLeftWidth: 1,
});

export const OnlyPlannedEndLine = styled(PlannedDateLine)({
  right: -1,
  borderRightWidth: 1,
  transform: 'translateX(-100%)',
});

const usePlannedBorderStyles = makeStyles(theme => ({
  plannedBorder: {
    width: 'calc(100% + 2px)',
    borderRadius: theme.shape.borderRadius,
    transition: theme.transitions.create(['width']),
    pointerEvents: 'none',
    position: 'absolute',
    top: -1,
    bottom: -1,
    right: -2,
    zIndex: 2,
    border: `3px dotted ${theme.palette.background.default}`,
    borderLeft: 'none',
  },
  showLeftBorder: {
    left: -1,
    borderLeft: `3px dotted ${theme.palette.background.default}`,
    marginLeft: 0,
  },
  noRightBorder: {
    borderRight: 'none',
    borderRadius: createBorderRadius(theme.shape.borderRadius, 0),
  },
}));

export const PlannedBorderOverlay = ({
  showLeftBorder,
  noRightBorder,
  style,
}: {
  showLeftBorder?: boolean;
  noRightBorder?: boolean;
  style?: React.CSSProperties;
}) => {
  const classes = usePlannedBorderStyles();
  return (
    <div
      className={classNames(classes.plannedBorder, {
        [classes.showLeftBorder]: showLeftBorder,
        [classes.noRightBorder]: noRightBorder,
      })}
      style={style}
    />
  );
};

const useLateBorderOverlayStyles = makeStyles(theme => ({
  border: {
    position: 'absolute',
    top: -1,
    bottom: -1,
    right: 0,
    zIndex: 1,
    border: `1px solid ${theme.palette.error.main}`,
    pointerEvents: 'none',
    borderLeft: 'none',
    borderRadius: createBorderRadius(0, theme.shape.borderRadius),
  },
  noRightBorder: {
    borderRight: 'none',
    borderRadius: 0,
  },
}));

export const LateBorderOverlay = ({
  noRightBorder,
  style,
}: {
  noRightBorder: boolean;
  style?: React.CSSProperties;
}) => {
  const classes = useLateBorderOverlayStyles();
  return (
    <div
      className={classNames(classes.border, {
        [classes.noRightBorder]: noRightBorder,
      })}
      style={style}
    />
  );
};

const useTimelineActivityBorderClasses = makeStyles(theme => {
  const invisibleBorderStyles = {
    position: 'absolute',
    content: '""',
    borderRadius: theme.shape.borderRadius,
    width: theme.shape.borderRadius * 2,
    height: theme.shape.borderRadius * 2,
    background: theme.palette.background.default,
  };
  return {
    paper: {
      borderRadius: theme.shape.borderRadius,
      padding: theme.spacing(0.5, 0),
      background: 'transparent',
      borderColor: theme.palette.text.primary,
      transition: theme.transitions.create([
        'background',
        'transform',
        'width',
      ]),
      '&$hovered, &:hover': {
        background: theme.palette.action.hover,
        '&$invisibleLeftBorder:before, &$invisibleLeftBorder:after, &$invisibleRightBorder:before, &$invisibleRightBorder:after':
          { opacity: 0 },
      },
    },
    hovered: {},
    invisibleLeftBorder: {
      borderLeftColor: theme.palette.background.default,
      '&:before, &:after': {
        ...invisibleBorderStyles,
        left: -1,
      },
      '&:before': { top: -1 },
      '&:after': { bottom: -1 },
    },
    invisibleRightBorder: {
      borderRightColor: theme.palette.background.default,
      '&:before, &:after': {
        ...invisibleBorderStyles,
        right: -1,
      },
      '&:before': { top: -1 },
      '&:after': { bottom: -1 },
    },
    noLeftBorder: {
      borderLeft: 'none',
      borderRadius: createBorderRadius(0, theme.shape.borderRadius),
    },
    noRightBorder: {
      borderRight: 'none',
      borderRadius: createBorderRadius(theme.shape.borderRadius, 0),
      '&$noLeftBorder': { borderRadius: 0 },
    },
  };
});

export const TimelineActivityBorder = ({
  hovered,
  hideLeftBorder,
  hideRightBorder,
  disableRightBorder,
  ...props
}: PaperProps & {
  hovered?: boolean;
  hideLeftBorder?: boolean;
  hideRightBorder?: boolean;
  disableRightBorder?: boolean;
}) => {
  const classes = useTimelineActivityBorderClasses();
  return (
    <Paper
      variant="outlined"
      className={classNames(classes.paper, {
        [classes.hovered]: hovered,
        [classes.invisibleLeftBorder]: hideLeftBorder,
        [classes.noRightBorder]: disableRightBorder,
        [classes.invisibleRightBorder]: !disableRightBorder && hideRightBorder,
      })}
      {...props}
    />
  );
};

export const useNavigationButtonStyles = makeStyles(theme => ({
  root: {
    position: 'absolute',
    transition: theme.transitions.create(['opacity']),
    [theme.breakpoints.up('md')]: {
      opacity: 0,
    },
  },
  show: { opacity: 1 },
  inFuture: { right: theme.spacing(1) },
  inPast: { left: theme.spacing(1) },
}));

export const NavigationButton = ({
  show,
  className,
  direction,
  ...props
}: IconButtonProps & { show?: boolean; direction: 'future' | 'past' }) => {
  const classes = useNavigationButtonStyles();
  return (
    <IconButton
      size="small"
      className={classNames(classes.root, className, {
        [classes.show]: show,
        [classes.inFuture]: direction === 'future',
        [classes.inPast]: direction === 'past',
      })}
      {...props}
    >
      {direction === 'future' ? (
        <ArrowForward fontSize="small" />
      ) : (
        <ArrowBack fontSize="small" />
      )}
    </IconButton>
  );
};
