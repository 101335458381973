import React, { ElementType } from 'react';

import { Typography } from '@material-ui/core';

import { useForceUpdate } from '@spotify-confidence/plugin-comments-react';

import { ComponentProp, DateUtils } from '../utils';

export type CommentsButtonProps<T extends ElementType> = ComponentProp<T> & {
  date: string | Date;
};

export const TimeAgo = <T extends ElementType = typeof Typography>(
  props: CommentsButtonProps<T>,
) => {
  const { component: Component = Typography, date, ...rest } = props;
  const parsedDate = new Date(date);

  const now = new Date();
  const delta = now.getTime() - parsedDate.getTime();

  const force = useForceUpdate();

  let nextTick = 0;
  const justNow = delta < 60 * 1000;

  if (delta < 60 * 1000) {
    nextTick = 60 * 1000;
  } else if (delta < 60 * 60 * 1000) {
    // less than an hour, update every minute
    nextTick = 60 * 1000;
  } else if (delta < 24 * 60 * 60 * 1000) {
    // less than a day, update every hour
    nextTick = 60 * 60 * 1000;
  } else {
    // more than a day, update every day
    nextTick = 24 * 60 * 60 * 1000;
  }

  React.useEffect(() => {
    const interval = setInterval(() => {
      force();
    }, nextTick);
    return () => clearInterval(interval);
  }, [nextTick]);

  return (
    <Component {...rest}>
      {justNow ? 'just now' : DateUtils.xAgo(parsedDate)}
    </Component>
  );
};
