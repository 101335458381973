import React from 'react';

import {
  Box,
  Card,
  CardContent,
  Grid,
  LinearProgress,
  Typography,
} from '@material-ui/core';
import { Alert } from '@material-ui/lab';

import {
  DateUtils,
  extractLastNameComponent,
} from '@spotify-confidence/core-react';
import {
  getTypeOrNull,
  useWorkflowInstanceQuery,
} from '@spotify-confidence/plugin-graphql';

export const WorkflowInstanceOverview = ({
  workflowInstanceName,
}: {
  workflowInstanceName: string;
}) => {
  const { data, error, loading } = useWorkflowInstanceQuery({
    variables: { name: workflowInstanceName },
  });
  const workflowInstance = getTypeOrNull(
    data?.workflowInstance,
    'WorkflowV1WorkflowInstance',
  );
  const details = [
    {
      label: 'State',
      value: workflowInstance?.state,
    },
    {
      label: 'Version',
      value: extractLastNameComponent(
        getTypeOrNull(
          workflowInstance?.workflowVersion,
          'WorkflowV1WorkflowVersion',
        )?.name,
      ),
    },
    {
      label: 'Created',
      value: workflowInstance?.createTime
        ? DateUtils.xAgo(new Date(workflowInstance?.createTime))
        : '',
    },
    {
      label: 'Updated',
      value: workflowInstance?.updateTime
        ? DateUtils.xAgo(new Date(workflowInstance?.updateTime))
        : '',
    },
  ];

  return (
    <>
      {error && <Alert severity="error">{error.message}</Alert>}
      {!loading && !data?.workflowInstance && (
        <Typography variant="body1">No instance data was loaded</Typography>
      )}
      {loading && <LinearProgress />}

      <Box mb={2}>
        <Card>
          <CardContent>
            <Grid container spacing={2}>
              {details.map(({ label, value }) => (
                <Grid item md={3} sm={6} key={label} data-testid={label}>
                  <Typography color="secondary" variant="overline">
                    {label}
                  </Typography>
                  <Typography display="block" variant="body2" gutterBottom>
                    {value}
                  </Typography>
                </Grid>
              ))}
            </Grid>
          </CardContent>
        </Card>
      </Box>
    </>
  );
};
