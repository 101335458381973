import React from 'react';

import { Tab, Tabs, Typography } from '@material-ui/core';
import { TabContext } from '@material-ui/lab';

import { CodeSnippet } from '../../CodeSnippet';
import { f } from '../../CodeSnippet';
import { SnippetProps } from '../../CodeSnippet';
import { StyledTabPanel } from '../../StyledTabPanel';
import { TUTORIAL_FLAG_NAME } from '../constants';

export const JsClientSnippets = (props: SnippetProps) => {
  const [packageManager, setPackageManager] = React.useState('npm');

  return (
    <ol>
      <li>
        <Typography variant="body1" gutterBottom>
          Install the required Confidence package.
        </Typography>
        <TabContext value={packageManager}>
          <Tabs
            indicatorColor="primary"
            value={packageManager}
            onChange={(_e, v) => setPackageManager(v)}
          >
            <Tab label="npm" value="npm" />
            <Tab label="yarn" value="yarn" />
          </Tabs>
          <StyledTabPanel value="npm">
            <CodeSnippet
              language="bash"
              text={f(`
npm install @spotify-confidence/sdk
`)}
              showCopyCodeButton
            />
          </StyledTabPanel>
          <StyledTabPanel value="yarn">
            <CodeSnippet
              language="bash"
              text={f(`
yarn add @spotify-confidence/sdk
`)}
              showCopyCodeButton
            />
          </StyledTabPanel>
        </TabContext>
      </li>
      <li>
        <Typography variant="body1" gutterBottom>
          <p>
            Import and initialize the Confidence SDK. This should be done as
            early as possible in your application.
          </p>
          <p>
            Remember to select an <code>environment</code> that matches your use
            case, <code>client</code> vs <code>backend</code>.
          </p>
        </Typography>
        <CodeSnippet
          language="typescript"
          showLineNumbers
          highlightedNumbers={[5]}
          text={f(`
import { Confidence } from '@spotify-confidence/sdk';

const confidence = Confidence.create({
  clientSecret: '${props.apiKey}',
  environment: 'client',
  timeout: 1000,
});

`)}
          showCopyCodeButton
        />
      </li>
      <li>
        <Typography variant="body1" gutterBottom>
          Next, we want to set the `visitor_id` context field to
          "tutorial_visitor", to match the targeting criteria pre-configured for
          this tutorial.
        </Typography>
        <CodeSnippet
          language="typescript"
          text={f(`
confidence.setContext({ visitor_id: 'tutorial_visitor' });
      `)}
          showCopyCodeButton
        />
      </li>
      <li>
        <Typography variant="body1" gutterBottom>
          We can now evaluate the `tutorial-feature` flag and read the `title`
          value.
        </Typography>
        <CodeSnippet
          language="typescript"
          text={f(`
const flagTitle = await confidence.getFlag(
  "${TUTORIAL_FLAG_NAME}.title",
  "Default"
);

console.log("tutorial-feature title:", flagTitle);
      `)}
          showCopyCodeButton
        />
      </li>
    </ol>
  );
};
