import React from 'react';

import {
  extractLastNameComponent,
  extractWorkflowFromInstanceName,
} from '@spotify-confidence/core-react';
import { eventRouteRef } from '@spotify-confidence/plugin-events';
import {
  IdentityFragment,
  getTypeOrNull,
  useAccountMode,
} from '@spotify-confidence/plugin-graphql';
import {
  assignmentTableRouteRef,
  factTableRouteRef,
  flagRouteRef,
  metricRouteRef,
  workflowInstanceRouteRef,
} from '@spotify-confidence/plugin-workflows';
import { useConfidence } from '@spotify-confidence/react';
import { IconLayout } from '@spotify-internal/encore-web';

import { useRouteRef } from '@backstage/core-plugin-api';

import { ModuleIcons, workflowToIcon } from '../components/ModuleIcons';
import { getMenuLinks, isMenuItem } from '../components/Root';
import { useSearchQuery } from '../generated/graphql';
import { commandScore } from './score';

export interface SearchDocument {
  id: string;
  title: string;
  subtitle: string;
  category: string;
  href: string;
  icon: React.ReactNode;
}

const owner = (identity: IdentityFragment | undefined | null) => {
  if (identity?.user) {
    return getTypeOrNull(identity.user, 'IamV1User')?.fullName ?? 'unknown';
  }

  if (identity?.group) {
    return (
      getTypeOrNull(identity.group, 'IamV1Group')?.displayName ?? 'unknown'
    );
  }

  return 'unknown';
};

export const useSearch = (query: string) => {
  const results: SearchDocument[] = [];
  const { isTotalConfidence } = useAccountMode();
  const reanalysisEnabled = useConfidence().useFlag(
    'reanalysis.enabled',
    false,
  );

  const workflowInstanceRoute = useRouteRef(workflowInstanceRouteRef);
  const assignmentTableRoute = useRouteRef(assignmentTableRouteRef);
  const metricRoute = useRouteRef(metricRouteRef);
  const factTableRoute = useRouteRef(factTableRouteRef);
  const flagRoute = useRouteRef(flagRouteRef);
  const eventRoute = useRouteRef(eventRouteRef);

  const { data, loading } = useSearchQuery({
    variables: {
      filter: query && `displayName:${query}*`,
      flagsFilter: query && `name:*${query}*`,
      eventsFilter: query && `name:*${query}*`,
      pageSize: 10,
    },
    skip: query.length === 0,
  });

  const workflowInstances = getTypeOrNull(
    data?.workflowInstances,
    'WorkflowV1ListWorkflowInstancesResponse',
  )?.workflowInstances;

  if (workflowInstances?.length) {
    results.push(
      ...workflowInstances.map(i => {
        const Icon = workflowToIcon(extractWorkflowFromInstanceName(i.name)!);
        return {
          id: i.name,
          title: i.displayName,
          subtitle: owner(getTypeOrNull(i.owner, 'IamV1Identity')),
          category: 'Workflow instance',
          href: workflowInstanceRoute({
            workflow: extractWorkflowFromInstanceName(i.name)!,
            id: extractLastNameComponent(i.name)!,
          }),
          icon: <Icon />,
        };
      }),
    );
  }

  const metrics = getTypeOrNull(
    data?.metrics,
    'MetricsV1ListMetricsResponse',
  )?.metrics;

  if (metrics?.length) {
    results.push(
      ...metrics.map(m => ({
        id: m.name,
        title: m.displayName,
        subtitle: owner(getTypeOrNull(m.owner, 'IamV1Identity')),
        category: 'Metric',
        href:
          metricRoute?.({
            id: extractLastNameComponent(m.name)!,
          }) ?? '',
        icon: <ModuleIcons.metrics />,
      })),
    );
  }

  const factTables = getTypeOrNull(
    data?.factTables,
    'MetricsV1ListFactTablesResponse',
  )?.factTables;

  if (factTables?.length) {
    results.push(
      ...factTables.map(f => ({
        id: f.name,
        title: f.displayName,
        subtitle: owner(getTypeOrNull(f.owner, 'IamV1Identity')),
        category: 'Fact table',
        href:
          factTableRoute?.({
            id: extractLastNameComponent(f.name)!,
          }) ?? '',
        icon: <ModuleIcons.metrics />,
      })),
    );
  }

  const flags = getTypeOrNull(
    data?.flags,
    'FlagsAdminV1ListFlagsResponse',
  )?.flags;

  if (flags?.length) {
    results.push(
      ...flags.map(f => ({
        id: f.name,
        title: extractLastNameComponent(f.name)!,
        subtitle: owner(getTypeOrNull(f.owner, 'IamV1Identity')),
        category: 'Flag',
        href:
          flagRoute?.({
            id: extractLastNameComponent(f.name)!,
          }) ?? '',
        icon: <ModuleIcons.flags />,
      })),
    );
  }

  const assignmentTables = getTypeOrNull(
    data?.assignmentTables,
    'MetricsV1ListAssignmentTablesResponse',
  )?.assignmentTables;

  if (assignmentTables?.length) {
    results.push(
      ...assignmentTables.map(a => ({
        id: a.name,
        title: a.displayName,
        category: 'Assignment table',
        subtitle: owner(getTypeOrNull(a.owner, 'IamV1Identity')),
        href:
          assignmentTableRoute?.({
            id: extractLastNameComponent(a.name)!,
          }) ?? '',
        icon: <ModuleIcons.metrics />,
      })),
    );
  }

  const eventDefinitions = getTypeOrNull(
    data?.eventDefinitions,
    'EventsAdminV1ListEventDefinitionsResponse',
  )?.eventDefinitions;

  if (eventDefinitions?.length) {
    results.push(
      ...eventDefinitions.map(e => ({
        id: e.name,
        title: extractLastNameComponent(e.name)!,
        subtitle: owner(getTypeOrNull(e.owner, 'IamV1Identity')),
        category: 'Event',
        href:
          eventRoute({
            id: extractLastNameComponent(e.name)!,
          }) ?? '',
        icon: <ModuleIcons.events />,
      })),
    );
  }

  const pagesLinks = getMenuLinks({ isTotalConfidence, reanalysisEnabled })
    .filter(m => isMenuItem(m))
    .flatMap(m => {
      // just to make ts happy
      if (isMenuItem(m)) {
        return m?.children
          ? m.children.map(child => ({
              url: child.to,
              title: child.label,
              icon: child.icon,
            }))
          : [{ url: m.to, title: m.label, icon: m.icon }];
      }
      return [];
    })
    .filter(p => query && p.title.toLowerCase().includes(query.toLowerCase()));

  if (pagesLinks.length) {
    results.push(
      ...pagesLinks.map(p => {
        const Icon = p.icon ?? IconLayout;

        return {
          id: p.url,
          title: p.title,
          subtitle: 'Page',
          category: 'Page',
          href: p.url,
          icon: <Icon />,
        };
      }),
    );
  }

  const scores = Object.fromEntries(
    results.map(r => [r.id, commandScore(r.title, query, [])]),
  );

  const ranked = results.sort((a, b) => scores[b.id] - scores[a.id]);

  return { results: ranked, loading };
};
