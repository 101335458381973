import React from 'react';

import { Typography, styled } from '@material-ui/core';

import {
  ResourceLink,
  Timeline,
  extractWorkflowFromInstanceName,
} from '@spotify-confidence/core-react';
import {
  StatusIndicator,
  WorkflowInstanceListItem,
  WorkflowInstanceStateType,
  moduleHelpers,
  useWorkflowInstanceState,
  workflowInstanceRouteRef,
} from '@spotify-confidence/plugin-workflows';

import { PlanningModule } from '../types';

const maybeDate = (date: string | undefined) =>
  date ? new Date(date) : undefined;

const extract = (item: WorkflowInstanceListItem) => {
  const data = moduleHelpers.getModuleData<PlanningModule>(
    item.moduleData,
    'planning',
  );

  return {
    start: maybeDate(data?.actual?.startTime),
    end: maybeDate(data?.actual?.endTime),
    plannedStart: maybeDate(data?.planned?.startTime),
    plannedEnd: maybeDate(data?.planned?.endTime),
    item,
  };
};

const Wrapper = styled('div')(({ theme }) => ({
  display: 'inline-flex',
  alignItems: 'center',
  gap: theme.spacing(1),
}));

const WorkflowInstanceItem = (item: WorkflowInstanceListItem) => {
  const { status, label } = useWorkflowInstanceState({
    state: item.state as WorkflowInstanceStateType,
    checks: item.checks,
    pendingTransition: item.pendingTransition,
  });

  return (
    <Wrapper>
      <StatusIndicator
        status={status}
        label={label}
        hasStarted={!!extract(item)?.start}
      />
      <Typography variant="body2">{item.displayName}</Typography>
    </Wrapper>
  );
};

export const WorkflowInstanceTimeline = ({
  items,
  children,
  topOffset,
  loading,
}: {
  topOffset?: number;
  items: WorkflowInstanceListItem[];
  children?: React.ReactNode;
  loading?: boolean;
}) => {
  return (
    <>
      <Timeline
        items={items}
        extract={extract}
        topOffset={topOffset}
        getLabel={item => (
          <ResourceLink
            name={item.name}
            routeRef={workflowInstanceRouteRef}
            params={{
              workflow: extractWorkflowFromInstanceName(item.name) || '',
            }}
          >
            {item.displayName}
          </ResourceLink>
        )}
        renderItem={WorkflowInstanceItem}
        loading={loading}
      />
      {children}
    </>
  );
};
