import { Typography, styled } from '@material-ui/core';

export const DisabledTypography = styled(Typography)(({ theme }) => ({
  color: theme.palette.text.disabled,
  fontStyle: 'italic',
}));

export const DisabledSpan = styled('span')(({ theme }) => ({
  color: theme.palette.text.disabled,
  fontStyle: 'italic',
}));
