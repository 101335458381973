import React, { useEffect } from 'react';

import { makeStyles, useTheme } from '@material-ui/core';

import { DrawerWrapper } from '@spotify-confidence/core-react';

const useStyles = makeStyles(theme => ({
  root: {
    position: 'sticky',
    gridArea: 'pageHeader',
    top: 0,
    zIndex: theme.zIndex.appBar,
    borderBottom: `1px solid transparent`,
    background: theme.palette.background.default,
    transition: theme.transitions.create(['border-bottom-color', 'background']),
    maxHeight: 58,
  },
  container: {
    padding: `${theme.spacing(1) - 1}px ${theme.spacing(3)}px`,
    display: 'flex',
    alignItems: 'center',
    gridArea: 'pageHeader',
    margin: '0 auto',

    // @ts-ignore
    ...theme.overrides?.BackstageHeader?.header,
  },
  content: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    flexGrow: 1,
    // override the duration for the padding-right transition
    // (defined by DrawerWrapper) to get a smoother transition
    // in and out of the drawer
    transition: theme.transitions.create(['padding-right'], {
      duration: theme.transitions.duration.short,
    }),
  },
}));

const SCROLL_THRESHOLD = 0;

export const FloatingHeader = ({
  pageElement,
  drawerOpen,
  children,
}: React.PropsWithChildren<{
  pageElement: HTMLElement | null;
  drawerOpen?: boolean;
}>) => {
  const classes = useStyles();
  const [open, setOpen] = React.useState(false);
  const theme = useTheme();

  useEffect(() => {
    const updateOpen = () => {
      if (pageElement) {
        setOpen(pageElement.scrollTop > SCROLL_THRESHOLD);
      }
    };

    if (pageElement) {
      pageElement.addEventListener('scroll', updateOpen);
      return () => {
        pageElement.removeEventListener('scroll', updateOpen);
      };
    }

    return () => {};
  }, [pageElement]);

  return (
    <div
      className={classes.root}
      style={{
        borderBottomColor: open ? theme.palette.divider : 'transparent',
        background: open ? theme.palette.background.paper : 'transparent',
        // needed for backstage light theme
        color: open
          ? theme.palette.text.primary
          : theme.palette.navigation.color,
      }}
    >
      <div className={classes.container}>
        <DrawerWrapper className={classes.content} open={drawerOpen}>
          {children}
        </DrawerWrapper>
      </div>
    </div>
  );
};
