import React from 'react';

import { Box, Chip } from '@material-ui/core';

import { DisabledTypography } from '@spotify-confidence/core-react';

export const ReadOnlyTags = ({
  tags = [],
  emptyLabel = 'No tags set',
}: {
  tags?: string[];
  emptyLabel?: string;
}) => {
  return (
    <Box display="inline-flex" gridGap={8}>
      {tags.length ? (
        tags.map(tag => <Chip key={tag} label={tag} />)
      ) : (
        <DisabledTypography display="block" gutterBottom>
          {emptyLabel}
        </DisabledTypography>
      )}
    </Box>
  );
};
