import React from 'react';
import type { unstable_BlockerFunction as BlockerFunction } from 'react-router-dom';
import { useBlocker } from 'react-router-dom';

import { ConfirmDialog, useDialog } from '../components';

type PreventLeavePageOptions = {
  title?: string;
  message?: React.ReactNode;
  action?: string;
};

/*
  Use this hook to prevent users from leaving a page when a condition is met.
  Commonly this would be used when a user has unsaved changes in a form that
  they might have missed, otherwise it should be used very sparingly as it
  can hurt the user experience.

  CAUTION:
  We are relying on "useDialog" in this hook, which means that it should NOT
  be used from within a dialog opened with "useDialog". This is because the
  dialog will overwrite the current dialog content and lose the data.
*/

export const usePreventLeavePage = (
  shouldPrevent: boolean,
  {
    title = 'Unsaved changes',
    message = 'You have unsaved changes. Are you sure you want to leave the page?',
    action = 'Proceed',
  }: PreventLeavePageOptions = {},
) => {
  /*
   * Block in-app navigation
   * https://github.com/remix-run/react-router/tree/main/examples/navigation-blocking
   */
  const { openDialog, closeDialog } = useDialog();

  // Allow the submission navigation to the same route to go through
  const shouldBlock = React.useCallback<BlockerFunction>(
    ({ currentLocation, nextLocation }) =>
      shouldPrevent && currentLocation.pathname !== nextLocation.pathname,
    [shouldPrevent],
  );

  const blocker = useBlocker(shouldBlock);

  React.useEffect(() => {
    if (blocker.state === 'blocked' && !shouldPrevent) {
      blocker.reset();
    }
  }, [blocker, shouldPrevent]);

  React.useEffect(() => {
    if (blocker.state === 'blocked') {
      openDialog({
        content: (
          <ConfirmDialog
            title={title}
            action={action}
            onConfirm={async () => {
              blocker.proceed();
            }}
            onClose={() => {
              blocker.reset();
              closeDialog();
            }}
          >
            {message}
          </ConfirmDialog>
        ),
      });
    }
  }, [blocker]);

  /*
   * Block closing/refreshing the page
   * https://developer.mozilla.org/en-US/docs/Web/API/Window/beforeunload_event
   */
  React.useEffect(() => {
    if (shouldPrevent) {
      const listener = (event: BeforeUnloadEvent) => {
        event.preventDefault();
        event.returnValue = '';
      };

      window.addEventListener('beforeunload', listener);

      return () => {
        window.removeEventListener('beforeunload', listener);
      };
    }
    return undefined;
  }, [shouldPrevent]);
};
