import React from 'react';

import { BalanceIcon } from './Icons';
import { SectionButton } from './Section';

export const BalanceWeightsButton = ({
  onClick,
  label = 'Split weights evenly',
}: {
  onClick: () => void;
  label?: string;
}) => (
  <SectionButton
    data-testid="balance-weights-button"
    startIcon={<BalanceIcon />}
    onClick={onClick}
    size="small"
  >
    {label}
  </SectionButton>
);
