import React, { Fragment } from 'react';

import {
  Box,
  Card,
  CardContent,
  CardHeader,
  Collapse,
  Divider,
  Typography,
  makeStyles,
} from '@material-ui/core';

import { ExpandButton, useDisplayNames } from '@spotify-confidence/core-react';
import {
  CommentZone,
  CommentZoneContext,
} from '@spotify-confidence/plugin-comments-react';
import classNames from 'classnames';
import _ from 'lodash';

import { resultUtils } from '.';
import { BucketingTemporalUnit } from '../../../MetricsModule';
import { MetricResult } from './MetricResult';
import { ResultData } from './MetricResultTable';
import { Annotation, StatsHypothesisResult } from './types';
import { useRelativeBounds } from './useRelativeBounds';

const useStyles = makeStyles(theme => ({
  root: {
    position: 'relative',
    display: 'grid',
    gridTemplateColumns:
      // title, means, (ci), effect, expand
      'minmax(240px, 1fr) minmax(0, 1fr) 2fr min-content minmax(50px, min-content)',

    [theme.breakpoints.down('md')]: {
      gridTemplateColumns: 'repeat(1, 1fr)',
    },
  },

  item: {
    display: 'contents',
  },

  /// THE A vs B TITLE
  variants: {
    gridColumn: 'span 5',
  },

  /// THE RESULT LINE

  header: {
    padding: theme.spacing(4),
    display: 'grid',
    [theme.breakpoints.down('md')]: {
      gridColumn: 'span 1',
    },
    gridColumn: 'span 5',
    gridTemplateColumns: 'subgrid',
    gap: theme.spacing(2),
  },

  error: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    ...theme.typography.body1,
  },

  metric: {
    [theme.breakpoints.down('md')]: {
      ...theme.typography.h5,
    },
  },
  means: {
    [theme.breakpoints.down('md')]: {
      flexDirection: 'column',
    },
  },
  ci: {
    [theme.breakpoints.down('md')]: {
      display: 'none',
    },
  },

  change: {
    display: 'flex',
    flexDirection: 'column-reverse',
    alignItems: 'flex-end',

    [theme.breakpoints.down('md')]: {
      flexDirection: 'column',
      alignItems: 'flex-start',
    },
    gridColumn: '4 / span 1',
  },

  effect: {
    fontSize: theme.typography.h6.fontSize,
    [theme.breakpoints.down('md')]: {
      fontSize: theme.typography.h4.fontSize,
    },
  },
  status: {
    ...theme.typography.body2,
    [theme.breakpoints.down('md')]: {
      ...theme.typography.overline,
    },
  },

  expand: {
    minWidth: 50,
    justifySelf: 'end',
    alignSelf: 'center',
    [theme.breakpoints.down('md')]: {
      justifySelf: 'start',
      boxShadow: `inset 0 0 0 1px ${theme.palette.textVerySubtle}`,
    },
    gridColumn: 'span 6',
  },

  /// THE GRAPH

  graph: {
    gridColumn: 'span 5',
  },
  timeline: {
    padding: theme.spacing(0, 4, 4, 4),
  },

  /// DIVIDER

  divider: {
    gridColumn: 'span 5',
  },

  noResults: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    ...theme.typography.body1,
    padding: theme.spacing(0.5),
    height: '100%', // Ensure the parent container has a height set
  },
}));

type MetricsResultSectionProps = {
  results?: StatsHypothesisResult[];
  bucket?: BucketingTemporalUnit;
  isLive?: boolean;
  annotations?: Record<string, Annotation[]>;
  className?: string;
  isSequential?: boolean;
};

export const MetricsResultSection = (props: MetricsResultSectionProps) => {
  const {
    bucket = BucketingTemporalUnit.HOURS,
    results,
    annotations,
    className,
    isSequential,
    isLive,
  } = props;

  const classes = useStyles();
  const { displayNames } = useDisplayNames();

  const [expanded, setExpanded] = React.useState<string[]>([]);

  const relativeBounds = useRelativeBounds(results ?? []);
  const data = resultUtils.convertAnalyzeResultToResultData(
    results,
    annotations,
  );

  const toggleExpanded = (r: ResultData) => {
    const key = r.comparison + r.metric;
    setExpanded(e =>
      e.includes(key) ? e.filter(ee => ee !== key) : [...e, key],
    );
  };

  const isExpanded = (r: ResultData) =>
    expanded.includes(r.comparison + r.metric);

  const grouping = _.groupBy(data, d => d.comparison);
  const isMultivariate = Object.keys(grouping).length > 1;

  return (
    <Card className={classNames(classes.root, className)}>
      {Object.entries(grouping).map(([key, rows]) => (
        <Fragment key={key}>
          {isMultivariate && (
            <CardHeader
              className={classes.variants}
              title={
                <CommentZone id="comparison" data={{ comparison: key }}>
                  <Typography variant="h6">
                    {displayNames.get(rows[0]?.baselineGroupId)}{' '}
                    <Box component="span" color="text.secondary">
                      vs
                    </Box>{' '}
                    {displayNames.get(rows[0]?.comparedGroupId)}
                  </Typography>
                </CommentZone>
              }
            />
          )}

          {_(rows)
            .groupBy(r => r.metric)
            .toArray()
            .map((subRows, i, arr) => {
              const hasRun = subRows[0].metricDetails?.hasRun;
              const hasError = subRows[0].annotations?.some(a => a.error);
              return (
                <CommentZoneContext
                  key={subRows[0].metric}
                  zone="metric"
                  data={{
                    comparison: subRows[0].comparison,
                    metric: subRows[0].metric,
                  }}
                >
                  <MetricResult
                    results={subRows}
                    bucket={bucket}
                    isLive={isLive ?? false}
                    component={CardContent}
                    className={classes.item}
                  >
                    <div
                      className={classes.header}
                      data-testid={`metric-result-${subRows[0].metric}/comparison/${subRows[0].comparison}`}
                    >
                      <MetricResult.Title classes={{ title: classes.metric }} />
                      <MetricResult.Means className={classes.means} />
                      <>
                        <MetricResult.ConfidenceInterval
                          className={classes.ci}
                          {...relativeBounds}
                        />
                        <div className={classes.change}>
                          <MetricResult.Status
                            className={classes.status}
                            disableIcon
                          />
                          <MetricResult.EffectSize className={classes.effect} />
                        </div>
                      </>
                      <ExpandButton
                        className={classes.expand}
                        onChange={() => toggleExpanded(subRows[0])}
                        expanded={isExpanded(subRows[0])}
                        disabled={(!isSequential && !hasError) || !hasRun}
                      />
                    </div>
                    <Collapse
                      className={classes.graph}
                      in={isExpanded(subRows[0])}
                    >
                      <>
                        <MetricResult.Error className={classes.timeline} />
                        <MetricResult.Graph
                          className={classes.timeline}
                          showRelativeChanges
                        />
                      </>
                    </Collapse>
                  </MetricResult>

                  {i < arr.length - 1 && (
                    <Divider variant="middle" className={classes.divider} />
                  )}
                </CommentZoneContext>
              );
            })
            .value()}
        </Fragment>
      ))}
    </Card>
  );
};
