import React from 'react';

import { Button, CircularProgress } from '@material-ui/core';
import { Alert } from '@material-ui/lab';

import { useAlert } from '@spotify-confidence/core-react';
import {
  Query,
  evictTypeFields,
  getError,
  useAllocateSegmentMutation,
} from '@spotify-confidence/plugin-graphql';

export const AllocationButton = ({
  name,
  disabled,
}: {
  name: string;
  disabled?: boolean;
}) => {
  const alert = useAlert();
  const [allocate, { loading: isAllocating, error: allocationError }] =
    useAllocateSegmentMutation({
      variables: {
        name: name,
      },
      update: cache => {
        evictTypeFields<Query>(cache, 'segments');
      },
      onCompleted: response => {
        const responseError = getError(response.allocateSegment);
        if (responseError) {
          alert.post({
            message: responseError.message,
            severity: 'error',
          });
        } else {
          alert.post({
            message: 'The segment has been allocated',
            severity: 'success',
            display: 'transient',
          });
        }
      },
    });
  return (
    <>
      {allocationError && (
        <Alert severity="error">{allocationError.message}</Alert>
      )}
      <Button
        variant="contained"
        color="primary"
        endIcon={
          isAllocating && (
            <CircularProgress
              data-testid="progress"
              size="1em"
              color="inherit"
            />
          )
        }
        disabled={isAllocating || disabled}
        size="small"
        onClick={() => allocate()}
      >
        Allocate
      </Button>
    </>
  );
};
