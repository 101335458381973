import * as React from 'react';

import { Box, IconButton, Typography, makeStyles } from '@material-ui/core';
import Close from '@material-ui/icons/Close';

export const HEADER_HEIGHT = 65;

type HeaderProps = React.PropsWithChildren<{
  title: string;
  onClose?: () => void;
}>;

const useStyles = makeStyles(theme => ({
  header: {
    width: '100%',
    background: theme.palette.background.paper,
    borderBottom: `1px solid ${theme.palette.divider}`,
    position: 'sticky',
    top: 0,
    zIndex: theme.zIndex.appBar,
  },
  headerContent: {
    padding: theme.spacing(1, 2),
    height: HEADER_HEIGHT,
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    maxWidth: theme.breakpoints.values.xl,
    margin: '0 auto',
  },
}));

export function Header({ title, onClose }: HeaderProps) {
  const classes = useStyles();
  return (
    <Box className={classes.header}>
      <Box className={classes.headerContent}>
        <Typography variant="h5" component="h2">
          {title}
        </Typography>
        {onClose && (
          <IconButton onClick={onClose}>
            <Close />
          </IconButton>
        )}
      </Box>
    </Box>
  );
}
