import React from 'react';

import { Box, Theme, Typography, useMediaQuery } from '@material-ui/core';

import { TopRightSwirlArrowIcon } from '../Icons';

type SectionEmptyStateProps = React.PropsWithChildren<{
  title?: string;
  description?: string;
}>;
export const SectionEmptyState = ({
  title,
  description,
  children,
}: SectionEmptyStateProps) => {
  const isMobile = useMediaQuery((theme: Theme) =>
    theme.breakpoints.down('sm'),
  );

  return (
    <Box position="relative" textAlign="center" padding={isMobile ? 2 : 6}>
      <Box
        fontSize={128}
        display="block"
        textAlign="right"
        width="100%"
        position={isMobile ? 'relative' : 'absolute'}
        right={60}
        top={0}
      >
        <TopRightSwirlArrowIcon fontSize="inherit" />
      </Box>
      <Box maxWidth={600} margin="0 auto">
        {title && (
          <Typography variant="h6" color="textPrimary">
            {title}
          </Typography>
        )}
        {description && (
          <Typography variant="body1" color="textPrimary">
            {description}
          </Typography>
        )}
      </Box>
      {children}
    </Box>
  );
};
