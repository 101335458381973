import React from 'react';

import { LinearProgress } from '@material-ui/core';
import { Alert } from '@material-ui/lab';

import { useRecentlyVisited } from '@spotify-confidence/core-react';
import {
  GetWorkflowInstanceQuery,
  Maybe,
  WorkflowInstanceCheckFragment,
  WorkflowInstanceFragmentDoc,
  getError,
  getTypeOrNull,
  isType,
  useGetWorkflowInstanceQuery,
  useOnWorkflowInstanceUpdatedSubscription,
} from '@spotify-confidence/plugin-graphql';

import { getWorkflowInstanceName } from './utils';

const getIntermediateRenderComponent = ({
  loading,
  error,
}: {
  loading: boolean;
  error?: Maybe<string>;
}) => {
  if (loading) {
    return () => <LinearProgress />;
  }

  if (error) {
    return () => <Alert severity="error">{error}</Alert>;
  }

  return null;
};
export type WorkflowInstance = Extract<
  GetWorkflowInstanceQuery['workflowInstance'],
  { __typename: 'WorkflowV1WorkflowInstance' }
>;

export type WorkflowInstanceCheck = WorkflowInstanceCheckFragment;

export const useGetWorkflowInstance = ({
  instanceId,
  workflowId,
}: {
  instanceId: string;
  workflowId: string;
}) => {
  const { triggerRecentlyVisited } = useRecentlyVisited();
  const workflowInstanceName = getWorkflowInstanceName(workflowId, instanceId);

  const queryResult = useGetWorkflowInstanceQuery({
    variables: {
      name: workflowInstanceName,
    },
    onCompleted: response => {
      if (isType(response.workflowInstance, 'WorkflowV1WorkflowInstance')) {
        triggerRecentlyVisited(response.workflowInstance.displayName);
      }
    },
  });

  useOnWorkflowInstanceUpdatedSubscription({
    onData: payload => {
      const instance =
        payload.data.data?.workflowInstanceUpdated?.workflowInstance;
      if (instance) {
        const cacheId = payload.client.cache.identify(instance);
        if (cacheId) {
          payload.client.cache.writeFragment({
            id: cacheId,
            data: instance,
            fragment: WorkflowInstanceFragmentDoc,
            fragmentName: 'WorkflowInstance',
          });
        }
      }
    },
  });

  const { data, loading, startPolling, stopPolling } = queryResult;

  React.useEffect(() => {
    if (
      isType(data?.workflowInstance, 'WorkflowV1WorkflowInstance') &&
      data?.workflowInstance?.pendingTransition
    ) {
      startPolling(30000); // what's a reasonable interval?
    } else {
      stopPolling();
    }
  }, [startPolling, stopPolling, data?.workflowInstance]);

  const workflowInstance = getTypeOrNull(
    data?.workflowInstance,
    'WorkflowV1WorkflowInstance',
  );

  const errorMessage =
    getError(data?.workflowInstance)?.message ?? queryResult.error?.message;

  const IntermediateRenderComponent = getIntermediateRenderComponent({
    loading: queryResult.loading,
    error: errorMessage,
  });

  return {
    ...queryResult,
    loading,
    workflowInstance,
    IntermediateRenderComponent,
  };
};
