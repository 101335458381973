import {
  ConnectorsV1SnowflakeConfigInput,
  ConnectorsV1EventConnectionSnowflakeEventConnectionConfigInput as SnowflakeEventInput,
  ConnectorsV1FlagAppliedConnectionSnowflakeFlagAppliedConnectionConfigInput as SnowflakeFlagAppliedInput,
} from '@spotify-confidence/plugin-graphql';

export const defaultSnowflakeEventInput: SnowflakeEventInput = {
  snowflakeConfig: {
    authenticationKey: '',
    account: '',
    user: '',
    role: '',
    database: '',
    schema: '',
  },
  tablePrefix: '',
};

export const defaultSnowflakeFlagAppliedInput: SnowflakeFlagAppliedInput = {
  snowflakeConfig: {
    authenticationKey: '',
    account: '',
    user: '',
    role: '',
    database: '',
    schema: '',
  },
  table: '',
};

export const hasConfigurationThatAffectsValidationChanged = (
  oldValues: Partial<ConnectorsV1SnowflakeConfigInput> = {},
  newValues: Partial<ConnectorsV1SnowflakeConfigInput> = {},
) => {
  return (
    oldValues.role !== newValues.role ||
    oldValues.account !== newValues.account ||
    oldValues.user !== newValues.user ||
    oldValues.authenticationKey !== newValues.authenticationKey
  );
};
