import React, { useEffect, useRef } from 'react';

import { Badge, Fade, Theme, makeStyles, styled } from '@material-ui/core';

import classNames from 'classnames';

import { CommentZoneProvider, useZone } from '../contexts';
import { useCommentState, useOpenCommentsForZone } from '../hooks';
import { useDelayedTrigger } from './useDelayedTrigger';

const Highlight = styled('div')(({ theme }) => ({
  position: 'absolute',
  left: -theme.spacing(1),
  top: -theme.spacing(0.5),
  bottom: -theme.spacing(0.5),
  right: -theme.spacing(1),
  opacity: 0.5,
  background: theme.palette.warning.dark,
  border: `3px solid ${theme.palette.warning.light}`,
  borderRadius: theme.shape.borderRadius,
  zIndex: theme.zIndex.appBar - 1,
  cursor: 'cell',
}));

const useStyles = makeStyles<
  Theme,
  { threadHasContent: boolean; display?: React.CSSProperties['display'] }
>(theme => ({
  root: {
    position: 'relative',
    textOverflow: 'inherit',
    whiteSpace: 'inherit',
    overflow: 'inherit',
  },
  threadBadge: {
    zIndex: theme.zIndex.appBar - 1,
    cursor: ({ threadHasContent }) =>
      threadHasContent ? 'pointer' : 'default',
    display: ({ display }) => display || 'block',
    position: 'relative',
    textOverflow: 'inherit',
    whiteSpace: 'inherit',
    overflow: 'inherit',
  },
}));

type CommentZoneProps<T extends React.ElementType = 'div'> =
  React.PropsWithChildren<{
    id: string;
    data?: { [key: string]: string };
    component?: T;
    display?: React.CSSProperties['display'];
    className?: string;
  }>;

export const CommentZone = <T extends React.ElementType = 'div'>({
  id: zoneId,
  data,
  children,
  component,
  display,
  className,
}: CommentZoneProps<T>) => {
  const Component = component || 'div';
  const selected = useCommentState(state => state.selectedZone);
  const comment = useCommentState(state => state.comment);
  const hoveredZone = useCommentState(state => state.hoveredZone);

  const selectComment = useCommentState(state => state.selectComment);
  const commentMode = useCommentState(state => state.commentMode);
  const readOnly = useCommentState(state => state.readOnly);

  const [hover, setHover] = React.useState(false);
  const ref = useRef<React.ComponentPropsWithRef<T>['ref']>(null);

  const zone = useZone(zoneId, data);

  const isHovered = hoveredZone === zone;
  const isSelected = selected === zone;

  const comments = useOpenCommentsForZone(zone);
  const commentCount = comments.length;
  const classes = useStyles({ threadHasContent: commentCount > 0, display });

  useEffect(() => {
    if (selected === zone && ref.current) {
      ref.current.scrollIntoView({ behavior: 'smooth', block: 'center' });
    }
  }, [selected, zone]);

  const { value: flash, trigger: triggerFlash } = useDelayedTrigger({
    delay: 350,
    duration: 250,
  });

  const enteredCommentMode = commentMode && !selected;
  useEffect(() => {
    if (enteredCommentMode) {
      triggerFlash();
    }
  }, [enteredCommentMode]);

  return (
    <CommentZoneProvider zone={zone}>
      <Component
        ref={ref}
        className={classNames(classes.root, className)}
        onMouseEnter={() => setHover(true)}
        onMouseLeave={() => setHover(false)}
      >
        {!readOnly && (
          <Fade in={flash} appear timeout={300} mountOnEnter unmountOnExit>
            <Highlight />
          </Fade>
        )}

        {(isSelected || isHovered) && <Highlight />}

        <Badge
          className={classes.threadBadge}
          onClick={() =>
            commentCount ? selectComment(comments[0].name, zone) : null
          }
          color="primary"
          variant="standard"
          overlap="rectangular"
          badgeContent={commentCount}
          anchorOrigin={{
            vertical: 'top',
            horizontal: 'left',
          }}
        />

        {children}

        {hover && commentMode && !readOnly && (
          <Highlight
            onClick={e => {
              comment(zone);
              e.preventDefault();
            }}
          />
        )}
      </Component>
    </CommentZoneProvider>
  );
};
