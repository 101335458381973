import {
  createExternalRouteRef,
  createRouteRef,
  createSubRouteRef,
} from '@backstage/core-plugin-api';

// internal routes
export const rootRouteRef = createRouteRef({ id: 'flags-root' });

export const flagsRouteRef = rootRouteRef;

export const resolverTesterRouteRef = createSubRouteRef({
  id: 'resolver-test',
  parent: rootRouteRef,
  path: '/resolver-test',
});

export const flagRouteRef = createSubRouteRef({
  id: 'flag',
  parent: rootRouteRef,
  path: '/:id',
});

export const segmentsRouteRef = createSubRouteRef({
  id: 'segments',
  parent: rootRouteRef,
  path: '/segments',
});

export const segmentRouteRef = createSubRouteRef({
  id: 'segment',
  parent: rootRouteRef,
  path: '/segments/:id',
});

// external routes
export const workflowRouteRef = createExternalRouteRef({
  id: 'workflow',
  params: ['workflow'],
  optional: true,
});

export const workflowInstanceRouteRef = createExternalRouteRef({
  id: 'workflow-instance',
  params: ['workflow', 'id'],
  optional: true,
});

export const clientsRouteRef = createExternalRouteRef({
  id: 'clients',
  optional: true,
});

export const clientSchemaRouteRef = createExternalRouteRef({
  id: 'clientSchema',
  optional: true,
});

export const clientRouteRef = createExternalRouteRef({
  id: 'client',
  params: ['id'],
  optional: true,
});
