import React from 'react';

import {
  Box,
  IconButton,
  TableCell,
  TableRow,
  Typography,
  makeStyles,
} from '@material-ui/core';
import Delete from '@material-ui/icons/Delete';
import Edit from '@material-ui/icons/Edit';

import {
  PolicyFragment,
  isError,
  isType,
} from '@spotify-confidence/plugin-graphql';
import {
  IdentityChip,
  PermissionUtils,
} from '@spotify-confidence/plugin-permissions-react';

const useStyles = makeStyles({
  cell: {
    verticalAlign: 'top',
  },
});

export interface PolicyTableRowProps {
  policy: PolicyFragment;
  onDelete: () => void;
  onEdit: () => void;
}

/**
 * Presentation component for a policy table row.
 */
export function PolicyTableRow(props: PolicyTableRowProps) {
  const { policy, onDelete, onEdit } = props;
  const canEdit = PermissionUtils.hasRelation(policy, 'can_edit');

  const classes = useStyles();

  return (
    <TableRow key={policy.name}>
      <TableCell className={classes.cell}>
        <Box
          display="flex"
          flexDirection="column"
          alignItems="flex-start"
          gridGap={5}
        >
          {policy.identities.map(identity => {
            if (isError(identity)) return 'Unknown';
            return <IdentityChip key={identity.name} {...identity} />;
          })}
        </Box>
      </TableCell>
      <TableCell className={classes.cell}>
        <Box
          display="flex"
          flexDirection="column"
          alignItems="flex-start"
          gridGap={5}
        >
          {policy.roles.map((role, roleIdx) => (
            <Typography key={roleIdx} variant="body1">
              {isType(role, 'IamV1Role')
                ? role.displayName
                : role.message ?? 'Unknown'}
            </Typography>
          ))}
        </Box>
      </TableCell>
      <TableCell className={classes.cell}>
        <Box display="flex" gridGap={5}>
          <IconButton onClick={() => onEdit?.()} disabled={!canEdit}>
            <Edit fontSize="small" />
          </IconButton>
          <IconButton onClick={() => onDelete?.()} disabled={!canEdit}>
            <Delete fontSize="small" />
          </IconButton>
        </Box>
      </TableCell>
    </TableRow>
  );
}
