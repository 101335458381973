import React from 'react';

import { makeStyles } from '@material-ui/core';

import { ScrollTopButton } from '../ScrollTopButton';
import { Content } from './Content';
import { Header, HeaderProps } from './Header';
import { HeaderContent } from './HeaderContent';
import { NarrowBoxProps } from './NarrowBox';
import { Page, PageProps } from './Page';
import { SidebarContent, SidebarContentProps } from './SidebarContent';

const useStyles = makeStyles(theme => ({
  content: {
    paddingBottom: theme.spacing(12),
  },
}));

export type PageLayoutProps = PageProps &
  Pick<
    HeaderProps,
    | 'title'
    | 'headerBreadcrumbs'
    | 'headerTabs'
    | 'stickyHeader'
    | 'stickyHeaderTabs'
    | 'headerTabsRef'
    | 'isLoading'
    | 'pageTitleOverride'
  > &
  Pick<SidebarContentProps, 'sideBarRight' | 'sideBarLeft'> & {
    narrow?: boolean | NarrowBoxProps['width'];
    headerContent?: React.ReactNode;
    scrollButton?: boolean;
  };

export const PageLayout = ({
  children,
  sideBarRight,
  sideBarLeft,
  title,
  headerBreadcrumbs,
  headerTabs,
  headerContent,
  stickyHeader,
  stickyHeaderTabs,
  headerTabsRef,
  isLoading,
  pageTitleOverride,
  scrollButton = false,
  narrow,
}: PageLayoutProps) => {
  const classes = useStyles();

  return (
    <Page>
      {scrollButton && <ScrollTopButton />}
      <Header
        title={title}
        isLoading={isLoading}
        pageTitleOverride={pageTitleOverride}
        narrow={narrow}
        headerBreadcrumbs={headerBreadcrumbs}
        headerTabs={headerTabs}
        stickyHeader={stickyHeader}
        stickyHeaderTabs={stickyHeaderTabs}
        headerTabsRef={headerTabsRef}
      >
        {headerContent ? <HeaderContent>{headerContent}</HeaderContent> : null}
      </Header>
      <Content narrow={narrow} className={classes.content}>
        {sideBarLeft || sideBarRight ? (
          <SidebarContent sideBarLeft={sideBarLeft} sideBarRight={sideBarRight}>
            {children}
          </SidebarContent>
        ) : (
          children
        )}
      </Content>
    </Page>
  );
};
