import React, { Fragment, useEffect } from 'react';
import { useAsyncFn } from 'react-use';

import {
  Box,
  Button,
  Checkbox,
  Fade,
  ListItemText,
  Table,
  TableBody,
  TableCell,
  TableRow,
  Typography,
  styled,
} from '@material-ui/core';
import Add from '@material-ui/icons/Add';
import DeleteIcon from '@material-ui/icons/Delete';

import {
  ActionBar,
  FilterBar,
  FilterListEmptyState,
  Highlight,
  StickyTableContainer,
  StickyTableHead,
  useAlert,
  useDialog,
  useListFilter,
  useMeasureDimensions,
} from '@spotify-confidence/core-react';
import { IdentityFragment } from '@spotify-confidence/plugin-graphql';
import {
  IdentityAvatar,
  IdentityUtils,
} from '@spotify-confidence/plugin-permissions-react';

import { useGroupMembers } from '../../hooks/useGroupMembers';
import { identityType } from '../utils';
import { AddMembersDialog } from './AddMembersDialog';

const NonRelativeAvatar = styled(IdentityAvatar)({
  position: 'static',
});

type GroupIdentityListProps = {
  name: string;
  readOnly?: boolean;
};

export const GroupIdentityList = ({
  name,
  readOnly,
}: GroupIdentityListProps) => {
  const { searchQuery } = useListFilter();
  const { openDialog, closeDialog } = useDialog();
  const alert = useAlert();

  const [selected, setSelected] = React.useState<IdentityFragment[]>([]);

  const {
    members: filteredIdentities,
    loading,
    error,
    removeMembers,
    addMembers,
  } = useGroupMembers(name);

  const [actionBarRef, { height: actionBarHeight }] =
    useMeasureDimensions<HTMLDivElement>();

  const handleSelectAll = (checked: boolean) => {
    if (checked) {
      setSelected(filteredIdentities);
    } else {
      setSelected(
        selected.filter(
          selectedIdentity => !filteredIdentities.includes(selectedIdentity),
        ),
      );
    }
  };

  const handleAdd = () => {
    openDialog({
      content: (
        <AddMembersDialog
          name={name}
          onAdd={async (identities: string[]) => {
            await addMembers(identities);
            closeDialog();
          }}
        />
      ),
    });
  };

  const [{ error: removeError, loading: removing }, handleRemove] =
    useAsyncFn(async () => {
      await removeMembers(selected.map(identity => identity.name));
      setSelected([]);
    }, [selected]);

  useEffect(() => {
    if (removeError) {
      alert.post({
        message: removeError.message,
        severity: 'error',
      });
    }
  }, [removeError]);

  const allSelected =
    selected.length === filteredIdentities.length && selected.length > 0;
  const someSelected =
    selected.length > 0 && selected.length < filteredIdentities.length;
  return (
    <Fragment>
      <ActionBar ref={actionBarRef} topOffset={0}>
        <FilterBar />
        <Button
          startIcon={<Add />}
          variant="contained"
          size="small"
          color="primary"
          data-testid="add-members-button"
          onClick={handleAdd}
          disabled={readOnly}
        >
          Add
        </Button>
      </ActionBar>

      {filteredIdentities.length === 0 && !loading ? (
        <FilterListEmptyState
          error={error}
          type="member"
          description={
            <Box
              display="flex"
              flexDirection="column"
              gridGap={32}
              alignItems="center"
            >
              <Typography variant="body2">
                When you add members to the group, they will appear here.
              </Typography>
              <Button
                startIcon={<Add />}
                variant="outlined"
                onClick={handleAdd}
                disabled={readOnly}
              >
                Add members
              </Button>
            </Box>
          }
        />
      ) : (
        <StickyTableContainer loading={loading} error={error}>
          <Table>
            <StickyTableHead topOffset={actionBarHeight}>
              <TableRow>
                <TableCell width={64}>
                  <Checkbox
                    onChange={e => handleSelectAll(e.target.checked)}
                    checked={allSelected}
                    indeterminate={someSelected}
                    disabled={readOnly}
                  />
                </TableCell>
                <TableCell>
                  {selected.length === 0 ? (
                    'Name'
                  ) : (
                    <Fade in={selected.length !== 0}>
                      <Button
                        size="small"
                        color="secondary"
                        startIcon={<DeleteIcon />}
                        variant="outlined"
                        disabled={removing}
                        onClick={() => handleRemove()}
                      >
                        Remove
                      </Button>
                    </Fade>
                  )}
                </TableCell>
              </TableRow>
            </StickyTableHead>
            <TableBody>
              {filteredIdentities.map(identity => (
                <TableRow
                  key={identity.name}
                  selected={selected.includes(identity)}
                >
                  <TableCell>
                    <Checkbox
                      disabled={readOnly}
                      checked={selected.includes(identity)}
                      onChange={e => {
                        if (e.target.checked) {
                          setSelected(selected.concat(identity));
                        } else {
                          setSelected(
                            selected.filter(i => i.name !== identity.name),
                          );
                        }
                      }}
                    />
                  </TableCell>
                  <TableCell>
                    <Box display="flex" alignItems="center" gridGap={8}>
                      <NonRelativeAvatar {...identity} />
                      <ListItemText
                        primary={
                          <Highlight highlight={searchQuery}>
                            {identity.displayName}
                          </Highlight>
                        }
                        secondary={IdentityUtils.getIdentityDescription(
                          identity,
                          identityType(identity),
                        )}
                      />
                    </Box>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </StickyTableContainer>
      )}
    </Fragment>
  );
};
