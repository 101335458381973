import { StringUtils } from '@spotify-confidence/core-react';

/**
 * 🧪 Variant
 */
const NAME_PATTERN = 'variants';

export const inferDisplayName = (name: string) =>
  name.split('/').at(-1) ?? 'untitled-variant';

export const inferFlagNameFromVariant = (name: string) => {
  return name.replace(`/variants/${inferDisplayName(name)}`, '');
};

export const newDisplayName = (index: number) =>
  index === 0 ? 'control' : `variant-${index}`;

export const formatDisplayName = StringUtils.kebabCase;

export const newName = (flagName: string) => (displayName: string) =>
  [flagName, NAME_PATTERN, formatDisplayName(displayName)].join('/');
