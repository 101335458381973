import React from 'react';

import { Box, FormGroup, FormHelperText, InputLabel } from '@material-ui/core';

import { ConfidenceWebsiteLink } from '@spotify-confidence/core-react';
import {
  InputMaybe,
  MetricsV1FilterInput,
} from '@spotify-confidence/plugin-graphql';

import { Expression } from './Expression';
import { useMetricFilters } from './useMetricFilters';

export type SetOp = 'or' | 'and';
export const MetricFilters = ({
  factTable: factTableName,
  onChange,
  filter: savedFilter,
}: {
  filter?: InputMaybe<MetricsV1FilterInput>;
  onChange: (v: InputMaybe<MetricsV1FilterInput>) => void;
  factTable?: string;
}) => {
  const { filter, ...rest } = useMetricFilters({
    filter: savedFilter,
    onChange,
  });

  return (
    <FormGroup>
      <InputLabel>Filter</InputLabel>
      <FormHelperText>
        Include rows that match the following criteria.{' '}
        <ConfidenceWebsiteLink
          route="/docs/metrics#filtered-metrics"
          underline="always"
        >
          Learn more
        </ConfidenceWebsiteLink>
        .
      </FormHelperText>
      <Box>
        <Expression
          path="set/0"
          criterias={filter.criteria}
          expression={filter.expression}
          factTableName={factTableName}
          {...rest}
        />
      </Box>
    </FormGroup>
  );
};
