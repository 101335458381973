import * as React from 'react';

import { Box, Button, Typography } from '@material-ui/core';

import { useAnalytics } from '@backstage/core-plugin-api';

import { Docs } from './Docs';
import { useStepContext } from './StepContext';
import { useStepperContext } from './StepperContext';

type StepProps = {
  /** product documentation for the wizard step */
  docs?: React.ReactNode;
  /** content of the wizard step */
  children?: React.ReactNode;
};

export function Step(props: StepProps) {
  const { docs, children } = props;
  const { onBack, canNext = true, onNext } = useStepContext();
  const ref = React.useRef<HTMLElement>();
  const { activeStep, setActiveStep, totalSteps, onComplete, stepLabel } =
    useStepperContext();
  const [validating, setValidating] = React.useState<boolean>(false);
  const [error, setError] = React.useState<string | undefined>(undefined);
  const analytics = useAnalytics();

  const onBackClick = async () => {
    setError(undefined);
    if (onBack) {
      onBack();
    }
    setActiveStep(prevActiveStep => prevActiveStep - 1);
  };

  const onContinueClick = async () => {
    if (onNext) {
      onNext();
    }
    setActiveStep(prevActiveStep => prevActiveStep + 1);
  };

  const onSubmitClick = async () => {
    if (onComplete) {
      try {
        setValidating(true);
        await onComplete();
      } catch (e) {
        const err = e instanceof Error ? e : new Error(`${e}`);
        setError(err.message);
        analytics.captureEvent('error', 'Setup Wizard', {
          attributes: {
            message: err.message,
          },
        });
      } finally {
        setValidating(false);
      }
    }
  };

  const isFirstStep = activeStep === 0;
  const isLastStep = activeStep === totalSteps - 1;

  React.useEffect(() => {
    analytics.captureEvent('wizard-step-view', stepLabel, {
      attributes: {
        'wizard-step-number': activeStep + 1,
      },
    });
  }, [activeStep, analytics, stepLabel]);

  React.useEffect(() => {
    ref.current?.parentElement?.scrollTo({ top: 0, behavior: 'smooth' });
  }, [activeStep]);

  return (
    <>
      <Box
        // @ts-ignore - bug in Box type which doesn't accept "ref", but it works as intended
        ref={ref}
        display="flex"
        flexDirection="column"
        margin={3}
        gridRowGap={3}
        gridArea="step"
      >
        {children}
        {error && (
          <Typography variant="body2" color="error">
            {error}
          </Typography>
        )}
        <Box display="flex" justifyContent="flex-end" paddingY={1} gridGap={8}>
          {!isFirstStep && (
            <Button
              size="medium"
              variant="outlined"
              disabled={validating}
              onClick={onBackClick}
            >
              <span>Go back</span>
            </Button>
          )}
          {!isLastStep && (
            <Button
              size="medium"
              variant="contained"
              color="primary"
              onClick={onContinueClick}
              disabled={!canNext}
            >
              <span>Next</span>
            </Button>
          )}
          {isLastStep && (
            <Button
              size="medium"
              variant="contained"
              color="primary"
              onClick={onSubmitClick}
              disabled={!canNext}
            >
              <span>Complete</span>
            </Button>
          )}
        </Box>
      </Box>
      {docs && <Docs>{docs}</Docs>}
    </>
  );
}
