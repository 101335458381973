import React from 'react';

import {
  Box,
  Chip,
  DialogActions,
  LinearProgress,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  makeStyles,
} from '@material-ui/core';
import { Alert } from '@material-ui/lab';

import {
  CodeEditor,
  DialogBody,
  DialogHeader,
  useDialog,
} from '@spotify-confidence/core-react';
import {
  getTypeOrNull,
  useGetMetricCalculationQuery,
} from '@spotify-confidence/plugin-graphql';

const useStyles = makeStyles(theme => ({
  table: {
    marginBottom: theme.spacing(2),
  },
}));

export const MetricCalculationDialog = ({
  name,
  calculationName,
}: {
  name: string;
  calculationName: string;
}) => {
  const { closeDialog } = useDialog();
  const classes = useStyles();

  const { data, loading, error } = useGetMetricCalculationQuery({
    variables: { name: calculationName },
  });

  const calculation = getTypeOrNull(
    data?.metricCalculation,
    'MetricsV1MetricCalculation',
  );

  const metricBucketStats = calculation?.metricResults?.metricBucketStats || [];

  // Extract unique dimension values
  const dimensions = [
    ...new Set(
      metricBucketStats.map(bucket =>
        bucket.dimensions
          .map(dimension => `${dimension.dimension}=${dimension.value}`)
          .join(','),
      ),
    ),
  ];

  // Group data by dimension value
  const groupedData = dimensions.map(dimensionValue => ({
    dimensionValue,
    buckets: metricBucketStats.filter(
      bucket =>
        bucket.dimensions
          .map(dimension => `${dimension.dimension}=${dimension.value}`)
          .join(',') === dimensionValue,
    ),
  }));
  return (
    <>
      <DialogHeader onClose={closeDialog} title={`Calculation for ${name}`} />
      <DialogBody autoHeight>
        {loading ? <LinearProgress /> : null}
        {!loading &&
          calculation?.metricResults?.metricBucketStats &&
          calculation?.metricResults?.metricBucketStats.length > 0 &&
          groupedData.map(group => (
            <div key={group.dimensionValue}>
              {group.buckets[0].dimensions.map(dimension => (
                <Chip size="small" label={dimension.value ?? ''} />
              ))}
              {group.buckets.length > 0 && (
                <Table
                  size="small"
                  aria-label="results"
                  className={classes.table}
                >
                  <TableHead>
                    <TableRow>
                      <TableCell>Group</TableCell>
                      <TableCell>Count</TableCell>
                      <TableCell>Count missing</TableCell>
                      {group.buckets[0].summaryStats.averageSummaryStats
                        ?.count !== undefined && (
                        <>
                          <TableCell>Mean</TableCell>
                          <TableCell>Variance</TableCell>
                        </>
                      )}
                      {group.buckets[0].summaryStats.ratioSummaryStats
                        ?.count !== undefined && (
                        <>
                          <TableCell>Numerator Mean</TableCell>
                          <TableCell>Numerator Variance</TableCell>
                          <TableCell>Denominator Mean</TableCell>
                          <TableCell>Denominator Variance</TableCell>
                        </>
                      )}
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {group.buckets.map(bucket => (
                      <TableRow key={bucket.variant}>
                        <TableCell
                          component="th"
                          scope="row"
                          data-testid="variant"
                        >
                          {bucket.variant}
                        </TableCell>
                        {bucket.summaryStats?.averageSummaryStats?.count !==
                          undefined && (
                          <>
                            <TableCell data-testid="count">
                              {bucket.summaryStats?.averageSummaryStats?.count}
                            </TableCell>
                            <TableCell data-testid="count-missing">
                              {
                                bucket.summaryStats?.averageSummaryStats
                                  ?.countMissing
                              }
                            </TableCell>
                            <TableCell data-testid="mean">
                              {bucket.summaryStats?.averageSummaryStats?.mean}
                            </TableCell>
                            <TableCell data-testid="variance">
                              {
                                bucket.summaryStats?.averageSummaryStats
                                  ?.variance
                              }
                            </TableCell>
                          </>
                        )}
                        {bucket.summaryStats?.ratioSummaryStats?.count !==
                          undefined && (
                          <>
                            <TableCell data-testid="count">
                              {bucket.summaryStats?.ratioSummaryStats?.count}
                            </TableCell>
                            <TableCell data-testid="count-missing">
                              {
                                bucket.summaryStats?.ratioSummaryStats
                                  ?.countMissing
                              }
                            </TableCell>
                            <TableCell data-testid="numerator-mean">
                              {
                                bucket.summaryStats?.ratioSummaryStats
                                  ?.numeratorMean
                              }
                            </TableCell>
                            <TableCell data-testid="numerator-variance">
                              {
                                bucket.summaryStats?.ratioSummaryStats
                                  ?.numeratorVariance
                              }
                            </TableCell>
                            <TableCell data-testid="denominator-mean">
                              {
                                bucket.summaryStats?.ratioSummaryStats
                                  ?.denominatorMean
                              }
                            </TableCell>
                            <TableCell data-testid="denominator-variance">
                              {
                                bucket.summaryStats?.ratioSummaryStats
                                  ?.denominatorVariance
                              }
                            </TableCell>
                          </>
                        )}
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              )}
            </div>
          ))}
        {!loading && calculation?.sql && (
          <CodeEditor value={calculation.sql} mode="sql" readOnly />
        )}
      </DialogBody>
      <DialogActions>
        <Box width="100%">
          {error ? (
            <Box width="100%">
              <Alert severity="error">{error.message}</Alert>
            </Box>
          ) : null}
        </Box>
      </DialogActions>
    </>
  );
};
