import React from 'react';

import {
  Box,
  Collapse,
  CollapseProps,
  IconButton,
  Tooltip,
  makeStyles,
} from '@material-ui/core';
import ExpandLess from '@material-ui/icons/ExpandLess';
import ExpandMore from '@material-ui/icons/ExpandMore';

import classNames from 'classnames';

const useStyles = makeStyles(theme => ({
  container: {
    position: 'relative',
  },
  hiddenOverlay: ({
    gradientColor,
    inPaper,
  }: Pick<ExpandableContentProps, 'gradientColor' | 'inPaper'>) => ({
    position: 'absolute',
    top: 0,
    bottom: 0,
    left: 0,
    right: 0,
    background: `linear-gradient(0deg, ${
      gradientColor || inPaper
        ? theme.palette.background.paper
        : theme.palette.background.default
    } 0%, transparent 30%)`,
  }),
}));

type ExpandableContentProps = Omit<CollapseProps, 'in' | 'collapsedSize'> & {
  collapsedSize?: number;
  inPaper?: boolean;
  defaultOpen?: boolean;
  gradientColor?: string;
};

export const ExpandableContent = ({
  collapsedSize = 200,
  inPaper,
  defaultOpen,
  gradientColor,
  children,
  ...collapseProps
}: ExpandableContentProps) => {
  const classes = useStyles({ gradientColor, inPaper });
  const [open, setOpen] = React.useState(defaultOpen);

  const contentRef = React.useRef<HTMLDivElement | null>(null);
  const [childHeight, setChildHeight] = React.useState(collapsedSize);
  const hasHiddenContent = childHeight > collapsedSize ? true : false;

  const toggleOpen = () => {
    setOpen(currentState => !currentState);
  };

  React.useLayoutEffect(() => {
    setChildHeight(
      contentRef.current?.getBoundingClientRect().height ?? collapsedSize,
    );
  });

  return (
    <>
      <Collapse
        {...collapseProps}
        className={classNames(classes.container, collapseProps.className)}
        in={open}
        collapsedSize={Math.min(childHeight, collapsedSize)}
      >
        <div ref={contentRef}>{children}</div>
        {hasHiddenContent && !open && <div className={classes.hiddenOverlay} />}
      </Collapse>
      {hasHiddenContent && (
        <Box
          marginTop={1}
          display="flex"
          alignItems="center"
          justifyContent="center"
        >
          <Tooltip title={open ? 'Minimize' : 'Expand'} enterDelay={500}>
            <IconButton size="small" onClick={toggleOpen}>
              {open ? <ExpandLess /> : <ExpandMore />}
            </IconButton>
          </Tooltip>
        </Box>
      )}
    </>
  );
};
