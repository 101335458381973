import React from 'react';
import { useParams } from 'react-router-dom';

import {
  Box,
  Card,
  CardContent,
  Collapse,
  Typography,
  styled,
} from '@material-ui/core';
import { Alert, Skeleton } from '@material-ui/lab';

import {
  AdvancedOptions,
  Breadcrumbs,
  CardSection,
  CardSectionWrapper,
  HeaderContent,
  InlineSave,
  PageLayout,
  Section,
  SidebarSection,
  SidebarValue,
  SidebarValueWrapper,
  useAlert,
  useDialog,
} from '@spotify-confidence/core-react';
import {
  FlagsAdminV1SegmentState,
  SegmentFragment,
  SegmentFragmentDoc,
  getError,
  getTypeOrNull,
  isType,
  useGetSegmentQuery,
  useListFlagClientsQuery,
  useOnSegmentUpdatedSubscription,
  useUpdateSegmentMutation,
} from '@spotify-confidence/plugin-graphql';
import {
  IdentityChip,
  PermissionUtils,
  ResourcePermissionsButton,
} from '@spotify-confidence/plugin-permissions-react';

import { Link } from '@backstage/core-components';
import { useRouteRef } from '@backstage/core-plugin-api';

import { segmentsRouteRef } from '../../../routes';
import { segmentIsExclusive } from '../../domain/segment.model';
import { targetingCodec, targetingHooks } from '../../domain/targeting';
import { AllocateDialog } from '../AllocateDialog';
import { AllocationButton } from '../AllocationButton';
import { AllocationInput } from '../AllocationInput';
import { ExclusionCriteria } from '../ExclusionCriteria';
import { ExclusivityTags } from '../ExclusivityTags';
import { SegmentState } from '../SegmentState';
import { TargetingContainer } from '../Targeting';
import { CriterionOptionsProvider } from '../Targeting/CriterionOptionsProvider';
import { TargetingChangedWarning } from '../TargetingChangedWarning';
import { ArchiveButton } from './components';
import { EditAboutButton } from './components/AboutDialog/AboutDialog';

const ContainerWrapper = styled('div')(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  gridGap: theme.spacing(2),
  padding: theme.spacing(1),
}));

const SegmentPageContent = ({
  segment: remoteSegment,
}: {
  segment: SegmentFragment;
}) => {
  const alert = useAlert();
  const { openDialog } = useDialog();

  const [lastChangeMs, setLastChangeMs] = React.useState<null | number>(null);

  const {
    remoteIsDifferentFromInitial,
    currentIsDifferentFromInitial,
    currentIsDifferentFromRemote,
    applyRemoteChanges,
    segment,
    setSegment,
  } = targetingHooks.useSegmentState(remoteSegment);

  const hasCoordination = segmentIsExclusive(segment);
  const isAllocated =
    remoteSegment.state === FlagsAdminV1SegmentState.Allocated;
  const isArchived = remoteSegment.state === FlagsAdminV1SegmentState.Archived;
  const [saveSegment, { error: saveSegmentError, loading: savingSegment }] =
    useUpdateSegmentMutation();

  const handleSegmentChange = (updatedSegment: SegmentFragment) => {
    setLastChangeMs(new Date().getTime());
    setSegment(updatedSegment);
  };
  const onCancel = () => {
    setLastChangeMs(null);
    applyRemoteChanges();
  };
  const { data: clientsData, error: listClientsError } =
    useListFlagClientsQuery({
      variables: {
        pageSize: 100,
      },
    });

  const clients = isType(clientsData?.clients, 'IamV1ListClientsResponse')
    ? clientsData?.clients?.clients?.map(c => c.name) || []
    : [];
  const evaluationContextOptions =
    targetingHooks.useDeriveEvaluationContextSchema(
      clients,
      segment.targeting,
      '',
    );
  const handleSave = (e?: React.FormEvent<{}>) => {
    e?.preventDefault();
    setLastChangeMs(null);
    saveSegment({
      variables: {
        segment: {
          allocation: segment.allocation,
          targeting: targetingCodec.toInput(segment.targeting),
          name: segment.name,
        },
      },
      onCompleted: updated => {
        const updateError = getError(updated.updateSegment);
        const updateSegment = getTypeOrNull(
          updated.updateSegment,
          'FlagsAdminV1Segment',
        );
        if (updateError) {
          alert.post({
            severity: 'error',
            message: updateError.message,
          });
        } else if (updateSegment) {
          setLastChangeMs(null);
          if (updateSegment.state === FlagsAdminV1SegmentState.Unallocated) {
            openDialog({
              content: <AllocateDialog name={updateSegment.name} />,
            });
          }
        }
      },
    });
  };

  const hasBeenEdited = currentIsDifferentFromInitial;

  const canEdit = PermissionUtils.hasRelation(remoteSegment, 'can_edit');
  const disabled = isArchived || isAllocated || savingSegment || !canEdit;
  return (
    <Card data-testid="audience-container">
      <CardContent>
        {saveSegmentError && (
          <Alert severity="error">{saveSegmentError.message}</Alert>
        )}
        {listClientsError && (
          <Alert severity="error">{listClientsError.message}</Alert>
        )}

        <form onSubmit={handleSave}>
          <ContainerWrapper>
            <Box>
              <Typography variant="h6">Inclusion criteria</Typography>
              <TargetingChangedWarning
                open={
                  remoteIsDifferentFromInitial && currentIsDifferentFromRemote
                }
                onDiscard={applyRemoteChanges}
                onOverwrite={handleSave}
              />
              <CriterionOptionsProvider value={evaluationContextOptions}>
                <TargetingContainer
                  title="Determines who is targeted for inclusion."
                  targeting={segment.targeting}
                  readOnly={disabled || isAllocated}
                  onChange={targeting =>
                    handleSegmentChange?.({ ...segment, targeting })
                  }
                />
              </CriterionOptionsProvider>
            </Box>
            <Box marginTop={1}>
              <CardSectionWrapper inlineContainer>
                <CardSection>
                  <AllocationInput
                    segment={segment}
                    onAllocationChange={allocation =>
                      handleSegmentChange?.({ ...segment, allocation })
                    }
                    readOnly={savingSegment || isArchived}
                  />
                </CardSection>
              </CardSectionWrapper>
            </Box>
            <Box marginTop={2}>
              <AdvancedOptions enabled={hasCoordination}>
                <CardSectionWrapper>
                  <CardSection>
                    <ExclusionCriteria
                      readOnly={disabled}
                      allocation={segment.allocation}
                      onAllocationChange={allocation =>
                        handleSegmentChange?.({ ...segment, allocation })
                      }
                    />
                  </CardSection>
                  <CardSection>
                    <ExclusivityTags
                      readOnly={disabled}
                      allocation={segment.allocation}
                      onAllocationChange={allocation =>
                        handleSegmentChange?.({ ...segment, allocation })
                      }
                    />
                  </CardSection>
                </CardSectionWrapper>
              </AdvancedOptions>
            </Box>
          </ContainerWrapper>
          <Collapse in={hasBeenEdited} unmountOnExit>
            <Box textAlign="right" paddingTop={2}>
              <InlineSave
                reminder
                saving={savingSegment}
                onCancel={onCancel}
                lastChange={lastChangeMs}
                disabled={savingSegment}
                variant="text"
              />
            </Box>
          </Collapse>
        </form>
      </CardContent>
    </Card>
  );
};

export const SegmentPage = () => {
  const { id: segmentId } = useParams();
  const segmentName = `segments/${segmentId}`;
  const segmentsRoute = useRouteRef(segmentsRouteRef);

  const {
    data: segmentData,
    error: getSegmentError,
    loading,
  } = useGetSegmentQuery({
    variables: {
      name: segmentName,
    },
  });

  useOnSegmentUpdatedSubscription({
    onData: payload => {
      const s = payload.data.data?.segmentUpdated?.segment;
      if (s) {
        const cacheId = payload.client.cache.identify(s);
        if (cacheId) {
          payload.client.cache.writeFragment({
            id: cacheId,
            data: s,
            fragment: SegmentFragmentDoc,
            fragmentName: 'Segment',
          });
        }
      }
    },
  });
  const segment = getTypeOrNull(segmentData?.segment, 'FlagsAdminV1Segment');
  const canEdit = segment && PermissionUtils.hasRelation(segment, 'can_edit');
  const error = getError(segmentData?.segment);
  const state = segment?.state;
  const isAllocated = state === FlagsAdminV1SegmentState.Allocated;
  const isUnAllocated = state === FlagsAdminV1SegmentState.Unallocated;
  const showActions = isUnAllocated || isAllocated;

  return (
    <PageLayout
      isLoading={loading}
      title={segment?.displayName}
      headerBreadcrumbs={
        <Breadcrumbs>
          <Link to={segmentsRoute()}>Segments</Link>
          <Typography>{segment?.displayName}</Typography>
        </Breadcrumbs>
      }
      headerContent={
        <HeaderContent>
          <ResourcePermissionsButton
            name={segmentName}
            title={segment?.displayName ?? undefined}
          />
        </HeaderContent>
      }
      sideBarRight={
        <>
          {segment && (
            <>
              <Collapse in={showActions}>
                <SidebarSection title="Actions">
                  {isUnAllocated && (
                    <AllocationButton name={segmentName} disabled={!canEdit} />
                  )}
                  {isAllocated && (
                    <ArchiveButton name={segmentName} disabled={!canEdit} />
                  )}
                </SidebarSection>
              </Collapse>
              <SidebarSection
                title="About"
                action={
                  <EditAboutButton segment={segment} disabled={!canEdit} />
                }
              >
                <SidebarValueWrapper>
                  <SidebarValue
                    name="Name"
                    singleLine
                    value={segment.displayName}
                  />
                  <SidebarValue
                    name="Owner"
                    value={
                      isType(segment.owner, 'IamV1Identity') ? (
                        <IdentityChip {...segment?.owner} />
                      ) : (
                        'Unknown'
                      )
                    }
                  />
                </SidebarValueWrapper>
              </SidebarSection>
              <SidebarSection title="Status">
                <SegmentState state={segment.state} />
              </SidebarSection>
            </>
          )}
        </>
      }
    >
      <Section label="Audience">
        {loading && <Skeleton height={200} />}
        {error && <Alert severity="error">{error.message}</Alert>}
        {getSegmentError && (
          <Alert severity="error">{getSegmentError.message}</Alert>
        )}
        {segment && <SegmentPageContent segment={segment} />}
      </Section>
    </PageLayout>
  );
};
