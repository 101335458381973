import {
  ClientFragment,
  DraftEventFragment,
  EventDefinitionFragment,
  EventDefinitionFragmentDoc,
  EventSchemaEntryFragment,
  getError,
  getTypeOrNull,
  isError,
  useDraftFieldsQuery,
  useOnEventDefinitionUpdatedSubscription,
  useUpdateEventDefinitionMutation,
} from '@spotify-confidence/plugin-graphql';

import { differenceBetweenSchemas } from '../../schema/domain/schema.helpers';
import { toEventSchemaInput } from './useEvent';

export type DraftField = {
  reason: DraftEventFragment['reason'];
  payload: DraftEventFragment['payload'];
  lastSeen: Date;
  schema: EventSchemaEntryFragment[];
  client: ClientFragment | undefined | null;
  event: EventDefinitionFragment | undefined | null;
};

// Return true if the draft event has a different schema than the event definition.
export const isDifferent = (draftEvent: DraftEventFragment) => {
  const event = getTypeOrNull(
    draftEvent.eventDefinition,
    'EventsAdminV1EventDefinition',
  );

  const diff = differenceBetweenSchemas(
    event?.schema ?? [],
    draftEvent.schema?.structSchema?.schema ?? [],
  );

  return diff.length > 0;
};

export function useDraftFields(pollInterval = 30 * 1000) {
  const { data, error, fetchMore, loading } = useDraftFieldsQuery({
    pollInterval,
    onCompleted: result => {
      if (isError(result.queryDraftEvents)) {
        throw getError(result.queryDraftEvents);
      }
    },
  });

  // Listen on updates to event definitions and update the cache.
  useOnEventDefinitionUpdatedSubscription({
    onData: options => {
      const e = options.data.data?.eventDefinitionUpdated?.eventDefinition;
      if (e) {
        const cacheId = options.client.cache.identify(e);
        if (cacheId) {
          const cached = options.client.cache.readFragment({
            id: cacheId,
            fragment: EventDefinitionFragmentDoc,
            fragmentName: 'EventDefinition',
          }) as EventDefinitionFragment | null;

          if (!cached || cached.updateTime < e.updateTime) {
            options.client.cache.writeFragment({
              id: cacheId,
              data: e,
              fragment: EventDefinitionFragmentDoc,
              fragmentName: 'EventDefinition',
            });
          }
        }
      }
    },
  });

  const { draftEvents: rawDraftEvents, nextPageToken } = getTypeOrNull(
    data?.queryDraftEvents,
    'EventsAdminV1QueryDraftEventsResponse',
  ) ?? { draftEvents: [], nextPageToken: '' };

  const loadMore = () =>
    fetchMore({
      variables: {
        nextPageToken,
      },
    });

  const draftFields: DraftField[] = rawDraftEvents
    // we only care for events that do have a change that is different
    // from the event definition
    .filter(isDifferent)
    .map(draftEvent => ({
      reason: draftEvent.reason,
      payload: draftEvent.payload,
      lastSeen: new Date(draftEvent.eventTime),
      schema: draftEvent.schema?.structSchema?.schema ?? [],
      client: getTypeOrNull(draftEvent.client, 'IamV1Client'),
      event: getTypeOrNull(
        draftEvent.eventDefinition,
        'EventsAdminV1EventDefinition',
      ),
    }))
    .filter(draftField => draftField.event !== null);

  const [updateSchema] = useUpdateEventDefinitionMutation({
    update: (cache, { data: createData }) => {
      if (isError(createData?.updateEventDefinition)) {
        return;
      }

      const updatedEvent = getTypeOrNull(
        createData?.updateEventDefinition,
        'EventsAdminV1EventDefinition',
      );

      if (!updatedEvent) {
        return;
      }

      cache.modify({
        fields: {
          queryDraftEvents(response = { draftEvents: [] }) {
            const existingDraftEvents = response.draftEvents ?? [];
            const filteredDraftEvents = [...existingDraftEvents].filter(
              (draftEvent: DraftEventFragment) => {
                if (draftEvent.draftEventDefinition) {
                  // This was not a draft field
                  return true;
                }

                if (!draftEvent.eventDefinition) {
                  // should not happen, but :shrug:
                  return true;
                }

                const event = cache.readFragment({
                  id: cache.identify(draftEvent.eventDefinition),
                  fragment: EventDefinitionFragmentDoc,
                  fragmentName: 'EventDefinition',
                }) as EventDefinitionFragment | null;

                return updatedEvent.name !== event?.name;
              },
            );

            return {
              ...response,
              draftEvents: filteredDraftEvents,
            };
          },
        },
      });
    },
  });

  const updateEvent = async ({
    name,
    schema,
  }: {
    name: string;
    schema: EventSchemaEntryFragment[];
  }) => {
    const schemaInput = toEventSchemaInput(schema);

    const result = await updateSchema({
      variables: {
        event: {
          name,
          schema: schemaInput,
        },
        updateMask: 'schema',
      },
    });

    if (isError(result.data?.updateEventDefinition)) {
      throw getError(result.data?.updateEventDefinition);
    }

    return getTypeOrNull(
      result.data?.updateEventDefinition,
      'EventsAdminV1EventDefinition',
    );
  };

  return {
    draftFields,
    loading,
    error,
    loadMore,
    updateEvent,
  };
}
