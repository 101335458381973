import React, { PropsWithChildren, createContext, useContext } from 'react';

import { extractLastNameComponent } from '@spotify-confidence/core-react';
import { WorkflowInstanceFragment } from '@spotify-confidence/plugin-graphql';
import { PermissionUtils } from '@spotify-confidence/plugin-permissions-react';

import { useRouteRef } from '@backstage/core-plugin-api';

import { workflowInstanceRouteRef } from '../../../routes';
import { useWorkflowContext } from './WorkflowContext';

interface WorkflowInstanceContext extends WorkflowInstanceFragment {
  href: string;
  route: (subpage: string) => string;
  canEdit: boolean;
}

const context = createContext<WorkflowInstanceContext | undefined>(undefined);

export const WorkflowInstanceContextConsumer = context.Consumer;

export const WorkflowInstanceContextProvider = ({
  value,
  children,
}: PropsWithChildren<{ value: WorkflowInstanceFragment }>) => {
  const { workflowId: workflow } = useWorkflowContext();

  const id = extractLastNameComponent(value?.name);

  const workflowInstanceRoute = useRouteRef(workflowInstanceRouteRef);
  const href = workflowInstanceRoute?.({
    workflow,
    id: id!,
  });

  const canEdit = PermissionUtils.hasRelation(value, 'can_edit');

  const route = (subpage: string) => `${href}/${subpage}`;

  return (
    <context.Provider value={{ ...value, href, route, canEdit }}>
      {children}
    </context.Provider>
  );
};

export const useWorkflowInstanceContext = () => {
  const value = useContext(context);

  if (!value) {
    throw new Error(
      'useWorkflowInstance must be used within a WorkflowInstanceProvider',
    );
  }

  return value;
};
