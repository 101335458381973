import React from 'react';

import { TextField, TextFieldProps, makeStyles } from '@material-ui/core';

import classNames from 'classnames';

const useStyles = makeStyles(theme => ({
  readOnly: {},
  root: {
    boxShadow: 'none',
    border: 'none',
    color: 'inherit',
    borderRadius: theme.shape.borderRadius,
    maxHeight: '80%',
    ...theme.typography.body2,
    fontFeatureSettings: "'tnum'",
    fontVariantNumeric: 'tabular-num',
    '&, &:focus, .Mui-focused&&, &:focus&': {
      backgroundColor: theme.palette.background.paper,
    },
  },
  input: {
    padding: theme.spacing(1),
  },
  disabled: {
    color: theme.palette.text.primary,
  },
  adornedEnd: {
    paddingRight: theme.spacing(0.5),
  },
  adornedStart: {
    paddingLeft: theme.spacing(0.5),
  },
  select: {
    '$readOnly &': {
      paddingRight: theme.spacing(1),
    },
  },
  icon: {
    '$readOnly &': {
      display: 'none',
    },
  },
}));

export const ChipInput = (params: TextFieldProps) => {
  const classes = useStyles();
  const InputPropsClasses = params.InputProps?.classes;
  const SelectPropsClasses = params.SelectProps?.classes;

  const InputProps = {
    ...(params?.InputProps || {}),
    className: classNames({
      [classes.adornedEnd]: Boolean(params?.InputProps?.endAdornment),
      [classes.adornedStart]: Boolean(params?.InputProps?.startAdornment),
    }),
    classes: {
      root: classNames(classes.root, InputPropsClasses?.root),
      input: classNames(classes.input, InputPropsClasses?.input),
      disabled: classNames(classes.disabled, InputPropsClasses?.disabled),
    },
  };

  const SelectProps = {
    ...(params.SelectProps || {}),
    classes: {
      select: classNames(classes.select, SelectPropsClasses?.select),
      icon: classNames(classes.icon, SelectPropsClasses?.icon),
    },
  };

  return (
    <TextField
      {...params}
      className={classNames(params.className, {
        [classes.readOnly]: params.InputProps?.readOnly,
      })}
      InputProps={InputProps}
      SelectProps={SelectProps}
      margin="none"
      variant="outlined"
    />
  );
};
