import React from 'react';

import { Button } from '@material-ui/core';
import AddIcon from '@material-ui/icons/Add';

import {
  ActionBar,
  FilterBar,
  FilterListProvider,
  useDialog,
  useMeasureDimensions,
  useSearchParamsConfig,
} from '@spotify-confidence/core-react';
import { FlagsAdminV1EvaluationContextFieldOverrideInput } from '@spotify-confidence/plugin-graphql';
import { useCheckResourcePermissions } from '@spotify-confidence/plugin-permissions-react';

import { CreateSchemaOverrideDialog } from './CreateSchemaOverrideDialog/CreateSchemaOverrideDialog';
import { SchemaList } from './SchemaList';
import { useFlagContextSchemaList } from './useFlagContextSchemaList';
import { ValueType, valueTypeMap } from './valueUtils';

type SchemaTabProperties = {
  topOffset?: number;
  clients?: string[];
  loading?: boolean;
  error?: Error;
};

export const SchemaTab = ({
  clients = [],
  loading: clientsLoading,
  error: clientsError,
  topOffset = 0,
}: SchemaTabProperties) => {
  const { openDialog } = useDialog();
  const [actionBarRef, { height: actionBarHeight }] =
    useMeasureDimensions<HTMLDivElement>();

  const { allowed } = useCheckResourcePermissions({
    can: 'create_evaluation_context_field_override',
    name: 'account',
  });

  const { items, error, loading, existingOverrides } = useFlagContextSchemaList(
    { clients },
  );

  const createOverride = React.useCallback(
    (
      defaultValues?: Partial<FlagsAdminV1EvaluationContextFieldOverrideInput>,
    ) => {
      openDialog({
        content: (
          <CreateSchemaOverrideDialog
            defaultValues={defaultValues}
            existingOverrides={existingOverrides}
          />
        ),
      });
    },
    [openDialog, existingOverrides],
  );

  useSearchParamsConfig({
    params: ['create', 'type'],
    handleParams: params => {
      if (params.create === 'true') {
        createOverride(valueTypeMap[params.type as ValueType]);
      }
    },
  });

  return (
    <FilterWrapper>
      <ActionBar ref={actionBarRef} topOffset={topOffset}>
        <FilterBar />
        <Button
          variant="contained"
          color="primary"
          size="small"
          onClick={() => createOverride()}
          data-testid="create-schema-override-button"
          startIcon={<AddIcon />}
          disabled={!allowed}
        >
          Create
        </Button>
      </ActionBar>
      <SchemaList
        items={items}
        loading={clientsLoading || loading}
        error={clientsError || error}
        createOverride={allowed ? createOverride : undefined}
        topOffset={actionBarHeight + topOffset}
        existingOverrides={existingOverrides}
      />
    </FilterWrapper>
  );
};

function FilterWrapper({ children }: React.PropsWithChildren<{}>) {
  return (
    <FilterListProvider storageKey="client-schema-overrides">
      {children}
    </FilterListProvider>
  );
}
