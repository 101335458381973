import * as React from 'react';

import { useTheme } from '@material-ui/core';
import NetworkCheckIcon from '@material-ui/icons/NetworkCheck';
import WifiIcon from '@material-ui/icons/Wifi';
import WifiOffIcon from '@material-ui/icons/WifiOff';

import {
  DateUtils,
  VerboseTooltip as Tooltip,
} from '@spotify-confidence/core-react';

export type EventStatusIndicatorProps = {
  publishTime?: Maybe<string>;
  validationFailedTime?: Maybe<string>;
  verbose?: boolean;
};

export const EventStatusIndicator = ({
  publishTime,
  verbose,
}: EventStatusIndicatorProps) => {
  const theme = useTheme();

  if (!publishTime) {
    return (
      <Tooltip title="Has never been used" verbose={verbose}>
        <WifiOffIcon htmlColor={theme.palette.divider} />
      </Tooltip>
    );
  }

  const publishDate = new Date(publishTime!);
  const now = new Date();
  const delta = now.getTime() - publishDate.getTime();
  const days = delta / (1000 * 60 * 60 * 24);

  if (days >= 7) {
    return (
      <Tooltip
        title={`Last used ${DateUtils.xAgo(publishDate)}`}
        verbose={verbose}
      >
        <NetworkCheckIcon color="error" />
      </Tooltip>
    );
  }

  // active and nice
  return (
    <Tooltip
      title={`Last used ${DateUtils.xAgo(publishDate)}`}
      verbose={verbose}
    >
      <WifiIcon htmlColor={theme.palette.success.main} />
    </Tooltip>
  );
};
