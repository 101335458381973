import React from 'react';

import { Grow, styled, useTheme } from '@material-ui/core';

import confirmationDark from './confirmation-step-dark-mode.png';
import confirmationLight from './confirmation-step-light-mode.png';

const Image = styled('img')({
  objectFit: 'contain',
  objectPosition: 'center',
  maxWidth: '90%',
});

export const ConfirmationGraphics = ({}: {}) => {
  const theme = useTheme();

  return (
    <Grow in>
      <Image
        src={
          theme.palette.type === 'dark' ? confirmationDark : confirmationLight
        }
        alt=""
      />
    </Grow>
  );
};
