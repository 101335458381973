import { makeStyles } from '@material-ui/core';

import {
  RuleFragment,
  SchemaEntryFragment,
  getTypeOrNull,
} from '@spotify-confidence/plugin-graphql';

import { segmentIsExclusive } from '../../../../../segment/domain/segment.model';
import {
  targetingCodec,
  targetingModel,
} from '../../../../../segment/domain/targeting';

export type RuleViewProps = {
  rule: RuleFragment;
  className?: string;
  seenAttributes?: SchemaEntryFragment[] | undefined;
};

export const useRuleViewStyles = makeStyles(theme => ({
  chip: {
    marginBottom: 0,
  },
  attribute: {
    fontFamily: 'monospace',
  },
  title: {
    display: 'flex',
    alignItems: 'baseline',
    flexWrap: 'wrap',
    rowGap: theme.spacing(0.5),
  },
}));

export const getRuleTargetingState = (rule: RuleFragment) => {
  const segment = getTypeOrNull(rule.segment, 'FlagsAdminV1Segment');
  const targeting = targetingCodec.fromSchemaTargeting(segment?.targeting);
  const hasTargeting = targetingModel.isTargetingValid(targeting);
  const sticky = !!rule.materializationSpec?.writeMaterialization;
  const paused =
    sticky && rule.materializationSpec?.mode?.materializationMustMatch;
  const targetingCanBeIgnored =
    sticky && rule.materializationSpec?.mode?.materializationMustMatch;
  const targetingIsAlwaysIgnored = paused && targetingCanBeIgnored;
  const isExclusive = segmentIsExclusive(segment);

  return {
    segment,
    targeting,
    hasTargeting,
    sticky,
    paused,
    targetingCanBeIgnored,
    targetingIsAlwaysIgnored,
    isExclusive,
  };
};
