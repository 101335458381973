import React from 'react';
import { useParams } from 'react-router-dom';

import { Box, LinearProgress } from '@material-ui/core';
import { Alert } from '@material-ui/lab';

import {
  BackLink,
  WorkflowInstance,
  moduleHelpers,
} from '@spotify-confidence/plugin-workflows';
import {
  StatsData,
  useIsStatsModuleStrategySequential,
} from '@spotify-confidence/plugin-workflows-module-stats';

import { useMetricsModuleConfigProviderContext } from '../../../MetricsModule/MetricsModuleConfigProvider';
import { ResultSection } from './ResultSection';
import { useAnalyzeResult, useReanalyzeResult } from './utils';

type ResultsPageProps = {
  workflowInstance: NonNullable<WorkflowInstance>;
  groupAmount?: number;
  backHref?: string;
  reanalyze?: boolean;
};

function resultsLoader(reanalyze: boolean, name: string, fetch: boolean) {
  return reanalyze
    ? useReanalyzeResult(name, fetch)
    : useAnalyzeResult(name, fetch);
}

export const ResultsPage = ({
  workflowInstance,
  groupAmount,
  backHref,
  reanalyze = false,
}: ResultsPageProps) => {
  const { workflow, id } = useParams();
  const name = ['workflows', workflow, 'instances', id].join('/');

  const { results, metricAnnotations, loading, error } = resultsLoader(
    reanalyze,
    name,
    workflowInstance.state !== 'draft',
  );

  const { metricsData } = useMetricsModuleConfigProviderContext();

  const statsData = moduleHelpers.getModuleData<StatsData>(
    workflowInstance.moduleData,
    'stats',
  );

  const sequential = useIsStatsModuleStrategySequential(statsData);

  return (
    <>
      {backHref && (
        <Box marginBottom={2}>
          <BackLink to={backHref} />
        </Box>
      )}
      {error && (
        <Box marginBottom={2}>
          <Alert severity="error">{error.message}</Alert>
        </Box>
      )}
      {loading && <LinearProgress />}
      {!loading && (
        <ResultSection
          results={results}
          bucket={metricsData?.bucket ?? undefined}
          sequential={sequential}
          groupAmount={groupAmount}
          annotations={metricAnnotations}
        />
      )}
    </>
  );
};
