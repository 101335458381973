import React from 'react';

import { HiddenProps, Hidden as MuiHidden } from '@material-ui/core';

/* This is a fix to allow to use the Hidden component together with React 18.
 * more info herehttps://stackoverflow.com/questions/71810438/compilation-issue-with-react-typescript-and-material-ui-4
 * TODO: In the future we should migrate from using Hidden
 */
export const Hidden = (props: React.PropsWithChildren<HiddenProps>) => {
  // @ts-ignore
  return <MuiHidden {...props} />;
};
