import React, { useRef } from 'react';

import {
  Box,
  InputAdornment,
  InputLabel,
  TextField,
  Typography,
  makeStyles,
} from '@material-ui/core';
import { Autocomplete } from '@material-ui/lab';

import { useAutocompleteCreateOption } from '@spotify-confidence/core-react';
import {
  CommentZone,
  CommentZoneContext,
} from '@spotify-confidence/plugin-comments-react';
import { getTypeOrNull } from '@spotify-confidence/plugin-graphql';

import { useRouteRef } from '@backstage/core-plugin-api';

import { clientSchemaRouteRef } from '../../../../routes';
import { useCriterionOptions } from '../../../../segment/components/Targeting/CriterionOptionsProvider';

type RandomizationInputProps = {
  readOnly?: boolean;
  onChange?: (key: string) => void;
  value?: string | null;
  label?: string;
  helperText?: string;
  placeholder?: string;
  fullWidth?: boolean;
  'data-testid'?: string;
  id?: string;
  minWidth?: number;
  required?: boolean;
};

type RandomizationKeyOption = {
  name: string;
  displayName: string | undefined;
};

const useStyles = makeStyles({
  input: ({ minWidth }: { minWidth: number }) => ({
    minWidth: minWidth,
  }),
});

export const RandomizationInput = ({
  readOnly = false,
  onChange,
  value,
  label = 'Randomization',
  helperText = 'Field in evaluation context to randomly determine who is included in which group. Must be of type Entity.',
  fullWidth = false,
  'data-testid': dataTestId = 'randomization-field',
  placeholder = 'Field name',
  id = 'randomization',
  minWidth = 300,
  required,
}: RandomizationInputProps) => {
  const classes = useStyles({ minWidth });
  const clientSchemaRoute = useRouteRef(clientSchemaRouteRef);
  const inputRef = useRef<HTMLInputElement>();
  const { criterionOptions } = useCriterionOptions();
  const targetingKeyOptions: RandomizationKeyOption[] = React.useMemo(() => {
    // We only allow strings for randomization
    // We only want to suggest fields connected to entities to make the connection to metrics more straightforward
    return (
      criterionOptions
        .filter(
          ({ type, hidden, semanticType }) =>
            type === 'String' && !hidden && semanticType?.entityReference,
        )
        .map(({ name, displayName, semanticType }) => {
          const entity = getTypeOrNull(
            semanticType?.entityReference?.entity,
            'MetricsV1Entity',
          );

          return {
            name,
            displayName: entity?.displayName ?? displayName,
          };
        }) || []
    );
  }, [criterionOptions]);
  const [inputValue, setInputValue] = React.useState(value ?? '');

  const onCreate = clientSchemaRoute
    ? () => {
        window.open(
          `${clientSchemaRoute()}?create=true&type=entityReference`,
          '_blank',
        );
      }
    : undefined;

  const autocompleteProps = useAutocompleteCreateOption({
    onCreate,
    label: 'Create entity override',
  });

  const renderOption = React.useCallback((option: RandomizationKeyOption) => {
    return (
      <span>
        {option.displayName && (
          <Typography component="span" color="textSecondary">
            ({option.displayName})
          </Typography>
        )}{' '}
        {option.name}
      </span>
    );
  }, []);

  const handleInputChange = React.useCallback(
    (_event: any, newValue: string) => {
      setInputValue(newValue);
      onChange?.(newValue);
    },
    [],
  );

  const matchedOption = targetingKeyOptions.find(
    option => option.name === value,
  );

  return (
    <Box
      display="flex"
      flexDirection="column"
      justifyContent="space-between"
      height="100%"
    >
      <CommentZoneContext zone="randomization">
        <div>
          <InputLabel required={required} htmlFor={id}>
            <Typography variant="h6" component="label" htmlFor={id}>
              <CommentZone id="title" component="span">
                {label}
              </CommentZone>
            </Typography>
          </InputLabel>
          <Typography variant="body1" color="textSecondary">
            {helperText}
          </Typography>
        </div>
        <CommentZone id="value">
          <Autocomplete
            freeSolo
            autoSelect
            includeInputInList
            value={matchedOption ?? value}
            inputValue={inputValue}
            onInputChange={handleInputChange}
            options={targetingKeyOptions}
            disabled={readOnly}
            data-testid={dataTestId}
            fullWidth={fullWidth}
            getOptionLabel={option => {
              if (typeof option === 'string') {
                return option;
              }

              return option.name;
            }}
            getOptionSelected={(option, curValue) =>
              option.name === curValue.name
            }
            renderOption={renderOption}
            renderInput={props => (
              <TextField
                {...props}
                fullWidth={fullWidth}
                className={classes.input}
                inputRef={inputRef}
                onKeyDown={event => {
                  if (event.key === 'Enter') {
                    inputRef.current?.blur();
                    event.preventDefault();
                  }
                }}
                inputProps={{ ...props.inputProps, id }}
                InputProps={{
                  ...props.InputProps,
                  startAdornment:
                    matchedOption && matchedOption.displayName ? (
                      <InputAdornment position="end">
                        <Typography component="span" color="textSecondary">
                          ({matchedOption.displayName})
                        </Typography>
                      </InputAdornment>
                    ) : null,
                }}
                variant="outlined"
                margin="dense"
                placeholder={placeholder}
                name="targeting-key"
                required={required}
              />
            )}
            {...autocompleteProps}
          />
        </CommentZone>
      </CommentZoneContext>
    </Box>
  );
};
