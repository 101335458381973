import React from 'react';

import { Typography } from '@material-ui/core';

import {
  ConfidenceWebsiteLink,
  FormFieldsBox,
  TutorialWizard,
} from '@spotify-confidence/core-react';

import { CodeSnippets, Instructions } from '../Instructions';
import { ProgrammingLanguage } from '../context';
import { MonitorFlag } from './MonitorFlag';
import {
  AndroidClientSnippets,
  FlutterClientSnippets,
  GoClientSnippets,
  JavaClientSnippets,
  JsClientSnippets,
  JsReactSnippets,
  PythonClientSnippets,
  RustClientSnippets,
  SwiftClientSnippets,
} from './snippets';

const snippets: CodeSnippets = {
  [ProgrammingLanguage.js]: apiKey => <JsClientSnippets apiKey={apiKey} />,
  [ProgrammingLanguage.js_react]: apiKey => <JsReactSnippets apiKey={apiKey} />,
  [ProgrammingLanguage.swift]: apiKey => (
    <SwiftClientSnippets apiKey={apiKey} />
  ),
  [ProgrammingLanguage.kotlin]: apiKey => (
    <AndroidClientSnippets apiKey={apiKey} />
  ),
  [ProgrammingLanguage.java]: apiKey => <JavaClientSnippets apiKey={apiKey} />,
  [ProgrammingLanguage.go]: apiKey => <GoClientSnippets apiKey={apiKey} />,
  [ProgrammingLanguage.python]: apiKey => (
    <PythonClientSnippets apiKey={apiKey} />
  ),
  [ProgrammingLanguage.rust]: apiKey => <RustClientSnippets apiKey={apiKey} />,
  [ProgrammingLanguage.flutter]: apiKey => (
    <FlutterClientSnippets apiKey={apiKey} />
  ),
};

export const FlagStep = () => {
  return (
    <TutorialWizard.Step
      docs={
        <div>
          <Typography variant="subtitle1">Flags</Typography>
          <Typography paragraph color="textSecondary" variant="body2">
            To learn more about flags, read our{' '}
            <ConfidenceWebsiteLink
              route="/docs/flags/introduction"
              underline="always"
            >
              full documentation
            </ConfidenceWebsiteLink>
            .
          </Typography>
        </div>
      }
    >
      <FormFieldsBox>
        <div>
          <TutorialWizard.StepTitle
            title="Control the user experience"
            estimatedTime="10 minutes"
          />
          <Typography paragraph>
            Feature flags make it possible to give different experiences to
            different users, which is the foundation for experimentation. For
            example, you can use feature flags to turn features on or off, or to
            change the design of the product.
          </Typography>
          <Typography paragraph>
            This step will guide you to add an example feature flag to your
            product, which you will be able to control remotely. Nothing will
            visibly change for your users unless you add additional logic to
            this code.
          </Typography>
        </div>

        <div>
          <Typography variant="h5">Add a feature flag</Typography>
          <Instructions snippets={snippets} />
          <MonitorFlag />
        </div>
      </FormFieldsBox>
    </TutorialWizard.Step>
  );
};
