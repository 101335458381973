import React from 'react';

import { Typography } from '@material-ui/core';

import { CodeSnippet } from '../../CodeSnippet';
import { f } from '../../CodeSnippet';

export const SwiftClientSnippets = () => {
  return (
    <>
      <Typography variant="body1" gutterBottom>
        Let's track the sample "tutorial-event" event from your application and
        make sure it gets published to our backend.
      </Typography>
      <CodeSnippet
        language="swift"
        showLineNumbers
        text={f(`
try confidence.track(eventName: "tutorial-event", data: [:])
confidence.flush()
`)}
        showCopyCodeButton
      />
      <Typography variant="body1" gutterBottom>
        The flush call ensures the event is scheduled immediately for upload by
        the SDK.
      </Typography>
    </>
  );
};
