import React from 'react';

import { Table, TableBody, TableCell, TableRow } from '@material-ui/core';

import {
  ACTION_BAR_HEIGHT,
  ConfidenceWebsiteLink,
  DateUtils,
  FilterListEmptyState,
  MediumScreenCell,
  ResourceTableRow,
  StickyTableContainer,
  StickyTableHead,
  extractLastNameComponent,
  useListFilter,
} from '@spotify-confidence/core-react';
import { ClientFragment } from '@spotify-confidence/plugin-graphql';

import { Link } from '@backstage/core-components';
import { useRouteRef } from '@backstage/core-plugin-api';

import { clientRouteRef } from '../../../../routes';

type ClientListProps = {
  clients?: ClientFragment[];
  loading?: boolean;
  error?: Error;
  createClient?: () => void;
  topOffset?: number;
};

export const ClientsList = ({
  clients = [],
  loading,
  error,
  createClient,
  topOffset = ACTION_BAR_HEIGHT,
}: ClientListProps) => {
  const clientRoute = useRouteRef(clientRouteRef);

  const { searchQuery } = useListFilter();
  const filteredClients = clients.filter(client =>
    client.displayName.toLowerCase().includes(searchQuery?.toLowerCase() ?? ''),
  );

  if (filteredClients.length === 0) {
    return (
      <FilterListEmptyState
        error={error}
        loading={loading}
        type="client"
        description={
          <>
            Clients allow you to authenticate requests to Confidence.{' '}
            <ConfidenceWebsiteLink
              route="/docs/clients#create-a-client"
              underline="always"
            >
              Learn more about clients.
            </ConfidenceWebsiteLink>
          </>
        }
        onCreate={createClient}
      />
    );
  }

  return (
    <StickyTableContainer error={error} loading={loading}>
      <Table>
        <StickyTableHead topOffset={topOffset}>
          <TableRow>
            <TableCell>Name</TableCell>
            <MediumScreenCell align="right">Last seen</MediumScreenCell>
          </TableRow>
        </StickyTableHead>
        <TableBody>
          {filteredClients.map(client => (
            <ResourceTableRow
              key={client.name}
              routeRef={clientRouteRef}
              name={client.name}
              data-testid={client.displayName}
            >
              <TableCell>
                <Link
                  to={clientRoute({
                    id: extractLastNameComponent(client.name)!,
                  })}
                >
                  {client.displayName}
                </Link>
              </TableCell>

              <MediumScreenCell width={200} align="right">
                {client.lastSeenTime
                  ? `${DateUtils.xAgo(new Date(client.lastSeenTime))}`
                  : 'Not yet seen'}
              </MediumScreenCell>
            </ResourceTableRow>
          ))}
        </TableBody>
      </Table>
    </StickyTableContainer>
  );
};
