import React from 'react';

type StepState = {
  /** callback for when the Back button is clicked */
  onBack?: () => void;
  /** enables the Next button if set to true */
  canNext?: boolean;
  /** callback for when the Next button is clicked */
  onNext?: () => void;
};

export const StepContext = React.createContext<StepState>({
  canNext: true,
});

export const useStepContext = () => {
  const context = React.useContext(StepContext);
  if (!context) {
    throw new Error('useStepContext must be used within a StepContextProvider');
  }
  return context;
};
