import React, { HTMLProps, forwardRef } from 'react';

import {
  Drawer,
  IconButton,
  type IconButtonProps,
  List,
  Menu,
  type MenuProps,
  WithStyles,
  useMediaQuery,
  useTheme,
  withStyles,
} from '@material-ui/core';
import MoreHoriz from '@material-ui/icons/MoreHoriz';

import classNames from 'classnames';

import { FloatingHeaderActionsContextProvider } from './context';

const styles = {
  root: {
    margin: 0,
    padding: 0,
    flexShrink: 0,
  },
  container: {
    display: 'flex',
    alignItems: 'center',
  },
  button: {},
  menu: {},
};

export interface RootProps
  extends WithStyles<typeof styles>,
    HTMLProps<HTMLDivElement> {
  // button
  ButtonComponent?: React.ElementType<IconButtonProps>;
  ButtonProps?: IconButtonProps;
  // menu
  MenuComponent?: React.ElementType<MenuProps>;
  MenuProps?: MenuProps;
}

export const Root = forwardRef<HTMLDivElement, RootProps>(
  (props: RootProps, ref) => {
    const theme = useTheme();
    const isCollapsed = useMediaQuery(theme.breakpoints.down('sm'));
    const {
      children,
      classes,
      className,
      ButtonComponent = IconButton,
      ButtonProps = {},
      MenuComponent = Menu,
      MenuProps = {},
      ...rest
    } = props;

    const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);

    const isMobile = useMediaQuery(theme.breakpoints.down('xs'));

    return (
      <FloatingHeaderActionsContextProvider value={{ isCollapsed }}>
        <div
          className={classNames(classes.root, className)}
          ref={ref}
          {...rest}
        >
          {isCollapsed ? (
            <ButtonComponent
              onClick={e => setAnchorEl(e.currentTarget)}
              color="secondary"
              {...ButtonProps}
              className={classNames(classes.button, ButtonProps.className)}
            >
              <MoreHoriz fontSize="small" />
            </ButtonComponent>
          ) : (
            <div className={classes.container}>{children}</div>
          )}
        </div>
        {isMobile ? (
          <Drawer
            anchor="bottom"
            open={Boolean(anchorEl)}
            onClose={() => setAnchorEl(null)}
            // rely on that events are bubbled up
            onClick={() => setAnchorEl(null)}
          >
            <List>{children}</List>
          </Drawer>
        ) : (
          <MenuComponent
            anchorEl={anchorEl}
            open={Boolean(anchorEl)}
            onClose={() => setAnchorEl(null)}
            anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
            {...MenuProps}
            className={classNames(classes.menu)}
          >
            {children}
          </MenuComponent>
        )}
      </FloatingHeaderActionsContextProvider>
    );
  },
);

export default withStyles(styles)(Root);
