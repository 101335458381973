import React from 'react';
import { useAsyncFn } from 'react-use';

import { Box, DialogActions, Divider, Typography } from '@material-ui/core';
import { Alert, AlertTitle } from '@material-ui/lab';

import {
  DialogBody,
  DialogHeader,
  FormSubmitButtons,
  useDialog,
} from '@spotify-confidence/core-react';
import {
  RuleFragment,
  VariantFragment,
  getError,
  getTypeOrNull,
  isType,
} from '@spotify-confidence/plugin-graphql';
import { v4 as uuidv4 } from 'uuid';

import { CriterionOptionsProvider } from '../../../../../segment/components/Targeting/CriterionOptionsProvider';
import { targetingHooks } from '../../../../../segment/domain/targeting';
import { assignmentWeights } from '../../../../domain/assignments.model';
import { AssignmentsContainer, RuleContainer } from '../../RuleContainer';

export type Props = {
  title?: string;
  variants: VariantFragment[];
  rule: RuleFragment;
  onSave?: (rule: RuleFragment) => Promise<void> | void | null;
  clients: string[];
};

function getVariantsAsAssignments(
  assignments: RuleFragment['assignmentSpec']['assignments'],
  variants: VariantFragment[],
): RuleFragment['assignmentSpec']['assignments'] {
  return variants.map(variant => {
    const matchingAssignment = assignments?.find(
      va =>
        getTypeOrNull(va.variant?.variant, 'FlagsAdminV1FlagVariant')?.name ===
        variant.name,
    );
    if (matchingAssignment) {
      return { ...matchingAssignment };
    }
    return {
      variant: {
        variant: { name: variant.name, __typename: 'FlagsAdminV1FlagVariant' },
      },
      bucketRanges: [],
    };
  });
}

export const DefaultRuleForm = ({
  title = 'Edit Rule',
  variants,
  rule,
  onSave,
  clients,
}: Props) => {
  const { closeDialog } = useDialog();
  const [assignments, setAssignments] = React.useState<
    RuleFragment['assignmentSpec']
  >({
    assignments: getVariantsAsAssignments(
      rule.assignmentSpec?.assignments || [],
      variants,
    ),
    bucketCount: rule.assignmentSpec?.bucketCount,
  });

  const segmentError = getError(rule.segment);
  const [segment, setSegment] = React.useState(
    getTypeOrNull(rule.segment, 'FlagsAdminV1Segment'),
  );
  const [targetingKeySelector, setTargetingKeySelector] = React.useState(
    rule.targetingKeySelector,
  );

  const [stickyAssignments, setStickyAssignments] = React.useState(
    !!rule?.materializationSpec?.writeMaterialization || false,
  );

  const evaluationContextOptions =
    targetingHooks.useDeriveEvaluationContextSchema(
      clients,
      segment?.targeting,
      targetingKeySelector,
    );

  const [{ loading: saving, error }, save] = useAsyncFn(async () => {
    const materializationName =
      rule.labels.find(l => l.key === 'writeMaterialization')?.value ||
      `materializedSegments/${uuidv4()}`;
    if (!segment) return;
    await onSave?.({
      ...rule,
      enabled: rule.enabled,
      assignmentSpec: {
        assignments: assignments.assignments?.filter(
          v => assignmentWeights(v.bucketRanges) > 0 || [],
        ),
        bucketCount: assignments.bucketCount,
      },
      segment,
      targetingKeySelector,
      labels: [
        ...(!stickyAssignments &&
        isType(
          rule?.materializationSpec?.writeMaterialization,
          'FlagsAdminV1MaterializedSegment',
        )
          ? [
              {
                key: 'writeMaterialization',
                value: rule?.materializationSpec?.writeMaterialization?.name,
              },
            ]
          : []),
        ...rule.labels,
      ],
      materializationSpec: stickyAssignments
        ? {
            writeMaterialization: {
              __typename: 'FlagsAdminV1MaterializedSegment',
              name:
                getTypeOrNull(
                  rule.materializationSpec?.writeMaterialization,
                  'FlagsAdminV1MaterializedSegment',
                )?.name || materializationName,
            },
            readMaterialization: {
              __typename: 'FlagsAdminV1MaterializedSegment',
              name:
                getTypeOrNull(
                  rule.materializationSpec?.readMaterialization,
                  'FlagsAdminV1MaterializedSegment',
                )?.name || materializationName,
            },
            mode: rule.materializationSpec?.mode,
          }
        : null,
    });
    closeDialog();
  }, [
    assignments,
    segment,
    targetingKeySelector,
    rule,
    onSave,
    stickyAssignments,
  ]);

  const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    save();
  };

  return (
    <>
      <DialogHeader title={title} />
      <form onSubmit={handleSubmit}>
        <DialogBody dividers autoHeight>
          {error && (
            <Box display="flex">
              <Alert severity="error">
                <AlertTitle>Error while saving</AlertTitle>
                <Typography>{error.message}</Typography>
              </Alert>
            </Box>
          )}
          {segmentError && (
            <Alert severity="error">{segmentError.message}</Alert>
          )}
          <CriterionOptionsProvider value={evaluationContextOptions}>
            {segment && (
              <RuleContainer
                targetingKeySelector={targetingKeySelector}
                segment={segment}
                handleSegmentChange={setSegment}
                handleTargetingKeySelectorChange={setTargetingKeySelector}
                stickyAssignments={stickyAssignments}
                handleStickyAssignmentsChange={sticky =>
                  setStickyAssignments(sticky)
                }
                stickyAssignmentsDescription={
                  'Once a variant has been assigned it will persist even if the rule changes.\n' +
                  '                  Note that this feature comes with additional charges.'
                }
              />
            )}
          </CriterionOptionsProvider>
          <Box marginTop={2} marginBottom={2}>
            <Divider />
          </Box>
          {/* TODO: allow selecting relevant variants instead of always listing all */}
          {assignments && (
            <AssignmentsContainer {...assignments} onChange={setAssignments} />
          )}
        </DialogBody>
        <DialogActions>
          <FormSubmitButtons onCancel={closeDialog} loading={saving} />
        </DialogActions>
      </form>
    </>
  );
};
