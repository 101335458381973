import React from 'react';
import { InView } from 'react-intersection-observer';

import {
  Box,
  Card,
  CardContent,
  Grid,
  IconButton,
  LinearProgress,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography,
  createStyles,
  makeStyles,
} from '@material-ui/core';
import KeyboardArrowDownOutlinedIcon from '@material-ui/icons/KeyboardArrowDownOutlined';
import KeyboardArrowUpOutlinedIcon from '@material-ui/icons/KeyboardArrowUpOutlined';
import { Alert } from '@material-ui/lab';

import { CodeEditor, DateUtils } from '@spotify-confidence/core-react';
import {
  EventFragment,
  EventTypeFragment,
  getError,
  getTypeOrNull,
  useListEventsQuery,
} from '@spotify-confidence/plugin-graphql';
import _ from 'lodash';

import { CopyTextButton } from '@backstage/core-components';

export const getEventTypeName = (type?: EventTypeFragment | null): string => {
  const name = type ? _.last(type.name.split('/')) : '';
  return name || 'Unknown';
};

const EventRow = ({
  event,
  workflowInstance,
}: {
  event: EventFragment;
  workflowInstance: string;
}) => {
  const { pubsubMetadata, name, cloudEvent } = event;
  const eventType = getTypeOrNull(event.eventType, 'ConnectorsV1EventType');
  const eventTypeError = getError(event.eventType);
  const [expanded, setExpanded] = React.useState(false);
  const eventInfo = [
    {
      label: 'Name',
      value: name,
    },
    {
      label: 'Description',
      value: eventType?.description ?? eventTypeError?.message,
    },
    {
      label: 'Published at',
      value: pubsubMetadata?.publishTime
        ? DateUtils.toDateString(
            pubsubMetadata?.publishTime || '',
            DateUtils.DATE_TIME_FORMAT,
          )
        : undefined,
    },
    {
      label: 'Consumed at',
      value: pubsubMetadata?.consumeTime
        ? DateUtils.toDateString(
            pubsubMetadata?.consumeTime || '',
            DateUtils.DATE_TIME_FORMAT,
          )
        : undefined,
    },
    {
      label: 'Event source',
      value: cloudEvent?.source,
    },
    {
      label: 'PubSub message id',
      value: pubsubMetadata?.messageId,
    },
  ];

  const eventData = JSON.stringify(event?.cloudEvent?.protoData)
    .replace(/'/g, "\\'")
    .replaceAll('\\n', '\\\\n');
  const text = `curl -H "Content-Type: application/json" -d $'{"event": "${event?.cloudEvent?.type}", "data": ${eventData}}' http://localhost:4050/${workflowInstance}:publishEvent`;

  return (
    <>
      <TableRow>
        <TableCell width="150">
          {DateUtils.xAgo(new Date(pubsubMetadata?.publishTime || ''))}
        </TableCell>
        <TableCell>{getEventTypeName(eventType)}</TableCell>
        <TableCell>{cloudEvent?.source}</TableCell>
        <TableCell
          width="50"
          align="right"
          onClick={() => setExpanded(!expanded)}
        >
          <IconButton size="small" name="expand-button">
            {expanded ? (
              <KeyboardArrowUpOutlinedIcon />
            ) : (
              <KeyboardArrowDownOutlinedIcon />
            )}
          </IconButton>
        </TableCell>
      </TableRow>
      {expanded && (
        <TableRow>
          <TableCell colSpan={4}>
            <Card>
              <CardContent>
                <Grid container spacing={2}>
                  {eventInfo.map(({ label, value }) => (
                    <Grid item md={4} xs={12} key={label}>
                      <Typography color="secondary" variant="overline">
                        {label}
                      </Typography>
                      <Typography display="block" variant="body2" gutterBottom>
                        {value}
                      </Typography>
                    </Grid>
                  ))}
                </Grid>
              </CardContent>
            </Card>

            <Box
              display="flex"
              justifyContent="space-between"
              mt={2}
              alignItems="center"
            >
              <Typography variant="h5">Event data</Typography>
              <CopyTextButton text={text} />
            </Box>

            <CodeEditor
              value={JSON.stringify(event, null, 2)}
              readOnly
              minLines={10}
              maxLines={30}
              mode="json"
            />
          </TableCell>
        </TableRow>
      )}
    </>
  );
};

const useStyles = makeStyles(() =>
  createStyles({
    tableRoot: {
      tableLayout: 'fixed',
    },
  }),
);

export const WorkflowInstanceEvents = ({
  workflowInstanceName,
}: {
  workflowInstanceName: string;
}) => {
  const classes = useStyles();
  const { data, fetchMore, error, loading } = useListEventsQuery({
    variables: {
      resource: workflowInstanceName,
    },
  });
  const eventsError = getError(data?.events);
  const eventsResponse = getTypeOrNull(
    data?.events,
    'ConnectorsV1ListEventsResponse',
  );
  const events = eventsResponse?.events || [];

  return (
    <>
      {error && <Alert severity="error">{error.message}</Alert>}
      {eventsError && <Alert severity="error">{eventsError.message}</Alert>}
      {!loading && events.length === 0 && (
        <Typography variant="body1">
          No events for this workflow instance
        </Typography>
      )}
      {loading && <LinearProgress />}

      <Table classes={{ root: classes.tableRoot }}>
        <TableHead>
          <TableRow>
            <TableCell>Time</TableCell>
            <TableCell>Type</TableCell>
            <TableCell>Source</TableCell>
            <TableCell />
          </TableRow>
        </TableHead>
        <TableBody data-testid="events-list">
          {events.map(event => (
            <EventRow
              key={event.name}
              event={event}
              workflowInstance={workflowInstanceName}
            />
          ))}
        </TableBody>
      </Table>
      <InView
        onChange={async inView => {
          const nextPageToken = eventsResponse?.nextPageToken;
          if (inView && nextPageToken) {
            await fetchMore({
              variables: {
                pageToken: nextPageToken,
              },
            });
          }
        }}
      />
    </>
  );
};
