import React from 'react';

import { TextField, TextFieldProps } from '@material-ui/core';

/**
 * Higher order component that wraps a TextField component and disables
 * autofill.
 *
 * @param Component Must be TextField
 * @returns A TextField component that disables autofill
 */
export const withoutAutoFill = (Component: typeof TextField) => {
  const WrapperComponent = (props: TextFieldProps) => {
    const { inputProps, ...rest } = props;

    return (
      <Component
        autoComplete="off"
        inputProps={{
          ...inputProps,
          'data-lpignore': 'true',
          'data-form-type': 'other',
        }}
        {...rest}
      />
    );
  };

  return WrapperComponent;
};
