import React from 'react';

import { Box } from '@material-ui/core';

import {
  ActionBar,
  FilterBar,
  useMeasureDimensions,
} from '@spotify-confidence/core-react';
import { WorkflowInstanceTable } from '@spotify-confidence/plugin-workflows';
import {
  ViewMode,
  ViewModeToggle,
} from '@spotify-confidence/plugin-workflows-module-planning';

type SurfaceExperimentsPageProps = {
  name: string;
};

export const SurfaceExperimentsPage = ({
  name,
}: SurfaceExperimentsPageProps) => {
  const [ref, { height }] = useMeasureDimensions<HTMLDivElement>();
  const [mode, setMode] = React.useState<ViewMode>('Table');

  return (
    <>
      <ActionBar ref={ref}>
        <ViewModeToggle mode={mode} onModeChange={setMode} />
        <FilterBar />
        <Box />
      </ActionBar>
      <WorkflowInstanceTable
        mode={mode}
        topOffset={height}
        filter={`surfaces:"${name}"`}
      />
    </>
  );
};
