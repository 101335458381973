import { useAsyncFn } from 'react-use';

export type CreatedAccount = {
  externalId: string;
  loginId: string;
  displayName: string;
};

export type InviteResponse = {
  autoJoinDomain: string;
  email: string;
  invitationToken: string;
  createdAccount?: CreatedAccount;
  name: string;
  warehouseNative: boolean;
};

export function useInvite(inviteId?: string) {
  return useAsyncFn(async () => {
    if (!inviteId) {
      return {
        createdAccount: undefined,
        warehouseNative: false,
      };
    }

    const response = await fetch(
      `https://onboarding.confidence.dev/v1/accountInvites/${inviteId}`,
    );
    const json = await response.json();
    if (!response.ok) {
      throw new Error(json.message);
    }
    return json as InviteResponse;
  }, [inviteId]);
}
