import React from 'react';

import { Theme, Typography, makeStyles, useTheme } from '@material-ui/core';

import classNames from 'classnames';
import { ScaleTime } from 'd3-scale';
import {
  Duration,
  add,
  differenceInDays,
  differenceInMonths,
  format,
  isAfter,
  isBefore,
  isSameMonth,
  isSameYear,
  isToday,
  set,
  startOfMonth,
} from 'date-fns';
import startOfDay from 'date-fns/startOfDay';
import _ from 'lodash';

import { TimeRange } from '../TimeControls';
import { getTranslateX } from '../helpers';
import {
  HighLevelAxis,
  HighLevelTick,
  LowLevelAxis,
  TickWrapper,
} from './TimeAxisComponents';

type TimeAxisProps = {
  timeRange: TimeRange;
  width: number;
  timeScale: ScaleTime<number, number>;
  start: Date;
  end: Date;
};

const useStyles = makeStyles((theme: Theme) => ({
  tickLabel: {
    padding: theme.spacing(1),
    margin: 0,
    height: '2em',
    width: '2em',
    borderRadius: '50%',
    whiteSpace: 'nowrap',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  broadTick: {
    borderRadius: theme.spacing(2),
    height: '1.3em',
    padding: theme.spacing(1, 3),
    transform: 'translateX(-25%)',
  },
  weekdayTick: {
    width: '4.5em',
  },
  currentTick: {
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.primary.contrastText,
  },
}));

const maximumTicksPerTimeRange: Record<
  TimeRange,
  { granularity: keyof Duration; amount: number }
> = {
  Year: { granularity: 'months', amount: 24 },
  Quarter: { granularity: 'months', amount: 12 },
  Month: { granularity: 'days', amount: 30 },
  Week: { granularity: 'days', amount: 14 },
};

const highLevelTickFormatPerTimeRange: Record<TimeRange, string> = {
  Year: 'Y',
  Quarter: 'Y',
  Month: 'MMMM',
  Week: 'MMMM',
};

const lowLevelTickFormatPerTimeRange: Record<TimeRange, string> = {
  Year: 'MMM',
  Quarter: 'MMM',
  Month: 'd',
  Week: 'E d',
};

function getMaxTickSize(timeRange: TimeRange, theme: Theme) {
  const maxTickSizePerTimeRange: Record<TimeRange, number> = {
    Week: theme.typography.fontSize * 4 + theme.spacing(4),
    Year: theme.typography.fontSize * 2 + theme.spacing(3),
    Quarter: theme.typography.fontSize * 2 + theme.spacing(3),
    Month: theme.typography.fontSize * 2 + theme.spacing(1),
  };
  return maxTickSizePerTimeRange[timeRange];
}

export const TimeAxis = ({
  timeRange,
  width = 0,
  timeScale,
  start,
  end,
}: TimeAxisProps) => {
  const classes = useStyles();
  const theme = useTheme();
  const today = startOfDay(new Date());
  const yearView = ['Year', 'Quarter'].includes(timeRange);
  const maxTickSize = getMaxTickSize(timeRange, theme);

  const ticks = React.useMemo(() => {
    const maxTickAmount = maximumTicksPerTimeRange[timeRange].amount;
    const granularity = maximumTicksPerTimeRange[timeRange].granularity;

    // How many ticks can fit on screen
    const maxTicks = Math.min(maxTickAmount, Math.ceil(width / maxTickSize));

    // How much time should there be between each tick in order to reach the maximum amount between start and end
    const diff = yearView
      ? differenceInMonths(end, start)
      : differenceInDays(end, start);
    const tickDiff = Math.max(1, width ? Math.ceil(diff / maxTicks) : 1);

    // Pad start and end for a better scrolling experience
    const paddedStart = add(start, { [granularity]: -maxTicks });
    const paddedEnd = add(end, { [granularity]: maxTicks });

    let _ticks: Date[] = [
      yearView ? startOfMonth(paddedStart) : startOfDay(paddedStart),
    ];

    let previousDate = _.last(_ticks)!;
    while (previousDate.getTime() < paddedEnd.getTime()) {
      const newDate = yearView
        ? startOfMonth(add(previousDate, { [granularity]: tickDiff }))
        : startOfDay(add(previousDate, { [granularity]: tickDiff }));
      _ticks.push(newDate);
      previousDate = newDate;
    }

    // If Today is within the range of start/end, it should always be visible as a tick
    // TODO: We can probably determine this beforehand instead of looping over everything again
    if (isBefore(today, paddedEnd) && isAfter(today, paddedStart)) {
      while (
        !_ticks.some(tick =>
          yearView ? isSameMonth(tick, today) : isToday(tick),
        )
      ) {
        _ticks = _ticks.map(tick => add(tick, { [granularity]: -1 }));
      }
    }
    return _ticks;
  }, [start, end, timeRange, yearView, width]);

  const highLevelTicks = React.useMemo(() => {
    const _highLevelTicks: Date[] = [];
    ticks.forEach(tick => {
      if (
        !_highLevelTicks.some(m =>
          yearView ? isSameYear(m, tick) : isSameMonth(m, tick),
        )
      ) {
        _highLevelTicks.push(set(tick, { date: 1 }));
      }
    });
    return _highLevelTicks;
  }, [ticks, yearView]);

  return (
    <>
      <HighLevelAxis style={{ width }}>
        {highLevelTicks.map(highLevelTick => (
          <HighLevelTick
            key={highLevelTick.toString()}
            style={{
              transform: getTranslateX(
                Math.max(timeScale(highLevelTick), theme.spacing(2)),
              ),
            }}
          >
            {format(highLevelTick, highLevelTickFormatPerTimeRange[timeRange])}
          </HighLevelTick>
        ))}
      </HighLevelAxis>
      <LowLevelAxis style={{ width }}>
        {ticks.map(tick => (
          <TickWrapper
            key={tick.toString()}
            style={{ transform: getTranslateX(timeScale(tick)) }}
          >
            <Typography
              key={tick.toString()}
              className={classNames(classes.tickLabel, {
                [classes.broadTick]: timeRange !== 'Month',
                [classes.weekdayTick]: timeRange === 'Week',
                [classes.currentTick]: yearView
                  ? isSameMonth(tick, today)
                  : isToday(tick),
              })}
              variant="body2"
            >
              {format(tick, lowLevelTickFormatPerTimeRange[timeRange])}
            </Typography>
          </TickWrapper>
        ))}
      </LowLevelAxis>
    </>
  );
};
