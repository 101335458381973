import {
  WorkflowInstanceCheckFragment,
  WorkflowV1WorkflowInstanceCheckState,
} from '@spotify-confidence/plugin-graphql';

export function checkSucceeded(
  checkState?: WorkflowInstanceCheckFragment['state'],
) {
  return checkState === WorkflowV1WorkflowInstanceCheckState.Succeeded;
}

export function checkPending(
  checkState?: WorkflowInstanceCheckFragment['state'],
) {
  return checkState === WorkflowV1WorkflowInstanceCheckState.Pending;
}

export function checkFailed(
  checkState?: WorkflowInstanceCheckFragment['state'],
) {
  return checkState === WorkflowV1WorkflowInstanceCheckState.Failed;
}

export function getCheckState(checks: WorkflowInstanceCheckFragment[]) {
  if (checks.some(c => checkFailed(c.state))) {
    return WorkflowV1WorkflowInstanceCheckState.Failed;
  } else if (checks.every(c => checkSucceeded(c.state))) {
    return WorkflowV1WorkflowInstanceCheckState.Succeeded;
  }
  return WorkflowV1WorkflowInstanceCheckState.Pending;
}
