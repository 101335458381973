import { Method } from '@spotify-confidence/plugin-metrics';

export type StatsData = {
  alpha: number; // if not set, defaults to 0.05
  power: number; // if not set, defaults to 0.8
  testHorizonStrategy?: TestHorizonStrategy;
  fallbackSampleSize: number;
  metricMethods?: {
    metricName: string;
    method: Method;
  }[];
};

export enum TestHorizonStrategy {
  FIXED_HORIZON = 'FIXED_HORIZON',
  SEQUENTIAL = 'SEQUENTIAL',
}
