import React from 'react';
import { useDebounce } from 'react-use';

import { Chip } from '@material-ui/core';
import CancelIcon from '@material-ui/icons/Cancel';
import { Autocomplete } from '@material-ui/lab';

import {
  getTypeOrNull,
  useGetSegmentsListQuery,
} from '@spotify-confidence/plugin-graphql';

import {
  CriterionSegment,
  SEGMENT_OPERATORS,
} from '../../domain/targeting/targeting.model';
import { ChipInput } from './ChipInput';
import { TargetingDropdown } from './TargetingDropdown';
import { useStyles } from './criterionStyle';

type SegmentValue = {
  name: string;
  displayName?: string | null;
};
export const SegmentCriterionChip = ({
  criterion,
  readOnly,
  onChange,
  onDelete,
}: {
  readOnly?: boolean;
  criterion: CriterionSegment;
  onDelete?: () => void;
  onChange: (newValue: CriterionSegment) => void;
}) => {
  const classes = useStyles();
  const [query, setQuery] = React.useState('');
  const changeSegmentOp = React.useCallback(
    (op: CriterionSegment['op']) => {
      onChange({
        ...criterion,
        op: op,
      });
    },
    [criterion, onChange],
  );
  const filter = 'labels.userCreated:true AND (state:ALLOCATED OR state:OK)';

  const { data, loading, fetchMore } = useGetSegmentsListQuery({
    variables: {
      pageSize: 25,
      filter: filter,
    },
    skip: readOnly,
  });
  useDebounce(
    () => {
      if (!readOnly) {
        fetchMore({
          variables: {
            pageSize: 25,
            filter: `${filter} AND displayName:*${query}*`,
          },
        });
      }
    },
    1000,
    [query],
  );
  const options =
    getTypeOrNull(data?.segments, 'FlagsAdminV1ListSegmentsResponse')
      ?.segments ?? [];
  const value: SegmentValue | undefined = criterion?.segment?.name
    ? {
        name: criterion.segment.name!,
        displayName: criterion.segment.displayName,
      }
    : undefined;
  return (
    <Chip
      classes={{ root: classes.root, label: classes.label }}
      label={
        <>
          <TargetingDropdown
            name="operand-picker"
            label={criterion.op}
            readOnly={readOnly}
            options={SEGMENT_OPERATORS.map(o => ({
              label: o,
              selected: o === criterion.op,
              onClick: () => changeSegmentOp(o),
            }))}
          />
          <Autocomplete
            options={options}
            loading={loading}
            value={value}
            disableClearable
            onInputChange={(_, v) => {
              setQuery(v);
            }}
            disabled={readOnly}
            getOptionSelected={v => v.name === criterion.segment.name}
            onChange={(_e, v) => {
              if (v) {
                onChange({
                  ...criterion,
                  segment: {
                    name: v.name,
                    displayName: v.displayName ?? undefined,
                  },
                });
              }
            }}
            getOptionLabel={v => v.displayName ?? v.name}
            renderInput={params => (
              <ChipInput
                {...params}
                inputProps={{
                  ...params.inputProps,
                  readOnly,
                  style: { width: 200 },
                }}
              />
            )}
          />
        </>
      }
      onDelete={readOnly ? undefined : onDelete}
      deleteIcon={<CancelIcon name="delete-icon" />}
    />
  );
};
