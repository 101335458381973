import React, { useEffect } from 'react';
import { useSearchParams } from 'react-router-dom';

import { Button, ButtonProps, Typography } from '@material-ui/core';

import { useAuth, useNavigate } from '@spotify-confidence/core-react';

import { emailLink } from '../../utils/email';
import { TransitionScreen } from '../shared';

const supportButtonProps = (message: string): ButtonProps => {
  return {
    href: emailLink(
      'Login issue',
      `Page: ${encodeURIComponent(
        global.window.location.href,
      )}%0D%0AError:${message}`,
    ),
    children: 'Contact support',
  };
};

export const InvitationPage = () => {
  const navigate = useNavigate();

  const {
    isAuthenticated,
    isLoading: loading,
    loginWithPopup,
    error,
  } = useAuth();

  const handleLogin = async () => {
    try {
      await loginWithPopup(undefined, {
        timeoutInSeconds: 600,
      });
    } catch (e) {
      console.log(e);
    }
  };

  useEffect(() => {
    if (isAuthenticated) {
      navigate('/?show-invite=true');
    }
  }, [isAuthenticated]);

  const { title, message, primaryAction, secondaryAction } = useMessageProps({
    error,
  });

  return (
    <TransitionScreen
      title={title}
      description={message}
      actions={
        <>
          <Button
            variant="contained"
            onClick={handleLogin}
            color="primary"
            size="large"
            disabled={loading}
            children="Sign up"
            {...primaryAction}
          />
          {secondaryAction && (
            <Button
              variant="outlined"
              color="primary"
              size="large"
              {...secondaryAction}
            />
          )}
        </>
      }
    />
  );
};

type MessageProps = {
  title: React.ReactNode;
  message: React.ReactNode;
  primaryAction?: Partial<ButtonProps>;
  secondaryAction?: Partial<ButtonProps>;
};

function useMessageProps({ error }: { error: Error | undefined }) {
  const [searchParams] = useSearchParams();
  const hasInvitation = !!searchParams.get('invitation');
  const organization = searchParams.get('organization_name');

  const props = React.useMemo((): MessageProps => {
    switch (true) {
      case error && hasInvitation && error.message.includes('not found'):
        return {
          title: "Sorry, we couldn't find that invite",
          message: (
            <span>
              If you have previously created an account, you can log into
              Confidence instead. If you are not able to log in, the invite
              might have expired. Ask for a new one and try again.
            </span>
          ),
          primaryAction: {
            onClick: () => {},
            href: '/',
            children: 'Go to login page',
          },
          secondaryAction: supportButtonProps(error!.message),
        };
      case error &&
        hasInvitation &&
        error.message.includes(
          'the specified account is not allowed to accept the current invitation',
        ):
        return {
          title: 'Invitation mismatch',
          message: (
            <span>
              It seems like the email used to login does not match the email on
              the invite. Ask your admin to create another invite.
            </span>
          ),
        };
      case error && error.message.includes('Timeout'):
        return {
          title: 'Login session timed out',
          message: <span>Please try again.</span>,
        };

      case error && error.message.includes('Invalid domain'):
        return {
          title: 'Email domain not allowed',
          message: (
            <span>
              The account does not allow emails from this domain to login.
              Please contact your account admin.
            </span>
          ),
        };
      case hasInvitation:
        return {
          title: 'Welcome to Confidence',
          message: (
            <span>
              You have been invited to{' '}
              {organization ? (
                <span>
                  the{' '}
                  <Typography variant="inherit" color="primary">
                    {organization}
                  </Typography>{' '}
                  workspace
                </span>
              ) : (
                'Confidence'
              )}
              . Sign up to get started!
            </span>
          ),
        };

      default:
        return {
          title: (
            <span>
              Have an idea.
              <br /> Test it. Ship it. <br /> With Confidence.
            </span>
          ),
          message: null,
        };
    }
  }, [error]);
  return props;
}
