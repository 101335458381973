import React from 'react';

import { Box, Typography, TypographyProps } from '@material-ui/core';

import { useDisplayNames } from '@spotify-confidence/core-react';

export const InlineTreatmentComparison = ({
  baselineGroupId = '',
  comparedGroupId = '',
  ...typographyProps
}: TypographyProps & {
  baselineGroupId: string;
  comparedGroupId: string;
}) => {
  const { displayNames } = useDisplayNames();
  const baselineGroupName =
    displayNames.get(baselineGroupId) || baselineGroupId || 'Baseline';
  const comparedGroupName =
    displayNames.get(comparedGroupId) || comparedGroupId || 'Comparison';
  return (
    <Box display="inline-flex" alignItems="center" gridGap={8}>
      <Typography {...typographyProps}>{baselineGroupName}</Typography>
      <Typography {...typographyProps} color="textSecondary">
        vs
      </Typography>
      <Typography {...typographyProps}>{comparedGroupName}</Typography>
    </Box>
  );
};
