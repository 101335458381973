import {
  GetWorkflowInstanceCreateInfoQuery,
  getTypeOrNull,
  useGetWorkflowInstanceCreateInfoQuery,
  useListEntityContextOverridesQuery,
} from '@spotify-confidence/plugin-graphql';

const hasModule = (
  workflow?: GetWorkflowInstanceCreateInfoQuery['workflow'],
  moduleKey?: string,
) => {
  return getTypeOrNull(
    workflow,
    'WorkflowV1Workflow',
  )?.workflowInfo.modules.some(m => m.key === moduleKey);
};

export const useWorkflowCreationData = ({
  workflowId,
  entity,
  assignmentTable,
}: {
  workflowId: string;
  entity?: string;
  assignmentTable?: string;
}) => {
  const { data: createInfo } = useGetWorkflowInstanceCreateInfoQuery({
    variables: { name: `workflows/${workflowId}` },
  });
  const { data: overrides } = useListEntityContextOverridesQuery({
    variables: { filter: `semanticType.entityReference.entity:"${entity}"` },
    skip: !entity,
  });

  const hasMetricsModule = hasModule(createInfo?.workflow, 'metrics');
  const hasFlagsModule = hasModule(createInfo?.workflow, 'flags');

  const accountHasEntities =
    (getTypeOrNull(createInfo?.entities, 'MetricsV1ListEntitiesResponse')
      ?.entities.length || 0) > 0;

  const assignmentTables =
    getTypeOrNull(
      createInfo?.assignmentTables,
      'MetricsV1ListAssignmentTablesResponse',
    )?.assignmentTables ?? [];
  const showAssignmentTables = assignmentTables.length !== 1;

  const showEntitySelect =
    accountHasEntities && (hasFlagsModule || hasMetricsModule);

  const entityOverride = getTypeOrNull(
    overrides?.evaluationContextFieldOverrides,
    'FlagsAdminV1ListEvaluationContextFieldOverridesResponse',
  )?.evaluationContextFieldOverrides[0];

  const moduleData = [];
  if (entity && hasFlagsModule && entityOverride) {
    moduleData.push({
      key: 'flags',
      value: {
        targetingKeySelector: entityOverride.field,
      },
    });
  }
  if (hasMetricsModule && (entity || showAssignmentTables)) {
    const entityEntry = entity ? { entity } : {};
    let assignmentTableEntry;
    if (assignmentTable) {
      assignmentTableEntry = { assignmentTable };
    } else {
      assignmentTableEntry =
        assignmentTables.length === 1
          ? { assignmentTable: assignmentTables[0].name }
          : {};
    }

    moduleData.push({
      key: 'metrics',
      value: {
        ...entityEntry,
        ...assignmentTableEntry,
      },
    });
  }

  return {
    showEntitySelect,
    showAssignmentTables,
    moduleData,
  };
};
