import { makeStyles } from '@material-ui/core';

export const useStyles = makeStyles(theme => ({
  root: {
    maxWidth: '100%',
    minHeight: 40,
    height: 'auto',
    padding: theme.spacing(0.5, 2),
    gap: theme.spacing(1),
    margin: 0,
  },
  label: {
    display: 'flex',
    gap: theme.spacing(0.5),
    alignItems: 'center',
    flexWrap: 'wrap',
  },
}));
