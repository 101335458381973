import React from 'react';

import loadable from '@loadable/component';
import { createWorkflowExtension } from '@spotify-confidence/plugin-workflows';

import { createPlugin } from '@backstage/core-plugin-api';

import { AnalysisTableRow } from './components/AnalysisWorkflowPage/AnalysisTableRow';
import { ANALYSIS_WORKFLOW_ID } from './constants';

export const confidenceWorkflowsModuleAnalysisPlugin = createPlugin({
  id: 'confidence-workflows-module-analysis',
});

const componentsChunk = () =>
  import(
    /* webpackChunkName: "workflows-analysis-pages", webpackPrefetch: 5 */ './components'
  );
const loadOptions = {};

const AnalysisWorkflowPage = loadable(
  () => componentsChunk().then(m => m.AnalysisWorkflowPage),
  loadOptions,
);
const AnalysisWorkflowInstancePage = loadable(
  () => componentsChunk().then(m => m.AnalysisWorkflowInstancePage),
  loadOptions,
);

export const AnalysisWorkflowExtension =
  confidenceWorkflowsModuleAnalysisPlugin.provide(
    createWorkflowExtension({
      title: 'Analysis',
      workflowId: ANALYSIS_WORKFLOW_ID,
      workflowPage: () => <AnalysisWorkflowPage />,
      workflowInstancePage: () => <AnalysisWorkflowInstancePage />,
      workflowTableRowComponent: AnalysisTableRow,
    }),
  );
