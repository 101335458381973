import React from 'react';

import { Typography } from '@material-ui/core';

import { CodeSnippet, SnippetProps } from '../../CodeSnippet';
import { f } from '../../CodeSnippet';
import { TUTORIAL_FLAG_NAME } from '../constants';

export const RustClientSnippets = (props: SnippetProps) => {
  return (
    <ol>
      <li>
        <Typography variant="body1" gutterBottom>
          Add the Confidence SDK to your project.
        </Typography>
        <CodeSnippet
          language="rust"
          text={f(`
cargo add spotify_confidence_sdk
`)}
          showCopyCodeButton
        />
      </li>
      <li>
        <Typography variant="body1" gutterBottom>
          Initialize the Confidence instance.
        </Typography>
        <CodeSnippet
          language="rust"
          text={f(`
// imports
use std::collections::HashMap;
use spotify_confidence_sdk::{APIConfig, Confidence, ConfidenceValue, Region};
use spotify_confidence_sdk::contextual_confidence::Contextual;

let api_config = APIConfig {
        api_key: "${props.apiKey}".to_string(),
        region: Region::Global,
      };

let root_confidence = Confidence::new(api_config);
      
`)}
          showCopyCodeButton
        />
      </li>
      <li>
        <Typography variant="body1" gutterBottom>
          Next, we want to set the `visitor_id` context field to
          "tutorial_visitor", to match the targeting criteria pre-configured for
          this tutorial.
        </Typography>
        <CodeSnippet
          language="rust"
          text={f(`
let mut context = HashMap::new();
context.insert("visitor_id".to_string(), ConfidenceValue::String("tutorial_visitor".to_string()));

let confidence = root_confidence.with_context(context);
          `)}
          showCopyCodeButton
        />
      </li>
      <li>
        <Typography variant="body1" gutterBottom>
          Resolve the "title" value for the "{TUTORIAL_FLAG_NAME}" flag.
        </Typography>
        <CodeSnippet
          language="rust"
          text={f(`
let details_title = confidence.get_flag("${TUTORIAL_FLAG_NAME}.title", "Default".to_string()).await;
println!("details string -> {:?}", details_title.unwrap());
      `)}
          showCopyCodeButton
        />
      </li>
    </ol>
  );
};
