import React from 'react';

import {
  ListItemSecondaryAction,
  ListItemText,
  styled,
} from '@material-ui/core';
import { AutocompleteRenderOptionState } from '@material-ui/lab';

import { MetricsV1TableState } from '@spotify-confidence/plugin-graphql';

import { TableState } from '../../../components/TableState';

type RenderTableOptionProps = {
  option: {
    name: string;
    displayName: string;
    state: MetricsV1TableState;
    error?: {
      message?: string | null;
      details: string[];
    } | null;
  };
  state: AutocompleteRenderOptionState;
};

const OptionItem = styled('div')(({ theme }) => ({
  position: 'relative',
  width: '100%',
  display: 'grid',
  gridGap: theme.spacing(1),
  gridTemplateColumns: '1fr 80px',
}));

const WordBreakListItemText = styled(ListItemText)({
  wordBreak: 'break-all',
});

export const RenderTableOption = ({
  option,
  state,
}: RenderTableOptionProps) => {
  const { inputValue, ...stateProps } = state;
  return (
    <OptionItem {...stateProps}>
      <WordBreakListItemText primary={option.displayName} />
      <ListItemSecondaryAction>
        {option.state &&
          option.state !== MetricsV1TableState.TableStateActive && (
            <TableState status={option.state} error={option.error} />
          )}
      </ListItemSecondaryAction>
    </OptionItem>
  );
};
