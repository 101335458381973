import React from 'react';

import { Extension, attachComponentData } from '@backstage/core-plugin-api';

import { WORKFLOW_EXTENSION_KEY, WORKFLOW_EXTENSION_WRAPPER_KEY } from './keys';

export type WorkflowExtensionOptions = {
  name?: string;
  title: string;
  workflowId: string;
  workflowPage: () => JSX.Element | null;
  workflowInstancePage: () => JSX.Element | null;
  workflowTableRowComponent?: React.ComponentType<any>;
};

export type WorkflowExtensionComponent<_TReturnValue, _TInputProps> =
  () => null;

export function createWorkflowExtension<
  TReturnValue = unknown,
  TInputProps = unknown,
>(
  options: WorkflowExtensionOptions,
): Extension<WorkflowExtensionComponent<TReturnValue, TInputProps>> {
  return {
    expose() {
      const WorkflowExtensionDataHolder = () => null;

      attachComponentData(
        WorkflowExtensionDataHolder,
        WORKFLOW_EXTENSION_KEY,
        options,
      );

      return WorkflowExtensionDataHolder;
    },
  };
}

export const WorkflowExtensions: React.ComponentType<
  React.PropsWithChildren<{}>
> = (): JSX.Element | null => null;

attachComponentData(WorkflowExtensions, WORKFLOW_EXTENSION_WRAPPER_KEY, true);
