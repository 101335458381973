import React from 'react';

import {
  TableCell,
  TableCellProps,
  Typography,
  styled,
} from '@material-ui/core';

import { Numeral } from './index';

export const MediumScreenCell = styled(TableCell)(({ theme }) => ({
  [theme.breakpoints.down('md')]: {
    display: 'none',
  },
}));

export const NumberCell = ({
  value,
  mediumScreenSize,
  suffix,
  ...tableCellProps
}: TableCellProps & {
  value?: string | number | null;
  suffix?: string;
  mediumScreenSize?: boolean;
}) => {
  const Component = mediumScreenSize ? MediumScreenCell : TableCell;
  return (
    <Component {...tableCellProps}>
      <Typography
        variant="body2"
        color={value ? 'textPrimary' : 'textSecondary'}
      >
        <Numeral value={value ?? undefined} invalidValue="-" suffix={suffix} />
      </Typography>
    </Component>
  );
};
