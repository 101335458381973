import React from 'react';

import { debounce } from 'lodash';

export type ChangeHandler<T> = (value: T) => void;
export type DebouncedChangeHandler<T> = (value: T) => void;
export type ImmediateChangeHandler<T> = (value: T) => void;

const useDebounced = <T>(
  value: T,
  onChange: ChangeHandler<T>,
  delay: number = 1000,
) => {
  // keep a ”live” display value to show
  const [displayValue, setDisplayValue] = React.useState<T>(value);
  const [debouncedValue, setDebouncedValue] = React.useState<T>(value);

  const valueRef = React.useRef(value);
  const onChangeRef = React.useRef(onChange);

  /**
   * Lifecycle Handling
   */

  const enqueueDebouncedValue = React.useCallback(
    debounce(setDebouncedValue, delay),
    [],
  );

  // keep latest onChange ref so we can decouple any onChange call from debounced value changes
  React.useEffect(() => {
    onChangeRef.current = onChange;
  }, [onChange]);

  // if we get a new value from props, update display value and cancel any pending value
  React.useEffect(() => {
    valueRef.current = value;
    setDisplayValue(value);
    enqueueDebouncedValue.cancel();
  }, [enqueueDebouncedValue, value]);

  // invoke latest onChange callback if debounced value’s changed (ties async callback to react render cycle)
  React.useEffect(() => {
    if (debouncedValue !== valueRef.current) {
      onChangeRef.current(debouncedValue);
    }
  }, [debouncedValue]);

  // cleanup queue on unmount
  React.useEffect(() => enqueueDebouncedValue.cancel, [enqueueDebouncedValue]);

  /**
   * Debounced methods
   */

  // callback for queueing new debounced onChange call
  const handleChangeDebounced: DebouncedChangeHandler<T> = React.useCallback(
    nextValue => {
      setDisplayValue(nextValue);
      enqueueDebouncedValue(nextValue);
    },
    [enqueueDebouncedValue],
  );

  // callback for clearing queue and immediately executing new onChange call
  const handleChangeImmediately: ImmediateChangeHandler<T> = React.useCallback(
    nextValue => {
      if (nextValue !== valueRef.current) {
        setDisplayValue(nextValue);
        onChangeRef.current(nextValue); // not async, no need to use debouncedValue
      }
      enqueueDebouncedValue.cancel();
    },
    [enqueueDebouncedValue],
  );

  return React.useMemo(
    () =>
      [displayValue, handleChangeDebounced, handleChangeImmediately] as const,
    [displayValue, handleChangeDebounced, handleChangeImmediately],
  );
};

export default useDebounced;
