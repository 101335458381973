import React from 'react';

import {
  Box,
  Collapse,
  IconButton,
  Link,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Tooltip,
  Typography,
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import KeyboardArrowDown from '@material-ui/icons/KeyboardArrowDown';
import KeyboardArrowUp from '@material-ui/icons/KeyboardArrowUp';
import LinkOutlined from '@material-ui/icons/LinkOutlined';
import { Alert } from '@material-ui/lab';

import { CodeEditor, DateUtils } from '@spotify-confidence/core-react';
import {
  ExposureCalculationFragment,
  ExposureScheduleFragment,
  MetricCalculationFragment,
  MetricsV1ExposureCalculationState,
  ScheduledExposureCalculationFragment,
  getTypeOrNull,
  isError,
} from '@spotify-confidence/plugin-graphql';
import { format } from 'date-fns';

import {
  StatusError,
  StatusOK,
  StatusPending,
  StatusRunning,
} from '@backstage/core-components';

const stateIcon = (
  fragment: ExposureCalculationFragment | null | undefined,
) => {
  const state = fragment?.state;
  switch (state) {
    case MetricsV1ExposureCalculationState.StateCompleted:
      return (
        <StatusOK>
          <Typography variant="body2" display="inline">
            {`Completed at ${DateUtils.toDateString(
              fragment!.updateTime,
              DateUtils.SHORT_DATE_TIME_FORMAT,
            )}`}
          </Typography>
        </StatusOK>
      );
    case MetricsV1ExposureCalculationState.StateFailed:
      return (
        <StatusError>
          <Typography variant="body2" display="inline">
            Failed
          </Typography>
        </StatusError>
      );
    case MetricsV1ExposureCalculationState.StateRunning:
      return (
        <StatusRunning>
          <Typography variant="body2" display="inline">
            Running
          </Typography>
        </StatusRunning>
      );
    default:
      return (
        <StatusPending>
          <Typography variant="body2" display="inline">
            Unknown
          </Typography>
        </StatusPending>
      );
  }
};

export function nextExposureCalculationString(
  scheduledExposureCalculation: Pick<
    ExposureScheduleFragment,
    'status' | 'exposureCalculationSpec'
  >,
  onlyEstimation: boolean = false,
): string {
  let waitingString = 'Waiting for data to be delivered.';
  let dataExpected = '';
  if (scheduledExposureCalculation.status.scheduled) {
    return `Next scheduled run is at ${DateUtils.toDateString(
      scheduledExposureCalculation.status.scheduled.nextScheduleTime,
      'yyyy-MM-dd HH:mm',
    )}`;
  } else if (scheduledExposureCalculation.status.waiting) {
    let what = 'data';
    if (scheduledExposureCalculation.status.waiting.assignmentTable) {
      what = 'assignments';
    }
    waitingString = `Waiting for ${what} to be delivered${
      scheduledExposureCalculation.status.waiting.needDataUntilTime
        ? ` until ${DateUtils.toDateString(
            scheduledExposureCalculation.status.waiting.needDataUntilTime,
            DateUtils.SHORT_DATE_TIME_FORMAT,
          )}`
        : ''
    }.`;
  }

  if (
    scheduledExposureCalculation.exposureCalculationSpec.assignmentTable &&
    !isError(
      scheduledExposureCalculation.exposureCalculationSpec.assignmentTable,
    )
  ) {
    const assignmentTable =
      scheduledExposureCalculation.exposureCalculationSpec.assignmentTable;
    if (
      assignmentTable &&
      !isError(assignmentTable) &&
      'dataDeliveredUntilUpdateStrategyConfig' in assignmentTable
    ) {
      const deliveryConfig =
        assignmentTable.dataDeliveredUntilUpdateStrategyConfig;
      if (
        deliveryConfig &&
        scheduledExposureCalculation.status.waiting?.needDataUntilTime
      ) {
        const nextScheduledRunTime = new Date(
          scheduledExposureCalculation.status.waiting?.needDataUntilTime,
        );
        let delayInSeconds;
        if (deliveryConfig.automaticUpdateConfig) {
          const commitDelay = deliveryConfig.automaticUpdateConfig.commitDelay;
          delayInSeconds = parseInt(commitDelay.replace('s', ''), 10);
        }
        if (delayInSeconds) {
          nextScheduledRunTime.setSeconds(
            nextScheduledRunTime.getSeconds() + delayInSeconds,
          );

          dataExpected = ` Exposure estimated to be available by ${DateUtils.toDateString(
            nextScheduledRunTime,
            DateUtils.SHORT_DATE_TIME_FORMAT,
          )}.`;
        }
      }
    }
  }
  if (onlyEstimation && dataExpected !== '') {
    return dataExpected;
  }
  return `${waitingString}${dataExpected}`;
}

type NextExposureCalculationRowProps = {
  scheduledExposureCalculation: ExposureScheduleFragment;
};

function NextExposureCalculationRow({
  scheduledExposureCalculation,
}: NextExposureCalculationRowProps) {
  if (
    scheduledExposureCalculation.status.calculating ||
    scheduledExposureCalculation.status.cancelled ||
    scheduledExposureCalculation.status.error ||
    (scheduledExposureCalculation.status.waiting &&
      !scheduledExposureCalculation.status.waiting?.needDataUntilTime)
  ) {
    // in this case there'll be a metric calculation
    return null;
  }

  const dataDeliveredUntilTime =
    scheduledExposureCalculation.status.waiting?.dataDeliveredUntilTime;
  const needDataUntilTime =
    scheduledExposureCalculation.status.waiting?.needDataUntilTime;

  return (
    <TableRow>
      <TableCell
        style={{
          fontFeatureSettings: "'tnum'",
          fontVariantNumeric: 'tabular-num',
          color: 'inherit',
        }}
      >
        {dataDeliveredUntilTime
          ? format(
              new Date(dataDeliveredUntilTime),
              DateUtils.SHORT_DATE_TIME_FORMAT,
            )
          : ''}
      </TableCell>
      <TableCell
        style={{
          fontFeatureSettings: "'tnum'",
          fontVariantNumeric: 'tabular-num',
          color: 'inherit',
        }}
      >
        {needDataUntilTime
          ? format(
              new Date(needDataUntilTime),
              DateUtils.SHORT_DATE_TIME_FORMAT,
            )
          : ''}
      </TableCell>
      <TableCell
        style={{
          color: 'inherit',
        }}
      >
        <StatusPending>
          <Typography variant="body2" display="inline">
            {nextExposureCalculationString(scheduledExposureCalculation)}
          </Typography>
        </StatusPending>
      </TableCell>
    </TableRow>
  );
}

type ExposureCalculationRowProps = {
  calculation: ExposureCalculationFragment;
  metricCalculation?: MetricCalculationFragment;
  scheduledExposureCalculation?: ScheduledExposureCalculationFragment;
  displayNames: Map<string, string>;
};
const useStyles = makeStyles(theme => ({
  disabledRow: {
    color: theme.palette.text.disabled,
  },
}));

function ExposureCalculationRow({
  calculation,
  metricCalculation,
  scheduledExposureCalculation,
  displayNames,
}: ExposureCalculationRowProps) {
  const [open, setOpen] = React.useState(false);
  const sqlJob = getTypeOrNull(calculation?.sqlJob, 'MetricsV1SqlJob');
  const link = sqlJob?.labels?.find(
    l => l.key === 'data_warehouse_link',
  )?.value;
  const classes = useStyles();
  const metricBucketStats =
    metricCalculation?.metricResults?.metricBucketStats || [];
  const retriggered =
    (scheduledExposureCalculation?.iteration || 0) >
    calculation.scheduleIteration;
  return (
    <React.Fragment>
      <TableRow
        key={calculation.name}
        className={retriggered ? classes.disabledRow : undefined}
      >
        <TableCell
          style={{
            fontFeatureSettings: "'tnum'",
            fontVariantNumeric: 'tabular-num',
            color: 'inherit',
          }}
        >
          {format(
            new Date(calculation.startTime),
            DateUtils.SHORT_DATE_TIME_FORMAT,
          )}
        </TableCell>
        <TableCell
          style={{
            fontFeatureSettings: "'tnum'",
            fontVariantNumeric: 'tabular-num',
            color: 'inherit',
          }}
        >
          {format(
            new Date(calculation.endTime),
            DateUtils.SHORT_DATE_TIME_FORMAT,
          )}
        </TableCell>
        <TableCell
          style={{
            color: 'inherit',
          }}
        >
          {stateIcon(calculation)}
          {retriggered ? ' (retriggered)' : ''}
        </TableCell>
        <TableCell>
          {link && (
            <Link href={link} target="_blank">
              <LinkOutlined />
            </Link>
          )}
        </TableCell>
        <TableCell>
          <Tooltip title="Toggle query">
            <IconButton
              aria-label="expand row"
              size="small"
              onClick={() => setOpen(!open)}
            >
              {open ? <KeyboardArrowUp /> : <KeyboardArrowDown />}
            </IconButton>
          </Tooltip>
        </TableCell>
      </TableRow>
      <TableRow>
        <TableCell padding="none" colSpan={5}>
          <Collapse in={open} timeout="auto" unmountOnExit>
            <Box margin={1}>
              {sqlJob?.failedQueryResult?.error && (
                <Alert severity="error">
                  {sqlJob?.failedQueryResult?.error}
                </Alert>
              )}
              {metricBucketStats.length > 0 && (
                <Table size="small" aria-label="results">
                  <TableHead>
                    <TableRow>
                      <TableCell>Group</TableCell>
                      <TableCell>Count</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {metricBucketStats.map(bucket => (
                      <TableRow key={bucket.variant}>
                        <TableCell component="th" scope="row">
                          {displayNames.get(bucket.variant)}
                        </TableCell>
                        <TableCell>
                          {bucket.summaryStats?.averageSummaryStats?.count}
                        </TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              )}
              {calculation.sql && (
                <CodeEditor value={calculation.sql} mode="sql" readOnly />
              )}
            </Box>
          </Collapse>
        </TableCell>
      </TableRow>
    </React.Fragment>
  );
}

export const ExposureCalculationsList = ({
  calculations = [],
  metricCalculations = [],
  displayNames = new Map<string, string>(),
  scheduledExposureCalculation,
}: {
  calculations?: ExposureCalculationFragment[];
  metricCalculations?: MetricCalculationFragment[];
  displayNames?: Map<string, string>;
  scheduledExposureCalculation?: ScheduledExposureCalculationFragment;
}) => {
  return (
    <Table size="small">
      <TableHead>
        <TableRow>
          <TableCell>Start time</TableCell>
          <TableCell>End time</TableCell>
          <TableCell />
          <TableCell />
          <TableCell />
          <TableCell />
        </TableRow>
      </TableHead>
      <TableBody>
        {scheduledExposureCalculation && (
          <NextExposureCalculationRow
            scheduledExposureCalculation={scheduledExposureCalculation}
          />
        )}
        {calculations.map(calculation => {
          const metricCalculation = metricCalculations.find(
            p =>
              p.startTime === calculation.startTime &&
              p.scheduleIteration === calculation.scheduleIteration,
          );
          return (
            <ExposureCalculationRow
              key={calculation.name}
              calculation={calculation}
              metricCalculation={metricCalculation}
              scheduledExposureCalculation={scheduledExposureCalculation}
              displayNames={displayNames}
            />
          );
        })}
      </TableBody>
    </Table>
  );
};
