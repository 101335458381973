import React from 'react';

import { PopupLoginOptions } from '@auth0/auth0-spa-js';

import { Region } from '../../../utils';
import { User } from '../auth0/auth.types';
import { getRegionFromTokenOrDefault } from '../auth0/jwt';

type TestAuthContextType = {
  isAuthenticated: boolean;
  isLoading: boolean;
  loginWithPopup: (options?: PopupLoginOptions) => void;
  loginWithRedirect: () => void;
  getAccessTokenSilently: () => void;
  logout: () => void;
  token?: string;
  user: User;
  error?: Error;
};

const defaultValue = {
  isAuthenticated: false,
  isLoading: false,
  loginWithPopup: () => {},
  loginWithRedirect: () => {},
  getAccessTokenSilently: () => {},
  logout: () => {},
  user: { name: 'e2e', picture: undefined, email: 'email@email.com' },
};

const TestAuthContext = React.createContext<TestAuthContextType>(defaultValue);

export const AuthenticationProvider: React.FC<{
  children: React.ReactElement;
  onTokenReceived: (token: string, region: Region) => void;
}> = ({ onTokenReceived, children }) => {
  const [token, setToken] = React.useState<TestAuthContextType['token']>();

  const checkSession = (polling?: boolean) => {
    const tokenItem = window.localStorage.getItem('confidence_test_ls_ak');

    if (tokenItem) {
      setToken(tokenItem);
    } else if (polling) {
      setTimeout(() => checkSession(polling), 1000);
    }
  };

  React.useEffect(() => {
    checkSession();
  }, []);

  const loginWithPopup = React.useCallback(() => {
    checkSession(true);
  }, [checkSession]);

  const value = React.useMemo<TestAuthContextType>(
    () => ({
      ...defaultValue,
      token,
      isAuthenticated: !!token,
      loginWithPopup,
    }),

    [defaultValue, token, loginWithPopup],
  );

  React.useEffect(() => {
    if (value.token) {
      onTokenReceived(value.token, getRegionFromTokenOrDefault(value.token));
    }
  }, [value]);

  return (
    <TestAuthContext.Provider value={value}>
      {children}
    </TestAuthContext.Provider>
  );
};

export const useAuth = () => React.useContext(TestAuthContext);
