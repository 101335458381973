import loadable from '@loadable/component';

const componentsChunk = () =>
  import(
    /* webpackChunkName: "metrics-module-components" */ './components.chunk'
  );

export const MetricsModuleSection = loadable(() =>
  componentsChunk().then(m => m.MetricsModuleSection),
);
export const MetricsModuleSectionV2 = loadable(() =>
  componentsChunk().then(m => m.MetricsModuleSectionV2),
);
export const MetricsModuleConfigProvider = loadable(() =>
  componentsChunk().then(m => m.MetricsModuleConfigProvider),
);
export const MetricsModuleSidebarSection = loadable(() =>
  componentsChunk().then(m => m.MetricsModuleSidebarSection),
);

export {
  isSuccessMetric,
  isGuardrailMetric,
  hasPlannedEffectSize,
} from './helpers';
export * from './types';
export * from './useSaveMetrics';
export * from '../ExposureModule/ScheduledExposureCalculationDialog';
export * from './ScheduledMetricCalculationDialog';
export * from './MetricsModuleConfigProvider';
export * from './MetricsCalculationSidebarSection';
export * from './CalculationDialog';
export * from './MetricDialog';
export * from './MetricCardV2';
