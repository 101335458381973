import {
  isError,
  useGetCurrentUserQuery,
} from '@spotify-confidence/plugin-graphql';

export function useCurrentUser() {
  const { data } = useGetCurrentUserQuery();
  if (isError(data?.currentUser)) return null;
  return data?.currentUser;
}
