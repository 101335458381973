import React from 'react';

import { LazyQueryHookOptions, QueryResult } from '@apollo/client';
import { getError } from '@spotify-confidence/plugin-graphql';
import _ from 'lodash';

import {
  PaginatedData,
  PaginatedListItem,
  PaginatedQuery,
  PaginatedVariables,
} from './types';
import { getPaginatedItems, getPaginatedListData } from './utils';

export type UseAutocompleteOptions<
  TData extends PaginatedData = PaginatedData,
> = {
  search?: string;
  searchField?: string | string[];
  selected?: string | string[];
  query: PaginatedQuery<TData>;
  queryOptions?: LazyQueryHookOptions<TData> & { skip?: boolean };
};

type UseAutocompleteOptionsResult<TListItem extends PaginatedListItem> = {
  options: TListItem[];
  error?: Error;
  loading?: boolean;
  called?: boolean;
  fetchNextPage: () => void;
};

function getQueryError<TData extends PaginatedData>(
  result: QueryResult<TData, PaginatedVariables>,
) {
  return (
    getError(result.data) ||
    getError(getPaginatedListData(result.data)) ||
    result.error
  );
}

export function useAutocompleteOptions<
  TData extends PaginatedData,
  TListItem extends PaginatedListItem,
>({
  search,
  searchField = 'displayName',
  selected,
  query,
  queryOptions = {},
}: UseAutocompleteOptions<TData>): UseAutocompleteOptionsResult<TListItem> {
  const shouldFetch = !queryOptions?.skip;
  const [refetching, setRefetching] = React.useState(false);
  const [options, setOptions] = React.useState<TListItem[]>([]);
  const mergeOptions = React.useCallback(
    (data: TData) => {
      const items = getPaginatedItems<TData, TListItem>(data) || [];
      if (items.length) {
        setOptions(currentOptions =>
          _.uniqBy(currentOptions.concat(items), 'name'),
        );
      }
      if (queryOptions.onCompleted) {
        queryOptions.onCompleted(data);
      }
    },
    [queryOptions.onCompleted],
  );

  // Separated into different requests in order to predictably combine the data into options
  const [paginatedFetch, paginatedQuery] = query(queryOptions);
  const [filteredFetch, filteredQuery] = query(queryOptions);
  const [selectedFetch, selectedQuery] = query({ onCompleted: mergeOptions });

  // Common error/loading state
  const queries = [paginatedQuery, filteredQuery, selectedQuery];
  const loading = refetching || queries.some(q => q.loading);
  const called = queries.some(q => q.called);
  const error = queries.map(getQueryError).find(Boolean);

  // If all pages have been succsssfully fetched we can avoid requests and rely on UI filtering
  const paginatedListData = getPaginatedListData<TData, TListItem>(
    paginatedQuery.data,
  );
  const hasMorePages = paginatedListData
    ? !!paginatedListData.nextPageToken
    : true;
  const preventAdditionalRequests = !shouldFetch || loading || !hasMorePages;

  // Populate initial options
  React.useEffect(() => {
    if (shouldFetch) {
      if (_.isEqual(paginatedQuery.variables, queryOptions.variables)) {
        paginatedFetch(queryOptions);
      } else {
        paginatedQuery.refetch(queryOptions);
      }
    }
  }, []);

  // Combine filter and search
  const createSearchFilter = React.useCallback(() => {
    const filterArray = [queryOptions?.variables?.filter || ''];
    if (search) {
      const searchFields = Array.isArray(searchField)
        ? searchField
        : [searchField];
      const searchFieldsFilter = searchFields
        .map(field => `${field}:*${search}*`)
        .join(' OR ');
      filterArray.push(`(${searchFieldsFilter})`);
    }
    return filterArray.filter(Boolean).join(' AND ').trim();
  }, [queryOptions?.variables?.filter, search, searchField]);

  // Enable infinite scrolling to gradually see all options
  const filteredListData = getPaginatedListData<TData, TListItem>(
    filteredQuery.data,
  );

  const fetchNextPage = React.useCallback(() => {
    if (!shouldFetch) {
      return;
    }
    if (search) {
      if (filteredListData?.nextPageToken) {
        filteredQuery.fetchMore({
          ...queryOptions,
          variables: {
            ...queryOptions.variables,
            filter: createSearchFilter(),
            pageToken: filteredListData.nextPageToken,
          },
        });
      }
    } else if (paginatedListData?.nextPageToken) {
      paginatedQuery.fetchMore({
        ...queryOptions,
        variables: {
          ...queryOptions.variables,
          pageToken: paginatedListData.nextPageToken,
        },
      });
    }
  }, [
    queryOptions,
    paginatedListData?.nextPageToken,
    filteredListData?.nextPageToken,
    search,
    shouldFetch,
  ]);

  // onComplete does not exist on fetchMore (which enables pagination), so we need to merge these options manually
  React.useEffect(() => {
    if (paginatedQuery.data) {
      mergeOptions(paginatedQuery.data);
    }
  }, [paginatedQuery.data, mergeOptions]);

  React.useEffect(() => {
    if (filteredQuery.data) {
      mergeOptions(filteredQuery.data);
    }
  }, [filteredQuery.data, mergeOptions]);

  // Fetch potential additional results matching current filter
  const previousFilter = filteredQuery.variables?.filter;
  const fetchFiltered = React.useCallback(
    _.debounce((_filter: string) => {
      filteredFetch({
        variables: {
          ...queryOptions.variables,
          filter: _filter,
        },
      });
    }, 500),
    [queryOptions.variables],
  );

  React.useEffect(() => {
    if (preventAdditionalRequests) {
      return;
    }
    const filter = createSearchFilter();
    if (filter && previousFilter !== filter) {
      fetchFiltered(filter);
    }
  }, [
    preventAdditionalRequests,
    createSearchFilter,
    fetchFiltered,
    previousFilter,
  ]);

  // Make sure already selected items are fetched
  const createSelectedFilter = React.useCallback(() => {
    const allSelected = _.isArray(selected) ? selected : [selected];
    const missingSelected = allSelected.filter(
      s => !!s && !options.find(o => o.name === s),
    );
    if (missingSelected.length > 0) {
      return `name:(${missingSelected.map(name => `"${name}"`).join(' ')})`;
    }
    return undefined;
  }, [selected, options]);

  React.useEffect(() => {
    if (preventAdditionalRequests) {
      return;
    }
    const filter = createSelectedFilter();
    if (filter) {
      selectedFetch({
        variables: {
          filter,
        },
      });
    }
  }, [
    preventAdditionalRequests,
    shouldFetch,
    createSelectedFilter,
    selectedFetch,
  ]);

  const refetch = React.useCallback(async () => {
    if (shouldFetch) {
      await paginatedQuery.refetch();
      if (filteredQuery.data) {
        const filter = createSearchFilter();
        if (filter) {
          await filteredQuery.refetch({ filter });
        }
      }
      if (selectedQuery.data) {
        const filter = createSelectedFilter();
        if (filter) {
          await selectedQuery.refetch({ filter });
        }
      }
    }
  }, [
    shouldFetch,
    paginatedQuery.refetch,
    filteredQuery.refetch,
    selectedQuery.refetch,
    createSearchFilter,
    createSelectedFilter,
  ]);

  // Reset options when passed down filter changes
  const previousItemFilter = paginatedQuery.variables?.filter;
  const itemFilter = queryOptions?.variables?.filter;
  React.useEffect(() => {
    if (itemFilter && previousItemFilter !== itemFilter) {
      setOptions([]);
      if (shouldFetch) {
        paginatedFetch(queryOptions);
      }
    }
  }, [itemFilter, previousItemFilter, shouldFetch]);

  // Trigger refetch when tab becomes visible
  const handleRefetch = React.useCallback(async () => {
    if (document.visibilityState === 'visible') {
      setRefetching(true);
      await refetch();
      setRefetching(false);
    }
  }, [refetch]);

  React.useEffect(() => {
    document.addEventListener('visibilitychange', handleRefetch);
    return () => {
      document.removeEventListener('visibilitychange', handleRefetch);
    };
  }, [handleRefetch]);

  return {
    loading,
    called,
    options,
    error,
    fetchNextPage,
  };
}
