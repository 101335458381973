import React from 'react';
import { InView } from 'react-intersection-observer';
import { useParams } from 'react-router-dom';

import {
  Box,
  Card,
  CardContent,
  Chip,
  LinearProgress,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography,
} from '@material-ui/core';
import { Alert } from '@material-ui/lab';

import {
  Breadcrumbs,
  ListEmptyState,
  PageLayout,
  ResourceTableRow,
  Section,
  extractWorkflowFromInstanceName,
  useRecentlyVisited,
} from '@spotify-confidence/core-react';
import {
  MetricDetailsFragmentDoc,
  getError,
  getTypeOrNull,
  isType,
  useGetMetricDetailsQuery,
  useMetricUsageQuery,
  useOnMetricUpdatedSubscription,
  useWorkflowDisplayNamesQuery,
} from '@spotify-confidence/plugin-graphql';
import { ResourcePermissionsButton } from '@spotify-confidence/plugin-permissions-react';
import {
  WorkflowInstanceNameCell,
  WorkflowInstanceStateCell,
} from '@spotify-confidence/plugin-workflows';
import _ from 'lodash';

import { Link } from '@backstage/core-components';
import { useRouteRef } from '@backstage/core-plugin-api';

import { formatPercentage } from '../../../MetricsModule/helpers';
import { metricsRouteRef, workflowInstanceRouteRef } from '../../../routes';
import {
  AverageSimplifiedQuery,
  RatioSimplifiedQuery,
} from '../SimplifiedQuery';
import { MetricSidebar } from './MetricSidebar';

export const MetricPage = () => {
  const { id } = useParams();
  const { triggerRecentlyVisited } = useRecentlyVisited();

  const name = `metrics/${id}`;
  const metricsRoute = useRouteRef(metricsRouteRef);

  const { data, loading, error } = useGetMetricDetailsQuery({
    variables: { name },
    onCompleted: response => {
      if (
        isType(response.metric, 'MetricsV1Metric') &&
        response.metric?.displayName
      ) {
        triggerRecentlyVisited(response.metric.displayName);
      }
    },
  });
  const metric = getTypeOrNull(data?.metric, 'MetricsV1Metric');
  const metricError = getError(data?.metric);
  useOnMetricUpdatedSubscription({
    onData: options => {
      const m = options.data.data?.metricUpdated?.metric;
      if (m) {
        const cacheId = options.client.cache.identify(m);
        if (cacheId) {
          options.client.cache.writeFragment({
            id: cacheId,
            data: m,
            fragment: MetricDetailsFragmentDoc,
            fragmentName: 'MetricDetails',
          });
        }
      }
    },
  });

  const metricUsage = useMetricUsageQuery({
    variables: {
      filter: `module_data.metrics.metrics.metric:"${name}"`,
    },
  });
  const workflowInstances =
    getTypeOrNull(
      metricUsage.data?.workflowInstances,
      'WorkflowV1ListWorkflowInstancesResponse',
    )?.workflowInstances ?? [];
  const metricStatsConfig =
    workflowInstances.flatMap(instance => {
      return instance.moduleData
        .filter(e => e.key === 'metrics')
        .flatMap(e => e.value.metrics)
        .filter(e => e.metric === name)
        .map(e => {
          const effectSize =
            e.metricRole?.nonInferiorityMargin ||
            e.metricRole?.minimumDetectableEffect;
          const role =
            effectSize === e.metricRole?.nonInferiorityMargin
              ? 'Guardrail'
              : 'Success';

          return {
            effectSize,
            role,
            preferredDirection: e.preferredDirection,
            name: instance.name,
          };
        });
    }) || [];

  const workflowDisplayNames = useWorkflowDisplayNamesQuery();

  const previewQueryTitle = 'Query preview';
  const previewQueryDescription =
    'This is a simplified version of the query. The final query takes into account exposure, duration, and starting point.';

  return (
    <PageLayout
      isLoading={loading}
      title={metric?.displayName ?? ''}
      headerBreadcrumbs={
        <Breadcrumbs>
          <Link to={metricsRoute()}>Metrics</Link>
          <Typography>{metric?.displayName ?? ''}</Typography>
        </Breadcrumbs>
      }
      headerContent={
        <ResourcePermissionsButton name={name} title={metric?.displayName} />
      }
      sideBarRight={
        <MetricSidebar metric={metric ?? undefined} disabled={loading} />
      }
    >
      {error && (
        <Box marginBottom={2}>
          <Alert severity="error">{error.message}</Alert>
        </Box>
      )}
      {metricError && (
        <Box marginBottom={2}>
          <Alert severity="error">{metricError.message}</Alert>
        </Box>
      )}

      <Section label="Query">
        <Card>
          <CardContent>
            {metric?.typeSpec?.averageMetricSpec && (
              <AverageSimplifiedQuery
                padding={4}
                title={previewQueryTitle}
                description={previewQueryDescription}
                entity={
                  getTypeOrNull(metric.entity, 'MetricsV1Entity')?.displayName
                }
                factTable={
                  getTypeOrNull(metric.factTable, 'MetricsV1FactTable')
                    ?.displayName
                }
                aggregationMethod={
                  metric.typeSpec.averageMetricSpec.aggregation.type
                }
                aggregationThreshold={
                  metric.typeSpec.averageMetricSpec.aggregation.threshold ??
                  undefined
                }
                measurement={
                  metric.typeSpec.averageMetricSpec.measurement?.name
                }
                replaceMissingWithZero={
                  metric.nullHandling?.replaceEntityNullWithZero ?? false
                }
                filter={metric.filter}
              />
            )}
            {metric?.typeSpec?.ratioMetricSpec && (
              <RatioSimplifiedQuery
                padding={4}
                title={previewQueryTitle}
                description={previewQueryDescription}
                entity={
                  getTypeOrNull(metric.entity, 'MetricsV1Entity')?.displayName
                }
                factTable={
                  getTypeOrNull(metric.factTable, 'MetricsV1FactTable')
                    ?.displayName
                }
                numerator={metric.typeSpec.ratioMetricSpec.numerator?.name}
                numeratorAggregation={
                  metric.typeSpec.ratioMetricSpec.numeratorAggregation.type
                }
                denominator={metric.typeSpec.ratioMetricSpec.denominator?.name}
                denominatorAggregation={
                  metric.typeSpec.ratioMetricSpec.denominatorAggregation.type
                }
                replaceMissingWithZero={
                  metric.nullHandling?.replaceEntityNullWithZero ?? false
                }
                filter={metric.filter}
              />
            )}
          </CardContent>
        </Card>
      </Section>
      <Section label="Usage">
        <Card>
          <CardContent>
            {metricUsage.loading ? (
              <LinearProgress />
            ) : (
              <>
                {metricUsage.error ? (
                  <Alert severity="error">{metricUsage.error.message}</Alert>
                ) : (
                  <>
                    {workflowInstances.length ? (
                      <Table>
                        <TableHead>
                          <TableRow>
                            <TableCell>Name</TableCell>
                            <TableCell>Status</TableCell>
                            <TableCell>Type</TableCell>
                            <TableCell>Planned effect</TableCell>
                            <TableCell>Preferred direction</TableCell>
                            <TableCell>Role</TableCell>
                          </TableRow>
                        </TableHead>
                        <TableBody>
                          {workflowInstances.map(instance => {
                            const workflowId = extractWorkflowFromInstanceName(
                              instance.name,
                            )!;
                            const effectSize = metricStatsConfig.find(
                              e => e.name === instance.name,
                            )?.effectSize;
                            return (
                              <ResourceTableRow
                                key={instance.name}
                                routeRef={workflowInstanceRouteRef}
                                name={instance.name}
                                params={{
                                  workflow: workflowId,
                                }}
                              >
                                <WorkflowInstanceNameCell
                                  workflowInstance={instance}
                                />
                                <WorkflowInstanceStateCell
                                  workflowInstance={instance}
                                />
                                <TableCell>
                                  <Chip
                                    size="small"
                                    label={
                                      getTypeOrNull(
                                        workflowDisplayNames.data?.workflows,
                                        'WorkflowV1ListWorkflowsResponse',
                                      )?.workflows.find(
                                        wf =>
                                          wf.name === `workflows/${workflowId}`,
                                      )?.displayName || workflowId
                                    }
                                  />
                                </TableCell>
                                <TableCell>
                                  {effectSize
                                    ? `${formatPercentage(effectSize)}%`
                                    : 'None'}
                                </TableCell>
                                <TableCell>
                                  {_.upperFirst(
                                    _.toLower(
                                      metricStatsConfig.find(
                                        e => e.name === instance.name,
                                      )?.preferredDirection || '',
                                    ),
                                  )}
                                </TableCell>
                                <TableCell>
                                  {metricStatsConfig.find(
                                    e => e.name === instance.name,
                                  )?.role || ''}
                                </TableCell>
                              </ResourceTableRow>
                            );
                          })}
                          <InView
                            onChange={async inView => {
                              if (
                                inView &&
                                isType(
                                  metricUsage?.data?.workflowInstances,
                                  'WorkflowV1ListWorkflowInstancesResponse',
                                )
                              ) {
                                await metricUsage.fetchMore({
                                  variables: {
                                    pageToken:
                                      metricUsage.data?.workflowInstances
                                        ?.nextPageToken,
                                  },
                                });
                              }
                            }}
                          />
                        </TableBody>
                      </Table>
                    ) : (
                      <ListEmptyState
                        title="This metric is currently unused"
                        description="Workflow instances that use this metric will show up here."
                      />
                    )}
                  </>
                )}
              </>
            )}
          </CardContent>
        </Card>
      </Section>
    </PageLayout>
  );
};
