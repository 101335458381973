import React from 'react';
import { Link } from 'react-router-dom';

import {
  Card,
  CardContent,
  CardHeader,
  Grid,
  IconButton,
  Link as MuiLink,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography,
  makeStyles,
} from '@material-ui/core';
import Cancel from '@material-ui/icons/Cancel';
import EditIcon from '@material-ui/icons/Edit';
import TuneIcon from '@material-ui/icons/Tune';

import {
  NumberUtils,
  SidebarSection,
  SidebarValue,
  SidebarValueWrapper,
  extractLastNameComponent,
  useDialog,
  useDisplayNames,
} from '@spotify-confidence/core-react';
import { CommentZone } from '@spotify-confidence/plugin-comments-react';

import { useRouteRef } from '@backstage/core-plugin-api';

import { Method, resultUtils } from '../result';
import { ResultData } from '../result/components';
import {
  MethodKind,
  methodToKindAndMethod,
} from '../result/components/Results/utils';
import { metricRouteRef } from '../routes';
import { StatsDetailsPopover } from '../shared/components/StatsDetails';
import { MetricDialog, MetricDialogProps } from './MetricDialog';
import { MetricResultsProps } from './MetricResults';
import { MetricSubheader, MetricSubheaderProps } from './MetricSubheader';
import { formatPercentage } from './helpers';
import { Metric } from './types';

const useStyles = makeStyles(theme => ({
  cardRoot: {
    maxWidth: '100%',
    overflowX: 'auto',
  },
  header: {
    gap: theme.spacing(2),
  },
  headerContent: {
    minWidth: 0,
  },
  title: {
    display: 'block',
    cursor: 'pointer',
    lineHeight: '1.2',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
  },
  effect: {
    display: 'inline-flex',
    alignItems: 'center',
    gap: theme.spacing(0.5),
    lineHeight: '1em',
  },
  icons: {
    marginTop: theme.spacing(1),
    display: 'flex',
    alignItems: 'center',
    gap: theme.spacing(0.5),
  },
}));

export const getMethodDescription = (
  method: MethodKind,
): string | undefined => {
  switch (method) {
    case 'zTest':
      return 'Z-test';
    case 'zTestRatio':
      return 'Z-test with delta method';
    case 'gstZTest':
      return 'Group sequential z-test';
    case 'gstZTestRatio':
      return 'Group sequential z-test with delta method';
    case 'asympCs':
      return 'Always-valid z-test';
    case 'asympCsRatio':
      return 'Always valid z-test with delta method';
    default:
      return undefined;
  }
};

type StatsDesignDetailsProps = {
  alpha?: number;
  beta?: number;
  methodName?: string;
};

function StatsDesignDetails({
  alpha,
  beta,
  methodName,
}: StatsDesignDetailsProps): JSX.Element {
  return (
    <SidebarValueWrapper>
      {methodName && <SidebarValue name="Method" value={methodName} popover />}
      {alpha && (
        <SidebarValue
          name="Adjusted alpha"
          value={`${formatPercentage(alpha)}%`}
          popover
        />
      )}
      {beta && (
        <SidebarValue
          name="Adjusted power"
          value={`${formatPercentage(1 - beta)}%`}
          popover
        />
      )}
    </SidebarValueWrapper>
  );
}

type StatsDetailsProps = {
  resultData: ResultData;
  baseline: string;
  compared: string;
};

export function StatsDetails({
  resultData,
  baseline,
  compared,
}: StatsDetailsProps): JSX.Element {
  const baselineSampleSize = resultData.baselineSampleSize;
  const comparedSampleSize = resultData.comparedSampleSize;
  const baselineMean = resultData.result?.groupMeanAbs.baseline;
  const comparedMean = resultData.result?.groupMeanAbs.compared;
  const unadjustedBaselineMean =
    resultData.result?.unadjustedGroupMeanAbs?.baseline;
  const unadjustedComparedMean =
    resultData.result?.unadjustedGroupMeanAbs?.compared;
  const alpha = resultData.statsSettings?.adjustedAlpha;
  const beta = resultData.statsSettings?.adjustedBeta;
  const methodName = getMethodDescription(
    methodToKindAndMethod(resultData.statsSettings?.method).method,
  );
  const varianceReduction = resultData.result?.varianceReductionRate;

  const dataAvailable =
    baselineMean && comparedMean && baselineSampleSize && comparedSampleSize;
  const statsAvailable = alpha || beta || methodName || varianceReduction;

  return (
    <Grid container spacing={2}>
      <Grid item xs={6}>
        <Card>
          <CardContent>
            <Typography variant="h6">Data details</Typography>
            {!dataAvailable ? (
              <>
                <Typography>No data available yet</Typography>
              </>
            ) : (
              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell /> {/* Empty cell for identifiers */}
                    <TableCell>{baseline}</TableCell>
                    <TableCell>{compared}</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  <TableRow>
                    <TableCell>Mean</TableCell> {/* Identifier */}
                    <TableCell>
                      {baselineMean &&
                        NumberUtils.formatNumberFixed2(baselineMean)}
                    </TableCell>
                    <TableCell>
                      {comparedMean &&
                        NumberUtils.formatNumberFixed2(comparedMean)}
                    </TableCell>
                  </TableRow>
                  {unadjustedBaselineMean && unadjustedComparedMean && (
                    <TableRow>
                      <TableCell>Mean (without variance reduction)</TableCell>{' '}
                      {/* Identifier */}
                      <TableCell>
                        {NumberUtils.formatNumberFixed2(unadjustedBaselineMean)}
                      </TableCell>
                      <TableCell>
                        {NumberUtils.formatNumberFixed2(unadjustedComparedMean)}
                      </TableCell>
                    </TableRow>
                  )}
                  <TableRow>
                    <TableCell>Sample size</TableCell> {/* Identifier */}
                    <TableCell>{baselineSampleSize}</TableCell>
                    <TableCell>{comparedSampleSize}</TableCell>
                  </TableRow>
                </TableBody>
              </Table>
            )}
          </CardContent>
        </Card>
      </Grid>
      <Grid item xs={6}>
        <Card>
          <CardContent>
            {!statsAvailable ? (
              <>
                <Typography variant="h6">Stats details</Typography>
                <Typography>
                  No statistical information available yet
                </Typography>
              </>
            ) : (
              <SidebarSection title="Stats details">
                <SidebarValueWrapper>
                  <Grid container spacing={2}>
                    {methodName && (
                      <Grid item>
                        <SidebarValue
                          name="Method"
                          value={methodName}
                          popover
                        />
                      </Grid>
                    )}
                    {alpha && (
                      <Grid item>
                        <SidebarValue
                          name="Adjusted alpha"
                          value={`${formatPercentage(alpha)}%`}
                          popover
                        />
                      </Grid>
                    )}
                    {beta && (
                      <Grid item>
                        <SidebarValue
                          name="Adjusted power"
                          value={`${formatPercentage(1 - beta)}%`}
                          popover
                        />
                      </Grid>
                    )}
                    {varianceReduction && (
                      <Grid item>
                        <SidebarValue
                          name="Variance reduction"
                          value={
                            varianceReduction > 0
                              ? `${formatPercentage(varianceReduction)}%`
                              : 'Disabled'
                          }
                          popover
                        />
                      </Grid>
                    )}
                  </Grid>
                </SidebarValueWrapper>
              </SidebarSection>
            )}
          </CardContent>
        </Card>
      </Grid>
    </Grid>
  );
}

type MetricCardProps = React.PropsWithChildren<
  {
    metric: Metric;
    disabledEdit: boolean;
    onSave: (v: Metric) => Promise<any>;
    onRemove: () => Promise<any>;
    error?: string;
    state?: string;
    metricMethod?: Method;
    extraDialogProps?: Partial<Pick<MetricDialogProps, 'allowSetEffect'>>;
    extraSubheaderProps?: Partial<Pick<MetricSubheaderProps, 'extraText'>>;
  } & MetricResultsProps
>;

export const MetricCardV2 = ({
  metric,
  onSave,
  onRemove,
  disabledEdit,
  metricMethod,
  extraDialogProps,
  extraSubheaderProps,
}: MetricCardProps) => {
  const classes = useStyles();
  const metricRoute = useRouteRef(metricRouteRef);
  const { openDialog } = useDialog();

  const { displayNames } = useDisplayNames();
  const handleEditMetric = () => {
    openDialog({
      content: (
        <MetricDialog
          metric={metric}
          onSubmit={onSave}
          {...(extraDialogProps ?? {})}
        />
      ),
    });
  };

  const metricId = extractLastNameComponent(metric?.metric);

  const displayName = displayNames.get(metric.metric) || metric.metric;

  const { method } = resultUtils.methodToKindAndMethod(metricMethod);

  return (
    <Card
      className={classes.cardRoot}
      data-testid={`metric-card-${displayName}`}
    >
      <CardHeader
        classes={{ root: classes.header, content: classes.headerContent }}
        title={
          <CommentZone id="title">
            <MuiLink
              variant="h6"
              className={classes.title}
              title={displayName}
              component={Link}
              to={
                metricId
                  ? metricRoute?.({
                      id: metricId,
                    })
                  : ''
              }
            >
              {displayName}
            </MuiLink>
          </CommentZone>
        }
        action={
          <>
            {!disabledEdit && (
              <div className={classes.icons}>
                {metricMethod && (
                  <StatsDetailsPopover
                    details={
                      <StatsDesignDetails
                        methodName={getMethodDescription(method)}
                      />
                    }
                  >
                    <TuneIcon />
                  </StatsDetailsPopover>
                )}
                <IconButton
                  onClick={handleEditMetric}
                  aria-label="edit"
                  data-testid="edit-button"
                  size="small"
                >
                  <EditIcon />
                </IconButton>
                <IconButton
                  onClick={onRemove}
                  aria-label="remove"
                  data-testid="remove-button"
                  size="small"
                >
                  <Cancel />
                </IconButton>
              </div>
            )}
          </>
        }
        subheader={
          <>
            <MetricSubheader metric={metric} {...extraSubheaderProps} />
          </>
        }
      />
    </Card>
  );
};
