import React, { useEffect } from 'react';

import {
  Chip,
  Collapse,
  ListItem,
  ListItemText,
  TextField,
  TextFieldProps,
} from '@material-ui/core';
import {
  Autocomplete,
  AutocompleteProps,
  FilterOptionsState,
} from '@material-ui/lab';

import {
  SurfaceFragment,
  getTypeOrNull,
  useSurfacesQuery,
} from '@spotify-confidence/plugin-graphql';

const SurfaceItem = ({ surface }: { surface: SurfaceFragment }) => (
  <ListItem>
    <ListItemText
      primary={surface.displayName}
      secondary={surface.description ?? '(No description)'}
    />
  </ListItem>
);

export interface SurfaceAutocompleteProps<Multi extends boolean | undefined>
  extends Omit<
    AutocompleteProps<SurfaceFragment, Multi, boolean, false>,
    'options' | 'renderInput' | 'onInputChange'
  > {
  textFieldProps?: TextFieldProps;
  clearOnSelect?: boolean;
  hideIfNoSurfaces?: boolean;
  disabledOptions?: SurfaceFragment[];
  mandatorySurfaces?: string[];
}

const filterOptions = (
  options: SurfaceFragment[],
  v: FilterOptionsState<SurfaceFragment>,
) =>
  options.filter(o =>
    v.getOptionLabel(o).toLowerCase().includes(v.inputValue.toLowerCase()),
  );

export function SurfaceAutocomplete<
  Multiple extends boolean | undefined = undefined,
>({
  value,
  onChange,
  disabledOptions = [],
  multiple,
  textFieldProps,
  clearOnSelect,
  hideIfNoSurfaces,
  mandatorySurfaces,
  ...rest
}: SurfaceAutocompleteProps<Multiple>) {
  const [inputValue, setInputValue] = React.useState('');
  const pageSize = 100;

  const { data, fetchMore } = useSurfacesQuery({
    variables: { pageSize },
  });
  const { surfaces, nextPageToken: pageToken } = getTypeOrNull(
    data?.surfaces,
    'WorkflowV1ListSurfacesResponse',
  ) || {
    surfaces: [],
  };

  const selectableSurfaces = React.useMemo(
    () =>
      (surfaces ?? []).filter(
        s => !mandatorySurfaces || !mandatorySurfaces?.includes(s.name),
      ),
    [surfaces],
  );

  useEffect(() => {
    if (pageToken) {
      fetchMore({
        variables: {
          pageToken,
          pageSize,
        },
      });
    }
  }, [pageToken, fetchMore]);

  return (
    <Collapse in={hideIfNoSurfaces ? surfaces.length > 0 : true}>
      <Autocomplete
        multiple={multiple}
        renderInput={params => (
          <TextField
            {...params}
            {...textFieldProps}
            inputProps={{ ...textFieldProps?.inputProps, ...params.inputProps }}
          />
        )}
        renderTags={(tagValues, getTagProps) =>
          tagValues.map((option, index) => (
            <Chip
              label={option.displayName}
              {...getTagProps({ index })}
              disabled={mandatorySurfaces?.includes(option.name)}
            />
          ))
        }
        options={selectableSurfaces}
        getOptionLabel={option => option.displayName}
        filterOptions={filterOptions}
        getOptionSelected={(option, v) => option.name === v?.name}
        getOptionDisabled={option =>
          disabledOptions.some(d => d.name === option.name)
        }
        renderOption={option => <SurfaceItem surface={option} />}
        value={value}
        onChange={onChange}
        onInputChange={(_event, newInputValue, reason) => {
          if (reason === 'reset' && clearOnSelect) {
            // eslint-disable-next-line no-param-reassign
            newInputValue = '';
          }
          setInputValue(newInputValue);
        }}
        inputValue={inputValue}
        {...rest}
      />
    </Collapse>
  );
}
