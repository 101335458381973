import React from 'react';

import { Card, CardContent, Chip, styled } from '@material-ui/core';

import loadable from '@loadable/component';
import { Section } from '@spotify-confidence/core-react';

const UsageChart = loadable(() =>
  import('./UsageChart').then(m => m.UsageChart),
);

const NoMarginChip = styled(Chip)({
  marginBottom: 0,
});

const truncateDateToMinute = (date: Date) => {
  const newDate = new Date(date);
  newDate.setSeconds(0);
  newDate.setMilliseconds(0);
  return newDate;
};

const lastDay = () => {
  const endTime = truncateDateToMinute(new Date());
  const startTime = new Date(endTime.getTime() - 24 * 60 * 60 * 1000);

  return {
    startTime,
    endTime,
  };
};

const lastWeek = () => {
  const endTime = truncateDateToMinute(new Date());
  const startTime = new Date(endTime.getTime() - 6 * 24 * 60 * 60 * 1000);

  return {
    startTime,
    endTime,
  };
};

export const UsageSection = ({ event }: { event: string }) => {
  const [timeRange, setTimeRange] = React.useState<'day' | 'week'>('day');
  const { startTime, endTime } = timeRange === 'day' ? lastDay() : lastWeek();

  return (
    <Section
      label="Usage"
      actions={
        <>
          <NoMarginChip
            label="Last day"
            onClick={() => setTimeRange('day')}
            size="small"
            color={timeRange === 'day' ? 'secondary' : 'default'}
          />
          <NoMarginChip
            label="Last week"
            onClick={() => setTimeRange('week')}
            size="small"
            color={timeRange === 'week' ? 'secondary' : 'default'}
          />
        </>
      }
    >
      <Card>
        <CardContent>
          <UsageChart
            event={event}
            startTime={startTime.toISOString()}
            endTime={endTime.toISOString()}
          />
        </CardContent>
      </Card>
    </Section>
  );
};
