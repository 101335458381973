import { EventSchemaEntryFragment } from '@spotify-confidence/plugin-graphql';
import _ from 'lodash';

import {
  differenceBetweenSchemas,
  findIdentifier,
  getNestedSchema,
} from '../../../domain/schema.helpers';
import { Entry } from '../../../domain/schema.hooks';
import { useSchemaContext } from '../SchemaContext';

type DraftSchemaEntriesProps = {
  draftSchema: EventSchemaEntryFragment[];
};

export const useDraftSchemaEntries = ({
  draftSchema,
}: DraftSchemaEntriesProps) => {
  const { schema, onAdd } = useSchemaContext();

  const difference = differenceBetweenSchemas(schema, draftSchema || []);
  const newFields =
    draftSchema?.filter(field => {
      return difference.some(diff => diff[0] === field.key);
    }) ?? [];

  const isExistingField = (path: string[]) => {
    return findIdentifier(schema, path, '0') !== undefined;
  };

  const addTopLevel = (path: string[]) => {
    const entry = newFields.find(f => f.key === path[0]);
    if (entry) {
      return onAdd('0', entry);
    }

    return undefined;
  };

  const addNested = (path: string[]) => {
    const parentPath = path.slice(0, -1);
    const identifier = findIdentifier(schema, parentPath, '0');
    const entry = path.reduce<Entry | undefined>((_entry, key) => {
      if (_entry) {
        const nestedSchema = getNestedSchema(_entry.value);
        if (nestedSchema) {
          return nestedSchema.schema.find(f => f.key === key);
        }
        return undefined;
      }
      return newFields.find(f => f.key === key);
    }, undefined);

    if (identifier && entry) {
      return onAdd(identifier, entry);
    }

    return undefined;
  };

  const handleAddAll = () => {
    // Sort to add existing fields first to avoid breaking identifiers by adding new entries
    _.sortBy(difference, p =>
      findIdentifier(schema, p.slice(0, -1), '0'),
    ).forEach(path => {
      if (path.length === 1) {
        addTopLevel(path);
      } else if (path.length > 1) {
        addNested(path);
      }
    });
  };

  return { handleAddAll, addNested, addTopLevel, isExistingField, newFields };
};
