import React from 'react';

import {
  Backdrop,
  Button,
  CircularProgress,
  Link,
  Tooltip,
  styled,
} from '@material-ui/core';
import Block from '@material-ui/icons/Block';
import PriorityHighIcon from '@material-ui/icons/PriorityHigh';

import _ from 'lodash';

import { ListEmptyState, useListFilter } from '../../';

export type FilterListEmptyStateProps = {
  onCreate?: () => void;
  createDisabledReason?: string;
  type: string;
  typePlural?: string;
  description?: React.ReactNode;
  filterDescription?: React.ReactNode;
  className?: string;
  loading?: boolean;
  error?: Error | string | null;
};

const AbsoluteBackdrop = styled(Backdrop)({
  position: 'absolute',
  zIndex: 1,
  pointerEvents: 'none',
});

export const FilterListEmptyState = ({
  className,
  type,
  typePlural = `${type}s`,
  onCreate,
  createDisabledReason,
  loading = false,
  error,
  description:
    propDescription = `When you create ${typePlural} they will show up here.`,
  filterDescription = 'Tweak your filters and try again.',
}: FilterListEmptyStateProps) => {
  const { isDefaultFilterState, resetFilters } = useListFilter();

  let title = isDefaultFilterState
    ? `We couldn't find any ${typePlural}`
    : `No ${typePlural} matching your criteria`;
  let description = isDefaultFilterState ? propDescription : filterDescription;
  let actions = isDefaultFilterState ? (
    onCreate && (
      <Tooltip title={createDisabledReason ?? ''}>
        <div>
          <Button
            variant="outlined"
            disabled={(createDisabledReason ?? '').length !== 0}
            onClick={onCreate}
          >
            Create {type}
          </Button>
        </div>
      </Tooltip>
    )
  ) : (
    <Button variant="outlined" onClick={resetFilters}>
      Reset filters
    </Button>
  );

  let supportSnippet;
  let icon;

  if (error) {
    const errorMessage = _.isError(error) ? error.message : error;
    const permissionError = errorMessage.includes('permission');
    if (permissionError) {
      title = 'Missing permissions';
      description = (
        <span>
          You don't have the necessary permissions to view {typePlural}. Contact
          your Confidence administrator to gain access.
        </span>
      );
      icon = Block;
    } else {
      title = `Failed to get ${typePlural}`;
      description = (
        <span>
          We are unable to display {typePlural} right now due to the following
          error: <br />"{errorMessage}"
        </span>
      );
      supportSnippet = (
        <span>
          Still having issues?{' '}
          <Link
            underline="always"
            href={[
              'mailto:confidence@spotify.com?',
              `subject=Support request: Failed to get ${typePlural}`,
              `&body=Page: ${encodeURIComponent(
                global.window.location.href,
              )}%0D%0AError: ${encodeURIComponent(
                errorMessage,
              )}%0D%0AAdditional details:`,
            ].join('')}
            target="_blank"
          >
            Contact support
          </Link>
          .
        </span>
      );
      icon = PriorityHighIcon;
      actions = (
        <Button
          color="secondary"
          variant="outlined"
          onClick={() => window.location.reload()}
        >
          Refresh
        </Button>
      );
    }
  }

  return (
    <ListEmptyState
      className={className}
      title={title}
      description={description}
      supportSnippet={supportSnippet}
      icon={icon}
      actions={actions}
    >
      <AbsoluteBackdrop open={loading}>
        <CircularProgress />
      </AbsoluteBackdrop>
    </ListEmptyState>
  );
};
