import { Chip, styled } from '@material-ui/core';

export const DroppedChip = styled(Chip)(({ theme }) => ({
  marginLeft: theme.spacing(2),
  marginBottom: 0,
}));

DroppedChip.defaultProps = {
  label: 'Not stored',
  color: 'secondary',
  variant: 'default',
  size: 'small',
};
