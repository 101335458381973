import React from 'react';
import { Route, Routes } from 'react-router-dom';

import loadable from '@loadable/component';
import { AuthorizationGate } from '@spotify-confidence/core-react';

import { ResolveTesterPage } from './resolveTester/components/ResolveTesterPage';
import {
  flagRouteRef,
  resolverTesterRouteRef,
  segmentRouteRef,
  segmentsRouteRef,
} from './routes';
import { SegmentListPage } from './segment/components/SegmentListPage';
import { SegmentPage } from './segment/components/SegmentPage';

const flagPagesChunk = () =>
  import(
    /* webpackChunkName: "flags-pages", webpackPrefetch: 5 */ './flag/components/pages.chunk'
  );
const loadOptions = {};

const FlagPage = loadable(
  () => flagPagesChunk().then(m => m.FlagPage),
  loadOptions,
);
const FlagsPage = loadable(
  () => flagPagesChunk().then(m => m.FlagsPage),
  loadOptions,
);

const routes = (
  <>
    <Route path="/" element={<FlagsPage />} />
    <Route path={resolverTesterRouteRef.path} element={<ResolveTesterPage />} />
    <Route path={segmentsRouteRef.path} element={<SegmentListPage />} />
    <Route path={segmentRouteRef.path} element={<SegmentPage />} />
    <Route path={flagRouteRef.path} element={<FlagPage />} />
  </>
);

export const Router = () => (
  <AuthorizationGate>
    <Routes>{routes}</Routes>
  </AuthorizationGate>
);
