import {
  createExternalRouteRef,
  createRouteRef,
  createSubRouteRef,
} from '@backstage/core-plugin-api';

// internal routes
export const rootRouteRef = createRouteRef({ id: 'metrics-root' });

export const metricsRouteRef = rootRouteRef;

export const metricRouteRef = createSubRouteRef({
  id: 'metric',
  path: '/:id',
  parent: rootRouteRef,
});

export const entitiesRouteRef = createSubRouteRef({
  id: 'entities',
  path: '/entities',
  parent: rootRouteRef,
});

export const entityRelationTableRouteRef = createSubRouteRef({
  id: 'entity-relation-tables',
  path: '/entities/:entityId/entityRelationTables/:entityRelationTableId',
  parent: rootRouteRef,
});

export const entityRelationTablesCreateRouteRef = createSubRouteRef({
  id: 'entity-relation-table-create',
  path: '/entity-relation-table-create/:parentEntityId',
  parent: rootRouteRef,
});

export const entityRouteRef = createSubRouteRef({
  id: 'entity',
  path: '/entities/:id',
  parent: rootRouteRef,
});

export const factTablesRouteRef = createSubRouteRef({
  id: 'fact-tables',
  path: '/fact-tables',
  parent: rootRouteRef,
});

export const factTablesCreateRouteRef = createSubRouteRef({
  id: 'fact-tables-create',
  path: '/fact-tables-create',
  parent: rootRouteRef,
});

export const factTableRouteRef = createSubRouteRef({
  id: 'fact-table',
  path: '/fact-tables/:id',
  parent: rootRouteRef,
});

export const factTableEditRouteRef = createSubRouteRef({
  id: 'fact-table-edit',
  path: '/fact-tables/:id/edit',
  parent: rootRouteRef,
});

export const assignmentTablesRouteRef = createSubRouteRef({
  id: 'assignment-tables',
  path: '/assignment-tables',
  parent: rootRouteRef,
});

export const assignmentTablesEditeRouteRef = createSubRouteRef({
  id: 'assignment-tables-create',
  path: '/assignment-tables/:id/edit',
  parent: rootRouteRef,
});

export const assignmentTablesCreateRouteRef = createSubRouteRef({
  id: 'assignment-tables-create',
  path: '/assignment-tables-create',
  parent: rootRouteRef,
});

export const assignmentTableRouteRef = createSubRouteRef({
  id: 'assignment-table',
  path: '/assignment-tables/:id',
  parent: rootRouteRef,
});

export const dimensionTablesRouteRef = createSubRouteRef({
  id: 'dimension-tables',
  path: '/dimension-tables',
  parent: rootRouteRef,
});

export const dimensionTableRouteRef = createSubRouteRef({
  id: 'dimension-table',
  path: '/dimension-tables/:id',
  parent: rootRouteRef,
});

export const dimensionTableCreateRouteRef = createSubRouteRef({
  id: 'dimension-table',
  path: '/dimension-tables-create',
  parent: rootRouteRef,
});

export const workflowInstanceRouteRef = createExternalRouteRef({
  id: 'workflow-instance',
  params: ['workflow', 'id'],
  optional: true,
});
