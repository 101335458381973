import React from 'react';

import { WithStyles, createStyles, withStyles } from '@material-ui/core';

import classNames from 'classnames';

import { resultUtils } from '..';
import { BucketingTemporalUnit } from '../../../../MetricsModule';
import { ResultData } from '../MetricResultTable/types';
import { MetricResultContextProvider } from './context';

const styles = createStyles({
  root: {},
});

export interface MetricResultProps extends WithStyles<typeof styles> {
  className?: string;
  results: ResultData[];
  bucket: BucketingTemporalUnit;
  isLive: boolean;
  component?: React.ElementType<{ className?: string }>;
  children?: React.ReactNode;
}

const Root = ({
  classes,
  className,
  results,
  component: Component = 'div',
  bucket,
  isLive,
  children,
}: MetricResultProps) => {
  const result = resultUtils.getLatestResult(results);
  const isSequential = results.length > 1;

  return (
    <MetricResultContextProvider
      value={result!}
      results={results}
      isSequential={isSequential}
      bucket={bucket}
      isLive={isLive}
    >
      <Component className={classNames(classes.root, className)}>
        {children}
      </Component>
    </MetricResultContextProvider>
  );
};

export default withStyles(styles)(Root);
