import React from 'react';
import { LinkProps } from 'react-router-dom';

import { TableRow, TableRowProps, makeStyles } from '@material-ui/core';

import classNames from 'classnames';

import { useNavigate } from '../../behaviors';

const useStyles = makeStyles(() => ({
  row: {
    cursor: 'pointer',
  },
}));

export type LinkableTableRowProps = TableRowProps & { to: LinkProps['to'] };

export const LinkableTableRow = ({
  children,
  to,
  hover = true,
  ...tableRowProps
}: LinkableTableRowProps) => {
  const navigate = useNavigate();
  const classes = useStyles();

  return (
    <TableRow
      hover={hover}
      className={classNames(classes.row, tableRowProps.className)}
      onClick={() => (to ? navigate(to) : undefined)}
      {...tableRowProps}
    >
      {children}
    </TableRow>
  );
};
