import React from 'react';

import { Box, DialogActions, TextField, Typography } from '@material-ui/core';
import { Alert } from '@material-ui/lab';

import {
  ConfidenceWebsiteLink,
  DialogBody,
  DialogHeader,
  FormSubmitButtons,
  extractLastNameComponent,
  useAlert,
  useDialog,
  useNavigate,
} from '@spotify-confidence/core-react';
import {
  ListOAuthAppsDocument,
  isType,
  useCreateOAuthAppMutation,
} from '@spotify-confidence/plugin-graphql';

import { useRouteRef } from '@backstage/core-plugin-api';

import { oauthAppRouteRef } from '../../../routes';

export const CreateOauthApp = () => {
  const [displayName, setDisplayName] = React.useState('');
  const { closeDialog } = useDialog();
  const alert = useAlert();
  const navigate = useNavigate();
  const oAuthAppRoute = useRouteRef(oauthAppRouteRef);

  const [create, { loading, error }] = useCreateOAuthAppMutation({
    refetchQueries: [{ query: ListOAuthAppsDocument }],
    onCompleted: response => {
      if (isType(response.createOAuthApp, 'IamV1OAuthApp')) {
        navigate(
          oAuthAppRoute({
            id: extractLastNameComponent(response.createOAuthApp.name)!,
          }),
        );
      } else {
        alert.post({
          message: response.createOAuthApp?.message ?? 'Something went wrong',
          severity: 'error',
        });
      }
    },
  });
  const createOAuthApp = () => {
    create({
      variables: {
        oauthApp: {
          displayName,
        },
      },
    });
  };
  return (
    <>
      <DialogHeader title="Create OAuth App" />
      <DialogBody>
        {error && <Alert severity="error">{error.message}</Alert>}
        <TextField
          name="createoauthapp"
          disabled={loading}
          variant="outlined"
          required
          label="Display name"
          onChange={e => setDisplayName(e.target.value)}
          fullWidth
          autoFocus
        />
        <Box mt={3}>
          <Typography variant="body2">
            Read more about OAuth Apps{' '}
            <ConfidenceWebsiteLink
              underline="always"
              route="/docs/api/iam/oauth"
            >
              in the documentation.
            </ConfidenceWebsiteLink>
          </Typography>
        </Box>
      </DialogBody>
      <DialogActions>
        <FormSubmitButtons
          onCancel={closeDialog}
          onSubmit={createOAuthApp}
          disabled={loading}
          loading={loading}
          label="Create"
        />
      </DialogActions>
    </>
  );
};
