import React from 'react';

import { TextField } from '@material-ui/core';

import {
  FilterAutocomplete,
  MultipleValue,
  RenderEditComponentProps,
  getFilterString,
  useAlert,
} from '@spotify-confidence/core-react';
import {
  getError,
  getTypeOrNull,
  useEntitiesFilterQuery,
} from '@spotify-confidence/plugin-graphql';

export const RenderEntitiesFilterValue = ({
  value,
}: {
  value?: MultipleValue<string>;
}) => {
  const filter = (value ?? []).map(f => `name:\"${f}\"`).join(' OR ');
  const { data, loading, previousData } = useEntitiesFilterQuery({
    variables: {
      filter,
    },
    skip: !value,
  });
  const entitiesData = loading ? previousData : data;
  const entities = (
    getTypeOrNull(entitiesData?.entities, 'MetricsV1ListEntitiesResponse')
      ?.entities ?? []
  )
    .filter(f => (value ?? []).includes(f.name))
    .map(e => e.displayName);

  if (!value) return null;
  return <span>{entities.join(', ')}</span>;
};

export const EntitiesFilter = (
  props: RenderEditComponentProps<MultipleValue<string>>,
) => {
  const [query, setQuery] = React.useState('');
  const alert = useAlert();
  const filter = getFilterString(
    {
      searchQuery: query ?? '',
      filters: [],
    },
    [],
    'displayName',
  );

  const handleError = (e: Error) => {
    alert.post({ message: e.message, severity: 'error' });
  };
  const { data, loading, fetchMore } = useEntitiesFilterQuery({
    variables: {
      filter,
    },
    onCompleted: response => {
      const error = getError(response.entities);
      if (error) {
        handleError(error);
      }
    },
    onError: handleError,
  });

  React.useEffect(() => {
    fetchMore({
      variables: {
        filter: (props.value ?? []).map(f => `name:\"${f}\"`).join(' OR '),
      },
    });
  }, [props.value]);

  const entities = (
    getTypeOrNull(data?.entities, 'MetricsV1ListEntitiesResponse')?.entities ??
    []
  ).map(e => ({
    name: e.name,
    displayName: e.displayName,
  }));
  const values = entities.filter(f => (props.value ?? []).includes(f.name));

  return (
    <FilterAutocomplete<
      { name: string; displayName: string },
      true,
      false,
      false
    >
      options={entities}
      loading={loading}
      multiple
      value={values}
      getOptionLabel={option => option.displayName}
      onInputChange={(_, v) => setQuery(v)}
      style={{ width: 300 }}
      onChange={(_e, v) => {
        props.onChange(v.map(e => e.name));
      }}
      renderInput={params => (
        <TextField
          {...params}
          size="small"
          margin="none"
          placeholder="Entities"
          variant="outlined"
        />
      )}
    />
  );
};
