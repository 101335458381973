import React from 'react';

import ClearAllIcon from '@material-ui/icons/ClearAll';
import ListIcon from '@material-ui/icons/List';
import { ToggleButtonGroup } from '@material-ui/lab';

import { TooltipToggleButton } from '@spotify-confidence/core-react';

export type ViewMode = 'Table' | 'Timeline';
const viewModeOptions = [
  { icon: <ListIcon />, name: 'Table' },
  { icon: <ClearAllIcon />, name: 'Timeline' },
];

type ViewModeToggleProps = {
  mode: ViewMode;
  onModeChange: (newViewMode: ViewMode) => void;
};

export const ViewModeToggle = ({ mode, onModeChange }: ViewModeToggleProps) => {
  const changeMode = (newMode: ViewMode | null) =>
    onModeChange(newMode === null ? mode : newMode);

  return (
    <ToggleButtonGroup
      exclusive
      size="small"
      value={mode}
      onChange={(_e, value) => changeMode(value)}
      data-testid="toggle-view-mode"
    >
      {viewModeOptions.map(viewMode => (
        <TooltipToggleButton
          key={viewMode.name}
          value={viewMode.name}
          TooltipProps={{ title: viewMode.name, enterDelay: 1000 }}
        >
          {viewMode.icon}
        </TooltipToggleButton>
      ))}
    </ToggleButtonGroup>
  );
};
