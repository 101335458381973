import {
  createPlugin,
  createRoutableExtension,
} from '@backstage/core-plugin-api';

import {
  entitiesRouteRef,
  entityRouteRef,
  eventIndexRouteRef,
  eventRouteRef,
  rootRouteRef,
} from './routes';

export const eventsPlugin = createPlugin({
  id: 'events',
  routes: {
    root: rootRouteRef,
    event: eventRouteRef,
    events: eventIndexRouteRef,
  },
  externalRoutes: {
    entity: entityRouteRef,
    entities: entitiesRouteRef,
  },
});

export const ConfidenceEventsPage = eventsPlugin.provide(
  createRoutableExtension({
    name: 'ConfidenceEventsPage',
    component: () =>
      import(
        /* webpackChunkName: "confidence-events", webpackPrefetch: 10 */ './Router'
      ).then(m => m.Router),
    mountPoint: rootRouteRef,
  }),
);
