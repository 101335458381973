import React from 'react';

import { Box, Button, TextFieldProps } from '@material-ui/core';
import FileCopyIcon from '@material-ui/icons/FileCopy';
import { Alert } from '@material-ui/lab';

import {
  CreatableAutocomplete,
  StringUtils,
  extractLastNameComponent,
  useAlert,
} from '@spotify-confidence/core-react';
import {
  IamV1CryptoKeyKind,
  IamV1CryptoKeyState,
  ListCryptoKeysDocument,
  getTypeOrNull,
  isType,
  useCreateCryptoKeyMutation,
  useGetCryptoKeyQuery,
  useListCryptoKeysQuery,
} from '@spotify-confidence/plugin-graphql';
import _ from 'lodash';

type CryptoKeyInputProps = Omit<TextFieldProps, 'value' | 'onChange'> & {
  value: string;
  onChange: (newValue: string) => void;
};

export function CryptoKeyInput({
  onChange,
  value,
  disabled,
  ...textFieldProps
}: CryptoKeyInputProps) {
  const alert = useAlert();
  const { data, loading } = useListCryptoKeysQuery({
    variables: {
      pageSize: 100,
    },
    fetchPolicy: 'cache-and-network',
  });
  const cryptoKeys = getTypeOrNull(
    data?.cryptoKeys,
    'IamV1ListCryptoKeysResponse',
  );
  const [
    createCryptoKeyMutation,
    { data: newKeyData, loading: createLoading, error: createError },
  ] = useCreateCryptoKeyMutation();
  const newKey = getTypeOrNull(newKeyData?.createCryptoKey, 'IamV1CryptoKey');
  const {
    data: getKeyData,
    loading: loadingNew,
    error: getError,
    startPolling,
    stopPolling,
  } = useGetCryptoKeyQuery({
    skip: !newKey?.name,
    variables: {
      name: newKey?.name!,
    },
  });
  const cryptoKey = getTypeOrNull(getKeyData?.cryptoKey, 'IamV1CryptoKey');
  React.useEffect(() => {
    if (cryptoKey?.state === IamV1CryptoKeyState.Creating) {
      startPolling(1000);
    } else {
      stopPolling();
    }
    if (cryptoKey?.state === IamV1CryptoKeyState.Created) {
      onChange(cryptoKey.name);
    }
  }, [cryptoKey]);

  const options: string[] = cryptoKeys?.cryptoKeys?.map(c => c.name) || [];

  const shouldCreateNew = (newValue: string) =>
    !options.includes(`cryptoKeys/${newValue}`) && !value;

  const handleCreate = async (newId: string) => {
    if (shouldCreateNew(newId)) {
      const response = await createCryptoKeyMutation({
        variables: {
          id: newId,
          kind: IamV1CryptoKeyKind.Snowflake,
        },
        awaitRefetchQueries: true,
        refetchQueries: [ListCryptoKeysDocument],
      });
      if (isType(response.data?.createCryptoKey, 'IamV1CryptoKey')) {
        onChange(response.data!.createCryptoKey.name);
      } else {
        alert.post({
          severity: 'error',
          message:
            response.data?.createCryptoKey?.message ?? 'Something went wrong',
        });
      }
    }
  };

  const handleChange = (option: string | null) => {
    if (option === null) {
      onChange('');
    } else if (_.isString(option)) {
      onChange(option);
    }
  };

  const selectedCryptoKey = cryptoKeys?.cryptoKeys?.find(f => f.name === value);

  const handleCopyKey = () => {
    if (!selectedCryptoKey) {
      return;
    }
    try {
      navigator.clipboard.writeText(
        selectedCryptoKey
          .publicKey!.replace('-----BEGIN PUBLIC KEY-----', '')
          .replace('-----END PUBLIC KEY-----', '')
          .replaceAll('\n', ''),
      );

      alert.post({
        severity: 'success',
        message: 'The public key was copied to the clipboard',
        display: 'transient',
      });
    } catch {
      alert.post({
        severity: 'error',
        message: 'Failed to copy the public key',
        display: 'transient',
      });
    }
  };

  const creating =
    createLoading ||
    loadingNew ||
    cryptoKey?.state === IamV1CryptoKeyState.Creating;

  return (
    <div>
      {createError && <Alert severity="error">{createError.message}</Alert>}
      {getError && <Alert severity="error">{getError.message}</Alert>}
      <CreatableAutocomplete<string>
        id="crypto-key-autocomplete"
        value={value}
        onChange={handleChange}
        onCreate={handleCreate}
        canCreate={shouldCreateNew}
        options={options}
        disabled={disabled || creating}
        loading={loading || creating}
        creating={creating}
        getOptionLabel={o => extractLastNameComponent(o) || ''}
        formatInputValue={StringUtils.kebabCase}
        TextFieldProps={{
          label: 'Crypto Key',
          ...textFieldProps,
        }}
      />
      <Box marginTop={1}>
        <Button
          variant="outlined"
          size="small"
          disabled={!selectedCryptoKey?.publicKey}
          onClick={handleCopyKey}
          startIcon={<FileCopyIcon />}
        >
          Copy public key
        </Button>
      </Box>
    </div>
  );
}
