import React from 'react';

import { MenuItem } from '@material-ui/core';

import {
  AttributeType,
  AttributeValue,
  CriterionAttribute,
  CriterionOption,
  SemanticType,
  ValueType,
  getSemanticTypeFromCriterionOption,
  isClosedRangeOp,
  isClosedRangeValue,
  isSetOp,
  isSetValue,
  isSingleValue,
} from '../../domain/targeting/targeting.model';
import { ChipInput } from './ChipInput';
import {
  ChipAutocomplete,
  ChipBooleanPicker,
  ChipCountryPicker,
  ChipDatePicker,
  ChipTextInput,
  ClosedRangeInput,
} from './CriteriaValueFields';
import { ChipDatetimePicker } from './CriteriaValueFields/ChipDatetimePicker';

type Props = {
  op: CriterionAttribute['op'];
  value: CriterionAttribute['value'];
  valueType: CriterionAttribute['attributeType'];
  onChange: (v: CriterionAttribute['value']) => void;
  disabled?: boolean;
  readOnly?: boolean;
  criterionOption?: CriterionOption;
};

export const TargetingField = React.memo(
  ({
    value,
    valueType,
    onChange,
    disabled,
    readOnly,
    op,
    criterionOption,
  }: Props) => {
    const handleChange = React.useCallback(
      (newValue?: AttributeValue) => {
        const getPayload = () => {
          if (isSetOp(op) && isSetValue(newValue)) {
            // array
            return newValue;
          } else if (isClosedRangeOp(op) && isClosedRangeValue(newValue)) {
            // range
            return {
              start: {
                value: newValue.start.value,
                inclusive: newValue.start.inclusive,
              },
              end: {
                value: newValue.end.value,
                inclusive: newValue.end.inclusive,
              },
            };
          } else if (isSingleValue(newValue) && newValue) {
            // singlevalue
            return newValue;
          }
          return '';
        };
        const payload = getPayload();
        onChange(payload);
      },
      [valueType, onChange],
    );

    const semanticType =
      criterionOption && getSemanticTypeFromCriterionOption(criterionOption);
    const type: AttributeType | SemanticType =
      semanticType || criterionOption?.type || valueType;

    if (value === null) {
      return null;
    }

    if (type === 'Country code') {
      let countryValue: ValueType | ValueType[] = null;
      if (isSetValue(value)) {
        countryValue = value || [];
      } else if (isSingleValue(value)) {
        countryValue = value || null;
      }
      return (
        <ChipCountryPicker
          value={countryValue}
          multiple={isSetOp(op)}
          onChange={handleChange}
          disabled={disabled}
          readOnly={readOnly}
        />
      );
    }

    if (type === 'Enum') {
      return (
        <ChipInput
          select
          value={isSetValue(value) ? value || [] : value || ''}
          onChange={e => handleChange(e.target.value as AttributeValue)}
          SelectProps={{ multiple: isSetOp(op) && isSetValue(value) }}
          disabled={disabled || readOnly}
          InputProps={{ readOnly }}
        >
          {criterionOption?.semanticType?.enumType?.values.map(enumValue => (
            <MenuItem key={enumValue.value} value={enumValue.value || ''}>
              {enumValue.value}
            </MenuItem>
          ))}
        </ChipInput>
      );
    }

    if (isClosedRangeOp(op) && isClosedRangeValue(value)) {
      let component = ChipTextInput;
      if (type === 'Date') {
        component = ChipDatePicker;
      } else if (type === 'Timestamp') {
        component = ChipDatetimePicker;
      }
      return (
        <ClosedRangeInput
          component={component}
          value={value}
          valueType={valueType}
          onChange={handleChange}
          readOnly={readOnly}
          disabled={disabled}
        />
      );
    }

    if (isSetOp(op) && isSetValue(value)) {
      return (
        <ChipAutocomplete
          valueType={valueType}
          value={value as string[]}
          onValueChange={handleChange}
          disabled={disabled}
          readOnly={readOnly}
          type={valueType === 'Number' ? 'number' : 'text'}
          multiple
        />
      );
    }

    if (isSingleValue(value)) {
      if (type === 'Boolean') {
        return (
          <ChipBooleanPicker
            value={value}
            handleChange={handleChange}
            disabled={disabled}
            readOnly={readOnly}
          />
        );
      }

      if (type === 'Date') {
        return (
          <ChipDatePicker
            value={value}
            onChange={handleChange}
            disabled={disabled}
            readOnly={readOnly}
          />
        );
      }

      if (type === 'Timestamp') {
        return (
          <ChipDatetimePicker
            value={value}
            onChange={handleChange}
            disabled={disabled}
            readOnly={readOnly}
          />
        );
      }

      return (
        <ChipTextInput
          onChange={handleChange}
          value={value}
          valueType={valueType}
          disabled={disabled}
          readOnly={readOnly}
        />
      );
    }
    return null;
  },
);
