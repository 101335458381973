import React from 'react';

import { Typography } from '@material-ui/core';

import { CodeSnippet } from '../../CodeSnippet/CodeSnippet';
import { f } from '../../CodeSnippet/format';

export const AndroidClientSnippets = () => {
  return (
    <>
      <Typography variant="body1" gutterBottom>
        Let's track the sample "tutorial-event" event from your application and
        make sure it gets published to our backend.
      </Typography>
      <CodeSnippet
        language="kotlin"
        showLineNumbers
        text={f(`
confidence.track(eventName = "tutorial-event", data = mapOf())
confidence.flush()
`)}
        showCopyCodeButton
      />
      <Typography variant="body1" gutterBottom>
        The flush call ensures the event is scheduled immediately for upload by
        the SDK.
      </Typography>
    </>
  );
};
