import { MetricType } from '../../../metricTypes';
import { useTimeWindowSection } from './TimeWindowSection';
import {
  createAverageMetricSections,
  editAverageMetricSections,
} from './averageMetricSections';
import {
  createConsumptionMetricSections,
  editConsumptionMetricSections,
} from './consumptionMetricSections';
import {
  createConversionMetricSections,
  editConversionMetricSections,
} from './conversionMetricSections';
import {
  createCtrMetricSections,
  editCtrMetricSections,
} from './ctrMetricSections';
import {
  aboutSection,
  missingValuesSection,
  suggestedUsageSection,
  varianceReductionSection,
} from './genericMetricSections';
import {
  createRatioMetricSections,
  editRatioMetricSections,
} from './ratioMetricSections';
import { MetricSection } from './types';

export * from './types';

const createSections: Record<MetricType, MetricSection[]> = {
  average: createAverageMetricSections,
  ratio: createRatioMetricSections,
  consumption: createConsumptionMetricSections,
  conversion: createConversionMetricSections,
  ctr: createCtrMetricSections,
  unknown: [],
};

const editSections: Record<MetricType, MetricSection[]> = {
  average: editAverageMetricSections,
  ratio: editRatioMetricSections,
  consumption: editConsumptionMetricSections,
  conversion: editConversionMetricSections,
  ctr: editCtrMetricSections,
  unknown: [],
};

export const getCreateSections = (type: MetricType): MetricSection[] => {
  const timeWindowSection = useTimeWindowSection();
  return [
    aboutSection,
    ...(createSections[type] || []),
    timeWindowSection,
    suggestedUsageSection,
  ];
};

export const getEditSections = (type: MetricType): MetricSection[] => {
  const timeWindowSection = useTimeWindowSection();
  return [
    aboutSection,
    ...(editSections[type] || []),
    timeWindowSection,
    suggestedUsageSection,
    varianceReductionSection,
    missingValuesSection,
  ];
};
