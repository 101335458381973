import React from 'react';

import { Box } from '@material-ui/core';

import {
  FlagsResolverAdminV1ResolvedFlagRuleDetailsRuleEvaluationReason,
  RuleDetailsFragment,
} from '@spotify-confidence/plugin-graphql';

import { RuleDetail } from './RuleDetail';

export function RuleDetails({
  ruleDetails,
  value,
}: {
  ruleDetails: RuleDetailsFragment[];
  value: any;
}) {
  const matchedRuleIndex = ruleDetails.findIndex(
    r =>
      r.ruleEvaluationReason ===
      FlagsResolverAdminV1ResolvedFlagRuleDetailsRuleEvaluationReason.RuleMatched,
  );
  return (
    <Box width="100%" display="flex" flexDirection="column">
      {ruleDetails.map((rule, idx) => (
        <RuleDetail
          rule={rule}
          value={value}
          isAfterMatched={idx > matchedRuleIndex && matchedRuleIndex >= 0}
          isLastRule={idx !== ruleDetails.length - 1}
        />
      ))}
    </Box>
  );
}
