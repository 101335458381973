import React from 'react';
import { InView } from 'react-intersection-observer';

import {
  Button,
  Link,
  ListItemText,
  Table,
  TableBody,
  TableCell,
  TableRow,
  Typography,
} from '@material-ui/core';
import Add from '@material-ui/icons/Add';
import ChevronRightRoundedIcon from '@material-ui/icons/ChevronRightRounded';

import {
  ActionBar,
  Breadcrumbs,
  ConfidenceWebsiteLink,
  FilterBar,
  FilterListEmptyState,
  FilterListProvider,
  Highlight,
  PageLayout,
  ResourceTableRow,
  StickyTableContainer,
  StickyTableHead,
  useDialog,
  useListFilter,
  useMeasureDimensions,
} from '@spotify-confidence/core-react';
import {
  getError,
  getTypeOrNull,
  useListOAuthAppsQuery,
} from '@spotify-confidence/plugin-graphql';
import { useCheckResourcePermissions } from '@spotify-confidence/plugin-permissions-react';

import { useRouteRef } from '@backstage/core-plugin-api';

import { oauthAppRouteRef, rootRouteRef } from '../../../routes';
import { CreateOauthApp } from './CreateOauthApp';

const OauthAppsList = ({
  topOffset,
  onCreate,
}: {
  topOffset: number;
  onCreate?: () => void;
}) => {
  const { searchQuery } = useListFilter();
  const { data, loading, error, fetchMore } = useListOAuthAppsQuery();
  const oAuthApps = getTypeOrNull(
    data?.oAuthApps,
    'IamV1ListOAuthAppsResponse',
  );
  const listOauthAppsError = getError(data?.oAuthApps);
  const filteredOAuthApps = (oAuthApps?.oauthApps || []).filter(user =>
    user.displayName.toLowerCase().includes(searchQuery.toLowerCase()),
  );

  if (filteredOAuthApps.length === 0 && !loading) {
    return (
      <FilterListEmptyState
        error={getError(data?.oAuthApps) || error}
        type="OAuth App"
        description={
          <>
            Create an OAuth App to allow third party applications to access
            Confidence on behalf of a user.{' '}
            <ConfidenceWebsiteLink
              route="/docs/api/iam/oauth"
              underline="always"
            >
              Learn more about OAuth Apps
            </ConfidenceWebsiteLink>
          </>
        }
        onCreate={onCreate}
      />
    );
  }

  return (
    <StickyTableContainer loading={loading} error={error ?? listOauthAppsError}>
      <Table>
        <StickyTableHead topOffset={topOffset}>
          <TableRow>
            <TableCell>Name</TableCell>
            <TableCell />
          </TableRow>
        </StickyTableHead>
        <TableBody>
          {filteredOAuthApps.map(app => (
            <ResourceTableRow
              key={app.name}
              name={app.name}
              data-testid={app.displayName}
              routeRef={oauthAppRouteRef}
            >
              <TableCell>
                <ListItemText
                  primary={
                    searchQuery ? (
                      <Highlight highlight={searchQuery}>
                        {app.displayName}
                      </Highlight>
                    ) : (
                      app.displayName
                    )
                  }
                />
              </TableCell>
              <TableCell width={50}>
                <ChevronRightRoundedIcon />
              </TableCell>
            </ResourceTableRow>
          ))}
        </TableBody>
      </Table>
      {data && (
        <InView
          onChange={async inView => {
            if (inView && oAuthApps?.nextPageToken) {
              await fetchMore({
                variables: {
                  pageToken: oAuthApps?.nextPageToken,
                },
              });
            }
          }}
        />
      )}
    </StickyTableContainer>
  );
};

export const OauthAppsIndexPage = () => {
  const adminRoute = useRouteRef(rootRouteRef);
  const { openDialog } = useDialog();
  const [ref, { height }] = useMeasureDimensions<HTMLDivElement>();

  const { allowed } = useCheckResourcePermissions({
    can: 'create_oauth_app',
    name: 'account',
  });

  const onCreate = () => {
    openDialog({ content: <CreateOauthApp /> });
  };
  return (
    <FilterListProvider storageKey="oauth-apps">
      <PageLayout
        narrow
        title="OAuth Apps"
        headerBreadcrumbs={
          <Breadcrumbs>
            <Link href={adminRoute()}>Admin</Link>
            <Typography>OAuth Apps</Typography>
          </Breadcrumbs>
        }
      >
        <ActionBar ref={ref}>
          <FilterBar />
          <Button
            startIcon={<Add />}
            onClick={onCreate}
            variant="contained"
            color="primary"
            size="small"
            data-testid="create-oauth-app-button"
            disabled={!allowed}
          >
            Create
          </Button>
        </ActionBar>
        <OauthAppsList
          topOffset={height}
          onCreate={allowed ? onCreate : undefined}
        />
      </PageLayout>
    </FilterListProvider>
  );
};
