import React from 'react';

import {
  Box,
  CircularProgress,
  Collapse,
  InputAdornment,
  InputLabel,
  TextFieldProps as MuiTextFieldProps,
  Paper,
  TextField,
} from '@material-ui/core';
import {
  Alert,
  AlertTitle,
  Autocomplete,
  AutocompleteProps,
} from '@material-ui/lab';

import { useAutocompleteLazyOptions } from '../../hooks';
import { AutocompleteEmptyState } from '../ConfidenceAutocomplete/AutocompleteEmptyState';

// TODO: refactor this to not require so many props for the empty state
export type RequiredResourceAutocompleteProps<
  T,
  Multiple extends boolean | undefined,
  DisableClearable extends boolean | undefined,
  FreeSolo extends boolean | undefined,
> = React.PropsWithChildren<
  Omit<
    AutocompleteProps<T, Multiple, DisableClearable, FreeSolo>,
    'open' | 'disablePortal' | 'disableCloseOnSelect' | 'renderInput'
  > & {
    hasFilter?: boolean;
    error?: Error;
    label?: MuiTextFieldProps['label'];
    placeholder?: MuiTextFieldProps['placeholder'];
    helperText?: MuiTextFieldProps['helperText'];
    required?: MuiTextFieldProps['required'];
    errorTitle?: string;
    emptyTitle?: string;
    emptyDescription?: React.ReactNode;
    createText?: string;
    createLink?: string;
    refetch?: () => Promise<any>;
    onOptionsScrollEnd?: () => void;
  }
>;

export const RequiredResourceAutocomplete = <
  T = any,
  Multiple extends boolean | undefined = undefined,
  DisableClearable extends boolean | undefined = undefined,
  FreeSolo extends boolean | undefined = undefined,
>({
  error,
  loading,
  hasFilter,
  options = [],
  label,
  required,
  placeholder,
  helperText,
  errorTitle = 'Unable to fetch resources',
  emptyTitle = 'There are no resources to choose from',
  emptyDescription,
  createText = 'Create resource',
  createLink,
  onOptionsScrollEnd,
  refetch,
  ...autocompleteProps
}: RequiredResourceAutocompleteProps<
  T,
  Multiple,
  DisableClearable,
  FreeSolo
>) => {
  const lazyOptionsProps = useAutocompleteLazyOptions({ onOptionsScrollEnd });
  const hasError = Boolean(error);
  const hasNoOptions = Boolean(
    !hasError && !hasFilter && !loading && !options.length,
  );

  const [refetching, setRefetching] = React.useState(false);

  const handleRefetch = React.useCallback(async () => {
    if (refetch && document.visibilityState === 'visible') {
      setRefetching(true);
      await refetch();
      setRefetching(false);
    }
  }, [refetch]);

  React.useEffect(() => {
    document.addEventListener('visibilitychange', handleRefetch);
    return () => {
      document.removeEventListener('visibilitychange', handleRefetch);
    };
  }, [handleRefetch]);

  return (
    <>
      <Collapse in={hasError} unmountOnExit>
        <Alert severity="error">
          <AlertTitle>{errorTitle}</AlertTitle>
          {error?.message}
        </Alert>
      </Collapse>
      <Collapse in={hasNoOptions} unmountOnExit>
        {label && (
          <InputLabel required={required} disabled>
            {label}
          </InputLabel>
        )}
        <Box marginTop={1}>
          <AutocompleteEmptyState
            fullWidth={autocompleteProps.fullWidth}
            title={emptyTitle}
            description={emptyDescription}
            actionLabel={createText}
            actionLink={createLink}
          />
        </Box>
      </Collapse>
      <Collapse in={!hasNoOptions}>
        <Autocomplete<T, Multiple, DisableClearable, FreeSolo>
          options={options}
          loading={loading || refetching}
          disabled={hasError || hasNoOptions}
          PaperComponent={props => <Paper {...props} />}
          renderInput={params => (
            <TextField
              error={hasError}
              margin="dense"
              {...params}
              InputProps={{
                ...params.InputProps,
                endAdornment: (
                  <>
                    {params.InputProps.endAdornment}
                    {loading && (
                      <InputAdornment position="end">
                        <CircularProgress size="1em" color="inherit" />
                      </InputAdornment>
                    )}
                  </>
                ),
              }}
              label={label}
              placeholder={placeholder}
              required={required}
              helperText={helperText}
              variant="outlined"
            />
          )}
          {...lazyOptionsProps}
          {...autocompleteProps}
        />
      </Collapse>
    </>
  );
};
