/* eslint-disable no-nested-ternary, new-cap */
import React from 'react';
import { useDebounce } from 'react-use';

import { CircularProgress, DialogActions, TextField } from '@material-ui/core';
import CancelIcon from '@material-ui/icons/Cancel';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';

import {
  DialogBody,
  DialogForm,
  DialogHeader,
  FormSubmitButtons,
  extractLastNameComponent,
  useAlert,
  useDialog,
  useNavigate,
} from '@spotify-confidence/core-react';
import {
  Query,
  evictTypeFields,
  getTypeOrNull,
  isType,
} from '@spotify-confidence/plugin-graphql';
import {
  useCreateEventMutation,
  useEventExistLazyQuery,
} from '@spotify-confidence/plugin-graphql';
import {
  IdentityAutocomplete,
  useDefaultOwner,
} from '@spotify-confidence/plugin-permissions-react';

import { useRouteRef } from '@backstage/core-plugin-api';

import { eventRouteRef } from '../../../routes';

const MIN_LENGTH = 4;
const MAX_LENGTH = 63;

const NAME_EXIST_ERROR = (message: string) =>
  message.endsWith('already existed');

const EVENT_KEY_PATTERN = /^[a-z0-9\-]+$/g;

const validateName = (name: string) => {
  if (!name || name === '') {
    return 'Event type name is required';
  }

  if (!name.match(EVENT_KEY_PATTERN)) {
    return "Allowed characters are a to z, 0 to 9 and '-'";
  }

  if (name.length < MIN_LENGTH) {
    return `Name is too short, min length is ${MIN_LENGTH} characters`;
  }

  if (name.length > MAX_LENGTH) {
    return `Name is too long, max length is ${MAX_LENGTH} characters`;
  }

  return null;
};

export const CreateEventDialog = () => {
  const { closeDialog } = useDialog();
  const eventPage = useRouteRef(eventRouteRef);
  const alert = useAlert();
  const navigate = useNavigate();
  const [eventKey, setEventKey] = React.useState('');
  const [owner, setOwner, { loading: loadingUserIdentity }] = useDefaultOwner();
  const name = `eventDefinitions/${eventKey}`;

  const [dirty, touch] = React.useState(false);

  const [validationError, setValidationError] = React.useState<string | null>(
    null,
  );

  const [getEvent, { loading: checking, error: checkError, data: checkData }] =
    useEventExistLazyQuery({
      fetchPolicy: 'no-cache',
    });

  const handleChange = (value: string) => {
    setEventKey(value.replace(/\s+/g, '-').toLocaleLowerCase());
    touch(true);
  };

  useDebounce(
    () => {
      // if the user has not inputted anything yet we do not
      // validate the event name
      if (!dirty) return;

      const message = validateName(eventKey);
      setValidationError(message);

      if (message) return;

      getEvent({ variables: { name } });
    },
    400,
    [eventKey],
  );

  const [createEvent, { loading: submitting, error: submitError }] =
    useCreateEventMutation({
      update: cache => {
        evictTypeFields<Query>(cache, 'eventDefinitions');
      },
      onCompleted: data => {
        if (
          isType(data?.createEventDefinition, 'EventsAdminV1EventDefinition')
        ) {
          closeDialog();
          navigate(
            eventPage({
              id: extractLastNameComponent(data.createEventDefinition!.name)!,
            }),
          );
        } else {
          alert.post({
            message:
              data?.createEventDefinition?.message ?? 'Something went wrong',
            severity: 'error',
          });
        }
      },
    });

  const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    createEvent({
      variables: {
        event: {
          owner: owner,
        },
        eventId: eventKey,
      },
    });
  };

  // If we checked and know a event exist with the current key.
  const existingEvent = Boolean(
    getTypeOrNull(checkData?.eventDefinition, 'EventsAdminV1EventDefinition')
      ?.name,
  );

  // If we know a event does not exist with the current key.
  const doesNotExist = checkError && checkError.message.endsWith(' not found');

  // If we know that the event exists already, either by trying to submit
  // or by checking.
  const alreadyExists =
    (submitError && NAME_EXIST_ERROR(submitError.message)) || existingEvent;

  const error =
    validationError ??
    submitError?.message ??
    // Check errors (that are not 404s)
    (!doesNotExist && checkError?.message) ??
    (alreadyExists && 'Event already exists') ??
    null;

  const endAdornment = validationError ? (
    <CancelIcon color="error" />
  ) : checking ? (
    <CircularProgress size={14} />
  ) : alreadyExists ? (
    <CancelIcon color="error" />
  ) : doesNotExist ? (
    <CheckCircleIcon htmlColor="green" />
  ) : null;

  return (
    <DialogForm onSubmit={handleSubmit} data-testid="create-event-form">
      <DialogHeader
        title="Create event"
        subTitle="Enter the name of the event. Good event keys are
          descriptive and short, like 'page-view' or 'navigation'."
      />
      <DialogBody>
        <TextField
          name="createevent"
          required
          variant="outlined"
          placeholder="Event name"
          margin="dense"
          value={eventKey}
          onChange={e => handleChange(e.target.value)}
          error={Boolean(error)}
          fullWidth
          disabled={submitting}
          InputProps={{
            endAdornment,
          }}
          // empty string is things do not jump around
          helperText={error || <div>&nbsp;</div>}
          autoFocus
        />
        <IdentityAutocomplete
          value={owner}
          onChange={(_e, newIdentity) => {
            setOwner(newIdentity);
          }}
          label="Owner"
          data-testid="owner"
          required
          loading={loadingUserIdentity}
        />
      </DialogBody>
      <DialogActions>
        <FormSubmitButtons
          onCancel={closeDialog}
          disabled={alreadyExists || eventKey.length === 0 || !owner}
          loading={submitting}
          label="Create"
        />
      </DialogActions>
    </DialogForm>
  );
};
