import React from 'react';
import { useAsyncFn } from 'react-use';

import { Box, Paper } from '@material-ui/core';

import { FormFieldsBox, WizardStepper } from '@spotify-confidence/core-react';
import { EventSchemaEntryFragment } from '@spotify-confidence/plugin-graphql';
import {
  IdentityAutocomplete,
  useDefaultOwner,
} from '@spotify-confidence/plugin-permissions-react';

import { SchemaEditor } from '../../schema/components/SchemaEditor';
import { SchemaProvider } from '../../schema/components/SchemaEditor/SchemaContext';
import { getHasInvalidChild } from '../../schema/domain/schema.helpers';
import { DraftEvent } from '../hooks';

/**
 * Dialog component for creating an event from a draft. Should be used with a full screen dialog.
 *
 * @param {onCreate} Callback to create the event. The callback should return a
 *    promise that resolves when the event is created.
 */
export function CreateEventFromDraftDialog({
  draftEvent,
  onCreate,
  onCancel,
}: {
  draftEvent: DraftEvent;
  onCreate?: (
    owner: string,
    schema: EventSchemaEntryFragment[],
  ) => Promise<void>;
  onCancel?: () => void;
}) {
  const [owner, setOwner, { loading: loadingUserIdentity }] = useDefaultOwner();
  const [schema, updateSchema] = React.useState(draftEvent.schema);

  const [{ loading: creating, error }, create] = useAsyncFn(
    async () => onCreate?.(owner!, schema),
    [owner, schema],
  );

  const handleSubmit = () => {
    create();
  };
  const invalidChildren = getHasInvalidChild(schema);

  return (
    <WizardStepper
      error={error}
      onSubmit={handleSubmit}
      disableNext={invalidChildren}
      onClose={onCancel}
      loading={creating}
      submitLabel="Create"
      width="lg"
      steps={[
        {
          name: `Create "${draftEvent.id}"`,
          description: (
            <>
              Review and modify the schema. Create the event to start receiving
              and storing the data.
            </>
          ),
          content: (
            <FormFieldsBox>
              <Box maxWidth={400}>
                <IdentityAutocomplete
                  value={owner}
                  onChange={(_event, newIdentity) => {
                    setOwner(newIdentity);
                  }}
                  loading={loadingUserIdentity}
                  label="Owner"
                  data-testid="owner"
                  required
                />
              </Box>
              <Paper variant="outlined">
                <SchemaProvider schema={schema} onChange={updateSchema}>
                  <SchemaEditor />
                </SchemaProvider>
              </Paper>
            </FormFieldsBox>
          ),
        },
      ]}
    />
  );
}
