import _ from 'lodash';

import { WeightError, WeightedItem } from './types';
import {
  changeItemWeightAndDistributeRemaining,
  distributeWeightEvenly,
} from './utils';

type Settings<T extends WeightedItem> = {
  maxTotal: number;
  onChange?: (items: T[]) => void;
  distributeRestOnChange?: boolean;
  shouldUseMax?: boolean;
};

export function useWeightedItems<T extends WeightedItem>(
  items: T[],
  { onChange, maxTotal, distributeRestOnChange, shouldUseMax }: Settings<T>,
) {
  const onItemWeightChange = (index: number) => (newWeight: number) => {
    if (distributeRestOnChange) {
      onChange?.(
        changeItemWeightAndDistributeRemaining(
          items,
          index,
          newWeight,
          maxTotal,
        ),
      );
    } else {
      const newItems = [...items];
      newItems[index] = { ...newItems[index], weight: newWeight };
      onChange?.(newItems);
    }
  };

  const splitWeightEvenly = () => {
    const newItems = distributeWeightEvenly(items, maxTotal);
    onChange?.(newItems);
  };

  const getItemsWithWeight = () => {
    return items.filter(item => (item.weight || 0) > 0);
  };

  let error;
  const totalWeight = _.sum(items.map(i => i.weight));

  if (totalWeight > maxTotal) {
    error = WeightError.MAX_TOTAL_EXCEEDED;
  } else if (
    (shouldUseMax || distributeRestOnChange) &&
    totalWeight < maxTotal
  ) {
    error = WeightError.MAX_TOTAL_NOT_REACHED;
  } else if (totalWeight < 0) {
    error = WeightError.LESS_THAN_0;
  }

  return { onItemWeightChange, splitWeightEvenly, getItemsWithWeight, error };
}
