import React, { useCallback } from 'react';
import { InView } from 'react-intersection-observer';

import { useListFilter } from '@spotify-confidence/core-react';
import {
  IamV1PolicyInput,
  PolicyFragment,
  PolicyFragmentDoc,
  getError,
  getTypeOrNull,
  isError,
  isType,
  useCreatePolicyMutation,
  useDeletePolicyMutation,
  usePoliciesQuery,
  useUpdatePolicyMutation,
} from '@spotify-confidence/plugin-graphql';

/**
 * Companion hook for the PolicyIndexPage component.
 */
export function usePolicies() {
  const { searchQuery } = useListFilter();
  const pageSize = 25;

  // Queries
  const { data, loading, error, fetchMore } = usePoliciesQuery({
    variables: {
      pageSize,
    },
  });

  const { policies, nextPageToken } = getTypeOrNull(
    data?.policies,
    'IamV1ListPoliciesResponse',
  ) ?? { policies: [] };

  const filteredPolicies = policies?.filter(policy =>
    // FIXME: add role
    policy.identities?.some(
      identity =>
        isType(identity, 'IamV1Identity') &&
        identity.displayName.toLowerCase().includes(searchQuery.toLowerCase()),
    ),
  );

  // Mutations
  const [createPolicyMutation] = useCreatePolicyMutation();
  const [updatePolicyMutation] = useUpdatePolicyMutation();
  const [deletePolicyMutation] = useDeletePolicyMutation();

  const createPolicy = useCallback(async (policy: IamV1PolicyInput) => {
    const response = await createPolicyMutation({
      variables: {
        policy,
      },
      update(cache, { data: result }) {
        cache.modify({
          fields: {
            policies(existing = { policies: [] }) {
              const addedPolicy = result?.createPolicy;
              return {
                ...existing,
                policies: [...existing?.policies, addedPolicy],
              };
            },
          },
        });
      },
    });
    if (isError(response.data?.createPolicy)) {
      throw getError(response.data?.createPolicy);
    }
  }, []);

  const updatePolicy = useCallback(
    async (policy: Partial<IamV1PolicyInput>, updateMask?: string) => {
      const response = await updatePolicyMutation({
        variables: {
          policy,
          updateMask,
        },
        // no need to update here because the policy is already in the cache
        // and it gets updated based on the result of the mutation
      });
      if (isError(response.data?.updatePolicy)) {
        throw getError(response.data?.updatePolicy);
      }
    },
    [],
  );

  const deletePolicy = useCallback(async (name: string) => {
    const response = await deletePolicyMutation({
      variables: {
        name,
      },
      update(cache) {
        cache.modify({
          fields: {
            policies(existing = {}) {
              return {
                ...existing,
                policies: [
                  // filter out the policy from the case
                  ...existing.policies?.filter(
                    (p: any) =>
                      cache.readFragment<PolicyFragment>({
                        id: cache.identify(p),
                        fragment: PolicyFragmentDoc,
                        fragmentName: 'Policy',
                      })?.name !== name,
                  ),
                ],
              };
            },
          },
        });
      },
    });
    if (isError(response.data?.deletePolicy)) {
      throw getError(response.data?.deletePolicy);
    }
  }, []);

  // Components
  const LoadMore = () => (
    <InView
      onChange={async inView => {
        if (inView && nextPageToken) {
          await fetchMore({
            variables: {
              pageToken: nextPageToken,
            },
          });
        }
      }}
    />
  );

  return {
    // data
    policies: filteredPolicies,
    error: getError(data?.policies) || error,
    loading,
    // operations
    createPolicy,
    updatePolicy,
    deletePolicy,
    // components
    LoadMore,
  };
}
