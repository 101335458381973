import {
  getError,
  getTypeOrNull,
  useDeleteGroupMutation,
  useGetGroupQuery,
} from '@spotify-confidence/plugin-graphql';

export const useGroup = (name: string) => {
  const { data, loading, error } = useGetGroupQuery({
    variables: {
      name,
    },
  });

  const group = getTypeOrNull(data?.group, 'IamV1Group');
  const groupError = getError(data?.group);
  const [deleteGroupMutation] = useDeleteGroupMutation();

  const deleteGroup = async () =>
    deleteGroupMutation({
      variables: {
        name,
      },
      update: cache => {
        cache.evict({
          id: cache.identify({
            __typename: 'IamV1Group',
            name,
          }),
        });
        cache.gc();
      },
    });

  return {
    group,
    loading,
    error: error ?? groupError,
    deleteGroup,
  };
};
