import loadable from '@loadable/component';

const exposureComponentsChunk = () =>
  import(
    /* webpackChunkName: "metrics-exposure-components" */ './components.chunk'
  );

export const WorkflowInstanceExposureCell = loadable(() =>
  exposureComponentsChunk().then(m => m.WorkflowInstanceExposureCell),
);

export const ExposureModuleSidebarSection = loadable(() =>
  exposureComponentsChunk().then(m => m.ExposureModuleSidebarSection),
);

export const WorkflowInstanceExposureHeaderCell = loadable(() =>
  exposureComponentsChunk().then(m => m.WorkflowInstanceExposureHeaderCell),
);

export * from './types';
