import React from 'react';

import { Collapse } from '@material-ui/core';

import {
  ConfidenceWebsiteLink,
  FormFieldsBox,
  useDisplayNames,
} from '@spotify-confidence/core-react';
import {
  MetricsV1AggregationType as AggregationType,
  MetricsV1MetricRatioMetricSpec as RatioSpec,
} from '@spotify-confidence/plugin-graphql';

import { AggregationTypeSelect } from './Aggregation/AggregationTypeSelect';
import { ValueCapInput } from './Aggregation/ValueCapInput';
import { MeasurementSelect } from './MeasurementSelect';

type RatioFormProps = {
  value: RatioSpec;
  entity?: string;
  factTable: string;
  onChange: (newRatioSpec: RatioSpec) => void;
};

export const RatioForm = ({
  value,
  entity = '',
  factTable,
  onChange,
}: RatioFormProps) => {
  const { displayNames } = useDisplayNames();
  const entityName = displayNames.get(entity) || 'entity';
  const handleChange = (changedFields: Partial<RatioSpec>) => {
    onChange({ ...value, ...changedFields });
  };

  return (
    <FormFieldsBox>
      <MeasurementSelect
        required={!value.denominator?.name}
        label="Numerator"
        value={value?.numerator?.name || ''}
        factTable={factTable}
        name="numerator"
        disabledOptions={
          value?.denominator?.name !== undefined
            ? [value.denominator.name]
            : undefined
        }
        onChange={measurementName => {
          handleChange(
            measurementName
              ? {
                  numerator: {
                    name: measurementName,
                  },
                }
              : {
                  numerator: undefined,
                  numeratorAggregation: {
                    type: AggregationType.AggregationTypeCount,
                  },
                },
          );
        }}
        disabled={!factTable}
        helperText="The value to divide."
      />

      <Collapse in={!!value?.numerator?.name} unmountOnExit>
        <AggregationTypeSelect
          label="Numerator method"
          data-testid="numerator-method-select"
          entityName={entityName}
          measurementName={value?.numerator?.name}
          value={value.numeratorAggregation.type}
          onChange={newType => {
            handleChange({
              numeratorAggregation: {
                ...value.numeratorAggregation,
                type: newType,
              },
            });
          }}
          disabled={!value?.numerator?.name}
          helperText={
            <>
              How to aggregate {value?.numerator?.name || 'the numerator'} per{' '}
              {entityName}.{' '}
              <ConfidenceWebsiteLink
                route="/docs/metrics#within-entity-aggregation"
                underline="always"
              >
                Learn more.
              </ConfidenceWebsiteLink>
            </>
          }
        />
      </Collapse>
      <ValueCapInput
        value={value.numeratorAggregation.cap}
        valueLabel="numerator values"
        onChange={newValueCap => {
          handleChange({
            numeratorAggregation: {
              ...value.numeratorAggregation,
              cap: newValueCap ?? null,
            },
          });
        }}
        entityName={displayNames.get(entity)}
        short
      />

      <MeasurementSelect
        required={!value.numerator?.name}
        label="Denominator"
        value={value?.denominator?.name || ''}
        factTable={factTable}
        name="denominator"
        disabledOptions={
          value?.numerator?.name !== undefined
            ? [value.numerator.name]
            : undefined
        }
        onChange={measurementName => {
          handleChange(
            measurementName
              ? {
                  denominator: {
                    name: measurementName,
                  },
                }
              : {
                  denominator: undefined,
                  denominatorAggregation: {
                    type: AggregationType.AggregationTypeCount,
                  },
                },
          );
        }}
        disabled={!factTable}
        helperText="The value to divide by."
      />

      <Collapse in={!!value?.denominator?.name} unmountOnExit>
        <AggregationTypeSelect
          label="Denominator method"
          data-testid="denominator-method-select"
          entityName={entityName}
          measurementName={value?.denominator?.name}
          value={value.denominatorAggregation.type}
          onChange={newType => {
            handleChange({
              denominatorAggregation: {
                ...value.denominatorAggregation,
                type: newType,
              },
            });
          }}
          disabled={!value?.denominator?.name}
          helperText={
            <>
              How to aggregate {value?.denominator?.name || 'the denominator'}{' '}
              per {entityName}.{' '}
              <ConfidenceWebsiteLink
                route="/docs/metrics#within-entity-aggregation"
                underline="always"
              >
                Learn more.
              </ConfidenceWebsiteLink>
            </>
          }
        />
      </Collapse>
      <ValueCapInput
        value={value.denominatorAggregation.cap}
        valueLabel="denominator values"
        onChange={newValueCap => {
          handleChange({
            denominatorAggregation: {
              ...value.denominatorAggregation,
              cap: newValueCap ?? null,
            },
          });
        }}
        entityName={displayNames.get(entity)}
        short
      />
    </FormFieldsBox>
  );
};
