import React from 'react';

import { Button, MenuItem } from '@material-ui/core';
import Add from '@material-ui/icons/Add';

import { ContextMenu } from '@spotify-confidence/core-react';
import {
  MetricsV1ColumnType,
  getTypeOrNull,
  useGetFactTableColumnsQuery,
} from '@spotify-confidence/plugin-graphql';

export const AddCriterionActions = ({
  factTableName,
  onAddSet,
  onAdd,
}: {
  factTableName?: string;
  onAddSet?: () => void;
  onAdd: (col: {
    name: string;
    type: MetricsV1ColumnType | null | undefined;
  }) => void;
}) => {
  const hasFactTable = factTableName && factTableName !== '';
  const { data } = useGetFactTableColumnsQuery({
    variables: { name: factTableName! },
    skip: !hasFactTable,
  });
  const factTable = getTypeOrNull(data?.factTable, 'MetricsV1FactTable');
  const column = [
    ...(factTable?.dimensions ?? []).flatMap(d => ({
      name: d.name,
      type: d.type,
    })),
    ...(factTable?.measures ?? []).flatMap(m => ({
      name: m.column.name,
      type: m.column.type,
    })),
  ]
    // TODO: add support for timestamps
    .filter(
      c =>
        !(
          c.type === MetricsV1ColumnType.ColumnTypeTimestamp ||
          c.type === MetricsV1ColumnType.ColumnTypeTimestampWithoutTimezone
        ),
    );
  const hasColumns = column.length !== 0;

  return (
    <>
      <ContextMenu
        closeOnSelect
        renderButton={p => (
          <Button
            {...p}
            disabled={!factTableName || !hasColumns}
            startIcon={<Add />}
            size="small"
            variant="text"
          >
            Add attribute
          </Button>
        )}
        renderMenu={() =>
          column.map(option => (
            <MenuItem key={option.name} divider onClick={() => onAdd(option)}>
              {option.name}
            </MenuItem>
          ))
        }
      />
      {onAddSet && (
        <Button
          disabled={!factTableName || !hasColumns}
          onClick={() => onAddSet()}
          startIcon={<Add />}
          size="small"
          variant="text"
        >
          Add set
        </Button>
      )}
    </>
  );
};
