import {
  solidBlack0,
  solidWhite200,
  spotifyBlack7,
  spotifyGreen157,
} from '@spotify-internal/encore-foundation';
import '@spotify-internal/encore-web/css/encore-dark-theme.css';

import { darkTheme } from '@backstage/theme';

import { buildEncoreTheme } from './buildEncoreTheme';

export const encoreDarkTheme = buildEncoreTheme({
  type: 'dark',
  containedHover: '#F6F6F6',
  outlinedHover: '#1A1A1A',
  outlinedResting: '#A7A7A7',
  activeRating: spotifyGreen157,
  snackbarBackground: '#242424',
  palette: {
    secondary: {
      main: solidWhite200,
      light: solidWhite200,
      dark: '#F6F6F6',
      contrastText: solidBlack0,
    },
    divider: 'rgba(255,255,255,0.082)',
    navigation: {
      ...darkTheme.palette.navigation,
      background: spotifyBlack7,
      color: '#A6A6A6',
      selectedColor: solidWhite200,
      submenu: {
        background: spotifyBlack7,
      },
    },
    text: {
      primary: solidWhite200,
      secondary: '#A6A6A6',
      disabled: '#727272',
      hint: '#A7A7A7',
    },
    background: {
      paper: spotifyBlack7,
      default: spotifyBlack7,
    },
    action: {
      hover: '#2A2A2A',
      hoverOpacity: 0.3,
      disabled: '#727272',
      disabledBackground: '#292929',
      active: '#1A1A1A',
      focus: spotifyBlack7,
    },
  },
});
