import React, { HTMLProps } from 'react';

import {
  Backdrop,
  CircularProgress,
  alpha,
  makeStyles,
} from '@material-ui/core';

type LoadingOverlayProps = HTMLProps<HTMLDivElement> & {
  loading?: boolean;
  height?: number;
};

const useStyles = makeStyles(theme => ({
  backdropContainer: {
    position: 'absolute',
    top: 0,
    bottom: 0,
    left: 0,
    right: 0,
    pointerEvents: 'none',
    minHeight: 100,
  },
  backdrop: {
    position: 'sticky',
    height: 'min(100vh, 100%)',
    background: alpha(theme.palette.background.default, 0.7),
    zIndex: 0,
  },
  spinner: {
    position: 'sticky',
    transition: theme.transitions.create('bottom'),
  },
}));

export const LoadingOverlay = ({
  loading = false,
  height,
  ...props
}: LoadingOverlayProps) => {
  const classes = useStyles();

  return (
    <div className={classes.backdropContainer} {...props}>
      <Backdrop open={loading} className={classes.backdrop}>
        <CircularProgress
          className={classes.spinner}
          style={{
            bottom: height ? `${height / 2}px` : undefined,
          }}
        />
      </Backdrop>
    </div>
  );
};
