import React from 'react';
import { InView } from 'react-intersection-observer';
import { useNavigate } from 'react-router-dom';

import {
  Box,
  LinearProgress,
  List,
  ListItem,
  ListItemText,
  TextField,
} from '@material-ui/core';
import { Alert } from '@material-ui/lab';

import {
  DateUtils,
  ListEmptyState,
  extractLastNameComponent,
} from '@spotify-confidence/core-react';
import {
  getTypeOrNull,
  useGetWorkflowInstancesQuery,
} from '@spotify-confidence/plugin-graphql';

import { useRouteRef } from '@backstage/core-plugin-api';

import { workflowInstanceOverviewRouteRef } from '../../../routes';

export const WorkflowInstances = ({
  workflowName,
}: {
  workflowName: string;
}) => {
  const navigate = useNavigate();
  const workflowInstanceOverviewRoute = useRouteRef(
    workflowInstanceOverviewRouteRef,
  );

  const [query, setQuery] = React.useState<string>('');
  const filter = query !== '' ? `displayName:${query}*` : '';
  const { data, loading, error, fetchMore } = useGetWorkflowInstancesQuery({
    variables: {
      parent: workflowName,
      filter,
    },
  });
  const workflowInstances = getTypeOrNull(
    data?.workflowInstances,
    'WorkflowV1ListWorkflowInstancesResponse',
  );
  const workflowInstancesError = getTypeOrNull(
    data?.workflowInstances,
    'Error',
  )?.message;
  const instances = workflowInstances?.workflowInstances || [];

  return (
    <>
      <TextField
        value={query}
        fullWidth
        onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
          setQuery(e.target.value)
        }
        placeholder="Search"
        name="instances-search"
      />
      {error && <Alert severity="error">{error.message}</Alert>}
      {!loading && instances.length === 0 && (
        <ListEmptyState
          title="No instances available"
          description="No workflow instances have been created yet."
        />
      )}
      {loading && <LinearProgress />}
      <Box mt={2}>
        {workflowInstancesError && (
          <Alert severity="error">{workflowInstancesError}</Alert>
        )}
        <List>
          {instances.map(instance => (
            <ListItem
              data-testid={instance.displayName}
              key={instance.name}
              button
              onClick={() =>
                navigate(
                  workflowInstanceOverviewRoute({
                    id: extractLastNameComponent(workflowName)!,
                    instance: extractLastNameComponent(instance.name)!,
                  }),
                )
              }
            >
              <ListItemText
                primary={instance.displayName}
                secondary={`${
                  instance.state
                } • Workflow ${extractLastNameComponent(
                  getTypeOrNull(
                    instance.workflowVersion,
                    'WorkflowV1WorkflowVersion',
                  )?.name,
                )} • Updated ${DateUtils.xAgo(new Date(instance.updateTime))}`}
              />
            </ListItem>
          ))}
        </List>
        <InView
          onChange={async inView => {
            const nextPageToken = workflowInstances?.nextPageToken;
            if (inView && nextPageToken) {
              await fetchMore({
                variables: {
                  pageToken: nextPageToken,
                },
              });
            }
          }}
        />
      </Box>
    </>
  );
};
