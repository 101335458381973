import React, { useCallback } from 'react';

import {
  Button,
  Table,
  TableBody,
  TableCell,
  TableRow,
  Typography,
} from '@material-ui/core';
import Add from '@material-ui/icons/Add';

import {
  ActionBar,
  Breadcrumbs,
  ConfirmDialog,
  FilterBar,
  FilterListEmptyState,
  FilterListProvider,
  PageLayout,
  StickyTableContainer,
  StickyTableHead,
  useDialog,
  useMeasureDimensions,
} from '@spotify-confidence/core-react';
import {
  IdentityFragment,
  PolicyFragment,
  RoleFragment,
  isType,
} from '@spotify-confidence/plugin-graphql';
import { useCheckResourcePermissions } from '@spotify-confidence/plugin-permissions-react';

import { Link } from '@backstage/core-components';
import { useRouteRef } from '@backstage/core-plugin-api';

import { rootRouteRef } from '../../../routes';
import { PolicyDialog } from '../PolicyDialog/PolicyDialog';
import { PolicyTableRow } from './PolicyTableRow';
import { usePolicies } from './usePolicies';

const PolicyPageContent = () => {
  const { openDialog } = useDialog();

  const [actionBarRef, { height: actionBarHeight }] =
    useMeasureDimensions<HTMLDivElement>();

  const {
    policies,
    loading,
    error,
    createPolicy,
    updatePolicy,
    deletePolicy,
    LoadMore,
  } = usePolicies();
  const { allowed } = useCheckResourcePermissions({
    name: 'account',
    can: 'create_iam_policy',
  });

  const handleCreate = useCallback(() => {
    openDialog({
      content: (
        <PolicyDialog
          title="Create policy"
          action="Create"
          value={{ roles: [], identities: [] }}
          onAction={async ({ roles, identities }) => {
            await createPolicy({
              roles: roles.map(role => role.name),
              identities,
            });
          }}
        />
      ),
    });
  }, []);

  const handleEdit = useCallback((policy: PolicyFragment) => {
    const roles = policy.roles.filter(r =>
      isType(r, 'IamV1Role'),
    ) as RoleFragment[];

    const identities = policy.identities.filter(f =>
      isType(f, 'IamV1Identity'),
    ) as IdentityFragment[];

    openDialog({
      content: (
        <PolicyDialog
          title="Edit policy"
          action="Save"
          value={{
            roles,
            identities: identities.map(identity => identity.name),
          }}
          onAction={async value => {
            await updatePolicy({
              name: policy.name,
              roles: value.roles.map(role => role.name),
              identities: value.identities,
            });
          }}
        />
      ),
    });
  }, []);

  const handleDelete = useCallback((policy: PolicyFragment) => {
    openDialog({
      content: (
        <ConfirmDialog
          onConfirm={async () => {
            await deletePolicy(policy.name);
          }}
          title="Delete policy"
        >
          This will delete the policy permanetly. Do you wish to proceed?
        </ConfirmDialog>
      ),
    });
  }, []);
  return (
    <>
      <ActionBar ref={actionBarRef} topOffset={0}>
        <FilterBar />
        <Button
          startIcon={<Add />}
          variant="contained"
          color="primary"
          size="small"
          data-testid="create-policy-button"
          onClick={handleCreate}
          disabled={!allowed}
        >
          Create
        </Button>
      </ActionBar>
      {!policies || (policies.length === 0 && !loading) ? (
        <FilterListEmptyState
          error={error}
          type="policy"
          typePlural="policies"
          description="Add policies to manage access to your resources"
        />
      ) : (
        <StickyTableContainer loading={loading} error={error}>
          <Table>
            <StickyTableHead topOffset={actionBarHeight}>
              <TableRow>
                <TableCell>Identity</TableCell>
                <TableCell valign="top">Role</TableCell>
                <TableCell width={120} align="center">
                  Actions
                </TableCell>
              </TableRow>
            </StickyTableHead>
            <TableBody>
              {policies.map(policy => (
                <PolicyTableRow
                  key={policy.name}
                  policy={policy}
                  onDelete={() => handleDelete(policy)}
                  onEdit={() => handleEdit(policy)}
                />
              ))}
            </TableBody>
          </Table>
        </StickyTableContainer>
      )}
      <LoadMore />
    </>
  );
};

export const PolicyIndexPage = () => {
  const adminRoute = useRouteRef(rootRouteRef);
  return (
    <FilterListProvider storageKey="policies">
      <PageLayout
        narrow
        title="Policies"
        headerBreadcrumbs={
          <Breadcrumbs>
            <Link to={adminRoute()}>Admin</Link>
            <Typography>Policies</Typography>
          </Breadcrumbs>
        }
      >
        <PolicyPageContent />
      </PageLayout>
    </FilterListProvider>
  );
};
