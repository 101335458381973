import React from 'react';

import {
  Box,
  IconButton,
  InputAdornment,
  createStyles,
  makeStyles,
} from '@material-ui/core';
import EventRoundedIcon from '@material-ui/icons/EventRounded';
import {
  KeyboardDatePickerProps,
  KeyboardDateTimePicker,
  KeyboardDateTimePickerProps,
} from '@material-ui/pickers';

import { format as formatDate } from 'date-fns';

import { ChipInput } from '../ChipInput';
import { SharedInputProps } from './types';

type ChipTimestampPickerProps = SharedInputProps & {
  component?:
    | React.FC<KeyboardDateTimePickerProps>
    | React.FC<KeyboardDatePickerProps>;
  id?: string;
  maxWidth?: number;
  format?: string;
  displayFormat?: string;
};

const useStyles = makeStyles(() =>
  createStyles({
    button: {
      height: 24,
      width: 24,
      backgroundColor: 'transparent',
    },
    input: {
      maxWidth: (props: { maxWidth: number }) => props.maxWidth,
    },
  }),
);

// Determine if we should display 12- or 24-hour time
const locale = navigator.language;
const ampm = new Intl.DateTimeFormat(locale, {
  hour: 'numeric',
}).resolvedOptions().hour12;

export const ChipTimestampPicker = ({
  value,
  disabled,
  readOnly,
  onChange,
  InputProps,
  component: Component = KeyboardDateTimePicker,
  id = 'timestamp-picker',
  maxWidth = 80,
  format = "yyyy-MM-dd'T'HH:mm:ss",
  displayFormat = format,
}: ChipTimestampPickerProps) => {
  const classes = useStyles({ maxWidth });
  const [isOpen, setIsOpen] = React.useState(false);
  const inputRef = React.useRef<HTMLInputElement | undefined>(undefined);

  const handleChange = (date: Date | null) => {
    try {
      const payload = date ? formatDate(date, format) : null;
      if (payload) {
        onChange(payload);
      }
    } catch (error) {
      // eslint-disable-next-line no-console
      console.log('ERROR', { error });
    }
  };

  return (
    <Component
      inputRef={inputRef}
      variant="inline"
      open={isOpen}
      ampm={ampm}
      autoOk
      emptyLabel="-"
      value={value || null}
      format={displayFormat}
      onClose={() => setIsOpen(false)}
      onChange={handleChange}
      PopoverProps={{
        id: `${id}-picker-popover`,
        anchorEl: () => inputRef.current!,
      }}
      TextFieldComponent={props => (
        <Box>
          <ChipInput
            {...props}
            inputRef={inputRef}
            disabled={disabled}
            inputProps={{
              style: {
                maxWidth: maxWidth,
              },
            }}
            required
            InputProps={{
              readOnly: readOnly,
              ...(!(disabled || readOnly) && {
                // onFocus: () => setIsOpen(o => !o),
                endAdornment: (
                  <>
                    <InputAdornment position="end">
                      <IconButton
                        className={classes.button}
                        onClick={() => setIsOpen(o => !o)}
                        name={`${id}-picker-icon`}
                        color="inherit"
                      >
                        <EventRoundedIcon style={{ fontSize: '0.82em' }} />
                      </IconButton>
                    </InputAdornment>
                    {InputProps?.endAdornment}
                  </>
                ),
              }),
            }}
          />
        </Box>
      )}
    />
  );
};
