import React from 'react';
import { InView } from 'react-intersection-observer';

import {
  Box,
  Button,
  CircularProgress,
  IconButton,
  List,
  ListItem,
  ListItemText,
  Tooltip,
  Typography,
} from '@material-ui/core';
import AddIcon from '@material-ui/icons/Add';
import CloseIcon from '@material-ui/icons/Close';
import DeleteIcon from '@material-ui/icons/Delete';
import { Alert } from '@material-ui/lab';

import {
  DangerBox,
  DangerButton,
  DangerZone,
  DateUtils,
  DialogBody,
  DialogHeader,
  ListEmptyState,
  SecretText,
  useAlert,
  useDialog,
  useNavigate,
} from '@spotify-confidence/core-react';
import {
  ClientCredentialFragment,
  ListClientCredentialsDocument,
  ListClientsDocument,
  getError,
  getTypeOrNull,
  useCreateClientCredentialMutation,
  useDeleteClientCredentialMutation,
  useDeleteClientMutation,
  useGetClientQuery,
  useListClientCredentialsQuery,
} from '@spotify-confidence/plugin-graphql';
import { PermissionUtils } from '@spotify-confidence/plugin-permissions-react';

import { CopyTextButton } from '@backstage/core-components';
import { useRouteRef } from '@backstage/core-plugin-api';

import { clientsRouteRef } from '../../../../routes';

export const copyText = (text: string) => navigator.clipboard.writeText(text);

export const ClientDialog = ({ id }: { id: string }) => {
  const { closeDialog } = useDialog();
  const navigate = useNavigate();
  const alert = useAlert();

  const clientsRoute = useRouteRef(clientsRouteRef);
  const clientName = `clients/${id}`;

  const [deleteClient, { loading: isDeleting, error: deleteClientError }] =
    useDeleteClientMutation({
      variables: {
        client: clientName,
      },
      refetchQueries: [{ query: ListClientsDocument }],
      onCompleted: () => navigate(clientsRoute()),
    });

  const {
    data: clientData,
    loading: gettingClientLoading,
    error: getClientError,
  } = useGetClientQuery({
    variables: {
      name: clientName,
    },
  });
  const client = getTypeOrNull(clientData?.client, 'IamV1Client');
  const clientError = getError(clientData?.client);
  const canEdit = client && PermissionUtils.hasRelation(client, 'can_edit');
  const canCreateCredential =
    client &&
    PermissionUtils.hasRelation(client, 'can_create_client_credential');

  const {
    data: clientCredentialsData,
    loading: listingClientCredentialsLoading,
    error: listClientCredentialsError,
    fetchMore,
  } = useListClientCredentialsQuery({
    variables: {
      parent: clientName,
    },
  });
  const clientCredentialsResponse = getTypeOrNull(
    clientCredentialsData?.clientCredentials,
    'IamV1ListClientCredentialsResponse',
  );

  const [
    createClientCredential,
    {
      data: clientCredentialData,
      loading: creatingClientCredentialLoading,
      error: createClientCredentialError,
    },
  ] = useCreateClientCredentialMutation({
    refetchQueries: [
      {
        query: ListClientCredentialsDocument,
        variables: { parent: clientName },
      },
    ],
  });
  const createClientCredentialResponse = getTypeOrNull(
    clientCredentialData?.createClientCredential,
    'IamV1ClientCredential',
  );
  const handleCreateClientSecret = React.useCallback(() => {
    createClientCredential({
      variables: {
        parent: clientName,
        clientCredential: {
          displayName: `${Date.now()}`,
        },
      },
    });
  }, [createClientCredential, clientName]);

  const [
    deleteClientCredential,
    {
      loading: deletingClientCredentialLoading,
      error: deleteClientCredentialError,
    },
  ] = useDeleteClientCredentialMutation({
    onCompleted: response => {
      const error = getError(response.deleteClientCredential);
      if (error) {
        alert.post({
          severity: 'error',
          message: error.message,
        });
      }
    },
    refetchQueries: [
      {
        query: ListClientCredentialsDocument,
        variables: { parent: clientName },
      },
    ],
  });

  const handleDeleteClientCredential = React.useCallback(
    (_clientCredential: ClientCredentialFragment) =>
      deleteClientCredential({
        variables: {
          name: _clientCredential.name,
        },
      }),
    [deleteClientCredential],
  );

  const loading =
    gettingClientLoading ||
    listingClientCredentialsLoading ||
    creatingClientCredentialLoading ||
    deletingClientCredentialLoading;

  const error =
    clientError ||
    getClientError ||
    listClientCredentialsError ||
    createClientCredentialError ||
    deleteClientError ||
    deleteClientCredentialError;

  React.useEffect(() => {
    if (createClientCredentialResponse) {
      alert.post({
        severity: 'success',
        message: `Credential was successfully created! The secret was copied to the clipboard`,
        display: 'transient',
      });
      copyText(createClientCredentialResponse.clientSecret?.secret!);
    }
  }, [createClientCredentialResponse]);

  const credentials = clientCredentialsResponse?.clientCredentials || [];

  const handleClose = () => {
    closeDialog();
    navigate(clientsRoute());
  };

  return (
    <>
      <DialogHeader title={client?.displayName} onClose={handleClose} />
      <DialogBody autoHeight>
        {error && <Alert severity="error">{error.message}</Alert>}

        {client && (
          <>
            {!!credentials.length && (
              <Box
                display="flex"
                justifyContent="space-between"
                mt={2}
                mb={2}
                alignItems="center"
              >
                <Typography variant="h6">All credentials</Typography>

                <Button
                  size="small"
                  variant="outlined"
                  onClick={handleCreateClientSecret}
                  startIcon={<AddIcon />}
                  disabled={!canCreateCredential}
                >
                  Add credential
                </Button>
              </Box>
            )}

            {!loading && credentials.length === 0 && (
              <Box mb={2}>
                <ListEmptyState
                  title="No credentials yet"
                  minimal
                  actions={
                    <Button
                      variant="outlined"
                      onClick={handleCreateClientSecret}
                      disabled={!canCreateCredential}
                    >
                      Create new credential
                    </Button>
                  }
                />
              </Box>
            )}
            {credentials.length > 0 && (
              <Box mt={3} mb={4}>
                <List>
                  {credentials.map(credential => {
                    const canEditCredential = PermissionUtils.hasRelation(
                      credential,
                      'can_edit',
                    );
                    return (
                      <ListItem
                        id={credential.name}
                        key={credential.name}
                        disableGutters
                        divider
                      >
                        <ListItemText
                          data-testid="client-secret"
                          primary={
                            <SecretText
                              renderTrailing={visible =>
                                visible ? (
                                  <CopyTextButton
                                    text={credential.clientSecret?.secret!}
                                  />
                                ) : null
                              }
                            >
                              {credential.clientSecret?.secret!}
                            </SecretText>
                          }
                          secondary={`${
                            credential.lastSeenTime
                              ? DateUtils.xAgo(
                                  new Date(credential.lastSeenTime),
                                )
                              : 'Not yet seen'
                          } • Created on ${DateUtils.toDateString(
                            credential.createTime,
                          )} • ${
                            getTypeOrNull(credential.creator, 'IamV1Identity')
                              ?.displayName
                          }`}
                        />
                        <IconButton
                          size="small"
                          disabled={loading || !canEditCredential}
                          data-testid="delete-client-credential-button"
                          onClick={() =>
                            handleDeleteClientCredential(credential)
                          }
                        >
                          <CloseIcon />
                        </IconButton>
                      </ListItem>
                    );
                  })}
                </List>
                <InView
                  onChange={async inView => {
                    const nextPageToken =
                      clientCredentialsResponse?.nextPageToken;

                    if (inView && nextPageToken) {
                      await fetchMore({
                        variables: {
                          pageToken: nextPageToken,
                        },
                      });
                    }
                  }}
                />
              </Box>
            )}
          </>
        )}

        <Box mt={2}>
          <DangerZone>
            <Tooltip
              title={
                credentials.length > 0
                  ? 'Unable to delete a client with credentials'
                  : ''
              }
            >
              <DangerBox>
                <strong>Delete Client</strong>

                <DangerButton
                  startIcon={<DeleteIcon />}
                  variant="contained"
                  disabled={isDeleting || credentials.length > 0 || !canEdit}
                  color="inherit"
                  endIcon={isDeleting && <CircularProgress size={14} />}
                  onClick={() => deleteClient()}
                >
                  Delete
                </DangerButton>
              </DangerBox>
            </Tooltip>
          </DangerZone>
        </Box>
      </DialogBody>
    </>
  );
};
