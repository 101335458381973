import React from 'react';

import { TextField } from '@material-ui/core';

import { FormFieldsBox } from '@spotify-confidence/core-react';
import { MetricsV1DatabricksConfigInput } from '@spotify-confidence/plugin-graphql';

type DataBricksDatawarehouseFormProps = {
  value?: MetricsV1DatabricksConfigInput | null;
  onChange: (conf: MetricsV1DatabricksConfigInput) => void;
  disabled?: boolean;
  requireAccessToken?: boolean;
};

export const DataBricksDatawarehouseForm = ({
  value,
  onChange,
  disabled,
  requireAccessToken,
}: DataBricksDatawarehouseFormProps) => {
  const host: string = value?.host ?? '';
  const warehouseId: string = value?.warehouseId ?? '';
  const clientId: string = value?.clientId ?? '';
  const clientSecret: string = value?.clientSecret ?? '';
  const exposureSchema: string = value?.exposureSchema ?? '';
  const resultsSchema: string = value?.resultsSchema ?? '';

  const handleChange = React.useCallback(
    (field: keyof MetricsV1DatabricksConfigInput) =>
      (e: React.ChangeEvent<HTMLInputElement>) => {
        onChange({ ...value, [field]: e.target.value });
      },
    [onChange],
  );

  return (
    <>
      <FormFieldsBox>
        <TextField
          disabled={disabled}
          label="Host (excluding protocol)"
          variant="outlined"
          value={host}
          required
          fullWidth
          helperText={
            <>
              The hostname for the Databricks instance, for example
              xx.x.gcp.databricks.com
            </>
          }
          onChange={handleChange('host')}
        />

        <TextField
          disabled={disabled}
          label="Warehouse ID"
          variant="outlined"
          value={warehouseId}
          required
          fullWidth
          helperText={
            <>
              The ID for the SQL warehouse that will run queries, for example
              a804933ad225d37a.
            </>
          }
          onChange={handleChange('warehouseId')}
        />

        <TextField
          disabled={disabled}
          label="Client ID"
          variant="outlined"
          value={clientId}
          required
          fullWidth
          helperText="The OAuth client ID for the service principal."
          onChange={handleChange('clientId')}
        />

        <TextField
          disabled={disabled}
          label="Client secret"
          variant="outlined"
          value={clientSecret}
          required={requireAccessToken}
          fullWidth
          helperText="The OAuth client secret for the service principal."
          onChange={handleChange('clientSecret')}
        />

        <TextField
          disabled={disabled}
          label="Exposure schema"
          variant="outlined"
          value={exposureSchema}
          required
          fullWidth
          onChange={handleChange('exposureSchema')}
        />

        <TextField
          disabled={disabled}
          label="Results schema"
          variant="outlined"
          value={resultsSchema}
          required
          fullWidth
          onChange={handleChange('resultsSchema')}
        />
      </FormFieldsBox>
    </>
  );
};
