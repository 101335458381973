import { getError, getTypeOrNull } from '@spotify-confidence/plugin-graphql';
import { isWithinInterval, subMinutes } from 'date-fns';

import { useGetFlagResolvedQuery } from '../../../generated/graphql';
import { TUTORIAL_FLAG_NAME } from './constants';

export function useFlagResolved({ skip }: { skip?: boolean } = {}) {
  const { data, loading, error, startPolling, stopPolling } =
    useGetFlagResolvedQuery({
      variables: {
        name: `flags/${TUTORIAL_FLAG_NAME}`,
      },
      skip,
    });

  const flagData = getTypeOrNull(data?.flag, 'FlagsAdminV1Flag');
  const lastResolveTime = flagData?.usageMetadata?.lastResolveTime;

  const now = new Date();

  // If the flag has resolved in the last 5 minutes.
  const hasResolved = Boolean(
    lastResolveTime &&
      isWithinInterval(new Date(lastResolveTime), {
        start: subMinutes(now, 12 * 60),
        end: now,
      }),
  );

  return {
    hasResolved,
    startPolling,
    stopPolling,
    loading,
    error: getError(data?.flag) || error,
  };
}
