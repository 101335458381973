import React, { forwardRef, isValidElement } from 'react';

import {
  Typography,
  TypographyProps,
  WithStyles,
  createStyles,
  withStyles,
} from '@material-ui/core';

import classNames from 'classnames';

const styles = () =>
  createStyles({
    root: {
      flexGrow: 1,
    },
  });

export interface RecommendationCardHeaderProps
  extends WithStyles<typeof styles> {
  className?: string;

  // If true, the children won't be wrapped by a Typography component. This can
  // be useful to render an alternative Typography variant by wrapping the
  // children (or primary) text, and optional secondary text with the Typography
  // component.
  disableTypography?: boolean;

  // The main content element.
  primary?: React.ReactNode;

  // These props will be forwarded to the primary typography component (as long
  // as disableTypography is not true).
  primaryTypographyProps?: TypographyProps;

  // The secondary content element.
  secondary?: React.ReactNode;

  // These props will be forwarded to the secondary typography component (as
  // long as disableTypography is not true).
  secondaryTypographyProps?: TypographyProps;

  // Alias for primary.
  children?: React.ReactNode;
}

const RecommendationCardHeader = forwardRef<
  HTMLDivElement,
  RecommendationCardHeaderProps
>(function RecommendationCardHeader(props, ref) {
  const {
    className,
    classes,
    children,
    disableTypography,
    primaryTypographyProps,
    secondaryTypographyProps,
  } = props;

  const { primary = children, secondary } = props;

  const primaryElement =
    primary &&
    !disableTypography &&
    !(isValidElement(primary) && primary.type === Typography) ? (
      <Typography variant="h6" {...primaryTypographyProps}>
        {primary}
      </Typography>
    ) : (
      primary
    );

  const secondaryElement =
    secondary &&
    !disableTypography &&
    !(isValidElement(secondary) && secondary.type === Typography) ? (
      <Typography variant="body2" {...secondaryTypographyProps}>
        {secondary}
      </Typography>
    ) : (
      secondary
    );

  return (
    <div ref={ref} className={classNames(className, classes.root)}>
      {primaryElement}
      {secondaryElement}
    </div>
  );
});

export default withStyles(styles)(RecommendationCardHeader);
