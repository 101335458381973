import React from 'react';

import {
  Button,
  CircularProgress,
  MenuItem,
  TextField,
} from '@material-ui/core';
import { Alert } from '@material-ui/lab';

import {
  FormFieldsBox,
  StringUtils,
  useAuth,
} from '@spotify-confidence/core-react';
import {
  ConnectorsV1Format,
  ConnectorsV1PubsubConfigInput,
  ConnectorsV1FlagAppliedConnectionPubsubFlagAppliedConnectionConfigInput as PubSubInput,
  useValidatePubsubFlagAppliedConnectionConfigMutation,
} from '@spotify-confidence/plugin-graphql';

type PubSubFlagAppliedConnectionFormProps = {
  value?: PubSubInput | null;
  onChange: (conf: PubSubInput) => void;
  disabled?: boolean;
};

const defaultPubsubConfig: ConnectorsV1PubsubConfigInput = {
  project: '',
  topic: '',
  serviceAccount: '',
  outputFormat: ConnectorsV1Format.Json,
};

export const PubSubFlagAppliedConnectionForm = ({
  value,
  onChange,
  disabled,
}: PubSubFlagAppliedConnectionFormProps) => {
  const { user: { org_name } = {} } = useAuth();

  const [validate, validation] =
    useValidatePubsubFlagAppliedConnectionConfigMutation({
      variables: {
        pubsub: value,
      },
    });

  const project = value?.pubsubConfig.project ?? '';
  const serviceAccount = value?.pubsubConfig.serviceAccount ?? '';
  const topic = value?.pubsubConfig.topic ?? '';
  const output =
    value?.pubsubConfig.outputFormat ?? defaultPubsubConfig.outputFormat;
  const validSAEmail = serviceAccount
    ? StringUtils.isValidEmail(serviceAccount)
    : true;
  const canValidate = Boolean(
    project && serviceAccount && topic && validSAEmail,
  );

  const handleChange =
    (newValue: keyof ConnectorsV1PubsubConfigInput) =>
    (e: React.ChangeEvent<HTMLInputElement>) => {
      onChange({
        ...value,
        pubsubConfig: {
          ...defaultPubsubConfig,
          ...value?.pubsubConfig,
          [newValue]: e.target.value,
        },
      });
    };

  const handleValidate = async () => {
    try {
      await validate();
    } catch (e) {
      //   console.log(e);
    }
  };

  return (
    <FormFieldsBox>
      <TextField
        disabled={disabled}
        label="GCP Project ID"
        variant="outlined"
        value={project}
        required
        fullWidth
        helperText="The ID of the GCP project where the data you want to use in Confidence resides."
        onChange={handleChange('project')}
      />
      <TextField
        disabled={disabled}
        label="Service Account"
        variant="outlined"
        value={serviceAccount}
        required
        fullWidth
        error={!validSAEmail}
        helperText={
          validSAEmail ? (
            <>
              A GCP service account that has write access to the destination
              table and has granted{' '}
              <strong>
                {`account-${org_name}@spotify-confidence.iam.gserviceaccount.com`}{' '}
              </strong>{' '}
              "Workload Identity User" role.
            </>
          ) : (
            'The service account should be a valid email address.'
          )
        }
        onChange={handleChange('serviceAccount')}
      />
      <TextField
        disabled={disabled}
        label="Topic"
        variant="outlined"
        value={topic}
        required
        fullWidth
        onChange={handleChange('topic')}
        helperText="The name of the topic."
      />
      <TextField
        disabled={disabled}
        label="Output format"
        variant="outlined"
        value={output}
        required
        helperText="If the connector should write events in JSON or Protobuf binary format."
        fullWidth
        select
        onChange={handleChange('outputFormat')}
      >
        {Object.values(ConnectorsV1Format).map(outputOption => (
          <MenuItem key={outputOption} value={outputOption}>
            {outputOption}
          </MenuItem>
        ))}
      </TextField>
      <div>
        <Button
          size="small"
          variant="outlined"
          disabled={!canValidate || validation.loading}
          onClick={handleValidate}
          endIcon={validation.loading && <CircularProgress size="1em" />}
        >
          Validate
        </Button>
      </div>
      {validation.data && (
        <Alert severity="success">Validation successful!</Alert>
      )}
      {validation.error && (
        <Alert severity="error">{validation.error.message}</Alert>
      )}
    </FormFieldsBox>
  );
};
