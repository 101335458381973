import React from 'react';

import { Chip, Tooltip, makeStyles } from '@material-ui/core';
import ErrorIcon from '@material-ui/icons/Error';
import VisibilityOff from '@material-ui/icons/VisibilityOff';

import {
  SchemaEntryFragment,
  getTypeOrNull,
} from '@spotify-confidence/plugin-graphql';
import classNames from 'classnames';

import {
  TargetingCriteria,
  targetingModel,
} from '../../../../segment/domain/targeting';
import {
  isClosedRangeValue,
  isSetValue,
  isSingleValue,
} from '../../../../segment/domain/targeting/targeting.model';
import { getCountryLabel } from '../../../domain/rule.helpers';

const useInlineCriteriaStyles = makeStyles(theme => ({
  criteria: {
    display: 'inline-block',
    padding: theme.spacing(0, 1),
    border: `1px solid ${theme.palette.divider}`,
    borderRadius: theme.spacing(1),
    marginTop: theme.spacing(0.5),
    marginBottom: theme.spacing(0.5),
    '&:only-child': {
      display: 'inline',
      margin: 0,
      padding: 0,
      border: 'none',
    },
  },

  attribute: {
    fontFamily: 'monospace',
    fontWeight: 'bold',
  },

  missing: {
    color: theme.palette.error.dark,
  },

  icon: {
    marginRight: theme.spacing(0.5),
    // middle did not center the icon correctly, revisit later
    verticalAlign: '-0.25em',
  },
}));

export const InlineCriteria = ({
  suffix,
  seenAttributes,
  ...targeting
}: TargetingCriteria & {
  suffix?: React.ReactNode;
  seenAttributes?: SchemaEntryFragment[] | undefined;
}) => {
  const classes = useInlineCriteriaStyles();

  if (!targetingModel.isTargetingValid(targeting)) {
    return null;
  }

  if (targetingModel.isCriterionSet(targeting)) {
    const validCriteria = targeting.criteria.filter(
      targetingModel.isTargetingValid,
    );
    return (
      <>
        <span className={classes.criteria}>
          {validCriteria.map((criteria, i) => {
            const isNotLast = i < validCriteria.length - 1;
            return (
              <React.Fragment key={criteria.name}>
                <InlineCriteria
                  {...criteria}
                  suffix={isNotLast ? <em> {targeting.operator} </em> : null}
                  seenAttributes={seenAttributes}
                />
              </React.Fragment>
            );
          })}
        </span>
        {suffix}
      </>
    );
  }
  if (targeting.type === 'segment') {
    return (
      <span className={classes.criteria}>
        <span>{targeting.op} </span>
        {targeting.segment.displayName} {suffix}
      </span>
    );
  }
  if (targeting.type === 'attribute') {
    const seenAttribute = seenAttributes?.find(
      entry => entry.key === targeting.attribute,
    );
    const hasBeenSeen = seenAttributes === undefined || !!seenAttribute;
    const hidden = seenAttribute?.value?.hidden ?? false;

    const entity = getTypeOrNull(
      seenAttribute?.value?.semanticType?.entityReference?.entity,
      'MetricsV1Entity',
    );
    const attributeDisplayName =
      entity?.displayName || seenAttribute?.value?.displayName;
    const nameLabel = attributeDisplayName
      ? `${attributeDisplayName} (${targeting.attribute})`
      : targeting.attribute;

    let attributeValue = targeting.value;
    let attributeComponent: React.ReactNode;

    // Convert value for country semantic type to country names
    if (
      seenAttribute?.value?.semanticType?.country?.format ===
      'TWO_LETTER_ISO_CODE'
    ) {
      if (isSetValue(attributeValue)) {
        attributeValue = attributeValue.map(getCountryLabel);
      } else if (isSingleValue(attributeValue)) {
        attributeValue = getCountryLabel(attributeValue);
      }
    }

    if (isClosedRangeValue(attributeValue)) {
      attributeComponent = (
        <span>
          {attributeValue.start.value}{' '}
          <span>
            ({attributeValue.start.inclusive ? 'inclusive' : 'exclusive'})
          </span>{' '}
          and {attributeValue.end.value}{' '}
          <span>
            ({attributeValue.end.inclusive ? 'inclusive' : 'exclusive'})
          </span>{' '}
        </span>
      );
    } else if (isSetValue(attributeValue)) {
      attributeComponent = attributeValue.map(value => (
        <Chip key={value} size="small" label={value} />
      ));
    } else {
      attributeComponent = attributeValue;
    }

    return (
      <>
        <span className={classes.criteria}>
          {!hasBeenSeen && (
            <Tooltip
              arrow
              title={`The flag has been resolved but "${targeting.attribute}" was not passed in the context.`}
            >
              <ErrorIcon className={classes.icon} fontSize="small" />
            </Tooltip>
          )}
          {hidden && (
            <Tooltip
              arrow
              title={`The "${targeting.attribute}" attribute is marked as hidden in the schema. Verify that you can rely on this attribute being present.`}
            >
              <VisibilityOff className={classes.icon} fontSize="small" />
            </Tooltip>
          )}
          <span
            className={classNames(
              classes.attribute,
              !hasBeenSeen ? classes.missing : undefined,
            )}
          >
            {nameLabel}
          </span>{' '}
          {targeting.listMatcherType
            ? `${targeting.listMatcherType.toLowerCase()} `
            : ''}
          {targeting.op?.toLowerCase()} {attributeComponent}
        </span>
        {suffix}
      </>
    );
  }

  return null;
};
