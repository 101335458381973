import React from 'react';

import { Tab, Tabs, Typography } from '@material-ui/core';
import { TabContext } from '@material-ui/lab';

import { useRegion } from '@spotify-confidence/core-react';

import { CodeSnippet } from '../../CodeSnippet';
import { f } from '../../CodeSnippet';
import { SnippetProps } from '../../CodeSnippet';
import { StyledTabPanel } from '../../StyledTabPanel';
import { TUTORIAL_FLAG_NAME } from '../constants';

export const JsReactSnippets = (props: SnippetProps) => {
  const region = useRegion();
  const [packageManager, setPackageManager] = React.useState('npm');

  return (
    <ol>
      <li>
        <Typography variant="body1" gutterBottom>
          This guide builds on a small React app. If you don't already have one,
          you can create one with the following command.
          <pre>yarn create react-app my-app --template typescript</pre>
        </Typography>
      </li>
      <li>
        <Typography variant="body1" gutterBottom>
          Install the required Confidence packages.
        </Typography>
        <TabContext value={packageManager}>
          <Tabs
            indicatorColor="primary"
            value={packageManager}
            onChange={(_e, v) => setPackageManager(v)}
          >
            <Tab label="npm" value="npm" />
            <Tab label="yarn" value="yarn" />
          </Tabs>
          <StyledTabPanel value="npm">
            <CodeSnippet
              language="bash"
              text={f(`
npm install @spotify-confidence/sdk @spotify-confidence/react
`)}
              showCopyCodeButton
            />
          </StyledTabPanel>
          <StyledTabPanel value="yarn">
            <CodeSnippet
              language="bash"
              text={f(`
yarn add @spotify-confidence/sdk @spotify-confidence/react
`)}
              showCopyCodeButton
            />
          </StyledTabPanel>
        </TabContext>
      </li>

      <li>
        <Typography variant="body1" gutterBottom>
          Import and create your Confidence instance. This should be done as
          early as possible in your application.
          <p>
            Wrap your component tree with the ConfidenceProvider and pass the
            confidence instance.
          </p>
          <p>
            You should ensure that any component using feature flags is wrapped
            with a React.Suspense.
          </p>
        </Typography>
        <CodeSnippet
          language="typescript"
          text={f(`
import React from 'react';
import { ConfidenceProvider } from '@spotify-confidence/react';
import { Confidence } from '@spotify-confidence/sdk';

const confidence = Confidence.create({
  clientSecret: '${props.apiKey}',
  region: '${(region || 'EU').toLowerCase()}',
  environment: 'client',
  timeout: 1000,
});

function App() {
  return (
    <ConfidenceProvider confidence={confidence}>
      <h1>My React app</h1>
      <React.Suspense fallback={<p>Loading... </p>}>
        <MyComponent />
      </React.Suspense>
    </ConfidenceProvider>
  );
}
      `)}
          showCopyCodeButton
        />
      </li>
      <li>
        <Typography variant="body1" gutterBottom>
          Next, we want to set the `visitor_id` context field to
          "tutorial_visitor", to match the targeting criteria pre-configured for
          this tutorial. For this tutorial we recommend setting the context as
          soon as possible.
        </Typography>
        <CodeSnippet
          language="typescript"
          showLineNumbers
          highlightedNumbers={[7]}
          text={f(`
const confidence = Confidence.create({
  clientSecret: '${props.apiKey}',
  region: '${(region || 'EU').toLowerCase()}',
  environment: 'client',
  timeout: 1000,
});
confidence.setContext({ visitor_id: 'tutorial_visitor' });

function App() {
  return (
    <ConfidenceProvider confidence={confidence}>
      <h1>My React app</h1>
      <React.Suspense fallback={<p>Loading... </p>}>
        <MyComponent />
      </React.Suspense>
    </ConfidenceProvider>
  );
}
      `)}
          showCopyCodeButton
        />
      </li>
      <li>
        <Typography variant="body1" gutterBottom>
          Inside any of your components you can now use the useFlag hook to get
          the value of the "{TUTORIAL_FLAG_NAME}" flag.
        </Typography>
        <CodeSnippet
          language="typescript"
          text={f(`
import { useConfidence } from '@spotify-confidence/react';

export const MyComponent = () => {
  const confidence = useConfidence();
  const title = confidence.useFlag('${TUTORIAL_FLAG_NAME}.title', 'Default Title');
  const message = confidence.useFlag('${TUTORIAL_FLAG_NAME}.message', 'Default message');
  return (
    <>
      <h1>{title}</h1>
      <p>{message}</p>
    </>
  );
};
      `)}
          showCopyCodeButton
        />
      </li>
    </ol>
  );
};
