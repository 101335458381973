import React from 'react';
import { InView } from 'react-intersection-observer';

import { useListFilter } from '@spotify-confidence/core-react';
import {
  IamV1GroupInput,
  getError,
  getTypeOrNull,
  useCreateGroupMutation,
  useListGroupsQuery,
} from '@spotify-confidence/plugin-graphql';

export const useGroups = () => {
  const { searchQuery } = useListFilter();
  const pageSize = 25;

  const { data, loading, error, fetchMore } = useListGroupsQuery({
    variables: {
      pageSize,
      filter: searchQuery
        ? `displayName:${searchQuery} OR description:${searchQuery}`
        : undefined,
    },
  });

  const { groups, nextPageToken } =
    getTypeOrNull(data?.groups, 'IamV1ListGroupsResponse') || {};

  const filteredGroups = groups || [];

  // component for fetching more data
  const fetchMoreObserver = (
    <InView
      onChange={async inView => {
        if (inView && nextPageToken) {
          await fetchMore({
            variables: {
              pageToken: nextPageToken,
            },
          });
        }
      }}
    />
  );

  const [createGroupMutation] = useCreateGroupMutation({
    update: cache => {
      cache.evict({ fieldName: 'groups' });
      cache.evict({ fieldName: 'identities' });
      cache.gc();
    },
  });

  const createGroup = async ({ displayName, description }: IamV1GroupInput) => {
    const result = await createGroupMutation({
      variables: {
        input: {
          displayName,
          description,
        },
      },
    });
    return result.data?.createGroup;
  };

  return {
    loading,
    error: getError(data?.groups) || error,
    groups: filteredGroups,
    fetchMoreObserver,
    createGroup,
  };
};
