import React from 'react';

import { Card, CardContent, Grow, Popper, makeStyles } from '@material-ui/core';

const useStyles = makeStyles(theme => ({
  popper: {
    marginTop: theme.spacing(1),
    maxWidth: 430,
    zIndex: theme.zIndex.tooltip,
  },
}));

export const StatsDetailsPopover = ({
  children,
  details,
}: {
  children: React.ReactNode;
  details: JSX.Element;
}) => {
  const classes = useStyles();
  const [anchorEl, setAnchorEl] = React.useState<HTMLElement | null>(null);

  const handlePopoverOpen = (
    event: React.MouseEvent<HTMLElement, MouseEvent>,
  ) => {
    setAnchorEl(event.currentTarget);
  };

  const handlePopoverClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);

  return (
    <div onMouseLeave={handlePopoverClose}>
      <div onMouseLeave={handlePopoverClose} onMouseEnter={handlePopoverOpen}>
        {children}
      </div>
      <Popper
        open={open}
        anchorEl={anchorEl}
        transition
        className={classes.popper}
        placement="top"
      >
        {({ TransitionProps }) => (
          <Grow {...TransitionProps}>
            <Card>
              <CardContent>{details}</CardContent>
            </Card>
          </Grow>
        )}
      </Popper>
    </div>
  );
};
