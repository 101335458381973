import {
  FlagsAdminV1FlagRuleInput,
  PartialFlagsAdminV1FlagRuleInput,
  RuleFragment,
  getTypeOrNull,
  isType,
} from '@spotify-confidence/plugin-graphql';
import { v4 as uuidv4 } from 'uuid';

import { newSegment } from '../../segment/domain/segment.model';
import { newAssignments } from './assignments.model';

export enum RuleType {
  OVERRIDE = 'override',
  ROLLOUT = 'rollout',
  GENERAL = 'general',
  COMPACT = 'compact',
}
export const newRule = (ruleType: RuleType, flag: string): RuleFragment => ({
  __typename: 'FlagsAdminV1FlagRule',
  name: uuidv4(),
  updateTime: Date.now().toString(),
  segment: newSegment(flag),
  enabled: false,
  assignmentSpec: newAssignments(),
  labels: [...(ruleType ? [{ key: 'ruleType', value: ruleType }] : [])],
});

export const ruleFragmentToInput = (
  rule: RuleFragment,
): FlagsAdminV1FlagRuleInput => ({
  name: rule.name,
  enabled: rule.enabled,
  segment: getTypeOrNull(rule.segment, 'FlagsAdminV1Segment')?.name ?? '',
  assignmentSpec: {
    bucketCount: rule.assignmentSpec.bucketCount,
    assignments: rule.assignmentSpec.assignments.map(assignment => ({
      assignmentId: assignment.assignmentId,
      bucketRanges: assignment.bucketRanges,
      ...(assignment.variant &&
      isType(assignment.variant?.variant, 'FlagsAdminV1FlagVariant')
        ? { variant: { variant: assignment.variant.variant.name } }
        : {}),
      ...(assignment.clientDefault ? { clientDefault: {} } : {}),
      ...(assignment.fallthrough ? { fallthrough: {} } : {}),
    })),
  },
  targetingKeySelector: rule.targetingKeySelector,
  labels: rule.labels,
  materializationSpec: !!rule.materializationSpec
    ? {
        readMaterialization: getTypeOrNull(
          rule.materializationSpec.readMaterialization,
          'FlagsAdminV1MaterializedSegment',
        )?.name,
        writeMaterialization: getTypeOrNull(
          rule.materializationSpec.writeMaterialization,
          'FlagsAdminV1MaterializedSegment',
        )?.name,
        mode: !!rule.materializationSpec.mode
          ? {
              materializationMustMatch:
                rule.materializationSpec.mode.materializationMustMatch || false,
              segmentTargetingCanBeIgnored:
                rule.materializationSpec.mode.segmentTargetingCanBeIgnored ||
                false,
            }
          : undefined,
      }
    : undefined,
});
export const fragmentsToInput = (
  rules: RuleFragment[],
): PartialFlagsAdminV1FlagRuleInput[] => {
  return rules.map(ruleFragmentToInput);
};
