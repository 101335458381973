import React from 'react';

import { Box, MenuItem, TextField } from '@material-ui/core';
import { Alert } from '@material-ui/lab';

import { FormFieldsBox } from '@spotify-confidence/core-react';
import _ from 'lodash';

import { BillingInfo } from './BillingInfo';
import { AccountDetailsState } from './CreateAccountForm';
import { OPEN_MARKETS } from './utils';

export const CompanyInfo = ({
  value,
  error,
  onChange,
}: {
  error?: Error;
  value: AccountDetailsState['billingDetails'];
  onChange: (newData: Partial<AccountDetailsState['billingDetails']>) => void;
}) => {
  const handleAddressChange = (
    v: Partial<AccountDetailsState['billingDetails']['address']>,
  ) => {
    onChange({
      address: {
        ...value.address,
        ...v,
      },
    });
  };

  const countryInfo = OPEN_MARKETS.find(m => m.code === value.address.country);
  return (
    <FormFieldsBox spacing={2}>
      <TextField
        variant="outlined"
        name="displayName"
        label="Company name"
        placeholder="For example My Company Inc"
        value={value.displayName}
        required
        onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
          onChange({ displayName: e.target.value })
        }
        fullWidth
      />

      <TextField
        variant="outlined"
        label="Legal address"
        value={value.address.line1}
        placeholder="Line 1"
        name="line1"
        error={Boolean(error)}
        required
        onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
          handleAddressChange({ line1: e.target.value })
        }
        fullWidth
      />
      <TextField
        variant="outlined"
        value={value.address.line2}
        placeholder="Apt., suite, unit number, etc. (optional)"
        onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
          handleAddressChange({ line2: e.target.value })
        }
        fullWidth
      />
      {countryInfo?.states && (
        <TextField
          variant="outlined"
          label="State"
          required
          placeholder="State"
          error={Boolean(error)}
          value={value.address.state}
          onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
            handleAddressChange({ state: e.target.value })
          }
          select
          SelectProps={{ displayEmpty: true }}
          fullWidth
        >
          {countryInfo.states.map(state => (
            <MenuItem key={state} value={state}>
              {_.startCase(_.toLower(state))}
            </MenuItem>
          ))}
        </TextField>
      )}
      <Box display="grid" gridGap={16} gridTemplateColumns="1fr 1fr">
        <TextField
          variant="outlined"
          label="Postal Code"
          name="postalcode"
          value={value.address.postalCode}
          required
          error={Boolean(error)}
          onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
            handleAddressChange({ postalCode: e.target.value })
          }
          fullWidth
        />
        <TextField
          variant="outlined"
          label="City"
          name="city"
          placeholder="For example Paris"
          value={value.address.city}
          error={Boolean(error)}
          required
          onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
            handleAddressChange({ city: e.target.value })
          }
          fullWidth
        />
      </Box>
      <TextField
        label="Country"
        InputProps={{
          readOnly: true,
        }}
        name="country"
        disabled
        value={countryInfo?.name}
        fullWidth
      />
      {error && <Alert severity="error">{error.message}</Alert>}
      <BillingInfo value={value} onChange={b => onChange({ taxId: b.taxId })} />
    </FormFieldsBox>
  );
};
