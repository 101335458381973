import loadable from '@loadable/component';

export * from './AutocompletePopper';

export * from './AuthorizationGate';
export { CodeEditor } from './CodeEditor';
export * from './Dialog';
export { ErrorBoundary } from './ErrorBoundary';
export * from './FilterList';
export { GridSkeleton } from './GridSkeleton';
export { Highlight } from './Highlight';
export * from './Icons';
export * from './Layout';
export * from './Redirect';
export { ResourceLink } from './ResourceLink';
export * from './Section';
export { Spacer } from './Spacer';

export * from './ActionBar';
export * from './BalanceWeightsButton';
export * from './CardSection';
export * from './ConfidenceAutocomplete';
export {
  default as ConfidenceInterval,
  type ConfidenceIntervalProps,
} from './ConfidenceInterval';
export * from './ContextMenu';
export * from './CreatableAutocomplete';
export * from './DisabledTypography';
export * from './ExpandableContent';
export * from './FeatureFlagged';
export * from './ImpressionTracker';
export * from './InteractionTracker';
export * from './LinkableTableRow';
export * from './LoadingOverlay';
export * from './PageLoadLinearProgress';
export * from './RecommendationCard';
export * from './RequiredResourceAutocomplete';
export * from './ResourceTable';
export * from './SecretText';
export * from './StickyTableHead';
export * from './TableCells';
export { selectTimeWindowDefaultOptions, timeWindowUtils } from './TimeWindow';
export * from './Timeline';
export * from './TreatmentCard';
export * from './VerboseTooltip';
export * from './WizardStepper';
export * from './TutorialWizard';
export * from './FeatureFlagged';
export * from './ImpressionTracker';
export * from './InteractionTracker';
export * from './TimeAgo';

const componentsChunk = () =>
  import(
    /* webpackChunkName: "core-react-components", webpackPrefetch: 10 */ './components.chunk'
  );

export const SidebarSection = loadable(() =>
  componentsChunk().then(m => m.SidebarSection),
);
export const SidebarValue = loadable(() =>
  componentsChunk().then(m => m.SidebarValue),
);
export const SidebarValueWrapper = loadable(() =>
  componentsChunk().then(m => m.SidebarValueWrapper),
);
export const EditButton = loadable(() =>
  componentsChunk().then(m => m.EditButton),
);
export const ExpandButton = loadable(() =>
  componentsChunk().then(m => m.ExpandButton),
);

export const EmptyStateImage = loadable(() =>
  componentsChunk().then(m => m.EmptyStateImage),
);
export const ListEmptyState = loadable(() =>
  componentsChunk().then(m => m.ListEmptyState),
);
export const LoginPage = loadable(() =>
  componentsChunk().then(m => m.LoginPage),
);
export const Footer = loadable(() => componentsChunk().then(m => m.Footer));

export const ChartBackground = loadable(() =>
  componentsChunk().then(m => m.ChartBackground),
);
export const PercentageSlider = loadable(() =>
  componentsChunk().then(m => m.PercentageSlider),
);

export * from './DragAndDrop/types';
export * from './PercentageInput/types';
export const PercentageInput = loadable(() =>
  componentsChunk().then(m => m.PercentageInput),
);

export const TagsInput = loadable(() =>
  componentsChunk().then(m => m.TagsInput),
);

export const OwnerSelector = loadable(() =>
  componentsChunk().then(m => m.OwnerSelector),
);
export const InlineEditable = loadable(() =>
  componentsChunk().then(m => m.InlineEditable),
);
export const InlineSavableTextArea = loadable(() =>
  componentsChunk().then(m => m.InlineSavableTextArea),
);
export const InlineSave = loadable(() =>
  componentsChunk().then(m => m.InlineSave),
);
export const InlineVariant = loadable(() =>
  componentsChunk().then(m => m.InlineVariant),
);
export const ConfirmDialog = loadable(() =>
  componentsChunk().then(m => m.ConfirmDialog),
);
export const ChartGraphic = loadable(() =>
  componentsChunk().then(m => m.ChartGraphic),
);
export const DangerBox = loadable(() =>
  componentsChunk().then(m => m.DangerBox),
);
export const DangerButton = loadable(() =>
  componentsChunk().then(m => m.DangerButton),
);
export const DangerZone = loadable(() =>
  componentsChunk().then(m => m.DangerZone),
);
export const DraggableCard = loadable(() =>
  componentsChunk().then(m => m.DraggableCard),
);

export const SelectableCard = loadable(() =>
  componentsChunk().then(m => m.SelectableCard),
);

export const FormFieldsBox = loadable(() =>
  componentsChunk().then(m => m.FormFieldsBox),
);
export const FormSubmitButtons = loadable(() =>
  componentsChunk().then(m => m.FormSubmitButtons),
);

export const EditNameDialogForm = loadable(() =>
  componentsChunk().then(m => m.EditNameDialogForm),
);

export type { NumeralProps } from './Numeral';
export const Numeral = loadable(() => componentsChunk().then(m => m.Numeral));

export const TooltipToggleButton = loadable(() =>
  componentsChunk().then(m => m.TooltipToggleButton),
);
export const EmptyCard = loadable(() =>
  componentsChunk().then(m => m.EmptyCard),
);

export const VariantCard = loadable(() =>
  componentsChunk().then(m => m.VariantCard),
);
export const VariantCardHeader = loadable(() =>
  componentsChunk().then(m => m.VariantCardHeader),
);

export const WizardStepper = loadable(() =>
  componentsChunk().then(m => m.WizardStepper),
);

export const ActionMenu = loadable(() =>
  componentsChunk().then(m => m.ActionMenu),
);
export const ChipMenu = loadable(() => componentsChunk().then(m => m.ChipMenu));

export const AdvancedOptions = loadable(() =>
  componentsChunk().then(m => m.AdvancedOptions),
);

export const FilterBar = loadable(() =>
  componentsChunk().then(m => m.FilterBar),
);

export const ActiveFilters = loadable(() =>
  componentsChunk().then(m => m.ActiveFilters),
);

export type { FilterListEmptyStateProps } from './FilterList/types';
export const FilterListEmptyState = loadable(() =>
  componentsChunk().then(m => m.FilterListEmptyState),
);

export const FormControlCardLabel = loadable(() =>
  componentsChunk().then(m => m.FormControlCardLabel),
);

export const TimeWindowInput = loadable(() =>
  componentsChunk().then(m => m.TimeWindowInput),
);

export const SelectTimeWindow = loadable(() =>
  componentsChunk().then(m => m.SelectTimeWindow),
);

export const ImageUploadCard = loadable(() =>
  componentsChunk().then(m => m.ImageUploadCard),
);

export const ProgressEllipsis = loadable(() =>
  componentsChunk().then(m => m.ProgressEllipsis),
);

export const ChipInput = loadable(() =>
  componentsChunk().then(m => m.ChipInput),
);

export const ConfidenceWebsiteLink = loadable(() =>
  componentsChunk().then(m => m.ConfidenceWebsiteLink),
);

export * from './Hidden';
