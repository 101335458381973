import React from 'react';

import { Theme, WithStyles, createStyles, withStyles } from '@material-ui/core';

import { CommentZone } from '@spotify-confidence/plugin-comments-react';
import classNames from 'classnames';

import { VariantValue } from '../MetricResultTable';
import { useMetricResultContext } from './context';

const styles = (theme: Theme) =>
  createStyles({
    root: {
      display: 'flex',
      '& > *': {
        // min width zero to make noWrap for typography work
        // in the children
        minWidth: 0,
        flex: 1,
      },
      gap: theme.spacing(2),
      flexDirection: 'row',
      alignItems: 'center',
    },
    base: {},
    compared: {},
  });

export interface MeansProps extends WithStyles<typeof styles> {
  className?: string;
  component?: React.ElementType<{ className?: string }>;
}

const Means = ({
  classes,
  className,
  component: Component = 'div',
}: MeansProps) => {
  const { value: result } = useMetricResultContext();

  return (
    <Component className={classNames(classes.root, className)}>
      <CommentZone id="mean-base">
        <VariantValue
          className={classes.base}
          variant={result.baselineGroupId}
          value={result.result?.groupMeanAbs?.baseline ?? ''}
          sampleSize={result.baselineSampleSize}
          isFraction={result.isFraction}
        />
      </CommentZone>
      <CommentZone id="mean-compared">
        <VariantValue
          className={classes.compared}
          variant={result.comparedGroupId}
          value={result.result?.groupMeanAbs?.compared ?? ''}
          sampleSize={result.comparedSampleSize}
          isFraction={result.isFraction}
        />
      </CommentZone>
    </Component>
  );
};

export default withStyles(styles)(Means);
