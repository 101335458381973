import { EvaluationContextFieldOverrideFragment } from '@spotify-confidence/plugin-graphql';

export type SchemaItem = Omit<
  EvaluationContextFieldOverrideFragment,
  'name' | '__typename'
> & {
  name?: string;
  __typeName?: string;
};

export function isOverride(
  item: SchemaItem | EvaluationContextFieldOverrideFragment,
): item is EvaluationContextFieldOverrideFragment {
  return !!item.name;
}
