import React, { ReactNode } from 'react';

import { Box, Tooltip, Typography, TypographyProps } from '@material-ui/core';
import Help from '@material-ui/icons/HelpOutlined';

import { CommentZone } from '@spotify-confidence/plugin-comments-react';
import _ from 'lodash';

import {
  formatPercentage,
  hasPlannedEffectSize,
  isGuardrailMetric,
  isSuccessMetric,
  toNonDesiredDirection,
} from './helpers';
import { Metric } from './types';

export type MetricSubheaderProps = TypographyProps & {
  metric: Metric;
  extraText?: ReactNode;
  poweredEffect?: number;
  requiredSampleSize?: number;
  currentSampleSize?: number;
};

const formatNumber = (x: number | undefined | null) => {
  return !!x ? _.round(x, 2) : undefined;
};

const sampleSizeFormatter = new Intl.NumberFormat('en-US', {
  notation: 'compact',
  compactDisplay: 'short',
}).format;

export const MetricSubheader = ({
  metric,
  extraText,
  poweredEffect,
  requiredSampleSize,
  currentSampleSize,
  variant = 'body1',
  color = 'textSecondary',
  ...typographyProps
}: MetricSubheaderProps) => {
  const roundedPoweredEffect = hasPlannedEffectSize(metric)
    ? formatNumber(poweredEffect)
    : undefined;

  let action;
  let direction;
  let value: number | undefined = 0;

  if (isSuccessMetric(metric)) {
    action = 'Detect';
    direction = metric.preferredDirection.toLocaleLowerCase();
    value = metric.metricRole.minimumDetectableEffect;
  } else if (isGuardrailMetric(metric)) {
    action = 'Warn if';
    direction = toNonDesiredDirection(
      metric.preferredDirection,
    ).toLocaleLowerCase();
    value = metric.metricRole.nonInferiorityMargin;
  }

  let isPowered;
  let tooltip;
  if (requiredSampleSize && currentSampleSize) {
    isPowered = Number(requiredSampleSize) <= Number(currentSampleSize);
    tooltip = `${sampleSizeFormatter(
      currentSampleSize,
    )} sample size out of ${sampleSizeFormatter(requiredSampleSize)} required`;
  }

  return (
    metric.preferredDirection && (
      <Box display="inline-flex" alignItems="center" gridGap={4}>
        <CommentZone id="subheader">
          <Typography variant={variant} color={color} {...typographyProps}>
            {action} {direction}
            {value ? ` of at least ${formatPercentage(value)}%` : null}
            {roundedPoweredEffect && (
              <Typography
                variant={variant}
                component="span"
                color={isPowered ? 'textPrimary' : color}
              >
                {' '}
                (powered for {roundedPoweredEffect}%)
              </Typography>
            )}
          </Typography>
        </CommentZone>
        {extraText}
        {tooltip && (
          <Tooltip arrow interactive title={tooltip}>
            <Help fontSize="small" color="inherit" />
          </Tooltip>
        )}
      </Box>
    )
  );
};
