import { MetricsV1TableIssueTableIssueSeverity } from '@spotify-confidence/plugin-graphql';

export const severityToAlertType = (
  severity: MetricsV1TableIssueTableIssueSeverity | null | undefined,
) => {
  switch (severity) {
    case MetricsV1TableIssueTableIssueSeverity.Warning:
      return 'warning';
    case MetricsV1TableIssueTableIssueSeverity.Error:
      return 'error';
    case MetricsV1TableIssueTableIssueSeverity.Info:
      return 'info';
    default:
      return 'info';
  }
};
