import React from 'react';

import {
  Box,
  DialogActions,
  LinearProgress,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
} from '@material-ui/core';
import { Alert } from '@material-ui/lab';

import {
  CodeEditor,
  DialogBody,
  DialogHeader,
  useDialog,
  useDisplayNames,
} from '@spotify-confidence/core-react';
import {
  getTypeOrNull,
  useExposureCalculationQuery,
  useGetMetricCalculationQuery,
} from '@spotify-confidence/plugin-graphql';

export const ExposureCalculationDialog = ({
  exposureCalculation,
  exposureMetricCalculation,
}: {
  exposureCalculation: string;
  exposureMetricCalculation: string;
}) => {
  const { closeDialog } = useDialog();

  const {
    data: exposureData,
    loading: exposureLoading,
    error: exposureError,
  } = useExposureCalculationQuery({
    variables: { name: exposureCalculation },
  });

  const { data, loading, error } = useGetMetricCalculationQuery({
    variables: { name: exposureMetricCalculation },
  });

  const exposureCalculationData = getTypeOrNull(
    exposureData?.exposureCalculation,
    'MetricsV1ExposureCalculation',
  );

  const exposureMetricCalculationData = getTypeOrNull(
    data?.metricCalculation,
    'MetricsV1MetricCalculation',
  );

  const metricBucketStats =
    exposureMetricCalculationData?.metricResults?.metricBucketStats || [];

  const { displayNames } = useDisplayNames();

  const sqlJob = getTypeOrNull(
    exposureCalculationData?.sqlJob,
    'MetricsV1SqlJob',
  );

  return (
    <>
      <DialogHeader onClose={closeDialog} title="Exposure calculation" />
      <DialogBody autoHeight>
        {loading || exposureLoading ? <LinearProgress /> : null}
        {!loading && !exposureLoading && (
          <Box margin={1}>
            {sqlJob?.failedQueryResult?.error && (
              <Alert severity="error">{sqlJob?.failedQueryResult?.error}</Alert>
            )}
            {metricBucketStats.length > 0 && (
              <Table size="small" aria-label="results">
                <TableHead>
                  <TableRow>
                    <TableCell>Group</TableCell>
                    <TableCell>Count</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {metricBucketStats.map(bucket => (
                    <TableRow key={bucket.variant}>
                      <TableCell component="th" scope="row">
                        {displayNames.get(bucket.variant)}
                      </TableCell>
                      <TableCell>
                        {bucket.summaryStats?.averageSummaryStats?.count}
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            )}
            {exposureCalculationData?.sql && (
              <CodeEditor
                value={exposureCalculationData.sql}
                mode="sql"
                readOnly
              />
            )}
          </Box>
        )}
      </DialogBody>
      <DialogActions>
        <Box width="100%">
          {error ? (
            <Box width="100%">
              <Alert severity="error">{error.message}</Alert>
            </Box>
          ) : null}
          {exposureError ? (
            <Box width="100%">
              <Alert severity="error">{exposureError.message}</Alert>
            </Box>
          ) : null}
        </Box>
      </DialogActions>
    </>
  );
};
