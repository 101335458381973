import React from 'react';

import { Box, DialogActions, TextField, Typography } from '@material-ui/core';
import { Alert } from '@material-ui/lab';

import {
  ConfidenceWebsiteLink,
  DialogBody,
  DialogHeader,
  FormSubmitButtons,
  extractLastNameComponent,
  useAlert,
  useDialog,
  useNavigate,
} from '@spotify-confidence/core-react';
import {
  ListApiClientsDocument,
  isType,
  useCreateApiClientMutation,
} from '@spotify-confidence/plugin-graphql';

import { useRouteRef } from '@backstage/core-plugin-api';

import { apiClientRouteRef } from '../../../routes';

export const CreateApiClient = () => {
  const [displayName, setDisplayName] = React.useState('');
  const { closeDialog } = useDialog();
  const navigate = useNavigate();
  const alert = useAlert();
  const apiClientRoute = useRouteRef(apiClientRouteRef);

  const [create, { loading, error }] = useCreateApiClientMutation({
    refetchQueries: [{ query: ListApiClientsDocument }],
    onCompleted: response => {
      if (isType(response?.createApiClient, 'IamV1ApiClient')) {
        navigate(
          apiClientRoute({
            id: extractLastNameComponent(response.createApiClient.name)!,
          }),
        );
      } else {
        alert.post({
          severity: 'error',
          message: response.createApiClient?.message ?? 'Something went wrong',
        });
      }
    },
  });
  const createApiClient = () => {
    create({
      variables: {
        apiClient: {
          displayName,
        },
      },
    });
  };
  return (
    <>
      <DialogHeader title="Create API Client" />
      <DialogBody>
        {error && <Alert severity="error">{error.message}</Alert>}
        <TextField
          name="createapiclient"
          variant="outlined"
          required
          label="Display name"
          onChange={e => setDisplayName(e.target.value)}
          fullWidth
          autoFocus
        />
        <Box mt={3}>
          <Typography variant="body2">
            Read more about API Clients{' '}
            <ConfidenceWebsiteLink
              underline="always"
              route="/docs/api/quickstart#create-an-api-client"
            >
              in the documentation.
            </ConfidenceWebsiteLink>
          </Typography>
        </Box>
      </DialogBody>
      <DialogActions>
        <FormSubmitButtons
          onCancel={() => closeDialog()}
          onSubmit={createApiClient}
          disabled={loading}
          label="Create"
        />
      </DialogActions>
    </>
  );
};
