import React from 'react';

export const useStatsConfigDescription = ({
  alpha,
  power,
  fallbackSampleSize,
  sequential,
}: {
  sequential?: boolean;
  alpha?: number | null;
  power?: number | null;
  fallbackSampleSize?: number | null;
}) => {
  const config = React.useMemo(() => {
    const config_ = [];
    config_.push(alpha ? `Alpha: ${alpha * 100}%` : 'No alpha');
    config_.push(power ? `Power: ${power * 100}%` : 'No power');

    if (sequential) {
      config_.push(
        fallbackSampleSize
          ? `Expected sample size: ${fallbackSampleSize}`
          : 'No expected sample size set',
      );
    }

    return config_;
  }, [alpha, power, fallbackSampleSize, sequential]);

  const configDescription = config.join(' / ');

  return {
    config,
    configDescription,
  };
};
