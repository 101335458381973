import { RuleType } from '../../../../domain/rule.model';
import { DefaultRuleView } from './default';
import { OverrideRuleView } from './override';
import { RolloutRuleView } from './rollout';

export const getRuleViewComponent = (ruleType = RuleType.COMPACT) => {
  switch (ruleType) {
    case RuleType.OVERRIDE:
      return OverrideRuleView;
    case RuleType.ROLLOUT:
      return RolloutRuleView;
    default:
      return DefaultRuleView;
  }
};
