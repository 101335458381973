import loadable from '@loadable/component';

const backstageThemesChunk = () =>
  import(/* webpackChunkName: "backstage-theme" */ './BackstageThemeProvider');

export const BackstageDarkThemeProvider = loadable(() =>
  backstageThemesChunk().then(m => m.BackstageDarkThemeProvider),
);

export const BackstageLightThemeProvider = loadable(() =>
  backstageThemesChunk().then(m => m.BackstageLightThemeProvider),
);

export {
  EncoreDarkThemeProvider,
  EncoreLightThemeProvider,
} from './EncoreThemeProvider';
