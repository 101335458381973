import React from 'react';
import { useNavigate, useParams } from 'react-router-dom';

import {
  Box,
  Card,
  CardContent,
  CircularProgress,
  Collapse,
  Grid,
  TextField,
  Typography,
  styled,
} from '@material-ui/core';
import { Alert, Autocomplete } from '@material-ui/lab';

import {
  Breadcrumbs,
  DangerBox,
  DangerButton,
  DangerZone,
  InlineSave,
  PageLayout,
  SecretText,
  useAlert,
} from '@spotify-confidence/core-react';
import {
  DetailedOAuthAppFragment,
  getTypeOrNull,
  isType,
  useDeleteOAuthAppMutation,
  useOAuthAppDetailsQuery,
  useUpdateOAuthAppMutation,
} from '@spotify-confidence/plugin-graphql';
import { PermissionUtils } from '@spotify-confidence/plugin-permissions-react';
import { isEqual, omit } from 'lodash';

import { Link } from '@backstage/core-components';
import { useRouteRef } from '@backstage/core-plugin-api';

import { oauthAppsRouteRef, rootRouteRef } from '../../../routes';

type OAuthAppState = Omit<DetailedOAuthAppFragment, '__typename'>;

const LogoPreview = styled('img')(({ theme }) => ({
  maxWidth: '100%',
  height: 70,
  marginRight: theme.spacing(2),
}));

export const OauthApp = () => {
  const { id } = useParams();
  const name = `oauthApps/${id}`;
  const alert = useAlert();

  const navigate = useNavigate();
  const adminRoute = useRouteRef(rootRouteRef);
  const oauthAppsRoute = useRouteRef(oauthAppsRouteRef);

  const [input, setInput] = React.useState<OAuthAppState>({
    name: '',
    displayName: '',
    description: '',
    allowedWebOrigins: [],
    allowedLogoutUrls: [],
    allowedCallbackUrls: [],
    clientId: '',
    clientSecret: '',
  });

  const [updateOauthApp, { loading: isUpdating }] = useUpdateOAuthAppMutation();

  const [deleteOAuthApp, { loading: isDeleting, error: deleteError }] =
    useDeleteOAuthAppMutation({
      variables: {
        name: name,
      },
      update: cache => {
        cache.evict({
          id: cache.identify({
            __typename: 'IamV1OAuthApp',
            name: name,
          }),
        });
      },
      onCompleted: () => {
        navigate(oauthAppsRoute());
      },
    });

  const { data, loading } = useOAuthAppDetailsQuery({
    variables: {
      name,
    },
    onCompleted: oauthAppData => {
      if (
        oauthAppData.oAuthApp &&
        isType(oauthAppData.oAuthApp, 'IamV1OAuthApp')
      ) {
        setInput(oauthAppData.oAuthApp);
      } else {
        alert.post({
          severity: 'error',
          message: oauthAppData.oAuthApp?.message ?? 'Somthing went wrong',
        });
      }
    },
  });
  const oAuthApp = getTypeOrNull(data?.oAuthApp, 'IamV1OAuthApp');
  const textChanged = !loading && !isEqual(input, oAuthApp);
  const canEdit = PermissionUtils.hasRelation(oAuthApp, 'can_edit');

  const updateState = React.useCallback(
    (newData: Partial<DetailedOAuthAppFragment>) => {
      setInput(v => ({ ...v, ...newData }));
    },
    [setInput],
  );

  const reset = () => {
    if (oAuthApp) setInput(oAuthApp);
  };

  const save = async () => {
    await updateOauthApp({
      variables: {
        oauthApp: {
          ...omit(input, 'clientId', 'clientSecret', 'resourceRelations'),
        },
      },
    });
  };

  return (
    <PageLayout
      narrow
      isLoading={loading}
      title={oAuthApp?.displayName}
      headerBreadcrumbs={
        <Breadcrumbs>
          <Link to={adminRoute()}>Admin</Link>
          <Link to={oauthAppsRoute()}>OAuth Apps</Link>
          <Typography>{oAuthApp?.displayName}</Typography>
        </Breadcrumbs>
      }
    >
      <Card>
        <CardContent>
          {oAuthApp && (
            <Grid container spacing={2}>
              <Grid item md={12}>
                <Typography color="secondary" variant="overline">
                  Client ID
                </Typography>
                <Typography
                  display="block"
                  variant="body2"
                  gutterBottom
                  data-testid="oauth-app-client-id"
                >
                  {oAuthApp?.clientId}
                </Typography>
              </Grid>
              <Grid item md={12}>
                <Typography color="secondary" variant="overline">
                  Client secret
                </Typography>
                <Typography
                  display="block"
                  variant="body2"
                  data-testid="oauth-app-client-secret"
                >
                  <SecretText>{oAuthApp.clientSecret ?? ''}</SecretText>
                </Typography>
              </Grid>
            </Grid>
          )}
        </CardContent>
      </Card>
      <Box mt={3}>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <TextField
              label="Display name"
              name="name"
              variant="outlined"
              value={input.displayName}
              disabled={!canEdit}
              onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                updateState({ displayName: e.target.value })
              }
              fullWidth
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              label="Description"
              name="description"
              variant="outlined"
              value={input.description}
              disabled={!canEdit}
              onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                updateState({
                  description:
                    (e.target.value ?? '').length === 0 ? null : e.target.value,
                })
              }
              fullWidth
            />
          </Grid>
          <Grid item xs={12}>
            <MultiStringField
              label="Allowed Callback Urls"
              id="allowed-callback-urls"
              value={input.allowedCallbackUrls}
              disabled={!canEdit}
              onChange={(newValue: string[]) => {
                updateState({
                  allowedCallbackUrls: newValue,
                });
              }}
            />
          </Grid>
          <Grid item xs={12}>
            <MultiStringField
              label="Allowed Logout Urls"
              value={input.allowedLogoutUrls}
              disabled={!canEdit}
              onChange={(newValue: string[]) => {
                updateState({
                  allowedLogoutUrls: newValue,
                });
              }}
            />
          </Grid>
          <Grid item xs={12}>
            <MultiStringField
              label="Allowed CORS origins"
              value={input.allowedWebOrigins}
              disabled={!canEdit}
              onChange={(newValue: string[]) => {
                updateState({
                  allowedWebOrigins: newValue,
                });
              }}
            />
          </Grid>
          <Grid item xs={12}>
            <Box display="flex" alignItems="center">
              {input.logoUri && (
                <>
                  <LogoPreview src={input.logoUri} alt="logo" />
                </>
              )}
              <TextField
                label="Logo URI"
                variant="outlined"
                value={input.logoUri}
                disabled={!canEdit}
                onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                  updateState({ logoUri: e.target.value })
                }
                fullWidth
              />
            </Box>
          </Grid>
        </Grid>
        <Collapse in={textChanged}>
          <Box textAlign="right" paddingTop={2}>
            <InlineSave
              onSave={save}
              saving={isUpdating}
              onCancel={reset}
              variant="text"
            />
          </Box>
        </Collapse>
      </Box>

      <Box my={3}>
        {deleteError && <Alert severity="error">{deleteError.message}</Alert>}

        <DangerZone>
          <DangerBox>
            <strong>Delete OAuth App</strong>
            <DangerButton
              variant="contained"
              disabled={isDeleting || loading || !canEdit}
              color="inherit"
              endIcon={isDeleting && <CircularProgress size="1em" />}
              onClick={() => deleteOAuthApp()}
            >
              Delete
            </DangerButton>
          </DangerBox>
        </DangerZone>
      </Box>
    </PageLayout>
  );
};

// This components keeps the inputted string even if the user has not pressed enter
function MultiStringField({
  id,
  value,
  onChange,
  label,
  disabled,
}: {
  id?: string;
  label: string;
  value: string[];
  onChange: (v: string[]) => void;
  disabled?: boolean;
}) {
  const [input, setInput] = React.useState('');
  return (
    <Autocomplete<string, true, false, true>
      freeSolo
      clearOnBlur
      multiple
      id={id}
      disabled={disabled}
      onBlur={() => {
        if (input !== '') {
          onChange([...value, input]);
        }
      }}
      value={value}
      onInputChange={(_e, v) => setInput(v)}
      options={[]}
      onChange={(_e: any, newValue: string[]) => {
        onChange(newValue ?? []);
      }}
      fullWidth
      renderInput={params => (
        <TextField {...params} label={label} variant="outlined" />
      )}
    />
  );
}
