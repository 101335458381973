import {
  createPlugin,
  createRoutableExtension,
} from '@backstage/core-plugin-api';

import { rootRouteRef, surfaceIndexRouteRef, surfaceRouteRef } from './routes';

export const surfacesPlugin = createPlugin({
  id: 'surfaces',
  routes: {
    root: rootRouteRef,
    surface: surfaceRouteRef,
    surfaces: surfaceIndexRouteRef,
  },
});

export const ConfidenceSurfacesPage = surfacesPlugin.provide(
  createRoutableExtension({
    name: 'ConfidenceSurfacesPage',
    component: () =>
      import(
        /* webpackChunkName: "confidence-surfaces", webpackPrefetch: 10 */ './Router'
      ).then(m => m.Router),
    mountPoint: rootRouteRef,
  }),
);
