import React, { Fragment } from 'react';

import {
  Box,
  Collapse,
  IconButton,
  Tooltip,
  Typography,
  makeStyles,
} from '@material-ui/core';
import ExpandLess from '@material-ui/icons/ExpandLess';
import ExpandMore from '@material-ui/icons/ExpandMore';

import { EventSchemaEntryFragment } from '@spotify-confidence/plugin-graphql';
import classNames from 'classnames';

import { differenceBetweenSchemas } from '../domain/schema.helpers';
import { DroppedChip } from './DroppedChip';
import { FieldIcon } from './FieldIcon';

export type SchemaDiffProps = {
  existingSchema: EventSchemaEntryFragment[];
  updatedSchema: EventSchemaEntryFragment[];
  onlyShowDifferences?: boolean;
};

export function SchemaDiff({
  existingSchema,
  updatedSchema,
  onlyShowDifferences,
}: SchemaDiffProps) {
  const diff = differenceBetweenSchemas(existingSchema, updatedSchema);
  const [open, setOpen] = React.useState(!onlyShowDifferences);

  const toggleOpen = () => {
    setOpen(!open);
  };

  return (
    <div>
      {updatedSchema.map((entry, i) => (
        <SchemaEntry
          key={i}
          path={[entry.key]}
          entry={entry}
          onlyShowDifferences={!open}
          diff={diff}
        />
      ))}
      <Box
        marginTop={1}
        display="flex"
        alignItems="center"
        justifyContent="center"
      >
        <Tooltip
          title={open ? 'Minimize' : 'Show full schema'}
          enterDelay={500}
        >
          <IconButton size="small" onClick={toggleOpen}>
            {open ? <ExpandLess /> : <ExpandMore />}
          </IconButton>
        </Tooltip>
      </Box>
    </div>
  );
}

function SchemaStruct({
  path,
  entry,
  onlyShowDifferences,
  diff,
}: {
  path: string[];
  entry: EventSchemaEntryFragment;
  onlyShowDifferences?: boolean;
  diff?: string[][];
}) {
  return (
    <Fragment>
      {entry.value?.structSchema?.schema?.map(e => (
        <SchemaEntry
          key={e.key}
          path={[...path, e.key]}
          entry={e}
          onlyShowDifferences={onlyShowDifferences}
          diff={diff}
        />
      ))}
    </Fragment>
  );
}

const useStyles = makeStyles(theme => ({
  root: {
    padding: theme.spacing(1),
    borderBottom: `1px solid ${theme.palette.divider}`,
  },
  added: {
    background: theme.palette.success.main,
    color: theme.palette.success.contrastText,
  },
}));
const qualified = (path: string[]) => `${path.join('.')}.`;

function SchemaEntry({
  path,
  entry,
  onlyShowDifferences,
  diff,
}: {
  path: string[];
  entry: EventSchemaEntryFragment | null;
  onlyShowDifferences?: boolean;
  diff?: string[][];
}) {
  const classes = useStyles();

  const isAdded = diff?.some(d => qualified(path).startsWith(qualified(d)));

  const hasDifferentChildren = diff?.some(d =>
    qualified(d).startsWith(qualified(path)),
  );

  const isDifferent =
    diff?.some(d => qualified(d) === qualified(path)) || isAdded;

  return (
    <Collapse
      in={!onlyShowDifferences || isDifferent || hasDifferentChildren}
      unmountOnExit
    >
      <Box className={classNames(classes.root)}>
        <Box
          paddingLeft={2 * (path.length - 1)}
          display="flex"
          justifyContent="space-between"
        >
          <Box display="inline-flex">
            <Typography
              variant="body1"
              component="span"
              color={isDifferent ? 'textPrimary' : 'textSecondary'}
            >
              {entry?.key}
            </Typography>
            {isDifferent && <DroppedChip size="small" />}
          </Box>
          <FieldIcon
            type={entry?.value?.structSchema ? 'structSchema' : 'stringSchema'}
            color={isDifferent ? 'inherit' : 'disabled'}
          />
        </Box>
      </Box>
      {entry?.value?.structSchema?.schema && (
        <SchemaStruct
          path={path}
          entry={entry}
          diff={diff}
          onlyShowDifferences={onlyShowDifferences && !isDifferent}
        />
      )}
    </Collapse>
  );
}
