import React from 'react';

import { MenuItem } from '@material-ui/core';

import _ from 'lodash';

import { ChipInput } from '../ChipInput';

export const ChipBooleanPicker = ({
  handleChange,
  value,
  disabled,
  readOnly,
}: {
  handleChange: (val: string) => void;
  value: string;
  disabled?: boolean;
  readOnly?: boolean;
}) => {
  return (
    <ChipInput
      select
      value={value}
      onChange={e => handleChange(e.target.value as string)}
      disabled={disabled || readOnly}
      InputProps={{ readOnly }}
      name="boolean-select"
    >
      {['TRUE', 'FALSE'].map(option => (
        <MenuItem key={option} value={option}>
          {_.capitalize(option)}
        </MenuItem>
      ))}
    </ChipInput>
  );
};
