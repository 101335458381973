import React from 'react';

import { Theme, makeStyles } from '@material-ui/core';
import { Breakpoint } from '@material-ui/core/styles/createBreakpoints';

type StyleProps = { width: Breakpoint };

export type NarrowBoxProps = React.HTMLProps<HTMLDivElement> &
  Partial<StyleProps>;

const useStyles = makeStyles<Theme, StyleProps>(theme => ({
  root: ({ width }) => ({
    width: '100%',
    maxWidth: '100%',
    margin: '0 auto',
    [theme.breakpoints.up(width)]: {
      width: theme.breakpoints.values[width],
    },
  }),
}));

export const NarrowBox = ({ width = 'md', ...props }: NarrowBoxProps) => {
  const classes = useStyles({ width });
  return <div className={classes.root} {...props} />;
};
