import React from 'react';

import {
  UseWorkflowPermissionGuardOptions,
  useWorkflowPermissionGuard,
} from './useWorkflowPermissionGuard';

type WorkflowPermissionGuardProps = UseWorkflowPermissionGuardOptions & {
  children: (
    props: ReturnType<typeof useWorkflowPermissionGuard>,
  ) => React.ReactElement;
};

export function WorkflowPermissionGuard({
  children,
  ...options
}: WorkflowPermissionGuardProps) {
  const props = useWorkflowPermissionGuard(options);

  return children(props);
}
