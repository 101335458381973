import React from 'react';

import { Button, Chip, MenuItem } from '@material-ui/core';
import ExpandMore from '@material-ui/icons/ExpandMore';
import { Autocomplete } from '@material-ui/lab';

import {
  ChipInput,
  ContextMenu,
  ObjUtils,
} from '@spotify-confidence/core-react';
import {
  MetricFilterCriterionFragment,
  MetricsV1FilterEqRuleInput,
  MetricsV1FilterRangeRule,
  MetricsV1FilterSetRuleInput,
} from '@spotify-confidence/plugin-graphql';
import _ from 'lodash';

import { OperatorOption, RangeOperatorOption, ValueType } from './Criteria';

const formatValue = (valueType: ValueType) => (v: string | number) => {
  return valueType === 'numberValue' && v !== '' ? Number(v) : v;
};
type RuleInputProps<T> = {
  valueType: ValueType;
  attribute: string;
  value?: T | null;
  onChange: (v: Partial<MetricFilterCriterionFragment>) => void;
};
export const MetricFilterEqRule = ({
  valueType,
  value,
  attribute,
  onChange,
}: RuleInputProps<MetricsV1FilterEqRuleInput>) => {
  if (valueType === 'boolValue') {
    return 'true';
  }
  const v = value?.value[valueType] ?? null;
  return (
    <ChipInput
      placeholder={valueType.replace('Value', '')}
      type={valueType === 'numberValue' ? 'number' : 'text'}
      onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
        const newValue = e.target.value;
        const formattedValue = formatValue(valueType)(newValue);
        onChange({
          attribute: {
            attribute,
            eqRule: {
              value: {
                [valueType]: formattedValue,
              },
            },
          },
        });
      }}
      value={v}
    />
  );
};

export const MetricFilterSetRule = ({
  valueType,
  value,
  attribute,
  onChange,
}: RuleInputProps<MetricsV1FilterSetRuleInput>) => {
  const values = (value?.values ?? []).map(
    v => v[valueType]?.toString() ?? 'unknown',
  );

  return (
    <Autocomplete
      disableClearable
      autoSelect
      renderInput={params => (
        <ChipInput
          {...params}
          InputProps={{
            ...params.InputProps,
            type: valueType === 'numberValue' ? 'number' : 'tex',
          }}
          placeholder={valueType.replace('Value', '')}
        />
      )}
      renderTags={(tagValues, getTagProps) =>
        tagValues.map((option, index) => (
          <Chip
            key={option}
            size="small"
            label={option}
            {...getTagProps({ index })}
            disabled={false}
          />
        ))
      }
      multiple
      options={[] as string[]}
      freeSolo
      value={values}
      onChange={(_e, newV) => {
        onChange({
          attribute: {
            attribute,
            setRule: {
              values: (newV ?? []).map(v => ({
                [valueType]: formatValue(valueType)(v),
              })),
            },
          },
        });
      }}
    />
  );
};

const isRangeValue = (value?: OperatorOption): value is RangeOperatorOption =>
  !!value && value.ruleType === 'rangeRule';

export const MetricFilterRangeRule = ({
  value,
  attribute,
  valueType,
  onChange,
  operator,
}: RuleInputProps<MetricsV1FilterRangeRule> & {
  operator?: OperatorOption;
}) => {
  if (!isRangeValue(operator)) return null;

  const keys = Object.keys(
    ObjUtils.cleanNulls(value ?? {}),
  ) as (keyof MetricsV1FilterRangeRule)[];

  const startKey = keys.find(k => k.startsWith('start'));
  const endKey = keys.find(k => k.startsWith('end'));

  const startValue = startKey
    ? value?.[startKey]?.[valueType]?.toString() ?? ''
    : null;
  const endValue = endKey
    ? value?.[endKey]?.[valueType]?.toString() ?? ''
    : null;

  const showStart = !_.isNil(startValue) && startKey;
  const showEnd = !_.isNil(endValue) && endKey;
  const isClosedRange = showStart && showEnd;
  const defaultProps = {
    placeholder: valueType.replace('Value', ''),
    type: valueType === 'numberValue' ? 'number' : 'text',
  };
  const defaultEnd = showEnd
    ? {
        [endKey]: {
          [valueType]: formatValue(valueType)(endValue),
        },
      }
    : {};

  const defaultStart = showStart
    ? {
        [startKey]: {
          [valueType]: formatValue(valueType)(startValue),
        },
      }
    : {};

  const handleChange = (v: MetricsV1FilterRangeRule) => {
    onChange({
      attribute: {
        attribute,
        rangeRule: v,
      },
    });
  };

  return (
    <>
      {showStart && (
        <ChipInput
          {...defaultProps}
          type={valueType === 'numberValue' ? 'number' : 'text'}
          onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
            handleChange({
              [startKey]: {
                [valueType]: formatValue(valueType)(e.target.value),
              },
              ...defaultEnd,
            });
          }}
          value={startValue}
          InputProps={{
            endAdornment: isClosedRange && (
              <InclusionPicker
                value={startKey}
                onChange={newKey => {
                  handleChange({
                    [newKey]: {
                      [valueType]: formatValue(valueType)(startValue),
                    },
                    ...defaultEnd,
                  });
                }}
              />
            ),
          }}
        />
      )}
      {isClosedRange && <span> and </span>}
      {showEnd && (
        <ChipInput
          {...defaultProps}
          onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
            handleChange({
              [endKey]: {
                [valueType]: formatValue(valueType)(e.target.value),
              },
              ...defaultStart,
            });
          }}
          InputProps={{
            endAdornment: isClosedRange && (
              <InclusionPicker
                value={endKey}
                onChange={newKey => {
                  handleChange({
                    [newKey]: {
                      [valueType]: formatValue(valueType)(endValue),
                    },
                    ...defaultStart,
                  });
                }}
              />
            ),
          }}
          value={endValue}
        />
      )}
    </>
  );
};

function InclusionPicker({
  value,
  onChange,
}: {
  value: keyof MetricsV1FilterRangeRule;
  onChange: (k: keyof MetricsV1FilterRangeRule) => void;
}) {
  const handleChange = (v: string) => {
    return () => {
      const prefix = value.replace('Inclusive', '').replace('Exclusive', '');
      const newKey = `${prefix}${v}` as keyof MetricsV1FilterRangeRule;
      onChange(newKey);
    };
  };
  const options = ['Inclusive', 'Exclusive'];
  return (
    <ContextMenu
      closeOnSelect
      renderButton={p => (
        <Button
          {...p}
          endIcon={<ExpandMore color="inherit" />}
          size="small"
          variant="text"
        >
          {options.find(o => value.includes(o))?.slice(0, 3)}
        </Button>
      )}
      renderMenu={() =>
        options.map(option => (
          <MenuItem key={option} divider onClick={handleChange(option)}>
            {option}
          </MenuItem>
        ))
      }
    />
  );
}
