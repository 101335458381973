import React, { useEffect, useState } from 'react';
import { useAsyncFn } from 'react-use';

import {
  Box,
  Checkbox,
  Chip,
  CircularProgress,
  Collapse,
  DialogActions,
  DialogContent,
  DialogContentText,
  FormHelperText,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Typography,
  alpha,
  makeStyles,
  styled,
} from '@material-ui/core';

import {
  DialogForm,
  DialogHeader,
  FormSubmitButtons,
  extractLastNameComponent,
  useAlert,
} from '@spotify-confidence/core-react';
import {
  SurfaceFragment,
  getTypeOrNull,
  isType,
  useSurfacesExclusivityGroupsQuery,
} from '@spotify-confidence/plugin-graphql';
import { SurfaceAutocomplete } from '@spotify-confidence/plugin-surfaces';
import { useWorkflowExtension } from '@spotify-confidence/plugin-workflows';
import _ from 'lodash';

const GLOBAL_SURFACE = 'surfaces/global';

const ListWrapper = styled(List)(({ theme }) => ({
  border: `1px solid ${theme.palette.divider}`,
  borderRadius: theme.shape.borderRadius,
}));

const useStyles = makeStyles(theme => ({
  surface: {
    background: alpha(theme.palette.info.light, 0.1),
    cursor: 'pointer',
    '&:hover': {
      background: alpha(theme.palette.info.light, 0.05),
    },
  },
  group: {
    alignItems: 'flex-start',
  },
}));

export const SurfacesDialog = ({
  onSubmit,
  onClose,
  tags,
  workflowId,
  surfaces: initialSurfaces,
}: {
  surfaces: SurfaceFragment[];
  workflowId: string;
  tags: string[];
  onSubmit: (
    surfaces: SurfaceFragment[],
    selectedGroups: string[],
  ) => Promise<void>;
  onClose: () => void;
}) => {
  const alert = useAlert();
  const classes = useStyles();
  const [surfaces, setSurfaces] = useState<SurfaceFragment[]>(initialSurfaces);
  const [selectedGroups, setSelectedGroups] = React.useState<string[]>(tags);
  const workflowExtension = useWorkflowExtension({ workflowId });

  const [{ error, loading }, submit] = useAsyncFn(async () => {
    await onSubmit(surfaces, selectedGroups);
  }, [onSubmit, selectedGroups, JSON.stringify(surfaces.map(s => s.name))]);

  useEffect(() => {
    if (error) {
      alert.post({
        message: error.message,
        severity: 'error',
      });
    }
  }, [error, alert]);

  // Workaround to handle instances that was created before global surfaces were introduced.
  const hasGlobalSurface = initialSurfaces.some(
    s => s.name === 'surfaces/global',
  );

  const filterString = React.useMemo(
    () => surfaces.map(s => `name:"${s.name}"`).join(' OR '),
    [surfaces],
  );
  const [prevSurfaces, setPrevSurfaces] = React.useState<string[]>(
    initialSurfaces.map(s => s.name),
  );
  const { data, loading: loadingGroups } = useSurfacesExclusivityGroupsQuery({
    variables: {
      filter: filterString,
    },
    onCompleted: response => {
      if (isType(response.surfaces, 'WorkflowV1ListSurfacesResponse')) {
        const newSurfaces =
          getTypeOrNull(response.surfaces, 'WorkflowV1ListSurfacesResponse')
            ?.surfaces ?? [];
        const added = _.difference(
          newSurfaces.map(s => s.name),
          prevSurfaces,
        );
        if (added.length !== 0) {
          const suggestedGroups = newSurfaces
            .filter(f => added.includes(f.name))
            .flatMap(s => {
              return (
                getTypeOrNull(
                  s.exclusivityGroups,
                  'WorkflowV1ListExclusivityGroupsResponse',
                )?.exclusivityGroups ?? []
              ).filter(e => e.isDefault);
            })
            .map(g => g.name);
          setSelectedGroups(v => [...v, ...suggestedGroups]);
        }
        setPrevSurfaces(newSurfaces.map(s => s.name));
      }
    },
  });

  const groupsBySurface = (
    getTypeOrNull(data?.surfaces, 'WorkflowV1ListSurfacesResponse')?.surfaces ??
    []
  ).map(s => ({
    ...s,
    exclusivityGroups:
      getTypeOrNull(
        s.exclusivityGroups,
        'WorkflowV1ListExclusivityGroupsResponse',
      )?.exclusivityGroups ?? [],
  }));

  const toggleGroup = (exclusivityGroup: string) => {
    setSelectedGroups(v => {
      if (v.includes(exclusivityGroup)) {
        return v.filter(g => g !== exclusivityGroup);
      }
      return [...v, exclusivityGroup];
    });
  };

  const isAllSelectedInSurface = (surface: string) => {
    const exclusivityGroups = (
      groupsBySurface.find(s => s.name === surface)?.exclusivityGroups ?? []
    ).map(e => e.name);
    return (
      selectedGroups.filter(g => g.startsWith(surface)).length ===
      exclusivityGroups.length
    );
  };

  const toggleAll = (surface: string) => {
    setSelectedGroups(v => {
      const exclusivityGroups = (
        groupsBySurface.find(s => s.name === surface)?.exclusivityGroups ?? []
      ).map(e => e.name);
      const otherValues = v.filter(g => !g.startsWith(surface));
      const allSelected = isAllSelectedInSurface(surface);

      if (allSelected) {
        return otherValues;
      }
      return [...otherValues, ...exclusivityGroups];
    });
  };

  useEffect(() => {
    const surfaceNames = surfaces.map(s => s.name);
    setSelectedGroups(v =>
      v.filter(groupName => surfaceNames.some(sn => groupName.startsWith(sn))),
    );
  }, [surfaces]);

  const showExclusivityGroups = groupsBySurface.some(
    s => s.exclusivityGroups.length > 0,
  );
  const instanceType = workflowExtension?.title ?? 'experiment';
  return (
    <DialogForm
      onSubmit={e => {
        e.preventDefault();
        submit();
      }}
    >
      <DialogHeader title="Surfaces and Exclusivity" />
      <DialogContent>
        <DialogContentText>
          Select surfaces which user experience that this workflow instance
          impacts, or surfaces that you need to coordinate with for some other
          reason.
        </DialogContentText>
        <SurfaceAutocomplete
          multiple
          value={surfaces}
          mandatorySurfaces={hasGlobalSurface ? [GLOBAL_SURFACE] : []}
          onChange={(_e, newSurfaces, reason) => {
            if (hasGlobalSurface && reason === 'clear') {
              setSurfaces(surfaces.filter(s => s.name === GLOBAL_SURFACE));
            } else {
              setSurfaces(newSurfaces);
            }
          }}
          textFieldProps={{
            variant: 'outlined',
            margin: 'dense',
          }}
          disableClearable={
            hasGlobalSurface &&
            surfaces.length === 1 &&
            surfaces[0].name === GLOBAL_SURFACE
          }
        />
        <Collapse in={showExclusivityGroups}>
          <>
            <Box my={2}>
              <Typography variant="h6">
                <strong>Exclusivity groups</strong>
              </Typography>
              <FormHelperText>
                Your {instanceType} will not overlap with any {instanceType}s
                that has at least one exclusivity group in common with yours.
              </FormHelperText>
            </Box>
            {loadingGroups && !data && (
              <Box display="flex" justifyContent="center">
                <CircularProgress />
              </Box>
            )}
            <ListWrapper>
              {groupsBySurface.map(surface => {
                if (surface.exclusivityGroups.length === 0) return null;
                const allSelected = isAllSelectedInSurface(surface.name);
                const isSomeSelected =
                  selectedGroups.filter(e => e.startsWith(surface.name))
                    .length !== 0;
                return (
                  <React.Fragment key={surface.name}>
                    <ListItem
                      className={classes.surface}
                      divider
                      onClick={() => toggleAll(surface.name)}
                    >
                      <ListItemIcon>
                        <Checkbox
                          edge="start"
                          indeterminate={isSomeSelected && !allSelected}
                          checked={allSelected}
                          tabIndex={-1}
                          disableRipple
                        />
                      </ListItemIcon>
                      <ListItemText
                        primaryTypographyProps={{ variant: 'body1' }}
                        primary={<strong>{surface.displayName}</strong>}
                        secondary={surface.description}
                      />
                    </ListItem>
                    <List>
                      {surface.exclusivityGroups.map(e => (
                        <ListItem
                          key={e.name}
                          button
                          className={classes.group}
                          onClick={() => toggleGroup(e.name)}
                        >
                          <ListItemIcon>
                            <Checkbox
                              edge="start"
                              checked={selectedGroups.includes(e.name)}
                              tabIndex={-1}
                              disableRipple
                            />
                          </ListItemIcon>
                          <ListItemText
                            primaryTypographyProps={{ variant: 'body2' }}
                            primary={
                              <Box
                                display="inline-flex"
                                gridGap={4}
                                alignItems="center"
                              >
                                {extractLastNameComponent(e.name)}
                                {e.isDefault && (
                                  <Chip label="Suggested" size="small" />
                                )}
                              </Box>
                            }
                            secondary={e.description}
                          />
                        </ListItem>
                      ))}
                    </List>
                  </React.Fragment>
                );
              })}
            </ListWrapper>
          </>
        </Collapse>
      </DialogContent>
      <DialogActions>
        <FormSubmitButtons onCancel={onClose} loading={loading} label="Save" />
      </DialogActions>
    </DialogForm>
  );
};
