import React, { useEffect, useMemo } from 'react';

import {
  getError,
  getTypeOrNull,
  useAnalyzeMutation,
  useReanalyzeMutation,
} from '@spotify-confidence/plugin-graphql';
import {
  WorkflowInstance,
  moduleHelpers,
} from '@spotify-confidence/plugin-workflows';
import _ from 'lodash';

import {
  ResultData,
  SignificantDirection,
  UndesiredReason,
  createBlankResultData,
  resultUtils,
} from '.';
import { MetricDirection, MetricsData } from '../../../MetricsModule';
import {
  AnalyzeResult,
  Method,
  MetricAnalysisType,
  MetricDetails,
  MetricResultStatus,
  StatsComparisonResult,
  StatsDataType,
  StatsHypothesisResult,
  StatsResult,
} from './types';
import {
  Annotation,
  ComparisonRecommendation,
  Context,
  Estimate,
} from './types';

const GROUP_COMPARISON_ID_DIVIDER = '|';

export type GroupComparisonId = {
  comparedGroupId: string;
  baselineGroupId: string;
};

export type ResultKind = 'MeanDifference' | 'RatioDifference';

export type MethodKind =
  | 'gstZTest'
  | 'gstZTestRatio'
  | 'asympCs'
  | 'asympCsRatio'
  | 'zTest'
  | 'zTestRatio'
  | 'unknown';

export type GroupEstimate = {
  baseline: number;
  compared: number;
};

export type OptionalGroupEstimate = {
  baseline?: number;
  compared?: number;
};

export type AbsRelEstimate = {
  timeLabel: string;
  groupMeanAbs: GroupEstimate;
  groupMeanRel: GroupEstimate;
  estimateAbs: Estimate;
  estimateRel: Estimate;
  isSignificant: boolean;
  kind: ResultKind;
  method: MethodKind;
  varianceReductionRate?: number;
  unadjustedGroupMeanAbs?: OptionalGroupEstimate;
};

export type SampleSizeEstimate = {
  current: number;
  required: number;
  poweredEffectRel?: number;
  poweredEffectAbs?: number;
};

export function getGroupsFromGroupComparisonId(
  groupId: string,
): GroupComparisonId {
  if (!groupId) {
    return { baselineGroupId: '', comparedGroupId: '' };
  }
  const [baselineGroupId, comparedGroupId] = groupId.split(
    GROUP_COMPARISON_ID_DIVIDER,
  );
  return { baselineGroupId, comparedGroupId };
}

// Matching format in annotation context
const SEGMENTS_DIVIDER = ',';
const SEGMENT_VALUE_DIVIDER = '=';

export function getSegmentId(dimensions: Record<string, string> = {}) {
  return Object.entries(dimensions)
    .sort(([key1], [key2]) => key1.localeCompare(key2))
    .map(entry => entry.join(SEGMENT_VALUE_DIVIDER))
    .join(SEGMENTS_DIVIDER);
}

export function getDimensionsFromSegmentId(segmentId: string = '') {
  if (!segmentId) {
    return {};
  }
  return Object.fromEntries(
    segmentId.split(SEGMENTS_DIVIDER).map(segment => {
      const parts = segment.split(SEGMENT_VALUE_DIVIDER);
      if (parts.length === 1) {
        return [parts[0], ''];
      }

      return [parts[0], parts[1]];
    }),
  );
}

export function isSameSegmentId(segmentId1: string, segmentId2: string) {
  return _.isEqual(
    getDimensionsFromSegmentId(segmentId1),
    getDimensionsFromSegmentId(segmentId2),
  );
}

export function getAnnotationContextValue(
  annotation: Annotation,
  context: Context,
) {
  return annotation.context?.find(c => c.key === context)?.value;
}

export function getAnnotationsForSegment(
  annotations: Annotation[] = [],
  segmentId: string = '',
  comparisonId: string = '',
) {
  return annotations.filter(
    a =>
      a.context?.some(
        c => c.key === 'CONTEXT_SEGMENT' && isSameSegmentId(c.value, segmentId),
      ) &&
      a.context?.some(
        c => c.key === 'CONTEXT_COMPARISON' && c.value === comparisonId,
      ),
  );
}

export function getRelativeCIBounds(
  estimate?: number,
  upper?: number,
  lower?: number,
) {
  if (!estimate || !upper || !lower) {
    return 0;
  }

  const lowerIsInfinite = !isFinite(lower);
  const upperIsInfinite = !isFinite(upper);

  const diffCI = (upperIsInfinite ? 0 : upper) - (lowerIsInfinite ? 0 : lower);

  const lowerGraphBounds = lowerIsInfinite
    ? Math.min(estimate, 0)
    : Math.min(lower - diffCI / 2, 0);
  const upperGraphBounds = upperIsInfinite
    ? Math.max(estimate, 0)
    : Math.max(upper + diffCI / 2, 0);

  return Math.max(Math.abs(lowerGraphBounds), Math.abs(upperGraphBounds));
}

export function getLatest<T>(
  items: T[],
  getTime: (entry: T) => string | Date | number | undefined | null,
): T | undefined {
  if (items.length === 0) {
    return undefined;
  }
  return items.reduce((a, b) => {
    const timeA = getTime(a);
    const timeB = getTime(b);
    if (_.isNil(timeA)) {
      return b;
    } else if (_.isNil(timeB)) {
      return a;
    }
    const aTime = new Date(timeA).getTime();
    const bTime = new Date(timeB).getTime();
    if (aTime > bTime) {
      return a;
    }
    return b;
  });
}

type TimebucketResultEntry = {
  timeLabel?: string | null | undefined;
};

export function getLatestResult<T extends TimebucketResultEntry>(
  results: T[],
): T | undefined {
  if (results === undefined || results.length === 0) {
    return undefined;
  }
  return getLatest<T>(results, entry => entry.timeLabel);
}

export const getMetricAnnotations = (
  response?: AnalyzeResult,
): Record<string, Annotation[]> => {
  if (!response) {
    return {};
  }

  const annotationMap: Record<string, Annotation[]> = {};
  (
    (response?.results ?? []).flatMap(result => result?.annotations) ?? []
  ).forEach(annotation => {
    const context = (annotation?.context ?? []).find(
      item => item.key === 'CONTEXT_HYPOTHESIS',
    );
    if (context === undefined) {
      return;
    }

    if (context.value in annotationMap) {
      annotationMap[context.value].push(annotation);
    } else {
      annotationMap[context.value] = [annotation];
    }
  });
  return annotationMap;
};

export function useAnalyzeResult(name: string, fetch: boolean) {
  const [analyze, { loading, data, error }] = useAnalyzeMutation({
    variables: { name },
  });

  const response: AnalyzeResult | undefined = useMemo(() => {
    return getTypeOrNull(
      data?.executeFunction,
      'WorkflowV1ExecuteFunctionResponse',
    )?.response;
  }, [data]);
  const metricAnnotations: Record<string, Annotation[]> = useMemo(() => {
    return getMetricAnnotations(response);
  }, [response]);

  const recommendations: ComparisonRecommendation[] = useMemo(() => {
    if (
      response?.treatments === undefined ||
      response.treatments.length === 0
    ) {
      return [];
    }

    const baseline = response.treatments.find(
      treatment => !treatment.recommendation,
    )!.id;

    const comparisonRecommendations = response.treatments
      .filter(treatment => treatment.recommendation)
      .map(treatment => {
        return {
          baseline: baseline,
          compared: treatment.id,
          recommendation: treatment.recommendation!.shipping,
          tanking: {
            status: treatment.recommendation!.status.tanking,
          },
          guardrail: {
            status: treatment.recommendation!.status.guardrail,
          },
          success: {
            status: treatment.recommendation!.status.success,
          },
          quality: {
            status: treatment.recommendation!.status.quality,
          },
        } as ComparisonRecommendation;
      });
    return comparisonRecommendations;
  }, [data]);

  React.useEffect(() => {
    if (fetch) {
      analyze();
    }
  }, [analyze, fetch]);

  // Re-analyze when page becomes visible
  useEffect(() => {
    function reanalyze() {
      if (document.visibilityState === 'visible') {
        analyze();
      }
    }

    document.addEventListener('visibilitychange', reanalyze);
    return () => {
      document.removeEventListener('visibilitychange', reanalyze);
    };
  }, [analyze]);

  return {
    results: response?.results,
    metricAnnotations,
    recommendations,
    loading,
    error: getError(data?.executeFunction) || error,
  };
}

export function useReanalyzeResult(name: string, fetch: boolean) {
  const [reanalyze, { loading, data, error }] = useReanalyzeMutation({
    variables: { name },
  });

  const response: AnalyzeResult | undefined = useMemo(() => {
    return getTypeOrNull(
      data?.executeFunction,
      'WorkflowV1ExecuteFunctionResponse',
    )?.response;
  }, [data]);
  const metricAnnotations: Record<string, Annotation[]> = useMemo(() => {
    return getMetricAnnotations(response);
  }, [response]);

  const recommendations: ComparisonRecommendation[] = useMemo(() => {
    if (
      response?.treatments === undefined ||
      response.treatments.length === 0
    ) {
      return [];
    }

    const baseline = response.treatments.find(
      treatment => !treatment.recommendation,
    )!.id;

    const comparisonRecommendations = response.treatments
      .filter(treatment => treatment.recommendation)
      .map(treatment => {
        return {
          baseline: baseline,
          compared: treatment.id,
          recommendation: treatment.recommendation!.shipping,
          tanking: {
            status: treatment.recommendation!.status.tanking,
          },
          guardrail: {
            status: treatment.recommendation!.status.guardrail,
          },
          success: {
            status: treatment.recommendation!.status.success,
          },
          quality: {
            status: treatment.recommendation!.status.quality,
          },
        } as ComparisonRecommendation;
      });
    return comparisonRecommendations;
  }, [data]);

  React.useEffect(() => {
    if (fetch) {
      reanalyze();
    }
  }, [reanalyze, fetch]);

  // Re-analyze when page becomes visible
  useEffect(() => {
    async function runReanalyze() {
      if (document.visibilityState === 'visible') {
        if (fetch) {
          await reanalyze();
        }
      }
    }

    document.addEventListener('visibilitychange', runReanalyze);
    return () => {
      document.removeEventListener('visibilitychange', runReanalyze);
    };
  }, [reanalyze]);

  return {
    results: response?.results,
    metricAnnotations,
    recommendations,
    loading,
    error: getError(data?.executeFunction) || error,
  };
}

export function toPercentage(estimate: Estimate): Estimate {
  return {
    estimate: estimate.estimate * 100.0,
    lower: estimate.lower * 100.0,
    upper: estimate.upper * 100.0,
    powered:
      !!estimate.powered && isFinite(estimate.powered)
        ? estimate.powered * 100.0
        : estimate.powered,
    stdErr:
      !!estimate.stdErr && isFinite(estimate.stdErr)
        ? estimate.stdErr * 100.0
        : estimate.stdErr,
  };
}

export const hasBeenLive = (workflowInstance: WorkflowInstance): boolean => {
  return (
    workflowInstance.state.toLowerCase() === 'live' ||
    workflowInstance.stateHistory.some(s => s.state?.toLowerCase() === 'live')
  );
};

export const canProduceResults = (
  workflowInstance: WorkflowInstance,
): boolean => {
  const metricsModule = moduleHelpers.getModuleData<MetricsData>(
    workflowInstance.moduleData,
    'metrics',
  );
  const hasMetrics = !!metricsModule?.metrics?.length;

  return hasMetrics && hasBeenLive(workflowInstance);
};

export const toSegmentAnnotations = (
  annotations: Record<string, Annotation[]>,
) => {
  return Object.entries(annotations).flatMap(
    ([metricName, metricAnnotations]) => {
      const segmentedAnnotations = _.groupBy(metricAnnotations, annotation =>
        resultUtils.getAnnotationContextValue(annotation, 'CONTEXT_SEGMENT'),
      );
      return Object.entries(segmentedAnnotations).map(
        ([segment, segmentAnnotations]) =>
          createBlankResultData({
            metric: metricName,
            annotations: segmentAnnotations,
            segment,
            dimensions: resultUtils.getDimensionsFromSegmentId(segment),
          }),
      );
    },
  );
};

export function methodToKindAndMethod(method?: Method): {
  kind: ResultKind;
  method: MethodKind;
} {
  switch (method) {
    case Method.Z_TEST:
      return { kind: 'MeanDifference', method: 'zTest' };
    case Method.GST_Z_TEST:
      return { kind: 'MeanDifference', method: 'gstZTest' };
    case Method.ASYMP_CS:
      return { kind: 'MeanDifference', method: 'asympCs' };
    case Method.RATIO:
      return { kind: 'RatioDifference', method: 'zTestRatio' };
    case Method.GST_RATIO:
      return { kind: 'RatioDifference', method: 'gstZTestRatio' };
    case Method.ASYMP_CS_RATIO:
      return { kind: 'RatioDifference', method: 'asympCsRatio' };
    default:
      return { kind: 'MeanDifference', method: 'unknown' };
  }
}

function strToInt(strOrInt: string | number | undefined): number | undefined {
  if (typeof strOrInt === 'number') {
    return strOrInt;
  }
  return strOrInt ? parseInt(strOrInt, 10) : undefined;
}

function statsResultToAbsRelEstimate(
  result: StatsResult[],
  kind: ResultKind,
  method: MethodKind,
): AbsRelEstimate[] {
  if (!result) {
    return [];
  }
  return result.map(res => {
    return {
      timeLabel: res.time,
      estimate: res.differenceEstimateAbs.estimate,
      groupMeanAbs: {
        baseline: res.baselineEstimateAbs,
        compared: res.comparedEstimateAbs,
      },
      groupMeanRel: {
        baseline: res.baselineEstimateRel,
        compared: res.comparedEstimateRel,
      },
      estimateAbs: {
        estimate: res.differenceEstimateAbs.estimate,
        lower: res.differenceEstimateAbs.lower,
        upper: res.differenceEstimateAbs.upper,
        powered: res.differenceEstimateAbs.powered,
        isSignificant: res.differenceEstimateAbs.isSignificant,
      },
      estimateRel: toPercentage({
        estimate: res.differenceEstimateRel.estimate,
        lower: res.differenceEstimateRel.lower,
        upper: res.differenceEstimateRel.upper,
        powered: res.differenceEstimateRel.powered,
        isSignificant: res.differenceEstimateRel.isSignificant,
      }),
      isSignificant: res.differenceEstimateAbs.isSignificant ?? false,
      method: method,
      kind: kind,
      varianceReductionRate: res.varianceReductionRate,
      unadjustedGroupMeanAbs: {
        baseline: res.unadjustedEstimateBaseline,
        compared: res.unadjustedEstimateCompared,
      },
    };
  });
}

export function sampleSizeFromHypothesisResult(
  statsHypothesisResult: StatsHypothesisResult,
): SampleSizeEstimate {
  const initialValue: SampleSizeEstimate = {
    poweredEffectRel: undefined,
    poweredEffectAbs: undefined,
    required: 0,
    current: 0,
  };

  const sampleSize = (statsHypothesisResult.result ?? []).reduce(
    (prev: SampleSizeEstimate, cur: StatsComparisonResult) => {
      const largerEffect =
        Math.abs(cur.lastResult?.differenceEstimateRel?.powered ?? 0) >
        Math.abs(prev.poweredEffectRel ?? 0);
      const largerSampleSize = cur.sampleSize.required > prev.required;

      return {
        poweredEffectRel: largerEffect
          ? cur.lastResult?.differenceEstimateRel?.powered
          : prev.poweredEffectRel,
        poweredEffectAbs: largerEffect
          ? cur.lastResult?.differenceEstimateAbs?.powered
          : prev.poweredEffectAbs,
        required: largerSampleSize ? cur.sampleSize.required : prev.required,
        current: largerSampleSize ? cur.sampleSize.current : prev.current,
      } as SampleSizeEstimate;
    },
    initialValue,
  );
  return {
    ...sampleSize,
    poweredEffectRel:
      sampleSize.poweredEffectRel && sampleSize.poweredEffectRel * 100,
  };
}

function metricResultStatusToDirection(
  status: MetricResultStatus,
): SignificantDirection | undefined {
  switch (status) {
    case MetricResultStatus.DESIRED_AND_UNDESIRED_SIGNIFICANT:
    case MetricResultStatus.UNDESIRED_SIGNIFICANT:
      return 'undesired';
    case MetricResultStatus.DESIRED_SIGNIFICANT:
      return 'desired';
    default:
      return undefined;
  }
}

function getUndesiredReason(
  status: MetricResultStatus,
): UndesiredReason | undefined {
  if (status === MetricResultStatus.UNDESIRED_SIGNIFICANT) {
    return 'failedTanking';
  } else if (status === MetricResultStatus.DESIRED_AND_UNDESIRED_SIGNIFICANT) {
    return 'failedTankingWithinNim';
  }
  return undefined;
}

function getEffectSizeWithDirection(metricDetails: MetricDetails) {
  if (!metricDetails.plannedEffectSize || !metricDetails.direction) {
    return undefined;
  }
  return (
    metricDetails.plannedEffectSize *
    (metricDetails.direction === MetricDirection.DECREASE ? 1 : -1) *
    100
  );
}

function createGroupComparisonId(
  baselineGroupId: string,
  comparedGroupId: string,
) {
  return [baselineGroupId, comparedGroupId].join(GROUP_COMPARISON_ID_DIVIDER);
}

function createBlankSegments(
  metricName: string,
  annotations: Annotation[],
): ResultData[] {
  const segmentsWithoutResults: Map<
    string | undefined,
    Map<
      string,
      {
        annotation: Annotation;
        baselineGroupId?: string;
        comparedGroupId?: string;
      }[]
    >
  > = new Map();
  annotations.forEach(annotation => {
    const comparison = resultUtils.getAnnotationContextValue(
      annotation,
      'CONTEXT_COMPARISON',
    );
    const segment = resultUtils.getAnnotationContextValue(
      annotation,
      'CONTEXT_SEGMENT',
    );

    // If there is no segment, we can't create a blank segment
    if (segment === undefined) {
      return;
    }

    let comparisonMap = segmentsWithoutResults.get(comparison);
    if (comparisonMap === undefined) {
      comparisonMap = new Map();
      segmentsWithoutResults.set(comparison, comparisonMap);
    }

    comparisonMap.set(segment, [
      ...(comparisonMap.get(segment) ?? []),
      { annotation: annotation },
    ]);
  });

  return [...segmentsWithoutResults.entries()].flatMap(
    ([comparisonId, comparisonMap]) => {
      return [...comparisonMap.entries()].map(
        ([segment, segmentAnnotations]) => {
          const baselineId = segmentAnnotations[0].baselineGroupId;
          const comparedId = segmentAnnotations[0].comparedGroupId;
          return createBlankResultData({
            metric: metricName,
            baselineGroupId: baselineId,
            comparedGroupId: comparedId,
            annotations: segmentAnnotations.map(({ annotation }) => annotation),
            comparison:
              baselineId && comparedId
                ? createGroupComparisonId(baselineId, comparedId)
                : comparisonId,
            segment,
            dimensions: resultUtils.getDimensionsFromSegmentId(segment),
          });
        },
      );
    },
  );
}

export function convertAnalyzeResultToResultData(
  results: StatsHypothesisResult[] = [],
  metricAnnotations: Record<string, Annotation[]> = {},
): ResultData[] {
  if (results.length === 0 && Object.keys(metricAnnotations).length > 0) {
    return toSegmentAnnotations(metricAnnotations);
  }
  return results.flatMap<ResultData>(hypothesisResult => {
    const { kind, method } = methodToKindAndMethod(
      hypothesisResult.statsSettings?.method,
    );
    const metricInfo: Pick<ResultData, 'metric' | 'nim' | 'mde'> = {
      metric: hypothesisResult.id,
      nim:
        hypothesisResult.metricDetails.metricType ===
          MetricAnalysisType.GUARDRAIL &&
        hypothesisResult.metricDetails.plannedEffectSize
          ? getEffectSizeWithDirection(hypothesisResult.metricDetails)
          : undefined,
      mde:
        hypothesisResult.metricDetails.metricType ===
          MetricAnalysisType.SUCCESS &&
        hypothesisResult.metricDetails.plannedEffectSize
          ? getEffectSizeWithDirection(hypothesisResult.metricDetails)
          : undefined,
    };

    if (!hypothesisResult.result || hypothesisResult.result.length === 0) {
      return createBlankSegments(
        hypothesisResult.id,
        metricAnnotations[hypothesisResult.id],
      );
    }
    return (hypothesisResult.result ?? []).flatMap(comparisonResult => {
      const withoutResult: Omit<ResultData, 'isSignificant' | 'result'> = {
        ...metricInfo,
        comparison: comparisonResult.comparisonId!,
        direction:
          comparisonResult?.status?.status &&
          metricResultStatusToDirection(comparisonResult.status!.status!),
        undesiredReason:
          comparisonResult?.status?.status &&
          getUndesiredReason(comparisonResult.status!.status),
        baselineGroupId: comparisonResult.groups.baseline,
        comparedGroupId: comparisonResult.groups.compared,
        baselineSampleSize: strToInt(
          comparisonResult.groupStats?.find(
            group => group.id === comparisonResult.groups.baseline,
          )?.sampleSize,
        ),
        comparedSampleSize: strToInt(
          comparisonResult.groupStats?.find(
            group => group.id === comparisonResult.groups.compared,
          )?.sampleSize,
        ),
        isFraction:
          comparisonResult.usedDataType === StatsDataType.DATA_TYPE_BINARY,
        requiredSampleSize: comparisonResult.sampleSize.required,
        currentSampleSize: comparisonResult.sampleSize.current,
        poweredEffectRel:
          comparisonResult.sampleSize.poweredEffectRel !== null &&
          comparisonResult.sampleSize.poweredEffectRel
            ? comparisonResult.sampleSize.poweredEffectRel * 100
            : undefined,
        poweredEffectAbs:
          comparisonResult.sampleSize.poweredEffectAbs !== null &&
          comparisonResult.sampleSize.poweredEffectAbs
            ? comparisonResult.sampleSize.poweredEffectAbs
            : undefined,
        // TODO: update in workflows and return relative powered effect´
        segment: comparisonResult.segmentId,
        dimensions: comparisonResult.dimensions,
        timeLabel: '',
        annotations:
          (metricAnnotations[hypothesisResult.id] ?? []).filter(
            annotation =>
              annotation.context?.find(ctx => ctx.key === 'CONTEXT_SEGMENT')
                ?.value === comparisonResult.segmentId &&
              annotation.context?.find(ctx => ctx.key === 'CONTEXT_COMPARISON')
                ?.value === comparisonResult.comparisonId,
          ) ?? [],
        statsSettings: hypothesisResult.statsSettings,
        metricDetails: hypothesisResult.metricDetails,
      };

      if (comparisonResult?.result && comparisonResult.result.length > 0) {
        return comparisonResult?.result.flatMap(singleTimeResult => {
          return {
            ...withoutResult,
            isSignificant:
              singleTimeResult.differenceEstimateAbs.isSignificant ||
              comparisonResult.status?.status ===
                MetricResultStatus.UNDESIRED_SIGNIFICANT,
            timeLabel: singleTimeResult.time,
            result: statsResultToAbsRelEstimate(
              [singleTimeResult],
              kind,
              method,
            )?.[0],
          };
        });
      }
      return withoutResult;
    });
  });
}
