import React, { ReactElement } from 'react';

import { ConfirmDialog, useDialog } from '@spotify-confidence/core-react';
import {
  usePauseIntakeMutation,
  useResumeIntakeMutation,
} from '@spotify-confidence/plugin-graphql';
import {
  ActionButton,
  WorkflowInstance,
  WorkflowPermissionGuard,
  moduleHelpers,
} from '@spotify-confidence/plugin-workflows';

import { FlagsData } from '../FlagsModule';

export const PauseResumeIntakeButtons = ({
  workflowInstance,
}: {
  workflowInstance: NonNullable<WorkflowInstance>;
}): ReactElement => {
  const { openDialog } = useDialog();
  const [pauseIntake, pauseData] = usePauseIntakeMutation({
    variables: {
      name: workflowInstance.name,
    },
  });
  const [resumeIntake, resumeData] = useResumeIntakeMutation({
    variables: {
      name: workflowInstance?.name,
    },
  });
  if (!workflowInstance) {
    return <></>;
  }
  const flagsData = moduleHelpers.getModuleData<FlagsData>(
    workflowInstance.moduleData,
    'flags',
  );
  if (!flagsData || !flagsData.stickyAssignments || !flagsData.rule) {
    return <></>;
  }

  const confirmPause = () => {
    return openDialog({
      content: (
        <ConfirmDialog onConfirm={pauseIntake} title="Pause intake">
          This will pause intake so that anyone already assigned will remain
          assigned, but no new assignments will be made.
        </ConfirmDialog>
      ),
    });
  };
  const confirmResume = () => {
    return openDialog({
      content: (
        <ConfirmDialog onConfirm={resumeIntake} title="Resume intake">
          This will resume intake. Anyone already assigned to a variant will
          remain in that variant, but new assignments will be allowed and
          stored.
        </ConfirmDialog>
      ),
    });
  };

  if (flagsData.intakePaused) {
    return (
      <WorkflowPermissionGuard type="function" name="resumeIntake">
        {({ allowed }) => (
          <ActionButton
            key="resumeIntake"
            action="resumeIntake"
            disabled={resumeData.loading || !allowed}
            onClick={confirmResume}
            workflowInstance={workflowInstance}
          />
        )}
      </WorkflowPermissionGuard>
    );
  }
  return (
    <WorkflowPermissionGuard type="function" name="pauseIntake">
      {({ allowed }) => (
        <ActionButton
          key="pauseIntake"
          action="pauseIntake"
          disabled={pauseData.loading || !allowed}
          onClick={confirmPause}
          workflowInstance={workflowInstance}
        />
      )}
    </WorkflowPermissionGuard>
  );
};
