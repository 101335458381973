import React, { useCallback } from 'react';
import { useSearchParams } from 'react-router-dom';

import {
  Box,
  Button,
  CircularProgress,
  List,
  ListItem,
  ListItemText,
  Popover,
  makeStyles,
} from '@material-ui/core';
import { Skeleton } from '@material-ui/lab';

import { IconBusiness } from '@spotify-internal/encore-web';
import classNames from 'classnames';

import { useAccounts } from '../hooks/useAccounts';

const useStyles = makeStyles({
  singleAccount: {
    pointerEvents: 'none',
  },
  root: {
    whiteSpace: 'nowrap',
    overflow: 'hidden',
  },
});

export function AccountSwitcher() {
  const classes = useStyles();
  const { selected, accounts, switchAccount, loading, switching } =
    useAccounts();
  const [searchParams, setSearchParams] = useSearchParams();
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);

  const handleSwitchAccount = useCallback(
    (newAccountId: string) => {
      setAnchorEl(null);
      switchAccount(newAccountId);
    },
    [switchAccount],
  );

  const openPopover = (event: React.MouseEvent<HTMLElement>) => {
    if (accounts.length > 1) {
      setAnchorEl(event.currentTarget);
    }
  };

  const shouldLogin = searchParams.get('login') === 'true';
  const organization = searchParams.get('organization') ?? undefined;

  React.useEffect(() => {
    if (selected) {
      if (shouldLogin && organization) {
        if (organization !== selected) {
          handleSwitchAccount(organization);
        }
        searchParams.delete('login');
        searchParams.delete('organization');
        setSearchParams(searchParams);
      }
    }
  }, [shouldLogin, organization, selected]);

  return (
    <Box>
      <Button
        variant="outlined"
        size="small"
        color="inherit"
        classes={{ root: classes.root }}
        className={classNames({
          [classes.singleAccount]: accounts.length === 1,
        })}
        startIcon={
          switching ? (
            <CircularProgress size="1em" color="inherit" />
          ) : (
            <IconBusiness size="small" />
          )
        }
        onClick={openPopover}
      >
        {loading ? <Skeleton variant="text" width="12ch" /> : selected}
      </Button>
      <Popover
        open={Boolean(anchorEl)}
        anchorEl={anchorEl}
        onClose={() => setAnchorEl(null)}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
      >
        <List>
          {accounts.map(account => (
            <ListItem
              button
              key={account.accountId}
              onClick={() => handleSwitchAccount(account.accountId)}
            >
              <ListItemText
                primary={account.accountId}
                secondary={account.displayName}
              />
            </ListItem>
          ))}
        </List>
      </Popover>
    </Box>
  );
}
