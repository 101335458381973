import React from 'react';
import { useNavigate, useParams } from 'react-router-dom';

import {
  Box,
  Card,
  CardContent,
  CircularProgress,
  Typography,
} from '@material-ui/core';
import { Alert } from '@material-ui/lab';

import {
  Breadcrumbs,
  DangerBox,
  DangerButton,
  DangerZone,
  DateUtils,
  PageLayout,
} from '@spotify-confidence/core-react';
import {
  getTypeOrNull,
  useDeleteFlagAppliedConnectionMutation,
  useFlagAppliedConnectionQuery,
} from '@spotify-confidence/plugin-graphql';
import { PermissionUtils } from '@spotify-confidence/plugin-permissions-react';

import { Link } from '@backstage/core-components';
import { useRouteRef } from '@backstage/core-plugin-api';

import { flagAppliedConnectionsRouteRef, rootRouteRef } from '../../../routes';
import {
  getFlagAppliedConnectionDetails,
  getFlagAppliedDestinationName,
} from '../../helpers';
import ConnectionAssignmentTable from './ConnectionAssignmentTable';

export const FlagAppliedConnectionPage = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const adminRoute = useRouteRef(rootRouteRef);
  const flagAppliedConnectionsRoute = useRouteRef(
    flagAppliedConnectionsRouteRef,
  );
  const name = `flagAppliedConnections/${id}`;
  const { data, loading } = useFlagAppliedConnectionQuery({
    variables: {
      flagAppliedConnectionName: name,
    },
  });

  const [deleteConnection, { loading: isDeleting, error: deleteError }] =
    useDeleteFlagAppliedConnectionMutation({
      variables: {
        name: name,
      },
      onCompleted: () => {
        navigate(flagAppliedConnectionsRoute());
      },
    });

  const connection = getTypeOrNull(
    data?.flagAppliedConnection,
    'ConnectorsV1FlagAppliedConnection',
  );
  const connectionError = getTypeOrNull(
    data?.flagAppliedConnection,
    'Error',
  )?.message;

  const connectionInfo = [
    {
      label: 'Destination',
      value: getFlagAppliedDestinationName(connection),
    },
    {
      label: 'Created',
      value: connection?.createTime
        ? DateUtils.toDateString(
            connection?.createTime || '',
            DateUtils.DATE_TIME_FORMAT,
          )
        : undefined,
    },
    ...getFlagAppliedConnectionDetails(connection, true),
  ];

  const canEdit = PermissionUtils.hasRelation(connection, 'can_edit');

  return (
    <PageLayout
      narrow
      title="Connection details"
      headerBreadcrumbs={
        <Breadcrumbs>
          <Link to={adminRoute()}>Admin</Link>
          <Link to={flagAppliedConnectionsRoute()}>
            Flag applied connections
          </Link>
          <Typography>Connection details</Typography>
        </Breadcrumbs>
      }
    >
      {connectionError ? (
        <Alert severity="error">{connectionError}</Alert>
      ) : (
        <>
          <Card>
            <CardContent>
              <Box display="grid" gridTemplateColumns="1fr 1fr" gridGap="1em">
                {connectionInfo.map(info => (
                  <div key={info.label}>
                    <Typography variant="body2" color="textSecondary">
                      {info.label}
                    </Typography>
                    <Typography>{info.value}</Typography>
                  </div>
                ))}
              </Box>
            </CardContent>
          </Card>

          {connection && (
            <Box marginTop={3}>
              <ConnectionAssignmentTable connection={connection} />
            </Box>
          )}

          <Box my={3}>
            {deleteError && (
              <Alert severity="error">{deleteError.message}</Alert>
            )}
            <DangerZone>
              <DangerBox>
                <strong>Delete Flag Applied Connection</strong>
                <DangerButton
                  variant="contained"
                  disabled={isDeleting || loading || !canEdit}
                  color="inherit"
                  endIcon={isDeleting && <CircularProgress size="1em" />}
                  onClick={() => deleteConnection()}
                >
                  Delete
                </DangerButton>
              </DangerBox>
            </DangerZone>
          </Box>
        </>
      )}
    </PageLayout>
  );
};
