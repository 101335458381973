import React from 'react';

import {
  Box,
  Button,
  ClickAwayListener,
  Grow,
  Paper,
  Popper,
  TextField,
  Typography,
  makeStyles,
} from '@material-ui/core';
import Add from '@material-ui/icons/Add';
import { Autocomplete, ToggleButtonGroup } from '@material-ui/lab';

import { TooltipToggleButton } from '@spotify-confidence/core-react';
import { getTypeOrNull } from '@spotify-confidence/plugin-graphql';

import {
  ATTRIBUTE_TYPES,
  AttributeType,
  CriterionOption,
  getSemanticTypeFromCriterionOption,
} from '../../domain/targeting/targeting.model';
import { AttributeIcon } from './AttributeIcon';
import { useCriterionOptions } from './CriterionOptionsProvider';

const useStyles = makeStyles(theme => ({
  popper: {
    zIndex: theme.zIndex.modal + 1,
  },
  paper: {
    width: 390,
    transformOrigin: 'top left',
    padding: theme.spacing(2),
  },
}));

type CreateCriterionProps = {
  onCreate: (criterion: {
    attribute: string;
    attributeType: AttributeType;
  }) => void;
};

export const CreateCriterion = ({ onCreate }: CreateCriterionProps) => {
  const classes = useStyles();
  const { criterionOptions, addCustomOption } = useCriterionOptions();
  const [attribute, setAttribute] = React.useState<string>('');
  const [attributeType, setAttributeType] = React.useState<AttributeType>(
    ATTRIBUTE_TYPES[0],
  );

  const [open, setOpen] = React.useState(false);
  const nameFieldRef = React.useRef<HTMLInputElement>(null);
  const anchorRef = React.useRef(null);
  const handleToggle = () => {
    setOpen(prevOpen => !prevOpen);
  };

  const handleAttributeNameSelect = (
    _e: any,
    newValue: string | CriterionOption | null,
  ) => {
    if (typeof newValue === 'string') {
      onCreate({
        attribute: newValue,
        attributeType,
      });
    } else if (newValue && newValue.type) {
      let type = newValue.type;
      if (newValue.semanticType?.version) {
        type = 'Version';
      } else if (
        newValue.semanticType?.date ||
        newValue.semanticType?.timestamp
      ) {
        type = 'Timestamp';
      }
      onCreate({
        attribute: newValue.name,
        attributeType: type,
      });
    }
    setOpen(false);
  };

  const handleTypeSelect = (
    _e: React.MouseEvent<HTMLElement>,
    newType: string | null,
  ) => {
    if (newType !== null) {
      setAttributeType(newType as AttributeType);
      nameFieldRef.current?.focus();
    }
  };

  const handleCreate = () => {
    onCreate({
      attribute,
      attributeType,
    });
    setAttribute('');
    setAttributeType(ATTRIBUTE_TYPES[0]);
    setOpen(false);
    addCustomOption({ name: attribute, type: attributeType });
  };

  const isAttributeNameInvalid = attribute.length < 2;
  const visibleOptions = criterionOptions.filter(o => !o.hidden);

  return (
    <>
      <Button
        onClick={handleToggle}
        ref={anchorRef}
        startIcon={<Add />}
        data-testid="add-criterion-button"
        size="small"
      >
        Add attribute criterion
      </Button>
      <Popper
        anchorEl={anchorRef.current}
        open={open}
        placement="bottom-start"
        transition
        className={classes.popper}
        role={undefined}
      >
        {({ TransitionProps }) => (
          <ClickAwayListener
            onClickAway={() => {
              setOpen(false);
            }}
          >
            <Grow in={open} {...TransitionProps}>
              <Paper
                elevation={14}
                className={classes.paper}
                data-testid="add-criterion-container"
              >
                <Autocomplete
                  options={visibleOptions}
                  disablePortal
                  getOptionLabel={option => {
                    if (typeof option === 'string') {
                      return option;
                    }
                    return option.displayName || option.name;
                  }}
                  renderOption={option => {
                    const entity = getTypeOrNull(
                      option.semanticType?.entityReference?.entity,
                      'MetricsV1Entity',
                    );
                    const displayName =
                      entity?.displayName || option.displayName;
                    return (
                      <Box display="flex" gridGap={4} alignItems="center">
                        <AttributeIcon
                          type={
                            getSemanticTypeFromCriterionOption(option) ||
                            option.type
                          }
                          color="disabled"
                        />
                        <span>
                          {displayName || option.name}{' '}
                          {displayName && (
                            <Typography component="span" color="textSecondary">
                              ({option.name})
                            </Typography>
                          )}
                        </span>
                      </Box>
                    );
                  }}
                  fullWidth
                  freeSolo
                  onInputChange={(_e, v) => {
                    setAttribute(v);
                  }}
                  onChange={handleAttributeNameSelect}
                  renderInput={params => (
                    <TextField
                      {...params}
                      placeholder="Field name"
                      variant="outlined"
                      margin="none"
                      size="small"
                      autoFocus
                      inputRef={nameFieldRef}
                      name="field-name"
                      inputProps={{
                        ...(params.inputProps ?? {}),
                        'data-form-type': 'other',
                        autoComplete: 'off',
                        lpignore: 'true',
                      }}
                    />
                  )}
                />

                <Box
                  display="flex"
                  alignItems="center"
                  gridGap={5}
                  mt={1}
                  justifyContent="space-between"
                >
                  <Typography color="textSecondary">Type:</Typography>

                  <ToggleButtonGroup
                    exclusive
                    value={attributeType}
                    onChange={handleTypeSelect}
                    color="default"
                    size="small"
                  >
                    {ATTRIBUTE_TYPES.map(type => (
                      <TooltipToggleButton
                        key={type}
                        TooltipProps={{ title: type }}
                        value={type}
                      >
                        <AttributeIcon type={type} />
                      </TooltipToggleButton>
                    ))}
                  </ToggleButtonGroup>
                  <Button
                    disabled={isAttributeNameInvalid}
                    variant="contained"
                    color="primary"
                    size="small"
                    name="add-field-button"
                    onClick={handleCreate}
                  >
                    Add
                  </Button>
                </Box>
              </Paper>
            </Grow>
          </ClickAwayListener>
        )}
      </Popper>
    </>
  );
};
