import React from 'react';
import { useParams, useSearchParams } from 'react-router-dom';

import { Typography } from '@material-ui/core';

import {
  Breadcrumbs,
  PageLayout,
  createHeaderTab,
  useDialog,
  useMeasureDimensions,
} from '@spotify-confidence/core-react';
import {
  getError,
  getTypeOrNull,
  useListClientsQuery,
} from '@spotify-confidence/plugin-graphql';

import { HeaderTabs, Link, Tab } from '@backstage/core-components';
import { useRouteRef } from '@backstage/core-plugin-api';

import {
  clientSchemaRouteRef,
  clientsRouteRef,
  rootRouteRef,
} from '../../../routes';
import { ClientDialog } from './Clients/ClientDialog';
import { ClientsTab } from './Clients/ClientsTab';
import { SchemaTab } from './Schema/SchemaTab';

const useTabs = () => {
  const [searchParams] = useSearchParams();
  const buildUrl = (base: string) => `${base}?${searchParams.toString()}`;

  const clientsRoute = useRouteRef(clientsRouteRef);
  const clientSchemaRoute = useRouteRef(clientSchemaRouteRef);

  const tabs: Tab[] = [
    createHeaderTab({
      id: 'clients',
      label: 'List',
      to: buildUrl(clientsRoute()),
    }),
    createHeaderTab({
      id: 'schema',
      label: 'Context schema',
      to: buildUrl(clientSchemaRoute()),
    }),
  ];

  // eslint-disable-next-line no-nested-ternary
  const selectedIndex = location.pathname.includes('schema')
    ? tabs.findIndex(tab => tab.id === 'schema')
    : 0;
  const selectedTabId = tabs[selectedIndex].id;

  return {
    tabs,
    selectedTabId,
    selectedIndex,
  };
};

export const ClientsPage = () => {
  const adminRoute = useRouteRef(rootRouteRef);
  const { id } = useParams();
  const { tabs, selectedIndex, selectedTabId } = useTabs();

  const { data, loading, error, fetchMore } = useListClientsQuery({
    variables: {
      pageSize: 100,
    },
    fetchPolicy: 'cache-and-network',
  });
  const clients = getTypeOrNull(data?.clients, 'IamV1ListClientsResponse');

  // We always fetch all clients in order to get the derived context for all clients
  React.useEffect(() => {
    if (clients?.nextPageToken) {
      fetchMore({
        variables: {
          pageToken: clients.nextPageToken,
        },
      });
    }
  }, [clients?.nextPageToken]);

  const [tabsRef, { height: tabsHeight }] =
    useMeasureDimensions<HTMLDivElement>();

  const { openDialog } = useDialog();

  React.useEffect(() => {
    if (id) {
      openDialog({
        content: <ClientDialog id={id} />,
      });
    }
  }, [id]);

  return (
    <PageLayout
      narrow
      title="Clients"
      headerBreadcrumbs={
        <Breadcrumbs>
          <Link to={adminRoute()}>Admin</Link>
          <Typography>Clients</Typography>
        </Breadcrumbs>
      }
      headerTabs={<HeaderTabs selectedIndex={selectedIndex} tabs={tabs} />}
      headerTabsRef={tabsRef}
    >
      {selectedTabId === 'clients' && (
        <ClientsTab
          topOffset={tabsHeight}
          clients={clients?.clients}
          loading={loading}
          error={getError(data?.clients) || error}
        />
      )}
      {selectedTabId === 'schema' && (
        <SchemaTab
          topOffset={tabsHeight}
          clients={clients?.clients.map(c => c.name)}
          loading={loading}
          error={getError(data?.clients) || error}
        />
      )}
    </PageLayout>
  );
};
