import React from 'react';

import { useLocalStorage } from '@spotify-confidence/core-react';
import {
  isError,
  useUserIdentityQuery,
} from '@spotify-confidence/plugin-graphql';

export const useDefaultOwner = (initialValue?: string) => {
  const [defaultOwner, setDefaultOwner] = useLocalStorage<string | null>(
    'default-owner-v2',
    null,
  );
  const [owner, setOwner] = React.useState<string | null>(
    initialValue ?? defaultOwner,
  );
  const { loading } = useUserIdentityQuery({
    skip: owner !== null,
    onCompleted: data => {
      const currentUser = !isError(data.currentUser)
        ? data.currentUser?.identity.name ?? null
        : null;
      setDefaultOwner(currentUser);
      setOwner(currentUser);
    },
  });

  React.useEffect(() => {
    if (owner && !initialValue) {
      setDefaultOwner(owner);
    }
  }, [owner, setDefaultOwner, initialValue]);

  return [owner, setOwner, { loading }] as const;
};
