import React from 'react';
import { InView } from 'react-intersection-observer';
import { Link, useNavigate } from 'react-router-dom';

import {
  Button,
  LinearProgress,
  Table,
  TableBody,
  TableCell,
  TableRow,
} from '@material-ui/core';
import AddIcon from '@material-ui/icons/Add';

import {
  ACTION_BAR_HEIGHT,
  ActionBar,
  DateUtils,
  FilterBar,
  FilterListEmptyState,
  FilterListProvider,
  MediumScreenCell,
  PageLayout,
  ResourceTableRow,
  StickyTableContainer,
  StickyTableHead,
  extractLastNameComponent,
  useDialog,
  useListFilter,
  useMeasureDimensions,
} from '@spotify-confidence/core-react';
import {
  getError,
  getTypeOrNull,
  useSegmentsPageListQuery,
} from '@spotify-confidence/plugin-graphql';
import { useCheckResourcePermissions } from '@spotify-confidence/plugin-permissions-react';

import { WarningPanel } from '@backstage/core-components';
import { useRouteRef } from '@backstage/core-plugin-api';

import { segmentRouteRef } from '../../../routes';
import { SegmentState } from '../SegmentState';
import { CreateSegmentDialog } from './components/CreateSegmentDialog';
import { filters } from './segmentFilters';

const SegmentsList = ({
  createSegment,
  topOffset = ACTION_BAR_HEIGHT,
}: {
  createSegment?: () => void;
  topOffset?: number;
}) => {
  const segmentRoute = useRouteRef(segmentRouteRef);

  const { filterString } = useListFilter();

  const searchFilter = filterString;
  let filter = `labels.userCreated:true`;
  if (searchFilter.length !== 0) {
    filter = `${filter} AND ${searchFilter}`;
  }

  const { data, loading, error, fetchMore } = useSegmentsPageListQuery({
    variables: {
      filter: filter,
    },
    fetchPolicy: 'cache-and-network',
  });
  const segmentsResponseError = getError(data?.segments);
  const segmentsResponse = getTypeOrNull(
    data?.segments,
    'FlagsAdminV1ListSegmentsResponse',
  );
  const { segments, nextPageToken } = segmentsResponse ?? {
    segments: [],
    nextPageToken: null,
  };

  if (loading || !data) {
    return <LinearProgress />;
  }

  if (error || segmentsResponseError || !data.segments) {
    return (
      <WarningPanel
        title="Could not load data"
        message={error?.message ?? segmentsResponseError?.message}
      />
    );
  }

  if (segments.length === 0) {
    return (
      <FilterListEmptyState
        type="segment"
        description="Segments allow you to create reusable sets of users."
        // todo: add link to docs
        onCreate={createSegment}
      />
    );
  }

  return (
    <StickyTableContainer>
      <Table>
        <StickyTableHead topOffset={topOffset}>
          <TableRow>
            <TableCell>Name</TableCell>
            <TableCell>Status</TableCell>
            <MediumScreenCell>Updated</MediumScreenCell>
          </TableRow>
        </StickyTableHead>
        <TableBody>
          {segments.map(segment => (
            <ResourceTableRow
              key={segment.name}
              routeRef={segmentRouteRef}
              name={segment.name}
            >
              <TableCell>
                <Link
                  to={segmentRoute({
                    id: extractLastNameComponent(segment.name)!,
                  })}
                >
                  {segment.displayName}
                </Link>
              </TableCell>
              <TableCell>
                <SegmentState state={segment.state} />
              </TableCell>
              <MediumScreenCell>
                {DateUtils.xAgo(new Date(segment?.updateTime))}
              </MediumScreenCell>
            </ResourceTableRow>
          ))}
        </TableBody>
      </Table>
      {data && (
        <InView
          onChange={async inView => {
            if (inView && nextPageToken) {
              await fetchMore({
                variables: {
                  pageToken: nextPageToken,
                },
              });
            }
          }}
        />
      )}
    </StickyTableContainer>
  );
};

export const SegmentListPage = () => {
  const { openDialog } = useDialog();
  const segmentRoute = useRouteRef(segmentRouteRef);
  const navigate = useNavigate();
  const [ref, { height }] = useMeasureDimensions<HTMLDivElement>();

  const { allowed } = useCheckResourcePermissions({
    can: 'create_segment',
    name: 'account',
  });

  const onSegmentCreated = React.useCallback(
    (name: string) =>
      navigate(segmentRoute({ id: extractLastNameComponent(name)! })),
    [navigate, segmentRoute],
  );

  const createSegment = React.useCallback(() => {
    openDialog({
      content: <CreateSegmentDialog onCreated={onSegmentCreated} />,
    });
  }, [openDialog]);

  return (
    <FilterWrapper>
      <PageLayout title="Segments" scrollButton>
        <ActionBar ref={ref}>
          <FilterBar />
          <Button
            variant="contained"
            color="primary"
            size="small"
            onClick={createSegment}
            data-testid="create-segment-button"
            startIcon={<AddIcon />}
            disabled={!allowed}
          >
            Create
          </Button>
        </ActionBar>
        <SegmentsList
          createSegment={allowed ? createSegment : undefined}
          topOffset={height}
        />
      </PageLayout>
    </FilterWrapper>
  );
};

function FilterWrapper({ children }: React.PropsWithChildren<{}>) {
  return (
    <FilterListProvider
      searchField="displayName"
      filters={filters}
      storageKey="segments"
    >
      {children}
    </FilterListProvider>
  );
}
