import { loadDevMessages, loadErrorMessages } from '@apollo/client/dev';
import * as Sentry from '@sentry/react';

import { loadTrackJS } from './utils/ConsentManagement';

const IS_SLINGSHOT = process.env.IS_SLINGSHOT === 'true';
const IS_PRODUCTION = process.env.NODE_ENV === 'production';
const IS_E2E_TEST = !!(window as typeof window & { Cypress?: object }).Cypress;
const IS_LOCALHOST = window?.location.hostname.startsWith('localhost');
const IS_SLINGSHOT_HOST = window?.location.host.endsWith(
  'slingshot-instance.spotify.net',
);

if (
  IS_PRODUCTION &&
  !IS_SLINGSHOT &&
  !IS_E2E_TEST &&
  !IS_LOCALHOST &&
  !IS_SLINGSHOT_HOST
) {
  loadTrackJS();
}

Sentry.init({
  dsn: 'https://e429b0654e22474f81655df4103dd4bb@o22381.ingest.sentry.io/4504633286131712',
  // Adjust this value in production, or use tracesSampler for greater control
  tracesSampleRate: 0.0025,
  // ...
  // Note: if you want to override the automatic release value, do not set a
  // `release` value here - use the environment variable `SENTRY_RELEASE`, so
  // that it will also get attached to your source maps
  enabled: IS_PRODUCTION && !IS_SLINGSHOT,
  environment: process.env.NODE_ENV,
});

if (!IS_PRODUCTION) {
  // show apollo errors in dev
  loadDevMessages();
  loadErrorMessages();

  // enable MSW
  try {
    if (window?.localStorage.getItem('use-msw') === 'true') {
      import('./mocks/browser').then(({ worker }) => {
        worker.start();
      });
    }
  } catch (e) {
    console.error(e);
  }
}
