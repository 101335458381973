import React from 'react';

import {
  Box,
  DialogActions,
  ListItemText,
  MenuItem,
  TextField,
} from '@material-ui/core';
import { Alert } from '@material-ui/lab';

import {
  AdvancedOptions,
  AutocompleteEmptyState,
  ConfidenceAutocomplete,
  DialogBody,
  DialogHeader,
  FormSubmitButtons,
  extractLastNameComponent,
  useDialog,
} from '@spotify-confidence/core-react';
import {
  MetricAssignmentTableFragment,
  MetricEntityFragment,
  MetricsV1TableState,
  getTypeOrNull,
  useMetricConfigAssignmentTablesLazyQuery,
  useMetricConfigAssignmentTablesQuery,
  useMetricConfigEntitiesLazyQuery,
  useMetricConfigFactTablesLazyQuery,
} from '@spotify-confidence/plugin-graphql';

import { useRouteRef } from '@backstage/core-plugin-api';

import { mapKeyTypeLabel } from '../entities/domain/useFormattedEntityType.hook';
import {
  assignmentTablesCreateRouteRef,
  entitiesRouteRef,
  factTablesCreateRouteRef,
} from '../routes';
import { RenderTableOption } from '../shared';
import { BucketingTemporalUnit, MetricsConfig } from './types';

type MetricDialogProps = {
  metricConfig?: MetricsConfig;
  onSave: (data: MetricsConfig) => Promise<any>;
  asStep?: boolean;
};

export const MetricConfigDialog = ({
  onSave,
  metricConfig,
  asStep,
}: MetricDialogProps) => {
  const { closeDialog } = useDialog();
  const entitiesRoute = useRouteRef(entitiesRouteRef);
  const assignmentTablesCreateRoute = useRouteRef(
    assignmentTablesCreateRouteRef,
  );
  const factTablesCreateRoute = useRouteRef(factTablesCreateRouteRef);
  const [isSaving, setIsSaving] = React.useState<boolean>(false);
  const [error, setError] = React.useState<Error>();
  const [config, setConfig] = React.useState<MetricsConfig>({
    entity: metricConfig?.entity || '',
    assignmentTable: metricConfig?.assignmentTable || '',
    bucket:
      metricConfig?.bucket ||
      BucketingTemporalUnit.BUCKETING_TEMPORAL_UNIT_UNSPECIFIED,
    exposureFilter: metricConfig?.exposureFilter,
  });

  const updateConfig = React.useCallback(
    (newValues: Partial<MetricsConfig>) => {
      setConfig(v => ({
        ...v,
        ...newValues,
      }));
    },
    [setConfig],
  );

  // Determine if there are more than one assignment tables to choose from.
  const { data, loading } = useMetricConfigAssignmentTablesQuery({
    variables: { pageSize: 2 },
  });

  const minimumAssignmentTableOptions = React.useMemo(
    () =>
      getTypeOrNull(
        data?.assignmentTables,
        'MetricsV1ListAssignmentTablesResponse',
      )?.assignmentTables || [],
    [data?.assignmentTables],
  );

  // Only show the option to select an assignment table if there's
  // more than one to choose from, or if there's no assignment table set.
  const showAssignmentTable = React.useMemo(() => {
    return (
      !loading &&
      (minimumAssignmentTableOptions.length > 1 ||
        !Boolean(config.assignmentTable))
    );
  }, [loading, minimumAssignmentTableOptions]);

  const handleCreateEntity = (searchInput?: string) => {
    window.open(
      `${entitiesRoute()}?create=true${
        searchInput ? `&name=${searchInput}` : ''
      }`,
      '_blank',
    );
  };

  const handleSubmit = () => {
    (async () => {
      setError(undefined);
      try {
        setIsSaving(true);
        await onSave({
          ...config,
          ...(config.entity !== metricConfig?.entity && { metrics: [] }),
        });

        if (!asStep) {
          setIsSaving(false);
          closeDialog();
        }
      } catch (e) {
        setError(e as Error);
        setIsSaving(false);
      }
    })();
  };

  const selectedAssignmentTable = React.useMemo(() => {
    return !config.assignmentTable && minimumAssignmentTableOptions.length === 1
      ? minimumAssignmentTableOptions[0].name
      : config.assignmentTable;
  }, [config.assignmentTable, minimumAssignmentTableOptions]);

  config.assignmentTable = selectedAssignmentTable;

  return (
    <>
      <DialogHeader
        title="Configure metrics"
        subTitle="These settings will be applied to all added metrics."
      />
      <DialogBody autoHeight data-testid="metrics-config-container">
        <>
          {error && <Alert severity="error">{error.message}</Alert>}
          {showAssignmentTable && (
            <Box mb={2}>
              <ConfidenceAutocomplete<MetricAssignmentTableFragment>
                query={useMetricConfigAssignmentTablesLazyQuery}
                queryOptions={{
                  variables: {
                    pageSize: 100,
                  },
                }}
                data-testid="assignment-table-select"
                onChange={(_e, v) => {
                  updateConfig({
                    assignmentTable: v ?? undefined,
                  });
                }}
                value={selectedAssignmentTable}
                label="Assignment table"
                placeholder="Select assignment table"
                required
                helperText="Select the table that can determine which entity was exposed to which treatment."
                renderOption={(table, state) => (
                  <RenderTableOption option={table} state={state} />
                )}
                getOptionDisabled={table =>
                  table.state !== MetricsV1TableState.TableStateActive
                }
              >
                <AutocompleteEmptyState
                  title="No assignment tables"
                  description="An assignment table is required to determine which entity was exposed to which treatment."
                  actionLabel="Create assignment table"
                  actionLink={assignmentTablesCreateRoute()}
                />
              </ConfidenceAutocomplete>
            </Box>
          )}
          <Box mb={2}>
            <TextField
              select
              label="Calculation interval"
              margin="dense"
              variant="outlined"
              fullWidth
              required
              helperText="How often the metrics should be calculated after launch."
              value={config.bucket}
              onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                updateConfig({
                  bucket: e.target.value as BucketingTemporalUnit,
                });
              }}
            >
              <MenuItem
                value={
                  BucketingTemporalUnit.BUCKETING_TEMPORAL_UNIT_UNSPECIFIED
                }
                disabled
              >
                Select interval...
              </MenuItem>
              <MenuItem value={BucketingTemporalUnit.DAYS}>Daily</MenuItem>
              <MenuItem value={BucketingTemporalUnit.HOURS}>Hourly</MenuItem>
            </TextField>
          </Box>
          <AdvancedOptions
            enabled={Boolean(
              config.exposureFilter?.factTable || !config.entity,
            )}
          >
            <Box mb={2}>
              <ConfidenceAutocomplete<MetricEntityFragment>
                query={useMetricConfigEntitiesLazyQuery}
                data-testid="entity-select"
                onChange={(_e, entity) =>
                  updateConfig({
                    entity: entity || '',
                    exposureFilter: { factTable: undefined },
                  })
                }
                value={config.entity}
                label="Entity"
                placeholder="Select entity"
                required
                helperText="The entity determines who to calculate metrics
                for, and is typically the same as the randomization unit."
                onCreate={handleCreateEntity}
                renderOption={(entity, { inputValue, ...state }) => (
                  <div {...state}>
                    <ListItemText
                      primary={entity.displayName}
                      secondary={mapKeyTypeLabel(entity.primaryKeyType)}
                    />
                  </div>
                )}
              />
            </Box>
            <ConfidenceAutocomplete<MetricAssignmentTableFragment>
              query={useMetricConfigFactTablesLazyQuery}
              queryOptions={{
                variables: {
                  filter: config.entity
                    ? `entityNames:${extractLastNameComponent(config.entity)}`
                    : '',
                },
              }}
              data-testid="fact-table-select"
              onChange={(_e, v) => {
                updateConfig({
                  exposureFilter: {
                    factTable: v,
                  },
                });
              }}
              value={config.exposureFilter?.factTable || ''}
              disabled={!config.entity}
              label="Exposure filter fact table"
              placeholder="Select fact table"
              helperText="Optionally only include entities that also matches criteria defined by a fact table."
              renderOption={(table, state) => (
                <RenderTableOption option={table} state={state} />
              )}
              getOptionDisabled={table =>
                table.state !== MetricsV1TableState.TableStateActive
              }
            >
              <AutocompleteEmptyState
                title="No fact tables"
                actionLabel="Create fact table"
                actionLink={factTablesCreateRoute()}
                description="There are no fact tables that match the selected entity."
              />
            </ConfidenceAutocomplete>
          </AdvancedOptions>
        </>
      </DialogBody>
      <DialogActions>
        <FormSubmitButtons
          onCancel={closeDialog}
          onSubmit={handleSubmit}
          label={asStep ? 'Continue' : 'Save'}
          disabled={!config.entity || !config.assignmentTable}
          loading={isSaving}
        />
      </DialogActions>
    </>
  );
};
