import React from 'react';

import {
  FlagsAdminV1EvaluationContextSchemaFieldKind,
  FlagsAdminV1EvaluationContextSchemaSchemaEntryInput,
  SegmentFragment,
  TargetingFragment,
  getTypeOrNull,
  useDeriveClientEvaluationSchemaMutation,
} from '@spotify-confidence/plugin-graphql';
import { isEqual } from 'lodash';

import { targetingCodec } from '.';
import { segmentIsDifferent } from '../segment.model';
import {
  AttributeType,
  CriterionOption,
  TargetingCriteria,
  isCriterionSet,
} from './targeting.model';

const MAP_ATTRIBUTE_TO_SCHEMAFIELD: Record<
  AttributeType,
  FlagsAdminV1EvaluationContextSchemaFieldKind
> = {
  String: FlagsAdminV1EvaluationContextSchemaFieldKind.StringKind,
  Boolean: FlagsAdminV1EvaluationContextSchemaFieldKind.BoolKind,
  Number: FlagsAdminV1EvaluationContextSchemaFieldKind.NumberKind,
  Timestamp: FlagsAdminV1EvaluationContextSchemaFieldKind.StringKind,
  Version: FlagsAdminV1EvaluationContextSchemaFieldKind.StringKind,
  Any: FlagsAdminV1EvaluationContextSchemaFieldKind.NullKind,
};

export const MAP_SCHEMAFIELD_TO_ATTRIBUTE: Record<
  FlagsAdminV1EvaluationContextSchemaFieldKind,
  AttributeType
> = {
  [FlagsAdminV1EvaluationContextSchemaFieldKind.StringKind]: 'String',
  [FlagsAdminV1EvaluationContextSchemaFieldKind.BoolKind]: 'Boolean',
  [FlagsAdminV1EvaluationContextSchemaFieldKind.NumberKind]: 'Number',
  [FlagsAdminV1EvaluationContextSchemaFieldKind.NullKind]: 'Any',
};

export const getMustMatchEvaluationContextSchema = (
  targeting?: TargetingCriteria,
  targetingKeySelector?: string | null,
) => {
  const schemaEntry: FlagsAdminV1EvaluationContextSchemaSchemaEntryInput[] = [];
  const traverseTargeting = (t: TargetingCriteria) => {
    if (isCriterionSet(t)) {
      t.criteria.map(t1 => traverseTargeting(t1));
    } else {
      if (t.type === 'attribute') {
        const schemaField = MAP_ATTRIBUTE_TO_SCHEMAFIELD[t.attributeType];
        if (schemaField !== 'NULL_KIND') {
          schemaEntry.push({
            key: t.attribute,
            value: { types: [schemaField] },
          });
        }
      }
    }
  };
  if (targeting) {
    traverseTargeting(targeting);
  }
  if (targetingKeySelector) {
    schemaEntry.push({
      key: targetingKeySelector,
      value: {
        types: [FlagsAdminV1EvaluationContextSchemaFieldKind.StringKind],
      },
    });
  }
  return schemaEntry;
};

export function useDeriveEvaluationContextSchema(
  clients: string[],
  targetingProp?: Maybe<TargetingFragment>,
  targetingKeySelector?: Maybe<string>,
): CriterionOption[] {
  const [options, setOptions] = React.useState<CriterionOption[]>([]);
  const targeting = targetingCodec.fromSchemaTargeting(targetingProp);
  const [getMatchSchema, { data, loading }] =
    useDeriveClientEvaluationSchemaMutation();
  const prev = React.useRef({
    mustMatchSchema:
      [] as FlagsAdminV1EvaluationContextSchemaSchemaEntryInput[],
    clients: [] as string[],
  });

  React.useEffect(() => {
    const mustMatchSchema = getMustMatchEvaluationContextSchema(
      targeting,
      targetingKeySelector,
    );
    if (
      clients.length > 0 &&
      (!isEqual(prev.current.mustMatchSchema, mustMatchSchema) ||
        !isEqual(prev.current.clients, clients))
    ) {
      prev.current = { mustMatchSchema, clients };

      getMatchSchema({
        variables: {
          schema: mustMatchSchema,
          clients,
        },
      });
      prev.current = { mustMatchSchema, clients };
    }
  }, [targeting, targetingKeySelector, clients]);

  React.useEffect(() => {
    const uniqueFields = new Set<string>();

    // Prevent options from flickering while loading
    if (loading) {
      return;
    }
    const schema = getTypeOrNull(
      data?.deriveClientEvaluationContextSchema,
      'FlagsAdminV1DeriveClientEvaluationContextSchemaResponse',
    );
    setOptions(
      (schema?.mergedSchema?.schema || [])
        .flatMap(option => {
          return (option.value?.types || []).map(o => ({
            name: option.key,
            displayName: option.value?.displayName ?? undefined,
            type: MAP_SCHEMAFIELD_TO_ATTRIBUTE[o],
            hidden: option.value?.hidden ?? undefined,
            semanticType: option.value?.semanticType ?? undefined,
          }));
        })
        .filter(value => {
          const key = `${value.name}_${value.type}`;
          if (uniqueFields.has(key)) {
            return false;
          }

          uniqueFields.add(key);
          return true;
        })
        .sort((a, b) =>
          (a.displayName || a.name).localeCompare(b.displayName || b.name),
        ),
    );
  }, [data, loading]);

  return clients.length === 0 ? [] : options;
}

export function useSegmentState(remoteSegment: SegmentFragment) {
  const initialSegment = React.useRef(remoteSegment);
  const [segment, setSegment] = React.useState(remoteSegment);

  const remoteIsDifferentFromInitial = segmentIsDifferent(
    remoteSegment,
    initialSegment.current,
  );
  const currentIsDifferentFromInitial = segmentIsDifferent(
    segment,
    initialSegment.current,
  );
  const currentIsDifferentFromRemote = segmentIsDifferent(
    remoteSegment,
    segment,
  );

  // Update the current segment to match the remote one
  const applyRemoteChanges = () => {
    setSegment(remoteSegment);
    initialSegment.current = remoteSegment;
  };

  // If the remote segment matches the user defined one, we want to update the original for future comparisons
  // For example: the user saved the segment and the remote one was updated
  React.useEffect(() => {
    if (!currentIsDifferentFromRemote) {
      initialSegment.current = remoteSegment;
    }
  }, [remoteSegment, currentIsDifferentFromRemote]);

  // If the user has not changed the segment, but the remote one is different, we want to apply the remote changes
  // For example: if targeting changes while viewing an experiment, we want to show the latest version
  React.useEffect(() => {
    if (!currentIsDifferentFromInitial && remoteIsDifferentFromInitial) {
      applyRemoteChanges();
    }
  }, [currentIsDifferentFromInitial, remoteIsDifferentFromInitial]);

  return {
    remoteIsDifferentFromInitial,
    currentIsDifferentFromInitial,
    currentIsDifferentFromRemote,
    applyRemoteChanges,
    segment,
    setSegment,
  };
}
