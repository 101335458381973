import React from 'react';

import {
  Box,
  Card,
  CardContent,
  IconButton,
  Tooltip,
  Typography,
  makeStyles,
  styled,
} from '@material-ui/core';
import Cancel from '@material-ui/icons/Cancel';
import EditIcon from '@material-ui/icons/Edit';
import ImageIcon from '@material-ui/icons/Image';

import {
  DraggableCard,
  DraggableCardProps,
} from '@spotify-confidence/core-react';
import { CommentZone } from '@spotify-confidence/plugin-comments-react';

// Styled image container for the treatment card
const ImageContainer = styled('div')(({ theme }) => ({
  aspectRatio: '1 / 1',
  position: 'relative',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  width: '100%',
  background: theme.palette.action.hover,
  borderRadius: theme.shape.borderRadius,
  overflow: 'hidden',
  marginBottom: theme.spacing(1),
}));

// Styled image for the treatment card
const TreatmentImage = styled('img')({
  position: 'absolute',
  height: '100%',
  width: '100%',
  objectFit: 'cover',
});

const useStyles = makeStyles({
  cardContent: {
    height: '100%',
    justifyContent: 'space-between',
    display: 'flex',
    flexDirection: 'column',
  },
  root: {
    // 100% so that the card can expand to the full height of
    // the grid. all cards will have the same height.
    height: '100%',
  },
  variantName: {
    wordBreak: 'break-all',
  },
});

export type TreatmentCardProps = {
  name: string;
  description?: string;

  // The image source for the treatment.
  imageSrc?: string;

  // If a placeholder for an image should be shown, if there is no image.
  imagePlaceholder?: boolean;

  weight?: number;
  recommendation?: React.ReactNode;
  onWeightChange?: (v: number) => void;
  onRemove?: () => void;
  onEdit?: () => void;
  loading?: boolean;
  disabled?: boolean;
  draggable?: boolean;
  isControlGroup?: boolean;
  children?: React.ReactNode;
} & DraggableCardProps;

/**
 * Presentation component for a treatment card.
 *
 * Design losely based on Encore Cards.
 */
export function TreatmentCard({
  id,
  name: variantName,
  imageSrc,
  imagePlaceholder,
  description,
  weight,
  onWeightChange,
  onRemove,
  onEdit,
  loading,
  disabled,
  draggable,
  isControlGroup,
  recommendation,
  children,
  ...draggableProps
}: TreatmentCardProps) {
  const classes = useStyles();

  // If the card is supposed to be draggable, we need to render a different
  // component. Otherwise, we render a regular card.
  const CardComponent = draggable ? DraggableCard : Card;

  return (
    <CommentZone id="treatment.card" data={{ variantName }}>
      <CardComponent
        id={id}
        data-testid={`treatment-card-${variantName}`}
        className={classes.root}
        draggable={draggable}
        disabled={disabled}
        {...draggableProps}
      >
        <CardContent className={classes.cardContent}>
          {/* Media slot */}
          {(imageSrc || imagePlaceholder) && (
            <ImageContainer>
              {imageSrc ? (
                <TreatmentImage
                  src={imageSrc}
                  alt={`${variantName} treatment`}
                />
              ) : (
                <ImageIcon fontSize="large" />
              )}
            </ImageContainer>
          )}

          <Box
            display="flex"
            justifyContent="space-between"
            alignItems="flex-start"
            // flex=1 here so that this section is expanded to the full height
            // if needed
            flex="1"
          >
            <Box data-testid={`card-header-${variantName}`}>
              {/* Pretitle slot */}
              <Typography variant="body2" color="textSecondary">
                {isControlGroup ? 'Control' : 'Treatment'}
              </Typography>
              {/* Title slot */}
              <Tooltip interactive title={`Treatment name: ${id}`}>
                <Typography
                  variant="h6"
                  color="textPrimary"
                  className={classes.variantName}
                >
                  {variantName}
                </Typography>
              </Tooltip>
              {/* Subtitle slot */}
              {description && (
                <Typography variant="body1" color="textSecondary" gutterBottom>
                  {description}
                </Typography>
              )}
            </Box>

            {/* Trailing slot*/}
            <Box
              display="flex"
              justifyContent="flex-start"
              alignItems="flex-start"
              marginTop={2}
            >
              {recommendation}

              {!disabled && !!onEdit && (
                <IconButton
                  disabled={loading}
                  onClick={onEdit}
                  size="small"
                  aria-label="edit"
                  name="edit-button"
                >
                  <EditIcon />
                </IconButton>
              )}
              {!disabled && !!onRemove && (
                <IconButton
                  disabled={loading}
                  onClick={onRemove}
                  size="small"
                  aria-label="remove"
                  name="remove-button"
                >
                  <Cancel />
                </IconButton>
              )}
            </Box>
          </Box>

          {/* Body slot */}
          {children}
        </CardContent>
      </CardComponent>
    </CommentZone>
  );
}
