/*
 * Copyright 2020 The Backstage Authors
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *     http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */
// IMPORTANT
// This is a copy of the Page component from the Backstage core-components package.
// We need to copy it here because the original component do not expose a ref, which
// is needed for us to be able to implement the floating menu.
//
// https://github.com/backstage/backstage/blob/master/packages/core-components/src/layout/Page/Page.tsx
import React, { forwardRef } from 'react';

import { Theme, ThemeProvider, makeStyles } from '@material-ui/core/styles';

export type PageClassKey = 'root';

const useStyles = makeStyles(
  theme => ({
    root: {
      display: 'grid',
      gridTemplateAreas:
        "'pageHeader pageHeader pageHeader' 'pageSubheader pageSubheader pageSubheader' 'pageNav pageContent pageSidebar'",
      gridTemplateRows: 'max-content auto 1fr',
      gridTemplateColumns: 'auto 1fr auto',
      overflowY: 'auto',
      height: '100vh',
      [theme.breakpoints.down('xs')]: {
        height: '100%',
      },
      '@media print': {
        display: 'block',
        height: 'auto',
        overflowY: 'inherit',
      },
    },
  }),
  { name: 'BackstagePage' },
);

type Props = {
  themeId: string;
  children?: React.ReactNode;
};

export const BackstagePage = forwardRef<HTMLDivElement, Props>(
  function BackstagePage(props: Props, ref) {
    const { themeId, children } = props;
    const classes = useStyles();
    return (
      <ThemeProvider
        theme={(baseTheme: Theme) => ({
          ...baseTheme,
          page: baseTheme.getPageTheme({ themeId }),
        })}
      >
        <main ref={ref} className={classes.root}>
          {children}
        </main>
      </ThemeProvider>
    );
  },
);
