import {
  EventSchemaEntryFragment,
  EventSchemaStringSchemaFragment,
  EventsAdminV1EventSchema,
  EventsAdminV1FieldSemanticType,
  EventsAdminV1FieldSemanticTypeCountrySemanticTypeCountryFormat,
} from '@spotify-confidence/plugin-graphql';
import _ from 'lodash';

import { Option } from '../components/SchemaEditor/AddFieldButton';
import { Entry } from './schema.hooks';

export const schemaTypeDisplayName = (name: string = '') =>
  _.capitalize(
    name
      .replace('Schema', '')
      .replace('Reference', '')
      .replace('Type', '')
      .replace('country', 'Country code'),
  );

const isStringSchema = (
  value: EventSchemaEntryFragment['value'],
): value is EventSchemaStringSchemaFragment => !!value?.stringSchema;

export const findSchemaType = (
  schema?: Maybe<EventSchemaEntryFragment['value']>,
): {
  schemaType: keyof EventsAdminV1EventSchema;
  semanticType?: keyof EventsAdminV1FieldSemanticType;
} => {
  const cleanObj = <T extends {}>(o?: T | null) =>
    _.omit(
      _.pickBy(o ?? {}, v => !_.isNull(v)),
      '__typename',
    );

  const cleanedSchema = cleanObj(schema);
  const [type] = Object.keys(cleanedSchema);
  if (type === 'stringSchema' && isStringSchema(cleanedSchema)) {
    const semanticType = cleanedSchema.stringSchema?.semanticType ?? {};
    const [semanticTypeKey] = Object.keys(cleanObj(semanticType));
    if (semanticTypeKey) {
      return {
        schemaType: type,
        semanticType: semanticTypeKey as keyof EventsAdminV1FieldSemanticType,
      };
    }
  }
  return {
    schemaType: type as keyof EventsAdminV1EventSchema,
    semanticType: undefined,
  };
};

export const formatName = (name: string) => {
  if (!name) return '';
  return name.replace(/\s+/g, '-');
};

export const appendId = (curId: string, newId: string | number) =>
  `${curId}.${newId}`;

export const getEntryByIdentifier = (
  schema: EventSchemaEntryFragment[],
  identifier: string | null,
) => {
  if (identifier === null) return { entry: null, path: [], siblingKeys: [] };
  let entry: EventSchemaEntryFragment | null = null;
  let path: string[] = [];
  let siblingKeys: string[] = [];

  const _lookForEntry = (
    currentIdentifier: string,
    p: string[],
    s: EventSchemaEntryFragment[],
  ) => {
    return s.forEach((e, i) => {
      const id = appendId(currentIdentifier, i);
      const currentPath = [...p, e.key];
      if (id === identifier) {
        path = currentPath;
        entry = e;
        siblingKeys = s.map(en => en.key).filter((_e, idx) => idx !== i);
        return;
      }
      if (e.value?.structSchema) {
        _lookForEntry(id, currentPath, e.value.structSchema.schema);
      }
      if (e.value?.listSchema?.elementSchema?.structSchema) {
        _lookForEntry(
          id,
          currentPath,
          e.value?.listSchema?.elementSchema?.structSchema.schema,
        );
      }
    });
  };

  _lookForEntry('0', [], schema);
  return { entry, path, siblingKeys };
};

export const modifyStructSchema = (
  e: Entry,
  callback: (i: Entry[]) => Entry[],
) => ({
  ...e,
  value: {
    structSchema: {
      ...e.value?.structSchema,
      schema: callback(e.value?.structSchema?.schema ?? []),
    },
  },
});

export const modifyListStructSchema = (
  e: Entry,
  callback: (i: Entry[]) => Entry[],
) => ({
  ...e,
  value: {
    listSchema: {
      ...e.value?.listSchema,
      elementSchema: {
        ...e.value?.listSchema?.elementSchema,
        structSchema: {
          ...e.value?.listSchema?.elementSchema?.structSchema,
          schema: callback(
            e.value?.listSchema?.elementSchema?.structSchema?.schema ?? [],
          ),
        },
      },
    },
  },
});

export function getTypeSchema(
  type: Option,
  metadata: { displayName: string | null; description: string | null } = {
    displayName: null,
    description: null,
  },
): EventSchemaEntryFragment['value'] {
  switch (type) {
    case 'entityReference':
      return {
        stringSchema: {
          ...metadata,
          displayName: '',
          semanticType: {
            entityReference: {
              entity: {
                __typename: 'MetricsV1Entity',
                name: '',
                displayName: '',
              },
            },
          },
        },
      };
    case 'enumType':
      return {
        stringSchema: {
          ...metadata,
          semanticType: {
            enumType: {
              values: [],
            },
          },
        },
      };
    case 'country':
      return {
        stringSchema: {
          ...metadata,
          semanticType: {
            country: {
              format:
                EventsAdminV1FieldSemanticTypeCountrySemanticTypeCountryFormat.TwoLetterIsoCode,
            },
          },
        },
      };
    case 'boolSchema':
      return {
        boolSchema: {
          ...metadata,
        },
      };
    case 'dateSchema':
      return {
        dateSchema: {
          ...metadata,
        },
      };
    case 'doubleSchema':
      return {
        doubleSchema: {
          ...metadata,
        },
      };
    case 'intSchema':
      return {
        intSchema: {
          ...metadata,
        },
      };
    case 'listSchema':
      return {
        listSchema: {
          ...metadata,
          elementSchema: {},
        },
      };
    case 'stringSchema':
      return {
        stringSchema: {
          ...metadata,
        },
      };
    case 'timestampSchema':
      return { timestampSchema: { ...metadata } };
    case 'structSchema':
      return { structSchema: { schema: [] } };
    default:
      return {};
  }
}

export const getNestedSchema = (value?: EventSchemaEntryFragment['value']) => {
  return value?.structSchema || value?.listSchema?.elementSchema?.structSchema;
};

export const differenceBetweenSchemas = (
  existing: EventSchemaEntryFragment[],
  draft: EventSchemaEntryFragment[],
  path: string[] = [],
): string[][] => {
  const existingKeys = existing.map(e => e.key);
  const draftKeys = draft.map(e => e.key);

  const changed = [];

  // For added keys we do not traverse down the schema but instead
  // just mark that the key was added
  const added = draft.filter(e => !existingKeys.includes(e.key));
  for (const { key } of added) {
    changed.push([...path, key]);
  }

  // For overlapping keys we traverse down the schema
  // to identify changes. but we only do it for nested
  // schemas
  const overlapping = existing
    .filter(e => draftKeys.includes(e.key))
    .filter(e => getNestedSchema(e.value));

  for (const { key: existingKey, value: existingValue } of overlapping) {
    const draftValue = draft.find(e => e.key === existingKey)?.value;
    const nestedDraftSchema = getNestedSchema(draftValue);
    const nestedSchema = getNestedSchema(existingValue);

    if (!nestedDraftSchema) {
      continue;
    }
    if (!nestedSchema) {
      continue;
    }

    const subPath = [...path, existingKey];
    const subChanges = differenceBetweenSchemas(
      nestedSchema.schema,
      nestedDraftSchema.schema,
      subPath,
    );

    changed.push(...subChanges);
  }

  return changed;
};

export const findIdentifier = (
  schema: EventSchemaEntryFragment[],
  path: string[],
  current: string,
): string | undefined => {
  for (let i = 0; i < schema.length; i++) {
    const next = `${current}.${i}`;

    if (schema[i].key === path[0]) {
      if (path.length === 1) {
        return next;
      }

      return findIdentifier(
        getNestedSchema(schema[i].value)?.schema ?? [],
        path.slice(1),
        next,
      );
    }
  }

  return undefined;
};

const notEmpty = <T extends unknown>(
  value: T | null | undefined,
): value is T => {
  return value !== null && value !== undefined;
};

export const identifiersForSchema = (
  schema: EventSchemaEntryFragment[],
  paths: string[][],
) => {
  return paths.map(path => findIdentifier(schema, path, '0')).filter(notEmpty);
};

export const getHasInvalidChild = (sc: EventSchemaEntryFragment[]): boolean => {
  const usedNames = sc.map(s => s.key);
  return sc.some((s: EventSchemaEntryFragment, idx: number) => {
    if (s.key === '') return true;
    if (usedNames.indexOf(s.key, idx + 1) !== -1) return true;
    if (s.value?.structSchema?.schema)
      return getHasInvalidChild(s.value.structSchema.schema);
    if (s.value?.listSchema?.elementSchema?.structSchema?.schema)
      return getHasInvalidChild(
        s.value.listSchema.elementSchema.structSchema?.schema,
      );
    return false;
  });
};

export const KEY_JOINER = '/';
export const getPermanentKeys = (schema: EventSchemaEntryFragment[] = []) => {
  const _findKeys = (
    s: EventSchemaEntryFragment[],
    current: string,
  ): string[] => {
    return s.flatMap(e => {
      const currentKey =
        current === '' ? e.key : [current, e.key].join(KEY_JOINER);
      if (e.value?.structSchema?.schema) {
        return [
          currentKey,
          ..._findKeys(e.value.structSchema.schema, currentKey),
        ];
      }
      if (e.value?.listSchema?.elementSchema?.structSchema?.schema) {
        return [
          currentKey,
          ..._findKeys(
            e.value?.listSchema?.elementSchema?.structSchema?.schema,
            currentKey,
          ),
        ];
      }
      return [currentKey];
    });
  };
  return _findKeys(schema, '');
};
