import { round } from 'lodash';

import {
  GuardrailMetric,
  Metric,
  MetricDirection,
  MetricInput,
  MetricKind,
  SuccessMetric,
} from './types';

export const toNonDesiredDirection = (preferredDirection: MetricDirection) => {
  if (preferredDirection === MetricDirection.INCREASE) {
    return MetricDirection.DECREASE;
  }

  return MetricDirection.INCREASE;
};

export const toPreferredDirection = (nonDesiredDirection: MetricDirection) => {
  if (nonDesiredDirection === MetricDirection.INCREASE) {
    return MetricDirection.DECREASE;
  }

  return MetricDirection.INCREASE;
};

export function isGuardrailMetric(
  metric: Metric | MetricInput,
): metric is (Metric | MetricInput) & { metricRole: GuardrailMetric } {
  return (
    !!(metric.metricRole as GuardrailMetric)?.hasOwnProperty(
      'nonInferiorityMargin',
    ) ||
    (metric.metricRole as GuardrailMetric)?.metricKind === MetricKind.GUARDRAIL
  );
}

export function isSuccessMetric(
  metric: Metric | MetricInput,
): metric is (Metric | MetricInput) & { metricRole: SuccessMetric } {
  return (
    !!(metric.metricRole as SuccessMetric)?.hasOwnProperty(
      'minimumDetectableEffect',
    ) || metric.metricRole?.metricKind === MetricKind.SUCCESS
  );
}

export const formatPercentage = (x: number | undefined | null) => {
  return !!x ? round(x * 100, 2) : undefined;
};

export const hasPlannedEffectSize = (metric: Metric) =>
  (isSuccessMetric(metric) && metric.metricRole.minimumDetectableEffect) ||
  (isGuardrailMetric(metric) && metric.metricRole.nonInferiorityMargin);
