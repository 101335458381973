import React from 'react';

import { Button, CircularProgress, TextField } from '@material-ui/core';
import { Alert } from '@material-ui/lab';

import { FormFieldsBox } from '@spotify-confidence/core-react';
import {
  ConnectorsV1FlagAppliedConnectionBigQueryFlagAppliedConnectionConfigInput as BigQueryInput,
  useValidateBigQueryFlagAppliedConnectionConfigMutation,
} from '@spotify-confidence/plugin-graphql';

import { BigQueryConfigForm } from './BigQueryConfigForm';
import { isBigQueryConfigValid } from './helpers';

type BigQueryFlagAppliedConnectionFormProps = {
  value: BigQueryInput;
  onChange: (conf: BigQueryInput) => void;
  disabled?: boolean;
};

export const BigQueryFlagAppliedConnectionForm = ({
  value,
  onChange,
  disabled,
}: BigQueryFlagAppliedConnectionFormProps) => {
  const [validate, validation] =
    useValidateBigQueryFlagAppliedConnectionConfigMutation({
      variables: {
        bigQuery: value,
      },
    });

  const canValidate = Boolean(
    value.table && isBigQueryConfigValid(value.bigQueryConfig),
  );

  const handleValidate = async () => {
    try {
      await validate();
    } catch (e) {
      //   We use the error from the validation object
    }
  };

  return (
    <FormFieldsBox>
      <BigQueryConfigForm
        value={value?.bigQueryConfig}
        disabled={disabled}
        onChange={newBigQueryConfig =>
          onChange({
            ...value,
            bigQueryConfig: newBigQueryConfig,
          })
        }
      />
      <TextField
        disabled={disabled}
        label="Table"
        variant="outlined"
        value={value.table}
        id="table"
        required
        fullWidth
        helperText="The table to write the data to. The connector automatically creates the table. If the table already exists, the connector verifies that the schema looks as expected, otherwise it attempts to create the required columns."
        onChange={e => onChange({ ...value, table: e.target.value })}
      />
      <div>
        <Button
          size="small"
          variant="outlined"
          disabled={!canValidate || validation.loading}
          onClick={handleValidate}
          endIcon={validation.loading && <CircularProgress size="1em" />}
        >
          Validate
        </Button>
      </div>
      {validation.data && (
        <Alert severity="success">Validation successful!</Alert>
      )}
      {validation.error && (
        <Alert severity="error">{validation.error.message}</Alert>
      )}
    </FormFieldsBox>
  );
};
