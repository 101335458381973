import React from 'react';

import { Typography } from '@material-ui/core';
import { Alert } from '@material-ui/lab';

import {
  LoadingOverlay,
  useAlert,
  useAuth,
  useNavigate,
} from '@spotify-confidence/core-react';
import _ from 'lodash';

import { Branding } from './CreateAccountForm';
import { IntroStep } from './IntroStep';
import { InviteResponse } from './hooks/useInvite';
import final from './img/final.png';

export const AccountCreated = ({
  createdAccount,
}: {
  createdAccount: NonNullable<InviteResponse['createdAccount']>;
}) => {
  const navigate = useNavigate();
  const [loggingIn, setLoggingIn] = React.useState(false);

  const alert = useAlert();
  const { isLoading: loading, loginWithRedirect, error } = useAuth();

  const handleLogin = async () => {
    setLoggingIn(true);
    try {
      await loginWithRedirect({
        authorizationParams: {
          organization: createdAccount.loginId,
        },
      });
      navigate('/');
    } catch (e) {
      let errorMessage = 'Something went wrong';
      if (_.isError(e)) {
        errorMessage = e.message;
      } else if (_.isString(e)) {
        errorMessage = e;
      }
      alert.post({
        severity: 'error',
        message: errorMessage,
      });
    } finally {
      setLoggingIn(false);
    }
  };

  return (
    <>
      <Branding />
      <IntroStep
        title="Wonderful! You're all set!"
        img={final}
        description={
          <>
            {error && (
              <Alert severity="error">
                {error.message.length === 0
                  ? 'Something went wrong'
                  : error.message}
              </Alert>
            )}
            <Typography>
              Psst. Your workspace ID is{' '}
              <Typography component="span" color="primary">
                {createdAccount.loginId}
              </Typography>
              .<br /> Don't lose it! You'll need it to log in.
            </Typography>
          </>
        }
        buttonLabel="Log in"
        onNext={handleLogin}
        disabled={loading}
      />
      <LoadingOverlay loading={loading || loggingIn} />
    </>
  );
};
