import React from 'react';

import { Box, Button, Typography, makeStyles } from '@material-ui/core';

const useStyles = makeStyles(theme => ({
  title: {
    lineHeight: '2.5rem',
    marginBottom: theme.spacing(1),
  },
  wrapper: {
    textAlign: 'center',
    maxWidth: 520,
    display: 'flex',
    gap: theme.spacing(6),
    flexDirection: 'column',
    alignItems: 'center',
    paddingTop: '15vh',
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
  },
  img: {
    objectFit: 'contain',
  },
}));

export const IntroStep = ({
  title,
  description,
  buttonLabel = 'Continue',
  onNext,
  img,
  disabled,
}: {
  title: React.ReactNode;
  description?: React.ReactNode;
  buttonLabel?: string;
  onNext?: () => void;
  img: string;
  disabled?: boolean;
}) => {
  const classes = useStyles();
  return (
    <Box display="flex" height="100%" justifyContent="center">
      <Box className={classes.wrapper}>
        {img && <img className={classes.img} src={img} alt="" width="350" />}
        <div>
          <Typography variant="h4" className={classes.title}>
            {title}
          </Typography>
          {description && (
            <Typography variant="body1">{description}</Typography>
          )}
        </div>
        {onNext && (
          <Button
            variant="contained"
            data-testid="continue"
            onClick={onNext}
            color="primary"
            disabled={disabled}
          >
            {buttonLabel}
          </Button>
        )}
      </Box>
    </Box>
  );
};
