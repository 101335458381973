import React from 'react';

import {
  Collapse,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Radio,
} from '@material-ui/core';

import { PercentageInput } from '@spotify-confidence/core-react';
import _ from 'lodash';

const INPUT_SUGGESTIONS = [
  {
    title: 'High - 90%',
    description: 'Increased sensitivity and reproducibility',
    value: 0.9,
  },
  {
    title: 'Standard - 80%',
    description: 'The standard recommendation for a typical A/B test',
    value: 0.8,
  },
];

const getSelectedSuggestion = (
  v?: Maybe<number>,
): PowerSuggestionValue | undefined => {
  if (v) {
    const suggestions = INPUT_SUGGESTIONS.map(s => s.value);
    return suggestions.includes(v) ? v : 'CUSTOM';
  }
  return undefined;
};

type PowerSuggestionValue = number | 'CUSTOM';

type PowerPickerProps = {
  power?: number | null;
  onChange: (v: number | undefined) => void;
};

export function PowerPicker({ power, onChange }: PowerPickerProps) {
  const [powerSuggestion, setPowerSuggestion] = React.useState(
    getSelectedSuggestion(power),
  );

  const pickPower = (value: PowerSuggestionValue) => {
    return () => {
      if (value !== powerSuggestion) {
        setPowerSuggestion(value);
        onChange(typeof value === 'number' ? value : undefined);
      }
    };
  };
  return (
    <List disablePadding>
      {INPUT_SUGGESTIONS.map((s, idx) => (
        <ListItem
          button
          onClick={pickPower(s.value)}
          key={s.description}
          disableGutters
        >
          <ListItemIcon>
            <Radio
              color="primary"
              name="powerSuggestion"
              id={`powerSuggestion-${idx}`}
              value={s.value}
              checked={powerSuggestion === s.value}
            />
          </ListItemIcon>
          <ListItemText primary={s.title} secondary={s.description} />
        </ListItem>
      ))}
      <ListItem button onClick={pickPower('CUSTOM')} disableGutters>
        <ListItemIcon>
          <Radio
            name="powerSuggestion"
            value="CUSTOM"
            color="primary"
            checked={powerSuggestion === 'CUSTOM'}
          />
        </ListItemIcon>
        <ListItemText
          primary="Custom"
          secondary={
            <Collapse in={powerSuggestion === 'CUSTOM'} unmountOnExit>
              <PercentageInput
                value={_.isNumber(power) ? power * 100 : undefined}
                onChange={v =>
                  _.isNumber(v) ? onChange(v / 100) : onChange(undefined)
                }
                required
                fullWidth={false}
              />
            </Collapse>
          }
        />
      </ListItem>
    </List>
  );
}
