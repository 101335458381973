import React from 'react';

import {
  Box,
  Table,
  TableBody,
  TableCell,
  TableRow,
  Tooltip,
  Typography,
} from '@material-ui/core';
import Help from '@material-ui/icons/Help';

import {
  ACTION_BAR_HEIGHT,
  FilterListEmptyState,
  StickyTableContainer,
  StickyTableHead,
  useListFilter,
} from '@spotify-confidence/core-react';
import { FlagsAdminV1EvaluationContextFieldOverrideInput } from '@spotify-confidence/plugin-graphql';

import { SchemaItem } from '../types';
import { SchemaItemRow } from './SchemaItemRow';

type SchemaListProps = {
  topOffset?: number;
  createOverride?: (
    defaultalues?: FlagsAdminV1EvaluationContextFieldOverrideInput,
  ) => void;
  loading?: boolean;
  error?: Error | null;
  items?: SchemaItem[];
  existingOverrides?: string[];
};

export const SchemaList = ({
  topOffset = ACTION_BAR_HEIGHT,
  items = [],
  error,
  loading,
  createOverride,
  existingOverrides,
}: SchemaListProps) => {
  const { searchQuery } = useListFilter();

  const filteredItems = React.useMemo(() => {
    return items.filter(item => {
      return (
        item.field.toLowerCase().includes(searchQuery.toLowerCase()) ||
        item.displayName?.toLowerCase().includes(searchQuery.toLowerCase())
      );
    });
  }, [items, searchQuery]);

  if (filteredItems.length === 0) {
    return (
      <FilterListEmptyState
        loading={loading}
        error={error}
        type="override"
        typePlural="schema values"
        onCreate={createOverride}
        description={
          <>
            This view will list all context values that have been sent by
            different clients as well as overrides. Create overrides to guide
            users when setting up targeting for rules, segments and experiments.
          </>
        }
      />
    );
  }

  return (
    <StickyTableContainer error={error} loading={loading}>
      <Table>
        <StickyTableHead topOffset={topOffset}>
          <TableRow>
            <TableCell>Display name</TableCell>
            <TableCell>Field</TableCell>
            <TableCell>Value</TableCell>
            <TableCell width={100} align="center">
              <Tooltip
                title="If checked, the field will show up as a selectable option when setting up targeting in rules, segments and experiments."
                placement="top"
                arrow
              >
                <Box display="inline-flex" gridGap={4} alignItems="center">
                  Visible
                  <Help fontSize="small" />
                </Box>
              </Tooltip>
            </TableCell>
            <TableCell width={50}>
              <Typography variant="srOnly">Actions</Typography>
            </TableCell>
          </TableRow>
        </StickyTableHead>
        <TableBody>
          {filteredItems.map((item, index) => (
            <SchemaItemRow
              key={item.name || `${item.field}-${index}`}
              override={item}
              createOverride={createOverride}
              existingOverrides={existingOverrides}
            />
          ))}
        </TableBody>
      </Table>
    </StickyTableContainer>
  );
};
