import React from 'react';
import { useParams, useSearchParams } from 'react-router-dom';

import { Typography } from '@material-ui/core';
import { Alert } from '@material-ui/lab';

import {
  Breadcrumbs,
  PageLayout,
  createHeaderTab,
  useMeasureDimensions,
} from '@spotify-confidence/core-react';
import {
  getError,
  getTypeOrNull,
  useWorkflowInstanceQuery,
} from '@spotify-confidence/plugin-graphql';

import { HeaderTabs, Link, Tab } from '@backstage/core-components';
import { useRouteRef } from '@backstage/core-plugin-api';

import {
  workflowInstanceEventsRouteRef,
  workflowInstanceOverviewRouteRef,
  workflowInstancesRouteRef,
  workflowsRouteRef,
} from '../../../routes';
import { WorkflowInstanceEvents } from './WorkflowInstanceEvents';
import { WorkflowInstanceOverview } from './WorkflowInstanceOverview';

const useTabs = ({ id, instance }: { id: string; instance: string }) => {
  const [searchParams] = useSearchParams();
  const buildUrl = (base: string) => `${base}?${searchParams.toString()}`;

  const workflowInstanceOverviewRoute = useRouteRef(
    workflowInstanceOverviewRouteRef,
  );
  const workflowInstanceEventsRoute = useRouteRef(
    workflowInstanceEventsRouteRef,
  );

  const tabs: Tab[] = [
    createHeaderTab({
      id: 'overview',
      label: 'Overview',
      to: buildUrl(workflowInstanceOverviewRoute({ id, instance })),
    }),
    createHeaderTab({
      id: 'events',
      label: 'Events',
      to: buildUrl(workflowInstanceEventsRoute({ id, instance })),
    }),
  ];

  // eslint-disable-next-line no-nested-ternary
  const selectedIndex = location.pathname.includes('events')
    ? tabs.findIndex(tab => tab.id === 'events')
    : 0;
  const selectedTabId = tabs[selectedIndex].id;

  return {
    tabs,
    selectedTabId,
    selectedIndex,
  };
};

export const WorkflowInstancePage = () => {
  const { id, instance } = useParams();
  const workflowInstanceName = `workflows/${id}/instances/${instance}`;
  const workflowInstancesRoute = useRouteRef(workflowInstancesRouteRef);
  const workflowsRoute = useRouteRef(workflowsRouteRef);

  const { data } = useWorkflowInstanceQuery({
    variables: { name: workflowInstanceName },
  });

  const { tabs, selectedTabId, selectedIndex } = useTabs({
    id: id!,
    instance: instance!,
  });
  const [tabsRef] = useMeasureDimensions<HTMLDivElement>();
  const workflowInstance = getTypeOrNull(
    data?.workflowInstance,
    'WorkflowV1WorkflowInstance',
  );
  const workflowInstanceError = getError(data?.workflowInstance);
  return (
    <PageLayout
      narrow
      title={workflowInstance?.displayName}
      headerBreadcrumbs={
        <Breadcrumbs>
          <Link to={workflowsRoute()}>Workflows</Link>
          <Link to={workflowInstancesRoute({ id: id! })}>Instances</Link>
          <Typography>{workflowInstance?.displayName}</Typography>
        </Breadcrumbs>
      }
      headerTabsRef={tabsRef}
      headerTabs={<HeaderTabs selectedIndex={selectedIndex} tabs={tabs} />}
    >
      {workflowInstanceError && (
        <Alert severity="error">{workflowInstanceError.message}</Alert>
      )}
      {selectedTabId === 'overview' && (
        <WorkflowInstanceOverview workflowInstanceName={workflowInstanceName} />
      )}
      {selectedTabId === 'events' && (
        <WorkflowInstanceEvents workflowInstanceName={workflowInstanceName} />
      )}
    </PageLayout>
  );
};
