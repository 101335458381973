import React from 'react';

import { getTypeOrNull } from './apollo';
import { AdminV1AccountMode, useCurrentAccountInfoQuery } from './graphql';

export const useAccountMode = () => {
  const { data, loading } = useCurrentAccountInfoQuery();

  const currentAccountData = getTypeOrNull(
    data?.currentAccount,
    'AdminV1AccountInfo',
  );

  const isTotalConfidence =
    currentAccountData?.mode === AdminV1AccountMode.TotalConfidence;
  const isWarehouseNative =
    currentAccountData?.mode === AdminV1AccountMode.WarehouseNative;
  return {
    loading,
    isTotalConfidence,
    isWarehouseNative,
  };
};

export const TotalConfidence = ({
  children,
  fallback = null,
}: React.PropsWithChildren<{ fallback?: React.ReactNode }>) => {
  const { isTotalConfidence } = useAccountMode();
  if (!isTotalConfidence) return fallback;
  return children;
};

export const WarehouseNative = ({
  children,
  fallback = null,
}: React.PropsWithChildren<{ fallback?: React.ReactNode }>) => {
  const { isWarehouseNative } = useAccountMode();
  if (!isWarehouseNative) return fallback;
  return children;
};
