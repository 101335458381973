import {
  StripeCustomerFragmentDoc,
  getError,
  getTypeOrNull,
  useBillingInformationQuery,
  useStripeCustomerUpdatedSubscription,
} from '@spotify-confidence/plugin-graphql';

export const useBillingInfo = () => {
  const {
    data: billingData,
    error: getBillingError,
    loading,
  } = useBillingInformationQuery();
  useStripeCustomerUpdatedSubscription({
    onData: payload => {
      const s = payload.data.data?.stripeCustomerUpdated?.stripeCustomer;
      if (s) {
        const cacheId = payload.client.cache.identify(s);
        if (cacheId) {
          payload.client.cache.writeFragment({
            id: cacheId,
            data: s,
            fragment: StripeCustomerFragmentDoc,
            fragmentName: 'StripeCustomer',
          });
        }
      }
    },
  });

  const stripeCustomer = getTypeOrNull(
    billingData?.currentStripeCustomer,
    'BillingV1StripeCustomer',
  );
  const queryTrialUsageInfo = getTypeOrNull(
    billingData?.queryTrialUsageInfo,
    'BillingV1QueryTrialUsageInfoResponse',
  );
  const error = getError(billingData?.currentStripeCustomer) ?? getBillingError;

  return {
    data: { stripeCustomer, queryTrialUsageInfo },
    error,
    loading,
  };
};
