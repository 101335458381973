import React from 'react';

import { IconButton } from '@material-ui/core';
import MoreHoriz from '@material-ui/icons/MoreHoriz';

import { ContextMenu } from '../ContextMenu';

export const HeaderActionMenu = ({ children }: React.PropsWithChildren<{}>) => {
  return (
    <ContextMenu
      closeOnSelect
      renderButton={p => (
        <IconButton {...p} color="secondary" data-testid="header-action-menu">
          <MoreHoriz fontSize="small" />
        </IconButton>
      )}
      renderMenu={() => children}
    />
  );
};
