import React from 'react';

import { Box, makeStyles } from '@material-ui/core';

import { Row, Table } from '@tanstack/react-table';
import classNames from 'classnames';
import _ from 'lodash';

import * as resultUtils from '../../utils';
import { isRowSelected } from '../tableUtils';
import {
  GroupableColumn,
  MetricResultTableGrouping,
  ResultData,
} from '../types';
import { GroupTitle } from './GroupTitle';
import { ResponsiveIntervalPlot } from './IntervalPlot';
import { ResponsiveTimelineChart } from './TimelineChart';

export type ResultVisualizationType = 'timeline' | 'interval plot' | 'none';

type ResultVisualizationProps = {
  type?: ResultVisualizationType;
  table: Table<ResultData>;
};

function filterOutNonSelected(rows: Row<ResultData>[]) {
  return rows.filter(isRowSelected);
}

const useStyles = makeStyles(theme => ({
  grid: {
    display: 'grid',
    gridTemplateColumns: 'max-content auto',
    border: `1px solid ${theme.palette.divider}`,
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(4),
    borderRadius: theme.shape.borderRadius,
  },
  borderTop: {
    borderTop: `1px solid ${theme.palette.divider}`,
    '&:first-child': {
      borderTop: 'none',
    },
  },
  group: {
    gridColumnStart: '1',
    padding: theme.spacing(2),
    borderRight: `1px solid ${theme.palette.divider}`,
  },
  graphs: {
    gridColumnStart: '2',
    borderBottom: `1px solid ${theme.palette.divider}`,
    '&:last-child': {
      borderBottom: 'none',
    },
  },
  graph: {
    padding: theme.spacing(2),
  },
}));

function getLatestRowPerFinalGroup(
  rows: Row<ResultData>[] = [],
  grouping: MetricResultTableGrouping = [],
): Row<ResultData>[] {
  function getLatestRow(row: Row<ResultData>): Row<ResultData>[] {
    if (row.subRows.length > 0) {
      if (row.groupingColumnId === _.last(grouping)) {
        return [
          resultUtils.getLatest(
            filterOutNonSelected(row.subRows),
            r => r.original.timeLabel,
          ),
        ] as Row<ResultData>[];
      }
      return filterOutNonSelected(row.subRows).flatMap(getLatestRow);
    }
    return [row];
  }
  return filterOutNonSelected(rows).flatMap(getLatestRow);
}

export const ResultVisualization = React.forwardRef(
  ({ type, table }: ResultVisualizationProps, forwardedRef) => {
    const classes = useStyles();

    // Workaround since MUI Box is missing ref in type
    const downloadableContainerProps = { ref: forwardedRef };

    const rows = table.getGroupedRowModel().rows;
    const grouping = table.getState().grouping as MetricResultTableGrouping;
    const { sequential, showRelativeChanges, bucket } =
      table.options.meta || {};

    const renderTimelineGraphs = React.useCallback(
      (rowsToRender: Row<ResultData>[]) => {
        return filterOutNonSelected(rowsToRender).map(row => {
          const groupId = row.groupingColumnId as GroupableColumn;
          const groupValue = row.groupingValue as string;

          if (row.groupingColumnId === _.last(grouping)) {
            return (
              <div
                className={classNames(classes.graph, classes.borderTop)}
                key={row.id}
              >
                <Box display="flex" justifyContent="flex-end">
                  <GroupTitle groupId={groupId} groupValue={groupValue} />
                </Box>
                <ResponsiveTimelineChart
                  results={filterOutNonSelected(row.subRows).map(
                    sr => sr.original,
                  )}
                  bucket={bucket}
                  showRelativeChanges={showRelativeChanges}
                />
              </div>
            );
          }
          return (
            <React.Fragment key={row.id}>
              <Box className={classNames(classes.group, classes.borderTop)}>
                <GroupTitle
                  groupId={row.groupingColumnId as GroupableColumn}
                  groupValue={row.groupingValue as string}
                />
              </Box>
              <div className={classes.graphs}>
                {renderTimelineGraphs(row.subRows)}
              </div>
            </React.Fragment>
          );
        });
      },
      [classes, showRelativeChanges, bucket, grouping],
    );

    let content;
    switch (type) {
      case 'timeline': {
        content =
          grouping.length > 0 ? (
            <Box className={classes.grid} {...downloadableContainerProps}>
              {renderTimelineGraphs(rows)}
            </Box>
          ) : (
            // TODO: handle ungrouped results
            <Box marginX={2} {...downloadableContainerProps}>
              <ResponsiveTimelineChart
                results={filterOutNonSelected(rows).map(sr => sr.original)}
                bucket={bucket}
                showRelativeChanges={showRelativeChanges}
              />
            </Box>
          );
        break;
      }

      case 'interval plot': {
        const data = getLatestRowPerFinalGroup(rows, grouping);
        content = (
          <ResponsiveIntervalPlot
            BoxProps={{ marginTop: 2, ...downloadableContainerProps }}
            results={data.map(r => r.original)}
            grouping={grouping}
            showRelativeChanges={showRelativeChanges}
            bucket={bucket}
            sequential={sequential}
          />
        );
        break;
      }

      case 'none':
      default: {
        return null;
      }
    }

    return content;
  },
);
