import React from 'react';

import { Box, Chip, Theme, Typography, styled } from '@material-ui/core';

import { ObjUtils } from '@spotify-confidence/core-react';
import {
  InputMaybe,
  MetricFilterExpressionFragment,
  MetricsV1FilterCriteriaEntryInput,
  MetricsV1FilterCriterionAttributeCriterion,
  MetricsV1FilterInput,
} from '@spotify-confidence/plugin-graphql';
import _ from 'lodash';

import { ValueType, getSelectedOperator } from './Criteria';
import { SetOp, getValueType } from './useMetricFilters';

export const MetricFilterPreview = ({
  filter,
}: {
  filter?: InputMaybe<MetricsV1FilterInput>;
}) => {
  if (!filter) return null;
  if (filter.criteria.length === 0)
    return <Typography variant="body1">No filters</Typography>;
  return (
    <ExpressionPreview
      criterias={filter.criteria}
      expression={filter.expression}
      depth={0}
    />
  );
};

const Operands = styled('div')(
  ({ theme, root }: { theme: Theme; root: boolean }) => ({
    gridGap: theme.spacing(1),
    display: 'flex',
    flexWrap: 'wrap',
    alignItems: 'center',
    width: '100%',
    border: root ? 'none' : `1px solid ${theme.palette.divider}`,
    borderRadius: theme.shape.borderRadius,
    padding: root ? 0 : theme.spacing(1),
    marginTop: theme.spacing(0.5),
  }),
);

function ExpressionPreview({
  expression,
  criterias,
  depth,
}: {
  expression: MetricFilterExpressionFragment;
  criterias?: InputMaybe<MetricsV1FilterCriteriaEntryInput[]>;
  depth: number;
}) {
  if (expression.and || expression.or) {
    const op = Object.keys(ObjUtils.cleanNulls(expression))[0] as SetOp;
    const operands = expression[op]?.operands ?? [];
    return (
      <Operands root={depth === 0}>
        {operands.map((v, idx) => {
          const isLastCriterion = idx === 0;
          return (
            <React.Fragment key={`op-${idx}`}>
              {!isLastCriterion && (
                <Typography variant="body2">{_.capitalize(op)}</Typography>
              )}
              <ExpressionPreview
                expression={v}
                criterias={criterias}
                depth={depth + 1}
              />
            </React.Fragment>
          );
        })}
      </Operands>
    );
  }
  if (expression.ref || expression.not) {
    const ref = expression.not?.ref || expression.ref;
    const criteraValue = (criterias ?? []).find(c => c.key === ref);
    if (!criteraValue || !criteraValue.value) return null;
    const { attribute, ...values } = criteraValue.value?.attribute ?? {};

    const valueType = getValueType(values);
    if (!valueType || !criteraValue.value.attribute)
      return <span>Invalid rule type</span>;

    const op = getSelectedOperator({
      attribute: criteraValue.value.attribute,
      isNot: !!expression.not,
      valueType,
    });

    return (
      <Chip
        size="small"
        label={
          <Box display="flex" gridGap={8} alignItems="center">
            <Typography variant="body2">{attribute}</Typography>
            <Typography variant="body2">{op?.label}</Typography>
            <Typography variant="body2">
              {getValues(criteraValue.value.attribute, valueType)}
            </Typography>
          </Box>
        }
      />
    );
  }
  return null;
}

function getValues(
  attribute: InputMaybe<MetricsV1FilterCriterionAttributeCriterion>,
  valueType: ValueType,
) {
  if (attribute?.eqRule) {
    if (valueType === 'boolValue') {
      return attribute.eqRule.value[valueType] === true ? 'true' : 'false';
    }
    return attribute.eqRule.value[valueType];
  }

  if (attribute?.setRule) {
    return attribute.setRule.values.map(v => v[valueType]).join(', ');
  }
  if (attribute?.rangeRule) {
    const value =
      attribute.rangeRule.startExclusive?.[valueType] ??
      attribute.rangeRule.startInclusive?.[valueType] ??
      attribute.rangeRule.endExclusive?.[valueType] ??
      attribute.rangeRule.endInclusive?.[valueType];

    return value;
  }
  return null;
}
