import {
  FlagsTypesV1TargetingValue,
  TargetingCriterionFragment,
} from '@spotify-confidence/plugin-graphql';

import {
  AttributeType,
  CriterionAttribute,
  OPEN_ENDED_RANGE_RULES,
  ValueType,
  getRuleCriteriaType,
  isClosedRangeValue,
  isSetValue,
  isSingleValue,
} from '../../targeting.model';

export const valueKey = (
  valueType: AttributeType,
): keyof FlagsTypesV1TargetingValue => {
  switch (valueType) {
    case 'Boolean':
      return 'boolValue';
    case 'Number':
      return 'numberValue';
    case 'Timestamp':
      return 'timestampValue';
    case 'Version':
      return 'versionValue';
    case 'String':
    default:
      return 'stringValue';
  }
};

export const toSchemaAttributeCriterion = (
  attCri: CriterionAttribute,
): { key: string; value: TargetingCriterionFragment } => {
  const ruleCriteriaType = getRuleCriteriaType(attCri.op);
  const valueType = valueKey(attCri.attributeType);

  const parseValueType = (value: ValueType) => {
    if (value === null) {
      return null;
    }
    if (valueType === 'boolValue') {
      return value.toUpperCase() === 'TRUE';
    }
    if (valueType === 'numberValue') {
      return value !== '' ? Number(value) : undefined;
    }
    if (valueType === 'timestampValue') {
      return !isNaN(Date.parse(value))
        ? new Date(value).toISOString().replace(/[.]\d+/, '')
        : undefined;
    }
    if (valueType === 'versionValue') {
      return { version: value };
    }
    return value;
  };

  const encodeRule = () => {
    if (ruleCriteriaType === 'eqRule' && isSingleValue(attCri.value)) {
      return {
        eqRule: {
          value: {
            [valueType]: parseValueType(attCri.value),
          },
        },
      };
    } else if (ruleCriteriaType === 'setRule' && isSetValue(attCri.value)) {
      return {
        setRule: {
          values: attCri.value.map(v => ({
            [valueType]: parseValueType(v),
          })),
        },
      };
    } else if (ruleCriteriaType === 'rangeRule') {
      if (isClosedRangeValue(attCri.value)) {
        const { start, end } = attCri.value;
        const startKey = start.inclusive ? 'startInclusive' : 'startExclusive';
        const endKey = end.inclusive ? 'endInclusive' : 'endExclusive';
        return {
          rangeRule: {
            [startKey]: {
              [valueType]: parseValueType(start.value),
            },
            [endKey]: {
              [valueType]: parseValueType(end.value),
            },
          },
        };
      } else if (isSingleValue(attCri.value)) {
        const key = OPEN_ENDED_RANGE_RULES[attCri.op] ?? 'endExclusive';

        return {
          rangeRule: {
            [key]: {
              [valueType]: parseValueType(attCri.value),
            },
          },
        };
      }
    }
    return {};
  };

  return {
    key: attCri.name,
    value: {
      attribute: {
        attributeName: attCri.attribute || '',
        ...(attCri.listMatcherType
          ? {
              [attCri.listMatcherType === 'Any' ? 'anyRule' : 'allRule']: {
                rule: encodeRule(),
              },
            }
          : encodeRule()),
      },
    },
  };
};
