import React from 'react';

import {
  getError,
  getTypeOrNull,
  isType,
  useUsageReportQuery,
} from '@spotify-confidence/plugin-graphql';
import { addDays } from 'date-fns';
import _ from 'lodash';

import { DateRange } from './useDateRange';

export function useUsageReport({
  dates,
  skip,
  showCost,
}: {
  dates: DateRange;
  skip?: boolean;
  showCost?: boolean;
}) {
  const { data, loading, error } = useUsageReportQuery({
    skip,
    variables: {
      startTime: dates.start,
      endTime: addDays(new Date(dates.end), 1).toISOString(), // the end date is exclusive so add one day
    },
  });

  const usageReport = getTypeOrNull(data?.usageReport, 'BillingV1UsageReport');
  const getUsageError = getError(data?.usageReport);
  const lines = React.useMemo(() => usageReport?.lines || [], [data]);

  const byDate = React.useMemo(() => {
    const groupByDate = _.groupBy(lines, line => line.usageTime);
    return Object.entries(groupByDate).map(([period, skuLines]) => {
      const skuValues = skuLines
        .filter(f => isType(f.sku, 'BillingV1Sku'))
        .map(skuLine => {
          return {
            // @ts-ignore
            [skuLine.sku!.displayName]: showCost
              ? Number(skuLine.billedAmount?.value)
              : skuLine.amount,
          };
        })
        .reduce((acc, cur) => ({
          ...acc,
          ...cur,
        }));

      return {
        period,
        ...skuValues,
      };
    });
  }, [lines]);
  // @ts-ignore
  const groupBySku = _.groupBy(lines, line => line.sku?.displayName);
  const bySku = React.useMemo(() => {
    return Object.entries(groupBySku).map(([sku, skuLines]) => {
      return {
        sku,
        amountSum: _.sumBy(skuLines, line => line.amount),
        billedAmountSum: _.sumBy(skuLines, line =>
          Number(line.billedAmount?.value),
        ),
      };
    });
  }, [groupBySku]);

  return {
    skus: Object.keys(groupBySku),
    byDate,
    bySku,
    total: usageReport?.total?.value,
    loading,
    error: error ?? getUsageError,
  };
}
