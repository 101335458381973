import React from 'react';

import {
  Box,
  Card,
  CardContent,
  FormHelperText,
  Typography,
  styled,
} from '@material-ui/core';

import { useDisplayNames } from '@spotify-confidence/core-react';
import { MetricsV1MetricInput } from '@spotify-confidence/plugin-graphql';

import { TimeWindowDropdown } from '../../TimeWindowDropdown';
import { parseExposureOffset } from '../utils';

type TimeWindowFormProps = {
  value: MetricsV1MetricInput;
  onChange: (newInput: MetricsV1MetricInput) => void;
  disabled?: boolean;
  entity?: string;
};

const Wrapper = styled('div')(({ theme }) => ({
  display: 'flex',
  flexWrap: 'wrap',
  alignItems: 'center',
  justifyContent: 'center',
  columnGap: '1em',
  [theme.breakpoints.up('sm')]: {
    padding: theme.spacing(2, 3),
  },
}));

const ValueWrapper = styled('div')({
  display: 'flex',
  flexWrap: 'wrap',
  alignItems: 'center',
  justifyContent: 'start',
  flexGrow: 1,
  columnGap: '1em',
});

export const TimeWindowForm = ({
  value,
  onChange,
  disabled,
  entity,
}: TimeWindowFormProps) => {
  const { displayNames } = useDisplayNames();
  const entityName = displayNames.get(entity || '');
  const entityValue = entityName?.toLocaleLowerCase() || 'entity';

  return (
    <>
      <Card>
        <CardContent>
          <Wrapper>
            <ValueWrapper>
              <Typography>Start measuring each {entityValue}</Typography>
              <Box flexShrink={0}>
                <TimeWindowDropdown
                  tooltip={`When to start measuring each ${entityValue}. Start measuring after exposure to reduce the impact of novelty effects.`}
                  name="exposure-offset"
                  value={
                    value.exposureOffset ??
                    value.measurementConfig?.closedWindow?.exposureOffset ??
                    '0s'
                  }
                  parseWindow={parseExposureOffset}
                  onChange={exposureOffset =>
                    onChange({
                      ...value,
                      measurementConfig: {
                        closedWindow: {
                          ...value.measurementConfig?.closedWindow,
                          aggregationWindow:
                            value.measurementConfig?.closedWindow
                              ?.aggregationWindow ?? '86400s',
                          exposureOffset: exposureOffset ?? '0s',
                        },
                      },
                    })
                  }
                  allowZero
                  disabled={disabled}
                />
              </Box>
            </ValueWrapper>
            <ValueWrapper>
              <Typography>for</Typography>
              <Box flexShrink={0}>
                <TimeWindowDropdown
                  tooltip={`How long to measure each ${entityValue}.`}
                  name="aggregation-window"
                  value={
                    value.aggregationWindow ??
                    value.measurementConfig?.closedWindow?.aggregationWindow ??
                    '0s'
                  }
                  onChange={aggregationWindow =>
                    onChange({
                      ...value,
                      measurementConfig: {
                        closedWindow: {
                          ...value.measurementConfig?.closedWindow,
                          aggregationWindow: aggregationWindow ?? '86400s',
                          exposureOffset:
                            value.measurementConfig?.closedWindow
                              ?.exposureOffset ?? '0s',
                        },
                      },
                    })
                  }
                  disabled={disabled}
                />
              </Box>
            </ValueWrapper>
          </Wrapper>
        </CardContent>
      </Card>
      {entityName && (
        <Box marginTop={1}>
          <FormHelperText>
            {entityName} refers to the entity you selected in a previous step.
          </FormHelperText>
        </Box>
      )}
    </>
  );
};
