import React, { CSSProperties } from 'react';

import { useTheme } from '@material-ui/core';
import { Skeleton } from '@material-ui/lab';

import _ from 'lodash';

import { Header as BackstageHeader } from '@backstage/core-components';

import { HeaderTabsContainer } from './HeaderTabsContainer';
import { NarrowBox, NarrowBoxProps } from './NarrowBox';

export type HeaderProps = React.PropsWithChildren<{
  title?: React.ReactNode;
  isLoading?: boolean;
  pageTitleOverride?: string;
  narrow?: boolean | NarrowBoxProps['width'];
  stickyHeader?: boolean;
  stickyHeaderTabs?: boolean;
  headerTabs?: React.ReactNode;
  headerTabsRef?: React.RefObject<HTMLDivElement | null>;
  headerBreadcrumbs?: React.ReactNode;
  enableMargin?: boolean;
}>;

const getStyles = ({
  stickyHeader,
  paddingTop,
}: Pick<HeaderProps, 'stickyHeader'> & { paddingTop?: number | string }) =>
  React.useMemo<Record<string, CSSProperties>>(
    () => ({
      root: stickyHeader
        ? {
            position: 'sticky',
            top: 0,
            paddingTop,
          }
        : { paddingTop },
    }),
    [],
  );

export const Header = ({
  title,
  isLoading,
  pageTitleOverride,
  children,
  narrow,
  headerBreadcrumbs = null,
  headerTabs = null,
  stickyHeader = false,
  stickyHeaderTabs = true,
  headerTabsRef,
  enableMargin = false,
}: HeaderProps) => {
  const pageTitleOverrideFallback = _.isString(title) ? title : 'Confidence';
  const theme = useTheme();
  const styles = getStyles({
    stickyHeader,
    paddingTop: enableMargin ? theme.spacing(9) : undefined,
  });

  const titleElement = isLoading ? (
    <Skeleton height={40} variant="text" width={300} />
  ) : (
    <>
      {headerBreadcrumbs}
      {title}
    </>
  );

  return (
    <>
      <BackstageHeader
        style={styles.root}
        pageTitleOverride={pageTitleOverride || pageTitleOverrideFallback}
        title={
          narrow ? (
            <NarrowBox
              width={typeof narrow === 'string' ? narrow : undefined}
              data-testid="header-title"
            >
              {titleElement}
            </NarrowBox>
          ) : (
            titleElement
          )
        }
        children={children}
      />
      {headerTabs && (
        <HeaderTabsContainer
          sticky={stickyHeaderTabs}
          narrow={narrow}
          ref={headerTabsRef}
        >
          {headerTabs}
        </HeaderTabsContainer>
      )}
    </>
  );
};
