import React from 'react';

import { useDialog } from '@spotify-confidence/core-react';
import { IamV1GroupInput } from '@spotify-confidence/plugin-graphql';

import { EditGroupDialog } from '../EditGroupDialog/EditGroupDialog';

export function CreateGroupDialog({
  onCreate,
}: {
  onCreate: ({ displayName, description }: IamV1GroupInput) => Promise<void>;
}) {
  const { closeDialog } = useDialog();

  const [input, setInput] = React.useState<{
    displayName: string;
    description: string;
  }>({
    displayName: '',
    description: '',
  });

  const handleCreateGroup = async () => onCreate(input);

  return (
    <EditGroupDialog
      title="Create group"
      value={input}
      onChange={setInput}
      onSubmit={handleCreateGroup}
      onClose={closeDialog}
    />
  );
}
