import React, { useState } from 'react';

import { IconButton } from '@material-ui/core';
import VisibilityIcon from '@material-ui/icons/Visibility';
import VisibilityOff from '@material-ui/icons/VisibilityOff';

type SecreateTextProps = {
  children: string;
  renderTrailing?: (visible: boolean) => React.ReactNode;
};

export const SecretText = ({ children, renderTrailing }: SecreateTextProps) => {
  const [visible, setVisible] = useState(false);
  const onClick = (e: React.MouseEvent) => {
    setVisible(!visible);
    e.preventDefault();
  };

  const iconProps = {
    ['data-testid']: 'secret-text-icon',
    style: { verticalAlign: 'baseline' },
  };
  const icon = visible ? (
    <VisibilityOff {...iconProps} />
  ) : (
    <VisibilityIcon {...iconProps} />
  );

  return (
    <>
      {visible ? children : children.replaceAll(/./g, '*')}
      <IconButton size="small" onClick={onClick}>
        {icon}
      </IconButton>
      {renderTrailing?.(visible)}
    </>
  );
};
