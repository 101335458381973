import React from 'react';

import { Chip, Tooltip, makeStyles } from '@material-ui/core';
import { Autocomplete, AutocompleteProps } from '@material-ui/lab';

import classNames from 'classnames';
import _ from 'lodash';

import { AttributeType } from '../../../domain/targeting';
import { ChipInput } from '../ChipInput';

type AutocompletePropsT<T> = AutocompleteProps<T, boolean, boolean, boolean> & {
  valueType: AttributeType;
  type: React.InputHTMLAttributes<HTMLInputElement>['type'];
};
type ChipAutocompleteProps = <T extends string>(
  props: Omit<AutocompletePropsT<T>, 'options' | 'renderInput'> &
    Partial<Pick<AutocompletePropsT<T>, 'options' | 'renderInput'>> & {
      onValueChange?: (val: string[]) => void;
      readOnly?: boolean;
    },
) => JSX.Element;

const useStyles = makeStyles({
  readOnly: {},
  endAdornment: {
    '$readOnly &': {
      display: 'none',
    },
  },
  input: {
    '$readOnly &': {
      display: 'none',
    },
  },
});

export const ChipAutocomplete: ChipAutocompleteProps = ({
  disabled,
  options,
  valueType,
  renderInput,
  value = [],
  onChange: _onChange,
  onValueChange,
  type,
  readOnly,
  ...otherProps
}) => {
  const classes = useStyles();

  const getOptionLabel = (option: any | string) =>
    otherProps?.getOptionLabel?.(option) || option;

  // Try to make sure the whole value is shown for string values
  let minWidth: string;
  if (_.isString(value)) {
    minWidth = `${getOptionLabel(value).length - 2}ch`;
  }

  // Show tooltip for values that exceed the limit (otherwise impossible to see them in readOnly mode)
  const limitTags = 4;
  const getLimitTagsText = (tags: number) =>
    _.isArray(value) ? (
      <Tooltip
        title={value.slice(limitTags).map(getOptionLabel).join(', ')}
        placement="top"
        arrow
      >
        <span>+{tags} more</span>
      </Tooltip>
    ) : (
      `${tags} more`
    );

  return (
    <Autocomplete
      autoSelect={!options}
      options={options ?? []}
      className={classNames({ [classes.readOnly]: readOnly })}
      classes={{
        endAdornment: classes.endAdornment,
        input: classes.input,
      }}
      getLimitTagsText={getLimitTagsText}
      limitTags={limitTags}
      freeSolo={!options}
      disabled={disabled || readOnly}
      renderTags={(tagValues, getTagProps) =>
        tagValues.map((option, index) => (
          <Chip
            size="small"
            label={getOptionLabel(option)}
            {...getTagProps({ index })}
            disabled={false}
            {...((disabled || readOnly) && { onDelete: undefined })}
          />
        ))
      }
      disableClearable
      renderInput={
        renderInput ??
        (params => (
          <ChipInput
            {...params}
            inputProps={{
              ...params.inputProps,
              type,
              readOnly,
              style: { minWidth },
            }}
          />
        ))
      }
      value={value}
      onChange={(...opts) => {
        _onChange?.(...opts);
        const _value = opts[1];
        if (_value) {
          onValueChange?.(_value as string[]);
        }
      }}
      {...otherProps}
    />
  );
};
