import { BillingDetailsTaxType } from './hooks/useCreateAccount';

export const OPEN_MARKETS: {
  taxType: BillingDetailsTaxType;
  code: string;
  name: string;
  states?: string[];
}[] = [
  {
    taxType: BillingDetailsTaxType.UsEin,
    code: 'US',
    name: 'United States',
    states: [
      'ALASKA',
      'ALABAMA',
      'ARKANSAS',
      'ARIZONA',
      'CALIFORNIA',
      'COLORADO',
      'CONNECTICUT',
      'DISTRICT OF COLUMBIA',
      'DELAWARE',
      'FLORIDA',
      'GEORGIA',
      'HAWAII',
      'IOWA',
      'IDAHO',
      'ILLINOIS',
      'INDIANA',
      'KANSAS',
      'KENTUCKY',
      'LOUISIANA',
      'MASSACHUSETTS',
      'MARYLAND',
      'MAINE',
      'MICHIGAN',
      'MINNESOTA',
      'MISSOURI',
      'MISSISSIPPI',
      'MONTANA',
      'NEBRASKA',
      'NEW HAMPSHIRE',
      'NEW JERSEY',
      'NEW MEXICO',
      'NEVADA',
      'NEW YORK',
      'NORTH CAROLINA',
      'NORTH DAKOTA',
      'OHIO',
      'OKLAHOMA',
      'OREGON',
      'PENNSYLVANIA',
      'PUERTO RICO',
      'RHODE ISLAND',
      'SOUTH CAROLINA',
      'SOUTH DAKOTA',
      'TENNESSEE',
      'TEXAS',
      'UTAH',
      'VIRGINIA',
      'VERMONT',
      'WASHINGTON',
      'WISCONSIN',
      'WEST VIRGINIA',
      'WYOMING',
    ],
  },
  {
    taxType: BillingDetailsTaxType.GbVat,
    code: 'GB',
    name: 'United Kingdom',
  },
  {
    taxType: BillingDetailsTaxType.EuVat,
    code: 'IE',
    name: 'Ireland',
  },
  {
    taxType: BillingDetailsTaxType.EuVat,
    code: 'DE',
    name: 'Germany',
  },
  {
    taxType: BillingDetailsTaxType.EuVat,
    code: 'FR',
    name: 'France',
  },
  {
    taxType: BillingDetailsTaxType.EuVat,
    code: 'NL',
    name: 'Netherlands',
  },
  {
    taxType: BillingDetailsTaxType.EuVat,
    code: 'FI',
    name: 'Finland',
  },
  {
    taxType: BillingDetailsTaxType.EuVat,
    code: 'ES',
    name: 'Spain',
  },
  {
    taxType: BillingDetailsTaxType.EuVat,
    code: 'SE',
    name: 'Sweden',
  },
  {
    taxType: BillingDetailsTaxType.EuVat,
    code: 'AT',
    name: 'Austria',
  },
  {
    taxType: BillingDetailsTaxType.EuVat,
    code: 'BE',
    name: 'Belgium',
  },
  {
    taxType: BillingDetailsTaxType.EuVat,
    code: 'BG',
    name: 'Bulgaria',
  },
  {
    taxType: BillingDetailsTaxType.EuVat,
    code: 'HR',
    name: 'Croatia',
  },
  {
    taxType: BillingDetailsTaxType.EuVat,
    code: 'CY',
    name: 'Cyprus',
  },
  {
    taxType: BillingDetailsTaxType.EuVat,
    code: 'CZ',
    name: 'Czech Republic',
  },
  {
    taxType: BillingDetailsTaxType.EuVat,
    code: 'DK',
    name: 'Denmark',
  },
  {
    taxType: BillingDetailsTaxType.EuVat,
    code: 'EE',
    name: 'Estonia',
  },
  {
    taxType: BillingDetailsTaxType.EuVat,
    code: 'GR',
    name: 'Greece',
  },
  {
    taxType: BillingDetailsTaxType.EuVat,
    code: 'HU',
    name: 'Hungary',
  },
  {
    taxType: BillingDetailsTaxType.EuVat,
    code: 'IT',
    name: 'Italy',
  },
  {
    taxType: BillingDetailsTaxType.EuVat,
    code: 'LV',
    name: 'Latvia',
  },
  {
    taxType: BillingDetailsTaxType.EuVat,
    code: 'LT',
    name: 'Lithuania',
  },
  {
    taxType: BillingDetailsTaxType.EuVat,
    code: 'LU',
    name: 'Luxembourg',
  },
  {
    taxType: BillingDetailsTaxType.EuVat,
    code: 'MT',
    name: 'Malta',
  },
  {
    taxType: BillingDetailsTaxType.EuVat,
    code: 'PL',
    name: 'Poland',
  },
  {
    taxType: BillingDetailsTaxType.EuVat,
    code: 'RO',
    name: 'Romania',
  },
  {
    taxType: BillingDetailsTaxType.EuVat,
    code: 'SK',
    name: 'Slovakia',
  },
  {
    taxType: BillingDetailsTaxType.EuVat,
    code: 'SI',
    name: 'Slovenia',
  },
  {
    taxType: BillingDetailsTaxType.NoVat,
    code: 'NO',
    name: 'Norway',
  },
  {
    taxType: BillingDetailsTaxType.AdNrt,
    code: 'AD',
    name: 'Andorra',
  },
  {
    taxType: BillingDetailsTaxType.IsVat,
    code: 'IS',
    name: 'Iceland',
  },
  {
    taxType: BillingDetailsTaxType.RsPib,
    code: 'RS',
    name: 'Serbia',
  },
  {
    taxType: BillingDetailsTaxType.ChVat,
    code: 'CH',
    name: 'Switzerland',
  },
  {
    taxType: BillingDetailsTaxType.TrTin,
    code: 'TR',
    name: 'Turkey',
  },
  {
    taxType: BillingDetailsTaxType.UaVat,
    code: 'UA',
    name: 'Ukraine',
  },
  {
    taxType: BillingDetailsTaxType.CaBn,
    code: 'CA',
    name: 'Canada',
    states: ['ON', 'QC', 'NS', 'NB', 'MB', 'BC', 'PE', 'SK', 'AB', 'NL'],
  },
  {
    taxType: BillingDetailsTaxType.MxRfc,
    code: 'MX',
    name: 'Mexico',
  },
  {
    taxType: BillingDetailsTaxType.IlVat,
    code: 'IL',
    name: 'Israel',
  },
  {
    taxType: BillingDetailsTaxType.ArCuit,
    code: 'AR',
    name: 'Argentina',
  },
  {
    taxType: BillingDetailsTaxType.BoTin,
    code: 'BO',
    name: 'Bolivia',
  },
  {
    taxType: BillingDetailsTaxType.ClTin,
    code: 'CL',
    name: 'Chile',
  },
  {
    taxType: BillingDetailsTaxType.CoNit,
    code: 'CO',
    name: 'Colombia',
  },
  {
    taxType: BillingDetailsTaxType.UyRuc,
    code: 'UY',
    name: 'Uruguay',
  },
  {
    taxType: BillingDetailsTaxType.VeRif,
    code: 'VE',
    name: 'Venezuela',
  },
  {
    taxType: BillingDetailsTaxType.IdNpwp,
    code: 'ID',
    name: 'Indonesia',
  },
  {
    taxType: BillingDetailsTaxType.MyFrp,
    code: 'MY',
    name: 'Malaysia',
  },
  {
    taxType: BillingDetailsTaxType.PhTin,
    code: 'PH',
    name: 'Philippines',
  },
  {
    taxType: BillingDetailsTaxType.SgGst,
    code: 'SG',
    name: 'Singapore',
  },
  {
    taxType: BillingDetailsTaxType.ThVat,
    code: 'TH',
    name: 'Thailand',
  },
  {
    taxType: BillingDetailsTaxType.VnTin,
    code: 'VN',
    name: 'Vietnam',
  },
  {
    taxType: BillingDetailsTaxType.KrBrn,
    code: 'KR',
    name: 'Korea',
  },
  {
    taxType: BillingDetailsTaxType.TwVat,
    code: 'TW',
    name: 'Taiwan',
  },
  {
    taxType: BillingDetailsTaxType.JpTrn,
    code: 'JP',
    name: 'Japan',
  },
];
