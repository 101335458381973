import React from 'react';

import { Divider, TextField, Typography } from '@material-ui/core';

import { FormFieldsBox, TimeWindowInput } from '@spotify-confidence/core-react';
import { ConnectorsV1BatchFileConfigInput } from '@spotify-confidence/plugin-graphql';

import { bytesToMb, megabytesToBytes } from './helpers';

type BatchFileConfigFormProps = {
  value: ConnectorsV1BatchFileConfigInput;
  onChange: (conf: ConnectorsV1BatchFileConfigInput) => void;
  disabled?: boolean;
};

export const BatchFileConfigForm = ({
  value,
  onChange,
  disabled,
}: BatchFileConfigFormProps) => {
  return (
    <FormFieldsBox>
      <FormFieldsBox spacing={1}>
        <div>
          <Typography variant="overline" color="textSecondary" gutterBottom>
            Batch settings
          </Typography>
          <Divider />
        </div>
        <TextField
          disabled={disabled}
          label="Max events per file"
          variant="outlined"
          type="number"
          value={value.maxEventsPerFile}
          required
          fullWidth
          inputProps={{
            min: 0,
          }}
          onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
            const newValue =
              e.target.value === '' ? '' : Number(e.target.value);
            onChange({
              ...value,
              maxEventsPerFile: newValue,
            });
          }}
        />
      </FormFieldsBox>
      <div>
        <TimeWindowInput
          id="max-file-age"
          label="Max file age"
          placeholder=""
          required
          value={value.maxFileAge}
          disabled={disabled}
          onChange={newValue =>
            onChange({
              ...value,
              maxFileAge: newValue ?? '',
            })
          }
        />
      </div>

      <TextField
        disabled={disabled}
        label="Max file size (in megabytes)"
        variant="outlined"
        type="number"
        value={
          value.maxFileSize === '' ? '' : bytesToMb(Number(value.maxFileSize))
        }
        required
        fullWidth
        inputProps={{
          min: 0,
        }}
        onChange={e => {
          const newValue =
            e.target.value === ''
              ? ''
              : megabytesToBytes(Number(e.target.value));
          onChange({
            ...value,
            maxFileSize: newValue,
          });
        }}
      />
    </FormFieldsBox>
  );
};
