import React from 'react';

import loadable from '@loadable/component';
import { createWorkflowExtension } from '@spotify-confidence/plugin-workflows';

import { createPlugin } from '@backstage/core-plugin-api';

import { RolloutTableRow } from './components/RolloutWorkflowPage/RolloutTableRow';
import { ROLLOUT_WORKFLOW_ID } from './constants';

export const confidenceWorkflowsModuleRolloutPlugin = createPlugin({
  id: 'confidence-workflows-module-rollout',
});

const componentsChunk = () =>
  import(
    /* webpackChunkName: "workflows-rollout-pages", webpackPrefetch: 5 */ './components'
  );
const loadOptions = {};

const RolloutWorkflowPage = loadable(
  () => componentsChunk().then(m => m.RolloutWorkflowPage),
  loadOptions,
);
const RolloutWorkflowInstancePage = loadable(
  () => componentsChunk().then(m => m.RolloutWorkflowInstancePage),
  loadOptions,
);

export const RolloutWorkflowExtension =
  confidenceWorkflowsModuleRolloutPlugin.provide(
    createWorkflowExtension({
      title: 'Rollout',
      workflowId: ROLLOUT_WORKFLOW_ID,
      workflowPage: () => <RolloutWorkflowPage />,
      workflowInstancePage: () => <RolloutWorkflowInstancePage />,
      workflowTableRowComponent: RolloutTableRow,
    }),
  );
