import React from 'react';

import {
  Tooltip,
  Typography,
  WithStyles,
  createStyles,
  withStyles,
} from '@material-ui/core';

import {
  extractLastNameComponent,
  useDisplayNames,
} from '@spotify-confidence/core-react';
import {
  CommentZone,
  useCommentState,
} from '@spotify-confidence/plugin-comments-react';
import classNames from 'classnames';
import _ from 'lodash';
import pluralize from 'pluralize';

import {
  Metric,
  isSuccessMetric,
  useMetricsModuleConfigProviderContext,
} from '../../../../MetricsModule';
import { useMetricResultContext } from './context';

const styles = createStyles({
  root: {},
  title: {},
  subtitle: {},
});

export interface MetricResultProps extends WithStyles<typeof styles> {
  className?: string;
  component?: React.ElementType<{ className?: string }>;
}

const getRoleName = (metric: Metric) => {
  const suffix = metric.surface !== undefined ? ' (required)' : '';
  return `${isSuccessMetric(metric) ? 'Success' : 'Guardrail'}${suffix}`;
};

const Title = ({
  classes,
  className,
  component: Component = 'div',
}: MetricResultProps) => {
  const { value: result } = useMetricResultContext();
  const { metricsData, entity } = useMetricsModuleConfigProviderContext();
  const { displayNames } = useDisplayNames();

  const role = _(metricsData?.metrics)
    .filter(metric => metric.metric === result.metric)
    .map(metric => getRoleName(metric))
    .first();

  const entities = `${pluralize(
    displayNames.get(entity!)?.toLocaleLowerCase() ?? '',
  )}`;

  const samples = result?.currentSampleSize ?? 0;
  const title =
    displayNames.get(result?.metric ?? '') ??
    extractLastNameComponent(result?.metric);
  const disabled = useCommentState(state => state.commentMode);
  const sampleSizeString =
    result?.metricDetails?.hasRun !== false
      ? `\u2022 ${samples} ${entities}`
      : '';

  return (
    <Component
      className={classNames(classes.root, className)}
      data-testid="metric-result-title"
    >
      <Tooltip title={title ?? ''} disableHoverListener={disabled}>
        <Typography className={classes.title} noWrap>
          <CommentZone id="metric-name" component="span">
            {title}
          </CommentZone>
        </Typography>
      </Tooltip>
      <Typography
        variant="body2"
        color="textSecondary"
        className={classes.subtitle}
        noWrap
      >
        <CommentZone id="metric-details" component="span">
          {role} {sampleSizeString}
        </CommentZone>
      </Typography>
    </Component>
  );
};

export default withStyles(styles)(Title);
