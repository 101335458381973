import * as React from 'react';

import { Box, Tooltip, Typography } from '@material-ui/core';
import { TooltipProps } from '@material-ui/core/Tooltip/Tooltip';

export const VerboseTooltip = ({
  verbose,
  title,
  children,
  ...otherProps
}: { verbose?: boolean } & TooltipProps) => {
  if (verbose) {
    return (
      <Box display="flex" alignItems="center" gridGap={8}>
        {children} <Typography variant="body2">{title}</Typography>
      </Box>
    );
  }

  return <Tooltip title={title} children={children} {...otherProps} />;
};
