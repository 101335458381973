import React from 'react';
import { InView } from 'react-intersection-observer';

import {
  Box,
  Button,
  Card,
  CardContent,
  Chip,
  Grid,
  LinearProgress,
  List,
  ListItem,
  ListItemSecondaryAction,
  ListItemText,
  Typography,
} from '@material-ui/core';
import { Alert } from '@material-ui/lab';

import {
  DateUtils,
  extractLastNameComponent,
} from '@spotify-confidence/core-react';
import {
  GetWorkflowVersionsDocument,
  getTypeOrNull,
  useGetWorkflowVersionsQuery,
  useUpdateWorkflowVersionMutation,
} from '@spotify-confidence/plugin-graphql';
import { PermissionUtils } from '@spotify-confidence/plugin-permissions-react';

export const WorkflowVersions = ({
  workflowName,
}: {
  workflowName: string;
}) => {
  const { data, loading, error, fetchMore } = useGetWorkflowVersionsQuery({
    variables: {
      parent: workflowName,
    },
  });
  const [setCurrentVersion, { loading: isUpdatingCurrent }] =
    useUpdateWorkflowVersionMutation({
      refetchQueries: [GetWorkflowVersionsDocument],
    });
  const workflow = getTypeOrNull(data?.workflow, 'WorkflowV1Workflow');
  const currentVersion = getTypeOrNull(
    workflow?.currentVersion,
    'WorkflowV1WorkflowVersion',
  )?.name;
  const workflowVersions = getTypeOrNull(
    data?.workflowVersions,
    'WorkflowV1ListWorkflowVersionsResponse',
  );
  const versions = workflowVersions?.workflowVersions || [];
  const details = data?.workflow
    ? [
        {
          label: 'Current version',
          // eslint-disable-next-line no-nested-ternary
          value: currentVersion
            ? extractLastNameComponent(currentVersion)
            : 'No version',
        },
      ]
    : [];

  if (workflow && !workflow?.builtin) {
    details.push(
      {
        label: 'Created',
        value: DateUtils.xAgo(new Date(workflow.createTime)),
      },
      {
        label: 'Created by ',
        value:
          getTypeOrNull(workflow.creator, 'IamV1Identity')?.displayName ||
          'Unknown',
      },
    );
  }

  return (
    <>
      {error && <Alert severity="error">{error.message}</Alert>}
      {!loading && versions.length === 0 && (
        <Typography variant="body1">
          No versions for this workflow has been created
        </Typography>
      )}
      {loading && <LinearProgress />}

      <Box mb={2}>
        <Card>
          <CardContent>
            <Grid container spacing={2}>
              {details.map(({ label, value }) => (
                <Grid item md={4} xs={12} key={label} data-testid={label}>
                  <Typography color="secondary" variant="overline">
                    {label}
                  </Typography>
                  <Typography display="block" variant="body2" gutterBottom>
                    {value}
                  </Typography>
                </Grid>
              ))}
            </Grid>
          </CardContent>
        </Card>
      </Box>

      <Typography variant="h6" gutterBottom>
        Versions
      </Typography>

      <List>
        {versions.map(version => {
          const isActive = currentVersion === version.name;
          const canEdit = PermissionUtils.hasRelation(version, 'can_edit');
          return (
            <ListItem data-testid={version.name} key={version.name}>
              <ListItemText
                primary={
                  <Box>
                    {extractLastNameComponent(version.name)}{' '}
                    {isActive && <Chip size="small" label="Current" />}
                  </Box>
                }
                secondary={`Created ${DateUtils.xAgo(
                  new Date(version.createTime),
                )}`}
              />
              {!isActive && (
                <ListItemSecondaryAction>
                  <Button
                    variant="outlined"
                    disabled={isUpdatingCurrent || !canEdit}
                    onClick={() => {
                      if (!isUpdatingCurrent) {
                        setCurrentVersion({
                          variables: {
                            version: version.name,
                            name: workflowName,
                          },
                        });
                      }
                    }}
                    size="small"
                  >
                    Make current
                  </Button>
                </ListItemSecondaryAction>
              )}
            </ListItem>
          );
        })}
      </List>
      <InView
        onChange={async inView => {
          const nextPageToken = workflowVersions?.nextPageToken;
          if (inView && nextPageToken) {
            await fetchMore({
              variables: {
                pageToken: nextPageToken,
              },
            });
          }
        }}
      />
    </>
  );
};
