import React from 'react';

import {
  Box,
  MenuItem,
  SvgIcon,
  TextField,
  TextFieldProps,
  Tooltip,
  styled,
} from '@material-ui/core';
import Add from '@material-ui/icons/Add';
import Check from '@material-ui/icons/Check';

import {
  timeWindowUtils,
  useLocalStorage,
} from '@spotify-confidence/core-react';

import { CustomOptionDialog } from './CustomOptionDialog';
import { defaultOptionValues, storageKey } from './constants';

type TimeWindowDropdownProps = Omit<
  TextFieldProps,
  'value' | 'onChange' | 'displayEmpty' | 'select' | 'children'
> & {
  value: string;
  onChange: (newValue?: string) => void;
  tooltip?: string;
  parseWindow?: typeof timeWindowUtils.parseWindow;
  allowZero?: boolean;
  allowEmpty?: boolean;
};

const AddMenuItem = styled(MenuItem)(({ theme }) => ({
  borderTop: `1px solid ${theme.palette.divider}`,
  padding: theme.spacing(1, 2),
}));

export const TimeWindowDropdown = ({
  value,
  onChange,
  tooltip = '',
  parseWindow = timeWindowUtils.parseWindow,
  allowZero,
  allowEmpty,
  ...textFieldProps
}: TimeWindowDropdownProps) => {
  const [customOptionsOpen, setCustomOptionsOpen] = React.useState(false);
  const [storedOptions] = useLocalStorage(storageKey, defaultOptionValues);

  const options = React.useMemo(() => {
    const currentValue = value;
    const newOptions: string[] = [];
    if (allowEmpty) {
      newOptions.push('');
    }
    if (allowZero) {
      newOptions.push('0s');
    }
    newOptions.push(...storedOptions);
    if (!newOptions.includes(currentValue)) {
      newOptions.push(currentValue);
    }
    return newOptions.map(option => ({
      value: option,
      label: parseWindow(option),
    }));
  }, [storedOptions, value, allowZero]);

  const openCustomOptions = () => {
    onChange(value);
    setCustomOptionsOpen(true);
  };

  const closeCustomOptions = () => {
    setCustomOptionsOpen(false);
  };

  return (
    <>
      <CustomOptionDialog
        open={customOptionsOpen}
        onClose={closeCustomOptions}
      />
      <Tooltip arrow placement="top" title={tooltip}>
        <TextField
          {...textFieldProps}
          value={value}
          select
          SelectProps={{
            renderValue: selected => parseWindow((selected as string) || ''),
            displayEmpty: allowEmpty,
          }}
        >
          {options.map(option => (
            <MenuItem
              key={option.value}
              value={option.value}
              onClick={() => onChange(option.value)}
            >
              <Box display="inline-flex" gridGap="0.5em">
                {option.value === value ? (
                  <Check fontSize="small" />
                ) : (
                  <SvgIcon fontSize="small" />
                )}
                {option.label}
              </Box>
            </MenuItem>
          ))}
          <AddMenuItem onClick={openCustomOptions}>
            <Box display="inline-flex" gridGap="0.5em">
              <Add fontSize="small" />
              Add option
            </Box>
          </AddMenuItem>
        </TextField>
      </Tooltip>
    </>
  );
};
