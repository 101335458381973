import React from 'react';

import { Typography } from '@material-ui/core';

import { Activity, NamedEntity } from '../types';
import { getActivityState } from './helpers';

export const TimelineActivityStatus = <T extends NamedEntity>({
  state: {
    startedLate,
    plannedStartOverdue,
    endedLate,
    plannedEndOverdue,
    endedEarly,
  },
  activity: { end, plannedEnd, start, plannedStart },
}: {
  state: ReturnType<typeof getActivityState>;
  activity: Activity<T>;
}) => {
  const status: string[] = [];
  if (startedLate || plannedStartOverdue) {
    status.push('delayed start');
  }
  if (endedLate) {
    status.push('ended late');
  } else if (plannedEndOverdue) {
    status.push('overdue');
  }
  if (endedEarly) {
    status.push('ended early');
  }
  if (!end && !plannedEnd) {
    status.push('unspecified due date');
  }
  if (!start && !plannedStart) {
    status.push('unspecified start date');
  }
  const hasStatus = status.length > 0;

  return hasStatus ? (
    <Typography variant="body2" color="textSecondary" component="span">
      ∘ {status.join(', ')}
    </Typography>
  ) : null;
};
