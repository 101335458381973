import React from 'react';

import { getTypeOrNull } from '@spotify-confidence/plugin-graphql';

import { useWorkflowPermissionContext } from '../WorkflowPermissionContext';

export type UseWorkflowPermissionGuardOptions = {
  // What kind of permission to evaluate
  type?: 'action' | 'function';

  // The action or function which we are checking permissions for
  name?: string;

  // Optimistic permissions. If true, we will assume that the user has
  // permissions to perform the action/function. If false, we will assume that
  // the user does not have permissions to perform the action/function.
  optimistic?: boolean;
};

export function useWorkflowPermissionGuard({
  type,
  name,
  optimistic = false,
}: UseWorkflowPermissionGuardOptions) {
  const { data, loading, error } = useWorkflowPermissionContext();
  const checkPermissions = getTypeOrNull(
    data?.checkPermissions,
    'WorkflowV1CheckPermissionsResponse',
  );
  const allowed = React.useMemo(() => {
    if (checkPermissions?.isAllAllowed) {
      return true;
    }
    if (type === 'action' && checkPermissions?.actionPermissionCheckResults) {
      return (
        checkPermissions.actionPermissionCheckResults.find(
          result => result.action === name,
        )?.isAllowed ?? false
      );
    }
    if (
      type === 'function' &&
      checkPermissions?.functionPermissionCheckResults
    ) {
      return (
        checkPermissions.functionPermissionCheckResults.find(
          result => result.function === name,
        )?.isAllowed ?? false
      );
    }
    return optimistic;
  }, [checkPermissions, type, name, optimistic]);

  return { allowed, loading, error };
}
