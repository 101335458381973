import * as React from 'react';

import {
  Box,
  ButtonBase,
  List,
  ListItem,
  ListItemText,
  Popover,
  Typography,
  makeStyles,
} from '@material-ui/core';

import {
  Maybe,
  PendingTransitionFragment,
  WorkflowInstanceCheckFragment,
  WorkflowV1WorkflowInstanceCheckState,
  isType,
} from '@spotify-confidence/plugin-graphql';
import _ from 'lodash';

import {
  StatusAborted,
  StatusError,
  StatusOK,
  StatusPending,
  StatusRunning,
  StatusWarning,
} from '@backstage/core-components';

import { UpdateTime } from '../../workflowInstancePage/UpdateTime';
import { WorkflowInstanceStateType } from './types';
import { Status, useWorkflowInstanceState } from './useWorkflowInstanceState';

const useStyles = makeStyles(theme => ({
  informative: {
    cursor: 'context-menu',
    '&:hover': {
      opacity: 0.8,
    },
  },
  popover: {
    pointerEvents: 'none',
  },
  paper: {
    padding: theme.spacing(0.5),
    maxWidth: 500,
  },
  listItemText: {
    whiteSpace: 'normal',
    overflow: 'visible',
  },
  timeline: {
    padding: 0,
    margin: 0,
  },
}));

type Severity = 'error' | 'warning';

const checkStateStatus = (
  state?: WorkflowV1WorkflowInstanceCheckState | null,
  severity?: Severity,
) => {
  switch (state) {
    case WorkflowV1WorkflowInstanceCheckState.Succeeded:
      return StatusOK;
    case WorkflowV1WorkflowInstanceCheckState.Pending:
      return StatusPending;
    case WorkflowV1WorkflowInstanceCheckState.Failed:
    default:
      return severity === 'warning' ? StatusWarning : StatusError;
  }
};

type PendingTransitionStep = {
  primary: string;
  secondary: string;
  StepStatus: typeof StatusOK;
};

type WorkflowInstanceStateProps = {
  workflowInstanceId?: string;
  updateTime?: string;
  state: WorkflowInstanceStateType;
  checks?: WorkflowInstanceCheckFragment[];
  verbose?: boolean;
  pendingTransition?: Maybe<PendingTransitionFragment>;
  onClick?: (e: React.MouseEvent<HTMLElement>) => void;
};

const statusMap: Record<Status, React.FunctionComponent> = {
  ok: StatusOK,
  warning: StatusWarning,
  error: StatusError,
  running: StatusRunning,
  aborted: StatusAborted,
  pending: StatusPending,
};

function sortAndFilterChecks(
  checks: WorkflowInstanceCheckFragment[],
): WorkflowInstanceCheckFragment[] {
  const parentIds =
    checks
      ?.filter(check => isType(check.parent, 'WorkflowV1WorkflowInstanceCheck'))
      // @ts-ignore
      .map(check => check.parent?.name ?? '-') || [];

  const filteredChecks = checks?.filter(
    check => !parentIds.includes(check.name),
  );

  const sortedChecks = filteredChecks.sort((checkA, checkB) => {
    const categoryA =
      checkA.labels?.find(label => label.key === 'category')?.value || '';
    const categoryB =
      checkB.labels?.find(label => label.key === 'category')?.value || '';

    if (!categoryA && !categoryB) {
      return 0;
    }

    if (!categoryA) {
      return 1;
    }

    if (!categoryB) {
      return -1;
    }

    return categoryA.localeCompare(categoryB);
  });

  return sortedChecks;
}

export const WorkflowInstanceState = ({
  workflowInstanceId,
  updateTime,
  state = 'draft',
  checks = [],
  verbose = false,
  pendingTransition,
  onClick,
}: WorkflowInstanceStateProps) => {
  const classes = useStyles();
  const { status, label } = useWorkflowInstanceState({
    state,
    checks,
    pendingTransition,
  });
  const lowerCaseState = _.toLower(state);
  const severity: Severity = ['live'].includes(lowerCaseState)
    ? 'error'
    : 'warning';

  const [anchorEl, setAnchorEl] = React.useState<HTMLDivElement | null>(null);
  const open = Boolean(anchorEl);

  const handlePopoverOpen = (event: React.MouseEvent<HTMLDivElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handlePopoverClose = () => {
    setAnchorEl(null);
  };

  const StatusNode = statusMap[status];

  const stateContent = (
    <Box display="flex" alignItems="center" gridGap={4}>
      <StatusNode />
      <Box>
        <Typography variant="body2" component="span">
          {label}
        </Typography>
        {!!workflowInstanceId && verbose && (
          <Box>
            <UpdateTime updateTime={updateTime} />
          </Box>
        )}
      </Box>
    </Box>
  );

  const stateComponent =
    onClick !== undefined ? (
      <ButtonBase onClick={onClick}>{stateContent}</ButtonBase>
    ) : (
      stateContent
    );

  const pendingTransitionSteps = React.useMemo<PendingTransitionStep[]>(() => {
    if (!pendingTransition) {
      return [];
    }
    const mergedSteps: PendingTransitionStep[] = [];
    pendingTransition.succeededSteps.forEach(step => {
      mergedSteps.push({
        StepStatus: StatusOK,
        primary: `Step "${step}" succeeded`,
        secondary: 'Done',
      });
    });
    pendingTransition.failedSteps.forEach(step => {
      mergedSteps.push({
        StepStatus: StatusError,
        primary: `Step "${step.actionStep}" failed`,
        secondary: step.failuresMessages.join(', ') || 'Failed',
      });
    });
    pendingTransition.unprocessedSteps.forEach(step => {
      mergedSteps.push({
        StepStatus: StatusPending,
        primary: `Step "${step}" pending`,
        secondary: 'Waiting to process',
      });
    });
    return mergedSteps;
  }, [pendingTransition]);

  if (checks.length === 0 && !pendingTransition) {
    return stateComponent;
  }

  return (
    <>
      <Box
        display="inline-block"
        aria-owns={open ? 'workflow-state-popover' : undefined}
        aria-haspopup="true"
        className={classes.informative}
        onMouseEnter={handlePopoverOpen}
        onMouseLeave={handlePopoverClose}
        onClick={onClick}
      >
        {stateComponent}
      </Box>

      <Popover
        id="workflow-state-popover"
        className={classes.popover}
        classes={{
          paper: classes.paper,
        }}
        PaperProps={{
          variant: 'elevation',
        }}
        open={open}
        anchorEl={anchorEl}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
        onClose={handlePopoverClose}
        disableRestoreFocus
      >
        {pendingTransitionSteps.length > 0 ? (
          <List dense>
            {pendingTransitionSteps.map(
              ({ StepStatus, primary, secondary }, index) => (
                <ListItem
                  key={primary}
                  divider={index !== pendingTransitionSteps.length - 1}
                >
                  <ListItemText
                    className={classes.listItemText}
                    primary={
                      <StepStatus>
                        <Typography variant="subtitle2" component="span">
                          {primary}
                        </Typography>
                      </StepStatus>
                    }
                    secondary={secondary}
                  />
                </ListItem>
              ),
            )}
          </List>
        ) : (
          <List dense>
            {sortAndFilterChecks(checks).map((check, i) => {
              const CheckStatus = checkStateStatus(check.state, severity);
              return (
                <ListItem
                  key={check.name}
                  dense
                  divider={i < checks.length - 1}
                >
                  <ListItemText
                    className={classes.listItemText}
                    primary={
                      <CheckStatus>
                        <Typography variant="subtitle2" component="span">
                          Check{' '}
                          {_.toLower(check.state || 'is in unknown state')}
                        </Typography>
                      </CheckStatus>
                    }
                    secondary={check.message}
                  />
                </ListItem>
              );
            })}
          </List>
        )}
      </Popover>
    </>
  );
};
