import React from 'react';

type StepperState = {
  activeStep: number;
  setActiveStep: React.Dispatch<React.SetStateAction<number>>;
  totalSteps: number;
  onComplete: () => Promise<void>;
  stepLabel: string;
};

export const StepperContext = React.createContext<StepperState>({
  activeStep: 0,
  setActiveStep: () => {},
  totalSteps: 0,
  onComplete: async () => {},
  stepLabel: 'unknown',
});

export const useStepperContext = () => {
  const context = React.useContext(StepperContext);
  if (!context) {
    throw new Error(
      'useStepperContext must be used within a StepperContextProvider',
    );
  }
  return context;
};
