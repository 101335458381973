import {
  TargetingCriterionFragment,
  getTypeOrNull,
} from '@spotify-confidence/plugin-graphql';

import { Criterion, SEGMENT_OPERATORS } from '../../targeting.model';

export const fromSchemaSegmentCriterion = (
  name: string,
  criterion: TargetingCriterionFragment,
  not?: boolean,
): Criterion => {
  const segment = getTypeOrNull(
    criterion.segment?.segment,
    'FlagsAdminV1Segment',
  );
  return {
    name,
    type: 'segment',
    op: not ? SEGMENT_OPERATORS[1] : SEGMENT_OPERATORS[0],
    segment: {
      displayName: segment?.displayName ?? undefined,
      name: segment?.name,
    },
  };
};
