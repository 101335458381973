import React from 'react';

import {
  CircularProgress,
  Collapse,
  DialogActions,
  TextField,
} from '@material-ui/core';

import {
  DialogBody,
  DialogForm,
  DialogHeader,
  FormSubmitButtons,
  useAlert,
  useDialog,
} from '@spotify-confidence/core-react';
import {
  Query,
  evictTypeFields,
  getError,
  isType,
  useCreateSegmentMutation,
} from '@spotify-confidence/plugin-graphql';
import {
  IdentityAutocomplete,
  useDefaultOwner,
} from '@spotify-confidence/plugin-permissions-react';

export const CreateSegmentDialog = ({
  onCreated,
}: {
  onCreated: (name: string) => void;
}) => {
  const { closeDialog } = useDialog();
  const alert = useAlert();
  const [displayName, setDisplayName] = React.useState('');
  const [owner, setOwner, { loading: loadingUserIdentity }] = useDefaultOwner();

  const [createSegment, { loading, error }] = useCreateSegmentMutation();

  const handleCreateSegment = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    createSegment({
      variables: {
        segment: {
          displayName,
          owner,
          allocation: {
            proportion: {
              value: '1',
            },
          },
          labels: [{ key: 'userCreated', value: 'true' }],
        },
      },
      update: cache => {
        evictTypeFields<Query>(cache, 'segments');
      },
      onCompleted: data => {
        const responseError = getError(data.createSegment);
        if (responseError) {
          alert.post({
            severity: 'error',
            message: responseError.message,
          });
        } else if (isType(data.createSegment, 'FlagsAdminV1Segment')) {
          onCreated(data.createSegment.name);
          closeDialog();
        }
      },
      refetchQueries: ['SegmentsPageList'],
    });
  };
  return (
    <DialogForm
      onSubmit={handleCreateSegment}
      data-testid="create-segment-dialog"
    >
      <DialogHeader
        title="Create segment"
        subTitle="Enter a name for the segment you want to create"
      />
      <DialogBody>
        <TextField
          name="createsegment"
          variant="outlined"
          required
          label="Segment name"
          value={displayName}
          onChange={e => setDisplayName(e.target.value)}
          fullWidth
          InputProps={{
            endAdornment: loading && <CircularProgress size="1em" />,
          }}
          helperText={
            <Collapse in={Boolean(error)}>
              {error?.message || <div>&nbsp;</div>}
            </Collapse>
          }
          error={Boolean(error)}
          autoFocus
        />
        <IdentityAutocomplete
          value={owner}
          onChange={(_e, newIdentity) => {
            setOwner(newIdentity);
          }}
          label="Owner"
          required
          loading={loadingUserIdentity}
        />
      </DialogBody>
      <DialogActions>
        <FormSubmitButtons
          onCancel={closeDialog}
          loading={loading}
          disabled={displayName.length < 2 || loading || !owner}
          label="Create"
        />
      </DialogActions>
    </DialogForm>
  );
};
