import React from 'react';
import { InView } from 'react-intersection-observer';

import {
  Box,
  IconButton,
  LinearProgress,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  createStyles,
  makeStyles,
} from '@material-ui/core';
import ErrorOutlineIcon from '@material-ui/icons/ErrorOutline';
import InfoOutlinedIcon from '@material-ui/icons/InfoOutlined';
import KeyboardArrowDownOutlinedIcon from '@material-ui/icons/KeyboardArrowDownOutlined';
import KeyboardArrowUpOutlinedIcon from '@material-ui/icons/KeyboardArrowUpOutlined';
import { Alert } from '@material-ui/lab';

import { DateUtils, ListEmptyState } from '@spotify-confidence/core-react';
import {
  WorkflowV1WorkflowLogLogLevel,
  getError,
  getTypeOrNull,
  useGetWorkflowLogsQuery,
} from '@spotify-confidence/plugin-graphql';

const LogIcon = ({ level }: { level: WorkflowV1WorkflowLogLogLevel }) => {
  if (level === WorkflowV1WorkflowLogLogLevel.LogLevelError) {
    return <ErrorOutlineIcon fontSize="small" />;
  }

  return <InfoOutlinedIcon fontSize="small" />;
};

const useStyles = makeStyles(() =>
  createStyles({
    tableRoot: {
      tableLayout: 'fixed',
    },
    messageBox: {
      fontFamily: 'monospace',
      overflow: 'auto',
    },
    ellipsis: {
      position: 'relative',
      verticalAlign: 'middle',

      '&:before': {
        content: '&nbsp;',
        visibility: 'hidden',
      },

      '& > span': {
        position: 'absolute',
        top: '-10px',
        left: 0,
        right: 0,
        whiteSpace: 'nowrap',
        overflow: 'hidden',
        textOverflow: 'ellipsis',
      },
    },
  }),
);

const LogRow = ({
  level,
  message,
  logTime,
}: {
  level: WorkflowV1WorkflowLogLogLevel;
  message: string;
  logTime: string;
}) => {
  const classes = useStyles();
  const [expanded, setExpanded] = React.useState(false);

  return (
    <>
      <TableRow>
        <TableCell width="100">
          <LogIcon level={level} />
        </TableCell>
        <TableCell width="150">{DateUtils.xAgo(new Date(logTime))}</TableCell>
        <TableCell>
          <div className={classes.ellipsis}>
            <span>{message}</span>
          </div>
        </TableCell>

        <TableCell
          width="50"
          align="right"
          onClick={() => setExpanded(!expanded)}
        >
          <IconButton size="small" name="expand-button">
            {expanded ? (
              <KeyboardArrowUpOutlinedIcon />
            ) : (
              <KeyboardArrowDownOutlinedIcon />
            )}
          </IconButton>
        </TableCell>
      </TableRow>
      {expanded && (
        <TableRow>
          <TableCell colSpan={4}>
            <span>Time: {logTime}</span>
            <br />
            <Box className={classes.messageBox}>{message}</Box>
          </TableCell>
        </TableRow>
      )}
    </>
  );
};

export const WorkflowLogs = ({ workflowName }: { workflowName: string }) => {
  const classes = useStyles();

  const { data, fetchMore, error, loading } = useGetWorkflowLogsQuery({
    variables: {
      parent: workflowName,
    },
  });
  const logsResonse = getTypeOrNull(
    data?.workflowLogs,
    'WorkflowV1ListWorkflowLogsResponse',
  );
  const logs = logsResonse?.workflowLogs || [];
  const logsError = getError(data?.workflowLogs);

  return (
    <>
      {logsError && <Alert severity="error">{logsError.message}</Alert>}
      {error && <Alert severity="error">{error.message}</Alert>}

      {loading && <LinearProgress />}

      <Table classes={{ root: classes.tableRoot }}>
        <TableHead>
          <TableRow>
            <TableCell width="100">Level</TableCell>
            <TableCell>Time</TableCell>
            <TableCell>Message</TableCell>
            <TableCell />
          </TableRow>
        </TableHead>
        <TableBody data-testid="logs-list">
          {logs.map(log => (
            <LogRow key={log.name} {...log} />
          ))}
        </TableBody>
      </Table>
      {!loading && logs.length === 0 && (
        <ListEmptyState
          title="No logs available"
          description="Once you create your first workflow instance you will see logs here."
        />
      )}

      <InView
        onChange={async inView => {
          const nextPageToken = logsResonse?.nextPageToken;
          if (inView && nextPageToken) {
            await fetchMore({
              variables: {
                pageToken: nextPageToken,
              },
            });
          }
        }}
      />
    </>
  );
};
