import * as React from 'react';

import { Box, makeStyles } from '@material-ui/core';

import { HEADER_HEIGHT, Header } from './Header';
import { Step } from './Step';
import { StepTitle } from './StepTitle';
import { Stepper } from './Stepper';
import { StepperContext } from './StepperContext';

type TutorialWizardProps = React.PropsWithChildren<{
  title: string;
  onClose?: () => void;
}>;

const useStyles = makeStyles(theme => ({
  wizard: {
    width: '100%',
    height: '100%',
    maxWidth: '100%',
    display: 'grid',
    gridTemplateRows: `${HEADER_HEIGHT}px 1fr`,
    gridTemplateColumns: '100%',
  },
  grid: {
    position: 'relative',
    display: 'grid',
    gridTemplateColumns: '100%',
    gridTemplateRows: 'max-content max-content 1fr',
    gridTemplateAreas: `
      "stepper"
      "docs"
      "step"
    `,
    justifyContent: 'center',
    width: '100%',
    height: '100%',
    maxWidth: theme.breakpoints.values.xl,
    margin: '0 auto',
    [theme.breakpoints.up('md')]: {
      gridTemplateRows: 'auto',
      gridTemplateAreas: `
        "stepper step docs"
      `,
      gridTemplateColumns: '200px calc(100% - 200px - 200px) 200px',
    },
    [theme.breakpoints.up('lg')]: {
      gridTemplateColumns: '224px calc(100% - 224px - 300px) 300px',
    },
    // Since this component is used for tutorials, we want to make sure the content is readable
    '& p': {
      maxWidth: '80ch',
    },
  },
}));

export function TutorialWizard({
  title,
  onClose,
  children,
}: TutorialWizardProps) {
  const classes = useStyles();
  return (
    <Box className={classes.wizard}>
      <Header title={title} onClose={onClose} />
      <Box className={classes.grid}>{children}</Box>
    </Box>
  );
}

/**
 * @param {number} props.activeStep the index of the currently active step
 * @param {setActiveStep} props.setActiveStep update the active step index
 * @param {number} props.totalSteps the total number of steps in the wizard
 * @param {onComplete} props.onComplete callback for when the Submit button is clicked in the final step
 * @param {string} props.stepLabel what the currently active step is called
 */
TutorialWizard.StepperContext = StepperContext;

/**
 * @param {StepProps[]} props.steps list of wizard steps to step through in order
 * @param {onComplete} props.onComplete callback for when the Submit button is clicked in the final step
 */
TutorialWizard.Stepper = Stepper;

/**
 * The Step component renders a single step of the wizard and can only be used within the
 * context of the TutorialWizard.Stepper component
 *
 * @example
 * <TutorialWizard.Step onBack={() => {}} onNext={() => {}} docs={<>This is hello world</>}>
 *   Hello world
 * </TutorialWizard.Step>
 */
TutorialWizard.Step = Step;

/**
 * The StepTitle component renders a title for a single step of the Tutorialwizard. It also accepts an estimated
 * time to complete the step.
 *
 * @example
 * <TutorialWizard.StepTitle title="First step" estimatedTime="2 minutes" />
 */
TutorialWizard.StepTitle = StepTitle;
