import React from 'react';
import { useAsyncFn } from 'react-use';

import {
  Box,
  DialogActions,
  MenuItem,
  Select,
  TextField,
  Typography,
  makeStyles,
} from '@material-ui/core';
import { Alert, AlertTitle, Autocomplete } from '@material-ui/lab';

import {
  DialogBody,
  DialogHeader,
  FormSubmitButtons,
  TagsInput,
  extractLastNameComponent,
  useDialog,
} from '@spotify-confidence/core-react';
import {
  RuleFragment,
  VariantFragment,
  getError,
  getTypeOrNull,
  isType,
} from '@spotify-confidence/plugin-graphql';
import _ from 'lodash';

import { AttributeIcon } from '../../../../../segment/components/Targeting/AttributeIcon';
import {
  CriterionAttribute,
  targetingCodec,
  targetingHooks,
  targetingState,
} from '../../../../../segment/domain/targeting';
import {
  CriterionOption,
  getSemanticTypeFromCriterionOption,
} from '../../../../../segment/domain/targeting/targeting.model';
import { ruleHelpers } from '../../../../domain';
import { countryCodes, getCountryLabel } from '../../../../domain/rule.helpers';

export type Props = {
  title?: string;
  variants: VariantFragment[];
  rule: RuleFragment;
  onSave?: (rule: RuleFragment) => Promise<void> | void | null;
  clients: string[];
};

const useStyles = makeStyles(() => ({
  fieldValue: {
    marginTop: 0,
    marginBottom: 0,
    minWidth: 200,
  },
}));

const getType = (criterionOption?: CriterionOption) => {
  return criterionOption
    ? getSemanticTypeFromCriterionOption(criterionOption) ||
        criterionOption.type
    : 'String';
};

export const OverrideRuleForm = ({
  title = 'Edit Rule',
  variants,
  rule,
  onSave,
  clients,
}: Props) => {
  const classes = useStyles();
  const { closeDialog } = useDialog();

  const segmentError = getError(rule.segment);
  const [segment, setSegment] = React.useState(
    getTypeOrNull(rule.segment, 'FlagsAdminV1Segment'),
  );
  const targeting = React.useMemo(
    () => targetingCodec.fromSchemaTargeting(segment?.targeting),
    [segment],
  );
  const plainCriteria = React.useMemo(() => {
    return 'criteria' in targeting
      ? (targeting.criteria?.[0] as CriterionAttribute)
      : undefined;
  }, [targeting]);

  const [variant, setVariant] = React.useState<VariantFragment | undefined>(
    ruleHelpers.getPreSelectedVariant(variants, rule.assignmentSpec),
  );
  const [assignmentSpec, setAssignmentSpec] = React.useState<
    RuleFragment['assignmentSpec']
  >(ruleHelpers.mapVariantToAssignmentsSpec(variant));

  const [attribute, setAttribute] = React.useState<string>(
    plainCriteria?.attribute ?? '',
  );
  const [values, setValues] = React.useState<string[]>(
    (plainCriteria?.value as string[]) ?? [],
  );

  const attributeRef = React.useRef<HTMLInputElement>(null);

  const { onAdd, onUpdate } = targetingState.useTargetingCriteria({
    targeting: targeting,
    onChange: newTageting =>
      setSegment(s => {
        if (isType(s, 'FlagsAdminV1Segment'))
          return {
            ...s,
            targeting: targetingCodec.toSchemaTargeting(newTageting),
          };
        return null;
      }),
  });

  React.useEffect(() => {
    if (!plainCriteria && attribute) {
      onAdd(targeting.name, {
        type: 'attribute',
        attribute,
        attributeType: 'String',
        op: 'In',
        value: [],
      });
    } else if (plainCriteria) {
      onUpdate(plainCriteria.name, {
        attribute,
        value: values,
      });
    }
  }, [values, attribute]);

  React.useEffect(() => {
    setAssignmentSpec(ruleHelpers.mapVariantToAssignmentsSpec(variant));
  }, [variant]);

  const evaluationContextOptions =
    targetingHooks.useDeriveEvaluationContextSchema(
      clients,
      segment?.targeting,
      attribute,
    );

  const [{ loading: saving, error }, save] = useAsyncFn(async () => {
    if (!segment) throw new Error('Missing segment');
    await onSave?.({
      ...rule,
      enabled: rule.enabled,
      assignmentSpec,
      segment,
      targetingKeySelector: attribute,
      labels: rule.labels,
    });
    closeDialog();
  }, [segment, rule, assignmentSpec, onSave]);

  const attributeContextOption = evaluationContextOptions.find(
    o => o.name === attribute,
  );
  const type = getType(attributeContextOption);

  const handleAttributeChange = (
    _e: any,
    newAttribute: string | CriterionOption | null,
  ) => {
    const newCriterion =
      typeof newAttribute === 'string'
        ? evaluationContextOptions.find(o => o.name === newAttribute)
        : newAttribute;

    // We can fall back to string since the RandomizationInput component only allows selecting string attributes
    const newType = newCriterion ? newCriterion.type : 'String';
    const newSemanticType =
      newCriterion && getSemanticTypeFromCriterionOption(newCriterion);

    // Only persist allowed values for semantic types when changing the attribute
    if (newType !== (attributeContextOption?.type || 'String')) {
      setValues([]);
    } else if (newSemanticType === 'Enum') {
      setValues(current =>
        current.filter(v =>
          newCriterion?.semanticType?.enumType?.values.some(e => e.value === v),
        ),
      );
    } else if (newSemanticType === 'Country code') {
      setValues(current => current.filter(v => countryCodes.includes(v)));
    }
    setAttribute(newCriterion?.name || '');
  };

  let options: string[] | undefined = undefined;
  let getOptionLabel;
  if (type === 'Enum') {
    options = attributeContextOption?.semanticType?.enumType?.values
      .map(v => v.value)
      .filter(_.isString);
  } else if (type === 'Country code') {
    options = countryCodes;
    getOptionLabel = getCountryLabel;
  }

  return (
    <>
      <DialogHeader
        title={title}
        subTitle="Use override rules to force a specific variant for specific users. Good for testing or debugging."
      />
      <DialogBody autoHeight>
        {error && (
          <Box display="flex">
            <Alert severity="error">
              <AlertTitle>Error while saving</AlertTitle>
              <Typography>{error.message}</Typography>
            </Alert>
          </Box>
        )}
        {segmentError && (
          <Box display="flex">
            <Alert severity="error">
              <Typography>{segmentError.message}</Typography>
            </Alert>
          </Box>
        )}
        <Box
          display="flex"
          flexDirection="column"
          alignItems="start"
          gridGap={16}
          mb={4}
        >
          <div>
            <Typography variant="h6" component="div">
              Variant
            </Typography>
            <Typography variant="body1" component="div" color="textSecondary">
              Determines the experience that users will receive.
            </Typography>
          </div>
          <Select
            variant="outlined"
            margin="dense"
            value={variant?.name}
            className={classes.fieldValue}
            fullWidth={false}
            onChange={e =>
              setVariant(variants.find(v => v.name === e.target.value))
            }
          >
            {variants.map(v => (
              <MenuItem value={v.name} key={v.name}>
                {extractLastNameComponent(v.name)}
              </MenuItem>
            ))}
          </Select>
        </Box>

        <Box display="flex" flexDirection="column" gridGap={16} mb={4}>
          <div>
            <Typography variant="h6" component="div">
              Attributes
            </Typography>
            <Typography variant="body1" component="div" color="textSecondary">
              Field in evaluation context used to determine who will receive the
              experience.
            </Typography>
          </div>
          <Autocomplete
            disablePortal={false}
            fullWidth
            freeSolo
            onChange={handleAttributeChange}
            options={evaluationContextOptions}
            getOptionLabel={option => {
              if (typeof option === 'string') {
                return option;
              }
              return option.displayName || option.name;
            }}
            renderInput={params => (
              <TextField
                {...params}
                placeholder="Attribute name"
                variant="outlined"
                margin="none"
                size="small"
                inputRef={attributeRef}
                name="attribute-name"
                inputProps={{
                  ...(params.inputProps ?? {}),
                  'data-form-type': 'other',
                  autoComplete: 'off',
                  lpignore: 'true',
                }}
              />
            )}
            renderOption={option => {
              const entity = getTypeOrNull(
                option.semanticType?.entityReference?.entity,
                'MetricsV1Entity',
              );
              const displayName = entity?.displayName || option.displayName;

              return (
                <Box display="flex" gridGap={4} alignItems="center">
                  <AttributeIcon
                    type={
                      getSemanticTypeFromCriterionOption(option) || option.type
                    }
                    color="disabled"
                  />
                  <span>
                    {displayName || option.name}{' '}
                    {displayName && (
                      <Typography component="span" color="textSecondary">
                        ({option.name})
                      </Typography>
                    )}
                  </span>
                </Box>
              );
            }}
          />
        </Box>

        <Box
          display="flex"
          flexDirection="column"
          alignItems="start"
          gridGap={8}
        >
          <div>
            <Typography variant="h6" component="div">
              Values
            </Typography>
            <Typography variant="body1" component="div" color="textSecondary">
              Which{' '}
              {attribute ? (
                <span>
                  values for{' '}
                  <strong>
                    {attributeContextOption?.displayName || attribute}
                  </strong>{' '}
                </span>
              ) : (
                'values of the attribute'
              )}{' '}
              will receive the experience.
            </Typography>
          </div>
          <TagsInput
            placeholder="Add value"
            freeSolo={!options ? true : undefined}
            clearOnBlur
            options={options}
            getOptionLabel={getOptionLabel}
            name="values-field"
            value={values}
            onChange={(_e, newValues = []) => setValues(newValues)}
            className={classes.fieldValue}
          />
        </Box>
      </DialogBody>
      <DialogActions>
        <FormSubmitButtons
          onCancel={closeDialog}
          loading={saving}
          disabled={!variant || !attribute || values.length === 0}
          onSubmit={save}
        />
      </DialogActions>
    </>
  );
};
