import { Auth0Client, User, createAuth0Client } from '@auth0/auth0-spa-js';

import { ApiRef, createApiRef } from '@backstage/core-plugin-api';

import { getCurrentOrgId } from '../behaviors';
import {
  AUDIENCE,
  CLIENT_ID,
  DOMAIN,
} from '../behaviors/auth-factory/auth0/auth';

export type ConfidenceUserApi = {
  getCredentials: () => Promise<Auth0Client>;
  login: () => Promise<void>;
  logout: () => Promise<void>;
  getUser: () => Promise<User | undefined>;
};

export const confidenceUserApiRef: ApiRef<ConfidenceUserApi> = createApiRef({
  id: 'confidence.user',
});

export class ConfidenceAuth implements ConfidenceUserApi {
  private client: Auth0Client | undefined;
  static create() {
    const client = new ConfidenceAuth();
    return client;
  }

  private async _getClient() {
    if (this.client) {
      return this.client;
    }
    const organization = getCurrentOrgId();

    const client = await createAuth0Client({
      clientId: CLIENT_ID,
      domain: DOMAIN,
      useRefreshTokens: true,
      useRefreshTokensFallback: true,
      cacheLocation: 'localstorage',
      authorizationParams: {
        audience: AUDIENCE,
        redirect_uri: window.location.origin,
        organization,
      },
    });
    this.client = client;
    return client;
  }

  async getUser() {
    const client = await this._getClient();
    return await client.getUser();
  }
  async getCredentials() {
    const client = await this._getClient();
    return client;
  }

  async login() {
    const client = await this._getClient();
    return client.loginWithPopup();
  }

  async logout() {
    const client = await this._getClient();
    return client.logout();
  }

  async isAuthenticated() {
    const client = await this._getClient();
    return client.isAuthenticated();
  }
}
