import { TargetingCriterionFragment } from '@spotify-confidence/plugin-graphql';

import { CriterionSegment } from '../../targeting.model';

export const toSchemaSegmentCriterion = (
  segCri: CriterionSegment,
): { key: string; value: TargetingCriterionFragment } => ({
  key: segCri.name,
  value: {
    segment: {
      segment: segCri.segment.name
        ? {
            __typename: 'FlagsAdminV1Segment',
            name: segCri.segment.name,
            displayName: segCri.segment.displayName,
          }
        : undefined,
    },
  },
});
