import React from 'react';

import { ListItemText, MenuItem, TextField } from '@material-ui/core';

import { MetricsV1AggregationType as AggregationType } from '@spotify-confidence/plugin-graphql';

import { getAggregationTypeLabel } from './aggregationUtils';

type AggregationTypeSelectProps = {
  entityName?: string;
  measurementName?: string;
  value: AggregationType;
  onChange: (type: AggregationType) => void;
  enabledOptions?: AggregationType[];
  'data-testid'?: string;
  helperText?: React.ReactNode;
  label?: string;
  disabled?: boolean;
};

const aggregationDescription: Record<
  AggregationType,
  (
    args: Pick<AggregationTypeSelectProps, 'entityName' | 'measurementName'>,
  ) => React.ReactNode
> = {
  [AggregationType.AggregationTypeAvg]: ({ entityName, measurementName }) =>
    `Average ${measurementName || 'value'} per ${entityName}.`,

  [AggregationType.AggregationTypeCount]: ({ measurementName, entityName }) =>
    `Amount of non-NULL ${measurementName || 'values'} per ${entityName}.`,

  [AggregationType.AggregationTypeCountDistinct]: ({
    measurementName,
    entityName,
  }) =>
    `Amount of unique non-NULL ${
      measurementName || 'values'
    } per ${entityName}.`,

  [AggregationType.AggregationTypeMax]: ({ measurementName, entityName }) =>
    `Highest ${measurementName || 'value'} per ${entityName}.`,

  [AggregationType.AggregationTypeMin]: ({ measurementName, entityName }) =>
    `Lowest ${measurementName || 'value'} per ${entityName}.`,

  [AggregationType.AggregationTypeSum]: ({ measurementName, entityName }) =>
    `Total of ${measurementName || 'values'} per ${entityName}.`,
};

export const AggregationTypeSelect = ({
  value,
  onChange,
  entityName,
  measurementName,
  enabledOptions,
  helperText,
  'data-testid': dataTestId,
  label = 'Method',
  disabled,
}: AggregationTypeSelectProps) => {
  return (
    <TextField
      select
      label={label}
      required
      fullWidth
      name="aggregation-type"
      data-testid={dataTestId ?? `aggregation-type-select-container`}
      value={value}
      onChange={e => onChange((e.target.value as AggregationType) ?? undefined)}
      helperText={helperText}
      disabled={disabled}
      variant="outlined"
    >
      {Object.values(AggregationType).map(option => (
        <MenuItem
          key={option}
          disabled={
            enabledOptions !== undefined && !enabledOptions.includes(option)
          }
          value={option}
        >
          <ListItemText
            primary={getAggregationTypeLabel(option)}
            secondary={aggregationDescription[option]({
              measurementName: measurementName || '',
              entityName: entityName || 'entity',
            })}
          />
        </MenuItem>
      ))}
    </TextField>
  );
};
