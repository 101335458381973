import React from 'react';
import { Route, Routes } from 'react-router-dom';

import { AuthorizationGate } from '@spotify-confidence/core-react';

import { AdminPage } from './AdminPage/AdminPage';
import { ApiClient, ApiClientsIndexPage } from './api-clients';
import { Reports } from './billing';
import { Subscription } from './billing/components/Subscription/Subscription';
import { ClientsPage } from './client';
import {
  FlagAppliedConnectionPage,
  FlagAppliedIndexPage,
} from './connections/components';
import { EventConnectionIndexPage } from './connections/components/EventConnectionIndexPage';
import { EventConnectionPage } from './connections/components/EventConnectionPage';
import { GroupIndexPage, GroupPage } from './group';
import { MetricsDataWarehousePage } from './metrics-datawarehouse';
import { OauthApp, OauthAppsIndexPage } from './oauth-apps';
import { PolicyIndexPage } from './policies';
import {
  apiClientRouteRef,
  apiClientsRouteRef,
  clientRouteRef,
  clientSchemaRouteRef,
  clientsRouteRef,
  eventConnectionRouteRef,
  eventConnectionsRouteRef,
  flagAppliedConnectionRouteRef,
  flagAppliedConnectionsRouteRef,
  groupRouteRef,
  groupsRouteRef,
  invitationsRouteRef,
  metricsDataWarehouseRouteRef,
  oauthAppRouteRef,
  oauthAppsRouteRef,
  policiesRouteRef,
  reportsRouteRef,
  subscriptionRouteRef,
  userRouteRef,
  usersRouteRef,
  workflowInstanceOverviewRouteRef,
  workflowRouteRef,
  workflowSecretRouteRef,
  workflowSecretsRouteRef,
  workflowsRouteRef,
} from './routes';
import { UserIndexPage } from './user';
import { WorkflowInstancePage, WorkflowPage, WorkflowsPage } from './workflow';
import { WorkflowSecretsPage } from './workflowSecrets';

const routes = (
  <>
    <Route
      path={workflowSecretsRouteRef.path}
      element={<WorkflowSecretsPage />}
    />
    <Route
      path={workflowSecretRouteRef.path}
      element={<WorkflowSecretsPage />}
    />
    <Route path={usersRouteRef.path} element={<UserIndexPage />} />
    <Route path={usersRouteRef.path} element={<UserIndexPage />} />
    <Route path={invitationsRouteRef.path} element={<UserIndexPage />} />
    <Route path={userRouteRef.path} element={<UserIndexPage />} />

    <Route path={policiesRouteRef.path} element={<PolicyIndexPage />} />
    <Route path={groupsRouteRef.path} element={<GroupIndexPage />} />
    <Route path={groupRouteRef.path} element={<GroupPage />} />

    <Route path={clientsRouteRef.path} element={<ClientsPage />} />
    <Route path={clientRouteRef.path} element={<ClientsPage />} />
    <Route path={clientSchemaRouteRef.path} element={<ClientsPage />} />

    <Route path={apiClientRouteRef.path} element={<ApiClient />} />
    <Route path={apiClientsRouteRef.path} element={<ApiClientsIndexPage />} />
    <Route path={oauthAppRouteRef.path} element={<OauthApp />} />
    <Route path={oauthAppsRouteRef.path} element={<OauthAppsIndexPage />} />
    <Route
      path={metricsDataWarehouseRouteRef.path}
      element={<MetricsDataWarehousePage />}
    />
    <Route path={reportsRouteRef.path} element={<Reports />} />
    <Route path={subscriptionRouteRef.path} element={<Subscription />} />
    <Route path={workflowsRouteRef.path} element={<WorkflowsPage />} />
    <Route
      path={`${workflowInstanceOverviewRouteRef.path}/*`}
      element={<WorkflowInstancePage />}
    />
    <Route path={`${workflowRouteRef.path}/*`} element={<WorkflowPage />} />
    <Route
      path={flagAppliedConnectionsRouteRef.path}
      element={<FlagAppliedIndexPage />}
    />
    <Route
      path={flagAppliedConnectionRouteRef.path}
      element={<FlagAppliedConnectionPage />}
    />
    <Route
      path={eventConnectionsRouteRef.path}
      element={<EventConnectionIndexPage />}
    />
    <Route
      path={eventConnectionRouteRef.path}
      element={<EventConnectionPage />}
    />
  </>
);

export const Router = () => {
  return (
    <AuthorizationGate>
      <Routes>
        <Route path="/" element={<AdminPage />} />
        {routes}
      </Routes>
    </AuthorizationGate>
  );
};
