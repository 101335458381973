import { useConfidenceCoreContext } from '@spotify-confidence/core-react';

import { WorkflowExtensionOptions } from './shared';

export const useWorkflowExtensions = () => {
  const { config } = useConfidenceCoreContext();
  return (config.workflowExtensions ?? []) as WorkflowExtensionOptions[];
};

export const useWorkflowExtension = ({
  workflowId,
}: {
  workflowId: string;
}) => {
  const workflowExtensions = useWorkflowExtensions();
  return workflowExtensions.find(
    workflowExtension => workflowExtension.workflowId === workflowId,
  );
};
