import React from 'react';

import { Box, Button, Chip } from '@material-ui/core';

import {
  EditButton,
  ResourceLink,
  SidebarSection,
  SidebarValue,
  useDialog,
} from '@spotify-confidence/core-react';
import {
  MetricDetailsFragment,
  getTypeOrNull,
  isType,
} from '@spotify-confidence/plugin-graphql';
import {
  IdentityChip,
  PermissionUtils,
} from '@spotify-confidence/plugin-permissions-react';

import { entityRouteRef, factTableRouteRef } from '../../..';
import { isFactTableUpdating } from '../../../factTable/domain';
import { getMetricType, metricTypeName } from '../../metricTypes';
import { MetricSection, getEditSections, metricUtils } from '../forms';
import { DeleteMetricDialog } from './DeleteMetricDialog';
import { EditSectionDialog } from './EditSectionDialog';

type MetricSidebarProps = {
  metric?: MetricDetailsFragment;
  disabled?: boolean;
};

export const MetricSidebar = ({
  metric,
  disabled: propDisabled,
}: MetricSidebarProps) => {
  const { openDialog } = useDialog();
  const metricInput = metric && metricUtils.getMetricInputForMetric(metric);
  const metricType = metric ? getMetricType(metric) : 'unknown';
  const sections = getEditSections(metricType);

  const deleteMetric = () => {
    if (metric) {
      openDialog({
        content: <DeleteMetricDialog metric={metric} />,
      });
    }
  };

  const editSection = ({ section }: { section: MetricSection }) => {
    if (metricInput) {
      openDialog({
        content: <EditSectionDialog metric={metricInput} section={section} />,
      });
    }
  };

  const canEdit = PermissionUtils.hasRelation(metric, 'can_edit');

  const disabled =
    propDisabled ||
    !metric ||
    isFactTableUpdating(
      getTypeOrNull(metric.factTable, 'MetricsV1FactTable'),
    ) ||
    !canEdit;

  const entity = getTypeOrNull(metric?.entity, 'MetricsV1Entity');
  const factTable = getTypeOrNull(metric?.factTable, 'MetricsV1FactTable');
  return (
    <>
      <SidebarSection title="Actions">
        <Box display="flex" gridGap={8}>
          <Button
            variant="outlined"
            size="small"
            disabled={disabled || !metric}
            onClick={deleteMetric}
          >
            Delete
          </Button>
        </Box>
      </SidebarSection>

      {sections.map(section => {
        return (
          <SidebarSection
            key={section.name}
            title={section.name}
            action={
              !disabled && (
                <EditButton
                  size="small"
                  disabled={disabled}
                  onClick={() => {
                    editSection({
                      section,
                    });
                  }}
                />
              )
            }
          >
            {metricInput &&
              section.summary(metricInput, {
                [entity?.name ?? 'unknown']: () => (
                  <ResourceLink
                    name={entity?.name ?? 'unknown'}
                    routeRef={entityRouteRef}
                  >
                    {entity?.displayName}
                  </ResourceLink>
                ),

                [factTable?.name ?? 'unknown']: () => (
                  <ResourceLink
                    name={factTable?.name ?? 'unknown'}
                    routeRef={factTableRouteRef}
                  >
                    {factTable?.displayName}
                  </ResourceLink>
                ),
                [getTypeOrNull(metric.owner, 'IamV1Identity')?.name ??
                'unknown']: () =>
                  isType(metric.owner, 'IamV1Identity') ? (
                    <IdentityChip {...metric.owner} />
                  ) : (
                    'Unknown'
                  ),
              })}
            {metric && section.name === 'About' && (
              <SidebarValue
                name="Type"
                value={
                  <Chip label={metricTypeName[metricType] || metricType} />
                }
              />
            )}
          </SidebarSection>
        );
      })}
    </>
  );
};
