import React from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';

import {
  Box,
  Checkbox,
  FormControlLabel,
  Link,
  TextField,
  Typography,
} from '@material-ui/core';
import { Alert } from '@material-ui/lab';

import {
  FormFieldsBox,
  NarrowBox,
  StringUtils,
} from '@spotify-confidence/core-react';
import { FormSubmitButtons } from '@spotify-confidence/core-react';
import { FormHelpText } from '@spotify-internal/encore-web';

import { useHubspotForm } from '../../hooks/useHubspotForm';
import { Branding } from './CreateAccountForm';
import { IntroStep } from './IntroStep';
import final from './img/final.png';

export const UnavailabilityPage = () => {
  const [{ loading, error, value }, postData] = useHubspotForm({
    portalId: '21894833',
    formId: 'e3cb3f9a-d0cd-4460-836c-a72e1e9435f3',
  });
  const [searchParams] = useSearchParams();
  const [company, setCompany] = React.useState('');
  const [email, setEmail] = React.useState('');
  const [acceptTerms, setAcceptTerms] = React.useState<boolean>(false);

  const navigate = useNavigate();
  const country = searchParams.get('country') ?? 'unknown';

  const submit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    postData([
      {
        objectTypeId: '0-1',
        name: 'email',
        value: email,
      },
      {
        name: 'company',
        value: company,
      },
      {
        name: 'country',
        value: country,
      },
    ]);
  };
  const disabled =
    company === '' ||
    !StringUtils.isValidEmail(email) ||
    loading ||
    !acceptTerms;

  return (
    <>
      <Branding />
      {value ? (
        <IntroStep
          title="Thank you for your interest in Confidence! We'll reach out once we launch in your country."
          img={final}
        />
      ) : (
        <Box paddingTop="15vh">
          <NarrowBox width="sm">
            <Typography variant="h4">
              Enter details for notification{' '}
            </Typography>
            <Typography variant="body1" color="textSecondary">
              We will notify you when we are available in your country.
            </Typography>
            <Box marginTop={1}>
              {error && <Alert severity="error">{error.message}</Alert>}
              {!value && (
                <form onSubmit={submit}>
                  <FormFieldsBox>
                    <TextField
                      type="email"
                      inputProps={{
                        inputMode: 'email',
                        autoCapitalize: 'never',
                      }}
                      value={email}
                      onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                        setEmail(e.target.value)
                      }
                      variant="outlined"
                      margin="dense"
                      label="E-mail"
                    />

                    <TextField
                      value={company}
                      onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                        setCompany(e.target.value)
                      }
                      variant="outlined"
                      margin="dense"
                      label="Company"
                    />
                    <div>
                      <FormControlLabel
                        control={
                          <Checkbox
                            color="primary"
                            checked={acceptTerms}
                            onChange={(_e, checked) => setAcceptTerms(checked)}
                            name="terms-of-service"
                          />
                        }
                        label="I agree to receive communications from Confidence."
                      />
                      <FormHelpText>
                        You may unsubscribe from these communications at any
                        time. Please review our{' '}
                        <Link
                          underline="always"
                          target="_blank"
                          href="https://www.spotify.com/us/legal/privacy-policy/"
                        >
                          Privacy Policy
                        </Link>
                        .
                      </FormHelpText>
                    </div>
                  </FormFieldsBox>
                  <Box textAlign="right" pt={2}>
                    <FormSubmitButtons
                      onCancel={() => navigate(-1)}
                      disabled={disabled}
                      loading={loading}
                      cancelLabel="Close"
                      label="Submit"
                    />
                  </Box>
                </form>
              )}
            </Box>
          </NarrowBox>
        </Box>
      )}
    </>
  );
};
