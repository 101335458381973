import React from 'react';

import { Tab, Tabs, Typography } from '@material-ui/core';
import { TabContext } from '@material-ui/lab';

import { CodeSnippet } from '../../CodeSnippet';
import { f } from '../../CodeSnippet';
import { SnippetProps } from '../../CodeSnippet';
import { StyledTabPanel } from '../../StyledTabPanel';
import { TUTORIAL_FLAG_NAME } from '../constants';

export const SwiftClientSnippets = (props: SnippetProps) => {
  const [packageManager, setPackageManager] = React.useState('spm');

  return (
    <ol>
      <li>
        <Typography variant="body1" gutterBottom>
          Install the required Confidence package.
        </Typography>
        <TabContext value={packageManager}>
          <Tabs
            indicatorColor="primary"
            value={packageManager}
            onChange={(_e, v) => setPackageManager(v)}
          >
            <Tab label="Swift Package Manager" value="spm" />
            <Tab label="Xcode" value="xcode" />
          </Tabs>
          <StyledTabPanel value="spm">
            <Typography variant="body1" gutterBottom>
              Update the "dependencies" section of Package.swift.
            </Typography>
            <CodeSnippet
              language="swift"
              text={f(`
.package(url: "git@github.com:spotify/confidence-sdk-swift.git", from: "0.2.1")
`)}
              showCopyCodeButton
            />
            <Typography variant="body1" gutterBottom>
              Next, update the "target dependencies" section.
            </Typography>
            <CodeSnippet
              language="swift"
              text={f(`
.product(name: "Confidence", package: "confidence-sdk-swift")
`)}
              showCopyCodeButton
            />
          </StyledTabPanel>
          <StyledTabPanel value="xcode">
            <Typography variant="body1" gutterBottom>
              First, ensure you have your GitHub account added in "Settings /
              Accounts". Then go to "File / Add Package Dependencies..." and add
              "Confidence" from the repository below.
            </Typography>
            <CodeSnippet
              language="bash"
              text={f(`
git@github.com:spotify/confidence-sdk-swift.git
`)}
              showCopyCodeButton
            />
          </StyledTabPanel>
        </TabContext>
      </li>
      <li>
        <Typography variant="body1" gutterBottom>
          Initialize the Confidence instance and fetch the flag evaluations from
          backend. `fetchAndActivate()` fetches the latest flag values from
          backend before returning, and should be called as early as possible in
          your application. We also want to set the `visitor_id` context to
          "tutorial_visitor", to match the targeting criteria pre-configured for
          this tutorial.
        </Typography>
        <CodeSnippet
          language="swift"
          text={f(`
import Confidence

let confidence = Confidence.Builder(clientSecret: "${props.apiKey}")
  .build()
confidence.putContext(context: ["visitor_id": ConfidenceValue.init(string: "tutorial_visitor")])
try await confidence.fetchAndActivate()
`)}
          showCopyCodeButton
        />
      </li>
      <li>
        <Typography variant="body1" gutterBottom>
          We can now get the title in the "{TUTORIAL_FLAG_NAME}" flag.
        </Typography>
        <CodeSnippet
          language="swift"
          text={f(`
let flagTitle = confidence.getValue(
  key: "tutorial-feature.title",
  defaultValue: "Default"
)
print(flagTitle)
      `)}
          showCopyCodeButton
        />
      </li>
    </ol>
  );
};
