import React from 'react';
import { useAsyncFn } from 'react-use';

import { Chip, Paper } from '@material-ui/core';

import {
  FormFieldsBox,
  WizardStepper,
  extractLastNameComponent,
} from '@spotify-confidence/core-react';
import { EventSchemaEntryFragment } from '@spotify-confidence/plugin-graphql';

import { DraftField } from '../../event';
import {
  KEY_JOINER,
  getHasInvalidChild,
  getPermanentKeys,
} from '../domain/schema.helpers';
import { SchemaEditor } from './SchemaEditor';
import { DraftSchemaEntries } from './SchemaEditor/DraftSchemaEntries';
import { SchemaProvider } from './SchemaEditor/SchemaContext';

/**
 * Dialog component for updating a schema from a draft event schema.
 * Should be used with a full screen dialog.
 */
export function UpdateSchemaFromDraftDialog({
  draftField,
  onClose,
  onUpdate,
}: {
  draftField: DraftField;
  onClose?: () => void;
  onUpdate?: (
    name: string,
    schema: EventSchemaEntryFragment[],
  ) => Promise<void>;
}) {
  const [schema, updateSchema] = React.useState(draftField.event?.schema || []);

  const [{ loading: updating, error }, update] = useAsyncFn(
    async () => onUpdate?.(draftField?.event?.name!, schema),
    [schema],
  );

  const handleSubmit = () => {
    update();
  };

  const permanentKeys = getPermanentKeys(draftField.event?.schema);
  const getEntryLabels = (_identity: string, path: string[]) => {
    if (!permanentKeys.includes(path.join(KEY_JOINER))) {
      return <Chip label="New" color="secondary" size="small" />;
    }
    return null;
  };
  const invalidChildren = getHasInvalidChild(schema);

  return (
    <WizardStepper
      onSubmit={handleSubmit}
      error={error}
      loading={updating}
      submitLabel="Save"
      disableNext={invalidChildren}
      onClose={onClose}
      width="lg"
      steps={[
        {
          name: `Update "${extractLastNameComponent(draftField.event?.name)}"`,
          description:
            'Review and modify the schema. Update the event to start receiving and storing the data.',
          content: (
            <FormFieldsBox>
              <SchemaProvider schema={schema} onChange={updateSchema}>
                <DraftSchemaEntries draftSchema={draftField.schema} />
                <Paper variant="outlined">
                  <SchemaEditor
                    getEntryLabels={getEntryLabels}
                    permanentKeys={permanentKeys}
                  />
                </Paper>
              </SchemaProvider>
            </FormFieldsBox>
          ),
        },
      ]}
    />
  );
}
