import { ObjUtils } from '@spotify-confidence/core-react';
import {
  InputMaybe,
  MetricFilterCriterionFragment,
  MetricFilterValuesFragment,
  MetricsV1ColumnType,
  MetricsV1ExpressionInput,
  MetricsV1FilterCriteriaEntryInput,
  MetricsV1FilterCriterionAttributeCriterion,
  MetricsV1FilterInput,
  MetricsV1FilterValueInput,
} from '@spotify-confidence/plugin-graphql';

import { ValueType } from './Criteria';

export type RuleType = keyof Omit<
  MetricsV1FilterCriterionAttributeCriterion,
  'attribute'
>;

export type SetOp = 'or' | 'and';

type OpCallback = (v: MetricsV1ExpressionInput[]) => MetricsV1ExpressionInput[];
const modifyExpression = (
  op: MetricsV1ExpressionInput,
  callback: OpCallback,
) => {
  if (op.or) {
    return {
      or: {
        operands: callback(op.or.operands),
      },
    };
  }
  if (op.and) {
    return {
      and: {
        operands: callback(op.and.operands),
      },
    };
  }
  return op;
};

export const getValueType = (v: MetricFilterValuesFragment) => {
  if (v.eqRule?.value) {
    return Object.keys(ObjUtils.cleanNulls(v?.eqRule.value))[0] as ValueType;
  } else if (v.setRule?.values) {
    return Object.keys(
      ObjUtils.cleanNulls(v.setRule?.values[0]),
    )[0] as ValueType;
  } else if (v.rangeRule) {
    const rangeValue =
      v.rangeRule?.startInclusive ??
      v.rangeRule?.startExclusive ??
      v.rangeRule.endInclusive ??
      v.rangeRule.endExclusive;
    return Object.keys(ObjUtils.cleanNulls(rangeValue))[0] as ValueType;
  }
  return null;
};

export const metricFilterHasEmptyAttributes = (
  filter?: InputMaybe<MetricsV1FilterInput>,
): boolean => {
  const criteria = filter?.criteria ?? [];
  return criteria.some((c): boolean => {
    if (!c.value?.attribute) return true;
    const { attribute, proto_type_url, ...values } = c.value.attribute;
    const ruleValue = ObjUtils.cleanNulls(values);
    const ruleType = Object.keys(ruleValue)?.[0] as RuleType;

    const valueType = getValueType(values);
    if (!valueType) return true;

    if (ruleType === 'eqRule') {
      return (
        (values[ruleType]?.value[valueType]?.toString() ?? '').length === 0
      );
    } else if (ruleType === 'setRule') {
      return (
        values[ruleType]?.values.some(
          v => (v[valueType]?.toString() ?? '').length === 0,
        ) ?? true
      );
    } else if (ruleType === 'rangeRule') {
      return Object.values(values.rangeRule ?? {})
        .filter(v => v !== null)
        .some(v => {
          if (typeof v !== 'string') {
            return (v?.[valueType]?.toString() ?? '').length === 0;
          }
          return false;
        });
    }
    return false;
  });
};
const setDefaultValueType = (
  type: MetricsV1ColumnType,
): MetricsV1FilterValueInput => {
  const valueType: MetricsV1FilterValueInput = {};
  if (type === MetricsV1ColumnType.ColumnTypeString) {
    valueType.stringValue = '';
  } else if (
    [
      MetricsV1ColumnType.ColumnTypeInt64,
      MetricsV1ColumnType.ColumnTypeInt32,
      MetricsV1ColumnType.ColumnTypeDouble,
    ].includes(type)
  ) {
    valueType.numberValue = undefined;
  } else if (type === MetricsV1ColumnType.ColumnTypeBoolean) {
    valueType.boolValue = true;
  } else if (
    [
      MetricsV1ColumnType.ColumnTypeDate,
      MetricsV1ColumnType.ColumnTypeTimestamp,
      MetricsV1ColumnType.ColumnTypeTimestampWithoutTimezone,
    ].includes(type)
  ) {
    valueType.timestampValue = '';
  }
  return valueType;
};

const getName = (
  name: string,
  existingCriteras?: MetricsV1FilterCriteriaEntryInput[],
) => {
  const _findName = (c: number): string => {
    const newKey = `${name}-${c}`;
    if ((existingCriteras ?? []).find(criteria => criteria.key === newKey)) {
      return _findName(c + 1);
    }
    return newKey;
  };
  return _findName(1);
};

export type UseMetricFilterResponse = ReturnType<typeof useMetricFilters>;
export const useMetricFilters = ({
  filter: savedFilter,
  onChange,
}: {
  filter?: InputMaybe<MetricsV1FilterInput>;
  onChange: (v: InputMaybe<MetricsV1FilterInput>) => void;
}) => {
  const hasFilters = savedFilter?.expression;
  const filter: MetricsV1FilterInput = hasFilters
    ? savedFilter
    : { criteria: [], expression: { or: { operands: [] } } };

  const handleDeleteCriterion = (key: string) => {
    const newCriteria = filter.criteria.filter(c => c.key !== key);
    const _deleteExpression = (
      e: MetricsV1ExpressionInput,
      currentPath: string = 'set/0',
    ): MetricsV1ExpressionInput => {
      return modifyExpression(e, operands =>
        operands
          .filter(f => f.ref !== key && f?.not?.ref !== key)
          .map((o, idx) => _deleteExpression(o, `${currentPath}/${idx}`)),
      );
    };
    const expression = _deleteExpression(filter?.expression ?? {});
    onChange({
      expression,
      criteria: newCriteria,
    });
  };

  const handleUpdateCriterion = (
    updateKey: string,
    newData: Partial<MetricFilterCriterionFragment>,
    isNot: boolean,
  ) => {
    const _createExpression = (
      e: MetricsV1ExpressionInput,
      currentPath: string = 'set/0',
    ): MetricsV1ExpressionInput => {
      return modifyExpression(e, operands =>
        operands.map((o, idx) => {
          if (o.ref === updateKey || o.not?.ref === updateKey) {
            const r = { ref: updateKey };
            return isNot ? { not: r } : r;
          }
          return _createExpression(o, `${currentPath}/${idx}`);
        }),
      );
    };
    const newCriteria = filter.criteria.map(c => {
      if (c.key === updateKey)
        return { ...c, value: { ...c.value, ...newData } };
      return c;
    });
    onChange({
      expression: _createExpression(filter?.expression ?? {}),
      criteria: newCriteria,
    });
  };

  const handleAddCriterion = (
    path: string,
    attr: {
      name: string;
      type?: MetricsV1ColumnType | null;
    },
  ) => {
    const { name } = attr;
    const newKey = getName(name, filter?.criteria);
    if (!attr.type) return;
    const valueType = setDefaultValueType(attr.type);
    const newCriteria: MetricsV1FilterCriteriaEntryInput = {
      key: newKey,
      value: {
        attribute: {
          attribute: name,
          eqRule: {
            value: valueType,
          },
        },
      },
    };
    const newExpression: MetricsV1ExpressionInput = { ref: newKey };
    const addToExpression = (
      e: MetricsV1ExpressionInput,
      currentPath: string = 'set/0',
    ): MetricsV1ExpressionInput => {
      if (currentPath === path) {
        return modifyExpression(e, operands => [...operands, newExpression]);
      }
      return modifyExpression(e, operands =>
        operands.map((o, idx) => addToExpression(o, `${currentPath}/${idx}`)),
      );
    };
    const expression = filter?.expression
      ? addToExpression(filter?.expression)
      : {};
    onChange({
      expression,
      criteria: [...(filter?.criteria ?? []), newCriteria],
    });
  };

  const handleAddSet = (path: string) => {
    const _addSet = (
      e: MetricsV1ExpressionInput,
      currentPath: string = 'set/0',
    ): MetricsV1ExpressionInput => {
      if (currentPath === path) {
        return modifyExpression(e, operands => [
          ...operands,
          { or: { operands: [] } },
        ]);
      }
      return modifyExpression(e, operands =>
        operands.map((o, idx) => _addSet(o, `${currentPath}/${idx}`)),
      );
    };

    const expression = _addSet(filter.expression);
    onChange({
      criteria: filter.criteria,
      expression,
    });
  };

  const handleRemoveSet = (path: string) => {
    const _removeSet = (
      e: MetricsV1ExpressionInput,
      currentPath: string = 'set/0',
    ): MetricsV1ExpressionInput => {
      return modifyExpression(e, operands =>
        operands
          .filter((_o, idx) => `${currentPath}/${idx}` !== path)
          .map((o, idx) => _removeSet(o, `${currentPath}/${idx}`)),
      );
    };

    const expression = _removeSet(filter.expression);
    onChange({
      criteria: filter.criteria,
      expression,
    });
  };

  const handleChangeSetOperator = (path: string, newOperator: SetOp) => {
    const _changeOperator = (
      e: MetricsV1ExpressionInput,
      currentPath: string = 'set/0',
    ): MetricsV1ExpressionInput => {
      if (currentPath === path) {
        const n: MetricsV1ExpressionInput = {
          // eslint-disable-next-line no-nested-ternary
          [newOperator]: e.and ? e.and : e.or ? e.or : {},
        };
        return n;
      }
      return modifyExpression(e, operands =>
        operands.map((o, idx) => _changeOperator(o, `${currentPath}/${idx}`)),
      );
    };
    const expression = _changeOperator(filter.expression);

    onChange({
      expression,
      criteria: filter.criteria,
    });
  };

  return {
    filter,
    onDeleteCriterion: handleDeleteCriterion,
    onUpdateCriterion: handleUpdateCriterion,
    onAddCriterion: handleAddCriterion,
    onAddSet: handleAddSet,
    onDeleteSet: handleRemoveSet,
    onChangeSetOperator: handleChangeSetOperator,
  };
};
