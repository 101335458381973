import React from 'react';
import { Link } from 'react-router-dom';

import { Link as MuiLink } from '@material-ui/core';

import { useDisplayNames } from '@spotify-confidence/core-react';
import { Metric } from '@spotify-confidence/plugin-metrics';

import { useRouteRef } from '@backstage/core-plugin-api';

import { surfaceRouteRef } from '../../routes';

type SurfaceLinkProps = {
  metric: Metric;
};

export const SurfaceLink = ({ metric }: SurfaceLinkProps) => {
  const surfaceRoute = useRouteRef(surfaceRouteRef);

  const { displayNames } = useDisplayNames();

  if (!metric.surface) {
    return null;
  }

  const displayName = displayNames.get(metric.surface) ?? metric.surface;

  return (
    <MuiLink
      title={displayName}
      component={Link}
      to={surfaceRoute?.({ id: metric.surface.split('/')[1] })}
    >
      {`(from ${displayName} Surface)`}
    </MuiLink>
  );
};
