import React, { forwardRef } from 'react';

import { List, ListProps } from '@material-ui/core';

export interface RecommendationCardCriteriaProps extends ListProps {}

const RecommendationCardCriteria = forwardRef<
  HTMLOListElement,
  RecommendationCardCriteriaProps
>(function RecommendationCardStatus({ ...props }, ref) {
  return <List ref={ref} {...props} />;
});

export default RecommendationCardCriteria;
