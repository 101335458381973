import React, { PropsWithChildren, createContext } from 'react';

import { BucketingTemporalUnit } from '../../../../MetricsModule';
import { ResultData } from '../MetricResultTable/types';

type Context = {
  value: ResultData;
  results: ResultData[];
  isSequential: boolean;
  bucket: BucketingTemporalUnit;
  isLive: boolean;
};

const context = createContext<Context | undefined>(undefined);

export const MetricResultContextProvider = ({
  value,
  results,
  isSequential,
  bucket,
  isLive,
  children,
}: PropsWithChildren<{
  value: ResultData;
  results: ResultData[];
  isSequential: boolean;
  bucket: BucketingTemporalUnit;
  isLive: boolean;
}>) => {
  return (
    <context.Provider value={{ value, results, bucket, isSequential, isLive }}>
      {children}
    </context.Provider>
  );
};

export const useMetricResultContext = () => {
  const ctx = React.useContext(context);
  if (!ctx) {
    throw new Error(
      'useMetricResultContext must be used within MetricResultProvider',
    );
  }
  return ctx;
};
