import { create } from 'zustand';

import { Comment } from '../types';

interface CommentsState {
  // true if we are in comment mode
  commentMode: boolean;

  // enable readOnly to prevent adding new comments
  readOnly: boolean;

  // current set of known Comments and the comment that is currently
  // selected, if any
  comments: Comment[];
  selectedComment?: string;

  updateComments: (comments: Comment[]) => void;
  selectComment: (id: string, zoneId: string) => void;
  deselectComment: () => void;

  // id of the zone that is currently selected for commenting
  selectZone: (id: string) => void;
  selectedZone?: string;
  deselectZone: () => void;

  // id of the zone that we should highlight
  hoveredZone?: string;
  hoverZone: (id: string) => void;
  unhoverZone: (id: string) => void;

  // id of the comment that is currently being created
  creating?: string;
  comment: (id: string) => void;
  created: () => void;

  // for entering comment mode
  enterCommentMode: () => void;

  // for toggling readOnly mode
  toggleReadOnly: (readOnly: boolean) => void;
}

export const useCommentState = create<CommentsState>(set => ({
  comments: [],
  selectedComment: undefined,
  selectedZone: undefined,
  targetedZone: undefined,
  hoveredZone: undefined,
  creating: undefined,
  commentMode: false,
  readOnly: false,

  // Update the state with known comments.
  updateComments: (comments: Comment[]) =>
    set(state => ({
      ...state,
      comments,
    })),

  comment: (id: string) =>
    set(state => {
      return {
        ...state,
        creating: id,
        commentMode: true,
        selectedComment: undefined,
        selectedZone: id,
        hoveredZone: undefined,
      };
    }),

  created: () =>
    set(state => ({
      ...state,
      creating: undefined,
      selectedZone: undefined,
    })),

  selectZone: (id: string) =>
    set(state => ({
      ...state,
      hoveredZone: undefined,
      selectedZone: id,
      commentMode: true,
    })),

  hoverZone: (id: string) =>
    set(state => ({
      ...state,
      hoveredZone: id,
    })),

  unhoverZone: (id: string) =>
    set(state => ({
      ...state,
      hoveredZone: state.hoveredZone === id ? undefined : state.hoveredZone,
    })),

  selectComment: (commentId: string, zoneId: string) =>
    set(state => ({
      ...state,
      selectedComment: commentId,
      selectedZone: zoneId,
      commentMode: true,
      creating: undefined,
    })),

  deselectComment: () =>
    set(state => ({
      ...state,
      selectedComment: undefined,
      selectedZone: undefined,
    })),

  enterCommentMode: () =>
    set(state => ({
      ...state,
      commentMode: true,
    })),

  toggleReadOnly: (readOnly: boolean) => set(state => ({ ...state, readOnly })),

  deselectZone: () =>
    set(state => ({
      ...state,
      hoveredZone: undefined,
      selectedZone: undefined,
      commentMode: false,
      creating: undefined,
    })),
}));
