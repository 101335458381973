import React from 'react';

import { Typography } from '@material-ui/core';

import { CodeSnippet, SnippetProps } from '../../CodeSnippet';
import { f } from '../../CodeSnippet';
import { TUTORIAL_FLAG_NAME } from '../constants';

export const FlutterClientSnippets = (props: SnippetProps) => {
  return (
    <ol>
      <li>
        <Typography variant="body1" gutterBottom>
          Add the confidence dependency to your flutter app.
        </Typography>
        <CodeSnippet
          language="bash"
          text={f(`
          flutter pub add confidence_flutter_sdk
`)}
          showCopyCodeButton
        />
      </li>
      <li>
        <Typography variant="body1" gutterBottom>
          Initialize the Confidence instance. We also want to set the
          `visitor_id` context to "tutorial_visitor", to match the targeting
          criteria pre-configured for this tutorial. For initialization of the
          confidence instance, we create an async function as following.
        </Typography>
        <CodeSnippet
          language="dart"
          text={f(`
import 'package:confidence_flutter_sdk/confidence_flutter_sdk.dart';

Future<void> initConfidence() async {
    ConfidenceFlutterSdk confidence = ConfidenceFlutterSdk();
    await confidence.setup("${props.apiKey}");
    await confidence.putContext("visitor_id", "tutorial_visitor");
}
`)}
          showCopyCodeButton
        />
      </li>
      <li>
        <Typography variant="body1" gutterBottom>
          Fetch the flag evaluations from backend. The async function
          `fetchAndActivate()` fetches the latest values from backend before
          returning, and should be called as early as possible in your
          application. after fetching the flags, We can get the title in the "
          {TUTORIAL_FLAG_NAME}" flag.
        </Typography>
        <CodeSnippet
          language="dart"
          text={f(`
await confidence.fetchAndActivate();
String message = confidence.getString("${TUTORIAL_FLAG_NAME}.title", "Default"); 
      `)}
          showCopyCodeButton
        />
      </li>
    </ol>
  );
};
