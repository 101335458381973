import {
  ConnectorsV1DatabricksConfigInput,
  ConnectorsV1DatabricksConnectionConfigInput,
  ConnectorsV1EventConnectionDatabricksEventConnectionConfigInput as DatabricksEventInput,
  ConnectorsV1FlagAppliedConnectionDatabricksFlagAppliedConnectionConfigInput as DatabricksFlagAppliedInput,
} from '@spotify-confidence/plugin-graphql';

import { defaultBatchConfig } from '../BatchFileConfigForm';
import {
  defaultS3BucketConfig,
  isS3BucketConfigValid,
} from '../S3BucketConfigForm';

const isConnectionConfigValid = (
  connectionConfig: ConnectorsV1DatabricksConnectionConfigInput,
) => {
  return Boolean(
    connectionConfig.host &&
      connectionConfig.warehouseId &&
      connectionConfig.clientId &&
      connectionConfig.clientSecret,
  );
};

export const isDatabricksFlagAppliedInputValid = (
  input: DatabricksFlagAppliedInput,
) => {
  return Boolean(
    input.table &&
      input.databricksConfig.schema &&
      isConnectionConfigValid(input.databricksConfig.connectionConfig) &&
      (input.databricksConfig.s3BucketConfig
        ? isS3BucketConfigValid(input.databricksConfig.s3BucketConfig)
        : true),
  );
};

export const isDatabricksEventInputValid = (input: DatabricksEventInput) => {
  return Boolean(
    input.databricksConfig.schema &&
      isConnectionConfigValid(input.databricksConfig.connectionConfig) &&
      (input.databricksConfig.s3BucketConfig
        ? isS3BucketConfigValid(input.databricksConfig.s3BucketConfig)
        : true),
  );
};

export const defaultConnectionConfig: ConnectorsV1DatabricksConnectionConfigInput =
  {
    host: '',
    warehouseId: '',
    clientId: '',
  };

export const defaultDatabricksConfig: ConnectorsV1DatabricksConfigInput = {
  batchFileConfig: defaultBatchConfig,
  connectionConfig: defaultConnectionConfig,
  s3BucketConfig: defaultS3BucketConfig,
  schema: '',
};

export const defaultDatabricksFlagAppliedInput: DatabricksFlagAppliedInput = {
  databricksConfig: defaultDatabricksConfig,
  table: '',
};

export const defaultDatabricksEventInput: DatabricksEventInput = {
  databricksConfig: defaultDatabricksConfig,
};
