import React from 'react';
import { useInView } from 'react-intersection-observer';

import { makeStyles, useTheme } from '@material-ui/core';

import { isAfter } from 'date-fns';

import { getTranslateX } from '../helpers';
import { Activity, NamedEntity } from '../types';
import {
  LateBorderOverlay,
  LateStartBorder,
  NavigationButton,
  OnlyPlannedEndLine,
  OnlyPlannedStartLine,
  OverduePlanLine,
  PlannedBorderOverlay,
  TimelineActivityBorder,
  TimelineActivityContent,
  TimelineActivityOverflowOverlay,
  TimelineActivityWrapper,
} from './TimelineActivityComponents';
import { TimelineActivityStatus } from './TimelineActivityStatus';
import { getActivityState } from './helpers';

type TimelineActivityProps<T extends NamedEntity> = {
  activity: Activity<T>;
  hovered?: boolean;
  originDate?: Date;
  todayX: number;
  activityMinWidth: number;
  renderItem: (item: T) => React.ReactNode;
  onNavigate?: (date: Date) => void;
};

const useStyles = makeStyles({
  '@keyframes fadeIn': {
    '0%': { opacity: 0 },
    '100%': { opacity: 1 },
  },
  fadeIn: { animation: '$fadeIn .2s ease-in-out' },
});

export const TimelineActivity = <T extends NamedEntity>({
  activity,
  todayX,
  activityMinWidth,
  hovered,
  renderItem,
  originDate,
  onNavigate,
}: TimelineActivityProps<T>) => {
  const { ref, inView } = useInView({
    fallbackInView: false,
  });
  const classes = useStyles();
  const theme = useTheme();
  const state = React.useMemo(() => getActivityState(activity), [activity]);

  const {
    startX,
    plannedStartX,
    endX,
    plannedEndX,
    start,
    plannedStart,
    end,
    plannedEnd,
  } = activity;
  const {
    notYetStarted,
    startsToday,
    endsToday,
    plannedEndInFuture,
    plannedStartOverdue,
    startedLate,
    endedEarly,
    endedLate,
    startAfterPlannedEnd,
    plannedEndOverdue,
    startedTodayWithoutEnd,
    ranOnlyToday,
    hasTimeData,
    passedCompletePlan,
  } = state;

  let activityX =
    startX && plannedStartX
      ? Math.min(startX, plannedStartX)
      : startX || plannedStartX;

  let borderX = 0;
  let plannedBorderX = 0;
  let width = 0;
  let minWidth = activityMinWidth;
  if (startedTodayWithoutEnd) {
    minWidth = activityMinWidth / 4;
  } else if (ranOnlyToday) {
    minWidth = activityMinWidth / 2;
  }
  if (activityX) {
    if (startX) {
      borderX = startX - activityX;
    }

    if (startsToday) {
      // Offset X so that border does not disappear if started today
      activityX = activityX - minWidth;
      if (!plannedEnd) {
        width = todayX - activityX;
      }
    }

    if (endX) {
      width = Math.max(endX - activityX, minWidth);
    } else if (plannedEndX && start) {
      width = Math.max(plannedEndX, todayX) - activityX;
    } else if (plannedEndX) {
      width = plannedEndX - activityX;
    } else if (start) {
      width = todayX - activityX;
    }

    if (start) {
      // Offset planned border to only show after today
      plannedBorderX = todayX - activityX - theme.spacing(1);
    }
  } else if (plannedEndX) {
    // Fallback to planned end since we will never have only end set
    activityX = plannedEndX;
  }

  const dateToNavigateTo = start || plannedStart || end || plannedEnd;
  const isLater =
    dateToNavigateTo && originDate
      ? isAfter(dateToNavigateTo, originDate)
      : false;

  const navigateToItem = () => {
    if (dateToNavigateTo) {
      onNavigate?.(new Date(dateToNavigateTo));
    }
  };

  const content = (
    <TimelineActivityContent>
      {renderItem(activity.item)}
      <TimelineActivityStatus activity={activity} state={state} />
    </TimelineActivityContent>
  );

  return (
    <>
      <TimelineActivityWrapper
        ref={ref}
        style={{
          transform: getTranslateX(activityX),
          minWidth: width,
        }}
      >
        {inView && hasTimeData && (
          <div className={classes.fadeIn}>
            {activityX && width > 0 ? (
              <>
                <TimelineActivityBorder
                  hovered={hovered}
                  hideLeftBorder={plannedStartOverdue}
                  hideRightBorder={!startAfterPlannedEnd && endedLate}
                  disableRightBorder={
                    endsToday || (plannedEndOverdue && !notYetStarted)
                  }
                  style={{
                    transform: getTranslateX(borderX - 1),
                    width: Math.max(width - borderX, minWidth),
                  }}
                >
                  {content}
                  {plannedStartX && plannedStartOverdue && (
                    <LateStartBorder
                      showRightBorder={passedCompletePlan}
                      style={{
                        width:
                          (plannedEndOverdue && plannedEndX
                            ? plannedEndX
                            : todayX) - plannedStartX,
                      }}
                    />
                  )}
                  {(notYetStarted || plannedEndInFuture) && (
                    <PlannedBorderOverlay
                      showLeftBorder={!start}
                      noRightBorder={endsToday}
                      style={{
                        width: width - plannedBorderX,
                      }}
                    />
                  )}
                  {!startAfterPlannedEnd &&
                    (endedLate || plannedEndOverdue) &&
                    plannedEndX && (
                      <LateBorderOverlay
                        noRightBorder={plannedEndOverdue}
                        style={{
                          width: width - (plannedEndX - activityX),
                        }}
                      />
                    )}
                  <TimelineActivityOverflowOverlay />
                </TimelineActivityBorder>

                {startedLate && plannedStartX && (
                  <OverduePlanLine
                    style={{
                      transform: getTranslateX(plannedStartX - activityX),
                    }}
                  />
                )}
                {(endedEarly || startAfterPlannedEnd) && plannedEndX && (
                  <OverduePlanLine
                    style={{
                      transform: getTranslateX(plannedEndX - activityX),
                    }}
                  />
                )}
              </>
            ) : (
              <>
                {plannedStart ? (
                  <OnlyPlannedStartLine overdue={plannedStartOverdue}>
                    {content}
                  </OnlyPlannedStartLine>
                ) : (
                  plannedEnd && (
                    <OnlyPlannedEndLine overdue={plannedEndOverdue}>
                      {content}
                    </OnlyPlannedEndLine>
                  )
                )}
              </>
            )}
          </div>
        )}
      </TimelineActivityWrapper>
      {Boolean(!inView && hasTimeData && dateToNavigateTo) && (
        <NavigationButton
          show={hovered}
          direction={isLater ? 'future' : 'past'}
          onClick={navigateToItem}
        />
      )}
    </>
  );
};
