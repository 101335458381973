import React from 'react';

import { ThemeProvider } from '@material-ui/core';

import { encoreDarkTheme, encoreLightTheme } from 'backstage-encore-theme';

export const EncoreDarkThemeProvider = ({
  children,
}: {
  children: React.ReactNode;
}) => {
  return <ThemeProvider theme={encoreDarkTheme}>{children}</ThemeProvider>;
};

export const EncoreLightThemeProvider = ({
  children,
}: {
  children: React.ReactNode;
}) => {
  return <ThemeProvider theme={encoreLightTheme}>{children}</ThemeProvider>;
};
