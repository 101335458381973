import React from 'react';

import { Typography } from '@material-ui/core';

import { CodeSnippet } from '../../CodeSnippet';
import { f } from '../../CodeSnippet';

export const RustClientSnippet = () => {
  return (
    <>
      <Typography variant="body1" gutterBottom>
        Add the following import to be able to send track events.
      </Typography>
      <CodeSnippet
        language="rust"
        showLineNumbers
        text={f(`
use spotify_confidence_sdk::event_sender::EventSender;
    `)}
        showCopyCodeButton
      />
      <Typography variant="body1" gutterBottom>
        Let's track a "tutorial-event" event from your App and make sure it gets
        correctly published to our backend. This track function spawns a new
        task to publish the event to the backend.
      </Typography>
      <CodeSnippet
        language="rust"
        showLineNumbers
        text={f(`
confidence.track("tutorial-event", HashMap::new());
  `)}
        showCopyCodeButton
      />
      <Typography variant="body1" gutterBottom>
        If you app immediately terminates after sending the track event, you
        might want to keep your app alive for some time to make sure the event
        reaches the backend.
      </Typography>
      <CodeSnippet
        language="rust"
        showLineNumbers
        text={f(`
sleep(std::time::Duration::from_secs(10));
  `)}
        showCopyCodeButton
      />
    </>
  );
};
