import React, { forwardRef } from 'react';

import { BackstagePage } from './BackstagePage';

export type PageProps = React.PropsWithChildren<{}>;

export const Page = forwardRef<HTMLDivElement, PageProps>(
  ({ children }: PageProps, ref) => {
    return (
      <BackstagePage ref={ref} themeId="other">
        {children}
      </BackstagePage>
    );
  },
);
