import React from 'react';

import { MetricConfigDialog } from './MetricConfigDialog';
import { MetricDialog } from './MetricDialog';
import {
  MetricsModuleConfiguratorDialogProps,
  MetricsModuleConfiguratorDialogStep,
} from './types';

export const MetricsModuleConfiguratorDialog = ({
  addMetric,
  saveConfig,
  entity,
  assignmentTable,
  existingMetrics,
  metricsData,
  currentStepDefault,
  metricType,
}: MetricsModuleConfiguratorDialogProps) => {
  const [currentStep, setCurrentStep] =
    React.useState<MetricsModuleConfiguratorDialogStep>(
      currentStepDefault ?? (!entity || !assignmentTable) ? 'config' : 'metric',
    );
  const isConfigStep = currentStep === 'config';
  const asStep = !currentStepDefault;
  const [entityName, setEntityName] = React.useState(entity);

  return isConfigStep ? (
    <MetricConfigDialog
      asStep={asStep}
      metricConfig={metricsData}
      onSave={async config => {
        await saveConfig(config);
        if (asStep) {
          setEntityName(config.entity!);
          setCurrentStep('metric');
        }
      }}
    />
  ) : (
    <MetricDialog
      onSubmit={addMetric}
      entity={entityName!}
      existingMetrics={existingMetrics}
      allowedMetricTypes={metricType ? [metricType] : ['guardrail', 'success']}
    />
  );
};
