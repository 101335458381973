import React from 'react';

import _ from 'lodash';

export function useLocalStorage<S>(storageKey: string, initalData: S) {
  const init = () => {
    const v = localStorage.getItem(storageKey);
    if (v) {
      try {
        return JSON.parse(v) as S;
      } catch (error) {
        // eslint-disable-next-line no-console
        console.error(error);
      }
    }
    return initalData;
  };
  const [value, setValue] = React.useState(init());
  React.useEffect(() => {
    if (window.localStorage) {
      const storedValue = init();
      window.localStorage.setItem(storageKey, JSON.stringify(value));
      if (!_.isEqual(storedValue, value)) {
        window.dispatchEvent(new StorageEvent('storage', { key: storageKey }));
      }
    }
  }, [value]);

  React.useEffect(() => {
    const storageEventHandler = (event: StorageEvent) => {
      if (event.key === storageKey) {
        const storedValue = init();
        setValue(storedValue);
      }
    };
    window.addEventListener('storage', storageEventHandler);
    return () => {
      window.removeEventListener('storage', storageEventHandler);
    };
  }, []);

  return [value, setValue] as const;
}
