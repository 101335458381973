import React from 'react';

import loadable from '@loadable/component';
import { createWorkflowExtension } from '@spotify-confidence/plugin-workflows';

import { createPlugin } from '@backstage/core-plugin-api';

import { ReanalysisTableRow } from './components/ReanalysisWorkflowPage/ReanalysisTableRow';
import { REANALYSIS_WORKFLOW_ID } from './constants';

export const confidenceWorkflowsModuleReanalysisPlugin = createPlugin({
  id: 'confidence-workflows-module-reanalysis',
});

const componentsChunk = () =>
  import(
    /* webpackChunkName: "workflows-analysis-pages", webpackPrefetch: 5 */ './components'
  );
const loadOptions = {};

const ReanalysisWorkflowPage = loadable(
  () => componentsChunk().then(m => m.ReanalysisWorkflowPage),
  loadOptions,
);
const ReanalysisWorkflowInstancePage = loadable(
  () => componentsChunk().then(m => m.ReanalysisWorkflowInstancePage),
  loadOptions,
);

export const ReanalysisWorkflowExtension =
  confidenceWorkflowsModuleReanalysisPlugin.provide(
    createWorkflowExtension({
      title: 'Analysis',
      workflowId: REANALYSIS_WORKFLOW_ID,
      workflowPage: () => <ReanalysisWorkflowPage />,
      workflowInstancePage: () => <ReanalysisWorkflowInstancePage />,
      workflowTableRowComponent: ReanalysisTableRow,
    }),
  );
