import React from 'react';

import { MenuItem, TextField } from '@material-ui/core';

import {
  getTypeOrNull,
  useFactTableMeasuresQuery,
} from '@spotify-confidence/plugin-graphql';

type MeasurementSelectProps = {
  value: string;
  label?: string;
  factTable?: string;
  onChange: (measurement: string) => void;
  disabled?: boolean;
  disabledOptions?: string[];
  id?: string;
  helperText?: React.ReactNode;
  name?: string;
  required?: boolean;
  emptyLabel?: string;
};

export function MeasurementSelect({
  label = 'Measurement',
  value,
  onChange,
  disabled,
  disabledOptions = [],
  helperText,
  id,
  name,
  required,
  factTable,
  emptyLabel = 'The number of fact rows',
}: MeasurementSelectProps) {
  const factTableData = useFactTableMeasuresQuery({
    skip: !factTable,
    variables: {
      name: factTable || '',
    },
  });
  const options =
    getTypeOrNull(factTableData.data?.factTable, 'MetricsV1FactTable')
      ?.measures || [];

  const handleChange = (
    e: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>,
  ) => {
    onChange(e.target.value);
  };

  return (
    <TextField
      required={required}
      label={label}
      name={name ?? 'measurement'}
      margin="dense"
      fullWidth
      variant="outlined"
      onChange={handleChange}
      value={value}
      disabled={disabled}
      select
      id={id}
      helperText={helperText}
      SelectProps={{ displayEmpty: !!emptyLabel }}
      InputLabelProps={{
        shrink: true,
      }}
    >
      <MenuItem value="" divider disabled={!emptyLabel}>
        <em>{emptyLabel}</em>
      </MenuItem>
      {options.map(option => (
        <MenuItem
          value={option.column.name}
          key={option.column.name}
          disabled={disabledOptions.includes(option.column.name)}
        >
          {option.column.name}
        </MenuItem>
      ))}
    </TextField>
  );
}
