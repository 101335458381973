import {
  AggregationFn,
  Cell,
  GroupingState,
  Header,
  Row,
} from '@tanstack/react-table';

import * as resultUtils from '../utils';
import { ResultData } from './types';

export const getLatestValue = (row: Row<ResultData>): ResultData => {
  const groupedData = row.subRows.map(subrow => subrow.original);
  const latest = resultUtils.getLatestResult(groupedData);
  return latest || row.original;
};
export const aggregateToLatestValue: AggregationFn<ResultData> = (
  columnId,
  _leafRows,
  childRows,
) => {
  const withTime = childRows.map(row => ({
    ...row,
    timeLabel: row.original.timeLabel,
  }));
  return resultUtils.getLatestResult(withTime)?.getValue(columnId) ?? null;
};

function isNotLastGroupingCell(
  cell: Cell<ResultData, unknown> | Header<ResultData, unknown>,
  grouping: GroupingState,
) {
  return !grouping.slice(0, -1).includes(cell.column.id);
}

export function getHeadersExcludingGroupTitle(
  headers: Header<ResultData, unknown>[],
  grouping?: GroupingState,
) {
  if (grouping && grouping.length > 1) {
    return headers.filter(cell => isNotLastGroupingCell(cell, grouping));
  }
  return headers;
}

export function getVisibleCellsExcludingGroupTitle(
  row: Row<ResultData>,
  grouping?: GroupingState,
) {
  if (grouping && grouping.length > 1) {
    return row
      .getVisibleCells()
      .filter(cell => isNotLastGroupingCell(cell, grouping));
  }
  return row.getVisibleCells();
}

export function isRowSelected(row: Row<ResultData>) {
  return (
    row.getIsSelected() ||
    row.getIsSomeSelected() ||
    row.getIsAllSubRowsSelected()
  );
}

export const DATE_FORMAT = 'yyyy-MM-dd';
export const DATETIME_FORMAT = 'yyyy-MM-dd HH:mm';
export const HOUR_FORMAT = 'HH:mm';
