import React from 'react';
import { useDeepCompareEffect } from 'react-use';

import {
  FlagsAdminV1EvaluationContextSchemaFieldKind as Kind,
  getError,
  getTypeOrNull,
  useGetClientSchemaMutation,
  useListEvaluationContextFieldOverridesQuery,
} from '@spotify-confidence/plugin-graphql';

import { SchemaItem } from './types';

export const useFlagContextSchemaList = ({
  clients = [],
}: {
  clients?: string[];
}) => {
  const overrides = useListEvaluationContextFieldOverridesQuery({
    fetchPolicy: 'cache-and-network',
  });

  const [getClientsSchema, clientsSchema] = useGetClientSchemaMutation({
    variables: {
      clients,
    },
  });

  const overridesData = React.useMemo(
    () =>
      getTypeOrNull(
        overrides.data?.evaluationContextFieldOverrides,
        'FlagsAdminV1ListEvaluationContextFieldOverridesResponse',
      ),
    [overrides.data],
  );

  const derivedData = React.useMemo(
    () =>
      getTypeOrNull(
        clientsSchema.data?.deriveClientEvaluationContextSchema,
        'FlagsAdminV1DeriveClientEvaluationContextSchemaResponse',
      ),
    [clientsSchema.data],
  );

  const existingOverrides = React.useMemo(
    () =>
      overridesData?.evaluationContextFieldOverrides.map(o => o.field) || [],
    [overridesData],
  );

  // Get derived context for all clients to show what is being sent in
  useDeepCompareEffect(() => {
    if (clients.length) {
      getClientsSchema({
        variables: { clients },
      });
    }
  }, [clients]);

  // Fetch all overrides to filter on all combined items in the UI
  React.useEffect(() => {
    if (overridesData?.nextPageToken) {
      overrides.fetchMore({
        variables: {
          pageToken: overridesData.nextPageToken,
        },
      });
    }
  }, [overridesData]);

  // Merge overrides and derived schema to show all items in the same list
  const items: SchemaItem[] = React.useMemo(() => {
    const overrideItems: SchemaItem[] =
      overridesData?.evaluationContextFieldOverrides || [];

    const derivedItems: SchemaItem[] =
      derivedData?.mergedSchema?.schema
        // Only get the fields that don't inherit values from an override to avoid
        // displaying outdated information in the UI after updates
        .filter(entry => !existingOverrides.includes(entry.key))
        .map(entry => {
          return {
            field: entry.key,
            ...entry.value,
            // TODO: should we highlight/warn if there are multiple types?
            kind: entry.value?.types[0] || Kind.StringKind,
          };
        }) || [];

    return overrideItems.concat(derivedItems);
  }, [overridesData, derivedData]);

  const loading = overrides.loading || clientsSchema.loading;
  const error =
    overrides.error ||
    clientsSchema.error ||
    getError(overrides.data?.evaluationContextFieldOverrides) ||
    getError(clientsSchema.data?.deriveClientEvaluationContextSchema);

  return {
    loading,
    error,
    items,
    existingOverrides,
  };
};
