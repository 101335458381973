import React, { PropsWithChildren, createContext, useContext } from 'react';

import { useRouteRef } from '@backstage/core-plugin-api';

import { workflowRouteRef } from '../../../routes';
import { WorkflowExtensionOptions } from '../extensions';

interface WorkflowContext extends WorkflowExtensionOptions {
  href: string;
}

const context = createContext<WorkflowContext | undefined>(undefined);

export const WorkflowContextConsumer = context.Consumer;

export const WorkflowContextProvider = ({
  value,
  children,
}: PropsWithChildren<{ value: WorkflowExtensionOptions }>) => {
  const workflowsRoute = useRouteRef(workflowRouteRef);
  const href = workflowsRoute?.({
    workflow: value.workflowId,
  });

  return (
    <context.Provider value={{ ...value, href }}>{children}</context.Provider>
  );
};

export const useWorkflowContext = () => {
  const value = useContext(context);

  if (!value) {
    throw new Error(
      'useWorkflowContext must be used within a WorkflowContextProvider',
    );
  }

  return value;
};
