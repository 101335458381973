import React, { PropsWithChildren, forwardRef } from 'react';

import { WithStyles, createStyles, withStyles } from '@material-ui/core';

const styles = () =>
  createStyles({
    root: {},
  });

export interface RecommendationCardBodyProps
  extends WithStyles<typeof styles>,
    PropsWithChildren<{}> {}

const RecommendationCardBody = forwardRef<
  HTMLDivElement,
  RecommendationCardBodyProps
>(function RecommendationCardStatus({ classes, children }, ref) {
  return (
    <div ref={ref} className={classes.root}>
      {children}
    </div>
  );
});

export default withStyles(styles)(RecommendationCardBody);
