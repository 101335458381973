import { LinearProgress, alpha, styled } from '@material-ui/core';

export const WizardWrapper = styled('div')(({ theme }) => ({
  height: '100%',
  width: '100%',
  maxWidth: theme.breakpoints.values.xl,
  margin: '0 auto',
  padding: theme.spacing(4),
}));

export const WizardContent = styled('section')(({ theme }) => ({
  paddingTop: theme.spacing(4),
  [theme.breakpoints.up('md')]: {
    paddingTop: `min(4vh, ${theme.spacing(4)}px)`,
  },
}));

export const WizardHeader = styled('div')(({ theme }) => ({
  position: 'absolute',
  right: theme.spacing(2),
  top: theme.spacing(2),
  [theme.breakpoints.up('sm')]: {
    position: 'fixed',
  },
}));

export const WizardFooter = styled('footer')(({ theme }) => ({
  margin: theme.spacing(0, -2),
  zIndex: 2,
  padding: theme.spacing(4, 2),
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'flex-end',
  columnGap: theme.spacing(1),
}));

export const WizardLoadingOverlay = styled('div')(({ theme }) => ({
  position: 'fixed',
  top: 0,
  bottom: 0,
  left: 0,
  right: 0,
  background: alpha(theme.palette.background.paper, 0.7),
  zIndex: 3,
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
}));

export const WizardStepWrapper = styled('div')(({ theme }) => ({
  marginTop: theme.spacing(4),
}));

export const TitleWrapper = styled('header')(({ theme }) => ({
  marginBottom: theme.spacing(4),
}));

export const StepProgressBar = styled(LinearProgress)(({ theme }) => ({
  // make the bar slightly wider than the rest of the content to make it look more aligned
  left: -3,
  right: 3,
  width: 'calc(100% + 6px)',
  marginBottom: theme.spacing(2),
}));
