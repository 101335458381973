import React from 'react';

import { DateUtils, MediumScreenCell } from '@spotify-confidence/core-react';
import {
  getTypeOrNull,
  useCurrentUser,
} from '@spotify-confidence/plugin-graphql';
import { WorkflowInstanceExposureCell } from '@spotify-confidence/plugin-metrics';
import {
  WorkflowInstanceListItem,
  WorkflowInstanceNameCell,
  WorkflowInstanceStateCell,
  WorkflowInstanceTableRow,
} from '@spotify-confidence/plugin-workflows';

export const ReanalysisTableRow = ({
  workflowInstance,
}: {
  workflowInstance: WorkflowInstanceListItem;
}) => {
  const currentUser = useCurrentUser();

  const comments =
    getTypeOrNull(
      workflowInstance.comments,
      'CollaborationV1ListCommentsResponse',
    )?.comments ?? [];

  const assignCount =
    comments
      ?.filter(c => !c.resolved)
      ?.filter(
        c =>
          getTypeOrNull(c.assignee, 'IamV1Identity')?.name ===
          currentUser?.identity?.name,
      )?.length ?? 0;

  return (
    <WorkflowInstanceTableRow workflowInstance={workflowInstance}>
      <WorkflowInstanceNameCell
        workflowInstance={workflowInstance}
        badgeCount={assignCount}
      />
      <WorkflowInstanceStateCell workflowInstance={workflowInstance} />
      {/* TODO: remove when we support configure header */}
      <MediumScreenCell />
      <WorkflowInstanceExposureCell workflowInstance={workflowInstance} />
      <MediumScreenCell>
        {DateUtils.xAgo(new Date(workflowInstance?.updateTime))}
      </MediumScreenCell>
    </WorkflowInstanceTableRow>
  );
};
