import React from 'react';

import { Button } from '@material-ui/core';

import { emailLink } from '../../utils/email';
import { TransitionScreen } from '../shared';

export const InviteError = ({ error }: { error: Error }) => {
  const notFound = error.message.includes('was not found');
  return (
    <TransitionScreen
      title={
        notFound
          ? "Sorry, we couldn't find that invite"
          : 'Something went wrong'
      }
      description={
        notFound ? (
          <span>
            Try clicking on the link that you received in your invitation email.
            If you still end up here, please contact us for support.
          </span>
        ) : (
          <span>
            We seem to be experiencing some trouble on our end. Try refreshing
            the page or contact us to help you figure out the next step.
          </span>
        )
      }
      actions={
        <Button
          variant="contained"
          href={emailLink('Account invite issue', error.message)}
          color="secondary"
          size="large"
          children="Contact support"
        />
      }
    />
  );
};
