import React from 'react';
import { InView } from 'react-intersection-observer';

import {
  Button,
  Link as MuiLink,
  Table,
  TableBody,
  TableCell,
  TableRow,
  Tooltip,
} from '@material-ui/core';
import AddIcon from '@material-ui/icons/Add';

import {
  ACTION_BAR_HEIGHT,
  ActionBar,
  ConfidenceWebsiteLink,
  FilterBar,
  FilterListEmptyState,
  FilterListProvider,
  MediumScreenCell,
  PageLayout,
  ResourceTableRow,
  StickyTableContainer,
  StickyTableHead,
  extractLastNameComponent,
  useDialog,
  useListFilter,
  useMeasureDimensions,
} from '@spotify-confidence/core-react';
import {
  getError,
  getTypeOrNull,
  isType,
  useFactTablePageListQuery,
  useListMetricsQuery,
} from '@spotify-confidence/plugin-graphql';
import { useCheckResourcePermissions } from '@spotify-confidence/plugin-permissions-react';

import { Link } from '@backstage/core-components';
import { useRouteRef } from '@backstage/core-plugin-api';

import { factTablesRouteRef, metricRouteRef } from '../../../routes';
import { CreateMetricDialog } from './CreateMetricDialog/CreateMetricDialog';

const MetricsList = ({
  createMetric,
  topOffset = ACTION_BAR_HEIGHT,
  createDisabledReason,
}: {
  createMetric?: () => void;
  topOffset?: number;
  createDisabledReason?: string;
}) => {
  const metricRoute = useRouteRef(metricRouteRef);
  const factTablesRoute = useRouteRef(factTablesRouteRef);

  const { filterString: filter } = useListFilter();

  const {
    data: listMetricsData,
    loading,
    error,
    fetchMore,
  } = useListMetricsQuery({
    variables: {
      filter,
    },
  });

  const listMetricsResponse = getTypeOrNull(
    listMetricsData?.metrics,
    'MetricsV1ListMetricsResponse',
  );
  const metrics = listMetricsResponse?.metrics || [];
  if (!loading && metrics.length === 0) {
    return (
      <FilterListEmptyState
        type="metric"
        error={getError(listMetricsData?.metrics) || error}
        description={
          <>
            Create metrics to aggregate measurements on an entity level. A
            metric requires a{' '}
            <MuiLink component={Link} to={factTablesRoute()} underline="always">
              fact table
            </MuiLink>
            .{' '}
            <ConfidenceWebsiteLink route="/docs/metrics" underline="always">
              Learn more about metrics
            </ConfidenceWebsiteLink>
            .
          </>
        }
        createDisabledReason={createDisabledReason}
        onCreate={createMetric}
      />
    );
  }
  return (
    <StickyTableContainer loading={loading} error={error}>
      <Table>
        <StickyTableHead topOffset={topOffset}>
          <TableRow>
            <TableCell>Name</TableCell>
            <TableCell>Fact table</TableCell>
            <MediumScreenCell>Entity</MediumScreenCell>
          </TableRow>
        </StickyTableHead>
        <TableBody>
          {metrics.map(metric => (
            <ResourceTableRow
              key={metric.name}
              routeRef={metricRouteRef}
              name={metric.name}
            >
              <TableCell>
                <Link
                  to={metricRoute({
                    id: extractLastNameComponent(metric.name)!,
                  })}
                >
                  {metric.displayName}
                </Link>
              </TableCell>
              <TableCell>
                {isType(metric.factTable, 'MetricsV1FactTable')
                  ? metric.factTable.displayName
                  : metric.factTable.message ?? 'Unknown'}
              </TableCell>
              <MediumScreenCell>
                {isType(metric.entity, 'MetricsV1Entity')
                  ? metric.entity.displayName
                  : metric.entity.message ?? 'Unknown'}
              </MediumScreenCell>
            </ResourceTableRow>
          ))}
        </TableBody>
      </Table>
      {listMetricsResponse && (
        <InView
          onChange={async inView => {
            if (inView && listMetricsResponse?.nextPageToken) {
              await fetchMore({
                variables: {
                  pageToken: listMetricsResponse.nextPageToken,
                },
              });
            }
          }}
        />
      )}
    </StickyTableContainer>
  );
};

export const MetricsListPage = () => {
  const { openDialog } = useDialog();
  const [ref, { height }] = useMeasureDimensions<HTMLDivElement>();

  const { allowed } = useCheckResourcePermissions({
    can: 'create_metric',
    name: 'account',
  });
  const { data: factTablesData, loading } = useFactTablePageListQuery();
  const hasFactTable =
    (
      getTypeOrNull(
        factTablesData?.factTables,
        'MetricsV1ListFactTablesResponse',
      )?.factTables ?? []
    ).length !== 0 && !loading;
  const disableMessage = !hasFactTable
    ? 'You need a fact table to create a metric'
    : '';
  function handleCreateMetric() {
    openDialog({
      dialogProps: {
        fullScreen: true,
      },
      content: <CreateMetricDialog />,
    });
  }
  return (
    <FilterWrapper>
      <PageLayout title="Metrics" scrollButton>
        <ActionBar ref={ref}>
          <FilterBar />

          <Tooltip title={disableMessage}>
            <div>
              <Button
                onClick={handleCreateMetric}
                data-testid="create-metric-button"
                variant="contained"
                color="primary"
                size="small"
                startIcon={<AddIcon />}
                disabled={!allowed || !hasFactTable}
              >
                Create
              </Button>
            </div>
          </Tooltip>
        </ActionBar>
        <MetricsList
          createDisabledReason={disableMessage}
          createMetric={allowed ? handleCreateMetric : undefined}
          topOffset={height}
        />
      </PageLayout>
    </FilterWrapper>
  );
};

function FilterWrapper({ children }: React.PropsWithChildren<{}>) {
  return (
    <FilterListProvider
      children={children}
      searchField="displayName"
      storageKey="metricsList"
    />
  );
}
