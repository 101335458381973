import React from 'react';

import { Tooltip, Typography } from '@material-ui/core';

import { DateUtils } from '@spotify-confidence/core-react';
import { MetricScheduleFragment } from '@spotify-confidence/plugin-graphql';
import _ from 'lodash';

import {
  StatusAborted,
  StatusError,
  StatusOK,
  StatusPending,
  StatusRunning,
} from '@backstage/core-components';

import { nextCalculationString } from '../../../MetricsModule/ScheduledMetricCalculationDialog/MetricCalculationsList';

type MetricStatusProps = React.PropsWithChildren<{
  schedule?: MetricScheduleFragment;
}>;

const formatDate = (date: string | number) => {
  return DateUtils.toDateString(date, 'yyyy-MM-dd HH:mm') || '';
};

export const statusInformation = (schedule: MetricScheduleFragment) => {
  const status = schedule.status;
  let tooltip = '';
  if (status.error) {
    tooltip = status.error.errorMessage;
  } else if (status.scheduled) {
    tooltip = `Next scheduled run is at ${formatDate(
      status.scheduled.nextScheduleTime,
    )}`;
  } else if (status.cancelled) {
    tooltip = `The calculation was stopped at ${formatDate(
      status.cancelled.cancelledTime,
    )}.`;
  } else if (status.waiting) {
    tooltip = nextCalculationString(schedule);
  }
  return tooltip;
};

export const MetricStatus = ({ schedule, children }: MetricStatusProps) => {
  const status = schedule?.status;

  if (!status) {
    return <>{children}</>;
  }

  let Status = StatusOK;
  let statusMessage = 'OK';

  if (status.calculating) {
    Status = StatusRunning;
    statusMessage = 'Running';
  } else if (status.error) {
    Status = StatusError;
    statusMessage = 'Error';
  } else if (status.scheduled) {
    Status = StatusPending;
    statusMessage = 'Scheduled';
  } else if (status.cancelled) {
    Status = StatusAborted;
    statusMessage = 'Stopped';
  } else if (status.waiting) {
    Status = StatusPending;
    statusMessage = 'Waiting';

    if (status.waiting.dataDeliveredUntilTime) {
      statusMessage = DateUtils.xAgoShort(
        new Date(status.waiting.dataDeliveredUntilTime),
      );
    }
  }
  return (
    <Tooltip arrow title={statusInformation(schedule)}>
      <div>
        <Status>
          {children || (
            <Typography variant="body2" color="textSecondary" display="inline">
              {statusMessage}
            </Typography>
          )}
        </Status>
      </div>
    </Tooltip>
  );
};
