import React from 'react';

import { Typography } from '@material-ui/core';

import { CodeSnippet, SnippetProps } from '../../CodeSnippet';
import { f } from '../../CodeSnippet';
import { TUTORIAL_FLAG_NAME } from '../constants';

export const GoClientSnippets = (props: SnippetProps) => {
  return (
    <ol>
      <li>
        <Typography variant="body1" gutterBottom>
          Add the Confidence SDK to your project.
        </Typography>
        <CodeSnippet
          language="go"
          text={f(`
go get github.com/spotify/confidence-sdk-go
`)}
          showCopyCodeButton
        />
      </li>
      <li>
        <Typography variant="body1" gutterBottom>
          Initialize the Confidence instance.
        </Typography>
        <CodeSnippet
          language="go"
          text={f(`
import (
    "context"
    "fmt"
    c "github.com/spotify/confidence-sdk-go/pkg/confidence"
)
root_confidence := c.NewConfidenceBuilder().SetAPIConfig(c.APIConfig{APIKey: "${props.apiKey}"}).Build()
`)}
          showCopyCodeButton
        />
      </li>
      <li>
        <Typography variant="body1" gutterBottom>
          Next, we want to set the `visitor_id` context field to
          "tutorial_visitor", to match the targeting criteria pre-configured for
          this tutorial.
        </Typography>
        <CodeSnippet
          language="go"
          text={f(`
confidence := root_confidence.WithContext(map[string]interface{}{"visitor_id": "tutorial_visitor"})
          `)}
          showCopyCodeButton
        />
      </li>
      <li>
        <Typography variant="body1" gutterBottom>
          Resolve the "title" value for the "{TUTORIAL_FLAG_NAME}" flag.
        </Typography>
        <CodeSnippet
          language="go"
          text={f(`
value := confidence.GetStringFlag(context.Background(), "${TUTORIAL_FLAG_NAME}.title", "Default")
fmt.Println(value)
      `)}
          showCopyCodeButton
        />
      </li>
    </ol>
  );
};
