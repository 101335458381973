/* eslint-disable no-nested-ternary, new-cap */
import React from 'react';

import { Collapse, Link, MenuItem, TextField } from '@material-ui/core';
import {
  Alert,
  Autocomplete,
  AutocompleteChangeDetails,
} from '@material-ui/lab';

import {
  ConfidenceAutocomplete,
  ConfidenceWebsiteLink,
  FormFieldsBox,
} from '@spotify-confidence/core-react';
import {
  EntityRowFragment,
  FlagsAdminV1EvaluationContextSchemaFieldKind as Kind,
  MetricsV1ColumnType,
  FlagsAdminV1EvaluationContextFieldOverrideInput as OverrideInput,
  useListEntitiesLazyQuery,
} from '@spotify-confidence/plugin-graphql';
import _ from 'lodash';

import { useRouteRef } from '@backstage/core-plugin-api';

import { entitiesRouteRef } from '../../../../routes';
import * as valueUtils from './valueUtils';

export type SchemaOverrideFormProps = {
  value: OverrideInput;
  onChange: (newValue: OverrideInput) => void;
  existingOverrides?: string[];
};

const getKindFromEntity = (entity?: EntityRowFragment): Kind => {
  if (entity?.primaryKeyType === MetricsV1ColumnType.ColumnTypeBoolean) {
    return Kind.BoolKind;
  } else if (
    entity &&
    [
      MetricsV1ColumnType.ColumnTypeDouble,
      MetricsV1ColumnType.ColumnTypeInt32,
      MetricsV1ColumnType.ColumnTypeInt64,
    ].includes(entity?.primaryKeyType)
  ) {
    return Kind.NumberKind;
  }
  return Kind.StringKind;
};

const FIELD_EXISTS = 'There already exists an override for this field.';

export const SchemaOverrideForm = ({
  value,
  onChange,
  existingOverrides,
}: SchemaOverrideFormProps) => {
  const fieldInvalid = existingOverrides?.includes(value.field);
  const valueType =
    !value.semanticType || !Object.values(value.semanticType).some(Boolean)
      ? value.kind
      : valueUtils.getSemanticValueType(value.semanticType);

  // Field
  const handleFieldChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    onChange({ ...value, field: e.target.value });

    /*
      Currently we only allow adding overrides targeting all clients in the UI, so
      there is no case for adding multiple overrides for the same field.
    */
    if (existingOverrides?.includes(e.target.value)) {
      e.target.setCustomValidity(FIELD_EXISTS);
    } else {
      e.target.setCustomValidity('');
    }
  };

  // Entity semantic type
  const entityValue = value.semanticType?.entityReference?.entity;

  const handleEntityChange = (
    _e: React.ChangeEvent<{}>,
    name: string | null,
    _reason: string,
    details?: AutocompleteChangeDetails<EntityRowFragment>,
  ) => {
    onChange({
      ...value,
      kind: getKindFromEntity(details?.option),
      semanticType: { entityReference: { entity: name || '' } },
    });
  };

  // Enum semantic type
  const enumValue =
    value.semanticType?.enumType?.values.map(v => v.value || '') || [];

  const handleEnumChange = (
    _e: React.ChangeEvent<{}>,
    values: (string | string[])[],
  ) =>
    onChange({
      ...value,
      semanticType: {
        enumType: {
          values: _.uniq(
            values?.map(v => ({
              value: _.isArray(v) ? v[0] : v || '',
            })) || [],
          ),
        },
      },
    });

  const entitiesRoute = useRouteRef(entitiesRouteRef);
  const handleCreateEntity = entitiesRoute
    ? (searchInput?: string) => {
        window.open(
          `${entitiesRoute()}?create=true${
            searchInput ? `&name=${searchInput}` : ''
          }`,
          '_blank',
        );
      }
    : undefined;

  return (
    <FormFieldsBox spacing={2}>
      <TextField
        id="override-field"
        variant="outlined"
        required
        label="Field"
        value={value.field}
        helperText={
          fieldInvalid
            ? FIELD_EXISTS
            : 'The name of the field as it appears in the evaluation context.'
        }
        onChange={handleFieldChange}
        error={fieldInvalid}
        fullWidth
        autoFocus
      />
      <Collapse in={valueType !== 'entityReference'} unmountOnExit>
        <TextField
          id="override-display-name"
          variant="outlined"
          label="Display name"
          helperText="A human friendly name that clarifies what this field represents."
          value={value.displayName || ''}
          onChange={e => onChange({ ...value, displayName: e.target.value })}
          fullWidth
        />
      </Collapse>
      <TextField
        id="override-type"
        variant="outlined"
        label="Value type"
        helperText="The type of value that the field sends in. This is used for validation and decides the input type when configuring targeting."
        required
        select
        value={valueType}
        onChange={e =>
          onChange({
            ...value,
            ...valueUtils.valueTypeMap[e.target.value as valueUtils.ValueType],
            displayName:
              e.target.value === 'entityReference' ? '' : value.displayName,
          })
        }
      >
        {Object.values(Kind).map(kind => (
          <MenuItem key={kind} value={kind}>
            {valueUtils.formatKind(kind)}
          </MenuItem>
        ))}
        {valueUtils.semanticTypeOptions.map(semanticType => (
          <MenuItem key={semanticType} value={semanticType}>
            {valueUtils.formatSemanticType(semanticType)}
          </MenuItem>
        ))}
      </TextField>

      <Collapse in={valueType === 'country'} unmountOnExit>
        <Alert severity="info">
          Sent in values should adhere to the{' '}
          <Link
            underline="always"
            href="https://en.wikipedia.org/wiki/ISO_3166-2"
            target="_blank"
          >
            two letter ISO code
          </Link>
          , for example "SE" for Sweden or "ES" for Spain.
        </Alert>
      </Collapse>

      <Collapse in={valueType === 'entityReference'} unmountOnExit>
        <ConfidenceAutocomplete<EntityRowFragment>
          query={useListEntitiesLazyQuery}
          label="Entity"
          value={entityValue}
          onChange={handleEntityChange}
          required
          autoSelectSingle
          onCreate={handleCreateEntity}
          helperText={
            <>
              Which{' '}
              <ConfidenceWebsiteLink underline="always" route="/docs/entities">
                entity
              </ConfidenceWebsiteLink>{' '}
              this field should map to.
            </>
          }
        />
      </Collapse>

      <Collapse in={valueType === 'enumType'} unmountOnExit>
        <Autocomplete
          freeSolo
          multiple
          value={enumValue}
          onChange={handleEnumChange}
          ChipProps={{ size: 'small' }}
          options={[]}
          autoSelect
          renderInput={params => (
            <TextField
              {...params}
              label="Possible values"
              InputLabelProps={{
                required: true,
              }}
              required={enumValue.length === 0}
              variant="outlined"
              helperText="List the values that can be sent in for this field."
            />
          )}
        />
      </Collapse>
    </FormFieldsBox>
  );
};
