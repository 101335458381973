import React from 'react';
import { Link } from 'react-router-dom';

import {
  Box,
  Button,
  Card,
  CardContent,
  DialogActions,
  DialogContent,
  LinearProgress,
  Link as MuiLink,
  TextField,
  Typography,
} from '@material-ui/core';
import { Alert } from '@material-ui/lab';

import {
  CreatableAutocomplete,
  DialogForm,
  DialogHeader,
  FormSubmitButtons,
  useAlert,
  useDialog,
} from '@spotify-confidence/core-react';
import {
  FlagAppliedConnectionFragment,
  ListEntitiesDocument,
  MetricsV1ColumnType,
  MetricsV1DataDeliveredUntilUpdateStrategyConfigStrategy,
  getTypeOrNull,
  isType,
  useCreateNewAssignmentTableMutation,
  useCreateNewEntityMutation,
  useGetFlagAppliedAssignmentTableQuery,
  useListEntitiesQuery,
} from '@spotify-confidence/plugin-graphql';

import { useRouteRef } from '@backstage/core-plugin-api';

import { assignmentTableRouteRef } from '../../../routes';

type ConnectionAssignmentTableProps = {
  connection: FlagAppliedConnectionFragment;
};

const bigQueryTable = (connection: FlagAppliedConnectionFragment) =>
  [
    connection.bigQuery?.bigQueryConfig?.project,
    connection?.bigQuery?.bigQueryConfig?.dataset,
    connection.bigQuery?.table,
  ].join('.');
const bigQueryAssignmentSql = (connection: FlagAppliedConnectionFragment) =>
  `SELECT targeting_key, rule AS exposure_key, assignment_id, assignment_time FROM \`${bigQueryTable(
    connection,
  )}\``;

const snowflakeTable = (connection: FlagAppliedConnectionFragment) =>
  [
    connection.snowflake?.snowflakeConfig?.database,
    connection?.snowflake?.snowflakeConfig?.schema,
    connection.snowflake?.table,
  ].join('.');
const snowflakeAssignmentSql = (connection: FlagAppliedConnectionFragment) =>
  `SELECT TARGETING_KEY AS "targeting_key", RULE AS "exposure_key", ASSIGNMENT_ID AS "assignment_id", ASSIGNMENT_TIME AS "assignment_time" FROM ${snowflakeTable(
    connection,
  )}`;

const redshiftTable = (connection: FlagAppliedConnectionFragment) =>
  [
    connection.redshift?.redshiftConfig?.database,
    connection?.redshift?.redshiftConfig?.schema,
    connection.redshift?.table,
  ].join('.');
const redshiftAssignmentSql = (connection: FlagAppliedConnectionFragment) =>
  `SELECT targeting_key, rule AS exposure_key, assignment_id, assignment_time FROM ${redshiftTable(
    connection,
  )}`;

const databricksTable = (connection: FlagAppliedConnectionFragment) =>
  [
    connection.databricks?.databricksConfig?.schema,
    connection?.databricks?.table,
  ].join('.');
const databricksAssignmentSql = (connection: FlagAppliedConnectionFragment) =>
  `SELECT targeting_key, rule AS exposure_key, assignment_id, assignment_time FROM ${databricksTable(
    connection,
  )}`;

function CreateAssignmentTableDialog({
  onCreate,
  onClose,
  title,
  creating,
}: {
  onCreate: (name: string, entity: string) => void;
  onClose: () => void;
  title: string;
  creating?: boolean;
}) {
  const alert = useAlert();
  const [name, setName] = React.useState<string>('');
  const [entity, setEntity] = React.useState<string>('');

  const { data, error, loading } = useListEntitiesQuery({
    variables: {
      pageSize: 100,
    },
    fetchPolicy: 'cache-and-network',
  });
  const [createEntity, createEntityState] = useCreateNewEntityMutation();

  const entities =
    getTypeOrNull(data?.entities, 'MetricsV1ListEntitiesResponse')?.entities ??
    [];

  const canCreate = (newName: string) =>
    !entities.some(e => e.displayName === newName);

  const handleChangeEntity = (newValue: string | null) => {
    setEntity(newValue || '');
  };

  const handleCreateEntity = async (newName: string) => {
    const response = await createEntity({
      variables: {
        entity: {
          displayName: newName,
          primaryKeyType: MetricsV1ColumnType.ColumnTypeString,
        },
      },
      awaitRefetchQueries: true,
      refetchQueries: [ListEntitiesDocument],
    });

    // We need to wait for the options to update before setting the value
    // in order for getOptionLabel to render correctly
    if (isType(response.data?.createEntity, 'MetricsV1Entity')) {
      handleChangeEntity(response.data?.createEntity?.name ?? null);
    } else {
      alert.post({
        severity: 'error',
        message: response.data?.createEntity?.message ?? 'Something went wrong',
      });
    }
  };

  const getOptionLabel = React.useCallback(
    (o: string) => entities.find(e => e.name === o)?.displayName || o,
    [entities],
  );

  return (
    <>
      <DialogHeader
        title={title}
        subTitle="Give the table a good name so that you can easily find it later."
      />
      <DialogContent>
        {error && <Alert severity="error">{error.message}</Alert>}
        <DialogForm onSubmit={() => {}}>
          <TextField
            label="Name"
            required
            placeholder="Enter a name for the assignment table"
            onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
              setName(e.target.value)
            }
            value={name}
          />
          <CreatableAutocomplete<string>
            id="entity-autocomplete"
            value={entity}
            onChange={handleChangeEntity}
            onCreate={handleCreateEntity}
            canCreate={canCreate}
            options={entities.map(e => e.name)}
            loading={loading}
            creating={createEntityState.loading}
            getOptionLabel={getOptionLabel}
            TextFieldProps={{
              label: 'Entity',
              variant: 'outlined',
              required: true,
            }}
          />
        </DialogForm>
      </DialogContent>
      <DialogActions>
        <FormSubmitButtons
          onSubmit={() => onCreate(name, entity)}
          onCancel={onClose}
          loading={creating}
        />
      </DialogActions>
    </>
  );
}

export default function ConnectionAssignmentTable({
  connection,
}: ConnectionAssignmentTableProps) {
  const { openDialog, closeDialog } = useDialog();
  const assignmentTableRoute = useRouteRef(assignmentTableRouteRef);

  const assignmentTableSQLFilter = React.useMemo(() => {
    if (connection?.bigQuery) {
      return bigQueryTable(connection);
    }
    if (connection?.snowflake) {
      return snowflakeTable(connection);
    }
    if (connection?.redshift) {
      return redshiftTable(connection);
    }
    if (connection?.databricks) {
      return databricksTable(connection);
    }
    return '';
  }, [connection]);

  const {
    data: existingTables,
    loading: loadingExistingTables,
    refetch,
  } = useGetFlagAppliedAssignmentTableQuery({
    variables: { filter: `sql:*"${assignmentTableSQLFilter}"*` },
    skip: !assignmentTableSQLFilter,
  });

  const assignmentTables =
    getTypeOrNull(
      existingTables?.assignmentTables,
      'MetricsV1ListAssignmentTablesResponse',
    )?.assignmentTables || [];

  const [createAssignmentTable, createTableState] =
    useCreateNewAssignmentTableMutation({
      onCompleted: () => {
        refetch();
        closeDialog();
      },
    });

  const onCreate = (sql: string) => (displayName: string) => {
    createAssignmentTable({
      variables: {
        assignmentTable: {
          dataDeliveredUntilUpdateStrategyConfig: {
            automaticUpdateConfig: {
              commitDelay: '300s',
            },
            strategy:
              MetricsV1DataDeliveredUntilUpdateStrategyConfigStrategy.Automatic,
          },
          displayName,
          sql,
          entityColumn: {
            name: 'targeting_key',
          },
          exposureKeyColumn: {
            name: 'exposure_key',
          },
          timestampColumn: {
            name: 'assignment_time',
          },
          variantKeyColumn: {
            name: 'assignment_id',
          },
        },
      },
    });
  };

  const handleCreateAssignmentTable = React.useCallback(
    (sql: string) => {
      openDialog({
        content: (
          <CreateAssignmentTableDialog
            title="Create assignment table"
            onClose={closeDialog}
            onCreate={onCreate(sql)}
            creating={createTableState.loading}
          />
        ),
      });
    },
    [closeDialog, createAssignmentTable, openDialog],
  );

  const handleCreate = React.useCallback(() => {
    if (connection?.bigQuery) {
      const sql = bigQueryAssignmentSql(
        connection as FlagAppliedConnectionFragment,
      );
      return handleCreateAssignmentTable(sql);
    }
    if (connection?.snowflake) {
      const sql = snowflakeAssignmentSql(
        connection as FlagAppliedConnectionFragment,
      );
      return handleCreateAssignmentTable(sql);
    }
    if (connection?.redshift) {
      const sql = redshiftAssignmentSql(
        connection as FlagAppliedConnectionFragment,
      );
      return handleCreateAssignmentTable(sql);
    }
    if (connection?.databricks) {
      const sql = databricksAssignmentSql(
        connection as FlagAppliedConnectionFragment,
      );
      return handleCreateAssignmentTable(sql);
    }
    return undefined;
  }, [connection, handleCreateAssignmentTable]);

  return (
    <Card>
      <CardContent>
        {loadingExistingTables && <LinearProgress />}
        {assignmentTables.length > 0 ? (
          <Box padding={2}>
            <Typography variant="body2" color="textSecondary">
              Assignment table
            </Typography>
            {assignmentTables.map(table => {
              const [_type, tableId] = table.name.split('/');
              return (
                <Typography key={table.name}>
                  <MuiLink
                    component={Link}
                    to={assignmentTableRoute?.({ id: tableId }) || ''}
                  >
                    {table.displayName}
                  </MuiLink>
                </Typography>
              );
            })}
          </Box>
        ) : (
          handleCreate && (
            <Box padding={2}>
              <Typography variant="subtitle1">Assignment table</Typography>
              <Typography>
                Create an assignment table to use this flag applied connection
                as an assignment source for experiment exposure.
              </Typography>
              <Box marginTop={2}>
                <Button
                  variant="outlined"
                  onClick={handleCreate}
                  disabled={!handleCreate}
                >
                  Create
                </Button>
              </Box>
            </Box>
          )
        )}
      </CardContent>
    </Card>
  );
}
