import React from 'react';

import { DialogActions, Grid, MenuItem, TextField } from '@material-ui/core';
import { Alert } from '@material-ui/lab';

import {
  DialogBody,
  DialogForm,
  DialogHeader,
  DisabledSpan,
  FormFieldsBox,
  FormSubmitButtons,
  useAlert,
  useDialog,
} from '@spotify-confidence/core-react';
import {
  EntitiesDocument,
  MetricsV1ColumnType,
  MetricsV1EntityInput,
  isType,
  useCreateEntityMutation,
} from '@spotify-confidence/plugin-graphql';
import _ from 'lodash';

import { mapKeyTypeLabel } from '../../../domain/useFormattedEntityType.hook';

type CreateEntityProps = {
  onCreate?: (newEntityName?: string) => void;
  displayName?: string;
};

const ENTITY_TYPES = [
  MetricsV1ColumnType.ColumnTypeString,
  MetricsV1ColumnType.ColumnTypeInt32,
  MetricsV1ColumnType.ColumnTypeInt64,
  MetricsV1ColumnType.ColumnTypeDouble,
  MetricsV1ColumnType.ColumnTypeBytes,
];

export const CreateEntity = ({ onCreate, displayName }: CreateEntityProps) => {
  const { closeDialog } = useDialog();
  const alert = useAlert();
  const [mutation, { loading, error }] = useCreateEntityMutation({
    onCompleted: response => {
      if (isType(response.createEntity, 'MetricsV1Entity')) {
        closeDialog();
        onCreate?.(response.createEntity?.name);
      } else {
        alert.post({
          severity: 'error',
          message: response.createEntity?.message ?? 'Something went wrong',
        });
      }
    },
    refetchQueries: [EntitiesDocument],
  });

  const [entity, setEntity] = React.useState<Partial<MetricsV1EntityInput>>({
    displayName: displayName ?? '',
    primaryKeyType: MetricsV1ColumnType.ColumnTypeString,
  });

  async function handleSubmit(e: React.FormEvent<HTMLFormElement>) {
    e.preventDefault();
    mutation({
      variables: {
        entity: entity as MetricsV1EntityInput,
      },
    });
  }

  return (
    <DialogForm onSubmit={handleSubmit} data-testid="create-entity-form">
      <DialogHeader title="Create new entity" />
      <DialogBody>
        {error && (
          <Grid item xs={12}>
            <Alert severity="error">{error.message}</Alert>
          </Grid>
        )}
        <FormFieldsBox>
          <Grid container spacing={2}>
            <Grid item xs={6}>
              <TextField
                variant="outlined"
                fullWidth
                required
                label="Display name"
                name="displayName"
                autoFocus
                value={entity.displayName}
                onChange={e =>
                  setEntity(v => ({ ...v, displayName: e.target.value }))
                }
                data-testid="display-name"
              />
            </Grid>
            <Grid item xs={6}>
              <TextField
                label="Primary key type"
                name="primary-key-type"
                variant="outlined"
                required
                fullWidth
                select
                onChange={e => {
                  setEntity(v => ({
                    ...v,
                    primaryKeyType: e.target.value as MetricsV1ColumnType,
                  }));
                }}
                value={entity.primaryKeyType ?? ''}
                SelectProps={{ displayEmpty: true }}
                InputLabelProps={{
                  shrink: true,
                }}
                data-testid="primary-key-type"
              >
                <MenuItem disabled value="">
                  <DisabledSpan>Select a key type</DisabledSpan>
                </MenuItem>
                {ENTITY_TYPES.map(option => (
                  <MenuItem key={option} value={option}>
                    {mapKeyTypeLabel(option)}
                  </MenuItem>
                ))}
              </TextField>
            </Grid>
          </Grid>
        </FormFieldsBox>
      </DialogBody>
      <DialogActions>
        <FormSubmitButtons
          label="Create"
          loading={loading}
          onCancel={closeDialog}
        />
      </DialogActions>
    </DialogForm>
  );
};
