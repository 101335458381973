import React from 'react';

import { Box, makeStyles } from '@material-ui/core';

import { Table } from '@tanstack/react-table';

import { ColumnSelect } from '../../ColumnSelect';
import { GroupingSelect } from '../../GroupingSelect';
import { RelativeToggle } from '../../RelativeToggle';
import { VisualizationSelect } from '../../VisualizationSelect';
import { ResultVisualizationType } from '../Visualizations';
import { ResultColumnType } from '../columns';
import {
  GroupableColumn,
  MetricResultTableGrouping,
  ResultData,
} from '../types';
import { ToggleGroupMenu } from './ToggleGroupMenu';

type ResultTableControlsProps = {
  table: Table<ResultData>;
  grouping?: MetricResultTableGrouping;
  sequential?: boolean;
  visibleColumns?: string[];
  columns?: ResultColumnType[];
  handleVisibleColumnsChange?: (val: string[]) => void;
  showRelativeChanges?: boolean;
  handleShowRelativeChange?: (val: boolean) => void;
  visualizationType?: ResultVisualizationType;
  handleVisualizationTypeChange?: (val: ResultVisualizationType) => void;
  handleGroupingChange?: (val: MetricResultTableGrouping) => void;
  groupingOptions?: MetricResultTableGrouping[];
};

const useControlsStyles = makeStyles(theme => ({
  row: {
    display: 'flex',
    alignItems: 'center',
    gridGap: theme.spacing(1),
    flexWrap: 'wrap',
  },
}));

export const ResultTableControls = ({
  table,
  grouping = [],
  visibleColumns = [],
  sequential,
  handleVisibleColumnsChange: handleColumnsChange,
  showRelativeChanges,
  handleShowRelativeChange,
  visualizationType = 'none',
  handleVisualizationTypeChange,
  handleGroupingChange,
  groupingOptions,
  columns,
}: ResultTableControlsProps) => {
  const classes = useControlsStyles();

  return (
    <Box
      justifyContent="space-between"
      marginTop={2}
      marginBottom={2}
      className={classes.row}
    >
      <Box className={classes.row}>
        {handleShowRelativeChange && (
          <RelativeToggle
            value={showRelativeChanges ? 'relative' : 'absolute'}
            onChange={value => handleShowRelativeChange(value === 'relative')}
          />
        )}
        {visualizationType !== 'none' &&
          grouping.map(group => (
            <ToggleGroupMenu
              key={group}
              rows={table.getGroupedRowModel().rows}
              groupId={group as GroupableColumn}
            />
          ))}
      </Box>
      <Box className={classes.row}>
        {handleVisualizationTypeChange && (
          <VisualizationSelect
            sequential={sequential}
            value={visualizationType}
            onChange={handleVisualizationTypeChange}
          />
        )}
        {handleGroupingChange && (
          <GroupingSelect
            value={grouping}
            onChange={handleGroupingChange}
            groupingOptions={groupingOptions}
            columns={columns}
          />
        )}
        {handleColumnsChange && (
          <ColumnSelect
            sequential={sequential}
            value={visibleColumns}
            onChange={handleColumnsChange}
            disabledColumns={[grouping[0]]}
            columns={columns}
          />
        )}
      </Box>
    </Box>
  );
};
