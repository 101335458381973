import { IdentityFragment } from '@spotify-confidence/plugin-graphql';

export const identityType = (identity: IdentityFragment) => {
  if (Boolean(identity.user)) {
    return 'User';
  }
  if (Boolean(identity.apiClient)) {
    return 'API Client';
  }
  if (Boolean(identity.group)) {
    return 'Group';
  }
  if (identity.everyone) {
    return 'Everyone';
  }

  return 'Unknown';
};
