import React from 'react';

import { Box, MenuList, Popover, makeStyles } from '@material-ui/core';

const useStyles = makeStyles(theme => ({
  popover: {
    zIndex: theme.zIndex.modal,
  },
}));

export type ContextMenuProps = {
  renderButton: (p: {
    id?: string;
    onClick: (event: React.MouseEvent<Element>) => void;
  }) => React.ReactNode;
  renderMenu?: () => React.ReactNode;
  renderContent?: () => React.ReactNode;
  closeOnSelect?: boolean;
  id?: string;
};
export const ContextMenu = ({
  renderButton,
  renderMenu,
  renderContent,
  closeOnSelect = false,
  id,
}: ContextMenuProps) => {
  const classes = useStyles();
  const [anchorEl, setAnchorEl] = React.useState<Element | null>(null);
  const open = Boolean(anchorEl);

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleToggle = (event: React.MouseEvent<Element>) => {
    if (anchorEl) {
      handleClose();
    } else {
      setAnchorEl(event.currentTarget);
    }
  };

  return (
    <>
      {renderButton({ onClick: handleToggle, id: `${id}-button` })}
      <Popover
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        role={undefined}
        disablePortal
        className={classes.popover}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
      >
        {renderMenu ? (
          <MenuList
            disablePadding
            dense
            autoFocusItem={open}
            id={`${id}-dropdown`}
            onClick={closeOnSelect ? handleClose : undefined}
          >
            {renderMenu()}
          </MenuList>
        ) : (
          <Box padding={1} autoFocus={open}>
            {renderContent?.() ?? null}
          </Box>
        )}
      </Popover>
    </>
  );
};
