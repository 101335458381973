import React, { PropsWithChildren } from 'react';
import { InView } from 'react-intersection-observer';

import { useAnalytics } from '@backstage/core-plugin-api';

export function ImpressionTracker({
  subject,
  children,
}: PropsWithChildren<{ subject: string }>) {
  const analytics = useAnalytics();

  const onChange = (inView: boolean) => {
    if (inView) analytics.captureEvent('impression', subject);
  };

  return (
    <>
      <InView onChange={onChange} />
      {children}
    </>
  );
}
