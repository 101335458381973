import React from 'react';

import { MetricsV1ColumnType } from '@spotify-confidence/plugin-graphql';
import _ from 'lodash';

export const mapKeyTypeLabel = (type: MetricsV1ColumnType) => {
  const prefix = 'COLUMN_TYPE_';
  return _.capitalize(
    type.startsWith(prefix) ? type.substring(prefix.length) : type,
  );
};

export const useFormattedEntityType = (type: MetricsV1ColumnType) =>
  React.useMemo(() => mapKeyTypeLabel(type), [type]);
