import React from 'react';

import { Checkbox, FormControlLabel, FormHelperText } from '@material-ui/core';

import { MetricsV1MetricNullHandlingConfigInput } from '@spotify-confidence/plugin-graphql';

type MissingValuesFormProps = {
  value?: MetricsV1MetricNullHandlingConfigInput | null;
  onChange: (newValue: MetricsV1MetricNullHandlingConfigInput) => void;
  disabled?: boolean;
};

export function MissingValuesForm({
  value,
  onChange,
  disabled,
}: MissingValuesFormProps) {
  const handleChange = (
    nullHandling: Partial<MetricsV1MetricNullHandlingConfigInput>,
  ) => {
    onChange({
      ...value,
      ...nullHandling,
    });
  };

  return (
    <>
      <FormControlLabel
        disabled={disabled}
        control={
          <Checkbox
            checked={Boolean(value?.replaceEntityNullWithZero)}
            name="entity-padding-checkbox"
            onChange={e => {
              const isEnabled = e.target.checked;
              handleChange({
                replaceEntityNullWithZero: isEnabled,
              });
            }}
          />
        }
        label="Replace missing values with zero"
      />
      <FormHelperText>
        If selected, the average will be calculated by replacing missing values
        with zero instead of excluding them.
      </FormHelperText>
    </>
  );
}
