import { StringUtils } from '@spotify-confidence/core-react';
import {
  ConnectorsV1EventConnectionBigQueryEventConnectionConfigInput as BigQueryEventConnectionInput,
  ConnectorsV1FlagAppliedConnectionBigQueryFlagAppliedConnectionConfigInput as BigQueryFlagAppliedInput,
  ConnectorsV1BigQueryConfigInput,
} from '@spotify-confidence/plugin-graphql';
import _ from 'lodash';

export const getBigQueryConfigValidationErrors = ({
  serviceAccount,
}: Partial<ConnectorsV1BigQueryConfigInput> = {}) => {
  const validationErrors: Record<
    keyof Omit<ConnectorsV1BigQueryConfigInput, 'proto_type_url'>,
    string[]
  > = {
    serviceAccount: [],
    project: [],
    dataset: [],
  };
  if (serviceAccount && !StringUtils.isValidEmail(serviceAccount)) {
    validationErrors.serviceAccount.push(
      'The service account should be a valid email address.',
    );
  }
  return validationErrors;
};

export const isBigQueryConfigValid = (
  config: Partial<ConnectorsV1BigQueryConfigInput> = {},
) => {
  return (
    Boolean(config.serviceAccount && config.project && config.dataset) &&
    Object.values(getBigQueryConfigValidationErrors(config)).every(_.isEmpty)
  );
};

export const defaultBigQueryConfig: ConnectorsV1BigQueryConfigInput = {
  project: '',
  dataset: '',
  serviceAccount: '',
};

export const defaultBigQueryFlagAppliedInput: BigQueryFlagAppliedInput = {
  bigQueryConfig: defaultBigQueryConfig,
  table: '',
};

export const defaultBigQueryEventInput: BigQueryEventConnectionInput = {
  bigQueryConfig: defaultBigQueryConfig,
  tablePrefix: '',
};
