import React from 'react';

import { Box, BoxProps, useTheme } from '@material-ui/core';

import { ChipInput } from '../ChipInput';
import { SharedInputProps } from './types';

const DotCharBox = ({
  count,
  color,
  ...rest
}: { count: number; color: string } & BoxProps) => {
  const content = new Array(count).fill('•').join('');
  const theme = useTheme();

  return (
    <Box
      position="absolute"
      fontFamily="monospace"
      padding={`${theme.spacing(1)}px 0px`}
      style={{
        color,
      }}
      {...rest}
    >
      {content}
    </Box>
  );
};

export const ChipTextInput = ({
  value,
  valueType = 'String',
  onChange,
  readOnly = false,
  disabled = false,
  InputProps,
}: SharedInputProps) => {
  const theme = useTheme();

  const placeholder = valueType;
  const $value = (value?.toString() ?? '') as string;

  let width = `${$value.length}ch`;

  if (['Number', 'Version'].includes(valueType)) {
    // add extra space to fit the stepper
    width = `${$value.length + 2}ch`;
  }

  if (!value) {
    // fit the placeholder
    width = `${placeholder.length}ch`;
  }

  // index of first non-space character in the string
  const leading = $value.search(/\S|$/);
  const trailing = $value.length - $value.trimEnd().length;
  const noContent = $value.trim() === '';

  return (
    <Box position="relative" maxWidth="100%">
      {leading > 0 && (
        <DotCharBox
          count={leading}
          left={theme.spacing(1)}
          color={theme.palette.error.main}
        />
      )}
      {!noContent && trailing > 0 && (
        <DotCharBox
          count={trailing}
          right={theme.spacing(1)}
          color={theme.palette.error.main}
        />
      )}
      <ChipInput
        margin="dense"
        disabled={disabled}
        type={valueType === 'Number' ? 'number' : 'text'}
        value={value}
        placeholder={placeholder}
        onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
          onChange(e.target.value)
        }
        InputProps={InputProps}
        inputProps={{
          readOnly: readOnly,
          style: {
            width,
            fontFamily: 'monospace',
            maxWidth: '100%',
          },
        }}
      />
    </Box>
  );
};
