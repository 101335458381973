import React from 'react';
import { useAsyncFn } from 'react-use';

import {
  Box,
  Button,
  CircularProgress,
  Collapse,
  MenuItem,
  TextField,
} from '@material-ui/core';

import {
  ListClientCredentialsDocument,
  getError,
  getTypeOrNull,
  isError,
  useCreateClientCredentialMutation,
  useCreateClientMutation,
} from '@spotify-confidence/plugin-graphql';

import { useConnect } from './context';
import { useSortedClients } from './useSortedClients';

function CreateClient() {
  const [displayName, setDisplayName] = React.useState('');

  const [createClient] = useCreateClientMutation({
    refetchQueries: ['ListClients'],
    onCompleted: result => {
      if (isError(result.createClient)) {
        throw getError(result.createClient);
      }
    },
  });

  const [createCredentials] = useCreateClientCredentialMutation({
    refetchQueries: [ListClientCredentialsDocument],
    onCompleted: result => {
      if (isError(result.createClientCredential)) {
        throw getError(result.createClientCredential);
      }
    },
  });

  const [{ error }, handleCreate] = useAsyncFn(async () => {
    // We need a name.
    if (displayName === '') throw new Error('Must provide a display name.');

    const result = await createClient({
      variables: {
        client: {
          displayName,
        },
      },
    });

    const newClient = getTypeOrNull(result.data?.createClient, 'IamV1Client');
    if (newClient)
      await createCredentials({
        variables: {
          parent: newClient?.name,
          clientCredential: {
            displayName: 'Default',
          },
        },
      });
  }, [displayName]);

  return (
    <Box display="flex" flexDirection="column" alignItems="flex-start">
      <Box display="flex" gridGap={8} alignItems="center">
        <TextField
          variant="outlined"
          placeholder="For example: Website"
          autoFocus
          value={displayName}
          onChange={e => setDisplayName(e.target.value)}
          error={Boolean(error)}
          label="Client"
          helperText={
            error?.message ||
            'There are no clients. Create a new client to get started.'
          }
          fullWidth
        />
        <Button
          variant="contained"
          color="secondary"
          onClick={handleCreate}
          size="small"
        >
          Create
        </Button>
      </Box>
    </Box>
  );
}

export function SelectClient() {
  const { clients, loading } = useSortedClients();
  const { client, setClient } = useConnect();

  const isEmpty = clients.length === 0 && !loading;

  if (loading) return <CircularProgress />;

  return (
    <div>
      <Collapse in={isEmpty}>
        <CreateClient />
      </Collapse>
      <Collapse in={!isEmpty}>
        <TextField
          label="Client"
          select
          value={client?.name ?? ''}
          onChange={e => {
            const selectedClient = clients.find(c => c.name === e.target.value);
            if (selectedClient) {
              setClient(selectedClient);
            }
          }}
          inputProps={{
            name: 'client',
            id: 'client-select',
          }}
          helperText="A client represents a product or repository that should be able to resolve a flag. Select the client that you want to connect to Confidence."
          fullWidth
          variant="outlined"
        >
          {clients.map(c => (
            <MenuItem key={c.name} value={c.name}>
              {c.displayName}
            </MenuItem>
          ))}
        </TextField>
      </Collapse>
    </div>
  );
}
