import { useAsyncFn } from 'react-use';

export type HubspotField = {
  objectTypeId?: string;
  name: string;
  value: string;
};
export const useHubspotForm = ({
  portalId,
  formId,
}: {
  portalId: string;
  formId: string;
}) => {
  return useAsyncFn((fields: HubspotField[]) =>
    fetch(
      `https://api.hsforms.com/submissions/v3/integration/submit/${portalId}/${formId}`,
      {
        method: 'post',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          submittedAt: new Date().getTime(),
          fields,
          context: {
            pageUri: window.location.href,
            pageName: 'Confidence App',
          },
          legalConsentOptions: {
            consent: {
              // Include this object when GDPR options are enabled
              consentToProcess: true,
              text: 'I agree to allow Spotify AB to store and process my personal data.',
              communications: [
                {
                  value: true,
                  subscriptionTypeId: 999,
                  text: 'I agree to receive marketing communications from Spotify.',
                },
              ],
            },
          },
        }),
      },
    ),
  );
};
