import React, { useEffect, useState } from 'react';
import { useAsyncFn } from 'react-use';

import {
  DialogActions,
  DialogContent,
  DialogContentText,
} from '@material-ui/core';

import {
  DialogForm,
  DialogHeader,
  FormSubmitButtons,
  useAlert,
} from '@spotify-confidence/core-react';
import { SurfaceFragment } from '@spotify-confidence/plugin-graphql';
import { SurfaceAutocomplete } from '@spotify-confidence/plugin-surfaces';

const GLOBAL_SURFACE = 'surfaces/global';

export const SurfacesDialog = ({
  onSubmit,
  onClose,
  surfaces: initialSurfaces,
}: {
  surfaces: SurfaceFragment[];
  onSubmit: (surfaces: SurfaceFragment[]) => Promise<void>;
  onClose: () => void;
}) => {
  const alert = useAlert();

  const [surfaces, setSurfaces] = useState<SurfaceFragment[]>(initialSurfaces);

  const [{ error, loading }, submit] = useAsyncFn(async () => {
    await onSubmit(surfaces);
  }, [onSubmit, JSON.stringify(surfaces.map(s => s.name))]);

  useEffect(() => {
    if (error) {
      alert.post({
        message: error.message,
        severity: 'error',
      });
    }
  }, [error, alert]);

  // Workaround to handle instances that was created before global surfaces were introduced.
  const hasGlobalSurface = initialSurfaces.some(
    s => s.name === 'surfaces/global',
  );

  return (
    <DialogForm
      onSubmit={e => {
        e.preventDefault();
        submit();
      }}
    >
      <DialogHeader title="Surfaces" onClose={onClose} />
      <DialogContent>
        <DialogContentText>
          Select surfaces which user experience that this workflow instance
          impacts, or surfaces that you need to coordinate with for some other
          reason.
        </DialogContentText>
        <SurfaceAutocomplete
          multiple
          value={surfaces}
          mandatorySurfaces={hasGlobalSurface ? [GLOBAL_SURFACE] : []}
          onChange={(_e, newSurfaces, reason) => {
            if (hasGlobalSurface && reason === 'clear') {
              setSurfaces(surfaces.filter(s => s.name === GLOBAL_SURFACE));
            } else {
              setSurfaces(newSurfaces);
            }
          }}
          textFieldProps={{
            variant: 'outlined',
            margin: 'dense',
          }}
          disableClearable={
            hasGlobalSurface &&
            surfaces.length === 1 &&
            surfaces[0].name === GLOBAL_SURFACE
          }
        />
      </DialogContent>
      <DialogActions>
        <FormSubmitButtons onCancel={onClose} loading={loading} label="Save" />
      </DialogActions>
    </DialogForm>
  );
};
