import React, { forwardRef } from 'react';

import {
  Card,
  CardContent,
  Theme,
  WithStyles,
  createStyles,
  withStyles,
} from '@material-ui/core';

import classNames from 'classnames';

export interface RecommendationCardProps extends WithStyles<typeof styles> {
  className?: string;
  variant?: 'success' | 'error' | 'info' | 'warning' | 'none';
  children?: React.ReactNode;
}

const styles = (theme: Theme) =>
  createStyles<string, Pick<RecommendationCardProps, 'variant'>>({
    root: ({ variant = 'none' }) => ({
      position: 'relative',
      /*
      background: ({ variant = 'info' }) =>
        `linear-gradient(175deg, ${alpha(
          theme.palette[variant].main,
          0.2,
        )}, ${lighten(theme.palette.background.paper, 0.05)})`, */
      ...(variant !== 'none' && {
        '&:before': {
          position: 'absolute',
          content: '""',
          top: 0,
          left: '0%',
          width: '80%',
          height: 100,
          background: theme.palette[variant].main,
          transform: 'skewX(-40deg)',
          filter: 'blur(100px)',
        },
      }),
    }),
    content: {
      position: 'relative',
      display: 'flex',
      flexDirection: 'row',
      gap: theme.spacing(2),

      [theme.breakpoints.down('md')]: {
        flexDirection: 'column',
        alignItems: 'stretch',
      },
    },
  });

const RecommendationCard = forwardRef<HTMLDivElement, RecommendationCardProps>(
  function RecommendationCardRoot({ className, children, classes }, ref) {
    return (
      <Card ref={ref} className={classNames(className, classes.root)}>
        <CardContent className={classes.content}>{children}</CardContent>
      </Card>
    );
  },
);

export default withStyles(styles)(RecommendationCard);
