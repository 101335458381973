import React from 'react';

import { ListItem, ListItemProps, styled } from '@material-ui/core';

const DefaultListItem = ({ button, ...props }: ListItemProps) => (
  <ListItem
    ContainerComponent="div"
    button={button as any} // TS issue: https://github.com/mui/material-ui/issues/14971
    {...props}
  />
);

export const SelectListItem = styled(DefaultListItem)(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  flexWrap: 'nowrap',
  justifyContent: 'space-between',
  paddingRight: 0,
  gap: theme.spacing(1),
}));
