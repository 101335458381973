import React from 'react';

import {
  MetricScheduleFragment,
  getTypeOrNull,
  useGetMetricSchedulesQuery,
} from '@spotify-confidence/plugin-graphql';

import { Metric, MetricsData } from './types';

export const useMetricsWithSchedule = (metricsData?: MetricsData) => {
  const validMetrics = metricsData?.metrics?.filter(m => m.schedule) ?? [];
  const { data: scheduleData } = useGetMetricSchedulesQuery({
    variables: {
      filter: validMetrics.length
        ? `name:(${validMetrics.map(m => `"${m.schedule}" `)})`
        : '',
    },
    skip: !metricsData?.metrics?.length,
  });

  return React.useMemo(() => {
    return (
      metricsData?.metrics?.map(m => {
        const metric: Metric & { scheduleData?: MetricScheduleFragment } = {
          ...m,
        };
        const schedule = (
          getTypeOrNull(
            scheduleData?.scheduledMetricCalculations,
            'MetricsV1ListScheduledMetricCalculationsResponse',
          )?.scheduledMetricCalculations ?? []
        )?.find(s => s.name === m.schedule);
        if (schedule) {
          metric.scheduleData = schedule;
        }
        return metric;
      }) || []
    );
  }, [metricsData, scheduleData]);
};
