import React from 'react';

import SvgIcon, { SvgIconProps } from '@material-ui/core/SvgIcon';

export const RolloutIcon = (props: SvgIconProps) =>
  React.createElement(
    SvgIcon,
    props,
    <>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M20 0.982849C20 0.722168 19.8946 0.472198 19.7071 0.287872C19.5196 0.103546 19.2652 0 19 0C18.7348 0 18.4804 0.103546 18.2929 0.287872L0.287868 18.2929C0.10355 18.4804 0 18.7348 0 19C0 19.2652 0.10355 19.5196 0.287868 19.7071C0.472187 19.8947 0.722179 20 0.982845 20L20 20L20 18L20 0.982849ZM18 18V3.5L12 9.5V18H18Z"
      />
    </>,
  );
