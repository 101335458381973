import React from 'react';

import {
  Box,
  Collapse,
  Grow,
  IconButton,
  Typography,
  alpha,
  makeStyles,
  useTheme,
} from '@material-ui/core';
import Edit from '@material-ui/icons/Edit';
import ExpandLess from '@material-ui/icons/ExpandLess';
import ExpandMore from '@material-ui/icons/ExpandMore';

import {
  CommentZone,
  CommentZoneContext,
} from '@spotify-confidence/plugin-comments-react';
import classNames from 'classnames';

const useStyles = makeStyles(theme => {
  const stickyBackground = alpha(theme.palette.background.default, 0.8);
  return {
    stickyHeader: {
      position: 'sticky',
      top: theme.spacing(1),
      left: 0,
      right: 0,
      zIndex: theme.zIndex.speedDial,
      background: stickyBackground,
      '&:after, &:before': {
        height: theme.spacing(1),
        width: '100%',
        background: stickyBackground,
        position: 'absolute',
        left: 0,
        right: 0,
        content: '""',
      },
      '&:after': {
        bottom: -theme.spacing(1),
      },
      '&:before': {
        top: -theme.spacing(1),
      },
    },
  };
});

export type SectionProps = React.PropsWithChildren<{
  label: React.ReactNode;
  actions?: React.ReactNode;
  leftActions?: React.ReactNode;
  expanded?: boolean;
  onChange?: (expanded: boolean) => void;
  onCollapse?: () => void;
  disableToggle?: boolean;
  onEditContent?: () => void;
  disableEdit?: boolean;
  stickyHeader?: boolean;
  zoneContext?: string;
}>;

export const Section = ({
  label,
  actions,
  expanded,
  onChange,
  onEditContent,
  disableToggle,
  disableEdit,
  leftActions,
  stickyHeader,
  zoneContext,
  children,
}: SectionProps) => {
  const classes = useStyles();
  const theme = useTheme();
  const canToggle = !disableToggle && !!onChange;

  return (
    <CommentZoneContext
      zone={zoneContext ?? label?.toLocaleString() ?? 'unknown'}
    >
      <Box
        display="flex"
        flexDirection="column"
        gridGap={theme.spacing(1)}
        marginBottom={4}
        position="relative"
        data-testid={`section-${label}`}
      >
        <Box
          display="flex"
          gridGap={theme.spacing(1)}
          alignItems="center"
          className={classNames({
            [classes.stickyHeader]: stickyHeader,
          })}
        >
          <Typography variant="h5">
            <CommentZone id="title" component="span">
              {label}
            </CommentZone>
          </Typography>
          {canToggle && (
            <div>
              <IconButton size="small" onClick={() => onChange(!expanded)}>
                {expanded ? <ExpandLess /> : <ExpandMore />}
              </IconButton>
            </div>
          )}
          <Grow in={!!leftActions}>
            <Box
              flexGrow="1"
              display="flex"
              alignItems="center"
              marginLeft="auto"
              gridGap={theme.spacing(2)}
            >
              {leftActions}
            </Box>
          </Grow>
          {(actions || onEditContent) && (
            <Grow in={canToggle ? expanded : true}>
              <Box
                flexGrow="1"
                display="flex"
                justifyContent="flex-end"
                alignItems="center"
                marginLeft="auto"
                gridGap={theme.spacing(1)}
              >
                {actions}
                {!disableEdit && onEditContent && (
                  <IconButton
                    disabled={disableEdit}
                    size="small"
                    onClick={onEditContent}
                  >
                    <Edit fontSize="small" />
                  </IconButton>
                )}
              </Box>
            </Grow>
          )}
        </Box>
        <Collapse in={canToggle ? expanded : true}>{children}</Collapse>
      </Box>
    </CommentZoneContext>
  );
};
