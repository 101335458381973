import React from 'react';

import {
  Box,
  Chip,
  ListItemText,
  ListItemTextProps,
  Theme,
  Typography,
  createStyles,
  withStyles,
} from '@material-ui/core';

import { ResourceLink } from '@spotify-confidence/core-react';
import { getTypeOrNull } from '@spotify-confidence/plugin-graphql';

import { entityRouteRef } from '../../../../routes';
import { findSchemaType } from '../../../domain/schema.helpers';
import { Entry } from '../../../domain/schema.hooks';
import { TypeLabel } from '../TypeLabel';

const styles = (theme: Theme) =>
  createStyles({
    primary: {
      color: 'inherit',
      display: 'flex',
      alignItems: 'center',
      gap: theme.spacing(1),
    },
    secondary: {
      paddingLeft: theme.spacing(3.5),
    },
  });

export interface SchemaEntryDetailsProps extends ListItemTextProps {
  entry: Entry;
}

const SchemaEntryDetails = function SchemaEntryDetails({
  children,
  classes,
  entry,
}: SchemaEntryDetailsProps) {
  const { key, value } = entry;
  const { schemaType: type, semanticType } = findSchemaType(value);

  const enumValue =
    semanticType === 'enumType'
      ? (value?.stringSchema?.semanticType?.enumType?.values ?? []).map(
          v => v.value || '',
        )
      : undefined;

  const entityValue =
    semanticType === 'entityReference'
      ? getTypeOrNull(
          value?.stringSchema?.semanticType?.entityReference?.entity,
          'MetricsV1Entity',
        )
      : null;

  const displayName =
    entityValue?.displayName || entry.value?.[type]?.displayName;
  const description = entry.value?.[type]?.description;
  return (
    <ListItemText
      classes={classes}
      primary={
        <>
          <TypeLabel onlyIcon type={type} semanticType={semanticType} />

          {displayName ? (
            <>
              {entityValue ? (
                <ResourceLink
                  routeRef={entityRouteRef}
                  name={entityValue.name}
                  target="_blank"
                >
                  {displayName}
                </ResourceLink>
              ) : (
                displayName
              )}{' '}
              <Typography
                variant="inherit"
                component="span"
                color="textSecondary"
              >
                ({key})
              </Typography>
            </>
          ) : (
            key
          )}

          {children}
        </>
      }
      secondary={
        <>
          {description}
          {enumValue && (
            <Box display="flex" marginTop={1} flexWrap="wrap" gridGap="0.5em">
              {enumValue.map(v => (
                <Chip key={v} size="small" label={v} />
              ))}
            </Box>
          )}
        </>
      }
    />
  );
};

export default withStyles(styles)(SchemaEntryDetails);
