import React from 'react';
import { useParams } from 'react-router-dom';
import { useEffectOnce } from 'react-use';

import {
  AuthenticationProvider,
  LoadingOverlay,
} from '@spotify-confidence/core-react';

import { AccountCreated } from './AccountCreated';
import { CreateAccountForm } from './CreateAccountForm';
import { InviteError } from './InviteError';
import { CreatedAccount, useInvite } from './hooks/useInvite';

const CLIENT_ID = '82qMvwZvqd3t3S0gRDvs8R53TehQXSJY';

export const SignUpPage = () => {
  const [token, setToken] = React.useState<string>();
  const [account, setAccount] = React.useState<CreatedAccount>();
  const { id: inviteId } = useParams();
  const [{ error: inviteError, loading, value: inviteData }, getAccountInvite] =
    useInvite(inviteId);

  useEffectOnce(() => {
    (async () => {
      try {
        if (inviteId) {
          const invite = await getAccountInvite();
          setAccount(invite.createdAccount);
        }
      } catch (error) {
        console.error(error);
      }
    })();
  });

  const accountDataAvailable = !loading && !!account;

  const renderContent = () => {
    if (loading) {
      return null;
    }
    if (inviteError) {
      return <InviteError error={inviteError} />;
    }
    if (accountDataAvailable) {
      return <AccountCreated createdAccount={account} />;
    }
    return (
      <AuthenticationProvider
        clientId={CLIENT_ID}
        onTokenReceived={t => setToken(t)}
      >
        <CreateAccountForm
          inviteId={inviteId}
          token={token}
          onCreate={setAccount}
          skipCompanyWizard={inviteData?.warehouseNative ?? false}
        />
      </AuthenticationProvider>
    );
  };

  return (
    <>
      <LoadingOverlay loading={loading} />
      {renderContent()}
    </>
  );
};
