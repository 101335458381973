import { FlagsTypesV1FlagSchema } from '@spotify-confidence/plugin-graphql';
import _ from 'lodash';

import { assignmentsModel } from '../../rule';

export const parseValue = (
  type: keyof FlagsTypesV1FlagSchema,
  value: any | undefined,
) => {
  if (type === 'boolSchema') {
    if (value === 'true') {
      return true;
    } else if (value === 'false') {
      return false;
    }
    return undefined;
  }

  if (type === 'intSchema') {
    const parsed = parseInt(value, 10);
    return !isNaN(parsed) ? parsed : undefined;
  }

  if (type === 'doubleSchema') {
    const parsed = parseFloat(value);
    return !isNaN(parsed) ? parsed : undefined;
  }

  if (type === 'stringSchema') {
    return value ? String(value) : undefined;
  }

  return value;
};

export const getVariantWeightAsPercent = (
  weight: number,
  bucketCount?: number | null,
) =>
  _.round(
    (weight / (bucketCount || assignmentsModel.DEFAULT_BUCKET_COUNT)) * 100,
    2,
  );
