import { track } from '@spotify-internal/track-js';

export const loadTrackJS = () => {
  track({
    oneTrustDomainScript: '50da44be-0564-43df-b139-329aedcf267b',
    googleTagManagerId: 'GTM-M4VP8GN',
    language: 'en',
    market: 'not_set',
    oneTrust: true,
  });
};
