import React from 'react';

import { Box, Chip, IconButton, Tooltip, Typography } from '@material-ui/core';
import ExpandLess from '@material-ui/icons/ExpandLess';
import ExpandMore from '@material-ui/icons/ExpandMore';
import ShowChart from '@material-ui/icons/ShowChart';

import {
  DateUtils,
  NumberUtils,
  Numeral,
  TooltipToggleButton,
} from '@spotify-confidence/core-react';
import {
  moduleHelpers,
  useWorkflowInstanceContext,
} from '@spotify-confidence/plugin-workflows';
import {
  StatsData,
  TestHorizonStrategy,
} from '@spotify-confidence/plugin-workflows-module-stats';
import {
  CellContext,
  ColumnDef,
  GroupColumnDef,
  createColumnHelper,
} from '@tanstack/react-table';
import _ from 'lodash';

import { BucketingTemporalUnit } from '../../../../MetricsModule';
import { getMethodDescription } from '../../../../MetricsModule/MetricCardV2';
import { useMetricDetails } from '../../../../MetricsModule/MetricDetailsProvider';
import { MetricSubheader } from '../../../../MetricsModule/MetricSubheader';
import ConfidenceIntervalCell, { BaseTooltip } from '../ConfidenceIntervalCell';
import { getGroupsFromGroupComparisonId } from '../utils';
import { AnnotationsSummary } from './AnnotationsSummary';
import { InlineTreatmentComparison } from './InlineTreatmentComparison';
import { MetricName } from './MetricName';
import { StatusMessage } from './StatusMessage';
import { VariantValue } from './VariantValue';
import * as tableUtils from './tableUtils';
import { ResultData } from './types';

export const columnHelper = createColumnHelper<ResultData>();

// types needed because there is an issue when generating types definitions
// `columns` has or is using name X from external module @tanstack/table-core but cannot be named
export type ResultColumnType = ColumnDef<ResultData>;

export function isGroupColumn(
  column: ResultColumnType,
): column is GroupColumnDef<ResultData> {
  return !!(column as GroupColumnDef<ResultData>).columns;
}

function NumeralCell({
  value,
  relative,
  showDirection,
}: {
  showDirection?: boolean;
  value?: number | null;
  relative?: boolean;
}) {
  const prefix = showDirection && value && value > 0 ? '+' : '';
  return (
    <Numeral
      prefix={prefix}
      value={value ?? undefined}
      formatter={NumberUtils.formatNumberFixed2}
      suffix={relative ? '%' : ''}
    />
  );
}

export function getMeta(props: CellContext<ResultData, any>) {
  return props.table.options?.meta;
}

export function isHeader(props: CellContext<ResultData, any>) {
  return props.column.id === props.table.getState().grouping[0];
}

export const GraphToggleColumn = columnHelper.display({
  id: 'Graph',
  enableHiding: true,
  meta: { align: 'center' },
  header: ({ table }) => {
    // Workaround: https://github.com/TanStack/table/issues/4349
    const toggleAllRows = () => {
      const allSelected = table.getIsAllRowsSelected();
      for (const row of table.getRowModel().flatRows) {
        row.toggleSelected(!allSelected);
      }
    };
    return (
      <TooltipToggleButton
        value="toggle-all"
        TooltipProps={{
          title: 'Toggle all entries in visualization',
        }}
        selected={table.getIsAllRowsSelected()}
        onChange={toggleAllRows}
        size="small"
      >
        <ShowChart fontSize="small" />
      </TooltipToggleButton>
    );
  },
  cell: ({ row }) => {
    let title = 'Toggle ';
    if (row.subRows.length > 0) {
      title += 'all child entries ';
    } else {
      title += 'this entry ';
    }
    title += 'in visualization';
    return (
      <TooltipToggleButton
        value="toggle-row"
        TooltipProps={{ title }}
        selected={row.getIsSelected()}
        disabled={!row.getCanSelect()}
        onChange={row.getToggleSelectedHandler()}
        size="small"
      >
        <ShowChart fontSize="small" />
      </TooltipToggleButton>
    );
  },
});

export const MetricColumn = columnHelper.accessor('metric', {
  header: 'Metric',
  enableHiding: false,
  cell: props => {
    const metricDetails = useMetricDetails();
    const metric = metricDetails.get(props.getValue());

    return (
      <>
        <MetricName
          name={props.row.original.metric}
          variant={isHeader(props) ? 'h6' : 'body1'}
        />
        {metric && (
          <MetricSubheader
            metric={metric}
            poweredEffect={props.row.original.poweredEffectRel}
            requiredSampleSize={props.row.original.requiredSampleSize}
            currentSampleSize={props.row.original.currentSampleSize}
          />
        )}
      </>
    );
  },
}) as ResultColumnType;

const formatSegmentValue = (value: string | undefined) => {
  if (value === undefined) {
    return 'No value';
  } else if (value === '') {
    return 'Empty string';
  }

  return value;
};

export const SegmentColumn = columnHelper.accessor('segment', {
  id: 'segment',
  header: 'Segment',
  cell: props => {
    const dimensions: Record<string, string> =
      props.row.original.dimensions || {};

    if (Object.keys(dimensions).length === 0) {
      return 'All';
    }

    return (
      <Box display="flex" flexWrap="wrap" gridGap={4}>
        {Object.keys(dimensions)
          .sort()
          .map(dimension => (
            <Tooltip
              key={dimension + dimensions[dimension]}
              title={_.upperFirst(dimensions[dimension])}
              arrow
            >
              <Chip
                size="small"
                label={formatSegmentValue(dimensions[dimension])}
              />
            </Tooltip>
          ))}
      </Box>
    );
  },
}) as ResultColumnType;

export const ComparisonColumn = columnHelper.accessor('comparison', {
  header: 'Comparison',
  enableHiding: true,
  cell: props => {
    return (
      <InlineTreatmentComparison
        {...getGroupsFromGroupComparisonId(props.getValue())}
        variant={isHeader(props) ? 'h6' : 'body1'}
        color="textPrimary"
      />
    );
  },
}) as ResultColumnType;

export const BaselineMeanColumn = columnHelper.accessor(
  row => ({
    baseline: row.result?.groupMeanAbs.baseline,
    baselineGroupId: row.baselineGroupId,
    baselineSampleSize: row.baselineSampleSize,
    isFraction: row.isFraction,
    originalBaseline: row.result?.unadjustedGroupMeanAbs?.baseline,
  }),
  {
    id: 'baselineMean',
    enableGrouping: false,
    enableHiding: true,
    header: 'Baseline mean',
    aggregationFn: tableUtils.aggregateToLatestValue,
    cell: props => {
      const row = props.getValue();
      return (
        <VariantValue
          variant={row.baselineGroupId}
          value={row.baseline}
          sampleSize={row.baselineSampleSize}
          isFraction={row.isFraction}
          originalValue={row.originalBaseline}
        />
      );
    },
  },
) as ResultColumnType;

export const ComparisonMeanColumn = columnHelper.accessor(
  row => ({
    compared: row.result?.groupMeanAbs.compared,
    comparedGroupId: row.comparedGroupId,
    comparedSampleSize: row.comparedSampleSize,
    isFraction: row.isFraction,
    originalCompared: row.result?.unadjustedGroupMeanAbs?.compared,
  }),
  {
    id: 'comparedMean',
    enableGrouping: false,
    enableHiding: true,
    header: 'Compared mean',
    aggregationFn: tableUtils.aggregateToLatestValue,
    cell: props => {
      const row = props.getValue();
      return (
        <VariantValue
          variant={row.comparedGroupId}
          value={row.compared}
          sampleSize={row.comparedSampleSize}
          isFraction={row.isFraction}
          originalValue={row.originalCompared}
        />
      );
    },
  },
) as ResultColumnType;

export const MeanColumnGroup = columnHelper.group({
  id: 'Mean',
  enableGrouping: false,
  enableHiding: true,
  header: 'Mean',
  columns: [BaselineMeanColumn, ComparisonMeanColumn],
});

export const BaselineSampleSize = columnHelper.accessor(
  row => row.baselineSampleSize,
  {
    id: 'baselineSampleSize',
    enableGrouping: false,
    enableHiding: true,
    header: 'Baseline sample size',
    aggregationFn: tableUtils.aggregateToLatestValue,
    cell: props => (
      <VariantValue
        variant={props.row.original.baselineGroupId}
        value={props.getValue()}
        isInteger
      />
    ),
  },
) as ResultColumnType;

export const ComparedSampleSize = columnHelper.accessor(
  row => row.comparedSampleSize,
  {
    id: 'comparedSampleSize',
    enableGrouping: false,
    enableHiding: true,
    header: 'Compared sample size',
    aggregationFn: tableUtils.aggregateToLatestValue,
    cell: props => (
      <VariantValue
        variant={props.row.original.comparedGroupId}
        value={props.getValue()}
        isInteger
      />
    ),
  },
) as ResultColumnType;

// fix time series

export const SampleSizeColumnGroup = columnHelper.group({
  id: 'Sample size',
  enableGrouping: false,
  enableHiding: true,
  header: 'Sample size',
  columns: [BaselineSampleSize, ComparedSampleSize],
});

export const ChangeValueColumn = columnHelper.accessor(
  row => row.result?.estimateRel.estimate,
  {
    enableGrouping: false,
    enableHiding: true,
    id: 'changeValue',
    header: 'Value',
    meta: {
      width: 100,
    },
    cell: props => {
      const row = tableUtils.getLatestValue(props.row);

      return (
        <NumeralCell
          showDirection
          value={
            getMeta(props)?.showRelativeChanges
              ? row.result?.estimateRel.estimate
              : row.result?.estimateAbs.estimate
          }
          relative={getMeta(props)?.showRelativeChanges}
        />
      );
    },
  },
) as ResultColumnType;

const ConfidenceIntervalColumn = columnHelper.accessor(
  row => row.result?.estimateRel.estimate,
  {
    id: 'ci',
    enableGrouping: false,
    enableHiding: true,
    header: 'Confidence Interval',
    meta: {
      align: 'center',
      width: 130,
      height: 35,
      padding: 'none',
    },
    cell: props => {
      const row = tableUtils.getLatestValue(props.row);
      if (row.result?.estimateRel.estimate === undefined) {
        return null;
      }
      return (
        <ConfidenceIntervalCell
          width={130}
          height={35}
          mde={row.mde}
          nim={row.nim}
          upperCI={row.result?.estimateRel.upper}
          lowerCI={row.result?.estimateRel.lower}
          observedDifference={row.result?.estimateRel.estimate}
          minLower={getMeta(props)?.minLower}
          maxUpper={getMeta(props)?.maxUpper}
          significant={row.isSignificant}
          direction={row.direction}
          tooltipContent={
            <>
              <BaseTooltip
                upperCI={
                  getMeta(props)?.showRelativeChanges
                    ? row.result?.estimateRel.upper
                    : row.result?.estimateAbs.upper
                }
                lowerCI={
                  getMeta(props)?.showRelativeChanges
                    ? row.result?.estimateRel.lower
                    : row.result?.estimateAbs.lower
                }
                unit={getMeta(props)?.showRelativeChanges ? '%' : ''}
                formatter={
                  getMeta(props)?.showRelativeChanges
                    ? NumberUtils.formatNumberFixed2
                    : NumberUtils.formatNumberFixed4
                }
              />
              {!_.isNil(row.nim) && row.nim !== 0 && (
                <Typography>
                  <strong>NIM:</strong>{' '}
                  <Numeral
                    value={row.nim}
                    formatter={NumberUtils.formatNumberFixed2}
                    suffix="%"
                  />
                </Typography>
              )}
              {!_.isNil(row.mde) && row.mde !== 0 && (
                <Typography>
                  <strong>MDE:</strong>{' '}
                  <Numeral
                    value={row.mde}
                    formatter={NumberUtils.formatNumberFixed2}
                    suffix="%"
                  />
                </Typography>
              )}
            </>
          }
        />
      );
    },
  },
) as ResultColumnType;

export const ChangeColumnGroup = columnHelper.group({
  id: 'change',
  enableGrouping: false,
  enableHiding: true,
  header: 'Change',
  columns: [ChangeValueColumn, ConfidenceIntervalColumn],
});

export const LowerBoundColumn = columnHelper.accessor(
  row => row.result?.estimateRel.lower,
  {
    enableGrouping: false,
    enableHiding: true,
    id: 'lowerBound',
    header: 'Lower bound',
    meta: {
      align: 'right',
    },
    cell: props => {
      const row = tableUtils.getLatestValue(props.row);
      return (
        <NumeralCell
          showDirection
          value={
            getMeta(props)?.showRelativeChanges
              ? row.result?.estimateRel.lower
              : row.result?.estimateAbs.lower
          }
          relative={getMeta(props)?.showRelativeChanges}
        />
      );
    },
  },
) as ResultColumnType;

export const UpperBoundColumn = columnHelper.accessor(
  row => row.result?.estimateRel.upper,
  {
    enableGrouping: false,
    enableHiding: true,
    id: 'upperBound',
    header: 'Upper bound',
    meta: {
      align: 'right',
    },
    cell: props => {
      const row = tableUtils.getLatestValue(props.row);
      return (
        <NumeralCell
          showDirection
          value={
            getMeta(props)?.showRelativeChanges
              ? row.result?.estimateRel.upper
              : row.result?.estimateAbs.upper
          }
          relative={getMeta(props)?.showRelativeChanges}
        />
      );
    },
  },
) as ResultColumnType;

export const VarianceReductionRateColumn = columnHelper.accessor(
  row => row.result?.varianceReductionRate,
  {
    enableGrouping: false,
    enableHiding: true,
    id: 'varianceReductionRate',
    header: 'Variance reduction',
    meta: {
      align: 'right',
    },
    cell: props => {
      const row = tableUtils.getLatestValue(props.row);
      return (
        <NumeralCell
          value={
            row.result?.varianceReductionRate
              ? 100 * row.result?.varianceReductionRate
              : undefined
          }
          relative
        />
      );
    },
  },
) as ResultColumnType;

export const AlphaColumn = columnHelper.accessor(
  row => row.statsSettings?.adjustedAlpha,
  {
    enableGrouping: false,
    enableHiding: true,
    id: 'adjustedAlpha',
    header: 'Adjusted alpha',
    meta: {
      align: 'right',
    },
    cell: props => {
      return (
        <NumeralCell
          value={
            props.row.original.statsSettings?.adjustedAlpha
              ? 100 * props.row.original.statsSettings?.adjustedAlpha
              : undefined
          }
          relative
        />
      );
    },
  },
) as ResultColumnType;

export const PowerColumn = columnHelper.accessor(
  row => row.statsSettings?.adjustedBeta,
  {
    enableGrouping: false,
    enableHiding: true,
    id: 'adjustedBeta',
    header: 'Adjusted power',
    meta: {
      align: 'right',
    },
    cell: props => {
      return (
        <NumeralCell
          value={
            props.row.original.statsSettings?.adjustedBeta
              ? 100 * (1 - props.row.original.statsSettings?.adjustedBeta)
              : undefined
          }
          relative
        />
      );
    },
  },
) as ResultColumnType;

export const MethodColumn = columnHelper.accessor(row => row.result?.method, {
  id: 'method',
  enableGrouping: false,
  enableHiding: true,
  header: 'Method',
  aggregationFn: tableUtils.aggregateToLatestValue,
  cell: props => {
    return <>{getMethodDescription(props.getValue() ?? 'unknown')}</>;
  },
}) as ResultColumnType;

export const TimeLabelColumn = columnHelper.accessor(
  row => row.result?.timeLabel,
  {
    enableGrouping: false,
    enableHiding: true,
    id: 'timeLabel',
    header: 'Time',
    aggregationFn: tableUtils.aggregateToLatestValue,
    cell: props => {
      return (
        DateUtils.toDateString(
          props.getValue() || '',
          getMeta(props)?.bucket === BucketingTemporalUnit.HOURS
            ? tableUtils.DATETIME_FORMAT
            : tableUtils.DATE_FORMAT,
        ) ?? null
      );
    },
  },
) as ResultColumnType;

export const StatusColumn = columnHelper.accessor(
  row => ({
    isSignificant: row.isSignificant,
    nim: row.nim,
    mde: row.mde,
  }),
  {
    enableGrouping: false,
    enableHiding: true,
    id: 'Status',
    meta: {
      width: 200,
    },
    cell: props => {
      const { state, moduleData } = useWorkflowInstanceContext();
      const isSequential =
        moduleHelpers.getModuleData<StatsData>(moduleData, 'stats')
          ?.testHorizonStrategy !== TestHorizonStrategy.FIXED_HORIZON;
      const row = tableUtils.getLatestValue(props.row);
      const errors =
        row?.annotations?.filter(annotation => annotation.error) ?? [];
      const hasRun = row?.metricDetails?.hasRun ?? false;
      const hasError = errors.length > 0;

      const isLiveFixedHorizon = state === 'live' && !isSequential;
      let noData = 'No data';
      if (!hasRun) {
        noData = 'Waiting for data';
      } else if (hasError) {
        noData = 'Error';
      } else if (isLiveFixedHorizon) {
        noData = 'Waiting for end';
      }

      return (
        <Box display="flex" justifyContent="space-between" gridGap={8}>
          <StatusMessage
            direction={row.direction}
            isSignificant={
              row.isSignificant === null ? undefined : row.isSignificant
            }
            nim={row.nim}
            undesiredReason={row.undesiredReason}
            message={_.isNil(row.isSignificant) ? noData : undefined}
          />
          <AnnotationsSummary annotations={row.annotations} />
        </Box>
      );
    },
  },
) as ResultColumnType;

export const ExpandColumn = columnHelper.display({
  id: 'Expand',
  enableHiding: false,
  enableGrouping: false,
  meta: {
    disableComments: true,
  },
  cell: props =>
    props.row.getCanExpand() && props.row.subRows.length > 1 ? (
      <IconButton
        size="small"
        color="default"
        onClick={props.row.getToggleExpandedHandler()}
      >
        {props.row.getIsExpanded() ? <ExpandLess /> : <ExpandMore />}
      </IconButton>
    ) : null,
});

export const defaultDetailedColumns: ResultColumnType[] = [
  GraphToggleColumn,
  MetricColumn,
  ComparisonColumn,
  ChangeColumnGroup,
  LowerBoundColumn,
  UpperBoundColumn,
  TimeLabelColumn,
  MethodColumn,
  AlphaColumn,
  PowerColumn,
  VarianceReductionRateColumn,
  StatusColumn,
  ExpandColumn,
];

export const defaultColumns: ResultColumnType[] = [
  GraphToggleColumn,
  MetricColumn,
  ComparisonColumn,
  MeanColumnGroup,
  ChangeColumnGroup,
  LowerBoundColumn,
  UpperBoundColumn,
  SampleSizeColumnGroup,
  TimeLabelColumn,
  MethodColumn,
  AlphaColumn,
  PowerColumn,
  VarianceReductionRateColumn,
  StatusColumn,
  ExpandColumn,
];

export type ColumnOption = {
  id: string;
  name: string;
  children?: ColumnOption[];
  disabled?: boolean;
};

export function getColumnOption(column: any): ColumnOption {
  return {
    id: column.id || column.accessorKey,
    name: _.isString(column.header)
      ? column.header
      : column.id || column.accessorKey,
    disabled: !column.enableHiding,
    children: column.columns?.map(getColumnOption),
  };
}

export const defaultColumnOptions = defaultColumns.map(getColumnOption);
