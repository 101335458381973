import React, { useEffect } from 'react';
import { useAsyncFn } from 'react-use';

import { DialogActions, ListItemText, TextField } from '@material-ui/core';
import { Alert, Autocomplete } from '@material-ui/lab';

import {
  DialogBody,
  DialogForm,
  DialogHeader,
  FormFieldsBox,
  FormSubmitButtons,
  useDialog,
} from '@spotify-confidence/core-react';
import {
  IdentityFragment,
  getError,
  getTypeOrNull,
  useIdentitiesQuery,
} from '@spotify-confidence/plugin-graphql';
import { IdentityUtils } from '@spotify-confidence/plugin-permissions-react';

import { useGroupMembers } from '../../hooks/useGroupMembers';

export function AddMembersDialog({
  name,
  onAdd,
}: {
  name: string;
  onAdd: (identities: string[]) => Promise<void>;
}) {
  const { closeDialog } = useDialog();
  const [members, setMembers] = React.useState<IdentityFragment[]>([]);

  const { members: existing, error: memberError } = useGroupMembers(name);

  const {
    data,
    loading,
    error: identitiesError,
    fetchMore,
  } = useIdentitiesQuery();
  const { identities, nextPageToken: pageToken } = getTypeOrNull(
    data?.identities,
    'IamV1ListIdentitiesResponse',
  ) || {
    identities: [],
  };

  const listIdentitiesError = getError(data?.identities);

  useEffect(() => {
    if (pageToken) {
      fetchMore({
        variables: {
          pageToken,
        },
      });
    }
  }, [pageToken]);

  const [{ loading: adding, error: addError }, handleAdd] =
    useAsyncFn(async () => {
      await onAdd(members.map(identity => identity.name));
    }, [members, onAdd]);

  const error = identitiesError ?? memberError ?? addError;

  return (
    <DialogForm
      onSubmit={e => {
        e.preventDefault();
        handleAdd();
      }}
      data-testid="add-group-members-dialog"
    >
      <DialogHeader title="Add members" />
      <DialogBody>
        {error && <Alert severity="error">{error.message}</Alert>}
        {listIdentitiesError && (
          <Alert severity="error">{listIdentitiesError.message}</Alert>
        )}
        <FormFieldsBox spacing={1}>
          <Autocomplete
            multiple
            options={identities}
            getOptionLabel={option => option.displayName}
            onChange={(_e, value) => setMembers(value)}
            getOptionDisabled={option =>
              getTypeOrNull(option.group, 'IamV1Group')?.name === name ||
              members.some(m => m.name === option.name) ||
              existing.some(m => m.name === option.name)
            }
            renderOption={option => (
              <ListItemText
                primary={option.displayName}
                secondary={IdentityUtils.getIdentityDescription(option)}
              />
            )}
            renderInput={params => (
              <TextField
                {...params}
                variant="outlined"
                label="Members"
                fullWidth
                placeholder="Search for members"
              />
            )}
          />
        </FormFieldsBox>
      </DialogBody>
      <DialogActions>
        <FormSubmitButtons
          onCancel={closeDialog}
          loading={adding}
          disabled={loading || adding || members.length === 0}
          label="Add"
        />
      </DialogActions>
    </DialogForm>
  );
}
