import React from 'react';

import { useDialog } from '@spotify-confidence/core-react';
import { WorkflowInstanceCheck } from '@spotify-confidence/plugin-workflows';
import _ from 'lodash';

import { MetricDetailsProvider } from './MetricDetailsProvider';
import { MetricsModuleConfiguratorDialog } from './MetricsModuleConfiguratorDialog';
import {
  MetricType,
  MetricsData,
  MetricsModuleConfigContextType,
  MetricsModuleConfiguratorDialogProps,
} from './types';
import { useMetricsModuleConfigState } from './useMetricsModuleConfigState';
import { useMetricsWithSchedule } from './useMetricsWithSchedule';

const MetricsModuleConfigContext = React.createContext<
  Partial<MetricsModuleConfigContextType>
>({
  metricsWithSchedule: [],
  guardrailOnly: false,
});

export const MetricsModuleConfigProvider = ({
  children,
  metricsData,
  workflowInstanceName,
  guardrailOnly,
  checks = [],
}: React.PropsWithChildren<{
  workflowInstanceName?: string;
  guardrailOnly?: boolean;
  metricsData?: MetricsData;
  checks?: WorkflowInstanceCheck[];
}>) => {
  const { openDialog } = useDialog();

  const metricsWithSchedule = useMetricsWithSchedule(metricsData);
  const metricsConfigState = useMetricsModuleConfigState({
    metricsData,
    workflowInstanceName,
  });

  const handleAddMetric = ({
    currentStepDefault,
    metricType,
  }: Partial<MetricsModuleConfiguratorDialogProps> & {
    metricType?: MetricType;
  } = {}) => {
    const props = _.pick(metricsConfigState, [
      'addMetric',
      'saveConfig',
      'entity',
      'assignmentTable',
      'existingMetrics',
      'metricsData',
    ]) as MetricsModuleConfiguratorDialogProps;

    openDialog({
      content: (
        <MetricsModuleConfiguratorDialog
          {...props}
          currentStepDefault={currentStepDefault}
          metricType={metricType}
        />
      ),
    });
  };

  const value = {
    ...metricsConfigState,
    guardrailOnly,
    handleAddMetric,
    metricsWithSchedule,
  };

  return (
    <MetricsModuleConfigContext.Provider value={value}>
      <MetricDetailsProvider
        metrics={metricsWithSchedule ?? []}
        checks={checks}
      >
        {children}
      </MetricDetailsProvider>
    </MetricsModuleConfigContext.Provider>
  );
};

export const useMetricsModuleConfigProviderContext = () => {
  const context = React.useContext(
    MetricsModuleConfigContext,
  ) as MetricsModuleConfigContextType;

  if (!context) {
    throw new Error('Missing MetricsModuleConfigProvider.');
  }

  return context;
};
