import React from 'react';
import { useSearchParams } from 'react-router-dom';

import {
  endOfDay,
  endOfMonth,
  formatISO,
  startOfDay,
  startOfMonth,
  startOfYear,
  subDays,
  subMonths,
} from 'date-fns';

export type DateRange = {
  start: string;
  end: string;
};

export const useDateRange = ({
  onInvalid = () => {},
}: {
  onInvalid?: () => void;
}) => {
  const [searchParams] = useSearchParams();
  const rangeType = (searchParams.get('range') as string) || 'currentMonth';
  const customStartDate = searchParams.get('start') as string | undefined;
  const customEndDate = searchParams.get('end') as string | undefined;

  const dateRange = React.useMemo(() => {
    const customRange =
      customStartDate && customEndDate
        ? {
            start: customStartDate,
            end: customEndDate,
          }
        : undefined;
    if (!customRange && rangeType === 'custom') {
      onInvalid();
    }
    return selectedValueToDateRange(rangeType, customRange);
  }, [rangeType, customStartDate, customEndDate, onInvalid]);
  return { rangeType, dateRange };
};

function selectedValueToDateRange(
  value: string,
  custom?: DateRange,
): DateRange {
  if (value === 'custom' && custom) {
    return {
      start: formatISO(startOfDay(new Date(custom.start))),
      end: formatISO(endOfDay(new Date(custom.end))),
    };
  }

  if (value === 'previousMonth') {
    const date = subMonths(new Date(), 1);
    return {
      start: formatISO(startOfMonth(date)),
      end: formatISO(endOfMonth(date)),
    };
  }
  if (value === 'last30Days') {
    const date = startOfDay(new Date());
    return {
      start: formatISO(subDays(date, 30)),
      end: formatISO(date),
    };
  }
  if (value === 'last90Days') {
    const date = startOfDay(new Date());
    return {
      start: formatISO(subDays(date, 90)),
      end: formatISO(date),
    };
  }

  if (value === 'yearToDate') {
    const date = new Date();
    return {
      start: formatISO(startOfYear(date)),
      end: formatISO(endOfDay(date)),
    };
  }

  const date = new Date();
  return {
    start: formatISO(startOfMonth(date)),
    end: formatISO(endOfMonth(date)),
  };
}
