import React from 'react';

import {
  Checkbox,
  Collapse,
  FormHelperText,
  TextField,
  Typography,
  makeStyles,
} from '@material-ui/core';

import {
  MetricsV1ValueCap,
  MetricsV1ValueCapInput,
} from '@spotify-confidence/plugin-graphql';
import _ from 'lodash';

const useStyles = makeStyles(theme => ({
  wrapper: {
    display: 'grid',
    columnGap: theme.spacing(2),
    alignItems: 'flex-start',
    gridTemplateColumns: 'max-content 1fr',
  },
  label: {
    cursor: 'pointer',
  },
  capInput: {
    gridRow: 2,
    gridColumn: 2,
  },
  alignedFields: {
    display: 'flex',
    marginTop: theme.spacing(1),
    gap: theme.spacing(2),
  },
  checkboxRoot: {
    padding: 0,
  },
}));

type ValueCapInputProps = {
  value?: MetricsV1ValueCap | null;
  valueLabel: string;
  onChange: (newValue: MetricsV1ValueCap | null) => void;
  entityName?: string;
  disabled?: boolean;
  valueCap?: MetricsV1ValueCapInput;
  short?: boolean;
};

export function ValueCapInput({
  value,
  valueLabel,
  onChange,
  entityName,
  disabled,
  short,
}: ValueCapInputProps) {
  const [error, setError] = React.useState<string | undefined>(undefined);
  const [minValue, setMinValue] = React.useState<string>(
    value?.min?.value ?? '',
  );
  const [maxValue, setMaxValue] = React.useState<string>(
    value?.max?.value ?? '',
  );

  const classes = useStyles();
  const useCap = Boolean(value);
  const minValid = !isNaN(parseFloat(minValue));
  const maxValid = !isNaN(parseFloat(maxValue));

  const onMinChange = React.useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      const newMinStr = e.target.value as string;
      setMinValue(newMinStr);

      const curMax = parseFloat(maxValue ?? '');
      const newMin = parseFloat(newMinStr);
      if (newMinStr !== '' && newMinStr !== '-' && isNaN(newMin)) {
        setError('Minimum value must be a valid number');
      } else if (!isNaN(curMax) && !isNaN(newMin) && newMin >= curMax) {
        setError('Minimum value must be smaller than maximum value');
      } else {
        onChange({
          min: newMinStr !== '' ? { value: newMinStr } : undefined,
          max: value?.max,
        });
        setError(undefined);
      }
    },
    [maxValue],
  );

  const onMaxChange = React.useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      const newMaxStr = e.target.value as string;
      setMaxValue(newMaxStr);

      const curMin = parseFloat(minValue ?? '');
      const newMax = parseFloat(newMaxStr);
      if (newMaxStr !== '' && newMaxStr !== '-' && isNaN(newMax)) {
        setError('Maximum value must be a valid number');
      } else if (!isNaN(curMin) && !isNaN(newMax) && newMax <= curMin) {
        setError('Maximum value must be larger than minimum value');
      } else {
        onChange({
          min: value?.min,
          max: newMaxStr !== '' ? { value: newMaxStr } : undefined,
        });
        setError(undefined);
      }
    },
    [minValue],
  );

  return (
    <div className={classes.wrapper}>
      <Checkbox
        classes={{
          root: classes.checkboxRoot,
        }}
        disabled={disabled}
        checked={useCap}
        name={`use-cap-checkbox-${valueLabel}`}
        id={`use-cap-checkbox-${valueLabel}`}
        onChange={e => {
          const isEnabled = e.target.checked;
          onChange(
            isEnabled
              ? {
                  min: { value: '0' },
                  max: { value: '100' },
                }
              : null,
          );
        }}
      />
      <label
        className={classes.label}
        htmlFor={`use-cap-checkbox-${valueLabel}`}
      >
        <Typography variant="body2" gutterBottom>
          Cap the {valueLabel} after aggregation on {entityName || 'entity'}
          -level.
        </Typography>
        <FormHelperText>
          Setting a minimum or maximum value will ensure that no value (after
          aggregation) for a single entity will go outside of those bounds.
          {short ? (
            ''
          ) : (
            <>
              {' '}
              This is useful to prevent outliers that would impact the metric
              unexpectedly. For example, a user cannot consume more than 24
              hours worth of music in a single day.
            </>
          )}
        </FormHelperText>
      </label>
      <Collapse in={useCap} unmountOnExit className={classes.capInput}>
        <div className={classes.alignedFields}>
          <TextField
            variant="outlined"
            margin="dense"
            label="Minimum value"
            name="min-field"
            value={minValue}
            onChange={onMinChange}
            type="number"
            disabled={disabled}
            error={!!error}
          />
          <TextField
            variant="outlined"
            margin="dense"
            label="Maximum value"
            name="min-field"
            value={maxValue}
            onChange={onMaxChange}
            type="number"
            disabled={disabled}
            error={!!error}
          />
        </div>
        {error && (
          <FormHelperText error={Boolean(error)}>{error}</FormHelperText>
        )}
        <FormHelperText>
          Aggregated values within {entityName || 'entity'}
          {!minValue && !maxValue ? ' are not capped' : ''}
          {minValid
            ? ` smaller than ${minValue} are capped to ${minValue}`
            : ''}
          {minValid && maxValid ? ', and values' : ''}
          {maxValid ? ` larger than ${maxValue} are capped to ${maxValue}` : ''}
          .
        </FormHelperText>
      </Collapse>
    </div>
  );
}
