import {
  ConnectorBatchFileConfigFragment,
  ConnectorBigQueryConfigFragment,
  ConnectorDatabricksConfigFragment,
  ConnectorKinesisConfigFragment,
  ConnectorPubsubConfigFragment,
  ConnectorRedshiftConfigFragment,
  ConnectorS3BucketConfigFragment,
  ConnectorSnowflakeConfigFragment,
  EventConnectionFragment,
  FlagAppliedConnectionFragment,
} from '@spotify-confidence/plugin-graphql';

export const metricEventDestinations = [
  'bigQuery',
  'databricks',
  'redshift',
  'unknown',
] as const;

export type MetricEventConnectionDestinationType =
  (typeof metricEventDestinations)[number];

export const flagAppliedDestinations = [
  'bigQuery',
  'databricks',
  'kinesis',
  'pubSub',
  'redshift',
  'snowflake',
  'unknown',
] as const;

export type FlagAppliedDestinationType =
  (typeof flagAppliedDestinations)[number];

export const getDestinationNameForType = (
  type?:
    | FlagAppliedDestinationType
    | MetricEventConnectionDestinationType
    | null,
): string => {
  switch (type) {
    case 'bigQuery':
      return 'BigQuery';
    case 'databricks':
      return 'Databricks';
    case 'kinesis':
      return 'Kinesis';
    case 'pubSub':
      return 'Pub/Sub';
    case 'redshift':
      return 'Redshift';
    case 'snowflake':
      return 'Snowflake';
    default:
      return 'unknown';
  }
};

export const getFlagAppliedDestinationName = (
  connection?: FlagAppliedConnectionFragment | null,
): string => {
  if (connection) {
    if (connection.bigQuery) {
      return getDestinationNameForType('bigQuery');
    } else if (connection.databricks) {
      return getDestinationNameForType('databricks');
    } else if (connection.kinesis) {
      return getDestinationNameForType('kinesis');
    } else if (connection.pubsub) {
      return getDestinationNameForType('pubSub');
    } else if (connection.redshift) {
      return getDestinationNameForType('redshift');
    } else if (connection.snowflake) {
      return getDestinationNameForType('snowflake');
    }
  }
  return 'unknown';
};

export const getEventConnectionDestinationName = (
  connection?: EventConnectionFragment | null,
): string => {
  if (connection) {
    if (connection.bigQuery) {
      return getDestinationNameForType('bigQuery');
    } else if (connection.databricks) {
      return getDestinationNameForType('databricks');
    } else if (connection.redshift) {
      return getDestinationNameForType('redshift');
    } else if (connection.snowflake) {
      return getDestinationNameForType('snowflake');
    }
  }
  return 'unknown';
};

const getPubsubConnectionDetails = (
  pubsubConfig: ConnectorPubsubConfigFragment,
  full?: boolean,
): { label: string; value: string }[] | [] => {
  return [
    {
      label: 'Topic',
      value: pubsubConfig.topic,
    },
    {
      label: 'GCP Project ID',
      value: pubsubConfig.project,
    },
    ...(full
      ? [
          {
            label: 'Service account',
            value: pubsubConfig.serviceAccount,
          },
          {
            label: 'Output format',
            value: pubsubConfig.outputFormat,
          },
        ]
      : []),
  ];
};

const getKinesisConnectionDetails = (
  kinesisConfig: ConnectorKinesisConfigFragment,
  full?: boolean,
): { label: string; value: string }[] | [] => {
  return [
    {
      label: 'Stream',
      value: kinesisConfig.stream,
    },
    ...(full
      ? [
          {
            label: 'Region',
            value: kinesisConfig.region,
          },
          {
            label: 'Role ARN',
            value: kinesisConfig.roleArn,
          },
          {
            label: 'Output format',
            value: kinesisConfig.outputFormat,
          },
        ]
      : []),
  ];
};

const getBigQueryConfigDetails = (
  bigQueryConfig: ConnectorBigQueryConfigFragment,
  full?: boolean,
): { label: string; value: string }[] | [] => {
  return [
    {
      label: 'Dataset',
      value: bigQueryConfig.dataset,
    },
    {
      label: 'GCP Project ID',
      value: bigQueryConfig.project,
    },
    ...(full
      ? [
          {
            label: 'Service Account',
            value: bigQueryConfig.serviceAccount,
          },
        ]
      : []),
  ];
};

const getSnowflakeConfigDetails = (
  snowflakeConfig: ConnectorSnowflakeConfigFragment,
  full?: boolean,
): { label: string; value: string }[] | [] => {
  return [
    {
      label: 'Database',
      value: snowflakeConfig.database,
    },
    {
      label: 'Schema',
      value: snowflakeConfig.schema,
    },
    ...(full
      ? [
          {
            label: 'Account',
            value: snowflakeConfig.account,
          },
          {
            label: 'User',
            value: snowflakeConfig.user,
          },
          {
            label: 'Role',
            value: snowflakeConfig.role,
          },
        ]
      : []),
  ];
};

const getRedshiftConfigDetails = (
  redshiftConfig: ConnectorRedshiftConfigFragment,
  full?: boolean,
): { label: string; value: string }[] | [] => {
  return [
    {
      label: 'Cluster',
      value: redshiftConfig.cluster,
    },
    {
      label: 'Database',
      value: redshiftConfig.database,
    },
    ...(full
      ? [
          {
            label: 'Region',
            value: redshiftConfig.region,
          },
          {
            label: 'Schema',
            value: redshiftConfig.schema,
          },
          {
            label: 'Redshift authentication',
            value: redshiftConfig?.credentialsSettings?.accessKeyId
              ? `Access key id: ${redshiftConfig.credentialsSettings?.accessKeyId}`
              : 'Service account',
          },
        ]
      : []),
  ];
};

const getS3BucketConfigDetails = (
  s3Config: ConnectorS3BucketConfigFragment,
  full?: boolean,
): { label: string; value: string }[] | [] => {
  return [
    {
      label: 'Bucket',
      value: s3Config.bucket,
    },
    ...(full
      ? [
          {
            label: 'Role ARN',
            value: s3Config.roleArn,
          },
          {
            label: 'Bucket region',
            value: s3Config.region,
          },
          {
            label: 'S3 authentication',
            value: s3Config?.credentialsSettings?.accessKeyId
              ? `Access key id: ${s3Config.credentialsSettings?.accessKeyId}`
              : 'Service account',
          },
        ]
      : []),
  ];
};

const getBatchFileConfigDetails = (
  batchFileConfig: ConnectorBatchFileConfigFragment,
  full?: boolean,
): { label: string; value: string }[] | [] => {
  return [
    ...(full
      ? [
          {
            label: 'Max events per file',
            value: batchFileConfig.maxEventsPerFile,
          },
          {
            label: 'Max file age',
            value: batchFileConfig.maxFileAge,
          },
          {
            label: 'Max file size (in bytes)',
            value: batchFileConfig.maxFileSize,
          },
        ]
      : []),
  ];
};

const getDatabricksConfigDetails = (
  databricksConfig: ConnectorDatabricksConfigFragment,
  full?: boolean,
): { label: string; value: string }[] | [] => {
  return [
    {
      label: 'Schema',
      value: databricksConfig.schema,
    },
    {
      label: 'Bucket',
      value: databricksConfig.s3BucketConfig?.bucket || '',
    },
    ...(full
      ? [
          {
            label: 'Role ARN',
            value: databricksConfig.s3BucketConfig?.roleArn || '',
          },
          {
            label: 'Host',
            value: databricksConfig.connectionConfig?.host,
          },
          {
            label: 'Warehouse ID',
            value: databricksConfig.connectionConfig?.warehouseId,
          },
          {
            label: 'Client ID',
            value: databricksConfig.connectionConfig?.clientId,
          },
        ]
      : []),
  ];
};

export const getFlagAppliedConnectionDetails = (
  connection?: FlagAppliedConnectionFragment | null,
  full?: boolean,
): { label: string; value: string }[] | [] => {
  if (connection) {
    if (connection.bigQuery) {
      return [
        ...getBigQueryConfigDetails(connection.bigQuery.bigQueryConfig, full),
        { label: 'Table', value: connection.bigQuery?.table },
      ];
    } else if (connection.databricks) {
      return [
        {
          label: 'Table',
          value: connection.databricks?.table,
        },
        ...getDatabricksConfigDetails(
          connection.databricks.databricksConfig,
          full,
        ),
      ];
    } else if (connection.kinesis) {
      return getKinesisConnectionDetails(
        connection.kinesis.kinesisConfig,
        full,
      );
    } else if (connection.pubsub) {
      return getPubsubConnectionDetails(connection.pubsub.pubsubConfig, full);
    } else if (connection.redshift) {
      return [
        ...getRedshiftConfigDetails(connection.redshift.redshiftConfig, full),
        ...getS3BucketConfigDetails(connection.redshift.s3Config, full),
        ...getBatchFileConfigDetails(connection.redshift.batchFileConfig, full),
        { label: 'Table', value: connection.redshift?.table },
      ];
    } else if (connection.snowflake) {
      return [
        ...getSnowflakeConfigDetails(
          connection.snowflake.snowflakeConfig,
          full,
        ),

        {
          label: 'Table',
          value: connection.snowflake.table,
        },
      ];
    }
  }
  return [];
};

export const getEventConnectionDetails = (
  connection?: EventConnectionFragment | null,
  full?: boolean,
): { label: string; value: string }[] | [] => {
  if (connection) {
    if (connection.bigQuery) {
      return [
        ...getBigQueryConfigDetails(connection.bigQuery.bigQueryConfig, full),
        {
          label: 'Table Prefix',
          value: connection.bigQuery?.tablePrefix || '',
        },
      ];
    } else if (connection.databricks) {
      return getDatabricksConfigDetails(
        connection.databricks.databricksConfig,
        full,
      );
    } else if (connection.redshift) {
      return [
        ...getRedshiftConfigDetails(connection.redshift.redshiftConfig, full),
        ...getS3BucketConfigDetails(connection.redshift.s3Config, full),
        ...getBatchFileConfigDetails(connection.redshift.batchFileConfig, full),
        {
          label: 'Table prefix',
          value: connection.redshift?.tablePrefix || '',
        },
      ];
    } else if (connection.snowflake) {
      return [
        ...getSnowflakeConfigDetails(
          connection.snowflake.snowflakeConfig,
          full,
        ),
        {
          label: 'Table prefix',
          value: connection.snowflake?.tablePrefix || '',
        },
      ];
    }
  }
  return [];
};
