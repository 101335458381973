import React from 'react';

import { Switch, Tooltip } from '@material-ui/core';

import { useDialog } from '@spotify-confidence/core-react';
import { RuleFragment } from '@spotify-confidence/plugin-graphql';

import { ruleHelpers } from '../../../domain';
import { RuleType } from '../../../domain/rule.model';
import { DisableRuleDialog } from '../dialogs';

export type RuleToggleProps = {
  rule: RuleFragment;
  disabled?: boolean;
  onToggle?: (enabled: boolean) => Promise<void>;
};

export const RuleToggle = ({ rule, disabled, onToggle }: RuleToggleProps) => {
  const { openDialog } = useDialog();

  const toggle = (enabled: boolean) => {
    return onToggle ? onToggle(enabled) : Promise.resolve();
  };

  const handleToggle = (value: boolean) => {
    if (!value) {
      const ruleType = ruleHelpers.getRuleType(rule);
      if (ruleType === RuleType.OVERRIDE) {
        toggle(false);
      } else {
        openDialog({
          disableBackdropClick: true,
          content: <DisableRuleDialog onConfirm={() => toggle(false)} />,
        });
      }
    } else {
      toggle(true);
    }
  };

  return (
    <Tooltip title="Disable or enable the rule">
      <span>
        <Switch
          checked={rule.enabled}
          disabled={disabled}
          onChange={e => handleToggle(e.target.checked)}
          name="enabled"
        />
      </span>
    </Tooltip>
  );
};
