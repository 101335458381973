import React from 'react';

import { alpha, makeStyles } from '@material-ui/core';

const getMatcher = (lookup?: string) =>
  new RegExp(`(${lookup?.replace(/[^\w\s_-]/gi, '')})`, 'i');

const useStyles = makeStyles(theme => ({
  default: {
    background: alpha(theme.palette.warning.light, 0.3),
  },
  bold: {
    // fontWeight: theme.typography.fontWeightBold,
  },
}));

export type HighlightPreset =
  | 'default' // default fluorescent marker
  | 'bold'; // bold marker

export type HighlightProps = {
  children?: string;
  highlight?: string;
  preset?: HighlightPreset;
};

export const Highlight: React.FC<HighlightProps> = ({
  children,
  highlight,
  preset = 'default',
}) => {
  const classes = useStyles();

  if (!highlight) {
    return <>{children}</>;
  }

  // Split on highlight term and include term into parts, ignore case
  const parts = (
    highlight ? children?.split(getMatcher(highlight)) : [children ?? '']
  )?.filter(part => !!part);

  const className = classes[preset];

  return parts ? (
    <span>
      {parts.map((part, i) => (
        <span
          key={`${part}${i}`}
          className={
            part.toLowerCase() === highlight?.toLowerCase()
              ? className
              : undefined
          }
        >
          {part}
        </span>
      ))}
    </span>
  ) : null;
};
