import React from 'react';

import { Box, Typography } from '@material-ui/core';

import { CriterionAttribute } from '../../../domain/targeting';
import {
  ClosedRange,
  ClosedRangeValue,
} from '../../../domain/targeting/targeting.model';
import { InclusivePicker } from './InclusivePicker';
import { SharedInputProps } from './types';

type ClosedRangeRuleProps = {
  component: React.ComponentType<SharedInputProps>;
  value: ClosedRange;
  onChange: (v: ClosedRange) => void;
  valueType?: CriterionAttribute['attributeType'];
  readOnly?: boolean;
  disabled?: boolean;
};

export const ClosedRangeInput = ({
  component: Component,
  onChange,
  value,
  valueType,
  readOnly = false,
  disabled = false,
}: ClosedRangeRuleProps) => {
  const rangeChange =
    (key: keyof ClosedRange, field: keyof ClosedRangeValue) =>
    (newValue: string | boolean) => {
      const d: ClosedRange = {
        ...value,
        [key]: { ...value[key], [field]: newValue },
      };

      onChange(d);
    };
  return (
    <Box display="flex" alignItems="center" gridGap={8}>
      <Box
        display="inline-flex"
        gridGap={4}
        data-testid="range-start-input-container"
      >
        <Component
          valueType={valueType}
          value={value.start.value}
          onChange={rangeChange('start', 'value')}
          disabled={disabled}
          readOnly={readOnly}
          InputProps={{
            endAdornment: (
              <InclusivePicker
                readOnly={readOnly}
                inclusive={value.start.inclusive}
                disabled={disabled}
                onChange={rangeChange('start', 'inclusive')}
              />
            ),
          }}
        />
      </Box>
      <Typography color="inherit" variant="body2">
        and
      </Typography>
      <Box
        display="inline-flex"
        gridGap={4}
        data-testid="range-end-input-container"
      >
        <Component
          valueType={valueType}
          value={value.end.value}
          onChange={rangeChange('end', 'value')}
          disabled={disabled}
          readOnly={readOnly}
          InputProps={{
            endAdornment: (
              <InclusivePicker
                readOnly={readOnly}
                disabled={disabled}
                inclusive={value.end.inclusive}
                onChange={rangeChange('end', 'inclusive')}
              />
            ),
          }}
        />
      </Box>
    </Box>
  );
};
