import React from 'react';

import { EventSchemaEntryFragment } from '@spotify-confidence/plugin-graphql';

import { schemaHooks } from '../../domain';

type SchemaContextValue = {
  getEntryLabels?: (identity: string) => React.ReactNode;
  readOnly?: boolean;
  permanentKeys?: string[];
  schema: EventSchemaEntryFragment[];
  onChange?: React.Dispatch<React.SetStateAction<EventSchemaEntryFragment[]>>;
};

export const SchemaContext = React.createContext<
  ReturnType<typeof schemaHooks.useSchema> & {
    schema: EventSchemaEntryFragment[];
    selectedEntry: string | null;
    setSelectedEntry: React.Dispatch<React.SetStateAction<string | null>>;
  }
>({
  schema: [],
  onAdd: () => '',
  onUpdate: () => '',
  onDelete: () => '',
  selectedEntry: null,
  setSelectedEntry: () => {},
});

export const SchemaProvider = ({
  schema = [],
  onChange,
  children,
}: React.PropsWithChildren<SchemaContextValue>) => {
  const { onAdd, onUpdate, onDelete } = schemaHooks.useSchema({
    onChange: v => onChange?.(v),
  });
  const [selectedEntry, setSelectedEntry] = React.useState<string | null>(null);

  return (
    <SchemaContext.Provider
      value={{
        schema,
        onAdd,
        onUpdate,
        onDelete,
        selectedEntry,
        setSelectedEntry,
      }}
    >
      {children}
    </SchemaContext.Provider>
  );
};

export const useSchemaContext = () => {
  const context = React.useContext(SchemaContext);

  if (!context) {
    throw new Error('useSchemaContext must be used within a SchemaProvider');
  }

  return context;
};
