import React from 'react';

import {
  Box,
  Card,
  CardContent,
  CircularProgress,
  Typography,
  styled,
} from '@material-ui/core';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';

const StyledContent = styled(CardContent)(({ theme }) => ({
  '&, &:last-child': {
    padding: `${theme.spacing(4)}px`,
  },
}));

type MonitorCardProps = React.PropsWithChildren<{
  title: React.ReactNode;
  waiting?: boolean;
}>;

export const MonitorCard = ({ title, children, waiting }: MonitorCardProps) => {
  return (
    <Card variant="outlined">
      <StyledContent>
        <Box display="flex" flexDirection="row" gridGap={16}>
          {waiting ? (
            <CircularProgress size={24} color="inherit" />
          ) : (
            <CheckCircleIcon color="primary" />
          )}
          <Box>
            <Typography variant="h6">{title}</Typography>
            {children}
          </Box>
        </Box>
      </StyledContent>
    </Card>
  );
};
