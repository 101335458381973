import { getRelativeBounds } from './MetricResultTable';
import { MetricAnalysisType, StatsHypothesisResult } from './types';
import { getRelativeCIBounds } from './utils';

export const useRelativeBounds = (results: StatsHypothesisResult[]) => {
  let bounds = results.flatMap(({ metricDetails, result }) => {
    return (
      result?.flatMap(({ lastResult }) => {
        return (
          Math.max(
            // max value from CI bounds
            getRelativeCIBounds(
              lastResult?.differenceEstimateRel?.estimate,
              lastResult?.differenceEstimateRel?.upper,
              lastResult?.differenceEstimateRel?.lower,
            ),
            // max value from NIM
            metricDetails.metricType === MetricAnalysisType.GUARDRAIL
              ? Math.abs(metricDetails.plannedEffectSize ?? 0)
              : 0,
          ) * 100
        );
      }) ?? 0
    );
  });

  if (bounds.every(bound => bound === 0)) {
    // If all bounds are 0, set default bounds to [-1, 1]
    // so it looks a little bit better.
    bounds = [-1, 1];
  }

  return getRelativeBounds(bounds);
};
