import React from 'react';
import { InView } from 'react-intersection-observer';

import {
  Button,
  ListItemText,
  Table,
  TableBody,
  TableCell,
  TableRow,
  Typography,
} from '@material-ui/core';
import Add from '@material-ui/icons/Add';
import ChevronRightRoundedIcon from '@material-ui/icons/ChevronRightRounded';

import {
  ActionBar,
  Breadcrumbs,
  ConfidenceWebsiteLink,
  FilterBar,
  FilterListEmptyState,
  FilterListProvider,
  PageLayout,
  ResourceTableRow,
  StickyTableContainer,
  StickyTableHead,
  useDialog,
  useListFilter,
  useMeasureDimensions,
} from '@spotify-confidence/core-react';
import {
  getError,
  getTypeOrNull,
  useListApiClientsQuery,
} from '@spotify-confidence/plugin-graphql';
import { useCheckResourcePermissions } from '@spotify-confidence/plugin-permissions-react';

import { Link } from '@backstage/core-components';
import { useRouteRef } from '@backstage/core-plugin-api';

import { apiClientRouteRef, rootRouteRef } from '../../../routes';
import { CreateApiClient } from './CreateApiClient';

const ApiClientList = ({
  topOffset,
  onCreate,
}: {
  topOffset: number;
  onCreate?: () => void;
}) => {
  const { searchQuery } = useListFilter();

  const { data, loading, error, fetchMore } = useListApiClientsQuery();
  const apiClientsResponse = getTypeOrNull(
    data?.apiClients,
    'IamV1ListApiClientsResponse',
  );
  const filteredApiClients = (apiClientsResponse?.apiClients || []).filter(
    user => user.displayName.toLowerCase().includes(searchQuery.toLowerCase()),
  );

  if (filteredApiClients.length === 0 && !loading) {
    return (
      <FilterListEmptyState
        error={getError(data?.apiClients) || error}
        type="API Client"
        description={
          <>
            Create your first API Client.{' '}
            <ConfidenceWebsiteLink
              route="/docs/api/quickstart#create-an-api-client"
              underline="always"
            >
              Learn more about API Clients
            </ConfidenceWebsiteLink>
          </>
        }
        onCreate={onCreate}
      />
    );
  }
  return (
    <StickyTableContainer loading={loading} error={error}>
      <Table>
        <StickyTableHead topOffset={topOffset}>
          <TableRow>
            <TableCell>Name</TableCell>
            <TableCell />
          </TableRow>
        </StickyTableHead>
        <TableBody>
          {filteredApiClients.map(client => (
            <ResourceTableRow
              key={client.name}
              name={client.name}
              data-testid={client.displayName}
              routeRef={apiClientRouteRef}
            >
              <TableCell>
                <ListItemText primary={client.displayName} />
              </TableCell>
              <TableCell width={50}>
                <ChevronRightRoundedIcon />
              </TableCell>
            </ResourceTableRow>
          ))}
        </TableBody>
      </Table>
      {data && (
        <InView
          onChange={async inView => {
            if (inView && apiClientsResponse?.nextPageToken) {
              await fetchMore({
                variables: {
                  pageToken: apiClientsResponse?.nextPageToken,
                },
              });
            }
          }}
        />
      )}
    </StickyTableContainer>
  );
};
export const ApiClientsIndexPage = () => {
  const adminRoute = useRouteRef(rootRouteRef);
  const { openDialog } = useDialog();
  const { allowed } = useCheckResourcePermissions({
    can: 'create_api_client',
    name: 'account',
  });
  const [ref, { height }] = useMeasureDimensions<HTMLDivElement>();
  const onCreate = () => {
    openDialog({ content: <CreateApiClient /> });
  };

  return (
    <FilterListProvider storageKey="api-clients">
      <PageLayout
        narrow
        title="API Clients"
        headerBreadcrumbs={
          <Breadcrumbs>
            <Link to={adminRoute()}>Admin</Link>
            <Typography>API Clients</Typography>
          </Breadcrumbs>
        }
      >
        <ActionBar ref={ref}>
          <FilterBar />
          <Button
            startIcon={<Add />}
            onClick={onCreate}
            variant="contained"
            color="primary"
            size="small"
            data-testid="create-api-client-button"
            disabled={!allowed}
          >
            Create
          </Button>
        </ActionBar>

        <ApiClientList
          topOffset={height}
          onCreate={allowed ? onCreate : undefined}
        />
      </PageLayout>
    </FilterListProvider>
  );
};
