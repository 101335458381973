import React from 'react';

import { Box, Button, Menu, MenuItem } from '@material-ui/core';
import VisibilityIcon from '@material-ui/icons/Visibility';

type ToggleValue = 'relative' | 'absolute';

type RelativeToggleProps = {
  value: ToggleValue;
  onChange: (value: ToggleValue) => void;
};

const selectOptions: { value: ToggleValue; label: string }[] = [
  { value: 'relative', label: 'Relative values' },
  { value: 'absolute', label: 'Absolute values' },
];

export const RelativeToggle = ({ value, onChange }: RelativeToggleProps) => {
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleSelect = (v: ToggleValue) => () => {
    onChange(v);
    handleClose();
  };

  return (
    <Box>
      <Button
        variant="outlined"
        size="small"
        name="relative-toggle-button"
        startIcon={<VisibilityIcon fontSize="small" />}
        onClick={(e: React.MouseEvent<HTMLButtonElement>) =>
          setAnchorEl(e.currentTarget)
        }
      >
        {value === 'relative' ? 'Relative values' : 'Absolute values'}
      </Button>
      <Menu
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        onClose={handleClose}
        data-testid="relative-toggle-menu"
      >
        {selectOptions.map(({ value: v, label }) => (
          <MenuItem
            value={v}
            key={v}
            onClick={handleSelect(v)}
            selected={v === value}
          >
            {label}
          </MenuItem>
        ))}
      </Menu>
    </Box>
  );
};
