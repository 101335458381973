import React from 'react';

import { useDisplayNames } from '@spotify-confidence/core-react';
import {
  SurfaceFragment,
  getTypeOrNull,
  isError,
  useGetMandatoryMetricsMutation,
  useWorkflowInstanceSurfacesQuery,
} from '@spotify-confidence/plugin-graphql';
import { Metric } from '@spotify-confidence/plugin-metrics';

type MetricMetadata = {
  metric: string;
  displayName?: string;
};
export type SurfaceMetrics = {
  metrics: Metric[];
  metadata: MetricMetadata[];
};

export const useMetricsFromSurface = (
  instance?: string,
  shouldFetch?: boolean,
  userMetrics?: Metric[],
) => {
  const [getMandatoryMetrics] = useGetMandatoryMetricsMutation();
  const { setDisplayName } = useDisplayNames();
  const [requiredMetrics, setRequiredMetrics] = React.useState<Metric[]>([]);

  const { data: workflowSurfacesData } = useWorkflowInstanceSurfacesQuery({
    variables: {
      name: instance ?? '',
    },
    skip: !instance || !shouldFetch,
  });

  React.useEffect(() => {
    if (instance !== undefined && shouldFetch) {
      const workflowInstance = getTypeOrNull(
        workflowSurfacesData?.workflowInstance,
        'WorkflowV1WorkflowInstance',
      );
      const surfaces = (workflowInstance?.surfaces.filter(s => !isError(s)) ??
        []) as SurfaceFragment[];
      surfaces.forEach(surface => {
        setDisplayName({ [surface.name]: surface.displayName });
      });

      getMandatoryMetrics({
        variables: {
          instance,
        },
      }).then(value => {
        if (value.errors === undefined) {
          const response: SurfaceMetrics | undefined = getTypeOrNull(
            value.data?.executeFunction,
            'WorkflowV1ExecuteFunctionResponse',
          )?.response;

          response?.metadata.forEach(m => {
            if (m.displayName !== undefined) {
              setDisplayName({ [m.metric]: m.displayName });
            }
          });

          setRequiredMetrics(response?.metrics ?? []);
        }
      });
    }
  }, [instance, workflowSurfacesData, userMetrics]); // We need to refetch when new surfaces are added or new sucess metrics are added

  return shouldFetch
    ? requiredMetrics
    : (userMetrics ?? []).filter(m => m.surface !== undefined);
};
