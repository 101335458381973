import React from 'react';

import {
  ConfirmDialog,
  useAlert,
  useDialog,
  useNavigate,
} from '@spotify-confidence/core-react';
import {
  MetricDetailsFragment,
  isType,
  useDeleteMetricMutation,
} from '@spotify-confidence/plugin-graphql';

import { useRouteRef } from '@backstage/core-plugin-api';

import { metricsRouteRef } from '../../../routes';

export type DeleteMetricDialogProps = {
  metric: MetricDetailsFragment;
};

export const DeleteMetricDialog = ({ metric }: DeleteMetricDialogProps) => {
  const alert = useAlert();
  const { closeDialog } = useDialog();
  const navigate = useNavigate();
  const metricsRoute = useRouteRef(metricsRouteRef);

  const [deleteMetric] = useDeleteMetricMutation({
    update: cache => {
      cache.evict({
        id: cache.identify({
          __typename: 'MetricsV1Metric',
          name: metric.name,
        }),
      });
    },
    onCompleted: reponse => {
      if (
        isType(reponse.deleteMetric, 'MetricsV1Metric') &&
        reponse.deleteMetric
      ) {
        alert.post({
          severity: 'success',
          message: `${metric?.displayName} was successfully deleted`,
          display: 'transient',
        });

        // Todo: Remove timeout when there is proper backend support
        setTimeout(() => {
          closeDialog();
          navigate(metricsRoute());
        }, 1500);
      } else {
        alert.post({
          severity: 'error',
          message: reponse.deleteMetric?.message ?? 'Something went wrong',
        });
      }
    },
  });

  const onDelete = async () => {
    if (metric) {
      await deleteMetric({
        variables: {
          name: metric.name,
        },
      });
    }
  };

  return (
    <ConfirmDialog
      expected="delete"
      title="Delete Metric"
      irreversible
      onConfirm={onDelete}
    >
      Deleting a metric may have consequences on running experiments. To confirm
      this action, please type "delete".
    </ConfirmDialog>
  );
};
