/* eslint-disable no-nested-ternary */
import React from 'react';

import { Chip, Tooltip, Typography } from '@material-ui/core';
import CancelIcon from '@material-ui/icons/Cancel';

import { getTypeOrNull } from '@spotify-confidence/plugin-graphql';

import {
  ATTRIBUTE_OPERATORS,
  ClosedRange,
  CriterionAttribute,
  getAttributeTypeDefaultValue,
  isClosedRangeOp,
  isClosedRangeValue,
  isSetOp,
  isSetValue,
} from '../../domain/targeting/targeting.model';
import { useCriterionOptions } from './CriterionOptionsProvider';
import { OperatorExtraConfig, OperatorExtraConfigProps } from './Operator';
import { TargetingField } from './Targeting.Field';
import { TargetingDropdown } from './TargetingDropdown';
import { useStyles } from './criterionStyle';

type AttributeCriterionChipProps = {
  criterion: CriterionAttribute;
  onDelete?: () => void;
  onChange: (newValue: CriterionAttribute) => void;
  readOnly?: boolean;
};

export const AttributeCriterionChip = ({
  criterion,
  onChange,
  onDelete,
  readOnly = false,
}: AttributeCriterionChipProps) => {
  const classes = useStyles();
  const { criterionOptions } = useCriterionOptions();
  const changeAttributeOp = React.useCallback(
    (op: CriterionAttribute['op']) => {
      if (op === criterion.op) return;
      /** Try to persist values between op change */
      const prevValue = criterion.value;
      if (isClosedRangeOp(op)) {
        const rangeValue: ClosedRange = !prevValue
          ? {
              start: { value: '', inclusive: true },
              end: { value: '', inclusive: true },
            }
          : isSetValue(prevValue)
          ? {
              start: { value: prevValue.at(0) ?? '', inclusive: true },
              end: { value: '', inclusive: true },
            }
          : isClosedRangeValue(prevValue)
          ? prevValue
          : {
              start: { value: prevValue, inclusive: true },
              end: { value: '', inclusive: true },
            };
        onChange({
          ...criterion,
          op,
          value: rangeValue,
        });
        return;
      }
      /** SetOp -> value should be a List */
      if (isSetOp(op)) {
        onChange({
          ...criterion,
          op,
          // eslint-disable-next-line no-nested-ternary
          value: !prevValue
            ? [] // default to empty array
            : isSetValue(prevValue)
            ? prevValue
            : isClosedRangeValue(prevValue)
            ? prevValue.start.value
              ? [prevValue.start.value]
              : []
            : [prevValue],
        });
        return;
      }

      const defaultValue = getAttributeTypeDefaultValue(
        criterion.attributeType,
      );
      /** non SetOp -> value should be a primitive */
      onChange({
        ...criterion,
        op,
        // eslint-disable-next-line no-nested-ternary
        value: op.includes('null')
          ? null
          : !prevValue
          ? defaultValue
          : isSetValue(prevValue) // if previousValue is a list
          ? prevValue.at(0) || defaultValue // take the first one,
          : isClosedRangeValue(prevValue)
          ? prevValue.start.value
          : prevValue, // otherwise (previousValue is Primitive); take it
      });
    },
    [criterion, onChange],
  );

  const changeAttributeValue = React.useCallback(
    (value: CriterionAttribute['value']) => {
      onChange({
        ...criterion,
        value,
      });
    },
    [criterion, onChange],
  );

  const onOpExtraConfigChange = React.useCallback(
    (extraConfig: OperatorExtraConfigProps['extraConfig']) => {
      onChange({
        ...criterion,
        ...extraConfig,
      });
    },
    [criterion, onChange],
  );

  const operators = React.useMemo(() => {
    const leType = criterion?.attributeType;
    return leType ? ATTRIBUTE_OPERATORS[leType] : [];
  }, [criterion]);

  const opExtraConfig = React.useMemo(
    () => ({
      listMatcherType: criterion.listMatcherType,
    }),
    [criterion],
  );

  const criterionOption = criterionOptions.find(
    option => option.name === criterion.attribute,
  );

  const entity = getTypeOrNull(
    criterionOption?.semanticType?.entityReference?.entity,
    'MetricsV1Entity',
  );

  return (
    <Chip
      data-testid={`${criterion.attribute}-container`}
      classes={{ root: classes.root, label: classes.label }}
      label={
        <>
          <Tooltip
            title={`${criterion.attribute} (${
              opExtraConfig.listMatcherType
                ? `${opExtraConfig.listMatcherType}, `
                : ''
            }${criterion.attributeType})`}
            arrow
          >
            <Typography variant="body2">
              {entity?.displayName ||
                criterionOption?.displayName ||
                criterion.attribute}
            </Typography>
          </Tooltip>
          <TargetingDropdown
            name="operand-picker"
            label={criterion.op.toLocaleLowerCase()}
            readOnly={readOnly}
            options={operators.map(o => ({
              label: o.toLocaleLowerCase(),
              selected: o === criterion.op,
              onClick: () => changeAttributeOp(o),
            }))}
          >
            <OperatorExtraConfig
              extraConfig={opExtraConfig}
              onExtraConfigChange={onOpExtraConfigChange}
            />
          </TargetingDropdown>

          <TargetingField
            op={criterion.op}
            value={criterion.value}
            valueType={criterion.attributeType}
            onChange={changeAttributeValue}
            disabled={operators.length === 0}
            criterionOption={criterionOption}
            readOnly={readOnly}
          />
        </>
      }
      onDelete={readOnly ? undefined : onDelete}
      deleteIcon={<CancelIcon name="delete-icon" />}
    />
  );
};
