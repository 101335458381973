import React from 'react';

import { Typography } from '@material-ui/core';

import { CodeSnippet, SnippetProps, f } from '../../CodeSnippet';
import { TUTORIAL_FLAG_NAME } from '../constants';

export const JavaClientSnippets = (props: SnippetProps) => {
  return (
    <ol>
      <li>
        <Typography variant="body1" gutterBottom>
          Add the Confidence SDK dependency.
        </Typography>
        <CodeSnippet
          language="xml"
          text={f(`
<dependency>
    <groupId>com.spotify.confidence</groupId>
    <artifactId>sdk-java</artifactId>
    <version>0.0.14</version>
</dependency>
`)}
          showCopyCodeButton
        />
      </li>
      <li>
        <Typography variant="body1" gutterBottom>
          Instantiate the Confidence class
        </Typography>
        <CodeSnippet
          language="java"
          text={f(`
Confidence rootConfidence = Confidence.builder("${props.apiKey}").build();
      `)}
          showCopyCodeButton
        />
      </li>
      <li>
        <Typography variant="body1" gutterBottom>
          Next, we want to set the `visitor_id` context field to
          "tutorial_visitor", to match the targeting criteria pre-configured for
          this tutorial.
        </Typography>
        <CodeSnippet
          language="java"
          text={f(`
Confidence confidence = rootConfidence.withContext(Map.of("visitor_id", ConfidenceValue.of("tutorial_visitor")));
          `)}
          showCopyCodeButton
        />
      </li>
      <li>
        <Typography variant="body1" gutterBottom>
          Resolve the "title" value for the "{TUTORIAL_FLAG_NAME}" flag.
        </Typography>
        <CodeSnippet
          language="java"
          text={f(`
String title = confidence.getValue(
  "${TUTORIAL_FLAG_NAME}.title",
  "Default"
);
      `)}
          showCopyCodeButton
        />
      </li>
    </ol>
  );
};
