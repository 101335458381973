import React from 'react';

import { Box, makeStyles } from '@material-ui/core';
import { Alert, AlertProps, AlertTitle } from '@material-ui/lab';

const useStyles = makeStyles(theme => ({
  root: {
    position: 'relative',
  },
  message: {
    width: '100%',
    paddingRight: theme.spacing(1),
  },
  action: {
    position: 'absolute',
    top: theme.spacing(2),
    right: theme.spacing(2),
  },
}));

type MissingFieldsWarningProps = AlertProps & {
  actions?: React.ReactNode;
  alignActions?: 'start' | 'end' | 'center';
};

export const MissingFieldsWarning = ({
  children,
  actions,
  alignActions,
  ...alertProps
}: MissingFieldsWarningProps) => {
  const classes = useStyles();

  return (
    <Alert severity="warning" classes={classes} {...alertProps}>
      <AlertTitle>Missing fields in schema</AlertTitle>
      The schema is missing fields that clients are publishing. These fields are
      being dropped and data is lost. Update the schema to start storing them.
      {children && <Box paddingY={2}>{children}</Box>}
      {actions && (
        <Box
          alignItems="center"
          gridGap={8}
          display="flex"
          justifyContent={alignActions}
          marginTop={2}
          width="100%"
        >
          {actions}
        </Box>
      )}
    </Alert>
  );
};
