/**
 * Helper function for asserting that a condition is true.
 * Pretty much https://www.npmjs.com/package/tiny-invariant
 *
 */
export function invariant<T>(
  condition: T | null | undefined,
  message: string,
): asserts condition is T {
  if (!condition) {
    throw new Error(message);
  }
}
