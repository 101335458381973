import React from 'react';

import { CriterionOption } from '../../domain/targeting/targeting.model';

type CriterionOptionsProviderType = {
  criterionOptions: CriterionOption[];
  addCustomOption: (option: CriterionOption) => void;
  removeCustomOption: (option: CriterionOption) => void;
};

const CriterionOptionsContext =
  React.createContext<CriterionOptionsProviderType>({
    criterionOptions: [],
    addCustomOption: () => {},
    removeCustomOption: () => {},
  });

export const CriterionOptionsProvider = ({
  children,
  value = [],
}: React.PropsWithChildren<{ value?: CriterionOption[] }>) => {
  // Custom options support editing between null/non-null for attributes not present in the context (as long as you stay on the page)
  // For example: if the user adds a custom boolean attribute, they should be able to change back to boolean operators from null
  const [customOptions, setCustomOptions] = React.useState<CriterionOption[]>(
    [],
  );

  const addCustomOption = React.useCallback(
    (option: CriterionOption) => {
      if (!value.some(o => o.name === option.name)) {
        setCustomOptions(current => {
          if (current.some(o => o.name === option.name)) {
            return current.map(o => (o.name === option.name ? option : o));
          }
          return current.concat(option);
        });
      }
    },
    [value],
  );

  const removeCustomOption = React.useCallback((option: CriterionOption) => {
    setCustomOptions(current => current.filter(o => o.name !== option.name));
  }, []);

  const allOptions = React.useMemo(() => {
    return value.concat(
      customOptions.filter(custom => !value.some(o => o.name === custom.name)),
    );
  }, [customOptions, value]);

  return (
    <CriterionOptionsContext.Provider
      value={{
        criterionOptions: allOptions,
        addCustomOption,
        removeCustomOption,
      }}
    >
      {children}
    </CriterionOptionsContext.Provider>
  );
};

export const useCriterionOptions = () => {
  const context = React.useContext(CriterionOptionsContext);

  if (!context) {
    throw new Error('Missing CriterionOptionsContext.');
  }

  return context;
};
