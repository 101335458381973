import React from 'react';

import { Tooltip, createStyles, withStyles } from '@material-ui/core';
import ErrorIcon from '@material-ui/icons/Error';

const styles = () => createStyles({});

export interface SchemaEntryErrorProps {
  error?: string | null;
}

const SchemaEntryError = function SchemaEntryError({
  error,
}: SchemaEntryErrorProps) {
  if (!error) {
    return null;
  }
  return (
    <Tooltip title={error}>
      <ErrorIcon color="error" />
    </Tooltip>
  );
};

export default withStyles(styles)(SchemaEntryError);
