import React from 'react';
import { useSearchParams } from 'react-router-dom';

import { DialogActions, ListItemText, TextField } from '@material-ui/core';
import { Alert } from '@material-ui/lab';

import {
  AutocompleteEmptyState,
  ConfidenceAutocomplete,
  DialogBody,
  DialogForm,
  DialogHeader,
  FormFieldsBox,
  FormSubmitButtons,
  useAlert,
  useDialog,
} from '@spotify-confidence/core-react';
import {
  MetricAssignmentTableFragment,
  MetricsV1TableState,
  WorkflowV1WorkflowInstanceInput,
  isType,
  useCreateWorkflowInstanceMutation,
  useListEntitiesLazyQuery,
  useMetricConfigAssignmentTablesLazyQuery,
} from '@spotify-confidence/plugin-graphql';
import {
  IdentityAutocomplete,
  useDefaultOwner,
} from '@spotify-confidence/plugin-permissions-react';

import { useRouteRef } from '@backstage/core-plugin-api';

import { entitiesRouteRef, workflowInstanceRouteRef } from '../../../routes';
import { useWorkflowCreationData } from './useWorkflowCreationData';
import { getWorkflowName, mergeModuleData } from './utils';

export const useCreateWorkflowInstance = ({
  workflowId,
  onCreated,
}: {
  workflowId: string;
  onCreated?: (context: { url: string }) => void;
}) => {
  const alert = useAlert();
  const [searchParams] = useSearchParams();
  const workflowInstanceRoute = useRouteRef(workflowInstanceRouteRef);
  const [createInstanceMutation, { loading, error }] =
    useCreateWorkflowInstanceMutation({
      update: cache => {
        cache.evict({ fieldName: 'workflowInstances' });
        cache.gc();
      },
    });

  const createInstance = async (
    defaultData: WorkflowV1WorkflowInstanceInput,
  ) => {
    await createInstanceMutation({
      variables: {
        parent: getWorkflowName(workflowId),
        workflowInstance: {
          ...defaultData,
        },
      },
      onCompleted: workflowInstanceDataResponse => {
        if (
          isType(
            workflowInstanceDataResponse.createWorkflowInstance,
            'WorkflowV1WorkflowInstance',
          )
        ) {
          const { name } = workflowInstanceDataResponse.createWorkflowInstance;
          const [_, workflow, __, id] = name.split('/');
          const preconfiguredFlag = searchParams.get('flag');
          const url = `${workflowInstanceRoute({
            workflow,
            id,
          })}${preconfiguredFlag ? `?flag=${preconfiguredFlag}` : ''}`;
          onCreated?.({ url });
        } else {
          alert.post({
            message:
              workflowInstanceDataResponse.createWorkflowInstance?.message ??
              'Could not create workflow instance',
            severity: 'error',
          });
        }
      },
    });
  };
  return {
    createInstance,
    error,
    loading,
  };
};

export const CreateWorkflowInstanceForm = ({
  title,
  workflowId,
  onCreated,
  defaultData = {},
}: React.PropsWithChildren<{
  onCreated: (url: string) => void;
  title: string;
  workflowId: string;
  defaultData?: Partial<WorkflowV1WorkflowInstanceInput>;
}>) => {
  const { closeDialog } = useDialog();
  const [displayName, setDisplayName] = React.useState<string>('');
  const [assignmentTable, setAssignmentTable] = React.useState<
    string | undefined
  >();
  const [entity, setEntity] = React.useState<string>('');
  const [owner, setOwner, { loading: loadingUserIdentity }] = useDefaultOwner();
  const { createInstance, loading, error } = useCreateWorkflowInstance({
    workflowId,
    onCreated: ({ url }) => {
      onCreated(url);
    },
  });

  const { showEntitySelect, showAssignmentTables, moduleData } =
    useWorkflowCreationData({
      workflowId,
      entity,
      assignmentTable,
    });

  const entitiesRoute = useRouteRef(entitiesRouteRef);
  const handleCreateEntity = (searchInput?: string) => {
    window.open(
      `${entitiesRoute?.()}?create=true${
        searchInput ? `&name=${searchInput}` : ''
      }`,
      '_blank',
    );
  };

  const handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();

    createInstance({
      ...defaultData,
      displayName,
      owner: owner,
      moduleData: defaultData?.moduleData
        ? mergeModuleData(defaultData.moduleData, moduleData)
        : moduleData,
    });
  };

  return (
    <DialogForm onSubmit={handleSubmit} data-testid="create-workflow-dialog">
      <DialogHeader title={title} />
      <DialogBody>
        {error && <Alert severity="error">{error.message}</Alert>}
        <FormFieldsBox>
          <TextField
            name="createworkflow"
            placeholder="Name"
            label="Name"
            value={displayName}
            variant="outlined"
            margin="dense"
            onChange={e => setDisplayName(e.target.value)}
            fullWidth
            // eslint-disable-next-line jsx-a11y/no-autofocus
            autoFocus
            disabled={loading}
            inputProps={{
              'data-form-type': 'other',
              autoComplete: 'off',
              lpignore: 'true',
            }}
            required
          />
          {showAssignmentTables && (
            <ConfidenceAutocomplete<MetricAssignmentTableFragment>
              query={useMetricConfigAssignmentTablesLazyQuery}
              queryOptions={{
                variables: {
                  pageSize: 100,
                },
              }}
              data-testid="assignment-table-select"
              onChange={(_e, v) => {
                if (v === null) return;
                setAssignmentTable(v);
              }}
              value={assignmentTable}
              label="Assignment table"
              placeholder="Select assignment table"
              required
              helperText="Select the table that can determine which entity was exposed to which treatment."
              renderOption={table => (
                <ListItemText primary={table.displayName} />
              )}
              getOptionDisabled={table =>
                table.state !== MetricsV1TableState.TableStateActive
              }
            >
              <AutocompleteEmptyState
                title="No assignment tables"
                description="An assignment table is required to determine which entity was exposed to which treatment."
              />
            </ConfidenceAutocomplete>
          )}
          {showEntitySelect && (
            <ConfidenceAutocomplete
              query={useListEntitiesLazyQuery}
              autoSelectSingle
              label="Entity"
              data-testid="entity-select"
              value={entity}
              onChange={(_e, newEntity) => setEntity(newEntity || '')}
              required
              onCreate={handleCreateEntity}
              helperText="Who you will target in this test."
            />
          )}
          <IdentityAutocomplete
            value={owner}
            onChange={(_e, newIdentity) => {
              setOwner(newIdentity);
            }}
            label="Owner"
            required
            loading={loadingUserIdentity}
          />
        </FormFieldsBox>
      </DialogBody>
      <DialogActions>
        <FormSubmitButtons
          onCancel={closeDialog}
          loading={loading}
          label="Create"
        />
      </DialogActions>
    </DialogForm>
  );
};
