import React from 'react';

import { useTheme } from '@material-ui/core';

import { defaultStyles, useTooltip, useTooltipInPortal } from '@visx/tooltip';
import _ from 'lodash';

import { ResultData, TableMetaOptions } from '../types';
import { ChartTooltip } from './ChartTooltip';

type UseChartTooltipArgs = TableMetaOptions;

export const useChartTooltip = ({
  showRelativeChanges,
  bucket,
}: UseChartTooltipArgs = {}) => {
  const theme = useTheme();
  const {
    showTooltip,
    hideTooltip,
    tooltipOpen,
    tooltipData,
    tooltipTop,
    tooltipLeft,
  } = useTooltip<ResultData>();

  const { containerRef, TooltipInPortal } = useTooltipInPortal({
    scroll: true,
  });

  const renderTooltip = () =>
    tooltipOpen &&
    tooltipData && (
      <TooltipInPortal
        top={tooltipTop}
        left={tooltipLeft}
        style={{
          ...defaultStyles,
          backgroundColor: theme.palette.background.paper,
          color: theme.palette.text.primary,
          border: `1px solid ${theme.palette.divider}`,
          boxShadow: theme.shadows[0],
        }}
      >
        <ChartTooltip
          value={tooltipData}
          showRelativeChanges={showRelativeChanges}
          bucket={bucket}
        />
      </TooltipInPortal>
    );

  return {
    containerRef,
    renderTooltip,
    showTooltip,
    hideTooltip,
    tooltipOpen,
    tooltipData,
    tooltipLeft,
    tooltipTop,
  };
};
