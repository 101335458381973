import React from 'react';

import { Button, CardContent, Popover } from '@material-ui/core';

import { useAlert } from '@spotify-confidence/core-react';

type CopySQLButtonProps = {
  disabled?: boolean;
  roleArn?: string;
};

const renderSqlSnippet = (roleArn: string | undefined) => {
  const roleParts = (roleArn ?? '').split('/');
  const roleName = roleParts.length > 1 ? roleParts[1] : '<ROLE>';
  return `CREATE database confidence;
  CREATE SCHEMA confidence;
  CREATE USER "IAMR:${roleName}" PASSWORD DISABLE;
  GRANT ALL ON SCHEMA confidence TO "IAMR:${roleName}";
  GRANT ALL ON ALL TABLES IN SCHEMA confidence TO "IAMR:${roleName}";
  ALTER DEFAULT PRIVILEGES FOR USER "IAMR:${roleName}" IN SCHEMA confidence GRANT ALL ON TABLES TO "IAMR:${roleName}";`;
};

export const CopySQLButton = ({ disabled, roleArn }: CopySQLButtonProps) => {
  const alert = useAlert();
  const [anchorEl, setAnchorEl] = React.useState<HTMLButtonElement | null>(
    null,
  );

  const openPopover = (event: React.MouseEvent<HTMLButtonElement>) => {
    if (!disabled) {
      setAnchorEl(event.currentTarget);
    }
  };

  const closePopover = () => {
    setAnchorEl(null);
  };

  const sqlSnippet = React.useMemo(() => {
    return renderSqlSnippet(roleArn);
  }, [roleArn]);

  const copySqlSnippet = React.useCallback(() => {
    try {
      navigator.clipboard.writeText(sqlSnippet);
      alert.post({
        severity: 'success',
        message: 'The SQL code was copied to the clipboard',
        display: 'transient',
      });
    } catch {
      alert.post({
        severity: 'error',
        message: 'The SQL code could not be copied to the clipboard',
        display: 'transient',
      });
    }
  }, [sqlSnippet]);

  const open = Boolean(anchorEl);

  return (
    <>
      <Popover
        open={open}
        anchorEl={anchorEl}
        onClose={closePopover}
        style={{ pointerEvents: 'none' }}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
      >
        <CardContent>
          <pre>
            <code>{sqlSnippet}</code>
          </pre>
        </CardContent>
      </Popover>
      <Button
        size="small"
        variant="outlined"
        disabled={disabled}
        onClick={copySqlSnippet}
        onMouseEnter={openPopover}
        onMouseLeave={closePopover}
      >
        Copy SQL
      </Button>
    </>
  );
};
