export const getWorkflowInstanceName = (workflow: string, id: string) =>
  `workflows/${workflow}/instances/${id}`;

export const getIsEditDisabled =
  (disabledStates: string[]) =>
  (
    workflowInstance?: Maybe<{
      state: string;
      lockedDataKeys: string[];
    }>,
    key?: string,
  ) =>
    Boolean(
      disabledStates.includes(workflowInstance?.state!) ||
        workflowInstance?.lockedDataKeys?.includes(key!),
    );
