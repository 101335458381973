import React from 'react';

import { Alert } from '@material-ui/lab';

import {
  DialogBody,
  DialogHeader,
  useDialog,
  useNavigate,
} from '@spotify-confidence/core-react';
import {
  getTypeOrNull,
  useUserDetailsQuery,
} from '@spotify-confidence/plugin-graphql';

import { useRouteRef } from '@backstage/core-plugin-api';

import { usersRouteRef } from '../../../routes';

export const UserDialog = ({ id }: { id: string }) => {
  const { closeDialog } = useDialog();
  const navigate = useNavigate();
  const usersRoute = useRouteRef(usersRouteRef);
  const name = `users/${id}`;
  const { data, error } = useUserDetailsQuery({
    variables: { name },
  });

  const handleClose = () => {
    closeDialog();
    navigate(usersRoute());
  };

  const user = getTypeOrNull(data?.user, 'IamV1User');
  return (
    <>
      <DialogHeader
        title={user?.fullName}
        subTitle={user?.email}
        onClose={handleClose}
      />
      <DialogBody autoHeight>
        {error && <Alert severity="error">{error.message}</Alert>}
      </DialogBody>
    </>
  );
};
