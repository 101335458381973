import React from 'react';

import {
  FilterListProvider,
  InlineDropdown,
  MultipleNominalOperator,
  MultipleValue,
  QueryRuleDefinition,
} from '@spotify-confidence/core-react';

const stateRule: QueryRuleDefinition<MultipleValue<string>> = {
  id: 'state',
  displayName: 'State',
  defaultValue: ['draft', 'live', 'ended'],
  operators: [MultipleNominalOperator.IS_ANY_OF],
  renderEditComponent: props => (
    <InlineDropdown
      {...props}
      options={['draft', 'live', 'ended', 'archived']}
      multiple
    />
  ),
};

const outcome: QueryRuleDefinition<MultipleValue<string>> = {
  id: 'moduleData.decision.outcome',
  displayName: 'Outcome',
  operators: [MultipleNominalOperator.IS_ANY_OF],
  renderEditComponent: props => (
    <InlineDropdown
      {...props}
      options={['shipped', 'abandoned', 'aborted', 'restarted']}
      multiple
    />
  ),
};

const defaultWorkFlowFilters = [stateRule, outcome];

export const DefaultWorkflowFilterState = ({
  children,
  additionalFilters = [],
  storageKey,
}: React.PropsWithChildren<{
  storageKey: string;
  additionalFilters?: QueryRuleDefinition<any>[];
}>) => (
  <FilterListProvider
    storageKey={storageKey}
    children={children}
    filters={[...defaultWorkFlowFilters, ...additionalFilters]}
    searchField="displayName"
  />
);
