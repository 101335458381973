import React from 'react';

import { useTheme } from '@material-ui/core';

import { extent } from 'd3';
import { eachDayOfInterval, eachHourOfInterval, isSameHour } from 'date-fns';
import isSameDay from 'date-fns/isSameDay';
import _ from 'lodash';

import { BucketingTemporalUnit } from '../../../../../MetricsModule';
import { ResultData } from '../types';
import { getResultColor } from './utils';

export const SignificanceGradient = ({
  results = [],
  bucket = BucketingTemporalUnit.DAYS,
  id = _.uniqueId('SignificanceGradient'),
  ...gradientProps
}: React.SVGAttributes<SVGLinearGradientElement> & {
  results?: ResultData[];
  bucket?: BucketingTemporalUnit;
  id?: string;
}) => {
  const theme = useTheme();

  // Colors
  const colorGradient = React.useMemo(() => {
    const domain = extent(results, r =>
      r.timeLabel ? new Date(r.timeLabel) : new Date(),
    ) as [Date, Date];
    const expectedEntries =
      bucket === BucketingTemporalUnit.HOURS
        ? eachHourOfInterval({ start: domain[0], end: domain[1] })
        : eachDayOfInterval({ start: domain[0], end: domain[1] });

    return expectedEntries.reduce<
      { entries: number; size: number; offset: number; stopColor: string }[]
    >((gradientArray, timeEntry) => {
      const result = results.find(
        r =>
          r.timeLabel &&
          (bucket === BucketingTemporalUnit.HOURS
            ? isSameHour(new Date(r.timeLabel), timeEntry)
            : isSameDay(new Date(r.timeLabel), timeEntry)),
      );

      const lastItem = _.last(gradientArray);
      if (result) {
        const color = getResultColor(result, theme);

        if (lastItem?.stopColor === color) {
          lastItem.entries++;
          lastItem.size = lastItem.entries / expectedEntries.length;
        } else {
          return gradientArray.concat({
            offset: lastItem ? lastItem.size + lastItem.offset : 0,
            size: 1 / expectedEntries.length,
            entries: 1,
            stopColor: color,
          });
        }
      } else if (lastItem) {
        lastItem.entries++;
        lastItem.size = lastItem.entries / expectedEntries.length;
      } else {
        return gradientArray.concat({
          offset: 0,
          size: 1 / expectedEntries.length,
          entries: 1,
          stopColor: theme.palette.text.disabled,
        });
      }
      return gradientArray;
    }, []);
  }, [theme, bucket, results]);

  return (
    <linearGradient id={id} {...gradientProps}>
      {colorGradient.map((stop, index) => {
        return (
          <React.Fragment key={`stop-${index}`}>
            <stop
              offset={`${_.round(stop.offset * 100)}%`}
              stopColor={stop.stopColor}
            />
            <stop
              offset={`${_.round((stop.size + stop.offset) * 100)}%`}
              stopColor={stop.stopColor}
            />
          </React.Fragment>
        );
      })}
    </linearGradient>
  );
};
