import React, { Fragment } from 'react';
import { useParams } from 'react-router-dom';

import { Box, Button, Card, CardContent, Typography } from '@material-ui/core';
import { Alert } from '@material-ui/lab';

import {
  Breadcrumbs,
  DisabledTypography,
  HeaderContent,
  PageLayout,
  Section,
  SectionEmptyState,
  extractLastNameComponent,
  useDialog,
  useRecentlyVisited,
} from '@spotify-confidence/core-react';
import {
  EventQuery,
  EventSchemaEntryFragment,
} from '@spotify-confidence/plugin-graphql';
import {
  PermissionUtils,
  ResourcePermissionsButton,
} from '@spotify-confidence/plugin-permissions-react';

import { Link } from '@backstage/core-components';
import { useRouteRef } from '@backstage/core-plugin-api';

import { eventIndexRouteRef } from '../../../routes';
import { DroppedChip } from '../../../schema';
import { MissingFieldsWarning } from '../../../schema/components/MissingFieldsWarning';
import { SchemaEditor } from '../../../schema/components/SchemaEditor';
import { SchemaProvider } from '../../../schema/components/SchemaEditor/SchemaContext';
import {
  differenceBetweenSchemas,
  identifiersForSchema,
} from '../../../schema/domain/schema.helpers';
import { useEvent } from '../../hooks/useEvent';
import { EventPageSidebar } from './EventPageSidebar';
import { SchemaDialog } from './SchemaDialog';
import { UsageSection } from './UsageSection';

const SchemaSection = ({
  event,
  draftSchema,
  onEdit,
}: {
  event: Extract<
    EventQuery['eventDefinition'],
    { __typename: 'EventsAdminV1EventDefinition' }
  >;
  draftSchema?: EventSchemaEntryFragment[];
  onEdit?: () => void;
}) => {
  // The difference between the draft schema and the current schema
  const diff =
    (draftSchema && differenceBetweenSchemas(event.schema, draftSchema)) || [];
  const draftIdentities = identifiersForSchema(draftSchema ?? [], diff);

  const getEntryLabels = (identity: string) => {
    if (
      draftIdentities.some(
        draftIdentity =>
          draftIdentity === identity || identity.startsWith(draftIdentity),
      )
    ) {
      return <DroppedChip size="small" />;
    }

    return null;
  };

  return (
    <Section label="Schema" onEditContent={onEdit}>
      <Card>
        <CardContent>
          {(draftSchema || event.schema).length === 0 ? (
            <>
              {onEdit ? (
                <SectionEmptyState
                  title="Empty event schema"
                  description="Edit schema to add properties of the event."
                />
              ) : (
                <DisabledTypography>No schema</DisabledTypography>
              )}
            </>
          ) : (
            <SchemaProvider schema={draftSchema ?? event.schema}>
              <SchemaEditor getEntryLabels={getEntryLabels} readOnly />
            </SchemaProvider>
          )}
        </CardContent>
      </Card>
    </Section>
  );
};

export const EventPage = () => {
  const { id: eventId } = useParams();
  const eventName = `eventDefinitions/${eventId}`;
  const eventIndexRoute = useRouteRef(eventIndexRouteRef);

  const { triggerRecentlyVisited } = useRecentlyVisited();
  const { openDialog } = useDialog();

  React.useEffect(() => {
    if (eventId) {
      triggerRecentlyVisited(eventId);
    }
  }, [eventId]);

  const { event, draftSchema, error, loading, updateSchema } = useEvent({
    name: eventName,
  });

  const canEdit = PermissionUtils.hasRelation(event, 'can_edit');
  const handleEdit = canEdit
    ? () => {
        openDialog({
          dialogProps: {
            fullScreen: true,
          },
          content: (
            <SchemaDialog
              eventName={eventName}
              draftSchema={draftSchema}
              updateSchema={updateSchema}
            />
          ),
        });
      }
    : undefined;

  return (
    <PageLayout
      isLoading={loading}
      title={extractLastNameComponent(event?.name)}
      headerBreadcrumbs={
        <Breadcrumbs>
          <Link to={eventIndexRoute()}>Events</Link>
          <Typography>{extractLastNameComponent(event?.name)}</Typography>
        </Breadcrumbs>
      }
      headerContent={
        <HeaderContent>
          <ResourcePermissionsButton name={eventName} />
        </HeaderContent>
      }
      sideBarRight={event && <EventPageSidebar eventDefinition={event} />}
    >
      {error && <Alert severity="error">{error.message}</Alert>}
      {event && (
        <Fragment>
          {draftSchema && (
            <Box mb={4}>
              <MissingFieldsWarning
                actions={
                  handleEdit && (
                    <Button
                      variant="outlined"
                      color="inherit"
                      onClick={handleEdit}
                      size="small"
                    >
                      Update
                    </Button>
                  )
                }
              />
            </Box>
          )}
          <UsageSection event={event.name} />
          <SchemaSection
            event={event}
            draftSchema={draftSchema}
            onEdit={handleEdit}
          />
        </Fragment>
      )}
    </PageLayout>
  );
};
