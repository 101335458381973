import React from 'react';

import {
  MenuItem,
  TextField,
  Tooltip,
  Typography,
  styled,
} from '@material-ui/core';
import InfoOutlined from '@material-ui/icons/InfoOutlined';

import { FormFieldsBox, PercentageInput } from '@spotify-confidence/core-react';
import {
  MetricsV1MetricInput,
  MetricsV1MetricPreferredDirection as PreferredDirection,
} from '@spotify-confidence/plugin-graphql';
import _ from 'lodash';

type MetricDefaultsFormProps = {
  value: MetricsV1MetricInput;
  onChange: (newInput: MetricsV1MetricInput) => void;
};

const TooltipIcon = styled(InfoOutlined)({
  marginLeft: '0.5em',
  verticalAlign: 'sub',
});

export const MetricDefaultsForm = ({
  value,
  onChange,
}: MetricDefaultsFormProps) => {
  const defaultEffectSizePercentage = value.defaultEffectSize?.value
    ? Number(value.defaultEffectSize.value) * 100
    : undefined;

  const handleDefaultEffectSizeChange = (newValue?: number) => {
    const convertedValue = _.isNil(newValue)
      ? undefined
      : String(Number(newValue) / 100);
    onChange({ ...value, defaultEffectSize: { value: convertedValue } });
  };

  return (
    <FormFieldsBox>
      <TextField
        select
        label="Preferred direction"
        name="preferred-direction"
        value={value.preferredDirection || ''}
        onChange={e =>
          onChange({
            ...value,
            preferredDirection:
              (e.target.value as PreferredDirection) || undefined,
          })
        }
        variant="outlined"
        SelectProps={{
          displayEmpty: true,
        }}
        helperText="The direction this metric should move in for a change to be considered positive."
      >
        <MenuItem value="">None</MenuItem>
        {Object.values(PreferredDirection).map(direction => (
          <MenuItem value={direction}>
            {_.upperFirst(direction.toLocaleLowerCase())}
          </MenuItem>
        ))}
      </TextField>
      <PercentageInput
        label="Threshold"
        name="effect-size"
        type="number"
        value={defaultEffectSizePercentage}
        onChange={handleDefaultEffectSizeChange}
        variant="outlined"
        helperText={
          <>
            Set the lowest amount of change required for it to be relevant. For
            example, if you would want to act on something as long as this
            metric changes by at least 3%, that is your threshold.
            <Tooltip
              arrow
              title={
                <>
                  <Typography variant="body2" paragraph>
                    <strong>As a success metric:</strong> The change is relevant
                    if the metric moves at least 3% in the preferred direction
                  </Typography>
                  <Typography variant="body2">
                    <strong>As a guardrail metric:</strong> The change is
                    relevant if the metric moves at least 3% in the
                    non-preferred direction
                  </Typography>
                </>
              }
            >
              <TooltipIcon fontSize="small" />
            </Tooltip>
          </>
        }
      />
    </FormFieldsBox>
  );
};
