import React, { useEffect, useReducer } from 'react';
import { useAsyncFn } from 'react-use';

import {
  Button,
  DialogActions,
  DialogContent,
  TextField,
} from '@material-ui/core';
import { Alert, Autocomplete } from '@material-ui/lab';

import {
  DialogHeader,
  useAlert,
  useDialog,
} from '@spotify-confidence/core-react';
import {
  RoleFragment,
  getError,
  getTypeOrNull,
  useListRolesQuery,
} from '@spotify-confidence/plugin-graphql';
import { IdentityAutocomplete } from '@spotify-confidence/plugin-permissions-react';

/**
 * Value for a policy definition.
 */
type Value = {
  identities: string[];
  roles: RoleFragment[];
};

interface PolicyDialogProps {
  // The title of the dialog
  title: string;

  // The action for the dialog; save or create
  action: string;

  // Value of the policy
  value: Value;

  // When the user hits the action button, this function is called
  // with the new value.  It should return a promise that resolves
  // when the action is complete, at which point the dialog will
  // close.
  onAction: (value: Value) => Promise<void>;
}

// Actions
interface SetIdentitiesAction {
  type: 'set-identities';
  identities: string[];
}

interface SetRolesAction {
  type: 'set-roles';
  roles: RoleFragment[];
}

type Action = SetIdentitiesAction | SetRolesAction;

/**
 * Dialog for creating or editing a policy.
 *
 * The dialog will close itself when the action is complete.
 */
export function PolicyDialog(props: PolicyDialogProps) {
  const { title, value, action: actionTitle, onAction } = props;
  const { closeDialog } = useDialog();
  const alert = useAlert();

  const [{ roles, identities }, dispatch] = useReducer(
    (state: Value, action: Action) => {
      switch (action.type) {
        case 'set-identities':
          return { ...state, identities: action.identities };
        case 'set-roles':
          return { ...state, roles: action.roles };
        default:
          throw new Error();
      }
    },
    value,
  );

  const [{ loading, error }, handleAction] = useAsyncFn(
    async (result: Value) => {
      await onAction(result);
      closeDialog();
    },
    [onAction],
  );

  useEffect(() => {
    if (error) {
      alert.post({
        message: error.message,
        severity: 'error',
      });
    }
  }, [error]);

  const { data } = useListRolesQuery();
  const allRoles =
    getTypeOrNull(data?.roles, 'IamV1ListRolesResponse')?.roles ?? [];
  const rolesError = getError(data?.roles);

  return (
    <React.Fragment>
      <DialogHeader title={title} onClose={closeDialog} />
      <DialogContent>
        {rolesError && <Alert severity="error">{rolesError.message}</Alert>}
        <IdentityAutocomplete
          multiple
          value={identities}
          onChange={(_e, newIdentities) =>
            dispatch({
              type: 'set-identities',
              identities: newIdentities ?? [],
            })
          }
          disabledOptions={identities}
          label="Principals"
          placeholder="Add people and groups"
          margin="normal"
        />
        <Autocomplete
          multiple
          options={allRoles}
          value={roles}
          onChange={(_, newRoles) =>
            dispatch({ type: 'set-roles', roles: newRoles })
          }
          getOptionLabel={option => option.displayName}
          getOptionDisabled={option => roles.includes(option)}
          getOptionSelected={(option, v) => option.name === v?.name}
          renderInput={params => (
            <TextField
              {...params}
              label="Roles"
              variant="outlined"
              placeholder="Add roles"
              helperText="Roles that will be granted to the principals"
              margin="normal"
            />
          )}
        />
      </DialogContent>
      <DialogActions>
        <Button variant="text" onClick={closeDialog}>
          Cancel
        </Button>
        <Button
          variant="contained"
          color="primary"
          disabled={roles.length === 0 || identities.length === 0 || loading}
          onClick={() => handleAction({ roles, identities })}
        >
          {actionTitle}
        </Button>
      </DialogActions>
    </React.Fragment>
  );
}
