import React, { useEffect } from 'react';

import { useAuth } from '@spotify-confidence/core-react';
import { useConfidence } from '@spotify-confidence/react';

export function FlagContext({ children }: React.PropsWithChildren<{}>) {
  const { user } = useAuth();
  const confidence = useConfidence();

  useEffect(() => {
    confidence.setContext({
      ...user,
    });
  }, [user]);

  // TODO wrap children in a ConfidenceProvider.WithContext({...user}) provider
  return <>{children}</>;
}
