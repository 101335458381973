import React from 'react';

import { MetricScheduleFragment } from '@spotify-confidence/plugin-graphql';
import { WorkflowInstanceCheck } from '@spotify-confidence/plugin-workflows';

import { Metric } from './types';

export type MetricDetails = Metric & {
  check?: WorkflowInstanceCheck;
  scheduleData?: MetricScheduleFragment;
};
export type DetailsPerMetric = Map<string, MetricDetails>;

const MetricDetailsContext = React.createContext<DetailsPerMetric>(new Map());

export const MetricDetailsProvider = ({
  children,
  metrics = [],
  checks = [],
}: React.PropsWithChildren<{
  metrics?: Metric[];
  checks?: WorkflowInstanceCheck[];
}>) => {
  const [MetricDetails, setMetricDetails] = React.useState<DetailsPerMetric>(
    new Map(),
  );

  React.useEffect(() => {
    if (metrics.length) {
      setMetricDetails(currentDetails => {
        const newMap = new Map(currentDetails);
        metrics.forEach(m => {
          const check = checks.find(c =>
            c.labels.some(
              label => label.key === 'metric' && label.value === m.metric,
            ),
          );
          if (m.metric && !newMap.has(m.metric)) {
            newMap.set(m.metric, { ...m, check });
          }
        });
        return newMap;
      });
    }
  }, [metrics, checks]);

  return (
    <MetricDetailsContext.Provider value={MetricDetails}>
      {children}
    </MetricDetailsContext.Provider>
  );
};

export const useMetricDetails = () => {
  const context = React.useContext(MetricDetailsContext);

  if (!context) {
    throw new Error('Missing MetricDetailsProvider.');
  }

  return context;
};
