import React from 'react';

import {
  Box,
  Checkbox,
  FormControl,
  FormControlLabel,
  FormHelperText,
  Tooltip,
  Typography,
  makeStyles,
} from '@material-ui/core';
import HelpOutlineIcon from '@material-ui/icons/HelpOutline';

import { ListMatcherType } from '../../../domain/targeting/targeting.model';

export type OperatorExtraConfigProps = {
  extraConfig: {
    listMatcherType?: ListMatcherType;
  };
  onExtraConfigChange: (
    extraConfig: OperatorExtraConfigProps['extraConfig'],
  ) => void;
};

const useStyles = makeStyles(() => ({
  moreInfo: {
    display: 'flex',
    gap: 4,
    alignItems: 'center',
  },
}));

export function OperatorExtraConfig({
  extraConfig,
  onExtraConfigChange,
}: OperatorExtraConfigProps) {
  const classes = useStyles();
  const listMatcherEnabled = extraConfig.listMatcherType === 'All';

  return (
    <Box p={1}>
      <FormControl>
        <FormControlLabel
          control={
            <Checkbox
              checked={listMatcherEnabled}
              color="default"
              onChange={e =>
                onExtraConfigChange({
                  listMatcherType: e.target.checked ? 'All' : undefined,
                })
              }
            />
          }
          label="All match"
        />
        <Tooltip
          placement="right-end"
          title={
            <Box p={1}>
              <Typography color="inherit" variant="body1" paragraph>
                Matching rule for multiple values
              </Typography>
              <Typography color="inherit" variant="subtitle2">
                If the field from the evaluation context contains a list of
                values "any" match rule will be applied by default. Enable{' '}
                <b>All match</b> checkbox to apply "all" match rule instead.
              </Typography>
            </Box>
          }
        >
          <FormHelperText className={classes.moreInfo}>
            Learn more
            <HelpOutlineIcon fontSize="inherit" />
          </FormHelperText>
        </Tooltip>
      </FormControl>
    </Box>
  );
}
