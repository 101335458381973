import React from 'react';

import { DialogActions, DialogContent, TextField } from '@material-ui/core';
import { Alert } from '@material-ui/lab';

import {
  DialogHeader,
  EditButton,
  FormFieldsBox,
  FormSubmitButtons,
  useAlert,
  useDialog,
} from '@spotify-confidence/core-react';
import {
  SegmentFragment,
  getError,
  getTypeOrNull,
  useUpdateSegmentMutation,
} from '@spotify-confidence/plugin-graphql';
import { IdentityAutocomplete } from '@spotify-confidence/plugin-permissions-react';

const AboutDialog = ({ segment }: { segment: SegmentFragment }) => {
  const [owner, setOwner] = React.useState(
    getTypeOrNull(segment.owner, 'IamV1Identity')?.name ?? null,
  );
  const [displayName, setDisplayName] = React.useState(
    segment.displayName ?? '',
  );
  const alert = useAlert();
  const { closeDialog } = useDialog();

  const [updateSegment, { loading: saving, error }] = useUpdateSegmentMutation({
    onCompleted: data => {
      const responseError = getError(data.updateSegment);
      if (responseError) {
        alert.post({
          message: responseError.message,
          severity: 'error',
        });
      } else {
        closeDialog();
      }
    },
  });

  const handleSave = (e: React.ChangeEvent<{}>) => {
    e.preventDefault();
    updateSegment({
      variables: {
        segment: {
          owner,
          displayName,
          name: segment.name,
        },
      },
    });
  };

  return (
    <form onSubmit={handleSave}>
      <DialogHeader title="About" onClose={closeDialog} />
      <DialogContent>
        <FormFieldsBox>
          {error && <Alert severity="error">{error.message}</Alert>}

          <TextField
            variant="outlined"
            margin="dense"
            label="Name"
            value={displayName}
            required
            onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
              setDisplayName(e.target.value)
            }
          />
          <IdentityAutocomplete
            value={owner}
            onChange={(_e, newIdentity) => {
              setOwner(newIdentity);
            }}
            label="Owner"
            required
          />
        </FormFieldsBox>
      </DialogContent>
      <DialogActions>
        <FormSubmitButtons onCancel={closeDialog} loading={saving} />
      </DialogActions>
    </form>
  );
};

export const EditAboutButton = ({
  segment,
  disabled,
}: {
  segment?: Maybe<SegmentFragment>;
  disabled?: boolean;
}) => {
  const { openDialog } = useDialog();
  const handleEditAbout = () => {
    if (segment) {
      openDialog({
        content: <AboutDialog segment={segment} />,
      });
    }
  };

  return (
    <EditButton size="small" onClick={handleEditAbout} disabled={disabled} />
  );
};
