import { FieldFunctionOptions } from '@apollo/client';

// Concatenate the incoming list items with existing list items
// see: https://www.apollographql.com/docs/react/caching/cache-field-behavior/#the-merge-function

export const namedMerger = (key: string) => {
  const namedMerge = (
    existing: any,
    incoming: any,
    { readField, mergeObjects }: FieldFunctionOptions,
  ) => {
    let merged: any[] = [];
    const existingItems =
      typeof existing === 'object' ? existing[key] : undefined;
    if (existingItems && Array.isArray(existingItems)) {
      merged = existing[key].slice(0);
    }

    const itemNameToIndex: Record<string, number> = Object.create(null);
    if (existingItems && Array.isArray(existingItems)) {
      existingItems.forEach((item, index) => {
        const itemName = readField<string>('name', item);
        if (itemName !== undefined) {
          itemNameToIndex[itemName] = index;
        }
      });
    }

    const incomingItems =
      typeof incoming === 'object' ? incoming?.[key] : undefined;
    if (incomingItems && Array.isArray(incomingItems)) {
      incomingItems.forEach(item => {
        const itemName = readField<string>('name', item);
        if (itemName === undefined) {
          return;
        }

        const index = itemNameToIndex[itemName];
        if (index !== undefined) {
          // Merge the new item data with the existing author data.
          merged[index] = mergeObjects(merged[index], item);
        } else {
          // First time we've seen this item in this array.
          itemNameToIndex[itemName] = merged.length;
          merged.push(item);
        }
      });
    }

    return {
      ...incoming,
      [key]: merged,
    };
  };

  return namedMerge;
};
