import React, { useCallback } from 'react';

import { scaleLinear } from '@visx/scale';

import { ResultData } from '../types';

type UseResultChartArgs = {
  height?: number;
  width?: number;
  padding?: Partial<{
    top: number;
    bottom: number;
    left: number;
    right: number;
  }>;
  results: ResultData[];
  showRelativeChanges?: boolean;
};

export function useResultChart({
  height = 0,
  width = 0,
  padding: propsPadding,
  showRelativeChanges,
  results,
}: UseResultChartArgs) {
  const padding = { top: 0, bottom: 0, left: 0, right: 0, ...propsPadding };
  const yMax = Math.max(0, height - padding.top - padding.bottom);
  const xMax = Math.max(0, width - padding.left - padding.right);
  const getLower = useCallback(
    (row: ResultData) =>
      showRelativeChanges
        ? row.result?.estimateRel.lower ?? 0
        : row.result?.estimateAbs.lower ?? 0,
    [showRelativeChanges],
  );
  const getUpper = useCallback(
    (row: ResultData) =>
      showRelativeChanges
        ? row.result?.estimateRel.upper ?? 0
        : row.result?.estimateAbs.upper ?? 0,
    [showRelativeChanges],
  );
  const getEstimate = useCallback(
    (row: ResultData) =>
      showRelativeChanges
        ? row.result?.estimateRel.estimate ?? 0
        : row.result?.estimateAbs.estimate ?? 0,
    [showRelativeChanges],
  );

  const yValues: number[] = React.useMemo(() => {
    function getValues(row: ResultData): number[] {
      if (showRelativeChanges) {
        return [
          row.result?.estimateRel.lower ?? 0,
          row.result?.estimateRel.upper ?? 0,
          row.result?.estimateRel.estimate ?? 0,
        ].map(Math.abs);
      }

      return [
        row.result?.estimateAbs.lower ?? 0,
        row.result?.estimateAbs.upper ?? 0,
        row.result?.estimateAbs.estimate ?? 0,
      ].map(Math.abs);
    }

    return results.flatMap(getValues);
  }, [showRelativeChanges, results]);

  const maxYValue = Math.max(...yValues, 0);

  const yScale = scaleLinear<number>({
    range: [yMax, 0],
    domain: [-maxYValue, maxYValue],
    nice: true,
  });

  return { yMax, xMax, maxYValue, yScale, getLower, getUpper, getEstimate };
}
