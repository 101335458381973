import React from 'react';

import { Button, CircularProgress, TextField } from '@material-ui/core';
import { Alert } from '@material-ui/lab';

import { FormFieldsBox } from '@spotify-confidence/core-react';
import {
  ConnectorsV1EventConnectionBigQueryEventConnectionConfigInput as BigQueryInput,
  useValidateBigQueryEventConnectionConfigMutation,
} from '@spotify-confidence/plugin-graphql';

import { BigQueryConfigForm } from './BigQueryConfigForm';
import { isBigQueryConfigValid } from './helpers';

type BigQueryEventConnectionFormProps = {
  value: BigQueryInput;
  onChange: (conf: BigQueryInput) => void;
  disabled?: boolean;
};

export const BigQueryEventConnectionForm = ({
  value,
  onChange,
  disabled,
}: BigQueryEventConnectionFormProps) => {
  const [validate, validation] =
    useValidateBigQueryEventConnectionConfigMutation({
      variables: {
        bigQuery: value,
      },
    });

  const canValidate = Boolean(
    value.tablePrefix && isBigQueryConfigValid(value.bigQueryConfig),
  );

  const handleValidate = async () => {
    try {
      await validate();
    } catch (e) {
      //   We use the error from the validation object
    }
  };

  return (
    <FormFieldsBox>
      <BigQueryConfigForm
        value={value?.bigQueryConfig}
        disabled={disabled}
        onChange={newBigQueryConfig =>
          onChange({
            ...value,
            bigQueryConfig: newBigQueryConfig,
          })
        }
      />
      <TextField
        disabled={disabled}
        label="Table Prefix"
        variant="outlined"
        id="tablePrefix"
        value={value.tablePrefix}
        fullWidth
        helperText="An optional prefix to use for the tables created by this connector."
        onChange={e => onChange({ ...value, tablePrefix: e.target.value })}
      />
      <div>
        <Button
          size="small"
          variant="outlined"
          disabled={!canValidate || validation.loading}
          onClick={handleValidate}
          endIcon={validation.loading && <CircularProgress size="1em" />}
        >
          Validate
        </Button>
      </div>
      {validation.data && (
        <Alert severity="success">Validation successful!</Alert>
      )}
      {validation.error && (
        <Alert severity="error">{validation.error.message}</Alert>
      )}
    </FormFieldsBox>
  );
};
