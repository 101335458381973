import React from 'react';
import { useAsyncFn } from 'react-use';

import { DialogActions, TextField } from '@material-ui/core';
import { Alert } from '@material-ui/lab';

import {
  DialogBody,
  DialogForm,
  DialogHeader,
  FormFieldsBox,
  FormSubmitButtons,
} from '@spotify-confidence/core-react';

type Value = {
  displayName: string;
  description: string;
};

export interface EditGroupDialogProps {
  title: string;
  value?: Value;
  onSubmit: () => Promise<void>;
  onChange: (value: Value) => void;
  onClose?: () => void;
  submitLabel?: string;
}

export function EditGroupDialog({
  title,
  value: initialValue,
  onSubmit,
  onChange,
  onClose,
  submitLabel = 'Create',
}: EditGroupDialogProps) {
  const [dirty, setDirty] = React.useState(false);
  const value = { displayName: '', description: '', ...initialValue };
  const nameIsInvalid = value.displayName.length < 3;

  const [{ loading, error }, submit] = useAsyncFn(async () => {
    await onSubmit();
  }, [onSubmit]);

  const onBlur = React.useCallback(() => {
    setDirty(true);
  }, []);

  return (
    <DialogForm
      onSubmit={e => {
        e.preventDefault();
        submit();
      }}
      data-testid="group-edit-dialog"
    >
      <DialogHeader title={title} />
      <DialogBody>
        {error && <Alert severity="error">{error.message}</Alert>}

        <FormFieldsBox spacing={1}>
          <TextField
            name="name"
            variant="outlined"
            placeholder="A short, but sweet name of the group."
            required
            label="Name"
            error={dirty && nameIsInvalid}
            value={value?.displayName ?? ''}
            onChange={e => onChange({ ...value, displayName: e.target.value })}
            fullWidth
            autoFocus
            autoComplete="off"
            onBlur={onBlur}
            helperText={
              nameIsInvalid && dirty
                ? 'The name should be at least 3 characters long.'
                : ''
            }
            inputProps={{
              'data-lpignore': 'true',
              'data-form-type': 'other',
            }}
          />
          <TextField
            name="description"
            variant="outlined"
            label="Description"
            value={value?.description ?? ''}
            onChange={e => onChange({ ...value, description: e.target.value })}
            fullWidth
          />
        </FormFieldsBox>
      </DialogBody>
      <DialogActions>
        <FormSubmitButtons
          onCancel={onClose}
          loading={loading}
          disabled={loading || nameIsInvalid}
          label={submitLabel}
        />
      </DialogActions>
    </DialogForm>
  );
}
