import React from 'react';

import { Button, MenuItem, TextField, TextFieldProps } from '@material-ui/core';
import Add from '@material-ui/icons/Add';

import { useDialog } from '@spotify-confidence/core-react';
import {
  getTypeOrNull,
  useListEntitiesFieldQuery,
} from '@spotify-confidence/plugin-graphql';

import { mapKeyTypeLabel } from '../../domain/useFormattedEntityType.hook';
import { CreateEntity } from '../EntitiesPage/components/CreateEntity';

type EntityFieldProps = Omit<TextFieldProps, 'onChange'> & {
  onChange: (v: string) => void;
};

export default function EntityField({
  onChange,
  ...fieldProps
}: EntityFieldProps) {
  const { openDialog } = useDialog();
  const { data, loading, refetch } = useListEntitiesFieldQuery();
  const entitiesOptions =
    getTypeOrNull(data?.entities, 'MetricsV1ListEntitiesResponse')?.entities ||
    [];

  function changeEntity(name: string) {
    onChange(name);
  }

  function onCreate(entityName?: string) {
    onChange(entityName || '');
    refetch();
  }

  const createEntity = () => {
    openDialog({
      content: <CreateEntity onCreate={onCreate} />,
    });
  };

  return (
    <>
      <TextField
        name="entity-select"
        select
        fullWidth
        variant="outlined"
        margin="dense"
        {...fieldProps}
        disabled={loading || fieldProps.disabled}
      >
        {entitiesOptions.map(option => (
          <MenuItem
            value={option.name}
            key={option.name}
            onClick={() => changeEntity(option.name)}
          >
            {option.displayName} (
            {mapKeyTypeLabel(option.primaryKeyType).toLowerCase()})
          </MenuItem>
        ))}
        <MenuItem onClick={createEntity}>
          <Button size="small" startIcon={<Add />}>
            Create new entity
          </Button>
        </MenuItem>
      </TextField>
    </>
  );
}
