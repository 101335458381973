import React from 'react';

import { Typography } from '@material-ui/core';

import { getGroupsFromGroupComparisonId } from '../../utils';
import { InlineTreatmentComparison } from '../InlineTreatmentComparison';
import { MetricName } from '../MetricName';
import { GroupableColumn } from '../types';

export const GroupTitle = ({
  groupId,
  groupValue,
}: {
  groupId: GroupableColumn;
  groupValue: string;
}) => {
  if (groupId === 'metric') {
    return <MetricName name={groupValue} hideStatus />;
  } else if (groupId === 'comparison') {
    return (
      <InlineTreatmentComparison
        {...getGroupsFromGroupComparisonId(groupValue)}
      />
    );
  }
  return <Typography variant="h6">{groupValue}</Typography>;
};
