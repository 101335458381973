import React from 'react';

import {
  ColorUtils,
  extractLastNameComponent,
  useWeightedItems,
} from '@spotify-confidence/core-react';
import {
  RuleFragment,
  getTypeOrNull,
} from '@spotify-confidence/plugin-graphql';
import _ from 'lodash';

import {
  DEFAULT_BUCKET_COUNT,
  assignmentWeights,
} from '../../../../domain/assignments.model';
import { VariantWeightInput } from './VariantWeightInput';

type Props = RuleFragment['assignmentSpec'] & {
  onChange: (assignments: RuleFragment['assignmentSpec']) => void;
  disabled?: boolean;
};

type Assignment = RuleFragment['assignmentSpec']['assignments'][number];
type AssignmentWithWeights = Assignment & { weight: number };

function addBucketRanges(assignments: AssignmentWithWeights[]): Assignment[] {
  return assignments.reduce<Assignment[]>((list, current, index) => {
    const lower = list[index - 1] ? list[index - 1].bucketRanges[0].upper : 0;
    const upper = lower + current.weight;
    return list.concat({
      ..._.omit(current, ['weight']),
      bucketRanges: [{ lower, upper }],
    });
  }, []);
}

export const AssignmentsContainer = ({
  onChange,
  disabled = false,
  bucketCount = DEFAULT_BUCKET_COUNT,
  assignments = [],
}: Props) => {
  const assignmentWithWeights = assignments.map(c => ({
    ...c,
    weight: assignmentWeights(c.bucketRanges),
  }));

  const changeWithBucketRanges = (curAssignments: AssignmentWithWeights[]) => {
    onChange({ assignments: addBucketRanges(curAssignments), bucketCount });
  };

  const {
    onItemWeightChange,
    splitWeightEvenly,
    error: weightError,
  } = useWeightedItems<AssignmentWithWeights>(assignmentWithWeights, {
    maxTotal: bucketCount || DEFAULT_BUCKET_COUNT,
    onChange: changeWithBucketRanges,
  });

  return (
    <VariantWeightInput<AssignmentWithWeights>
      title="Split targets between variants"
      value={assignmentWithWeights}
      maxTotal={bucketCount || DEFAULT_BUCKET_COUNT}
      onItemWeightChange={onItemWeightChange}
      splitWeightEvenly={splitWeightEvenly}
      error={weightError}
      renderVariant={v =>
        extractLastNameComponent(
          getTypeOrNull(v.variant?.variant, 'FlagsAdminV1FlagVariant')?.name,
        )
      }
      getVariantColor={v =>
        ColorUtils.stringToColor(
          getTypeOrNull(v.variant?.variant, 'FlagsAdminV1FlagVariant')?.name,
        )
      }
      disabled={disabled}
    />
  );
};
