import React from 'react';

import { getTypeOrNull } from '@spotify-confidence/plugin-graphql';
import _ from 'lodash';

import { RuleViewProps, getRuleTargetingState } from './shared';

export const RuleCardIdentifier = ({ rule, seenAttributes }: RuleViewProps) => {
  const { segment, paused, sticky } = getRuleTargetingState(rule);
  const proportion = Number(segment?.allocation?.proportion?.value || 0);
  const allocatesAll = proportion === 1;

  let forGroupLabel: string;
  if (paused) {
    forGroupLabel = 'previously assigned';
  } else {
    forGroupLabel = allocatesAll ? 'all' : `${_.round(proportion * 100, 2)}%`;
  }

  const seenAttribute = seenAttributes?.find(
    entry => entry.key === rule.targetingKeySelector,
  );
  const entity = getTypeOrNull(
    seenAttribute?.value?.semanticType?.entityReference?.entity,
    'MetricsV1Entity',
  );
  const attributeDisplayName =
    entity?.displayName || seenAttribute?.value?.displayName;
  const targetingKey = rule.targetingKeySelector || 'targeting_key';

  return (
    <>
      <strong>For </strong>
      {forGroupLabel} using identifier{' '}
      {attributeDisplayName
        ? `${attributeDisplayName} (${targetingKey})`
        : targetingKey}
      {!paused && sticky && ' or previously assigned'}
    </>
  );
};
