import React from 'react';

import { Card, CardContent, makeStyles } from '@material-ui/core';
import { Alert } from '@material-ui/lab';

import { Section, useLocalStorage } from '@spotify-confidence/core-react';
import { useWorkflowInstanceContext } from '@spotify-confidence/plugin-workflows';

import { resultUtils } from '.';
import { BucketingTemporalUnit } from '../../../MetricsModule';
import { defaultGroupingOptions } from './GroupingSelect';
import {
  DownloadResultsButton,
  MetricResultTableGrouping,
  ResultVisualization,
  ResultVisualizationType,
  getRelativeBounds,
  useResultTable,
} from './MetricResultTable';
import { MetricResultTable } from './MetricResultTable/MetricResultTable';
import { ResultTableControls } from './MetricResultTable/ResultTableControls';
import { Annotation, MetricAnalysisType, StatsHypothesisResult } from './types';
import { getRelativeCIBounds } from './utils';

const useStyles = makeStyles({
  card: {
    // TODO: make the table properly responsive instead
    overflow: 'auto',
  },
});

type ResultSectionProps = {
  results?: StatsHypothesisResult[];
  sequential?: boolean;
  bucket?: BucketingTemporalUnit;
  groupAmount?: number;
  annotations?: Record<string, Annotation[]>;
};

const defaultColumns = [
  'metric',
  'baselineMean',
  'comparedMean',
  'changeValue',
  'ci',
  'Status',
];

const fixedHorizonColumns = defaultColumns.concat('lowerBound', 'upperBound');
const sequentialColumns = defaultColumns.concat('timeLabel', 'Expand');

export const ResultSection = ({
  results,
  sequential,
  bucket,
  annotations,
  groupAmount = 2,
}: ResultSectionProps) => {
  const { name } = useWorkflowInstanceContext();
  const key = (item: string) => `result-section-${item}:${name}`;

  const classes = useStyles();
  const [visibleColumns, setVisibleColumns] = useLocalStorage<string[]>(
    key('visibleColumns'),
    sequential ? sequentialColumns : fixedHorizonColumns,
  );
  const [grouping, setGrouping] = useLocalStorage<MetricResultTableGrouping>(
    key('grouping'),
    groupAmount < 3 ? defaultGroupingOptions[1] : defaultGroupingOptions[0],
  );
  const [visualizationType, setVisualizationType] =
    useLocalStorage<ResultVisualizationType>(
      key('visualizationType'),
      'interval plot',
    );
  const [showRelativeChanges, setShowRelativeChanges] =
    useLocalStorage<boolean>(key('showRelativeChanges'), true);
  const downloadableRef = React.useRef(null);

  const handleGroupingChange = (newGrouping: MetricResultTableGrouping) => {
    setGrouping(newGrouping);
  };

  const [data, relativeBounds] = React.useMemo(() => {
    const bounds = (results ?? []).flatMap(({ metricDetails, result }) => {
      return (
        result?.flatMap(({ lastResult }) => {
          return (
            Math.max(
              // max value from CI bounds
              getRelativeCIBounds(
                lastResult?.differenceEstimateRel?.estimate,
                lastResult?.differenceEstimateRel?.upper,
                lastResult?.differenceEstimateRel?.lower,
              ),
              // max value from NIM
              metricDetails.metricType === MetricAnalysisType.GUARDRAIL
                ? Math.abs(metricDetails.plannedEffectSize ?? 0)
                : 0,
            ) * 100
          );
        }) ?? 0
      );
    });
    return [
      resultUtils.convertAnalyzeResultToResultData(results, annotations),
      getRelativeBounds(bounds),
    ];
  }, [results]);

  const table = useResultTable({
    data,
    relativeBounds,
    sequential,
    showRelativeChanges,
    bucket,
    visibleColumns,
    grouping,
    visualizationType,
  });

  return (
    <Section
      label="Results"
      zoneContext="results"
      actions={
        <DownloadResultsButton
          table={table}
          downloadableRef={downloadableRef}
        />
      }
    >
      {!results && <Alert severity="info">No results available</Alert>}
      {results && (
        <>
          <ResultTableControls
            sequential={sequential}
            table={table}
            visualizationType={visualizationType}
            grouping={grouping}
            visibleColumns={visibleColumns}
            handleVisibleColumnsChange={setVisibleColumns}
            showRelativeChanges={showRelativeChanges}
            handleShowRelativeChange={setShowRelativeChanges}
            handleVisualizationTypeChange={setVisualizationType}
            handleGroupingChange={handleGroupingChange}
          />
          <ResultVisualization
            type={visualizationType}
            table={table}
            ref={downloadableRef}
          />
          <Card className={classes.card}>
            <CardContent>
              <MetricResultTable table={table} />
            </CardContent>
          </Card>
        </>
      )}
    </Section>
  );
};
