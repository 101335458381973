import React from 'react';
import { InView } from 'react-intersection-observer';

import { Table, TableBody, TableCell, TableRow } from '@material-ui/core';

import {
  DateUtils,
  MediumScreenCell,
  PageLayout,
  ResourceTableRow,
  StickyTableContainer,
  StickyTableHead,
  extractLastNameComponent,
} from '@spotify-confidence/core-react';
import {
  getError,
  getTypeOrNull,
  isType,
  useListWorkflowsQuery,
} from '@spotify-confidence/plugin-graphql';

import { Link } from '@backstage/core-components';
import { useRouteRef } from '@backstage/core-plugin-api';

import { workflowRouteRef } from '../../../routes';

export const WorkflowsPage = ({}) => {
  const workflowRoute = useRouteRef(workflowRouteRef);

  const { data, loading, error, fetchMore } = useListWorkflowsQuery({});

  const workflowsError = getError(data?.workflows);
  const { workflows = [], nextPageToken } =
    getTypeOrNull(data?.workflows, 'WorkflowV1ListWorkflowsResponse') || {};

  return (
    <PageLayout narrow title="Workflows">
      <StickyTableContainer loading={loading} error={error ?? workflowsError}>
        <Table>
          <StickyTableHead>
            <TableRow>
              <TableCell>Name</TableCell>
              <TableCell>Version</TableCell>
              <MediumScreenCell align="right">Updated</MediumScreenCell>
            </TableRow>
          </StickyTableHead>
          <TableBody>
            {workflows.map(workflow => (
              <ResourceTableRow
                key={workflow.name}
                routeRef={workflowRouteRef}
                name={workflow.name}
                data-testid={workflow.name}
              >
                <TableCell>
                  <Link
                    to={workflowRoute({
                      id: extractLastNameComponent(workflow.name)!,
                    })}
                  >
                    {workflow.displayName}
                  </Link>
                </TableCell>
                <TableCell>
                  {isType(workflow.currentVersion, 'WorkflowV1WorkflowVersion')
                    ? extractLastNameComponent(workflow.currentVersion.name)
                    : 'No version set'}
                </TableCell>
                <MediumScreenCell width={200} align="right">
                  {DateUtils.xAgo(new Date(workflow.updateTime))}
                </MediumScreenCell>
              </ResourceTableRow>
            ))}
          </TableBody>
        </Table>
        {data && (
          <InView
            onChange={async inView => {
              if (inView && nextPageToken) {
                await fetchMore({
                  variables: {
                    pageToken: nextPageToken,
                  },
                });
              }
            }}
          />
        )}
      </StickyTableContainer>
    </PageLayout>
  );
};
