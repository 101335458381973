import { useConfidence } from '@spotify-confidence/react';

import { MetricSection } from '../types';
import { timeWindowSection as timeWindowSectionV1 } from './v1';
import { timeWindowSection as timeWindowSectionV2 } from './v2';

export function useTimeWindowSection(): MetricSection {
  const enabled = useConfidence().useFlag('open-ended-metrics.enabled', false);
  return enabled ? timeWindowSectionV2 : timeWindowSectionV1;
}
