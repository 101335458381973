import React from 'react';

import { Table, TableBody, TableCell, TableRow } from '@material-ui/core';

import {
  ACTION_BAR_HEIGHT,
  MediumScreenCell,
  StickyTableContainer,
  StickyTableHead,
  extractWorkflowFromInstanceName,
} from '@spotify-confidence/core-react';
import { WorkflowInstanceExposureHeaderCell } from '@spotify-confidence/plugin-metrics';
import {
  ViewMode,
  WorkflowInstanceTimeline,
} from '@spotify-confidence/plugin-workflows-module-planning';

import { useWorkflowExtension } from '../../workflowExtensions';
import {
  IntermediateRenderComponentProps,
  WorkflowInstanceListItem,
  useListWorkflowInstances,
} from '../workflowPage';
import { DefaultWorkflowTableRow } from './DefaultWorkflowTableRow';

const WorkflowInstanceTableRow = ({
  workflowInstance,
}: {
  workflowInstance: WorkflowInstanceListItem;
}) => {
  const workflowId = extractWorkflowFromInstanceName(workflowInstance.name)!;
  const extension = useWorkflowExtension({ workflowId });

  const TableRowComponent =
    extension?.workflowTableRowComponent ?? DefaultWorkflowTableRow;

  return <TableRowComponent workflowInstance={workflowInstance} />;
};

export const WorkflowInstanceTable = ({
  mode = 'Table',
  topOffset = ACTION_BAR_HEIGHT,
  workflowId,
  filter,
  emptyStateProps,
}: {
  mode?: ViewMode;
  topOffset?: number;
  workflowId?: string;
  filter?: string;
  emptyStateProps?: IntermediateRenderComponentProps['emptyStateProps'];
}) => {
  const {
    workflowInstances,
    IntermediateRenderComponent,
    InfiniteScroll,
    showLoading,
  } = useListWorkflowInstances({
    workflowId,
    filter,
  });

  if (IntermediateRenderComponent) {
    return (
      <IntermediateRenderComponent
        showLoading={showLoading}
        emptyStateProps={emptyStateProps}
      />
    );
  }

  if (mode === 'Timeline') {
    return (
      <WorkflowInstanceTimeline
        items={workflowInstances}
        topOffset={topOffset}
        loading={showLoading}
      >
        <InfiniteScroll />
      </WorkflowInstanceTimeline>
    );
  }

  return (
    <StickyTableContainer loading={showLoading}>
      <Table>
        <StickyTableHead topOffset={topOffset}>
          <TableRow>
            <TableCell width="40%">Name</TableCell>
            <TableCell width="30%">Status</TableCell>
            {/* TODO: add support for configure header */}
            {workflowId === 'rollout' ? (
              <MediumScreenCell width="10%">Reach</MediumScreenCell>
            ) : (
              <MediumScreenCell />
            )}
            <WorkflowInstanceExposureHeaderCell width="20%" />
            <MediumScreenCell style={{ minWidth: 140 }}>
              Updated
            </MediumScreenCell>
          </TableRow>
        </StickyTableHead>
        <TableBody>
          {workflowInstances.map(workflowInstance => (
            <WorkflowInstanceTableRow
              workflowInstance={workflowInstance}
              key={workflowInstance.name}
            />
          ))}
        </TableBody>
      </Table>
      <InfiniteScroll />
    </StickyTableContainer>
  );
};
