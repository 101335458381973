import React from 'react';

import { Chip, ChipProps, LinearProgress, makeStyles } from '@material-ui/core';

const useStyles = makeStyles({
  loadingLabel: {
    opacity: 0.2,
    width: 120,
  },
});

export const LoadingChip = ({
  loading,
  label,
  ...props
}: ChipProps & { loading?: boolean }) => {
  const classes = useStyles();
  return (
    <Chip
      {...props}
      classes={{
        label: loading ? classes.loadingLabel : undefined,
      }}
      label={loading ? <LinearProgress color="secondary" /> : label}
    />
  );
};
