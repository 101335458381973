import React from 'react';

import {
  Checkbox,
  FormControlLabel,
  MenuItem,
  MenuList,
  Typography,
  makeStyles,
} from '@material-ui/core';

import { capitalize } from 'lodash';

import { RenderEditComponentProps } from '../filterBuilder.types';

const useDropdownStyles = makeStyles(theme => ({
  checkboxRoot: {
    padding: theme.spacing(0.5),
    marginRight: theme.spacing(1),
  },
  filterFieldChipRoot: {
    margin: 0,
  },
}));

interface SharedProps<T> {
  options: T[];
  multiple?: boolean;
  getOptionId?: (option: T) => string | number;
  renderOption?: (option: T) => React.ReactNode;
}

interface InlineDropdownSingleValueProps<T>
  extends SharedProps<T>,
    RenderEditComponentProps<T> {
  multiple?: false;
  renderValue?: (value: T) => React.ReactNode;
}

interface InlineDropdownMultipleValueProps<T>
  extends SharedProps<T>,
    RenderEditComponentProps<T[]> {
  multiple: true;
  renderValue?: (values: T[]) => React.ReactNode;
}

export type InlineDropdownProps<T> =
  | InlineDropdownMultipleValueProps<T>
  | InlineDropdownSingleValueProps<T>;

function isMultiSelect<T>(
  props: InlineDropdownProps<T>,
): props is InlineDropdownMultipleValueProps<T> {
  return !!(props as InlineDropdownMultipleValueProps<T>).multiple;
}

function defaultRenderOption(option: any) {
  return capitalize(option);
}

function defaultGetOptionId(option: any) {
  return option;
}

export function InlineDropdown<T extends any>(props: InlineDropdownProps<T>) {
  const classes = useDropdownStyles();
  const {
    options,
    getOptionId = defaultGetOptionId,
    renderOption = defaultRenderOption,
  } = props;

  function handleChangeValue(option: T) {
    return () => {
      if (isMultiSelect(props)) {
        const multiValue = props.value || [];
        if (isSelected(option)) {
          props.onChange(
            multiValue.filter(
              selectedValue =>
                getOptionId(selectedValue) !== getOptionId(option),
            ),
          );
        } else {
          props.onChange(multiValue.concat(option));
        }
      } else {
        props.onChange(option);
      }
    };
  }

  function isSelected(option: T) {
    if (isMultiSelect(props)) {
      const multiValue = props.value || [];
      return multiValue.some(
        selectedValue => getOptionId(selectedValue) === getOptionId(option),
      );
    }
    return props.value && getOptionId(props.value) === getOptionId(option);
  }

  const isAllSelected = options.every(isSelected);

  function toggleAll() {
    if (isMultiSelect(props)) {
      if (isAllSelected) {
        props.onChange([]);
      } else {
        props.onChange(options);
      }
    }
  }

  const multiSelect = isMultiSelect(props);

  return (
    <MenuList disablePadding dense id="menu-list-grow">
      {multiSelect && (
        <MenuItem key="ALL" divider button={false}>
          <FormControlLabel
            control={
              <Checkbox
                classes={{ root: classes.checkboxRoot }}
                size="small"
                onChange={toggleAll}
                checked={isAllSelected}
              />
            }
            label={<Typography variant="body2">Select all</Typography>}
          />
        </MenuItem>
      )}
      {options.map((option: T, index) => (
        <MenuItem key={`${getOptionId(option)}-${index}`} button={false}>
          <FormControlLabel
            control={
              <Checkbox
                classes={{ root: classes.checkboxRoot }}
                size="small"
                onChange={handleChangeValue(option)}
                checked={isSelected(option)}
              />
            }
            label={
              <Typography variant="body2">{renderOption(option)}</Typography>
            }
          />
        </MenuItem>
      ))}
    </MenuList>
  );
}
