import React, { RefObject, useEffect, useRef, useState } from 'react';

import { debounce } from 'lodash';
import ResizeObserver from 'resize-observer-polyfill';

type DimensionsRect = {
  width: number;
  height: number;
};

type Options = {
  measureHeight?: boolean;
  measureWidth?: boolean;
  debounceMs?: number;
  offset?: number;
};

const initialDimensions: DimensionsRect = {
  width: 0,
  height: 0,
};

export const useMeasureDimensions = <T extends HTMLElement>(
  options: Options = {},
  _ref?: RefObject<T | null>,
) => {
  const ref = _ref || React.useRef<T>(null);

  const { measureHeight, measureWidth, debounceMs, offset } = {
    measureHeight: true,
    offset: 5,
    debounceMs: 200,
    ...options,
  };

  const prevDimensionsRef = useRef<DimensionsRect>(initialDimensions);
  const [dimensions, setDimensions] =
    useState<DimensionsRect>(initialDimensions);

  const debouncedUseMeasureRef = useRef(
    debounce(
      ([entry]) => {
        if (!entry.borderBoxSize.length && !entry?.contentRect) {
          return;
        }

        const prevDimensions = prevDimensionsRef.current;
        const newDimensions = {
          width: entry.borderBoxSize[0]?.inlineSize || entry.contentRect.width,
          height: entry.borderBoxSize[0]?.blockSize || entry.contentRect.height,
        };

        const heightChanged =
          Math.abs(prevDimensions.height - newDimensions.height) > offset;
        const widthChanged =
          Math.abs(prevDimensions.width - newDimensions.width) > offset;

        if (
          (measureHeight && heightChanged) ||
          (measureWidth && widthChanged)
        ) {
          setDimensions(newDimensions);
          prevDimensionsRef.current = newDimensions;
        }
      },
      debounceMs,
      { leading: true },
    ),
  );

  useEffect(() => {
    if (ref.current === null) {
      return undefined;
    }

    const resizeObserver = new ResizeObserver(entries =>
      window.requestAnimationFrame(() =>
        debouncedUseMeasureRef.current(entries),
      ),
    );
    resizeObserver.observe(ref.current);

    return () => {
      resizeObserver.disconnect();
    };
  }, []);

  return [ref, dimensions] as const;
};
