import React from 'react';

import { Box, Tooltip, Typography } from '@material-ui/core';

import {
  FlagsAdminV1SegmentState,
  Maybe,
} from '@spotify-confidence/plugin-graphql';
import _ from 'lodash';

import {
  StatusOK,
  StatusPending,
  StatusRunning,
  StatusWarning,
} from '@backstage/core-components';

const getStateConfig = (state?: Maybe<FlagsAdminV1SegmentState>) => {
  const stateConfigs: Record<
    FlagsAdminV1SegmentState,
    {
      description: string;
      State: React.ComponentType;
    }
  > = {
    [FlagsAdminV1SegmentState.Allocated]: {
      description:
        "This segment is ready to be used but can't be changed in order to ensure consistency.",
      State: StatusOK,
    },
    [FlagsAdminV1SegmentState.Archived]: {
      description: 'Archived',
      State: StatusPending,
    },
    [FlagsAdminV1SegmentState.Unallocated]: {
      description:
        'You need to allocate this segment to ensure a consistent audience.',
      State: StatusRunning,
    },
    [FlagsAdminV1SegmentState.Ok]: {
      description: 'This segment is ready to be used.',
      State: StatusOK,
    },
  };
  return state
    ? stateConfigs[state]
    : {
        State: StatusWarning,
        description: '',
      };
};

export const SegmentState = ({
  state,
}: {
  state?: Maybe<FlagsAdminV1SegmentState>;
}) => {
  const { description, State } = getStateConfig(state);
  return (
    <Tooltip title={description} arrow>
      <Box display="inline-flex" alignItems="center" gridGap={4}>
        <State />
        <Typography
          variant="body2"
          component="span"
          data-testid="segment-state"
        >
          {_.capitalize(state ?? 'unknown')}
        </Typography>
      </Box>
    </Tooltip>
  );
};
