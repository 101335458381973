import { RolloutIcon } from '@spotify-confidence/core-react';
import {
  IconChartBar,
  IconChartLine,
  IconEvents,
  IconFlag,
  IconGridView,
  IconLab,
  IconStations,
  IconUser,
} from '@spotify-internal/encore-web';

import { IconComponent } from '@backstage/core-plugin-api';

export type Module =
  | 'metrics'
  | 'abtests'
  | 'rollouts'
  | 'flags'
  | 'segments'
  | 'events'
  | 'analysis'
  | 'insights'
  | 'surfaces';

export const ModuleIcons: Record<Module, IconComponent> = {
  metrics: IconChartLine,
  flags: IconFlag,
  abtests: IconLab,
  rollouts: RolloutIcon,
  events: IconEvents,
  analysis: IconChartBar,
  insights: IconStations,
  segments: IconUser,
  surfaces: IconGridView,
};

export const workflowToIcon = (workflow: string): IconComponent => {
  switch (workflow) {
    default:
    case 'abest':
      return ModuleIcons.abtests;
    case 'rollout':
      return ModuleIcons.rollouts;
    case 'analysis':
      return ModuleIcons.analysis;
  }
};
