import React from 'react';

import {
  Box,
  Button,
  IconButton,
  MenuItem,
  Theme,
  makeStyles,
  styled,
} from '@material-ui/core';
import Cancel from '@material-ui/icons/Cancel';
import ExpandMore from '@material-ui/icons/ExpandMore';

import { ContextMenu, ObjUtils } from '@spotify-confidence/core-react';
import {
  InputMaybe,
  MetricFilterExpressionFragment,
  MetricsV1Expression,
  MetricsV1FilterCriteriaEntryInput,
} from '@spotify-confidence/plugin-graphql';
import { capitalize } from 'lodash';

import { AddCriterionActions } from './AddCriterionActions';
import { Criteria } from './Criteria';
import { SetOp } from './MetricFilters';
import { UseMetricFilterResponse } from './useMetricFilters';

const SetContent = styled('div')(
  ({ theme, root }: { theme: Theme; root: boolean }) => ({
    maxWidth: '100%',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
    rowGap: theme.spacing(1),
    flexWrap: 'wrap',
    borderRadius: theme.shape.borderRadius,
    padding: theme.spacing(root ? 0 : 2),
    border: root ? 'none' : `1px solid ${theme.palette.divider}`,
    flex: 1,
    '& &': {
      rowGap: theme.spacing(2),
      alignItems: 'center',
      flexDirection: 'row',
    },
  }),
);

type SharedProps = {
  path: string;
  factTableName?: string;
  criterias?: InputMaybe<MetricsV1FilterCriteriaEntryInput[]>;
} & Omit<UseMetricFilterResponse, 'filter'>;

const useStyles = makeStyles(theme => ({
  set: {
    position: 'relative',
    display: 'flex',
    justifyContent: 'center',
    flexDirection: 'column',
    width: '100%',
  },
  deleteButton: {
    opacity: 0,
    position: 'absolute',
    right: theme.spacing(0.5),
    top: theme.spacing(0.5),
    transition: theme.transitions.create('opacity', {
      duration: theme.transitions.duration.shortest,
    }),
    '$set:hover &': {
      opacity: 1,
    },
  },
}));

export const Expression = ({
  expression,
  criterias,
  factTableName,
  path,
  ...rest
}: {
  expression: MetricFilterExpressionFragment;
} & SharedProps) => {
  const {
    onAddCriterion,
    onAddSet,
    onDeleteSet,
    onDeleteCriterion,
    onUpdateCriterion,
  } = rest;

  const classes = useStyles();
  if (expression.and || expression.or) {
    const op = Object.keys(ObjUtils.cleanNulls(expression))[0] as SetOp;
    const operands = expression[op]?.operands ?? [];
    const level = path.split('/').length - 1;
    const canBeRemoved = level > 1;
    const canAddSet = level < 2;

    return (
      <Box className={classes.set}>
        {canBeRemoved && (
          <IconButton
            title="Delete set"
            name="delete-set-button"
            className={classes.deleteButton}
            color="inherit"
            onClick={() => onDeleteSet(path)}
          >
            <Cancel color="inherit" />
          </IconButton>
        )}
        <SetContent root={!canBeRemoved}>
          <Operands
            path={path}
            operands={operands}
            criterias={criterias}
            operator={op}
            factTableName={factTableName}
            {...rest}
          />
          <Box display="flex" gridGap={8} pt={1}>
            <AddCriterionActions
              factTableName={factTableName}
              onAdd={c => onAddCriterion(path, c)}
              onAddSet={canAddSet ? () => onAddSet(path) : undefined}
            />
          </Box>
        </SetContent>
      </Box>
    );
  }

  if (expression.ref || expression.not) {
    const ref = expression.not?.ref || expression.ref;
    const criteraValue = (criterias ?? []).find(c => c.key === ref);
    if (!criteraValue || !criteraValue.value)
      return <span>Missing ref: {ref}</span>;
    return (
      <Criteria
        key={ref}
        isNot={!!expression.not}
        critera={criteraValue.value}
        onDelete={() => onDeleteCriterion(criteraValue.key)}
        onChange={(t, isNot) => onUpdateCriterion(criteraValue.key, t, isNot)}
      />
    );
  }

  return null;
};

function SetOperatorPicker({
  operator,
  onChange,
}: {
  operator: string;
  onChange: (o: string) => void;
}) {
  return (
    <ContextMenu
      closeOnSelect
      renderButton={p => (
        <Button
          {...p}
          endIcon={<ExpandMore color="inherit" />}
          size="small"
          variant="text"
        >
          {capitalize(operator)}
        </Button>
      )}
      renderMenu={() =>
        ['or', 'and'].map(option => (
          <MenuItem key={option} divider onClick={() => onChange(option)}>
            {capitalize(option)}
          </MenuItem>
        ))
      }
    />
  );
}

function Operands({
  operands,
  criterias,
  operator,
  factTableName,
  path,
  ...rest
}: {
  operator: string;
  operands: MetricsV1Expression[];
} & SharedProps) {
  return (
    <>
      {operands?.map((o, idx) => {
        const isLastCriterion = idx === operands.length - 1;
        return (
          <React.Fragment key={`op-${idx}`}>
            <Expression
              path={`${path}/${idx}`}
              expression={o}
              factTableName={factTableName}
              criterias={criterias}
              {...rest}
            />
            {!isLastCriterion && (
              <SetOperatorPicker
                onChange={newOp =>
                  rest.onChangeSetOperator(path, newOp as SetOp)
                }
                operator={operator}
              />
            )}
          </React.Fragment>
        );
      })}
    </>
  );
}
