import { extractLastNameComponent } from '@spotify-confidence/core-react';
import {
  RuleFragment,
  VariantFragment,
  getTypeOrNull,
  isType,
} from '@spotify-confidence/plugin-graphql';
import { TCountryCode, countries, getEmojiFlag } from 'countries-list';

import { RuleType } from './rule.model';

export const getRuleType = (rule: RuleFragment) =>
  (rule.labels.find(label => label.key === 'ruleType')?.value as RuleType) ??
  RuleType.COMPACT;

export const mapVariantToAssignmentsSpec = (
  variant?: VariantFragment,
  variantAssignment: number = 10000,
): Omit<RuleFragment['assignmentSpec'], '__typename'> => {
  return {
    assignments: isType(variant, 'FlagsAdminV1FlagVariant')
      ? [
          {
            assignmentId: variant.name,
            bucketRanges: [{ upper: variantAssignment, lower: 0 }],
            variant: {
              variant: {
                name: variant.name,
                __typename: 'FlagsAdminV1FlagVariant',
              },
            },
          },
        ]
      : [],
    bucketCount: 10000,
  };
};

export const getPreSelectedVariant = (
  variants: VariantFragment[],
  assignmentSpec?: RuleFragment['assignmentSpec'],
): VariantFragment => {
  const plainAssignment = assignmentSpec?.assignments?.[0];

  if (plainAssignment) {
    const preselected = variants.find(
      v =>
        v.name ===
        getTypeOrNull(
          plainAssignment.variant?.variant,
          'FlagsAdminV1FlagVariant',
        )?.name,
    );
    if (preselected) {
      return preselected;
    }
  }

  return (
    variants.find(v => extractLastNameComponent(v.name) === 'enabled') ??
    variants[0]
  );
};

// Country code semantic type helpers
export const countryCodes = Object.keys(countries);
export const getCountryLabel = (option?: string | null) => {
  if (!option) {
    return '';
  }
  const code = option as TCountryCode;
  return [getEmojiFlag(code), countries[code].name].join(' ').trim();
};
