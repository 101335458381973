import React from 'react';

import { Tooltip, makeStyles } from '@material-ui/core';

import classNames from 'classnames';

import { Status } from './WorkflowInstanceState';

const useStyles = makeStyles(theme => ({
  statusIndicator: {
    minWidth: theme.spacing(1),
    width: theme.spacing(1),
    height: theme.spacing(1),
    borderRadius: '50%',
    background: 'transparent',
    border: '1px solid currentColor',
  },
  hasStarted: {
    background: 'currentColor',
  },
  ok: { color: theme.palette.status.ok },
  warning: { color: theme.palette.status.warning },
  error: { color: theme.palette.status.error },
  aborted: { color: theme.palette.status.aborted },
  pending: { color: theme.palette.status.pending },
  running: { color: theme.palette.status.running },
}));

export const StatusIndicator = ({
  status,
  label,
  hasStarted,
}: {
  status: Status;
  label: string;
  hasStarted?: boolean;
}) => {
  const classes = useStyles();
  return (
    <Tooltip title={label}>
      <div
        className={classNames(classes.statusIndicator, classes[status], {
          [classes.hasStarted]: hasStarted,
        })}
      />
    </Tooltip>
  );
};
