import React, { useState } from 'react';
import { InView } from 'react-intersection-observer';

import {
  Box,
  Button,
  DialogActions,
  FormControlLabel,
  Grid,
  LinearProgress,
  Switch,
  makeStyles,
} from '@material-ui/core';
import ReplayIcon from '@material-ui/icons/Replay';
import { Alert } from '@material-ui/lab';

import {
  DialogBody,
  DialogHeader,
  useDialog,
} from '@spotify-confidence/core-react';
import {
  MetricCalculationFragment,
  MetricsV1ScheduledMetricCalculationState,
  getTypeOrNull,
  useGetScheduledMetricCalculationQuery,
  useListMetricCalculationsQuery,
  useRetriggerMetricCalculationMutation,
  useRetriggerMetricScheduleMutation,
} from '@spotify-confidence/plugin-graphql';

import { MetricCalculationsList } from './MetricCalculationsList';

const useStyles = makeStyles(theme => {
  return {
    container: {
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',
      marginBottom: theme.spacing(2),
    },
    calculations: {
      marginBottom: theme.spacing(2),
    },
  };
});

export const ScheduledMetricCalculationDialog = ({
  name,
}: {
  name: string;
}) => {
  const { closeDialog } = useDialog();

  const classes = useStyles();

  const {
    data: scheduleData,
    loading: isScheduleLoading,
    error: scheduleError,
  } = useGetScheduledMetricCalculationQuery({
    variables: { name: name },
  });
  const {
    data: listData,
    loading: isListLoading,
    error: listError,
    fetchMore,
  } = useListMetricCalculationsQuery({
    variables: { parent: name, pageSize: 50 },
  });

  const scheduledMetricCalculation = getTypeOrNull(
    scheduleData?.scheduledMetricCalculation,
    'MetricsV1ScheduledMetricCalculation',
  );
  const metric = getTypeOrNull(
    scheduledMetricCalculation?.metricCalculationSpec.metric,
    'MetricsV1Metric',
  );

  const metricCalculationsResponse = getTypeOrNull(
    listData?.metricCalculations,
    'MetricsV1ListMetricCalculationsResponse',
  );
  const calculations = metricCalculationsResponse?.metricCalculations;

  const [
    retriggerScheduledMetricCalculation,
    { loading: isRetriggeringSchedule, error: retriggerScheduleError },
  ] = useRetriggerMetricScheduleMutation();

  const [
    retriggerMetricCalculation,
    { loading: isRetriggering, error: retriggerError },
  ] = useRetriggerMetricCalculationMutation();
  const handleRetrigger = React.useCallback(
    (calculation: MetricCalculationFragment) =>
      retriggerMetricCalculation({
        variables: {
          name: calculation.name,
        },
      }),
    [retriggerMetricCalculation],
  );

  const handleRetriggerSchedule = React.useCallback(
    () =>
      retriggerScheduledMetricCalculation({
        variables: {
          name,
        },
      }),
    [retriggerScheduledMetricCalculation, name],
  );

  const error =
    scheduleError || retriggerError || listError || retriggerScheduleError;
  const loading =
    isScheduleLoading ||
    isRetriggering ||
    isListLoading ||
    isRetriggeringSchedule;

  const hasRetriggeredCalculations = !!calculations?.find(
    calc => !!calc.supersededBy,
  );
  const [showRetriggeredCalculations, setShowRetriggeredCalculations] =
    useState(false);

  return loading ? (
    <LinearProgress />
  ) : (
    <>
      <DialogHeader
        onClose={closeDialog}
        title={
          metric?.displayName
            ? `Metric calculations for ${metric?.displayName}`
            : 'Metric calculations'
        }
      />
      <DialogBody autoHeight>
        {(calculations?.length || 0) > 0 &&
          scheduledMetricCalculation?.state ===
            MetricsV1ScheduledMetricCalculationState.Active && (
            <div className={classes.container}>
              <FormControlLabel
                control={
                  <Switch
                    checked={showRetriggeredCalculations}
                    onChange={event =>
                      setShowRetriggeredCalculations(event.target.checked)
                    }
                    color="primary"
                    name="showRetriggeredCalculations"
                  />
                }
                label="Show retriggered calculations"
                labelPlacement="end"
                disabled={!hasRetriggeredCalculations}
              />
              <Button
                variant="contained"
                color="primary"
                size="small"
                startIcon={<ReplayIcon />}
                disabled={isRetriggeringSchedule}
                onClick={() => handleRetriggerSchedule()}
              >
                Retrigger
              </Button>
            </div>
          )}
        {listData && (
          <>
            <Box mb={3}>
              <Grid item xs={12}>
                <MetricCalculationsList
                  calculations={calculations}
                  onRetrigger={handleRetrigger}
                  isRetriggerDisabled={isRetriggering}
                  hideSupersededCalculations={!showRetriggeredCalculations}
                  className={classes.calculations}
                  scheduledMetricCalculation={
                    scheduledMetricCalculation ?? undefined
                  }
                  exposureOffset={metric?.exposureOffset ?? undefined}
                  aggregationWindow={metric?.aggregationWindow ?? undefined}
                />
                <InView
                  onChange={async inView => {
                    const nextPageToken =
                      metricCalculationsResponse?.nextPageToken;
                    if (inView && nextPageToken) {
                      await fetchMore({
                        variables: {
                          pageToken: nextPageToken,
                        },
                      });
                    }
                  }}
                />
              </Grid>
            </Box>
          </>
        )}
      </DialogBody>
      <DialogActions>
        <Box width="100%">
          {error ? (
            <Box width="100%">
              <Alert severity="error">{error.message}</Alert>
            </Box>
          ) : null}
          {loading ? (
            <Box display="flex" justifyContent="center" width="100%">
              <LinearProgress />
            </Box>
          ) : null}
        </Box>
      </DialogActions>
    </>
  );
};
