import React from 'react';
import { Link } from 'react-router-dom';

import {
  Box,
  Button,
  Collapse,
  LinearProgress,
  Link as MuiLink,
  Typography,
} from '@material-ui/core';
import Check from '@material-ui/icons/Check';
import ShowChart from '@material-ui/icons/ShowChart';
import { Alert } from '@material-ui/lab';

import {
  Breadcrumbs,
  ConfidenceWebsiteLink,
  FormSubmitButtons,
  ListEmptyState,
  PageLayout,
  useAlert,
  useDialog,
} from '@spotify-confidence/core-react';
import {
  BigQueryConfigFragment,
  DataBricksConfigFragment,
  DataWarehouseFragment,
  ListDataWarehousesDocument,
  MetricsV1DataWarehouseConfigInput,
  MetricsV1RedshiftConfigInput,
  MetricsV1SnowflakeConfigInput,
  RedshiftConfigFragment,
  getTypeOrNull,
  isType,
  useDataWarehouseStateQuery,
  useListDataWarehousesQuery,
  useUpdateDataWarehouseMutation,
} from '@spotify-confidence/plugin-graphql';
import {
  ResourcePermissionGuard,
  useCheckResourcePermissions,
} from '@spotify-confidence/plugin-permissions-react';
import _ from 'lodash';

import { useRouteRef } from '@backstage/core-plugin-api';

import { rootRouteRef, usersRouteRef } from '../../../routes';
import { RedshiftDatawarehouseForm } from '../../Redshift';
import { BigQueryDatawarehouseForm } from '../BigQuery';
import { ConfigureDatawarehouseDialog } from '../ConfigureDatawarehouseDialog';
import { DataBricksDatawarehouseForm } from '../Databricks';
import { SnowflakeDatawarehouseForm } from '../Snowflake';
import { DataWareHouseType } from '../types';
import { DemoModeState } from './DemoModeState';

function getTypeAndConfig({ config }: DataWarehouseFragment): {
  type?: DataWareHouseType;
  config: Partial<MetricsV1DataWarehouseConfigInput>;
} {
  if (config?.bigQueryConfig) {
    return {
      type: 'bigQueryConfig',
      config: { bigQueryConfig: config.bigQueryConfig },
    };
  } else if (config?.dataBricksConfig) {
    return {
      type: 'dataBricksConfig',
      config: { dataBricksConfig: config.dataBricksConfig },
    };
  } else if (config?.redshiftConfig) {
    return {
      type: 'redshiftConfig',
      config: {
        redshiftConfig: config.redshiftConfig as MetricsV1RedshiftConfigInput,
      },
    };
  } else if (config?.snowflakeConfig) {
    return {
      type: 'snowflakeConfig',
      config: {
        snowflakeConfig: {
          ...config?.snowflakeConfig,
          authenticationKey:
            getTypeOrNull(
              config.snowflakeConfig.authenticationKey,
              'IamV1CryptoKey',
            )?.name ?? '',
        },
      },
    };
  }
  return { config: {} };
}

export const MetricsDataWarehousePage = () => {
  const alert = useAlert();
  const { openDialog } = useDialog();
  const adminRoute = useRouteRef(rootRouteRef);
  const usersRoute = useRouteRef(usersRouteRef);
  const [dataWarehouse, setDataWarehouse] = React.useState<DataWareHouseType>();
  const [inputData, setInputData] = React.useState<
    Partial<MetricsV1DataWarehouseConfigInput>
  >({});

  const { data: existsData } = useDataWarehouseStateQuery();
  const { allowed: canViewWarehouses } = useCheckResourcePermissions({
    name: 'account',
    can: 'view_data_warehouse',
  });
  const configured =
    getTypeOrNull(
      existsData?.existsDataWarehouses,
      'MetricsV1ExistsDataWarehousesResponse',
    )?.exists ?? false;
  const demoData =
    getTypeOrNull(
      existsData?.existsDataWarehouses,
      'MetricsV1ExistsDataWarehousesResponse',
    )?.demoData ?? false;

  const {
    data: listDataWarehousesData,
    loading,
    error,
  } = useListDataWarehousesQuery({
    fetchPolicy: 'cache-and-network',
    skip: !configured || !canViewWarehouses || demoData,
    onCompleted: data => {
      if (isType(data.dataWarehouses, 'MetricsV1ListDataWarehousesResponse')) {
        const warehouse = data.dataWarehouses?.dataWarehouses[0];
        if (warehouse) {
          const { type, config } = getTypeAndConfig(warehouse);
          setDataWarehouse(type);
          setInputData(config);
        }
      } else {
        alert.post({
          severity: 'error',
          message: data.dataWarehouses?.message ?? 'Something went wrong',
        });
      }
    },
  });
  const dataWarehouses = getTypeOrNull(
    listDataWarehousesData?.dataWarehouses,
    'MetricsV1ListDataWarehousesResponse',
  );
  const dataWarehousesError = getTypeOrNull(
    listDataWarehousesData?.dataWarehouses,
    'Error',
  )?.message;
  const resetForm = () => {
    if (dataWarehouses) {
      const remoteDatawarehouse = dataWarehouses?.dataWarehouses[0];
      if (remoteDatawarehouse) {
        const { type, config } = getTypeAndConfig(remoteDatawarehouse);
        setDataWarehouse(type);
        setInputData(config);
      }
    }
  };

  const [update, { loading: isUpdating, error: updateError }] =
    useUpdateDataWarehouseMutation({
      onCompleted: () => {
        alert.post({
          message: 'Successfully updated data warehouse configuration',
          severity: 'success',
          display: 'transient',
        });
      },
      refetchQueries: [ListDataWarehousesDocument],
    });

  const existingDataWarehouse = dataWarehouses?.dataWarehouses[0];
  const config = existingDataWarehouse?.config;

  function handleSubmit(e: React.FormEvent<HTMLFormElement>) {
    e.preventDefault();
    update({
      variables: {
        dataWarehouse: {
          name: existingDataWarehouse!.name!,
          config: inputData,
        },
      },
    });
  }

  function setConfig(type: DataWareHouseType) {
    return (
      data:
        | DataBricksConfigFragment
        | BigQueryConfigFragment
        | RedshiftConfigFragment
        | MetricsV1SnowflakeConfigInput,
    ) => {
      setInputData({
        [type]: data,
      });
    };
  }

  const openConfigureDialog = () => {
    openDialog({
      content: <ConfigureDatawarehouseDialog />,
      dialogProps: {
        fullScreen: true,
      },
    });
  };

  const isEmptyState = !demoData && !dataWarehouse && !loading;

  let hasChanged = false;
  if (
    dataWarehouse &&
    inputData[dataWarehouse] &&
    config?.[dataWarehouse] &&
    existingDataWarehouse
  ) {
    const { config: originalConfig } = getTypeAndConfig(existingDataWarehouse);
    hasChanged = !_.isEqual(
      inputData?.[dataWarehouse],
      originalConfig?.[dataWarehouse],
    );
  }

  return (
    <PageLayout
      narrow="sm"
      title="Metrics data warehouse"
      headerBreadcrumbs={
        <Breadcrumbs>
          <Link to={adminRoute()}>Admin</Link>
          <Typography>Metrics data warehouse</Typography>
        </Breadcrumbs>
      }
    >
      {demoData ? (
        <DemoModeState />
      ) : (
        <>
          {configured && (
            <Typography color="textSecondary" paragraph>
              Your metrics data warehouse connection only configures where
              Confidence writes data to. You can still read data from elsewhere
              in your data warehouse.
            </Typography>
          )}
          {dataWarehousesError && (
            <Alert severity="error">{dataWarehousesError}</Alert>
          )}
          {error && <Alert severity="error">{error.message}</Alert>}
          {updateError && <Alert severity="error">{updateError.message}</Alert>}
          {loading && <LinearProgress />}
          {isEmptyState && (
            <ListEmptyState
              title={
                configured
                  ? 'Data warehouse configured!'
                  : 'No data warehouse configured'
              }
              icon={configured ? Check : ShowChart}
              description={
                <span>
                  {configured ? (
                    ''
                  ) : (
                    <Typography paragraph>
                      Configure a data warehouse to compute metrics. Your
                      metrics data warehouse connection only configures where
                      Confidence writes data to. You can still read data from
                      elsewhere in your data warehouse.
                    </Typography>
                  )}
                  <Typography>
                    Want to learn more about how Confidence interacts with your
                    data warehouse?{' '}
                    <ConfidenceWebsiteLink
                      route="/docs/data-warehouse-native#how-confidence-interacts-with-your-data-warehouse"
                      underline="always"
                    >
                      Learn more.
                    </ConfidenceWebsiteLink>
                  </Typography>
                </span>
              }
              supportSnippet={
                <>
                  This step should be completed by someone with access to your
                  data warehouse that can create a new service account. If it's
                  not you, you can{' '}
                  <MuiLink
                    color="inherit"
                    underline="always"
                    component={Link}
                    to={usersRoute()}
                  >
                    invite a colleague
                  </MuiLink>
                  .
                </>
              }
              actions={
                configured ? null : (
                  <ResourcePermissionGuard
                    name="account"
                    can="create_data_warehouse"
                  >
                    {({ allowed }) => (
                      <Button
                        variant="contained"
                        color="primary"
                        onClick={openConfigureDialog}
                        disabled={!allowed}
                      >
                        Connect data warehouse
                      </Button>
                    )}
                  </ResourcePermissionGuard>
                )
              }
            />
          )}
          {dataWarehouses && !isEmptyState && (
            <form onSubmit={handleSubmit}>
              {dataWarehouse === 'bigQueryConfig' && (
                <BigQueryDatawarehouseForm
                  value={inputData?.bigQueryConfig}
                  disabled={loading}
                  onChange={setConfig('bigQueryConfig')}
                />
              )}
              {dataWarehouse === 'dataBricksConfig' && (
                <DataBricksDatawarehouseForm
                  value={inputData?.dataBricksConfig}
                  onChange={setConfig('dataBricksConfig')}
                  disabled={loading}
                />
              )}
              {dataWarehouse === 'redshiftConfig' && (
                <RedshiftDatawarehouseForm
                  value={inputData?.redshiftConfig}
                  onChange={setConfig('redshiftConfig')}
                  disabled={loading}
                  warehouseName={existingDataWarehouse?.name}
                />
              )}
              {dataWarehouse === 'snowflakeConfig' && (
                <SnowflakeDatawarehouseForm
                  value={inputData?.snowflakeConfig}
                  disabled={loading}
                  onChange={setConfig('snowflakeConfig')}
                />
              )}
              <Collapse in={hasChanged}>
                <Box textAlign="right" paddingTop={2}>
                  <FormSubmitButtons
                    onCancel={resetForm}
                    disabled={isUpdating}
                  />
                </Box>
              </Collapse>
            </form>
          )}
        </>
      )}
    </PageLayout>
  );
};
