import React from 'react';

import { WithStyles, createStyles, withStyles } from '@material-ui/core';

import { CommentZone } from '@spotify-confidence/plugin-comments-react';
import classNames from 'classnames';
import _ from 'lodash';

import { ErrorSymbol } from '../MetricResultTable/AnnotationsSummary';
import {
  StatusMessage,
  StatusMessageProps,
} from '../MetricResultTable/StatusMessage';
import { useMetricResultContext } from './context';

const styles = createStyles({
  root: {},
});

export interface StatusProps
  extends WithStyles<typeof styles>,
    StatusMessageProps {
  className?: string;
}

const Status = ({ classes, className, ...rest }: StatusProps) => {
  const { value: result, isSequential, isLive } = useMetricResultContext();
  const errors =
    result?.annotations?.filter(annotation => annotation.error) ?? [];
  const hasRun = result?.metricDetails?.hasRun ?? false;
  const hasError = errors.length > 0;
  const isLiveFixedHorizon = isLive && !isSequential;
  let noData = 'No data';
  let symbol;
  if (!hasRun) {
    noData = 'Waiting for data';
  } else if (hasError) {
    noData = 'Error';
    symbol = errors.length > 0 ? <ErrorSymbol fontSize="small" /> : undefined;
  } else if (isLiveFixedHorizon) {
    noData = 'Waiting for end';
  }

  return (
    <CommentZone id="status">
      <StatusMessage
        className={classNames(classes.root, className)}
        isSignificant={result.isSignificant ?? undefined}
        nim={result.nim}
        direction={result.direction}
        undesiredReason={result.undesiredReason}
        message={_.isNil(result.isSignificant) ? noData : undefined}
        symbol={symbol}
        {...rest}
      />
    </CommentZone>
  );
};

export default withStyles(styles)(Status);
