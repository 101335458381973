import {
  MetricDetailsFragment,
  MetricsV1AggregationType,
  MetricsV1MetricInput,
  getTypeOrNull,
} from '@spotify-confidence/plugin-graphql';
import { omit } from 'lodash';

import { AverageMetricInput, MetricInput, RatioMetricInput } from '../types';

export const getMetricInputForMetric = (
  metric: MetricDetailsFragment,
): MetricInput => {
  const shared: Omit<
    MetricsV1MetricInput,
    '__typename' | 'resourceRelations' | 'dummy'
  > = omit(
    {
      ...metric,
      owner: getTypeOrNull(metric.owner, 'IamV1Identity')?.name,
      entity: getTypeOrNull(metric.entity, 'MetricsV1Entity')?.name ?? '',
      factTable:
        getTypeOrNull(metric.factTable, 'MetricsV1FactTable')?.name ?? '',
      measurementConfig: {
        closedWindow: metric.measurementConfig?.closedWindow,
        semiOpenWindow: metric.measurementConfig?.semiOpenWindow,
        openWindow: !!metric.measurementConfig?.openWindow ? {} : undefined,
      },
    },
    ['resourceRelations', '__typename'],
  );

  if (metric.typeSpec?.ratioMetricSpec) {
    const ratio: RatioMetricInput = {
      ...shared,
      typeSpec: { ratioMetricSpec: metric.typeSpec.ratioMetricSpec },
    };
    return ratio;
  }
  const avg: AverageMetricInput = {
    ...shared,
    typeSpec: {
      averageMetricSpec: {
        aggregation: {
          ...metric.typeSpec?.averageMetricSpec?.aggregation,
          type:
            metric.typeSpec?.averageMetricSpec?.aggregation.type ||
            MetricsV1AggregationType.AggregationTypeAvg,
        },
        measurement:
          metric.typeSpec?.averageMetricSpec?.measurement?.name !== undefined
            ? {
                name: metric.typeSpec.averageMetricSpec.measurement.name,
              }
            : undefined,
      },
    },
  };
  return avg;
};

export type RatioDenominatorType = 'COUNT' | 'SUM';

export function getRatioDenominatorType(
  metric: RatioMetricInput,
): RatioDenominatorType {
  if (
    metric.typeSpec.ratioMetricSpec.denominatorAggregation?.type ===
    MetricsV1AggregationType.AggregationTypeCount
  ) {
    return 'COUNT';
  }
  return 'SUM';
}
