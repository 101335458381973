import {
  createExternalRouteRef,
  createRouteRef,
  createSubRouteRef,
} from '@backstage/core-plugin-api';

// internal routes
export const rootRouteRef = createRouteRef({ id: 'events-root' });

export const eventIndexRouteRef = rootRouteRef;

export const eventRouteRef = createSubRouteRef({
  id: 'event',
  parent: rootRouteRef,
  path: '/:id',
});

// external routes
export const entityRouteRef = createExternalRouteRef({
  id: 'entity',
  params: ['id'],
  optional: true,
});

export const entitiesRouteRef = createExternalRouteRef({
  id: 'entities',
  optional: true,
});
