import React, {
  PropsWithoutRef,
  useEffect,
  useLayoutEffect,
  useRef,
} from 'react';
import { Link } from 'react-router-dom';

import { ListItem, ListItemProps, makeStyles } from '@material-ui/core';

import classNames from 'classnames';

const useStyles = makeStyles(theme => ({
  root: {
    transition: theme.transitions.create(['transform', 'opacity']),
    transform: 'translateY(-10px)',
    opacity: 0,
  },
  in: {
    transform: 'translateY(0)',
    opacity: 1,
  },
}));

export const ResultListItem = ({
  href,
  className,
  rank,
  ...props
}: PropsWithoutRef<ListItemProps & { href: string; rank: number }>) => {
  const ref = useRef<HTMLDivElement>(null);
  const classes = useStyles();
  const [inProp, setInProp] = React.useState(false);

  useEffect(() => {
    if (props.selected && ref.current) {
      ref.current.scrollIntoView({
        block: 'center',
        inline: 'nearest',
        behavior: 'smooth',
      });
    }
  }, [props.selected]);

  useLayoutEffect(() => {
    setInProp(true);
  }, []);

  return (
    <ListItem
      // XXX: ListItemProps do not like "button" prop
      // @ts-ignore
      button
      className={classNames(className, classes.root, inProp && classes.in)}
      component={Link}
      to={href}
      ref={ref}
      style={{ transitionDelay: `${rank * 30}ms` }}
      {...props}
    />
  );
};
