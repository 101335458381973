import {
  FlagsTypesV1Expression,
  Maybe,
  TargetingFragment,
} from '@spotify-confidence/plugin-graphql';

import {
  CriterionOption,
  TargetingCriteria,
  isCriterion,
} from '../../targeting.model';
import { fromSchemaAttributeCriterion } from './attribute';
import { fromSchemaSegmentCriterion } from './segment';

// format data payload received from Schema (server) to map to UI models
export const fromSchemaTargeting = (
  targeting?: Maybe<TargetingFragment>,
  criterionOptions: CriterionOption[] = [],
): TargetingCriteria => {
  const traverseExpression = (
    e: Maybe<FlagsTypesV1Expression> | undefined,
    isNotCri: boolean = false,
    k: string,
  ): TargetingCriteria | undefined => {
    if (!e) return undefined;

    if (e.ref) {
      const name = e.ref;
      const { value: criterion } =
        targeting?.criteria?.find(({ key }) => key === name) || {};

      if (!criterion || !name) return undefined;

      if ('attribute' in criterion && !!criterion.attribute) {
        return fromSchemaAttributeCriterion(
          name,
          criterion,
          isNotCri,
          criterionOptions.find(
            option => criterion.attribute?.attributeName === option.name,
          ),
        );
      }

      return fromSchemaSegmentCriterion(name, criterion, isNotCri);
    }

    if (e.not) {
      return traverseExpression(e.not, true, `${k}.`);
    }

    if (e.or) {
      const expressions = e.or.operands ?? [];
      return {
        name: k,
        operator: 'or',
        criteria: expressions
          .map((expression, i) =>
            traverseExpression(expression, false, `${k}.${i}`),
          )
          .filter(v => !!v) as TargetingCriteria[],
      };
    }

    if (e.and) {
      const expressions = e.and.operands ?? [];
      return {
        name: k,
        operator: 'and',
        criteria: expressions
          .map((expression, idx) =>
            traverseExpression(expression, false, `${k}.${idx}`),
          )
          .filter(v => !!v) as TargetingCriteria[],
      };
    }
    return undefined;
  };

  let response = traverseExpression(targeting?.expression, false, 'group');

  // for empty rules, we want to wrap these in a group. This state will only happen if they interact with the api
  if (response && isCriterion(response)) {
    response = {
      name: 'group',
      operator: 'or',
      criteria: [response],
    };
  }

  return response
    ? response
    : {
        name: 'group',
        operator: 'or',
        criteria: [],
      };
};
