import React from 'react';

import { Typography } from '@material-ui/core';

import { DateUtils } from '@spotify-confidence/core-react';
import { MetricScheduleFragment } from '@spotify-confidence/plugin-graphql';
import _ from 'lodash';

import {
  StatusAborted,
  StatusError,
  StatusOK,
  StatusPending,
  StatusRunning,
} from '@backstage/core-components';

import { nextCalculationString } from '../../../MetricsModule/ScheduledMetricCalculationDialog/MetricCalculationsList';

type MetricCalculationStatusProps = React.PropsWithChildren<{
  state?: string;
}>;

const formatDate = (date: string | number) => {
  return DateUtils.toDateString(date, 'yyyy-MM-dd HH:mm') || '';
};

export const statusInformation = (schedule: MetricScheduleFragment) => {
  const status = schedule.status;
  let tooltip = '';
  if (status.error) {
    tooltip = status.error.errorMessage;
  } else if (status.scheduled) {
    tooltip = `Next scheduled run is at ${formatDate(
      status.scheduled.nextScheduleTime,
    )}`;
  } else if (status.cancelled) {
    tooltip = `The calculation was stopped at ${formatDate(
      status.cancelled.cancelledTime,
    )}.`;
  } else if (status.waiting) {
    tooltip = nextCalculationString(schedule);
  }
  return tooltip;
};

export const MetricCalculationStatus = ({
  state,
  children,
}: MetricCalculationStatusProps) => {
  let Status = StatusOK;
  let statusMessage = 'OK';

  switch (state) {
    case 'STATE_UNSPECIFIED':
      Status = StatusPending;
      statusMessage = 'Pending';
      break;
    case 'STATE_FAILED':
      Status = StatusError;
      statusMessage = 'Failed';
      break;
    case 'STATE_RUNNING':
      Status = StatusRunning;
      statusMessage = 'Running';
      break;
    case 'STATE_COMPLETED':
      Status = StatusOK;
      statusMessage = 'Completed';
      break;
    case 'STATE_CANCELLED':
      Status = StatusAborted;
      statusMessage = 'Cancelled';
      break;
    default:
      Status = StatusPending;
      statusMessage = 'Pending';
      break;
  }
  return (
    <Status>
      {children || (
        <Typography variant="body2" color="textSecondary" display="inline">
          {statusMessage}
        </Typography>
      )}
    </Status>
  );
};
