import React from 'react';

import { Chip, makeStyles } from '@material-ui/core';

import { capitalize } from 'lodash';

import { ContextMenu } from '../../ContextMenu';
import { ActiveFilter, useListFilter } from '../FilterListProvider';
import { QueryBuilderValue, QueryRuleValue } from '../filterBuilder.types';

const useChipStyles = makeStyles(theme => ({
  checkboxRoot: {
    padding: theme.spacing(0.5),
    marginRight: theme.spacing(1),
  },
  filterFieldChipRoot: {
    margin: 0,
  },
}));

function renderValueDefault(value?: any) {
  return Array.isArray(value)
    ? value.map(v => capitalize(v)).join(', ')
    : capitalize(String(value));
}

export const FilterChip = (field: ActiveFilter) => {
  const { value, definition } = field;
  const classes = useChipStyles();
  const { setFilterValue, deleteFilter } = useListFilter();

  const handleChange = (currentValue: QueryRuleValue) => {
    return (newValue: QueryBuilderValue) => {
      const id = currentValue.id;
      setFilterValue(id, newValue);
    };
  };

  const displayName = definition?.displayName ?? capitalize(value.id);
  function renderValue(queryBuilderValue: QueryBuilderValue) {
    if (definition?.renderValue) {
      return definition.renderValue(queryBuilderValue);
    }
    return renderValueDefault(queryBuilderValue ?? '');
  }
  const label = (
    <span>
      {displayName}: {renderValue(value.value)}
    </span>
  );

  return (
    <ContextMenu
      renderButton={p => (
        <Chip
          {...p}
          label={label}
          onDelete={() => deleteFilter(value.id)}
          classes={{ root: classes.filterFieldChipRoot }}
          aria-haspopup="true"
          size="small"
        />
      )}
      renderContent={() =>
        definition?.renderEditComponent({
          value: value.value,
          onChange: handleChange(value),
          operator: value.operator,
        })
      }
    />
  );
};
