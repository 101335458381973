import React from 'react';

import { useAlert } from '@spotify-confidence/core-react';
import {
  RuleFragment,
  VariantFragment,
  getTypeOrNull,
  isType,
  useCreateMaterializedSegmentMutation,
  useUpdateRuleMutation,
  useUpdateSegmentMutation,
} from '@spotify-confidence/plugin-graphql';

import { targetingCodec } from '../../../../segment/domain/targeting';
import { ruleHelpers } from '../../../domain';
import { ruleFragmentToInput } from '../../../domain/rule.model';
import { getRuleFormComponent } from '../../RuleForm';

export type Props = {
  variants: VariantFragment[];
  rule: RuleFragment;
  clients: string[];
};

export const EditRuleDialog = ({ variants, rule, clients }: Props) => {
  const [updateSegment] = useUpdateSegmentMutation();
  const [updateRule] = useUpdateRuleMutation();
  const [createMaterialization] = useCreateMaterializedSegmentMutation();
  const alert = useAlert();
  const onSave = async (updatedRule: RuleFragment) => {
    if (!isType(updatedRule.segment, 'FlagsAdminV1Segment')) return;
    const segment = await updateSegment({
      variables: {
        segment: {
          name: updatedRule.segment.name,
          allocation: updatedRule.segment.allocation,
          targeting: targetingCodec.toInput(updatedRule.segment.targeting),
        },
      },
    });
    if (isType(segment.data?.updateSegment, 'FlagsAdminV1Segment')) {
      if (updatedRule.materializationSpec?.writeMaterialization) {
        const id = getTypeOrNull(
          updatedRule.materializationSpec.writeMaterialization,
          'FlagsAdminV1MaterializedSegment',
        )?.name.split('/')[1];
        try {
          await createMaterialization({
            variables: {
              materializationId: id,
              materializedSegment: {
                displayName: `Materialization for ${updatedRule.segment.name}`,
              },
            },
          });
        } catch (e: any) {
          const isAlreadyExistsError =
            e instanceof Error && e.message.includes('ALREADY_EXISTS');
          if (!isAlreadyExistsError) {
            throw e;
          }
        }
      }
      await updateRule({
        variables: {
          rule: {
            ...ruleFragmentToInput({ ...rule, ...updatedRule }),
            segment:
              segment.data?.updateSegment?.name || updatedRule.segment.name,
          },
        },
      });
    } else {
      alert.post({
        severity: 'error',
        message:
          segment?.data?.updateSegment?.message ?? 'Something went wrong',
      });
    }
  };

  const ruleType = ruleHelpers.getRuleType(rule);
  const FormComponent = getRuleFormComponent(ruleType);

  return (
    <FormComponent
      title="Edit rule"
      variants={variants}
      rule={rule}
      clients={clients}
      onSave={onSave}
    />
  );
};
