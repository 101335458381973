import React from 'react';
import { useSearchParams } from 'react-router-dom';
import { useAsync } from 'react-use';

import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Button,
  Chip,
  Collapse,
  FormControlLabel,
  FormHelperText,
  FormLabel,
  Grid,
  MenuItem,
  Paper,
  Switch,
  TextField,
  TextFieldProps,
  Typography,
  styled,
} from '@material-ui/core';
import AddCircleIcon from '@material-ui/icons/AddCircle';
import CancelIcon from '@material-ui/icons/Cancel';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import {
  Autocomplete,
  ToggleButton as MuiToggleButton,
  ToggleButtonGroup as MuiToggleButtonGroup,
} from '@material-ui/lab';
import { MuiPickersUtilsProvider } from '@material-ui/pickers';

import DateFnsUtils from '@date-io/date-fns';
import {
  ChipInput,
  CodeEditor,
  ConfidenceAutocomplete,
  ContextMenu,
  FormFieldsBox,
  PageLayout,
  extractLastNameComponent,
} from '@spotify-confidence/core-react';
import {
  FlagOptionFragment,
  FlagsAdminV1EvaluationContextSchemaFieldKind,
  SchemaEntryFragment,
  getTypeOrNull,
  isType,
  useAvailableClientsQuery,
  useFlagsLazyQuery,
  useGetMergedClientSchemaMutation,
  useResolveFlagsVerboseMutation,
} from '@spotify-confidence/plugin-graphql';
import { capitalize } from 'lodash';

import {
  ChipBooleanPicker,
  ChipCountryPicker,
  ChipDatePicker,
} from '../../../segment/components/Targeting/CriteriaValueFields';
import { ChipDatetimePicker } from '../../../segment/components/Targeting/CriteriaValueFields/ChipDatetimePicker';
import { RuleDetails } from './components/RuleDetails';

const ToggleButtonGroup = styled(MuiToggleButtonGroup)({
  width: '100%',
});
const ToggleButton = styled(MuiToggleButton)({
  flex: 1,
});
type Value = string | boolean | number | null;
type EvaluationContextValue = {
  value: Value;
  contextSchema: SchemaEntryFragment;
};

const schemaEntryName = (v: SchemaEntryFragment) =>
  v.value?.displayName ? capitalize(v.value.displayName) : v.key;

export const ResolveTesterPage = () => {
  const [searchParams] = useSearchParams();

  const [inputMode, setInputMode] = React.useState('key-value');
  const [jsonInput, setJsonInput] = React.useState('{}');
  const [selectedFlag, setSelectedFlag] = React.useState<string | null>(
    searchParams.get('flag'),
  );
  const [flagFilter, setFlagFilter] = React.useState(
    !!searchParams.get('flag') ? true : false,
  );
  const [selectedClient, setSelectedClient] = React.useState<string | null>(
    searchParams.get('client'),
  );
  const [evaluationContext, setEvaluationContext] = React.useState<
    EvaluationContextValue[]
  >([]);

  const { data } = useAvailableClientsQuery();
  const clients =
    getTypeOrNull(data?.clients, 'IamV1ListClientsResponse')?.clients ?? [];

  const [resolve, { loading, data: resolveData }] =
    useResolveFlagsVerboseMutation();

  const selectedClientFragment = React.useMemo(
    () => clients.find(c => c.name === selectedClient) ?? null,
    [selectedClient, clients],
  );

  const resolveFlag = () => {
    if (!selectedClientFragment) return;
    const clientSecret = getTypeOrNull(
      selectedClientFragment.clientCredentials,
      'IamV1ListClientCredentialsResponse',
    )?.clientCredentials?.[0].clientSecret?.secret;

    if (!clientSecret) return;

    const evaluationContextObj =
      inputMode === 'json'
        ? JSON.parse(jsonInput)
        : Object.fromEntries(
            evaluationContext.map(v => [v.contextSchema.key, v.value]),
          );
    resolve({
      variables: {
        clientSecret: clientSecret,
        flags: flagFilter && !!selectedFlag ? [selectedFlag] : [],
        evaluationContext: evaluationContextObj,
      },
    });
  };

  const resolvedFlags =
    getTypeOrNull(
      resolveData?.resolveFlagsVerbose,
      'FlagsResolverAdminV1ResolveFlagsVerboseResponse',
    )?.resolvedFlags ?? [];

  const validJson = React.useMemo(() => {
    if (inputMode !== 'json') return true;
    try {
      JSON.parse(jsonInput);
      return true;
    } catch (error) {
      return false;
    }
  }, [inputMode, jsonInput]);
  const multipleFlags = resolvedFlags.length > 1;
  return (
    <PageLayout title="Resolver test">
      <MuiPickersUtilsProvider utils={DateFnsUtils}>
        <Box component={Paper} padding={2}>
          <Grid container spacing={2}>
            <Grid item xs={4}>
              <FormFieldsBox spacing={1}>
                <Autocomplete
                  value={selectedClientFragment}
                  onChange={(_e, v) => {
                    setSelectedClient(v?.name ?? null);
                  }}
                  ChipProps={{ size: 'small' }}
                  options={clients}
                  getOptionLabel={client => client.displayName}
                  renderOption={client => client.displayName}
                  renderInput={params => (
                    <TextField
                      {...params}
                      label="Clients"
                      InputLabelProps={{
                        required: true,
                      }}
                      variant="outlined"
                    />
                  )}
                />
                <Collapse in={!!selectedClient}>
                  <FormFieldsBox spacing={1}>
                    <Box>
                      <FormControlLabel
                        control={
                          <Switch
                            checked={flagFilter}
                            onChange={(_e, checked) => setFlagFilter(checked)}
                          />
                        }
                        label="Filter by flag"
                      />
                      <Collapse in={flagFilter && !!selectedClient}>
                        <ConfidenceAutocomplete<FlagOptionFragment>
                          query={useFlagsLazyQuery}
                          searchField="name"
                          autoSelectSingle
                          value={selectedFlag}
                          onChange={(_e, flagName) => setSelectedFlag(flagName)}
                          placeholder="Select flag..."
                          queryOptions={{
                            variables: {
                              filter: `state:ACTIVE AND clients:\"${selectedClient}\"`,
                            },
                          }}
                          required
                          getOptionLabel={flag =>
                            extractLastNameComponent(flag.name) || ''
                          }
                          getOptionDisabled={flag => flag.variants.length === 0}
                        />
                      </Collapse>
                    </Box>

                    <FormLabel>Evaluation context</FormLabel>

                    <ToggleButtonGroup
                      value={inputMode}
                      size="small"
                      exclusive
                      onChange={(_e, value) => setInputMode(value)}
                      aria-label="text alignment"
                    >
                      <ToggleButton value="key-value" aria-label="left aligned">
                        Key-Value edit
                      </ToggleButton>
                      <ToggleButton value="json" aria-label="centered">
                        JSON
                      </ToggleButton>
                    </ToggleButtonGroup>

                    {inputMode === 'key-value' && selectedClient && (
                      <KeyValueBuilder
                        evaluationContext={evaluationContext}
                        setEvaluationContext={setEvaluationContext}
                        client={selectedClient}
                      />
                    )}
                    {inputMode === 'json' && (
                      <>
                        <CodeEditor
                          value={jsonInput}
                          onChange={v => setJsonInput(v)}
                          mode="json"
                        />
                        {!validJson && (
                          <FormHelperText>Invalid json</FormHelperText>
                        )}
                      </>
                    )}
                  </FormFieldsBox>
                </Collapse>
              </FormFieldsBox>

              <Box mt={2}>
                <Button
                  onClick={resolveFlag}
                  fullWidth
                  disabled={loading || !validJson}
                  variant="contained"
                  color="primary"
                >
                  Resolve
                </Button>
              </Box>
            </Grid>
            <Grid item xs={8}>
              {resolvedFlags.map(resolvedFlag => {
                const ruleDetails = resolvedFlag.ruleDetails;
                return (
                  <Accordion defaultExpanded={!multipleFlags}>
                    <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                      <Typography variant="h5">
                        {isType(resolvedFlag.flag, 'FlagsAdminV1Flag')
                          ? extractLastNameComponent(resolvedFlag.flag.name)
                          : 'Unknown flag'}
                      </Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                      <RuleDetails
                        value={resolvedFlag.value}
                        ruleDetails={ruleDetails}
                      />
                    </AccordionDetails>
                  </Accordion>
                );
              })}
            </Grid>
          </Grid>
        </Box>
      </MuiPickersUtilsProvider>
    </PageLayout>
  );
};

function getInputType(contextSchema: SchemaEntryFragment): {
  type:
    | 'text'
    | 'number'
    | 'country'
    | 'date'
    | 'timestamp'
    | 'boolean'
    | 'select';
  textFieldProps?: TextFieldProps;
} {
  const contextSchemaValue = contextSchema?.value;
  switch (true) {
    case !!contextSchemaValue?.semanticType?.country:
      return { type: 'country' };
    case !!contextSchemaValue?.semanticType?.date:
      return { type: 'date' };
    case !!contextSchemaValue?.semanticType?.timestamp:
      return { type: 'timestamp' };
    case !!contextSchemaValue?.semanticType?.enumType:
      return {
        type: 'select',
        textFieldProps: {
          select: true,
          children: [
            (contextSchemaValue?.semanticType?.enumType?.values ?? []).map(
              (v, idx) => (
                <MenuItem value={v.value ?? ''} key={`enum-option-${idx}`}>
                  {v.value}
                </MenuItem>
              ),
            ),
          ],
        },
      };

    case !!contextSchemaValue?.types.includes(
      FlagsAdminV1EvaluationContextSchemaFieldKind.NumberKind,
    ):
      return { type: 'number' };

    case !!contextSchemaValue?.types.includes(
      FlagsAdminV1EvaluationContextSchemaFieldKind.BoolKind,
    ):
      return { type: 'boolean' };

    default:
      return {
        type: 'text',
      };
  }
}

function KeyValueBuilder({
  evaluationContext,
  setEvaluationContext,
  client,
}: {
  evaluationContext: EvaluationContextValue[];
  setEvaluationContext: React.Dispatch<
    React.SetStateAction<EvaluationContextValue[]>
  >;
  client?: string;
}) {
  const [getMergedClientSchema] = useGetMergedClientSchemaMutation();

  const contextSchemaState = useAsync(async () => {
    if (!client) return [];
    const res = await getMergedClientSchema({
      variables: { clients: [client] },
    });
    return (
      getTypeOrNull(
        res.data?.deriveClientEvaluationContextSchema,
        'FlagsAdminV1DeriveClientEvaluationContextSchemaResponse',
      )?.mergedSchema?.schema ?? []
    );
  }, [client]);

  const handleRemove = (key: string) => {
    setEvaluationContext(currentValues =>
      currentValues.filter(v => v.contextSchema.key !== key),
    );
  };

  const handleContextChange = (key: string) => (newValue: Value) => {
    setEvaluationContext(currentValues =>
      currentValues.map(value => {
        if (key === value.contextSchema.key) {
          return {
            ...value,
            value: newValue,
          };
        }
        return value;
      }),
    );
  };

  return (
    <>
      <Box display="flex" flexDirection="column">
        {evaluationContext.map(ec => (
          <Box flex={1} marginBottom={1}>
            <Chip
              label={
                <Box display="flex" gridGap={4} alignItems="center">
                  <Typography variant="body2">
                    {schemaEntryName(ec.contextSchema)}
                  </Typography>
                  <Typography variant="body2">is</Typography>
                  <EvaluationContextField
                    contextSchema={ec.contextSchema}
                    value={ec.value}
                    onChange={handleContextChange(ec.contextSchema.key)}
                  />
                </Box>
              }
              onDelete={() => handleRemove(ec.contextSchema.key)}
              deleteIcon={<CancelIcon name="delete-icon" />}
            />
          </Box>
        ))}
      </Box>
      <div>
        <ContextMenu
          closeOnSelect
          renderButton={p => (
            <Button
              {...p}
              disabled={contextSchemaState.loading}
              startIcon={<AddCircleIcon />}
              size="small"
              variant="text"
            >
              Add evaluation context
            </Button>
          )}
          renderMenu={() =>
            (contextSchemaState.value ?? []).map(option => (
              <MenuItem
                key={option.key}
                divider
                disabled={evaluationContext
                  .map(f => f.contextSchema.key)
                  .includes(option.key)}
                onClick={() =>
                  setEvaluationContext(v => [
                    ...v,
                    {
                      contextSchema: option,
                      value: null,
                    },
                  ])
                }
              >
                {schemaEntryName(option)}
              </MenuItem>
            ))
          }
        />
      </div>
    </>
  );
}

function EvaluationContextField({
  contextSchema,
  onChange,
  value,
}: {
  contextSchema: SchemaEntryFragment;
  value: Value;
  onChange: (v: Value) => void;
}) {
  const { type, textFieldProps } = getInputType(contextSchema);

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const v = e.target.value;
    onChange(type === 'number' ? Number(v) : v);
  };

  if (type === 'select') {
    return (
      <ChipInput
        value={value}
        {...textFieldProps}
        onChange={handleChange}
        type={type}
      />
    );
  }

  if (type === 'country') {
    return (
      <ChipCountryPicker
        onChange={c => onChange(c as string)}
        value={!!value ? `${value}` : null}
        multiple={false}
      />
    );
  }

  if (type === 'boolean') {
    return (
      <ChipBooleanPicker
        value={value ? 'TRUE' : 'FALSE'}
        handleChange={c => onChange(c === 'TRUE')}
      />
    );
  }

  if (type === 'timestamp') {
    return (
      <ChipDatetimePicker
        value={!!value ? `${value}` : null}
        onChange={c => onChange(c as string)}
      />
    );
  }

  if (type === 'date') {
    return (
      <ChipDatePicker
        value={!!value ? `${value}` : null}
        onChange={c => onChange(c as string)}
      />
    );
  }

  return <ChipInput value={value} onChange={handleChange} type={type} />;
}
