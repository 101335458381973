// export * from './RecommendationCard-old';
import { default as Root } from './RecommendationCard';
import { default as ActionArea } from './RecommendationCardActionArea';
import { default as Body } from './RecommendationCardBody';
import { default as Content } from './RecommendationCardContent';
import { default as Criteria } from './RecommendationCardCriteria';
import { default as CriteriaItem } from './RecommendationCardCriteriaItem';
import { default as Divider } from './RecommendationCardDivider';
import { default as Header } from './RecommendationCardHeader';
import { default as Image } from './RecommendationCardImage';

const pkg = Object.assign(Root, {
  ActionArea,
  Content,
  Criteria,
  CriteriaItem,
  Body,
  Divider,
  Image,
  Header,
});

export {
  pkg as RecommendationCard,
  ActionArea as RecommendationCardActionArea,
  Body as RecommendationCardBody,
  Content as RecommendationCardContent,
  Criteria as RecommendationCardCriteria,
  CriteriaItem as RecommendationCardCriteriaItem,
  Divider as RecommendationCardDivider,
  Image as RecommendationCardImage,
};
