import { default as Root } from './SchemaEntry';
import { default as Actions } from './SchemaEntryActions';
import { default as Details } from './SchemaEntryDetails';
import { default as Error } from './SchemaEntryError';
import { default as Expand } from './SchemaEntryExpand';
import { default as Nested } from './SchemaEntryNested';

const pkg = Object.assign(Root, {
  Actions,
  Error,
  Expand,
  Details,
  Nested,
});

export {
  pkg as SchemaEntry,
  Actions as SchemaEntryActions,
  Error as SchemaEntryError,
  Expand as SchemaEntryExpand,
  Details as SchemaEntryDetails,
  Nested as SchemaEntryNested,
};
