import {
  FlagsTypesV1TargetingCriterion,
  FlagsTypesV1TargetingCriterionAttributeCriterion,
  FlagsTypesV1TargetingRangeRule,
  FlagsTypesV1TargetingValue,
  Maybe,
  TargetingCriterionFragment,
} from '@spotify-confidence/plugin-graphql';
import _ from 'lodash';

import {
  ATTRIBUTE_OPERATORS,
  AttributeOperator,
  AttributeType,
  AttributeValue,
  Criterion,
  CriterionOption,
  ListMatcherType,
  RULE_TO_OPERATOR,
  ValueType,
} from '../../targeting.model';

export const cleanValue = <T extends object>(input: T | null | undefined) =>
  _.omit(
    _.pickBy(input, v => !_.isNull(v)),
    '__typename',
  );

const extractValues = (
  attribute: FlagsTypesV1TargetingCriterion['attribute'],
): FlagsTypesV1TargetingValue | FlagsTypesV1TargetingValue[] | undefined => {
  if (!attribute) {
    return undefined;
  }

  if (attribute.anyRule) {
    return extractValues(attribute.anyRule.rule);
  }

  if (attribute.allRule) {
    return extractValues(attribute.allRule.rule);
  }

  if (attribute.eqRule) {
    const rule = attribute.eqRule;
    return cleanValue<FlagsTypesV1TargetingValue>(rule.value);
  }

  if (attribute.setRule) {
    const rule = attribute.setRule;
    return rule.values
      .map(cleanValue)
      .filter(v => !!v) as FlagsTypesV1TargetingValue[];
  }

  if (attribute.rangeRule) {
    const rule = attribute.rangeRule;
    const rangeRule = cleanValue<FlagsTypesV1TargetingRangeRule>(rule);
    const rangeValue = Object.values(rangeRule || {}).map(v => cleanValue(v));
    return rangeValue;
  }

  return undefined;
};

const fromSchemaAttributeType = (
  criterion: TargetingCriterionFragment,
  criterionOption?: CriterionOption,
) => {
  const obj = extractValues(criterion.attribute);

  if (!obj) return criterionOption?.type || 'Any';
  const targetingValue = (Array.isArray(obj) ? obj : [obj]).reduce(
    (acc, cur) => ({
      ...acc,
      ...cur,
    }),
    {},
  );
  const k = Object.keys(targetingValue).find(() => true);
  switch (k) {
    case 'timestampValue':
      return 'Timestamp';
    case 'boolValue':
      return 'Boolean';
    case 'stringValue':
      return 'String';
    case 'numberValue':
      return 'Number';
    case 'versionValue':
      return 'Version';
    default:
      return criterionOption?.type || 'Any';
  }
};

const fromSchemaListMatcherType = ({
  attribute,
}: TargetingCriterionFragment): ListMatcherType | undefined => {
  if (attribute?.anyRule) {
    return 'Any';
  }
  if (attribute?.allRule) {
    return 'All';
  }
  return undefined;
};

const extractValueFromType = (
  attributeType: AttributeType,
  nullFallback: any = null,
) => {
  return (value?: Maybe<FlagsTypesV1TargetingValue>): ValueType => {
    switch (attributeType) {
      case 'String':
        if (value?.stringValue === null) {
          return nullFallback;
        }
        return value?.stringValue ?? '';
      case 'Boolean':
        if (value?.boolValue === null) {
          return nullFallback;
        }
        return value?.boolValue === true ? 'TRUE' : 'FALSE';
      case 'Number':
        if (value?.numberValue === null) {
          return nullFallback;
        }
        return value?.numberValue?.toString() ?? '';
      case 'Timestamp':
        if (value?.timestampValue === null) {
          return nullFallback;
        }
        return value?.timestampValue?.toString() ?? '';
      case 'Version':
        if (value?.versionValue === null) {
          return nullFallback;
        }
        return value?.versionValue?.version?.toString() ?? '';
      case 'Any':
      default:
        return nullFallback;
    }
  };
};

const fromSchemaOp = (
  attribute: FlagsTypesV1TargetingCriterion['attribute'],
  attributeType?: AttributeType,
  not?: boolean,
): AttributeOperator => {
  if (!attributeType || !attribute) return '=';

  const map = RULE_TO_OPERATOR[attributeType];

  if (attribute.anyRule) {
    return fromSchemaOp(attribute.anyRule.rule, attributeType, not);
  }

  if (attribute.allRule) {
    return fromSchemaOp(attribute.allRule.rule, attributeType, not);
  }

  if (attribute.eqRule) {
    const value = extractValueFromType(attributeType)(attribute.eqRule.value);
    if (value === null) {
      return not ? 'Is not null' : 'Is null';
    }
    return (
      (not ? map.eqRule_not : map.eqRule) ||
      ATTRIBUTE_OPERATORS[attributeType][0]
    );
  }

  if (attribute.setRule) {
    return (not ? map.setRule_not : map.setRule) || 'In';
  }

  if (attribute.rangeRule) {
    if (
      (attribute.rangeRule.startInclusive ||
        attribute.rangeRule.startExclusive) &&
      (attribute.rangeRule.endInclusive || attribute.rangeRule.endExclusive)
    ) {
      return (not ? map.rangeRule_not : map.rangeRule) || 'Is between';
    }
    if (attribute.rangeRule.startInclusive)
      return map.rangeRule_startInclusive || 'Is on or before';
    if (attribute.rangeRule.startExclusive)
      return map.rangeRule_startExclusive || 'Is after';
    if (attribute.rangeRule.endInclusive)
      return map.rangeRule_endInclusive || 'Is on or before';
    if (attribute.rangeRule.endExclusive)
      return map.rangeRule_endExclusive || 'Is before';
  }
  return ATTRIBUTE_OPERATORS[attributeType][0];
};

const decodeValueFromSchema = (
  attributeCriterion: Maybe<FlagsTypesV1TargetingCriterionAttributeCriterion>,
  type: AttributeType,
): AttributeValue => {
  if (!attributeCriterion) return '';

  if (attributeCriterion.anyRule) {
    return decodeValueFromSchema(attributeCriterion.anyRule.rule!, type);
  }

  if (attributeCriterion.allRule) {
    return decodeValueFromSchema(attributeCriterion.allRule.rule!, type);
  }

  if (attributeCriterion.eqRule) {
    const getValue = extractValueFromType(type);
    return getValue(attributeCriterion.eqRule.value);
  }

  const getValue = extractValueFromType(type, '');
  if (attributeCriterion.setRule) {
    return attributeCriterion.setRule.values
      .map(getValue)
      .filter(v => !!v) as ValueType[];
  }
  if (attributeCriterion.rangeRule) {
    const { rangeRule } = attributeCriterion;
    const { startExclusive, startInclusive, endExclusive, endInclusive } =
      rangeRule;
    const hasStart = !!startExclusive || !!startInclusive;
    const hasEnd = !!endExclusive || !!endInclusive;
    const isClosedRange = hasStart && hasEnd;
    if (isClosedRange) {
      return {
        start: {
          value: getValue(startInclusive ? startInclusive : startExclusive!),
          inclusive: !!startInclusive,
        },
        end: {
          value: getValue(endInclusive ? endInclusive : endExclusive!),
          inclusive: !!endInclusive,
        },
      };
    }
    return getValue(
      startInclusive ?? startExclusive ?? endInclusive ?? endExclusive,
    );
  }

  return '';
};

export const fromSchemaAttributeCriterion = (
  name: string,
  criterion: TargetingCriterionFragment,
  not?: boolean,
  criterionOption?: CriterionOption,
): Criterion => {
  const attributeType = fromSchemaAttributeType(criterion, criterionOption);
  const listMatcherType = fromSchemaListMatcherType(criterion);
  const op = fromSchemaOp(criterion.attribute, attributeType, not);
  const value = decodeValueFromSchema(criterion.attribute!, attributeType);

  return {
    name,
    type: 'attribute',
    attribute: criterion.attribute!.attributeName || '',
    attributeType,
    op,
    value,
    ...(listMatcherType !== undefined ? { listMatcherType } : {}),
  };
};
