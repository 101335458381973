import {
  ConnectorsV1AwsRegion,
  ConnectorsV1S3BucketConfigInput,
} from '@spotify-confidence/plugin-graphql';

export const defaultS3BucketConfig: ConnectorsV1S3BucketConfigInput = {
  bucket: '',
  region: ConnectorsV1AwsRegion.EuWest_1,
  roleArn: '',
};

export const isS3BucketConfigValid = (
  config: ConnectorsV1S3BucketConfigInput,
) => {
  return Boolean(config.bucket && config.region && config.roleArn);
};
