import React from 'react';
import { useList } from 'react-use';

type EditableState = {
  isEditingAny: boolean;
  setIsEditing: (editId: string, editing: boolean) => void;
  editingStates: string[];
};

const EditableContext = React.createContext<EditableState>({
  isEditingAny: false,
  setIsEditing: () => {},
  editingStates: [],
});

export const EditableProvider = ({ children }: React.PropsWithChildren<{}>) => {
  const [editingStates, editingStatesMethods] = useList<string>([]);
  const isEditingAny = editingStates.length > 0;

  const setIsEditing = (editId: string, isEditing: boolean) => {
    if (!isEditing) {
      if (editingStates.includes(editId)) {
        editingStatesMethods.filter(state => state !== editId);
      }
    } else {
      if (!editingStates.includes(editId)) {
        editingStatesMethods.push(editId);
      }
    }
  };

  const state = { isEditingAny, setIsEditing, editingStates };
  return (
    <EditableContext.Provider value={state}>
      {children}
    </EditableContext.Provider>
  );
};

export const useEditable = () => React.useContext(EditableContext);
