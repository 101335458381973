import React from 'react';

import { Box, BoxProps, makeStyles, useTheme } from '@material-ui/core';

import { AxisBottom, AxisLeft } from '@visx/axis';
import { localPoint } from '@visx/event';
import { GridRows } from '@visx/grid';
import { Group } from '@visx/group';
import ParentSize from '@visx/responsive/lib/components/ParentSize';
import { scaleLinear, scaleTime } from '@visx/scale';
import { Area, Bar, Line, LinePath } from '@visx/shape';
import { curveMonotoneX, extent } from 'd3';
import { isSameDay, isSameHour } from 'date-fns';
import _ from 'lodash';

import { BucketingTemporalUnit } from '../../../../../MetricsModule';
import { ResultData, TableMetaOptions } from '../types';
import { SignificanceGradient } from './SignificanceGradient';
import { useChartTooltip } from './useChartTooltip';
import { useResultChart } from './useResultChart';
import { getResultColor } from './utils';

export type TimelineChartProps = TableMetaOptions & {
  results: ResultData[];
  width?: number;
  height?: number;
  padding?: { top?: number; bottom?: number; left?: number; right?: number };
  BoxProps?: Partial<BoxProps>;
  axisPosition?: 'left' | 'right';
};

const useStyles = makeStyles(theme => ({
  label: {
    fill: theme.palette.text.secondary,
    fontFamily: theme.typography.fontFamily,
    fontSize: theme.typography.caption.fontSize,
  },
}));

const getDate = (d: ResultData) => new Date(d.timeLabel);

// TODO: handle ungrouped results

export const TimelineChart = ({
  results = [],
  bucket,
  showRelativeChanges,
  height = 300,
  width = 800,
  padding: propsPadding,
  BoxProps: boxProps,
  axisPosition = 'left',
}: TimelineChartProps) => {
  const theme = useTheme();
  const classes = useStyles();

  const padding = {
    top: theme.spacing(2),
    left: theme.spacing(8),
    right: theme.spacing(2),
    bottom: theme.spacing(4),
    ...propsPadding,
  };

  const { yMax, xMax, maxYValue, getLower, getUpper, getEstimate } =
    useResultChart({ results, height, width, showRelativeChanges, padding });

  const yScale = scaleLinear<number>({
    range: [yMax + padding.top, padding.top],
    domain: [-maxYValue, maxYValue],
    nice: true,
  });

  const timeScale = React.useMemo(() => {
    return scaleTime<number>({
      range: [padding.left, xMax + padding.left],
      domain: extent(results, getDate) as [Date, Date],
    });
  }, [padding.left, xMax, results]);

  const {
    containerRef,
    renderTooltip,
    showTooltip,
    hideTooltip,
    tooltipData,
    tooltipLeft,
    tooltipTop,
    tooltipOpen,
  } = useChartTooltip({ showRelativeChanges, bucket });

  const showRowTooltip = React.useCallback(
    (event: React.MouseEvent<SVGRectElement>) => {
      const { x } = localPoint(event) || { x: 0 };
      const x0 = timeScale.invert(x);
      const d = results.find(r =>
        bucket === BucketingTemporalUnit.HOURS
          ? isSameHour(getDate(r), x0)
          : isSameDay(getDate(r), x0),
      );
      if (d) {
        showTooltip({
          tooltipData: d,
          tooltipLeft: x,
          tooltipTop: yScale(getEstimate(d)),
        });
      }
    },
    [bucket, getEstimate, results, showTooltip, timeScale, yScale],
  );

  const uniqueGradientId = React.useRef(_.uniqueId('timeline-chart'));

  return (
    <Box {...boxProps}>
      <svg ref={containerRef} width={width} height={height}>
        <defs>
          <SignificanceGradient
            id={uniqueGradientId.current}
            results={results}
            bucket={bucket}
          />
        </defs>
        <GridRows
          scale={yScale}
          width={xMax}
          height={yMax}
          left={padding.left}
          stroke={theme.palette.divider}
          numTicks={5}
        >
          {({ lines }) =>
            lines.map(({ from, to, index }) => (
              <Line
                key={`row-line-${index}`}
                from={from}
                to={to}
                stroke={
                  index === Math.floor(lines.length / 2)
                    ? theme.palette.textVerySubtle
                    : theme.palette.divider
                }
                strokeWidth={1}
              />
            ))
          }
        </GridRows>

        <LinePath<ResultData>
          curve={curveMonotoneX}
          data={results}
          x={r => timeScale(getDate(r) ?? 0)}
          y={r => yScale(getEstimate(r)) ?? 0}
          stroke={`url(#${uniqueGradientId.current})`}
          strokeWidth={2}
          shapeRendering="geometricPrecision"
        />
        <Area<ResultData>
          curve={curveMonotoneX}
          data={results}
          x={r => timeScale(getDate(r) ?? 0)}
          y0={r => yScale(getUpper(r)) ?? 0}
          y1={r => yScale(getLower(r)) ?? 0}
          fill={`url(#${uniqueGradientId.current})`}
          fillOpacity={0.5}
          shapeRendering="geometricPrecision"
        />

        <Bar
          x={padding.left}
          y={padding.top}
          width={xMax}
          height={yMax}
          fill="transparent"
          onMouseMove={showRowTooltip}
          onMouseLeave={hideTooltip}
        />

        <AxisLeft
          scale={yScale}
          left={
            axisPosition === 'left' ? padding.left : xMax - padding.right + 10
          }
          stroke={theme.palette.text.primary}
          numTicks={5}
          tickFormat={tick => (showRelativeChanges ? `${tick}%` : `${tick}`)}
          hideAxisLine
          hideTicks
          tickLabelProps={{
            width: padding.left,
            className: classes.label,
          }}
        />
        <AxisBottom
          scale={timeScale}
          top={yMax + padding.top}
          stroke={theme.palette.text.primary}
          tickLabelProps={{
            className: classes.label,
          }}
          hideAxisLine
          hideTicks
        />
        {tooltipOpen && tooltipData && (
          <Group>
            <Line
              from={{ x: tooltipLeft, y: padding.top }}
              to={{ x: tooltipLeft, y: yMax + padding.top }}
              stroke={getResultColor(tooltipData, theme)}
              strokeWidth={1}
              pointerEvents="none"
            />
            <circle
              cx={tooltipLeft}
              cy={tooltipTop}
              r={4}
              fill={getResultColor(tooltipData, theme)}
              stroke={theme.palette.background.paper}
              strokeWidth={1}
              pointerEvents="none"
            />
          </Group>
        )}
      </svg>

      {renderTooltip()}
    </Box>
  );
};

export const ResponsiveTimelineChart = (props: TimelineChartProps) => {
  return (
    <ParentSize>
      {parent => <TimelineChart width={parent.width} {...props} />}
    </ParentSize>
  );
};
