/* eslint-disable no-nested-ternary */
import React from 'react';

import { Box, BoxProps, Tooltip } from '@material-ui/core';

import { NumberUtils } from '../utils';

export type NumeralProps = {
  prefix?: string | false;
  value?: number | string;
  invalidValue?: string;
  suffix?: string | false;
  formatter?: (value: number) => string;
  infinityAsSymbol?: boolean;
  type?: 'monospace' | 'tabular';
};

const defaultFormatter = NumberUtils.formatNumber;

export const formatIfFinite = (
  number: number,
  infinityAsSymbol: boolean,
  formatter: NumeralProps['formatter'],
) =>
  formatter && isFinite(number)
    ? formatter(number)
    : infinityAsSymbol && number === -Infinity
    ? '-∞'
    : infinityAsSymbol && number === Infinity
    ? '∞'
    : String(number);

// check if value is a numerical number or string (parseFloat also parses Infinity)
const isNumerical = (value: unknown) => !isNaN(parseFloat(value as string));

// @ts-ignore
export const Numeral: React.FC<
  NumeralProps & Omit<BoxProps, keyof NumeralProps>
> = React.forwardRef(
  (
    {
      prefix,
      value,
      infinityAsSymbol = false,
      invalidValue = 'N/A',
      suffix,
      formatter = defaultFormatter,
      type = 'tabular',
      ...boxProps
    },
    ref,
  ) => {
    const showSuffix =
      !(!isFinite(Number(value)) && infinityAsSymbol) && suffix;
    const getDecoratedValue = (number: string) =>
      [prefix ? prefix : '', number, showSuffix ? suffix : ''].join('');

    const formattedValue = isNumerical(value)
      ? getDecoratedValue(
          formatIfFinite(Number(value), infinityAsSymbol, formatter),
        )
      : invalidValue;
    const fullValue = isNumerical(value)
      ? getDecoratedValue(String(value))
      : invalidValue;

    const hasHiddenInformation =
      fullValue.length > formattedValue.length && !infinityAsSymbol;

    const typeProps: Partial<BoxProps> = {};

    if (type === 'monospace') {
      typeProps.fontFamily = 'monospace';
    } else if (type === 'tabular') {
      typeProps.style = {
        ...typeProps.style,
        fontFeatureSettings: "'tnum'",
        fontVariantNumeric: 'tabular-num',
      };
    }

    return (
      <Tooltip
        arrow
        placement="top"
        title={hasHiddenInformation ? fullValue : ''}
      >
        <Box
          component="span"
          {...typeProps}
          {...boxProps}
          {...({ ref } as any)}
        >
          {formattedValue}
        </Box>
      </Tooltip>
    );
  },
);
