import React from 'react';

import { Tooltip, makeStyles } from '@material-ui/core';
import NimSymbol from '@material-ui/icons/KeyboardTab';

import classNames from 'classnames';
import * as d3 from 'd3';
import _ from 'lodash';

import { SignificantDirection } from '../MetricResultTable';
import { BaseTooltip } from './Tooltips';
import { DEFAULT_WIDTH } from './constants';

const useStyles = makeStyles(theme => {
  return {
    /* Tooltip */
    popover: {
      maxWidth: 400,
    },
    /* SVG */
    ci: (props: ConfidenceIntervalCellProps) => ({
      minWidth: props.width,
      minHeight: props.height,
      maxWidth: props.width,
      maxHeight: props.height,
      color: theme.palette.text.disabled,
    }),
    significant: {
      '&$ci': {
        color: theme.palette.text.primary,
      },
    },
    line: {
      stroke: theme.palette.text.secondary,
      opacity: 0.4,
      strokeWidth: 1,
    },
    difference: {
      fill: theme.palette.background.default,
      stroke: theme.palette.divider,
      strokeWidth: 1,
    },
    infinity: {
      fill: theme.palette.text.primary,
    },
    interval: {
      fill: 'currentColor',
    },
    upperInfinite: {
      fill: 'url(#upperInfinite)',
    },
    lowerInfinite: {
      fill: 'url(#lowerInfinite)',
    },
    opaqueBarGradient: {
      stopColor: 'currentColor',
      stopOpacity: 1,
    },
    fadingBarGradient: {
      stopColor: 'currentColor',
      stopOpacity: 0,
    },
    nim: {
      color: theme.palette.text.secondary,
    },
    overlappingNim: {
      color: theme.palette.warning.light,
    },
    positiveNim: {
      '& path': {
        transform: 'rotate(180deg)',
        transformOrigin: 'center',
      },
    },
  };
});

function isInfinity(number: number) {
  return number === Infinity || number === -Infinity;
}

export type ConfidenceIntervalCellProps = {
  lowerCI?: number;
  upperCI?: number;
  observedDifference?: number;
  minLower?: number;
  maxUpper?: number;
  unit?: string;
  nim?: number;
  mde?: number;
  significant?: boolean | null;
  direction?: SignificantDirection;

  // Styling
  className?: string;
  height?: number;
  width?: number;
  padding?: number;

  // Tooltip
  tooltipContent?: React.ReactNode;
};

export default function ConfidenceIntervalCell({
  lowerCI = 0,
  upperCI = 0,
  observedDifference = 0,
  minLower = lowerCI,
  maxUpper = upperCI,
  unit = '%',
  nim,
  // mde,
  significant,

  height = 40,
  width = DEFAULT_WIDTH,
  padding = 20,

  tooltipContent,
}: ConfidenceIntervalCellProps) {
  const classes = useStyles({ width, height });
  const barHeight = height / 2;
  const iconSize = _.round(height * 0.6);

  // domain: value (for example %), range: width (pixels)
  const scale = d3
    .scaleLinear()
    .domain([minLower, maxUpper])
    .range([0, width - padding * 2]);

  const lowerIsInfinite = isInfinity(lowerCI);
  const upperIsInfinite = isInfinity(upperCI);

  const scaledX = lowerIsInfinite ? 0 : Math.max(scale(lowerCI), 0) + padding;
  const scaledWidth = upperIsInfinite
    ? width - scaledX
    : Math.min(scale(upperCI) - scaledX, width - padding - scaledX) + padding;

  const middleOfHeight = height / 2;
  const halfOfBar = barHeight / 2;

  const nimPosition = nim && nim !== 0 ? scale(nim) + padding : 0;

  let zeroPosition = scale(0) + padding;

  if (maxUpper < 0) {
    zeroPosition = scale(maxUpper) + padding;
  } else if (minLower > 0) {
    zeroPosition = scale(minLower) + padding;
  }

  return (
    <Tooltip
      title={
        <div className={classes.popover}>
          {tooltipContent || (
            <BaseTooltip lowerCI={lowerCI} upperCI={upperCI} unit={unit} />
          )}
        </div>
      }
    >
      {/* Visualisation */}
      <svg
        width="100%"
        height="100%"
        viewBox={`0 0 ${width} ${height}`}
        className={classNames(classes.ci, {
          [classes.significant]: significant,
        })}
        preserveAspectRatio="none"
      >
        <defs>
          <linearGradient
            id="upperInfinite"
            gradientUnits="userSpaceOnUse"
            x1={padding}
            x2={width}
          >
            <stop className={classes.opaqueBarGradient} offset="10%" />
            <stop className={classes.opaqueBarGradient} offset="50%" />
            <stop className={classes.fadingBarGradient} offset="100%" />
          </linearGradient>
          <linearGradient
            id="lowerInfinite"
            gradientUnits="userSpaceOnUse"
            x1={padding}
            x2={width}
          >
            <stop className={classes.fadingBarGradient} offset="0%" />
            <stop className={classes.opaqueBarGradient} offset="40%" />
            <stop className={classes.opaqueBarGradient} offset="90%" />
          </linearGradient>
        </defs>
        <line
          className={classes.line}
          x1={0}
          y1={middleOfHeight}
          x2={width}
          y2={middleOfHeight}
        />
        <line
          className={classes.line}
          x1={zeroPosition}
          y1={0}
          x2={zeroPosition}
          y2={height}
          strokeDasharray={1}
        />
        <rect
          className={classNames(classes.interval, {
            [classes.upperInfinite]: upperIsInfinite,
            [classes.lowerInfinite]: lowerIsInfinite,
          })}
          height={barHeight}
          width={scaledWidth}
          x={scaledX}
          y={middleOfHeight - halfOfBar}
          rx={2}
        />
        {(lowerIsInfinite || upperIsInfinite) && (
          <text
            className={classes.infinity}
            fontSize={Math.max(barHeight, padding)}
            dominantBaseline="central"
            y={middleOfHeight}
            x={lowerIsInfinite ? 0 : width}
            textAnchor={lowerIsInfinite ? 'start' : 'end'}
          >
            ∞
          </text>
        )}
        {!_.isNil(nim) && (
          <NimSymbol
            viewBox={nim > 0 ? '2 0 24 24' : '-2 0 24 24'}
            height={iconSize}
            width={iconSize}
            x={nim > 0 ? nimPosition : nimPosition - iconSize}
            y={middleOfHeight - iconSize / 2}
            className={classNames(classes.nim, {
              [classes.positiveNim]: nim > 0,
              [classes.overlappingNim]: !significant,
            })}
          />
        )}
        <circle
          className={classes.difference}
          cx={scale(observedDifference) + padding}
          cy={middleOfHeight}
          r={_.round(barHeight / 6)}
        />
      </svg>
    </Tooltip>
  );
}
