import React from 'react';

import { useSaveWorkflowInstanceModuleDataMutation } from '@spotify-confidence/plugin-workflows';

import { BucketingTemporalUnit, Metric, MetricsData } from './types';

export const useSaveMetrics = ({
  metricsData,
  name,
}: {
  metricsData?: MetricsData;
  name?: string;
}) => {
  // making sure previous object is not locked in closure between saveConfig and other methods
  // previous value due to context value is not synced in dialog
  const metricsDataRef = React.useRef(metricsData);
  React.useEffect(() => {
    metricsDataRef.current = metricsData;
  }, [metricsData]);

  const [saveInstanceModules, queryResult] =
    useSaveWorkflowInstanceModuleDataMutation();

  const onMetricsModuleSave = async (newMetricsData: MetricsData) => {
    return saveInstanceModules({
      variables: {
        workflowInstance: {
          name,
          moduleData: [{ key: 'metrics', value: newMetricsData }],
        },
        updateMask: 'moduleData.metrics',
      },
    });
  };

  return {
    saveConfig: async (
      newConfig: Pick<MetricsData, 'assignmentTable' | 'bucket' | 'entity'>,
    ) => {
      return onMetricsModuleSave({
        ...metricsDataRef.current,
        metrics: metricsDataRef.current?.metrics || [],
        ...newConfig,
      });
    },

    addMetric: async (newMetric: Metric) => {
      return onMetricsModuleSave({
        ...metricsDataRef.current,
        bucket:
          metricsDataRef.current?.bucket ||
          BucketingTemporalUnit.BUCKETING_TEMPORAL_UNIT_UNSPECIFIED,
        metrics: metricsDataRef.current?.metrics.concat(newMetric) || [
          newMetric,
        ],
      });
    },

    removeMetric: async (metricToRemove: string) => {
      return onMetricsModuleSave({
        ...metricsDataRef.current,
        bucket:
          metricsDataRef.current?.bucket ||
          BucketingTemporalUnit.BUCKETING_TEMPORAL_UNIT_UNSPECIFIED,
        metrics: (metricsDataRef.current?.metrics || []).filter(
          m => m.metric !== metricToRemove,
        ),
      });
    },

    updateMetric: async (newMetricValue: Metric) => {
      return onMetricsModuleSave({
        ...metricsDataRef.current,
        bucket:
          metricsDataRef.current?.bucket ||
          BucketingTemporalUnit.BUCKETING_TEMPORAL_UNIT_UNSPECIFIED,
        metrics: [...(metricsDataRef.current?.metrics || [])].map(m => {
          return m.metric === newMetricValue.metric ? newMetricValue : m;
        }),
      });
    },

    updateMetrics: async (newMetrics: Metric[]) => {
      return onMetricsModuleSave({
        ...metricsDataRef.current,
        bucket:
          metricsDataRef.current?.bucket ||
          BucketingTemporalUnit.BUCKETING_TEMPORAL_UNIT_UNSPECIFIED,
        metrics: newMetrics,
      });
    },

    ...queryResult,
  };
};
