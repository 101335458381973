import React from 'react';

import { TableRowProps } from '@material-ui/core';

import { ResourcePathArgs, useResourcePath } from '../../hooks/useResourcePath';
import { LinkableTableRow } from '../LinkableTableRow';

export type ResourceTableRowProps = TableRowProps & ResourcePathArgs;

export const ResourceTableRow = ({
  routeRef,
  name,
  children,
  params,
  ...tableRowProps
}: ResourceTableRowProps) => {
  const path = useResourcePath({ routeRef, name, params });

  return (
    <LinkableTableRow {...tableRowProps} to={path || ''}>
      {children}
    </LinkableTableRow>
  );
};
