import React from 'react';
import { InView } from 'react-intersection-observer';
import { useSearchParams } from 'react-router-dom';

import {
  Button,
  CircularProgress,
  IconButton,
  Tooltip,
  Typography,
} from '@material-ui/core';
import { Breakpoint } from '@material-ui/core/styles/createBreakpoints';
import Close from '@material-ui/icons/Close';
import { Alert } from '@material-ui/lab';

import { NarrowBox } from '@spotify-confidence/core-react';
import _ from 'lodash';

import { useAnalytics } from '@backstage/core-plugin-api';

import {
  StepProgressBar,
  TitleWrapper,
  WizardContent,
  WizardFooter,
  WizardHeader,
  WizardLoadingOverlay,
  WizardStepWrapper,
  WizardWrapper,
} from './WizardComponents';

export type WizardStep = {
  name: string;
  description?: React.ReactNode;
  content: React.ReactNode;
  optional?: boolean;
  disableNext?: boolean;
  forceReview?: boolean;
};

type WizardStepperProps = {
  onClose?: () => void;
  onSubmit?: () => void;
  steps?: WizardStep[];
  submitLabel?: string;
  cancelLabel?: string;
  backLabel?: string;
  nextLabel?: string;
  disableNext?: boolean;
  error?: Error;
  loading?: boolean;
  activeStep?: number;
  onStepChange?: (newStep: number) => void;
  formName?: string;
  width?: Breakpoint;
  subject?: string;
};

export const WizardStepper = ({
  steps = [],
  onClose,
  onSubmit,
  submitLabel = 'Submit',
  backLabel = 'Back',
  nextLabel = 'Next',
  cancelLabel = 'Cancel',
  disableNext = false,
  error,
  loading,
  activeStep: controlledActiveStep,
  onStepChange,
  formName,
  width = 'sm',
  subject,
}: WizardStepperProps) => {
  const [searchParams, setUrlSearchParams] = useSearchParams();
  const [internalActiveStepIndex, setInternalActiveStepIndex] =
    React.useState(0);
  const searchIndex = searchParams.get('step')
    ? Number(searchParams.get('step'))
    : undefined;
  const activeStepIndex = controlledActiveStep ?? internalActiveStepIndex;
  const activeStep = steps[activeStepIndex] || steps[0];
  const lastIndex = steps.length - 1;
  const isLastStep = activeStepIndex === lastIndex;
  const analytics = useAnalytics();

  const [hasSeenFullStep, setHasSeenFullStep] = React.useState(false);
  const progressPercentage = (activeStepIndex / lastIndex) * 100;

  const handleNextStep = (e?: React.MouseEvent<HTMLButtonElement>) => {
    if (!isLastStep) {
      e?.preventDefault();
      setInternalActiveStepIndex(current => {
        const nextStep = Math.min(
          (controlledActiveStep ?? current) + 1,
          lastIndex,
        );
        onStepChange?.(nextStep);

        searchParams.set('step', String(nextStep));
        setUrlSearchParams(searchParams);
        return nextStep;
      });
    }
  };

  const handleGoBack = () => {
    setInternalActiveStepIndex(current => {
      const nextStep = Math.max((controlledActiveStep ?? current) - 1, 0);
      onStepChange?.(nextStep);

      searchParams.set('step', String(nextStep));
      setUrlSearchParams(searchParams);
      return nextStep;
    });
  };

  const handleClose = () => {
    searchParams.delete('step');
    setUrlSearchParams(searchParams);
    onClose?.();
  };

  const handleSubmit = (e: React.FormEvent) => {
    e.preventDefault();
    if (isLastStep) {
      onSubmit?.();
    } else {
      handleNextStep();
    }
  };

  React.useEffect(() => {
    if (
      !_.isNil(controlledActiveStep) &&
      controlledActiveStep !== searchIndex
    ) {
      searchParams.set('step', String(controlledActiveStep));
      setUrlSearchParams(searchParams);
    }
  }, [controlledActiveStep]);

  React.useEffect(() => {
    if (!_.isNil(searchIndex)) {
      const normalizedSearchIndex = Math.max(
        Math.min(searchIndex, lastIndex),
        0,
      );
      if (normalizedSearchIndex !== activeStepIndex) {
        onStepChange?.(normalizedSearchIndex);
        setInternalActiveStepIndex(normalizedSearchIndex);
      }
    }
  }, [searchIndex]);

  React.useEffect(() => {
    if (subject) {
      analytics.captureEvent('impression', subject, {
        value: activeStepIndex,
      });
    }
  }, [subject, activeStepIndex]);

  let nextIsDisabled = loading;
  let disabledTooltip = '';

  if (disableNext || activeStep.disableNext) {
    nextIsDisabled = true;
    disabledTooltip =
      'It is not possible to proceed yet. Did you enter all the requested information?';
  } else if (
    !activeStep.optional &&
    !hasSeenFullStep &&
    activeStep?.forceReview
  ) {
    nextIsDisabled = true;
    disabledTooltip =
      'Please review this step before proceeding by scrolling down.';
  }

  return (
    <WizardWrapper>
      <WizardHeader>
        {onClose && (
          <IconButton data-testid="close-wizard" onClick={handleClose}>
            <Close />
          </IconButton>
        )}
      </WizardHeader>

      <form onSubmit={handleSubmit} name={formName}>
        <NarrowBox width={width}>
          <WizardContent>
            {steps.length > 1 && (
              <>
                <StepProgressBar
                  variant="determinate"
                  value={progressPercentage}
                />
                <Typography variant="body2" color="textSecondary">
                  Step {activeStepIndex + 1} of {lastIndex + 1}{' '}
                  {activeStep.optional ? (
                    <Typography
                      variant="inherit"
                      color="textSecondary"
                      component="span"
                    >
                      (optional)
                    </Typography>
                  ) : null}
                </Typography>
              </>
            )}

            <WizardStepWrapper>
              <TitleWrapper>
                <Typography variant="h4">{activeStep.name}</Typography>
                <Typography variant="body1" color="textSecondary">
                  {activeStep.description}
                </Typography>
              </TitleWrapper>
              {error && <Alert severity="error">{error.message}</Alert>}
              {activeStep.content}
              <InView onChange={setHasSeenFullStep} />
            </WizardStepWrapper>

            <WizardFooter>
              {onClose && activeStepIndex === 0 && (
                <Button onClick={handleClose} disabled={loading}>
                  {cancelLabel}
                </Button>
              )}
              {activeStepIndex > 0 && (
                <Button onClick={handleGoBack} disabled={loading}>
                  {backLabel}
                </Button>
              )}
              <Tooltip title={disabledTooltip} arrow placement="right-start">
                <div>
                  <Button
                    disabled={nextIsDisabled}
                    variant="contained"
                    color="primary"
                    onClick={handleNextStep}
                    type={isLastStep ? 'submit' : 'button'}
                  >
                    {isLastStep ? submitLabel : nextLabel}
                  </Button>
                </div>
              </Tooltip>
            </WizardFooter>
          </WizardContent>
        </NarrowBox>
      </form>
      {loading && (
        <WizardLoadingOverlay>
          <CircularProgress />
        </WizardLoadingOverlay>
      )}
    </WizardWrapper>
  );
};
