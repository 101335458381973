import { useCallback, useEffect, useMemo } from 'react';

import { useAlert } from '@spotify-confidence/core-react';
import {
  FgaV1Permission,
  FgaV1ResourcePermissionInput,
  IdentityFragment,
  ResourcePermissionFragment,
  ResourcePermissionFragmentDoc,
  getError,
  getTypeOrNull,
  isError,
  isType,
  useCheckResourcePermissionsMutation,
  useListResourcePermissionsQuery,
  useWriteResourcePermissionsMutation,
} from '@spotify-confidence/plugin-graphql';

import { RelatedResource } from './types';

/**
 * A hook that returns checks what resources can be shared by the
 * current user.
 *
 * @private
 * @param resources A list of resources to check
 * @returns A map from resource name to whether the resource can be shared
 */
const useSharableResources = (resources: string[]) => {
  const [check, { data }] = useCheckResourcePermissionsMutation({
    variables: {
      resourceRelations: resources.map(resource => ({
        resource,
        relation: 'can_share',
      })),
    },
  });

  useEffect(() => {
    if (resources.length > 0) {
      check();
    }
    // Since resources is an array, we need to stringify it to compare
    // or it will look as if it has changed every time.
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [check, JSON.stringify(resources)]);

  if (isError(data?.executeCheck)) {
    // TODO: handle this error when this hook is being used
    return new Map<string, boolean>();
  }
  return new Map<string, boolean>(
    data?.executeCheck?.results?.map(result => [
      result.relation!.resource,
      result.allowed ?? false,
    ]) ?? [],
  );
};

/**
 * Hook for managing resource permissions.
 *
 * @public
 * @param name The name of the resource.
 * @param relatedResources A list of related resources that should be shared
 *   together with the main resource.
 * @returns An object with the following properties:
 *  - permissions: The current permissions for the resource.
 *  - sharableResources: A map from resource name to whether the resource can be shared.
 *  - share: A function that shares the resource with a set of identities.
 *  - remove: A function that removes all access to the resource for an identity.
 *  - changeRole: A function that changes the role for an identity.
 *  - changeOwner: A function that changes the owner for the resource.
 */
export const useResourcePermissions = (
  name: string,
  relatedResources: RelatedResource[] = [],
) => {
  const alert = useAlert();

  // Queries
  const { data } = useListResourcePermissionsQuery({
    variables: {
      name,
    },
  });

  const permissions = useMemo(
    () =>
      getTypeOrNull(
        data?.resourcePermissions,
        'FgaV1ListResourcePermissionsResponse',
      )?.resourcePermissions ?? [],
    [data?.resourcePermissions],
  );

  // Mutations
  const [writeResourcePermissions] = useWriteResourcePermissionsMutation({
    onError: error => {
      alert.post({
        severity: 'error',
        message: error.message,
      });
    },
    update(cache, { data: response }) {
      const writeResourcePermissionsResponse = getTypeOrNull(
        response?.writeResourcePermissions,
        'FgaV1WriteResourcePermissionsResponse',
      );
      const responseError = getError(response?.writeResourcePermissions);
      if (writeResourcePermissionsResponse) {
        cache.modify({
          fields: {
            resourcePermissions(existing = {}, { storeFieldName }) {
              // this is a bit more complicated than it should be because apollo
              // do not take keyArg into consideration when we're updating the cache.
              // see https://github.com/apollographql/apollo-client/issues/7129
              //
              // this resulted in that the "resourcePermissions" had resource permissions
              // for multiple different resources after a cache update, where it in reality
              // should just have for the queried resource.
              //
              // to solve it, we need to manually filter out the resource permissions
              // that we do not want to update.

              // the storeFieldName is in the format of
              // resourcePermissions:{"resource":"workflows/abtest/instances/ojocrmqvsked4zqcbsv8"}
              // and we need to extract the resource name from it.
              const resource = /"resource":"(.*?)"/g.exec(storeFieldName)?.[1];

              const addedPermissions =
                writeResourcePermissionsResponse?.addedPermissions
                  .filter(p => p.resource === resource)
                  .map(p =>
                    cache.writeFragment({
                      data: p,
                      fragmentName: 'ResourcePermission',
                      fragment: ResourcePermissionFragmentDoc,
                    }),
                  ) ?? [];

              const removedPermissions =
                writeResourcePermissionsResponse?.removedPermissions?.map(
                  p => p.name,
                ) ?? [];

              return {
                ...existing,
                resourcePermissions: [
                  ...existing.resourcePermissions?.filter(
                    (p: any) =>
                      !removedPermissions.includes(
                        cache.readFragment<ResourcePermissionFragment>({
                          id: cache.identify(p),
                          fragment: ResourcePermissionFragmentDoc,
                          fragmentName: 'ResourcePermission',
                        })?.name!,
                      ),
                  ),
                  ...addedPermissions,
                ],
              };
            },
          },
        });
      } else if (responseError) {
        alert.post({
          message: responseError?.message,
          severity: 'error',
        });
      }
    },
  });

  // Figure out which of the related resources that we have permissions
  // to share.
  const sharableResources = useSharableResources([
    name,
    ...relatedResources.map(r => r.name),
  ]);

  // Share the resource (with related resources) to a set of identities.
  // Will only share resources that the user actually have permissions
  // to share.
  const share = useCallback(
    async (identities: string[], role: FgaV1Permission) => {
      const addPermissions: FgaV1ResourcePermissionInput[] = identities
        .map(identity => ({
          resource: name,
          identity: identity,
          resourcePermission: role,
        }))
        .concat(
          relatedResources.flatMap(
            ({ name: resourceName, defaultPermission }) =>
              identities.map(identity => ({
                resource: resourceName,
                identity: identity,
                resourcePermission: defaultPermission ?? role,
              })),
          ),
        )
        // Filter out the resources that we do not have permissions to share
        .filter(r => sharableResources.get(r.resource));

      const removePermissions: string[] = [];

      await writeResourcePermissions({
        variables: {
          addPermissions,
          removePermissions,
        },
      });
    },
    [name, relatedResources, sharableResources, writeResourcePermissions],
  );

  // Remove all access to the resource for a set an identity.
  const remove = useCallback(
    (identity: IdentityFragment) => {
      const removePermissions = permissions
        .filter(
          p =>
            isType(p.identity, 'IamV1Identity') &&
            p.identity.name === identity.name,
        )
        .map(p => p.name);

      writeResourcePermissions({
        variables: {
          addPermissions: [],
          removePermissions,
        },
      });
    },
    [permissions, writeResourcePermissions],
  );

  // Change the role of an identity (e.g., from Viewer to Editor)
  const changeRole = useCallback(
    async (identity: IdentityFragment, role: FgaV1Permission) => {
      const addPermissions: FgaV1ResourcePermissionInput[] = [
        {
          resource: name,
          identity: identity.name,
          resourcePermission: role,
        },
      ];

      const removePermissions = permissions
        .filter(
          p =>
            isType(p.identity, 'IamV1Identity') &&
            p.identity.name === identity.name,
        )
        .map(p => p.name);

      await writeResourcePermissions({
        variables: {
          addPermissions,
          removePermissions,
        },
      });
    },
    [name, permissions, writeResourcePermissions],
  );

  // Change the owner of the resource. This will remove all other permissions
  // and add the new owner.
  const changeOwner = useCallback(
    async (owner: string) => {
      const removePermissions: string[] = permissions
        .filter(p => p.resourcePermission === FgaV1Permission.Owner)
        .map(p => p.name);

      const addPermissions: FgaV1ResourcePermissionInput[] = [
        {
          identity: owner,
          resource: name,
          resourcePermission: FgaV1Permission.Owner,
        },
      ];

      await writeResourcePermissions({
        variables: {
          removePermissions,
          addPermissions,
        },
      });
    },
    [name, permissions, writeResourcePermissions],
  );

  return {
    permissions,
    sharableResources,
    share,
    remove,
    changeRole,
    changeOwner,
  };
};
