import React from 'react';

import {
  Box,
  Button,
  Collapse,
  Divider,
  MenuItem,
  TextField,
  Typography,
  styled,
} from '@material-ui/core';
import { Autocomplete, AutocompleteChangeDetails } from '@material-ui/lab';

import {
  ConfidenceAutocomplete,
  FormFieldsBox,
} from '@spotify-confidence/core-react';
import {
  EntityRowFragment,
  EventSchemaEntryFragment,
  EventsAdminV1EventSchema,
  getTypeOrNull,
  useListEntitiesLazyQuery,
} from '@spotify-confidence/plugin-graphql';
import _ from 'lodash';

import { useRouteRef } from '@backstage/core-plugin-api';

import { entitiesRouteRef } from '../../../routes';
import { findSchemaType, getTypeSchema } from '../../domain/schema.helpers';
import { Entry } from '../../domain/schema.hooks';
import { SCHEMA_TYPES, SEMANTIC_STRING_TYPES } from './AddFieldButton';
import { MAXIMUM_DEPTH } from './SchemaEditor';
import { TypeLabel } from './TypeLabel';

const Editor = styled('div')(({ theme }) => ({
  padding: theme.spacing(2),
  height: '100%',
  position: 'relative',
  [theme.breakpoints.up(620)]: {
    borderLeft: `1px solid ${theme.palette.divider}`,
  },
}));

const Sticky = styled('div')({
  position: 'sticky',
  top: 0,
});

// TODO: allow setting semantic type for lists of strings
export const EntryEditor = ({
  entry,
  onUpdate,
  onDelete,
  isPermanent: isPermanentProp,
  selectedEntry,
  usedKeys,
}: {
  onUpdate: (v: Partial<Entry>) => void;
  onDelete: () => void;
  usedKeys: string[];
  entry: EventSchemaEntryFragment;
  isPermanent: boolean;
  selectedEntry: string;
}) => {
  const { key, value } = entry;
  const { schemaType: type, semanticType } = findSchemaType(entry?.value);
  const displayName = value?.[type]?.displayName || null;
  const description = value?.[type]?.description || null;
  const metadata = {
    displayName,
    description,
  };

  const enumValue =
    semanticType === 'enumType'
      ? (value?.stringSchema?.semanticType?.enumType?.values ?? []).map(
          v => v.value || '',
        )
      : [];

  const entityValue =
    semanticType === 'entityReference'
      ? getTypeOrNull(
          value?.stringSchema?.semanticType?.entityReference?.entity,
          'MetricsV1Entity',
        )
      : null;

  const handleEnumChange = (
    _e: React.ChangeEvent<{}>,
    values: (string | string[])[],
  ) => {
    onUpdate({
      value: {
        stringSchema: {
          ...metadata,
          semanticType: {
            enumType: {
              values: _.uniq(
                values?.map(v => ({
                  value: _.isArray(v) ? v[0] : v || '',
                })) || [],
              ),
            },
          },
        },
      },
    });
  };
  const handleEntityChange = (
    _e: React.ChangeEvent<{}>,
    v: string | null,
    _reason: string,
    details?: AutocompleteChangeDetails<EntityRowFragment>,
  ) => {
    onUpdate({
      value: {
        stringSchema: {
          ...metadata,
          semanticType: {
            entityReference: {
              entity: {
                __typename: 'MetricsV1Entity',
                name: v ?? '',
                displayName: (details?.option.displayName || v) ?? '',
              },
            },
          },
        },
      },
    });
  };
  const level = (selectedEntry ?? '')?.split('.').length - 1;
  const invalidName = usedKeys.includes(key) && key !== '';
  const isPermanent = isPermanentProp && !usedKeys.includes(key);

  const entitiesRoute = useRouteRef(entitiesRouteRef);
  const handleCreateEntity = entitiesRoute
    ? (searchInput?: string) => {
        window.open(
          `${entitiesRoute()}?create=true${
            searchInput ? `&name=${searchInput}` : ''
          }`,
          '_blank',
        );
      }
    : undefined;

  return (
    <Editor>
      <Sticky>
        <Typography paragraph variant="h6">
          Edit field
        </Typography>
        <Divider />
        <FormFieldsBox spacing={2}>
          <TextField
            fullWidth
            disabled={isPermanent}
            value={key ?? ''}
            name="field-name"
            required
            margin="dense"
            variant="outlined"
            onChange={e =>
              onUpdate({
                key: e.target.value,
              })
            }
            error={invalidName}
            helperText={
              invalidName
                ? 'There is already another property with this name.'
                : ''
            }
            label="Field name"
          />
          <Collapse in={semanticType !== 'entityReference'} unmountOnExit>
            <TextField
              fullWidth
              value={displayName ?? ''}
              margin="dense"
              variant="outlined"
              onChange={e =>
                onUpdate({
                  value: {
                    ...value,
                    [type]: { ...value?.[type], displayName: e.target.value },
                  },
                })
              }
              label="Display name"
            />
          </Collapse>
          <TextField
            fullWidth
            value={description ?? ''}
            label="Description"
            multiline
            margin="dense"
            variant="outlined"
            onChange={e =>
              onUpdate({
                value: {
                  ...value,
                  [type]: { ...value?.[type], description: e.target.value },
                },
              })
            }
          />

          <TextField
            value={type ?? null}
            label="Type"
            required
            disabled={isPermanent}
            InputProps={{ readOnly: isPermanent }}
            select
            margin="dense"
            variant="outlined"
            fullWidth
            onChange={e =>
              onUpdate({
                value: getTypeSchema(
                  e.target.value as keyof EventsAdminV1EventSchema,
                  metadata,
                ),
              })
            }
          >
            {SCHEMA_TYPES.filter(f =>
              level <= MAXIMUM_DEPTH ? true : f !== 'structSchema',
            ).map(t => (
              <MenuItem key={t} value={t}>
                <TypeLabel type={t} />
              </MenuItem>
            ))}
          </TextField>

          <Collapse in={type === 'stringSchema'} unmountOnExit>
            <TextField
              value={semanticType ?? null}
              label="Semantic type"
              select
              fullWidth
              margin="dense"
              variant="outlined"
              onChange={e =>
                onUpdate({
                  value: getTypeSchema(
                    e.target.value as keyof EventsAdminV1EventSchema,
                    metadata,
                  ),
                })
              }
            >
              <MenuItem value="stringSchema">No semantic type</MenuItem>
              {SEMANTIC_STRING_TYPES.map(t => (
                <MenuItem key={t} value={t}>
                  <TypeLabel type={t} />
                </MenuItem>
              ))}
            </TextField>
          </Collapse>

          <Collapse in={semanticType === 'entityReference'} unmountOnExit>
            <ConfidenceAutocomplete<EntityRowFragment, false, true>
              query={useListEntitiesLazyQuery}
              label="Entity"
              value={entityValue?.name}
              onChange={handleEntityChange}
              onCreate={handleCreateEntity}
              required
              disableClearable
            />
          </Collapse>
          <Collapse in={semanticType === 'enumType'} unmountOnExit>
            <Autocomplete
              freeSolo
              multiple
              value={enumValue}
              onChange={handleEnumChange}
              ChipProps={{ size: 'small' }}
              options={[]}
              renderInput={params => (
                <TextField
                  {...params}
                  label="Enum values"
                  name="enum-values"
                  placeholder="Possible values"
                  required={enumValue.length === 0}
                />
              )}
            />
          </Collapse>

          <Collapse in={!!value?.listSchema} unmountOnExit>
            {value?.listSchema && (
              <TextField
                select
                required
                label="List type"
                disabled={isPermanent}
                fullWidth
                margin="dense"
                variant="outlined"
                value={
                  findSchemaType(value.listSchema.elementSchema).schemaType ||
                  ''
                }
                onChange={e => {
                  const listType = e.target
                    .value as keyof EventsAdminV1EventSchema;
                  onUpdate({
                    value: {
                      listSchema: {
                        elementSchema: getTypeSchema(listType, metadata),
                      },
                    },
                  });
                }}
                name="list-type-select"
              >
                {SCHEMA_TYPES.map(t => (
                  <MenuItem key={t} value={t}>
                    <TypeLabel type={t} />
                  </MenuItem>
                ))}
              </TextField>
            )}
          </Collapse>
        </FormFieldsBox>
        <Box marginY={3} textAlign="right">
          <Button onClick={onDelete} variant="outlined" size="small">
            Delete
          </Button>
        </Box>
      </Sticky>
    </Editor>
  );
};
