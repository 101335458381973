import React from 'react';

import { Box, Button, Checkbox, Menu, MenuItem } from '@material-ui/core';
import CompareIcon from '@material-ui/icons/Compare';
import StraightenIcon from '@material-ui/icons/Straighten';

import { Row } from '@tanstack/react-table';
import _ from 'lodash';

import { GroupTitle } from '../Visualizations/GroupTitle';
import { isRowSelected } from '../tableUtils';
import { GroupableColumn, ResultData } from '../types';

function getGroupRows(
  row: Row<ResultData>,
  groupId: string,
): Row<ResultData>[] {
  if (row.groupingColumnId === groupId) {
    return [row];
  }
  return row.subRows.flatMap(subRow => getGroupRows(subRow, groupId));
}

export const ToggleGroupMenu = ({
  rows,
  groupId,
}: {
  rows: Row<ResultData>[];
  groupId: GroupableColumn;
}) => {
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);

  const groupRows = React.useMemo(
    () => rows.flatMap(row => getGroupRows(row, groupId)),
    [rows, groupId],
  );

  const groupOptions = _.uniq(groupRows.map(r => r.groupingValue as string));
  const value = _.uniq(
    groupRows.filter(isRowSelected).map(r => r.groupingValue as string),
  );

  const isAllActive = groupRows.every(isRowSelected);
  const isSomeActive = groupRows.some(isRowSelected);

  const isGroupActive = (group: string) =>
    groupRows.some(row => row.groupingValue === group && isRowSelected(row));

  const toggleAllRows = () => {
    groupRows.forEach(row => {
      if (isSomeActive) {
        if (isRowSelected(row)) {
          row.toggleSelected();
        }
      } else {
        row.toggleSelected();
      }
    });
  };

  const toggleGroupRows = (group: string) => () => {
    groupRows.forEach(row => {
      if (row.groupingValue === group) {
        if (isGroupActive(group)) {
          if (isRowSelected(row)) {
            row.toggleSelected();
          }
        } else {
          if (!isRowSelected(row)) {
            row.toggleSelected();
          }
        }
      }
    });
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  if (groupOptions.length === 0) {
    return null;
  }

  const IconComponent = groupId === 'comparison' ? CompareIcon : StraightenIcon;
  return (
    <Box>
      <Button
        variant="outlined"
        size="small"
        name={`toggle-group-${groupId}-button`}
        startIcon={<IconComponent fontSize="small" />}
        onClick={(e: React.MouseEvent<HTMLButtonElement>) =>
          setAnchorEl(e.currentTarget)
        }
      >
        {value.length === groupOptions.length
          ? `All ${groupId}s`
          : `${value.length} ${groupId}${value.length === 1 ? '' : 's'}`}
      </Button>
      <Menu
        open={Boolean(anchorEl)}
        anchorEl={anchorEl}
        onClose={handleClose}
        data-testid={`toggle-group-${groupId}-menu`}
      >
        <MenuItem onClick={toggleAllRows} divider>
          <Checkbox
            size="small"
            checked={isAllActive}
            indeterminate={!isAllActive && isSomeActive}
          />
          Show all {groupId}s
        </MenuItem>
        {groupOptions.map(groupingValue => (
          <MenuItem
            key={groupingValue}
            value={groupingValue}
            onClick={toggleGroupRows(groupingValue)}
          >
            <Checkbox size="small" checked={isGroupActive(groupingValue)} />
            <GroupTitle groupId={groupId} groupValue={groupingValue} />
          </MenuItem>
        ))}
      </Menu>
    </Box>
  );
};
