import React from 'react';

import { TextField } from '@material-ui/core';

import { FormFieldsBox, useAuth } from '@spotify-confidence/core-react';
import { ConnectorsV1BigQueryConfigInput } from '@spotify-confidence/plugin-graphql';

import { getBigQueryConfigValidationErrors } from './helpers';

type BigQueryConfigInput = Omit<
  ConnectorsV1BigQueryConfigInput,
  'proto_type_url'
>;

type BigQueryConfigFormProps = {
  value: BigQueryConfigInput;
  onChange: (conf: BigQueryConfigInput) => void;
  disabled?: boolean;
};

export const BigQueryConfigForm = ({
  value,
  onChange,
  disabled,
}: BigQueryConfigFormProps) => {
  const { user: { org_name } = {} } = useAuth();
  const project: string = value?.project ?? '';
  const dataset: string = value?.dataset ?? '';
  const serviceAccount: string = value?.serviceAccount ?? '';

  const validationErrors = getBigQueryConfigValidationErrors(value);

  const handleConfigChange =
    (field: keyof ConnectorsV1BigQueryConfigInput) =>
    (e: React.ChangeEvent<HTMLInputElement>) => {
      onChange({
        ...value,
        [field]: e.target.value,
      });
    };

  return (
    <>
      <FormFieldsBox>
        <TextField
          disabled={disabled}
          label="GCP Project ID"
          variant="outlined"
          value={project}
          required
          id="project"
          fullWidth
          helperText="The ID of the GCP project where you want to export the data."
          onChange={handleConfigChange('project')}
        />
        <TextField
          disabled={disabled}
          label="Service Account"
          variant="outlined"
          value={serviceAccount}
          id="serviceAccount"
          required
          fullWidth
          error={validationErrors.serviceAccount?.length !== 0}
          helperText={
            validationErrors.serviceAccount?.join(', ') || (
              <>
                A GCP service account that has write access to the destination
                table and has granted{' '}
                <strong>
                  {`account-${org_name}@spotify-confidence.iam.gserviceaccount.com`}{' '}
                </strong>{' '}
                "Workload Identity User" role.
              </>
            )
          }
          onChange={handleConfigChange('serviceAccount')}
        />
        <TextField
          disabled={disabled}
          label="Dataset"
          id="dataset"
          variant="outlined"
          value={dataset}
          required
          fullWidth
          helperText="The dataset in which to create the destination table."
          onChange={handleConfigChange('dataset')}
        />
      </FormFieldsBox>
    </>
  );
};
