import { createRouteRef, createSubRouteRef } from '@backstage/core-plugin-api';

// internal routes
export const rootRouteRef = createRouteRef({ id: 'surfaces-root' });

export const surfaceIndexRouteRef = rootRouteRef;

export const surfaceRouteRef = createSubRouteRef({
  id: 'surface',
  parent: rootRouteRef,
  path: '/:id',
});

export const surfaceSettingsRouteRef = createSubRouteRef({
  id: 'surface',
  parent: rootRouteRef,
  path: '/:id/settings',
});
