import React from 'react';

import { darken, lighten, useTheme } from '@material-ui/core';

import {
  CodeSnippet as BackstageCodeSnippet,
  CodeSnippetProps,
} from '@backstage/core-components';

export function CodeSnippet(props: CodeSnippetProps) {
  const { shape, spacing, palette } = useTheme();

  return (
    <BackstageCodeSnippet
      {...props}
      customStyle={{
        padding: spacing(3),
        borderRadius: shape.borderRadius,
        backgroundColor:
          palette.type === 'dark'
            ? lighten(palette.background.paper, 0.05)
            : darken(palette.background.paper, 0.05),
      }}
    />
  );
}
