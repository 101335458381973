import React from 'react';

import { Box, Tooltip } from '@material-ui/core';

import { EventsAdminV1FieldSemanticType } from '@spotify-confidence/plugin-graphql';

import { schemaTypeDisplayName } from '../../domain/schema.helpers';
import { FieldIcon } from '../FieldIcon';
import { Option } from './AddFieldButton';

// TODO: support showing the list type (for example: list of strings)
export const TypeLabel = ({
  type,
  semanticType,
  onlyIcon = false,
}: {
  type: Option;
  onlyIcon?: boolean;
  semanticType?: keyof EventsAdminV1FieldSemanticType;
}) => {
  const label = (
    <>
      {schemaTypeDisplayName(type)}
      {semanticType && ` (${schemaTypeDisplayName(semanticType)})`}
    </>
  );

  return (
    <Box display="inline-flex" alignItems="center" gridGap={8}>
      <Tooltip title={onlyIcon ? label : ''} arrow>
        <Box display="inline-flex" alignItems="center" component="span">
          <FieldIcon type={type} fontSize="small" color="disabled" />
        </Box>
      </Tooltip>
      {!onlyIcon && label}
    </Box>
  );
};
