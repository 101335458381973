import React from 'react';

import { Button, Collapse, List } from '@material-ui/core';
import Add from '@material-ui/icons/Add';

import { EventSchemaEntryFragment } from '@spotify-confidence/plugin-graphql';

import { appendId, getNestedSchema } from '../../../domain/schema.helpers';
import { Entry, useSchema } from '../../../domain/schema.hooks';
import { useSchemaContext } from '../SchemaContext';
import { SchemaEntry } from '../SchemaEntry';

export type SchemaState = ReturnType<typeof useSchema>;

export type SharedSchemaProps = {
  path: string[];
  identifier: string;
  disabled?: boolean;
  isExisting?: (path: string[]) => boolean;
  addToParent?: (entry: Entry) => void;
  entry: Entry;
  hasNestedField?: boolean;
};

type DraftEventEntriesProps = {
  schema: EventSchemaEntryFragment[];
} & SharedSchemaProps;

function DraftEventEntries({
  schema,
  identifier = '0',
  entry: structEntry,
  hasNestedField: hasNestedFieldProp,
  ...rest
}: DraftEventEntriesProps) {
  const hasNestedField =
    hasNestedFieldProp || schema.some(s => getNestedSchema(s.value));

  return (
    <List>
      {schema.map((entry, i) => (
        <DraftEventEntry
          key={`entry-${i}-${identifier}`}
          {...rest}
          entry={entry}
          identifier={appendId(identifier, i)}
          hasNestedField={hasNestedField}
        />
      ))}
    </List>
  );
}

type DraftEventEntryProps = {
  error?: string | null;
} & SharedSchemaProps;

export function DraftEventEntry({
  entry,
  identifier,
  path,
  disabled: disabledProp,
  error = null,
  isExisting,
  addToParent,
  hasNestedField,
  ...rest
}: DraftEventEntryProps) {
  const { onAdd } = useSchemaContext();
  const { key, value } = entry;
  const [expanded, setExpanded] = React.useState(true);
  const nestedSchema = getNestedSchema(value);
  const currentPath = [...path, key];
  const canExpand = !!nestedSchema;
  const exists = isExisting?.(currentPath);
  const disabled = disabledProp || exists;

  const parentIdentifier = identifier.split('.').slice(0, -1).join('.');

  // Add this entry to the schema
  const handleAdd = () => {
    if (addToParent) {
      addToParent?.(entry);
    } else {
      onAdd(parentIdentifier, entry);
    }
  };

  // Add a child entry to this entry
  const addToEntry = (childEntry: Entry) => {
    if (!nestedSchema) {
      return;
    }

    // Add this entry's parent to the schema if necessary
    if (!isExisting?.(path) && addToParent) {
      addToParent(entry);
    }

    if (!exists) {
      // If this entry does not already exist in the schema
      const newSchema = nestedSchema.schema.filter(
        e => e.key === childEntry.key,
      );
      const newValue = {
        key,
        value: {
          ...value,
        },
      };
      // Filter out sibling entries to add only clicked entry
      if (value?.structSchema) {
        newValue.value.structSchema = {
          ...value.structSchema,
          schema: newSchema ?? [],
        };
      } else if (value?.listSchema?.elementSchema?.structSchema) {
        newValue.value.listSchema = {
          ...value.listSchema,
          elementSchema: {
            ...value.listSchema.elementSchema,
            structSchema: {
              ...value.listSchema.elementSchema.structSchema,
              schema: newSchema ?? [],
            },
          },
        };
      }
      onAdd(parentIdentifier, newValue);
    } else {
      // If this entry exists in the schema, add the child entry to it
      onAdd(identifier, childEntry);
    }
  };

  return (
    <>
      <SchemaEntry
        dense
        button
        disabled={disabled}
        level={path.length}
        divider
        onClick={() => {
          if (canExpand && !expanded) {
            setExpanded(true);
          }
        }}
      >
        {hasNestedField && (
          <SchemaEntry.Expand
            expanded={expanded}
            onChange={setExpanded}
            canExpand={canExpand}
          />
        )}
        <SchemaEntry.Details entry={entry} />
        <SchemaEntry.Actions>
          {!disabled && (
            <Button size="small" startIcon={<Add />} onClick={handleAdd}>
              Add field
            </Button>
          )}
          <SchemaEntry.Error error={error} />
        </SchemaEntry.Actions>
      </SchemaEntry>
      {nestedSchema && (
        <Collapse in={expanded}>
          <DraftEventEntries
            {...rest}
            entry={entry}
            path={currentPath}
            identifier={identifier}
            schema={nestedSchema.schema}
            disabled={disabledProp}
            isExisting={isExisting}
            addToParent={addToEntry}
            hasNestedField={hasNestedField}
          />
        </Collapse>
      )}
    </>
  );
}
