import * as React from 'react';

import { Box } from '@material-ui/core';

type SpacerSize = 'xs' | 's' | 'm' | 'l' | 'xl' | 'xxl';

const SIZE = {
  xs: 4,
  s: 8,
  m: 16,
  l: 24,
  xl: 32,
  xxl: 64,
};

export const Spacer: React.FC<{
  vertical?: boolean;
  size?: SpacerSize;
}> = ({ vertical = false, size = 's' }) => {
  const _size = SIZE[size];

  return vertical ? (
    <Box display="inline-block" width={_size} height="100%" />
  ) : (
    <Box display="block" width="100%" height={_size} />
  );
};
