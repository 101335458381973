import React from 'react';

import { DialogActions } from '@material-ui/core';
import { Alert } from '@material-ui/lab';

import {
  DialogBody,
  DialogForm,
  DialogHeader,
  FormSubmitButtons,
  useDialog,
} from '@spotify-confidence/core-react';
import {
  EvaluationContextFieldOverrideFragment,
  FlagsAdminV1EvaluationContextFieldOverrideInput as OverrideInput,
  getError,
  getTypeOrNull,
  useUpdateEvaluationContextFieldOverrideMutation,
} from '@spotify-confidence/plugin-graphql';

import { SchemaOverrideForm } from '../SchemaOverrideForm';
import { overrideRefetchQueries } from '../constants';
import { SchemaItem } from '../types';

export type EditSchemaOverrideDialogProps = {
  override: EvaluationContextFieldOverrideFragment;
  existingOverrides?: string[];
};

const convertOverrideToInput = (override: SchemaItem): OverrideInput => {
  let entityName = '';

  // TODO: how should we handle permission errors?
  const entity = getTypeOrNull(
    override.semanticType?.entityReference?.entity,
    'MetricsV1Entity',
  );
  if (entity) {
    entityName = entity.name;
  }
  return {
    field: override.field,
    displayName: override.displayName,
    kind: override.kind,
    semanticType: override.semanticType
      ? {
          ...override.semanticType,
          date: override.semanticType.date ? {} : undefined,
          timestamp: override.semanticType.timestamp ? {} : undefined,
          version: override.semanticType.version ? {} : undefined,
          entityReference: override.semanticType.entityReference
            ? {
                entity: entityName,
              }
            : undefined,
        }
      : undefined,
  };
};

export const EditSchemaOverrideDialog = ({
  override: original,
  existingOverrides,
}: EditSchemaOverrideDialogProps) => {
  const { closeDialog } = useDialog();
  const [override, setOverride] = React.useState<OverrideInput>(
    convertOverrideToInput(original),
  );
  const [update, updateResult] =
    useUpdateEvaluationContextFieldOverrideMutation({
      refetchQueries: overrideRefetchQueries,
    });
  const otherExistingOverrides = existingOverrides?.filter(
    f => f !== original.field,
  );

  const handleUpdateOverride = React.useCallback(
    (e: React.FormEvent<HTMLFormElement>) => {
      e.preventDefault();
      update({
        variables: {
          evaluationContextFieldOverride: { name: original.name, ...override },
          updateMask: 'displayName,field,kind,semanticType',
        },
      });
    },
    [override],
  );

  const error =
    updateResult.error ||
    getError(updateResult.data?.updateEvaluationContextFieldOverride);

  React.useEffect(() => {
    if (updateResult.data && !error) {
      closeDialog();
    }
  }, [updateResult.data, error]);

  return (
    <DialogForm onSubmit={handleUpdateOverride}>
      <DialogHeader title="Edit schema override" />
      <DialogBody>
        {error && <Alert severity="error">{error.message}</Alert>}
        <SchemaOverrideForm
          value={override}
          onChange={setOverride}
          existingOverrides={otherExistingOverrides}
        />
      </DialogBody>
      <DialogActions>
        <FormSubmitButtons
          onCancel={closeDialog}
          loading={updateResult.loading}
          label="Save"
        />
      </DialogActions>
    </DialogForm>
  );
};
