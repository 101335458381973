import React from 'react';

import { IconButton, Menu, MenuItem } from '@material-ui/core';
import MoreVert from '@material-ui/icons/MoreVert';

type SchemaItemActionMenuProps = {
  id?: string;
  size: 'small' | 'medium';
  disabled?: boolean;
  actions: { label: string; onClick: () => void }[];
};

export const SchemaItemActionMenu = ({
  id,
  size,
  disabled,
  actions,
}: SchemaItemActionMenuProps) => {
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);

  const handleOpen = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <div>
      <IconButton
        aria-label="more"
        aria-controls={id}
        aria-haspopup="true"
        onClick={handleOpen}
        size={size}
        disabled={disabled}
      >
        <MoreVert fontSize={size} />
      </IconButton>
      <Menu id={id} anchorEl={anchorEl} open={open} onClose={handleClose}>
        {actions.map(action => (
          <MenuItem
            key={action.label}
            onClick={() => {
              action.onClick();
              handleClose();
            }}
            disabled={disabled}
          >
            {action.label}
          </MenuItem>
        ))}
      </Menu>
    </div>
  );
};
