import { RuleType } from '../../../domain/rule.model';
import { DefaultRuleForm } from './default';
import { OverrideRuleForm } from './override';
import { RolloutRuleForm } from './rollout';

export const getRuleFormComponent = (ruleType = RuleType.COMPACT) => {
  switch (ruleType) {
    case RuleType.OVERRIDE:
      return OverrideRuleForm;
    case RuleType.ROLLOUT:
      return RolloutRuleForm;
    default:
      return DefaultRuleForm;
  }
};
