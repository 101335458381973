import React from 'react';

import {
  Box,
  Checkbox,
  FormControlLabel,
  Typography,
  styled,
} from '@material-ui/core';

import {
  AdvancedOptions,
  CardSection,
  CardSectionWrapper,
} from '@spotify-confidence/core-react';
import {
  CommentZone,
  CommentZoneContext,
} from '@spotify-confidence/plugin-comments-react';
import { SegmentFragment } from '@spotify-confidence/plugin-graphql';

import {
  AllocationInput,
  ExclusionCriteria,
  ExclusivityTags,
  TargetingContainer,
} from '../../../../segment/components';
import { segmentIsExclusive } from '../../../../segment/domain/segment.model';
import { RandomizationInput } from './RandomizationInput';

const RuleContainerWrapper = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  gridGap: theme.spacing(2),
}));

type RuleContainerProps = {
  readOnly?: boolean;
  segment: SegmentFragment;
  targetingKeySelector?: string | null;
  stickyAssignments?: boolean;
  handleSegmentChange?: (segment: SegmentFragment) => void;
  handleTargetingKeySelectorChange?: (key: string) => void;
  handleStickyAssignmentsChange?: (sticky: boolean) => void;
  stickyAssignmentsDescription: string;
  randomizationPlaceholder?: string;
  // TODO: probably a nicer way to compose components so we can avoid passing down this prop
  exclusivityTagSuggestions?: string[];
  hideAllocationProportion?: boolean;
};

export const RuleContainer = ({
  readOnly = false,
  segment,
  targetingKeySelector,
  handleSegmentChange,
  handleTargetingKeySelectorChange,
  exclusivityTagSuggestions,
  hideAllocationProportion = false,
  stickyAssignmentsDescription,
  stickyAssignments,
  handleStickyAssignmentsChange,
  randomizationPlaceholder,
}: RuleContainerProps) => {
  const hasCoordination = segmentIsExclusive(segment);

  return (
    <RuleContainerWrapper>
      <Box>
        <CommentZoneContext zone="inclusion-criteria">
          <Typography variant="h6">
            <CommentZone id="title" component="span">
              Inclusion criteria
            </CommentZone>
          </Typography>

          <TargetingContainer
            title="Determines who is targeted for inclusion."
            targeting={segment.targeting}
            readOnly={readOnly}
            onChange={targeting =>
              handleSegmentChange?.({ ...segment, targeting })
            }
          />
        </CommentZoneContext>
      </Box>
      <Box marginTop={1}>
        <CardSectionWrapper inlineContainer>
          <CardSection>
            <RandomizationInput
              value={targetingKeySelector}
              readOnly={readOnly}
              onChange={handleTargetingKeySelectorChange}
              placeholder={randomizationPlaceholder}
              data-testid="randomization-field"
              required
              fullWidth
            />
          </CardSection>
          {!hideAllocationProportion && (
            <CardSection>
              <AllocationInput
                segment={segment}
                onAllocationChange={allocation =>
                  handleSegmentChange?.({ ...segment, allocation })
                }
                readOnly={readOnly}
              />
            </CardSection>
          )}
        </CardSectionWrapper>
      </Box>
      <Box marginTop={1}>
        <AdvancedOptions enabled={hasCoordination || stickyAssignments}>
          <Box marginTop={2} marginBottom={4}>
            <CommentZone id="sticky-assignments" component="span">
              <FormControlLabel
                disabled={readOnly}
                control={
                  <Checkbox
                    checked={stickyAssignments || false}
                    name="sticky-assignments-checkbox"
                    onChange={(
                      _e: React.ChangeEvent<HTMLInputElement>,
                      checked: boolean,
                    ) => handleStickyAssignmentsChange?.(checked)}
                  />
                }
                label={<Typography variant="h6">Sticky assignments</Typography>}
              />
            </CommentZone>
            <Box marginBottom={2}>
              <Typography variant="body1" color="textSecondary">
                {stickyAssignmentsDescription}
              </Typography>
            </Box>
          </Box>
          <CardSectionWrapper>
            <CardSection>
              <ExclusionCriteria
                readOnly={readOnly}
                allocation={segment.allocation}
                onAllocationChange={allocation =>
                  handleSegmentChange?.({ ...segment, allocation })
                }
              />
            </CardSection>
            <CardSection>
              <ExclusivityTags
                readOnly={readOnly}
                allocation={segment.allocation}
                exclusivityTagSuggestions={exclusivityTagSuggestions}
                onAllocationChange={allocation =>
                  handleSegmentChange?.({ ...segment, allocation })
                }
              />
            </CardSection>
          </CardSectionWrapper>
        </AdvancedOptions>
      </Box>
    </RuleContainerWrapper>
  );
};
