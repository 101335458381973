import React from 'react';

import {
  extractWorkflowFromInstanceName,
  useAlert,
} from '@spotify-confidence/core-react';
import {
  getError,
  getTypeOrNull,
  useTransitionWorkflowInstanceMutation,
} from '@spotify-confidence/plugin-graphql';
import _ from 'lodash';

import { pendingTransitionUtils } from '../components/WorkflowInstanceState';

type UseTransitionWorkflowInstanceArgs = {
  workflowInstanceName?: string;
  onTransitionCompleted?: (state: string) => void;
};

export const useTransitionWorkflowInstance = ({
  workflowInstanceName,
  onTransitionCompleted,
}: UseTransitionWorkflowInstanceArgs) => {
  const alert = useAlert();

  const [transition, mutationData] = useTransitionWorkflowInstanceMutation({
    update: cache => {
      cache.evict({
        id: cache.identify({
          __typename: 'WorkflowV1WorkflowInstance',
          name: workflowInstanceName,
        }),
      });
      cache.gc();
    },
    onCompleted: ({ transitionWorkflowInstance }) => {
      const response = getTypeOrNull(
        transitionWorkflowInstance,
        'WorkflowV1TransitionWorkflowInstanceResponse',
      );
      const transitionError = getError(transitionWorkflowInstance);
      if (response) {
        const { state, pendingTransition } = response?.workflowInstance || {};
        if (pendingTransition) {
          if (pendingTransitionUtils.hasFailingSteps(pendingTransition)) {
            alert.post({
              message: `${pendingTransition.failedSteps.length} step${
                pendingTransition.failedSteps.length === 1 ? '' : 's'
              } failed when trying to ${
                pendingTransition.transitionAction
              }. Find details and retry in the Status section`,
              severity: 'error',
            });
          } else {
            alert.post({
              message: `${_.upperFirst(
                pendingTransition.transitionAction,
              )} in progress`,
              severity: 'info',
            });
          }
        } else {
          alert.post({
            message: `The ${extractWorkflowFromInstanceName(
              workflowInstanceName,
            )} is now ${_.toLower(state)}`,
            severity: 'success',
            display: 'transient',
          });
          onTransitionCompleted?.(state!);
        }
      } else if (transitionError) {
        alert.post({
          message: transitionError.message,
          severity: 'error',
        });
      }
    },
    onError: error => {
      alert.post({
        message: error.message,
        severity: 'error',
      });
    },
  });

  const performTransition = React.useCallback(
    async (targetState?: string | null, parameters?: any) => {
      if (workflowInstanceName && targetState) {
        try {
          await transition({
            variables: {
              name: workflowInstanceName,
              action: targetState,
              parameters,
            },
          });
        } catch (error) {
          // eslint-disable-next-line no-console
          console.error(error);
        }
      }
    },
    [transition, workflowInstanceName],
  );

  return { ...mutationData, performTransition };
};
