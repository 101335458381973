import React from 'react';

import { Box } from '@material-ui/core';

import {
  SidebarValue,
  SidebarValueWrapper,
} from '@spotify-confidence/core-react';
import { MetricsV1AggregationType } from '@spotify-confidence/plugin-graphql';

import { ConsumptionMetricInputsForm } from '../ConsumptionMetricInputsForm';
import { MetricFilterPreview } from '../MetricFilters/MetricFilterPreview';
import { metricFilterHasEmptyAttributes } from '../MetricFilters/useMetricFilters';
import { MetricSection } from './types';

const consumptionMetricInputsSection: MetricSection = {
  name: 'Inputs',
  description:
    'Select the entity you want to measure consumption for and the fact table that tracks the relevant events.',
  canBeEditedSafely: false,
  isValid: metric =>
    Boolean(
      !metricFilterHasEmptyAttributes(metric.filter) &&
        metric.factTable &&
        metric.entity &&
        (metric.typeSpec?.averageMetricSpec?.aggregation.type !==
        MetricsV1AggregationType.AggregationTypeCount
          ? !!metric.typeSpec?.averageMetricSpec?.measurement?.name
          : true),
    ),
  form: (value, onChange) => (
    <ConsumptionMetricInputsForm value={value} onChange={onChange} />
  ),
  summary: (metric, renderValues) => (
    <SidebarValueWrapper>
      <SidebarValue
        name="Fact table"
        value={renderValues?.[metric.factTable]?.() || metric.factTable}
      />
      <Box display="grid" gridTemplateColumns="1fr 1fr">
        <SidebarValue
          name="Entity"
          value={renderValues?.[metric.entity]?.() || metric.entity}
        />
        {metric.typeSpec?.averageMetricSpec?.measurement?.name && (
          <SidebarValue
            name="Consumption value"
            value={metric.typeSpec.averageMetricSpec.measurement.name}
          />
        )}
      </Box>
      {metric.filter && (
        <SidebarValue
          name="Filter"
          value={<MetricFilterPreview filter={metric.filter} />}
        />
      )}
    </SidebarValueWrapper>
  ),
  updateMask: 'entity,factTable,typeSpec.averageMetricSpec,filter',
};

// Used in the create form
export const createConsumptionMetricSections: MetricSection[] = [
  consumptionMetricInputsSection,
];

// Used in the create summary + metric page sidebar
export const editConsumptionMetricSections: MetricSection[] = [
  consumptionMetricInputsSection,
];
