import React from 'react';

import { TableCell } from '@material-ui/core';

import { DateUtils, MediumScreenCell } from '@spotify-confidence/core-react';
import {
  WorkflowInstanceListItem,
  WorkflowInstanceNameCell,
  WorkflowInstanceStateCell,
  WorkflowInstanceTableRow,
} from '@spotify-confidence/plugin-workflows';

export const DefaultWorkflowTableRow = ({
  workflowInstance,
}: {
  workflowInstance: WorkflowInstanceListItem;
}) => {
  return (
    <WorkflowInstanceTableRow workflowInstance={workflowInstance}>
      <WorkflowInstanceNameCell workflowInstance={workflowInstance} />
      <WorkflowInstanceStateCell workflowInstance={workflowInstance} />
      <TableCell colSpan={2}>(no progress)</TableCell>
      <TableCell />
      <MediumScreenCell>
        {DateUtils.xAgo(new Date(workflowInstance?.updateTime))}
      </MediumScreenCell>
    </WorkflowInstanceTableRow>
  );
};
