import React from 'react';

import { Fade, FadeProps } from '@material-ui/core';
import { duration } from '@material-ui/core/styles/transitions';

type FadeInProps = FadeProps & {
  visible?: boolean;
  timeout?: number;
};

export const FadeIn = React.forwardRef(
  (
    {
      visible = true,
      timeout = duration.shorter,
      children,
      ...fadeProps
    }: FadeInProps,
    ref,
  ) => (
    <Fade
      ref={ref}
      unmountOnExit
      mountOnEnter
      {...fadeProps}
      in={visible}
      timeout={timeout}
      exit={false}
    >
      {children}
    </Fade>
  ),
);
