import React from 'react';

import { Box } from '@material-ui/core';

import {
  ConfidenceWebsiteLink,
  SidebarValue,
  timeWindowUtils,
} from '@spotify-confidence/core-react';
import _ from 'lodash';

import { TimeWindowFormV1 } from '../../../TimeWindowForm';
import { parseExposureOffset } from '../../../TimeWindowForm/utils';
import { MetricSection } from '../../types';

export const timeWindowSection: MetricSection = {
  name: 'Time window',
  description: (
    <>
      Set a starting point and duration time for evaluating the data in relation
      to the first exposure.{' '}
      <ConfidenceWebsiteLink
        route="/docs/metrics#time-windows"
        underline="always"
      >
        Learn more.
      </ConfidenceWebsiteLink>
    </>
  ),
  canBeEditedSafely: false,
  isValid: metric => metric.aggregationWindow !== '0s',
  form: (value, onChange) => (
    <TimeWindowFormV1 value={value} onChange={onChange} entity={value.entity} />
  ),
  summary: metric => (
    <Box display="grid" gridTemplateColumns="1fr 1fr">
      <SidebarValue
        name="Starting point"
        value={_.upperFirst(
          parseExposureOffset(
            metric.exposureOffset ??
              metric.measurementConfig?.closedWindow?.exposureOffset ??
              '0s',
          ),
        )}
      />
      <SidebarValue
        name="Duration"
        value={timeWindowUtils.parseWindow(
          metric.aggregationWindow ??
            metric.measurementConfig?.closedWindow?.aggregationWindow ??
            '0s',
        )}
      />
    </Box>
  ),
  updateMask: 'measurementConfig',
};
