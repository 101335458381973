import React, { useEffect } from 'react';
import { InView } from 'react-intersection-observer';

import {
  FilterListEmptyState,
  FilterListEmptyStateProps,
  useDelayedLoading,
  useListFilter,
} from '@spotify-confidence/core-react';
import {
  WorkflowInstanceListItemFragment,
  getError,
  getTypeOrNull,
  useListWorkflowInstancesQuery,
} from '@spotify-confidence/plugin-graphql';

import { getWorkflowName } from './utils';

export type WorkflowInstanceListItem = WorkflowInstanceListItemFragment;

export type IntermediateRenderComponentProps = {
  errorText?: string;
  emptyStateProps?: Partial<FilterListEmptyStateProps>;
  showLoading?: boolean;
};

const EMPTY_STATE_COMPONENT = ({
  emptyStateProps,
  showLoading,
}: IntermediateRenderComponentProps) => (
  <FilterListEmptyState
    loading={showLoading}
    type="instance"
    description="Create an instance for it to show up in this list."
    {...emptyStateProps}
  />
);

const getIntermediateRenderComponent = (
  error: string | null,
  workflowInstances: WorkflowInstanceListItem[],
  isFirstLoad: boolean,
) => {
  if (error) {
    return ({ errorText }: IntermediateRenderComponentProps) => (
      <FilterListEmptyState error={errorText || error} type="instance" />
    );
  }

  if (workflowInstances.length === 0 && !isFirstLoad) {
    return EMPTY_STATE_COMPONENT;
  }

  return null;
};

export const useListWorkflowInstances = ({
  pageSize = 25,
  workflowId,
  filter: inputFilter,
}: {
  pageSize?: number;
  workflowId?: string;
  filter?: string;
}) => {
  const { isDefaultFilterState, filterString } = useListFilter();

  const filter = [inputFilter, filterString].filter(Boolean).join(' AND ');
  const listWorkflowInstancesQueryVariables = React.useMemo(
    () => ({
      pageSize,
      workflow: workflowId ? getWorkflowName(workflowId) : undefined,
      filter,
    }),
    [pageSize, workflowId, filter],
  );
  const queryResult = useListWorkflowInstancesQuery({
    variables: listWorkflowInstancesQueryVariables,
    errorPolicy: 'all',
  });

  const { data, fetchMore, loading, error } = queryResult;

  const errorMessage = getError(data?.workflowInstances)?.message;
  const instancesResponse = getTypeOrNull(
    data?.workflowInstances,
    'WorkflowV1ListWorkflowInstancesResponse',
  );

  const [workflowInstances, setWorkflowInstances] = React.useState<
    WorkflowInstanceListItem[]
  >([]);

  const [isFirstLoad, setIsFirstLoad] = React.useState(true);

  useEffect(() => {
    if (instancesResponse?.workflowInstances) {
      setWorkflowInstances(instancesResponse.workflowInstances);
      setIsFirstLoad(false);
    }
  }, [instancesResponse]);

  const InfiniteScroll = () =>
    instancesResponse ? (
      <InView
        onChange={async inView => {
          const nextPageToken = instancesResponse.nextPageToken;
          if (inView && nextPageToken) {
            await fetchMore({
              variables: {
                pageToken: nextPageToken,
              },
            });
          }
        }}
      />
    ) : null;

  const { showLoading } = useDelayedLoading(loading);
  const IntermediateRenderComponent = getIntermediateRenderComponent(
    errorMessage ?? error?.message ?? null,
    workflowInstances,
    isFirstLoad,
  );

  return {
    nextPageToken: instancesResponse?.nextPageToken,
    showLoading,

    isDefaultFilterState,
    workflowInstances,

    IntermediateRenderComponent,
    InfiniteScroll,
  };
};
