import React from 'react';

import { Box, Button, Collapse } from '@material-ui/core';
import { Alert } from '@material-ui/lab';

export type TargetingChangedWarningProps = {
  open?: boolean;
  onDiscard?: () => void;
  onOverwrite?: () => void;
};

export const TargetingChangedWarning = ({
  open,
  onDiscard,
  onOverwrite,
}: TargetingChangedWarningProps) => {
  return (
    <Collapse in={open} unmountOnExit>
      <Box mb={1}>
        <Alert severity="warning">
          The targeting was changed by someone else while you were editing.
          <Box display="flex" gridGap={8} marginTop={2}>
            {onDiscard && (
              <Button variant="outlined" size="small" onClick={onDiscard}>
                Discard your changes
              </Button>
            )}
            {onOverwrite && (
              <Button variant="outlined" size="small" onClick={onOverwrite}>
                Save and ignore their changes
              </Button>
            )}
          </Box>
        </Alert>
      </Box>
    </Collapse>
  );
};
