import { ConnectorsV1BatchFileConfigInput } from '@spotify-confidence/plugin-graphql';

const MB_TO_BYTES_CONVERSION_FACTOR = 1024 * 1024;

export function bytesToMb(bytes: number): number {
  return bytes / MB_TO_BYTES_CONVERSION_FACTOR;
}

export function megabytesToBytes(mb: number): number {
  return mb * MB_TO_BYTES_CONVERSION_FACTOR;
}

export const defaultBatchConfig: ConnectorsV1BatchFileConfigInput = {
  maxEventsPerFile: 50000,
  maxFileAge: '600s',
  maxFileSize: 50 * MB_TO_BYTES_CONVERSION_FACTOR,
};
