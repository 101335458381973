import React from 'react';
import { useLocation, useNavigate } from 'react-router-dom';

import { useLocalStorage } from '../../utils/useLocalStorage';
import {
  QueryBuilderValue,
  QueryRuleDefinition,
  QueryRuleValue,
} from './filterBuilder.types';
import {
  getDefaultFilters,
  getFilterString,
  isDefaultFilters,
} from './helpers';
import { FilterDefinition, FilterState } from './types';

type FilterListProviderType = {
  state: FilterState;
  config: FilterDefinition;
  setSearchQuery: (v: string) => void;
  setFilterState: (v: Partial<FilterState>) => void;
};

const FilterListContext = React.createContext<FilterListProviderType>({
  config: {
    filters: [],
    searchField: 'name',
  },
  state: {
    filters: [],
    searchQuery: '',
  },
  setSearchQuery: () => {},
  setFilterState: () => {},
});

export type ActiveFilter = {
  definition?: QueryRuleDefinition;
  value: QueryRuleValue<any>;
};

const getParams = (state: FilterState) => {
  const u = new URLSearchParams();
  u.set('searchQuery', state.searchQuery);
  state.filters.forEach(v => {
    if (v.value) {
      u.set(v.id, v.value.toString());
    }
  });
  return u.toString();
};

export const FilterListProvider = ({
  filters = [],
  searchField = 'name',
  children,
  storageKey,
}: React.PropsWithChildren<{
  filters?: FilterDefinition['filters'];
  searchField?: FilterDefinition['searchField'];
  storageKey: string;
}>) => {
  const navigate = useNavigate();

  const defaultFilters: QueryRuleValue[] = getDefaultFilters(filters);
  const location = useLocation();
  const [searchQuery, setSearchQuery] = React.useState('');
  const [filterState, setFilterState] = useLocalStorage<
    Omit<FilterState, 'searchQuery'>
  >(storageKey, {
    filters: defaultFilters,
  });

  const setUrl = (state: FilterState) => {
    const currentParams = new URLSearchParams(location.search);
    if (isDefaultFilters(state, filters) && currentParams.size === 0) {
      return;
    }
    const newFilterParams = getParams(state);
    navigate(`${location.pathname}?${newFilterParams.toString()}`, {
      replace: true,
    });
  };

  React.useEffect(() => {
    setUrl({ searchQuery, ...filterState });
  }, [filterState, searchQuery]);

  const value = React.useMemo<FilterListProviderType>(
    () => ({
      state: {
        filters: filterState.filters,
        searchQuery,
      },
      config: { filters, searchField },
      setSearchQuery,
      setFilterState: (v: Partial<FilterState>) => {
        setFilterState(p => ({ ...p, ...v }));
      },
    }),
    [filterState, searchQuery, setFilterState, searchField],
  );

  return (
    <FilterListContext.Provider value={value}>
      {children}
    </FilterListContext.Provider>
  );
};

export const useListFilter = () => {
  const { setFilterState, setSearchQuery, config, state } =
    React.useContext(FilterListContext);

  const resetFilters = () => {
    setFilterState({
      filters: getDefaultFilters(config.filters),
    });
    setSearchQuery('');
  };

  const addFilter = (definition: QueryRuleDefinition) => {
    const initialOperator = definition.operators[0];
    const initialValue = definition.defaultValue;
    setFilterState({
      filters: [
        ...state.filters,
        {
          id: definition.id,
          value: initialValue || undefined,
          operator: initialOperator,
        },
      ],
    });
  };

  const deleteFilter = (id: string) => {
    setFilterState({
      filters: state.filters.filter(f => f.id !== id),
    });
  };

  const setFilterValue = (id: string, newValue: QueryBuilderValue) => {
    setFilterState({
      filters: state.filters.map(f => {
        if (f.id === id) {
          return {
            ...f,
            value: newValue,
          };
        }
        return f;
      }),
    });
  };

  const activeFilters: ActiveFilter[] = state.filters.map(f => {
    const definition = config.filters.find(d => d.id === f.id);
    return {
      definition,
      value: f,
    };
  });

  return {
    filterString: getFilterString(state, activeFilters, config.searchField),
    activeFilters,
    filterOptions: config.filters.filter(
      f => !activeFilters.map(a => a.value.id).includes(f.id),
    ),
    resetFilters,
    deleteFilter,
    isDefaultFilterState: isDefaultFilters(state, config.filters),
    setFilterValue,
    addFilter,
    searchQuery: state.searchQuery,
    setSearchQuery,
  };
};
