import React from 'react';
import { InView } from 'react-intersection-observer';
import { useNavigate } from 'react-router-dom';

import {
  LinearProgress,
  Link,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from '@material-ui/core';
import { Alert } from '@material-ui/lab';

import {
  MediumScreenCell,
  extractLastNameComponent,
  useDialog,
} from '@spotify-confidence/core-react';
import {
  getTypeOrNull,
  isType,
  useListEntityRelationTablesQuery,
} from '@spotify-confidence/plugin-graphql';

import { useRouteRef } from '@backstage/core-plugin-api';

import { TableState } from '../../../../components/TableState';
import { entityRelationTableRouteRef } from '../../../../routes';

export default function EntityRelationTable({ id }: { id: string }) {
  const navigate = useNavigate();
  const { closeDialog } = useDialog();
  const entityRelationTableRoute = useRouteRef(entityRelationTableRouteRef);
  const { data, loading, error, fetchMore } = useListEntityRelationTablesQuery({
    variables: {
      parent: `entities/${id}`,
    },
    fetchPolicy: 'cache-and-network',
  });

  const entityRelationTablesResponse = getTypeOrNull(
    data?.entityRelationTables,
    'MetricsV1ListEntityRelationTablesResponse',
  );
  const entityRelationTables =
    entityRelationTablesResponse?.entityRelationTables || [];

  return (
    <>
      <Typography variant="overline" color="textSecondary">
        Entity relation tables
      </Typography>

      <TableContainer component={Paper}>
        {loading && <LinearProgress />}
        {error && <Alert severity="error">{error.message}</Alert>}
        {entityRelationTables.length > 0 && (
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>Target entity</TableCell>
                <TableCell>State</TableCell>
                <MediumScreenCell>Parent entity column</MediumScreenCell>
                <MediumScreenCell>Target entity column</MediumScreenCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {entityRelationTables.map(entityRelationTable => (
                <TableRow
                  hover
                  onClick={() => {
                    closeDialog();
                    navigate(
                      entityRelationTableRoute({
                        entityId: id,
                        entityRelationTableId: extractLastNameComponent(
                          entityRelationTable.name,
                        )!,
                      }),
                    );
                  }}
                >
                  <TableCell>
                    <Link href="#">
                      {isType(
                        entityRelationTable.targetEntity,
                        'MetricsV1Entity',
                      )
                        ? entityRelationTable.targetEntity.displayName
                        : 'Unknown'}
                    </Link>
                  </TableCell>
                  <TableCell>
                    <TableState
                      error={entityRelationTable.error}
                      status={entityRelationTable.state}
                    />
                  </TableCell>
                  <TableCell>
                    {entityRelationTable.parentEntityIdColumn.name}
                  </TableCell>
                  <TableCell>
                    {entityRelationTable.targetEntityIdColumn.name}
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        )}
        <InView
          onChange={async inView => {
            if (inView && entityRelationTablesResponse?.nextPageToken) {
              await fetchMore({
                variables: {
                  pageToken: entityRelationTablesResponse.nextPageToken,
                },
              });
            }
          }}
        />
      </TableContainer>
    </>
  );
}
