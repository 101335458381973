import { getError, getTypeOrNull } from '@spotify-confidence/plugin-graphql';
import { isWithinInterval, subMinutes } from 'date-fns';

import { useGetEventPublishedQuery } from '../../../generated/graphql';

export function useEventPublished({
  skip,
  eventName,
}: {
  skip?: boolean;
  eventName: string;
}) {
  const { data, loading, error, startPolling, stopPolling } =
    useGetEventPublishedQuery({
      variables: {
        name: `eventDefinitions/${eventName}`,
      },
      skip,
    });

  const eventData = getTypeOrNull(
    data?.eventDefinition,
    'EventsAdminV1EventDefinition',
  );
  const lastPublishTime = eventData?.usageMetadata?.lastPublishTime;

  const now = new Date();

  // If the event has been published in the last 5 minutes.
  const isPublished = Boolean(
    lastPublishTime &&
      isWithinInterval(new Date(lastPublishTime), {
        start: subMinutes(now, 12 * 60),
        end: now,
      }),
  );

  return {
    isPublished,
    startPolling,
    stopPolling,
    loading,
    error: getError(data?.eventDefinition) || error,
  };
}
