import React from 'react';

import { Box, Chip, Typography } from '@material-ui/core';

import { getTypeOrNull } from '@spotify-confidence/plugin-graphql';
import classNames from 'classnames';
import { TCountryCode, getCountryData, getEmojiFlag } from 'countries-list';
import _ from 'lodash';

import { targetingCodec } from '../../../../../../segment/domain/targeting';
import {
  isCriterion,
  isCriterionSet,
  isSetValue,
  isSingleValue,
} from '../../../../../../segment/domain/targeting/targeting.model';
import { RuleCardAssign } from '../RuleCardAssign';
import { RuleViewProps, useRuleViewStyles } from '../shared';

export const OverrideRuleView = ({
  rule,
  seenAttributes,
  className,
}: RuleViewProps) => {
  const classes = useRuleViewStyles();
  const segment = getTypeOrNull(rule.segment, 'FlagsAdminV1Segment');
  const targeting = React.useMemo(
    () => targetingCodec.fromSchemaTargeting(segment?.targeting),
    [segment?.targeting],
  );

  const attribute = ((isCriterionSet(targeting) &&
    targeting.criteria.length === 1 &&
    isCriterion(targeting.criteria[0]) &&
    targeting.criteria[0].type === 'attribute' &&
    targeting.criteria[0].attribute) ||
    '') as string;

  const seenAttribute = seenAttributes?.find(a => a.key === attribute);
  const entity = getTypeOrNull(
    seenAttribute?.value?.semanticType?.entityReference?.entity,
    'MetricsV1Entity',
  );
  const attributeDisplayName =
    entity?.displayName || seenAttribute?.value?.displayName;

  let values: string[] = [];
  if (isCriterionSet(targeting) && targeting.criteria.length === 1) {
    const criterion = targeting.criteria[0];

    if (isCriterion(criterion) && criterion.type === 'attribute') {
      const criterionValue = criterion.value;
      if (isSetValue(criterionValue)) {
        values = criterionValue.filter(_.isString);
      } else if (isSingleValue(criterionValue) && _.isString(criterionValue)) {
        values = [criterionValue];
      }
    }
  }

  if (
    seenAttribute?.value?.semanticType?.country?.format ===
    'TWO_LETTER_ISO_CODE'
  ) {
    values = values.map(value => {
      const code = value as TCountryCode;
      return [getEmojiFlag(code), getCountryData(code).name || value]
        .join(' ')
        .trim();
    });
  }

  return (
    <Typography
      variant="body1"
      className={classNames(className, classes.title)}
      component="div"
    >
      <RuleCardAssign assignmentSpec={rule.assignmentSpec} hidePercentages />
      <strong>if </strong>
      <span className={classes.attribute}>
        &nbsp;
        {attributeDisplayName
          ? `${attributeDisplayName} (${attribute})`
          : attribute}
        &nbsp;
      </span>
      <Box component="strong" display="inline-block" marginRight={1}>
        is any of
      </Box>
      <Box display="flex" gridGap={4} flexWrap="wrap">
        {values.map(value => (
          <Chip
            label={value}
            key={value}
            className={classes.chip}
            size="small"
          />
        ))}
      </Box>
    </Typography>
  );
};
