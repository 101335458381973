import React from 'react';
import { useNavigate, useParams } from 'react-router-dom';

import {
  Box,
  Card,
  CardContent,
  CircularProgress,
  Typography,
} from '@material-ui/core';
import { Alert } from '@material-ui/lab';

import {
  Breadcrumbs,
  DangerBox,
  DangerButton,
  DangerZone,
  DateUtils,
  PageLayout,
  useAlert,
} from '@spotify-confidence/core-react';
import {
  getError,
  getTypeOrNull,
  useDeleteEventConnectionMutation,
  useEventConnectionQuery,
} from '@spotify-confidence/plugin-graphql';
import { PermissionUtils } from '@spotify-confidence/plugin-permissions-react';

import { Link } from '@backstage/core-components';
import { useRouteRef } from '@backstage/core-plugin-api';

import { eventConnectionsRouteRef, rootRouteRef } from '../../../routes';
import {
  getEventConnectionDestinationName,
  getEventConnectionDetails,
} from '../../helpers';

export const EventConnectionPage = () => {
  const { id } = useParams();
  const alert = useAlert();
  const navigate = useNavigate();
  const adminRoute = useRouteRef(rootRouteRef);
  const eventConnectionsRoute = useRouteRef(eventConnectionsRouteRef);
  const name = `eventConnections/${id}`;
  const { data, loading } = useEventConnectionQuery({
    variables: {
      eventConnectionName: name,
    },
  });

  const [deleteConnection, { loading: isDeleting, error: deleteError }] =
    useDeleteEventConnectionMutation({
      variables: {
        name: name,
      },
      onCompleted: response => {
        const error = getError(response.deleteEventConnection);
        if (error) {
          alert.post({
            message: error.message,
            severity: 'error',
          });
        } else {
          navigate(eventConnectionsRoute());
        }
      },
    });

  const connection = getTypeOrNull(
    data?.eventConnection,
    'ConnectorsV1EventConnection',
  );
  const connectionError = getTypeOrNull(
    data?.eventConnection,
    'Error',
  )?.message;

  const connectionInfo = [
    {
      label: 'Destination',
      value: getEventConnectionDestinationName(connection),
    },
    {
      label: 'Created',
      value: connection?.createTime
        ? DateUtils.toDateString(
            connection?.createTime || '',
            DateUtils.DATE_TIME_FORMAT,
          )
        : undefined,
    },
    ...getEventConnectionDetails(connection, true),
  ];

  const canEdit = PermissionUtils.hasRelation(connection, 'can_edit');

  return (
    <PageLayout
      narrow
      title="Connection details"
      headerBreadcrumbs={
        <Breadcrumbs>
          <Link to={adminRoute()}>Admin</Link>
          <Link to={eventConnectionsRoute()}>Event connections</Link>
          <Typography>Connection details</Typography>
        </Breadcrumbs>
      }
    >
      {connectionError && <Alert severity="error">{connectionError}</Alert>}
      {connection && (
        <>
          <Card>
            <CardContent>
              <Box display="grid" gridTemplateColumns="1fr 1fr" gridGap="1em">
                {connectionInfo.map(info => (
                  <div key={info.label}>
                    <Typography variant="body2" color="textSecondary">
                      {info.label}
                    </Typography>
                    <Typography>{info.value}</Typography>
                  </div>
                ))}
              </Box>
            </CardContent>
          </Card>

          <Box my={3}>
            {deleteError && (
              <Alert severity="error">{deleteError.message}</Alert>
            )}
            <DangerZone>
              <DangerBox>
                <strong>Delete Event Connection</strong>
                <DangerButton
                  variant="contained"
                  disabled={isDeleting || loading || !canEdit}
                  color="inherit"
                  endIcon={isDeleting && <CircularProgress size="1em" />}
                  onClick={() => deleteConnection()}
                >
                  Delete
                </DangerButton>
              </DangerBox>
            </DangerZone>
          </Box>
        </>
      )}
    </PageLayout>
  );
};
