import React from 'react';

import { useElementFilter } from '@backstage/core-plugin-api';

import {
  WORKFLOW_EXTENSION_KEY,
  WORKFLOW_EXTENSION_WRAPPER_KEY,
  WorkflowExtensionOptions,
} from './index';

export const useCustomExtensions = <
  TComponentDataType = WorkflowExtensionOptions,
>(
  outlet: React.ReactNode,
) => {
  return useElementFilter(outlet, elements =>
    elements
      .selectByComponentData({
        key: WORKFLOW_EXTENSION_WRAPPER_KEY,
      })
      .findComponentData<TComponentDataType>({
        key: WORKFLOW_EXTENSION_KEY,
      }),
  );
};

export const useExtensions = ({
  outlet,
  defaultExtensions = [],
}: {
  outlet: React.ReactNode;
  defaultExtensions?: WorkflowExtensionOptions[];
}) => {
  const customWorkflowExtensions = useCustomExtensions(outlet);

  return React.useMemo(
    () => [
      ...customWorkflowExtensions,
      ...defaultExtensions.filter(
        ({ workflowId }) =>
          !customWorkflowExtensions.some(
            customWorkflowExtension =>
              customWorkflowExtension.workflowId === workflowId,
          ),
      ),
    ],
    [customWorkflowExtensions, defaultExtensions],
  );
};
