import React from 'react';

import { Box } from '@material-ui/core';
import CheckCircle from '@material-ui/icons/CheckCircle';

import {
  ConfidenceWebsiteLink,
  FeatureFlagged,
  FormFieldsBox,
  SidebarValue,
  SidebarValueWrapper,
} from '@spotify-confidence/core-react';
import { MetricsV1AggregationType } from '@spotify-confidence/plugin-graphql';

import { aggregationUtils } from '../Aggregation';
import { AggregationForm } from '../AggregationForm';
import { MetricFilters } from '../MetricFilters';
import { MetricInputsForm } from '../MetricInputsForm';
import { metricInputsSection } from './genericMetricSections';
import { MetricSection } from './types';

const metricInputsDescription =
  'Select the entity you want to measure and the fact table with the measurement you want to use.';

const replaceEntityNullWithZeroAggs = [
  MetricsV1AggregationType.AggregationTypeSum,
  MetricsV1AggregationType.AggregationTypeCount,
  MetricsV1AggregationType.AggregationTypeCountDistinct,
];

const averageMetricInputsEditSection: MetricSection = {
  ...metricInputsSection,
  description: metricInputsDescription,
  form: (value, onChange) => (
    <FormFieldsBox>
      <MetricInputsForm value={value} onChange={onChange} />
      <FeatureFlagged with="metric-filters">
        <MetricFilters
          factTable={value.factTable}
          filter={value.filter}
          onChange={newFilter => {
            onChange({
              ...value,
              filter: newFilter,
            });
          }}
        />
      </FeatureFlagged>
      {value.typeSpec?.averageMetricSpec && (
        <AggregationForm
          value={value.typeSpec.averageMetricSpec}
          onChange={averageMetricSpec =>
            onChange({
              ...value,
              typeSpec: {
                averageMetricSpec,
              },
            })
          }
          entity={value.entity}
          factTable={value.factTable}
        />
      )}
    </FormFieldsBox>
  ),
  updateMask: [
    metricInputsSection.updateMask,
    'typeSpec.averageMetricSpec',
  ].join(','),
};

const toCapLabel = (value: string | undefined | null) => {
  if (value !== undefined && value !== null && !isNaN(parseFloat(value))) {
    return value;
  }

  return 'No cap';
};

const aggregationSection: MetricSection = {
  name: 'Aggregation',
  description: (
    <>
      Select the way you want to aggregate your metric.{' '}
      <ConfidenceWebsiteLink
        route="/docs/metrics#within-entity-aggregation"
        underline="always"
      >
        Learn more.
      </ConfidenceWebsiteLink>
    </>
  ),
  canBeEditedSafely: false,
  form: (value, onChange) =>
    value.typeSpec?.averageMetricSpec && (
      <AggregationForm
        value={value.typeSpec.averageMetricSpec}
        onChange={averageMetricSpec =>
          onChange({
            ...value,
            typeSpec: {
              averageMetricSpec,
            },
            nullHandling: {
              replaceEntityNullWithZero: replaceEntityNullWithZeroAggs.includes(
                averageMetricSpec.aggregation.type,
              ),
            },
          })
        }
        entity={value.entity}
        factTable={value.factTable}
      />
    ),
  summary: metric =>
    metric.typeSpec?.averageMetricSpec && (
      <SidebarValueWrapper>
        <Box display="grid" gridTemplateColumns="1fr 1fr">
          <SidebarValue
            name="Measurement"
            value={
              metric.typeSpec.averageMetricSpec.measurement?.name ||
              'The number of fact rows'
            }
          />
          {metric.typeSpec?.averageMetricSpec.measurement?.name && (
            <SidebarValue
              name="Method"
              value={aggregationUtils.getAggregationTypeLabel(
                metric.typeSpec?.averageMetricSpec.aggregation.type,
              )}
            />
          )}
        </Box>
        <Box display="grid" gridTemplateColumns="1fr 1fr">
          <SidebarValue
            name="Min cap"
            value={`${toCapLabel(
              metric.typeSpec?.averageMetricSpec.aggregation.cap?.min?.value,
            )}`}
          />
          <SidebarValue
            name="Max cap"
            value={`${toCapLabel(
              metric.typeSpec?.averageMetricSpec.aggregation.cap?.max?.value,
            )}`}
          />
        </Box>
        {metric.typeSpec?.averageMetricSpec.aggregation.threshold && (
          <SidebarValue
            icon={<CheckCircle fontSize="small" />}
            name="Map aggregated value to booleans"
            value={`${
              aggregationUtils.getAggregationThresholdDirectionDetails(
                metric.typeSpec?.averageMetricSpec.aggregation.threshold
                  ?.direction,
              ).label
            } ${
              metric.typeSpec?.averageMetricSpec?.aggregation.threshold
                ?.threshold.value
            }`}
          />
        )}
      </SidebarValueWrapper>
    ),
  updateMask: 'typeSpec.averageMetricSpec',
};

// Used in the create form
export const createAverageMetricSections: MetricSection[] = [
  {
    ...metricInputsSection,
    description: metricInputsDescription,
  },
  aggregationSection,
];

// Used in the create summary + metric page sidebar
export const editAverageMetricSections: MetricSection[] = [
  averageMetricInputsEditSection,
  aggregationSection,
];
