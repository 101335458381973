import { useLocalStorage } from '../../utils';

/**
 * Used to maintain user sessions with support for multiple organizations
 * https://community.auth0.com/t/how-do-i-use-organizations-with-the-react-sdk-and-maintain-user-sessions/69949
 */

const ORG_ID_STORAGE_KEY = 'orgId';

export const useOrgId = (): [
  string | undefined,
  (newOrgId: string | null) => void,
] => {
  const [orgId, setOrgId] = useLocalStorage<string | null>(
    ORG_ID_STORAGE_KEY,
    null,
  );

  return [orgId || undefined, setOrgId];
};

export const getCurrentOrgId = () => {
  const orgId = localStorage.getItem(ORG_ID_STORAGE_KEY);
  const organization = orgId ? JSON.parse(orgId) : undefined;
  return organization ?? undefined;
};
