import React from 'react';

import {
  Tooltip,
  Typography,
  TypographyProps,
  makeStyles,
} from '@material-ui/core';
import FiberManualRecord from '@material-ui/icons/FiberManualRecord';

import classNames from 'classnames';
import _ from 'lodash';

import { SignificantDirection, UndesiredReason } from './types';

export const useStatusMessageStyles = makeStyles(theme => ({
  status: {
    display: 'flex',
    alignItems: 'center',
    gap: theme.spacing(1),
  },
  inlineIcon: {
    fontSize: '0.7em',
  },
  significant: {
    color: theme.palette.text.primary,
  },
  notSignificant: {
    color: theme.palette.text.disabled,
  },
  possiblyInferior: {
    color: theme.palette.warning.dark,
  },
  undesiredSignificant: {
    color: theme.palette.error.dark,
  },
}));

export type StatusMessageProps = {
  className?: string;
  isSignificant?: boolean;
  direction?: SignificantDirection;
  nim?: number;
  undesiredReason?: UndesiredReason;
  title?: string;
  message?: string;
  status?: keyof Omit<
    ReturnType<typeof useStatusMessageStyles>,
    'status' | 'inlineIcon'
  >;
  disableIcon?: boolean;
  typographyProps?: TypographyProps;
  symbol?: React.ReactNode;
};

export const undesiredMessage = (reason: UndesiredReason | undefined) => {
  if (reason === 'failedTankingWithinNim') {
    return 'Evidence of deterioration, but within the acceptable margin';
  }

  return 'Evidence of deterioration';
};

export const StatusMessage = ({
  isSignificant,
  nim,
  direction,
  undesiredReason,
  title: titleOverride,
  message: messageOverride,
  status: statusOverride,
  disableIcon,
  typographyProps,
  className,
  symbol,
}: StatusMessageProps & React.HTMLAttributes<HTMLElement>) => {
  const classes = useStatusMessageStyles();

  let title = '';
  let message;
  let typographyClass = classes.status;

  if (isSignificant && direction === 'undesired') {
    typographyClass = classNames(typographyClass, classes.undesiredSignificant);
    message = 'Has deteriorated';
    title = undesiredMessage(undesiredReason);
  } else if (isSignificant) {
    typographyClass = classNames(typographyClass, classes.significant);
    if (nim && nim !== 0) {
      message = title = 'Within margin';
    } else if (direction === 'desired') {
      title = 'Significant improvement';
      message = 'Has improved';
    } else {
      message = 'Significant';
    }
  } else if (isSignificant !== undefined && !_.isNil(nim) && nim !== 0) {
    typographyClass = classNames(typographyClass, classes.possiblyInferior);
    message = 'Possibly inferior';
    title = 'No evidence yet of being within the acceptable margin';
  } else {
    typographyClass = classNames(typographyClass, classes.notSignificant);
    message = 'Not significant';
  }

  title = titleOverride || title;
  message = messageOverride || message;
  typographyClass = statusOverride
    ? classNames(typographyClass, classes[statusOverride])
    : typographyClass;

  return (
    <Tooltip title={title} arrow>
      <Typography
        className={classNames(className, typographyClass)}
        noWrap
        variant="body1"
        {...typographyProps}
      >
        {!disableIcon && (
          <FiberManualRecord
            className={classes.inlineIcon}
            color="inherit"
            fontSize="small"
          />
        )}
        {symbol}
        {message}
      </Typography>
    </Tooltip>
  );
};
