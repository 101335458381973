import React from 'react';
import { Link } from 'react-router-dom';

import {
  Button,
  ButtonBase,
  Card,
  CardActions,
  CardContent,
  Chip,
  Grow,
  Popper,
  Typography,
  makeStyles,
  styled,
} from '@material-ui/core';

import {
  getTypeOrNull,
  useDataWarehouseStateQuery,
} from '@spotify-confidence/plugin-graphql';

const useStyles = makeStyles(theme => ({
  popperCard: {
    marginTop: theme.spacing(1),
    maxWidth: 430,
  },
}));

const DemoChip = styled(Chip)(({ theme }) => ({
  background: theme.palette.info.main,
  color: theme.palette.info.contrastText,
  margin: 0,
}));

export const DemoModeChip = () => {
  const classes = useStyles();
  const { data } = useDataWarehouseStateQuery();
  const state = getTypeOrNull(
    data?.existsDataWarehouses,
    'MetricsV1ExistsDataWarehousesResponse',
  );

  const [anchorEl, setAnchorEl] = React.useState<HTMLElement | null>(null);

  const handlePopoverOpen = (
    event: React.MouseEvent<HTMLElement, MouseEvent>,
  ) => {
    setAnchorEl(event.currentTarget);
  };

  const handlePopoverClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);

  if (state?.demoData) {
    return (
      <div onMouseLeave={handlePopoverClose}>
        <ButtonBase
          component={DemoChip}
          onMouseEnter={handlePopoverOpen}
          label="Demo mode"
          size="small"
        />
        <Popper open={open} anchorEl={anchorEl} transition disablePortal>
          {({ TransitionProps }) => (
            <Grow {...TransitionProps}>
              <Card className={classes.popperCard}>
                <CardContent>
                  <Typography paragraph variant="body2">
                    You are in Confidence demo mode.
                  </Typography>
                  <Typography paragraph variant="body2">
                    When you are done exploring, exit demo mode and connect your
                    data warehouse to start experimenting!
                  </Typography>
                  <CardActions>
                    <Button
                      variant="outlined"
                      component={Link}
                      onClick={handlePopoverClose}
                      to="/admin/metrics-datawarehouse"
                      size="small"
                    >
                      Exit demo mode
                    </Button>
                  </CardActions>
                </CardContent>
              </Card>
            </Grow>
          )}
        </Popper>
      </div>
    );
  }
  return null;
};
