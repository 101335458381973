import loadable from '@loadable/component';

const iconsChunk = () =>
  import(/* webpackChunkName: "core-react-icons" */ './icons.chunk');

export const AbsoluteNumberIcon = loadable(() =>
  iconsChunk().then(m => m.AbsoluteNumberIcon),
);

export const AverageIcon = loadable(() =>
  iconsChunk().then(m => m.AverageIcon),
);
export const BalanceIcon = loadable(() =>
  iconsChunk().then(m => m.BalanceIcon),
);

export const DoubleIcon = loadable(() => iconsChunk().then(m => m.DoubleIcon));

export const IntIcon = loadable(() => iconsChunk().then(m => m.IntIcon));
export const JsonIcon = loadable(() => iconsChunk().then(m => m.JsonIcon));
export const RelativeNumberIcon = loadable(() =>
  iconsChunk().then(m => m.RelativeNumberIcon),
);

export const TopRightSwirlArrowIcon = loadable(() =>
  iconsChunk().then(m => m.TopRightSwirlArrowIcon),
);
export const LeftSwirlArrowIcon = loadable(() =>
  iconsChunk().then(m => m.LeftSwirlArrowIcon),
);

// this needed from the start, so it's ok to go into main chunk
export { RolloutIcon } from './RolloutIcon';
export { ConfidenceLogo } from './ConfidenceLogo';
