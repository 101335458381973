import React from 'react';

import { Collapse, List } from '@material-ui/core';

import { EventSchemaEntryFragment } from '@spotify-confidence/plugin-graphql';

import {
  appendId,
  getHasInvalidChild,
  getNestedSchema,
  getTypeSchema,
} from '../../domain/schema.helpers';
import { Entry } from '../../domain/schema.hooks';
import { AddFieldButton, Option } from './AddFieldButton';
import { useSchemaContext } from './SchemaContext';
import { MAXIMUM_DEPTH } from './SchemaEditor';
import { SchemaEntry } from './SchemaEntry';

export type SharedSchemaProps = {
  path: string[];
  disabled?: boolean;
  readOnly?: boolean;
  existingFieldError?: boolean;
  expandParent?: () => void;
  getEntryLabels?: (
    identity: string,
    path: string[],
    entry: Entry,
  ) => React.ReactNode;
  hasNestedField?: boolean;
};

export const EventSchema = ({
  schema,
  identifier = '0',
  readOnly,
  entry: structEntry,
  getEntryLabels,
  hasNestedField: _hasNestedField,
  ...rest
}: {
  identifier?: string;
  schema: EventSchemaEntryFragment[];
  entry?: EventSchemaEntryFragment;
} & SharedSchemaProps) => {
  const usedNames = schema.map(n => n.key);
  const hasNestedField =
    _hasNestedField || schema.some(s => getNestedSchema(s.value));

  return (
    <List>
      {schema.map((entry, i) => {
        /*
            We assume older keys appear later in the list since
            we add new properties to the start of the list.
          */
        const olderFieldExists = entry.key
          ? usedNames.indexOf(entry.key, i + 1) !== -1
          : false;

        return (
          <EventSchemaEntry
            key={`entry-${i}-${identifier}`}
            {...rest}
            getEntryLabels={getEntryLabels}
            entry={entry}
            identifier={appendId(identifier, i)}
            readOnly={readOnly}
            existingFieldError={olderFieldExists}
            hasNestedField={hasNestedField}
          />
        );
      })}
    </List>
  );
};

function EventSchemaEntry({
  entry,
  identifier,
  path,
  disabled,
  readOnly,
  existingFieldError,
  expandParent,
  hasNestedField,
  ...rest
}: {
  identifier: string;
  entry: Entry;
} & SharedSchemaProps) {
  const { onAdd, selectedEntry, setSelectedEntry } = useSchemaContext();

  const { key, value } = entry;
  const [expanded, setExpanded] = React.useState(!readOnly);
  const nestedSchema = getNestedSchema(value);
  const currentPath = [...path, key];
  const canExpand = !!nestedSchema;

  const showInvalidChild =
    !!nestedSchema && getHasInvalidChild(nestedSchema.schema) && !expanded;

  const handleOnAdd = (v: Option) => {
    setExpanded(true);
    const newId = onAdd(identifier, {
      key: '',
      value: getTypeSchema(v),
    });
    setSelectedEntry(newId);
  };
  const isSelected = identifier === selectedEntry;

  let error: string | null = null;
  if (existingFieldError) {
    error = 'There is already another property with this name.';
  } else if (key === '') {
    error = 'Missing field name.';
  } else if (showInvalidChild) {
    error = 'Invalid sub field.';
  }

  return (
    <>
      <SchemaEntry
        level={path.length}
        dense
        button={!readOnly as any}
        onClick={
          !readOnly
            ? () => {
                setSelectedEntry(identifier);
                if (canExpand && !expanded) {
                  setExpanded(true);
                }
              }
            : undefined
        }
        selected={isSelected}
      >
        {hasNestedField && (
          <SchemaEntry.Expand
            expanded={expanded}
            onChange={setExpanded}
            canExpand={canExpand}
          />
        )}
        <SchemaEntry.Details entry={entry}>
          {rest.getEntryLabels?.(identifier, currentPath, entry)}
        </SchemaEntry.Details>
        <SchemaEntry.Actions>
          <SchemaEntry.Error error={error} />
        </SchemaEntry.Actions>
      </SchemaEntry>
      {nestedSchema && (
        <Collapse in={expanded}>
          {!readOnly && (
            <SchemaEntry level={currentPath.length}>
              {hasNestedField && <SchemaEntry.Expand />}
              <AddFieldButton
                size="small"
                variant="text"
                disabled={disabled}
                entry={entry}
                disabledTypes={
                  path.length >= MAXIMUM_DEPTH - 1 ? ['structSchema'] : []
                }
                onClick={handleOnAdd}
              />
            </SchemaEntry>
          )}
          <EventSchema
            {...rest}
            entry={entry}
            path={currentPath}
            identifier={identifier}
            schema={nestedSchema.schema}
            readOnly={readOnly}
            disabled={disabled}
            hasNestedField={hasNestedField}
            expandParent={() => {
              expandParent?.();
              setExpanded(true);
            }}
          />
        </Collapse>
      )}
    </>
  );
}
