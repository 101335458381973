import React from 'react';

import {
  AutocompleteChangeDetails,
  AutocompleteGetTagProps,
  AutocompleteHighlightChangeReason,
  AutocompleteProps,
  AutocompleteRenderOptionState,
  FilterOptionsState,
  Value,
} from '@material-ui/lab';

import { PaginatedListItem } from '../../hooks';
import { LoadingChip } from './LoadingChip';

export type AutocompleteTypeConversionMethod =
  | 'getOptionLabel'
  | 'getOptionSelected'
  | 'renderOption'
  | 'getOptionDisabled'
  | 'groupBy'
  | 'onHighlightChange';

export type AutocompleteTypeConversionMethods<
  T,
  Multiple extends boolean | undefined = undefined,
  DisableClearable extends boolean | undefined = undefined,
  FreeSolo extends boolean | undefined = undefined,
> = Pick<
  AutocompleteProps<T, Multiple, DisableClearable, FreeSolo>,
  AutocompleteTypeConversionMethod
> & {
  getOptionLabel?: (option: T) => string;
  filterOptions?: (options: T[], state: FilterOptionsState<string>) => string[];
  renderTags?: (
    options: T[],
    getTagProps: AutocompleteGetTagProps,
  ) => React.ReactNode;
  onChange?: (
    event: React.ChangeEvent<{}>,
    value: Value<string, Multiple, DisableClearable, FreeSolo>,
    reason: string,
    details?: AutocompleteChangeDetails<T>,
  ) => void;
};

export const useAutocompleteTypeConversionMethods = <
  T extends PaginatedListItem = PaginatedListItem,
  Multiple extends boolean | undefined = undefined,
  DisableClearable extends boolean | undefined = undefined,
  FreeSolo extends boolean | undefined = undefined,
>({
  options,
  loading,
  ...overrides
}: AutocompleteTypeConversionMethods<
  T,
  Multiple,
  DisableClearable,
  FreeSolo
> & {
  options: T[];
  loading?: boolean;
}): AutocompleteTypeConversionMethods<
  string,
  Multiple,
  DisableClearable,
  FreeSolo
  // getOptionLabel is optional as input, but always returned from this hook
> & { getOptionLabel: (option: string) => string } => {
  const getOption = React.useCallback(
    (option: string) => {
      return options.find(o => o.name === option) || ({ name: option } as T);
    },
    [options],
  );

  const getOptionLabel = React.useCallback(
    (name: string) => {
      const option = getOption(name);
      return overrides.getOptionLabel?.(option) || option?.displayName || name;
    },
    [getOption, overrides.getOptionLabel],
  );

  const getOptionDisabled = React.useCallback(
    (name: string) => {
      const option = getOption(name);
      return overrides.getOptionDisabled?.(option) ?? false;
    },
    [overrides.getOptionDisabled, getOption],
  );

  const renderOption = React.useCallback(
    (name: string, props: AutocompleteRenderOptionState) => {
      const option = getOption(name);
      return overrides.renderOption?.(option, props) ?? getOptionLabel(name);
    },
    [overrides.renderOption, getOption],
  );

  const getOptionSelected = React.useCallback(
    (name: string, selectedName: string) => {
      const option = getOption(name);
      const selectedOption = getOption(selectedName);
      return overrides.getOptionSelected?.(option, selectedOption) ?? false;
    },
    [overrides.getOptionSelected, getOption],
  );

  const groupBy = React.useCallback(
    (name: string) => {
      const option = getOption(name);
      return overrides.groupBy?.(option) || '';
    },
    [overrides.groupBy, getOption],
  );

  const onHighlightChange = React.useCallback(
    (
      event: React.ChangeEvent<{}>,
      name: string | null,
      reason: AutocompleteHighlightChangeReason,
    ) => {
      return overrides.onHighlightChange?.(
        event,
        name ? getOption(name) : null,
        reason,
      );
    },
    [overrides.onHighlightChange, getOption],
  );

  const filterOptions = React.useCallback(
    (_names: string[], props: FilterOptionsState<string>) => {
      return overrides.filterOptions?.(options, props) || [];
    },
    [overrides.filterOptions, options],
  );

  const renderTags = React.useCallback(
    (selected: string[], getTagProps: AutocompleteGetTagProps) =>
      overrides.renderTags?.(selected.map(getOption), getTagProps) ??
      // Override default rendering with LoadingChip to avoid flickering while fetching displayNames
      selected.map((option: string, index: number) => {
        const tagProps = getTagProps({ index });
        const loadingTag = loading && !options.some(o => o.name === option);
        return (
          <LoadingChip
            {...tagProps}
            size="small"
            loading={loadingTag}
            label={getOptionLabel(option)}
          />
        );
      }),
    [overrides.renderTags, getOption, options, loading],
  );

  const onChange = React.useCallback(
    (event: React.ChangeEvent<{}>, value: any, reason: any, details = {}) => {
      const option = getOption(value);

      return overrides.onChange?.(event, value, reason, {
        ...details,
        option,
      });
    },
    [overrides.onChange, options],
  );

  return {
    getOptionLabel,
    renderTags,
    onChange: overrides.onChange ? onChange : undefined,
    getOptionDisabled: overrides.getOptionDisabled
      ? getOptionDisabled
      : undefined,
    renderOption: overrides.renderOption ? renderOption : undefined,
    getOptionSelected: overrides.getOptionSelected
      ? getOptionSelected
      : undefined,
    groupBy: overrides.groupBy ? groupBy : undefined,
    onHighlightChange: overrides.onHighlightChange
      ? onHighlightChange
      : undefined,
    filterOptions: overrides.filterOptions ? filterOptions : undefined,
  };
};
