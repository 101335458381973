import React from 'react';
import { useDebounce } from 'react-use';

import {
  Box,
  Button,
  CircularProgress,
  Collapse,
  Link,
  MenuItem,
  TextField,
  Typography,
} from '@material-ui/core';
import { Alert, AlertTitle } from '@material-ui/lab';

import { FormFieldsBox } from '@spotify-confidence/core-react';
import {
  MetricsV1SnowflakeConfigInput,
  MetricsV1ValidateDataWarehouseConfigResponseSnowflakeConfigValidation as SnowflakeValidation,
  getTypeOrNull,
  useValidateSnowflakeDataWarehouseConfigMutation,
} from '@spotify-confidence/plugin-graphql';

import { CryptoKeyInput } from './CryptoKeyInput';

type SnowflakeDatawarehouseFormProps = {
  value?: MetricsV1SnowflakeConfigInput | null;
  onChange: (conf: MetricsV1SnowflakeConfigInput) => void;
  disabled?: boolean;
};

const canSendValidationRequest = (
  p: Partial<MetricsV1SnowflakeConfigInput> = {},
) => {
  return !!(p.user && p.account && p.authenticationKey);
};

const hasConfigurationThatAffectsValidationChanged = (
  oldValues: Partial<MetricsV1SnowflakeConfigInput> = {},
  newValues: Partial<MetricsV1SnowflakeConfigInput> = {},
) => {
  return (
    oldValues.role !== newValues.role ||
    oldValues.exposureDatabase !== newValues.exposureDatabase ||
    oldValues.account !== newValues.account ||
    oldValues.user !== newValues.user ||
    oldValues.authenticationKey !== newValues.authenticationKey
  );
};

const defaultConfig = {
  account: '',
  authenticationKey: '',
  exposureDatabase: '',
  exposureSchema: '',
  role: '',
  user: '',
  warehouse: '',
};

function getAvailable(
  validation?: SnowflakeValidation | null,
  type?: keyof SnowflakeValidation,
  currentValue?: string,
) {
  if (validation && type) {
    const options = validation?.[type] || [];
    if (options.length > 0) {
      return options;
    }
  }
  if (currentValue) {
    return [currentValue];
  }
  return [];
}

export const SnowflakeDatawarehouseForm = ({
  value,
  onChange,
  disabled,
}: SnowflakeDatawarehouseFormProps) => {
  const [validate, validation] =
    useValidateSnowflakeDataWarehouseConfigMutation({
      variables: {
        snowflakeConfig: value,
      },
    });

  const account: string = value?.account ?? '';
  const user: string = value?.user ?? '';
  const authenticationKey: string = value?.authenticationKey ?? '';
  const role: string = value?.role ?? '';
  const warehouse: string = value?.warehouse ?? '';
  const exposureDatabase: string = value?.exposureDatabase ?? '';
  const exposureSchema: string = value?.exposureSchema ?? '';

  const prevValue = React.useRef(value);
  const canValidate = canSendValidationRequest(value ?? undefined);

  const handleChange =
    (field: keyof MetricsV1SnowflakeConfigInput) => (newFieldValue: string) => {
      const newValue = {
        ...defaultConfig,
        ...value,
        [field]: newFieldValue || '',
      };

      // If any of these changes, all the rest of the settings might be invalid, so need to reset them.
      if (
        newValue.account !== value?.account ||
        newValue.user !== value?.user ||
        newValue.authenticationKey !== value?.authenticationKey
      ) {
        newValue.role = '';
        newValue.warehouse = '';
        newValue.exposureDatabase = '';
        newValue.exposureSchema = '';
      }

      // If role changes, we might have lost permissions to warehouses + databases, so need to reset them.
      if (newValue.role !== value?.role) {
        newValue.warehouse = '';
        newValue.exposureDatabase = '';
        newValue.exposureSchema = '';
      }

      onChange(newValue);
    };

  const handleTextFieldChange =
    (field: keyof MetricsV1SnowflakeConfigInput) =>
    (e: React.ChangeEvent<HTMLInputElement>) => {
      handleChange(field)(e.target.value);
    };

  const configValidation = getTypeOrNull(
    validation.data?.validateDataWarehouseConfig,
    'MetricsV1ValidateDataWarehouseConfigResponse',
  )?.snowflakeConfigValidation;

  const availableRoles = getAvailable(configValidation, 'roles', role);
  const availableWarehouses = getAvailable(
    configValidation,
    'warehouses',
    warehouse,
  );
  const availableDatabases = getAvailable(
    configValidation,
    'databases',
    exposureDatabase,
  );
  const availableSchemas = getAvailable(
    configValidation,
    'schemas',
    exposureSchema,
  );

  useDebounce(
    () => {
      if (
        canValidate &&
        hasConfigurationThatAffectsValidationChanged(
          value ?? undefined,
          prevValue.current ?? undefined,
        ) &&
        value
      ) {
        validate();
      } else if (!canValidate) {
        validation.reset();
      }
      prevValue.current = value;
    },
    1000,
    [value, canValidate],
  );

  return (
    <>
      <FormFieldsBox>
        <TextField
          disabled={disabled}
          label="Account identifier"
          variant="outlined"
          value={account}
          required
          fullWidth
          helperText={
            <>
              The{' '}
              <Link
                href="https://docs.snowflake.com/en/user-guide/admin-account-identifier"
                underline="always"
                target="_blank"
              >
                Snowflake account identifier
              </Link>{' '}
              (e.g. myorg-account123).
            </>
          }
          onChange={handleTextFieldChange('account')}
        />

        <TextField
          disabled={disabled}
          label="User"
          variant="outlined"
          value={user}
          required
          fullWidth
          helperText="The Snowflake user name."
          onChange={handleTextFieldChange('user')}
        />

        <CryptoKeyInput
          disabled={disabled}
          label="Crypto Key"
          variant="outlined"
          value={authenticationKey}
          required
          fullWidth
          helperText={
            <>
              The key used to authenticate the requests to the Snowflake API.
              Copy the public key and{' '}
              <Link
                href="https://docs.snowflake.com/en/user-guide/key-pair-auth#step-4-assign-the-public-key-to-a-snowflake-user"
                underline="always"
                target="_blank"
              >
                assign it
              </Link>{' '}
              to the Snowflake user configured above.
            </>
          }
          onChange={newValue =>
            onChange({
              ...value,
              authenticationKey: newValue,
            } as MetricsV1SnowflakeConfigInput)
          }
        />

        <Collapse in={validation.loading} unmountOnExit>
          <Box display="flex" alignItems="center" gridGap={8}>
            <CircularProgress size="2em" />
            <Typography color="textSecondary">
              Getting available options from Snowflake...
            </Typography>
          </Box>
        </Collapse>

        {validation.error && (
          <>
            <Alert
              severity="error"
              action={
                canValidate && <Button onClick={() => validate()}>Retry</Button>
              }
            >
              <AlertTitle>Validation failed</AlertTitle>
              {validation.error.message}
            </Alert>
          </>
        )}

        <TextField
          label="Role"
          select
          required
          value={role}
          disabled={
            !canValidate || validation.loading || availableRoles.length === 0
          }
          error={!!validation.error}
          onChange={handleTextFieldChange('role')}
          helperText="The role to use for running queries."
          variant="outlined"
        >
          <MenuItem value="">Select role</MenuItem>
          {availableRoles.map(availableRole => (
            <MenuItem key={availableRole} value={availableRole}>
              {availableRole}
            </MenuItem>
          ))}
        </TextField>

        <TextField
          label="Warehouse"
          select
          required
          value={warehouse}
          disabled={
            !canValidate ||
            validation.loading ||
            availableWarehouses.length === 0
          }
          error={!!validation.error}
          onChange={handleTextFieldChange('warehouse')}
          helperText="The warehouse to use for running queries."
          variant="outlined"
        >
          <MenuItem value="">Select warehouse</MenuItem>
          {availableWarehouses.map(availableWarehouse => (
            <MenuItem key={availableWarehouse} value={availableWarehouse}>
              {availableWarehouse}
            </MenuItem>
          ))}
        </TextField>

        <TextField
          label="Exposure database"
          select
          required
          value={exposureDatabase}
          disabled={
            !canValidate ||
            validation.loading ||
            availableDatabases.length === 0
          }
          error={!!validation.error}
          onChange={handleTextFieldChange('exposureDatabase')}
          helperText="The database where Confidence will store the exposure data."
          variant="outlined"
        >
          <MenuItem value="">Select database</MenuItem>
          {availableDatabases.map(availableDatabase => (
            <MenuItem key={availableDatabase} value={availableDatabase}>
              {availableDatabase}
            </MenuItem>
          ))}
        </TextField>

        <TextField
          label="Exposure schema"
          select
          required
          value={exposureSchema}
          disabled={
            !canValidate || validation.loading || availableSchemas.length === 0
          }
          error={!!validation.error}
          onChange={handleTextFieldChange('exposureSchema')}
          helperText="The schema where Confidence will store the exposure data."
          variant="outlined"
        >
          <MenuItem value="">Select schema</MenuItem>
          {availableSchemas.map(availableSchema => (
            <MenuItem key={availableSchema} value={availableSchema}>
              {availableSchema}
            </MenuItem>
          ))}
        </TextField>
      </FormFieldsBox>
    </>
  );
};
