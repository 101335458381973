import React from 'react';
import { Link } from 'react-router-dom';

import { Box, Button, Typography, makeStyles } from '@material-ui/core';
import OpenInNew from '@material-ui/icons/OpenInNew';
import { Alert, AlertTitle } from '@material-ui/lab';

import classNames from 'classnames';

export type AutocompleteEmptyStateProps = {
  title?: string;
  description?: React.ReactNode;
  actionLabel?: string;
  actionLink?: string;
  fullWidth?: boolean;
};

const useStyles = makeStyles(theme => ({
  alert: {
    padding: theme.spacing(2),
  },
  alertMessage: {
    width: '100%',
  },
}));

export const AutocompleteEmptyState = ({
  fullWidth,
  title = 'There are no available options',
  description,
  actionLabel = 'Create',
  actionLink,
}: AutocompleteEmptyStateProps) => {
  const classes = useStyles();

  return (
    <Box marginTop={1}>
      <Alert
        severity="info"
        classes={{
          root: classes.alert,
          message: classNames({
            [classes.alertMessage]: fullWidth,
          }),
        }}
      >
        <AlertTitle>{title}</AlertTitle>
        {description && (
          <Typography variant="body2" color="textSecondary">
            {description}
          </Typography>
        )}
        {actionLink && (
          <Box marginTop={4} display="flex" gridColumnGap="1em">
            <Button
              size="small"
              component={Link}
              to={actionLink}
              target="_blank"
              startIcon={<OpenInNew />}
              variant="outlined"
            >
              {actionLabel}
            </Button>
          </Box>
        )}
      </Alert>
    </Box>
  );
};
