import React from 'react';

import { TextField } from '@material-ui/core';

import { FormFieldsBox } from '@spotify-confidence/core-react';
import { ConnectorsV1DatabricksConfigInput } from '@spotify-confidence/plugin-graphql';

import { BatchFileConfigForm } from '../BatchFileConfigForm';
import { S3BucketConfigForm } from '../S3BucketConfigForm';
import { DatabricksConnectionConfigForm } from './DatabricksConnectionConfigForm';

type DatabricksConfigFormProps = {
  value: ConnectorsV1DatabricksConfigInput;
  onChange: (conf: ConnectorsV1DatabricksConfigInput) => void;
  disabled?: boolean;
  schemaHelperText?: string;
};

export const DatabricksConfigForm = ({
  value,
  onChange,
  disabled,
  schemaHelperText = 'The name of the Databricks Schema/Catalogue',
}: DatabricksConfigFormProps) => {
  const handleConfigChange = (
    newValue: Partial<ConnectorsV1DatabricksConfigInput>,
  ) => {
    onChange({
      ...value,
      ...newValue,
    });
  };

  return (
    <FormFieldsBox>
      <TextField
        disabled={disabled}
        label="Schema"
        variant="outlined"
        value={value.schema}
        required
        fullWidth
        helperText={schemaHelperText}
        onChange={e => handleConfigChange({ schema: e.target.value })}
      />
      <DatabricksConnectionConfigForm
        disabled={disabled}
        value={value.connectionConfig}
        onChange={newConnectionConfig => {
          handleConfigChange({
            connectionConfig: newConnectionConfig,
          });
        }}
      />
      {value.s3BucketConfig && (
        <S3BucketConfigForm
          disabled={disabled}
          value={value.s3BucketConfig}
          onChange={newS3BucketConfig =>
            handleConfigChange({
              s3BucketConfig: newS3BucketConfig,
            })
          }
        />
      )}
      <BatchFileConfigForm
        disabled={disabled}
        value={value.batchFileConfig}
        onChange={newBatchFileConfig =>
          handleConfigChange({
            batchFileConfig: newBatchFileConfig,
          })
        }
      />
    </FormFieldsBox>
  );
};
