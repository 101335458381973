import React from 'react';

import { Box, Typography } from '@material-ui/core';

import { TagsInput } from '@spotify-confidence/core-react';
import {
  CommentZone,
  CommentZoneContext,
} from '@spotify-confidence/plugin-comments-react';
import { AllocationFragment } from '@spotify-confidence/plugin-graphql';

import { defaultAllocation } from '../domain/segment.model';
import { ReadOnlyTags } from './ReadOnlyTags';

export const ExclusivityTags = ({
  readOnly = false,
  allocation,
  onAllocationChange,
  exclusivityTagSuggestions = [],
}: {
  readOnly?: boolean;
  allocation?: Maybe<AllocationFragment>;
  onAllocationChange: (allocation: AllocationFragment) => void;
  exclusivityTagSuggestions?: string[];
}) => {
  const handleChange = React.useCallback(
    (update: Partial<AllocationFragment>) => {
      onAllocationChange({
        ...defaultAllocation,
        ...allocation,
        ...update,
      });
    },
    [allocation, onAllocationChange],
  );

  return (
    <Box
      display="flex"
      flexDirection="column"
      justifyContent="space-between"
      height="100%"
    >
      <CommentZoneContext zone="exclusivity-tags">
        <Box mb={1}>
          <Typography variant="h6" component="label" htmlFor="exclusivity-tags">
            <CommentZone id="title" component="span">
              Exclusivity tags
            </CommentZone>
          </Typography>
          <Typography variant="body1" color="textSecondary">
            Tags allowing others to target this segment in their exclusion
            criteria.
          </Typography>
        </Box>
        <CommentZone id="value">
          {readOnly ? (
            <ReadOnlyTags tags={allocation?.exclusivityTags} />
          ) : (
            <TagsInput
              id="exclusivity-tags"
              placeholder="Add tag"
              disabled={readOnly}
              freeSolo
              autoSelect
              value={allocation?.exclusivityTags}
              options={exclusivityTagSuggestions}
              onChange={(_e, exclusivityTags = []) =>
                handleChange({ exclusivityTags })
              }
            />
          )}
        </CommentZone>
      </CommentZoneContext>
    </Box>
  );
};
