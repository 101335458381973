import React from 'react';

import {
  Box,
  DialogActions,
  IconButton,
  TextField,
  Tooltip,
  Typography,
} from '@material-ui/core';
import CalendarTodayOutlined from '@material-ui/icons/CalendarTodayOutlined';
import EditIcon from '@material-ui/icons/Edit';
import WarningIcon from '@material-ui/icons/Warning';
import { Alert } from '@material-ui/lab';
import { DatePicker, MuiPickersUtilsProvider } from '@material-ui/pickers';

import DateFnsUtils from '@date-io/date-fns';
import {
  DialogBody,
  DialogForm,
  DialogHeader,
  FormFieldsBox,
  FormSubmitButtons,
  SidebarSection,
  useDialog,
} from '@spotify-confidence/core-react';
import { CommentZone } from '@spotify-confidence/plugin-comments-react';
import { useUpdatePlanningMutation } from '@spotify-confidence/plugin-graphql';
import { endOfDay, format, isBefore, startOfDay } from 'date-fns';

import { PlanningModule, PlanningPeriod } from './types';

const simpleFormat = (date: Date) => format(date, 'yyyy-MM-dd');

const EditPlanningDialog = ({
  planningData,
  workflowInstanceName,
}: {
  workflowInstanceName: string;
  planningData?: PlanningModule;
}) => {
  const { closeDialog } = useDialog();
  const [update, { loading, error }] = useUpdatePlanningMutation();
  const [planned, setPlanned] = React.useState({
    startTime: planningData?.planned?.startTime,
    endTime: planningData?.planned?.endTime,
  });

  const handleSave = () => {
    update({
      onCompleted: () => {
        closeDialog();
      },
      variables: {
        workflowInstance: {
          name: workflowInstanceName,
          moduleData: [
            {
              key: 'planning',
              value: {
                planned,
              },
            },
          ],
        },
      },
    });
  };

  const handleDateChange = (field: keyof PlanningPeriod) => {
    const timeParser = field === 'endTime' ? endOfDay : startOfDay;
    return (date: Date | null) => {
      try {
        const payload = date ? timeParser(date).toISOString() : null;
        if (payload) {
          setPlanned({
            ...planned,
            [field]: payload,
          });
        }
      } catch (e) {
        // eslint-disable-next-line no-console
        console.log({ e });
      }
    };
  };
  return (
    <MuiPickersUtilsProvider utils={DateFnsUtils}>
      <DialogForm
        onSubmit={e => {
          e.preventDefault();
          handleSave();
        }}
      >
        <DialogHeader title="Configuration" />
        <DialogBody>
          <FormFieldsBox>
            {error && <Alert severity="error">{error.message}</Alert>}
            <Box>
              <DatePicker
                format="yyyy-MM-dd"
                label="Planned start date"
                autoOk
                emptyLabel="-"
                minDate={new Date()}
                value={planned.startTime || null}
                onChange={handleDateChange('startTime')}
                fullWidth
                TextFieldComponent={props => (
                  <TextField
                    {...props}
                    variant="outlined"
                    InputProps={{
                      endAdornment: (
                        <IconButton size="small">
                          <CalendarTodayOutlined fontSize="small" />
                        </IconButton>
                      ),
                    }}
                  />
                )}
              />
            </Box>
            <Box>
              <DatePicker
                format="yyyy-MM-dd"
                label="Planned end date"
                autoOk
                emptyLabel="-"
                value={planned.endTime || null}
                minDate={
                  planned?.startTime ? new Date(planned?.startTime) : undefined
                }
                onChange={handleDateChange('endTime')}
                fullWidth
                TextFieldComponent={props => (
                  <TextField
                    {...props}
                    variant="outlined"
                    InputProps={{
                      endAdornment: (
                        <IconButton size="small">
                          <CalendarTodayOutlined fontSize="small" />
                        </IconButton>
                      ),
                    }}
                  />
                )}
              />
            </Box>
          </FormFieldsBox>
        </DialogBody>
        <DialogActions>
          <FormSubmitButtons onCancel={() => closeDialog()} loading={loading} />
        </DialogActions>
      </DialogForm>
    </MuiPickersUtilsProvider>
  );
};

type PlanningSidebarSectionProps = {
  planningData?: PlanningModule;
  disabled?: boolean;
  workflowInstanceName: string;
};

const plannedDatesToStrng = (planned?: PlanningPeriod) => {
  if (!planned) return 'Not set';
  return `${
    planned.startTime
      ? simpleFormat(new Date(planned.startTime))
      : 'No start date'
  } → ${
    planned.endTime ? simpleFormat(new Date(planned.endTime)) : 'No end date'
  }`;
};

const actualDatesToStrng = (planned?: PlanningPeriod) => {
  return `${
    planned?.startTime
      ? simpleFormat(new Date(planned?.startTime))
      : 'Not started'
  } → ${
    planned?.endTime
      ? simpleFormat(new Date(planned?.endTime))
      : 'Still running'
  }`;
};

export const PlanningSidebarSection = ({
  planningData,
  disabled,
  workflowInstanceName,
}: PlanningSidebarSectionProps) => {
  const { openDialog } = useDialog();

  const handleEdit = () => {
    openDialog({
      content: (
        <EditPlanningDialog
          workflowInstanceName={workflowInstanceName}
          planningData={planningData}
        />
      ),
    });
  };

  const now = startOfDay(new Date());

  const showStartDateWarning =
    !disabled &&
    planningData?.planned?.startTime &&
    isBefore(new Date(planningData?.planned?.startTime), now);

  return (
    <SidebarSection
      title="Planning"
      zoneContext="planning"
      action={
        !disabled && (
          <IconButton
            disabled={disabled}
            size="small"
            title="Edit"
            onClick={handleEdit}
          >
            <EditIcon fontSize="small" />
          </IconButton>
        )
      }
    >
      <Box display="flex" justifyContent="space-between" mb={1}>
        <Typography variant="body2" color="textSecondary">
          Planned runtime
        </Typography>
        <CommentZone id="planned">
          <Box display="inline-flex" alignItems="center" gridGap={1}>
            <Typography variant="body2" align="right">
              {plannedDatesToStrng(planningData?.planned)}
            </Typography>
            {showStartDateWarning && (
              <Tooltip title="The planned start date is before the current date">
                <WarningIcon color="error" fontSize="small" />
              </Tooltip>
            )}
          </Box>
        </CommentZone>
      </Box>
      {planningData?.actual && (
        <Box display="flex" justifyContent="space-between" mb={1}>
          <Typography variant="body2" color="textSecondary">
            Actual runtime
          </Typography>
          <CommentZone id="actual">
            <Typography variant="body2" align="right">
              {actualDatesToStrng(planningData.actual)}
            </Typography>
          </CommentZone>
        </Box>
      )}
    </SidebarSection>
  );
};
