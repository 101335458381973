import React from 'react';
import { useParams, useSearchParams } from 'react-router-dom';

import { LinearProgress, Typography } from '@material-ui/core';

import {
  Breadcrumbs,
  PageLayout,
  createHeaderTab,
} from '@spotify-confidence/core-react';
import {
  getError,
  isError,
  useGetWorkflowDetailsQuery,
} from '@spotify-confidence/plugin-graphql';

import {
  HeaderTabs,
  Link,
  Tab,
  WarningPanel,
} from '@backstage/core-components';
import { useRouteRef } from '@backstage/core-plugin-api';

import {
  workflowInstancesRouteRef,
  workflowLogsRouteRef,
  workflowRouteRef,
  workflowsRouteRef,
} from '../../../routes';
import { WorkflowInstances } from './WorkflowInstances';
import { WorkflowLogs } from './WorkflowLogs';
import { WorkflowVersions } from './WorkflowVersions';

const useTabs = ({ id }: { id: string }) => {
  const [searchParams] = useSearchParams();
  const buildUrl = (base: string) => `${base}?${searchParams.toString()}`;

  const workflowRoute = useRouteRef(workflowRouteRef);
  const workflowInstancesRoute = useRouteRef(workflowInstancesRouteRef);
  const workflowLogsRoute = useRouteRef(workflowLogsRouteRef);

  const tabs: Tab[] = [
    createHeaderTab({
      id: 'overview',
      label: 'Overview',
      to: buildUrl(workflowRoute({ id })),
    }),
    createHeaderTab({
      id: 'instances',
      label: 'Instances',
      to: buildUrl(workflowInstancesRoute({ id })),
    }),
    createHeaderTab({
      id: 'logs',
      label: 'Logs',
      to: buildUrl(workflowLogsRoute({ id })),
    }),
  ];

  // eslint-disable-next-line no-nested-ternary
  const selectedIndex = location.pathname.includes('instances')
    ? tabs.findIndex(tab => tab.id === 'instances')
    : location.pathname.includes('logs')
    ? tabs.findIndex(tab => tab.id === 'logs')
    : 0;
  const selectedTabId = tabs[selectedIndex].id;

  return {
    tabs,
    selectedTabId,
    selectedIndex,
  };
};

export const WorkflowPage = ({}) => {
  const { id } = useParams();
  const workflowName = `workflows/${id}`;
  const workflowsRoute = useRouteRef(workflowsRouteRef);

  const { tabs, selectedTabId, selectedIndex } = useTabs({ id: id! });

  const { data, loading, error } = useGetWorkflowDetailsQuery({
    variables: { name: workflowName },
  });

  if (loading || !data) {
    return <LinearProgress />;
  }

  if (error || isError(data.workflow)) {
    return (
      <WarningPanel
        title="Could not load data"
        message={getError(data.workflow)?.message || error?.message}
      />
    );
  }

  return (
    <PageLayout
      narrow
      title={data.workflow?.displayName}
      headerBreadcrumbs={
        <Breadcrumbs>
          <Link to={workflowsRoute()}>Workflows</Link>
          <Typography>{data.workflow?.displayName}</Typography>
        </Breadcrumbs>
      }
      headerTabs={<HeaderTabs selectedIndex={selectedIndex} tabs={tabs} />}
    >
      {selectedTabId === 'overview' && (
        <WorkflowVersions workflowName={workflowName} />
      )}
      {selectedTabId === 'instances' && (
        <WorkflowInstances workflowName={workflowName} />
      )}
      {selectedTabId === 'logs' && <WorkflowLogs workflowName={workflowName} />}
    </PageLayout>
  );
};
