import {
  DraftEventFragment,
  EventDefinitionFragment,
  EventDefinitionFragmentDoc,
  EventSchemaEntryFragment,
  EventsAdminV1EventDefinitionSchemaEntryInput,
  EventsAdminV1EventSchemaInput,
  getError,
  getTypeOrNull,
  isError,
  useEventQuery,
  useOnEventDefinitionUpdatedSubscription,
  useUpdateEventDefinitionMutation,
} from '@spotify-confidence/plugin-graphql';

import { isDifferent } from './useDraftFields';

export type EventOptions = {
  name: string;
};

export function useEvent({ name }: EventOptions) {
  const draftFilter = `reason:EVENT_DEFINITION_UNKNOWN_FIELDS AND eventDefinition:"${name}"`;
  const { data, error, loading } = useEventQuery({
    fetchPolicy: 'cache-and-network',
    variables: {
      name,
      draftFilter,
    },
    onCompleted: result => {
      if (isError(result.queryDraftEvents)) {
        throw getError(result.queryDraftEvents);
      }
      if (isError(result.eventDefinition)) {
        throw getError(result.eventDefinition);
      }
    },
  });

  /// DRAFT SCHEMA ///

  const draftEvent = getTypeOrNull(
    data?.queryDraftEvents,
    'EventsAdminV1QueryDraftEventsResponse',
  )?.draftEvents?.[0];

  const draftSchema: EventSchemaEntryFragment[] | undefined =
    draftEvent && isDifferent(draftEvent)
      ? draftEvent.schema?.structSchema?.schema
      : undefined;

  /// EVENT ///

  const event = getTypeOrNull(
    data?.eventDefinition,
    'EventsAdminV1EventDefinition',
  );

  useOnEventDefinitionUpdatedSubscription({
    onData: options => {
      const e = options.data.data?.eventDefinitionUpdated?.eventDefinition;
      if (e) {
        const cacheId = options.client.cache.identify(e);
        if (cacheId) {
          options.client.cache.writeFragment({
            id: cacheId,
            data: e,
            fragment: EventDefinitionFragmentDoc,
            fragmentName: 'EventDefinition',
          });
        }
      }
    },
  });

  const [updateSchemaMutation] = useUpdateEventDefinitionMutation({
    onCompleted: response => {
      const e = getError(response.updateEventDefinition);
      if (e) {
        throw e;
      }
    },
    update: (cache, { data: updateData }) => {
      if (isError(updateData?.updateEventDefinition)) {
        return;
      }

      const updatedEvent = getTypeOrNull(
        updateData?.updateEventDefinition,
        'EventsAdminV1EventDefinition',
      );

      if (!updatedEvent) {
        return;
      }

      cache.modify({
        fields: {
          queryDraftEvents(response = { draftEvents: [] }) {
            const existingDraftEvents = response.draftEvents ?? [];
            const filteredDraftEvents = [...existingDraftEvents].filter(
              (de: DraftEventFragment) => {
                if (de.draftEventDefinition) {
                  // This was not a draft field
                  return true;
                }

                if (!de.eventDefinition) {
                  // should not happen, but :shrug:
                  return true;
                }

                const ev = cache.readFragment({
                  id: cache.identify(de.eventDefinition),
                  fragment: EventDefinitionFragmentDoc,
                  fragmentName: 'EventDefinition',
                }) as EventDefinitionFragment | null;

                return updatedEvent.name !== ev?.name;
              },
            );

            return {
              ...response,
              draftEvents: filteredDraftEvents,
            };
          },
        },
      });
    },
  });

  const updateSchema = async ({
    schema,
  }: {
    schema: EventSchemaEntryFragment[];
  }) => {
    const schemaInput = toEventSchemaInput(schema);
    const result = await updateSchemaMutation({
      variables: {
        event: {
          name,
          schema: schemaInput,
        },
        updateMask: 'schema',
      },
    });

    if (isError(result.data?.updateEventDefinition)) {
      throw getError(result.data?.updateEventDefinition);
    }

    return getTypeOrNull(
      result.data?.updateEventDefinition,
      'EventsAdminV1EventDefinition',
    );
  };

  return {
    event,
    loading,
    error,
    draftSchema,
    updateSchema,
  };
}

export function toEventSchemaInput(
  schema: EventSchemaEntryFragment[],
): EventsAdminV1EventDefinitionSchemaEntryInput[] {
  const _toInput = (
    schemaValue: EventSchemaEntryFragment['value'],
  ): EventsAdminV1EventSchemaInput => {
    if (schemaValue === null) return {};
    if (schemaValue?.stringSchema?.semanticType?.entityReference) {
      return {
        stringSchema: {
          ...schemaValue.stringSchema,
          semanticType: {
            entityReference: {
              entity:
                getTypeOrNull(
                  schemaValue?.stringSchema?.semanticType?.entityReference
                    .entity,
                  'MetricsV1Entity',
                )?.name ?? 'unknown',
            },
          },
        },
      } satisfies EventsAdminV1EventSchemaInput;
    }

    if (schemaValue?.listSchema) {
      return {
        listSchema: {
          ...schemaValue.listSchema,
          elementSchema: _toInput(schemaValue.listSchema.elementSchema),
        },
      } satisfies EventsAdminV1EventSchemaInput;
    }

    if (schemaValue?.structSchema) {
      return {
        structSchema: {
          ...schemaValue.structSchema,
          schema: schemaValue.structSchema.schema.map(s => ({
            ...s,
            value: _toInput(s.value),
          })),
        },
      } satisfies EventsAdminV1EventSchemaInput;
    }

    return schemaValue as EventsAdminV1EventSchemaInput;
  };
  const schemaInput = schema.map(s => ({
    ...s,
    value: _toInput(s.value),
  }));
  return schemaInput;
}
