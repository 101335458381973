import React from 'react';

import { Box, LinearProgress } from '@material-ui/core';

export const PageLoadLinearProgress = () => {
  return (
    <Box style={{ marginTop: 65 }}>
      <LinearProgress />
    </Box>
  );
};
