import React from 'react';

import { styled } from '@material-ui/core';

import { NumberUtils } from '../../utils';
import { Numeral, NumeralProps } from '../Numeral';

type CommonTooltipProps = {
  lowerCI?: number;
  upperCI?: number;
  unit?: string;
};

type BaseTooltipProps = CommonTooltipProps & {
  formatter?: NumeralProps['formatter'];
};

const Grid = styled('div')(({ theme }) => ({
  display: 'grid',
  columnGap: theme.spacing(1),
  gridTemplateColumns: 'auto auto',
  ...theme.typography.body2,
}));

const Value = styled(Numeral)({
  justifySelf: 'end',
});

export function BaseTooltip({
  lowerCI,
  upperCI,
  unit,
  formatter = NumberUtils.formatNumberFixed2,
}: BaseTooltipProps) {
  return (
    <Grid>
      <span>Lower bound: </span>
      <Value
        value={lowerCI}
        formatter={formatter}
        suffix={unit}
        type="tabular"
      />

      <span>Upper bound: </span>
      <Value
        value={upperCI}
        formatter={formatter}
        suffix={unit}
        type="tabular"
      />
    </Grid>
  );
}
