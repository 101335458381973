import loadable from '@loadable/component';

export * from './workflowPage';
export * from './workflowInstancePage';
export * from './extensions';
export * from './components';
export * from './modules';
export * from './permissions';
export * from './contexts';

const workflowComponentsChunk = () =>
  import(
    /* webpackChunkName: "workflow-shared-components" */ './components.chunk'
  );

export const WorkflowInstanceState = loadable(() =>
  workflowComponentsChunk().then(m => m.WorkflowInstanceState),
);
export const StatusIndicator = loadable(() =>
  workflowComponentsChunk().then(m => m.StatusIndicator),
);

export const ActionButtonsList = loadable(() =>
  workflowComponentsChunk().then(m => m.ActionButtonsList),
);
export const ActionButton = loadable(() =>
  workflowComponentsChunk().then(m => m.ActionButton),
);
export const CheckAlerts = loadable(() =>
  workflowComponentsChunk().then(m => m.CheckAlerts),
);
export const BackLink = loadable(() =>
  workflowComponentsChunk().then(m => m.BackLink),
);
export const StatusSidebarSection = loadable(() =>
  workflowComponentsChunk().then(m => m.StatusSidebarSection),
);
export const EditInstanceNameButton = loadable(() =>
  workflowComponentsChunk().then(m => m.EditInstanceNameButton),
);
export const EditInstanceNameMenuItem = loadable(() =>
  workflowComponentsChunk().then(m => m.EditInstanceNameMenuItem),
);

export const WorkflowInstanceTableRow = loadable(() =>
  workflowComponentsChunk().then(m => m.WorkflowInstanceTableRow),
);
export const WorkflowInstanceNameCell = loadable(() =>
  workflowComponentsChunk().then(m => m.WorkflowInstanceNameCell),
);
export const WorkflowInstanceStateCell = loadable(() =>
  workflowComponentsChunk().then(m => m.WorkflowInstanceStateCell),
);
