import React from 'react';

import { Typography } from '@material-ui/core';

import { DateUtils } from '@spotify-confidence/core-react';

type UpdateTimeProps = {
  updateTime?: string | null;
};

export const UpdateTime = ({ updateTime }: UpdateTimeProps) => {
  if (!updateTime) {
    return null;
  }
  return (
    <>
      <Typography variant="body2" color="textSecondary">
        Updated {DateUtils.xAgo(new Date(updateTime))}
      </Typography>
    </>
  );
};
