import React from 'react';

import { TextField } from '@material-ui/core';

import { FormFieldsBox, StringUtils } from '@spotify-confidence/core-react';
import {
  BigQueryConfigFragment,
  getTypeOrNull,
  useValidateBigQueryDataWarehouseConfigMutation,
} from '@spotify-confidence/plugin-graphql';

import { ValidationCheckList } from '../../../shared';

type BigQueryDatawarehouseFormProps = {
  value?: BigQueryConfigFragment | null;
  onChange: (conf: BigQueryConfigFragment) => void;
  disabled?: boolean;
};

// Use the empty validation to list checklist items
const emptyValidation = { variables: { bigQueryConfig: {} } };

export const BigQueryDatawarehouseForm = ({
  value,
  onChange,
  disabled,
}: BigQueryDatawarehouseFormProps) => {
  const [validate, validation] = useValidateBigQueryDataWarehouseConfigMutation(
    { variables: { bigQueryConfig: value || {} } },
  );

  const gcpProjectId: string = value?.gcpProjectId ?? '';
  const dataset: string = value?.dataset ?? '';
  const serviceAccount: string = value?.serviceAccount ?? '';

  const checks =
    getTypeOrNull(
      validation.data?.validateDataWarehouseConfig,
      'MetricsV1ValidateDataWarehouseConfigResponse',
    )?.validation || [];
  const hasNotValidated = checks.every(check => !check.success && !check.error);

  const validSAEmail = serviceAccount
    ? StringUtils.isValidEmail(serviceAccount)
    : true;
  const canValidate = Boolean(
    gcpProjectId && dataset && serviceAccount && validSAEmail,
  );

  React.useEffect(() => {
    // Run validate once on load to populate the checklist
    validate(emptyValidation);
  }, []);

  const handleChange =
    (field: keyof BigQueryConfigFragment) =>
    (e: React.ChangeEvent<HTMLInputElement>) => {
      onChange({ ...value, [field]: e.target.value });

      if (!e.target.value) {
        validate(emptyValidation);
      }
    };

  const handleValidate = () => {
    if (!hasNotValidated && canValidate && !validation.loading) {
      validate();
    }
  };

  const getCheckError = (key: string) => {
    return checks.find(v => v.key === key)?.error || '';
  };

  let serviceAccountErrorText = '';
  if (!validSAEmail) {
    serviceAccountErrorText =
      'The service account should be a valid email address.';
  } else if (canValidate) {
    serviceAccountErrorText =
      getCheckError('SERVICE_ACCOUNT') || getCheckError('PERMISSIONS');
  }

  return (
    <>
      <FormFieldsBox>
        <TextField
          disabled={disabled}
          label="GCP Project ID"
          variant="outlined"
          value={gcpProjectId}
          required
          fullWidth
          helperText="The ID of the GCP project where the data you want to use in Confidence resides."
          onChange={handleChange('gcpProjectId')}
          onBlur={handleValidate}
        />

        <TextField
          disabled={disabled}
          label="Service account"
          variant="outlined"
          value={serviceAccount}
          type="email"
          placeholder={`name@${
            gcpProjectId || 'project-id'
          }.iam.gserviceaccount.com`}
          required
          onChange={handleChange('serviceAccount')}
          onBlur={handleValidate}
          fullWidth
          error={!!serviceAccountErrorText}
          helperText={
            serviceAccountErrorText ||
            'The service account that will let Confidence write to and read from your Data Warehouse.'
          }
        />

        <TextField
          disabled={disabled}
          label="Dataset"
          variant="outlined"
          value={dataset}
          required
          onChange={handleChange('dataset')}
          onBlur={handleValidate}
          fullWidth
          helperText={
            getCheckError('DATASET') ||
            'The name of the dataset Confidence should use to store data.'
          }
          error={!!getCheckError('DATASET')}
        />

        <ValidationCheckList
          title="Checklist"
          loading={validation.loading}
          canValidate={canValidate}
          onValidate={validate}
          checks={checks}
        />
      </FormFieldsBox>
    </>
  );
};
