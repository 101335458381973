import React from 'react';

import { Box, BoxProps, styled } from '@material-ui/core';

const Container = styled(Box)({
  containerType: 'inline-size',
});

const Wrapper = styled(Box)(({ theme }) => ({
  display: 'flex',
  width: '100%',
  flexDirection: 'row',
  gridGap: theme.spacing(6),
  [theme.breakpoints.down('md')]: {
    flexDirection: 'column',
  },
  [`@container (max-width: ${theme.breakpoints.values.sm}px)`]: {
    flexDirection: 'column',
  },
}));

export const CardSectionWrapper = ({
  inlineContainer,
  ...props
}: React.PropsWithChildren<BoxProps & { inlineContainer?: boolean }>) =>
  inlineContainer ? (
    <Container>
      <Wrapper {...props} />
    </Container>
  ) : (
    <Wrapper {...props} />
  );
