import React from 'react';

import { Box, Typography } from '@material-ui/core';
import { Alert } from '@material-ui/lab';

import { PercentageSlider, useAlert } from '@spotify-confidence/core-react';
import {
  CommentZone,
  CommentZoneContext,
} from '@spotify-confidence/plugin-comments-react';
import {
  AllocationFragment,
  SegmentFragment,
  isType,
  useSegmentAvailableSpaceMutation,
} from '@spotify-confidence/plugin-graphql';

import { defaultAllocation } from '../../domain/segment.model';
import { targetingCodec } from '../../domain/targeting';

export const AllocationInput = ({
  segment,
  readOnly = false,
  onAllocationChange,
}: {
  readOnly?: boolean;
  segment: SegmentFragment;

  onAllocationChange: (allocation: AllocationFragment) => void;
}) => {
  const alert = useAlert();
  const { allocation, targeting } = segment;
  const {
    proportion: savedProportion,
    exclusiveTo = [],
    exclusivityTags = [],
  } = allocation ?? defaultAllocation;
  const proportion = Number(savedProportion?.value ?? 0);
  const defaultAllocationValue = Number(
    defaultAllocation.proportion.value ?? 0,
  );
  const [checkSegmentAvailableSpace] = useSegmentAvailableSpaceMutation();
  const [maxProportion, setMaxProportion] = React.useState(
    Number(defaultAllocation?.proportion?.value ?? 0),
  );

  const handleChange = React.useCallback(
    (update: Partial<AllocationFragment>) => {
      onAllocationChange({
        ...defaultAllocation,
        ...allocation,
        ...update,
      });
    },
    [allocation, onAllocationChange],
  );

  React.useEffect(() => {
    if (readOnly) {
      setMaxProportion(defaultAllocationValue);
    } else {
      checkSegmentAvailableSpace({
        variables: {
          queryParams: {
            exclusivityTags: exclusivityTags,
            exclusiveTo: exclusiveTo,
            targeting: targetingCodec.toInput(segment.targeting),
          },
        },
        onCompleted: ({ segmentAvailableSpace }) => {
          if (
            isType(
              segmentAvailableSpace,
              'FlagsAdminV1SegmentAvailableSpaceResponse',
            )
          ) {
            const value = segmentAvailableSpace?.availableSpace?.value;
            const newMaxProportion = Number(value);

            if (isNaN(newMaxProportion)) {
              setMaxProportion(defaultAllocationValue);
            } else {
              setMaxProportion(newMaxProportion);
              if (newMaxProportion < proportion) {
                handleChange({
                  proportion: {
                    value: newMaxProportion.toString(),
                  },
                });
              }
            }
          } else {
            alert.post({
              severity: 'error',
              message: segmentAvailableSpace?.message ?? 'Something went wrong',
            });
          }
        },
      });
    }
  }, [exclusivityTags, exclusiveTo, targeting, readOnly]);

  return (
    <Box
      display="flex"
      flexDirection="column"
      justifyContent="space-between"
      height="100%"
    >
      <CommentZoneContext zone="allocation">
        <div>
          <Typography variant="h6" component="label" htmlFor="allocation">
            <CommentZone id="title" component="span">
              Allocation
            </CommentZone>
          </Typography>
          <Typography variant="body1" color="textSecondary">
            Limits the allocation to a percentage of valid targets
            {maxProportion < 1 && (
              <span>
                {' '}
                (max {maxProportion * 100}% based on current inclusion and
                exclusion criteria)
              </span>
            )}
            .
          </Typography>
        </div>
        <div>
          <CommentZone id="value">
            {maxProportion > 0 ? (
              <PercentageSlider
                id="allocation"
                name="allocation-input"
                disabled={readOnly}
                value={proportion}
                maxValue={1}
                minLimit={0.0001}
                maxLimit={maxProportion}
                onChange={newProportion =>
                  handleChange({
                    proportion: {
                      value: newProportion.toString(),
                    },
                  })
                }
              />
            ) : (
              <Alert severity="warning">
                No space available to allocate the segment. Adjust exclusivity
                and/or targeting filter to be able to allocate this segment.
              </Alert>
            )}
          </CommentZone>
        </div>
      </CommentZoneContext>
    </Box>
  );
};
