import {
  FlagsAdminV1ContextFieldSemanticTypeCountrySemanticTypeCountryFormat as CountryFormat,
  FlagsAdminV1EvaluationContextSchemaFieldKind as Kind,
  SemanticTypeFragment,
  FlagsAdminV1ContextFieldSemanticTypeInput as SemanticTypeInput,
} from '@spotify-confidence/plugin-graphql';
import _ from 'lodash';

export type SemanticTypeOption = keyof SemanticTypeFragment;
export type ValueType = Kind | SemanticTypeOption;

export const semanticTypeOptions: SemanticTypeOption[] = [
  'country',
  'entityReference',
  'enumType',
  'version',
  'timestamp',
  'date',
];

// The value to set in the override when a certain type is selected
export const valueTypeMap: Record<
  ValueType,
  {
    kind: Kind;
    semanticType: SemanticTypeInput;
  }
> = {
  NULL_KIND: {
    kind: Kind.NullKind,
    semanticType: {},
  },
  NUMBER_KIND: {
    kind: Kind.NumberKind,
    semanticType: {},
  },
  BOOL_KIND: {
    kind: Kind.BoolKind,
    semanticType: {},
  },
  STRING_KIND: {
    kind: Kind.StringKind,
    semanticType: {},
  },
  country: {
    kind: Kind.StringKind,
    semanticType: {
      country: {
        // We currently only support one format so we select it by default
        format: CountryFormat.TwoLetterIsoCode,
      },
    },
  },
  entityReference: {
    kind: Kind.StringKind,
    semanticType: {
      entityReference: {
        entity: '',
      },
    },
  },
  enumType: {
    kind: Kind.StringKind,
    semanticType: {
      enumType: {
        values: [],
      },
    },
  },
  version: {
    kind: Kind.StringKind,
    semanticType: {
      version: {},
    },
  },
  timestamp: {
    kind: Kind.StringKind,
    semanticType: {
      timestamp: {},
    },
  },
  date: {
    kind: Kind.StringKind,
    semanticType: {
      date: {},
    },
  },
};

export const getSemanticKind = (
  value?: Maybe<SemanticTypeInput> | SemanticTypeFragment,
): SemanticTypeOption | undefined => {
  if (value?.country) {
    return 'country';
  }
  if (value?.entityReference) {
    return 'entityReference';
  }
  if (value?.enumType) {
    return 'enumType';
  }
  if (value?.version) {
    return 'version';
  }
  if (value?.timestamp) {
    return 'timestamp';
  }
  if (value?.date) {
    return 'date';
  }
  return undefined;
};

export const getSemanticValueType = (value?: SemanticTypeInput): ValueType => {
  return getSemanticKind(value) || Kind.StringKind;
};

export const formatKind = (kind: Kind): string => {
  return _.upperFirst(kind.split('_')[0].toLocaleLowerCase());
};

export const formatSemanticType = (
  semanticType: SemanticTypeOption,
): string => {
  if (semanticType === 'entityReference') {
    return 'Entity';
  }
  if (semanticType === 'enumType') {
    return 'Enum';
  }
  if (semanticType === 'country') {
    return 'Country code';
  }
  return _.upperFirst(_.startCase(semanticType));
};
