import {
  createPlugin,
  createRoutableExtension,
} from '@backstage/core-plugin-api';

import { insightsRouteRef, rootRouteRef } from './routes';

export const insightsPlugin = createPlugin({
  id: 'insights',
  routes: {
    root: rootRouteRef,
    insights: insightsRouteRef,
  },
});

export const ConfidenceInsightsPage = insightsPlugin.provide(
  createRoutableExtension({
    name: 'ConfidenceInsightsPage',
    component: () =>
      import(
        /* webpackChunkName: "confidence-insights", webpackPrefetch: 10 */ './Router'
      ).then(m => m.Router),
    mountPoint: rootRouteRef,
  }),
);
