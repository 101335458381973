import React from 'react';

import {
  Box,
  IconButton,
  Typography,
  createStyles,
  makeStyles,
} from '@material-ui/core';

import {
  BalanceIcon,
  InlineVariant,
  PercentageSlider,
  WeightError,
  WeightedItem,
} from '@spotify-confidence/core-react';

const weightErrorLabels: Record<WeightError, string> = {
  [WeightError.MAX_TOTAL_EXCEEDED]: 'Sizes add up to more than 100%',
  [WeightError.MAX_TOTAL_NOT_REACHED]: 'Sizes should add upp to 100%',
  [WeightError.LESS_THAN_0]: 'Sizes need to be positive',
};

const useStyles = makeStyles(theme =>
  createStyles({
    root: {
      display: 'flex',
      alignItems: 'flex-start',
      gap: theme.spacing(1),

      ...theme.typography.body1,
    },
    name: {
      fontWeight: 'bold',
    },
    prefix: {
      display: 'flex',
      justifyContent: 'flex-end',
      alignItems: 'center',

      width: theme.spacing(9),
      height: theme.spacing(5),

      fontWeight: 500,
    },
    bars: {
      display: 'flex',
      alignItems: 'center',
      width: theme.spacing(50),
      height: theme.spacing(5),
      marginLeft: theme.spacing(2),
    },
    helper: {
      display: 'flex',
      alignItems: 'center',
      height: theme.spacing(5),

      ...theme.typography.body2,
      color: theme.palette.text.hint,
    },
    error: {
      color: theme.palette.error.main,
    },
  }),
);

function defaultRenderVariant<T extends WeightedItem>(
  _item: T,
  index: number,
): React.ReactNode {
  return `Variant ${index}`;
}

type Props<T extends WeightedItem> = {
  maxTotal: number;
  value?: T[];
  onItemWeightChange?: (index: number) => (weight: number) => void;
  splitWeightEvenly?: () => void;
  disabled?: boolean;
  title?: string;
  renderVariant?: (item: T, index: number) => React.ReactNode;
  getVariantColor?: (item: T, index: number) => string | undefined;
  error?: WeightError;
};

export function VariantWeightInput<T extends WeightedItem = WeightedItem>({
  maxTotal,
  onItemWeightChange,
  value = [],
  splitWeightEvenly,
  disabled = false,
  renderVariant = defaultRenderVariant,
  getVariantColor,
  title,
  error,
}: Props<T>) {
  const classes = useStyles();

  return (
    <>
      <Box display="flex" justifyContent="space-between" alignItems="center">
        {title && <Typography>{title}</Typography>}
        {value.length > 1 && splitWeightEvenly && (
          <IconButton title="Split evenly" onClick={splitWeightEvenly}>
            <BalanceIcon />
          </IconButton>
        )}
      </Box>
      {value.length === 0 && (
        <Box>
          <Typography className={classes.helper} variant="inherit">
            Add at least one variant to get started.
          </Typography>
        </Box>
      )}

      {value.map((variant, index) => (
        <Box
          key={`variant_${index}`}
          display="flex"
          alignItems="center"
          marginBottom={1}
        >
          <Box flex={1}>
            <InlineVariant
              color={getVariantColor?.(variant, index)}
              label={renderVariant(variant, index)}
              classes={{ typography: classes.name }}
            />
          </Box>
          <Box flex={1}>
            <PercentageSlider
              name="variant-weight-input"
              value={variant.weight || 0}
              disabled={disabled}
              maxValue={maxTotal}
              onChange={onItemWeightChange?.(index)}
              error={!!error}
            />
          </Box>
        </Box>
      ))}

      {error && (
        <Box padding={3} paddingBottom={0}>
          <Typography variant="body2" color="error" align="right">
            {weightErrorLabels[error]}
          </Typography>
        </Box>
      )}
    </>
  );
}
