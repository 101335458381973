import React from 'react';

import { Chip, ChipProps } from '@material-ui/core';
import { Autocomplete, AutocompleteProps } from '@material-ui/lab';

export const FilterAutocomplete = <
  T,
  Multiple extends boolean | undefined,
  DisableClearable extends boolean | undefined,
  FreeSolo extends boolean | undefined,
>(
  props: Omit<
    AutocompleteProps<T, Multiple, DisableClearable, FreeSolo>,
    'getOptionLabel'
  > & {
    getOptionLabel: (v: T) => string;
  },
) => {
  return (
    <Autocomplete
      {...props}
      renderTags={(t, getTagProps) =>
        t.map((option: T, index: number) => {
          const {
            onDelete,
            disabled: tagDisabled,
            ...tagProps
          }: ChipProps = getTagProps({ index });
          return (
            <Chip
              {...tagProps}
              size="small"
              label={props.getOptionLabel(option)}
              onDelete={tagDisabled ? undefined : onDelete}
            />
          );
        })
      }
      style={{ width: 300 }}
    />
  );
};
