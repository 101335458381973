import { isError } from '@spotify-confidence/plugin-graphql';

export const hasRelation = (
  resource?: Maybe<{
    resourceRelations?:
      | Maybe<{
          __typename: 'FgaV1ListResourceRelationsResponse';
          resourceRelations?: { relation: string }[];
        }>
      | { __typename: 'Error' };
  }>,
  relation: string = '',
): boolean => {
  if (!resource) return false;
  if (isError(resource?.resourceRelations)) return false;
  return (
    resource?.resourceRelations?.resourceRelations?.some(
      r => r.relation === relation,
    ) ?? false
  );
};
