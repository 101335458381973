import React from 'react';

import { TableCell, TableRow } from '@material-ui/core';

import {
  GroupingState,
  Header,
  HeaderGroup,
  flexRender,
} from '@tanstack/react-table';

import { getHeadersExcludingGroupTitle } from './tableUtils';
import { ResultData, TableCellMeta } from './types';

export const FooterCell = ({
  header,
}: {
  header: Header<ResultData, unknown>;
}) => {
  const { disableComments, ...metaTableCellProps } = (header.column.columnDef
    .meta || {}) as TableCellMeta;
  const content = header.isPlaceholder
    ? null
    : flexRender(header.column.columnDef.footer, header.getContext());
  return (
    <TableCell
      colSpan={header.colSpan}
      padding={content ? 'normal' : 'none'}
      {...metaTableCellProps}
    >
      {content}
    </TableCell>
  );
};

export const FooterRow = ({
  headerGroup,
  className,
  grouping,
}: {
  headerGroup: HeaderGroup<ResultData>;
  grouping?: GroupingState;
  className?: string;
}) => {
  return (
    <TableRow key={headerGroup.id} className={className}>
      {getHeadersExcludingGroupTitle(headerGroup.headers, grouping).map(
        header => {
          return <FooterCell key={header.id} header={header} />;
        },
      )}
    </TableRow>
  );
};
