import { colors } from '@material-ui/core';

type ColorPalette = keyof typeof colors.amber;

const COLOR_MAP = [
  colors.amber,
  colors.blue,
  colors.blueGrey,
  colors.brown,
  colors.cyan,
  colors.deepOrange,
  colors.deepPurple,
  colors.green,
  colors.indigo,
  colors.lightGreen,
  colors.lightBlue,
  colors.lime,
  colors.orange,
  colors.pink,
  colors.purple,
  colors.red,
  colors.teal,
  colors.yellow,
];

export const colorList = Object.values(COLOR_MAP.map(v => v[400]));

const SHADES: ColorPalette[] = [300, 400, 500, 600, 700, 800, 900];

export const stringToColor = (str?: string) => {
  if (!str) return undefined;
  let hash = 0;
  for (let i = 0; i < str.length; i++) {
    hash = str.charCodeAt(i) + ((hash << 5) - hash);
  }

  const colorIndex = Math.abs(hash) % COLOR_MAP.length;
  const palette: Record<ColorPalette, string> = COLOR_MAP[colorIndex];
  const colorShade = SHADES[Math.abs(hash) % SHADES.length];
  const color = palette[colorShade];
  return color;
};
