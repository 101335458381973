import React from 'react';

import {
  CardContent,
  List,
  ListItem,
  ListItemText,
  ListSubheader,
  Popover,
  makeStyles,
  styled,
} from '@material-ui/core';
import ErrorIcon from '@material-ui/icons/Error';
import InfoIcon from '@material-ui/icons/Info';
import WarningIcon from '@material-ui/icons/Warning';

import { Annotation } from '../types';
import { getAnnotationContextValue } from '../utils';
import { VariantValue } from './VariantValue';

type AnnotationsSummaryProps = {
  annotations?: Annotation[];
};

const useStyles = makeStyles(theme => ({
  popover: {
    pointerEvents: 'none',
  },
  symbol: {
    '&:hover': {
      opacity: theme.palette.action.hoverOpacity,
    },
  },
  subList: {
    '&:not(:first-child)': {
      marginTop: theme.spacing(2),
      borderTop: `1px solid ${theme.palette.divider}`,
    },
  },
  listSubheader: {
    display: 'inline-flex',
    gap: theme.spacing(1),
    alignItems: 'center',
  },
}));

export const ErrorSymbol = styled(ErrorIcon)(({ theme }) => ({
  color: theme.palette.error.main,
}));

const WarningSymbol = styled(WarningIcon)(({ theme }) => ({
  color: theme.palette.warning.main,
}));

const InfoSymbol = styled(InfoIcon)(({ theme }) => ({
  color: theme.palette.text.disabled,
}));

function getAnnotationId(annotation: Annotation) {
  let status = 'info';
  if (annotation.error) {
    status = 'error';
  } else if (annotation.warning) {
    status = 'warning';
  }
  const context = annotation.context?.map(a => a.value) || [];
  return [status, ...context].join('-');
}

function renderAnnotation(annotation: Annotation) {
  const group = getAnnotationContextValue(annotation, 'CONTEXT_GROUP');
  const message =
    annotation.error?.details || annotation.warning || annotation.info;

  return (
    <ListItem key={getAnnotationId(annotation)}>
      {group ? (
        <VariantValue variant={group} value={message} />
      ) : (
        <ListItemText primary={message} />
      )}
    </ListItem>
  );
}

export const AnnotationsSummary = ({
  annotations = [],
}: AnnotationsSummaryProps) => {
  const classes = useStyles();
  const [anchorEl, setAnchorEl] = React.useState<SVGSVGElement | null>(null);

  const open = Boolean(anchorEl);

  const openPopover: React.MouseEventHandler<SVGSVGElement> = e => {
    setAnchorEl(e.currentTarget);
  };

  const closePopover = () => {
    setAnchorEl(null);
  };

  if (annotations.length === 0) {
    return null;
  }

  const info = annotations.filter(a => a.info);
  const warnings = annotations.filter(a => a.warning);
  const errors = annotations.filter(a => a.error);

  let Symbol = InfoSymbol;

  if (errors.length > 0) {
    Symbol = ErrorSymbol;
  } else if (warnings.length > 0) {
    Symbol = WarningSymbol;
  }

  return (
    <>
      <Symbol
        className={classes.symbol}
        onMouseEnter={openPopover}
        onMouseLeave={closePopover}
      />

      <Popover
        open={open}
        anchorEl={anchorEl}
        onClose={closePopover}
        className={classes.popover}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
      >
        <CardContent>
          <List>
            {errors.length > 0 && (
              <List className={classes.subList}>
                <ListSubheader className={classes.listSubheader}>
                  <ErrorSymbol fontSize="small" />
                  Errors
                </ListSubheader>
                {errors.map(renderAnnotation)}
              </List>
            )}
            {warnings.length > 0 && (
              <List className={classes.subList}>
                <ListSubheader className={classes.listSubheader}>
                  <WarningSymbol fontSize="small" />
                  Warnings
                </ListSubheader>
                {warnings.map(renderAnnotation)}
              </List>
            )}
            {info.length > 0 && (
              <List className={classes.subList}>
                <ListSubheader className={classes.listSubheader}>
                  <InfoSymbol fontSize="small" />
                  Info
                </ListSubheader>
                {info.map(renderAnnotation)}
              </List>
            )}
          </List>
        </CardContent>
      </Popover>
    </>
  );
};
