import React from 'react';

import { Collapse } from '@material-ui/core';

import {
  ConfidenceWebsiteLink,
  FormFieldsBox,
  useDisplayNames,
} from '@spotify-confidence/core-react';
import {
  MetricsV1MetricAverageMetricSpec as AverageSpec,
  MetricsV1AggregationType,
} from '@spotify-confidence/plugin-graphql';

import {
  AggregationThresholdInput,
  AggregationTypeSelect,
} from './Aggregation';
import { ValueCapInput } from './Aggregation/ValueCapInput';
import { MeasurementSelect } from './MeasurementSelect';

type AggregationFormProps = {
  value: AverageSpec;
  entity?: string;
  factTable: string;
  onChange: (newSpec: AverageSpec) => void;
};

export const AggregationForm = ({
  value,
  entity = '',
  onChange,
  factTable,
}: AggregationFormProps) => {
  const { displayNames } = useDisplayNames();
  const entityName = displayNames.get(entity) || 'entity';
  const handleChange = (changedFields: Partial<AverageSpec>) => {
    onChange({ ...value, ...changedFields });
  };

  return (
    <FormFieldsBox>
      <MeasurementSelect
        label="Measurement"
        value={value.measurement?.name || ''}
        factTable={factTable}
        onChange={measurementName => {
          onChange({
            ...value,
            measurement: {
              name: measurementName || '',
            },
            aggregation: {
              ...value.aggregation,
              type: measurementName
                ? value.aggregation.type
                : MetricsV1AggregationType.AggregationTypeCount,
            },
          });
        }}
        disabled={!factTable}
        helperText="This measurement is the basis for your metric."
      />

      <Collapse in={!!value.measurement?.name} unmountOnExit>
        <AggregationTypeSelect
          entityName={displayNames.get(entity)}
          measurementName={value.measurement?.name}
          value={value.aggregation.type}
          onChange={newType => {
            handleChange({
              aggregation: {
                ...value.aggregation,
                type: newType,
              },
            });
          }}
          helperText={
            <>
              How to aggregate {value?.measurement?.name || 'the measurement'}{' '}
              per {entityName}.{' '}
              <ConfidenceWebsiteLink
                route="/docs/metrics#within-entity-aggregation"
                underline="always"
              >
                Learn more.
              </ConfidenceWebsiteLink>
            </>
          }
        />
      </Collapse>
      <AggregationThresholdInput
        value={value.aggregation.threshold}
        aggregationType={value.aggregation.type}
        entityName={displayNames.get(entity)}
        measurementName={value.measurement?.name}
        onChange={newThreshold => {
          handleChange({
            aggregation: {
              ...value.aggregation,
              threshold: newThreshold ?? null,
            },
          });
        }}
      />
      <ValueCapInput
        value={value.aggregation.cap}
        valueLabel="values"
        onChange={newValueCap => {
          handleChange({
            aggregation: {
              ...value.aggregation,
              cap: newValueCap ?? null,
            },
          });
        }}
        entityName={displayNames.get(entity)}
      />
    </FormFieldsBox>
  );
};
