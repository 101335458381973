import React from 'react';

import { SvgIcon, Typography, styled } from '@material-ui/core';
import CachedIcon from '@material-ui/icons/Cached';
import DataUsageIcon from '@material-ui/icons/DataUsage';
import TouchAppIcon from '@material-ui/icons/TouchApp';

import {
  AverageIcon,
  RelativeNumberIcon,
} from '@spotify-confidence/core-react';
import { MetricsV1AggregationType } from '@spotify-confidence/plugin-graphql';

import { MetricType, metricTypeName } from '../../../metricTypes';
import {
  AverageSimplifiedQuery,
  RatioSimplifiedQuery,
} from '../../SimplifiedQuery';

const ExampleList = styled('ul')(({ theme }) => ({
  padding: theme.spacing(0, 2),
}));

export type MetricTypeOption = {
  value: MetricType;
  title: string;
  description: string;
  icon?: typeof AverageIcon | typeof SvgIcon;
  expandedContent?: React.ReactNode;
};

export const METRIC_TYPE_TEMPLATE_OPTIONS: MetricTypeOption[] = [
  {
    value: 'conversion',
    title: metricTypeName.conversion,
    description: 'Measure your conversion rate based on events.',
    icon: CachedIcon,
    expandedContent: (
      <>
        <Typography variant="body2" gutterBottom>
          Examples
        </Typography>
        <Typography variant="body2" color="textSecondary">
          Evaluate marketing campaigns or user onboarding processes:
        </Typography>
        <Typography
          variant="body2"
          component={ExampleList}
          color="textSecondary"
        >
          <li>
            Share of users who make a purchase or sign up because of your
            marketing campaign
          </li>
          <li>
            Share of users who complete certain goals or milestones in your
            onboarding process
          </li>
        </Typography>
      </>
    ),
  },
  {
    value: 'consumption',
    title: metricTypeName.consumption,
    description: 'Measure engagement, consumption and revenue.',
    icon: DataUsageIcon,
    expandedContent: (
      <>
        <Typography variant="body2" gutterBottom>
          Examples
        </Typography>
        <Typography variant="body2" color="textSecondary">
          Assess user behavior and consumption patterns:
        </Typography>
        <Typography
          variant="body2"
          component={ExampleList}
          color="textSecondary"
        >
          <li>Average order volume per customer</li>
          <li>Average time spent in checkout per user</li>
        </Typography>
      </>
    ),
  },
  {
    value: 'ctr',
    title: metricTypeName.ctr,
    description: 'Measure how often users click on elements presented to them.',
    icon: TouchAppIcon,
    expandedContent: (
      <>
        <Typography variant="body2" gutterBottom>
          Examples
        </Typography>
        <Typography variant="body2" color="textSecondary">
          Evaluate marketing campaigns or conversions at the event level:
        </Typography>
        <Typography
          variant="body2"
          component={ExampleList}
          color="textSecondary"
        >
          <li>The percentage of page views that result in a click</li>
          <li>The percentage of initiated orders that visitors complete</li>
        </Typography>
      </>
    ),
  },
];

export const METRIC_TYPE_CUSTOM_OPTIONS: MetricTypeOption[] = [
  {
    value: 'average',
    title: metricTypeName.average,
    description:
      'Gives you the average or typical value of a specific variable.',
    icon: AverageIcon,
    expandedContent: (
      <>
        <AverageSimplifiedQuery
          dense
          marginBottom={4}
          aggregationMethod={MetricsV1AggregationType.AggregationTypeSum}
          measurement="column"
          helperText="Note: SUM is just an example, you can choose from average, count, max, min and sum."
        />
        <Typography variant="body2" gutterBottom>
          Examples
        </Typography>
        <Typography
          variant="body2"
          component={ExampleList}
          color="textSecondary"
        >
          <li>
            Average minutes played per user: the average audio a user streamed
          </li>
          <li>
            Premium conversion: the share of users that converted to premium
          </li>
          <li>
            Share of active users: the percentage experiment participants who
            were active
          </li>
        </Typography>
      </>
    ),
  },
  {
    value: 'ratio',
    title: metricTypeName.ratio,
    description:
      'Compares two quantities or variables by expressing their relationship as a ratio.',
    icon: RelativeNumberIcon,
    expandedContent: (
      <>
        <RatioSimplifiedQuery
          dense
          marginBottom={4}
          numerator="column1"
          numeratorAggregation={MetricsV1AggregationType.AggregationTypeAvg}
          denominator="column2"
          denominatorAggregation={MetricsV1AggregationType.AggregationTypeAvg}
          helperText="Note: AVG is just an example, you can choose from average, count, max, min and sum."
        />
        <Typography variant="body2" gutterBottom>
          Examples
        </Typography>
        <Typography
          variant="body2"
          component={ExampleList}
          color="textSecondary"
        >
          <li>
            Click-through rate: number of interactions divided by the number of
            impressions in each group
          </li>
          <li>
            Search success rate: number of successful searches divided by the
            total number of searches
          </li>
          <li>
            Average value per order: total order value divided by total number
            of orders
          </li>
        </Typography>
      </>
    ),
  },
];

export const METRIC_TYPE_OPTIONS: MetricTypeOption[] =
  METRIC_TYPE_TEMPLATE_OPTIONS.concat(METRIC_TYPE_CUSTOM_OPTIONS);
