import React from 'react';

import { RecommendationCard } from '@spotify-confidence/core-react';

import { QualityStatus, TankingStatus } from '../../result';

export function HealthCheckCondition({
  tankingStatus,
  qualityStatus,
}: {
  tankingStatus: TankingStatus;
  qualityStatus?: QualityStatus;
}) {
  if (
    (!qualityStatus || qualityStatus === QualityStatus.ALL_SUCCEEDED) &&
    (tankingStatus === TankingStatus.ALL_SUCCEEDED ||
      tankingStatus === TankingStatus.NO_METRICS)
  ) {
    return (
      <RecommendationCard.CriteriaItem status="success">
        All health checks passed.
      </RecommendationCard.CriteriaItem>
    );
  } else if (
    qualityStatus === QualityStatus.SOME_FAILED ||
    tankingStatus === TankingStatus.SOME_FAILED
  ) {
    return (
      <RecommendationCard.CriteriaItem status="error">
        At least one health check has failed.
      </RecommendationCard.CriteriaItem>
    );
  }

  return (
    <RecommendationCard.CriteriaItem status="pending">
      At least one health check is pending.
    </RecommendationCard.CriteriaItem>
  );
}
