import React from 'react';

import { Box, Chip, Typography } from '@material-ui/core';
import { MuiPickersUtilsProvider } from '@material-ui/pickers';

import DateFnsUtils from '@date-io/date-fns';
import { SegmentFragment } from '@spotify-confidence/plugin-graphql';

import { targetingCodec } from '../../domain/targeting/';
import {
  isCriterionSet,
  isTargetingPresent,
} from '../../domain/targeting/targeting.model';
import { useTargetingCriteria } from '../../domain/targeting/targeting.state';
import { useCriterionOptions } from './CriterionOptionsProvider';
import { TargetingCriteria } from './TargetingCritera';

type Props = {
  targeting: SegmentFragment['targeting'];
  readOnly?: boolean;
  onChange?: (targeting: SegmentFragment['targeting']) => void;

  title?: string;
};

export const TargetingContainer = ({
  onChange,
  targeting,
  readOnly = false,
  title,
}: Props) => {
  const { criterionOptions } = useCriterionOptions();
  const criterion = targetingCodec.fromSchemaTargeting(
    targeting,
    criterionOptions,
  );

  const { onAdd, onUpdate, onDelete } = useTargetingCriteria({
    targeting: criterion,
    onChange: newTargeting => {
      onChange?.(targetingCodec.toSchemaTargeting(newTargeting));
    },
  });

  const isEmpty = !isTargetingPresent(criterion);

  return (
    <MuiPickersUtilsProvider utils={DateFnsUtils}>
      {title && (
        <Box marginBottom={2}>
          <Typography variant="body1" color="textSecondary">
            {title}
          </Typography>
        </Box>
      )}
      {isEmpty && (
        <Box marginBottom={readOnly ? 0 : 2}>
          <Chip label="Everyone" />
        </Box>
      )}

      <TargetingCriteria
        readOnly={readOnly}
        depth={0}
        maxDepth={2}
        onAdd={onAdd}
        onUpdate={onUpdate}
        onDelete={onDelete}
        criterion={criterion}
        operator={isCriterionSet(criterion) ? criterion.operator : undefined}
      />
    </MuiPickersUtilsProvider>
  );
};
