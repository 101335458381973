import {
  solidBlack0,
  solidWhite200,
} from '@spotify-internal/encore-foundation';

import { lightTheme } from '@backstage/theme';

import { buildEncoreTheme } from './buildEncoreTheme';

export const encoreLightTheme = buildEncoreTheme({
  type: 'light',
  containedHover: '#0D0D0D',
  outlinedHover: '#F6F6F6',
  outlinedResting: '#6A6A6A',
  activeRating: '#169B45',
  snackbarBackground: '#F6F6F6',
  palette: {
    secondary: {
      main: solidBlack0,
      light: solidBlack0,
      dark: '#0D0D0D',
      contrastText: solidWhite200,
    },
    navigation: {
      ...lightTheme.palette.navigation,
      background: solidWhite200,
      color: '#6A6A6A',
      selectedColor: solidBlack0,
      submenu: {
        background: solidWhite200,
      },
    },
    divider: '#DEDEDE',
    text: {
      primary: solidBlack0,
      secondary: '#6A6A6A',
      disabled: '#878787',
      hint: '#6A6A6A',
    },
    background: {
      paper: solidWhite200,
      default: solidWhite200,
    },
    action: {
      hover: '#F6F6F6',
      hoverOpacity: 0.2,
      disabled: '#6A6A6A',
      disabledBackground: '#DEDEDE',
      active: '#F6F6F6',
      focus: '#B7B7B7',
    },
  },
});
