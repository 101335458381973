import React from 'react';

import { sum } from 'lodash';

import { BUCKET_COUNT } from '../constants';
import {
  WeightedItem,
  changeItemWeightAndDistributeRemaining,
  distributeWeightEvenly,
} from './useWeightedItems';

// We translate treatment weights into a space of 10_000 buckets to ensure two-digit precision
// after the comma for given percentage values

export function scaleWeights<T extends { weight?: number | null }>(
  treatments: T[],
): T[] {
  const sumOfWeights = sum(treatments.map(t => t.weight || 0));
  if (sumOfWeights !== BUCKET_COUNT) {
    const tra = treatments.map(t => {
      const rescaledWeight = Math.round(
        ((t.weight || 0) * BUCKET_COUNT) / sumOfWeights,
      );
      return {
        ...t,
        weight: rescaledWeight,
      };
    });
    return tra;
  }

  return treatments;
}

export const useTreatmentWeightsChange = <T extends WeightedItem>({
  treatments,
  onChange,
}: {
  treatments: T[];
  onChange: (treatments: T[]) => void;
}) => {
  const handleWeightChange = React.useCallback(
    (index: number) => {
      return (v: number) => {
        const newTreatments = changeItemWeightAndDistributeRemaining(
          treatments,
          index,
          v,
          BUCKET_COUNT,
        );

        onChange(newTreatments);
      };
    },
    [onChange, treatments],
  );

  const balanceWeights = React.useCallback(() => {
    const newTreatments = distributeWeightEvenly(treatments, BUCKET_COUNT);
    onChange(newTreatments);
  }, [onChange, treatments]);

  return {
    handleWeightChange,
    balanceWeights,
  };
};
