import React, { useEffect } from 'react';

import { Box, Collapse, Typography } from '@material-ui/core';

import { MonitorCard } from '../MonitorCard';
import { useConnect } from '../context';
import { useEventPublished } from './useEventPublished';

const Published = () => {
  return (
    <MonitorCard title="Published">
      <Typography paragraph variant="body1" color="textSecondary">
        We got your event! You can use event data to create metrics, which in
        turn let you measure the outcomes of your experiments and rollouts.
      </Typography>
      <Typography>Next up: Onboard to Confidence.</Typography>
    </MonitorCard>
  );
};

const Waiting = ({ eventName }: { eventName: string }) => {
  return (
    <MonitorCard
      waiting
      title={
        <>
          Waiting for{' '}
          <Typography component="span" variant="inherit" color="primary">
            {eventName}
          </Typography>{' '}
          data...
        </>
      }
    >
      <Typography variant="body1" color="textSecondary" paragraph>
        Launch your application again (locally is OK). Confidence will detect as
        soon as you start tracking the event (this might take a few minutes).
      </Typography>
    </MonitorCard>
  );
};

export function MonitorEvent({ eventName }: { eventName: string }) {
  const { setEventPublished } = useConnect();
  const { isPublished, startPolling, stopPolling } = useEventPublished({
    eventName,
  });

  React.useEffect(() => {
    if (!isPublished) {
      startPolling(5000);
    } else {
      stopPolling();
    }
  }, [isPublished]);

  useEffect(() => {
    setEventPublished(Boolean(isPublished));
  }, [isPublished, setEventPublished]);

  return (
    <Collapse in>
      <Box marginY={4}>
        {isPublished ? <Published /> : <Waiting eventName={eventName} />}
      </Box>
    </Collapse>
  );
}
