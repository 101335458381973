import {
  FlagsAdminV1SegmentInput,
  RuleFragment,
  SegmentFragment,
  getTypeOrNull,
} from '@spotify-confidence/plugin-graphql';
import _, { omit } from 'lodash';
import { v4 as uuidv4 } from 'uuid';

import { targetingCodec } from './targeting';

export const defaultAllocation = {
  proportion: {
    value: '1',
  },
  exclusiveTo: [],
  exclusivityTags: [],
};

export const segmentIsDifferent = (a: SegmentFragment, b: SegmentFragment) => {
  const formatTargeting = (t: SegmentFragment['targeting']) =>
    targetingCodec.fromSchemaTargeting(targetingCodec.removeEmptyTargeting(t));

  const formatAllocation = (allocation: SegmentFragment['allocation']) => {
    if (!allocation) return allocation;

    const allocationWithoutTypenames = {
      ...allocation,
      proportion: _.omit(allocation.proportion, '__typename'),
    };
    return _.omit(allocationWithoutTypenames, '__typename');
  };

  return !_.isEqual(
    {
      ...omit(a, ['state']),
      allocation: formatAllocation(a.allocation),
      targeting: formatTargeting(a.targeting),
    },
    {
      ...omit(b, ['state']),
      allocation: formatAllocation(b.allocation),
      targeting: formatTargeting(b.targeting),
    },
  );
};

export const newSegment = (
  flag: string,
): Extract<RuleFragment['segment'], { __typename: 'FlagsAdminV1Segment' }> => ({
  name: uuidv4(),
  allocation: defaultAllocation,
  labels: [{ key: 'uiCreated', value: 'true' }],
  flag: { name: flag, __typename: 'FlagsAdminV1Flag' },
  __typename: 'FlagsAdminV1Segment',
});

export function segmentIsExclusive(segment: SegmentFragment | null) {
  if (!segment) return false;
  return Boolean(
    segment.allocation?.exclusiveTo.length ||
      segment.allocation?.exclusivityTags.length,
  );
}

export const toNewSegmentInput = (
  segment: SegmentFragment,
  flag: string,
): FlagsAdminV1SegmentInput => {
  return omit(
    {
      ...segment,
      owner: getTypeOrNull(segment.owner, 'IamV1Identity')?.name ?? undefined,
      targeting: targetingCodec.toInput(segment.targeting),
      flag: flag,
      workflowInstance: getTypeOrNull(
        segment.workflowInstance,
        'WorkflowV1WorkflowInstance',
      )?.name,
    },
    ['name'],
  );
};
