import React from 'react';
import { InView } from 'react-intersection-observer';
import { Link } from 'react-router-dom';

import {
  Box,
  Chip,
  Link as MuiLink,
  Table,
  TableBody,
  TableCell,
  TableRow,
} from '@material-ui/core';

import {
  ACTION_BAR_HEIGHT,
  ConfidenceWebsiteLink,
  FilterListEmptyState,
  Highlight,
  ResourceTableRow,
  StickyTableContainer,
  StickyTableHead,
  extractLastNameComponent,
  useListFilter,
} from '@spotify-confidence/core-react';
import {
  getError,
  getTypeOrNull,
  useListEventConnectionsQuery,
} from '@spotify-confidence/plugin-graphql';

import { useRouteRef } from '@backstage/core-plugin-api';

import { eventConnectionRouteRef } from '../../../routes';
import {
  getEventConnectionDestinationName,
  getEventConnectionDetails,
} from '../../helpers';

type EventConnectionListProps = {
  topOffset?: number;
};

export const EventConnectionList = ({
  topOffset = ACTION_BAR_HEIGHT,
}: EventConnectionListProps) => {
  const eventConnectionRoute = useRouteRef(eventConnectionRouteRef);
  const { searchQuery } = useListFilter();

  const { data, loading, fetchMore } = useListEventConnectionsQuery({
    variables: {
      pageSize: 25,
    },
    fetchPolicy: 'cache-and-network',
  });

  const { eventConnections, nextPageToken } =
    getTypeOrNull(
      data?.eventConnections,
      'ConnectorsV1ListEventConnectionsResponse',
    ) || {};
  const error = getError(data?.eventConnections);

  const filteredConnections = (eventConnections || []).filter(connection =>
    Object.values(getEventConnectionDetails(connection)).some(detail =>
      detail.value.toLowerCase().includes(searchQuery.toLowerCase()),
    ),
  );

  if (filteredConnections?.length === 0 && !loading) {
    return (
      <FilterListEmptyState
        error={getError(data?.eventConnections) || error}
        type="connection"
        description={
          <>
            Use event connections to export event data ingested through the
            Confidence event sender SDKs.{' '}
            <ConfidenceWebsiteLink
              route="/docs/api/connectors/events"
              underline="always"
            >
              Read more about event connections in the documentation
            </ConfidenceWebsiteLink>
            .
          </>
        }
      />
    );
  }

  return (
    <StickyTableContainer loading={loading} error={error}>
      <Table>
        <StickyTableHead topOffset={topOffset}>
          <TableRow>
            <TableCell>Destination</TableCell>
            <TableCell>Details</TableCell>
          </TableRow>
        </StickyTableHead>
        <TableBody>
          {filteredConnections.map(connection => {
            const details = getEventConnectionDetails(connection);
            return (
              <ResourceTableRow
                key={connection.name}
                name={connection.name}
                data-testid={connection.name}
                routeRef={eventConnectionRouteRef}
              >
                <TableCell>
                  <MuiLink
                    component={Link}
                    to={eventConnectionRoute({
                      id: extractLastNameComponent(connection.name)!,
                    })}
                  >
                    {getEventConnectionDestinationName(connection)}
                  </MuiLink>
                </TableCell>
                <TableCell>
                  <Box
                    display="flex"
                    gridGap="0.5em"
                    flexWrap="wrap"
                    gridRowGap="0.5em"
                  >
                    {details.map(({ label, value }) => (
                      <Chip
                        size="small"
                        key={label}
                        label={
                          <>
                            <strong>{label}:</strong>{' '}
                            <Highlight highlight={searchQuery}>
                              {value}
                            </Highlight>
                          </>
                        }
                      />
                    ))}
                  </Box>
                </TableCell>
              </ResourceTableRow>
            );
          })}
        </TableBody>
      </Table>
      {data && (
        <InView
          onChange={async inView => {
            if (inView && nextPageToken) {
              await fetchMore({
                variables: {
                  pageToken: nextPageToken,
                },
              });
            }
          }}
        />
      )}
    </StickyTableContainer>
  );
};
