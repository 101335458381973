import React from 'react';

import { Divider, MenuItem, TextField, Typography } from '@material-ui/core';

import { FormFieldsBox } from '@spotify-confidence/core-react';
import {
  ConnectorsV1AwsRegion,
  ConnectorsV1S3BucketConfigInput,
} from '@spotify-confidence/plugin-graphql';

import { OptionalAwsCredentialSettings } from '../../../metrics-datawarehouse/Redshift/OptionalAwsCredentialSettings';

type S3BucketConfigFormProps = {
  value: ConnectorsV1S3BucketConfigInput;
  onChange: (conf: ConnectorsV1S3BucketConfigInput) => void;
  disabled?: boolean;
};

export const S3BucketConfigForm = ({
  value,
  onChange,
  disabled,
}: S3BucketConfigFormProps) => {
  const region: string = value?.region ?? ConnectorsV1AwsRegion.EuWest_1;
  const bucket: string = value?.bucket ?? '';
  const roleArn: string = value?.roleArn ?? '';

  const handleConfigChange =
    (field: keyof ConnectorsV1S3BucketConfigInput) =>
    (e: React.ChangeEvent<HTMLInputElement>) => {
      onChange({
        ...value,
        [field]: e.target.value,
      });
    };

  return (
    <FormFieldsBox>
      <FormFieldsBox spacing={1}>
        <div>
          <Typography variant="overline" color="textSecondary" gutterBottom>
            S3 Bucket Settings
          </Typography>
          <Divider />
        </div>
        <TextField
          disabled={disabled}
          label="Region"
          variant="outlined"
          value={region}
          required
          fullWidth
          onChange={handleConfigChange('region')}
          select
        >
          {Object.values(ConnectorsV1AwsRegion).map(regionOption => (
            <MenuItem key={regionOption} value={regionOption}>
              {regionOption}
            </MenuItem>
          ))}
        </TextField>
      </FormFieldsBox>

      <OptionalAwsCredentialSettings
        value={value?.credentialsSettings ?? undefined}
        onChange={credentialsSettings =>
          onChange({
            ...value,
            credentialsSettings: {
              accessKeyId: credentialsSettings?.accessKeyId ?? '',
              secretAccessKey: credentialsSettings?.secretAccessKey ?? '',
            },
          })
        }
      />
      <TextField
        disabled={disabled}
        label="Role ARN"
        variant="outlined"
        value={roleArn}
        required
        fullWidth
        helperText="The ARN for the AWS Role that has read/write access to the S3 bucket. You need to configure the role to have a trust relationship so that the Confidence service account can to assume this role."
        onChange={handleConfigChange('roleArn')}
      />
      <TextField
        disabled={disabled}
        label="Bucket"
        variant="outlined"
        value={bucket}
        required
        fullWidth
        helperText="The S3 bucket to write the parquet files to."
        onChange={handleConfigChange('bucket')}
      />
    </FormFieldsBox>
  );
};
