import { WeightedItem } from './types';

function normalizeWeight(value: number, maxValue: number) {
  return Math.max(Math.min(value, maxValue), 0);
}

export function changeItemWeightAndDistributeRemaining<T extends WeightedItem>(
  items: T[],
  index: number,
  desiredWeight: number,
  maxWeight: number,
) {
  if (items.length < 1) {
    return [];
  }

  if (!items[index]) {
    return items;
  }

  if (items.length === 1) {
    return [{ ...items[index], weight: maxWeight }];
  }

  const newWeight = normalizeWeight(desiredWeight, maxWeight);

  const weightDifference = (items[index].weight || 0) - newWeight;
  const remainingWeight = maxWeight - newWeight;
  const shouldDistributeAll = remainingWeight === maxWeight;

  const otherIndices = items
    .filter((_item, i) => i !== index)
    .map(item => items.indexOf(item));

  const indicesWithWeight = otherIndices.filter(i => items[i].weight);
  const indicesForDistribution =
    indicesWithWeight.length === 0 ? otherIndices : indicesWithWeight;

  const otherItemsCount = indicesForDistribution.length;
  const weightToDistribute = shouldDistributeAll ? maxWeight : weightDifference;

  const excess = weightToDistribute % otherItemsCount;
  const perItemModification = (weightToDistribute - excess) / otherItemsCount;

  const modifyWeight = (i: number) => {
    const isFirstOtherItem = indicesForDistribution[0] === i;

    if (shouldDistributeAll) {
      if (isFirstOtherItem) {
        return perItemModification + excess;
      }
      return perItemModification;
    }

    const currentWeight = items[i].weight || 0;
    if (isFirstOtherItem) {
      return currentWeight + excess + perItemModification;
    }
    return currentWeight + perItemModification;
  };

  return items.map((item, i) => {
    let weight = 0;

    if (index === i) {
      weight = newWeight;
    } else if (indicesForDistribution.includes(i) && remainingWeight > 0) {
      weight = modifyWeight(i);
    }

    return {
      ...item,
      weight: normalizeWeight(weight, maxWeight),
    };
  });
}

export function distributeWeightEvenly<T extends WeightedItem>(
  items: T[],
  maxTotal: number,
) {
  const numberOfItems = items.length;

  // The sum of weights might be != maxTotal, so we add any excess to the first item
  const excess = maxTotal % numberOfItems;
  const newSize = (maxTotal - excess) / numberOfItems;

  const newItems = items.map((item, i) => ({
    ...item,
    weight: i === 0 ? newSize + excess : newSize,
  }));

  return newItems;
}
