import React from 'react';

import { Box, Button, Chip, MenuItem, Typography } from '@material-ui/core';
import CancelIcon from '@material-ui/icons/Cancel';
import ExpandMore from '@material-ui/icons/ExpandMore';

import { ContextMenu, ObjUtils } from '@spotify-confidence/core-react';
import {
  InputMaybe,
  MetricFilterCriterionFragment,
  MetricsV1FilterCriterionAttributeCriterion,
  MetricsV1FilterCriterionInput,
  MetricsV1FilterRangeRule,
  MetricsV1FilterValue,
} from '@spotify-confidence/plugin-graphql';

import {
  MetricFilterEqRule,
  MetricFilterRangeRule,
  MetricFilterSetRule,
} from './RuleInputs';
import { RuleType, getValueType } from './useMetricFilters';

export type ValueType = keyof MetricsV1FilterValue;

export type RangeOperatorOption = {
  ruleType: 'rangeRule';
  label: string;
  isNot: boolean;
  fieldKeys: (keyof MetricsV1FilterRangeRule)[];
  isSelected: (v: MetricsV1FilterCriterionAttributeCriterion) => boolean;
};
export type BaseOperatorOption = {
  label: string;
  isNot: boolean;
  ruleType: 'setRule' | 'eqRule';
  isSelected?: (v: MetricsV1FilterCriterionAttributeCriterion) => boolean;
};

export type OperatorOption = BaseOperatorOption | RangeOperatorOption;
export const OP: Record<ValueType, OperatorOption[]> = {
  stringValue: [
    {
      label: 'is',
      isNot: false,
      ruleType: 'eqRule',
    },
    {
      label: 'is not',
      isNot: true,
      ruleType: 'eqRule',
    },
    {
      label: 'in',
      isNot: false,
      ruleType: 'setRule',
    },
    {
      label: 'not in',
      isNot: true,
      ruleType: 'setRule',
    },
  ],
  boolValue: [
    {
      label: 'is',
      isNot: false,
      ruleType: 'eqRule',
    },
    {
      label: 'is not',
      isNot: true,
      ruleType: 'eqRule',
    },
  ],
  numberValue: [
    {
      label: '=',
      isNot: false,
      ruleType: 'eqRule',
    },
    {
      label: '≠',
      isNot: true,
      ruleType: 'eqRule',
    },
    {
      label: '>',
      isNot: false,
      ruleType: 'rangeRule',
      fieldKeys: ['startExclusive'],
      isSelected: v => {
        return (
          !v.rangeRule?.endExclusive &&
          !v.rangeRule?.endInclusive &&
          !!v.rangeRule?.startExclusive &&
          !v.rangeRule?.startInclusive
        );
      },
    },
    {
      label: '≥',
      isNot: false,
      ruleType: 'rangeRule',
      fieldKeys: ['startInclusive'],
      isSelected: v =>
        !v.rangeRule?.endExclusive &&
        !v.rangeRule?.endInclusive &&
        !v.rangeRule?.startExclusive &&
        !!v.rangeRule?.startInclusive,
    },
    {
      label: '<',
      isNot: false,
      ruleType: 'rangeRule',
      fieldKeys: ['endExclusive'],
      isSelected: v =>
        !!v.rangeRule?.endExclusive &&
        !v.rangeRule?.endInclusive &&
        !v.rangeRule?.startExclusive &&
        !v.rangeRule?.startInclusive,
    },
    {
      label: '≤',
      isNot: false,
      ruleType: 'rangeRule',
      fieldKeys: ['endInclusive'],
      isSelected: v =>
        !v.rangeRule?.endExclusive &&
        !!v.rangeRule?.endInclusive &&
        !v.rangeRule?.startExclusive &&
        !v.rangeRule?.startInclusive,
    },
    {
      label: 'in',
      isNot: false,
      ruleType: 'setRule',
    },
    {
      label: 'not in',
      isNot: true,
      ruleType: 'setRule',
    },
    {
      label: 'is between',
      isNot: false,
      ruleType: 'rangeRule',
      fieldKeys: ['startExclusive', 'endExclusive'],
      isSelected: v => {
        return (
          (!!v.rangeRule?.endInclusive || !!v.rangeRule?.endExclusive) &&
          (!!v.rangeRule?.startExclusive || !!v.rangeRule?.startInclusive)
        );
      },
    },
    {
      label: 'is not between',
      isNot: true,
      fieldKeys: ['startExclusive', 'endExclusive'],
      ruleType: 'rangeRule',
      isSelected: v => {
        return (
          (!!v.rangeRule?.endInclusive || !!v.rangeRule?.endExclusive) &&
          (!!v.rangeRule?.startExclusive || !!v.rangeRule?.startInclusive)
        );
      },
    },
  ],
  timestampValue: [],
};

export const getSelectedOperator = ({
  attribute: criterionAttribute,
  valueType,
  isNot,
}: {
  attribute?: InputMaybe<MetricsV1FilterCriterionAttributeCriterion>;
  isNot: boolean;
  valueType: ValueType;
}) => {
  const { attribute, ...values } = criterionAttribute ?? {};

  const ruleValue = ObjUtils.cleanNulls(values);
  const ruleType = Object.keys(ruleValue)?.[0] as RuleType;
  if (!criterionAttribute) return null;

  const selectedOp = OP[valueType].find(s => {
    if (s.isSelected)
      return s.isSelected(criterionAttribute) && s.isNot === isNot;
    return s.ruleType === ruleType && s.isNot === isNot;
  });
  return selectedOp;
};

export const Criteria = ({
  critera,
  onChange,
  onDelete,
  isNot,
}: {
  isNot: boolean;
  critera?: MetricsV1FilterCriterionInput;
  onChange: (t: Partial<MetricFilterCriterionFragment>, isNot: boolean) => void;
  onDelete: () => void;
}) => {
  if (!critera?.attribute) return null;

  const { attribute, ...values } = critera?.attribute ?? {};
  const attrName = critera.attribute?.attribute ?? 'unknown';
  const ruleValue = ObjUtils.cleanNulls(values);
  const ruleType = Object.keys(ruleValue)?.[0] as RuleType;
  const valueType = React.useRef(getValueType(values)).current;
  if (!valueType || !critera?.attribute) return null;
  const availableOp = OP[valueType] ?? [];

  const selectedOp = getSelectedOperator({
    attribute: critera.attribute,
    isNot,
    valueType,
  });
  if (!selectedOp) return <span>Invalid operator</span>;

  const handleChangeOperator = (newOperator: OperatorOption) => {
    return () => {
      if (
        critera &&
        newOperator.ruleType === ruleType &&
        newOperator.ruleType !== 'rangeRule'
      ) {
        if (newOperator.isNot !== isNot) {
          onChange(critera, newOperator.isNot);
        }
        return;
      }
      // Pick the value to bring to the new rule
      let currentValue = null;
      if (critera?.attribute?.eqRule) {
        currentValue = critera?.attribute?.eqRule.value;
      } else if (critera?.attribute?.setRule) {
        currentValue = critera?.attribute?.setRule.values[0];
      } else if (critera?.attribute?.rangeRule) {
        currentValue =
          critera?.attribute?.rangeRule.startExclusive ??
          critera?.attribute?.rangeRule.startInclusive ??
          critera?.attribute?.rangeRule.endExclusive ??
          critera?.attribute?.rangeRule.endInclusive;
      }

      // if no values are added, we cannot reuse the value-type
      if (!currentValue) {
        currentValue = {
          [valueType]: undefined,
        };
      }

      if (newOperator.ruleType === 'eqRule') {
        onChange(
          {
            attribute: {
              attribute: attrName,
              eqRule: {
                value: currentValue,
              },
            },
          },
          newOperator.isNot,
        );
      } else if (newOperator.ruleType === 'setRule') {
        onChange(
          {
            attribute: {
              attribute: attrName,
              setRule: {
                values: [currentValue],
              },
            },
          },
          newOperator.isNot,
        );
      } else if (newOperator.ruleType === 'rangeRule') {
        const [firstKey, secondKey] = newOperator.fieldKeys;
        onChange(
          {
            attribute: {
              attribute: attrName,
              rangeRule: {
                [firstKey]: currentValue,
                ...(secondKey && {
                  [secondKey]: {
                    [valueType]: undefined,
                  },
                }),
              },
            },
          },
          newOperator.isNot,
        );
      }
    };
  };

  return (
    <Box>
      <Chip
        label={
          <Box display="flex" gridGap={8} alignItems="center">
            <Typography variant="body2">
              {critera.attribute.attribute}
            </Typography>

            <ContextMenu
              closeOnSelect
              renderButton={p => (
                <Button
                  {...p}
                  endIcon={<ExpandMore color="inherit" />}
                  size="small"
                  variant="text"
                >
                  {selectedOp?.label}
                </Button>
              )}
              renderMenu={() =>
                availableOp.map(option => (
                  <MenuItem
                    key={option.label}
                    divider
                    onClick={handleChangeOperator(option)}
                  >
                    {option.label}
                  </MenuItem>
                ))
              }
            />

            {ruleType === 'eqRule' && valueType && (
              <MetricFilterEqRule
                valueType={valueType}
                attribute={attrName}
                value={critera.attribute.eqRule}
                onChange={v => onChange(v, isNot)}
              />
            )}
            {ruleType === 'setRule' && valueType && (
              <MetricFilterSetRule
                valueType={valueType}
                attribute={attrName}
                value={critera.attribute.setRule}
                onChange={v => onChange(v, isNot)}
              />
            )}
            {ruleType === 'rangeRule' && valueType && (
              <MetricFilterRangeRule
                valueType={valueType}
                operator={selectedOp}
                attribute={attrName}
                value={critera.attribute.rangeRule}
                onChange={v => onChange(v, isNot)}
              />
            )}
          </Box>
        }
        onDelete={onDelete}
        deleteIcon={<CancelIcon name="delete-icon" />}
      />
    </Box>
  );
};
