import React from 'react';

import { TableCell, TableRow, Typography, makeStyles } from '@material-ui/core';

import {
  GroupingState,
  Header,
  HeaderGroup,
  flexRender,
} from '@tanstack/react-table';
import classNames from 'classnames';
import _ from 'lodash';

import { getHeadersExcludingGroupTitle } from './tableUtils';
import { ResultData, TableCellMeta } from './types';

const useCellStyles = makeStyles(theme => ({
  mainHeader: {
    color: theme.palette.text.primary,
    fontWeight: 'bold',
  },
  headerColumn: {
    padding: theme.spacing(2),
    borderBottomWidth: 0,
  },
}));

export const HeaderCell = ({
  header,
}: {
  header: Header<ResultData, unknown>;
}) => {
  const classes = useCellStyles();
  const { disableComments, ...metaTableCellProps } = (header.column.columnDef
    .meta || {}) as TableCellMeta;

  return (
    <TableCell
      colSpan={header.colSpan}
      rowSpan={header.depth}
      {...metaTableCellProps}
      className={classNames(classes.headerColumn)}
    >
      {header.isPlaceholder ? null : (
        <Typography
          component="span"
          className={classNames({
            [classes.mainHeader]: !header.column.parent?.id,
          })}
        >
          {flexRender(header.column.columnDef.header, header.getContext())}
        </Typography>
      )}
    </TableCell>
  );
};

const useRowStyles = makeStyles(theme => ({
  header: {
    borderBottom: `1px solid ${theme.palette.divider}`,
  },
  subHeader: {
    color: theme.palette.text.secondary,
    borderBottom: `1px solid ${
      theme.palette.type === 'dark'
        ? theme.palette.grey[700]
        : theme.palette.grey[200]
    }`,
  },
}));

export const HeaderRow = ({
  headerGroup,
  grouping,
}: {
  headerGroup: HeaderGroup<ResultData>;
  grouping?: GroupingState;
}) => {
  const classes = useRowStyles();
  if (headerGroup.depth > 0) {
    return null;
  }

  return (
    <TableRow
      key={headerGroup.id}
      className={classNames(classes.header, {
        [classes.subHeader]: headerGroup.depth > 0,
      })}
    >
      {getHeadersExcludingGroupTitle(headerGroup.headers, grouping).map(
        header => {
          const subHeaders = getHeadersExcludingGroupTitle(
            header.subHeaders,
            grouping,
          );
          return header.isPlaceholder ? (
            subHeaders.map(subHeader => (
              <HeaderCell key={subHeader.id} header={subHeader} />
            ))
          ) : (
            <HeaderCell key={header.id} header={header} />
          );
        },
      )}
    </TableRow>
  );
};
