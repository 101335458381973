import React from 'react';

import {
  Box,
  DialogActions,
  TextField,
  Tooltip,
  Typography,
} from '@material-ui/core';
import InfoOutlined from '@material-ui/icons/InfoOutlined';

import {
  DialogBody,
  DialogForm,
  DialogHeader,
  FormSubmitButtons,
  useDialog,
} from '@spotify-confidence/core-react';

import { AlphaPicker } from './AlphaPicker';
import { PowerPicker } from './PowerPicker';
import { StatsData, TestHorizonStrategy } from './types';

const SectionTitle = ({
  title,
  description,
}: {
  title: string;
  description: string;
}) => {
  return (
    <Box display="flex" flexDirection="row" gridGap={4}>
      <Typography gutterBottom>
        <strong>{title}</strong>
      </Typography>
      <Tooltip title={description}>
        <InfoOutlined fontSize="small" color="inherit" />
      </Tooltip>
    </Box>
  );
};

const ALPHA_DESCRIPTION =
  'Alpha determines the risk of false positives and the size of the ' +
  'confidence intervals. Decreasing alpha requires more samples but narrows the confidence ' +
  'intervals, increasing alpha requires less samples but increases the risk of false positives.';
const POWER_DESCRIPTION =
  'Power is the ability to detect real effects. Decreasing power requires ' +
  'less samples, but increases the risk of sign and magnitude errors and lowers the chance ' +
  'of detecting an effect. Increasing power increases the chance to detect real effects and' +
  'reduces the risk of sign and magnitude errors but increases the sample size requirements.';
const EXPECTED_DESCRIPTION =
  'The maximum sample size that you would expect for the target ' +
  'population. This is required to use group sequential tests, if not set, a less powerful ' +
  'non-parametric test will be used.';

export const StatsConfigDialog = ({
  statsData,
  onSave,
}: {
  statsData?: StatsData;
  onSave: (newData: Partial<StatsData>) => Promise<void>;
}) => {
  const { closeDialog } = useDialog();
  const [loading, setLoading] = React.useState<boolean>(false);
  const [config, setConfig] = React.useState<Partial<StatsData>>({
    alpha: statsData?.alpha,
    power: statsData?.power,
    fallbackSampleSize: statsData?.fallbackSampleSize,
    testHorizonStrategy: statsData?.testHorizonStrategy,
  });

  const updateConfig = React.useCallback(
    (newValues: Partial<StatsData>) => {
      setConfig(v => ({
        ...v,
        ...newValues,
      }));
    },
    [setConfig],
  );

  const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    (async () => {
      setLoading(true);
      await onSave(config);
      setLoading(false);
      closeDialog();
    })();
  };

  return (
    <DialogForm onSubmit={handleSubmit}>
      <DialogHeader title="Configure statistics" />
      <DialogBody autoHeight>
        <SectionTitle title="Alpha" description={ALPHA_DESCRIPTION} />
        <AlphaPicker
          alpha={config.alpha}
          onChange={alpha => updateConfig({ alpha })}
        />

        <Box marginTop={2}>
          <SectionTitle title="Power" description={POWER_DESCRIPTION} />
          <PowerPicker
            power={config.power}
            onChange={power => updateConfig({ power })}
          />
        </Box>

        {config.testHorizonStrategy === TestHorizonStrategy.SEQUENTIAL && (
          <Box marginTop={2}>
            <SectionTitle
              title="Expected sample size"
              description={EXPECTED_DESCRIPTION}
            />
            <TextField
              placeholder="Expected sample size"
              fullWidth
              variant="outlined"
              type="number"
              value={config?.fallbackSampleSize || ''}
              onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                const value = Number(e.target.value);
                updateConfig({
                  fallbackSampleSize: isNaN(value) ? undefined : value,
                });
              }}
              inputProps={{
                min: 0,
              }}
            />
          </Box>
        )}
      </DialogBody>
      <DialogActions>
        <FormSubmitButtons onCancel={closeDialog} loading={loading} />
      </DialogActions>
    </DialogForm>
  );
};
