import React from 'react';
import { useEffectOnce } from 'react-use';

import {
  Box,
  Button,
  Card,
  CardActions,
  CardContent,
  CircularProgress,
  Divider,
  LinearProgress,
  Tooltip,
  Typography,
  makeStyles,
  withStyles,
} from '@material-ui/core';
import OpenInNewIcon from '@material-ui/icons/OpenInNew';
import { Alert } from '@material-ui/lab';

import {
  Breadcrumbs,
  PageLayout,
  Section,
} from '@spotify-confidence/core-react';
import {
  BillingV1StripeCustomerAddressStatus,
  BillingV1StripeCustomerPaymentMethodCardInfo,
  BillingV1StripeCustomerTaxInfoState,
  BillingV1SubscriptionState,
  getError,
  isType,
  useConvertSubscriptionMutation,
  useStartBillingPortalSessionMutation,
} from '@spotify-confidence/plugin-graphql';
import classNames from 'classnames';
import { TCountryCode, getCountryData } from 'countries-list';
import { differenceInDays, format, lastDayOfMonth } from 'date-fns';

import {
  StatusError,
  StatusPending,
  StatusWarning,
} from '@backstage/core-components';
import { Link } from '@backstage/core-components';
import { useRouteRef } from '@backstage/core-plugin-api';

import { rootRouteRef } from '../../../routes';
import { useBillingInfo } from '../../hooks/useBillingInfo';

const useStyles = makeStyles(theme => ({
  barWarning: {
    backgroundColor: theme.palette.warning.main,
  },
  barError: {
    backgroundColor: theme.palette.error.main,
  },
}));

const Progress = withStyles(theme => ({
  root: {
    height: theme.spacing(1),
  },
}))(LinearProgress) as typeof LinearProgress;

const makeNumberFormatter = new Intl.NumberFormat('en', {
  notation: 'standard',
});

const TaxState = ({
  state,
}: {
  state?: BillingV1StripeCustomerTaxInfoState;
}) => {
  if (state === BillingV1StripeCustomerTaxInfoState.Invalid) {
    return <StatusError>Tax info is invalid</StatusError>;
  } else if (state === BillingV1StripeCustomerTaxInfoState.Valid) {
    return null;
  } else if (state === BillingV1StripeCustomerTaxInfoState.Pending) {
    return <StatusPending>Tax info is pending</StatusPending>;
  } else if (state) {
    return <>{state}</>;
  }
  return null;
};

const CardStatus = ({
  cardInfo,
}: {
  cardInfo: BillingV1StripeCustomerPaymentMethodCardInfo;
}) => {
  const cardExpireInDays =
    cardInfo?.expirationMonth && cardInfo?.expirationYear
      ? Math.max(
          0,
          differenceInDays(
            lastDayOfMonth(
              new Date().setFullYear(
                cardInfo?.expirationYear,
                cardInfo?.expirationMonth - 1,
              ),
            ),
            new Date(),
          ),
        )
      : null;
  if (cardExpireInDays && cardExpireInDays === 0)
    return <StatusError>Your payment details have expired</StatusError>;
  if (cardExpireInDays && cardExpireInDays < 15) {
    return (
      <StatusWarning>
        Your payment details will expire in {cardExpireInDays} days
      </StatusWarning>
    );
  }
  return null;
};
export const Subscription = () => {
  const classes = useStyles();
  const adminRoute = useRouteRef(rootRouteRef);
  const [startSession, { data }] = useStartBillingPortalSessionMutation();
  const [convert, { loading: isConverting }] = useConvertSubscriptionMutation();
  useEffectOnce(() => {
    setTimeout(async () => {
      await startSession();
    }, 100);
  });

  const handleOpenLink = () => {
    if (
      isType(
        data?.startBillingPortalSession,
        'BillingV1BillingPortalSession',
      ) &&
      data?.startBillingPortalSession
    ) {
      window.open(data.startBillingPortalSession.redirectUri, '_blank');
    }
  };

  const {
    data: { stripeCustomer, queryTrialUsageInfo },
    loading,
    error: billingError,
  } = useBillingInfo();
  const error = getError(data?.startBillingPortalSession) ?? billingError;
  const cardInfo = stripeCustomer?.paymentMethod?.cardInfo;
  const trial = stripeCustomer?.subscriptions.find(
    s => s.state === BillingV1SubscriptionState.Trialing,
  );
  const isInTrial = !!trial;
  const trialPeriod = (queryTrialUsageInfo?.trialUsageInfo ?? []).find(
    period => {
      const now = new Date().getTime();
      return (
        now < new Date(period.usagePeriodEndTime).getTime() &&
        now > new Date(period.usagePeriodStartTime).getTime()
      );
    },
  );

  const eventUsage =
    100 -
    (trialPeriod
      ? (trialPeriod?.currentUsage / trialPeriod?.includedInTrial) * 100
      : 0);

  const canConvert = isInTrial && !!cardInfo;

  return (
    <PageLayout
      narrow
      title="Subscription"
      headerBreadcrumbs={
        <Breadcrumbs>
          <Link to={adminRoute()}>Admin</Link>
          <Typography>Subscription</Typography>
        </Breadcrumbs>
      }
    >
      {loading && <CircularProgress />}
      {error && <Alert severity="error">{error.message}</Alert>}
      {isInTrial && (
        <Section label="Trial usage">
          <Card>
            <CardContent>
              {trialPeriod ? (
                <>
                  <Box
                    display="flex"
                    alignItems="center"
                    justifyContent="space-between"
                    mb={2}
                  >
                    <Typography variant="body1">Event usage</Typography>
                    <Typography variant="body1" color="textSecondary">
                      Between{' '}
                      {format(
                        new Date(trialPeriod?.usagePeriodStartTime),
                        'yyyy-MM-dd',
                      )}
                      {' and '}
                      {format(
                        new Date(trialPeriod?.usagePeriodEndTime),
                        'yyyy-MM-dd',
                      )}
                    </Typography>
                  </Box>
                  <Box display="flex" gridGap={8} flexDirection="column">
                    <Progress
                      classes={{
                        bar: classNames({
                          [classes.barWarning]: eventUsage < 40,
                          [classes.barError]: eventUsage < 15,
                        }),
                      }}
                      variant="determinate"
                      value={eventUsage}
                    />
                    <Typography variant="body2" color="textSecondary">
                      {makeNumberFormatter.format(trialPeriod.currentUsage)} of{' '}
                      {makeNumberFormatter.format(trialPeriod.includedInTrial)}{' '}
                      included events
                    </Typography>
                  </Box>
                </>
              ) : (
                <Typography>No usage data available</Typography>
              )}
              <CardActions>
                <Tooltip
                  title={
                    !canConvert
                      ? 'You need to update your billing information before you can start the subscription'
                      : ''
                  }
                  arrow
                  placement="right-start"
                >
                  <div>
                    <Button
                      variant="contained"
                      disabled={!canConvert || isConverting}
                      endIcon={
                        isConverting && (
                          <CircularProgress color="inherit" size="1rem" />
                        )
                      }
                      size="medium"
                      onClick={() => convert()}
                    >
                      Convert to subscription
                    </Button>
                  </div>
                </Tooltip>
              </CardActions>
            </CardContent>
          </Card>
        </Section>
      )}
      {stripeCustomer && (
        <Section label="Billing information">
          <Card>
            <CardContent>
              <Typography variant="h6">Tax number</Typography>
              <Box mb={2}>
                <Typography gutterBottom variant="body1">
                  {stripeCustomer?.taxInfo.taxId}
                </Typography>
                <TaxState state={stripeCustomer?.taxInfo.state} />
              </Box>
              <Divider />
              <Box mt={2} />

              <Typography variant="h6">Address</Typography>
              <Box mb={2}>
                <Typography gutterBottom variant="body1">
                  {stripeCustomer?.address.line1}
                  <br />
                  {stripeCustomer?.address.line2 && (
                    <>
                      {stripeCustomer.address.line2} <br />
                    </>
                  )}
                  {stripeCustomer?.address.state && (
                    <>
                      {stripeCustomer.address.state} <br />
                    </>
                  )}
                  {`${stripeCustomer?.address.postalCode} ${stripeCustomer?.address.city} `}
                  <br />
                  {
                    getCountryData(
                      stripeCustomer?.address.country as TCountryCode,
                    ).name
                  }
                </Typography>
                {stripeCustomer?.address.status ===
                  BillingV1StripeCustomerAddressStatus.Invalid && (
                  <StatusError>Address is invalid</StatusError>
                )}
              </Box>
              <Divider />
              <Box mt={2} />
              <Typography variant="h6">Card details</Typography>
              <Box mb={2}>
                {cardInfo ? (
                  <>
                    <Typography variant="body2">
                      **** **** **** {cardInfo?.last4}
                    </Typography>
                    <Typography variant="body2">
                      {cardInfo.expirationMonth}/{cardInfo.expirationYear}
                    </Typography>

                    <CardStatus cardInfo={cardInfo} />
                  </>
                ) : (
                  <StatusWarning>Missing payment details</StatusWarning>
                )}
              </Box>
              <CardActions>
                <Button
                  variant="contained"
                  endIcon={<OpenInNewIcon />}
                  size="medium"
                  onClick={handleOpenLink}
                >
                  Update billing information
                </Button>
              </CardActions>
            </CardContent>
          </Card>
        </Section>
      )}
    </PageLayout>
  );
};
