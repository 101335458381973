import React from 'react';
import { InView } from 'react-intersection-observer';

import { AutocompleteProps } from '@material-ui/lab';

export const useAutocompleteLazyOptions = ({
  onOptionsScrollEnd,
}: {
  onOptionsScrollEnd?: () => void;
}) => {
  if (!onOptionsScrollEnd) return {};

  return {
    groupBy: () => 'group.all',
    renderGroup: props => (
      <>
        {props.children}
        {!!onOptionsScrollEnd && (
          <InView
            onChange={inView => {
              if (inView) {
                onOptionsScrollEnd();
              }
            }}
          />
        )}
      </>
    ),
  } as Pick<
    AutocompleteProps<any, boolean, boolean, boolean>,
    'groupBy' | 'renderGroup'
  >;
};
