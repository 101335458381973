import loadable from '@loadable/component';

const componentsChunk = () =>
  import(
    /* webpackChunkName: "core-react-dialog-components" */ './components.chunk'
  );

export const DialogForm = loadable(() =>
  componentsChunk().then(m => m.DialogForm),
);
export const DialogHeader = loadable(() =>
  componentsChunk().then(m => m.DialogHeader),
);
export const DialogBody = loadable(() =>
  componentsChunk().then(m => m.DialogBody),
);

export { DialogProvider, useDialog } from './Dialog';
