import React, { useState } from 'react';

import {
  Box,
  CircularProgress,
  Divider,
  ListItem,
  TextFieldProps as MuiTextFieldProps,
  Paper,
  TextField,
  Typography,
} from '@material-ui/core';
import Add from '@material-ui/icons/Add';
import {
  Autocomplete,
  AutocompleteInputChangeReason,
  AutocompleteProps,
  Value,
} from '@material-ui/lab';

type CreatableAutocompleteProps<T = string> = Omit<
  AutocompleteProps<T, false, false, false>,
  | 'open'
  | 'inputValue'
  | 'freeSolo'
  | 'onChange'
  | 'multiple'
  | 'disableClearable'
  | 'renderInput'
> & {
  onChange: (newValue: Value<T, false, false, false>) => void;
  onCreate: (inputValue: string) => void;
  creating?: boolean;
  canCreate?: (inputValue: string) => boolean;
  TextFieldProps?: Omit<MuiTextFieldProps, 'onChange' | 'value'>;
  formatInputValue?: (newValue: string) => string;
};

export const CreatableAutocomplete = <T,>({
  onChange,
  value,
  onCreate,
  disabled,
  creating,
  TextFieldProps,
  loading,
  options,
  canCreate,
  formatInputValue,
  onInputChange,
  ...autocompleteProps
}: CreatableAutocompleteProps<T>) => {
  const [open, setOpen] = React.useState(false);
  const [inputValue, setInputValue] = useState('');

  const shouldCreateNew =
    !!inputValue && (canCreate ? canCreate(inputValue) : true);

  const handleCreate = (newId: string) => {
    if (shouldCreateNew) {
      onCreate(newId);
    }
  };

  const handleChange = async (
    _e: React.ChangeEvent<{}>,
    option: Value<T, false, false, false>,
  ) => {
    onChange(option);
    setOpen(false);
  };

  const handleInputChange = (
    e: React.ChangeEvent<{}>,
    newValue: string,
    reason: AutocompleteInputChangeReason,
  ) => {
    setInputValue(formatInputValue ? formatInputValue(newValue) : newValue);
    onInputChange?.(e, newValue, reason);
  };

  const handleKeyDown: React.KeyboardEventHandler<HTMLDivElement> = e => {
    if (e.key === 'Enter') {
      if (shouldCreateNew) {
        setOpen(false);
      }
    } else {
      setOpen(true);
    }
  };

  const handleOnBlur: React.FocusEventHandler<HTMLDivElement> = _e => {
    setOpen(false);
    if (shouldCreateNew) {
      handleCreate(inputValue);
    }
  };

  const openList = () => {
    setOpen(true);
  };

  const onCreateClick: React.MouseEventHandler<HTMLDivElement> = () => {
    if (shouldCreateNew) {
      handleCreate(inputValue);
      setOpen(false);
    }
  };

  const PaperComponent: CreatableAutocompleteProps<T>['PaperComponent'] = ({
    children,
    ...params
  }) => (
    <Paper {...params}>
      {children}
      <Divider />
      <ListItem button disabled={!shouldCreateNew} onMouseDown={onCreateClick}>
        <Typography color="textSecondary">
          <Box display="inline-flex" gridGap={4} alignItems="center">
            <Add fontSize="small" />
            Create{' '}
            {shouldCreateNew
              ? `"${inputValue}"`
              : 'a new option by typing a unique name'}
          </Box>
        </Typography>
      </ListItem>
    </Paper>
  );

  const renderInput: AutocompleteProps<
    T,
    false,
    false,
    false
  >['renderInput'] = ({ InputProps, ...params }) => (
    <TextField
      {...TextFieldProps}
      {...params}
      onClick={openList}
      onFocus={openList}
      InputProps={{
        ...InputProps,
        ...TextFieldProps?.InputProps,
        endAdornment: (
          <>
            {(loading || creating) && (
              <CircularProgress size="1em" color="inherit" />
            )}
            {TextFieldProps?.InputProps?.endAdornment}
            {InputProps.endAdornment}
          </>
        ),
      }}
    />
  );

  return (
    <Autocomplete<T, false, false, false>
      {...autocompleteProps}
      value={value}
      onChange={handleChange}
      options={options}
      disabled={disabled || creating}
      loading={loading || creating}
      onFocus={openList}
      onClick={openList}
      onKeyDown={handleKeyDown}
      onBlur={handleOnBlur}
      open={open}
      PaperComponent={PaperComponent}
      inputValue={inputValue}
      onInputChange={handleInputChange}
      renderInput={renderInput}
    />
  );
};
