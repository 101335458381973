import React from 'react';
import { useNavigate } from 'react-router-dom';

import {
  Box,
  Dialog,
  MenuItem,
  TextField,
  Typography,
  makeStyles,
} from '@material-ui/core';

import {
  AuthorizationGate,
  ConfidenceWebsiteLink,
  FormFieldsBox,
  LoadingOverlay,
  TutorialWizard,
  useLocalStorage,
} from '@spotify-confidence/core-react';
import { ClientFragment } from '@spotify-confidence/plugin-graphql';

import { EventsStep } from './EventsStep/EventsStep';
import { TUTORIAL_EVENT_NAME } from './EventsStep/constants';
import { useEventPublished } from './EventsStep/useEventPublished';
import { FlagStep } from './FlagStep';
import { useFlagResolved } from './FlagStep/useFlagResolved';
import { SelectClient } from './SelectClient';
import {
  ConnectProvider,
  ProgrammingLanguage,
  ProgrammingLanguageLabels,
} from './context';
import { useSortedClients } from './useSortedClients';

const useStyles = makeStyles(() => ({
  paperFullScreen: {
    padding: 0,
  },
}));

export const PageContent = () => {
  const classes = useStyles();
  const navigate = useNavigate();
  const [language, setLanguage] = useLocalStorage<ProgrammingLanguage>(
    'connect-language',
    ProgrammingLanguage.js,
  );
  const eventName: string = (function getEventName() {
    return TUTORIAL_EVENT_NAME;
  })();
  const [client, setClient] = React.useState<ClientFragment | null>(null);
  const [flagResolved, setFlagResolved] = React.useState(false);

  const [eventPublished, setEventPublished] = React.useState(false);
  const { clients, loading: clientsLoading } = useSortedClients();
  const { hasResolved, loading: flagLoading } = useFlagResolved();
  const { isPublished, loading: eventLoading } = useEventPublished({
    eventName,
  });
  const loading = clientsLoading || flagLoading || eventLoading;

  const [activeStep, setActiveStep] = React.useState(0);
  const shouldSetStepAutomatically = React.useRef(true);

  React.useEffect(() => {
    if (clients.length > 0) {
      setClient(clients[0]);
    }
  }, [clients]);

  React.useEffect(() => {
    if (shouldSetStepAutomatically.current) {
      if (client && !loading) {
        if (isPublished) {
          setActiveStep(3);
        } else if (hasResolved) {
          setActiveStep(2);
        }
        shouldSetStepAutomatically.current = false;
      }
      setFlagResolved(hasResolved);
      setEventPublished(isPublished);
    }
  }, [loading, client, hasResolved, isPublished]);

  const exitConnect = async () => {
    navigate('/');
  };

  const steps = [
    {
      label: 'Get started',
      canNext: !!language && !!client,
      content: (
        <TutorialWizard.Step>
          <>
            <TutorialWizard.StepTitle title="Welcome to Confidence" />
            <FormFieldsBox>
              <Typography>
                This guide will show you the basics of resolving flags and
                emitting events via the Confidence SDKs. If you prefer to start
                with the wider Confidence integration, you can jump directly to
                the{' '}
                <ConfidenceWebsiteLink
                  route="/docs/onboarding-guide"
                  underline="always"
                >
                  Onboarding Guide
                </ConfidenceWebsiteLink>
                .
                {/* TODO: add CTA to notify/ping a team member when that functionality is in place */}
              </Typography>

              <Typography>
                Before we start, we need to know what programming language{' '}
                {clients.length > 1 && 'and client '} you will be using to
                adjust our code examples.
              </Typography>
              <Box
                display="flex"
                flexDirection="column"
                gridGap={16}
                maxWidth={550}
              >
                <TextField
                  label="Language"
                  select
                  value={language}
                  onChange={e =>
                    setLanguage(e.target.value as ProgrammingLanguage)
                  }
                  variant="outlined"
                  fullWidth
                >
                  {Object.entries(ProgrammingLanguageLabels).map(
                    ([value, label]) => (
                      <MenuItem key={value} value={value}>
                        {label}
                      </MenuItem>
                    ),
                  )}
                </TextField>
                {clients.length !== 1 && <SelectClient />}
              </Box>
            </FormFieldsBox>
          </>
        </TutorialWizard.Step>
      ),
    },
    {
      label: 'Flags',
      canNext: flagResolved,
      content: <FlagStep />,
    },
    {
      label: 'Events',
      canNext: eventPublished,
      content: <EventsStep eventName={eventName} />,
    },
    {
      label: 'Experiments',
      content: (
        <TutorialWizard.Step>
          <>
            <TutorialWizard.StepTitle title="Run an experiment" />
            <div>
              <Typography paragraph>
                You now have all the parts in place to run a real A/B test: The
                feature flag can give different experiences to users, and we
                have events so that we can measure how the experiences impact
                your users.
              </Typography>

              <Typography paragraph>
                To get started, follow our{' '}
                <ConfidenceWebsiteLink
                  route="/docs/onboarding-guide"
                  underline="always"
                >
                  Onboarding Guide
                </ConfidenceWebsiteLink>
                .
              </Typography>
            </div>
          </>
        </TutorialWizard.Step>
      ),
    },
  ];

  return (
    <ConnectProvider
      value={{
        language,
        setLanguage,
        client,
        setClient,
        flagResolved,
        setFlagResolved,
        eventPublished,
        setEventPublished,
      }}
    >
      <TutorialWizard.StepperContext.Provider
        value={{
          activeStep,
          setActiveStep,
          totalSteps: steps.length,
          onComplete: exitConnect,
          stepLabel: steps[activeStep].label,
        }}
      >
        <Dialog
          fullScreen
          open
          classes={{ paperFullScreen: classes.paperFullScreen }}
        >
          {loading ? (
            <LoadingOverlay loading />
          ) : (
            <TutorialWizard title="Connect your product" onClose={exitConnect}>
              <TutorialWizard.Stepper onComplete={exitConnect} steps={steps} />
            </TutorialWizard>
          )}
        </Dialog>
      </TutorialWizard.StepperContext.Provider>
    </ConnectProvider>
  );
};

export const ConnectPage = () => {
  return (
    <AuthorizationGate>
      <PageContent />
    </AuthorizationGate>
  );
};
