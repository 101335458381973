export type ConclusionComment = {
  id: string;
  text: string;
  author: string;
  createTime: string;
  editTime?: string | undefined;
  inReplyTo?: string | undefined;
};

export type Conclusion = {
  text: string;
  updateTime?: string;
};

export type FactTableDimension = {
  column: string;
  factTable: string;
};

export type DimensionTableDimension = {
  column: string;
  dimensionTable: string;
};

export type Dimension = FactTableDimension | DimensionTableDimension;

export function isFactTableDimension(
  dimension: Dimension,
): dimension is FactTableDimension {
  return !!(dimension as FactTableDimension).factTable;
}

export function isDimensionTableDimension(
  dimension: Dimension,
): dimension is DimensionTableDimension {
  return !!(dimension as DimensionTableDimension).dimensionTable;
}

export type ExploratoryAnalysisMetric = {
  name: string;
  dimensions: Dimension[];
  metricCalculation?: string;
};

export enum AnalysisState {
  Empty = 'EMPTY',
  Calculating = 'CALCULATING',
  Done = 'DONE',
  Error = 'ERROR',
  Stale = 'STALE',
}

export type ExploratoryAnalysis = {
  id: string;
  name: string;
  conclusion?: Conclusion;
  metrics: ExploratoryAnalysisMetric[];
  state: AnalysisState;
  messages: string[];
  createTime: string;
  updateTime: string;
  comments: ConclusionComment[];
  createdBy?: string | undefined;
};

export type ExploreData = {
  exploratoryAnalyses: ExploratoryAnalysis[];
};
