import React from 'react';
import { InView } from 'react-intersection-observer';

import { useListFilter } from '@spotify-confidence/core-react';
import {
  GetGroupMembersDocument,
  getError,
  getTypeOrNull,
  useAddMembersMutation,
  useGetGroupMembersQuery,
  useRemoveMembersMutation,
} from '@spotify-confidence/plugin-graphql';

export function useGroupMembers(name: string) {
  const { searchQuery } = useListFilter();

  const { data, loading, error, fetchMore } = useGetGroupMembersQuery({
    variables: {
      name,
    },
  });
  const groupMembersResponse = getTypeOrNull(
    data?.groupMembers,
    'IamV1ListGroupMembersResponse',
  );
  const members = (groupMembersResponse?.groupMembers || []).filter(identity =>
    identity.displayName.toLowerCase().includes(searchQuery.toLowerCase()),
  );
  const membersError = getError(data?.groupMembers);
  // component for fetching more data
  const fetchMoreObserver = (
    <InView
      onChange={async inView => {
        if (inView && groupMembersResponse?.nextPageToken) {
          await fetchMore({
            variables: {
              pageToken: groupMembersResponse.nextPageToken,
            },
          });
        }
      }}
    />
  );

  const [addMembersMutation] = useAddMembersMutation({
    refetchQueries: [GetGroupMembersDocument],
  });

  const addMembers = (names: string[]) => {
    return addMembersMutation({
      variables: {
        group: name,
        identities: names,
      },
    });
  };

  const [removeMembersMutation] = useRemoveMembersMutation({
    refetchQueries: [GetGroupMembersDocument],
  });

  const removeMembers = (names: string[]) => {
    return removeMembersMutation({
      variables: {
        group: name,
        identities: names,
      },
    });
  };

  return {
    members,
    loading,
    error: error ?? membersError,
    addMembers,
    removeMembers,
    fetchMoreObserver,
  };
}
