import {
  createComponentExtension,
  createPlugin,
  createRoutableExtension,
} from '@backstage/core-plugin-api';

import {
  assignmentTableRouteRef,
  clientRouteRef,
  clientSchemaRouteRef,
  clientsRouteRef,
  entitiesRouteRef,
  entityRouteRef,
  groupRouteRef,
  groupsRouteRef,
  policiesRouteRef,
  rootRouteRef,
  userRouteRef,
  usersRouteRef,
} from './routes';

export const confidenceAdminPlugin = createPlugin({
  id: 'confidence-admin',
  routes: {
    root: rootRouteRef,
    users: usersRouteRef,
    user: userRouteRef,
    groups: groupsRouteRef,
    group: groupRouteRef,
    clients: clientsRouteRef,
    client: clientRouteRef,
    clientSchema: clientSchemaRouteRef,
    policies: policiesRouteRef,
  },
  externalRoutes: {
    assignmentTable: assignmentTableRouteRef,
    entity: entityRouteRef,
    entities: entitiesRouteRef,
  },
});

export const ConfidenceAdminPage = confidenceAdminPlugin.provide(
  createRoutableExtension({
    name: 'ConfidenceAdminPage',
    component: () =>
      import(
        /* webpackChunkName: "confidence-admin", webpackPrefetch: 10 */ './Router'
      ).then(m => m.Router),
    mountPoint: rootRouteRef,
  }),
);

export const BillingBanner = confidenceAdminPlugin.provide(
  createComponentExtension({
    name: 'BillingBanner',
    component: {
      lazy: () =>
        import('./billing/components/BillingBanner/BillingBanner').then(
          m => m.BillingBanner,
        ),
    },
  }),
);
