import React from 'react';

import { Box } from '@material-ui/core';
import { Alert } from '@material-ui/lab';

export const EditMetricWarning = () => {
  return (
    <Box marginBottom={2}>
      <Alert severity="warning">
        Editing this metric may invalidate existing calculations. Retrigger
        earlier calculations to align with the latest configuration.
      </Alert>
    </Box>
  );
};
