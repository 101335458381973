/* eslint-disable no-nested-ternary */
import {
  AttributeType,
  Criterion,
  CriterionSet,
  TargetingCriteria,
  isCriterionSet,
  newAttributeCriterion,
  newCriterionSet,
  newSegmentCriterion,
} from './targeting.model';

export type AddTypes =
  | {
      type: 'attribute';
      attribute: string;
      attributeType: AttributeType;
    }
  | Criterion
  | { type: 'segment' }
  | { type: 'set' };
export const useTargetingCriteria = ({
  targeting,
  onChange,
}: {
  targeting: TargetingCriteria;
  onChange?: (newValue: TargetingCriteria) => void;
}) => {
  const onAdd = (name: string, criterion: AddTypes) => {
    const recursiveUpdate = (
      currentRule: TargetingCriteria,
      k: string,
    ): TargetingCriteria => {
      if (isCriterionSet(currentRule)) {
        if (currentRule.name === name) {
          return {
            ...currentRule,
            criteria: [
              ...currentRule.criteria,
              criterion.type === 'attribute'
                ? newAttributeCriterion(criterion)
                : criterion.type === 'segment'
                ? newSegmentCriterion()
                : newCriterionSet(`${k}.${currentRule.criteria.length}`),
            ],
          };
        }
        return {
          ...currentRule,
          criteria: currentRule.criteria.map((r, i) =>
            recursiveUpdate(r, `${k}.${i}`),
          ),
        };
      }
      return currentRule;
    };
    const newValue = recursiveUpdate(targeting, 'group');

    onChange?.(newValue);
  };

  const onUpdate = (
    name: string,
    newValue: Partial<Criterion | CriterionSet>,
  ) => {
    const recursiveUpdate = (
      currentRule: TargetingCriteria,
    ): TargetingCriteria => {
      if (isCriterionSet(currentRule)) {
        if (currentRule.name === name) {
          return {
            ...currentRule,
            ...newValue,
          };
        }
        return {
          ...currentRule,
          criteria: currentRule.criteria.map(r => recursiveUpdate(r)),
        };
      }
      if (currentRule.name === name) {
        return {
          ...currentRule,
          ...newValue,
        } as Criterion;
      }

      return currentRule;
    };
    const newState = recursiveUpdate(targeting);
    onChange?.(newState);
  };

  const onDelete = (name: string) => {
    const recursiveUpdate = (
      currentRule: TargetingCriteria,
      k: string,
    ): TargetingCriteria => {
      if (isCriterionSet(currentRule)) {
        return {
          ...currentRule,
          name: k,
          criteria: currentRule.criteria
            .filter(r => r.name !== name)
            .map((r, i) => recursiveUpdate(r, `${k}.${i}`)),
        };
      }
      return currentRule;
    };

    const newState = recursiveUpdate(targeting, 'group');
    onChange?.(newState);
  };

  return {
    onDelete,
    onUpdate,
    onAdd,
  };
};

export type TargetingCriteriaState = ReturnType<typeof useTargetingCriteria>;
