import { WorkflowV1WorkflowInstanceModuleDataEntryInput } from '@spotify-confidence/plugin-graphql';

export const getWorkflowName = (id: string) => `workflows/${id}`;

export const mergeModuleData = (
  moduleData: WorkflowV1WorkflowInstanceModuleDataEntryInput[],
  newModuleData: WorkflowV1WorkflowInstanceModuleDataEntryInput[],
) => {
  return [
    ...moduleData
      .concat(newModuleData || [])
      .reduce(
        (map, data) =>
          map.set(data.key, {
            ...data,
            value: { ...(map.get(data.key)?.value || {}), ...data.value },
          }),
        new Map(),
      )
      .values(),
  ];
};
