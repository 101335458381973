import React from 'react';

import { RecommendationCard } from '@spotify-confidence/core-react';

import { SuccessStatus } from '../../result';

export function SuccessCondition({ status }: { status: SuccessStatus }) {
  switch (status) {
    case SuccessStatus.ALL_POWERED_NONE_SIGNIFICANT:
    case SuccessStatus.NONE_SIGNIFICANT:
      return (
        <RecommendationCard.CriteriaItem status="pending">
          No success metric has significantly improved.
        </RecommendationCard.CriteriaItem>
      );
    case SuccessStatus.SOME_POWERED_SIGNIFICANT:
    case SuccessStatus.SOME_UNPOWERED_SIGNIFICANT:
      return (
        <RecommendationCard.CriteriaItem status="success">
          At least one success metric has significantly improved.
        </RecommendationCard.CriteriaItem>
      );
    case SuccessStatus.ALL_POWERED_UNKNOWN_SIGNIFICANCE:
      return (
        <RecommendationCard.CriteriaItem status="success">
          All success metrics are powered.
        </RecommendationCard.CriteriaItem>
      );
    case SuccessStatus.SOME_UNPOWERED_UNKNOWN_SIGNIFICANCE:
      return (
        <RecommendationCard.CriteriaItem status="warning">
          At least one success metrics isn't powered yet.
        </RecommendationCard.CriteriaItem>
      );
    case SuccessStatus.SOME_MISSING:
      return (
        <RecommendationCard.CriteriaItem status="pending">
          At least one success metric doesn't have data yet.
        </RecommendationCard.CriteriaItem>
      );
    case SuccessStatus.WAIT_FOR_END:
      return (
        <RecommendationCard.CriteriaItem status="pending">
          Success metric results are made available when you end the experiment.
        </RecommendationCard.CriteriaItem>
      );
    case SuccessStatus.NO_SUCCESS_METRIC:
      return null;
    default:
      return (
        <RecommendationCard.CriteriaItem status="error">
          Unknown status
        </RecommendationCard.CriteriaItem>
      );
  }
}
