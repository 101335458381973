import React from 'react';
import { useSearchParams } from 'react-router-dom';

import { Box, IconButton, Typography, makeStyles } from '@material-ui/core';
import ChatBubbleOutlineIcon from '@material-ui/icons/ChatBubbleOutline';

import {
  ConfidenceLogo,
  ConfidenceWebsiteLink,
  Hidden,
} from '@spotify-confidence/core-react';

import { Link } from '@backstage/core-components';
import { BackstageTheme } from '@backstage/theme';

import { AccountSwitcher } from './AccountSwitcher';
import { DemoModeChip } from './DemoModeChip';
import { SearchBar } from './search/SearchBar';

export const HEADER_HEIGHT = 65;
const useHeaderStyles = makeStyles((theme: BackstageTheme) => ({
  root: {
    display: 'flex',
    flexFlow: 'row nowrap',
    alignItems: 'center',
    position: 'fixed',
    gap: theme.spacing(2),
    top: 0,
    left: 0,
    right: 0,
    height: HEADER_HEIGHT,
    background: theme.palette.navigation.background,
    color: theme.palette.navigation.selectedColor,
    zIndex: theme.zIndex.appBar,
    padding: theme.spacing(2, 2.5),
    borderBottom: `1px solid ${theme.palette.divider}`,
    '& +div main': {
      paddingTop: HEADER_HEIGHT,
    },
    '& +div > nav > div > div:first-child, & +div > nav > div > div > [data-testid*="item-with-submenu"] > div:last-child':
      {
        marginTop: HEADER_HEIGHT,
      },
  },
  logoWrapper: {
    display: 'flex',
    minWidth: 190,
    [theme.breakpoints.down('sm')]: {
      minWidth: 40,
    },
    gap: theme.spacing(2),
  },
  link: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    gap: theme.spacing(1.5),
  },
  logo: {
    fontSize: 26,
  },
}));

export const Header = ({ to }: { to: string }) => {
  const classes = useHeaderStyles();
  const [_s, setSearchParams] = useSearchParams();
  const openChat = () => {
    setSearchParams({ chat: 'open' });
    // @ts-ignore
    global.window?.HubSpotConversations?.widget?.load({ widgetOpen: true });
  };
  return (
    <header className={classes.root}>
      <Box className={classes.logoWrapper}>
        <Box>
          <Link
            to={to}
            underline="none"
            className={classes.link}
            aria-label="Home"
            color="inherit"
          >
            <ConfidenceLogo color="inherit" className={classes.logo} />
            <Hidden xsDown>
              <Typography variant="h6" component="h1">
                Confidence
              </Typography>
            </Hidden>
          </Link>
        </Box>
        <DemoModeChip />
      </Box>
      <Box display="flex" justifyContent="flex-start" flex={1}>
        <SearchBar />
      </Box>
      <Box
        marginLeft="auto"
        marginRight={0.5}
        display="flex"
        alignItems="center"
        gridGap={12}
      >
        <Hidden xsDown>
          <ConfidenceWebsiteLink
            aria-label="Documentation"
            route="/docs"
            color="inherit"
          >
            Documentation
          </ConfidenceWebsiteLink>
        </Hidden>
        <Hidden smDown>
          <ConfidenceWebsiteLink aria-label="Blog" route="/blog">
            Blog
          </ConfidenceWebsiteLink>
        </Hidden>

        <IconButton size="small" onClick={openChat} color="inherit">
          <ChatBubbleOutlineIcon />
        </IconButton>

        <AccountSwitcher />
      </Box>
    </header>
  );
};
