import React from 'react';

import { Button } from '@material-ui/core';

import { EmptyState } from '@backstage/core-components';

export const MissingDatawarehouse = () => {
  return (
    <EmptyState
      missing="info"
      title="No Data Warehouse configured"
      description="Before you can create tables and metrics you need to set up a connection to your Data Warehouse in the management console."
      action={
        <Button
          variant="contained"
          color="primary"
          href="/admin/metrics-datawarehouse"
          target="_blank"
        >
          Configure Data Warehouse
        </Button>
      }
    />
  );
};
