import { useCommentState } from './useCommentState';

/**
 * Return the comments that are open for a given zone.
 *
 * @param zoneId
 * @returns
 */
export const useOpenCommentsForZone = (zone: string) => {
  const comments = useCommentState(state => state.comments);
  return comments
    .filter(comment => !comment.resolved)
    .filter(comment => comment.zone === zone);
};
