import React from 'react';

import { makeStyles, styled } from '@material-ui/core';

import classNames from 'classnames';

import { CreateNewOption } from './CreateNewOption';

type UseAutocompleteCreateOptions = {
  label?: string;
  onCreate?: (name?: string) => void;
  createName?: string;
};

const useStyles = makeStyles({
  canCreate: {
    padding: 0,
  },
});

const ListBox = styled('ul')({
  padding: 0,
  position: 'relative',
});

/*
  Use this hook to render a "Create new" option in the Autocomplete component
  through props.
*/

export const useAutocompleteCreateOption = ({
  label = 'Create',
  createName,
  onCreate,
}: UseAutocompleteCreateOptions) => {
  const classes = useStyles();
  const canCreate = !!onCreate;

  const handleCreate = React.useCallback(() => {
    if (onCreate) {
      onCreate(createName);
    }
  }, [onCreate, createName]);

  const createNewProps = React.useMemo(
    () => ({
      onMouseDown: handleCreate,
      label: label,
    }),
    [handleCreate, label],
  );

  // Add a "Create new" option to the list if onCreate is provided
  const ListboxComponent = React.useCallback(
    React.forwardRef<HTMLUListElement, React.HTMLAttributes<HTMLUListElement>>(
      ({ children: listBoxChildren, ...listBoxProps }, ref) => {
        return (
          <ListBox {...listBoxProps} ref={ref}>
            {listBoxChildren}
            {canCreate ? <CreateNewOption {...createNewProps} /> : null}
          </ListBox>
        );
      },
    ),
    [canCreate, createNewProps],
  );

  return {
    ListboxComponent,
    noOptionsText: canCreate ? (
      <CreateNewOption {...createNewProps} />
    ) : undefined,
    classes: {
      noOptions: classNames({ [classes.canCreate]: canCreate }),
    },
  };
};
