import React from 'react';
import { useDebounce } from 'react-use';

import {
  Box,
  Button,
  CircularProgress,
  Collapse,
  TextField,
  Typography,
} from '@material-ui/core';
import { Alert, AlertTitle } from '@material-ui/lab';

import { FormFieldsBox } from '@spotify-confidence/core-react';
import {
  ConnectorsV1SnowflakeConfigInput,
  ConnectorsV1EventConnectionSnowflakeEventConnectionConfigInput as SnowflakeInput,
  getTypeOrNull,
  useValidateSnowflakeEventConnectionConfigMutation,
} from '@spotify-confidence/plugin-graphql';

import {
  SnowflakeConnectionForm,
  canSendValidationRequest,
} from './SnowflakeConnectionForm';
import { hasConfigurationThatAffectsValidationChanged } from './helpers';

type SnowflakeEventConnectionFormProps = {
  value: SnowflakeInput;
  onChange: (conf: SnowflakeInput) => void;
  disabled?: boolean;
};

export const SnowflakeEventConnectionForm = ({
  value,
  onChange,
  disabled,
}: SnowflakeEventConnectionFormProps) => {
  const prevValue = React.useRef(value);

  const [validate, validation] =
    useValidateSnowflakeEventConnectionConfigMutation({
      variables: {
        snowflake: value?.snowflakeConfig,
      },
    });
  const account = value?.snowflakeConfig.account ?? '';
  const user = value?.snowflakeConfig.user ?? '';
  const role = value?.snowflakeConfig?.role ?? '';
  const database = value?.snowflakeConfig.database ?? '';
  const schema = value?.snowflakeConfig.schema ?? '';
  const canValidate = canSendValidationRequest(
    value?.snowflakeConfig ?? undefined,
  );
  const validateEventConnectionConfig = getTypeOrNull(
    validation.data?.validateEventConnectionConfig,
    'ConnectorsV1ValidateEventConnectionConfigResponse',
  );
  const validateEventConnectionConfigError = getTypeOrNull(
    validation.data?.validateEventConnectionConfig,
    'Error',
  )?.message;

  const handleConfigChange = (
    snowflakeConfig: ConnectorsV1SnowflakeConfigInput,
  ) =>
    onChange({
      ...value,
      snowflakeConfig,
    });

  const handleValidate = async () => {
    try {
      await validate();
    } catch (e) {
      //   console.log(e);
    }
  };

  useDebounce(
    () => {
      if (
        canValidate &&
        hasConfigurationThatAffectsValidationChanged(
          value?.snowflakeConfig ?? undefined,
          prevValue.current?.snowflakeConfig ?? undefined,
        ) &&
        value
      ) {
        validate();
      } else if (!canValidate) {
        validation.reset();
      }
      prevValue.current = value;
    },
    1000,
    [value, canValidate],
  );

  return (
    <FormFieldsBox>
      <SnowflakeConnectionForm
        value={value?.snowflakeConfig}
        onChange={handleConfigChange}
        validating={validation.loading}
        validationError={validation.error}
        validationStatus={
          <>
            <Collapse in={validation.loading} unmountOnExit>
              <Box display="flex" alignItems="center" gridGap={8}>
                <CircularProgress size="2em" />
                <Typography color="textSecondary">
                  Getting available options from Snowflake...
                </Typography>
              </Box>
            </Collapse>
            {validateEventConnectionConfigError && (
              <Alert
                severity="error"
                action={
                  canValidate && <Button onClick={handleValidate}>Retry</Button>
                }
              >
                <AlertTitle>Validation failed</AlertTitle>
                {validateEventConnectionConfigError}
              </Alert>
            )}
            {validation.error && (
              <Alert
                severity="error"
                action={
                  canValidate && <Button onClick={handleValidate}>Retry</Button>
                }
              >
                <AlertTitle>Validation failed</AlertTitle>
                {validation.error.message}
              </Alert>
            )}
          </>
        }
        snowflakeConfigValidation={
          validateEventConnectionConfig?.snowflakeConfigValidation
        }
      />

      <TextField
        label="Table prefix"
        helperText="An optional prefix to use for the tables created by this connector."
        value={value?.tablePrefix ?? ''}
        disabled={
          disabled || !database || !user || !role || !schema || !account
        }
        error={!!validation.error}
        onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
          onChange({
            ...value,
            tablePrefix: e.target.value,
          })
        }
        variant="outlined"
      />
    </FormFieldsBox>
  );
};
