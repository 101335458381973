import loadable from '@loadable/component';

const componentsChunk = () =>
  import(/* webpackChunkName: "metrics-result-components" */ './components');

export const ResultsPage = loadable(() =>
  componentsChunk().then(module => module.ResultsPage),
);

export const ResultSection = loadable(() =>
  componentsChunk().then(module => module.ResultSection),
);

export const MetricsResultSection = loadable(() =>
  componentsChunk().then(module => module.MetricsResultSection),
);

export * as resultUtils from './components/Results/utils';
export * from './components/Results/types';
