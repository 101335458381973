import React from 'react';

import { Typography } from '@material-ui/core';

import {
  Breadcrumbs,
  FilterListProvider,
  PageLayout,
} from '@spotify-confidence/core-react';

import { Link } from '@backstage/core-components';
import { useRouteRef } from '@backstage/core-plugin-api';

import { rootRouteRef } from '../../../routes';
import { GroupList } from './GroupList';

export const GroupIndexPage = () => {
  const adminRoute = useRouteRef(rootRouteRef);
  return (
    <FilterListProvider storageKey="groups">
      <PageLayout
        narrow
        title="Groups"
        headerBreadcrumbs={
          <Breadcrumbs>
            <Link to={adminRoute()}>Admin</Link>
            <Typography>Groups</Typography>
          </Breadcrumbs>
        }
      >
        <GroupList />
      </PageLayout>
    </FilterListProvider>
  );
};
