import React from 'react';

import { SvgIconProps } from '@material-ui/core';
import AccessTimeIcon from '@material-ui/icons/AccessTime';
import EmojiFlagsIcon from '@material-ui/icons/EmojiFlags';
import EventIcon from '@material-ui/icons/Event';
import FingerprintIcon from '@material-ui/icons/Fingerprint';
import HelpOutline from '@material-ui/icons/HelpOutline';
import StyleIcon from '@material-ui/icons/Style';
import TextFieldsIcon from '@material-ui/icons/TextFields';
import ToggleOnIcon from '@material-ui/icons/ToggleOn';
import TonalityIcon from '@material-ui/icons/Tonality';

import { IntIcon } from '@spotify-confidence/core-react';

import { AttributeType } from '../../domain/targeting';
import { SemanticType } from '../../domain/targeting/targeting.model';

export const AttributeIcon = ({
  type,
  ...rest
}: { type: AttributeType | SemanticType } & SvgIconProps) =>
  ({
    Boolean: <ToggleOnIcon {...rest} />,
    String: <TextFieldsIcon {...rest} />,
    'Country code': <EmojiFlagsIcon {...rest} />,
    Enum: <StyleIcon {...rest} />,
    Entity: <FingerprintIcon {...rest} />,
    Number: <IntIcon {...rest} />,
    Version: <TonalityIcon {...rest} />,
    Date: <EventIcon {...rest} />,
    Timestamp: <AccessTimeIcon {...rest} />,
    Any: <HelpOutline {...rest} />,
  }[type] || null);
