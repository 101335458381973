import React from 'react';

import { Typography } from '@material-ui/core';

import { CodeSnippet } from '../../CodeSnippet/CodeSnippet';
import { f } from '../../CodeSnippet/format';

export const PythonClientSnippet = () => {
  return (
    <>
      <Typography variant="body1" gutterBottom>
        Let's track a "tutorial-event" event from your App and make sure it gets
        published to our backend. In this particular example we are waiting for
        the track event to be sent to the Confidence backend before allowing the
        program to progress.
      </Typography>
      <CodeSnippet
        language="python"
        showLineNumbers
        text={f(`
confidence.track("tutorial-event", {})
  `)}
        showCopyCodeButton
      />
      <Typography variant="body1" gutterBottom>
        Alternatively, a coroutine could be spawned to send a track event in the
        background. For this function to be working, we need the function to be
        running inside of a asyncio.run(...) context.
      </Typography>
      <CodeSnippet
        language="python"
        showLineNumbers
        text={f(`
confidence.track_async("tutorial-event", {})
  `)}
        showCopyCodeButton
      />
    </>
  );
};
