import React from 'react';
import { InView } from 'react-intersection-observer';
import { useNavigate, useParams } from 'react-router-dom';

import {
  Button,
  Table,
  TableBody,
  TableCell,
  TableRow,
} from '@material-ui/core';
import AddIcon from '@material-ui/icons/Add';

import {
  ACTION_BAR_HEIGHT,
  ActionBar,
  ConfidenceWebsiteLink,
  FilterBar,
  FilterListEmptyState,
  FilterListProvider,
  PageLayout,
  ResourceTableRow,
  StickyTableContainer,
  StickyTableHead,
  extractLastNameComponent,
  useDialog,
  useListFilter,
  useMeasureDimensions,
  useSearchParamsConfig,
} from '@spotify-confidence/core-react';
import {
  EntityRowFragment,
  getError,
  getTypeOrNull,
  useEntitiesQuery,
} from '@spotify-confidence/plugin-graphql';
import { useCheckResourcePermissions } from '@spotify-confidence/plugin-permissions-react';

import { Link } from '@backstage/core-components';
import { useRouteRef } from '@backstage/core-plugin-api';

import { entitiesRouteRef, entityRouteRef } from '../../../routes';
import { useFormattedEntityType } from '../../domain/useFormattedEntityType.hook';
import { CreateEntity } from './components/CreateEntity';
import ViewEntityPanel from './components/ViewEntityPanel';

type EntityListProps = {
  pageSize?: number;
  pageToken?: string;
  filter?: string;
  createEntity?: () => void;
  topOffset?: number;
};

const EntityList = ({
  pageSize = 25,
  pageToken = '',
  createEntity,
  topOffset = ACTION_BAR_HEIGHT,
}: EntityListProps) => {
  const { filterString: filter } = useListFilter();

  const { data, loading, error, fetchMore } = useEntitiesQuery({
    variables: {
      filter,
      pageSize,
      pageToken,
    },
    fetchPolicy: 'cache-and-network',
  });
  const entitiesResponse = getTypeOrNull(
    data?.entities,
    'MetricsV1ListEntitiesResponse',
  );
  const entities = entitiesResponse?.entities ?? [];

  if (entities.length === 0 && !loading) {
    return (
      <FilterListEmptyState
        loading={loading}
        error={getError(data?.entities) || error}
        type="entity"
        typePlural="entities"
        description={
          <>
            Entities are used to map your table columns to a measurable unit,
            like a user.{' '}
            <ConfidenceWebsiteLink route="/docs/entities" underline="always">
              Learn more about entities.
            </ConfidenceWebsiteLink>
          </>
        }
        onCreate={createEntity}
      />
    );
  }

  return (
    <StickyTableContainer loading={loading} error={error}>
      {data && (
        <>
          <Table>
            <StickyTableHead topOffset={topOffset}>
              <TableRow>
                <TableCell>Name</TableCell>
                <TableCell>Primary key type</TableCell>
              </TableRow>
            </StickyTableHead>
            <TableBody>
              {entities.map(entity => (
                <EntityRow entity={entity} key={entity.name} />
              ))}
            </TableBody>
          </Table>
        </>
      )}
      <InView
        onChange={async inView => {
          if (inView && entitiesResponse?.nextPageToken) {
            await fetchMore({
              variables: {
                pageToken: entitiesResponse.nextPageToken,
              },
            });
          }
        }}
      />
    </StickyTableContainer>
  );
};

function EntityRow({ entity }: { entity: EntityRowFragment }) {
  const typeLabel = useFormattedEntityType(entity.primaryKeyType);
  const entityRoute = useRouteRef(entityRouteRef);
  return (
    <ResourceTableRow routeRef={entityRouteRef} name={entity.name}>
      <TableCell>
        <Link
          to={entityRoute({
            id: extractLastNameComponent(entity.name)!,
          })}
        >
          {entity.displayName}
        </Link>
      </TableCell>
      <TableCell>{typeLabel}</TableCell>
    </ResourceTableRow>
  );
}

export const EntitiesPage = () => {
  const entitiesRoute = useRouteRef(entitiesRouteRef);
  const { openDialog } = useDialog();
  const navigate = useNavigate();
  const [ref, { height }] = useMeasureDimensions<HTMLDivElement>();
  const { id } = useParams();

  const { allowed } = useCheckResourcePermissions({
    can: 'create_entity',
    name: 'account',
  });

  function createEntity(name?: string) {
    openDialog({
      content: <CreateEntity displayName={name} />,
    });
  }

  React.useEffect(() => {
    if (id) {
      openDialog({
        content: <ViewEntityPanel id={id} />,
        onClose: () => {
          navigate(entitiesRoute());
        },
      });
    }
  }, [id]);

  useSearchParamsConfig({
    params: ['create', 'name'],
    handleParams: params => {
      if (params.create === 'true') {
        createEntity(params.name);
      }
    },
  });

  return (
    <FilterWrapper>
      <PageLayout title="Entities" scrollButton narrow>
        <ActionBar ref={ref}>
          <FilterBar />
          <Button
            variant="contained"
            color="primary"
            size="small"
            onClick={() => createEntity()}
            startIcon={<AddIcon />}
            data-testid="create-entity-button"
            disabled={!allowed}
          >
            Create
          </Button>
        </ActionBar>
        <EntityList
          createEntity={allowed ? createEntity : undefined}
          topOffset={height}
        />
      </PageLayout>
    </FilterWrapper>
  );
};

function FilterWrapper({ children }: React.PropsWithChildren<{}>) {
  return (
    <FilterListProvider
      children={children}
      searchField="displayName"
      storageKey="entities"
    />
  );
}
