import React from 'react';
import { useNavigate } from 'react-router-dom';

import { Button, CircularProgress, Grid, Typography } from '@material-ui/core';
import AddIcon from '@material-ui/icons/Add';
import DeleteIcon from '@material-ui/icons/Delete';
import { Alert } from '@material-ui/lab';

import {
  DangerBox,
  DangerButton,
  DangerZone,
  DialogBody,
  DialogHeader,
  GridSkeleton,
  useDialog,
} from '@spotify-confidence/core-react';
import { FeatureFlagged } from '@spotify-confidence/core-react';
import {
  MetricsV1ColumnType,
  getError,
  getTypeOrNull,
  useDeleteEntityMutation,
  useGetEntityQuery,
} from '@spotify-confidence/plugin-graphql';
import {
  PermissionUtils,
  useCheckResourcePermissions,
} from '@spotify-confidence/plugin-permissions-react';

import { useRouteRef } from '@backstage/core-plugin-api';

import { entityRelationTablesCreateRouteRef } from '../../../../routes';
import { useFormattedEntityType } from '../../../domain/useFormattedEntityType.hook';
import EntityRelationTable from './EntityRelationTable';

export default function ViewEntityPanel({ id }: { id: string }) {
  const name = `entities/${id}`;
  const entityRelationTableCreateRoute = useRouteRef(
    entityRelationTablesCreateRouteRef,
  )({ parentEntityId: id });
  const navigate = useNavigate();
  const { closeDialog } = useDialog();
  const [deleteEntity, { loading: isDeleting }] = useDeleteEntityMutation({
    variables: { name },
    update: cache => {
      cache.modify({
        fields: {
          entities: (existingEntities = {}) => {
            return {
              ...existingEntities,
              entities: existingEntities.entities?.filter(
                (e: { __ref: string }) => !e.__ref?.includes(name),
              ),
            };
          },
        },
      });
    },
    onCompleted: () => closeDialog(),
  });
  const { data, error, loading } = useGetEntityQuery({ variables: { name } });

  const { allowed: canCreateEntityRelationTable } = useCheckResourcePermissions(
    {
      can: 'create_entity_relation_table',
      name: 'account',
    },
  );

  function createEntityRelationTable() {
    closeDialog();
    navigate(entityRelationTableCreateRoute);
  }
  const entity = getTypeOrNull(data?.entity, 'MetricsV1Entity');
  const canEdit = PermissionUtils.hasRelation(entity, 'can_edit');

  const entityError = getError(data?.entity);
  return (
    <>
      <DialogHeader
        title={
          getTypeOrNull(data?.entity, 'MetricsV1Entity')?.displayName ?? ''
        }
        onClose={closeDialog}
      />
      <DialogBody autoHeight>
        <Grid container spacing={2}>
          {error && (
            <Grid item xs={12}>
              <Alert severity="error">{error.message}</Alert>
            </Grid>
          )}
          {entityError && (
            <Grid item xs={12}>
              <Alert severity="error">{entityError.message}</Alert>
            </Grid>
          )}
          {loading && <Loading />}
          {entity && <EntityDetails entity={entity} />}
          <FeatureFlagged with="entity-relation-tables">
            <Grid item xs={12}>
              <EntityRelationTable id={id} />
            </Grid>

            <Grid item xs={12}>
              <Button
                variant="contained"
                color="primary"
                size="small"
                onClick={createEntityRelationTable}
                disabled={!canCreateEntityRelationTable}
                startIcon={<AddIcon />}
                data-testid="create-entity-relation-table-button"
              >
                Create
              </Button>
            </Grid>
          </FeatureFlagged>
          <Grid item xs={12}>
            <DangerZone>
              <DangerBox>
                <strong>Delete entity</strong>
                <DangerButton
                  startIcon={<DeleteIcon />}
                  variant="contained"
                  disabled={isDeleting || !canEdit}
                  color="inherit"
                  endIcon={isDeleting && <CircularProgress size="1em" />}
                  onClick={() => deleteEntity()}
                >
                  Delete
                </DangerButton>
              </DangerBox>
            </DangerZone>
          </Grid>
        </Grid>
      </DialogBody>
    </>
  );
}

function EntityDetails({
  entity,
}: {
  entity: {
    name: string;
    displayName: string;
    primaryKeyType: MetricsV1ColumnType;
  };
}) {
  const typeLabel = useFormattedEntityType(entity.primaryKeyType);

  return (
    <>
      <Grid item xs={12}>
        <Typography variant="overline" color="textSecondary">
          Name
        </Typography>
        <Typography paragraph variant="body2">
          {entity.displayName}
        </Typography>
      </Grid>
      <Grid item xs={12}>
        <Typography variant="overline" color="textSecondary">
          Primary Key Type
        </Typography>
        <Typography paragraph variant="body2">
          {typeLabel}
        </Typography>
      </Grid>
    </>
  );
}

function Loading() {
  return (
    <GridSkeleton
      gridProps={[
        {
          xs: 6,
          item: true,
        },
        {
          xs: 6,
          item: true,
        },
      ]}
    />
  );
}
