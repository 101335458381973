/**
 * * 📅 Dates lib with mixed feelings v0.0
 *
 * TODO: CONSIDER IF WE SHOULD MIGRATE TO LUXON TO ALIGN WITH BACKSTAGE ADR: https://backstage.io/docs/architecture-decisions/adrs-adr010
 *
 * ? ---
 * ? 📦 Depends on date-fns, @date-io/date-fns
 * ? 🤷🏽‍♂️ EXPORTED but not in use yet ?
 */
import DateFnsUtils from '@date-io/date-fns';
import {
  Locale,
  addMonths,
  endOfWeek,
  format,
  isValid,
  startOfWeek,
} from 'date-fns';
import formatDistanceToNowStrict from 'date-fns/formatDistanceToNowStrict';
import isWithinIntervalFp from 'date-fns/fp/isWithinInterval';
import defaultLocale from 'date-fns/locale/en-GB';
import locale from 'date-fns/locale/en-US';

export const DATE_FORMAT = 'yyyy-MM-dd';

export const DATE_TIME_FORMAT = 'yyyy-MM-dd HH:mm:ss';
export const SHORT_DATE_TIME_FORMAT = 'yyyy-MM-dd HH:mm';
export const HUMAN_DATE_FORMAT = 'd MMMM yyyy';
export const HUMAN_DATE_FORMAT_COMPACT = 'dd MMM yyyy';
export const SHORT_DATE_FORMAT = 'MMM dd';
export const MONTH_DAY_FORMAT = 'MMM d';

/**
 * toDateString
 *
 * @param timestamp
 * @param formatting
 */
export function toDateString(timestamp: Date, formatting?: string): string;
export function toDateString(
  timestamp: string | number,
  formatting?: string,
): string | undefined;
export function toDateString(
  timestamp: string | number | Date,
  formatting: string = DATE_FORMAT,
): string | undefined {
  const date =
    typeof timestamp === 'number' || typeof timestamp === 'string'
      ? new Date(timestamp)
      : timestamp;
  return isValid(date) ? format(date, formatting) : undefined;
}

/**
 * toTimeStamp
 *
 * @param dateString
 * @returns number
 */
export const toTimeStamp = (dateString: string): number =>
  new Date(dateString).getTime();

/**
 * xAgo Format
 *
 * @param target
 * @returns string
 */
export const xAgo = (target: Date): string => {
  return formatDistanceToNowStrict(target, {
    addSuffix: true,
  });
};

/**
 * xAgoShort Format
 *
 * @param target
 * @returns string
 */

// https://github.com/date-fns/date-fns/issues/1706#issuecomment-836601089
const shortFormat = {
  lessThanXSeconds: '{{count}}s',
  xSeconds: '{{count}}s',
  halfAMinute: '30s',
  lessThanXMinutes: '{{count}}m',
  xMinutes: '{{count}}m',
  aboutXHours: '{{count}}h',
  xHours: '{{count}}h',
  xDays: '{{count}}d',
  aboutXWeeks: '{{count}}w',
  xWeeks: '{{count}}w',
  aboutXMonths: '{{count}}m',
  xMonths: '{{count}}m',
  aboutXYears: '{{count}}y',
  xYears: '{{count}}y',
  overXYears: '{{count}}y',
  almostXYears: '{{count}}y',
};

const formatDistance: Locale['formatDistance'] = (
  token: keyof typeof shortFormat,
  count,
  options = {},
) => {
  const result = shortFormat[token].replace('{{count}}', count);

  if (options.addSuffix) {
    if (options.comparison > 0) {
      return `In ${result}`;
    } else if (options.comparison < 0) {
      return `${result} ago`;
    }
    return 'Now';
  }

  return result;
};

export const xAgoShort = (target: Date): string => {
  return formatDistanceToNowStrict(target, {
    addSuffix: true,
    locale: {
      ...locale,
      formatDistance,
    },
  });
};

/**
 * DateUtils Class
 *
 * * Implementation is influenced by the Calendar component.
 */
export class DateUtils extends DateFnsUtils {
  locale: Locale;

  constructor(args: { locale?: Locale }) {
    super({ locale: args.locale });
    this.locale = args.locale || defaultLocale;
  }

  // Week helpers
  // ------------
  isStartOfWeek(date: Date) {
    return super.isSameDay(date, startOfWeek(date, { locale: this.locale }));
  }

  isEndOfWeek(date: Date) {
    return super.isSameDay(date, endOfWeek(date, { locale: this.locale }));
  }

  // Interval helpers
  // ----------------
  isWithinIntervalFp(compareTo: Interval) {
    return isWithinIntervalFp(compareTo);
  }

  isSubIntervalFp(compareTo: Interval) {
    const isWithin = this.isWithinIntervalFp(compareTo);
    return (interval: Interval) =>
      isWithin(interval.start) && isWithin(interval.end);
  }

  isStartOfIntervalFp(interval: Interval) {
    return (date: Date) => super.isSameDay(date, interval.start as Date);
  }

  isEndOfIntervalFp(compareTo: Interval) {
    return (date: Date) =>
      (!compareTo.end && super.isSameDay(date, compareTo.start as Date)) || // the case of 1 day interval
      super.isSameDay(date, compareTo.end as Date);
  }

  isSameIntervalFp(compareTo: Interval) {
    return (interval: Interval) =>
      super.isSameDay(interval.start as Date, compareTo.start as Date) &&
      super.isSameDay(interval.end as Date, compareTo.end as Date);
  }

  // Formatters
  // ----------
  private getShortDate(start: Date) {
    return super.format(start as Date, SHORT_DATE_FORMAT);
  }

  formatInterval({ start = 0, end = 0 }: Interval) {
    if (!start) {
      return 'Select a date range';
    }

    try {
      const [startDay, startYear] = [
        this.getShortDate(start as Date),
        super.getYearText(start as Date),
      ];

      // handle open intervals i.e. end date is not set yet
      const [endDay, endYear] = !end
        ? ['', '']
        : [this.getShortDate(end as Date), super.getYearText(end as Date)];

      // the case of different dates
      // @example:
      if (startYear !== endYear) {
        return `${startDay}, ${startYear} — ${endDay}, ${endYear}`;
      }

      // the case of exact date
      // @example:
      if (startDay === endDay) {
        return `${startDay}, ${startYear}`;
      }

      // the case of different dates in the same year
      // @example:
      return `${startDay} — ${endDay}, ${startYear}`;
    } catch (error) {
      return 'interval format error';
    }
  }

  get presets() {
    return {
      /* Current */
      startOfToday: super.startOfDay(new Date()),
      endOfToday: super.endOfDay(new Date()),
      startOfThisWeek: startOfWeek(new Date(), { locale: this.locale }),
      startOfThisMonth: super.startOfMonth(new Date()),

      /* Previous */
      startOfYesterday: super.startOfDay(super.addDays(new Date(), -1)),
      endOfYesterday: super.endOfDay(super.addDays(new Date(), -1)),

      startOfLastWeek: startOfWeek(super.addDays(new Date(), -7), {
        locale: this.locale,
      }),
      endOfLastWeek: endOfWeek(super.addDays(new Date(), -7), {
        locale: this.locale,
      }),

      startOfLastMonth: super.startOfMonth(addMonths(new Date(), -1)),
      endOfLastMonth: super.endOfMonth(addMonths(new Date(), -1)),

      /* X Days Ago */
      daysAgo7: super.addDays(new Date(), -7),
      daysAgo14: super.addDays(new Date(), -14),
      daysAgo30: super.addDays(new Date(), -30),
    };
  }
}
