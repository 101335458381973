import React from 'react';

import { DialogActions, Typography } from '@material-ui/core';
import { Alert } from '@material-ui/lab';

import {
  DialogBody,
  DialogHeader,
  FormSubmitButtons,
  useAlert,
  useDialog,
} from '@spotify-confidence/core-react';
import {
  Query,
  evictTypeFields,
  getError,
  useAllocateSegmentMutation,
} from '@spotify-confidence/plugin-graphql';

export const AllocateDialog = ({ name }: { name: string }) => {
  const { closeDialog } = useDialog();
  const alert = useAlert();
  const [allocate, { loading, error }] = useAllocateSegmentMutation({
    variables: {
      name,
    },
    update: cache => {
      evictTypeFields<Query>(cache, 'segments');
    },
    onCompleted: response => {
      const responseError = getError(response.allocateSegment);
      if (responseError) {
        alert.post({
          message: responseError.message,
          severity: 'error',
        });
      } else {
        alert.post({
          message: 'The segment has been allocated',
          severity: 'success',
          display: 'transient',
        });
        closeDialog();
      }
    },
  });
  return (
    <div data-testid="allocate-dialog">
      <DialogHeader title="Allocate segment?" />
      <DialogBody>
        {error && <Alert severity="error">{error.message}</Alert>}
        <Typography variant="body1" gutterBottom>
          The segment is currently unallocated. To use the configured
          exclusivity tags it needs to be allocated.
        </Typography>
      </DialogBody>
      <DialogActions>
        <FormSubmitButtons
          label="Allocate"
          cancelLabel="Skip"
          onCancel={closeDialog}
          loading={loading}
          disabled={loading}
          onSubmit={() => allocate()}
        />
      </DialogActions>
    </div>
  );
};
