import React from 'react';

import { Typography } from '@material-ui/core';

import {
  ConfidenceWebsiteLink,
  FormFieldsBox,
  TutorialWizard,
} from '@spotify-confidence/core-react';

import { CodeSnippets, Instructions } from '../Instructions';
import { ProgrammingLanguage } from '../context';
import { MonitorEvent } from './MonitorEvent';
import {
  AndroidClientSnippets,
  FlutterClientSnippets,
  GoClientSnippet,
  JavaClientSnippets,
  JsClientSnippets,
  JsReactSnippets,
  PythonClientSnippet,
  RustClientSnippet,
  SwiftClientSnippets,
} from './snippets';

const snippets: CodeSnippets = {
  [ProgrammingLanguage.js]: () => <JsClientSnippets />,
  [ProgrammingLanguage.js_react]: () => <JsReactSnippets />,
  [ProgrammingLanguage.java]: () => <JavaClientSnippets />,
  [ProgrammingLanguage.swift]: () => <SwiftClientSnippets />,
  [ProgrammingLanguage.kotlin]: () => <AndroidClientSnippets />,
  [ProgrammingLanguage.go]: () => <GoClientSnippet />,
  [ProgrammingLanguage.flutter]: () => <FlutterClientSnippets />,
  [ProgrammingLanguage.python]: () => <PythonClientSnippet />,
  [ProgrammingLanguage.rust]: () => <RustClientSnippet />,
};

export const EventsStep = ({ eventName }: { eventName: string }) => {
  return (
    <TutorialWizard.Step
      docs={
        <div>
          <Typography variant="subtitle1">Events</Typography>
          <Typography paragraph color="textSecondary" variant="body2">
            To learn more about events, read our{' '}
            <ConfidenceWebsiteLink route="/docs/api/events" underline="always">
              full documentation
            </ConfidenceWebsiteLink>
            .
          </Typography>
        </div>
      }
    >
      <FormFieldsBox>
        <div>
          <TutorialWizard.StepTitle
            title="Instrument your product"
            estimatedTime="5 minutes"
          />
          <Typography paragraph>
            Events let Confidence know when your users do something in your
            product. These events allow you to measure the impact of changes you
            make. Examples of events are button clicks, application crashes or
            page views.
          </Typography>
        </div>

        <div>
          <Typography variant="h5">Track an event</Typography>
          <Instructions snippets={snippets} />
          <MonitorEvent eventName={eventName} />
        </div>
      </FormFieldsBox>
    </TutorialWizard.Step>
  );
};
