import React from 'react';

import {
  Box,
  FormHelperText,
  InputLabel,
  MenuItem,
  TextField,
  TextFieldProps,
} from '@material-ui/core';

import * as timeWindowUtils from './timeWindowUtils';

export type TimeWindowInputProps = {
  label: string;
  disabled?: boolean;
  required?: boolean;
  onChange: (newValue?: string) => void;
  value?: string;
  margin?: TextFieldProps['margin'];
  placeholder?: string;
  id?: string;
};

function getUnit(value?: string): timeWindowUtils.TimeWindowUnit {
  return value
    ? timeWindowUtils.getClosestUnit(timeWindowUtils.getWindowAsSeconds(value))
    : 'seconds';
}

function getAmount(
  unit: timeWindowUtils.TimeWindowUnit,
  value?: string,
): string {
  return value ? String(timeWindowUtils.getWindowForUnit(value, unit)) : '';
}

export function TimeWindowInput({
  label,
  disabled,
  required,
  margin,
  value = '',
  placeholder,
  onChange,
  id,
}: TimeWindowInputProps) {
  const [dirty, setDirty] = React.useState(false);
  const [unit, setUnit] = React.useState<timeWindowUtils.TimeWindowUnit>(
    getUnit(value),
  );
  const [amount, setAmount] = React.useState<string>(getAmount(unit, value));
  const [isEditing, setIsEditing] = React.useState(false);

  const requiredError = required
    ? !isEditing && dirty && value === '0s'
    : false;

  // Update interval values if value was modified from outside
  React.useEffect(() => {
    if (!isEditing) {
      const derivedUnit = getUnit(value);
      const derivedAmount = getAmount(unit, value);
      if (unit !== derivedUnit || amount !== derivedAmount) {
        setUnit(derivedUnit);
        setAmount(derivedAmount);
      }
    }
  }, [value, unit, amount, isEditing]);

  const stopEditing = () => {
    setDirty(true);
    setIsEditing(false);
  };

  const onAmountChange = (newAmount: string) => {
    setIsEditing(true);
    setAmount(newAmount);
    onChange(timeWindowUtils.createWindow(Number(newAmount), unit));
  };

  const onUnitChange = (newUnit: timeWindowUtils.TimeWindowUnit) => {
    setUnit(newUnit);
    onChange(timeWindowUtils.createWindow(Number(amount), newUnit));
  };

  return (
    <Box width="100%">
      {label && (
        <InputLabel required={required} htmlFor={id}>
          {label}
        </InputLabel>
      )}
      <Box mt={label ? 1 : 0} display="flex" gridGap={8}>
        <TextField
          id={id}
          variant="outlined"
          placeholder={placeholder ?? `${label} amount`}
          hiddenLabel
          type="number"
          inputProps={{
            min: 0,
          }}
          disabled={disabled}
          required={required}
          value={amount}
          onChange={e => onAmountChange(e.target.value)}
          onBlur={stopEditing}
          margin={margin}
          error={requiredError}
        />
        <TextField
          variant="outlined"
          onChange={e =>
            onUnitChange(e.target.value as timeWindowUtils.TimeWindowUnit)
          }
          select
          fullWidth
          disabled={disabled}
          value={unit}
          margin={margin}
        >
          {timeWindowUtils.timeWindowUnits.map(option => (
            <MenuItem key={option} value={option}>
              {option}
            </MenuItem>
          ))}
        </TextField>
      </Box>
      {requiredError && (
        <FormHelperText error>
          The duration needs to be longer than 0 seconds.
        </FormHelperText>
      )}
    </Box>
  );
}
