import React from 'react';

import { Content as BackstageContent } from '@backstage/core-components';

import { NarrowBox, NarrowBoxProps } from './NarrowBox';

export type ContentProps = React.PropsWithChildren<{
  narrow?: boolean | NarrowBoxProps['width'];
  className?: string;
}>;

export const Content = ({ children, narrow, className }: ContentProps) => {
  return (
    <BackstageContent className={className}>
      {narrow ? (
        <NarrowBox width={typeof narrow === 'string' ? narrow : undefined}>
          {children}
        </NarrowBox>
      ) : (
        children
      )}
    </BackstageContent>
  );
};
