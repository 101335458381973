import React from 'react';
import { useNavigate, useParams } from 'react-router-dom';

import { Button } from '@material-ui/core';
import AddIcon from '@material-ui/icons/Add';

import loadable from '@loadable/component';
import {
  ActionBar,
  FilterBar,
  FilterListProvider,
  useDialog,
  useMeasureDimensions,
  useSearchParamsConfig,
} from '@spotify-confidence/core-react';
import { ClientFragment } from '@spotify-confidence/plugin-graphql';
import { useCheckResourcePermissions } from '@spotify-confidence/plugin-permissions-react';

import { useRouteRef } from '@backstage/core-plugin-api';

import { clientRouteRef } from '../../../../routes';
import { ClientDialog } from './ClientDialog';
import { ClientsList } from './ClientsList';

const CreateClientDialog = loadable(() =>
  import('./CreateClientDialog').then(m => m.CreateClientDialog),
);

type ClientsTabProps = {
  topOffset?: number;
  clients?: ClientFragment[];
  loading?: boolean;
  error?: Error;
};

export const ClientsTab = ({
  clients = [],
  error,
  loading,
  topOffset = 0,
}: ClientsTabProps) => {
  const clientRoute = useRouteRef(clientRouteRef);
  const navigate = useNavigate();
  const { id } = useParams();
  const { openDialog } = useDialog();
  const [actionBarRef, { height: actionBarHeight }] =
    useMeasureDimensions<HTMLDivElement>();

  const { allowed } = useCheckResourcePermissions({
    can: 'create_client',
    name: 'account',
  });

  const onClientCreated = React.useCallback(
    (_id: string) => navigate(clientRoute({ id: _id })),
    [navigate, clientRoute],
  );

  const createClient = React.useCallback(() => {
    openDialog({
      content: <CreateClientDialog onClientCreated={onClientCreated} />,
    });
  }, [openDialog, onClientCreated]);

  useSearchParamsConfig({
    params: ['create'],
    handleParams: params => {
      if (params.create === 'true') {
        createClient();
      }
    },
  });

  React.useEffect(() => {
    if (id) {
      openDialog({
        content: <ClientDialog id={id} />,
      });
    }
  }, [id]);

  return (
    <FilterWrapper>
      <ActionBar ref={actionBarRef} topOffset={topOffset}>
        <FilterBar />
        <Button
          variant="contained"
          color="primary"
          size="small"
          onClick={createClient}
          data-testid="create-client-button"
          startIcon={<AddIcon />}
          disabled={!allowed}
        >
          Create
        </Button>
      </ActionBar>
      <ClientsList
        createClient={allowed ? createClient : undefined}
        topOffset={actionBarHeight + topOffset}
        clients={clients}
        error={error}
        loading={loading}
      />
    </FilterWrapper>
  );
};

function FilterWrapper({ children }: React.PropsWithChildren<{}>) {
  return (
    <FilterListProvider storageKey="clients">{children}</FilterListProvider>
  );
}
