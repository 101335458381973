import React from 'react';

import {
  Box,
  BoxProps,
  CircularProgress,
  ClickAwayListener,
  Grow,
  Paper,
  Popper,
  PopperProps,
  TextField,
  makeStyles,
} from '@material-ui/core';
import Search from '@material-ui/icons/Search';
import { Autocomplete, AutocompleteProps } from '@material-ui/lab';

import classNames from 'classnames';

type AutocompletePopperProps<
  T,
  Multiple extends boolean | undefined,
  DisableClearable extends boolean | undefined,
  FreeSolo extends boolean | undefined,
> = React.PropsWithChildren<
  Omit<
    AutocompleteProps<T, Multiple, DisableClearable, FreeSolo>,
    'open' | 'disableCloseOnSelect' | 'renderInput'
  > & {
    id?: string;
    placement?: PopperProps['placement'];
    display?: BoxProps['display'];
  }
>;

const useStyles = makeStyles(theme => ({
  popper: {
    zIndex: theme.zIndex.drawer,
  },
  paper: {
    padding: theme.spacing(1),
    marginTop: theme.spacing(0.5),
    width: 300,
  },
  autoCompletePaper: {
    boxShadow: 'none',
    margin: 0,
    color: theme.palette.text.secondary,
    fontSize: theme.typography.body2.fontSize,
  },
  popperDisablePortal: {
    position: 'relative',
  },
}));

export const AutocompletePopper = <
  T,
  Multiple extends boolean | undefined,
  DisableClearable extends boolean | undefined,
  FreeSolo extends boolean | undefined,
>({
  id: propId,
  children,
  classes: propClasses,
  placement = 'bottom-end',
  display = 'inline-block',
  ...autocompleteProps
}: AutocompletePopperProps<T, Multiple, DisableClearable, FreeSolo>) => {
  const classes = useStyles();
  const [anchorEl, setAnchorEl] = React.useState<HTMLElement | null>(null);

  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    if (!autocompleteProps.disabled) {
      setAnchorEl(event.currentTarget);
    }
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);
  const id = open ? propId || 'autocomplete-popover' : undefined;

  return (
    <>
      <Box display={display} onClick={handleClick}>
        {children}
      </Box>
      <Popper
        id={id}
        open={open}
        anchorEl={anchorEl}
        placement={placement}
        className={classes.popper}
        transition
      >
        {({ TransitionProps }) => (
          <ClickAwayListener onClickAway={handleClose}>
            <Grow in={open} {...TransitionProps}>
              <Paper elevation={2} className={classes.paper}>
                <Autocomplete
                  {...autocompleteProps}
                  classes={{
                    ...propClasses,
                    paper: classNames(
                      classes.autoCompletePaper,
                      propClasses?.paper,
                    ),
                    popperDisablePortal: classNames(
                      classes.popperDisablePortal,
                      propClasses?.popperDisablePortal,
                    ),
                  }}
                  open
                  disablePortal
                  disableCloseOnSelect
                  renderInput={({ InputProps, ...props }) => (
                    <TextField
                      {...props}
                      variant="outlined"
                      margin="dense"
                      autoFocus
                      InputProps={{
                        ...InputProps,
                        startAdornment: <Search />,
                        endAdornment: autocompleteProps.loading ? (
                          <>
                            <CircularProgress size="1em" color="inherit" />
                            {InputProps?.endAdornment}
                          </>
                        ) : (
                          InputProps.endAdornment
                        ),
                      }}
                    />
                  )}
                />
              </Paper>
            </Grow>
          </ClickAwayListener>
        )}
      </Popper>
    </>
  );
};
