import React from 'react';

import {
  Button,
  ButtonProps,
  Menu,
  MenuItem,
  MenuItemProps,
  MenuProps as MuiMenuProps,
  Tooltip,
  makeStyles,
} from '@material-ui/core';
import ExpandMore from '@material-ui/icons/ExpandMore';

import classNames from 'classnames';

type Option = Omit<MenuItemProps, 'button'> & {
  label: string;
};

type TargetingDropdownProps = ButtonProps & {
  id?: string;
  name?: string;
  readOnly?: boolean;
  label?: string;
  MenuProps?: Partial<MuiMenuProps>;
  highlight?: boolean;
  tooltip?: string;
  options?: Option[];
};

const useStyles = makeStyles(theme => ({
  button: {
    opacity: 0.7,
    textTransform: 'none',
    minWidth: 'auto',
    margin: theme.spacing(0, 1),
    '& + &': {
      marginLeft: 0,
    },
    '&$colorInherit$text': {
      '&:hover, &:active, &:focus': {
        opacity: 1,
      },
    },
  },
  highlight: {
    opacity: 1,
  },
  colorInherit: {},
  text: {},
  disabled: {},
  endIcon: {
    marginLeft: theme.spacing(0.5),
    transition: theme.transitions.create(['opacity'], {
      duration: '100ms',
    }),
    '$button &': {
      color: 'inherit',
      opacity: theme.palette.action.disabledOpacity,
    },
    '$button:hover &': {
      opacity: 1,
    },
  },
  readOnly: {
    color: 'inherit',
    '$disabled&': {
      color: 'inherit',
      opacity: 1,
    },
  },
}));

export const TargetingDropdown = ({
  disabled = false,
  label,
  tooltip,
  id = 'dropdown',
  name,
  MenuProps,
  readOnly,
  children,
  className,
  highlight,
  options,
  ...buttonProps
}: TargetingDropdownProps) => {
  const classes = useStyles();
  const [anchorEl, setAnchorEl] = React.useState<null | Element>(null);
  const open = Boolean(anchorEl);
  const handleOpen = (event: React.MouseEvent<Element>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <>
      <Tooltip title={tooltip || ''} arrow>
        <div>
          <Button
            {...buttonProps}
            classes={{
              colorInherit: classes.colorInherit,
              text: classes.text,
              endIcon: classes.endIcon,
              disabled: classes.disabled,
            }}
            className={classNames(classes.button, className, {
              [classes.highlight]: highlight,
              [classes.readOnly]: readOnly,
            })}
            variant="text"
            color="inherit"
            size="small"
            name={`${name}-button`}
            aria-haspopup="true"
            aria-expanded={open ? 'true' : 'false'}
            endIcon={readOnly ? null : <ExpandMore color="inherit" />}
            onClick={readOnly ? undefined : handleOpen}
            disabled={readOnly || disabled}
          >
            {label}
          </Button>
        </div>
      </Tooltip>
      <Menu
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        getContentAnchorEl={null}
        data-testid={`${name}-menu`}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        MenuListProps={{
          'aria-labelledby': id,
        }}
        {...MenuProps}
      >
        {options?.map(({ label: optionLabel, onClick, ...menuItemProps }) => (
          <MenuItem
            key={optionLabel}
            onClick={e => {
              onClick?.(e);
              handleClose();
            }}
            {...menuItemProps}
          >
            {optionLabel}
          </MenuItem>
        ))}
        {children}
      </Menu>
    </>
  );
};
