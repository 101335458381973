import React from 'react';
import { Route, RouterProvider, createBrowserRouter } from 'react-router-dom';

import { CssBaseline } from '@material-ui/core';

import {
  ConfidenceAuth,
  ConfidenceCoreProvider,
  confidenceUserApiRef,
} from '@spotify-confidence/core-react';
import {
  ConfidenceAdminPage,
  confidenceAdminPlugin,
} from '@spotify-confidence/plugin-admin';
import {
  ConfidenceEventsPage,
  eventsPlugin,
} from '@spotify-confidence/plugin-events';
import {
  ConfidenceFlagsPage,
  flagsPlugin,
} from '@spotify-confidence/plugin-flags';
import { ConfidenceInsightsPage } from '@spotify-confidence/plugin-insights';
import {
  ConfidenceMetricsPage,
  metricsPlugin,
} from '@spotify-confidence/plugin-metrics';
import {
  ConfidenceSurfacesPage,
  surfacesPlugin,
} from '@spotify-confidence/plugin-surfaces';
import {
  ConfidenceWorkflowsPage,
  WorkflowExtensions,
  workflowsPlugin,
} from '@spotify-confidence/plugin-workflows';
import { ABTestWorkflowExtension } from '@spotify-confidence/plugin-workflows-module-ab-test';
import { AnalysisWorkflowExtension } from '@spotify-confidence/plugin-workflows-module-analysis';
import { ReanalysisWorkflowExtension } from '@spotify-confidence/plugin-workflows-module-reanalysis';
import { RolloutWorkflowExtension } from '@spotify-confidence/plugin-workflows-module-rollout';
import { ConfidenceProvider } from '@spotify-confidence/react';
import { Confidence } from '@spotify-confidence/sdk';
import { IconMix, IconMoon } from '@spotify-internal/encore-web';

import { createApp } from '@backstage/app-defaults';
import { AppRouter, FlatRoutes } from '@backstage/core-app-api';
import { AlertDisplay } from '@backstage/core-components';
import {
  AnyApiFactory,
  analyticsApiRef,
  createApiFactory,
  featureFlagsApiRef,
} from '@backstage/core-plugin-api';
import { imageUploadApiRef } from '@backstage/plugin-image-upload';
import { userSettingsPlugin } from '@backstage/plugin-user-settings';

import { ConfidenceImageUploadClient } from './apis/image-upload';
import { ConnectPage } from './components/ConnectPage/ConnectPage';
import { FlagContext } from './components/FlagContext';
import { HomePage } from './components/HomePage';
import { InvitationPage } from './components/InvitationPage';
import { Root } from './components/Root';
import { SignUpPage } from './components/SignUp';
import { UnavailabilityPage } from './components/SignUp/UnavailabilityPage';
import {
  BackstageDarkThemeProvider,
  BackstageLightThemeProvider,
  EncoreDarkThemeProvider,
  EncoreLightThemeProvider,
} from './theme';
import { ConfidenceAnalytics } from './utils/ConfidenceAnalytics';
import { ConfidenceFeatureFlags } from './utils/ConfidenceFeatureFlags';

const confidenceApi = Confidence.create({
  clientSecret: 'NiAgIVmkjVSTNI5k8mpsgs3EeFqkacrA',
  environment: 'client',
  region: 'eu',
  timeout: 3000,
  logger: console,
});

const apis: AnyApiFactory[] = [
  createApiFactory({
    api: analyticsApiRef,
    deps: { confidenceUserApi: confidenceUserApiRef },
    factory: ({ confidenceUserApi }) =>
      new ConfidenceAnalytics({ confidenceApi, confidenceUserApi }),
  }),
  createApiFactory({
    api: confidenceUserApiRef,
    deps: {},
    factory: () => ConfidenceAuth.create(),
  }),
  createApiFactory({
    api: featureFlagsApiRef,
    deps: {},
    factory: () => new ConfidenceFeatureFlags(),
  }),
  createApiFactory({
    api: imageUploadApiRef,
    deps: {
      userApi: confidenceUserApiRef,
    },
    factory: ({ userApi }) => new ConfidenceImageUploadClient(userApi),
  }),
];

const app = createApp({
  apis,
  // To get access to the react-router data API (supporting for example useBlocker),
  // we need to replace the default Router component
  // https://github.com/backstage/backstage/issues/19681#issuecomment-1761603883
  components: {
    Router: props => {
      const router = createBrowserRouter([
        { path: '*', Component: () => <>{props.children}</> },
      ]);

      return <RouterProvider router={router} />;
    },
  },
  plugins: [
    workflowsPlugin,
    flagsPlugin,
    metricsPlugin,
    confidenceAdminPlugin,
    userSettingsPlugin,
    surfacesPlugin,
  ],
  themes: [
    {
      id: 'encore-dark',
      title: 'Encore Dark',
      variant: 'dark',
      icon: <IconMoon />,
      Provider: ({ children }) => (
        <EncoreDarkThemeProvider>
          <CssBaseline>{children}</CssBaseline>
        </EncoreDarkThemeProvider>
      ),
    },
    {
      id: 'encore-light',
      title: 'Encore Light',
      variant: 'light',
      icon: <IconMix />,
      Provider: ({ children }) => (
        <EncoreLightThemeProvider>
          <CssBaseline>{children}</CssBaseline>
        </EncoreLightThemeProvider>
      ),
    },
    {
      id: 'backstage-dark',
      title: 'Backstage Dark',
      variant: 'dark',
      icon: <IconMoon />,
      Provider: ({ children }) => (
        <BackstageDarkThemeProvider>
          <CssBaseline>{children}</CssBaseline>
        </BackstageDarkThemeProvider>
      ),
    },
    {
      id: 'backstage-light',
      title: 'Backstage Light',
      variant: 'light',
      icon: <IconMix />,
      Provider: ({ children }) => (
        <BackstageLightThemeProvider>
          <CssBaseline>{children}</CssBaseline>
        </BackstageLightThemeProvider>
      ),
    },
  ],

  bindRoutes({ bind }) {
    bind(workflowsPlugin.externalRoutes, {
      flag: flagsPlugin.routes.flag,
      flags: flagsPlugin.routes.flags,
      metric: metricsPlugin.routes.metric,
      metrics: metricsPlugin.routes.metrics,
      entity: metricsPlugin.routes.entity,
      entities: metricsPlugin.routes.entities,
      assignmentTable: metricsPlugin.routes.assignmentTable,
      assignmentTablesCreate: metricsPlugin.routes.assignmentTablesCreate,
      factTable: metricsPlugin.routes.factTable,
    });

    bind(metricsPlugin.externalRoutes, {
      workflowInstance: workflowsPlugin.routes.workflowInstanceRoute,
    });
    bind(flagsPlugin.externalRoutes, {
      workflow: workflowsPlugin.routes.workflowRoute,
      workflowInstance: workflowsPlugin.routes.workflowInstanceRoute,
      clients: confidenceAdminPlugin.routes.clients,
      clientSchema: confidenceAdminPlugin.routes.clientSchema,
      client: confidenceAdminPlugin.routes.client,
    });

    bind(confidenceAdminPlugin.externalRoutes, {
      assignmentTable: metricsPlugin.routes.assignmentTable,
      entity: metricsPlugin.routes.entity,
      entities: metricsPlugin.routes.entities,
    });

    bind(eventsPlugin.externalRoutes, {
      entity: metricsPlugin.routes.entity,
      entities: metricsPlugin.routes.entities,
    });
  },
});

const workflowExtensions = (
  <WorkflowExtensions>
    <ABTestWorkflowExtension />
    <RolloutWorkflowExtension />
    <AnalysisWorkflowExtension />
    <ReanalysisWorkflowExtension />
  </WorkflowExtensions>
);

const routes = (
  <FlatRoutes>
    <Route path="/" element={<HomePage />} />
    <Route path="/invitation" element={<InvitationPage />} />

    <Route path="/signup/unsupported-market" element={<UnavailabilityPage />} />

    <Route path="/signup/:id?" element={<SignUpPage />} />
    <Route path="/beta/:id" element={<SignUpPage />} />
    <Route path="/connect" element={<ConnectPage />} />

    <Route path="/workflows" element={<ConfidenceWorkflowsPage />}>
      {workflowExtensions}
    </Route>
    <Route path="/flags" element={<ConfidenceFlagsPage />} />
    <Route path="/metrics" element={<ConfidenceMetricsPage />} />
    <Route path="/events" element={<ConfidenceEventsPage />} />
    <Route path="/surfaces" element={<ConfidenceSurfacesPage />}>
      {workflowExtensions}
    </Route>
    <Route path="/admin" element={<ConfidenceAdminPage />} />
    <Route path="/insights" element={<ConfidenceInsightsPage />} />
  </FlatRoutes>
);

export const App = app.createRoot(
  <>
    <AlertDisplay transientTimeoutMs={2500} />
    <AppRouter>
      <ConfidenceProvider confidence={confidenceApi}>
        <ConfidenceCoreProvider>
          <FlagContext>
            <Root>{routes}</Root>
          </FlagContext>
        </ConfidenceCoreProvider>
      </ConfidenceProvider>
    </AppRouter>
  </>,
);
