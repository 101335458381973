import React from 'react';
import { useAsyncFn } from 'react-use';

import {
  Box,
  DialogActions,
  DialogContent,
  TextField,
} from '@material-ui/core';
import { Alert } from '@material-ui/lab';

import {
  DialogForm,
  DialogHeader,
  FormSubmitButtons,
  useDialog,
  withoutAutoFill,
} from '@spotify-confidence/core-react';
import {
  IdentityAutocomplete,
  useDefaultOwner,
} from '@spotify-confidence/plugin-permissions-react';

const TextFieldWithoutAutoFill = withoutAutoFill(TextField);

/**
 * The value of the form. Passed to the onSubmit callback.
 */
type SurfaceInput = {
  displayName: string;
  description: string;
  owner?: string;
};

/**
 * Props for the SurfaceCreateDialog component. See {@link SurfaceCreateDialog}.
 */
export interface SurfaceCreateDialogProps {
  value?: SurfaceInput;
  onSubmit: (value: SurfaceInput) => Promise<void>;
  onClose?: () => void;
  actionLabel?: string;
}

/**
 * Dialog for creating a new surface.
 *
 * @param onSubmit Callback for when the form is submitted. The callback
 *   should return a promise that resolves when the surface has been created.
 */
export function SurfaceCreateDialog({
  value,
  onSubmit,
  onClose,
  actionLabel = 'Create',
}: SurfaceCreateDialogProps) {
  const { closeDialog } = useDialog();
  const [owner, setOwner, { loading: loadingUserIdentity }] = useDefaultOwner();

  const [{ error, loading }, handleSubmit] = useAsyncFn(onSubmit, []);

  return (
    <Box>
      <DialogForm
        onSubmit={e => {
          // prevent the form from submitting, or it will cause a full
          // page reload
          e.preventDefault();

          const formData = new FormData(e.currentTarget);
          handleSubmit({
            displayName: formData.get('name') as string,
            description: formData.get('description') as string,
            owner: owner ?? undefined,
          });
        }}
      >
        <DialogHeader
          title={value ? 'Update surface' : 'Create surface'}
          onClose={onClose}
        />
        <DialogContent>
          {error && <Alert severity="error">{error.message}</Alert>}

          <TextFieldWithoutAutoFill
            name="name"
            label="Name"
            autoFocus
            fullWidth
            placeholder="A name for your surface, like 'Search' or 'Front page'"
            required
            variant="outlined"
            defaultValue={value?.displayName}
            InputLabelProps={{
              shrink: true,
            }}
          />
          <TextFieldWithoutAutoFill
            name="description"
            label="Description"
            multiline
            minRows={3}
            maxRows={4}
            fullWidth
            placeholder="Add a short description of the surface"
            variant="outlined"
            defaultValue={value?.description}
            InputLabelProps={{
              shrink: true,
            }}
          />
          <IdentityAutocomplete
            value={owner}
            onChange={(_e, newIdentity) => {
              setOwner(newIdentity);
            }}
            label="Owner"
            name="owner"
            required
            loading={loadingUserIdentity}
          />
        </DialogContent>
        <DialogActions>
          <FormSubmitButtons
            onCancel={closeDialog}
            loading={loading}
            disabled={false}
            label={actionLabel}
          />
        </DialogActions>
      </DialogForm>
    </Box>
  );
}
