import React from 'react';

import {
  Box,
  Card,
  CardContent,
  Collapse,
  FormControlLabel,
  FormControlLabelProps,
  FormHelperText,
  InputLabel,
  Radio,
  RadioGroup,
  Typography,
  makeStyles,
  styled,
} from '@material-ui/core';
import { Alert } from '@material-ui/lab';

import { useDisplayNames } from '@spotify-confidence/core-react';
import { MetricsV1MetricInput } from '@spotify-confidence/plugin-graphql';

import { TimeWindowDropdown } from '../../TimeWindowDropdown';
import {
  MeasurementConfigKind,
  getAggregationWindowIfExists,
  getExposureOffsetIfExists,
  getMeasurementKind,
  parseExposureOffset,
} from '../utils';

export type TimeWindowFormProps = {
  value: MetricsV1MetricInput;
  onChange: (newInput: MetricsV1MetricInput) => void;
  disabled?: boolean;
  entity?: string;
  isEdit: boolean;
};

const Wrapper = styled('div')(({ theme }) => ({
  display: 'flex',
  flexWrap: 'wrap',
  alignItems: 'center',
  justifyContent: 'center',
  columnGap: '1em',
  [theme.breakpoints.up('sm')]: {
    padding: theme.spacing(2, 3),
  },
}));

const ValueWrapper = styled('div')({
  display: 'flex',
  flexWrap: 'wrap',
  alignItems: 'center',
  justifyContent: 'start',
  flexGrow: 1,
  columnGap: '1em',
});

type FormControlWithDescriptionProps = Omit<FormControlLabelProps, 'label'> & {
  label: string;
  description: string;
  caveat?: string;
};

const useStyles = makeStyles(theme => ({
  root: {
    margin: theme.spacing(2, 1.5, 2, 1.5),
  },
  wrapper: {
    display: 'flex',
    alignItems: 'center',
    gap: theme.spacing(4),
  },
}));

export const FormControlWithDescription = ({
  label,
  description,
  caveat,
  ...formControlLabelProps
}: FormControlWithDescriptionProps) => {
  const classes = useStyles();

  return (
    <FormControlLabel
      {...formControlLabelProps}
      classes={{
        root: classes.root,
      }}
      label={
        <div className={classes.wrapper}>
          <div>
            <Typography variant="h6">{label}</Typography>
            <Typography variant="body1" color="textSecondary">
              {description}
              {caveat && <span style={{ fontStyle: 'italic' }}> {caveat}</span>}
            </Typography>
          </div>
        </div>
      }
    />
  );
};

export const TimeWindowForm = ({
  value,
  onChange,
  disabled,
  entity,
  isEdit,
}: TimeWindowFormProps) => {
  const { displayNames } = useDisplayNames();
  const entityName = displayNames.get(entity || '');
  const entityValue = entityName?.toLocaleLowerCase() || 'entity';
  const [measurementConfigKind, setMeasurementConfigKind] =
    React.useState<MeasurementConfigKind>(
      getMeasurementKind(value.measurementConfig),
    );

  const [lastAggregationWindow, setLastAggregationWindow] = React.useState<
    string | undefined
  >(getAggregationWindowIfExists(value.measurementConfig) ?? '86400s');
  const [lastExposureOffset, setLastExposureOffset] = React.useState<
    string | undefined
  >(getExposureOffsetIfExists(value.measurementConfig));

  return (
    <>
      <Card>
        <CardContent>
          <InputLabel>Include the {entityValue} in metric results</InputLabel>
          <Box marginTop={2}>
            <RadioGroup
              aria-label="type"
              name="measurement-type"
              value={measurementConfigKind}
              onChange={event => {
                if (event.target.value === MeasurementConfigKind.ClosedWindow) {
                  onChange({
                    ...value,
                    measurementConfig: {
                      closedWindow: {
                        aggregationWindow: lastAggregationWindow ?? '0s',
                        exposureOffset: lastExposureOffset ?? '0s',
                      },
                    },
                  });
                } else if (
                  event.target.value === MeasurementConfigKind.SemiOpenWindow
                ) {
                  onChange({
                    ...value,
                    measurementConfig: {
                      semiOpenWindow: {
                        aggregationWindow: lastAggregationWindow ?? '0s',
                        exposureOffset: lastExposureOffset ?? '0s',
                      },
                    },
                  });
                } else {
                  onChange({
                    ...value,
                    measurementConfig: {
                      openWindow: {},
                    },
                  });
                }
                setMeasurementConfigKind(
                  event.target.value as MeasurementConfigKind,
                );
              }}
            >
              {isEdit && (
                <Box marginBottom={2}>
                  <Alert severity="warning">
                    It is not possible to edit measurement type for an existing
                    metric.
                  </Alert>
                </Box>
              )}

              <FormControlWithDescription
                disabled={isEdit}
                value={MeasurementConfigKind.ClosedWindow}
                control={<Radio color="primary" />}
                label="At the end of a window"
                description="Example: Second week consumption. Includes all consumption during the second week after exposure."
                caveat="Includes an entity when they reach the end of their second week."
              />
              <FormControlWithDescription
                disabled={isEdit}
                value={MeasurementConfigKind.SemiOpenWindow}
                control={<Radio color="primary" />}
                label="Cumulatively during a window"
                description="Example: Second week consumption. Includes all consumption during the second week after exposure."
                caveat="Includes an entity when they reach the beginning of their second week."
              />
              <FormControlWithDescription
                disabled={isEdit}
                value={MeasurementConfigKind.OpenWindow}
                control={<Radio color="primary" />}
                label="Cumulatively"
                description="Example: Average order value. Includes all orders after exposure. Includes all exposed entities."
              />
            </RadioGroup>
          </Box>
          <Collapse
            in={measurementConfigKind !== MeasurementConfigKind.OpenWindow}
            unmountOnExit
          >
            <Box marginTop={4}>
              <InputLabel>Time window</InputLabel>
              <Wrapper>
                <ValueWrapper>
                  <Typography>Starting</Typography>
                  <Box flexShrink={0}>
                    <TimeWindowDropdown
                      tooltip={`When to start measuring each ${entityValue}. Start measuring after exposure to reduce the impact of novelty effects.`}
                      name="exposure-offset"
                      value={
                        getExposureOffsetIfExists(value.measurementConfig) ??
                        lastExposureOffset ??
                        '0s'
                      }
                      parseWindow={parseExposureOffset}
                      onChange={exposureOffset => {
                        setLastExposureOffset(exposureOffset);
                        if (!!value.measurementConfig?.closedWindow) {
                          onChange({
                            ...value,
                            measurementConfig: {
                              closedWindow: {
                                ...value.measurementConfig.closedWindow,
                                exposureOffset: exposureOffset ?? '0s',
                              },
                            },
                          });
                        } else if (!!value.measurementConfig?.semiOpenWindow) {
                          onChange({
                            ...value,
                            measurementConfig: {
                              semiOpenWindow: {
                                ...value.measurementConfig.semiOpenWindow,
                                exposureOffset: exposureOffset ?? '0s',
                              },
                            },
                          });
                        }
                      }}
                      allowZero
                      disabled={disabled}
                    />
                  </Box>
                </ValueWrapper>
                <ValueWrapper>
                  <Typography>ending</Typography>
                  <Box flexShrink={0}>
                    <TimeWindowDropdown
                      tooltip={`How long to measure each ${entityValue}.`}
                      name="aggregation-window"
                      value={
                        getAggregationWindowIfExists(value.measurementConfig) ??
                        lastAggregationWindow ??
                        '0s'
                      }
                      onChange={aggregationWindow => {
                        setLastAggregationWindow(aggregationWindow);
                        if (!!value.measurementConfig?.closedWindow) {
                          onChange({
                            ...value,
                            measurementConfig: {
                              closedWindow: {
                                ...value.measurementConfig.closedWindow,
                                aggregationWindow: aggregationWindow ?? '0s',
                              },
                            },
                          });
                        } else if (!!value.measurementConfig?.semiOpenWindow) {
                          onChange({
                            ...value,
                            measurementConfig: {
                              semiOpenWindow: {
                                ...value.measurementConfig.semiOpenWindow,
                                aggregationWindow: aggregationWindow ?? '0s',
                              },
                            },
                          });
                        }
                      }}
                      disabled={disabled}
                    />
                  </Box>
                  <Typography>later</Typography>
                </ValueWrapper>
              </Wrapper>
            </Box>
          </Collapse>
        </CardContent>
      </Card>
      {entityName && (
        <Box marginTop={1}>
          <FormHelperText>
            {entityName} refers to the entity you selected in a previous step.
          </FormHelperText>
        </Box>
      )}
    </>
  );
};
