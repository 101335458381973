import React from 'react';

import { MenuItem, Select } from '@material-ui/core';

import _ from 'lodash';

import { ResultVisualizationType } from './MetricResultTable';

type VisualizationSelectProps = {
  value: ResultVisualizationType;
  sequential?: boolean;
  onChange: (newValue: ResultVisualizationType) => void;
};

export const options: ResultVisualizationType[] = [
  'interval plot',
  'timeline',
  'none',
];

export const VisualizationSelect = ({
  value,
  sequential,
  onChange,
}: VisualizationSelectProps) => {
  const handleTypeChange = (event: React.ChangeEvent<{ value: unknown }>) => {
    const newType = event.target.value as ResultVisualizationType;
    onChange(newType);
  };
  const optionsToRender = React.useMemo(
    () => (sequential ? options : options.filter(o => o !== 'timeline')),
    [sequential],
  );

  const getTypeValue = (renderValue: unknown) => {
    const type = renderValue as ResultVisualizationType;
    return _.capitalize(type);
  };

  return (
    <Select
      value={value}
      onChange={handleTypeChange}
      variant="outlined"
      margin="dense"
      displayEmpty
      renderValue={getTypeValue}
      name="visualization-type"
    >
      {optionsToRender.map((option, i) => (
        <MenuItem key={`visualizationOption-${i}`} value={option}>
          {getTypeValue(option)}
        </MenuItem>
      ))}
    </Select>
  );
};
