import React from 'react';

import { ConfirmDialog } from '@spotify-confidence/core-react';

export type DisableRuleDialogProps = {
  onConfirm: () => Promise<any>;
};

export const DisableRuleDialog = ({ onConfirm }: DisableRuleDialogProps) => {
  return (
    <ConfirmDialog
      expected="disable"
      title="Disable Rule"
      onConfirm={onConfirm}
    >
      Disabling a rule will stop it from serving the assigned variants. To
      confirm this action, please type "disable".
    </ConfirmDialog>
  );
};
