import React from 'react';

import { Typography } from '@material-ui/core';

import { CodeSnippet } from '../../CodeSnippet/CodeSnippet';
import { f } from '../../CodeSnippet/format';

export const JavaClientSnippets = () => {
  return (
    <>
      <Typography variant="body1" gutterBottom>
        Let's track the sample "tutorial-event" event from your service and make
        sure it gets published to our backend.
      </Typography>
      <CodeSnippet
        language="java"
        text={f(`
confidence.track("tutorial-event");
`)}
        showCopyCodeButton
      />
      <Typography variant="body1" gutterBottom>
        To ensure the event is scheduled immediately for upload by the SDK, it's
        recommended to add the following as well.
      </Typography>
      <CodeSnippet
        language="java"
        showLineNumbers
        highlightedNumbers={[3, 14]}
        text={f(`
confidence.flush();
`)}
        showCopyCodeButton
      />
      <Typography variant="body1" gutterBottom>
        You can also use the following to wait for the event to be acknowledged
        by the backend before terminating your program.
      </Typography>
      <CodeSnippet
        language="java"
        showLineNumbers
        highlightedNumbers={[3, 14]}
        text={f(`
confidence.close();
`)}
        showCopyCodeButton
      />
    </>
  );
};
