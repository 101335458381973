import React from 'react';

import { Box, Typography, makeStyles } from '@material-ui/core';

import { SidebarSection } from '@spotify-confidence/core-react';
import { CommentZone } from '@spotify-confidence/plugin-comments-react';
import {
  WorkflowInstance,
  moduleHelpers,
} from '@spotify-confidence/plugin-workflows';

import { StrategyCard, StrategyCardMenu } from './StrategyCard';
import { StatsData } from './types';
import { useStatsModuleResultsConfig } from './useStatsModuleResultsConfig';

const useStyles = makeStyles(theme => ({
  wrapper: {
    position: 'relative',
    display: 'flex',
    flexDirection: 'column',
    gap: theme.spacing(0.5),
    '& > *': {
      minHeight: theme.spacing(3),
    },
  },
}));

export const SidebarResultsModuleSection = ({
  workflowInstance,
  disabled,
  onlySequential,
}: {
  workflowInstance: WorkflowInstance;
  disabled?: boolean;
  onlySequential?: boolean;
}) => {
  const classes = useStyles();
  const {
    strategy,
    sequential,
    configDescription,
    openResultsStrategyConfig,
    openStatsConfig,
  } = useStatsModuleResultsConfig({
    statsData: moduleHelpers.getModuleData<StatsData>(
      workflowInstance?.moduleData,
      'stats',
    ),
    name: workflowInstance?.name!,
    onlySequential,
  });

  return (
    <SidebarSection
      title="Results"
      zoneContext="stats"
      action={
        !disabled && (
          <StrategyCardMenu
            onEditResultsSettingsClick={openResultsStrategyConfig}
            onEditConfigureStatisticsClick={openStatsConfig}
          />
        )
      }
    >
      <CommentZone id="content">
        <Box className={classes.wrapper}>
          {!!strategy ? (
            <StrategyCard sequential={sequential} footer={configDescription} />
          ) : (
            <Typography color="textSecondary">Not configured</Typography>
          )}
        </Box>
      </CommentZone>
    </SidebarSection>
  );
};
