import { Navigate, useLocation, useNavigate } from 'react-router-dom';

const useNavigateTab = () => {
  const navigate = (href: string) => {
    const newTab = window.open(href, '_blank');
    if (newTab) {
      newTab.focus();
    }
  };

  return navigate;
};

export const withSubRoutes = (route: string) => `${route}/*`;

export {
  useNavigateTab,
  // ref. react-router-dom
  useNavigate,
  useLocation,
  Navigate,
};
