/**
 * ? note(@oussaz) - camelCase/PascalCase are lowerCased first
 *
 * @param str to convert to kebab case
 * @returns opiniated kebabCase string
 */
export function kebabCase(str: string) {
  return str.toLowerCase().replace(/[^a-z0-9]/g, '-');
}

export const isValidEmail = (email: string): boolean => {
  return !!String(email)
    .toLowerCase()
    .match(
      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|.(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
    );
};
